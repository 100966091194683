import React from 'react';
import Grid from '@material-ui/core/Grid';
import {CatalogImportFormHeader} from '../CatalogImportFormHeader';
import {HoopsFieldMappingRow} from '../HoopsFieldMappingRow';
import {required} from '../../../shared/formHelpers/validation';
import red from '../../../../ui/theme/red';
import orange from '../../../../ui/theme/orange';

export const fieldsToMap = [{
  name: 'code',
  label: 'Product Code',
  help: 'http://help.hoopscrm.com/en/articles/3667971-product-information',
  validate: required,
  chip: {
    theme: red,
    text: 'Required'
  }
}, {
  name: 'sku',
  label: 'SKU',
  array: true
}, {
  name: 'title',
  label: 'Product Name',
  help: 'http://help.hoopscrm.com/en/articles/3667971-product-information',
  validate: required,
  chip: {
    theme: red,
    text: 'Required'
  },
}, {
  name: 'description',
  label: 'Product Description',
  help: 'http://help.hoopscrm.com/en/articles/3667971-product-information',
  chip: {
    theme: orange,
    text: 'Recommended'
  }
}, {
  name: 'colors',
  label: 'Color(s)',
  help: 'http://help.hoopscrm.com/en/articles/3667971-product-information',
  validate: required,
  chip: {
    theme: red,
    text: 'Required'
  },
  array: true
}, {
  name: 'size',
  label: 'Size(s)',
  help: 'http://help.hoopscrm.com/en/articles/3667971-product-information',
  validate: required,
  chip: {
    theme: red,
    text: 'Required'
  },
  array: true
}, {
  name: 'brand',
  label: 'Brand',
}, {
  name: 'tags',
  label: 'Tags',
  array: true
}, {
  name: 'category',
  label: 'Category',
}, {
  name: 'subCategory',
  label: 'Sub Category',
}, {
  name: 'url',
  label: 'Product URL',
}, {
  name: 'primaryPriceDescription',
  label: 'Primary Price Description',
}, {
  name: 'materials',
  label: 'Materials',
}, {
  name: 'decorationsAvailable',
  label: 'Decorations Available',
}, {
  name: 'decorationAreas',
  label: 'Decoration Areas',
}, {
  name: 'stockIndent',
  label: 'Stock/Indent',
}];

export function Step2ProductInfo({values}) {
  return (
    <Grid container direction={'column'} spacing={4}>
      <CatalogImportFormHeader/>
      {fieldsToMap.map((field, index) =>
        <Grid container spacing={2} wrap={'nowrap'} key={index}>
          <HoopsFieldMappingRow
            formValues={values}
            fieldName={`fieldMapping.${field.name}`}
            key={field.name}
            field={field}/>
        </Grid>
      )}
    </Grid>
  );
}
