import React, {useCallback, useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import * as numeral from 'numeral';
import {useSelector} from 'react-redux';
import {get} from 'lodash';
import Chip from '@material-ui/core/Chip';
import {MuiThemeProvider, Tooltip, useTheme} from '@material-ui/core';
import {client} from '../../servicesOld/GraphQLService';
import {GQL_GET_INVOICE} from '../../queries/invoice';
import green from '../../ui/theme/green';
import Grid from '@material-ui/core/Grid';
import purple from '../../ui/theme/purple';
import red from '../../ui/theme/red';
import orange from '../../ui/theme/orange';
import InvoiceErrorChip from './InvoiceErrorChip';
import InfoIcon from '@material-ui/icons/Info';
import {CREATING, CURRENT, ERROR, OVERDUE, PAID_IN_FULL, PARTIALLY_PAID, QBO_ERROR} from '../../constants/invoiceStatuses';

function TooltipContent({invoice, status}) {
  const currencySymbolSelector = (state) => get(state, 'authReducer.userData.company.currencySymbol');
  const currencySymbol = useSelector(currencySymbolSelector);
  const [dueDate, setDueDate] = useState('-');
  useEffect(() => {
    if (moment(invoice.dueDate).isValid()) {
      setDueDate(moment(invoice.dueDate).format('DD MMM. YYYY'));
    }
  }, [invoice]);

  if (status === CREATING) {
    return (
      <>
        <div style={{width: 215, padding: 3}}>
          Your invoice data is being sent to your Accounting software.
        </div>
      </>
    );
  }

  return (
    <>
      <div style={{width: 215, padding: 3}}>
        <Grid container justifyContent={'space-between'} spacing={1}>
          <Grid item>
            <Grid container direction={'column'}>
              <Grid item>Invoice Number</Grid>
              <Grid item>Due Date</Grid>
              <Grid item>Total Amount</Grid>
              <Grid item>Amount Owing</Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction={'column'}>
              <Grid item>{invoice.invoiceNumber ? invoice.invoiceNumber : '-'}</Grid>
              <Grid item>{dueDate || '-'}</Grid>
              <Grid item>{currencySymbol}{numeral(invoice.totalAmount).format('0,0.00')}</Grid>
              <Grid item>{currencySymbol}{numeral(invoice.balance).format('0,0.00')}</Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

function InvoiceCellWithChip({chipProps, setInvoice, primaryText, secondaryText, invoice, status, showText}) {
  if (invoice && invoice.qboLastRequestStatus === QBO_ERROR) {
    return <InvoiceErrorChip invoice={invoice} setInvoice={setInvoice}/>;
  }
  return (
    <>
      <Grid container spacing={2} alignItems={'center'} justifyContent={'space-between'} wrap={'nowrap'}>
        {showText && <Grid item>
            {!!primaryText && <Typography variant={'body2'}>{primaryText}</Typography>}
            {!!secondaryText && <Typography variant={'body2'} color={'textSecondary'}>{secondaryText}</Typography>}
        </Grid>}
        {!!chipProps && <Grid item>
          <Tooltip title={<TooltipContent invoice={invoice} status={status} />} arrow>
              <Chip size={'small'} {...chipProps}/>
          </Tooltip>
        </Grid>}
      </Grid>
    </>
  );
}

function InvoiceCell({invoiceData, showText = true}) {
  const theme = useTheme();
  const companySelector = (state) => get(state, 'authReducer.userData.company.accountingPlatform.companyName');
  const companyName = useSelector(companySelector);

  const [invoiceStatuses] = useState({
    [CREATING]: {
      key: CREATING,
      theme: orange,
      label: 'Sending to Accounts'
    },
    [OVERDUE]: {
      key: OVERDUE,
      theme: red,
      label: 'Overdue'
    },
    [ERROR]: {
      key: ERROR,
      theme: red,
      label: 'Error Sending to Accounts'
    },
    [PAID_IN_FULL]: {
      key: PAID_IN_FULL,
      theme: green,
      label: 'Paid'
    },
    [PARTIALLY_PAID]: {
      key: PARTIALLY_PAID,
      theme: purple,
      label: 'Partially Paid'
    },
    [CURRENT]: {
      key: CURRENT,
      theme,
      label: 'Waiting Payment'
    }
  });

  const [dueDateFormatted, setDueDateFormatted] = useState('');
  const [invoice, setInvoice] = useState(invoiceData);
  const [invoiceStatus, setInvoiceStatus] = useState(invoiceStatuses[CURRENT]);

  const handleRefresh = useCallback(() => {
    client.query({
      query: GQL_GET_INVOICE,
      fetchPolicy: 'no-cache',
      variables: {_id: invoice._id}
    })
      .then(({data: {invoice: inv}}) => setInvoice(inv));
  }, [invoice]);

  useEffect(() => {
    setInvoice(invoiceData);
  }, [invoiceData]);

  useEffect(() => {
    if (invoice && invoice.dueDate) {
      setDueDateFormatted(moment(invoice.dueDate).format('MMM DD, YY'));
    }
  }, [invoice]);

  useEffect(() => {
    if (invoice && invoice.qboLastRequestStatus === QBO_ERROR) {
      setInvoiceStatus(invoiceStatuses[ERROR]);
      return;
    }

    if (!(invoice.qboInvoiceReference || invoice.xeroInvoiceId)) {
      setInvoiceStatus(invoiceStatuses[CREATING]);
      setTimeout(handleRefresh, 5000);
      return;
    }

    if (invoice.balance <= 0) {
      setInvoiceStatus(invoiceStatuses[PAID_IN_FULL]);
      return;
    }

    if (invoice.balance < invoice.totalAmount) {
      setInvoiceStatus(invoiceStatuses[PARTIALLY_PAID]);
      return;
    }

    if (moment(invoice.dueDate).isBefore()) {
      setInvoiceStatus(invoiceStatuses[OVERDUE]);
      return;
    }

    setInvoiceStatus(invoiceStatuses[CURRENT]);
  }, [handleRefresh, invoice, invoiceStatuses]);

  if (!companyName || !invoice) {
    return (<></>);
  }

  return (
    <>
      <MuiThemeProvider theme={invoiceStatus.theme}>
        <InvoiceCellWithChip
          invoice={invoice}
          setInvoice={setInvoice}
          status={invoiceStatus.key}
          primaryText={!!invoice.invoiceNumber && `#${invoice.invoiceNumber}`}
          secondaryText={(invoiceStatus.key !== PAID_IN_FULL) && dueDateFormatted}
          chipProps={{
            label: invoiceStatus.label,
            color: 'primary',
            icon: <InfoIcon />,
          }}
          showText={showText}
          />
      </MuiThemeProvider>
    </>
  );
}

export default InvoiceCell;
