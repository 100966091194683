import {get} from 'lodash';
import React from 'react';
import {useSelector} from 'react-redux';
import StatusCellEdit from './StatusCellEdit';

export const StatusChangerCell = ({
    entityType,
    entityMapping,
    value,
    disabled = false,
    size = 'small',
    onChange = () => null,
    returnValue = false
}) => {
    const statusGroupSelector = (state) => {
        if (!entityMapping || !entityType) {
            return {};
        }

        let group = {};

        if (entityMapping === 'sentStatus' && entityType === 'purchase_order') {
            group = {
                '_id': 'MOCKED_GROUP',
                'label': 'Purchase Order',
                'locked': true,
                'entityType': entityType,
                'entityName': 'PURCHASE ORDER',
                'entityMapping': entityMapping,
                'isVirtual': true,
                'statuses': [
                    {
                        '_id': 'GROUPNOTSENT',
                        'label': 'NOT SENT',
                        'value': 'Not_Sent',
                        'color': '#D9DADC',
                        'locked': true
                    },
                    {
                        '_id': 'GROUPSENT',
                        'label': 'SENT',
                        'value': 'Sent',
                        'color': '#22CC96',
                        'locked': true
                    },
                    {
                        '_id': 'GROUPVIEWED',
                        'label': 'VIEWED',
                        'value': 'Viewed',
                        'color': '#9366AF',
                        'locked': true
                    },
                ]
            };
        } else {
            group = get(state, 'companyReducer.company.statuses', []).filter((statusGroup) => statusGroup.entityType === entityType && statusGroup.entityMapping === entityMapping)[0];
        }
        return group;
    };
    const statusGroup = useSelector(statusGroupSelector);

    const handleOnChange = (status) => {
        const result = returnValue ? status.value : status;
        onChange(result);
    };

    return (
        <StatusCellEdit onChange={handleOnChange} value={value} disabled={disabled} statusGroup={statusGroup} size={size} />
    );
};

export default StatusChangerCell;
