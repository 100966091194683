// TODO: fix eslint disable
/* eslint-disable consistent-return, no-shadow */

import {Checkbox, Chip, Typography} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {TreeItem, TreeView} from '@material-ui/lab';
import {uniqBy} from 'lodash';
import React from 'react';
import theme from '../../ui/theme';
import {DefaultMarkup} from './defaultMarkupClass';

export const RootTree = ({
  treeRootTitle = '',
  markup = null,
  markupType = null,
  treeData = [],
  assignedMarkupData = [],
  onChange = () => null,
  onDelete = () => null,
}) => {

  const rootObj = {
    type: markupType,
    markupId: markup._id,
    vendorId: null,
    brand: null,
    category: null,
    subCategory: null,
    markup: {name: markup.name,}
  };

  const classyTreeBranch = new DefaultMarkup(rootObj, assignedMarkupData);
  const {isAssignedMarkup, isParentOfMarkup, assignedMarkupId, assignedMarkupName, parentAssignedMarkupArray} = classyTreeBranch;

  const renderTreeItems = (treeData) => treeData.filter((d) => d.name).sort((a, b) => a.name.localeCompare(b.name)).map((treeItem, idx) => {
    let treeBranchObj = {...rootObj};
    let children = null;

    if (treeItem.type === 'vendor' || markupType === 'ADDITIONAL_COST') {
      treeBranchObj.vendorId = treeItem._id;
    } else if (treeItem.type === 'brand') {
      treeBranchObj.brand = treeItem.name;
    } else if (treeItem.type === 'category') {
      treeBranchObj.category = treeItem.name;
    } else if (treeItem.type === 'subCategory') {
      treeBranchObj.subCategory = treeItem.name;
    }

    const classyTreeBranch = new DefaultMarkup(treeBranchObj, assignedMarkupData);
    const {
      isAssignedMarkup,
      assignedMarkupId,
      assignedMarkupName,
      isChildOfMarkup,
      isChildOfMarkupId,
      isParentOfMarkup,
      parentAssignedMarkupArray
    } = classyTreeBranch;

    const checkboxObj = {...treeBranchObj};

    if (Array.isArray(treeItem.children)) {
      children = renderTreeItems(treeItem.children);
    }

    return (
      <TreeItem
        key={`${idx}`}
        nodeId={`${idx}${treeItem.name}`}
        label={
          <div style={{display: 'flex', alignItems: 'center'}}>
            <Checkbox
              color='primary'
              size='small'
              onChange={(e) => onChange(e, checkboxObj)}
              checked={isAssignedMarkup || isChildOfMarkup}
              indeterminate={isParentOfMarkup && !isAssignedMarkup}
              disabled={
                (isAssignedMarkup && assignedMarkupId !== markup._id)
                || (isChildOfMarkup && isChildOfMarkupId !== markup._id)
              }
              onClick={(e) => e.stopPropagation()}
            />
            <Typography variant='body2'>{treeItem.name}</Typography>
            {(isAssignedMarkup) && assignedMarkupId !== markup._id &&
              <Chip
                label={assignedMarkupName}
                size='small'
                style={{marginLeft: theme.spacing(1)}}
                onDelete={(e) => onDelete(e, checkboxObj)}
              />
            }
            {(isParentOfMarkup) && !isAssignedMarkup &&
              uniqBy(parentAssignedMarkupArray, 'markup.name').map((el, idx) => markup._id !== el.markupId && (
                <Chip
                  key={idx}
                  label={el.markup.name}
                  size='small'
                  style={{marginLeft: theme.spacing(1)}}
                  onDelete={(e) => onDelete(e, el, checkboxObj)}
                />
              ))}

          </div>
        }
      >
        {children}
      </TreeItem>
    );
  });

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon/>}
      defaultExpandIcon={<ChevronRightIcon/>}
      disableSelection

    >
      <TreeItem
        nodeId='parent'
        label={
          <div style={{display: 'flex', alignItems: 'center'}}>
            <Checkbox
              color='primary'
              size='small'
              checked={isAssignedMarkup}
              indeterminate={isParentOfMarkup && !isAssignedMarkup}
              disabled={(isAssignedMarkup) && assignedMarkupId !== markup._id}
              onChange={(e) => onChange(e, rootObj)}
              style={(isParentOfMarkup && !isAssignedMarkup) ? {color: 'rgba(0, 0, 0, 0.54)'} : {}}
              onClick={(e) => e.stopPropagation()}
            />
            <Typography variant='body2'>{treeRootTitle}</Typography>
            {(isAssignedMarkup && !isParentOfMarkup) && assignedMarkupId !== markup._id &&
              <Chip
                label={assignedMarkupName}
                size='small'
                style={{marginLeft: theme.spacing(1)}}
                onDelete={(e) => onDelete(e, rootObj)}
              />
            }
            {(isParentOfMarkup) &&
              uniqBy(parentAssignedMarkupArray, 'markup.name').map((el, idx) => markup._id !== el.markupId && (
                <Chip
                  key={idx}
                  label={el.markup.name}
                  size='small'
                  style={{marginLeft: theme.spacing(1)}}
                  onDelete={(e) => onDelete(e, el, rootObj)}
                />
              ))}
          </div>
        }
      >
        {treeData && assignedMarkupData && renderTreeItems(treeData)}

      </TreeItem>
    </TreeView>);
};
