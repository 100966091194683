// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import {Icon, IconButton, ListItemIcon, Menu, MenuItem, Typography} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import ConfirmDeleteDialog from '../../componentsOld/confirmDeleteDialog';
import {updateAutomation} from './actions';

const AutomationManyTableActionMenu = ({
    rowData = [],
    handleDelete = () => null,
    openEditModal = () => null,
    duplicateLineItem = () => null,
}) => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const openConfirmDelete = () => {
        setConfirmDelete(true);
        setAnchorEl(null);
    };

    const handleToggle = (data) => {
        const updatedData = {...data, isDisabled: !data.isDisabled};
        dispatch(updateAutomation(data._id, updatedData));
    };

    return (
        <>
            <IconButton
                onClick={handleClick}
                variant={'outlined'}
                size={'small'}
            >
                <Icon>more_horiz</Icon>
            </IconButton>
            <Menu
                elevation={1}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={(e) => { handleClose(); openEditModal(e, rowData._id); }}>
                    <ListItemIcon style={{minWidth: '48px'}}>
                        <Icon fontSize='small'>edit</Icon>
                    </ListItemIcon>
                    <Typography variant='body2'>Edit</Typography>
                </MenuItem>
                <MenuItem onClick={(e) => { handleClose(); duplicateLineItem(e, rowData._id); }}>
                    <ListItemIcon style={{minWidth: '48px'}}>
                        <FileCopyIcon fontSize='small' />
                    </ListItemIcon>
                    <Typography variant='body2'>Duplicate</Typography>
                </MenuItem>
                <MenuItem onClick={(e) => { handleClose(); handleToggle(rowData); }}>
                    <ListItemIcon style={{minWidth: '48px'}}>
                        {
                            rowData.isDisabled
                                ? <CheckCircleIcon fontSize='small'/>
                                : <CancelIcon fontSize='small' />
                        }
                    </ListItemIcon>
                    <Typography variant='body2'>
                        {
                            rowData.isDisabled
                                ? 'Activate'
                                : 'Disable'
                        }
                    </Typography>
                </MenuItem>
                <MenuItem onClick={() => { handleClose(); openConfirmDelete(); }}>
                    <ListItemIcon style={{minWidth: '48px'}}>
                        <Icon fontSize='small'>delete</Icon>
                    </ListItemIcon>
                    <Typography variant='body2'>Delete</Typography>
                </MenuItem>
            </Menu>
            <ConfirmDeleteDialog name={rowData.title} isOpen={confirmDelete} ok={(e) => { setConfirmDelete(false); handleDelete(e, rowData._id); }} />
        </>
    );
};

export default AutomationManyTableActionMenu;
