import {Popover} from '@mui/material';
import {CalendarPicker} from '@mui/x-date-pickers';
import * as React from 'react';
import theme from '../../../../ui/theme';
import {useCallback, useState} from 'react';
import {CellContainerPopover} from './CellContainerPopover';
import {formatDateLong, formatTime} from '../../../../utils';

const dateColor = (purpose, dueDate) => {
  switch (purpose) {
    case 'dueDate': {
      const now = new Date();
      let daysToDueDate = Math.ceil((now.getTime() - dueDate.getTime()) / (1000 * 3600 * 24));
      if (daysToDueDate > -7 && daysToDueDate < 0) {
        return theme.colors.orange;
      }
      if (daysToDueDate > 0) {
        return theme.colors.red;
      }
      break;
    }

    default:
      break;
  }
  return theme.palette.text.primary;
};

export const DateCell = ({value, colDef, ...props}) => {
  const date = new Date(value);

  return (
          <CellContainerPopover value={isNaN(date) ? null : date} colDef={colDef} popover={DatePopover} {...props}>
            <div className={'DateCell'} style={{color: dateColor(colDef.purpose, date)}}>
              <span>{value ? `${formatDateLong(date)}${colDef.showTime ? ' ' + formatTime(date) : ''}` : ''}</span>
            </div>
          </CellContainerPopover>
  );
};

const minDate = new Date((new Date()).getFullYear() - 2, 0, 0, 0, 0, 0);
const DatePopover = ({id, anchorEl, onChange, onClose, value, ...props}) => {
  const [view, setView] = useState('day');

  const handleChange = useCallback((newDate) => {
    if (view === 'day') {
      onChange(newDate);
    } else {
      setView('day');
    }
  }, [view, onChange]);

  const handleViewChange = useCallback((newView) => {
    setView(newView);
  }, [setView]);

  return (
    <Popover
      id={id}
      anchorEl={anchorEl}
      onClose={onClose}
      className={'DatePopover'}
      {...props}
    >
      <CalendarPicker date={value} minDate={minDate} onChange={handleChange} onViewChange={handleViewChange} view={view}/>
    </Popover>
  );
};
