import {Dialog, DialogContent, makeStyles} from '@material-ui/core';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import React, {useState} from 'react';
import PaymentForm from './PaymentForm';
import PaymentSuccess from './PaymentSuccess';

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        padding: theme.spacing(4),
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
            minWidth: 600,
            minHeight: 512,
        },
    },
}));

const PayOnlineDialog = ({
    _id = null,
    quoteNumber = null,
    currencySymbol = '$',
    payTotal = null,
    isDialogOpen = false,
    onClose = () => null,
    stripeConnectedAccount,
    onSuccess = () => null
}) => {

    const classes = useStyles();
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY, {stripeAccount: stripeConnectedAccount});
    const [isPaymentSuccessfull, setIsPaymentSuccessfull] = useState();

    return (
        <Dialog
            open={isDialogOpen}
            onClose={onClose}
        >
            <DialogContent
                className={classes.dialogContent}
            >

                {isPaymentSuccessfull === _id
                    ?
                    <PaymentSuccess
                        _id={_id}
                    />
                    :
                    isDialogOpen &&
                    <Elements stripe={stripePromise} >
                        <PaymentForm
                            _id={_id}
                            quoteNumber={quoteNumber}
                            onSuccess={() => {setIsPaymentSuccessfull(_id);onSuccess(_id);}}
                            currencySymbol={currencySymbol}
                            payTotal={payTotal}
                        />
                    </Elements>
                }

            </DialogContent>
        </Dialog>
    );
};

export default PayOnlineDialog;
