// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React, {Component, Fragment} from 'react';
import {Dialog, Typography} from '@material-ui/core';
import {connect} from 'react-redux';
import DialogTitle from '../../DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '../../DialogActions';
import Grid from '@material-ui/core/Grid';
import FormBlock from './FormBlock';
import ProductsTable from './ProductsTable';
import DecorationsTable from './DecorationsTable';
import AdditionalItemsTable from './AdditionalItems';
import {getFormValues, reduxForm, reset, change as _change} from 'redux-form';
import Button from '@material-ui/core/Button';
import {get} from 'lodash';
import {closePurchaseOrderVariantsModal as _closePurchaseOrderVariantsModal} from '../../../../actions/modals';

class PurchaseOrderVariantsModal extends Component {
  handleClose = () => {
    const {closeDialog, resetForm} = this.props;
    resetForm('purchaseOrderVariantsForm');

    // This is a hack for a race condition
    setTimeout(() => {
      closeDialog();
    }, 500);

  };

  handleSubmit(values) {
    const {formContext: {form, name: fieldName}, changeParentForm} = this.props;

    changeParentForm(form, fieldName, values);
    this.handleClose();
  }

  render() {
    const {poFormValues, variantsFormValues, modalOpen, handleSubmit, formContext: {product}} = this.props;
    const locked = get(poFormValues, 'locked', false);
    const decorations = get(variantsFormValues, 'decorations', []);
    const additionalItems = get(variantsFormValues, 'additionalItems', []);
    const variants = get(variantsFormValues, 'variants', []);

    const anyQuantity = !!((variants[0] && variants[0].quantity > 0) || (decorations[0] && decorations[0].quantity > 0)  || (additionalItems[0] && additionalItems[0].quantity > 0));

    return (
      <Fragment>
        <Dialog open={modalOpen}
          fullWidth={true}
          maxWidth={'lg'}
        >
          <DialogTitle onClick={this.handleClose}>
            <Typography variant={'h4'}>Select Variants</Typography>
            {locked &&
              <Typography variant={'body2'} color={'textSecondary'}>
                Note: As this Purchase Order was created directly from a job, only some aspects can be edited.
              </Typography>
            }
          </DialogTitle>
          <form onSubmit={handleSubmit((values) => this.handleSubmit(values))}>
            <DialogContent>
              <Grid container component={'div'} spacing={2}>
                {!(locked && !variants.length) && <Grid item component={'div'} xs={12}>
                  <FormBlock title={'Product Quantities'}>
                    <ProductsTable product={product} />
                  </FormBlock>
                </Grid>}
                {!(locked && !decorations.length) && <Grid item component={'div'} xs={12}>
                  <FormBlock title={'Decorations'}>
                    <DecorationsTable product={product} />
                  </FormBlock>
                </Grid>}
                {!(locked && !additionalItems.length) && <Grid item component={'div'} xs={12}>
                  <FormBlock title={'Additional Items'}>
                    <AdditionalItemsTable />
                  </FormBlock>
                </Grid>}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid container justifyContent={'space-between'} component={'div'}>
                <Grid item component={'div'}>
                  <Button variant={'text'} color={'secondary'} onClick={this.handleClose}>Cancel</Button>
                </Grid>
                <Grid item component={'div'}>
                  <Button
                    variant={'contained'}
                    color={'primary'}
                    type={'submit'}
                    disabled={!product && !anyQuantity}
                  >Save</Button>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        </Dialog>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    initialValues: state.purchaseOrdersReducer.activePurchaseOrderVariants,
    modalOpen: state.modalReducer.purchaseOrderVariantsModal.isOpen,
    formContext: state.modalReducer.purchaseOrderVariantsModal.context,
    poFormValues: getFormValues('purchaseOrderForm')(state),
    variantsFormValues: getFormValues('purchaseOrderVariantsForm')(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeDialog: () => dispatch(_closePurchaseOrderVariantsModal()),
    resetForm: () => dispatch(reset('purchaseOrderVariantsForm')),
    changeParentForm: (form, field, value) => dispatch(_change(form, field, value))
  };
}

PurchaseOrderVariantsModal = reduxForm({
  form: 'purchaseOrderVariantsForm',
  enableReinitialize: true
})(PurchaseOrderVariantsModal);
PurchaseOrderVariantsModal = connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderVariantsModal);
export default PurchaseOrderVariantsModal;
