// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-shadow */

import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {getFiles} from '../../actions/files';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

export const FileSelect = ({label, placeholder, value = [], ChipProps = {color: 'primary', size: 'small'}, ...props}) => {
  const dispatch = useDispatch();
  const itemsSelector = (state) => state.filesReducer.list;
  const items = useSelector(itemsSelector);
  const itemIds = items.map((item) => item._id);
  const getItem = (itemId) => items.filter((item) => item._id === itemId)[0];

  const [filter, setFilter] = useState('');
  useEffect(
      () => {
          dispatch(getFiles({isWorkable: true, filter}));
      }, [dispatch, filter]
  );

  const _placeholder = value.length ? null : placeholder;

  const getOptionLabel = (itemId) => {
    const item = getItem(itemId);
    return item ? `File #${item.filename}` : '\'File not found';
  };

  const merged = itemIds
      .sort(
          (a, b) => getOptionLabel(b).localeCompare(getOptionLabel(a))
      );

  const getCustomLabel = (itemId) => {
    const item = getItem(itemId);
    return (<div>
      <div><Typography variant='subheading2'>{item.filename}</Typography></div>
      <div><Typography >{item.description}</Typography></div>
      </div>
    );
  };

  return (
      <Autocomplete
        multiple
        limitTags={2}
        options={merged}
        disableCloseOnSelect
        label={label}
        value={value}
        ChipProps={ChipProps}
        getOptionLabel={getOptionLabel}
        onChange={(event, value) => {
          props.onChange(value ? value : null);
        }}
        onInputChange={(event, value, reason) => {
            setFilter(value);
        }}

        renderOption={(itemId, {selected}) => (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{marginRight: 8}}
              checked={selected}
            />
            {getCustomLabel(itemId)}
          </React.Fragment>
        )}

        renderInput={(params) => (
          <TextField {...params} variant='standard' label={label} placeholder={_placeholder} InputLabelProps={{shrink: true,}} />
        )}
      />
    );
};

export default FileSelect;
