// TODO: fix eslint disable
/* eslint-disable no-duplicate-imports, no-class-assign */

import React, {Component, Fragment} from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import withStyles from '@material-ui/core/styles/withStyles';
import AdditionalItemsTableRow from './AdditionalItemsTableRow';
import {FieldArray} from 'redux-form';
import TableRow from '@material-ui/core/TableRow';
import {connect} from 'react-redux';
import {formValueSelector} from 'redux-form';

const styles = () => {
  const baseWidth = 140;
  return {
    largeColumn: {width: `${baseWidth * 2}px`},
    actionColumn: {width: `${baseWidth / 3}px`}
  };
};

class AdditionalItemsTable extends Component {
  render() {
    const {classes, additionalItems} = this.props;
    return (
      <Fragment>
        <Table className={classes.table}>
          {Boolean(additionalItems && additionalItems.length) && <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell className={classes.largeColumn}>Vendor</TableCell>
              <TableCell className={classes.actionColumn}/>
            </TableRow>
          </TableHead>}
          <TableBody>
            <FieldArray name={'additionalItems'} component={AdditionalItemsTableRow}/>
          </TableBody>
        </Table>
      </Fragment>
    );
  }
}

const selector = formValueSelector('jobVariantsForm');
function mapStateToProps(state) {
  return {additionalItems: selector(state, 'additionalItems')};
}

AdditionalItemsTable = connect(mapStateToProps)(AdditionalItemsTable);
AdditionalItemsTable = withStyles(styles)(AdditionalItemsTable);
export default AdditionalItemsTable;
