// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import React, {forwardRef, useCallback, useEffect, useMemo, useRef} from 'react';
import {useGridApiContext} from '@mui/x-data-grid-pro/hooks/utils/useGridApiContext';
import {GridAddIcon} from '@mui/x-data-grid/components/icons';
import {useGridRootProps} from '@mui/x-data-grid/hooks/utils/useGridRootProps';
import {useGridSelector} from '@mui/x-data-grid/hooks/utils/useGridSelector';
import {gridFilterModelSelector} from '@mui/x-data-grid/hooks/features/filter/gridFilterSelector';
import {gridFilterableColumnDefinitionsSelector} from '@mui/x-data-grid/hooks/features/columns/gridColumnsSelector';
import {GridLinkOperator} from '@mui/x-data-grid/models';
import {GridPanelContent} from '@mui/x-data-grid/components/panel/GridPanelContent';
import {GridPanelWrapper} from '@mui/x-data-grid/components/panel/GridPanelWrapper';
import {GridFilterForm, GridPanelFooter} from '@mui/x-data-grid';
import {ArchivedJobsFilter} from './ArchivedJobsFilter';
import {VoidedJobsFilter} from './VoidedJobsFilter';

export const CustomGridFilterPanel = forwardRef(
  function GridFilterPanel(props, ref) {
    const apiRef = useGridApiContext();
    const rootProps = useGridRootProps();
    const filterModel = useGridSelector(apiRef, gridFilterModelSelector);
    const filterableColumns = useGridSelector(apiRef, gridFilterableColumnDefinitionsSelector);
    const lastFilterRef = useRef(null);

    const {
      linkOperators = [GridLinkOperator.And, GridLinkOperator.Or],
      columnsSort,
      filterFormProps,
      children,
      ...other
    } = props;

    const getDefaultItem = useCallback(() => {
      const firstColumnWithOperator = filterableColumns.find(
        (colDef) => colDef.filterOperators?.length,
      );

      if (!firstColumnWithOperator) {
        return null;
      }

      return {
        columnField: firstColumnWithOperator.field,
        operatorValue: firstColumnWithOperator.filterOperators[0].value,
        id: Math.round(Math.random() * 1e5),
      };
    }, [filterableColumns]);

    const applyFilter = useCallback(
      (item) => {
        apiRef.current.upsertFilterItem(item);
      },
      [apiRef],
    );

    const applyFilterLinkOperator = useCallback(
      (operator) => {
        apiRef.current.setFilterLinkOperator(operator);
      },
      [apiRef],
    );

    const [standardItems] = useMemo(() => {
      const standard = [];
      const custom = [];
      filterModel.items.forEach((item) => {
        const col = filterableColumns.find((colDef) => colDef.field === item.columnField);
        if (col && !col.customFilter) {
          standard.push(item);
        } else {
          custom.push;
        }
      });

      if (standard.length === 0) {
        const defaultItem = getDefaultItem();
        if (defaultItem) {
          standard.push(defaultItem);
        }
      }
      return [standard, custom];
    }, [filterModel.items, filterableColumns, getDefaultItem]);

    const hasMultipleFilters = standardItems.length > 1;

    const addNewFilter = useCallback(() => {
      const defaultItem = getDefaultItem();
      if (defaultItem) {
        apiRef.current.upsertFilterItem(defaultItem);
      }
    }, [apiRef, getDefaultItem]);

    const deleteFilter = useCallback(
      (item) => {
        const col = filterableColumns.find((colDef) => colDef.field === item.columnField);
        const shouldCloseFilterPanel = standardItems.length === 1 && col && !col.customFilter;
        apiRef.current.deleteFilterItem(item);
        if (shouldCloseFilterPanel) {
          apiRef.current.hideFilterPanel();
        }
      },
      [apiRef, filterableColumns, standardItems.length],
    );

    useEffect(() => {
      if (linkOperators.length > 0 && filterModel.linkOperator && !linkOperators.includes(filterModel.linkOperator)) {
        applyFilterLinkOperator(linkOperators[0]);
      }
    }, [linkOperators, applyFilterLinkOperator, filterModel.linkOperator]);

    useEffect(() => {
      if (standardItems.length > 0) {
        lastFilterRef.current.focus();
      }
    }, [standardItems.length]);

    return (
      <GridPanelWrapper ref={ref} {...other}>
        <GridPanelContent>
          {standardItems.map((item, index) => (
            <GridFilterForm
              key={item.id == null ? index : item.id}
              item={item}
              applyFilterChanges={applyFilter}
              deleteFilter={deleteFilter}
              hasMultipleFilters={hasMultipleFilters}
              showMultiFilterOperators={index > 0}
              multiFilterOperator={filterModel.linkOperator}
              disableMultiFilterOperator={index !== 1}
              applyMultiFilterOperatorChanges={applyFilterLinkOperator}
              focusElementRef={index === standardItems.length - 1 ? lastFilterRef : null}
              linkOperators={linkOperators}
              columnsSort={columnsSort}
              {...filterFormProps}
            />
          ))}
        </GridPanelContent>
        {!rootProps.disableMultipleColumnsFiltering && (
          <>
            <GridPanelFooter>
              <rootProps.components.BaseButton
                onClick={addNewFilter}
                startIcon={<GridAddIcon/>}
                {...rootProps.componentsProps?.baseButton}
              >
                {apiRef.current.getLocaleText('filterPanelAddFilter')}
              </rootProps.components.BaseButton>
            </GridPanelFooter>
            <ArchivedJobsFilter deleteFilter={deleteFilter} applyFilterChanges={applyFilter}/>
            <VoidedJobsFilter deleteFilter={deleteFilter} applyFilterChanges={applyFilter}/>
          </>
        )}
      </GridPanelWrapper>
    );
  },
);
