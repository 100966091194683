// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import {CircularProgress, MenuItem} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {Field, formValueSelector, reduxForm} from 'redux-form';
import {REGISTER_FIELD_GROUP_REGIONS} from '../../../constants/index';
import theme from '../../../ui/theme';
import {renderSelectField, renderTextField} from '../../shared/formHelpers/fields';
import {email, passwordMatch, required} from '../../shared/formHelpers/validation';

class XeroSignUpForm extends React.Component {

  handleOrganisationChange(event) {
    const {change, organisations} = this.props;
    const {value} = event.target;

    const match = organisations.find((org) => org.id === value);

    if (match) {
      change('company.name', match.name);
      change('xero.xeroOrganisationId', match.name);
      change('xero.xeroCompanyName', match.name);
      change('xero.xeroCountryCode', match.region);
    }
  }

  render() {
    const {error, invalid, handleSubmit, isLoading, organisations} = this.props;

    return (
      <Fragment>
        <form autoComplete='off' onSubmit={handleSubmit}>

          <Grid container spacing={4}>
            {/* FORM HEADING */}
            <Grid item xs={12}>
              <Typography color={'primary'} variant={'h4'}>Xero Sign Up</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant={'body'}>Xero Sign Up is almost complete. Please confirm your organisation details and make sure you set your password.</Typography>
            </Grid>

            {/*  FORM*/}
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormGroup row>
                    <Field
                      name='company.xeroOrganisationId'
                      component={renderSelectField}
                      label='Xero Organisation'
                      validate={[required]}
                      onChange={(ev) => this.handleOrganisationChange(ev)}
                    >
                      {organisations && organisations.map((organisation) =>
                        <MenuItem key={organisation.id} value={organisation.id}>{organisation.name}</MenuItem>
                      )}
                    </Field>
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup row>
                    <Field
                      name='company.name'
                      component={renderTextField}
                      label='Company Name'
                      validate={[required]}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup row>
                    <Field
                      name='company.phone'
                      component={renderTextField}
                      label='Company Phone'
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup row>
                    <Field
                      name='company.region'
                      component={renderSelectField}
                      label='Company Region'
                      validate={[required]}
                    >
                      {REGISTER_FIELD_GROUP_REGIONS.regions.map((region) => <MenuItem key={region.id} value={region.name}>{region.name}</MenuItem>)}
                    </Field>
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup row>
                    <Field
                      name='firstName'
                      component={renderTextField}
                      label='First Name'
                      validate={[required]}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup row>
                    <Field
                      name='lastName'
                      component={renderTextField}
                      label='Last Name'
                      validate={[required]}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup row>
                    <Field
                      name='username'
                      component={renderTextField}
                      type='email'
                      label='Your Email'
                      validate={[required, email]}

                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup row>
                    <Field
                      name='password'
                      component={renderTextField}
                      type='password'
                      label='Password'
                      validate={[required]}

                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup row>
                    <Field
                      name='confirmPassword'
                      component={renderTextField}
                      type='password'
                      label='Confirm Password'
                      validate={[required, passwordMatch]}

                    />
                  </FormGroup>
                </Grid>
              </Grid>

              <Grid item xs={12}>

                <Grid container alignItems={'center'} justifyContent={'space-between'} style={{paddingTop: '16px'}}>
                  <Grid item>
                    {error && <strong>{error}</strong>}
                    <Button
                      variant='contained'
                      disabled={isLoading || invalid}
                      size='medium'
                      color='primary'
                      type='submit'
                    >
                      {isLoading ? <><CircularProgress size={16} style={{marginRight: theme.spacing(1)}}/>Sign Up with Xero</> : 'Sign Up with Xero'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/*  BUTTON */}
            {/* {formGroup.name !== "login" &&
              <Grid item xs={12}>
                {error && <strong>{error}</strong>}
                <Button
                  variant="contained"
                  disabled={isLoading}
                  size="medium"
                  color="primary"
                  type={formGroup["name"]}
                >
                  {isLoading ? <><CircularProgress size={16} style={{marginRight:theme.spacing(1)}}/>{formGroup["buttonLabel"]}</> : formGroup["buttonLabel"]}
                </Button>
              </Grid>} */}

            {/*  LINKS*/}

          </Grid>
        </form>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const selector = formValueSelector('register');
  return {
    initialValues: state.authReducer.newUser,
    user: state.user,
    organisations: selector(state, 'organisations')
  };
}

XeroSignUpForm = reduxForm({
  form: 'register',
  enableReinitialize: true
})(XeroSignUpForm);
XeroSignUpForm = connect(mapStateToProps)(XeroSignUpForm);

export default XeroSignUpForm;
