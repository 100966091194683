// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {makeStyles, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@material-ui/core';
import React, {cloneElement, useState, useRef} from 'react';
import theme from '../../ui/theme';

const useStyles = makeStyles((theme) => ({
    dialogTitle: {padding: theme.spacing(3),},
    dialogContent: {
        padding: theme.spacing(3),
        overflowY: 'hidden'
    },
    dialogActions: {
        padding: theme.spacing(3),
        justifyContent: 'space-between'
    },
}));

const SendDialog = ({
    open = false,
    closeDialog = () => null,
    title = '',
    formComponent = () => null
}) => {
    const classes = useStyles();
    const sendFunction = useRef(null);

    const [isValid, setIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    return (
        <Dialog open={open} maxWidth={'lg'} onClose={closeDialog}>
            <DialogTitle className={classes.dialogTitle}>
                <Typography variant='h4' >{title}</Typography>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>

                {cloneElement(formComponent, {setIsValid: setIsValid, setIsLoading: setIsLoading, sendFunction: sendFunction})}

            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button color={'secondary'} onClick={closeDialog} >Cancel</Button>
                <Button
                    variant='contained'
                    disabled={isLoading || !isValid}
                    color='primary'
                    onClick={() => sendFunction.current()}
                >
                    {isLoading ? <><CircularProgress size={16} style={{marginRight: theme.spacing(1)}}/>Send</> : 'Send'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SendDialog;
