import ScheduleService from '../servicesOld/ScheduleService';
import {newSchedule} from '../reducers/schedule';
export const SET_SCHEDULE = 'SET_SCHEDULE';
export const SET_SCHEDULE_LOADING = 'SET_SCHEDULE_LOADING';
export const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE';
export const CREATE_SCHEDULE = 'CREATE_SCHEDULE';
export const REMOVE_SCHEDULE = 'REMOVE_SCHEDULE';
export const UPDATE_SCHEDULES = 'UPDATE_SCHEDULES';
export const MODAL_SCHEDULE = 'MODAL_SCHEDULE';

export function editNewSchedule(state = {}) {
  return function(dispatch) {
    const payload = {...newSchedule, ...state};
    dispatch({type: SET_SCHEDULE, payload}); // new empty schedule and merge any data
    dispatch(setModal('edit')); // open modal
    return payload;
  };
}

export function setModal(viewType = null) {
  return function(dispatch) {
    dispatch({type: MODAL_SCHEDULE, payload: viewType});
    return viewType;
  };
}

export function getSchedules(variables, cb) {
  return function(dispatch) {
    return ScheduleService.getSchedules(variables).then((payload) => {
      if (cb) {
        cb(payload);
      }
      dispatch({type: UPDATE_SCHEDULES, payload: payload});
      return payload;
    });
  };
}

export function createSchedule(data, cb) {
  return function(dispatch) {
    return ScheduleService.createSchedule(data).then((file) => {
      if (cb) {
        cb(file);
      }
      dispatch({type: CREATE_SCHEDULE, payload: file});
      return file;
    });
  };
}

export function getSchedule(id, cb) {
  return function(dispatch) {
    dispatch({type: SET_SCHEDULE_LOADING, payload: true});
    return ScheduleService.getSchedule(id).then((payload) => {
      dispatch({
        type: SET_SCHEDULE,
        payload,
      });
      dispatch({type: SET_SCHEDULE_LOADING, payload: false});
      if (cb) {
        cb(payload);
      }

      return payload;
    });
  };
}

export function updateSchedule(id, data, cb) {
  return function(dispatch) {
    return ScheduleService.updateSchedule(id, data).then((payload) => {
      dispatch({type: UPDATE_SCHEDULE, payload});
      if (cb) {
        cb(payload);
      }
      return payload;
    });
  };
}

export function deleteSchedule(id) {
  return function(dispatch) {
    return ScheduleService.deleteSchedule(id).then((d) => {
      dispatch({type: REMOVE_SCHEDULE, payload: id});
      return d;
    });
  };
}
