import {gql} from 'apollo-boost';

export const GQL_EMAIL_TEMPLATE_CREATE = gql`
    mutation EmailTemplateCreateOne($record: CreateOneEmailTemplateInput!){
        emailTemplateCreateOne(record: $record) {
            recordId
            record {
                _id
                via
                title
                subject
                body
                entity
            }
        }
    }
`;

export const GQL_EMAIL_TEMPLATE_UPDATE = gql`
    mutation EmailTemplateUpdateById($_id: MongoID!, $record: UpdateByIdEmailTemplateInput!){
        emailTemplateUpdateById(_id: $_id, record: $record) {
            recordId
            record {
                _id
                via
                title
                subject
                body
                entity
            }
        }
    }
`;

export const GQL_EMAIL_TEMPLATE_DELETE = gql`
    mutation EmailTemplateRemoveById($_id: MongoID!){
        emailTemplateRemoveById(_id: $_id) {
            recordId
        }
    }
`;

export const GQL_EMAIL_TEMPLATE_MAKE_DEFAULT = gql`
    mutation EmailTempateMakeDefault($emailTemplateId: MongoID!){
        emailTempateMakeDefault(emailTemplateId: $emailTemplateId) {
            
                _id
            
        }
    }
`;

export const GQL_EMAIL_TEMPLATE_GET_BY_ID = gql`
    query EmailTemplateById(
        $_id: MongoID!
    ){
        emailTemplateById(
            _id: $_id
        ) {
            title
            subject
            entity 
            isDefault
            body
            via
            _id
        }
    }
`;

export const GQL_EMAIL_TEMPLATE_GET_MANY = gql`
    query EmailTemplateMany(
        $filter: FilterFindManySearchGCemailTemplateInput,
        $sort: SortFindManyEmailTemplateInput,
        $limit: Int,
        $skip: Int
    ){
        emailTemplateMany(
            filter: $filter
            sort: $sort
            limit: $limit
            skip: $skip
        ) {
            title
            subject
            entity 
            isDefault
            body
            via
            _id
        }
    }
`;

export const GQL_EMAIL_TEMPLATE_PAGINATION = gql`
    query EmailTemplatePagination(
        $filter: FilterFindManySearchGCemailTemplateInput,
        $sort: SortFindManyEmailTemplateInput,
        $perPage: Int,
        $page: Int
    ){
        emailTemplatePagination(
            filter: $filter,
            sort: $sort,
            perPage: $perPage,
            page: $page
        ) {
            items {
                title
                subject
                entity 
                isDefault
                via
                _id
            }
            pageInfo {
                currentPage
                perPage
                pageCount
                itemCount
                hasNextPage
                hasPreviousPage
            }
        }
    }
`;
