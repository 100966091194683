import React, {useCallback, useEffect, useState} from 'react';
import {Button, ButtonBase, Grid, MenuItem, Select, Typography} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import {IntegrationTile} from '../IntegrationTile';
import QUICKBOOKS_DEFAULT_IMAGE from '../../../assets/images/integrations/quickbooks/connect-default.png';
import QUICKBOOKS_HOVER_IMAGE from '../../../assets/images/integrations/quickbooks/connect-hover.png';
import QUICKBOOKS_LOGO from '../../../assets/images/integrations/quickbooks/quickbooks_logo.svg';
import {hoopsTheme} from '../../../theme';
import {connectToQuickBooks} from '../../../actions/integrations';
import {client} from '../../../servicesOld/GraphQLService';
import {GQL_DISCONNECT_QUICKBOOKS} from '../../../queries/companies';
import {getCurrentUser} from '../../../actions/action-types';
import {StandardDialog} from '../../../componentsLib/Dialogs';
import {useGetIntegration, useUpdateIntegration} from '../../../hooks/api';

const styles = {
  '.disconnect-button': {
    color: hoopsTheme.colors.palette.red,
    borderColor: hoopsTheme.colors.palette.red,
    padding: '6px 0 4px',
  },
};

export const QuickbooksIntegrationTile = ({config}) => {
  const user = useSelector((state) => state.authReducer.userData);
  const dispatch = useDispatch();
  const [imageUrl, setImageUrl] = useState(QUICKBOOKS_DEFAULT_IMAGE);
  const [settingsIsOpen, setSettingsIsOpen] = useState(false);
  const [accountingPlatform, setAccountingPlatform] = useState();
  const [tokenExpiry, setTokenExpiry] = useState();

  useEffect(() => {
    setAccountingPlatform(user?.company?.accountingPlatform);
    if (accountingPlatform && accountingPlatform.refreshTokenExpiry) {
      setTokenExpiry(moment(accountingPlatform.refreshTokenExpiry, 'x'));
    }
  }, [user, accountingPlatform]);

  const handleMouseEnterConnectButton = useCallback(() => {
    setImageUrl(QUICKBOOKS_HOVER_IMAGE);
  }, []);

  const handleMouseLeaveConnectButton = useCallback(() => {
    setImageUrl(QUICKBOOKS_DEFAULT_IMAGE);
  }, []);

  const handleConnect = useCallback(() => {
    connectToQuickBooks(user.companyId);
  }, [user]);

  const handleDisconnect = useCallback(async () => {
    await client.mutate({mutation: GQL_DISCONNECT_QUICKBOOKS});
    dispatch(getCurrentUser());
  }, [dispatch]);

  const handleSettingsClick = useCallback(() => {
    setSettingsIsOpen(true);
  }, [setSettingsIsOpen]);

  const handleSettingsClose = useCallback(() => {
    setSettingsIsOpen(false);
  }, [setSettingsIsOpen]);

  const QuickbooksConnected = () => (
    <Grid sx={styles} container justifyContent={'center'} direction={'column'} spacing={1}>
      <Grid item>
        <Typography align={'center'}>
          Connected to <br/> <strong>{accountingPlatform.companyName}</strong>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button variant={'outlined'} fullWidth onClick={handleSettingsClick}>Settings</Button>
      </Grid>
      {tokenExpiry && tokenExpiry.isBefore() &&
        <Grid item xs>
          <Button variant={'outlined'} fullWidth onClick={handleConnect}>Reconnect</Button>
        </Grid>
      }
      <Grid item xs>
        <Button className='disconnect-button' variant={'outlined'} fullWidth onClick={handleDisconnect}>Disconnect Quickbooks</Button>
      </Grid>
      <Grid item>
        {!!tokenExpiry && <Typography variant={'body2'} align={'center'}>
          Reconnect will be required on <br/>
          <i>{tokenExpiry.format('ll')}</i>
        </Typography>}
      </Grid>
    </Grid>
  );

  const AccountingConnected = () => (
    <Grid container alignItems={'center'} justifyContent={'center'}>
      <Grid item xs>
        <Typography align={'center'} variant={'body2'}>
          Only one accounting integration can be connected. Disconnect Xero to connect to Quickbooks.
        </Typography>
      </Grid>
    </Grid>
  );

  const NothingConnected = () => (
    <ButtonBase onMouseEnter={handleMouseEnterConnectButton} onMouseLeave={handleMouseLeaveConnectButton} onClick={handleConnect}>
      <img src={imageUrl} alt={'Connect to Quickbooks'} onClick={handleConnect} height={45} />
    </ButtonBase>
  );

  return (
    <IntegrationTile
      name={name}
      logo={QUICKBOOKS_LOGO}
      disabled={false}
      ContentComponent={() => (
        <>
          {accountingPlatform && accountingPlatform.platform === 'QUICKBOOKS' && <QuickbooksConnected/>}
          {accountingPlatform && accountingPlatform.platform !== 'QUICKBOOKS' && <AccountingConnected/>}
          {!accountingPlatform && <NothingConnected/>}
          {settingsIsOpen &&
            <SettingsModal isOpen={settingsIsOpen} onClose={handleSettingsClose}/>
          }
        </>
      )}
      config={config}>
    </IntegrationTile>
  );
};

function SettingsModal({isOpen, onClose}) {
  const {data: {integration: settings}, isLoading} = useGetIntegration('quickbooks');
  const {mutateAsync: updateSettings} = useUpdateIntegration({id: 'quickbooks'});
  const [defaultRevenueAccount, setDefaultRevenueAccount] = useState('');
  const [defaultExpenseAccount, setDefaultExpenseAccount] = useState('');
  const [defaultRevenueTax, setDefaultRevenueTax] = useState('');
  const [defaultTaxFreeRevenueTax, setDefaultTaxFreeRevenueTax] = useState('');
  const [defaultExpenseTax, setDefaultExpenseTax] = useState('');
  const [defaultInvoiceItem, setDefaultInvoiceItem] = useState('');
  const [defaultPurchaseOrderItem, setDefaultPurchaseOrderItem] = useState('');

  useEffect(() => {
    setDefaultRevenueAccount(settings?.defaultRevenueAccount ?? '');
    setDefaultExpenseAccount(settings?.defaultExpenseAccount ?? '');
    setDefaultRevenueTax(settings?.defaultRevenueTax ?? '');
    setDefaultTaxFreeRevenueTax(settings?.defaultTaxFreeRevenueTax ?? '');
    setDefaultExpenseTax(settings?.defaultExpenseTax ?? '');
    setDefaultInvoiceItem(settings?.defaultInvoiceItem ?? '');
    setDefaultPurchaseOrderItem(settings?.defaultPurchaseOrderItem ?? '');
  }, [setDefaultExpenseAccount, setDefaultRevenueTax, setDefaultExpenseTax, setDefaultPurchaseOrderItem, settings]);

  const onSave = useCallback(() => {
    onClose();
    updateSettings({
      data: {
        defaultRevenueAccount,
        defaultExpenseAccount,
        defaultRevenueTax,
        defaultTaxFreeRevenueTax,
        defaultExpenseTax,
        defaultInvoiceItem,
        defaultPurchaseOrderItem
      }
    });
  }, [
    defaultExpenseAccount,
    defaultExpenseTax,
    defaultInvoiceItem,
    defaultPurchaseOrderItem,
    defaultRevenueAccount,
    defaultRevenueTax,
    defaultTaxFreeRevenueTax,
    onClose,
    updateSettings
  ]);

  return (
    <StandardDialog open={isOpen} onClose={onClose} onSave={onSave} loading={isLoading} title={'QuickBooks Settings'}>
      <HoopsSelect label={'Default Revenue Account'} value={defaultRevenueAccount} onChange={(e) => setDefaultRevenueAccount(e.target.value)}>
        {settings?.revenueAccounts?.map((ex) => (<MenuItem key={ex.id} value={ex.id}>{ex.name}</MenuItem>))}
      </HoopsSelect>
      <HoopsSelect label={'Default Expense Account'} value={defaultExpenseAccount} onChange={(e) => setDefaultExpenseAccount(e.target.value)}>
        {settings?.expenseAccounts?.map((ex) => (<MenuItem key={ex.id} value={ex.id}>{ex.name}</MenuItem>))}
      </HoopsSelect>
      <HoopsSelect label={'Default QuickBooks Revenue Tax'} value={defaultRevenueTax} onChange={(e) => setDefaultRevenueTax(e.target.value)}>
        {settings?.revenueTaxes?.map((ex) => (<MenuItem key={ex.id} value={ex.id}>{ex.name}</MenuItem>))}
      </HoopsSelect>
      <HoopsSelect label={'Default QuickBooks Tax Free Revenue Tax'} value={defaultTaxFreeRevenueTax} onChange={(e) => setDefaultTaxFreeRevenueTax(e.target.value)}>
        {settings?.taxFreeRevenueTaxes?.map((ex) => (<MenuItem key={ex.id} value={ex.id}>{ex.name}</MenuItem>))}
      </HoopsSelect>
      <HoopsSelect label={'Default QuickBooks Expense Tax'} value={defaultExpenseTax} onChange={(e) => setDefaultExpenseTax(e.target.value)}>
        {settings?.expenseTaxes?.map((ex) => (<MenuItem key={ex.id} value={ex.id}>{ex.name}</MenuItem>))}
      </HoopsSelect>
      <HoopsSelect label={'Default Inventory Item for Invoices'} value={defaultInvoiceItem} onChange={(e) => setDefaultInvoiceItem(e.target.value)}>
        {settings?.inventoryItems?.map((ex) => (<MenuItem key={ex.id} value={ex.id}>{ex.name}</MenuItem>))}
      </HoopsSelect>
      <HoopsSelect label={'Default Inventory Item for Purchase Orders'} value={defaultPurchaseOrderItem} onChange={(e) => setDefaultPurchaseOrderItem(e.target.value)}>
        {settings?.inventoryItems?.map((ex) => (<MenuItem key={ex.id} value={ex.id}>{ex.name}</MenuItem>))}
      </HoopsSelect>
    </StandardDialog>
  );
}

const menuStyles = {maxHeight: 500};

const HoopsSelect = ({
                              label = null,
                              children = null,
                              variant = 'outlined',
                              sx = {},
                              MenuProps,
                              ...props
                            }) => (
  <Grid item sx={sx}>
    {label && <Typography variant='caption'>{label}</Typography>}
    <Select
      MenuProps={{...MenuProps, sx: {...MenuProps?.sx, ...menuStyles}}}
      variant={variant}
      fullWidth
      {...props}
    >
      {children}
    </Select>
  </Grid>
);
