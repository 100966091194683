// TODO: fix eslint disable
/* eslint-disable no-class-assign, no-shadow */

import {CardNumberElement, injectStripe, CardCVCElement, CardExpiryElement} from 'react-stripe-elements';
import React from 'react';
import {Button} from '@material-ui/core';
import {Field, reduxForm} from 'redux-form';
import {renderTextField} from '../common/RenderTextField';
import StripeElementWrapper from './StripeInputWrapper';
import Grid from '@material-ui/core/Grid';
import {connect} from 'react-redux';
import {addPaymentMethod, getPaymentMethods} from '../../actions/billing';
import {snackError} from '../../actions/action-types';

class PaymentMethodForm extends React.Component {

  handleSubmit = async (values) => {
    const {
      stripe,
      addPaymentMethod,
      onSave,
      snackError
    } = this.props;
    try {
      const {token, error} = await stripe.createToken({
        type: 'card',
        name: values.name
      });

      if (error) {throw new Error(error.message);}
      await addPaymentMethod(token.id);
      onSave();
    } catch (err) {
      console.error(err);
      snackError(err.message);
    }
  };

  render() {
    const {handleSubmit} = this.props;
    return (
      <form onSubmit={handleSubmit(this.handleSubmit)}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Field component={renderTextField}
                   name={'name'}
                   label={'Cardholder Name'}/>
          </Grid>
          <Grid item xs={12}>
            <StripeElementWrapper label='Card Number' component={CardNumberElement}/>
          </Grid>
          <Grid item xs={7}>
            <StripeElementWrapper label='Card Expiry' component={CardExpiryElement}/>
          </Grid>
          <Grid item xs={5}>
            <StripeElementWrapper label='CVC' component={CardCVCElement}/>
          </Grid>
          <Grid container item justifyContent={'flex-end'}>
            <Button color={'primary'}
                    type={'submit'}>Add Card</Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addPaymentMethod: (token) => {
      dispatch(addPaymentMethod(token));
    },
    getPaymentMethods: (options) => {
      dispatch(getPaymentMethods(options));
    },
    snackError: (message) => {
      dispatch(snackError(message));
    }
  };
}

PaymentMethodForm = reduxForm({form: 'paymentMethod'})(PaymentMethodForm);
PaymentMethodForm = connect(null, mapDispatchToProps)(PaymentMethodForm);
PaymentMethodForm = injectStripe(PaymentMethodForm);
export default PaymentMethodForm;
