import {Fade} from '@material-ui/core';
import React from 'react';

export const Logo = ({
  fill = [],
  collapsed = false
}) => (
    <div style={{width: 100}}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 -152 819.125 432'>
        <title>logo</title>
        <g>

          <Fade in={!collapsed}>
            {/* p */}
            <path fill={fill[0]} d='M659.075,42.536c-18.941-19.287-42.044-29.067-68.668-29.067c-21.565,0-41.002,6.528-57.9,19.424c-0.071-5.3-1.983-9.863-5.687-13.57c-3.889-3.884-8.554-5.855-13.87-5.855c-5.43,0-10.153,1.97-14.038,5.857c-3.775,3.775-5.689,8.44-5.689,13.87V255.48c0,5.43,1.914,10.095,5.738,13.921c3.877,3.742,8.585,5.638,13.987,5.638c5.29,0,9.937-1.896,13.87-5.689c3.775-3.775,5.689-8.442,5.689-13.87v-64.046c16.895,12.894,36.332,19.422,57.897,19.422c26.621,0,49.721-9.721,68.668-28.899c18.809-19.158,28.345-42.583,28.345-69.626C687.42,85.175,677.882,61.694,659.075,42.536z M648.134,112.33c0,16.455-5.521,30.18-16.852,41.938c-11.429,11.643-24.801,17.304-40.877,17.304s-29.448-5.661-40.865-17.292c-11.46-11.778-17.032-25.5-17.032-41.95c0-16.562,5.572-30.338,17.02-42.105c11.432-11.643,24.804-17.304,40.877-17.304c16.074,0,29.445,5.661,40.852,17.279C642.615,81.98,648.134,95.761,648.134,112.33z' />
          </Fade>

          <Fade in={!collapsed}>
            {/* s */}
            <path fill={fill[0]} d='M793.277,108.469c-7.731-5.588-19.732-10.618-36.701-15.388c-15.314-4.261-21.379-7.128-23.766-8.796c-3.099-2.372-4.606-6.114-4.606-11.442c0-5.466,2.143-9.993,6.554-13.842c4.678-4.093,10.151-6.083,16.732-6.083c4.934,0,9.284,1.144,13.242,3.457c3.935,2.402,6.676,5.405,8.404,9.243c2.357,4.99,6.099,8.44,11.124,10.252c4.978,1.797,10.097,1.571,14.861-0.608c5.028-2.232,8.506-5.931,10.316-10.939c1.952-5.161,1.741-10.26-0.623-15.161c-4.901-10.733-12.637-19.437-23.009-25.874c-10.441-6.406-21.984-9.652-34.316-9.652c-17.2,0-32.069,5.786-44.195,17.2c-12.304,11.587-18.542,25.721-18.542,42.011c0,18.509,6.996,32.923,20.751,42.815c7.746,5.722,19.709,10.763,36.596,15.418c15.349,4.139,21.344,7.128,23.711,8.936c3.343,2.486,4.967,6.345,4.967,11.798c0,5.45-2.186,9.968-6.752,13.867c-4.563,4.075-9.97,6.058-16.534,6.058c-5.537,0-11.168-1.968-16.737-5.847l-0.081-0.056c-5.099-3.363-9.228-7.54-12.245-12.367c-2.799-4.614-6.902-7.588-12.202-8.844c-5.247-1.241-10.311-0.453-14.874,2.311l-0.12,0.076c-4.436,2.908-7.316,7.047-8.554,12.271c-1.258,5.176-0.483,10.13,2.326,14.76c6.091,9.843,14.226,18.217,24.181,24.893c12.027,8.02,24.913,12.085,38.305,12.085c17.081,0,31.944-5.783,44.21-17.213c12.185-11.585,18.362-25.714,18.362-41.999C814.059,133.214,807.076,118.637,793.277,108.469z' />
          </Fade>

          <Fade in={true}>
            <path fill={fill[0]} d='M487.199,112.498c0,27.043-9.591,50.461-28.535,69.644c-18.944,19.155-42.042,28.881-68.658,28.881c-16.038,0-30.8-3.518-44.096-10.471c6.343-4.103,12.337-9.032,17.983-14.732c4.85-4.904,9.114-10.097,12.815-15.532c4.21,0.958,8.633,1.439,13.295,1.439c16.066,0,29.44-5.649,40.87-17.292c11.457-11.775,17.025-25.498,17.025-41.938c0-16.572-5.57-30.345-17.025-42.121c-11.429-11.618-24.804-17.292-40.87-17.292c-4.662,0-9.086,0.506-13.321,1.464c-1.813,0.402-3.597,0.908-5.328,1.492c-8.181,2.796-15.532,7.54-22.221,14.36c-3.277,3.358-6.048,6.899-8.394,10.603c-1.065,1.759-2.052,3.516-2.931,5.356c-3.811,7.886-5.702,16.544-5.702,26.136c0,9.538,1.891,18.146,5.702,26.003c-2.397,4.342-5.435,8.42-9.192,12.281c-6.421,6.528-13.534,11.002-21.473,13.456c-9.513-15.293-14.307-32.61-14.307-51.743c0-19.208,4.797-36.607,14.307-51.898c0.958-1.546,1.973-3.066,3.01-4.555c3.277-4.662,7.009-9.114,11.19-13.349c6.02-6.18,12.495-11.376,19.368-15.586c1.706-1.037,3.437-2.023,5.194-2.931c13.295-7.006,28.03-10.55,44.096-10.55c26.616,0,49.742,9.78,68.658,29.067C477.608,61.851,487.199,85.351,487.199,112.498z' />
          </Fade>

          <Fade in={!collapsed}>
            {/* h */}
            <path fill={fill[1]} d='M160.589,42.3c-17.726-19.353-39.446-29.168-64.562-29.168c-19.063,0-36.408,5.895-51.679,17.543v-61.997c0-5.43-1.914-10.095-5.689-13.872c-3.889-3.884-8.554-5.855-13.87-5.855c-5.43,0-10.153,1.97-14.038,5.857c-3.775,3.775-5.689,8.442-5.689,13.87v222.284c0,5.43,1.914,10.095,5.738,13.921c3.877,3.742,8.585,5.638,13.987,5.638c5.29,0,9.937-1.896,13.87-5.689c3.775-3.775,5.689-8.44,5.689-13.87v-78.966c0-16.412,5.155-30.635,15.314-42.26c10.176-11.539,22.071-17.149,36.365-17.149c14.292,0,26.189,5.61,36.368,17.152c10.273,11.635,15.482,25.854,15.482,42.258v79.472c0,5.293,1.896,9.942,5.689,13.87c3.775,3.775,8.442,5.689,13.87,5.689s10.095-1.914,13.87-5.689c3.887-3.887,5.857-8.552,5.857-13.87v-79.472C187.159,84.888,178.216,61.437,160.589,42.3z' />
          </Fade>
          <Fade in={true}>
            <path fill={fill[1]} d='M388.7,112.498c0,19.13-4.822,36.447-14.333,51.743c-0.958,1.543-1.945,3.038-3.01,4.555c-3.277,4.634-7.009,9.083-11.19,13.346c-6.076,6.129-12.548,11.297-19.45,15.479c-1.706,1.04-3.437,2.026-5.196,2.931c-13.321,6.953-28.03,10.471-44.014,10.471c-26.748,0-49.849-9.726-68.658-28.907c-18.916-19.13-28.535-42.576-28.535-69.619c0-27.147,9.619-50.647,28.535-69.804c18.809-19.262,41.91-29.067,68.658-29.067c15.985,0,30.719,3.544,44.04,10.55c-6.34,4.131-12.334,9.086-17.958,14.866c-4.848,4.904-9.086,10.072-12.761,15.504c-4.235-0.958-8.659-1.464-13.321-1.464c-16.066,0-29.44,5.674-40.87,17.317c-11.457,11.75-17.025,25.523-17.025,42.095c0,16.44,5.567,30.16,17.025,41.938c11.429,11.643,24.804,17.292,40.87,17.292c4.662,0,9.086-0.48,13.295-1.439c1.838-0.402,3.597-0.908,5.356-1.492c8.181-2.799,15.532-7.54,22.221-14.36c3.277-3.356,6.048-6.899,8.392-10.603c1.065-1.731,2.052-3.516,2.956-5.328c3.783-7.858,5.674-16.465,5.674-26.003c0-9.591-1.891-18.25-5.702-26.136c2.397-4.37,5.435-8.445,9.192-12.309c6.421-6.554,13.534-11.002,21.473-13.453C383.877,75.891,388.7,93.29,388.7,112.498z' />
          </Fade>
        </g>
      </svg>
    </div>
  );

export default Logo;
