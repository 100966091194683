import React, {Component} from 'react';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import numeral from 'numeral';
import {withStyles} from '@material-ui/core';
import {toUpper} from 'lodash';
import {connect} from 'react-redux';
import classNames from 'classnames';

const styles = (theme) => ({
  borderTopNoBottom: {
    borderTop: `1px solid ${theme.colors.grey.light}`,
    borderBottom: '0px',
    padding: '8px',
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(5)
  },
  borderTopOnly: {
    borderTop: `1px solid ${theme.colors.grey.light}`,
    padding: '8px',
    paddingRight: theme.spacing(2),
  },
  noBorder: {
    border: '0px',
    padding: '8px',
    paddingRight: theme.spacing(2),
  },
  tableBottom: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(5)
  },

});

class _QuoteTotals extends Component {
  render() {
    const {classes, columnClasses} = this.props;
    const {total, taxes, subTotal, company} = this.props;

    return (
      <TableBody>
        <TableRow>
          <TableCell className={classes.borderTopNoBottom} colSpan={6} />
          <TableCell className={classes.borderTopNoBottom} style={{paddingTop: ''}} align='right'>
            <Typography variant='body2'>SUBTOTAL</Typography>
          </TableCell>
          <TableCell className={classNames(classes.borderTopNoBottom, columnClasses.tdTotal)} align='right'>
            <Typography style={{fontSize: '12px'}}>{company.currencySymbol} {numeral(subTotal).format('0,0.00')}
            </Typography>
          </TableCell>
        </TableRow>
        {taxes && taxes.map((taxItem) => (
          <TableRow key={taxItem.tax.name}>
            <TableCell colSpan={6} className={classes.noBorder}/>
            <TableCell className={classes.noBorder} align='right'>
        <Typography variant='body2'>{toUpper(taxItem.tax.name)} {taxItem.tax.rate}%</Typography>
            </TableCell>
            <TableCell className={`${classes.noBorder} ${columnClasses.tdTotal}`} align='right'>
              <Typography
                style={{fontSize: '12px'}}>{company.currencySymbol} {numeral(taxItem.total).format('0,0.00')}</Typography>
            </TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell colSpan={6} className={`${classes.noBorder} ${classes.tableBottom}`}/>
          <TableCell className={`${classes.noBorder} ${classes.tableBottom}`} align='right'>
          <Typography variant='body2'
              style={{fontWeight: '500'}}>TOTAL</Typography>
          </TableCell>
          <TableCell className={`${classes.noBorder} ${columnClasses.tdTotal}  ${classes.tableBottom}`} align='right'>
            <Typography
              style={{fontWeight: '500'}}>{company.currencySymbol} {numeral(total).format('0,0.00')}
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }
}

function mapStateToProps(state) {
  return {company: state.quoteReducer.quote.company,};
}

export const QuoteTotals = withStyles(styles)(connect(mapStateToProps)(_QuoteTotals));
