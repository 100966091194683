import {InputAdornment} from '@material-ui/core';
import {maxBy} from 'lodash';
import React, {useCallback, useContext, useState} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {HoopsTextField} from '../../../componentsOld/HoopsTextField';
import {decimalPlaces, toFloat} from '../../../componentsOld/shared/numberUtils';
import {DefaultMarkup} from '../../../pages/markups/defaultMarkupClass';
import MarkupService from '../../../pages/markups/markupsService';
import {markupType} from '../../../pages/markups/markupTypeValues';
import {ItemBuilderContext} from '../ItemBuilderContext';
import {calculatePriceElementBreakdown} from '../itemBuilderUtils';
import priceLogicValues from '../itemLogicValues';
import {useWatchEffect} from '../../../hooks';
import {asCurrencyString, asFloat} from '../../../utils';

const MarkupField = ({
  field = {},
  name = '',
  index = null,
  defaultValue = null,
  ...params
}) => {
  const {watch, control, setValue} = useFormContext();
  const {assignedDefaultMarkupData, onPriceChanged} = useContext(ItemBuilderContext);

  const [, setDefaultMarkupValue] = useState(false);
  const [isCustomValue, setIsCustomValue] = useState(false);

  const {grossMargin} = priceLogicValues;
  const priceLogicSelected = watch('priceLogic');
  const isGrossMargin = priceLogicSelected === grossMargin;

  const priceElement = watch() || [];
  const values = calculatePriceElementBreakdown(priceElement);
  const totalVariationCost = values.variationTotalCost;

  const product = watch(`variations[${index}].product`);
  const vendorId = watch(`variations[${index}].vendorId`);
  const markup = watch(`variations[${index}].markup`);
  const buyPrice = watch(`variations[${index}].buyPrice`);
  const quantity = watch(`variations[${index}].quantity`);
  const variationQuantity = values.variationQuantity;

  const setDefaultMarkup = useCallback((isLoading) => {
    if (!isGrossMargin && variationQuantity > 0 && buyPrice > 0) {

      const {PRODUCT} = markupType;

      const productObj = {
        type: PRODUCT,
        vendorId: product?.vendor?._id ?? vendorId,
        brand: product?.brand ?? null,
        category: product?.category ?? null,
        subCategory: product?.subCategory ?? null,
      };

      const defaultMarkupObj = new DefaultMarkup(productObj, assignedDefaultMarkupData);
      const {isAssignedMarkup, assignedMarkupId, isChildOfMarkup, isChildOfMarkupId} = defaultMarkupObj;

      if (isAssignedMarkup || isChildOfMarkup) {
        MarkupService.getMarkupCache(assignedMarkupId || isChildOfMarkupId).then(
          (data) => {
            let acceptedMarkupArray = [];
            data.markupRanges.forEach((el) => {
              if (
                buyPrice >= el.lowerPrice
                && buyPrice <= el.upperPrice
                && values.variationQuantity >= el.quantity
              ) {
                acceptedMarkupArray.push(el);
              }
            });
            const newDefaultMarkupObj = maxBy(acceptedMarkupArray, (el) => el.quantity);
            const oldDefaultMarkupIndex = acceptedMarkupArray.findIndex((el) => el.percentage === toFloat(defaultValue));
            const duplicateDefaultMarkupIndex = data.markupRanges.findIndex((el) => el.percentage === toFloat(markup) && variationQuantity + toFloat(quantity) >= el.quantity);
            const prevMarkupIndex = data.markupRanges.findIndex((el) => el.percentage === toFloat(markup));

            // Now set the default markup, if there is a custom value set we will not override it, but, if there is
            // no custom value set we will. Also, while loading, we determine whether a custom value is defined.
            setDefaultMarkupValue(
              (prevVal) => {
                let newVal = newDefaultMarkupObj?.percentage;
                const markupCleaned = toFloat(markup);

                if (
                  ((markupCleaned === prevVal) && newVal !== prevVal && newVal)
                  || ((markup == null || markup === '') && !!newVal)
                  || ((!defaultValue) && (prevVal === undefined || prevVal === '') && newVal)
                ) {
                  if (isLoading && asFloat(markup) !== asFloat(newVal)) {
                    setIsCustomValue(true);
                  } else if (!isCustomValue) {
                    setValue(name, decimalPlaces(newVal, 2));
                  }
                  return newVal;
                } else if (
                  (prevVal === undefined || prevVal === false)
                  && defaultValue === newVal
                  && newVal !== prevVal
                ) {
                  return newVal;
                } else if (
                  (oldDefaultMarkupIndex > -1) ||
                  (duplicateDefaultMarkupIndex > -1 && !prevVal) ||
                  (prevMarkupIndex > -1)
                ) {
                  if (isLoading && asFloat(markup) !== asFloat(newVal)) {
                    setIsCustomValue(true);
                  } else if (!isCustomValue) {
                    setValue(name, decimalPlaces(newVal, 2));
                  }
                  return newVal;
                }
                return newVal;
              }
            );
          }
        );
      }
    }
  }, [assignedDefaultMarkupData, buyPrice, defaultValue, isCustomValue, isGrossMargin, markup, name, quantity, setValue, values.variationQuantity, variationQuantity, vendorId, product]);

  useWatchEffect(([prevTotalVariationCost]) => {
    // Determine if we are still loading, and tell the default markup calculator about it
    const postLoaded = prevTotalVariationCost != null && asCurrencyString(totalVariationCost) !== asCurrencyString(prevTotalVariationCost);
    setDefaultMarkup(!postLoaded);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalVariationCost]);

  return (
    <Controller
      name={name}
      defaultValue={field.markup}
      render={(props) => (
        <HoopsTextField
          type='number'
          inputType='currency'
          responsive
          {...props}
          onChange={(e) => {
            onPriceChanged();
            setIsCustomValue(!!e.target.value);
            props.onChange(e);
          }}
          disabled={isGrossMargin}
          InputProps={{endAdornment: <InputAdornment position={'end'}>%</InputAdornment>}}
          {...params}
        />
      )}
      control={control}
    />
  );
};

export default MarkupField;
