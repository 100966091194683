import {useEffect, useState} from 'react';

export function useVisibleObserver(
  elementRef,
  {
    threshold = 0,
    root = null,
    rootMargin = '0%'
  } = {},
) {
  const [visible, setVisible] = useState();

  useEffect(() => {
    if (!elementRef.current) {
      return () => null;
    }
    const observer = new IntersectionObserver((entries) => {
      setVisible(entries?.[0]?.isIntersecting ?? false);
    }, {threshold, root, rootMargin});

    observer.observe(elementRef.current);

    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef.current, threshold, root, rootMargin]);

  return visible;
}
