import {openInvoiceModal} from './modals';
import {InvoiceService} from '../servicesOld/InvoiceService';

export function draftInvoiceFromQuote(quoteId) {
  return (dispatch) => {
    InvoiceService.draftInvoiceFromQuote(quoteId)
      .then((invoice) => {
        dispatch(openInvoiceModal({invoice, isDraft: invoice._id == null}));
      });
  };
}

export function draftInvoiceFromJob(jobId) {
  return (dispatch) => {
    InvoiceService.draftInvoiceFromJob(jobId)
      .then((invoice) => {
        dispatch(openInvoiceModal({invoice, isDraft: invoice._id == null}));
      });
  };
}

export function getInvoice(_id) {
  return (dispatch) => {
    InvoiceService.getInvoice(_id)
      .then((invoice) => {
        dispatch(openInvoiceModal({invoice, isDraft: false}));
      });
  };
}
