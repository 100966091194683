// TODO: fix eslint disable
/* eslint-disable no-shadow, no-unused-vars */

import {USER_LOGGED_IN, SET_USER_DATA, login} from './auth';
import {snackError, snackSuccess} from './snackbar';
import UserService from '../../servicesOld/UserService';
import {
  REGISTER_ERROR,
  REGISTER_LOADING,
  REGISTER_SUCCESS,
} from './registration';
import {hoopsQuerySetRefetch} from '../hoopsQuery';

export const SET_USERS = 'SET_USERS';
export const SET_NEW_USER = 'SET_NEW_USER';
export const CLEAR_NEW_USER = 'CLEAR_NEW_USER';
export const SET_LOADING_USERS_FLAG = 'SET_LOADING_USERS_FLAG';
export const CLEAR_LOADING_USERS_FLAG = 'CLEAR_LOADING_USERS_FLAG';
export const SET_USER_INTERCOM_ATTRIBUTES = 'SET_USER_INTERCOM_ATTRIBUTES';

export const UserLoggedIn = (payload) => ({
    type: USER_LOGGED_IN,
    payload,
  });

export const SetUserData = (payload) => ({
    type: SET_USER_DATA,
    payload,
  });

const SetLoadingUsersFlag = (payload) => ({type: SET_LOADING_USERS_FLAG,});

const ClearLoadingUsersFlag = (payload) => ({type: SET_LOADING_USERS_FLAG,});

export const DeleteUser = (_id) => {
  SetLoadingUsersFlag();
  return function(dispatch) {
    return UserService.deleteUser(_id)
      .then(({data: {deleteUser: user}}) => {
        ClearLoadingUsersFlag();
        dispatch(hoopsQuerySetRefetch('user', true));
        dispatch(snackSuccess(`${user.fullName}'s User Account deleted.`));
      })
      .catch((err) => {
        dispatch(snackError('Error deleting user.'));
        ClearLoadingUsersFlag();
      });
  };
};

export const UpdateUserRole = (user, role) => {
  SetLoadingUsersFlag();
  return function(dispatch) {
    return UserService.updateUserRole(user, role)
      .then(({data: {updateUserRole: fullName}}) => {
        ClearLoadingUsersFlag();
        dispatch(hoopsQuerySetRefetch('user', true));
        dispatch(snackSuccess('User role updated.'));
      })
      .catch((err) => {
        dispatch(snackError('Error updating user role.'));
        ClearLoadingUsersFlag();
      });
  };
};

export const GetUsers = (payload) => {
  SetLoadingUsersFlag();
  return function(dispatch) {
    return UserService.getUsers(payload)
      .then(({data: {users}}) => {
        ClearLoadingUsersFlag();
        dispatch({
          type: SET_USERS,
          payload: users,
        });
      })
      .catch((err) => {
        dispatch(snackError('Error getting users.'));
        ClearLoadingUsersFlag();
      });
  };
};

export const InviteUser = (user) => function(dispatch) {
    return UserService.inviteUser(user)
      .then(({data: {inviteUser: user}}) => {
        dispatch(snackSuccess(`${user.fullName} has been invited.`));
        dispatch(
          GetUsers({fetchPolicy: 'no-cache',})
        );
      })
      .catch((err) => {
        dispatch(snackError( err.message.replace('GraphQL error: ', '')));
      });
  };

export const AcceptInvite = (user) => function(dispatch) {
    dispatch({type: REGISTER_LOADING,});
    const {username, password} = user;
    return UserService.acceptInvite(user)
      .then(({data: {acceptInvite: user}}) => {
        dispatch({type: REGISTER_SUCCESS,});
        dispatch(snackSuccess('Welcome to Hoops!'));
        dispatch(login(username, password));
      })
      .catch((err) => {
        dispatch({type: REGISTER_ERROR,});
        dispatch(snackError('Something went wrong with your invite.'));
      });
  };

export const GetUserByExternalId = (externalId) => function(dispatch) {
    if (!externalId) {
      return dispatch({type: CLEAR_NEW_USER,});
    }
    return UserService.getUserByExternalId(externalId).then((user) => {
      dispatch({
        type: SET_NEW_USER,
        payload: user,
      });
    });
  };

export const SetNewUser = (payload) => function(dispatch) {
    return dispatch({
      type: SET_NEW_USER,
      payload
    });
  };
