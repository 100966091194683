import {Button, Dialog, DialogActions, DialogContent, DialogTitle, MuiThemeProvider, Typography} from '@material-ui/core';
import React from 'react';
import green from '../../../ui/theme/green';

export const CustomerImportDialog = ({
    open = false,
    onClose = () => null
}) => (
        <Dialog onClose={onClose} aria-labelledby='simple-dialog-title' open={open}>
            <DialogTitle>Importing Customers</DialogTitle>
            <DialogContent>
                <Typography variant='body1' component='p' align='center'>Your customer import has started. It may take several minutes for the import to complete.</Typography>
            </DialogContent>
            <DialogActions>
                <MuiThemeProvider theme={green}>
                    <Button color='primary' variant='contained' onClick={onClose}>Got It</Button>
                </MuiThemeProvider>
            </DialogActions>
        </Dialog>
    );
