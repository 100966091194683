// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React, {Component} from 'react';
import {Grid, MuiThemeProvider, Button, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import CheckCircle from '@material-ui/icons/CheckCircle';
import {Cancel} from '@material-ui/icons';
import green from '../../../ui/theme/green';
import {withRouter} from 'react-router';
import {get} from 'lodash';
import red from '../../../ui/theme/red';

const styles = (theme) => ({
  actionButtonContainer: {
    width: '100%',
    padding: theme.spacing(2)
  },
  actionButton: {
    textTransform: 'none',
    padding: theme.spacing(2)
  },
  dividerText: {fontWeight: theme.typography.fontWeightRegular}
});

class ActionButtons extends Component {
  render() {
    const {
      classes,
      match: {params: {jobId}},
      activeProofIndex,
      activeProof,
      proofs,
      approveProof,
      rejectProof
    } = this.props;

    return (
      <Grid container className={classes.actionButtonContainer} 
      justifyContent={'space-between'} alignItems={'center'}>
        <Grid item sm={5} xs={12}>
          <MuiThemeProvider theme={green}>
            <Button
              fullWidth={true}
              component={Grid}
              className={classes.actionButton}
              container justifyContent={'space-between'}
              variant={'contained'} color={'primary'}
              onClick={() => approveProof(jobId, get(activeProof, '_id'))}
            >
              <Grid item container direction={'column'} xs={8}>
                <Grid item>
                  <Typography variant={'body1'}>
                    <strong>APPROVE</strong> Proof {activeProofIndex + 1} of {proofs.length}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant={'body2'} style={{fontSize: 12}}>
                    and proceed with the order
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item justifyContent='flex-end' xs={4}>
                <CheckCircle/>
              </Grid>
            </Button>
          </MuiThemeProvider>
        </Grid>
        <Grid item container justifyContent={'center'} sm={2} xs={12}>
          <Typography color={'textSecondary'}
                    variant={'h6'}
                    className={classes.dividerText}
          >OR</Typography>
        </Grid>
        <Grid item sm={5} xs={12}>
          <MuiThemeProvider theme={red}>
            <Button
              className={classes.actionButton}
              fullWidth={true}
              component={Grid}
              container
              justifyContent={'space-between'}
              variant={'contained'}
              color={'primary'}
              onClick={() => rejectProof(jobId, get(activeProof, '_id'))}
            >
              <Grid item container direction={'column'} xs={8}>
                <Grid item>
                  <Typography variant={'body1'}>
                    <strong>REJECT</strong> Proof {activeProofIndex + 1} of {proofs.length}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant={'body2'} style={{fontSize: 12}}>
                    and tell us what changes to make
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item justifyContent='flex-end' xs={4}>
                <Cancel/>
              </Grid>
            </Button>
          </MuiThemeProvider>
        </Grid>
      </Grid>
    );
  }
}

ActionButtons = withStyles(styles)(ActionButtons);
ActionButtons = withRouter(ActionButtons);

export default ActionButtons;
