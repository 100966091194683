export const ADDRESS_TO_FRAGMENT = `
    _id
    label
    address1
    address2
    city
    state
    postcode
    country
    formattedAddress
`;
