// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React, {Component} from 'react';
import {Grid, Typography, MuiThemeProvider, Button} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {Done as DoneIcon} from '@material-ui/icons';
import green from '../../../ui/theme/green';
import {withRouter} from 'react-router';

const styles = (theme) => ({
  checkBackground: {
    height: theme.spacing(30),
    width: theme.spacing(30),
    border: `3px solid ${theme.colors.green}`,
    borderRadius: '50%',
    color: theme.colors.green,
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      height: theme.spacing(20),
      width: theme.spacing(20),
      marginBottom: theme.spacing(7),
      alignItems: 'center'
    }
  },
  icon: {
    fontSize: '135px',
    [theme.breakpoints.down('md')]: {fontSize: '90px'}
  },
  text: {
    fontSize: 26,
    [theme.breakpoints.down('md')]: {fontSize: 20}
  },
  button: {marginTop: theme.spacing(2)}
});

class Complete extends Component {
  render() {
    const {classes, onDisplayProofs} = this.props;
    return (
        <MuiThemeProvider theme={green}>
          <Grid container direction='column' justifyContent='center' alignItems='center' style={{height: '100vh'}}>
            <Grid container item justifyContent='center' alignItems='center' className={classes.checkBackground}>
              <DoneIcon className={classes.icon} />
            </Grid>
          <Typography color='primary' align='center' className={classes.text}>All proofs have been actioned.</Typography>
          <Typography color='primary' align='center' className={classes.text} gutterBottom>Thank you</Typography>
          <Button
            variant='contained'
            color='primary'
            size='large'
            className={classes.button}
            onClick={() => onDisplayProofs()}
          >View Proofs</Button>
        </Grid>
      </MuiThemeProvider>
    );
  }
}

Complete = withStyles(styles)(Complete);
Complete = withRouter(Complete);

export default Complete;
