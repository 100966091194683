import {createGraphQLClient} from './GraphQLService';
import moment from 'moment';
import {
  GQL_CREATE_PO,
  GQL_GET_DRAFT_PO,
  GQL_GET_PO,
  GQL_GET_PURCHASE_ORDERS,
  GQL_GET_PURCHASE_ORDER_MANY,
  GQL_UPDATE_PO,
  GQL_UPDATE_PURCHASE_ORDER_ITEM,
  GQL_UPDATE_PURCHASE_ORDER_ITEM_VARIANT,
  GQL_UPDATE_PURCHASE_ORDER_ITEM_DECORATION,
  GQL_UPDATE_PURCHASE_ORDER_ITEM_ADDITIONAL_ITEM,
  GQL_DELETE_PURCHASE_ORDER
} from '../queries/purchaseOrders';
import {toFloat} from './ItemBuilderServices';
import {get, remove, set} from 'lodash';
import {parseLineItemTax} from './QuoteService';

const client = createGraphQLClient({});

class PurchaseOrdersService {

  static parsePo(values) {
    const linkedJobs = get(values, 'linkedJobs', []);
    const items = get(values, 'items', []);
    remove(items, (item) => !item.variants); // Remove any un-used item fields
    const vendorId = get(values, 'vendor._id', null);

    const deadlineAt = values.deadlineAt
      ? moment(values.deadlineAt).startOf('day').toISOString()
      : undefined;

    return {
      title: values.title,
      status: values.status,
      accountingSync: values.accountingSync,
      deadlineAt,
      customReference: values.customReference,
      ownerId: values.ownerId,
      linkedJobIds: (linkedJobs.length ? linkedJobs.map((j) => j._id) : undefined),
      vendorId: (vendorId ? vendorId : undefined),
      isMasterPurchaseOrder: values.isMasterPurchaseOrder,
      masterPurchaseOrderId: values.masterPurchaseOrderId,
      childPurchaseOrderIds: values.childPurchaseOrderIds,
      items: (items.length ? items.map(PurchaseOrdersService.parsePoItem) : undefined),
      notes: values.notes,
      shipTo: PurchaseOrdersService.parseShipTo(values.shipTo)
    };
  }

  static parseShipTo(values) {
    if (!values) {
      return undefined;
    }
    let address = get(values, 'address', {});
    if (!address) {address = {};}
    const {address1, address2, city, state, postcode, country} = address;
    return {
      name: values.name,
      email: values.email,
      phone: values.phone,
      attention: values.attention,
      address: {
        address1,
        address2,
        city,
        state,
        postcode,
        country
      }
    };
  }

  static parsePoItem(values) {

    const variants = get(values, 'variants', []);
    const decorations = get(values, 'decorations', []);
    const additionalItems = get(values, 'additionalItems', []);
    const tax = get(values, 'tax', {});
    const Files = get(values, 'files', []);

    let PoItem = {
      description: get(values, 'description'),
      variants: variants.map(PurchaseOrdersService.parsePoItemVariant),
      decorations: decorations.map(PurchaseOrdersService.parsePoItemDecoration),
      additionalItems: additionalItems.map(PurchaseOrdersService.parsePoItemAdditionalItem),
      tax: parseLineItemTax(tax),
      fileIds: Files.map((file) => file._id)
    };

    if (values.product) {
      set(PoItem, 'productId', values.product._id);
    }

    return PoItem;
  }

  static parsePoItemVariant(values) {
    let PoItemVariant = {
      jobItemVariantId: values.jobItemVariantId,
      productId: values.productId,
      productCode: values.productCode,
      productName: values.productName,
      colour: values.colour,
      size: values.size,
      quantity: parseInt(values.quantity, 10),
      ordered: Boolean(values.ordered),
      received: Boolean(values.received),
      rate: toFloat(values.rate, 0),
      isCustomBuyPrice: values.isCustomBuyPrice || undefined
    };

    const productVariantId = get(values, 'productVariant._id');
    if (productVariantId && productVariantId.length === 24) {
      set(PoItemVariant, 'productVariantId', get(values, 'productVariant._id'));
      delete PoItemVariant.size;
    } else {
      PoItemVariant.size = productVariantId || PoItemVariant.size;
    }

    return PoItemVariant;
  }

  static parsePoItemDecoration(values) {
    return {
      jobItemDecorationId: values.jobItemDecorationId,
      description: values.description,
      decorationId: values.decorationId,
      position: values.position,
      colours: values.colours,
      size: values.size,
      quantity: parseInt(values.quantity, 10),
      ordered: Boolean(values.ordered),
      received: Boolean(values.received),
      rate: toFloat(values.rate, 0)
    };
  }

  static parsePoItemAdditionalItem(values) {
    return {
      jobItemAdditionalItemId: values.jobItemAdditionalItemId,
      description: values.description,
      quantity: parseInt(values.quantity, 10),
      ordered: Boolean(values.ordered),
      received: Boolean(values.received),
      rate: toFloat(values.rate, 0)
    };
  }

  static parsePoItemTax({name, rate}) {
    return {
      name,
      rate: toFloat(rate, 0)
    };
  }

  static getPurchaseOrders(variables) {
    return client.query({
      variables,
      query: GQL_GET_PURCHASE_ORDERS,
      fetchPolicy: 'no-cache'
    })
      .then(({data: {purchaseOrders}}) => purchaseOrders);
  }

  static getPurchaseOrderMany(variables) {
    return client.query({
      variables,
      query: GQL_GET_PURCHASE_ORDER_MANY,
      fetchPolicy: 'no-cache'
    })
      .then(({data: {purchaseOrderMany}}) => purchaseOrderMany);
  }

  static getPurchaseOrder(_id) {
    return client.query({
      query: GQL_GET_PO,
      variables: {_id},
      fetchPolicy: 'no-cache'
    })
      .then(({data: {purchaseOrder}}) => purchaseOrder);
  }

  static getDraftPo(jobId, vendorId) {
    return client.query({
      query: GQL_GET_DRAFT_PO,
      variables: {
        jobId,
        vendorId
      },
      fetchPolicy: 'no-cache'
    });
  }

  static updatePurchaseOrder(_id, values) {
    return client.mutate({
      mutation: GQL_UPDATE_PO,
      variables: {
        _id,
        poData: PurchaseOrdersService.parsePo(values)
      }
    })
      .then(({data: {updatePurchaseOrder: po}}) => po);
  }

  static createPurchaseOrder(values) {
    return client.mutate({
      mutation: GQL_CREATE_PO,
      variables: {poData: PurchaseOrdersService.parsePo(values)}
    })
      .then(({data: {createPurchaseOrder: po}}) => po);
  }

  static updatePurchaseOrderItem(purchaseOrderId, itemId, updatedItem) {
    return client.mutate({
      mutation: GQL_UPDATE_PURCHASE_ORDER_ITEM,
      variables: {
        purchaseOrderId,
        itemId,
        updatedItem
      }
    });
  }

  static updatePurchaseOrderItemVariant(purchaseOrderId, itemId, variantId, updatedVariant) {
    return client.mutate({
      mutation: GQL_UPDATE_PURCHASE_ORDER_ITEM_VARIANT,
      variables: {
        purchaseOrderId,
        itemId,
        variantId,
        updatedVariant
      }
    });
  }

  static updatePurchaseOrderItemDecoration(purchaseOrderId, itemId, decorationId, updatedDecoration) {
    return client.mutate({
      mutation: GQL_UPDATE_PURCHASE_ORDER_ITEM_DECORATION,
      variables: {
        purchaseOrderId,
        itemId,
        decorationId,
        updatedDecoration
      }
    });
  }

  static updatePurchaseOrderItemAdditionalItem(purchaseOrderId, itemId, additionalItemId, updatedAdditionalItem) {
    return client.mutate({
      mutation: GQL_UPDATE_PURCHASE_ORDER_ITEM_ADDITIONAL_ITEM,
      variables: {
        purchaseOrderId,
        itemId,
        additionalItemId,
        updatedAdditionalItem
      }
    });
  }

  static deletePurchaseOrder(_id) {
    return client.mutate({
      mutation: GQL_DELETE_PURCHASE_ORDER,
      variables: {purchaseOrderId: _id}
    });
  }

}

export default PurchaseOrdersService;
