// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {find, get} from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getFormValues, reduxForm} from 'redux-form';
import {closeViewPurchaseOrderModal as _closeViewPurchaseOrderModal} from '../../../../actions/modals';
import {getPurchaseOrder as _getPurchaseOrder} from '../../../../actions/purchaseOrders';
import AdditionalItemsTable from '../../../purchase-orders/SinglePurchaseOrder/AdditionalItemsTable';
import Attachments from '../../../purchase-orders/SinglePurchaseOrder/Attachments';
import DecorationsTable from '../../../purchase-orders/SinglePurchaseOrder/DecorationsTable';
import Totals from '../../../purchase-orders/SinglePurchaseOrder/Totals';
import VariantsTable from '../../../purchase-orders/SinglePurchaseOrder/VariantsTable';

export const purchaseOrderForm = 'purchaseOrderForm';

export function AddressOneLine({addressType, addresses}) {
  let address = find(addresses, {label: addressType});
  if (!address) {
    if (addresses && addresses.length) {
      address = addresses[0];
    }
  }

  let addressString = [
    'address1', 'address2', 'city', 'state', 'postcode', 'country'
  ]
    .filter((key) => Boolean(address && address[key]))
    .map((key) => address[key])
    .join(', ');

  return (
    <>
      {(address ?
        <Typography variant='body2' gutterBottom>
          {addressString}
        </Typography> :
        <Typography variant={'body2'} color={'textSecondary'} gutterBottom>No linked address</Typography>)}
    </>
  );
}

const styles = (theme) => ({
  topContainer: {marginBottom: theme.spacing(3)},
  primaryColor: {backgroundColor: theme.palette.primary.main},
  header: {
    position: 'relative',
    backgroundColor: theme.colors.grey.ultraLight,
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  orderContainer: {padding: theme.spacing(5)},
  button: {
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  buttonContainer: {marginBottom: theme.spacing(8)},
  marginRight: {marginRight: theme.spacing(2)},
  badgeMargin: {margin: theme.spacing(2)},
  tableContainer: {
    border: `1px solid ${theme.colors.grey.light}`,
    marginTop: theme.spacing(3)
  },
});

class ViewPurchaseOrderModal extends Component {
  state = {PurchaseOrder: {}}

  initForm = () => {
    const {getPurchaseOrder, context} = this.props;
    const purchaseOrderId = get(context, 'purchaseOrderId', false);
    getPurchaseOrder(purchaseOrderId);
  };

  handleClose = () => {
    const {closeModal} = this.props;
    closeModal();
  };

  handleEditShipTo = () => {
    const {openShipTo, poFormValues: {shipTo}} = this.props;
    openShipTo(shipTo, true);
  };

  render() {
    const {
      open, classes, company, user,
      poFormValues, PurchaseOrder,
    } = this.props;

    const shipTo = get(PurchaseOrder, 'shipTo', {});
    const shippingAddress = get(shipTo, 'address.formattedAddress', '');

    if (!open) {
      return null;
    }

    open && this.initForm();

    return (
      <Dialog open={open} fullScreen={true} >
        <form >
          <Grid container className={classes.header}>
            <Toolbar style={{width: '100%'}}>
              <Grid container justifyContent={'space-between'}>

                <Grid item xs={11}>
                  {/* PO Title */}
                  <Grid container spacing={4} alignItems={'center'}> {/*1*/}
                    <Grid item>
                    </Grid> {/*Offset*/}
                    <Grid container item xs={10} spacing={5}>
                      <Grid container item spacing={5}>
                      </Grid>
                      <Grid container item spacing={5}>
                        <Grid item xs={10}>
                        <Typography variant='h4'>{get(PurchaseOrder, 'title', 'Purchase Order Preview')}</Typography>
                        <Typography>
                            <strong>PO #: {get(PurchaseOrder, 'number', '')}</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                        </Grid>
                        <Grid container item direction={'column'} xs={4}>
                        </Grid>
                      </Grid>
                      <Grid container item spacing={5}>
                        <Grid container item xs={4} direction='row' wrap='nowrap'>
                          <Grid item className={classes.marginRight}>
                            <Avatar className={classes.primaryColor}>
                              <Icon>assignment_ind</Icon>
                            </Avatar>
                          </Grid>
                          <Grid item>
                            <Typography variant='h6' style={{fontWeight: 400}} gutterBottom>Vendor:</Typography>
                            {PurchaseOrder && PurchaseOrder.vendor && (
                              <>
                                <Typography variant='body2' style={{fontWeight: 500}}
                                  gutterBottom>{PurchaseOrder.vendor.name}</Typography>
                                <AddressOneLine addressType={'BILLING'} addresses={PurchaseOrder.vendor.addresses} />
                                <Typography variant='body2' gutterBottom>{PurchaseOrder.vendor.email}</Typography>
                                <Typography variant='body2' gutterBottom>{PurchaseOrder.vendor.phone}</Typography>
                              </>
                            )}
                          </Grid>
                        </Grid>
                        <Grid container item xs={4} direction='column'>
                          <Typography variant='h6' style={{fontWeight: 400}} gutterBottom>Bill to:</Typography>
                          <Typography variant='body2' style={{fontWeight: 500}} gutterBottom>{company.name}</Typography>
                          <AddressOneLine addressType={'BILLING'} addresses={company.addresses} />
                          <Typography variant='body2' gutterBottom>{user.username}</Typography>
                          <Typography variant='body2' gutterBottom>{company.phone}</Typography>
                        </Grid>
                        <Grid container item xs={4} direction='column'>
                          <Grid item container justifyContent={'space-between'} alignItems={'center'}>
                            <Typography variant='h6' style={{fontWeight: 400}} gutterBottom>Ship to:</Typography>
                            {/* <Button size={"small"}
                              color={"primary"}
                              variant={"outlined"}
                              onClick={() => this.handleEditShipTo()}
                            >
                              Edit
                            </Button> */}
                          </Grid>
                          <Typography variant='body2' style={{fontWeight: 500}} gutterBottom>{shipTo.name}</Typography>
                          {shipTo.attention &&
                            <Typography variant='body2' gutterBottom>Attn: {shipTo.attention}</Typography>}
                          <Typography variant='body2' gutterBottom>{shippingAddress}</Typography>
                          <Typography variant='body2' gutterBottom>{shipTo.email}</Typography>
                          <Typography variant='body2' gutterBottom>{shipTo.phone}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <IconButton onClick={this.handleClose}>
                    <Icon>close</Icon>
                  </IconButton>
                </Grid>
              </Grid>
            </Toolbar>
          </Grid>
          <Grid container className={classes.orderContainer}>
            {poFormValues && poFormValues.vendor && (
              <Typography variant='h6' style={{fontWeight: 400}}>Items</Typography>

            )}
            {PurchaseOrder.items ? PurchaseOrder.items.map((PurchaseOrderItem) => {
              const files = get(PurchaseOrderItem, 'files');

              return (
                <Grid key={PurchaseOrderItem._id} item xs={12} className={classes.tableContainer} >

                  {PurchaseOrderItem.variants && PurchaseOrderItem.variants.length ?
                    <VariantsTable
                      item={PurchaseOrderItem}
                      description={get(PurchaseOrderItem, 'description')}
                      variants={get(PurchaseOrderItem, 'variants', [])}
                      readOnly={true}
                    /> : null}
                  {PurchaseOrderItem.decorations && PurchaseOrderItem.decorations.length ?
                    <DecorationsTable
                      item={PurchaseOrderItem}
                      itemId={get(PurchaseOrderItem, '_id')}
                      decorations={get(PurchaseOrderItem, 'decorations', [])}
                      readOnly={true}

                    /> : null}
                  {PurchaseOrderItem.additionalItems && PurchaseOrderItem.additionalItems.length ?
                    <AdditionalItemsTable

                      item={PurchaseOrderItem}
                      itemId={get(PurchaseOrderItem, '_id')}
                      additionalItems={get(PurchaseOrderItem, 'additionalItems', [])}
                      readOnly={true}
                    /> : null}
                  {files && Boolean(files.length) && <Attachments files={files} />}
                </Grid>
              );
            }) : null}
            <Grid container item xs={12} direction='column' justifyContent='right' spacing={2} style={{paddingTop: '16px'}}>
              <Totals PurchaseOrder={PurchaseOrder} />
            </Grid>
          </Grid>

        </form>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    open: state.modalReducer.viewPurchaseOrderModal.isOpen,
    context: state.modalReducer.viewPurchaseOrderModal.context,
    company: state.companyReducer.company,
    user: state.authReducer.userData,
    initialValues: state.purchaseOrdersReducer.formPurchaseOrder,
    poFormValues: getFormValues(purchaseOrderForm)(state),
    PurchaseOrder: state.purchaseOrdersReducer.activePurchaseOrder,

  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => dispatch(_closeViewPurchaseOrderModal()),
    getPurchaseOrder: (_id) => dispatch(_getPurchaseOrder(_id)),
  };
}

ViewPurchaseOrderModal = reduxForm({
  form: purchaseOrderForm,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(ViewPurchaseOrderModal);
ViewPurchaseOrderModal = withStyles(styles)(ViewPurchaseOrderModal);
ViewPurchaseOrderModal = connect(mapStateToProps, mapDispatchToProps)(ViewPurchaseOrderModal);

export default ViewPurchaseOrderModal;
