import {Skeleton} from '@mui/material';
import React from 'react';
import {HoopsPropTypes} from '../utils';

export const TextSkeleton = ({width, animation}) => (
  <Skeleton variant='text' width={width} animation={animation} sx={{fontSize: '1rem'}} ></Skeleton>
);

TextSkeleton.propTypes = {
  width: HoopsPropTypes.number,
  animation: HoopsPropTypes.stringOrBool,
};
