// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-class-assign */

import React, {Component} from 'react';
import {withStyles} from '@material-ui/core';
import HoopsFormDialog from '../modal/HoopsFormDialog';
import {Field, Form, reduxForm, submit as _submit, change as _change} from 'redux-form';
import AddressFields from '../address/addressFields';
import Grid from '@material-ui/core/Grid';
import {connect, useDispatch} from 'react-redux';
import {renderTextField} from '../shared/formHelpers/fields';
import {closePOShipTo as _closePOShipTo} from '../../actions/modals';
import Button from '@material-ui/core/Button';
import {updatePo as _updatePo} from '../../actions/purchaseOrders';
import {purchaseOrderForm} from '../purchase-orders/PurchaseOrderEditPage';
import SelectShipTo from './SelectShipTo';

const styles = (theme) => ({});
const PO_SHIP_TO_FORM_NAME = 'POShipToForm';

function GridField(props) {
  return (
    <Grid item xs={12}>
      <Field {...props}/>
    </Grid>
  );
}

function SaveButton() {
  const dispatch = useDispatch();
  const submit = (form) => dispatch(_submit(form));

  return (
    <>
      <Button
        color={'primary'}
        variant={'contained'}
        onClick={() => submit(PO_SHIP_TO_FORM_NAME)}
      >
        Update
      </Button>
    </>
  );
}

class POShipToModal extends Component {
  onSubmit = (values) => {
    const {purchaseOrder, closeModal, isDraftPo, updatePo, setPoFormShipTo} = this.props;

    setPoFormShipTo(values);
    if (purchaseOrder._id && !isDraftPo) {
      updatePo(purchaseOrder._id, {
        ...purchaseOrder,
        shipTo: values
      }, () => closeModal());
      return;
    }
    closeModal();
  };

  handleSelect = (value) => {
    const {setShipToValue} = this.props;
    setShipToValue('name', value.name);
    setShipToValue('phone', value.phone);
    setShipToValue('email', value.email);
  }

  render() {
    const {isOpen, closeModal, handleSubmit, purchaseOrder} = this.props;

    return (
      <>
        <HoopsFormDialog
          open={isOpen}
          onCancel={closeModal}
          SaveButton={SaveButton}
          title={'Edit Ship To'}
        >
          <Form onSubmit={handleSubmit(this.onSubmit)}>
            <Grid container spacing={3} direction={'column'}>
              <Grid item>
                <Grid container justifyContent={'center'}>
                  <Grid item xs={8}>
                    <Field name={'address'}
                           component={SelectShipTo}
                           onSelect={this.handleSelect}
                           purchaseOrder={purchaseOrder}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Grid container spacing={2}>
                      <GridField
                        label={'Company Name'}
                        name={'name'}
                        component={renderTextField}/>
                      <GridField
                        label={'Attn:'}
                        name={'attention'}
                        component={renderTextField}/>
                      <GridField
                        label={'Phone'}
                        name={'phone'}
                        component={renderTextField}/>
                      <GridField
                        label={'Email'}
                        name={'email'}
                        component={renderTextField}/>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <AddressFields address={'address'} showAddressLine2 />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </HoopsFormDialog>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    purchaseOrder: state.purchaseOrdersReducer.activePurchaseOrder,
    isOpen: state.modalReducer.poShipToModal.isOpen,
    initialValues: state.modalReducer.poShipToModal.context.shipTo,
    isDraftPo: state.modalReducer.poShipToModal.context.isDraftPo
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => dispatch(_closePOShipTo()),
    updatePo: (_id, data, cb) => dispatch(_updatePo(_id, data, cb)),
    setPoFormShipTo: (values) => dispatch(_change(purchaseOrderForm, 'shipTo', values)),
    setShipToValue: (field, value) => dispatch(_change(PO_SHIP_TO_FORM_NAME, field, value))
  };
}

POShipToModal = reduxForm({
  form: PO_SHIP_TO_FORM_NAME,
  enableReinitialize: true
})(POShipToModal);
POShipToModal = withStyles(styles)(POShipToModal);
POShipToModal = connect(mapStateToProps, mapDispatchToProps)(POShipToModal);

export default POShipToModal;
