// TODO: fix eslint disable
/* eslint-disable consistent-return, no-shadow */

import {add} from 'date-fns';
import {DayPilot} from 'daypilot-pro-react';

export class Task {

    constructor(task) {
        Object.assign(this, task);
    }

    toDayPilotEvent(statuses = []) {
        if (!this.dueAt) {
            return;
        }

        const payload = {
            id: this._id,
            start: new DayPilot.Date(new Date(this.dueAt), true),
            end: this.endAt ? new DayPilot.Date(new Date(this.endAt), true) : new DayPilot.Date(add(new Date(this.dueAt), {minutes: 15}), true),
            text: this.title,
            entityType: 'task',
            allday: this.allDay,
            entityData: this,
            resource: this.resourceId,
        };

        if (statuses) {
            const status = statuses.filter((status) => this.status === status.value)[0];
            if(status) {
                payload.backColor = status.color;
            }
        }

        return payload;
    }
}

export const tasksToDayPilot = (tasks, statuses) => tasks.map(
        (task) => new Task(task).toDayPilotEvent(statuses)
    )
    .filter((d) => !!d);

export const taskToDayPilot = (task, statuses) => new Task(task).toDayPilotEvent(statuses);

export const resourceToDayPilot = (resource) => ({
    id: resource._id,
    name: resource.title
});

export const resourcesToDayPilot = (resources) => resources.map(resourceToDayPilot);
