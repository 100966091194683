// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import {Grid, withStyles} from '@material-ui/core';
import {Field} from 'redux-form';
import {renderTextField} from '../shared/formHelpers/fields';
import {required, email} from '../shared/formHelpers/validation';
import React from 'react';

const styles = (theme) => ({
  gridPadding: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
});

class ContactFields extends React.Component {
  render() {
    const {classes, contact, notRequired} = this.props;

    return <Grid container spacing={1}>
      <Grid item xs={6} className={classes.gridPadding}>
        <Field fullWidth
               label={`First Name ${notRequired ? '' : '*'}`}
               name={`${contact}.firstName`}
               validate={notRequired ? null : required}
               type={'text'}
               component={renderTextField}
               data-intercom-target={'customer-contact-firstName'}/>
      </Grid>
      <Grid item xs={6} className={classes.gridPadding}>
        <Field fullWidth
               label={'Last Name'}
               name={`${contact}.lastName`}
               type={'text'}
               component={renderTextField}
               data-intercom-target={'customer-contact-lastName'}/>
      </Grid>
      <Grid item xs={6} className={classes.gridPadding}>
        <Field fullWidth
               label={`Email ${notRequired ? '' : '*'}`}
               name={`${contact}.email`}
               type={'email'}
               validate={notRequired ? email : [required, email]}
               component={renderTextField}
               data-intercom-target={'customer-contact-email'}/>
      </Grid>
      <Grid item xs={6} className={classes.gridPadding}>
        <Field fullWidth
               label={'Phone'}
               name={`${contact}.phone`}
               type={'text'}
               component={renderTextField}
               data-intercom-target={'customer-contact-phone'}/>
      </Grid>
    </Grid>;
  }
}

ContactFields = withStyles(styles)(ContactFields);

export default ContactFields;
