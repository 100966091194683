import {gql} from 'apollo-boost';

// TODO: This file would benefit from fragments

export const GQL_XERO_DETAILS = gql`
  query XeroDetails {
    xeroAccounts {
      code
      name
    }

    xeroAccountsExpense {
      code
      name
    }

    xeroTaxRates {
      taxType
      name
      rate
    }
  }
`;

export const GQL_DISCONNECT_QUICKBOOKS = gql`
  mutation DisconnectQuickbooks {
    disconnectQuickbooks {
      _id
      name
      accountingPlatform {
        _id
        platform
        platformId
        companyName
        refreshTokenExpiry
        xeroAccountCode
        xeroAccountList {
          code
          name
        }
        xeroAccountCodeExpense
        xeroAccountListExpense {
          code
          name
        }
        xeroTaxTypeDefault
        xeroTaxTypeDefaultExpense
        xeroTaxTypeMapping {
          hoopsTaxId
          xeroTaxType
        }
        defaultDueDate
        defaultInvoiceStatus
      }
    }
  }
`;

export const GQL_DISCONNECT_XERO = gql`
  mutation disconnectXero {
    disconnectXero {
      _id
      name
      accountingPlatform {
        _id
        platform
        platformId
        companyName
        refreshTokenExpiry
        xeroAccountCode
        xeroAccountList {
          code
          name
        }
        xeroAccountCodeExpense
        xeroAccountListExpense {
          code
          name
        }
        defaultDueDate
        defaultInvoiceStatus
      }
    }
  }
`;

export const GQL_UPDATE_XERO_SETTINGS = gql`
  mutation updateXeroSettings($data: XeroSettingsInput!) {
    updateXeroSettings(data: $data) {
      _id
      name
      accountingPlatform {
        _id
        platform
        platformId
        companyName
        refreshTokenExpiry
        xeroAccountCode
        xeroAccountList {
          code
          name
        }
        xeroAccountCodeExpense
        xeroAccountListExpense {
          code
          name
        }
        xeroTaxTypeDefault
        xeroTaxTypeDefaultExpense
        xeroTaxTypeMapping {
          hoopsTaxId
          xeroTaxType
        }
        defaultDueDate
        defaultInvoiceStatus
      }
    }
  }
`;

export const GQL_GET_COMPANY_FROM_JOB = gql`
  query getCompany($jobId: String) {
    company(jobId: $jobId) {
      currencySymbol
      companyTradingEntities {
        _id
        phone
        email
        name
        logo
        addresses {
          formattedAddress
        }
      }
      shippingAddress {
        formattedAddress
      }
      stripeSubscriptionId
      subscriptionType
      appAccess {
        jobBoard {
          columnLimit
          viewLimit
        },
        allowedFeatures
      }
      leadTypes
      industryTypes
      accountingPlatform {
        _id
        platform
        platformId
        companyName
        refreshTokenExpiry
        xeroAccountCode
        xeroAccountList {
          code
          name
        }
        xeroAccountCodeExpense
        xeroAccountListExpense {
          code
          name
        }
        xeroTaxTypeDefault
        xeroTaxTypeDefaultExpense
        xeroTaxTypeMapping {
          hoopsTaxId
          xeroTaxType
        }
        defaultDueDate
        defaultInvoiceStatus
      }
    }
  }
`;

export const GQL_COMPANY_FRAG = gql`
  fragment CompanyFragment on Company {
    _id
    region
    companyTradingEntities {
        _id
      name
      phone
      email
      website
      logo
      addresses {
        _id
        address1
        address2
        state
        postcode
        city
        label
        country
        formattedAddress
      }
    }
    billingAddress {
      _id
      address1
      address2
      state
      postcode
      city
      label
      country
      formattedAddress
    }
    shippingAddress {
      _id
      address1
      address2
      state
      postcode
      city
      label
      country
      formattedAddress
    }
    taxes {
      name
    }
    numberOfUsers {
      ACTIVE
      INVITED
    }
    jobDepartmentalStatuses {
      accountStatuses {
        name
        color
      }
      purchasingStatuses {
        name
        color
      }
      artworkStatuses {
        name
        color
      }
      productionStatuses {
        name
        color
      }
      shippingStatuses {
        name
        color
      }
    }
    tags {
      _id
      name
      companyId
      description
    }
    statuses {
      _id
      label
      locked
      entityType
      entityName
      entityMapping
      isVirtual
      statuses {
        _id
        label
        value
        color
        locked
      }
    }

    defaultTimezone
    quoteTerms
    proofTerms
    invoiceTerms
    createdAt
    currencySymbol
    updatedAt
    quoteNumber
    jobNumber
    purchaseOrderNumber
    stripeSubscriptionId
    subscriptionType
    subscriptionManager
    appAccess {
        jobBoard {
          columnLimit
          viewLimit
        }
        allowedFeatures
      }
    accountingPlatform {
      _id
      platform
      platformId
      companyName
      refreshTokenExpiry
      xeroAccountCode
      xeroAccountList {
        code
        name
      }
      xeroAccountCodeExpense
      xeroAccountListExpense {
        code
        name
      }
      xeroTaxTypeDefault
      xeroTaxTypeDefaultExpense
      xeroTaxTypeMapping {
        hoopsTaxId
        xeroTaxType
      }
      defaultDueDate
      defaultInvoiceStatus
    }
  }
`;

export const GET_COMPANY = gql`
  query getcompany {
    company {
      _id
      companyTradingEntities {
        _id
        name
        phone
        email
        website
        logo
        addresses {
          _id
          address1
          address2
          state
          postcode
          city
          label
          country
          formattedAddress
        }
      }
      region
      billingAddress {
        _id
        address1
        address2
        state
        postcode
        city
        label
        country
        formattedAddress
      }
      shippingAddress {
        _id
        address1
        address2
        state
        postcode
        city
        label
        country
        formattedAddress
      }
      taxes {
        _id
        name
        type
        isDefault
        components {
          name
          rate
        }
      }
      numberOfUsers {
        ACTIVE
        INVITED
      }
      jobDepartmentalStatuses {
        accountStatuses {
          name
          color
        }
        purchasingStatuses {
          name
          color
        }
        artworkStatuses {
          name
          color
        }
        productionStatuses {
          name
          color
        }
        shippingStatuses {
          name
          color
        }
      }
      tags {
        _id
        name
        companyId
        description
      }
      statuses {
        _id
        label
        locked
        entityType
        entityName
        entityMapping
        isVirtual
        statuses {
          _id
          label
          value
          color
          locked
        }
      }

      defaultTimezone
      quoteTerms
      proofTerms
      invoiceTerms
      createdAt
      currencySymbol
      updatedAt
      quoteNumber
      jobNumber
      purchaseOrderNumber
      stripeSubscriptionId
      subscriptionType
      subscriptionManager
      stripeConnectedAccountId
      appAccess {
        jobBoard {
          columnLimit
          viewLimit
        }
        allowedFeatures
      }
      leadTypes
      industryTypes
      royaltySettings {
        royaltyCap
        royaltyPercentage
        marketingFee
        storeID
      }
      accountingPlatform {
        _id
        platform
        platformId
        companyName
        refreshTokenExpiry
        xeroAccountCode
        xeroAccountList {
          code
          name
        }
        xeroAccountCodeExpense
        xeroAccountListExpense {
          code
          name
        }
        xeroTaxTypeDefault
        xeroTaxTypeDefaultExpense
        xeroTaxTypeMapping {
          hoopsTaxId
          xeroTaxType
        }
        defaultDueDate
        defaultInvoiceStatus
      }
    }
  }
`;

export const GQL_UPDATE_COMPANY_NUMBERING = gql`
  mutation companyUpdateEntityNumbers(
    $_id: MongoID!
    $jobNumber: Int!
    $quoteNumber: Int!
    $purchaseOrderNumber: Int!
  ) {
    companyUpdateEntityNumbers(
      _id: $_id
      jobNumber: $jobNumber
      quoteNumber: $quoteNumber
      purchaseOrderNumber: $purchaseOrderNumber
    ) {
      _id
      jobNumber
      quoteNumber
      purchaseOrderNumber
    }
  }
`;

export const GQL_UPDATE_COMPANY = gql`
  mutation companyUpdateById(
    $_id: MongoID!
    $record: UpdateByIdGCCompanyInput!
  ) {
    companyUpdateById(_id: $_id, record: $record) {
      record {
        _id
        companyTradingEntities {
          _id
          name
          phone
          email
          website
          logo
          addresses {
            _id
            address1
            address2
            state
            postcode
            city
            label
            country
          }
        }
        stripeSubscriptionId
        subscriptionType
        appAccess {
          jobBoard {
            columnLimit
            viewLimit
          }
          allowedFeatures
        }
        leadTypes
        industryTypes
        defaultTimezone
        invoiceTerms
        currencySymbol
        quoteTerms
        proofTerms
        createdAt
        updatedAt
        quoteNumber
        jobNumber
        purchaseOrderNumber
        royaltySettings {
          royaltyCap
          royaltyPercentage
          marketingFee
          storeID
        }
      }
    }
  }
`;

export const GQL_GET_COMPANIES = gql`
  query companyMany($limit: Int) {
    companyMany(limit: $limit) {
      _id
      companyTradingEntities {
        _id
        name
        phone
        email
      }
    }
  }
`;
