import {SendDialog} from './SendDialog';
import React, {useCallback} from 'react';
import {useSendSalesDoc} from '../../hooks/api';

export function SendSalesDocModal({salesDoc, onClose}) {
  const {send: sendApi} = useSendSalesDoc();

  const handleSend = useCallback(async (sendOptions) => {
    if (sendOptions.email?.to || sendOptions.sms?.phone) {
      const to = sendOptions.email?.to && sendOptions.sms?.phone
        ? `${sendOptions.email.to} and ${sendOptions.sms.phone}`
        : sendOptions.email?.to ?? sendOptions.sms?.phone;
      await sendApi({id: salesDoc._id, ...sendOptions}, {successMessage: `Sent ${salesDoc.docTypeName} #${salesDoc.number} to ${to}`});
    }
  }, [salesDoc._id, salesDoc.docTypeName, salesDoc.number, sendApi]);

  return (
    <SendDialog
      entity={'quote'}
      title={`Send ${salesDoc.docTypeName} #${salesDoc.number}`}
      onClose={onClose}
      onSend={handleSend}
      to={salesDoc.contact?.email || salesDoc.customer?.email}
      replyTo={salesDoc.owner?.username}
      phone={salesDoc.contact?.phone || salesDoc.customer?.phone}
    />
  );
}
