import {useQuery} from '@apollo/client';
import {Grid, MuiThemeProvider} from '@material-ui/core';
import {Image} from '@material-ui/icons';
import {uniqBy} from 'lodash';
import React, {useState} from 'react';
import CardContainer from '../../../componentsOld/common/CardContainer';
import {GQL_GET_CUSTOMER_PROOFS} from '../../../queries/proofs';
import theme from '../../../ui/theme/index';
import ProofsTable from './ProofsTable';

export const CustomerProofs = ({customerId = null,}) => {
  const [proofs, setProofs] = useState([]);

  const {loading: loadingProofs} = useQuery(GQL_GET_CUSTOMER_PROOFS, {
    fetchPolicy: 'no-cache',
    variables: {customerId: customerId},
    onCompleted: (result) => {
      if (result) {
        setProofs(uniqBy(result.proofs, 'fileId'));
      }
    },
  });

  return (
    <MuiThemeProvider theme={theme}>
      <CardContainer
        title='Proofs'
        icon={<Image />}
        iconBackgroundColor={theme.palette.primary.main}
      >
        <Grid container>
          <Grid item xs={12}>
            <ProofsTable
              proofs={proofs}
              isLoading={loadingProofs}
            />
          </Grid>
        </Grid>
      </CardContainer>
    </MuiThemeProvider>

  );

};

export default CustomerProofs;
