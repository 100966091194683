// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import {TableContainer, Grid, Paper, Table, makeStyles} from '@material-ui/core';
import React from 'react';
import {GQL_DECORATION_PAGINATION} from '../../queries/decorations';
import {colors} from '../../ui/theme';
import {HoopsQuery, HoopsQueryContext, HoopsQueryEmptyState, HoopsQueryFilterDropMenu, HoopsQueryFilterVendor, HoopsQueryPagination, HoopsQueryTableBody, HoopsQueryTableHeader, HoopsQueryFilterSearch, HoopsQueryFilterChips} from '../HoopsQuery';
import NameWithInitials from '../NameWithInitials';
import FormattedCurrency from '../reporting/FormattedCurrency';
import DecorationMenuCell from './decorationMenuCell';
import {hoopsQueryStyles} from '../../ui/theme/hoops';

const useStyles = makeStyles((theme) => ({...hoopsQueryStyles,}));

const columns = [{
  label: 'Name',
  field: 'name',
  render: (rowData) => <NameWithInitials name={rowData.name} color={colors.orange} />,
  sortable: true,
  sortKey: 'NAME',
  cellStyle: {maxWidth: '300px'},
  disableClick: false
}, {
  label: 'Category',
  field: 'category',
  sortable: true,
  sortKey: 'CATEGORY',
  disableClick: false
}, {
  label: 'Vendor',
  field: 'vendor.name',
  sortable: false,
  sortKey: 'VENDOR',
  disableClick: false
}, {
  label: 'Setup Cost',
  field: 'setupPrice',
  render: (rowData) => <FormattedCurrency justifyContent='flex-start'>{rowData.setupPrice}</FormattedCurrency>,
  sortable: true,
  sortKey: 'SETUPPRICE',
  disableClick: false
},
{
  label: 'Actions',
  render: (rowData) => <DecorationMenuCell rowData={rowData} />,
}];

const filterListOne = [{
  key: 'vendorId',
  type: 'array',
  options: [],
  label: 'Vendor',
  component: (props) => <HoopsQueryFilterVendor {...props} />
}];

export const DecorationManyTable = () => {
  const classes = useStyles();
  return (
    <HoopsQuery refetchStateId='decoration' queryStateId='decorationMany' columns={columns} gqlQuery={GQL_DECORATION_PAGINATION} initialSort='NAME_ASC' resultField='decorationPagination' initialFilter={{}}>
      <HoopsQueryContext.Consumer>
        {({items, sort, setSort, filter, setFilter, paging, setPaging, chips, setChips, hoopsQueryColumns, setHoopsQueryColumns}) => (<>
            <Grid container direction='row' justifyContent='space-between' alignItems='flex-start'>
              <Grid item>
                <HoopsQueryFilterDropMenu
                  label={'Vendor'}
                  filter={filter}
                  filterComponents={filterListOne}
                  onFilterChange={setFilter}
                  chips={chips}
                  onChipsChange={setChips}
                />
              </Grid>
              <Grid item>
                <HoopsQueryFilterSearch filter={filter} onChange={setFilter} chips={chips} onChipsChange={setChips} />
              </Grid>

            </Grid>
            <HoopsQueryFilterChips filter={filter} onFilterChange={setFilter} chips={chips} onChipsChange={setChips} />
            <TableContainer component={Paper}>
              <Table className={classes.table}>
                <HoopsQueryTableHeader hasCheckBox={false} columns={hoopsQueryColumns} onSort={setSort} sortString={sort} />
                <HoopsQueryTableBody rowsPerPage={paging.perPage} rows={items} columns={hoopsQueryColumns} emptyStateComponent={() => <HoopsQueryEmptyState filter={filter} columns={hoopsQueryColumns} field={'Decoration'} rowsPerPage={paging.perPage} onClearFilter={setFilter} onChipsChange={setChips} onAddItem={() => console.log('Open a modal')} />} />
              </Table>
            </TableContainer>
            <HoopsQueryPagination paging={paging} onPaging={setPaging} />
          </>)}
      </HoopsQueryContext.Consumer>
    </HoopsQuery>
  );
};

export default DecorationManyTable;
