// TODO: fix eslint disable
/* eslint-disable no-shadow */

import React, {forwardRef, useCallback} from 'react';
import {List, ListItem, ListItemButton} from '@mui/material';
import {cloneDeep} from 'lodash';
import {StatusListEditor} from './StatusListEditor';
import {Status, Statuses} from './StatusPicker';
import {immutablySwapItems} from '../../../utils/ImmutablySwapItems';
import classNames from 'classnames';
import {StatusLabel} from '../../../componentsLib/Basic';

interface StatusListProps {
  onStatusesChange: (statuses: Statuses | ((statuses: Statuses) => Statuses)) => void;
  statuses: Statuses;
  onSelect?: (status: Status)=>void;
  editing: boolean;
  selected?: string;
}

export const StatusList = forwardRef<HTMLUListElement, StatusListProps>(({
                                                                           selected,
                                                                                   onSelect,
                                                                                   statuses,
                                                                                   editing,
                                                                                   onStatusesChange
                                                                                 }, ref) => {
  const move = useCallback((dragIndex: number, hoverIndex: number) => {
    onStatusesChange((prevCards: Status[]) => immutablySwapItems(prevCards, dragIndex, hoverIndex));
  }, [onStatusesChange]);
  return <List ref={ref} className={'status-list'} >
    {statuses.map((statusState, index) => {
      const onChange = (statusState: Status) => {
        const clone = cloneDeep(statuses);
        clone[index] = statusState;
        onStatusesChange(clone);
      };
      const onDelete = () => {
        const clone = cloneDeep(statuses);
        clone.splice(index, 1);
        onStatusesChange(clone);
      };

      const {statusLabel, color} = statusState;
      return <ListItem disablePadding key={`${index}`} className={classNames(selected && 'item-list-selected')}>
        {!editing && <ListItemButton onClick={() => onSelect && onSelect(statusState)}>
          <StatusLabel color={color} >
            {statusLabel}
          </StatusLabel>
        </ListItemButton>}
        {editing &&
        <StatusListEditor statusState={statusState} onChange={onChange} onDelete={onDelete} index={index} move={move}/>}
      </ListItem>;
    })}

  </List>;
});

