import React, {useMemo, useState} from 'react';
import HideIcon from '@mui/icons-material/VisibilityOff';
import ShowIcon from '@mui/icons-material/Visibility';
import {TextInput} from '.';
import {HoopsPropTypes} from '../utils';

export function PasswordInput({className, allowShowPassword, name, ...props}) {
  const [showPassword, setShowPassword] = useState(false);

  const hidePasswordButton = useMemo(() => {
    if (!allowShowPassword) {
      return undefined;
    } else {
      return <HideIcon onClick={() => setShowPassword(false)}/>;
    }
  }, [allowShowPassword]);

  const showPasswordButton = useMemo(() => {
    if (!allowShowPassword) {
      return undefined;
    } else {
      return <ShowIcon onClick={() => setShowPassword(true)}/>;
    }
  }, [allowShowPassword]);

  return (
    <TextInput
      className={className}
      name={name}
      type={showPassword ? 'text' : 'password'}
      suffix={showPassword ? hidePasswordButton : showPasswordButton}
      {...props}
    />);
}

PasswordInput.propTypes = {
  className: HoopsPropTypes.className,
  allowShowPassword: HoopsPropTypes.bool,
  autoComplete: HoopsPropTypes.string,
  label: HoopsPropTypes.string,
  name: HoopsPropTypes.string,
  trim: HoopsPropTypes.bool,
  value: HoopsPropTypes.stringOrNumber,
  onChange: HoopsPropTypes.func,
};
