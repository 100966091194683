// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {Button, Divider, Grid, Link, makeStyles, MuiThemeProvider, Typography} from '@material-ui/core';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import React from 'react';
import {HoopsTextField} from '../../componentsOld/HoopsTextField';
import green from '../../ui/theme/green';
import theme from '../../ui/theme/index';

const useStyles = makeStyles((theme) => ({
    header: {
        background: theme.colors.grey.ultraLight,
        padding: theme.spacing(3),
        overflowX: 'hidden'
    },
    content: {
        padding: theme.spacing(3),
        overflowX: 'hidden'
    },
    link: {
        display: 'inline-flex',
        lineHeight: '24px'
    },
}));

export const ModalHeader = ({
    title = '',
    helpArticleUrl = '',
    onClose = () => null,
    onSave = () => null,
    formValues = {}
}) => {
    const classes = useStyles();

    return (

        <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='flex-end'
            className={classes.header}
            spacing={3}
        >
            <Grid item >
                <Typography variant='h1'>{title}</Typography>
            </Grid>
            <Grid item>

                <Link
                    href={helpArticleUrl}
                    rel='noopener'
                    target='_blank'
                    className={classes.link}
                    underline='none'
                >
                    <BookmarkIcon />&nbsp;Learn About Default Markups
                </Link>

            </Grid>
            <Grid item>
                <Grid container spacing={2}>
                    <Grid item>
                        <Button
                            onClick={onClose}
                            variant='outlined'
                            color='primary'
                            style={{background: theme.colors.white}}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        <MuiThemeProvider theme={green}>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={onSave}
                            >
                                Save
                            </Button>
                        </MuiThemeProvider>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Typography variant='h5' gutterBottom>Markup Name</Typography>
                <HoopsTextField
                    name='name'
                    defaultValue={formValues.name}
                    fullWidth={false}
                />
            </Grid>
        </Grid>
    );
};
