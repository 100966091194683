import {get} from 'lodash';
import {toFloat} from '../../servicesOld/ItemBuilderServices';

export const reduceElement = (sum, v) => {
    const rate = toFloat(get(v, 'rate', 0));
    const quantity = toFloat(get(v, 'quantity', 0));
    return sum + (rate * quantity);
};

export function calculateItemSubtotal(item) {
    const variants = get(item, 'variants', []);
    const decorations = get(item, 'decorations', []);
    const additionalItems = get(item, 'additionalItems', []);

    let subTotal = 0;
    subTotal += variants.reduce(reduceElement, 0);
    subTotal += decorations.reduce(reduceElement, 0);
    subTotal += additionalItems.reduce(reduceElement, 0);
    return (subTotal);
}

