import PropTypes from 'prop-types';
import {featureFlagEnabled, featureFlags} from '../../utils/featureFlag';

export const FeatureFlags = featureFlags;

// Checks access and either show or hides children
export function FeatureFlag({allow, disallow, children}) {
  if (allow && disallow && (!featureFlagEnabled(allow) || featureFlagEnabled(disallow))) {
    return false;
  }

  if (allow && featureFlagEnabled(allow)) {
    return children;
  }

  if (disallow && !featureFlagEnabled(disallow)) {
    return children;
  }

  return false;
}

FeatureFlag.propTypes = {
  allow: PropTypes.object,
  disallow: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
};
