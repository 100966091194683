// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React from 'react';
import {Snackbar, SnackbarContent, IconButton, Icon, withStyles} from '@material-ui/core';
import {snackbarToggle} from '../../actions/action-types';
import {connect} from 'react-redux';
import green from '../../ui/theme/green';

const variantIcon = {
  success: 'check_circle',
  warning: 'warning',
  error: 'error',
  info: 'info'
};

const styles = (theme) => (
  {
    success: {backgroundColor: green.palette.primary.main},
    error: {backgroundColor: theme.palette.error.main},
    info: {backgroundColor: theme.palette.primary.main},
    warning: {backgroundColor: theme.colors.orange},
    icon: {fontSize: 20},
    iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing(1)
    },
    message: {
      display: 'flex',
      alignItems: 'center'
    }
  }
);

class SnackbarLauncher extends React.Component {

  handleClose = () => {
    const {dispatch} = this.props;
    const closeOptions = {snackMessage: '',};
    dispatch(snackbarToggle(closeOptions));
  };

  render() {
    const {snackOptions, snackbarOpen, classes} = this.props;
    const {snackMessage, variant} = snackOptions;

    return (
        <Snackbar
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                autoHideDuration={6000}
                open={snackbarOpen}
                onClose={this.handleClose}
        >
          <SnackbarContent
            className={classes[variant]}
            message={<span className={classes.message}>
              <Icon className={classes.iconVariant}>{variantIcon[variant]}</Icon>
              {snackMessage}
            </span>}
            action={[
              <IconButton key={'close'} color={'inherit'} onClick={this.handleClose}>
                <Icon>close</Icon>
              </IconButton>
            ]}/>
          </Snackbar>
    );
  }
}

function mapStateToProps(state) {
  return {
    snackOptions: state.snackbarReducer.snackOptions,
    snackbarOpen: state.snackbarReducer.snackbarOpen
  };
}

SnackbarLauncher = connect(mapStateToProps)(SnackbarLauncher);

SnackbarLauncher = withStyles(styles)(SnackbarLauncher);

export default SnackbarLauncher;
