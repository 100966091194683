import {Checkbox, Grid, makeStyles, TextField, Typography} from '@material-ui/core';
import {find} from 'lodash';
import React from 'react';
import {useFormContext} from 'react-hook-form';
import {useSelector} from 'react-redux';
import ControlledAutocomplete from './ControlledAutocomplete';

const useStyles = makeStyles((theme) => ({
    greyText: {color: theme.colors.grey.main,},
    selectClass: {minWidth: 184,},
    optionTextAny: {fontStyle: 'italic',},
}));

const AutomationTriggerConfigStatusChange = ({
    entityMapping = 'status',
    options = [],
    entityAutomationTypesArray = []
}) => {
    const classes = useStyles();
    const {control, watch, errors} = useFormContext();
    const companySelector = (state) => state.companyReducer.company;
    const company = useSelector(companySelector);
    const entityType = watch('entityType');

    const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

    if (entityType && entityMapping && company && company.statuses) {
        // sometimes the name of the entity is different from what is defined in the automation API
        const entityObj = find(entityAutomationTypesArray, {'entity': entityType});
        const statuses = company
            .statuses
            .filter((status) => status.entityType === entityObj.companyEntityType && status.entityMapping === entityMapping)[0]
            .statuses;

        options = statuses.map((status) => ({value: status.value, label: status.label}));
        options.unshift({value: '*', label: 'Anything'});
    }

    return (

        <Grid container direction='row' justifyContent='center' alignItems='flex-end' spacing={1}>
            <Grid item >
                <Typography variant='h5' className={classes.greyText}>If the status is changed to </Typography>
            </Grid>
            <Grid item >
                <ControlledAutocomplete
                    control={control}
                    name='status'
                    options={options}
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    renderOption={(option, {selected}) => (
                        <>
                            <Checkbox
                                color='primary'
                                style={{marginRight: 8}}
                                checked={selected}
                            />
                            <span className={option.label === 'Anything' && classes.optionTextAny}>{capitalize(option.label)}</span>
                        </>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} label='Select' helperText={errors.status && 'Status is required'} error={errors.status} />
                    )}
                    multiple={true}
                    disableCloseOnSelect={true}
                    defaultValue={[]}
                />
            </Grid>
        </Grid>

    );
};

export default AutomationTriggerConfigStatusChange;
