// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import {TableContainer, Paper, Table, makeStyles} from '@material-ui/core';
import React from 'react';
import {useSelector} from 'react-redux';
import {GQL_VENDORS_PAGINATION} from '../../queries/vendors';
import {colors} from '../../ui/theme';
import {HoopsQuery, HoopsQueryContext, HoopsQueryEmptyState, HoopsQueryPagination, HoopsQueryTableBody, HoopsQueryTableHeader} from '../HoopsQuery';
import NameWithInitials from '../NameWithInitials';
import VendorMenuCell from './vendorMenuCell';
import {hoopsQueryStyles} from '../../ui/theme/hoops';

const useStyles = makeStyles((theme) => ({...hoopsQueryStyles,}));

const ActionsCell = ({rowData, company}) => {
  if (!company) {
    return null;
  }

  // No actions if you aren't the owner
  return (company._id === rowData.companyId)
    ? <VendorMenuCell rowData={rowData} />
    : null;
};

export const VendorManyTable = ({onVendorOpen = () => null,}) => {
  const companySelector = (state) => state.companyReducer.company;
  const company = useSelector(companySelector);
  const classes = useStyles();
  const columns = [{
    label: 'Name',
    field: 'name',
    sortable: true,
    sortKey: 'NAME',
    disableClick: false,
    render: (rowData) => <NameWithInitials name={rowData.name} color={colors.purple} to={`/settings/vendors/${rowData._id}`} />
  }, {
    label: 'Email',
    field: 'email',
    sortable: true,
    disableClick: false,
    sortKey: 'EMAIL',
  }, {
    label: 'Actions',
    render: (rowData) => <ActionsCell rowData={rowData} company={company} />
  }];

  return (
    <HoopsQuery refetchStateId='vendor' queryStateId='vendorMany' gqlQuery={GQL_VENDORS_PAGINATION} resultField='vendorPagination' initialFilter={{}} initialSort={'NAME_ASC'}>
      <HoopsQueryContext.Consumer>
        {({items, sort, setSort, filter, setFilter, paging, setChips, setPaging}) => (<>
            <TableContainer component={Paper}>
              <Table className={classes.table}>
                <HoopsQueryTableHeader hasCheckBox={false} columns={columns} onSort={setSort} sortString={sort} />
                <HoopsQueryTableBody rowsPerPage={paging.perPage} rows={items} columns={columns} emptyStateComponent={() => <HoopsQueryEmptyState filter={filter} columns={columns} rowsPerPage={paging.perPage} onClearFilter={setFilter} onChipsChange={setChips} onAddItem={onVendorOpen} />} />
              </Table>
            </TableContainer>
            <HoopsQueryPagination paging={paging} onPaging={setPaging} />
          </>)}
      </HoopsQueryContext.Consumer>
    </HoopsQuery>
  );
};

export default VendorManyTable;
