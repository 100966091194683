import * as numeral from 'numeral';

export const asFloat = (number) => {
  if (number == null) {
    return 0;
  }

  if (typeof number !== 'number') {
    number = parseFloat(String(number).replace(/,/g, ''));
  }

  if (Number.isNaN(number)) {
    return 0;
  }

  return number;
};

export const asNumber = asFloat;

export const digitsIn = (number) => Math.round(number).toString().length;
export const asRounded = (number, decimals) => Math.round(asFloat(number) * Math.pow(10, decimals)) / Math.pow(10, decimals);
export const asRoundedString = (number, minDecimals, maxDecimals = minDecimals) =>
  numeral(asRounded(number, maxDecimals)).format(`0.${'0'.repeat(minDecimals)}[${'0'.repeat(maxDecimals - minDecimals)}]`);

export const asCurrencyRounded = (number) => Math.round(asFloat(number) * 100) / 100;

export const asCurrencyString = (number) => asCurrencyRounded(number).toFixed(2);

export const asCurrencyRateRounded = (number) => Math.round(asFloat(number) * 1000000) / 1000000;

export const asCurrencyRateString = (number) => numeral(asCurrencyRateRounded(number)).format('0.00[0000]');

export const asCurrencyStringCommaSeparated = (number) => numeral(asCurrencyRounded(number)).format('0,0.00');

export const asCurrencyRateStringCommaSeparated = (number, decimals) => numeral(asCurrencyRateRounded(number)).format(`0,0.${'0'.repeat(decimals)}`);

export const asPercentageRounded = (number) => Math.round(asFloat(number) * 100) / 100;

export const asPercentageString = (number) => numeral(asPercentageRounded(number)).format('0[.]00');

export function marginFromMarkup(markup) {
  markup = markup / 100;
  return 100 * markup / (1 + markup);
}

export function markupFromMargin(margin) {
  margin = Math.min(Math.max(margin, 0), 99) / 100;
  return 100 * margin / (1 - margin);
}
