// TODO: fix eslint disable
/* eslint-disable no-class-assign, no-shadow */

import React, {Fragment} from 'react';
import Paper from '@material-ui/core/Paper';
import {Typography, withStyles} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {connect} from 'react-redux';
import {getPaymentMethods, getUpcomingInvoice} from '../../actions/billing';
import {getCompany} from '../../actions/action-types/company';
import AddCardDialog from './AddCardDialog';
import PaymentMethodCard from './PaymentMethodCard';
import * as moment from 'moment';

const styles = (theme) => ({
    paper: {padding: theme.spacing(2)},
    firstRow: {marginTop: theme.spacing(3),},
    cardContainer: {backgroundColor: '#f8f8f8'},
    defaultCard: {
      borderLeftStyle: 'solid',
      borderLeftWidth: '4px',
      borderLeftColor: theme.palette.primary.main
    }
  });

class PaymentMethods extends React.Component {

  UNSAFE_componentWillMount() {
    const {getPaymentMethods, getUpcomingInvoice, getCompany} = this.props;
    getUpcomingInvoice();
    getPaymentMethods();
    getCompany();
  }

  render() {
    const {classes, companyReducer: {company}, billingReducer: {paymentMethods, upcomingInvoice}} = this.props;

    const nextPayment = moment(upcomingInvoice.created, 'X').format('DD MMM YYYY');
    return (
      <Fragment>
        <Grid container>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid container justifyContent={'space-between'}>
                <Grid item>
                  <Typography variant={'h4'}>Payment Methods</Typography>
                </Grid>
                <Grid item>
                  <AddCardDialog/>
                </Grid>
              </Grid>
              <Grid container justifyContent={'center'} spacing={2} className={classes.firstRow}>
                {paymentMethods.map((method, idx) => (
                    <Grid item xs={6} key={idx}>
                      <PaymentMethodCard method={method}/>
                    </Grid>
                  ))}

                {!paymentMethods.length &&
                  <Grid item>
                    <Typography variant={'body1'} align={'center'}>{company.name} {'doesn\'t'} have any stored payment methods.</Typography>
                    {upcomingInvoice.created && (<Typography variant={'body2'} color={'textSecondary'} align={'center'}>
                      Make sure you add a credit card to your account before your next payment is due on
                      {nextPayment}
                    </Typography>)}
                  </Grid>
                }
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Fragment>
    );
  }

}

function mapStateToProps(state) {
  return {
    companyReducer: state.companyReducer,
    billingReducer: state.billingReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getPaymentMethods: (options) => dispatch(getPaymentMethods(options)),
    getUpcomingInvoice: (options) => dispatch(getUpcomingInvoice(options)),
    getCompany: () => dispatch(getCompany())
  };
}

PaymentMethods = withStyles(styles)(PaymentMethods);
PaymentMethods = connect(mapStateToProps, mapDispatchToProps)(PaymentMethods);
export default PaymentMethods;
