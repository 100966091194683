// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {useQuery} from '@apollo/client';
import {Button, Grid, Link, makeStyles, Paper, TextField, Typography} from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import {Skeleton} from '@material-ui/lab';
import {get} from 'lodash';
import moment from 'moment';
import React from 'react';
import {useParams} from 'react-router';
import defaultLogo from '../../../../assets/images/default-logo.png';
import {GQL_GET_PO} from '../../../../queries/purchaseOrders';
import theme from '../../../../ui/theme';
import LogoMini from '../../../LogoMini';
import Attachments from '../Attachments';
import AdditionalItemsTable from './AdditionalItemsTable';
import DecorationsTable from './DecorationsTable';
import Totals from './Totals';
import VariantsTable from './VariantsTable';
import VendorInformationCard from './VendorInformationCard';

const useStyles = makeStyles((theme) => ({
    flexContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between'
    },
    paperPadding: {
        padding: theme.spacing(3),
        height: '100%'
    },
    tableTopBuffer: {
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('md')]: {marginTop: theme.spacing(0),},
    },
    topBufferLarger: {marginTop: theme.spacing(7),},
    content: {padding: theme.spacing(2),},
    companyLogo: {
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        height: '75px',
        width: '75px',
        backgroundRepeat: 'no-repeat',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2)

    },
    redText: {color: theme.colors.red},
    itemContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
        border: '1px solid',
        borderColor: theme.colors.grey.light
    },
    buttonContainer: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
        },
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
        }
    },
    stickyFooter: {
        bottom: 0,
        left: 0,
        display: 'flex',
        position: 'fixed',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 42,
        background: theme.colors.white,
        zIndex: 10,
        boxShadow: '5px 10px 30px 10px #EFEFEF',
    },
    hoopsLink: {
        color: theme.colors.grey.main,
        padding: '5px 15px 0 15px',
        borderRadius: 20,
        '&:hover': {background: theme.colors.grey.ultraLight},
    }
}));

const PreviewPurchaseOrderContent = () => {
    const classes = useStyles();
    const {orderId} = useParams();

    const {loading, data} = useQuery(GQL_GET_PO, {variables: {_id: orderId},});

    const purchaseOrder = data && data.purchaseOrder;
    const company = data && data.purchaseOrder && data.purchaseOrder.company;
    const vendor = data && data.purchaseOrder && data.purchaseOrder.vendor;
    const deadline = moment(get(purchaseOrder, 'deadlineAt', ''));
    const pdfLink = `${process.env.REACT_APP_BASE_URL}/purchase-orders/${orderId}/pdf`;

    return (
        <>
            <Grid container justifyContent={'center'} spacing={3} className={classes.content}>
              {company?.companyTradingEntities?.[0] &&
                <Grid
                  item
                  xs={12}
                  id='companyLogo'
                  className={classes.companyLogo}
                  style={{backgroundImage: company.companyTradingEntities[0].logo ? `url(${company.companyTradingEntities[0].logo})` : `url(${defaultLogo})`,}}
                ></Grid>
              }
                <Grid item md={4} xs={12}>
                    <VendorInformationCard
                        classes={classes}
                        loading={loading}
                        heading='Vendor'
                        subHeading={get(vendor, 'name')}
                        information={{
                            phone: get(vendor, 'contacts[0].phone', '-'),
                            email: get(vendor, 'email', '-'),
                            address: get(vendor, 'addresses[0].formattedAddress', '-'),
                        }}
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <VendorInformationCard
                        classes={classes}
                        loading={loading}
                        heading='Bill To'
                        subHeading={company?.companyTradingEntities?.[0]?.name ?? '-'}
                        information={{
                          phone: company?.companyTradingEntities?.[0]?.phone ?? '',
                          email: company?.companyTradingEntities?.[0]?.email ?? '',
                          address: company?.billingAddress?.formattedAddress ?? '',
                        }}
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <VendorInformationCard
                        classes={classes}
                        loading={loading}
                        heading='Ship To'
                        subHeading={get(purchaseOrder, 'shipTo.name', '-')}
                        information={{
                            attention: get(purchaseOrder, 'shipTo.attention'),
                            phone: get(purchaseOrder, 'shipTo.phone', '-'),
                            email: get(purchaseOrder, 'shipTo.email', '-'),
                            address: get(purchaseOrder, 'shipTo.address.formattedAddress', '-'),
                        }}
                    />
                </Grid>

                <Grid item md={5} xs={12}>
                    <Typography variant='h4'>Purchase Order</Typography>
                    <Typography variant='body1'>PO #: {get(purchaseOrder, 'number', '')}</Typography>
                    <Typography variant='body1'>Reference Number: {get(purchaseOrder, 'customReference', '')}</Typography>
                    {get(purchaseOrder, 'deadlineAt') && <Typography variant='body1' className={classes.redText}><strong>Deadline: {moment(deadline).format('D MMM YYYY')}</strong></Typography>}
                </Grid>

                <Grid item md={7} xs={12} className={classes.buttonContainer}>
                    <Button
                        color='primary'
                        startIcon={<DescriptionIcon />}
                        href={pdfLink}
                        target='_blank'
                    >
                        Download PDF
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    {loading ? <Skeleton variant='rect' width='100%' height={400} /> :
                        <Paper className={classes.paperPadding}>
                            <Typography variant='h5'>Items</Typography>

                            {purchaseOrder && purchaseOrder.items ? purchaseOrder.items.map((purchaseOrderItem, idx) => {
                                const files = get(purchaseOrderItem, 'files');
                                return (
                                    <div key={idx} className={classes.itemContainer}>
                                        {purchaseOrderItem.variants && purchaseOrderItem.variants.length ?
                                            <VariantsTable
                                                item={purchaseOrderItem}
                                                description={get(purchaseOrderItem, 'description')}
                                                variants={get(purchaseOrderItem, 'variants', [])}
                                                company={get(purchaseOrderItem, 'company', [])}
                                            /> : null}
                                        {purchaseOrderItem.decorations && purchaseOrderItem.decorations.length ?
                                            <DecorationsTable
                                                item={purchaseOrderItem}
                                                itemId={get(purchaseOrderItem, '_id')}
                                                decorations={get(purchaseOrderItem, 'decorations', [])}
                                                company={get(purchaseOrderItem, 'company', [])}
                                            /> : null}
                                        {purchaseOrderItem.additionalItems && purchaseOrderItem.additionalItems.length ?
                                            <AdditionalItemsTable
                                                item={purchaseOrderItem}
                                                itemId={get(purchaseOrderItem, '_id')}
                                                additionalItems={get(purchaseOrderItem, 'additionalItems', [])}
                                                company={get(purchaseOrderItem, 'company', [])}
                                            /> : null}
                                        {files && Boolean(files.length) && <Attachments files={files} />}
                                    </div>
                                );
                            }) : null}
                            <Grid container spacing={3} alignContent='flex-start'>
                                <Grid item md={7} xs={12}>
                                    <Typography variant='body1' style={{fontWeight: 500}}>Notes</Typography>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={4}
                                        variant='outlined'
                                        defaultValue={get(purchaseOrder, 'notes', [])}
                                        disabled
                                    />
                                </Grid>
                                <Grid item md={5} xs={12}>
                                    <Totals purchaseOrder={purchaseOrder} company={company} />
                                </Grid>
                            </Grid>
                        </Paper>
                    }
                </Grid>
            </Grid>
            <div className={classes.stickyFooter}>
                <Link href='https://hoopscrm.com/' color='initial' underline='none' target='_blank' variant='caption' className={classes.hoopsLink}>
                    <LogoMini style={{marginRight: theme.spacing(1), width: 24, paddingTop: 2, float: 'left'}} />
                    We run on Hoops
                </Link>
            </div>

        </>
    );
};

export default PreviewPurchaseOrderContent;
