import Grid from '@material-ui/core/Grid';
import React from 'react';
import {ReactComponent as UndrawImage} from '../../../../assets/images/undraw_online_shopping_ga73.svg';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';

function Step6Complete() {
  return (
    <Grid item container justifyContent={'center'}>
      <Grid item container xs={8} spacing={2} justifyContent={'center'}>
        <Grid item container spacing={2} xs={8} direction={'column'}>
          <Grid item xs={true}>
            <Typography variant={'h6'}>Ready to Import your Catalog</Typography>
          </Grid>
          <Grid item xs={true}>
            <Typography>
              You&apos;ve successfully mapped your CSV fields to generate products correctly in your Hoops Catalog.
            </Typography>
          </Grid>

          <Grid item xs={true}>
            <Typography>
              The import process will first create your products and their variants before mapping your images
              against each product and variant (if applicable).
            </Typography>
          </Grid>
          <Grid item xs={true}>
            <Alert color={'success'}>
              <Typography>
                Importing large numbers of products and linking images can take some time.
                You can keep using Hoops as normal and we’ll notify you when your import is done.
              </Typography>
            </Alert>
          </Grid>
        </Grid>
        <Grid item container justifyContent={'center'} xs={4}>
          <UndrawImage style={{margin: 'auto', width: '100%', height: 'auto'}}/>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Step6Complete;
