// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import {Grid, Link as MuiLink, makeStyles, Typography, Tooltip, Avatar} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import {get} from 'lodash';
import moment from 'moment';
import React from 'react';
import {Link} from 'react-router-dom';
import {kanban} from '../../ui/theme/kanban';
import HoopsQueryTableCellDateTime from './HoopsQueryTableCellDateTime';
import {colors} from '../../ui/theme';
import {initials} from '../../utils';

const useStyles = makeStyles((theme) => (
    {...kanban,}
));

export const HoopsQueryKanbanCardTask = ({item = {}}) => {
    const classes = useStyles();
    const now = moment();
    const dueDate = get(item, 'dueAt');
    const daysToDueDate = now.diff(dueDate, 'days');

    // handle due date styling
    const dueDateClass = (param) => {
        if (param > -7 && param < 0) {return classes.orangeText;}
        if (param > 0) {return classes.redText;}
        return classes.greyText;
    };

    const Members = ({task}) => {
        const ToolTipTitle = ({users}) => (<>
                {users.map((user, idx) =>
                    <Typography key={idx}>{user.fullName}</Typography>
                )}
            </>);

        const members = task.members ? task.members : [];
        return (
            <Tooltip title={<ToolTipTitle users={members} />} arrow>
                <AvatarGroup max={4} style={{paddingLeft: 4}}>
                    {members.map((user) =>
                        <Avatar
                            key={user._id}
                            alt={user.fullName}
                            size='small'
                            style={{
                                backgroundColor: colors.green,
                                fontSize: 12,
                                width: 28,
                                height: 28,
                            }}
                        >{initials(user.fullName)}</Avatar>
                    )}
                </AvatarGroup>
            </Tooltip>
        );
    };

    return (
        <>
            <Grid container direction='row' justifyContent='space-between' alignItems='flex-start'>
                <Grid item xs={12} className={classes.itemTitle}>
                    <Typography variant='body1'>
                        <MuiLink component={Link} underline={'none'} color={'primary'} to={`/tasks/${item._id}/view`}>
                            {get(item, 'title')}
                        </MuiLink>
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.itemTextObject}>
                    <Typography variant='caption' display='block'>{get(item, 'description')}</Typography>
                </Grid>
                <Grid item xs={12} className={classes.itemTextObject}>
                    {item.jobs && item.jobs.map((job, jIdx) => (
                            <Typography variant='caption' display='block' className={classes.greyText} key={jIdx}>
                                Job:
                                <MuiLink rowdata-intercom-target={'job-link'} component={Link} underline={'none'} color={'primary'} style={{paddingLeft: '8px'}}
                                    to={`/jobs/${job._id}')}/details`}>
                                    {`${job.number} - ${job.customer.name}`}
                                </MuiLink>
                            </Typography>
                        ))
                    }
                </Grid>
                <Grid item xs={12} className={classes.itemTextObject}>
                    {item.quotes.map((quote, qIdx) => (
                            <Typography variant='caption' display='block' className={classes.greyText} key={qIdx}>
                                Quote:
                                <MuiLink rowdata-intercom-target={'job-link'} component={Link} underline={'none'} color={'primary'} style={{paddingLeft: '8px'}}
                                    to={`/quotes/${quote._id}/view`}>
                                    {`${quote.number} - ${quote.customer.name}`}
                                </MuiLink>
                            </Typography>
                        ))
                    }
                </Grid>
                <Grid item xs={12} className={classes.itemTextObject}>
                    {/* Show chip if we're past the due date */}
                    {/* List the dueDate if it is set */}
                    {dueDate &&
                        <>
                            <AccessTimeIcon style={{fontSize: 13, top: 2, position: 'relative', marginRight: 4}} className={dueDateClass(daysToDueDate)} />
                            <HoopsQueryTableCellDateTime value={dueDate} showTime={false} variant='caption' className={dueDateClass(daysToDueDate)} />
                        </>
                    }
                </Grid>
                <Grid item xs={12}>
                    <Members task={item} />
                </Grid>
            </Grid>
        </>
    );
};

export default HoopsQueryKanbanCardTask;
