import {Button, Grid, makeStyles, MuiThemeProvider, Paper, TextField} from '@material-ui/core';
import React, {useState} from 'react';
import green from '../../../ui/theme/green';

const useStyles = makeStyles((theme) => ({
    commentsPanel: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(2)
    },
    commentText: {fontSize: 14},
    secondaryButton: {
        color: theme.colors.grey.main,
        marginLeft: theme.spacing(2)
    },
    hideComments: {display: 'none'}
}));

const PreviewQuoteCommentField = ({
    showComments = null,
    saveComment = () => null,
    handleShowComments = () => null,
    quoteId = null
}) => {
    const classes = useStyles();
    const [commentValue, setCommentValue] = useState('');

    return (
        <Grid
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='flex-start'
            xs={12}
            component={Paper}
            className={showComments ? classes.commentsPanel : classes.hideComments}
        >
            <Grid item xs={12} style={{marginBottom: '16px'}}>
                <TextField value={commentValue} onChange={(e) => setCommentValue(e.target.value)} fullWidth multiline variant='outlined' InputProps={{classes: {input: classes.commentText}}} />
            </Grid>
            <Grid item>
                <MuiThemeProvider theme={green}>
                    <Button color={'primary'} variant={'contained'} onClick={() => {saveComment(quoteId, commentValue); setCommentValue('');}}>Save</Button>
                </MuiThemeProvider>
                <Button className={classes.secondaryButton} onClick={() => handleShowComments(quoteId)}>Cancel</Button>
            </Grid>
        </Grid>

    );
};

export default PreviewQuoteCommentField;
