import {Grid} from '@material-ui/core';
import React, {Fragment} from 'react';
import EntityListHero from '../EntityList/EntityListHero';
import InviteUserDialog from '../InviteUser/InviteUserDialog';
import UserManyTable from './UserManyTable';
class UserManyViewPage extends React.Component {

  componentDidMount() {
    document.title = 'Users | Hoops';
  }

  render() {
    return (
      <Fragment>
        <Grid
          container
          
        >
          <EntityListHero
            title='Users'
            subtitle='Invite your entire team to use Hoops &amp; streamline your workflow.'
            helpArticleUrl='http://help.hoopscrm.com/en/articles/4636482-invite-your-team'
          >
            <InviteUserDialog />
          </EntityListHero>

          <Grid item xs={12}>
            <UserManyTable />
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default UserManyViewPage;
