import {pick, remove} from 'lodash';
import {toFloat} from '../../componentsOld/shared/numberUtils';
import {client} from '../../servicesOld/GraphQLService';
import {
  GQL_CREATE_MARKUP, GQL_DELETE_MARKUP, GQL_GET_ASSIGNED_MARKUPS, GQL_GET_MARKUP,
  GQL_GET_MARKUPS, GQL_GET_MARKUP_TREE,
  GQL_UPDATE_DEFAULT_MARKUP_ASSIGNMENT, GQL_UPDATE_MARKUP
} from './markupsQueries';

export default class MarkupService {

  static _parseMarkupInput(input) {
    const keys = ['name', 'markupRanges'];
    const pruned = pick(input, keys);

    pruned.markupRanges.forEach((item) => {
      item.lowerPrice = toFloat(item.lowerPrice);
      item.upperPrice = toFloat(item.upperPrice);
      item.quantity = toFloat(item.quantity);
      item.percentage = toFloat(item.percentage);
    });

    return pruned;
  }

  static _parseDefaultMarkupAssignementInput(input) {

    return input.map((item) => ({
      markupId: item.markupId,
      vendorId: item.vendorId,
      brand: item.brand,
      category: item.category,
      subCategory: item.subCategory,
      type: item.type.toUpperCase(),
    }));

  }

  static async getMarkup(id) {
    return client
      .query({
        query: GQL_GET_MARKUP,
        variables: {_id: id,},
      })
      .then(({data: {markupOne}}) => markupOne);
  }

  static async getMarkupCache(id) {
    return client
      .query({
        query: GQL_GET_MARKUP,
        fetchPolicy: 'cache-first',
        variables: {_id: id,},
      })
      .then(({data: {markupOne}}) => markupOne);
  }

  static async getMarkups({...variables}) {
    return client
      .query({
        query: GQL_GET_MARKUPS,
        fetchPolicy: 'network-only',
        variables: {...variables,},
      })
      .then(({data: {markupMany}}) => markupMany);
  }

  static async createMarkup(markupData) {
    return client
      .mutate({
        mutation: GQL_CREATE_MARKUP,
        variables: {record: MarkupService._parseMarkupInput(markupData),},
      })
      .then(({data: {markupCreateOne}}) => markupCreateOne);
  }

  static async updateMarkup(_id, data) {
    return client
      .mutate({
        mutation: GQL_UPDATE_MARKUP,
        variables: {
          _id,
          record: MarkupService._parseMarkupInput(data),
        },
      })
      .then(({data: {markupUpdateById}}) => markupUpdateById);
  }

  static async deleteMarkup(_id) {
    return client
      .mutate({
        mutation: GQL_DELETE_MARKUP,
        variables: {_id,},
      })
      .then(({data: {markupRemoveById}}) => {
        const removedMarkupId = markupRemoveById.recordId;
        MarkupService.getDefaultMarkups().then((data) => {
          const udpatedDefaultMarkups = remove(data, (dm) => (dm.markupId !== removedMarkupId));
          MarkupService.updateDefaultAMarkupAssignment(udpatedDefaultMarkups);
        });

        return (markupRemoveById);
      });
  }

  static async getMarkupTree() {
    return client
      .query({query: GQL_GET_MARKUP_TREE,})
      .then(({data}) => data);
  }

  static async getDefaultMarkups() {
    return client
      .query({query: GQL_GET_ASSIGNED_MARKUPS,})
      .then(({data: {defaultMarkupAssignmentFind}}) => defaultMarkupAssignmentFind);
  }

  static async updateDefaultAMarkupAssignment(data) {
    return client
      .mutate({
        mutation: GQL_UPDATE_DEFAULT_MARKUP_ASSIGNMENT,
        variables: {data: MarkupService._parseDefaultMarkupAssignementInput(data)}
      });
  }

}

