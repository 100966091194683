import {useMutation} from '@apollo/client';
import {AppBar, Button, Dialog, Divider, Grid, Link, makeStyles, MuiThemeProvider, Toolbar, Typography} from '@material-ui/core';
import {get} from 'lodash';
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import SuccessDialog from '../../componentsOld/shared/modals/SuccessDialog';
import MarkupService from '../../pages/markups/markupsService';
import {GQL_CREATE_CONFIGURED_PRODUCT} from '../../queries/configuredProducts';
import {parsePriceElementInput} from '../../servicesOld/QuoteService';
import theme from '../../ui/theme';
import {editEntity} from '../../ui/theme/editEntity';
import green from '../../ui/theme/green';
import ItemBuilderAdditionalItemTable from './additionalItem/ItemBuilderAdditionalItemTable';
import ItemBuilderAdjustmentLine from './additionalItem/ItemBuilderAdjustmentLine';
import ItemBuilderDecorationTable from './decoration/ItemBuilderDecorationTable';
import {createPriceElement, updatePriceElement} from './itemBuilderActions';
import {ItemBuilderContext} from './ItemBuilderContext';
import ItemBuilderPriceLogic from './ItemBuilderPriceLogic';
import ItemBuilderValueBar from './ItemBuilderValueBar';
import SaveConfiguredItemModal from './SaveConfiguredItemModal';
import {ConfirmDialog} from '../../componentsOld/modal/ConfirmDialog';
import ItemBuilderProductTable from './product/ItemBuilderProductTable';
import {calculatePriceElementBreakdown} from './itemBuilderUtils';

const useStyles = makeStyles((_theme) => ({
  ...editEntity,
  appBar: {
    padding: _theme.spacing(3),
    background: _theme.colors.grey.ultraLight
  },
  title: {flex: 1,},
  newSash: {
    color: 'white',
    textAlign: 'center',
    fontSize: '10px',
    transform: 'rotate(-45deg)',
    position: 'absolute',
    borderBottom: '13px solid red',
    borderLeft: '13px solid transparent',
    borderRight: '13px solid transparent',
    width: 48,
    zIndex: 2,
    margin: '4px 0 0 -13px',
    '& div': {position: 'inherit'}
  }
}));

export const ItemBuilderEditModal = () => {
  const classes = useStyles();
  const [valBarHeight, setValBarHeight] = useState(0);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [successDialogObject, setSuccessDialogObject] = useState({open: false});
  const currencySymbolSelector = (state) => get(state, 'authReducer.userData.company.currencySymbol');
  const currencySymbol = useSelector(currencySymbolSelector);
  const [isConfiguredProductModalOpen, setIsConfiguredProductModalOpen] = useState(false);
  const {item, open, setOpen, product, primaryProduct, priceElement, handleSavePriceElement, setProductDecorations,setAssignedDefaultMarkupData, isNotTouched, setIsNotTouched, setSexyPrice} = useContext(ItemBuilderContext);
  const dispatch = useDispatch();
  const appBar = useRef();

  // Adding hook forms
  const methods = useForm({mode: 'onChange'});
  const {handleSubmit, reset} = methods;

  useEffect(() => {
    reset(priceElement);
  }, [product, priceElement, reset]);

  const handleValBarHeight = useCallback(() => {
    appBar && appBar.current && setValBarHeight(appBar.current.offsetHeight);
  }, []);

  useEffect(() => {
    MarkupService.getDefaultMarkups().then((data) =>
      setAssignedDefaultMarkupData(data)
    );
    window.addEventListener('resize', handleValBarHeight);
    return () => window.removeEventListener('resize', handleValBarHeight);

  }, [setAssignedDefaultMarkupData, appBar, handleValBarHeight]);

  useEffect(() => {
    handleValBarHeight();
  }, [appBar, handleValBarHeight]);

  // Handle Pre-Built Items
  const [saveConfiguredProduct, {loading}] = useMutation(GQL_CREATE_CONFIGURED_PRODUCT, {
    onCompleted: (result) => {
      if (result) {
        setIsConfiguredProductModalOpen(false);
        setSuccessDialogObject({
          open: true,
          title: 'Pre-Built Item Saved',
          bodyHeader: '',
          body:
            <span>
              <p>This configuration has been saved and can now be added in 1 click to future Quotes &amp; Jobs. </p>
              <p>To view all Pre-Built Items that have been saved, go to Catalog &gt; Pre-Built Items.</p>
              <p><Link color='primary' underline='none' target='_blank' href='http://help.hoopscrm.com/en/articles/6061405-configured-products'>Learn More</Link></p>
            </span>
        });
      }
    },
  });

  const handleSaveConfiguredProduct = useCallback( ({code, name}, formValues) => {
    const _priceElement = parsePriceElementInput(formValues);
    const calculatedValues = calculatePriceElementBreakdown(formValues);

    const tax = !item.tax || !item.tax._id ? null : {
      _id: item.tax && item.tax._id,
      name: item.tax && item.tax.name,
      type: item.tax && item.tax.type,
    };

    const description = primaryProduct?.description ? `#DYNAMIC# ${primaryProduct?.title} - ${primaryProduct?.description}` : `#DYNAMIC# ${primaryProduct?.title ?? priceElement?.variations[0]?.productName ?? ''}`;

    const configuredProduct = {
      name: name,
      code: code,
      imageUrl: item.imageUrl ?? primaryProduct?.primaryImage?.url ?? primaryProduct?.images[0]?.url ?? null,
      description: item?.description ?? description,
      rate: calculatedValues.sellPricePerUnit,
      quantity: calculatedValues.productQuantity,
      productId: item.product && item.product._id,
      _Id: _priceElement._id,
      priceElement: _priceElement,
      tax: tax
    };

    const priceLogic = item.priceLogic
      ? {
        priceLogic: item.priceLogic,
        priceLogicValue: item.priceLogicValue,
      }
      : null;

    saveConfiguredProduct({variables: {configuredProduct: {...configuredProduct, ...priceLogic}}});

  }, [item, saveConfiguredProduct, priceElement, primaryProduct]);

  const handleConfirmClose = useCallback((confirmed) => {
    if (confirmed) {
      setOpen(false);
      setIsNotTouched(true);
      setProductDecorations([]);
    }
    setSexyPrice(null);
    setShowCancelConfirmation(false);
  }, [setIsNotTouched, setOpen, setProductDecorations, setSexyPrice]);

  const handleCancel = useCallback(() => {
    if (isNotTouched) {
      handleConfirmClose(true);
    } else {
      setShowCancelConfirmation(true);
    }
  }, [handleConfirmClose, isNotTouched]);

  const onSubmit = useCallback((value) => {
    if (priceElement._id) {
      dispatch(updatePriceElement(priceElement._id, value, (err, result) => {
        handleConfirmClose(true);
        handleSavePriceElement(result);
      }));
      return;
    }
    dispatch(createPriceElement(value, (err, result) => {
      handleSavePriceElement(result);
      handleConfirmClose(true);
    }));
  }, [dispatch, handleSavePriceElement, priceElement, handleConfirmClose]);

  return (
    <Dialog fullScreen open={open ? open : false} onClose={handleCancel} style={{marginTop: valBarHeight}}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...methods} >

          <AppBar position={'relative'} className={classes.appBar} color='transparent' elevation={1} ref={appBar}>
            <Toolbar disableGutters>

              <Grid container justifyContent={'space-between'} spacing={1}>
                <Grid item>
                  <Typography variant='h1' className={classes.title}>
                    Item Builder
                  </Typography>
                </Grid>

                <Grid item>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Button
                        variant='outlined'
                        className={classes.whiteOutlinedButton}
                        disableElevation
                        onClick={handleCancel}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    {!item.configuredProductId &&
                      <Grid item>

                        <Button
                          variant='contained'
                          color='primary'

                          onClick={() => setIsConfiguredProductModalOpen(true)}
                        >
                          Save Pre-built item
                        </Button>

                      </Grid>
                    }
                    <Grid item>
                      <MuiThemeProvider theme={green}>
                        <Button
                          type='submit'
                          variant='contained'
                          color='primary'
                          onClick={handleSubmit(onSubmit)}
                          disabled={isNotTouched}
                        >
                          Save
                        </Button>
                      </MuiThemeProvider>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={12}>
                  <Divider style={{marginTop: theme.spacing(3)}} />
                  <ItemBuilderValueBar currencySymbol={currencySymbol} />
                </Grid>
              </Grid>

            </Toolbar>
          </AppBar>

          {/* Context  */}
          <SaveConfiguredItemModal
            isLoading={loading}
            onSave={handleSaveConfiguredProduct}
            open={isConfiguredProductModalOpen}
            onClose={() => setIsConfiguredProductModalOpen(false)}
          />
          <SuccessDialog successDialogObject={successDialogObject} handleClose={() => setSuccessDialogObject({...successDialogObject, open: false})} />
          <ItemBuilderPriceLogic />
          <ItemBuilderProductTable priceElement={priceElement} currencySymbol={currencySymbol} />
          <ItemBuilderDecorationTable priceElement={priceElement} currencySymbol={currencySymbol} />
          <ItemBuilderAdditionalItemTable priceElement={priceElement} currencySymbol={currencySymbol} />
          <ItemBuilderAdjustmentLine priceElement={priceElement} currencySymbol={currencySymbol} />

        </FormProvider>

      </form>
      {showCancelConfirmation &&
        <ConfirmDialog
          title={'Cancel Editing?'}
          content={'Changes you have made so far will not be saved.'}
          cancelText={'Keep Editing'}
          okText={'Yes, Cancel'}
          onClose={handleConfirmClose} />}
    </Dialog>
  );
};

