import {Grid} from '@material-ui/core';
import React, {useState} from 'react';
import {OrgSettingsField} from './OrgSettingsField';
import SettingsPaper from './SettingsPaper';

export const CompanyRoyaltySettings = ({
  company,
  loading,
  saveSettings = () => null
}) => {
  const [editing, setEditing] = useState(false);
  const [companyUpdate, setCompanyUpdate] = useState(company);

  const toggleEdit = () => {
    setCompanyUpdate(company);
    setEditing((prev) => !prev);
  };

  const handleCompanyEdit = (params) => {
    setCompanyUpdate((prev) => ({...prev, royaltySettings: {...prev.royaltySettings, ...params}}));
  };

  const handleSave = async () => {
    saveSettings(companyUpdate);
    setEditing(false);
  };

  return (
    <SettingsPaper 
      title={'Royalty Settings'}
      subTitle={'These settings relate to the royalty report. These cannot be seen or managed by a franchisee type login, only the UFG Admin account has access to this.'}
      editing={editing}
      onEdit={toggleEdit}
      onSave={handleSave}
      onCancel={toggleEdit}
      loading={loading}
      gridContainerProps={{spacing: 3}}
    >
      <Grid item xs={12}>
        <OrgSettingsField
          onChange={handleCompanyEdit}
          company={companyUpdate?.royaltySettings}
          editing={editing}
          fieldName={'Royalty Cap'}
          fieldReference={'royaltyCap'}
          currencySymbol={company?.currencySymbol}
          type='number'
        />
      </Grid>
      <Grid item xs={12}>
        <OrgSettingsField
          onChange={handleCompanyEdit}
          company={companyUpdate?.royaltySettings}
          editing={editing}
          fieldName={'Royalty Percentage'}
          fieldReference={'royaltyPercentage'}
          percent
          type='number'
        />
      </Grid>
      <Grid item xs={12}>
        <OrgSettingsField
          onChange={handleCompanyEdit}
          company={companyUpdate?.royaltySettings}
          editing={editing}
          fieldName={'Marketing Fee'}
          fieldReference={'marketingFee'}
          percent
          type='number'
        />
      </Grid>
      <Grid item xs={12}>
        <OrgSettingsField
          onChange={handleCompanyEdit}
          company={companyUpdate?.royaltySettings}
          editing={editing}
          fieldName={'Store ID'}
          fieldReference={'storeID'}
          type='number'
        />
      </Grid>
    </SettingsPaper>

  );
};
