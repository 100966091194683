import {Grid} from '@material-ui/core';
import {TableBody, TableCell, TableRow} from '@mui/material';
import React from 'react';
import ConfiguredProductCard from './ConfiguredProductCard';

export const ConfiguredProductManyGrid = ({
    rows = [],
    emptyStateComponent = () => null
}) => (
        <TableBody>
            {rows.length > 0 ?
                <TableRow>
                    <TableCell>
                        <Grid container spacing={3}>
                            {rows.map((configuredProduct, index) => (
                                <Grid key={index} item style={{width: '250px'}}>
                                <ConfiguredProductCard configuredProduct={configuredProduct} />
                            </Grid>
                            ))}
                        </Grid>
                    </TableCell>
                </TableRow>
                : emptyStateComponent()
            }

        </TableBody>
    );

export default ConfiguredProductManyGrid;
