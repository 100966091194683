import {gql} from 'apollo-boost';

export const STATUS_GROUP_FRAGMENT = gql`
    fragment StatusGroup on StatusGroup {
        _id
        label
        entityType
        entityName
        locked
        isVirtual
        
        companyId
        
        statuses {
            _id
            label
            color
            value
            locked
            tooltip
        }
    }
`;

export const GQL_GET_STATUS_GROUP = gql`
    query getStatusGropup($_id: String!) {
        statusGroup(_id: $_id) {
            ...StatusGroup
        }
    }
    ${STATUS_GROUP_FRAGMENT}
`;

export const GQL_GET_STATUS_GROUPS = gql`
    query getStatusGropups($entityType: String) {
        statusGroups(entityType: $entityType) {
            ...StatusGroup
        }
    }
    ${STATUS_GROUP_FRAGMENT}
`;

export const GQL_UPDATE_STATUS_GROUP = gql`
    mutation updateStatusGroup($_id: String!, $data: StatusGroupUpdateInput!) {
        updateStatusGroup(_id: $_id, data: $data) {
            ...StatusGroup
        }
    }
    ${STATUS_GROUP_FRAGMENT}
`;
