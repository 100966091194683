import {initTemplateSubstitutions} from './templateSubstitutions';

export const productSubstitutions =  initTemplateSubstitutions({
  code: {},
  category: {},
  decorationsAvailable: {},
  cartonSize: {field: 'cartonSize.text'},
  name: {field: 'title'},
  subCategory: {},
  decorationAreas: {},
  cartonWeight: {},
  description: {},
  productUrl: {field: 'url', view: 'Product URL'},
  stockIndent: {name: 'Stock / Indent'},
  qtyPerCarton: {},
  vendor: {field: 'vendorName'},
  primaryPriceDescription: {},
  individualProductPackaging: {},
  cartonCubic: {},
  brand: {},
  materials: {},
  freightDescription: {},
  cartonNotes: {},
});

export const oneOffProductSubstitutions =  initTemplateSubstitutions({
  code: {},
  name: {},
  vendor: {field: 'vendorName'},
});
