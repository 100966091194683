// TODO: fix eslint disable
/* eslint-disable no-duplicate-imports, no-class-assign */

import React, {Component, Fragment} from 'react';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import {TableRow} from '@material-ui/core';
import {renderTextField} from '../../formHelpers/fields';
import {Field, change as _change, getFormValues} from 'redux-form';
import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';
import {Delete} from '@material-ui/icons';
import {connect} from 'react-redux';
import {TextField, InputAdornment} from '@material-ui/core';
import numeral from 'numeral';
import Typography from '@material-ui/core/Typography';
import {reduceElement} from '../../../purchase-orders/CalculationMethods';
import {get} from 'lodash';
import Grid from '@material-ui/core/Grid';
import FileCopyIcon from '@material-ui/icons/FileCopy';

const styles = (theme) => ({tablePadding: {padding: theme.spacing(1)}});

class AdditionalItemsTableRow extends Component {
  handleAddMoreClick = () => {
    const {fields} = this.props;
    fields.push({});
  };

  handleDeleteRowClick = (index) => {
    const {fields} = this.props;
    fields.splice(index, 1);
  };

  handleDuplicateRowClick = (field) => {
    const {fields, formValues} = this.props;
    const newRow = get(formValues, field, {});
    delete newRow._id;

    fields.push(newRow);
  };

  calculateTotal = (index) => {
    const {formValues} = this.props;
    const {quantity, rate} = formValues.additionalItems[index];
    let total = '';
    if (quantity && rate) {
      total = numeral(quantity * rate).format('0,0.00');
    }
    return total;
  };

  render() {
    const {classes, fields, poFormValues: {locked}, formValues, currencySymbol} = this.props;

    const additionalItemsTotal = numeral(formValues.additionalItems.reduce(reduceElement, 0)).format('0,0.00');

    return (
      <Fragment>
        {fields.map((field, index) => (
            <TableRow key={index}>
              <TableCell className={classes.tablePadding}>
                <Field
                  name={`${field}.quantity`}
                  component={renderTextField}
                  disabled={locked}
                  variant={'outlined'}
                  margin={'dense'}
                  InputProps={{
                    type: 'number',
                    step: 1
                  }}/>
              </TableCell>
              <TableCell className={classes.tablePadding}>
                <Field name={`${field}.description`}
                       component={renderTextField}
                       variant={'outlined'}
                       margin={'dense'}/>
              </TableCell>
              <TableCell className={classes.tablePadding}>
                <Field name={`${field}.rate`}
                       component={renderTextField}
                       variant={'outlined'}
                       margin={'dense'}
                  />
              </TableCell>
              <TableCell className={classes.tablePadding}>
                <TextField
                  variant='outlined'
                  margin='dense'
                  disabled={true}
                  InputProps={{
                    value: this.calculateTotal(index),
                    startAdornment: <InputAdornment position='start'>{currencySymbol}</InputAdornment>
                    }}/>
              </TableCell>
              <TableCell className={classes.tablePadding}>
                <Grid container wrap='nowrap'>
                  <Grid item>
                    <IconButton onClick={() => this.handleDuplicateRowClick(field)}>
                      <FileCopyIcon color={'secondary'}/>
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={() => this.handleDeleteRowClick(index)}>
                      <Delete color={'secondary'}/>
                    </IconButton>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        <TableRow>
          <TableCell className={classes.tablePadding} colSpan={2}>
            {!locked &&
              <Button
                component={'div'}
                variant={'text'}
                color={'primary'}
                onClick={this.handleAddMoreClick}
              >
                {fields.length ? 'Add Another Additional Item' : 'Add an Additional Item'}
              </Button>}
          </TableCell>
          <TableCell className={classes.tablePadding}>
            <Typography variant={'body2'}
                        align={'right'}
                        color={'textSecondary'}>Sub Total</Typography>
          </TableCell>
          <TableCell className={classes.tablePadding}>
            <TextField disabled
                       className={classes.subTotalInput}
                       variant={'outlined'}
                       value={additionalItemsTotal}
                       margin={'dense'}
                       fullWidth={true}
                       InputProps={{startAdornment: <InputAdornment position={'start'}>{currencySymbol}</InputAdornment>}}

            />
          </TableCell>
          <TableCell/>
        </TableRow>
      </Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    formValues: getFormValues(ownProps.meta.form)(state),
    poFormValues: getFormValues('purchaseOrderForm')(state),
    currencySymbol: state.authReducer.userData.company.currencySymbol
  };
}

function mapDispatchToProps(dispatch) {
  return {change: (form, field, value) => dispatch(_change(form, field, value))};
}

AdditionalItemsTableRow = connect(mapStateToProps, mapDispatchToProps)(AdditionalItemsTableRow);
AdditionalItemsTableRow = withStyles(styles)(AdditionalItemsTableRow);
export default AdditionalItemsTableRow;
