import {IconButton, Tooltip, makeStyles} from '@material-ui/core';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import React from 'react';
import {Link} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    automationButton: {
        color: theme.colors.grey.main,
        padding: theme.spacing(1),
    }
}));

const AutomationIconButton = () => {

    const classes = useStyles();

    return (
        <Tooltip title='Automations' placement='top' arrow>
            <IconButton
                to={'settings/automations'}
                size='small'
                component={Link}
                className={classes.automationButton}
            >
                <FlashOnIcon />
            </IconButton>
        </Tooltip>
    );
};

export default AutomationIconButton;
