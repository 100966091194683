// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {CardActions} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300,
    height: '100%',
    minHeight: 200,
  },
  contentContainer: {
    minHeight: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },
  image: {
    display: 'block',
    margin: `${theme.spacing(1)}px auto`,
  },
  actionContainer: {
    padding: theme.spacing(3),
    justifyContent: 'center',
    textAlign: 'center'
  }
}));

export function IntegrationTile({name, type, logo, ContentComponent, ActionComponent, config}) {
  const classes = useStyles();

  return (
    <>
      <Grid item>
        <Card className={classes.root}>
          <CardContent className={classes.contentContainer}>
            <div>
              <img src={logo} alt={name} width='70%' className={classes.image} />
              {!!ContentComponent && <ContentComponent />}
            </div>
          </CardContent>
          {!!ActionComponent &&
            <CardActions className={classes.actionContainer}>
              <ActionComponent config={config} />
            </CardActions>
          }
        </Card>
      </Grid>
    </>
  );
}
