// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {Button, MuiThemeProvider} from '@material-ui/core';
import React from 'react';
import {useDispatch} from 'react-redux';
import {openPurchaseOrderVariantsModal} from '../../../actions/modals';
import {setActivePoVariants} from '../../../actions/purchaseOrders';
import CatalogService from '../../../servicesOld/CatalogService';
import green from '../../../ui/theme/green';

const VariantsButton = ({meta: {form}, input: {name, value}, product}) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    if (product) {
      CatalogService.getProduct(product._id)
        .then((response) => {
          let product = response && response.data && response.data.product;
          dispatch(setActivePoVariants(value));
          dispatch(openPurchaseOrderVariantsModal({form, name, product}));
        });
    } else {
      dispatch(setActivePoVariants(value));
      dispatch(openPurchaseOrderVariantsModal({form, name, product}));
    }
  };

  return (
    <MuiThemeProvider theme={green}>
      <Button disableElevation variant={'contained'} color={'primary'} style={{width: 120, marginBottom: 1}} onClick={handleClick}>
        Build Item
      </Button>
    </MuiThemeProvider>
  );
};

export default VariantsButton;
