// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React, {Component, Fragment} from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import withStyles from '@material-ui/core/styles/withStyles';
import AdditionalItemsTableRow from './AdditionalItemsTableRow';
import {FieldArray} from 'redux-form';
import TableRow from '@material-ui/core/TableRow';

const styles = (theme) => {
  const baseWidth = 140;
  return {
    sectionFooter: {
      margin: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      maxWidth: '100%'
    },
    largeColumn: {width: `${baseWidth * 2}px`},
    baseColumn: {width: `${baseWidth}px`},
    actionColumn: {width: `${baseWidth / 3}px`}
  };
};

class AdditionalItemsTable extends Component {
  render() {
    const {classes} = this.props;
    return (
      <Fragment>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.baseColumn}>Quantity</TableCell>
              <TableCell className={classes.largeColumn}>Name/Description</TableCell>
              <TableCell className={classes.baseColumn}>Rate</TableCell>
              <TableCell className={classes.baseColumn}>Total</TableCell>
              <TableCell className={classes.actionColumn}/>
            </TableRow>
          </TableHead>
          <TableBody>
            <FieldArray name={'additionalItems'} component={AdditionalItemsTableRow}/>
          </TableBody>
        </Table>
      </Fragment>
    );
  }
}

AdditionalItemsTable = withStyles(styles)(AdditionalItemsTable);
export default AdditionalItemsTable;
