import {SET_PRICE_BUILDER, RESET_PRICE_BUILDER} from '../modules/itemBuilder/itemBuilderActions';

const initialState = {
  itemBuilder: {
    variations: [],
    additionalCosts: [],
    decorationCosts: []
  }
};

const itemBuilderReducer = (state = {...initialState}, action) => {
  switch (action.type) {
    case SET_PRICE_BUILDER:
      return {
        ...state,
        itemBuilder: action.payload
      };
    case RESET_PRICE_BUILDER:
      return {
        ...state,
        itemBuilder: initialState.itemBuilder
      };
    default:
      return state;
  }
};

export default itemBuilderReducer;
