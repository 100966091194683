import React, {useCallback, useEffect, useState} from 'react';
import {MenuItem} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {Field} from 'react-final-form';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import {Select} from '@kestreltech/final-form-material-ui';
import csv from 'csvtojson';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import LabelledInput from '../LabelledInput';
import SingleFile from '../SingleFile';
import red from '../../../../ui/theme/red';
import {renderTextField} from '../../../shared/formHelpers/fields';
import VendorAutocomplete from '../../../vendors/VendorAutocomplete';
import {required} from '../../../shared/formHelpers/validation';
import {CatalogImportData} from '../../../../db/CatalogImportData';
import HoopsDropzone from '../../../shared/HoopsDropzone';
import completeTemplate from '../../../../assets/Hoops Complete Import Template.csv';
import simpleTemplate from '../../../../assets/Hoops Simplified Import Template.csv';

export function Step1UploadCSV({values})  {
  return (
    <Grid container direction={'column'} spacing={4}>
      <Grid item container>
        <LabelledInput
          label={'Description'}
          chipTheme={red}
          chipLabel={'Required'}>
          <Field
            name='description'
            validate={required}
            variant='outlined'
            size='small'
            fullWidth
            component={renderTextField}
            inputProps={{maxLength: 50}}
          >
          </Field>
        </LabelledInput>
      </Grid>
      <Grid item container>
        <LabelledInput
          label={'Vendor'}
          chipTheme={red}
          chipLabel={'Required'}>
          <Field
            name='vendor'
            validate={required}
            size='small'
            fullWidth
            component={VendorAutocomplete}
            textFieldProps={{variant: 'outlined'}}
            style={{width: '100%'}}
          >
          </Field>
        </LabelledInput>
      </Grid>
      <Grid item container>
        <LabelledInput
          label={'Upload Product CSV'}
          chipLabel={'Required'}
          chipTheme={red}
          helpLink={'http://help.hoopscrm.com/en/articles/3667967-upload-product-csv'}>
          <Field
            name={'file'}
            validate={required}
            component={CSVUploadField}
            formValues={values}
          />
        </LabelledInput>
      </Grid>
      {values?.file?.headerRow?.[0] && <Grid item container>
        <LabelledInput
          label={'Group Products Using'}
          chipLabel={'Required'}
          chipTheme={red}
          helpLink={'http://help.hoopscrm.com/en/articles/3667970-product-grouping'}
        >
          <Field
            name={'groupBy'}
            validate={required}
            component={Select}
            options={values?.file?.headerRow ?? []}
            formControlProps={{
              fullWidth: true,
              margin: 'dense',
              size: 'small',
              variant: 'outlined',
            }}
          >
            {values?.file?.headerRow.map((option) => <MenuItem value={option} key={option}>{option}</MenuItem>)}
          </Field>
        </LabelledInput>
      </Grid>}
    </Grid>
  );
}

function CSVUploadField({meta, formValues, input: {value, onChange}}) {
  const catalogImportData = new CatalogImportData();
  const [content, setContent] = useState({});

  const updateCount = useCallback(async () => {
    const items = await catalogImportData._db.where('id').above(0).count();
    setContent({lines: items});
  }, [catalogImportData._db]);

  const handleRemove = useCallback(async () => {
    onChange();
    await catalogImportData._db.where('id').above(0).delete();
    updateCount();
  }, [catalogImportData._db, onChange, updateCount]);

  const onDrop = useCallback(async ({file, ...fileObject}) => {
    handleRemove();
    const reader = new FileReader();
    reader.readAsText(file, 'utf8');
    reader.onloadend = () => {
      const csvString = reader.result;
      let headerRow = [];
      csv()
        .on('header', (header) => {
          headerRow = header;
        })
        .fromString(csvString)
        .then(async (data) => {
          await catalogImportData.bulkAdd(data);
          updateCount();
          const sampleData = await catalogImportData.getSampleData(formValues.groupBy);
          onChange({file: fileObject, headerRow, sampleData});
        });
    };
    // TODO: FIX HOOK DEPENDENCIES
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogImportData, handleRemove, onChange, updateCount]);

  useEffect(() => {
    updateCount();
  }, [updateCount, value]);

  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

  return (
    <FormControl error={showError}>
      {((value.file && value.file.filename) ?
        <SingleFile
          file={value.file}
          content={content}
          onRemove={handleRemove}
        /> :
        <Grid container direction={'column'} spacing={1} alignItems={'center'}>
          <Grid item container>
            <HoopsDropzone onComplete={onDrop}
                           accept={['.csv']}
                           cardList
                           s3ObjectParams={{Bucket: process.env.REACT_APP_IMPORT_BUCKET}}
                           dropzoneOptions={{multiple: false}}/>
          </Grid>
          <Grid item>
            <Typography variant={'body1'} align={'center'}>
              Hoops can handle most CSV file types! If you need some help getting started
              on your own, download the <Link href={completeTemplate} download>complete template</Link> or the <Link
              href={simpleTemplate} download>simplified template</Link>.
            </Typography>
          </Grid>
        </Grid>)
      }

      {showError &&
      <FormHelperText>{meta.error || meta.submitError}</FormHelperText>
      }
    </FormControl>
  );
}
