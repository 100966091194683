// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import {Button, IconButton, TableCell, TableRow, withStyles} from '@material-ui/core';
import {Delete} from '@material-ui/icons';
import React, {Component, Fragment, useEffect} from 'react';
import {useSelector} from 'react-redux';
import connect from 'react-redux/es/connect/connect';
import {change as _change, Field, formValueSelector, getFormValues} from 'redux-form';
import VendorAutocomplete from '../../../vendors/VendorAutocomplete';
import {renderTextField} from '../../formHelpers/fields';
import {DecorationField} from '../DecorationField';

const styles = (theme) => ({tablePadding: {padding: theme.spacing(1)}});

function JobItemDecorationInput(props) {
  const {index, meta: {form}, input: {value, onChange}} = props;

  const selector = formValueSelector(form);

  const variantsSelector = (state) => selector(state, 'variants');
  const quantitySelector = (state) => selector(state, 'quantity');

  const variants = useSelector(variantsSelector);
  const quantity = useSelector(quantitySelector);

  useEffect(() => {
    if ((variants.length || index > 0) && (quantity !== value)) {
      onChange(quantity);
    }
  });

  const InputComponent = renderTextField;
  return (
    <InputComponent
      disabled={(variants.length || index > 0)}
      {...props} />
  );
}

class DecorationsTableRow extends Component {
  handleAddMoreClick = () => {
    const {fields} = this.props;
    fields.push({});
  };

  handleDeleteRowClick = (index) => {
    const {fields} = this.props;
    fields.splice(index, 1);
  };

  render() {
    const {classes, fields} = this.props;
    return (
      <Fragment>
        {fields.map((field, index) => (
            <TableRow key={index}>
              <TableCell className={classes.tablePadding}>
                <Field name={`${field}.quantity`}
                  component={JobItemDecorationInput}
                  data-intercom-target={'job-item-decoration-quantity-field'}
                  variant={'outlined'}
                  margin={'dense'}
                  index={index}
                  InputProps={{
                    type: 'number',
                    step: 1
                  }}
                />
              </TableCell>
              <TableCell className={classes.tablePadding}>
                <DecorationField field={field} {...this.props} />
              </TableCell>
              <TableCell className={classes.tablePadding}>
                <Field name={`${field}.position`}
                  component={renderTextField}
                  variant={'outlined'}
                  margin={'dense'} />
              </TableCell>
              <TableCell className={classes.tablePadding}>
                <Field name={`${field}.colour`}
                  component={renderTextField}
                  variant={'outlined'}
                  margin={'dense'} />
              </TableCell>
              <TableCell className={classes.tablePadding}>
                <Field name={`${field}.size`}
                  component={renderTextField}
                  variant={'outlined'}
                  margin={'dense'} />
              </TableCell>
              <TableCell className={classes.tablePadding}>
                <Field
                  {...this.props}
                  fullWidth
                  name={`${field}.vendor`}
                  field={field}
                  component={VendorAutocomplete}
                  textFieldProps={{
                    margin: 'dense',
                    variant: 'outlined'
                  }}
                />
              </TableCell>
              <TableCell className={classes.tablePadding}>
                <IconButton data-intercom-target={'job-item-decoration-delete-button'}
                  onClick={() => this.handleDeleteRowClick(index)}>
                  <Delete color={'secondary'} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        <TableRow>
          <TableCell className={classes.tablePadding} colSpan={4}>
            <Button
              color={'primary'}
              variant={'outlined'}
              size={'small'}
              data-intercom-target={'job-item-decoration-add-more-button'}
              onClick={this.handleAddMoreClick}
            >
              {fields.length ? 'Add Another Decoration' : 'Add Decoration'}
            </Button>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {formValues: getFormValues(ownProps.meta.form)(state),};
}

function mapDispatchToProps(dispatch) {
  return {change: (form, field, value) => dispatch(_change(form, field, value))};
}

DecorationsTableRow = connect(mapStateToProps, mapDispatchToProps)(DecorationsTableRow);
DecorationsTableRow = withStyles(styles)(DecorationsTableRow);

export default DecorationsTableRow;
