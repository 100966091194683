import React from 'react';
import {Icon, IconButton, TableCell, TableRow, Typography} from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import AddIcon from '@material-ui/icons/Add';
import {get} from 'lodash';
import numeral from 'numeral';
import {Field} from 'redux-form';
import TaxSelect from '../../../modules/tax/TaxSelect';
import {renderTextField} from '../../shared/formHelpers/fields';
import {calculateItemSubtotal} from '../CalculationMethods';
import VariantsButton from './VariantsButton';

const renderItemSubtotal = ({company, input: {value}}) => {
  const subTotal = calculateItemSubtotal(value);
  return (
    <Typography>
      {company.currencySymbol} {numeral(subTotal).format('0,0.00')}
    </Typography>
  );
};

function PurchaseOrderItemFieldRow({
  handleDelete = () => null,
  fields,
  index,
  field,
  classes,
  company,
  openPurchaseOrderFileUploadModal = () => null,
  formValues
}) {

  const Files = fields.get(index).files;
  const numberOfFiles = (Files ? Files.length : 0);

  return (
    <>
      <TableRow key={index}>
        <TableCell align={'center'} className={classes.paddedCell} padding={'none'}>
          <Field component={VariantsButton}
            name={field}
            product={get(formValues, `${field}.product`, '')}
          />
        </TableCell>
        <TableCell padding={'none'}>
          <Field
            name={`${field}.description`}
            variant={'outlined'}
            inputProps={{className: classes.quoteInput}}
            component={renderTextField} />
        </TableCell>

        <TableCell align={'center'} className={classes.paddedCell} padding={'none'}>
          <Field
            name={`${field}.tax`}
            component={TaxSelect}
            taxType='expense'
            variant='outlined'
            containerProps={{justifyContent: 'center'}}
            style={{maxHeight: '35px'}}
          />
        </TableCell>
        <TableCell padding={'none'} className={classes.subTotalCell} align={'right'}>
          <Field name={`${field}`}
            company={company}
            component={renderItemSubtotal}
            classes={classes}
          />
          <Typography />
        </TableCell>
        <TableCell align={'center'} className={classes.paddedCell} padding={'none'}>
          <Field
            name={`${field}.files`}
            component={() => (
                <Badge color={'primary'} badgeContent={numberOfFiles} className={classes.badgeMargin}>
                  <IconButton
                    size={'small'}
                    onClick={() => openPurchaseOrderFileUploadModal({
                      item: field,
                      initialValues: {
                        // shouldn't have to do this, we should have access to the fileIds directly
                        fileIds: get(formValues, `${field}.files`, []).map((file) => file._id),
                        Files: get(formValues, `${field}.files`)
                      }
                    })}>
                    <AddIcon />
                  </IconButton>
                </Badge>
              )}
          />
        </TableCell>
        <TableCell className={classes.paddedCell} padding={'none'} align={'center'}>
          <IconButton onClick={handleDelete(index)}>
            <Icon color={'secondary'}>delete</Icon>
          </IconButton>
        </TableCell>
      </TableRow>
 </>
  );
}

export default PurchaseOrderItemFieldRow;
