import {SET_REPORT_DATA} from '../actions/reporting';

const initialState = {
  status: {
    loading: false,
    error: false,
  },
  reportData: {}
};

export const reportingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REPORT_DATA:
      return {
        status: {
          ...state.status,
          loading: false,
          error: false
        },
        reportData: action.payload,
      };
    default:
      return state;
  }
};
