import React, {useRef} from 'react';
import {DragDropContext as BeautifulDragDropContext, Draggable as BeautifulDraggable, Droppable as BeautifulDroppable} from 'react-beautiful-dnd';
import classNames from 'classnames';

export function DragDropContext({onDragStart, onDragEnd, children}) {
  return (
    <BeautifulDragDropContext onBeforeDragStart={initBeforeDragStart} onDragStart={onDragStart} onDragEnd={onDragEnd}>
      {children}
    </BeautifulDragDropContext>
  );
}

export function Droppable({className, droppableId, children, type, isDropDisabled}) {
  return (
    <BeautifulDroppable droppableId={droppableId} type={type} isDropDisabled={isDropDisabled}>
      {(provided, snapshot) => (
        <div
          className={classNames([className, 'drop-target', snapshot.isDraggingOver && 'dragging-over'])}
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          {children}
          {provided.placeholder}
        </div>
      )}
    </BeautifulDroppable>
  );
}

export function Draggable({className, draggableId, index, children, type, isDropDisabled}) {
  const ref = useRef();

  return (
    <BeautifulDraggable draggableId={draggableId} index={index} type={type} isDropDisabled={isDropDisabled}>
      {(provided, snapshot) => (
          <div
            className={classNames([className, 'draggable', snapshot.isDragging && 'dragging'])}
            ref={(element) => {ref.current = element; provided.innerRef(element); }}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={snapshot.isDragging ? adjustStyles(provided.draggableProps.style) : provided.draggableProps.style}
          >
            {children}
          </div>
        )
    }
    </BeautifulDraggable>
  );
}

// This is a huge hack, but since there can be only one drag operation active at a time, it is safe and efficient
let styleAdjustment = null;

function initBeforeDragStart(start) {
  const elem = document.querySelector(`*[data-rbd-draggable-id="${start.draggableId}"]`);
  const transformEl = elem?.closest('.transform-source');
  if (elem && transformEl) {
    const elemRect = elem.getBoundingClientRect();
    const transRect = transformEl.getBoundingClientRect();
    const computedStyles = window.getComputedStyle(transformEl);
    const values = computedStyles.transform.match(/([-0-9.]+)/g)?.map((s) => Number.parseFloat(s)) ?? [1, 0, 0, 1, 0, 0];
    const mx = (values[0] ?? 1);
    const my = (values[3] ?? 1);
    styleAdjustment = {
      mx,
      my,
      x: (elemRect.left - transRect.left) / mx,
      y: (elemRect.top - transRect.top) / my,
    };
  } else {
    styleAdjustment = null;
  }
}

function adjustStyles(styles) {
  if (styleAdjustment && styles && styles.top != null && styles.left != null && styles.width != null && styles.height != null) {
    styles = {
      ...styles,
      left: styleAdjustment.x,
      top: styleAdjustment.y,
      width: styles.width / styleAdjustment.mx,
      height: styles.height / styleAdjustment.my,
    };
    const trans = styles.transform?.match(/([-0-9.]+)/g);
    if (trans && trans[0] != null && trans[1] != null) {
      styles.transform = `translate(${trans[0] / styleAdjustment.mx}px, ${trans[1] / styleAdjustment.my}px)`;
    }
  }
  return styles;
}
