// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React, {Component, Fragment} from 'react';
import {withStyles} from '@material-ui/core';
import {Route, withRouter} from 'react-router-dom';
import PublicWrapper from '../../componentsOld/auth/login/PublicWrapper';
import {connect} from 'react-redux';

const styles = () => ({});

export const FORGOT_PASSWORD_ROUTE = '/forgot-password';
export const REGISTER_ROUTE = '/register';
export const REGISTER_PROOFS_ROUTE = '/registerproofs';
export const REGISTER_FULLYPROMOTED_ROUTE = '/fullypromoted';
export const REGISTER_XERO_ROUTE = '/xeroregister';
export const INVITED_USER_ROUTE = '/register/:externalId';
export const LOGIN_ROUTE = '/login';

class PreAuthRoutes extends Component {

  render() {
    return (
      <Fragment>
        <Route path={LOGIN_ROUTE}>
          <PublicWrapper/>
        </Route>
        <Route path={REGISTER_ROUTE}>
          <PublicWrapper/>
        </Route>
        <Route path={REGISTER_PROOFS_ROUTE}>
          <PublicWrapper/>
        </Route>
        <Route path={REGISTER_FULLYPROMOTED_ROUTE}>
          <PublicWrapper/>
        </Route>
        <Route path={REGISTER_XERO_ROUTE}>
          <PublicWrapper/>
        </Route>
        <Route path={INVITED_USER_ROUTE}>
          <PublicWrapper/>
        </Route>
        <Route path={FORGOT_PASSWORD_ROUTE}>
          <PublicWrapper/>
        </Route>
      </Fragment>
    );
  }
}

const mapStateToProps = function mapStateToProps(state) {
  return {auth: state.authReducer};
};

PreAuthRoutes = withStyles(styles)(PreAuthRoutes);
PreAuthRoutes = withRouter(connect(mapStateToProps)(PreAuthRoutes));

export default PreAuthRoutes;
