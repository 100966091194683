import ErrorIcon from '@material-ui/icons/Error';
import {Typography} from '@material-ui/core';
import React from 'react';
import theme from '../ui/theme';

export function ErrorMessage({error, onClick}) {
  if (!error) {return null;}
  return <div>
    <ErrorIcon style={{color: theme.colors.red}} />
    <Typography variant='body2' display='inline'
      style={{verticalAlign: 'top', lineHeight: 2, marginLeft: 8, color: theme.colors.red}}
    >
      An unexpected error has occurred,
      {error.message}
    </Typography>
    {onClick &&
      <>
        <br />
        <a
          onClick={onClick}
          href='/#'
        >
          Please try again
        </a>
        <br />
        <br />
      </>
    }
  </div>;
}
