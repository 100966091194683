// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import {Icon, IconButton, ListItemIcon, Menu, MenuItem} from '@material-ui/core';
import ConfirmDeleteDialog from '../confirmDeleteDialog';
import React from 'react';
import {deleteDecoration, setDecoration, getDecoration} from '../../actions/decoration';
import {connect} from 'react-redux';
import DecorationDialog from './decorationDialog';

class DecorationMenuCell extends React.Component {
  state = {
    anchorEl: null,
    confirmDelete: false,
    editModalOpen: false
  };

  handleClick = (event) => {
    this.setState({anchorEl: event.currentTarget});
  };

  handleClose = () => {
    this.setState({anchorEl: null});
  };

  openConfirmDelete = () => {
    this.setState({confirmDelete: !this.state.confirmDelete});
  };

  openEditModal = () => {
    const {dispatch, rowData} = this.props;
    dispatch(getDecoration(rowData._id, () => {
      this.setState({editModalOpen: true, anchorEl: null});
    }));

  };

  editModalHandleClose = () => {
    this.props.dispatch(setDecoration({}));
    this.setState({editModalOpen: false});
  };

  deleteDecoration = () => {
    this.handleClose();
    return this.props.dispatch(deleteDecoration(this.props.rowData));
  };

  render() {
    const {anchorEl} = this.state;
    const {rowData} = this.props;

    return (
      <div key={rowData._id}>
        <IconButton
          onClick={this.handleClick}
          variant='outlined'
          size={'small'}
        >
          <Icon>more_horiz</Icon>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.openEditModal}>
            <ListItemIcon>
              <Icon>edit</Icon>
            </ListItemIcon>
            Edit
          </MenuItem>
          <MenuItem onClick={this.openConfirmDelete}>
            <ListItemIcon>
              <Icon>delete</Icon>
            </ListItemIcon>
            Delete
            <ConfirmDeleteDialog name={rowData.name} isOpen={this.state.confirmDelete} ok={this.deleteDecoration}/>
          </MenuItem>
        </Menu>
        <DecorationDialog data={rowData} isOpen={this.state.editModalOpen} onClose={this.editModalHandleClose} />
      </div>
    );
  }
}

DecorationMenuCell = connect()(DecorationMenuCell);

export default DecorationMenuCell;
