import {createGraphQLClient} from './GraphQLService';

import {GQL_REGISTER} from '../queries/registration';

const client = createGraphQLClient({});

class RegistrationService {
  static register(data) {
    const {
      firstName,
      lastName,
      password,
      username,
      region,
      company,
      source,
      xeroOrganisationId,
      xero
    } = data;

    const registrationData = {
      firstName,
      lastName,
      password,
      username,
      region,
      company,
      source,
    };

    // Set this data if exists
    if (xeroOrganisationId) {
      registrationData.xeroOrganisationId = xeroOrganisationId;
      registrationData.xero = xero;
    }

    return client.mutate({
      mutation: GQL_REGISTER,
      variables: {data: registrationData}
    });
  }
}

export default RegistrationService;
