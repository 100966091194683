import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {
  GridColumnMenuContainer,
  GridColumnPinningMenuItems,
  GridFilterMenuItem,
  SortGridMenuItems
} from '@mui/x-data-grid-pro';
import {HideGridColMenuItem, useGridApiContext} from '@mui/x-data-grid';
import {Divider, MenuItem} from '@mui/material';
import {BodyText} from '../../Text';
import {AttentionConfirmDialog, Placement} from '../../Popovers';
import {GridViewAddColumnMenu} from './GridViewAddColumnMenu';

const menuItemStyle = {display: 'flex', justifyContent: 'space-between'};

export function GridViewColumnMenu({hideMenu, currentColumn, ...containerProps}) {
  const apiRef = useGridApiContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const handleSubMenuOpen = useCallback((e) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const handleSubMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleAddColumn = useCallback((colType, colName) => {
    apiRef.current.hideColumnMenu();
    setAnchorEl(null);
    const position = anchorEl.closest('li')?.getAttribute('value');
    if (colType != null && position != null) {
      currentColumn.addColumn(colType, colName, position === 'after');
    }
  }, [anchorEl, apiRef, currentColumn]);

  const handleShowDeleteConfirm = useCallback(() => {
    setShowConfirmDelete(true);
  }, []);

  const handleConfirmDelete = useCallback((confirm) => {
    apiRef.current.hideColumnMenu();
    setAnchorEl(null);
    if (confirm) {
      currentColumn.deleteColumn();
    }
  }, [apiRef, currentColumn]);

  return (
    <>
      <GridColumnMenuContainer hideMenu={hideMenu} currentColumn={currentColumn} {...containerProps} style={showConfirmDelete ? {display: 'none'} : {}}>
        <SortGridMenuItems onClick={hideMenu} column={currentColumn}/>
        <GridFilterMenuItem onClick={hideMenu} column={currentColumn}/>
        <HideGridColMenuItem onClick={hideMenu} column={currentColumn}/>
        {(currentColumn.hideable || currentColumn.filterable || currentColumn.sortable) &&
          <Divider/>
        }
        <GridColumnPinningMenuItems onClick={hideMenu} column={currentColumn}/>
        <Divider/>
        <MenuItem sx={menuItemStyle} onClick={handleSubMenuOpen} disabled={!currentColumn.context.allowAddColumns} value={'before'}>
          Add Column Left
          <ArrowRightIcon/>
        </MenuItem>
        <MenuItem sx={menuItemStyle} onClick={handleSubMenuOpen} disabled={!currentColumn.context.allowAddColumns} value={'after'}>
          Add Column Right
          <ArrowRightIcon/>
        </MenuItem>
        {anchorEl &&
          <GridViewAddColumnMenu anchorEl={anchorEl} onClose={handleSubMenuClose} onAddColumn={handleAddColumn} placement={Placement.RightStart}/>
        }
        {currentColumn.deletable &&
          <Divider/>
        }
        {currentColumn.deletable &&
          <MenuItem onClick={handleShowDeleteConfirm}>Delete Column</MenuItem>
        }
      </GridColumnMenuContainer>
      {showConfirmDelete &&
        <AttentionConfirmDialog onClose={handleConfirmDelete} okText={'Delete'} warningIcon>
          <BodyText>{`Are you sure you want to delete the '${currentColumn.title}' column? This action cannot be undone.`}</BodyText>
          <br/>
          <BodyText italic>Note: Instead of deleting, you can HIDE the column from the view, this will not delete the data within the column.</BodyText>
        </AttentionConfirmDialog>
      }
    </>
  );
}

GridViewColumnMenu.propTypes = {
  currentColumn: PropTypes.object.isRequired,
  hideMenu: PropTypes.func.isRequired,
};
