import {
  InputAdornment as MuiInputAdornment,
  makeStyles, withStyles
} from '@material-ui/core';
import React, {useEffect} from 'react';
import {renderTextField} from '../formHelpers/fields';
import {Field, formValueSelector} from 'redux-form';
import {useSelector} from 'react-redux';
import {isNaN} from 'lodash';
import * as numeral from 'numeral';
import {toFloat} from '../../../servicesOld/ItemBuilderServices';

const InputAdornment = withStyles((theme) => ({
  root: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    marginRight: 0
  }
}))(MuiInputAdornment);

export function ItemBuilderTextField(props) {
  const {currency, percent, number: numberInput, step = 0.01} = props;
  const companySelector = (state) => state.companyReducer.company;
  const company = useSelector(companySelector);
  const useStyles = makeStyles((theme) => ({
      inputClass: {
        fontSize: 12,
        fontWeight: props.isBold ? 'bold' : undefined,
        padding: theme.spacing(1) * 1.25,
      }
  }));

  let startAdornment;
  let endAdornment;

  if (currency) {
    startAdornment = (<InputAdornment disableTypography position={'start'}>{company.currencySymbol}</InputAdornment>);
  }

  if (percent) {
    endAdornment = (<InputAdornment disableTypography position={'end'}>%</InputAdornment>);
  }

  const classes = useStyles();
  return (
    <Field
      margin={'dense'}
      variant={'outlined'}
      fullWidth
      inputProps={{
        className: classes.inputClass,
        type: (numberInput ? 'number' : 'text'),
        step: (numberInput ? step : undefined),
      }}
      // eslint-disable-next-line
      InputProps={{
        startAdornment,
        endAdornment
      }}
      component={renderTextField}
      {...props}
    />
  );
}

const selector = formValueSelector('itemBuilder');
export function ItemBuilderSellPrice(props) {
  const {field, input: {onChange: updateValue}} = props;

  const quantitySelector = (state) => selector(state, 'quantity');
  const markupSelector = (state) => selector(state, `${field}.markup`);
  const buyPriceSelector = (state) => selector(state, `${field}.buyPrice`);
  const additionalCostSelector = (state) => selector(state, `${field}.additionalCost`);
  const setupPriceSelector = (state) => selector(state, `${field}.setupPrice`);

  const quantity = toFloat(useSelector(quantitySelector));
  const markup = toFloat(useSelector(markupSelector));
  const buyPrice = toFloat(useSelector(buyPriceSelector));
  const additionalCost = toFloat(useSelector(additionalCostSelector));
  const setupPrice = toFloat(useSelector(setupPriceSelector));

  const setupUnitPrice = setupPrice / toFloat(quantity, 0);

  useEffect( () => {
    const sellPrice = (buyPrice + additionalCost + (isNaN(setupUnitPrice) ? 0 : setupUnitPrice)) * (1 + (markup / 100));
    updateValue(numeral(sellPrice).format('0,0.00'));
  });
  return (<ItemBuilderTextField currency {...props}/>);
}
