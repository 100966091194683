// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {
  GQL_CREATE_INVOICE,
  GQL_DRAFT_INVOICE_FROM_JOB,
  GQL_DRAFT_INVOICE_FROM_QUOTE,
  GQL_GET_INVOICE,
  GQL_UPDATE_INVOICE
} from '../queries/invoice';
import {stripHTMLKeepBreaks} from '../utils/stripHtml';
import {createGraphQLClient} from './GraphQLService';
import {parseLineItemTax} from './QuoteService';

const client = createGraphQLClient({});

const parseInvoiceInput = (invoice) => ({
  _id: invoice._id,
  quoteId: invoice.quoteId,
  jobId: invoice.jobId,
  customerId: invoice.customer._id,
  contactId: invoice.contactId,
  customReference: invoice.customReference,
  billTo: invoice.billTo,
  shipTo: invoice.shipTo,
  items: invoice.items.map((item) => ({
    _id: item._id,
    description: stripHTMLKeepBreaks(item.description),
    priceElementId: item.priceElementId,
    jobItemVariantId: item.jobItemVariantId,
    rate: parseFloat(item.rate),
    qty: parseFloat(item.qty),
    tax: parseLineItemTax(item.tax),
    accountingRevenueAccount: item.accountingRevenueAccount,
    accountingInventoryItem: item.accountingInventoryItem,
  }))
});

export class InvoiceService {
  constructor(invoice) {
    Object.assign(this, {...invoice});
  }

  static draftInvoiceFromQuote(quoteId) {
    return client.query({
      query: GQL_DRAFT_INVOICE_FROM_QUOTE,
      variables: {quoteId}
    })
      .then(({data: {draftInvoiceFromQuote: invoice}}) => invoice);
  }

  static draftInvoiceFromJob(jobId) {
    return client.query({
      query: GQL_DRAFT_INVOICE_FROM_JOB,
      variables: {jobId}
    })
      .then(({data: {draftInvoiceFromJob: invoice}}) => invoice);
  }

  static getInvoice(_id) {
    return client.query({
      query: GQL_GET_INVOICE,
      fetchPolicy: 'no-cache',
      variables: {_id}
    })
      .then(({data: {invoice}}) => invoice);
  }

  save(isDraft = false) {
    const invoice = parseInvoiceInput(this);
    const {_id} = this;

    if (_id && !isDraft) {
      return client.mutate({
        mutation: GQL_UPDATE_INVOICE,
        variables: {_id, invoice}
      })
        .then(({data: {updateInvoice: invoice}}) => invoice);
    } else {
      return client.mutate({
        mutation: GQL_CREATE_INVOICE,
        variables: {invoice}
      })
        .then(({data: {createInvoice: invoice}}) => invoice);
    }
  }
}
