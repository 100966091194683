import theme from '../theme';

//these styles are used on the 
//job quote and purchase order edit pages
export const editEntity = {
    header: {
      position: 'relative',
      backgroundColor: 'rgb(243 243 246 / 50%)',
      padding: theme.spacing(3),
      borderBottomColor: theme.colors.grey.light,
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
    },
    greyText: {color: theme.colors.grey.main},
    primaryColor: {backgroundColor: theme.palette.primary.main},
    entityDetails: {
      padding: theme.spacing(3),
      // paddingBottom: theme.spacing(6),
      borderBottomColor: theme.colors.grey.light,
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
    },
    flex: {flex: 1},
    button: {
      paddingBottom: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    buttons: {marginLeft: theme.spacing(2),},
    buttonContainer: {marginBottom: theme.spacing(8)},
    inputStyles: {
      '&&& $input': {padding: '12px !important',},
      '& .MuiOutlinedInput-root': {
        background: theme.colors.white,
        padding: '0px !important',
      },
      '& .MuiOutlinedInput-input': {padding: '12px !important',},
      '& .MuiInputAdornment-positionEnd': {
        paddingRight: theme.spacing(1),
        marginLeft: 0
      },
      '& .MuiInputAdornment-positionStart': {marginRight: 0,},
      '& .MuiFormControl-root': {margin: 0},
    },
    labelText: {
      marginBottom: theme.spacing(1),
      display: 'block'
    },
    headerInfo: {paddingTop: theme.spacing(1),},
    whiteButton: {
      backgroundColor: theme.colors.white,
      color: theme.palette.primary.main
    },
    whiteOutlinedButton: {
      backgroundColor: theme.colors.white,
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      
    },
    detailsSecondRow: {paddingTop: '0px !important'},
    entityTable: {padding: theme.spacing(3)},
    tooltip: {},
    icon: {
      color: theme.colors.grey.main,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1), 
    },
    hideIfSmall: {[theme.breakpoints.down('sm')]: {display: 'none',}},
    showIfSmall: {[theme.breakpoints.up('md')]: {display: 'none',}},
  };

  export default editEntity;
