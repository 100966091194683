import {Button, Grid, MuiThemeProvider} from '@material-ui/core';
import React, {createContext, useState} from 'react';
import EntityListHero from '../../componentsOld/EntityList/EntityListHero';
import green from '../../ui/theme/green';
import EmailTemplateEditModal from './EmailTemplateEditModal';
import EmailTemplateManyTable from './EmailTemplateManyTable';

export const EmailTemplateContext = createContext(null);

export const EmailTemplateManyViewPage = () => {
    const [open, setOpen] = useState(false);
    const [activeEmailTemplate, setActiveEmailTemplate] = useState(null);

    const handleOpen = () => {
        setActiveEmailTemplate(null);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Grid container>
            <EmailTemplateContext.Provider value={{open, setOpen, activeEmailTemplate, setActiveEmailTemplate}}>
                <EntityListHero
                    title='Templates'
                    subtitle='Customise and manage the statuses of your entire workflow.'
                    helpArticleUrl='http://help.hoopscrm.com/en/articles/5082911-email-templates'
                >
                    <MuiThemeProvider theme={green}>
                        <Button
                            data-intercom-target={'email-template-create'}
                            onClick={handleOpen}
                            color={'primary'}
                            variant={'contained'}>Create Template</Button>
                    </MuiThemeProvider>
                    <EmailTemplateEditModal open={open} handleClose={handleClose} />
                </EntityListHero>

                <Grid item xs={12}>
                    <EmailTemplateManyTable />
                </Grid>
            </EmailTemplateContext.Provider>
        </Grid>

    );
};

export default EmailTemplateManyViewPage;
