
export function parseColor(color) {
  const parts = color.match(/#?([0-9a-fA-F][0-9a-fA-F])([0-9a-fA-F][0-9a-fA-F])([0-9a-fA-F][0-9a-fA-F])((?:[0-9a-fA-F][0-9a-fA-F])?)|(?:rgb|rgba)\((\d+), (\d+), (\d+)(?:, (0?\.?\d+))?\)/);
  if (parts?.length === 9 && parts[0] === color) {
    return [
      parts[1] ? Number.parseInt(parts[1], 16) : Number.parseInt(parts[5]),
      parts[2] ? Number.parseInt(parts[2], 16) : Number.parseInt(parts[6]),
      parts[3] ? Number.parseInt(parts[3], 16) : Number.parseInt(parts[7]),
      (parts[4] || parts[8]) && (parts[4] ? Number.parseInt(parts[4], 16) : Math.round(Number.parseFloat(parts[8] ?? '1') * 255)),
    ];
  }
  return [];
}

export function hexColor(color, prefix = '') {
  return prefix + color.slice(0, 3).reduce((s, v) => s + v.toString(16).padStart(2, '0').slice(-2), '');
}

export function getBrightness(color) {
  const [r, g, b] = parseColor(color);
  return ((r * 299) + (g * 587) + (b * 114)) / 1000;
}
getBrightness.ALMOST_WHITE = 224;
getBrightness.WHITE = 255;

export function isLightColor(color) {
  return getBrightness(color) > getBrightness.ALMOST_WHITE;
}

export function brightness(color) {
  const b = getBrightness(color);
  return {isLight: b > getBrightness.ALMOST_WHITE, isWhite: b >= getBrightness.WHITE};
}

// A quick function to blend a color with alpha (color1) onto the background (color2)
export function alphaBlend(color1, color2, prefix) {
  let parsed = false;
  if (!Array.isArray(color1)) {
    parsed = true;
    color1 = parseColor(color1);
  }
  if (!Array.isArray(color2)) {
    parsed = true;
    color2 = parseColor(color2);
  }

  let blend = 1;
  let blended;
  if (color1[3] != null) {
    blend = color1[3] / 255;
  } else if (color2[3] != null) {
    blend = 1 - color2[3] / 255;
  } else {
    blended = color1;
  }

  if (!blended) {
    blended = color1.slice(0, 3).map((v, index) => Math.round(v * blend + color2[index] * (1 - blend)));
  }

  return parsed ? hexColor(blended, prefix) : blended;
}
