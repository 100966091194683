import * as React from 'react';
import {Tooltip} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import {colors} from '../../../theme';
import {Fields} from '../../Layout';
import {asCurrencyStringCommaSeparated, formatDateShort} from '../../../utils';
import {CellContainer} from './CellContainer';
import {useCallback} from 'react';
import {Chip} from '../../Chips';

export const ACCOUNTING_STATUS = {
  CREATING: {
    color: colors.palette.orange,
    label: 'Sending to Accounts',
    message: 'Your invoice data is being sent to your Accounting software.',
  },
  OVERDUE: {
    color: colors.palette.red,
    label: 'Overdue'
  },
  ERROR: {
    color: colors.palette.red,
    label: 'Error Sending to Accounts'
  },
  PAID_IN_FULL: {
    color: colors.palette.green,
    label: 'Paid'
  },
  PARTIALLY_PAID: {
    color: colors.palette.purple,
    label: 'Partially Paid'
  },
  CURRENT: {
    color: colors.palette.blue,
    label: 'Waiting Payment'
  }
};

export function AccountingStatusCell({invoice}) {
  const info = ACCOUNTING_STATUS[invoice?.status] ?? ACCOUNTING_STATUS.ERROR;

  const handleClick = useCallback(() => {}, []);

  return ((invoice ?? null) &&
    <CellContainer onClick={handleClick}>
      {!invoice.errorMessage &&
        <Tooltip arrow title={
          <Fields leftLabel noRowGap>
            <div>Invoice Number</div>
            <div>{invoice.invoiceNumber ?? '-'}</div>
            <div>Due Date</div>
            <div>{formatDateShort(invoice.dueDate)}</div>
            <div>Total Amount</div>
            <div className={'currency-prefix'}>{asCurrencyStringCommaSeparated(invoice.totalAmount)}</div>
            <div>Amount Owing</div>
            <div className={'currency-prefix'}>{asCurrencyStringCommaSeparated(invoice.balance)}</div>
          </Fields>
        }>
          <Chip text={info.label} color={info.color} icon={InfoIcon}/>
        </Tooltip>
      }
      {invoice.errorMessage &&
        <Tooltip arrow title={
          <div>
            An error occurred while syncing the invoice.
            {invoice.errorMessage &&
              <>
                <hr/>
                <div>{invoice.errorMessage}</div>
              </>
            }
          </div>
        }>
          <Chip text={'Error Syncing'} color={colors.palette.red}/>
        </Tooltip>
      }
    </CellContainer>
  );
}
