import {gql} from 'apollo-boost';
import {SINGLE_PRICE_ELEMENT_FRAGMENT} from './quotes';

export const SINGLE_CONFIGURED_PRODUCT_FRAGMENT = gql`
  fragment ConfiguredProduct on ConfiguredProduct {
    _id
    code
    name
    imageUrl
    description
    rate
    quantity
    tax {
      _id
      type
      name
    }
    priceElementId
    priceElement {
      ...SinglePriceElement
    }
    productId
    product {
      _id
      readOnly
      title
      code
      description
      vendor {
        name
        _id
      }
      brand
      category
      subCategory
      url
      companyId
      createdAt
      updatedAt
      createdById
      deleted
      primaryImage {
        _id
        contentLength
        contentType
        bucket
        key
        filename
        url
      }
      images {
        _id
        contentLength
        contentType
        bucket
        key
        filename
        url
      }
    }
    priceLogic
    priceLogicValue 
  }
  ${SINGLE_PRICE_ELEMENT_FRAGMENT}
`;

export const SINGLE_CONFIGURED_GCPRODUCT_FRAGMENT = gql`
  fragment GCConfiguredProduct on GCConfiguredProduct {
    _id
    code
    name
    imageUrl
    description
    rate
    quantity    
  }
`;

export const GQL_CREATE_CONFIGURED_PRODUCT = gql`
  mutation createConfiguredProduct($configuredProduct:ConfiguredProductInput!) {
    createConfiguredProduct(configuredProduct:$configuredProduct) {
        ...ConfiguredProduct
    }
  }
  ${SINGLE_CONFIGURED_PRODUCT_FRAGMENT}
`;

export const GQL_GET_CONFIGURED_PRODUCT = gql`
  query configuredProduct($_id:String!) {
    configuredProduct(_id:$_id) {
        ...ConfiguredProduct
    }
  }
  ${SINGLE_CONFIGURED_PRODUCT_FRAGMENT}
`;

export const GQL_GET_CONFIGURED_PRODUCTS = gql`
  query configuredProducts($s: String, $limit: Int, $skip: Int ) {
    configuredProducts(s: $s, limit: $limit, skip: $skip) {
        ...ConfiguredProduct
    }
  }
  ${SINGLE_CONFIGURED_PRODUCT_FRAGMENT}
`;

export const GQL_UPDATE_CONFIGURED_PRODUCT = gql`
  mutation updateConfiguredProduct($_id: String!, $configuredProduct: ConfiguredProductInput!) {
    updateProduct(_id:$_id, configuredProduct:$configuredProduct) {
      ...ConfiguredProduct
    }
  }
  ${SINGLE_CONFIGURED_PRODUCT_FRAGMENT}
`;

export const GQL_DELETE_CONFIGURED_PRODUCT = gql`
  mutation configuredProductRemoveById($_id:MongoID!) {
    configuredProductRemoveById(_id:$_id) {
        recordId
    }
  }
`;

export const GQL_CONFIGURED_PRODUCT_PAGINATION = gql`
    query configuredProductPagination(
        $filter: FilterFindManySearchGCConfiguredProductInput,
        $sort: SortFindManyGCConfiguredProductInput,
        $perPage: Int,
        $page: Int
    ){
        configuredProductPagination(
            filter: $filter,
            sort: $sort,
            perPage: $perPage,
            page: $page
        ) {
            items {
                ...GCConfiguredProduct
            }
            pageInfo {
                currentPage
                perPage
                pageCount
                itemCount
                hasNextPage
                hasPreviousPage
            }
        }
    }
    ${SINGLE_CONFIGURED_GCPRODUCT_FRAGMENT}
`;
