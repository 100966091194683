import CatalogService from '../servicesOld/CatalogService';
import {snackSuccess} from './action-types';

export const SET_ACTIVE_IMPORT = 'import_SET_ACTIVE_IMPORT';

export function processImport(importId, catalogImportSettings) {
  return async function (dispatch) {
    CatalogService.processImport(importId, catalogImportSettings)
      .then(async (catalogImport) => {
        dispatch({
          type: SET_ACTIVE_IMPORT,
          payload: catalogImport
        });
        dispatch(snackSuccess('Catalog import has successfully started.'));
      });
  };
}
