import React, {useCallback, useContext, useState} from 'react';
import {CellContainer} from './CellContainer';
import {useWatch} from '../../../../hooks';
import {PageContext} from '../../../../componentsOld/PageContext';
import {HoopsTextField} from '../../../../componentsOld/HoopsTextField';

export const TextCell = ({value: text, ...props}) => {
  const [value, setValue] = useState(text);
  const [editing, setEditing] = useState(false);
  const {updateField} = useContext(PageContext);

  useWatch(() => {
    setValue(text);
  }, [text]);

  const handleClick = useCallback(() => {
    setEditing(true);
  }, []);

  const saveValue = useCallback((newValue) => {
    setEditing(false);
    setValue((prev) => {
      if (newValue !== prev) {
        if (updateField) {
          updateField({_id: props.row._id, field: props.field, value: newValue});
        }
      }
      return newValue;
    });
  }, [props.field, props.row._id, updateField]);

  const handleBlur = useCallback((event) => {
    saveValue(event.currentTarget.value);
  }, [saveValue]);

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Escape') {
      setEditing(false);
      setValue(text);
    } else if (event.key === 'Enter') {
      saveValue(event.target.value);
    }
  }, [text, saveValue]);

  return (
          <CellContainer onClick={handleClick} className={editing ? 'TextCell TextCell-Editing' : 'TextCell'}>
            {editing
                    ? <HoopsTextField autoFocus selectOnFocus defaultValue={value} onBlur={handleBlur} onKeyDown={handleKeyDown}/>
                    : <div className={'TextCell-Container'}><span>{value}</span></div>
            }
          </CellContainer>
  );
};
