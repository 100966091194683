import * as React from 'react';
import CheckIcon from '@mui/icons-material/CheckCircle';
import UncheckedIcon from '@mui/icons-material/Brightness1';
import {registerGlobalStyle} from '../../theme';
import {HoopsPropTypes} from '../utils';
import {Chip} from './Chip';
import {useCallback} from 'react';

registerGlobalStyle('.selectable-chip', (theme) => ({
  cursor: 'pointer',
  '&:not(.selected)': {backgroundColor: theme.colors.palette.greyLighter},
  '&.selected': {backgroundColor: theme.colors.palette.blue},
}));

export function SelectableChip({className, selected, text, onChange, children}) {
  const handleClick = useCallback(() => {
    onChange?.(!selected);
  }, [onChange, selected]);

  return (
    <Chip
      className={[className, 'selectable-chip', selected && 'selected']}
      text={text}
      icon={selected ? CheckIcon : UncheckedIcon}
      onClick={handleClick}
      selected={selected}
    >
      {children}
    </Chip>
  );
}

SelectableChip.propTypes = {
  className: HoopsPropTypes.className,
  text: HoopsPropTypes.string,
  onChange: HoopsPropTypes.func,
  children: HoopsPropTypes.children,
};
