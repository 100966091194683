import {

  OPEN_JOB_VARIANTS_MODAL,
  CLOSE_JOB_VARIANTS_MODAL,
  OPEN_PROOF_VIEW_MODAL,
  CLOSE_PROOF_VIEW_MODAL,
  OPEN_PROOF_NOTES_MODAL,
  CLOSE_PROOF_NOTES_MODAL,
  OPEN_PROOF_UPLOAD_MODAL,
  CLOSE_PROOF_UPLOAD_MODAL,
  OPEN_SEND_PROOF_MODAL,
  CLOSE_SEND_PROOF_MODAL,
  OPEN_ADD_PURCHASE_ORDER_MODAL,
  CLOSE_ADD_PURCHASE_ORDER_MODAL,
  OPEN_MERGE_PURCHASE_ORDER_MODAL,
  CLOSE_MERGE_PURCHASE_ORDER_MODAL,
  OPEN_VIEW_PURCHASE_ORDER_MODAL,
  CLOSE_VIEW_PURCHASE_ORDER_MODAL,
  OPEN_PURCHASE_ORDER_VARIANTS_MODAL,
  CLOSE_PURCHASE_ORDER_VARIANTS_MODAL,
  OPEN_GUEST_REJECT_PROOF_MODAL,
  CLOSE_GUEST_REJECT_PROOF_MODAL,
  OPEN_PROOF_TERMS_MODAL,
  CLOSE_PROOF_TERMS_MODAL,
  OPEN_CUSTOMER_MODAL,
  CLOSE_CUSTOMER_MODAL,
  OPEN_MERGE_PURCHASE_ORDER_VENDOR_MODAL,
  CLOSE_MERGE_PURCHASE_ORDER_VENDOR_MODAL,
  OPEN_PRICE_BUILDER_MODAL,
  CLOSE_PRICE_BUILDER_MODAL,
  OPEN_QUOTE_TERMS,
  CLOSE_QUOTE_TERMS,
  OPEN_PURCHASE_ORDER_FILE_UPLOAD_MODAL,
  OPEN_CATALOG_MODAL,
  CLOSE_CATALOG_MODAL,
  OPEN_PRODUCT_PRICE_VARIANTS_MODAL,
  CLOSE_PRODUCT_PRICE_VARIANTS_MODAL,
  OPEN_CREATE_PRODUCT_VARIANT_MODAL,
  CLOSE_CREATE_PRODUCT_VARIANT_MODAL, OPEN_TAG_MODAL, CLOSE_TAG_MODAL,
  CLOSE_PURCHASE_ORDER_FILE_UPLOAD_MODAL, OPEN_PO_SHIP_TO, CLOSE_PO_SHIP_TO,
  OPEN_SEND_QUOTE_MODAL, CLOSE_SEND_QUOTE_MODAL,
  OPEN_JOB_NOTE_MODAL, CLOSE_JOB_NOTE_MODAL,
  OPEN_JOB_ARTWORK_MODAL, CLOSE_JOB_ARTWORK_MODAL,
  OPEN_JOB_DELETE_MODAL, CLOSE_JOB_DELETE_MODAL, SET_LOADING_VARIANTS_MODAL,
  OPEN_INVOICE_MODAL,
  CLOSE_INVOICE_MODAL,
  SET_INVOICE_MODAL_ON_CLOSE, SET_MODAL_OPEN, SET_MODAL_CONTEXT,
} from '../actions/modals';

const initialState = {
  shareProductModal: {
    open: false,
    context: {},
  },
  customerModal: {
    open: false,
    context: {},
  },
  mergePurchaseOrderVendorModal: {
    open: false,
    context: {},
  },
  itemBuilderModal: {
    open: false,
    context: {},
  },
  quoteTerms: {open: false,},
  jobVariantsModalIsOpen: false,
  proofUploadModalIsOpen: false,
  jobVariantsModalContext: {},
  jobProofViewModal: {
    isOpen: false,
    proof: {},
  },
  jobProofNotesModal: {
    isOpen: false,
    context: {
      rejecting: false,
      proofId: null,
    },
  },
  jobSendProofModal: {
    isOpen: false,
    context: {},
  },
  guestRejectProofModal: {isOpen: false,},
  proofTermsModal: {isOpen: false,},
  addPurchaseOrderModal: {isOpen: false,},
  mergePurchaseOrderModal: {
    isOpen: false,
    context: {},
  },
  viewPurchaseOrderModal: {
    isOpen: false,
    context: {},
  },
  purchaseOrderVariantsModal: {
    isOpen: false,
    context: {},
  },
  purchaseOrderFileUploadModal: {
    isOpen: false,
    context: {initialValues: {files: [],},},
  },
  poShipToModal: {
    isOpen: false,
    context: {shipTo: {},},
  },
  sendQuoteModal: {
    isOpen: false,
    context: {},
  },
  jobNoteModal: {
    isOpen: false,
    context: {},
  },
  jobArtworkModal: {
    isOpen: false,
    context: {initialValues: {product: [],},},
  },
  catalogModal: {
    isOpen: false,
    context: {},
  },
  productPriceVariantsModal: {
    isOpen: false,
    loadingVariantsModal: false,
    context: {},
  },
  createProductVariationsModal: {
    isOpen: false,
    context: {},
  },
  tagModal: {
    isOpen: false,
    context: {},
  },
  jobDeleteModal: {
    isOpen: false,
    context: {},
  },
  draftInvoiceModal: {
    isOpen: false,
    isDraft: false,
    context: {},
    onClose: null,
  },
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MODAL_OPEN:
      return {
        ...state,
        [action.payload.modal]: {
          ...state[action.payload.modal],
          open: action.payload.open,
        },
      };
    case SET_MODAL_CONTEXT:
      return {
        ...state,
        [action.payload.modal]: {
          ...state[action.payload.modal],
          context: action.payload.context,
        },
      };
    case OPEN_PRICE_BUILDER_MODAL:
      return {
        ...state,
        itemBuilderModal: {
          open: true,
          context: {
            ...state.itemBuilderModal.context,
            ...action.payload,
          },
        },
      };
    case CLOSE_PRICE_BUILDER_MODAL:
      return {
        ...state,
        itemBuilderModal: {
          open: false,
          context: initialState.itemBuilderModal.context,
        },
      };
    case OPEN_CUSTOMER_MODAL:
      return {
        ...state,
        customerModal: {
          ...state.customerModal,
          open: true,
          context: action.payload,
        },
      };
    case CLOSE_CUSTOMER_MODAL:
      return {
        ...state,
        customerModal: {
          ...state.customerModal,
          open: false,
        },
      };

      case OPEN_MERGE_PURCHASE_ORDER_VENDOR_MODAL:
        return {
          ...state,
          mergePurchaseOrderVendorModal: {
            ...state.mergePurchaseOrderVendorModal,
            open: true,
            context: action.payload,
          },
        };
      case CLOSE_MERGE_PURCHASE_ORDER_VENDOR_MODAL:
        return {
          ...state,
          mergePurchaseOrderVendorModal: {
            ...state.mergePurchaseOrderVendorModal,
            open: false,
          },
        };
    case OPEN_PROOF_UPLOAD_MODAL:
      return {
        ...state,
        proofUploadModalIsOpen: true,
      };
    case CLOSE_PROOF_UPLOAD_MODAL:
      return {
        ...state,
        proofUploadModalIsOpen: false,
      };
    case OPEN_JOB_VARIANTS_MODAL:
      return {
        ...state,
        jobVariantsModalIsOpen: true,
        jobVariantsModalContext: action.payload,
      };
    case CLOSE_JOB_VARIANTS_MODAL:
      return {
        ...state,
        jobVariantsModalIsOpen: false,
      };

    case OPEN_PROOF_VIEW_MODAL:
      return {
        ...state,
        jobProofViewModal: {
          isOpen: true,
          proof: action.payload,
        },
      };

    case CLOSE_PROOF_VIEW_MODAL:
      return {
        ...state,
        jobProofViewModal: {isOpen: false,},
      };

    case OPEN_PROOF_NOTES_MODAL:
      return {
        ...state,
        jobProofNotesModal: {
          isOpen: true,
          context: action.payload,
        },
      };

    case CLOSE_PROOF_NOTES_MODAL:
      return {
        ...state,
        jobProofNotesModal: {
          isOpen: false,
          context: action.payload,
        },
      };

    case OPEN_SEND_PROOF_MODAL:
      return {
        ...state,
        jobSendProofModal: {
          isOpen: true,
          context: action.payload,
        },
      };
    case CLOSE_SEND_PROOF_MODAL:
      return {
        ...state,
        jobSendProofModal: {
          ...state.jobSendProofModal,
          isOpen: false,
        },
      };

    case OPEN_GUEST_REJECT_PROOF_MODAL:
      return {
        ...state,
        guestRejectProofModal: {
          isOpen: true,
          ...action.payload,
        },
      };

    case CLOSE_GUEST_REJECT_PROOF_MODAL:
      return {
        ...state,
        guestRejectProofModal: {isOpen: false,},
      };

    case OPEN_PROOF_TERMS_MODAL:
      return {
        ...state,
        proofTermsModal: {isOpen: true,},
      };

    case CLOSE_PROOF_TERMS_MODAL:
      return {
        ...state,
        proofTermsModal: {isOpen: false,},
      };

    case OPEN_ADD_PURCHASE_ORDER_MODAL:
      return {
        ...state,
        addPurchaseOrderModal: {isOpen: true,},
      };

    case CLOSE_ADD_PURCHASE_ORDER_MODAL: {
      return {
        ...state,
        addPurchaseOrderModal: {isOpen: false,},
      };
    }

    case OPEN_MERGE_PURCHASE_ORDER_MODAL:
      return {
        ...state,
        mergePurchaseOrderModal: {
          isOpen: true,
          context: action.payload,
        },
      };

    case CLOSE_MERGE_PURCHASE_ORDER_MODAL: {
      return {
        ...state,
        mergePurchaseOrderModal: {
          isOpen: false,
          context: {},
        },
      };
    }

    case OPEN_VIEW_PURCHASE_ORDER_MODAL:
      return {
        ...state,
        viewPurchaseOrderModal: {
          isOpen: true,
          context: action.payload,
        },
      };

    case CLOSE_VIEW_PURCHASE_ORDER_MODAL: {
      return {
        ...state,
        viewPurchaseOrderModal: {
          isOpen: false,
          context: {},
        },
      };
    }

    case OPEN_PURCHASE_ORDER_VARIANTS_MODAL:
      return {
        ...state,
        purchaseOrderVariantsModal: {
          isOpen: true,
          context: action.payload,
        },
      };

    case CLOSE_PURCHASE_ORDER_VARIANTS_MODAL: {
      return {
        ...state,
        purchaseOrderVariantsModal: {
          isOpen: false,
          context: {},
        },
      };
    }

    case OPEN_QUOTE_TERMS:
      return {
        ...state,
        quoteTerms: {open: true,},
      };
    case CLOSE_QUOTE_TERMS:
      return {
        ...state,
        quoteTerms: {open: false,},
      };
    case OPEN_PURCHASE_ORDER_FILE_UPLOAD_MODAL:
      return {
        ...state,
        purchaseOrderFileUploadModal: {
          isOpen: true,
          context: {...action.payload,},
        },
      };
    case CLOSE_PURCHASE_ORDER_FILE_UPLOAD_MODAL:
      return {
        ...state,
        purchaseOrderFileUploadModal: {...initialState.purchaseOrderFileUploadModal,},
      };
    case OPEN_PO_SHIP_TO:
      return {
        ...state,
        poShipToModal: {
          isOpen: true,
          context: {...action.payload,},
        },
      };
    case CLOSE_PO_SHIP_TO:
      return {
        ...state,
        poShipToModal: {
          isOpen: false,
          context: {shipTo: {},},
        },
      };
    case OPEN_SEND_QUOTE_MODAL:
      return {
        ...state,
        sendQuoteModal: {
          isOpen: true,
          context: action.payload,
        },
      };
    case CLOSE_SEND_QUOTE_MODAL:
      return {
        ...state,
        sendQuoteModal: {isOpen: false,},
      };

    case OPEN_JOB_NOTE_MODAL:
      return {
        ...state,
        jobNoteModal: {
          isOpen: true,
          context: {...action.payload,},
        },
      };
    case CLOSE_JOB_NOTE_MODAL:
      return {
        ...state,
        jobNoteModal: {isOpen: false,},
      };

    case OPEN_JOB_ARTWORK_MODAL:
      return {
        ...state,
        jobArtworkModal: {
          isOpen: true,
          context: {...action.payload,},
        },
      };
    case CLOSE_JOB_ARTWORK_MODAL:
      return {
        ...state,
        jobArtworkModal: {isOpen: false,},
      };

    case OPEN_CATALOG_MODAL:
      return {
        ...state,
        catalogModal: {
          isOpen: true,
          context: {...action.payload,},
        },
      };
    case CLOSE_CATALOG_MODAL:
      return {
        ...state,
        catalogModal: {isOpen: false,},
      };
    case OPEN_PRODUCT_PRICE_VARIANTS_MODAL:
      return {
        ...state,
        productPriceVariantsModal: {
          isOpen: true,
          loadingVariantsModal: true,
          context: {...action.payload,},
        },
      };

    case SET_LOADING_VARIANTS_MODAL:
      return {
        ...state,
        productPriceVariantsModal: {
          ...state.productPriceVariantsModal,
          loadingVariantsModal: action.payload,
        },
      };
    case CLOSE_PRODUCT_PRICE_VARIANTS_MODAL:
      return {
        ...state,
        productPriceVariantsModal: {isOpen: false,},
      };

    case OPEN_CREATE_PRODUCT_VARIANT_MODAL:
      return {
        ...state,
        createProductVariationsModal: {
          isOpen: true,
          context: {...action.payload,},
        },
      };
    case CLOSE_CREATE_PRODUCT_VARIANT_MODAL:
      return {
        ...state,
        createProductVariationsModal: {isOpen: false,},
      };

    case OPEN_TAG_MODAL:
      return {
        ...state,
        tagModal: {
          isOpen: true,
          context: {...action.payload,},
        },
      };

    case CLOSE_TAG_MODAL:
      return {
        ...state,
        tagModal: {isOpen: false,},
      };

    case OPEN_JOB_DELETE_MODAL:
      return {
        ...state,
        jobDeleteModal: {
          isOpen: true,
          context: {...action.payload,},
        },
      };
    case CLOSE_JOB_DELETE_MODAL:
      return {
        ...state,
        jobDeleteModal: {isOpen: false,},
      };

    case OPEN_INVOICE_MODAL:
      return {
        ...state,
        draftInvoiceModal: {
          ...state.draftInvoiceModal,
          isOpen: true,
          isDraft: action.payload.isDraft,
          context: {...action.payload.invoice,},
        },
      };
    case CLOSE_INVOICE_MODAL:
      return {
        ...state,
        draftInvoiceModal: {
          ...state.draftInvoiceModal,
          isOpen: false,
          context: {...action.payload,},
        },
      };

    case SET_INVOICE_MODAL_ON_CLOSE:
      return {
        ...state,
        draftInvoiceModal: {
          ...state.draftInvoiceModal,
          onClose: action.payload,
        },
      };

    default:
      return {...state,};
  }
};

export default modalReducer;
