import React, {useCallback} from 'react';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import EventIcon from '@mui/icons-material/Event';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import PersonIcon from '@mui/icons-material/Person';
import {PopupItem, PopupMenu} from '../../Popovers';
import {HoopsPropTypes} from '../../utils';

export function GridViewAddColumnMenu({anchorEl, placement, onAddColumn, onClose}) {
  const handleAddColumn = useCallback((e) => {
    const colType = e.target.closest('li')?.getAttribute('value');
    if (colType === 'Person') {
      onAddColumn('USER', 'User');
    } else {
      onAddColumn(colType.toUpperCase(), colType);
    }
  }, [onAddColumn]);

  return (
    <PopupMenu anchorEl={anchorEl} onClose={onClose} placement={placement}>
      <PopupItem onClick={handleAddColumn} value={'Status'}>
        <ViewAgendaIcon/>
        Status
      </PopupItem>
      <PopupItem onClick={handleAddColumn} value={'Date'}>
        <EventIcon/>
        Date
      </PopupItem>
      <PopupItem onClick={handleAddColumn} value={'Text'}>
        <TextFieldsIcon/>
        Text
      </PopupItem>
      <PopupItem onClick={handleAddColumn} value={'Person'}>
        <PersonIcon/>
        Person
      </PopupItem>
    </PopupMenu>
  );
}

GridViewAddColumnMenu.propTypes = {
  anchorEl: HoopsPropTypes.object,
  placement: HoopsPropTypes.string,
  onAddColumn: HoopsPropTypes.func,
  onClose: HoopsPropTypes.func,
};
