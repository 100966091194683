import {InputAdornment} from '@material-ui/core';
import {maxBy} from 'lodash';
import React, {useContext, useEffect, useState} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {HoopsTextField} from '../../../componentsOld/HoopsTextField';
import {decimalPlaces, toFloat} from '../../../componentsOld/shared/numberUtils';
import {DefaultMarkup} from '../../../pages/markups/defaultMarkupClass';
import MarkupService from '../../../pages/markups/markupsService';
import {markupType} from '../../../pages/markups/markupTypeValues';
import {ItemBuilderContext} from '../ItemBuilderContext';
import priceLogicValues from '../itemLogicValues';

const MarkupField = ({
    defaultValue = '',
    name = '',
    index = null,
    classes,
    ...params
}) => {
    const {watch, control, setValue} = useFormContext();
    const {assignedDefaultMarkupData, onPriceChanged} = useContext(ItemBuilderContext);
    const {grossMargin} = priceLogicValues;
    const priceLogicSelected = watch('priceLogic');
    const isGrossMargin = priceLogicSelected === grossMargin;
    const buyPrice = watch(`decorationCosts[${index}].buyPrice`);
    const setupPrice = watch(`decorationCosts[${index}].setupPrice`);
    const quantity = watch(`decorationCosts[${index}].quantity`);
    const decoration = watch(`decorationCosts[${index}].decoration`);
    const markup = watch(`decorationCosts[${index}].markup`);
    const decorationId = decoration && decoration._id;
    const [defaultMarkupValue, setDefaultMarkupValue] = useState(false);

    useEffect(() => {
        if (!isGrossMargin && quantity > 0 && (buyPrice > 0 || setupPrice > 0)) {

            const {DECORATION} = markupType;

            const productObj = {
                type: DECORATION,
                vendorId: decoration && decoration.vendor && decoration.vendor._id,
                brand: decoration && decoration.brand,
                category: decoration && decoration.category,
                subCategory: decoration && decoration.subCategory,
            };

            const defaultMarkupObj = new DefaultMarkup(productObj, assignedDefaultMarkupData);
            const {isAssignedMarkup, assignedMarkupId, isChildOfMarkup, isChildOfMarkupId} = defaultMarkupObj;
            if (isAssignedMarkup || isChildOfMarkup) {
                MarkupService.getMarkup(assignedMarkupId || isChildOfMarkupId).then(
                    (data) => {
                        let acceptedMarkupArray = [];
                        data.markupRanges.forEach((el) => {
                            if (
                                buyPrice >= el.lowerPrice
                                && buyPrice <= el.upperPrice
                                && quantity >= el.quantity
                            ) {
                                acceptedMarkupArray.push(el);
                            }
                        });
                        const newDefaultMarkupObj = maxBy(acceptedMarkupArray, (el) => el.quantity);
                        const oldDefaultMarkupIndex = acceptedMarkupArray?.findIndex((el) => el.percentage === toFloat(defaultValue));

                        newDefaultMarkupObj && setDefaultMarkupValue(
                            (prevVal) => {
                                let newVal = newDefaultMarkupObj && newDefaultMarkupObj.percentage;
                                if (
                                    ((toFloat(markup) === prevVal) && newVal !== prevVal)
                                    || (markup == null | markup === '')
                                    || ((!defaultValue) && (prevVal === undefined || prevVal === ''))
                                ) {
                                    setValue(name, decimalPlaces(newVal, 2));
                                    return newVal;
                                }
                                else if (
                                    (prevVal === undefined || prevVal === false)
                                    && defaultValue === newVal
                                    && newVal !== prevVal
                                )
                                    {return newVal;}
                                else if (oldDefaultMarkupIndex > -1) {
                                    setValue(`decorationCosts[${index}].markup`, decimalPlaces(toFloat(newVal), 2));
                                    return newVal;
                                }
                                return newVal;
                            }
                        );
                    }
                );
            }
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [decorationId, buyPrice, quantity, defaultMarkupValue]);

    return (
        <Controller
            name={name}
            defaultValue={defaultValue}
            render={(field) => (
                <HoopsTextField
                    type='number'
                    responsive
                    {...field}
                    onChange={(e) => {onPriceChanged(); field.onChange(e);}}
                    disabled={isGrossMargin}
                    className={isGrossMargin && classes.disabledTextField}
                    InputProps={{endAdornment: <InputAdornment position={'end'}>%</InputAdornment>}}
                    {...params}
                />
            )}
            control={control}
        />
    );
};

export default MarkupField;
