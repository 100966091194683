import {Button, Dialog} from '@material-ui/core';
import React, {Fragment} from 'react';
import DialogTitle from '../shared/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import {Elements, StripeProvider} from 'react-stripe-elements';
import Grid from '@material-ui/core/Grid';
import PaymentMethodForm from './PaymentMethodForm';

class AddCardDialog extends React.Component {
  state = {isOpen: false};

  openDialog = () => {
    this.setState({
      ...this.state,
      isOpen: true
    });
  };

  handleClose = () => {
    this.setState({
      ...this.state,
      isOpen: false
    });
  };

  render() {
    const {isOpen} = this.state;
    return (
      <Fragment>
        <Button color={'primary'} variant={'contained'} onClick={this.openDialog}>Add New Card</Button>
        <Dialog open={isOpen} maxWidth={'sm'} fullWidth={true}>
          <DialogTitle onClick={this.handleClose}>Add New Payment Method</DialogTitle>
          <DialogContent>
            <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
              <Fragment>
                <Elements>
                  <Fragment>
                    <Grid container justifyContent={'center'}>
                      <Grid item xs={12}>
                        <PaymentMethodForm onSave={this.handleClose}/>
                      </Grid>
                    </Grid>
                  </Fragment>
                </Elements>
              </Fragment>
            </StripeProvider>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

export default AddCardDialog;
