// TODO: fix eslint disable
/* eslint-disable no-shadow */

import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import {get} from 'lodash';
import classNames from 'classnames';

function RenderAutocompleteField({
  input: {name, value, onChange},
  meta: {error} = {touch: false, error: null},
  label,
  textFieldProps: {inputProps: textFieldInputProps},
  textFieldProps,
  fullWidth,
  disabled,
  ButtonComponent,
  ...rest
}) {
  return (
    <Autocomplete
      onChange={(e, value) => onChange(value)}
      disabled={disabled}
      fullWidth={fullWidth}
      PopperComponent={({
        children,
        ...params
      }) => (
        <Popper
          {...params}
          placement={'bottom-start'}
          style={{width: 'auto'}}
        >
          {children}
        </Popper>
      )}
      PaperComponent={({children, ...paperProps}) => (
        <Paper {...paperProps}>
          {children}
          {!!ButtonComponent && ButtonComponent}
        </Paper>
      )}
      {...rest}
      renderInput={({inputProps, ...rest}) => {
        const customClassName = get(textFieldInputProps, 'className', '');
        return (
          <TextField
            {...textFieldProps}
            {...rest}
            inputProps={{
              ...inputProps,
              className: classNames(customClassName, inputProps.className),
              autoComplete: 'off',
            }}
            fullWidth={fullWidth}
            name={name}
            error={(!disabled && Boolean(error))}
            helperText={(error) ? error : null}
            value={value || ''}
            label={label}
          />
        );
      }}
    />
  );
}

export default RenderAutocompleteField;
