// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {useLazyQuery, useMutation} from '@apollo/client';
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon, makeStyles, Typography} from '@material-ui/core';
import React, {useState} from 'react';
import useOnce from '../hooks/useOnce';
import FileList from '../modules/file/FileList';
import {GQL_GET_FILES, GQL_UPDATE_FILE} from '../queries/files';
import theme from '../ui/theme';

const useStyles = makeStyles((theme) => ({
  dialog: {'& .MuiDialog-paper': {padding: theme.spacing(2)}},
  greyText: {color: theme.colors.grey.main}
}));

const standardFilePreviewOptions = {
  thumbWidth: 80,
  thumbHeight: 80,
  tooltipMaxWidth: 650
};

export const AddExistingFileModal = ({
  isOpen = false,
  entityIds = null,
  title = '',
  subTitle = '',
  getEntityIds = [],
  customerId = null,
  isWorkable = true,
  canCreateTask = true,
  showCustomerBtn = false,
  showUploadFileBtn = false,
  canDelete = true,
  createTaskState = {},
  filePreviewOptions = standardFilePreviewOptions,
  onCloseModal = () => null,
  handleDeleteAction = () => null,
  handleEditAction = () => null,

}) => {
  const classes = useStyles();

  const fileActionOptions = {
    view: true,
    download: true,
    edit: true,
    delete: canDelete,
    createTask: canCreateTask,
    createTaskState
  };

  const [files, setFiles] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [udpateFiles, {loading: loadingUpdate}] = useMutation(GQL_UPDATE_FILE, {
    fetchPolicy: 'no-cache',
    onCompleted: (result) => {
      if (result) {
          onCloseModal();
      }
    },
  });

  const [getFiles, {loading}] = useLazyQuery(GQL_GET_FILES, {
    fetchPolicy: 'no-cache',
    variables: {
      entityIds: entityIds,
      isWorkable: isWorkable,
      customerId: customerId,
      isProof: false
    },
    onCompleted: (result) => {
      if (result) {
        setFiles(result.files);
        for (const file of result.files) {
          if (file.entities.some((entity) => getEntityIds.indexOf(entity) >= 0)) {
            setSelectedFiles((prev) => [...prev, file._id]);
          }
        }

      }
    },
  });

  const handleSelectionChange = (selections) => {
    setSelectedFiles(selections);
  };

  const handleSubmit = () => {

    files.forEach((file) => {
      let entities = file.entities;
      let editedFile = false;
      if (selectedFiles.indexOf(file._id) >= 0) {
        entities.push(...getEntityIds);
        editedFile = true;
      } else if(entities.some((entity) => getEntityIds.indexOf(entity) >= 0)){
        entities = entities.filter((el) => !getEntityIds.includes(el));
        editedFile = true;

      }
      const updatedFile = {
        description: file.description,
        entities: entities,
        status: file.status,
      };
      editedFile && udpateFiles({variables: {_id: file._id, file: updatedFile}});
    });

  };

  useOnce(getFiles);

  return (
    <Dialog
      open={isOpen}
      maxWidth={'lg'}
      fullWidth={true}
      onClose={onCloseModal}
      className={classes.dialog}
    >
      <DialogTitle>
        <Typography variant='h4'>{title}</Typography>
        <Typography variant='body2' className={classes.greyText}>{subTitle}</Typography>
      </DialogTitle>
      <DialogContent>

        <FileList
          showAddButton={false}
          canUpload={false}
          files={files}
          fileActionOptions={fileActionOptions}
          filePreviewOptions={filePreviewOptions}
          showCustomerBtn={showCustomerBtn}
          showUploadFileBtn={showUploadFileBtn}
          showTitle={false}
          selectable={true}
          selected={selectedFiles}
          onSelect={handleSelectionChange}
          onDeleteAction={handleDeleteAction}
          onEditAction={handleEditAction}
          loading={loading}
        />

      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={'space-between'}>
          <Grid item>
            <Button onClick={onCloseModal}>
              <Icon>close</Icon>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant={'contained'}
                  color={'primary'}
                  onClick={handleSubmit}
                  disabled={loadingUpdate}
                  type='submit'
                >
                  {loadingUpdate ? <><CircularProgress size={16} style={{marginRight: theme.spacing(1)}} />Associate Files</> : 'Associate Files'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );

};

export default AddExistingFileModal;
