import {gql} from 'apollo-boost';
import {GQL_GCFILE_FRAGMENT} from './files';

export const GQL_TASK_LIST_PAGINATED_FRAGMENT = gql`
    fragment TaskListFragmentPagination on TaskPagination {
        items {
            _id
            title
            description
            status
            dueAt
            endAt
            allDay
            memberIds
            members {
                _id
                fullName
            }
            entities {
                entityType
                entityId
            }
            jobIds
            jobs {
                _id
                number
                customer {
                _id
                name
                }
            }
            quoteIds
            quotes {
                _id
                number
                customer {
                _id
                name
                }
            }
            fileIds
            
            resourceId
            
            isDone
        }
    }
`;

export const GQL_TASK_LIST_FRAGMENT = gql`
    fragment TaskListFragment on Task {
        _id
        title
        description
        status
        dueAt
        endAt
        allDay
        customer {
            name
            _id
        }
        customerId
        memberIds
        createdBy {
            _id
            fullName
        }
        members {
            _id
            fullName
        }
        entities {
            entityType
            entityId
        }
        jobIds
        jobs {
            _id
            number
            customer {
                _id
                name
            }
        }
        quoteIds
        quotes {
            _id
            number
            customer {
            _id
            name
            }
        }
        fileIds

        resourceId
        
        isDone
    }
`;

export const GQL_TASK_DETAIL_FRAGMENT = gql`
    ${GQL_GCFILE_FRAGMENT}
    fragment TaskDetailFragment on Task {
        _id
        title
        description
        status
        dueAt
        endAt
        allDay
        customer {
            name
            _id
        }
        customerId
        createdBy {
            _id
            fullName
        }

        memberIds
        members {
            _id
            fullName
        }
        entities {
            entityType
            entityId
        }
        jobIds
        jobs {
            _id
            number
            customer {
            _id
            name
            }
        }
        quoteIds
        quotes {
            _id
            number
            customer {
            _id
            name
            }
        }
        fileIds
        files {
            ...GCFileFragment
        }

        resourceId
        resource {
            _id
            title
        }
        isDone
    }
`;

export const GQL_GET_TASK = gql`
    ${GQL_TASK_DETAIL_FRAGMENT}
    query getTask($_id: MongoID!) {
        taskOne(filter: {_id: $_id}) {
            ...TaskDetailFragment
        }
    }
`;

export const GQL_GET_TASKS = gql`
    ${GQL_TASK_LIST_FRAGMENT}
    query getTasks(
        $filter: FilterFindManyTaskInput,
        $sort: SortFindManyTaskInput
        ) {
            taskMany(
                filter: $filter,
                sort: $sort
            ) {
                ...TaskListFragment
            }
        }
`;

export const GQL_CREATE_TASK = gql`
    ${GQL_TASK_DETAIL_FRAGMENT}
    mutation createTask($record: CreateOneTaskInput!) {
        taskCreateOne(record: $record) {
            recordId
            record {
                ...TaskDetailFragment
            }
        }
    }
`;

export const GQL_UPDATE_TASK = gql`
    ${GQL_TASK_DETAIL_FRAGMENT}
    mutation updatetask($_id: MongoID!, $record: UpdateByIdTaskInput!) {
        taskUpdateById(_id: $_id, record: $record) {
            recordId
            record {
                ...TaskDetailFragment
            }
        }
    }
`;

export const GQL_DELETE_TASK = gql`
    mutation deleteTask($_id: MongoID!) {
        taskRemoveById(_id: $_id) {
            recordId
        }
    }
`;

export const GQL_TASK_PAGINATION = gql`
    query TaskPagination(
        $filter: FilterFindManyTaskInput,
        $sort: SortFindManyTaskInput,
        $perPage: Int,
        $page: Int
    ){
        taskPagination(
            filter: $filter,
            sort: $sort,
            perPage: $perPage,
            page: $page
        ) {
            items {
                ...TaskListFragment
            }
            pageInfo {
                currentPage
                perPage
                pageCount
                itemCount
                hasNextPage
                hasPreviousPage
            }
        }
    }
    ${GQL_TASK_LIST_FRAGMENT}
`;
