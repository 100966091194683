// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import {InputAdornment} from '@material-ui/core';
import {maxBy} from 'lodash';
import React, {useContext, useEffect, useState} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {HoopsTextField} from '../../../componentsOld/HoopsTextField';
import {decimalPlaces, toFloat} from '../../../componentsOld/shared/numberUtils';
import {DefaultMarkup} from '../../../pages/markups/defaultMarkupClass';
import MarkupService from '../../../pages/markups/markupsService';
import {markupType} from '../../../pages/markups/markupTypeValues';
import {ItemBuilderContext} from '../ItemBuilderContext';
import priceLogicValues from '../itemLogicValues';

const MarkupField = ({
    defaultValue = '',
    name = '',
    index = null,
}) => {
    const {watch, control, setValue} = useFormContext();
    const {assignedDefaultMarkupData, product, onPriceChanged} = useContext(ItemBuilderContext);
    const [defaultMarkupValue, setDefaultMarkupValue] = useState(false);

    const {grossMargin} = priceLogicValues;
    const priceLogicSelected = watch('priceLogic');
    const isGrossMargin = priceLogicSelected === grossMargin;

    const markup = watch(`additionalCosts[${index}].markup`);

    const buyPrice = toFloat(watch(`additionalCosts[${index}].buyPrice`));
    const quantity = 1;
    const vendorId = watch(`additionalCosts[${index}].vendorId`);

    useEffect(() => {
        if (!isGrossMargin && buyPrice > 0) {

            const {ADDITIONAL_COST} = markupType;

            const productObj = {
                type: ADDITIONAL_COST,
                vendorId: vendorId && vendorId,
                brand: null,
                category: null,
                subCategory: null
            };

            const defaultMarkupObj = new DefaultMarkup(productObj, assignedDefaultMarkupData);
            const {isAssignedMarkup, assignedMarkupId, isChildOfMarkup, isChildOfMarkupId} = defaultMarkupObj;

            if (isAssignedMarkup || isChildOfMarkup) {
                MarkupService.getMarkup(assignedMarkupId || isChildOfMarkupId).then(
                    (data) => {
                        let acceptedMarkupArray = [];
                        data.markupRanges.forEach((el) => {
                            if (
                                buyPrice >= el.lowerPrice
                                && buyPrice <= el.upperPrice
                                && quantity >= el.quantity
                            ) {
                                acceptedMarkupArray.push(el);
                            }
                        });
                        const newDefaultMarkupObj = maxBy(acceptedMarkupArray, (el) => el.quantity);
                        newDefaultMarkupObj && setDefaultMarkupValue(
                            (prevVal) => {
                                let newVal = newDefaultMarkupObj && newDefaultMarkupObj.percentage;
                                if (
                                    (toFloat(markup) === prevVal && newVal !== prevVal)
                                    || markup == null
                                    || (!defaultValue && (prevVal === undefined || prevVal === ''))
                                ) {
                                    setValue(name, decimalPlaces(newVal, 2));
                                    return toFloat(newVal);
                                }
                                if (prevVal == null && toFloat(defaultValue) === newVal && newVal !== prevVal)
                                    {return toFloat(newVal);}
                                return toFloat(newVal);
                            }
                        );
                    }
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buyPrice, vendorId]);

    return (
        <Controller
            name={name}
            defaultValue={defaultValue === null ? null : decimalPlaces(defaultValue, 2)}
            render={(field) => (
                <HoopsTextField
                    type='number'
                    responsive
                    {...field}
                    onChange={(e) => {onPriceChanged(); field.onChange(e);}}
                    disabled={isGrossMargin}
                    InputProps={{endAdornment: <InputAdornment position={'end'}>%</InputAdornment>}}
                />
            )}
            control={control}
        />
    );
};

export default MarkupField;
