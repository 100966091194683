// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import {MenuItem, Typography, Button, Grid, FormHelperText} from '@material-ui/core';
import arrayMutators from 'final-form-arrays';
import {upperFirst} from 'lodash';
import React, {useCallback, useEffect, useState} from 'react';
import {Field, Form} from 'react-final-form';
import {FieldArray} from 'react-final-form-arrays';
import {useDispatch, useSelector} from 'react-redux';
import {getCurrentUser} from '../../actions/action-types';
import TaxService from '../tax/TaxService';
import {GQL_UPDATE_XERO_SETTINGS, GQL_XERO_DETAILS} from '../../queries/companies';
import {client} from '../../servicesOld/GraphQLService';
import HoopsFormDialog from '../../componentsOld/modal/HoopsFormDialog';
import {renderSelectField} from '../../componentsOld/shared/formHelpers/fields';
import DeleteIcon from '@material-ui/icons/Delete';
import {getCompany} from '../../actions/action-types/company';

function SaveButton({handleSubmit}) {
  return (
    <Button type={'button'} color={'primary'}
      variant={'contained'} onClick={handleSubmit}>Submit</Button>
  );
}

export default function XeroSettingsModal({isOpen, setIsOpen}) {
  const dispatch = useDispatch();
  const [xeroAccounts, setXeroAccounts] = useState([]);
  const [xeroAccountsExpense, setXeroAccountsExpense] = useState([]);
  const [xeroTaxRates, setXeroTaxRates] = useState([]);

  const taxesSelector = (state) => state.companyReducer.company;
  const {taxes, accountingPlatform} = useSelector(taxesSelector);

  const handleFormSubmit = useCallback(({
    xeroAccountCode,
    xeroAccountCodeExpense,
    defaultDueDate,
    defaultInvoiceStatus,
    xeroTaxTypeMapping,
    xeroTaxTypeDefault,
    xeroTaxTypeDefaultExpense
  }) => client.mutate({
      mutation: GQL_UPDATE_XERO_SETTINGS,
      variables: {
        data: {
          defaultDueDate,
          defaultInvoiceStatus,
          xeroAccountCode,
          xeroAccountCodeExpense,
          xeroTaxTypeMapping,
          xeroTaxTypeDefault,
          xeroTaxTypeDefaultExpense
        }
      }
    })
      .then(() => {
        dispatch(getCurrentUser());
        dispatch(getCompany()); // we want to have the latest data in the app now
        setIsOpen(false);
      }), [setIsOpen, dispatch]);

  useEffect(() => {
    client.query({query: GQL_XERO_DETAILS})
      .then(({data}) => {
        setXeroAccounts(data.xeroAccounts);
        setXeroAccountsExpense(data.xeroAccountsExpense);
        setXeroTaxRates(data.xeroTaxRates);
      });
  }, []);

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <Form
        initialValues={accountingPlatform}
        onSubmit={handleFormSubmit}
        mutators={{...arrayMutators}}
        keepDirtyOnReinitialize={true}
        render={({handleSubmit, form, submitting, values, form: {mutators: {push, pop}}}) => (
            <>
              <HoopsFormDialog
                open={isOpen}
                onCancel={() => {
                  form.reset();
                  setIsOpen(false);
                }}
                SaveButton={() => <SaveButton handleSubmit={handleSubmit} />}
                title={'Xero Settings'}
                maxWidth={'sm'}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field name={'defaultDueDate'}
                      label={'Default Due Date'}
                      component={renderSelectField}>
                      <MenuItem value={'ON_RECEIPT'}>On Receipt</MenuItem>
                      <MenuItem value={'INVOICE_PLUS_30_DAYS'}>30 Days From Invoice Date</MenuItem>
                      <MenuItem value={'INVOICE_PLUS_15_DAYS'}>14 Days From Invoice Date</MenuItem>
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name={'defaultInvoiceStatus'}
                      label={'Default Invoice Status'}
                      component={renderSelectField}>
                      <MenuItem value={'DRAFT'}>Draft</MenuItem>
                      <MenuItem value={'SUBMITTED'}>Submitted</MenuItem>
                      <MenuItem value={'AUTHORISED'}>Authorised</MenuItem>
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name={'xeroAccountCode'}
                      label={'Revenue Account'}
                      required={true}
                      component={renderSelectField}>
                      {xeroAccounts.map((acc) => <MenuItem key={acc.code} value={acc.code}>{acc.name} - {acc.code}</MenuItem>)}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name={'xeroAccountCodeExpense'}
                      label={'Expense Account'}
                      required={true}
                      component={renderSelectField}>
                      {xeroAccountsExpense.map((acc) => <MenuItem key={acc.code} value={acc.code}>{acc.name} - {acc.code}</MenuItem>)}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='h5'>Tax Mapping</Typography>
                    <Grid container alignItems='center'
                      justifyContent='center'>
                      <Grid item xs>
                        <Field
                          label='Default Xero Revenue Tax'
                          required
                          name={'xeroTaxTypeDefault'}
                          component={renderSelectField}
                          placeholder='Choose Default Hoops Income Tax Rate'
                        >
                          {xeroTaxRates.map((tax) => <MenuItem key={tax.taxType} value={tax.taxType}>{tax.name} - {tax.rate}%</MenuItem>)}
                        </Field>
                        <FormHelperText>This will be used if a tax mismatch occurs</FormHelperText>

                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems='center'
                      justifyContent='center'>
                      <Grid item xs>
                        <Field
                          label='Default Xero Expense Tax'
                          required
                          name={'xeroTaxTypeDefaultExpense'}
                          component={renderSelectField}
                          placeholder='Choose Default Hoops Expense Tax Rate'
                        >
                          {xeroTaxRates.map((tax) => <MenuItem key={tax.taxType} value={tax.taxType}>{tax.name} - {tax.rate}%</MenuItem>)}
                        </Field>
                        <FormHelperText>This will be used if a tax mismatch occurs</FormHelperText>

                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <FieldArray name='xeroTaxTypeMapping'>
                      {({fields}) =>
                        fields.map((name, index) => (
                          <Grid container key={name} alignItems='center' justifyContent='center' spacing={2}>
                            <Grid item xs>
                              <Field
                                label='Xero Tax Type'
                                name={`${name}.xeroTaxType`}
                                component={renderSelectField}
                                placeholder='Choose Xero Tax Rate'
                              >
                                {xeroTaxRates.map((tax) => <MenuItem key={tax.taxType} value={tax.taxType}>{tax.name} - {tax.rate}%</MenuItem>)}
                              </Field>
                            </Grid>
                            <Grid item xs={6}>
                              <Field
                                label='Hoops Tax Rate'
                                name={`${name}.hoopsTaxId`}
                                component={renderSelectField}
                                placeholder='Choose Hoops Tax Rate'
                              >
                                {taxes.map((tax) => <MenuItem key={tax._id} value={tax._id}>{upperFirst(tax.type)}: {tax.name} - {TaxService.getTaxRateTotal(tax)}%</MenuItem>)}
                              </Field>
                            </Grid>
                            <Grid item xs='auto'>
                              <Button onClick={() => fields.remove(index)}><DeleteIcon /></Button>
                            </Grid>
                          </Grid>
                        ))
                      }
                    </FieldArray>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      color='primary'
                      variant='contained'
                      onClick={() => push('xeroTaxTypeMapping', undefined)}
                    >
                      Add Tax Mapping
                    </Button>
                  </Grid>
                </Grid>
              </HoopsFormDialog>
            </>
          )}
      />
    </>
  );
}
