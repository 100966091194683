import {Grid, Link} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import React from 'react';

const ExpandMoreButton = ({
  onClick = () => null,
  showSubItems = false,
}) => (
    <Link component='button' onClick={onClick} variant='body2' underline='none' style={{marginTop: 4}}>
      <Grid container>
        <Grid item>
          {showSubItems
            ? 'Hide Details'
            : 'View Details'
          }
        </Grid>
        <Grid item>
          {showSubItems
            ? <ExpandLessIcon fontSize='small' />
            : <ExpandMoreIcon fontSize='small' />
          }
        </Grid>
      </Grid>
    </Link>
  );

export default ExpandMoreButton;
