import * as React from 'react';
import {BodyText} from '../../Text';
import {Column} from '../../Layout';
import {registerGlobalStyle} from '../../../theme';

registerGlobalStyle('.power-grid .title-and-info-cell', () => ({
  justifyContent: 'space-evenly',
  overflow: 'hidden',
  '.text': {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '&:is(.MuiDataGrid-root--densityCompact .title-and-info-cell)': {
    justifyContent: 'center',
    p: {lineHeight: 1.2,}
  }
}));

export function TitleAndInfoCell({title, info}) {
  return (
    <Column className={'title-and-info-cell'}>
      <BodyText text={title}/>
      <BodyText text={info} descriptive noWrap small/>
    </Column>
  );
}
