import React, {useCallback} from 'react';
import {Grid,} from '@material-ui/core';
import {openProductPriceVariantsModal as _openProductPriceVariantsModal} from '../../../actions/modals';
import {useDispatch, useSelector} from 'react-redux';
import ProductVariantPriceTable from '../../Products/ProductVariantPriceTable';
import LoadingButton from '../../shared/LoadingButton';
import {get} from 'lodash';

function Prices() {
  const dispatch = useDispatch();
  const currencySymbolSelector = (state) => get(state, 'authReducer.userData.company.currencySymbol');
  const productSelector = (state) => state.catalogReducer.activeProduct;
  const loadingVariantsSelector = (state) => state.modalReducer.productPriceVariantsModal.loadingVariantsModal;
  const product = useSelector(productSelector);
  const loadingVariants = useSelector(loadingVariantsSelector);
  const currencySymbol = useSelector(currencySymbolSelector);

  const openProductPriceVariantsModal = useCallback(() => {
    dispatch(_openProductPriceVariantsModal(product));
  }, [product, dispatch]);

  return (
    <Grid container>
      {!product.readOnly &&
        <Grid item >

          <LoadingButton
            variant={'outlined'}
            color={'primary'}
            onClick={openProductPriceVariantsModal}
            loading={loadingVariants}
          >
            Edit Variants
          </LoadingButton>

        </Grid>}
      <Grid item xs={7}>
        <ProductVariantPriceTable product={product} currencySymbol={currencySymbol} />
      </Grid>
    </Grid>
  );
}

export default Prices;
