import {TableHead, TableRow} from '@material-ui/core';
import React from 'react';
import {HoopsTableCell, isSelectable} from '../HoopsTable';

export const JobItemTableVariantDecorationHeader = ({mode = 'checkbox'}) => (
        <TableHead>
            <TableRow>
            {isSelectable(mode) ? <HoopsTableCell /> : null}
                <HoopsTableCell colSpan={2}>
                    Decorations
                </HoopsTableCell>
                <HoopsTableCell colSpan={1}>
                    Vendor
                </HoopsTableCell>
                <HoopsTableCell>
                    Color
                </HoopsTableCell>
                <HoopsTableCell>
                    Size
                </HoopsTableCell>
                <HoopsTableCell>
                    Position
                </HoopsTableCell>
                <HoopsTableCell>
                    {/* Status // No status for decoration yet */}
                </HoopsTableCell>
            </TableRow>
        </TableHead>
    );

export default JobItemTableVariantDecorationHeader;
