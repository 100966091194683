// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-shadow */

import {makeStyles} from '@material-ui/core';
import {get} from 'lodash';
import MaterialTable from 'material-table';
import numeral from 'numeral';
import React from 'react';
import theme from '../../../../ui/theme';

const baseColumnWidth = {width: '100px', maxWidth: '100px'};
const headerStyle = {
  width: '100px', maxWidth: '100px', backgroundColor: theme.colors.grey.ultraLight,
  color: theme.colors.grey.main,
  fontWeight: 400,
};

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiPaper-root': {
      borderRadius: 0,
      boxShadow: 'none'
    }
  }
}));

export const RenderRate = ({rate, company}) => (
    <>
      <span>{company.currencySymbol} </span>
      <span>{numeral(rate).format('0,0.00')}</span>
    </>
  );

const AdditionalItemsTable = ({
  company,
  additionalItems,
  description,
}) => {
  const classes = useStyles();
  const columns = [{
    title: 'Name/Description',
    field: 'description',
    cellStyle: baseColumnWidth,
    headerStyle: headerStyle
  }, {
    title: '',
    render: () => '',
    cellStyle: baseColumnWidth,
    headerStyle: headerStyle
  }, {
    title: '',
    render: () => '',
    cellStyle: baseColumnWidth,
    headerStyle: headerStyle
  }, {
    title: '',
    render: () => '',
    cellStyle: baseColumnWidth,
    headerStyle: headerStyle
  }, {
    title: 'Quantity',
    field: 'quantity',
    cellStyle: baseColumnWidth,
    headerStyle: headerStyle
  }, {
    title: 'Rate',
    field: 'rate',
    cellStyle: baseColumnWidth,
    headerStyle: headerStyle,
    render: (row) => <RenderRate company={company} rate={get(row, 'rate', 0)} />
  }];

  return (
    <div className={classes.root}>
      <MaterialTable
        title={description}
        columns={columns}
        data={additionalItems}
        options={{
          showTitle: false,
          paging: false,
          search: false,
          sorting: false,
          draggable: false,
          toolbar: false
        }}
      />
    </div>
  );
};

export default AdditionalItemsTable;
