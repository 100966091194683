import React, {useCallback, useRef} from 'react';
import {Box, List, ListItem, Popover, Typography} from '@mui/material';
import theme from '../../../../ui/theme';
import {useMountEffect} from '../../../../hooks';
import {CellContainerPopover} from './CellContainerPopover';
import {get} from 'lodash';
import {UserAvatar} from '../../../../componentsLib/Basic';

export const AvatarCell = (props) => {
  const {row, field,  colDef} = props;
  const userId = get(row, field);
  const user = colDef.valueOptions?.find((v) => v.value === userId);

  return (
          <CellContainerPopover {...props} popover={AvatarPopover} options={colDef.valueOptions} value={userId}>
            <UserAvatar name={user?.label} initials={user?.initials}/>
          </CellContainerPopover>
  );
};

const AvatarPopover = ({value, options, onChange, onClose, ...props}) => (
          <PopoverList
                  className={'AvatarSelectPopover'}
                  id={props.id}
                  onClose={onClose}
                  onSelect={onChange}
                  selected={value}
                  {...props}
          >
            {options?.map((o) =>
                    <Box key={o.value}>
                      <UserAvatar name={o.label} intials={o.initials}/>
                      <Typography>{o.label}</Typography>
                    </Box>
            )}
          </PopoverList>
  );

const PopoverList = ({id, anchorEl, onSelect, onClose, children, selected, ...props}) => {
  const handleSelect = useCallback((index) => {
    if (onSelect) {
      onSelect(index);
    }
    if (onClose) {
      onClose();
    }
  }, [onSelect, onClose]);

  return (
    <Popover
      id={id}
      anchorEl={anchorEl}
      onClose={onClose}
      className={'PopoverList'}
      {...props}
    >
      <SelectList onSelect={handleSelect} selected={selected}>
        {children}
      </SelectList>
    </Popover>
  );
};

const SelectList = ({onSelect, selected, children}) => {
  const selectedRef = useRef(null);

  const handleClick = useCallback((event) => {
    let target = event.target;
    while (target && target.tagName !== 'LI') {
      target = target.parentNode;
    }
    if (target && target.parentNode && target.parentNode.tagName === 'UL') {
      const index = Array.from(target.parentNode.children).indexOf(target);
      onSelect(children[index].key);
    }
  }, [onSelect, children]);

  useMountEffect(() => {
    if (selectedRef.current) {
      // If the current item is out of view we will scroll it into view
      const rect = selectedRef.current.getBoundingClientRect();
      const parentRect = selectedRef.current.parentNode.getBoundingClientRect();
      if (rect && parentRect && (rect.bottom < parentRect.top + 8 || rect.top > parentRect.bottom - 8)) {
        selectedRef.current.scrollIntoView(true);
      }
    }
  });

  return (
    <List
      sx={{
        maxHeight: '320px',
        overflowY: 'auto',
        position: 'relative',
        'li:hover': {backgroundColor: theme.palette.secondary.hover,},
        'li > div': {
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          '& > p': {paddingLeft: '1em',},
        },
        'li.selected::after': {
          content: '""',
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          borderRadius: '4px',
          border: `2px solid ${theme.palette.primary.main}`,
        }
      }}>
      {children?.map((c) =>
        <ListItem key={c.key} className={`${c.key === selected ? 'selected' : ''}`} onClick={handleClick} ref={c.key === selected ? selectedRef : null}>
          {c}
        </ListItem>)
      }
    </List>
  );
};
