import React from 'react';
import {Button, Grid, IconButton} from '@material-ui/core';
import {Field} from 'react-final-form';
import {MultipleAutocompleteField, renderTextField} from '../shared/formHelpers/fields';
import {autocompleteRequired, required} from '../shared/formHelpers/validation';
import {FieldArray} from 'react-final-form-arrays';
import DeleteIcon from '@material-ui/icons/Delete';

function SizeColorPriceBreakFields({form: {mutators, getFieldState}, sizeOptions = [], colorOptions = []}) {

  const priceBreaks = getFieldState('priceBreaks');
  return (
    <>
      <Grid item xs={12}>
        <Field
          name='sizes'
          freeSolo
          component={MultipleAutocompleteField}
          options={sizeOptions}
          helperText={'Type the name of a size and press tab or enter.'}
          label='Sizes *'
          validate={autocompleteRequired}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name='colors'
          freeSolo
          component={MultipleAutocompleteField}
          options={colorOptions}
          helperText={'Type the name of a color and press tab or enter.'}
          label='Colors *'
          validate={autocompleteRequired}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <FieldArray name='priceBreaks'>
          {({fields}) =>
            fields.map((name, index) => (
              <Grid container key={name} spacing={2}>
                <Grid item xs={6}>
                  <Field
                    name={`${name}.quantity`}
                    component={renderTextField}
                    label='Quantity *'
                    type={'number'}
                    validate={required}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Field
                    name={`${name}.price`}
                    component={renderTextField}
                    label='Price *'
                    type={'number'}
                    inputProps={{step: '0.01'}}
                    validate={required}
                  />
                </Grid>
                <Grid item xs={1} style={{display: 'flex', alignItems: 'center'}}>
                  <IconButton size={'small'} onClick={() => mutators.remove('priceBreaks', index)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))
          }
        </FieldArray>
      </Grid>
      <Grid item xs={12}>
        <Grid item>
          <Button
            color={'primary'}
            onClick={() => {
              mutators.push('priceBreaks', undefined);
            }}
          >Add{priceBreaks && priceBreaks.length > 0 && ' another'} price break</Button>
        </Grid>
      </Grid>
    </>
  );
}

export default SizeColorPriceBreakFields;
