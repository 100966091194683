// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React, {Component} from 'react';
import {
  Dialog,
  Typography,
} from '@material-ui/core';
import DialogTitle from '../../DialogTitle';
import {connect} from 'react-redux';
import {closeCatalogModal as _closeCatalogModal} from '../../../../actions/modals';
import CatalogModalForm from './CatalogModalForm';

class CatalogModal extends Component {

  closeCatalogModal = () => {
    const {closeModal} = this.props;
    closeModal();
  };

  render() {
    const {isOpen, closeModal} = this.props;
    return (
      <Dialog
        open={isOpen}
        maxWidth={'sm'}
        fullWidth={true}
        onClose={this.closeCatalogModal}
      >
        <DialogTitle onClick={this.closeCatalogModal}>
          <Typography variant='h5'>Add a Product</Typography>
        </DialogTitle>
        <CatalogModalForm closeModal={closeModal} />
      </Dialog>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {closeModal: () => dispatch(_closeCatalogModal()),};
}

function mapStateToProps(state) {
  return {isOpen: state.modalReducer.catalogModal.isOpen,};
}

CatalogModal = connect(mapStateToProps, mapDispatchToProps)(CatalogModal);
export default CatalogModal;
