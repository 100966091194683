import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import {HoopsButton} from '.';
import {HoopsTextField} from '../componentsOld/HoopsTextField';
import {Typography} from '@mui/material';
import {formStyles} from '../ui/theme/formStyles';
import {HoopsIconButton} from '../componentsOld/HoopsIconButton';
import {Delete, Edit} from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';

export const EditableList = ({
  editing = true,
  data = [],
  fieldName = '',
  fieldReference = '',
  onChange = () => null
}) => {

  const [listData, setListData] = React.useState(data.sort((a, b) => {
    if (a.toLowerCase() > b.toLowerCase()) {
      return 1;
    } else {
      return -1;
    }
  }));
  const [editItem, setEditItem] = React.useState(null);
  const [addItem, setAddItem] = React.useState();
  const [addItemError, setAddItemError] = React.useState();
  const [updateValue, setUpdateValue] = React.useState();

  const handleDelete = (item) => {
    setListData((prev) => {
      const listItemRemoved = prev.filter((el) => el !== item);
      onChange({[fieldReference]: listItemRemoved});
      return (listItemRemoved);
    });
  };

  const handleEditItem = (item) => {
    setEditItem(item);
    setUpdateValue(item);
  };

  const handleSaveItem = () => {
    setListData((prev) => {
      const cloneList = [...prev];
      const index = cloneList.indexOf(editItem);
      cloneList[index] = updateValue;
      onChange({[fieldReference]: cloneList});
      return (cloneList);
    });

  };

  const handleAdd = () => {
    if (!addItem || addItem === '') {
      setAddItemError(`${fieldName} field is empty`);
      return;
    }
    setListData((prev) => {
      const cloneList = [...prev];
      cloneList.push(addItem);
      const sortedList = cloneList.sort((a, b) => {
        if (a.toLowerCase() > b.toLowerCase()) {
          return 1;
        } else {
          return -1;
        }
      });
      onChange({[fieldReference]: sortedList});
      return (sortedList);
    });
    setAddItem('');
  };

  const handleValidateAddItem = (value) => {
    setAddItemError(null);
    setAddItem(value);
    const index = listData.findIndex((el) => el === value);
    if (index > -1) {
      setAddItemError(`${fieldName} already exists`);
    }
  };

  return (
    <>
      {editing ? null : <Typography variant={'body2'} color={'textSecondary'}>{fieldName}</Typography>}
      {editing &&
        <>
          <Typography variant='caption' sx={formStyles.labelText}>{fieldName}</Typography>
          <div style={{display: 'flex'}}>
            <HoopsTextField placeholder={`Add ${fieldName}`} fullWidth helperText={addItemError} error={!!addItemError} value={addItem} onChange={(e) => handleValidateAddItem(e.target.value)} style={{marginRight: 8}} />
            <HoopsButton purpose='save' disabled={!!addItemError} onClick={() => handleAdd()}>add</HoopsButton>
          </div>
        </>

      }
      <List
        dense
        sx={{
          width: '100%',
          bgcolor: 'background.paper',
          position: 'relative',
          overflow: 'auto',
          '& ul': {padding: 0},
        }}>
        {listData?.map((item) => (
          <ListItem
            disableGutters
            key={item}
            secondaryAction={editing &&
              <>
                {editItem === item
                  ?
                  <>
                    <HoopsIconButton onClick={() => handleSaveItem()}><SaveIcon fontSize='small' /></HoopsIconButton>
                    <HoopsIconButton onClick={() => setEditItem(null)}><CloseIcon fontSize='small' /></HoopsIconButton>
                  </>
                  :
                  <>
                    <HoopsIconButton onClick={() => handleEditItem(item)}><Edit fontSize='small' /></HoopsIconButton>
                    <HoopsIconButton onClick={() => handleDelete(item)}><Delete fontSize='small' /></HoopsIconButton>
                  </>
                }
              </>
            }
            disablePadding
          >
            {editItem === item
              ? <HoopsTextField value={updateValue} style={{maxWidth: '80%'}} onChange={(e) => setUpdateValue(e.target.value)} />
              : <Typography variant={'body2'} id={item} noWrap sx={{maxWidth: '80%'}}>{item}</Typography>
            }
          </ListItem>
        ))}
      </List>
    </>
  );
};
