import {Button, Grid, Link, MuiThemeProvider, Paper, Typography} from '@material-ui/core';
import csv from 'csvtojson';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Link as RouterLink, useHistory} from 'react-router-dom';
import {decorationImport} from '../../../actions/decoration';
import decorationImportTemplate from '../../../assets/Decoration Import Template.csv';
import green from '../../../ui/theme/green';
import SingleFile from '../../catalog/catalogImport/SingleFile';
import {EntityListHero} from '../../EntityList/EntityListHero';
import HoopsUpload from '../../shared/HoopsUpload';
import VendorAutocomplete from '../../vendors/VendorAutocomplete';
import {DecorationImportDialog} from './DecorationImportDialog';

export const DecorationImport = () => {
    let history = useHistory();

    const [csvFile, setCsvFile] = useState(null);
    const [vendorId, setVendorId] = useState(null);
    const [content, setContent] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const dispatch = useDispatch();

    const handleUploadComplete = async (hoopsFile) => {
        const {file} = hoopsFile;
        const reader = new FileReader();

        reader.readAsText(file, 'utf8');
        reader.onloadend = () => {
            const csvString = reader.result;
            // let headerRow = [];
            csv()
                // .on('header', (header) => {
                //     headerRow = header;
                // })
                .fromString(csvString).then(async (data) => {
                    setContent({
                        data,
                        lines: data.length
                    });
                    setCsvFile(hoopsFile);
                });
        };
    };

    const handleRemoveCsvFile = () => {
        setContent(null);
        setCsvFile(null);
    };

    const handleImport = () => {
        setIsDialogOpen(true);
        dispatch(decorationImport({
            fileId: csvFile._id,
            vendorId: vendorId,
        }));
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
        history.push('/settings/decorations');
    };

    const handleVendorChange = (vendor) => {
        setVendorId(vendor ? vendor._id : null);
    };

    return (
        <Grid container>
            <DecorationImportDialog open={isDialogOpen} onClose={handleDialogClose} />
            <EntityListHero
                title='Decoration Import'
                subtitle='Quickly &amp; easily import your decoration price lists.'
                helpArticleUrl='http://help.hoopscrm.com/en/articles/4736261-import-decorations'
                videoId='9sj_lYlCt0w'
            >
            </EntityListHero>
            <Grid item xs={12} >

                <Paper>
                    <Grid
                        container
                        direction='column'
                        justifyContent='center'
                        alignItems='center'
                    >
                        <Grid item style={{width: '603px'}}>
                            <br />
                            <br />
                            <br />
                            <Typography variant='h1'>Step 1</Typography>
                            <Typography variant='subtitle1'>Download the <Link href={decorationImportTemplate} target='_blank'>template file</Link> and add your decoration information.</Typography>
                            <Typography variant='body2'>Note: If you have multiple vendors who supply decoration, each vendor will need to be imported separately.</Typography>
                            <br />
                            <br />
                            <Typography variant='h1'>Step 2</Typography>
                            <Typography variant='subtitle1'>Select the vendor</Typography>
                            <Typography variant='body2'>Note: If you don&apos;t buy these decorations from a 3rd party (such as in-house decorators), you can skip this step.</Typography>
                            <VendorAutocomplete input={{onChange: handleVendorChange}} fullWidth />

                            <br />
                            <br />
                            <Typography variant='h1'>Step 3</Typography>
                            <Typography variant='subtitle1'>Upload the completed CSV file</Typography>

                            {csvFile && content
                                ? <><br /><SingleFile file={csvFile} onRemove={handleRemoveCsvFile} content={content} /></>
                                : <HoopsUpload accept={['.csv']} maxSizeMb='10' onUploadComplete={handleUploadComplete} />
                            }

                            <br />
                            <Typography variant='body2'>If we find any decoration names within your CSV that already exist in Hoops, we&apos;ll update the Hoops information to match your CSV.</Typography>
                            <br />
                            <br />
                            <Grid container alignContent='space-between'>
                                <Grid item>
                                    <Link
                                        component={RouterLink}
                                        to='/decorations'
                                        underline='none'
                                    >
                                        <Button color='secondary'>Cancel</Button>
                                    </Link>
                                </Grid>
                                <Grid item>
                                    {csvFile && content
                                        ? <MuiThemeProvider theme={green}>
                                            <Button color='primary' variant='contained' onClick={handleImport}>Import</Button>
                                        </MuiThemeProvider>
                                        : null
                                    }
                                </Grid>
                            </Grid>
                            <br />
                            <br />
                        </Grid>
                    </Grid>
                </Paper>

            </Grid>
        </Grid>
    );
};
