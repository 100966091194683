// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {Grid} from '@material-ui/core';
import React from 'react';
import CardContainer from '../../../componentsOld/common/CardContainer';
import EmptyTable from '../../../componentsOld/emptyTable';
import {colors} from '../../../ui/theme';
import {ProofListItem} from './ProofListItem';

export const ProofList = ({
  showAddButton = false,
  proofs = [],
  proofPreviewOptions = {},
  proofActionOptions,
  selectable = false,
  selected = [],
  onSelect = () => null,
  onUploadAction = () => null,
  onEditAction = () => null,
  onDeleteAction = () => null,
  onLinkProducts = () => null,
  linkedProducts = {},
  loading = false,
  view = null,
  job = {},
}) => {
  const isSelected = (proof) => selected.some((selected) => proof._id.toString() === selected);

  const handleSelect = (proof, isSelected) => {
    const removed = selected.filter((id) => id !== proof._id); // ensure no duplicates

    const result = isSelected
      ? [...removed, proof._id]
      : removed;

    onSelect(result);
  };

  return (
    <CardContainer
      showTitle={false}
      iconBackgroundColor={colors.orange}
    >
      <Grid container alignContent='center' justifyContent='center' style={{padding: 0}}>
        <Grid item xs={12} >
          {
            loading ?
              <div style={{padding: '20px 0'}}>
                <EmptyTable
                  showAddButton={showAddButton}
                  field='Proof'
                  openDialog={onUploadAction}
                  loading={loading}
                />
              </div> :
              proofs.map((proof) =>
                <ProofListItem
                  key={proof._id}
                  proof={proof}
                  selectable={selectable}
                  selected={isSelected(proof)}
                  proofActionOptions={proofActionOptions}
                  proofPreviewOptions={proofPreviewOptions}
                  onSelect={(state) => handleSelect(proof, state)}
                  onEditAction={onEditAction}
                  onDeleteAction={onDeleteAction}
                  onLinkProducts={onLinkProducts}
                  linkedProducts={linkedProducts}
                  view={view}
                  job={job}
                />
              )
          }
        </Grid>
      </Grid>
    </CardContainer>
  );
};

export default ProofList;
