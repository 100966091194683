import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {HoopsTextField} from '../../HoopsTextField';
import {baseColumnWidth} from './PurchaseOrder';

export function backOrderColumns() {
  return ([
    {
      title: 'Quantity Ordered',
      cellStyle: baseColumnWidth,
      key: 'QuantityOrdered',
      headerStyle: baseColumnWidth,
      render: (row) => (
        <HoopsTextField
          disabled={row.ordered}
          type='number'
          variant={'outlined'}
          className={this.props.classes.inputStyles}
          onChange={(e) => {
            this.props.setBackorders((prev) => {
              let backOrdersClone = {...prev};
              backOrdersClone[row._id] = backOrdersClone[row._id] || {};
              backOrdersClone[row._id].ordered = e.target.value;
              backOrdersClone[row._id].backorder = row.quantity - backOrdersClone[row._id].ordered;
              if (backOrdersClone[row._id].backorder < 0) {
                backOrdersClone[row._id].backorder = 0;
              }
              return (backOrdersClone);
            });
          }}
          value={row.ordered ? '' : (this.props.backorders[row._id] && this.props.backorders[row._id].ordered !== undefined) ? this.props.backorders[row._id].ordered : null} />
      ),
    },
    {
      title: 'Quantity on Backorder',
      cellStyle: baseColumnWidth,
      key: 'QuantityBackorder',
      headerStyle: baseColumnWidth,
      render: (row) => (
        <HoopsTextField
          disabled={true}
          variant='outlined'
          type='number'
          InputProps={{
            disabled: true,
            readOnly: true,
          }}
          defaultValue={'0'}
          value={row.ordered ? '' : this.props.backorders[row._id] ? this.props.backorders[row._id].backorder : 0} />
      ),
    },
    {
      title: 'Backorder Date',
      cellStyle: {width: '200px', maxWidth: '200px', minWidth: '200px'},
      key: 'BackorderDate',
      headerStyle: {width: '200px', maxWidth: '200px', minWidth: '200px'},
      render: (row) => (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            clearable={true}
            inputVariant={'outlined'}
            fullWidth={true}
            variant={'dialog'}
            disabled={row.ordered || !this.props.backorders[row._id]}
            format='dd MMM yyyy'
            InputAdornmentProps={{position: 'start'}}
            onChange={(date) => {
              this.props.setBackorders((prev) => {
                let backOrdersClone = {...prev};
                backOrdersClone[row._id].date = date;
                return (backOrdersClone);
              });
            }}
            value={row.ordered ? null : (this.props.backorders[row._id] && this.props.backorders[row._id].date) || null}
            className={this.props.classes.inputStyles}

          />
        </MuiPickersUtilsProvider>
      ),
    },]
  );
}
