import theme from '.';

//these styles are used on hoopsQuery Tables
export const hoopsQueryStyles = {
  root: {width: '100%',},
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },

  header: {
    fontWeight: '500',
    whiteSpace: 'nowrap'
  },
  hideCell: {display: 'none'},
  sortIcon: {
    color: theme.colors.grey.light,
    float: 'right',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  table: {
    minWidth: 750,

    '& .MuiTableCell-root': {
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(2),
    },
    '& .MuiTableCell-head': {padding: theme.spacing(2)},

    '& td:nth-child(1)': {fontWeight: 500},
    '& td:nth-child(2)': {fontWeight: 500},
  },
  tableContainer: {
    width: '100%',
    scrollbarWidth: 'thin',
    scrollbarColor: `${theme.colors.grey.main} ${theme.colors.grey.light}`,
    '&::-webkit-scrollbar': {width: 15,},
    '&::-webkit-scrollbar-track': {background: theme.colors.grey.light,},
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.colors.grey.main,
      borderRadius: 6,
      border: `3px solid ${theme.colors.grey.light}`
    },
  },

  greyRow: {'&:nth-of-type(odd)': {backgroundColor: '#f9f9fa',},},

  whiteRow: {backgroundColor: '#FFF'},

  typographyRestrictTwoLines: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 2,
    wordBreak: 'break-all',
    overflow: 'hidden'
  },

  topShadow: {boxShadow: '4px 3px 5px 0px rgba(0, 0, 0, 0.2)'},

  leftSticky: {width: '5%',},
  leftShadowHeader: {boxShadow: '4px -3px 5px 0px rgba(0, 0, 0, 0.2)'},
  leftShadow: {boxShadow: '4px 3px 5px 0px rgba(0, 0, 0, 0.2)'},

  rightSticky: {width: '5%',},
  rightShadowHeader: {boxShadow: '-4px -3px 5px 0px rgba(0, 0, 0, 0.2)'},
  rightShadow: {boxShadow: '-4px 3px 5px 0px rgba(0, 0, 0, 0.2)'},
};
