// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-shadow */

import {Button, Divider, Grid, InputAdornment, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import InfoIcon from '@material-ui/icons/Info';
import {get} from 'lodash';
import React, {useContext} from 'react';
import {Controller, useFieldArray, useFormContext} from 'react-hook-form';
import {HoopsTextField} from '../../../componentsOld/HoopsTextField';
import ControlledHoopsAutocomplete from '../../../componentsOld/ControlledHoopsAutocomplete';
import {itemBuilder} from '../../../ui/theme/itemBuilder';
import adjustmentLineValues from '../adjustmentLineValues';
import {ItemBuilderVendorAutocomplete} from '../ItemBuilderVendorAutocomplete';
import {ItemBuilderContext} from '../ItemBuilderContext';
import ActionMenu from './ActionMenu';
import AdditionalItemTotals from './AdditionalItemTotals';
import MarkupField from './MarkupField';
import SellPriceField from './SellPriceField';
import {permissionCheck, SubscriptionTypes} from '../../../componentsHoops';

const useStyles = makeStyles(() => ({...itemBuilder,}));

const ItemBuilderAdditionalItemTable = ({
  product = {},
  currencySymbol = '$',
}) => {
  const classes = useStyles();
  const {control, setValue, watch} = useFormContext();
  const {onPriceChanged} = useContext(ItemBuilderContext);
  const {fields, insert, remove} = useFieldArray({
    control,
    name: 'additionalCosts'
  });

  const {adjustmentLineName} = adjustmentLineValues;

  const priceElement = watch();
  const additionalCosts = fields && fields.filter((el) => el !== null);

  const adjustmentIndex = fields && fields.findIndex((additionalCost) => additionalCost && additionalCost.name === adjustmentLineName);
  const additionalCostsEndIndex = fields && adjustmentIndex > -1 ? fields.length - 1 : fields.length;

  const additionalCostsLength = additionalCosts && additionalCosts.length - (adjustmentIndex > -1 ? 1 : 0);

  const title = 'Additional Cost';

  const handleDuplicate = (rowIndex) => {
    insert(additionalCostsEndIndex, priceElement.additionalCosts[rowIndex]);
    onPriceChanged();
  };
  const handleAdd = () => {
    insert(additionalCostsEndIndex, {
      markup: null,
      sellPrice: 0,
      name: null,
      vendor: null,
      buyPrice: 0,
    });
    onPriceChanged();
  };
  const handleAddShipping = () => {
    insert(additionalCostsEndIndex, {
      markup: null,
      sellPrice: 0,
      name: 'Shipping  ',
      vendor: null,
      buyPrice: 0,
    });
    onPriceChanged();
  };
  const handleDelete = (rowIndex) => {
    fields.length > 1
      ? remove(rowIndex)
      : remove();
    onPriceChanged();
  };

  return (
    <div className={classes.container}>
      <Grid container direction='row' justifyContent='flex-start' alignItems='center' className={classes.itemBuilderHeader}>
        <Grid item><Typography variant='h6' gutterBottom>{`${title}(s)`}</Typography></Grid>
        <Grid item className={classes.infoCell}>
          <Tooltip arrow placement='top' classes={{tooltip: classes.tooltip}} title={
            <>
              {'Add any fixed charges here. '}<br /><br />
              {'This section is commonly used for artwork/design charges, shipping, or any other type of fixed charge associated with this line item.'}
            </>
          }><InfoIcon fontSize='small' className={classes.icon} /></Tooltip>
        </Grid>
      </Grid>
      <Divider />
      <Paper elevation={0} className={classes.tableContainer}>
        <Table >
          {additionalCostsLength > 0 &&
            <TableHead>
              <TableRow className={classes.tableHeaderRow}>
                <TableCell style={{width: '50%'}}>Name</TableCell>
                <TableCell style={{width: '20%'}}>Vendor</TableCell>
                <TableCell style={{width: '10%'}}>Rate</TableCell>
                <TableCell style={{width: '10%'}}>Markup (%)</TableCell>
                <TableCell style={{width: '10%'}}>Sell Price</TableCell>
                <TableCell style={{width: '5%'}} className={classes.responsiveActionButtons}>Clone</TableCell>
                <TableCell style={{width: '5%'}} className={classes.responsiveActionButtons}>Delete</TableCell>
                <TableCell style={{width: '5%'}} className={classes.responsiveActionMenu}>Actions</TableCell>
              </TableRow>
            </TableHead>
          }
          <TableBody>
            {fields.map((field, rowIdx) =>
            (
              <>
                {field.name === adjustmentLineName
                  ? <></>
                  : <TableRow hover={false} key={field.id} >
                    <TableCell scope='row' className={classes.tableCell}>
                      <Controller
                        name={`additionalCosts[${rowIdx}].name`}
                        defaultValue={field.name}
                        render={(field) => (
                          <HoopsTextField
                            responsive
                            {...field}
                            onChange={(e) => { onPriceChanged(); field.onChange(e); }}
                            disabled={additionalCosts[rowIdx].name === 'Shipping  '}
                          />)}
                        control={control}
                      />
                    </TableCell>
                    <TableCell scope='row' className={classes.tableCell}>
                      <ControlledHoopsAutocomplete
                        name={`additionalCosts[${rowIdx}].vendor`}
                        control={control}
                        placeholder='Search Vendor'
                        textFieldProps={{
                          margin: 'dense',
                          variant: 'outlined',
                          placeholder: 'Search Vendors',
                          inputProps: {
                            margin: 'none',
                            className: classes.inputClass,
                          }
                        }}
                        Component={(params) =>
                          <ItemBuilderVendorAutocomplete
                            fullWidth
                            {...params}
                          />
                        }
                        defaultValue={field.vendor}
                        onSelect={(d) => {
                          setValue(`additionalCosts[${rowIdx}].vendor`, d);
                          setValue(`additionalCosts[${rowIdx}].vendorId`, get(d, '_id'));
                          onPriceChanged();
                        }}
                        onChange={(d) => {
                          setValue(`additionalCosts[${rowIdx}].vendor`, d);
                          setValue(`additionalCosts[${rowIdx}].vendorId`, get(d, '_id'));
                          onPriceChanged();
                        }}
                      />

                      <Controller
                        name={`additionalCosts[${rowIdx}].vendorId`}
                        defaultValue={field.vendorId}
                        render={(field) => (
                          <input
                            type='hidden'
                            {...field}
                          />)}
                        control={control}
                      />
                    </TableCell>
                    <TableCell scope='row' className={classes.tableCell}>
                      <Controller
                        name={`additionalCosts[${rowIdx}].buyPrice`}
                        defaultValue={field.buyPrice}
                        render={(field) => (
                          <HoopsTextField
                            type='number'
                            responsive
                            {...field}
                            onChange={(e) => { onPriceChanged(); field.onChange(e); }}
                            InputProps={{startAdornment: <InputAdornment position={'start'}>{currencySymbol}</InputAdornment>}}
                          />
                        )}
                        control={control}
                      />
                    </TableCell>
                    <TableCell scope='row' className={classes.tableCell}>
                      <MarkupField index={rowIdx} field={field} name={`additionalCosts[${rowIdx}].markup`} defaultValue={field.markup} classes={classes} />
                    </TableCell>
                    <TableCell scope='row' className={classes.tableCell}>
                      <SellPriceField product={product} index={rowIdx} field={field} name={`additionalCosts[${rowIdx}].sellPrice`} classes={classes} currencySymbol={currencySymbol} />
                    </TableCell>
                    <TableCell scope='row' className={classes.responsiveActionButtons}>
                      <FileCopyIcon color={'secondary'} style={{cursor: 'pointer'}} onClick={() => handleDuplicate(rowIdx)} />
                    </TableCell>
                    <TableCell scope='row' className={classes.responsiveActionButtons}>
                      <DeleteIcon color={'secondary'} style={{cursor: 'pointer'}} onClick={() => handleDelete(rowIdx)} />
                    </TableCell>
                    <TableCell className={classes.responsiveActionMenu}>
                      <ActionMenu rowIndex={rowIdx} onDuplicate={handleDuplicate} onDelete={handleDelete} />
                    </TableCell>
                  </TableRow>}
              </>
            )
            )}
          </TableBody>
        </Table>
      </Paper>
      <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start'>
        <Grid item xs={6}>
          <Button color='primary' variant={additionalCostsLength > 0 ? 'text' : 'outlined'} className={classes.button} onClick={handleAdd}>{`Add ${title}`}</Button>
          {permissionCheck({allowedSubscriptions: [SubscriptionTypes.fullyPromoted]}) &&
            <Button color='primary' variant={additionalCostsLength > 0 ? 'text' : 'outlined'} className={classes.button} onClick={handleAddShipping}>Add Shipping</Button>
          }
        </Grid>
        <Grid item xs={6}>{fields.length > 0 && <AdditionalItemTotals classes={classes} />}</Grid>
      </Grid>
    </div>
  );
};

export default ItemBuilderAdditionalItemTable;
