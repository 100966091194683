// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React, {Fragment} from 'react';
import {DialogTitle, Typography} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '../shared/DialogActions';
import Button from '@material-ui/core/Button';
import {ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles';
import red from '../../ui/theme/red';
import Grid from '@material-ui/core/Grid';
import {DeleteUser} from '../../actions/action-types';
import {connect} from 'react-redux';

class DeleteUserDialog extends React.Component {
  handleClose = () => {
    const {onClose} = this.props;
    onClose();
  };

  handleDelete = () => {
    const {user, onClose, deleteUser} = this.props;
    deleteUser(user._id);
    onClose();
  };

  render() {
    const {user} = this.props;

    return (
      <Fragment>
        <MuiThemeProvider theme={red}>
          <Dialog {...this.props}>
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogContent>
              <Typography>Are you sure you want to delete <strong>{user.fullName}{'\'s'}</strong> account?</Typography>
            </DialogContent>
            <DialogActions>
              <Grid container justifyContent={'space-between'}>
                <Grid item>
                  <Button color={'secondary'} onClick={this.handleClose}>Cancel</Button>
                </Grid>
                <Grid item>
                  <Button color={'primary'} onClick={this.handleDelete}>Yes</Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        </MuiThemeProvider>
      </Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {deleteUser: (id) => dispatch(DeleteUser(id))};
}

DeleteUserDialog = connect(null, mapDispatchToProps)(DeleteUserDialog);

export default DeleteUserDialog;
