import React from 'react';
import Grid from '@material-ui/core/Grid';
import LabelledInput from '../LabelledInput';
import orange from '../../../../ui/theme/orange';
import red from '../../../../ui/theme/red';
import {Field} from 'react-final-form';
import {CatalogImportFormHeader} from '../CatalogImportFormHeader';
import {HoopsFieldMappingRow} from '../HoopsFieldMappingRow';
import {required} from '../../../shared/formHelpers/validation';
import {FormControlLabel} from '@material-ui/core';
import {Checkbox} from '@kestreltech/final-form-material-ui';
import FormControl from '@material-ui/core/FormControl';
import SingleFile from '../SingleFile';
import HoopsDropzone from '../../../shared/HoopsDropzone';
import {MAX_FILE_SIZE_BYTES} from '../../../../constants/file';

export function Step5Images({values, catalogImportId}) {
  return (
    <Grid container spacing={3}>
      {values && !values.noImages && <>
        <Grid container item xs={12}>
          <LabelledInput
            label={'Image Archive'}
            chipTheme={red}
            chipLabel={'Required'}
            helpLink={'http://help.hoopscrm.com/en/articles/3737283-importing-product-images'}
          >
            <Field
              name={'imageFile'}
              component={ImageUploadField}
              validate={required}
              fullWidth
              catalogImportId={catalogImportId}
              dropzoneOptions={{multiple: false}}
            />
          </LabelledInput>
        </Grid>
        <Grid container item xs={12}>
          <LabelledInput
            label={'Image Field(s)'}
            chipTheme={orange}
            chipLabel={'Recommended'}>
            <CatalogImportFormHeader/>
            <Grid container alignItems={'center'}>
              <Grid container item xs={true} spacing={2} direction={'column'}>
                <HoopsFieldMappingRow
                  formValues={values}
                  fieldName={'fieldMapping.image'}
                  key={'image'}
                  field={{
                    label: 'Primary Image',
                    name: 'image',
                    validate: required
                  }}/>
                <HoopsFieldMappingRow
                  formValues={values}
                  fieldName={'fieldMapping.variantImage'}
                  key={'variantImage'}
                  field={{
                    label: 'Variant Image',
                    name: 'variantImage',
                  }}/>
              </Grid>
            </Grid>
          </LabelledInput>
        </Grid>
      </>}
      <Grid container item xs={12} justifyContent={'center'}>
        <Grid item>
          <FormControlLabel
            control={
              <Field name={'noImages'}
                     component={Checkbox}
                     color={'primary'}
                     type={'checkbox'}
              />
            }
            label={'Proceed with the import without mapping images from a zip file.'} />
        </Grid>
      </Grid>
    </Grid>
  );
}

function ImageUploadField({catalogImportId, formControlProps, input: {value, onChange}, ...props}) {
  const s3ObjectParams = {
    Bucket: process.env.REACT_APP_IMPORT_BUCKET,
    Metadata: {'catalog-import-id': catalogImportId}
  };

  return (
    <FormControl fullWidth {...formControlProps}>
      {
        ((value && value.filename) ?
          <SingleFile
            file={value}
            onRemove={() => onChange()} /> :
          <HoopsDropzone
            snackbar
            snackbarDestination={'Catalog Import'}
            multipartFileLabel={catalogImportId}
            accept={['.zip']}
            maxSize={MAX_FILE_SIZE_BYTES * 5 * 20}
            dropzoneOptions={{multiple: false}}
            onComplete={onChange}
            s3ObjectParams={s3ObjectParams}
            uploadMessage={'Please wait for the upload to complete before continuing to the next step'}
            {...props}
          />)
      }
    </FormControl>
  );
}
