import {gql} from 'apollo-boost';

export const GQL_CUSTOMER_FRAG = gql`
    fragment CustomerFragment on Customer {
        type
        _id
        name
        email
        phone
        website
        profile
        industryType
        customerRepId
        customerRep {
            firstName
            lastName
            fullName
            username
            _id
        }
        settings {
            minimumMarkup
            salesDiscount
            whitelabelProofPortal
        }
        addresses {
            _id
            label
            address1
            address2
            city
            state
            country
            postcode
            formattedAddress
        }
        contacts {
            _id
            firstName
            lastName
            email
            phone
        }
        companyTradingEntityId
    }
`;

export const GQL_CREATE_CUSTOMER = gql`
    mutation CustomerCreateOne($record: CreateOneCustomerWithContactsInput!, $contacts: [CreateManyGCContactInput!]!) {
        customerCreateOne(record: $record, contacts: $contacts) {
            recordId
            record {
                type
                _id
                name
                email
                phone
                website
                profile
                industryType
                customerRepId
                customerRep {
                    firstName
                    lastName
                    fullName
                    username
                    _id
                }
                settings {
                    minimumMarkup
                    salesDiscount
                    whitelabelProofPortal
                }
                addresses {
                    _id
                    label
                    address1
                    address2
                    city
                    state
                    country
                    postcode
                    formattedAddress
                }
                contacts {
                    _id
                    firstName
                    lastName
                    fullName
                    email
                    phone
                }
                companyTradingEntityId
            }
        }
    }
`;

export const GQL_GET_CUSTOMER = gql`
    query GetCustomer($_id: MongoID!) {
        customerOne(filter: { _id: $_id }) {
            ...CustomerFragment
        }
    }
    ${GQL_CUSTOMER_FRAG}
`;

export const GQL_GET_CUSTOMERS = gql`
    query getCustomers($filter: FilterFindPagingationSearchCustomerInput, $page: Int, $perPage: Int) {
        customerPagination(filter: $filter, page: $page, perPage: $perPage) {
            items {
                ...CustomerFragment
            }
            pageInfo {
                perPage
                pageCount
                currentPage
                hasNextPage
                itemCount
            }
        }
    }
    ${GQL_CUSTOMER_FRAG}
`;

export const GQL_UPDATE_CUSTOMER = gql`
    mutation CustomerUpdateById($_id: MongoID!, $record: UpdateByIdCustomerWithContactsInput!, $contacts: [GCContactInput]) {
        customerUpdateById(_id: $_id, record: $record, contacts: $contacts) {
            record {
                _id
                name
                email
                type
                phone
                website
                profile
                industryType
                customerRepId
                customerRep {
                    firstName
                    lastName
                    fullName
                    username
                    _id
                }
                settings {
                    minimumMarkup
                    salesDiscount
                    whitelabelProofPortal
                }
                addresses {
                    _id
                    label
                    address1
                    city
                    state
                    country
                    postcode
                }
                contacts {
                    _id
                    fullName
                    firstName
                    lastName
                    email
                    phone
                }
                companyTradingEntityId
            }
        }
    }
`;

export const GQL_DELETE_CUSTOMER = gql`
    mutation CustomerRemoveById($_id: MongoID!) {
        customerRemoveById(_id: $_id) {
            recordId
        }
    }
`;

export const GQL_IMPORT_CUSTOMERS = gql`
    mutation CustomerImport($_id: MongoID!) {
        customerImport(_id: $_id) {
            status
        }
    }
`;

export const GQL_CUSTOMER_PAGINATION = gql`
    query CustomerPagination(
        $filter: FilterFindPagingationSearchCustomerInput,
        $sort: SortFindManyCustomerInput,
        $page: Int,
        $perPage: Int
    ) {
        customerPagination(
            filter: $filter,
            sort: $sort,
            perPage: $perPage,
            page: $page
        ) {
            items {
                ...CustomerFragment
            }
            pageInfo {
                currentPage
                perPage
                pageCount
                itemCount
                hasNextPage
                hasPreviousPage
            }
        }
    }
    ${GQL_CUSTOMER_FRAG}
`;
