import {Button, Fade, Menu, MenuItem, MuiThemeProvider} from '@material-ui/core';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {openCatalogModal} from '../../actions/modals';
import {PermissionBasedContent, roles, SubscriptionTypes} from '../../componentsHoops';
import green from '../../ui/theme/green';
import {featureFlagEnabled, featureFlags} from '../../utils/featureFlag';
import DecorationDialog from '../decorations/decorationDialog';

const ProductManyViewButtons = () => {
    const dispatch = useDispatch();
    const {admin, standard} = roles;

    const [anchorEl, setAnchorEl] = useState(null);
    const [isCreateDecorationOpen, setIsCreateDecorationOpen] = useState(false);

    const openCreateDecoration = () => {
        handleAddToCatalogClose();
        setIsCreateDecorationOpen(true);
    };

    const handleCreateDecorationClose = () => {
        setIsCreateDecorationOpen(false);
    };

    const handleAddToCatalogClick = (e) => {
        e.preventDefault();
        setAnchorEl(e.currentTarget);
    };

    const handleAddToCatalogClose = () => {
        setAnchorEl(null);
    };

    const handleAddProduct = () => {
        handleAddToCatalogClose();
        dispatch(openCatalogModal());
    };

    return (
        <>
            <MuiThemeProvider theme={green}>
                <Button
                    color='primary'
                    variant='contained'
                    endIcon={<KeyboardArrowDown />}
                    onClick={handleAddToCatalogClick}
                    aria-controls='fade-menu'
                    aria-haspopup='true'
                >
                    Add to Catalog
                </Button>
            </MuiThemeProvider>
            <Menu
                id='fade-menu'
                anchorEl={anchorEl}
                keepMounted

                onClose={handleAddToCatalogClose}
                TransitionComponent={Fade}

                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={Boolean(anchorEl)}
            >
                <MenuItem onClick={handleAddProduct}>Add Product</MenuItem>
                <MenuItem onClick={openCreateDecoration}>Add Decoration</MenuItem>
                <MenuItem component={Link} to='/settings/decorations/import'>Import Decoration</MenuItem>
                {featureFlagEnabled(featureFlags.promo_standards_feature) &&
                    <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.proofs]} allowedRoles={[admin, standard]}>
                        <MenuItem
                            to={'/settings/integrations'}
                            component={Link}
                        >
                            Connect Supplier
                        </MenuItem>
                    </PermissionBasedContent>
                }
            </Menu>
            {isCreateDecorationOpen &&
                <DecorationDialog
                    isOpen={isCreateDecorationOpen}
                    onClose={handleCreateDecorationClose}
                />}
        </>
    );
};

export default ProductManyViewButtons;
