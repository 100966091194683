import {client} from './GraphQLService';
import {pick} from 'lodash';
import {
  GQL_GET_SCHEDULE,
  GQL_GET_SCHEDULES,
  GQL_CREATE_SCHEDULE,
  GQL_UPDATE_SCHEDULE,
  GQL_DELETE_SCHEDULE,
  GQL_GET_SCHEDULE_JOB
} from '../queries/schedules';

export default class ScheduleService {
  static _parseScheduleInput(schedule) {
    const keys = [
      'title',
      'description',
      'status',
      'startAt',
      'endAt',
      'isDone',
      'jobId',
      'jobChildMappingIds',
      'resourceId'
    ];

    if (schedule.startAt) {
      schedule.startAt = new Date(schedule.startAt).toISOString();
    }
    
    if (schedule.endAt) {
      schedule.endAt = new Date(schedule.endAt).toISOString();
    }
    
    if (schedule.resource) {
      schedule.resourceId =  schedule.resource._id;
    }

    return pick(schedule, keys);
  }

  static async getSchedule(id) {
    return client
      .query({
        query: GQL_GET_SCHEDULE,
        variables: {_id: id,},
        fetchPolicy: 'no-cache',
      })
      .then(({data: {scheduleById}}) => scheduleById);
  }

  static async getJob(id) {
    return client
      .query({
        query: GQL_GET_SCHEDULE_JOB,
        variables: {_id: id,},
        fetchPolicy: 'no-cache',
      })
      .then(({data: {job}}) => job);
  }

  static async getSchedules({...variables}) {
    return client
      .query({
        query: GQL_GET_SCHEDULES,
        fetchPolicy: 'network-only',
        variables: {...variables,},
      })
      .then(({data: {scheduleMany}}) => scheduleMany);
  }

  static async createSchedule(scheduleData) {
    return client
      .mutate({
        mutation: GQL_CREATE_SCHEDULE,
        variables: {record: ScheduleService._parseScheduleInput(scheduleData),},
      })
      .then(({data: {scheduleCreateOne}}) => scheduleCreateOne);
  }

  static async updateSchedule(_id, data) {
    return client
      .mutate({
        mutation: GQL_UPDATE_SCHEDULE,
        variables: {
          _id,
          record: ScheduleService._parseScheduleInput(data),
        },
      })
      .then(({data: {scheduleUpdateById}}) => scheduleUpdateById);
  }

  static async deleteSchedule(_id) {
    return client
      .mutate({
        mutation: GQL_DELETE_SCHEDULE,
        variables: {_id,},
      })
      .then(({data: {scheduleRemoveById}}) => scheduleRemoveById);
  }
}
