import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import {connect} from 'react-redux';

class ConfirmDeleteDialog extends React.Component {
  state = {open: false};

  cancel = () => {
    this.setState({open: false});
    this.props.cancel?.();
  };

  ok = () => {
    this.setState({open: false});
    this.props.ok();
  };

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({open: props.isOpen});
  }

  render() {
    return (
      <div>
        <Dialog open={this.state.open}>
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure you want to delete
              <strong> {this.props.name}</strong>?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.cancel} color='secondary'>
              Cancel
            </Button>
            <Button onClick={this.ok} color='primary'>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default connect()(ConfirmDeleteDialog);
