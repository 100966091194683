import {Button, CircularProgress} from '@mui/material';
import {styled} from '@mui/material/styles';
import * as React from 'react';

const primaryHoverBgOpacity = 80;

const AddButton = styled(Button)(({theme}) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.colors.green,
  '&:hover': {backgroundColor: theme.colors.greenDark,},
}));

const AttentionButton = styled(Button)(({theme}) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.colors.red,
  '&:hover': {backgroundColor: theme.colors.redHover,},
}));

const SubtleButton = styled(Button)(({theme}) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.colors.grey.subtle,
  '&:hover': {backgroundColor: theme.colors.grey.main,},
}));

const OkButton = styled(Button)(({theme}) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.colors.green,
  '&:hover': {backgroundColor: theme.colors.greenDark,},
}));

const CancelButton = styled(Button)(({theme}) => ({
  color: theme.colors.grey.main,
  '&:hover': {backgroundColor: theme.colors.grey.ultraLight,},
}));

const MenuButton = styled(Button)(({theme}) => ({
  color: theme.palette.primary.main,
  backgroundColor: theme.colors.white,
  borderColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: `${theme.palette.primary.light + primaryHoverBgOpacity}`,
    borderColor: theme.palette.primary.main,
  },
}));

const PrimaryButton = styled(Button)(({theme}) => ({
  color: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  textTransform: 'none',
  '&:hover': {
    backgroundColor: `${theme.palette.primary.light + primaryHoverBgOpacity}`,
    borderColor: theme.palette.primary.main,
  },
}));

const StatusButton = styled(Button)(({theme}) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.colors.white,
  justifyContent: 'space-between',
  '&:hover': {},
}));

const SaveButton = styled(Button)(() => ({
  justifyContent: 'space-between',
  '&:hover': {},
}));

const StandardButton = styled(Button)(({theme}) => ({
  textTransform: 'none',
  color: theme.palette.text.primary,
  '&:hover': {backgroundColor: theme.colors.grey.ultraLight,},
}));

const Spinner = styled(CircularProgress)(({theme}) => ({marginRight: theme.spacing(1),}));

export const HoopsButton = ({
  innerRef = null,
  purpose = '',
  children,
  loading = false,
  ...props
}) => (
  <>
    {(() => {
      switch (purpose) {
        case 'add':
          return <AddButton disabled={loading} variant='contained' ref={innerRef} {...props}>{loading && <Spinner size={16} />}{children}</AddButton>;
        case 'attention':
          return <AttentionButton disabled={loading} variant='contained' ref={innerRef} {...props}>{loading && <Spinner size={16} />}{children}</AttentionButton>;
        case 'cancel':
          return <CancelButton disabled={loading} variant='text' ref={innerRef} {...props}>{loading && <Spinner size={16} />}{children}</CancelButton>;
        case 'menu':
          return <MenuButton disabled={loading} variant='outlined' ref={innerRef} {...props}>{loading && <Spinner size={16} />}{children}</MenuButton>;
        case 'ok':
          return <OkButton disabled={loading} variant='contained' ref={innerRef} {...props}>{loading && <Spinner size={16} />}{children}</OkButton>;
        case 'primary':
          return <PrimaryButton ref={innerRef} {...props}>{loading && <Spinner size={16} />}{children}</PrimaryButton>;
        case 'save':
          return <SaveButton disabled={loading} variant='contained' ref={innerRef} {...props}>{loading && <Spinner size={16} />}{children}</SaveButton>;
        case 'standard':
          return <StandardButton disabled={loading} variant='text' ref={innerRef} {...props}>{loading && <Spinner size={16} />}{children}</StandardButton>;
        case 'status':
          return <StatusButton disabled={loading} variant='contained' ref={innerRef} {...props}>{loading && <Spinner size={16} />}{children}</StatusButton>;
        case 'subtle':
          return <SubtleButton disabled={loading} variant='contained' ref={innerRef} {...props}>{loading && <Spinner size={16} />}{children}</SubtleButton>;
        default:
          return <Button disabled={loading} variant='text' ref={innerRef} {...props}>{loading && <Spinner size={16} />}{children}</Button>;
      }
    })()}

  </>
);
