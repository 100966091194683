// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React, {Component} from 'react';
import {Grid, Typography, withStyles} from '@material-ui/core';
import {connect} from 'react-redux';
import {get} from 'lodash';
import * as numeral from 'numeral';
import {RenderRate} from './VariantsTable';

const styles = (theme) => ({
  total: {
    fontWeight: '500',
    marginLeft: theme.spacing(2)
  },
  leftBuffer: {marginLeft: theme.spacing(2)},
});

class Totals extends Component {
  render() {
    const {purchaseOrder, classes, company} = this.props;
    const taxes = get(purchaseOrder, 'taxes', []);
    return (
    <>
      <Grid item>
        <Typography align='right' className={classes.leftBuffer}>
          SUBTOTAL <RenderRate company={company} rate={get(purchaseOrder, 'subTotal')}/>
        </Typography>
      </Grid>
      {taxes.map(({tax, total}) => (
      <Grid key={tax.name} item>
        <Typography align='right' className={classes.leftBuffer}>
          {tax.name} <RenderRate company={company} rate={total}/>
        </Typography>
      </Grid>
      ))}
      <Grid item>
        <Typography className={classes.total} align='right'>
          TOTAL {company.currencySymbol} {numeral(purchaseOrder.total).format('0,0.00')}
        </Typography>
      </Grid>
    </>
    );
  }
}

function mapStateToProps(state) {
  return {
    purchaseOrder: state.purchaseOrdersReducer.activePurchaseOrder,
    company: state.companyReducer.company
  };
}

Totals = connect(mapStateToProps)(Totals);
Totals = withStyles(styles)(Totals);
export default Totals;
