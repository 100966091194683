import React, {useMemo} from 'react';
import {Box, Typography} from '@mui/material';
import classNames from 'classnames';

const styles = {
  display: 'flex',
  '&>p': {writingMode: 'vertical-rl',},
};

export function AngleText({className, sx, angle, children, ...props}) {
  sx = useMemo(() => ({
    ...styles,
    alignItems: 'end',
    transform: `skew(${-angle}deg)`,
    transformOrigin: 'bottom center',
    ...sx,
    '&>p': {
      ...styles['&>p'],
      transform: `skew(${angle}deg) rotate(${angle >= 0 ? 180 + angle : angle}deg)`,
      transformOrigin: 'bottom center',
      ...sx?.['&>p'],
    }
  }), [angle, sx]);
  return (
    <Box className={classNames(['angle-text', className])} sx={sx}>
      <Typography {...props}>{children}</Typography>
    </Box>
  );
}
