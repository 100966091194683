// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React, {Component, Fragment} from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import withStyles from '@material-ui/core/styles/withStyles';
import {FieldArray, getFormValues} from 'redux-form';
import ProductsTableRows from './ProductsTableRows';
import {connect} from 'react-redux';
import {TableRow} from '@material-ui/core';

const styles = (theme) => {
  const baseWidth = 150;
  return {
    sectionFooter: {
      margin: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      maxWidth: '100%'
    },
    baseColumn: {width: `${baseWidth}px`},
    vendorColumn: {width: `${baseWidth * 2}px`},
    actionColumn: {width: `${baseWidth / 3}px`}
  };
};

class ProductsTable extends Component {
  render() {
    const {classes, product} = this.props;
    return (
      <Fragment>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.baseColumn}>Product Type</TableCell>
              <TableCell>Product Name</TableCell>
              <TableCell className={classes.baseColumn}>Product Code</TableCell>
              <TableCell className={classes.baseColumn}>Quantity</TableCell>
              <TableCell className={classes.baseColumn}>Color</TableCell>
              <TableCell className={classes.baseColumn}>Size</TableCell>
              <TableCell className={classes.baseColumn}>Rate/Unit</TableCell>
              <TableCell className={classes.baseColumn}>Total</TableCell>
              <TableCell className={classes.actionColumn}/>
            </TableRow>
          </TableHead>
          <TableBody>
            <FieldArray name={'variants'} component={ProductsTableRows} product={product}/>
          </TableBody>
        </Table>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {formValues: getFormValues('purchaseOrderVariantsForm')(state)};
}

ProductsTable = connect(mapStateToProps)(ProductsTable);
ProductsTable = withStyles(styles)(ProductsTable);
export default ProductsTable;
