import {GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton} from '@mui/x-data-grid';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import theme from '../../../ui/theme';
import React, {useCallback, useEffect, useMemo} from 'react';
import {KeyboardArrowDown} from '@mui/icons-material';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {Link} from 'react-router-dom';
import {debounce} from 'lodash';
import {GridCsvExportButton} from './components/GridCsvExportButton';
import {HoopsTextField} from '../../../componentsOld/HoopsTextField';
import {HoopsButton} from '../../../componentsLib';

export const CustomToolbar = ({onSearch, search, viewChanged, viewIsHome, onSaveView}: any) => {
  const debouncedOnSearch = useMemo(() => debounce(onSearch, 600), [onSearch]);
  const handleChange = useCallback((event) => {
    debouncedOnSearch(event.currentTarget.value);
  }, [debouncedOnSearch]);
  useEffect(() => () => {
      debouncedOnSearch.cancel();
    }, [debouncedOnSearch]);
  return (
    <GridToolbarContainer sx={{
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0 12px 12px',
      '& div >  * + * ': {mr: 2,},
      '& .MuiOutlinedInput-root': {paddingRight: '8px'}
    }}>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <HoopsTextField placeholder='Search...' type='search' defaultValue={search} onChange={handleChange} fullWidth={false}/>

        <GridToolbarFilterButton/>
        <GridToolbarColumnsButton/>
        <GridToolbarDensitySelector/>
        <GridCsvExportButton/>
        {onSaveView && <SaveViewButton saveAs={viewChanged} saveOver={viewChanged && !viewIsHome} onSaveView={onSaveView}/>}
      </div>
      <div>
        <Link to={'/settings/automations'}  style={{textDecoration: 'none'}}>
        <HoopsButton purpose='primary' endIcon={<FlashOnIcon/>}
                     sx={{color: theme.palette.primary.main, textTransform: 'none'}}>Automations</HoopsButton>
        </Link>
      </div>
    </GridToolbarContainer>
  );
};

interface SaveViewButtonProps {
  saveAs: boolean,
  saveOver: boolean,
  onSaveView: (asNewView: boolean ) => void
}

function SaveViewButton({saveAs, saveOver, onSaveView}: SaveViewButtonProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const _onSaveView = (asNewView: boolean) => {
    handleClose();
    onSaveView(asNewView);
  };
  return <>
    {saveAs && !saveOver && <HoopsButton
      purpose='save'
      onClick={() => onSaveView(true)}
      disableElevation>Save As New View</HoopsButton>}
    {saveAs && saveOver &&
    <>
      <HoopsButton
        purpose='save'
        onClick={onClick}
        disableElevation endIcon={<KeyboardArrowDown/>}>Save View</HoopsButton>
      <ClickAwayListener onClickAway={handleClose}>
        <Menu
          autoFocus={false}
          id='long-menu'
          MenuListProps={{'aria-labelledby': 'long-button',}}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem autoFocus={false} onClick={() => _onSaveView(false)}>
            As Existing View
          </MenuItem>
          <MenuItem autoFocus={false} onClick={() => _onSaveView(true)}>
            As New View
          </MenuItem>
        </Menu>
      </ClickAwayListener>
    </>}
  </>;
}
