// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {Controller, useForm} from 'react-hook-form';
import {Button, DialogActions, DialogContent} from '@material-ui/core';
import {HoopsTextField} from '../../HoopsTextField';
import Grid from '@material-ui/core/Grid';
import {Close} from '@material-ui/icons';
import LoadingButton from '../../shared/LoadingButton';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {snackError} from '../../../actions/action-types';

export function TrackingNumberForm({onSubmit,model, onCancel, deleting = false}) {
  const {control, handleSubmit, errors} = useForm({defaultValues: model});
  const dispatch = useDispatch();
  const [loading,setLoading] = useState();
  const _onSubmit = async (model) => {
    setLoading(true);
    try {
      await onSubmit(model);
    } catch (e) {
      console.error(e);
      dispatch(snackError(e.message));
    }
    setLoading(false);
    onCancel();
  };
  return (
    <form onSubmit={handleSubmit(_onSubmit)} autoComplete='off'>
      <DialogContent>
        <Controller
          as={<input
            type={'hidden'}
          />}
          name='_id'
          control={control}
        />
        {deleting && <p>Are you sure?</p>}
        {!deleting &&
        <><Controller
          rules={{
            'required': {
              value: true,
              message: 'Shipping Company is required'
            }
          }}
          as={<HoopsTextField
            size='small'
            label='Shipping Company'
            autoComplete='off'
            meta={{error: errors && errors.shippingCompany  && errors.shippingCompany.message}}
            placeholder='Shipping Company'
          />}
          name='shippingCompany'
          control={control}
        />
        <br/>
        <br/>
        <Controller
          rules={{
            'required': {
              value: true,
              message: 'Tracking Number is required'
            }
          }}
          as={<HoopsTextField

            size='small'
            label='Tracking Number'
            autoComplete='off'
            meta={{error: errors && errors.trackingNumber  && errors.trackingNumber.message}}
            placeholder='Tracking Number'
          />}
          name='trackingNumber'
          control={control}
        />
        <br/>
        <br/>
        <Controller
          rules={{
            pattern: {
              value: new RegExp('https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)'),
              message: 'Link needs to be a valid url (http://example.com/)'
            }
          }}
          as={<HoopsTextField

            size='small'
            label='Tracking Link'
            autoComplete='off'
            placeholder='Tracking Link'
            meta={{error: errors && errors.trackingLink  && errors.trackingLink.message}}

          />}
          name='trackingLink'
          control={control}
        />
        </>}
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={'space-between'}>
          <Grid item>
            <Button variant={'text'}
                    color={'secondary'}
                    onClick={onCancel}
            >
              <Close/> Cancel
            </Button>
          </Grid>

          <Grid item>
            <LoadingButton
              loading={loading}
              type={'submit'}
              variant={'contained'}
              color={'primary'}
            >{deleting ? 'Delete' : 'Save'} </LoadingButton>
          </Grid>
        </Grid>
      </DialogActions>
    </form>
  );
}
