// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-class-assign, */

import React, {Component, Fragment} from 'react';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ExpandMoreButton from '../../shared/ExpandMoreButton';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core';
import {connect} from 'react-redux';
import {toFloat} from '../../../servicesOld/ItemBuilderServices';
import ImageWithTooltip from '../../shared/ImageWithTooltip';
import {get} from 'lodash';
import Grid from '@material-ui/core/Grid';
import {decimalPlaces} from '../../shared/numberUtils';
import {asCurrencyRounded} from '../../../utils';
import {cleanDescriptionDynamic} from '../../../utils/stripHtml';

const styles = (theme) => ({
  itemSummaryRow: {
    border: '0px',
    borderTop: `1px solid ${theme.colors.grey.light}`,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    }
  },
  noBorder: {border: '0px'},
  topBorder: {borderTop: `1px solid ${theme.colors.grey.light}`,},
  chevronDown: {transform: 'scale(1, -1)'},
  nowrap: {[theme.breakpoints.down('md')]: {whiteSpace: 'nowrap'}},
});

const decorationsVisibleToCustomer = (decorations) => decorations.filter((decorationCost) => decorationCost.displayToCustomer);

function RenderDescription({item}) {
  const {description} = item;

  return (
    <>
      <Grid container direction={'column'} spacing={1}>
        <Grid item>
          <div dangerouslySetInnerHTML={{__html: cleanDescriptionDynamic(description)}} />
        </Grid>
      </Grid>
    </>
  );
}

const Decorations = ({classes, decorationCosts}) => (decorationCosts.length > 0
    ? <React.Fragment>
      <TableRow className={classes.noBorder}>
        <TableCell className={classes.noBorder} />
        <TableCell className={classes.noBorder} />
        <TableCell className={classes.noBorder} />
        <TableCell className={classes.noBorder}>
          <Typography variant='subtitle2' colspan='6'>Decorations</Typography>
        </TableCell>
        <TableCell className={classes.noBorder}>
          <Typography variant='subtitle2' colspan='6'>Position</Typography>
        </TableCell>
        <TableCell colSpan={2} className={classes.noBorder}>
          <Typography variant='subtitle2' colspan='6'>Size</Typography>
        </TableCell>
        <TableCell className={classes.noBorder}>
          <Typography variant='subtitle2' colspan='6'>Color</Typography>
        </TableCell>
      </TableRow>
      {decorationCosts.map(
        (decorationCost) => <TableRow key={decorationCost._id} className={classes.noBorder}>
          <TableCell className={classes.noBorder} />
          <TableCell className={classes.noBorder} />
          <TableCell className={classes.noBorder} />
          <TableCell className={classes.noBorder}>
            {decorationCost.description}
          </TableCell>
          <TableCell className={classes.noBorder}>
            {decorationCost.position}
          </TableCell>
          <TableCell colSpan={2} className={classes.noBorder}>
            {decorationCost.size}
          </TableCell>
          <TableCell className={classes.noBorder}>
            {decorationCost.colour}
          </TableCell>
        </TableRow>)
      }</React.Fragment>
    : null);

function RenderPriceElement({classes, company, columnClasses, priceElement}) {
  const {quantity} = priceElement;

  const decorations = get(priceElement, 'decorationCosts', []);
  const additionalCosts = get(priceElement, 'additionalCosts', []);
  const variations = get(priceElement, 'variations', []);

  return variations.map((variation) => {
    const decorationSellPrice = decorations && decorations.reduce((sum, elem) => toFloat(elem.sellPrice) + toFloat(sum), 0);

    const additionalCostsSellPrice = additionalCosts && additionalCosts.reduce((sum, elem) => toFloat(elem.sellPrice) + toFloat(sum), 0);

    const rate = asCurrencyRounded(toFloat(variation.sellPrice) + (decorationSellPrice) + additionalCostsSellPrice / toFloat(quantity));

    return (
      <TableRow key={variation.size}>
        <TableCell className={classes.noBorder} />
        <TableCell className={classes.noBorder} />
        <TableCell className={classes.noBorder}>
          {variation.displayToCustomer && variation.productName}
        </TableCell>
        <TableCell className={classes.noBorder}>
          {variation.size || ''}
        </TableCell>
        <TableCell className={classes.noBorder}>
          {variation.colour || ''}
        </TableCell>
        <TableCell className={`${classes.noBorder} ${columnClasses.tdQuantity}`}>
          {variation.quantity}
        </TableCell>
        <TableCell className={`${classes.noBorder} ${columnClasses.tdRate}`}>
          {company.currencySymbol} {decimalPlaces(rate, 2)}
        </TableCell>
        <TableCell className={`${classes.noBorder} ${columnClasses.tdTotal}`}
          align='right'>
          {company.currencySymbol} {decimalPlaces(rate * variation.quantity, 2)}
        </TableCell>
      </TableRow>
    );
  }
  );
}

class QuoteItem extends Component {
  constructor(props) {
    super(props);

    this.state = {showSubItems: false};
  }

  render() {
    const {showSubItems} = this.state;
    const {item, company, columnClasses, hideTotal} = this.props;
    const {imageUrl, rate, quantity, priceElement} = item;

    const variations = get(item, 'priceElement.variations', []);
    const decorationCosts = decorationsVisibleToCustomer(get(
      item,
      'priceElement.decorationCosts',
      []
    ));

    const showProductNameHeader = variations.some((e) => e.displayToCustomer === true);

    const {classes} = this.props;
    return (
      <TableBody>
        <TableRow>
          <TableCell
            className={`${classes.itemSummaryRow} ${columnClasses.tdProduct} ${!showSubItems ? classes.topBorder : null}`}>
            <ImageWithTooltip imageUrl={imageUrl} />
          </TableCell>
          <TableCell className={`${classes.itemSummaryRow} ${!showSubItems ? classes.topBorder : null}`} colSpan={4}>
            <RenderDescription item={item} showDetail={showSubItems} />
            {Boolean(variations.length || decorationCosts.length) &&
              <ExpandMoreButton
                onClick={() => this.setState((prevState) => ({showSubItems: !prevState.showSubItems}))}
                showSubItems={showSubItems}
              />
            }
          </TableCell>
          <TableCell
            className={`${classes.itemSummaryRow} ${columnClasses.tdQuantity} ${!showSubItems ? classes.topBorder : null}`}>
            {quantity}
          </TableCell>
          <TableCell
            className={`${classes.itemSummaryRow} ${columnClasses.tdRate} ${!showSubItems ? classes.topBorder : null}`}>
            {rate && <Fragment>{company.currencySymbol} {decimalPlaces(rate, 2)}</Fragment>}
          </TableCell>
          <TableCell
            className={`${classes.itemSummaryRow} ${columnClasses.tdTotal} ${!showSubItems ? classes.topBorder : null}`}
            style={{fontWeight: '500'}}
            align='right'>
            {rate && <Fragment>{company.currencySymbol} {decimalPlaces(quantity * decimalPlaces(rate, 2), 2)}</Fragment>}
          </TableCell>
        </TableRow>
        {Boolean(showSubItems && priceElement && variations.length) &&
          <TableRow className={classes.subItemsHeader}>
            <TableCell className={classes.noBorder} />
            <TableCell className={classes.noBorder} />
            <TableCell className={classes.noBorder}>
              {showProductNameHeader && <Typography variant='subtitle2'>Product Name</Typography>}
            </TableCell>
            <TableCell className={classes.noBorder}><Typography variant='subtitle2'>Size</Typography></TableCell>
            <TableCell className={classes.noBorder}><Typography variant='subtitle2'>Color</Typography></TableCell>
            <TableCell className={`${classes.noBorder} ${columnClasses.tdQuantity}`}><Typography
              variant='subtitle2'>Quantity</Typography></TableCell>
            <TableCell className={`${classes.noBorder} ${columnClasses.tdRate}`}><Typography
              variant='subtitle2'>{`Rate${hideTotal ? ' (Ex Tax)' : ''}`}</Typography></TableCell>
            <TableCell className={`${classes.noBorder} ${columnClasses.tdTotal}`} align='right'><Typography
              variant='subtitle2'>{`Total${hideTotal ? ' (Ex Tax)' : ''}`}</Typography></TableCell>
          </TableRow>
        }
        {showSubItems && priceElement ?
          <RenderPriceElement company={company} priceElement={priceElement} columnClasses={columnClasses}
            classes={classes} /> : null}
        {showSubItems ? <Decorations classes={classes} decorationCosts={decorationCosts} /> : null}
      </TableBody>
    );
  }
}

function mapStateToProps(state) {
  return {
    company: state.quoteReducer.quote.company,
    quote: state.quoteReducer.quote
  };
}

QuoteItem = connect(mapStateToProps)(QuoteItem);

export default withStyles(styles)(QuoteItem);
