// TODO: fix eslint disable
/* eslint-disable no-class-assign */

// Wrapper around the actual Stripe <*Element>, so that it can be used as `inputComponent`
// for Material UI's <Input>. Also adds some styling.

import React, {PureComponent} from 'react';
import withStyles from '@material-ui/core/es/styles/withStyles';

const styles = () => ({
  root: {
    width: '100%',
    padding: '6px 0 7px',
    cursor: 'text',
  },
});

class StripeInput extends PureComponent {
  static displayName = 'StripeInput';

  static defaultProps = {onChange: () => {},};

  render() {
    const {
      classes: c,
      theme,
      component: Component,
      onFocus,
      onBlur,
      onChange,
    } = this.props;

    return (
      <Component
        className={c.root}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        placeholder=''
        style={{
          base: {
            fontSize: `${theme.typography.fontSize}px`,
            fontFamily: theme.typography.fontFamily,
            color: '#000000de',
          },
        }}
      />
    );
  }
}

StripeInput = withStyles(styles, {withTheme: true})(StripeInput);

export default StripeInput;
