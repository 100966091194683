import React, {useCallback, useEffect, useState} from 'react';
import {Typography} from '@mui/material';
import {Button, UserAvatar} from '../../componentsLib/Basic';
import {
  AutoCompleteContainer,
  AutoCompleteEndButtons,
  AutoCompleteList,
  AutoCompleteListItem,
  AutoCompletePopover,
  AutoCompleteSearchBar,
  AutoCompleteTextInput
} from '../../componentsLib/Popovers';
import {HoopsPropTypes} from '../../componentsLib';
import {Column, Row} from '../../componentsLib/Layout';
import {HighlightSearchText} from '../../componentsLib/Text';
import {useListContacts} from '../../hooks/api';
import {colors} from '../../theme';
import {fullName} from '../../utils/fullName';

export function ContactAutoComplete({className, contact: initContact, search: initSearch, onChange, onSearchChange, onAddNewCustomer, placeholder, label}) {
  const [currentContact, setContact] = useState(initContact);
  const [search, setSearch] = useState(initSearch);
  const {data: {contacts}, isLoading} = useListContacts({search});

  useEffect(() => {
    setContact(initContact);
  }, [initContact]);

  useEffect(() => {
    setSearch(initSearch);
  }, [initSearch]);

  const handleChange = useCallback((_id) => {
    const newContact = contacts.find((contact) => contact._id === _id);
    if (newContact) {
      setContact(newContact);
      if (newContact !== currentContact) {
        onChange?.(newContact);
      }
    }
  }, [currentContact, onChange, contacts]);

  const handleSearchChange = useCallback((e) => {
    const newSearch = e.target.value;
    if (newSearch !== search) {
      onSearchChange(newSearch);
    }
  }, [onSearchChange, search]);

  return (
    <AutoCompleteContainer
      className={[className, 'customer-contact-autocomplete']}
      label={label}
      value={currentContact?._id}
      search={search}
      onChange={handleChange}
      onSearchChange={handleSearchChange}
      placeholder={placeholder}
      loading={isLoading}
    >
      <AutoCompleteTextInput label={label} value={(currentContact && currentContact.customer) ? `${currentContact.customer?.name ?? ''} | ${currentContact.fullName ?? fullName(currentContact) ?? ''}` : ''} />
      <AutoCompletePopover className={'customer-contact-autocomplete-popover'}>
        <AutoCompleteSearchBar search={search} />
        <AutoCompleteList>
          {contacts?.map((contact) => (
            <AutoCompleteListItem key={contact._id} value={contact._id}>
              <Row gap>
                <UserAvatar name={contact.fullName} color={colors.palette.orange} />
                <Column className={'contact-details'}>
                  <Typography className={'title'}>
                    <HighlightSearchText text={contact.fullName} searchTerm={search} />
                  </Typography>
                  <Typography variant={'caption'}>
                    {contact.customer && <HighlightSearchText text={contact.customer.name} searchTerm={search} />}
                    {contact.email && ` | ${contact.email}`}
                    {contact.phone && ` | ${contact.phone}`}
                  </Typography>
                </Column>
              </Row>
            </AutoCompleteListItem>
          ))}
        </AutoCompleteList>
        <AutoCompleteEndButtons>
          <Button navPrimary onClick={onAddNewCustomer}>Add New Customer</Button>
        </AutoCompleteEndButtons>
      </AutoCompletePopover>
    </AutoCompleteContainer>
  );
}

ContactAutoComplete.propTypes = {
  className: HoopsPropTypes.className,
  label: HoopsPropTypes.string,
  search: HoopsPropTypes.string,
  onChange: HoopsPropTypes.func,
  contact: HoopsPropTypes.object,
  onSearchChange: HoopsPropTypes.func,
  onAddNewCustomer: HoopsPropTypes.func,
  placeholder: HoopsPropTypes.string,
};
