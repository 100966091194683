import {
  SET_SCHEDULE,
  CREATE_SCHEDULE,
  UPDATE_SCHEDULE,
  REMOVE_SCHEDULE,
  UPDATE_SCHEDULES,
  MODAL_SCHEDULE
} from '../actions/schedules';

export const newSchedule = {
  $isNew: true,
  status: 'TO DO',
  startAt: null,
  endAt: null,
  isDone: false,
  resourceId: null,
  jobId: null,
  jobChildMappingIds: []
};

export const initialState = {
  active: {...newSchedule},
  list: {
    data: [],
    pagination: null,
    loaded: false
  },
  lastSaved: null,
  lastRemoved: null,
  modalOpen: null
};

const scheduleReducer = (state = initialState, {type, payload}) => {
  switch (type) {

    case MODAL_SCHEDULE:
      return {
        ...state,
        modalOpen: payload
      };
    
    case UPDATE_SCHEDULES:
      return {
        ...state,
        list: {
          data: payload,
          loaded: true
        }
      };

      case SET_SCHEDULE:
        return {
          ...state,
          active: payload
        };

    case CREATE_SCHEDULE:
      return {
        ...state,
        lastSaved: payload.record,
        list: {
          ...state.list,
          data: [
            ...state.list.data,
            payload.record
          ]
        }
      };

    case UPDATE_SCHEDULE:
      return {
        ...state,
        lastSaved: payload.record,
        list: {
          ...state.list,
          data: state.list.data.map(
            (item) => item._id === payload.recordId ? payload.record : item
          )
        }
      };

    case REMOVE_SCHEDULE:
      return {
        ...state,
        lastRemoved: payload,
        list: {
          ...state.list,
          data: state.list.data.filter(
            (item) => item._id === payload ? false : true
          )
        }
      };

    default:
      return state;
  }
};

export default scheduleReducer;
