// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {AuthService} from '../../servicesOld/AuthService';
import {SET_USER_INTERCOM_ATTRIBUTES, SetUserData, UserLoggedIn} from './user';
import {snackError} from '../action-types/snackbar';
import UserService from '../../servicesOld/UserService';
import {getBilling, getUpcomingInvoice} from '../billing';

export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const SET_USER_DATA = 'SET_USER_DATA';
export const LOGGING_IN = 'LOGGING_IN';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const login = (username, password) => function(dispatch) {
    dispatch({type: LOGGING_IN});

    return AuthService.login(username, password)
      .then(() => {
        dispatch(getCurrentUser());
        dispatch(UserLoggedIn(true));

        // Billing
        dispatch(getBilling());
        dispatch(getUpcomingInvoice());
      })
      .catch((err) => {
        dispatch({
          type: LOGIN_ERROR,
          payload: err.message
        });
        dispatch(snackError('Password/Username incorrect'));
      });
  };

export const logout = () => function(dispatch) {
    return AuthService.logout()
      .then(() => {
        dispatch(SetUserData({}));
        return dispatch(UserLoggedIn(false));
      });
  };

export const getCurrentUser = () => function(dispatch) {
    return AuthService.getMe()
      .then((user) => {
        dispatch(SetUserData(user));
        return UserService.getUserIntercomAttributes()
          .then(({data: user}) => {
            dispatch({
              type: SET_USER_INTERCOM_ATTRIBUTES,
              payload: user.user,
            });
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((e) => {
        console.error(e);
        // eslint-disable-next-line no-debugger
        debugger;
        dispatch(logout());
      });
  };
