import {Link as RouterLink} from 'react-router-dom';
import {Icon, ListItemIcon, MenuItem, Link} from '@mui/material';
import * as React from 'react';

export const HoopsMenuItem = ({
  href = null,
  to = null,
  intercom = null,
  icon = null,
  text = null,
  ...props
}) => {

  if (href)
    {return (
      <MenuItem
        data-intercom-target={intercom}
        href={href}
        color='inherit'
        underline='none'
        target='_blank'
        component={Link} 
        {...props}
      >
        <ListItemIcon>
          <Icon>{icon}</Icon>
        </ListItemIcon>
        {text}
      </MenuItem>
    );}

  if (to)
    {return (
      <RouterLink to={to} style={{textDecoration: 'none', outline: 'none', color: 'inherit'}}>
        <MenuItem
          data-intercom-target={intercom}
          {...props}
        >
          <ListItemIcon>
            <Icon>{icon}</Icon>
          </ListItemIcon>
          {text}
        </MenuItem>
      </RouterLink>
    );}

  return (
    <MenuItem
      data-intercom-target={intercom}
      {...props}
    >
      <ListItemIcon>
        <Icon>{icon}</Icon>
      </ListItemIcon>
      {text}
    </MenuItem>
  );
};
