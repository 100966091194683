import * as React from 'react';
import {DialogContent, Typography} from '@mui/material';
import theme from '../../../ui/theme';
import {AttentionConfirmDialog} from '../../../componentsLib/Popovers';

export const DeleteColumnConfirmation = ({onClose, columnName}) => (
    <AttentionConfirmDialog onClose={onClose} okText={'Delete'} cancelText={'Cancel'} warningIcon>
      <DialogContent>
        <Typography sx={{px: 10, color: theme.colors.grey.dark}} align={'center'}>
          {`Are you sure you want to delete the '${columnName}' column? This action cannot be undone.`}
        </Typography>
        <br/>
        <Typography sx={{px: 10,  color: theme.colors.grey.dark}} align={'center'} fontStyle={'italic'}>
          Note: Instead of deleting, you can HIDE the column from the view, this will not delete the data within the column.
        </Typography>
      </DialogContent>
    </AttentionConfirmDialog>
  );
