import {makeStyles, MenuItem, Select} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    select: {
        background: theme.colors.white,
        marginBottom: theme.spacing(2),
        top: -6
    },
}));

export const HoopsQueryKanbanSelectJob = ({
    getStatusColumnName = () => null,
    onChange = () => null,
    statusType = ''
}) => {
    const classes = useStyles();
    return (
        <Select
            variant='outlined'
            value={statusType}
            onChange={onChange}
            margin='dense'
            size='small'
            style={{width: 176, textAlign: 'left'}}
            className={classes.select}
        >
            <MenuItem value={'accountStatus'}>{getStatusColumnName('departmentalStatuses|accountStatus')}</MenuItem>
            <MenuItem value={'purchasingStatus'}>{getStatusColumnName('departmentalStatuses|purchasingStatus')}</MenuItem>
            <MenuItem value={'artworkStatus'}>{getStatusColumnName('departmentalStatuses|artworkStatus')}</MenuItem>
            <MenuItem value={'productionStatus'}>{getStatusColumnName('departmentalStatuses|productionStatus')}</MenuItem>
            <MenuItem value={'shippingStatus'}>{getStatusColumnName('departmentalStatuses|shippingStatus')}</MenuItem>
        </Select>
    );
};

export default HoopsQueryKanbanSelectJob;
