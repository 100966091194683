// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles, TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import {get} from 'lodash';
import React from 'react';
import {Controller, useFieldArray} from 'react-hook-form';
import editEntity from '../../ui/theme/editEntity';

const useStyles = makeStyles((theme) => (
  {
    ...editEntity,
    link: {paddingLeft: theme.spacing(2)},
    inputWidth: {width: 176,},
    vertDivider: {
      margin: '0 auto',
      color: theme.colors.grey.light,
    },
  }
));

export const TaxRateForm = ({form: {control, errors, formState}, dataId = ''}) => {
  const classes = useStyles();
  const {fields, remove, append} = useFieldArray({
    control,
    name: 'components',
  });

  return (
    <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start'>
      <Grid item xs={12}>
        <Typography variant='h5' style={{paddingTop: 32, paddingBottom: 24}}>Add Rates</Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          style={{width: 384}}
        >
          <Grid item xs='6'>
            <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start'>
              <Grid item><Typography variant='caption'>Tax Name </Typography></Grid>
              <Grid item><Tooltip placement='top' arrow classes={{tooltip: classes.tooltip}}
                title={<>
                  <Typography align='center' variant='caption' display='block'>Set the tax name. For invidual taxes, this would often &quot;GST&quot; or &quot;VAT&quot;</Typography>
                  <Typography align='center' variant='caption' display='block'>For multiple taxes, this is each individual tax within the group such as &quot;County&quot; or &quot;State&quot;</Typography>
                </>}><InfoIcon fontSize='small' className={classes.icon} /></Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs='6' style={{paddingBottom: 4}}>
            <Typography variant='caption'>Rate (%)</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {fields.map((field, idx) => (
          <Grid container spacing={2} key={field.id}>
            <Grid item>
              <Controller
                render={(props) => (
                  <TextField
                    {...props}
                    className={`${classes.inputStyles} ${classes.inputWidth}`}
                    placeholder='Enter tax name...'
                    variant='outlined'
                    required
                    error={!!get(errors, `components[${idx}].name`)}
                    helperText={get(errors, `components[${idx}].name.message`)}
                  />
                )}
                name={`components[${idx}].name`}
                rules={{required: {value: true, message: 'Rate Name is Required'}}}
                control={control}
              />
            </Grid>
            <Grid item>
              <Controller
                render={(props) => (
                  <TextField
                    {...props}
                    className={`${classes.inputStyles} ${classes.inputWidth}`}
                    variant='outlined'
                    placeholder='Enter rate...'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>% </InputAdornment>
                      ),
                      min: '0',
                      step: '0.1'
                    }}
                    type={'number'}
                    required
                    error={!!get(errors, `components[${idx}].rate`)}
                    helperText={get(errors, `components[${idx}].rate.message`)}
                  />
                )}
                name={`components[${idx}].rate`}
                rules={{required: {value: true, message: 'Rate is Required'}}}
                control={control}
              />
              <IconButton aria-label='delete'
                color='secondary'
                onClick={() => remove(idx)}
                style={{marginLeft: 8}}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>

          </Grid>
        ))}
      </Grid>
      <Grid item xs='auto'>
        <Button
          color='primary'
          onClick={() => append({name: '', rate: 0})}
        >
          Add another Tax Rate
        </Button>
      </Grid>
    </Grid>
  );
};
