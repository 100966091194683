// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import {Grid, makeStyles, Typography} from '@material-ui/core';
import React from 'react';
import IndividualAddress from '../address/individualAddress';
import HoopsDataPanel from '../shared/HoopsDataPanel';
import ProofPortalCheckbox from './ProofPortalCheckbox';
import {AppAccess, permissionCheck, SubscriptionTypes} from '../../componentsHoops';
import store from '../../store';

const useStyles = makeStyles((theme) => ({grey: {color: theme.colors.grey.main},}));

const CustomerViewDetail = ({customer = null,}) => {
  const classes = useStyles();
  const company = store.getState()?.companyReducer.company;

  const companyEntity = (customer?.companyTradingEntityId && company?.companyTradingEntities?.find((entity) => entity._id === customer?.companyTradingEntityId))
    || company?.companyTradingEntities?.[0];

  return (
    <>
      <Grid container direction={'column'} spacing={4}>
        <Grid item>
          <HoopsDataPanel title={'Company'} icon={'business'}>
            <Grid container item xs={12} spacing={4}>
              <Grid item xs={3}>
                <Typography className={classes.grey}>Company Name</Typography>
                <Typography>{customer.name}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.grey}>Company Email</Typography>
                <Typography>{customer.email}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.grey}>Company Phone</Typography>
                <Typography>{customer.phone}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.grey}>Website</Typography>
                <Typography>{customer.website}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.grey}>Customer Rep</Typography>
                <Typography>{customer.customerRep && customer.customerRep.fullName}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.grey}>Minimum Markup</Typography>
                <Typography>{customer.settings && customer.settings?.minimumMarkup}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.grey}>Sales Discount</Typography>
                <Typography>{customer.settings && customer.settings?.salesDiscount}</Typography>
              </Grid>
              {permissionCheck({allowedSubscriptions: [SubscriptionTypes.fullyPromoted]}) &&
                <Grid item xs={3}>
                  <Typography className={classes.grey}>Industry Type</Typography>
                  <Typography>{customer.industryType}</Typography>
                </Grid>
              }
              {permissionCheck({allowedAppAccess: [AppAccess.MultiCompany]}) &&
                <Grid item xs={3}>
                  <Typography className={classes.grey}>Your Company Branding to Display</Typography>
                  <Typography>{companyEntity?.name}</Typography>
                </Grid>
              }
              <Grid item xs={12}>
                <Typography className={classes.grey}>Profile</Typography>
                <Typography style={{whiteSpace: 'pre-line'}}>{customer.profile}</Typography>
              </Grid>
              <Grid container item>
                <Grid item container>
                  <Typography className={classes.grey}>Customer Options</Typography>
                </Grid>
                <Grid item container>
                  <ProofPortalCheckbox />
                </Grid>
              </Grid>
            </Grid>
          </HoopsDataPanel>
        </Grid>
        <Grid item>
          <HoopsDataPanel title={'Contacts'} icon={'person'}>
            <Grid container item xs={12} spacing={4}>
              {customer.contacts.length === 0 && (
                <Grid item>
                  <Typography className={classes.grey} component={'span'}>
                    {customer.name} has no stored contacts
                  </Typography>
                </Grid>
              )}
              {customer.contacts.map((contact) => (
                <>
                  <Grid item xs={3}>
                    <Typography className={classes.grey}>Name</Typography>
                    <Typography>{contact.firstName} {contact.lastName}</Typography>
                  </Grid>

                  <Grid item xs={3}>
                    <Typography className={classes.grey}>Email</Typography>
                    <Typography>{contact.email}</Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography className={classes.grey}>Phone</Typography>
                    <Typography>{contact.phone}</Typography>
                  </Grid>
                </>
              ))}
            </Grid>
          </HoopsDataPanel>
        </Grid>
        <Grid item>
          <HoopsDataPanel title={'Address'} icon={'place'}>
            <Grid container item xs={12} spacing={4}>
              {customer.addresses.length === 0 && (
                <Grid item>
                  <Typography className={classes.grey} component={'span'}>{customer.name} has no stored addresses</Typography>
                </Grid>
              )}
              {customer.addresses.map((address, index) => (
                <IndividualAddress key={index} address={address} />
              ))}
            </Grid>
            <Grid item xs={12}></Grid>
          </HoopsDataPanel>
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    </>
  );
};

export default CustomerViewDetail;
