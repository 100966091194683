// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React, {Component} from 'react';
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  withStyles,
  Grid,
  InputAdornment,
  Typography
} from '@material-ui/core';
import {get} from 'lodash';
import {Field} from 'react-final-form';
import {renderTextField} from '../../shared/formHelpers/fields';

const styles = (theme) => ({
  border: {
    border: `1px solid ${theme.colors.grey.light}`,
    width: '50%'
  },
  stripedRow: {backgroundColor: theme.colors.grey.ultraLight},
  minimumPadding: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: 'white',
    fontSize: '12px',
    fontWeight: '500',
  },
  noMargin: {margin: '0px',}
});

class ProductShippingDetailsTable extends Component {
  render() {
    const {classes} = this.props;
    return (
      <Table size='small' >
        <TableBody>
          <TableRow>
            <TableCell className={classes.border}>Carton Size</TableCell>
            {this._renderCartonSizeCell(['length', 'width', 'height'])}
          </TableRow>
          <TableRow className={classes.stripedRow}>
            <TableCell className={classes.border}>Carton Weight</TableCell>
            {this._renderEditCell('cartonWeight')}
          </TableRow>
          <TableRow>
            <TableCell className={classes.border}>Qty Per Carton</TableCell>
            {this._renderEditCell('qtyPerCarton')}
          </TableRow>
          <TableRow className={classes.stripedRow}>
            <TableCell className={classes.border}>Carton Cubic</TableCell>
            {this._renderEditCell('cartonCubic')}
          </TableRow>
          <TableRow>
            <TableCell className={classes.border}>Carton Notes</TableCell>
            {this._renderEditCell('cartonNotes')}
          </TableRow>
          <TableRow className={classes.stripedRow}>
            <TableCell className={classes.border}>Freight Description</TableCell>
            {this._renderEditCell('freightDescription')}
          </TableRow>
          <TableRow>
            <TableCell className={classes.border}>Individual Product Packaging</TableCell>
            {this._renderEditCell('individualProductPackaging')}
          </TableRow>
        </TableBody>
      </Table>
    );
  }

  _renderEditCell = (key) => {
    const {product, classes, editing} = this.props;
    return (
      <TableCell className={classes.border}>
        {editing ? (
          <Field
            name={key}
            component={renderTextField}
            variant={'outlined'}
            size={'small'}
            margin={'none'}
            InputProps={{classes: {input: classes.minimumPadding}}}
          />
        ) : get(product, key, '')}
      </TableCell>
    );
  };

  _renderCartonSizeCell = (keys) => {
    const {product, classes, editing} = this.props;
    return (
      <TableCell className={classes.border}>
        { editing ? (
          <Grid container spacing={1}>
            {keys.map((key) => (
              <Grid key={key} item container alignItems={'center'} xs={4}>
                <Grid item>
                  <Field
                    name={`cartonSize.${key}`}
                    component={renderTextField}
                    variant={'outlined'}
                    size={'small'}
                    margin={'none'}
                    InputProps={{
                      classes: {input: classes.minimumPadding},
                      startAdornment:
                        <InputAdornment
                          position='start'
                        >
                          <Typography muted style={{fontSize: '12px'}}>{key[0].toUpperCase()}</Typography>
                        </InputAdornment>
                    }}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
        ) :
          <span>
            {product.cartonSize.length && product.cartonSize.width && product.cartonSize.height ? (
                <span>
                  {`
                    L: ${get(product, 'cartonSize.length', '')} |
                    W: ${get(product, 'cartonSize.width', '')} |
                    H: ${get(product, 'cartonSize.height', '')}
                  `}
                </span>
            ) : (
              ''
            )}
          </span>
        }
      </TableCell>
    );
  }
}

ProductShippingDetailsTable = withStyles(styles)(ProductShippingDetailsTable);
export default ProductShippingDetailsTable;
