import {pick} from 'lodash';
import {
  GQL_AUTOMATION_CREATE,
  GQL_AUTOMATION_DELETE,
  GQL_AUTOMATION_GET_BY_ID,
  GQL_AUTOMATION_GET_MANY,
  GQL_AUTOMATION_UPDATE,
} from './queries';
import {client} from '../../servicesOld/GraphQLService';
import {toFloat} from '../../componentsOld/shared/numberUtils';

export default class AutomationService {
  static _parseAutomationInput(automation) {
    const keys = [
      'name',
      'entityType',
      'trigger',
      'isDisabled',
      'config'
    ];
    if (automation.config?.howLong !== undefined) {
      automation.config.howLong = toFloat(automation.config.howLong);
    }
    return pick(automation, keys);
  }

  static createAutomation(automationData) {
    return client
      .mutate({
        mutation: GQL_AUTOMATION_CREATE,
        variables: {record: AutomationService._parseAutomationInput(automationData),},
      })
      .then(({data: {automationCreateOne: automation}}) => automation);
  }

  static updateAutomation(_id, automationData) {
    return client
      .mutate({
        mutation: GQL_AUTOMATION_UPDATE,
        variables: {
          _id,
          record: AutomationService._parseAutomationInput(automationData),
        },
      })
      .then(({data: {automationUpdateById: automation}}) => automation);
  }

  static getAutomationById(_id) {
    return client
      .query({
        query: GQL_AUTOMATION_GET_BY_ID,
        variables: {_id,},
        fetchPolicy: 'no-cache',
      })
      .then(({data: {automationById}}) => automationById);
  }

  static getManyAutomation(entity) {
    return client
      .query({
        query: GQL_AUTOMATION_GET_MANY,
        variables: {filter: {entity,},},
        fetchPolicy: 'no-cache',
      })
      .then(({data: {automationMany}}) => automationMany);
  }

  static deleteAutomation(_id) {
    return client
      .mutate({
        mutation: GQL_AUTOMATION_DELETE,
        variables: {_id,},
      })
      .then(({data: {automationRemoveById: recordId}}) => recordId);
  }
}
