import React, {useCallback, useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import {useDispatch, useSelector} from 'react-redux';
import XERO_DEFAULT from '../../assets/images/integrations/xero/connect-white.svg';
import {get} from 'lodash';
import moment from 'moment';
import {connectToXero} from '../../actions/integrations';
import {client} from '../../servicesOld/GraphQLService';
import {GQL_DISCONNECT_XERO} from '../../queries/companies';
import {getCurrentUser} from '../../actions/action-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {MuiThemeProvider} from '@material-ui/core';
import red from '../../ui/theme/red';
import ButtonBase from '@material-ui/core/ButtonBase';
import XeroSettingsModal from './XeroSettingsModal';

function XeroConnect() {
  const dispatch = useDispatch();
  const userSelector = (state) => state.authReducer.userData;
  const user = useSelector(userSelector);

  const [url, setUrl] = useState(XERO_DEFAULT);
  const [xeroSettingsIsOpen, setXeroSettingsIsOpen] = useState(false);
  const [accountingPlatform, setAccountingPlatform] = useState();
  const [tokenExpiry, setTokenExpiry] = useState();

  useEffect(() => {
    setAccountingPlatform(get(user, 'company.accountingPlatform'));
    if (accountingPlatform && accountingPlatform.refreshTokenExpiry) {
      setTokenExpiry(moment(accountingPlatform.refreshTokenExpiry, 'x'));
    }
  }, [user, accountingPlatform]);

  const handleMouseEnter = useCallback(() => {
    setUrl(XERO_DEFAULT);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setUrl(XERO_DEFAULT);
  }, []);

  const handleClick = useCallback(() => {
    connectToXero(user.companyId);
  }, [user]);

  const handleDisconnect = useCallback(async () => {
    await client.mutate({mutation: GQL_DISCONNECT_XERO});
    dispatch(getCurrentUser());
  }, [dispatch]);

  const handleSettingsClick = useCallback(() => {
    setXeroSettingsIsOpen(true);
  }, []);

  if (accountingPlatform && accountingPlatform.platform === 'QUICKBOOKS') {
    return <>
      <Grid container alignItems={'center'} justifyContent={'center'}>
        <Grid item xs>
          <Typography align={'center'} variant={'body2'}>
            Only one accounting integration can be connected. Disconnect Quickbooks to connect to Xero.
          </Typography>
        </Grid>
      </Grid>
    </>;
  }

  if (accountingPlatform && accountingPlatform.platform === 'XERO') {
    return (
      <>
        <XeroSettingsModal isOpen={xeroSettingsIsOpen} setIsOpen={setXeroSettingsIsOpen} />
        <Grid container justifyContent={'center'} direction={'row'} spacing={1}>
          <Grid item xs={12}>
            <Typography align={'center'}>
              Connected to <br />
              <strong>{accountingPlatform.companyName}</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button color={'primary'} variant={'outlined'} fullWidth onClick={handleSettingsClick}>Settings</Button>
          </Grid>
          {tokenExpiry && tokenExpiry.isBefore() &&
            <Grid item xs={12}>
              <Button color={'primary'} variant={'outlined'} fullWidth onClick={handleClick}>Reconnect</Button>
            </Grid>}
          {tokenExpiry && tokenExpiry.isAfter() &&
            <Grid item xs={12}>
              <MuiThemeProvider theme={red}>
                <Button color={'primary'} variant={'outlined'} fullWidth onClick={handleDisconnect}>Disconnect
                  Xero</Button>
              </MuiThemeProvider>
            </Grid>}
          <Grid item>
            {!!tokenExpiry && <Typography variant={'body2'} align={'center'}>
              Reconnect will be required on <br />
              <i>{tokenExpiry.format('ll')}</i>
            </Typography>}
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <>
      <ButtonBase
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        <img
          src={url}
          alt={'Connect to Xero'}
          onClick={handleClick}
          height={45}
        />
      </ButtonBase>
    </>
  );
}

export default XeroConnect;
