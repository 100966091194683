import {Grid, ListItemIcon, TextField} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import StatusGroupStatusEdit from './StatusGroupStatusEdit';

export default ({statusGroup, onChange = () => null}) => {
  const {control, handleSubmit, watch} = useForm({defaultValues: statusGroup});
  const [_statusGroup, setStatusGroup] = useState(statusGroup);
  const watchLabel = watch('label');
  const onSubmit = (data) => console.log('d', data);

  useEffect(() => {
    setStatusGroup((prev) => {
      const res = {...prev, label: watchLabel};
      onChange(res);
      return res;
    });
  }, [watchLabel, onChange]);

  const handleLabelsChange = (statuses) => {
    setStatusGroup((prev) => {
      const res = {...prev, statuses};
      onChange(res);
      return res;
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
      <Grid container spacing={2}>
        {statusGroup.locked ? null : <Grid item xs={12}>
          <ListItemIcon style={{width: '73px'}}>&nbsp;</ListItemIcon>
          <Controller
            as={<TextField
              size='small'
              label='Column Name'
              autoComplete='off'
              inputProps={{maxLength: 15}}
              placeholder='Column Name'
            />}
            name='label'
            control={control}
          />
        </Grid>}
        <Grid item xs={12}>
          <StatusGroupStatusEdit statuses={_statusGroup.statuses} onChange={handleLabelsChange} />
        </Grid>
      </Grid>
    </form>
  );
};
