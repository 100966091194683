import React, {ReactNode, Ref, useLayoutEffect, useRef} from 'react';
import {useDrag, useDrop} from 'react-dnd';
import type {Identifier} from 'dnd-core';

const ITEM_TYPE = 'SORTABLE_ITEM';

interface DragItem {
  index: number,
  id: string,
  type: string
}

interface HoopsSingleSortableListProps<T = any> {
  move: (dragIndex: number, hoverIndex: number) => void,
  index: number,
  id: string,
  itemType?: string,
  children: ((params: { handlerRef: Ref<T> }) => ReactNode) | ReactNode
}

export function HoopsSingleSortableList({
                                          index,
                                          move,
                                          children,
                                          id,
                                          itemType = ITEM_TYPE
                                        }: HoopsSingleSortableListProps) {
  const handlerRef = useRef<HTMLDivElement>(null);
  const [{isDragging,}, drag, dragPreview] = useDrag({
    type: itemType,
    item: () => ({id, index}),
    collect: (monitor) => ({isDragging: monitor.isDragging(),}),
  });
  const [{handlerId}, drop] = useDrop<DragItem, void,
    { handlerId: Identifier | null }>({
    accept: itemType,
    collect(monitor) {
      return {handlerId: monitor.getHandlerId(),};
    },
    hover(item, monitor) {
      if (!handlerRef.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = handlerRef.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      if (!clientOffset) {
        return;
      }
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      move(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;

    },
  });

  drag(drop(handlerRef));
  const ref = typeof children === 'function' ? dragPreview : handlerRef;
  useLayoutEffect((() => {
    if (handlerRef.current) {
      handlerRef.current.classList.add('sortable-handler');
    }
  }), []);
  const opacity = isDragging ? 0 : 1;
  return <div ref={ref} style={{opacity}} className={'sortable-item'} data-handler-id={handlerId}>
    {typeof children === 'function' ? children({handlerRef}) : children}
  </div>;
}
