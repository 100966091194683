import {Button} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import React from 'react';

const useStyles = makeStyles((theme) => ({button: {backgroundColor: theme.colors.white,},}));

export const ActionsButton = (props) => {
  const classes = useStyles();
  return (
    <Button
      color='primary'
      variant='outlined'
      endIcon={<KeyboardArrowDown />}
      className={classes.button}
      {...props}
    >{props.children ? props.children : 'Actions'}</Button>
  );
};

export default ActionsButton;
