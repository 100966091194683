// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import {Grid, makeStyles, Paper, Table, TableContainer} from '@material-ui/core';
import React from 'react';
import {GQL_USER_PAGINATION} from '../../queries/users';
import {colors} from '../../ui/theme';
import {hoopsQueryStyles} from '../../ui/theme/hoops';
import {HoopsQuery, HoopsQueryContext, HoopsQueryEmptyState, HoopsQueryFilterChips, HoopsQueryFilterDropMenu, HoopsQueryFilterList, HoopsQueryFilterSearch, HoopsQueryPagination, HoopsQueryTableBody, HoopsQueryTableHeader} from '../HoopsQuery';
import HoopsQueryTableCellDateTime from '../HoopsQuery/HoopsQueryTableCellDateTime';
import NameWithInitials from '../NameWithInitials';
import UserActionsMenu from './UserActionsMenu';
import UserStatusChip from './UserStatusChip';

const useStyles = makeStyles((theme) => ({...hoopsQueryStyles,}));

export const UserManyTable = () => {
  const classes = useStyles();
  const columns = [{
    label: 'Name',
    field: 'fullName',
    sortable: true,
    sortKey: 'FIRSTNAME',
    render: ({fullName}) => <NameWithInitials name={fullName} color={colors.orange} />
  }, {
    label: 'Email',
    field: 'username'
  }, {
    label: 'Invited On',
    field: 'createdAt',
    render: (rowData) => <HoopsQueryTableCellDateTime value={rowData.createdAt} />,
  }, {
    label: 'Status',
    field: 'status',
    align: 'center',
    render: ({status}) => <UserStatusChip status={status} style={{align: 'center'}} />
  }, {
    label: 'Actions',
    render: (user) => <UserActionsMenu user={user} />
  }];

  const filterListStatus = [{
    key: 'status',
    type: 'array',
    options: [],
    label: 'Status',
    component: (props) => <HoopsQueryFilterList {...props}
      options={
        [{value: 'ACTIVE', label: 'ACTIVE'}, {value: 'INVITED', label: 'PENDING'}, {value: 'DELETED', label: 'DELETED'}]
      }
    />
  }];

  return (
    <HoopsQuery refetchStateId='user' queryStateId='userMany' gqlQuery={GQL_USER_PAGINATION} columns={columns} resultField='userPagination' initialFilter={{}} initialSort={'FIRSTNAME_ASC'}>
      <HoopsQueryContext.Consumer>
        {({items, sort, setSort, filter, setFilter, paging, setPaging, chips, setChips, hoopsQueryColumns, setHoopsQueryColumns}) => (<>
            <Grid container direction='row' justifyContent='space-between' alignItems='flex-start'>
              <Grid item>
                <HoopsQueryFilterDropMenu
                  label={'Status'}
                  filter={filter}
                  filterComponents={filterListStatus}
                  onApply={setFilter}
                  chips={chips}
                            onChipsChange={setChips}
                />
              </Grid>
              <Grid item>
              <Grid container>
                    <Grid item>
                      <HoopsQueryFilterSearch onChange={setFilter} filter={filter} placeholder={'Search'} chips={chips} onChipsChange={setChips} />
                    </Grid>
                  </Grid>
              </Grid>
            </Grid>
            <HoopsQueryFilterChips filter={filter} onFilterChange={setFilter} chips={chips} onChipsChange={setChips} />
            <TableContainer component={Paper}>
                <Table className={classes.table}>
                <HoopsQueryTableHeader hasCheckBox={false} columns={hoopsQueryColumns} onSort={setSort} sortString={sort} />
                <HoopsQueryTableBody rowsPerPage={paging.perPage} rows={items} columns={hoopsQueryColumns} emptyStateComponent={() => <HoopsQueryEmptyState filter={filter} field={'User'} columns={hoopsQueryColumns} rowsPerPage={paging.perPage} onClearFilter={setFilter}/>} />
              </Table>
            </TableContainer>
            <HoopsQueryPagination paging={paging} onPaging={setPaging} />
          </>)}
      </HoopsQueryContext.Consumer>
    </HoopsQuery>
  );
};

export default UserManyTable;
