import React from 'react';

export const HoopsQueryContext = React.createContext({

    fetch: () => null,
    fetchAction: () => null,

    chips: {},
    setChips: () => { },

    rows: [],
    setRows: () => [],

    view: null,
    setView: () => null,

    statusType: null, 
    setStatusType: () => null,

    getKanbanColumns: () => null,
       
    pageInfo: {
        itemCount: 25,
        perPage: 25,
        pageCount: 1,
        currentPage: 1,
        hasNextPage: false,
        hasPreviousPage: false,
    },
    setPageInfo: () => { },

    filter: {},
    setFilter: () => { },

    handleScroll: () => null,
    leftScrollShadow: false,
    rightScrollShadow: false,

    contentHeight: 0,

    hoopsQueryColumns: [],
    setHoopsQueryColumns: () => null,
    
});

export default HoopsQueryContext;
