import {format, isPast} from 'date-fns';

export const taskAssociationsToEntities = ({jobs = [], quotes = []}) => {
    const jobsMapped = jobs ? jobs.map((item) => ({entityType: 'Job', entityId: item._id, entityData: item})) : [];
    const quotesMapped = quotes ? quotes.map((item) => ({entityType: 'Quote', entityId: item._id, entityData: item})) : [];
    return [
        ...jobsMapped,
        ...quotesMapped
    ];
};

export const isOverdue = (date) => isPast(new Date(date));

export const DueDate = ({task}) => {
    if (!task.dueAt) {
        return null;
    }

    const formatted = task.allDay ?
        format(new Date(task.dueAt), 'dd MMM yyyy') :
        format(new Date(task.dueAt), 'dd MMM yyyy  hh:mma');

    return formatted;
};
