import Dexie from 'dexie';
import {assign, omit} from 'lodash';

const DB_VERSION = 1;
export const db = new Dexie('hoopsDb');

db.version(DB_VERSION).stores({
  catalogImportSettings: '++id,vendorId',
  catalogImportData: '++id,settingsId'
});

export class DexieBase {
  constructor(store, data) {
    this._db = db[store];
    this._collection = this._db.where('id').above(0);
    assign(this, data);
  }

  findById(id) {
    return this._db.where('id').equals(id).first();
  }

  save() {
    this.vendorId = this.vendor._id;
    return this._db.put(omit(this, ['_db', '_collection']));
  }

  update(id, change) {
    return this._db.update(id, change);
  }

  bulkAdd(data) {
    return this._db.bulkAdd(data);
  }

}

export default db;
