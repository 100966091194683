// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import {Dialog, Grid, Link, makeStyles, Typography} from '@material-ui/core';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import React, {useState} from 'react';
import Youtube from 'react-youtube';

const useStyles = makeStyles((theme) => ({
  subtitle: {paddingLeft: '3px',},
  links: {margin: '10px 0 20px -2px'},
  link: {
    display: 'inline-flex',
    lineHeight: '24px'
  },
  dialogContainer: {
    width: '854px',
    height: '480px'
  }
}));

export const EntityListHero = ({
  title = 'TITLE NOT SET',
  subtitle = null,
  helpArticleUrl = null,
  helpArticleTitle = null,
  videoId = null,
  children = null
}) => {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleVideoClick = (e) => {
    e.preventDefault();
    setIsDialogOpen(true);
  };

  return (
    <Grid container justifyContent={'space-between'} spacing={3}>
      <Grid item>
        <Typography variant='h1'>{title}</Typography>
        {subtitle ? <Typography variant='body2' className={classes.subtitle}>{subtitle}</Typography> : null}
        <div className={classes.links}>
          {helpArticleUrl ? <Link
            href={helpArticleUrl}
            rel='noopener'
            target='_blank'
            className={classes.link}
            underline='none'
          >
            <BookmarkIcon />&nbsp;{helpArticleTitle ? helpArticleTitle : 'Get Started'}
          </Link> : null}
          &nbsp;&nbsp;&nbsp;
          {videoId ? <Link
            href='#'
            rel='noopener'
            target='_blank'
            className={classes.link}
            underline='none'
            onClick={handleVideoClick}
          >
            <PlayCircleFilledWhiteIcon />&nbsp;Watch a Video
          </Link> : null}
        </div>
        <Dialog
          open={isDialogOpen}
          fullScreen={true}
          classes={{paper: classes.dialogContainer}}
          onClose={() => setIsDialogOpen(false)}

        >
          <Youtube
            opts={{width: '854px', height: '480px'}}
            videoId={videoId}
          />
        </Dialog>
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EntityListHero;
