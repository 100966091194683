// TODO: fix eslint disable
/* eslint-disable no-shadow */

import React, {MouseEvent, ReactNode} from 'react';
import {Popover} from '@mui/material';
import {ColorResult} from '@uiw/color-convert/src';
import {
  color as handleColor,
  getContrastingColor,
  hexToHsva,
  HsvaColor,
  hsvaToHex,
  validHex,
} from '@uiw/color-convert';
import EditableInput from '@uiw/react-color-editable-input';
import Swatch from '@uiw/react-color-swatch';

interface ColorPickerProps {
  color?: string,
  button: ReactNode,
  onChange: (color: string) => void
}

export const ColorPicker = ({color, onChange, button}: ColorPickerProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const open = (event: MouseEvent<HTMLDivElement>) => setAnchorEl(event.currentTarget);
  const close = () => setAnchorEl(null);
  const onSelect = (color: ColorResult) => {
    onChange(color.hex);
    close();
  };
  const _onChange = (color: ColorResult) => {
    onChange(color.hex);
  };
  const isOpen = Boolean(anchorEl);
  const id = isOpen ? 'simple-popover' : undefined;

  return (
    <>
    <div onClick={open}>{button}</div>
      <Popover
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={close}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Block
            colors={[
              '#00CCF0',
              '#9CECFA',
              '#00CEC9',
              '#A29BFE',
              '#9366AF',
              '#22CC96',
              '#178965',
              '#48FC70',
              '#7DB84C',
              '#4D7103',
              '#FC5F65',
              '#FD79A8',
              '#FC66FF',
              '#FAB1A0',
              '#D01E25',
              '#A36468',
              '#CC8778',
              '#FD8979',
              '#FFBF66',
              '#FFEAA7',
              '#2F323C',
              '#8F9197',
              '#8F9197',
              '#007DF0',
              '#01549F',
            ]}
            color={color}
            onChange={_onChange}
            onSelect={onSelect}
          />
      </Popover>
    </>
  );
};

// @ts-ignore
export interface BlockProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange' | 'color'> {
  prefixCls?: string;
  color?: string | HsvaColor;
  colors?: string[];
  onChange?: (color: ColorResult) => void;
  onSelect?: (color: ColorResult) => void;
}

export const validFullHex = (hex: string): boolean => /^#?([A-Fa-f0-9]{6})/.test(hex);

const _Block = (props: BlockProps, ref: React.Ref<HTMLDivElement>) => {
  const {prefixCls = 'w-color-block', className, style, color, colors, onChange, onSelect, ...other} = props;
  // @ts-ignore
  const hsva: HsvaColor = (typeof color === 'string' && validHex(color) ? hexToHsva(color) : color);
  const hex = color ? hsvaToHex(hsva) : '';
  const handleChange = (hsv: HsvaColor) => {
    onSelect && onSelect(handleColor(hsv));
  };
  const handleHex = (value: string | number) => {
    if (typeof value === 'string' && validFullHex(value) && /(6)/.test(String(value.replace(/^#/, '').length))) {
      onChange && onChange(handleColor(hexToHsva(value)));
      return true;
    }
    return false;
  };
  return (
          // @ts-ignore
          <div
                  ref={ref}
                  className={[prefixCls, className].filter(Boolean).join(' ')}
                  style={{
                    width: 170,
                    borderRadius: 6,
                    background: 'rgb(255, 255, 255)',
                    boxShadow: 'rgb(0 0 0 / 10%) 0 1px',
                    position: 'relative',
                    ...style,
                  }}
                  {...other}
          >
            <div
                    style={{
                      width: 0,
                      height: 0,
                      borderStyle: 'solid',
                      borderWidth: '0 10px 10px',
                      borderColor: `transparent transparent ${hex}`,
                      position: 'absolute',
                      top: -10,
                      left: '50%',
                      marginLeft: -10,
                    }}
            />
            <div
                    title={hex}
                    style={{
                      backgroundColor: `${hex}`,
                      color: getContrastingColor(hex),
                      height: 110,
                      fontSize: 18,
                      borderRadius: '6px 6px 0 0',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
            >
              {hex.toLocaleUpperCase()}
            </div>
            <Swatch
                    colors={colors}
                    color={hex}
                    style={{
                      paddingLeft: 10,
                      paddingTop: 10,
                    }}
                    rectProps={{
                      style: {
                        marginRight: 10,
                        marginBottom: 10,
                        borderRadius: 4,
                        height: 22,
                        width: 22,
                      },
                    }}
                    onChange={handleChange}
            />
            <EditableInput
                    value={hex.toLocaleUpperCase()}
                    onChange={(evn, val) => handleHex(val)}
                    onBlur={(evn) => {
                      if (handleHex(evn.target.value)){
                        onSelect && onSelect(handleColor(hexToHsva(evn.target.value)));
                      }
                    }}
                    inputStyle={{
                      height: 22,
                      outline: 0,
                      borderRadius: 3,
                      color: '#666',
                      padding: '0 7px',
                    }}
                    style={{
                      padding: 10,
                      paddingTop: 0,
                      borderRadius: '0 0 6px 6px',
                    }}
            />
          </div>
  );
};
const Block = React.forwardRef<HTMLDivElement, BlockProps>(_Block);

Block.displayName = 'Block';

