// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-class-assign */

import React, {Component} from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  Grid,
  Icon,
} from '@material-ui/core';
import DialogTitle from '../DialogTitle';
import DialogActions from '../DialogActions';
import {connect} from 'react-redux';
import {closeJobArtworkModal as _closeJobArtworkModal} from '../../../actions/modals';
import {updateFile as _updateFile} from '../../../actions/files';
import {reduxForm, reset as _reset} from 'redux-form';
import {
  getJob as _getJob,
  addJobArtwork as _addJobArtwork,
  updateJobArtwork as _updateJobArtwork,
} from '../../../actions/jobs';

import HoopsUpload from '../HoopsUpload';
import HoopsFileAssociation from '../../../modules/file/HoopsFileAssociation';

class JobArtworkModal extends Component {

  state = {files: []};

  handleUpload = (file) => {
    this.setState({files: [...this.state.files, file]});
  }

  handleFileAssociation = ({file, associations}) => {
    const {updateFile} = this.props;
    const payload = {
      ...file,
      JobItemIds: associations.filter((item) => item.type === 'product').map((item) => item.value),
      JobItemVariantDecorationIds: associations.filter((item) => item.type === 'decoration').map((item) => item.value),
    };
    updateFile(file._id, payload);
  }

  closeJobArtworkModal = () => {
    const {closeModal, resetForm} = this.props;
    closeModal();
    resetForm();
    this.setState({files: []});
  };

  handleSubmit = ({content}) => {
    const {getJob, Job} = this.props;
    this.closeJobArtworkModal();
    getJob(Job._id);
  };

  render() {
    const {isOpen, handleSubmit, Job} = this.props;

    // Convert the nested Product -> Decoration structure to a list
    const flatList = Job?.items?.reduce((agg, product) => {
      let local = [
        ...agg,
        {
          value: product._id,
          text: product.description,
          type: 'product',
          bold: true,
        },
      ];

      if (product && product.variants) {
        local = [
          ...local,
          ...product.variants.decorations.map((decoration) => ({
            value: decoration._id,
            text: `${decoration.description}`,
            type: 'decoration',
          })),
        ];
      }

      return local;
    }, []) ?? [];

    return (
      <Dialog
        open={isOpen}
        maxWidth={'sm'}
        fullWidth={true}
        onClose={this.closeJobArtworkModal}
      >
        <DialogTitle onClick={this.closeJobArtworkModal}>
          <Typography variant='h5'>Add Artwork to Job</Typography>
        </DialogTitle>
        <form onSubmit={handleSubmit(this.handleSubmit)}>
          <DialogContent>

          <HoopsUpload onUploadComplete={this.handleUpload} meta={{JobId: Job?._id}}/>

          <HoopsFileAssociation files={this.state.files} options={flatList} onChange={this.handleFileAssociation} />

          </DialogContent>
          <DialogActions>
            <Grid container justifyContent={'space-between'}>
              <Grid item>
                <Button onClick={this.closeJobArtworkModal}>
                  <Icon>close</Icon>
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      variant={'contained'}
                      color={'primary'}
                      type='submit'
                    >
                      done
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => dispatch(_closeJobArtworkModal()),
    getJob: (id) => dispatch(_getJob(id)),
    addJobArtwork: (jobId, content) => dispatch(_addJobArtwork(jobId, content)),
    updateFile: (fileId, fileData) => dispatch(_updateFile(fileId, fileData)),
    updateJobArtwork: (artworkId, content, jobId) =>
      dispatch(_updateJobArtwork(artworkId, content, jobId)),
    resetForm: () => dispatch(_reset('jobArtwork')),
  };
}

function mapStateToProps(state) {
  return {
    isOpen: state.modalReducer.jobArtworkModal.isOpen,
    Job: state.jobsReducer.activeJob,
    initialValues: state.modalReducer.jobArtworkModal.context,
    context: state.modalReducer.jobArtworkModal.context,
  };
}

JobArtworkModal = reduxForm({
  form: 'jobArtwork',
  initialValues: {files: []}
})(JobArtworkModal);
JobArtworkModal = connect(mapStateToProps, mapDispatchToProps)(JobArtworkModal);
export default JobArtworkModal;
