// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React, {Component, Fragment} from 'react';
import {closeProofUploadModal as _closeProofUploadModal} from '../../../../actions/modals';
import {connect} from 'react-redux';
import {Button, Dialog, DialogContent, Icon, Typography} from '@material-ui/core';
import DialogTitle from '../../DialogTitle';
import Grid from '@material-ui/core/Grid';
import HoopsDropzone from '../../HoopsDropzone';
import ProofFileList from './ProofFileList';
import DialogActions from '../../DialogActions';
import {withStyles} from '@material-ui/styles';
import {
  reduxForm,
  change as _change,
  reset as _reset,
  arrayPush as _arrayPush,
  FieldArray,
} from 'redux-form';
import {
  bulkCreateProof as _bulkCreateProof,
  getProofs as _getProofs
} from '../../../../actions/proofs';
import Link from '@material-ui/core/Link';
import {get} from 'lodash';
import {MAX_FILE_SIZE_BYTES, MAX_FILE_SIZE_MEGABYTES} from '../../../../constants/file';

const styles = (theme) => ({
  root: {overflowY: 'visible'},
  dialogTitle: {marginBottom: theme.spacing(2)}
});

const UnacceptedFileTypeReason = () => {
  const helpDocs = 'http://help.hoopscrm.com/en/articles/3300631-image-file-types';
  return (
    <Typography variant={'body2'}>Only image type files are accepted (.png, .jpeg, .jpg).
      <Link href={helpDocs} target={'_blank'}> View Full List</Link>
    </Typography>
  );
};

class ProofUploadModal extends Component {
  handleFileUploadComplete = (file) => {
    const {addPendingProof, job} = this.props;
    addPendingProof({
      file: file,
      fileId: file._id,
      jobId: job._id,
      products: []
    });
  };

  handleSubmit = (values) => {
    const {getProofs, job, bulkCreateProof, resetForm} = this.props;

    if (values.proofs.length === 0) {
      return;
    }

    bulkCreateProof(values.proofs, () => {
      resetForm();
      getProofs(job._id);
    });
  };

  render() {
    const {isOpen, close, classes, job, handleSubmit} = this.props;
    const meta = {customerId: job?.customerId || undefined};

    const customer = get(job, 'customer', {});

    return (
      <Fragment>
        <Dialog open={isOpen}
                maxWidth={'lg'}
                fullWidth={true}>
          <DialogTitle onClick={close}>
            <Typography className={classes.dialogTitle} variant={'h4'}>Upload Proof</Typography>
            {job && <Typography variant={'body2'} color={'textSecondary'}><strong>Job Number:</strong> {job.number}
            </Typography>}
            {job && <Typography variant={'body2'} color={'textSecondary'}><strong>Customer:</strong> {get(customer, 'name', '')}
            </Typography>}
          </DialogTitle>
          <form onSubmit={handleSubmit(this.handleSubmit)}>
            <DialogContent className={classes.root}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <HoopsDropzone
                        meta={meta}
                        data-intercom-target={'proof-upload-dropzone'}
                        onComplete={this.handleFileUploadComplete}
                                     maxSize={MAX_FILE_SIZE_BYTES}
                                     accept={['.png', '.jpeg', '.gif', '.jpg', '.bmp', '.pdf']}
                                     acceptReason={<UnacceptedFileTypeReason/>}
                      />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent={'center'} spacing={2}>
                          <Grid item xs={'auto'}>
                            <Typography variant={'body2'}>Recommended file types:</Typography>
                          </Grid>
                          <Grid item xs={'auto'}>
                            <Typography variant={'body2'}>jpg, jpeg, gif, png, pdf</Typography>
                          </Grid>
                          <Grid item><Typography variant={'body1'} color={'textSecondary'}> | </Typography> </Grid>
                          <Grid item xs={'auto'}>
                            <Typography variant={'body2'}>Recommended size:</Typography>
                          </Grid>
                          <Grid item xs={'auto'}>
                            <Typography variant={'body2'}><strong>1000 x 1000px,
                            200KB</strong></Typography>
                          </Grid>
                          <Grid item><Typography variant={'body1'} color={'textSecondary'}> | </Typography> </Grid>
                          <Grid item xs={'auto'}>
                            <Typography variant={'body2'}>Maximum file size:</Typography>
                          </Grid>
                          <Grid item xs={'auto'}>
                            <Typography variant={'body2'}>{MAX_FILE_SIZE_MEGABYTES}MB</Typography>
                          </Grid>
                        </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <FieldArray name={'proofs'} component={ProofFileList}/>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid container justifyContent={'space-between'}>
                <Grid item>
                  <Button onClick={close}>
                    <Icon>close</Icon>
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Button variant={'contained'}
                              color={'primary'}
                              data-intercom-target={'proof-save-button'}
                              type='submit'>Save</Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        </Dialog>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {

  return {
    job: state.jobsReducer.activeJob,
    isOpen: state.modalReducer.proofUploadModalIsOpen,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    close: () => {
      dispatch(_reset('proofUploadForm'));
      dispatch(_closeProofUploadModal());
    },
    change: (field, value) => dispatch(_change('proofUploadForm', field, value)),
    resetForm: () => dispatch(_reset('proofUploadForm')),
    bulkCreateProof: (proofData, cb) => dispatch(_bulkCreateProof(proofData, cb)),
    addPendingProof: (proofData) => dispatch(_arrayPush('proofUploadForm', 'proofs', proofData)),
    getProofs: (jobId) => dispatch(_getProofs(jobId))
  };
}

ProofUploadModal = withStyles(styles)(ProofUploadModal);
ProofUploadModal = reduxForm({
  form: 'proofUploadForm',
  initialValues: {proofs: []}
})(ProofUploadModal);
ProofUploadModal = connect(mapStateToProps, mapDispatchToProps)(ProofUploadModal);
export default ProofUploadModal;

