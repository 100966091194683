// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import {InputAdornment, TableRow, TextField} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import {get} from 'lodash';
import numeral from 'numeral';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {change as _change, getFormValues} from 'redux-form';
import {reduceElement} from '../../../purchase-orders/CalculationMethods';
import {ProductsTableRow} from './ProductsTableRow';

const styles = (theme) => ({
    tablePadding: {padding: theme.spacing(1)},
    subTotalInput: {border: 'none'},
    textFieldWithAdornmentBtn: {
      margin: '8px 0 4px 0 !important',
      '& .MuiOutlinedInput-adornedEnd': {
        paddingLeft: 0,
        paddingRight: 0,
      }
    }
  });

class ProductsTableRows extends Component {
  handleAddMoreClick = () => {
    const {fields} = this.props;
    fields.push({});
  };

  handleDeleteRowClick = (index) => {
    const {fields} = this.props;
    fields.splice(index, 1);
  };

  handleDuplicateRowClick = (field) => {
    const {fields, formValues} = this.props;
    const newRow = get(formValues, field, {});
    delete newRow._id;

    fields.push(newRow);
  };

  calculateTotal = (index) => {
    const {formValues} = this.props;
    const {quantity, rate} = formValues.variants[index];
    let total = '';
    if (quantity && rate) {
      total = numeral(quantity * rate).format('0,0.00');
    }
    return total;
  };

  render() {
    const {fields, classes, poFormValues: {locked}, formValues, product, currencySymbol, meta} = this.props;
    const variantsTotal = numeral(formValues.variants.reduce(reduceElement, 0)).format('0,0.00');
    return (
      <Fragment>
        {fields.map((field, index) => (
            <ProductsTableRow
              locked={locked}
              key={index}
              field={field}
              rowIdx={index}
              meta={meta}
              formValues={formValues}
              classes={classes}
              currencySymbol={currencySymbol}
              calculateTotal={this.calculateTotal}
              handleDeleteRowClick={this.handleDeleteRowClick}
              handleDuplicateRowClick={this.handleDuplicateRowClick}
            />))}
        <TableRow>
          <TableCell className={classes.tablePadding} colSpan={5}>
            {!locked &&
              <Button
                component={'div'}
                variant={'text'}
                color={'primary'}
                onClick={() => this.handleAddMoreClick(product)}
              >
                {fields.length ? 'Add Another Product' : 'Add a Product'}
              </Button>}
          </TableCell>
          <TableCell className={classes.tablePadding}>
            <Typography variant={'body2'}
              align={'right'}
              color={'textSecondary'}>Sub Total</Typography>
          </TableCell>
          <TableCell className={classes.tablePadding}>
            <TextField disabled
              className={classes.subTotalInput}
              variant={'outlined'}
              value={variantsTotal}
              margin={'dense'}
              fullWidth={true}
              InputProps={{startAdornment: <InputAdornment position={'start'}>{currencySymbol}</InputAdornment>}}
            />
          </TableCell>
          <TableCell />
        </TableRow>
      </Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    formValues: getFormValues(ownProps.meta.form)(state),
    poFormValues: getFormValues('purchaseOrderForm')(state),
    currencySymbol: state.authReducer.userData.company.currencySymbol
  };
}

function mapDispatchToProps(dispatch) {
  return {change: (form, field, value) => dispatch(_change(form, field, value))};
}

ProductsTableRows = connect(mapStateToProps, mapDispatchToProps)(ProductsTableRows);
ProductsTableRows = withStyles(styles)(ProductsTableRows);
export default ProductsTableRows;
