import {useLocation} from 'react-router';
import {useLocalStorageState} from './usePersistentState';
import {useMemo} from 'react';

/**
 * This will record a 'refresh hint' for a specified path. If the current path
 * matches the specified path, it will return the recorded hint, otherwise it
 * will return the default value.
 *
 * The idea being that, in some cases it is convenient to set a short path in the browser,
 * but we need a little more information about what was happening at that path.
 *
 * Currently, it is used to remember what was being edited on the SalesDoc page, but
 * it might be useful to remember the page number on a grid, etc
 *
 * @param path
 * @param defaultValue
 * @returns {[*,*]}
 */
export function useRefreshHint(path, defaultValue) {
  const location = useLocation();
  const [_hint, setHint] = useLocalStorageState(path, defaultValue);
  const hint = location.pathname === path ? _hint : defaultValue;

  return useMemo(() => [hint, setHint], [hint, setHint]);
}
