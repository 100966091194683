// TODO: fix eslint disable
/* eslint-disable no-class-assign, */

import React, {Fragment} from 'react';
import {CircularProgress, IconButton, Icon, withStyles} from '@material-ui/core';
import {omit} from 'lodash';
import ImageWithTooltip from '../ImageWithTooltip';

const styles = () => ({
    popper: {opacity: 1},
    tooltip: {maxWidth: '450px'}
  });

class IconUploadField extends React.Component {
  handleClick = () => {
    const {onClick} = this.props;
    if (onClick) {
      onClick();
      return;
    }

    this.upload.click();
  };

  render() {
    const {input, inputProps, uploadState: {progress, s3Object, isLoading}} = this.props;

    return (
      <Fragment>
        <input {...omit(input, 'value')}
               {...inputProps}
               type={'file'}
               style={{display: 'none'}}
               ref={(ref) => this.upload = ref}/>
        {
          !isLoading && !s3Object && !input.value &&
          <IconButton onClick={this.handleClick}>
            <Icon color={'primary'}>add_circle_outline</Icon>
          </IconButton>
        }
        {
          isLoading &&
          <CircularProgress value={progress * 100} variant={'static'}/>
        }
        {
          input && input.value &&
          !isLoading && <ImageWithTooltip imageUrl={input.value} onClick={this.handleClick}/>
        }
      </Fragment>
    );
  }
}

IconUploadField = withStyles(styles)(IconUploadField);

export default IconUploadField;
