import {Grid, TableBody, TableCell, TableRow} from '@material-ui/core';
import React from 'react';
import ProductCard from './ProductCard';

export const ProductManyGrid = ({
    rows = [],
    emptyStateComponent = () => null,
    onProductSelect = () => null
}) => (
        <TableBody>
            {rows.length > 0 ?
                <TableRow>
                    <TableCell>
                        <Grid container spacing={3}>
                            {rows.map((product, index) => (
                                <Grid key={index} item style={{width: '250px'}}>
                                    <ProductCard product={product} onProductSelect={onProductSelect} />
                                </Grid>
                            ))}
                        </Grid>
                    </TableCell>
                </TableRow>
                : emptyStateComponent()
            }

        </TableBody>
    );

export default ProductManyGrid;
