// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-shadow */

import {Chip, Grid, Link as MuiLink, makeStyles, Typography} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {get} from 'lodash';
import moment from 'moment';
import React from 'react';
import {Link} from 'react-router-dom';
import {kanban} from '../../ui/theme/kanban';
import {toFloat} from '../shared/numberUtils';
import HoopsQueryTableCellDateTime from './HoopsQueryTableCellDateTime';

const useStyles = makeStyles((theme) => (
    {...kanban,}
));

export const HoopsQueryKanbanCardJob = ({item = {}}) => {
    const classes = useStyles();

    const now = moment();
    const dueDate = get(item, 'deadlineAt');
    const daysToDueDate = now.diff(dueDate, 'days');

    // handle due date styling
    const dueDateClass = (param) => {
        if (param > -7 && param < 0) {return classes.orangeText;}
        if (param > 0) {return classes.redText;}
        return classes.greyText;
    };

    return (
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
            <Grid item xs={12} className={classes.itemTitle}>
                <Typography variant='body2'>
                    <MuiLink rowdata-intercom-target={'job-link'} component={Link} underline={'none'} color={'primary'} to={`/jobs/${item._id}/details`}>
                        {item.number} - {item.title}
                    </MuiLink>
                </Typography>
            </Grid>
            <Grid item xs={12} className={classes.itemTextObject}>
                <Typography variant='caption' display='block'>{`${get(item, 'contact.fullName')} (${get(item, 'customer.name')})`}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.itemTextObject}>
                <Typography variant='caption' display='block' className={classes.greyText}>{get(item, 'quoteOwner.fullName')}</Typography>
            </Grid>
            {
                item.quote &&
                <Grid item xs={12} className={classes.itemTextObject}>
                    <Typography variant='caption' className={classes.greyText}>
                        Quote:
                        <MuiLink rowdata-intercom-target={'quote-link'} component={Link} underline={'none'} color={'primary'} style={{paddingLeft: '4px'}}
                            to={`/quotes/${item.quote._id}/view`}>
                            {get(item, 'quote.number')}
                        </MuiLink>
                    </Typography>
                </Grid>
            }
            <Grid item xs={6} className={classes.itemTextObject}>
                <Typography variant='caption' className={classes.greyText}>Total Qty: {
                    item.quote && get(item, 'quote.items').reduce((agg, item) => agg += toFloat(item.quantity), 0)
                }</Typography>

            </Grid>
            <Grid item xs={6} style={{textAlign: 'end'}} className={classes.itemTextObject}>
                {/* List the dueDate if it is set */}
                {dueDate &&
                    <>
                        <AccessTimeIcon style={{fontSize: 13, top: 2, position: 'relative', marginRight: 4}} className={dueDateClass(daysToDueDate)} />
                        <HoopsQueryTableCellDateTime value={dueDate} showTime={false} variant='caption' className={dueDateClass(daysToDueDate)} />
                    </>
                }
            </Grid>

            <Grid item xs={6}>
                {item.quote && item.quote.invoice && item.quote.invoice.invoiceNumber && <Typography variant='caption'>#{get(item, 'quote.invoice.invoiceNumber')}</Typography>}
            </Grid>
            <Grid item xs={6} style={{textAlign: 'end'}}>
                {/* Show chip if we're past the due date */}
                {daysToDueDate > 0 && <Chip size={'small'} label={' OVERDUE '} className={classes.chip} />}
            </Grid>
        </Grid>
    );
};

export default HoopsQueryKanbanCardJob;
