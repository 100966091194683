import {gql} from 'apollo-boost';

export const GQL_DECORATION_FRAG = gql`
    fragment DecorationFragment on Decoration {
        _id
        name
        category
        vendorId
        setupPrice
        priceBreaks {
            quantity
            price
        }
        vendor {
            _id
            name
        }
    }
`;

export const GQL_DECORATION_LIST_FRAGMENT = gql`
    fragment DecorationListFragment on Decoration {
        _id
        name
        category
        vendorId
        setupPrice
        priceBreaks {
            quantity
            price
        }
        vendor {
            _id
            name
        }
    }
`;

export const GQL_CREATE_DECORATION = gql`
    mutation DecorationCreate($record: CreateOneDecorationInput!) {
        decorationCreateOne(record: $record) {
            recordId
            record {
                ...DecorationFragment
            }   
        }
    }
    ${GQL_DECORATION_FRAG}
`;

export const GQL_GET_DECORATION = gql`
    query GetDecorationById($_id: MongoID!) {
        decorationById(_id: $_id) {
            ...DecorationFragment
        }
    }
    ${GQL_DECORATION_FRAG}
`;

export const GQL_GET_DECORATIONS = gql`
    query getDecorations($filter: FilterFindPaginationSearchDecorationInput, $page: Int, $perPage: Int) {
        decorationPagination(filter: $filter, page: $page, perPage: $perPage) {
            items {
                ...DecorationFragment
            }
            pageInfo {
                perPage
                pageCount
                currentPage
                hasNextPage
                itemCount
            }
        }
    }
    ${GQL_DECORATION_FRAG}
`;

export const GQL_DECORATION_CATEGORY_SEARCH = gql`
    query GetDecorationCategories($filter: FilterSearchDecorationCategoryInput, $limit: Int) {
        decorationCategories(filter: $filter, limit: $limit) {
            name
        }
    }
`;

export const GQL_SEARCH_MANY_DECORATIONS = gql`
    query SearchDecorationMany($filter: FilterFindManySearchDecorationInput, $limit: Int) {
        decorationMany(filter: $filter, limit: $limit) {
            ...DecorationFragment
        }
    }
    ${GQL_DECORATION_FRAG}
`;

export const GQL_UPDATE_DECORATION = gql`
    mutation DecorationUpdateById($_id: MongoID!, $record: UpdateByIdDecorationInput!) {
        decorationUpdateById(_id: $_id, record: $record) {
            recordId
            record {
                ...DecorationFragment
            }   
        }
    }
    ${GQL_DECORATION_FRAG}
`;

export const GQL_DELETE_DECORATION = gql`
    mutation DecorationRemoveById($_id: MongoID!) {
        decorationRemoveById(_id: $_id) {
            recordId
        }
    }
`;

export const GQL_DELETE_VENDOR_DECORATIONS = gql`
  mutation DecorationRemoveMany($filter: FilterRemoveManyDecorationInput!) {
    decorationRemoveMany(filter: $filter) {
      numAffected
    }
  }
`;

export const GQL_IMPORT_DECORATIONS = gql`
    mutation DecorationImport($fileId: MongoID!, $vendorId: MongoID) {
        decorationImport(fileId: $fileId, vendorId: $vendorId) {
            status
        }
    }
`;

export const GQL_DECORATION_PAGINATION = gql`
    query DecorationPagination(
        $filter: FilterFindPaginationSearchDecorationInput,
        $sort: SortFindManyDecorationInput,
        $perPage: Int,
        $page: Int
    ){
        decorationPagination(
            filter: $filter,
            sort: $sort,
            perPage: $perPage,
            page: $page
        ) {
            items {
                ...DecorationListFragment
            }
            pageInfo {
                currentPage
                perPage
                pageCount
                itemCount
                hasNextPage
                hasPreviousPage
            }
        }
    }
    ${GQL_DECORATION_LIST_FRAGMENT}
`;
