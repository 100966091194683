import React, {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import {HoopsButton} from '../../componentsLib';
import theme from '../../ui/theme';
import {DeleteViewConfirmation} from './Views';
import {useViewState} from './Views/useViewState';
import {CLEAR_JOB} from '../../actions/jobs';
import {PageContextContainer} from '../../componentsOld/PageContext';
import {JobsBoard} from './JobsBoard';
import {useJobsState} from './useJobsState';
import {PermissionBasedContent, SubscriptionTypes} from '../../componentsHoops';
import {PageTitleHero} from './PageTitleHero';

export const JobsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const viewState = useViewState({}, 'jobs');
  const jobsState = useJobsState(viewState.state);

  const handleAddJob = useCallback(() => {
    dispatch({type: CLEAR_JOB});
    history.push('/jobs/new');
  }, [dispatch, history]);

  // If a view is deleted we confirm the user really wants to delete it
  const [showDeleteViewConfirmation, setShowDeleteViewConfirmation] = useState({show: false});
  const handleDeleteView = useCallback((id, name) => {
    if (id !== 'home') {
      setShowDeleteViewConfirmation({show: true, id, name: name});
    }
  }, []);
  const handleDeleteViewConfirmation = useCallback(async (confirmation) => {
    setShowDeleteViewConfirmation({show: false});
    if (confirmation && showDeleteViewConfirmation.id) {
      await viewState.deleteView(showDeleteViewConfirmation.id);
    }
  }, [viewState, showDeleteViewConfirmation.id]);

  // Update the data in a view field
  const updateViewField = useCallback(async (path, fieldProps) => {
    await viewState.updateViewField(path, fieldProps);
    await jobsState.refetch();

  }, [viewState, jobsState]);

  // When the view is saved from the grid toolbar we have to update the view tabs to put the
  // new view in edit mode.
  const [editingView, setEditingView] = useState(null);
  const handleSaveView = useCallback(async (asNew) => {
    const newViewId = await viewState.saveView(asNew);
    if (newViewId) {
      setEditingView(newViewId);
    }
  }, [viewState]);

  // View variables
  // const viewType = 'grid'

  // Kanban Config
  // const queryStateId = "jobMany"
  // const fixedFilter = {}

  // Add proof subscription view limits
  // TODO move this to viewState
  const viewArray = viewState.viewLimit
    ? viewState.viewData?.views.slice(0, viewState.viewLimit + 1)
    : viewState.viewData?.views;

  return (
    <PageContextContainer updateField={jobsState.updateField}
      updateViewField={updateViewField}
      saveView={handleSaveView}
    >
      <div style={{
        margin: '-24px 0 0 -24px',
        width: 'calc(100% + 48px)',
        height: 'calc(100% + 48px)',
        minHeight: 'calc(100% + 48px)',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.colors.white,
      }}>
        <PageTitleHero
          currentView={viewState.currentView}
          editingView={editingView}
          title={'Job'}
          views={viewArray}
          onChangeCurrentView={viewState.setCurrentView}
          onAddView={viewState.addView}
          allowAddViews={viewState.allowAddViews}
          onDeleteView={handleDeleteView}
          onRenameView={viewState.renameView}
        >
          <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.fullyPromoted]}>
            <HoopsButton purpose='add' onClick={handleAddJob} style={{whiteSpace: 'nowrap'}}>Add Job</HoopsButton>
          </PermissionBasedContent>
        </PageTitleHero>
        <JobsBoard viewState={viewState} jobsState={jobsState} />

        {
          /*
          viewType === 'grid'
          ?
          <JobsBoard viewState={viewState} jobsState={jobsState}/>
          :
          <HoopsKanban
            refetchStateId="job"
            statusType="accountStatus"
            queryStateId={queryStateId}
            columns={undefined}
            gqlQuery={GQL_JOB_PAGINATION}
            resultField="jobPagination"
            initialFilter={{_operators: {archived: {ne: true}}}}
            fixedFilter={fixedFilter}
            initialSort={"NUMBER_DESC"}
          />
          */
        }

        {showDeleteViewConfirmation?.show &&
          <DeleteViewConfirmation onClose={handleDeleteViewConfirmation} name={showDeleteViewConfirmation.name} />
        }
      </div>
    </PageContextContainer>
  );
};

