import {makeStyles} from '@material-ui/core/styles';
import React, {useState} from 'react';
import {Document, Page, pdfjs} from 'react-pdf/dist/esm/entry.webpack';
import {HoopsIconButton} from '../../componentsOld/HoopsIconButton';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme) => ({
  parent: {padding: '0px'},
  container: {padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`},
  proofImage: {
    maxWidth: '100%',
    maxHeight: 'inherit',
    objectFit: 'contain',
  },
  iconButton: {
    borderWidth: 1,
    borderStyle: 'solid',
    width: theme.spacing(7),
    height: theme.spacing(7),
    borderRadius: '50%',
    padding: 0,
    margin: theme.spacing(2)
  },
  proofGridContainerStyles: {
    root: {
      [theme.breakpoints.down('md')]: {maxHeight: 320,},
      [theme.breakpoints.down('lg')]: {maxHeight: 520,},
      [theme.breakpoints.up('lg')]: {maxHeight: 800,},
    }
  },
  pdfDoc: {
    display: 'flex',
    justifyContent: 'center',
    '&:hover .pageController': {opacity: 1}
  },
  pdfPage: {
    padding: theme.spacing(2),
    '&:hover .pageController': {opacity: 1}
  },
  pageController: {
    position: 'absolute',
    bottom: '5%',
    left: '50%',
    background: 'white',
    opacity: 100,
    transform: 'translateX(-50%)',
    boxShadow: '0 20px 30px 0 rgb(16 36 94 / 20%)',
    borderRadius: 8,
    padding: theme.spacing(1)
  }
}));

export const SinglePagePdfViewer = ({pdfUrl}) => {
  const classes = useStyles();

  const [numberOfPages, setNumberOfPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({numPages}) {
    setNumberOfPages(numPages);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <>
      <Document
        file={pdfUrl}
        onLoadSuccess={onDocumentLoadSuccess}
        className={classes.pdfDoc}
      >
        <Page
          pageNumber={pageNumber}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          className={classes.pdfPage}
        />
      </Document>
      <div className={classes.pageController}>
          <HoopsIconButton purpose='previous' size='small' onClick={previousPage} disabled={pageNumber <= 1} />
          {pageNumber || (numberOfPages ? 1 : '--')} of {numberOfPages || '--'}
          <HoopsIconButton purpose='next' size='small' onClick={nextPage} disabled={pageNumber >= numberOfPages} />
      </div>
    </>
  );
};
