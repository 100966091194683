import React, {useState} from 'react';
import MuiMultiSelect from './formHelpers/MuiMultiSelect';
import {Chip, FormControlLabel, Typography} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';

export default ({options, input}) => {
  const [value, setValue] = useState(input ? input.value : []);

  const handleChange = (event) => {
    const {onChange = () => null} = input || {};
    setValue(event.target.value);
    onChange(event);
  };

  const args = {
    input: {value,},
    onChange: handleChange,
    options,
    SelectedComponent: ({value: item}) => (
      <Chip
        key={item.value}
        color={'primary'}
        label={item.text}
        size={'small'}
      />
    ),
    OptionComponent: ({selected, option: item}) => (
      <FormControlLabel
        control={
          <Checkbox
            checked={
              !!selected.filter(
                (selectedItem) => selectedItem.value === item.value
              ).length
            }
            name={item.text}
            color='primary'
          />
        }
        label={<Typography style={{fontWeight: item.bold ? 'bold' : ''}}>{item.text}</Typography>}
      />
    ),
  };

  return <MuiMultiSelect {...args} />;
};
