// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-shadow */

import {makeStyles} from '@material-ui/core/styles';
import React, {useCallback, useEffect, useState} from 'react';
import CatalogService from '../../../servicesOld/CatalogService';
import RenderAutocompleteField from '../../common/RenderAutocompleteField';
import ProductAutocompleteRenderOption from './ProductAutocompleteRenderOption';
import ProductAutocompleteRenderOptionButton from './ProductAutocompleteRenderOptionButton';
import productAutocompleteStyles from './productAutocompleteStyles';
import PropTypes from 'prop-types';
import {useDebounce} from '../../../hooks';

import {useSelector} from 'react-redux';
import {formValueSelector} from 'redux-form';

const useStyles = makeStyles((theme) => ({...productAutocompleteStyles}));

export const ProductAutocomplete = ({
  onSelect = () => null,
  textFieldProps,
  input: {
    value,
    onChange
  },
  input,
  meta,
  field = null,
  isSageActive = false,
  isConfiguredProductActive = false,
  ...params
}) => {
  const [loading, setLoading] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const [products, setProducts] = useState([]);
  const classes = useStyles();

  const selector = formValueSelector(meta.form);
  const productSelector = (state) => selector(state, `${field}.product`);
  const product = useSelector(productSelector);

  useEffect(() => {
    CatalogService.listProducts('HOOPS', (typeof value === 'string' ? value : value.code), 1, 8)
      .then(({data: {listProducts: {items}}}) => {
        setProducts(items);
      })
      .finally(() => setLoading(false));
  }, [value]);

  useEffect(() => {
    value ? setInputValue(typeof value === 'string' ? value : value.code) : setInputValue('');
  }, [product, value]);

  const searchProducts = useDebounce((value) => CatalogService.listProducts('HOOPS', (typeof value === 'string' ? value : value.code), 1, 25)
      .then(({data: {listProducts: {items}}}) => {
        setProducts(items);
      })
      .catch(() => {
        setProducts([]);
      })
      .finally(() => setLoading(false)), []);

  const handleAutocompleteSearch = useCallback((value) => {
    value && onChange(value);
    setProducts([]);
    setLoading(true);
    return searchProducts(value);
  }, [searchProducts, onChange]);

  return (
      <RenderAutocompleteField
        meta={meta}
        input={input}
        onChange={(e, val) => {
          if (!val) {return;}
          onChange(val);
          if (onSelect) {
            onSelect(val);
          }
        }}
        fullWidth
        onInputChange={(e, newInputValue, reason) => {
          if (reason === 'reset') {
            setInputValue('');
            return;
          } else {
            handleAutocompleteSearch(newInputValue);
          }
        }}
        textFieldProps={{
          variant: 'outlined',
          margin: 'dense',
          fullWidth: true,
          classes: {root: classes.reducedMargin},
        }}
        classes={{inputRoot: `${classes.reducedPadding} ${(isSageActive || isConfiguredProductActive) && classes.rightPanelBorder}`}}
        loading={loading}
        options={products}
        renderOption={(option) => ProductAutocompleteRenderOption(option, classes)}
        getOptionLabel={(option) => option.code}
        noOptionsText={`No results for "${value}"`}
        filterOptions={(options) => options}
        getOptionSelected={(option, value) => (option._id === value._id)}
        inputValue={inputValue}
        defaultValue={inputValue}
        ButtonComponent={(<ProductAutocompleteRenderOptionButton />)}
        {...params}
      />
  );
};

ProductAutocomplete.propTypes = {value: PropTypes.string};

export default ProductAutocomplete;
