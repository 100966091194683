import React, {useMemo} from 'react';
import * as Cells from '../Cells';
import {useGetDecorationCategories, useListUsers, useListVendors} from '../../../../hooks/api';
import {getFilteredGridOperators} from '../../GridFilter/getGridOperators';
import {get, set} from 'lodash';
import {useSelector} from 'react-redux';

const staticColumnInfo = {
  '_id': {hide: true, filterable: false},
  'actions': {
    minWidth: 60, width: 130,//130, width: 130,
    disableReorder: true,
    align: 'center',
    filterable: false,
    hideable: false,
  },
  'archived': {
    hide: true,
    hideable: false,
    pinnable: false,
    deletable: false,
    type: 'boolean',
    customFilter: true,
  },
  'voided': {
    hide: true,
    hideable: false,
    pinnable: false,
    deletable: false,
    type: 'boolean',
    customFilter: true,
  },
  'createdById': {editable: false,},
  'createdAt': {editable: false,},
  'BOOLEAN': {},
  'BUILTIN': {},
  'DATE': {
    align: 'center',
    editable: true,
    filters: ['is', 'after', 'onOrAfter', 'before', 'onOrBefore'],
    renderCell: (props) => <Cells.DateCell {...props} />,
    sort: -1,
    type: 'date',
  },
  'DECORATION': {
    filters: ['isAnyOf'],
    type: 'singleSelect',
    width: 162,
  },
  'NUMBER': {},
  'STATUS': {
    filters: ['isAnyOf'],
    type: 'singleSelect',
    width: 200,
    valueOptions: (field) => {
      const valueOptions = field.options?.map(({statusLabel}) => ({value: statusLabel, label: statusLabel})) ?? [];
      return [{value: null, label: '[NO STATUS]'}, ...valueOptions];
    },
  },
  'TEXT': {
    editable: true,
    filters: ['contains', 'startsWith', 'endsWith'],
    renderCell: (props) => <Cells.TextCell {...props} />,
    sort: 1,
  },
  'USER': {
    align: 'center',
    editable: true,
    filters: ['isAnyOf'],
    type: 'singleSelect',
    renderCell: (props) => <Cells.AvatarCell {...props} />,
    valueGetter({field, row, colDef}) {
      const value = get(row, field);
      const user = colDef.valueOptions?.find((v) => v.value === value);
      return user?.label ?? '';
    }
  },
  'VENDOR': {
    filters: ['isAnyOf'],
    type: 'singleSelect',
    width: 250,
    renderCell: Cells.VendorsCell,
    valueGetter({row, colDef: {valueOptions}}) {
      return row.digest?.vendorIds?.map((id) => valueOptions.find((option) => option.value === id)?.label).join(', ');
    }
  },
};

const columnsHiddenForSubscription = {PROOFS: ['digest.quote.number', 'digest.quote.stripeInvoiceId', 'digest.invoice._id', 'decorationCategories', 'digest.vendorIds']};

export function useGridColumnBuilder({fields, columnInfo}) {
  const {data: {users}} = useListUsers();
  const {data: {vendors}} = useListVendors();
  const {data: {decorationCategories}} = useGetDecorationCategories();

  // Permission based access control on entity boards
  const companySelector = (state) => state.companyReducer.company;
  const {appAccess, subscriptionType} = useSelector(companySelector);
  const columnLimit = appAccess?.jobBoard?.columnLimit ?? null;

  return useMemo(() => {
    if (!fields?.length) {
      return [];
    }

    const usersValueOptions = users
      ?.filter(({status}) => status === 'ACTIVE')
      .map(({fullName, _id}) => ({value: _id, label: fullName, initials: `${fullName.split(' ')[0]?.[0] || ''}${fullName.split(' ')[1]?.[0] || ''}`}));
    const vendorsKeyValue = (vendors || []).map(({_id, name}) => ({value: _id, label: name}));
    const decorationCategoriesValues = (decorationCategories || []).map(({_id}) => ({value: _id, label: _id}));

    const dynamicColumnInfo = {
      'DECORATION': {
        valueOptions: decorationCategoriesValues,
        renderCell: (params) => {
          const getLabel = (id) => {
            const row = (decorationCategories || []).find(({decorationIds}) => decorationIds.includes(id));
            return row?._id;
          };
          const decorationIds = params.row?.digest?.decorationIds;
          return decorationIds?.length > 0 && <Cells.ChipCell getLabel={getLabel} dataArray={decorationIds} />;
        },
        valueGetter({row}) {
          return row.digest?.decorationIds?.map((id) => (decorationCategories || []).find(({decorationIds}) => decorationIds.includes(id))?._id).join(', ');
        },
      },
      'USER': {valueOptions: usersValueOptions,},
      'STATUS': {
        renderCell: (props) => <Cells.StatusCell rowId={props.row._id}
          value={props.value}
          field={fields?.find((field) => field.path === props.field)} />,
      },
      'VENDOR': {valueOptions: vendorsKeyValue,},
    };

    // Remove columns from fields using the columnsHiddenForSubscription object
    const columnsHiddenForSubscriptionFilter = fields.map((field) => !columnsHiddenForSubscription[subscriptionType]?.includes(field.path) && field);
    // If subscription is a downgrade there may already be more added columns than permitted on the users jobBoard below we allow up to the columnLimit to render
    let addedFieldCounter = 0;
    const fieldArray = typeof columnLimit === 'number'
      ? columnsHiddenForSubscriptionFilter.map((field) => {
        if (!field.builtin && addedFieldCounter < columnLimit) {
          addedFieldCounter++;
          return (field);
        }
        return field.builtin && (field);
      })
      : fields;

    return [...fieldArray].filter(Boolean).map((field, index) => {
      const {filters, sort, type, valueOptions, ...props} = {
        ...(staticColumnInfo[field.type] ?? {}),
        ...(dynamicColumnInfo[field.type] ?? {}),
        ...(columnInfo[field.type] ?? {}),
        ...(staticColumnInfo[field.path] ?? {}),
        ...(dynamicColumnInfo[field.path] ?? {}),
        ...(columnInfo[field.path] ?? {}),
      };

      return {
        deletable: !field.builtin,
        minWidth: 120,
        width: 140,
        field: field.path,
        headerName: field.title,
        headerAlign: 'center',
        ...(filters ? {filterOperators: getFilteredGridOperators(filters, type)} : {}),
        ...(sort == null ? {sortable: false} : {sortingOrder: sort > 0 ? ['asc', 'desc'] : ['desc', 'asc']}),
        valueOptions: typeof valueOptions === 'function' ? valueOptions(field) : valueOptions,
        type,
        cellClassName: `CellDataType${field.type}`,
        valueGetter,
        valueSetter,
        ...props,
        index,
      };
    });
  }, [fields, columnInfo, users, vendors, decorationCategories, columnLimit, subscriptionType]);
}

function valueSetter({row, value}) {
  const clone = JSON.parse(JSON.stringify(row));
  return set(clone, this.field, value);
}

function valueGetter({field, row}) {
  return get(row, field);
}
