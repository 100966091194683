import React from 'react';
import {Tooltip as MuiTooltip} from '@mui/material';
import classNames from 'classnames';
import {HoopsPropTypes} from '../utils';
import {Placement} from '../Popovers';
import {registerGlobalStyle} from '../../theme';

registerGlobalStyle('.tooltip-container', () => ({display: 'flex'}));

export function ToolTip({className, tip, arrow = true, offset, placement, bottomLeft, left, right, top, children, disabled, focusable}) {
  if (!placement) {
    if (bottomLeft) {
      placement = Placement.BottomStart;
    } else if (left) {
      placement = Placement.Left;
    } else if (right) {
      placement = Placement.Right;
    } else if (top) {
      placement = Placement.Top;
    }
  }

  const tooltipOffset = offset && {modifiers: [{name: 'offset', options: {offset}}]};

  if ((!disabled && tip) || focusable) {
    return (
      <MuiTooltip
        title={!disabled && tip ? tip : ''}
        arrow={arrow}
        PopperProps={tooltipOffset}
        placement={placement}
        componentsProps={className ? {tooltip: {className: classNames([className, 'tooltip-popover'])}} : {}}
      >
        <div className={classNames([className, 'tooltip-container'])}>
          {children}
        </div>
      </MuiTooltip>
    );
  }

  return children;
}

ToolTip.propTypes = {
  className: HoopsPropTypes.className,
  arrow: HoopsPropTypes.bool,
  disabled: HoopsPropTypes.bool,
  focusable: HoopsPropTypes.bool, // If the children can have focus this prevents the structure changing
  offset: HoopsPropTypes.arrayOf(HoopsPropTypes.number),
  placement: HoopsPropTypes.string,
  tip: HoopsPropTypes.any,
  bottomLeft: HoopsPropTypes.bool,
  left: HoopsPropTypes.bool,
  right: HoopsPropTypes.bool,
  top: HoopsPropTypes.bool,
  children: HoopsPropTypes.children,
};
