import React, {useCallback, useRef} from 'react';
import {registerGlobalStyle} from '../../theme';
import {HoopsPropTypes} from '../utils';
import {Column} from '../Layout';
import {CloudUpload as UploadIcon} from '@mui/icons-material';
import {useSnackbar} from '../../hooks';
import {filterFiles, MaxFileSize} from '../../utils';
import {ProgressRing} from '../Progress/ProgressRing';

registerGlobalStyle('.file-select', (theme) => ({
  border: `1px dashed ${theme.colors.border.main}`,
  borderRadius: theme.shape.borderRadius,
  alignItems: 'center',
  justifyContent: 'center',
  '&>div': {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
    borderRadius: '50%',
    background: theme.colors.border.light,
    'svg': {
      width: 20,
      height: 20,
      color: theme.colors.border.main,
    },
    input: {display: 'none',},
  },
  '&:hover>div': {
    background: theme.colors.background.contrast.medium,
    pointer: 'arrow',
    svg: {color: theme.colors.background.contrast.main,}
  },
  '.progress-ring': {
    position: 'absolute',
    '--size': '46px',
    '--thickness': '8px',
    '--color': theme.colors.border.main,
  },
}));

export function FileSelect({className, accept, multi, maxSize, onChange, progress, children}) {
  const inputRef = useRef();
  const snack = useSnackbar();

  const handleChange = useCallback((e) => {
    onChange(filterFiles([...e.target.files], {accept, multi, maxSize}, snack));
  }, [accept, maxSize, multi, onChange, snack]);

  return (
    <Column className={[className, 'file-select']} onClick={() => inputRef.current?.click()}>
      <div>
        {progress && progress < 100 &&
          <ProgressRing progress={progress} flat/>
        }
        <UploadIcon/>
        <input ref={inputRef} type={'file'} accept={accept} multiple={multi} onChange={handleChange}/>
      </div>
      {children}
    </Column>
  );
}

FileSelect.propTypes = {
  className: HoopsPropTypes.string,
  accept: HoopsPropTypes.string,
  multi: HoopsPropTypes.bool,
  maxSize: HoopsPropTypes.oneOf(Object.values(MaxFileSize)),
  onChange: HoopsPropTypes.func,
  progress: HoopsPropTypes.stringOrNumber,
  children: HoopsPropTypes.children,
};
