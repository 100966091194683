import {applyMiddleware, createStore, compose} from 'redux';
import rootReducer from '../reducers';
import {mixpanelTracking} from './mixpanel-tracking';
import thunk from 'redux-thunk';
import {createLogger} from 'redux-logger';

const middlewares = [ thunk, mixpanelTracking ];

const logger = createLogger({predicate: (getState, action) => !action.type.includes('@@redux-form'),});

if (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_REDUX_LOGGER === true) {
  middlewares.push(logger);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer, /* preloadedState, */
  composeEnhancers(
    applyMiddleware(...middlewares)
  )
);

export default store;
