import {Button, Divider, Grid} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import {makeStyles, MuiThemeProvider} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LockIcon from '@material-ui/icons/Lock';
import {get} from 'lodash';
import numeral from 'numeral';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Elements, StripeProvider} from 'react-stripe-elements';
import {snackError, snackSuccess} from '../actions/action-types/snackbar';
import {getBilling} from '../actions/billing';
import {BillingService} from '../servicesOld/BillingService';
import green from '../ui/theme/green';
import CheckoutForm from './CheckoutForm';

const useStyles = makeStyles((theme) => ({
  heading: {marginBottom: theme.spacing(1)},
  contentContainer: {
    paddingTop: `${theme.spacing(4)}px !important`,
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    textAlign: 'center'
  },
  pricingContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    textAlign: 'center',
    // display: 'flex',
    // justifyContent: 'space-evenly',
    width: '100%'
  },
  divider: {width: '100%'},
  formContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 56px'
  },
  lockIcon: {color: theme.palette.primary.main}
}));

const billingSelector = (state) => state.billingReducer.billing;
const upcomingInvoiceSelector = (state) => state.billingReducer.upcomingInvoice;

export const UnpaidSubscriptionModal = () => {
  const [showModal, setShowModal] = useState(false);
  const classes = useStyles();
  const billing = useSelector(billingSelector);
  const upcomingInvoice = useSelector(upcomingInvoiceSelector);

  const dispatch = useDispatch();

  const handleRetryPayment = async (sourceId) => {
    try {
      await BillingService.retryStripePayment({source: sourceId});
      dispatch(snackSuccess('Payment Processed'));
      dispatch(getBilling()); // Get billing again
      setShowModal(false);
    } catch (e) {
      dispatch(snackError(e.message));
    }
  };

  useEffect(() => {
    const status = get(billing, 'stripeSubscription.status');

    if (!status) {
      setShowModal(false);
      return;
    }

    if (status === 'trialing' || status === 'active') {
      setShowModal(false);
    } else {
      setShowModal(true);
    }

  }, [billing]);

  const handleSuccess = (sourceId) => {
    dispatch(snackSuccess('Card Added'));
    handleRetryPayment(sourceId);
  };

  return (
    <Dialog
      aria-labelledby='customized-dialog-title'
      open={showModal}
      maxWidth={'sm'}
      fullWidth={true}
    >
      <MuiDialogContent className={classes.contentContainer}>
        <Grid container alignItems={'center'} spacing={4}>
          <Grid item xs={12} >
            <Typography className={classes.heading} variant='h5' color='primary'>Your Payment Failed!</Typography>
            <Typography variant='subtitle1' color='textSecondary'>Please retry or update your payment information.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent='space-evenly' spacing={4}>
              <Grid item xs>
                <Typography variant='subtitle1' color='textPrimary' align='left'>{'Total Amount Outstanding'}</Typography>
              </Grid>
              <Grid item xs>
                <Typography variant='h5' color='primary' align='right'>{numeral(get(upcomingInvoice, 'amount_due', 0) / 100).format('$0,0.00')}</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <MuiThemeProvider theme={green}>
              <Button variant='contained' color='primary' onClick={() => handleRetryPayment()}>Retry Payment</Button>
            </MuiThemeProvider>
          </Grid>

          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>

          <Grid item xs={12}>
            <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
              <Elements>
                <CheckoutForm checkoutSuccess={({sourceId}) => handleSuccess(sourceId)} buttonText='PROCESS PAYMENT' />
              </Elements>
            </StripeProvider>

          </Grid>

          <Grid item xs={12}>
            <Grid container direction='row' alignItems='center' justifyContent='center'>
              <LockIcon className={classes.lockIcon} />&nbsp;<Typography color='textSecondary'>Secured by Stripe with bank-level encryption</Typography>
            </Grid>

          </Grid>

        </Grid>
      </MuiDialogContent>
    </Dialog>
  );
};

export default UnpaidSubscriptionModal;
