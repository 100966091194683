// TODO: fix eslint disable
/* eslint-disable no-shadow */

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {get, has, sortBy} from 'lodash';
import * as numeral from 'numeral';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {FormattedCurrency} from '../FormattedCurrency';

const SalesPerUserRow = ({data}) => (
    <TableRow>
      <TableCell>{get(data, 'user.fullName', '-')}</TableCell>
      <TableCell align={'right'}>
        <FormattedCurrency>
          {get(data, 'totals.totalSales', 0)}
        </FormattedCurrency>
      </TableCell>
      <TableCell align={'right'}>
        {numeral(get(data, 'totals.percentOfTotal', 0) * 100).format('0.0')}%
      </TableCell>
    </TableRow>
  );

function SalesPerUserTable() {
  const currencySymbolSelector = (state) => get(state, 'authReducer.userData.company.currencySymbol');
  const reportDataSelector = (state) => state.reportingReducer.reportData;
  const currencySymbol = useSelector(currencySymbolSelector);
  const reportData = useSelector(reportDataSelector);

  const [salesPerUser, setSalesPerUser] = useState([]);

  useEffect(() => {
    const salesPerUser = get(reportData, 'data', []);

    if (has(salesPerUser, '[0].user._id')) {
      setSalesPerUser(sortBy(salesPerUser, (userSales) => {
        const percentOfTotal = get(userSales, 'totals.percentOfTotal', 0);
        return -1 * percentOfTotal;
      }));
    }

  }, [reportData]);

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              User
            </TableCell>
            <TableCell align={'right'}>
              Total ({currencySymbol}) (Ex Tax)
            </TableCell>
            <TableCell align={'right'}>
              % of Sales
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {salesPerUser.map((data) => <SalesPerUserRow key={data.user._id} data={data} />)}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell>Total</TableCell>
            <TableCell align={'right'}>
              <FormattedCurrency>
                {get(reportData, 'totals.totalSales', 0)}
              </FormattedCurrency>
            </TableCell>
            <TableCell align={'right'}>
              100%
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </>
  );
}

export default SalesPerUserTable;
