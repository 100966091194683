// TODO: fix eslint disable
/* eslint-disable no-shadow */

import React, {useCallback, useState} from 'react';
import {Link} from 'react-router-dom';
import {Form} from 'react-final-form';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import arrayMutators from 'final-form-arrays';
import {MuiThemeProvider, Paper} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import CatalogService from '../../../servicesOld/CatalogService';
import green from '../../../ui/theme/green';
import PageHeader from '../../common/PageHeader';
import {Step1UploadCSV} from './ImportSteps/Step1UploadCSV';
import {Step2ProductInfo} from './ImportSteps/Step2ProductInfo';
import {Step3Shipping} from './ImportSteps/Step3Shipping';
import {Step4Pricing} from './ImportSteps/Step4Pricing';
import {Step5Images} from './ImportSteps/Step5Images';
import Step6Complete from './ImportSteps/Step6Complete';
import {useMountEffect} from '../../../hooks';
import {roles} from '../../../componentsHoops/AccessControl';

const paperStyle = {padding: 16};

function CatalogImport() {
  const steps = [{
    content: Step1UploadCSV,
    label: 'Upload CSV',
    title: 'Upload Product CSV',
  }, {
    content: Step2ProductInfo,
    label: 'Product Info',
    title: 'Product Information',
  }, {
    content: Step3Shipping,
    label: 'Shipping',
    title: 'Shipping Information',
  }, {
    content: Step4Pricing,
    label: 'Pricing',
    title: 'Pricing Information',
  }, {
    content: Step5Images,
    label: 'Images',
    title: 'Product Images',
  }, {
    content: Step6Complete,
    label: 'Import',
    title: 'Finalise Import',
  }];

  const [activeStep, setActiveStep] = useState(0);
  const [highestStep, setHighestStep] = useState(-1);

  const [values, setValues] = useState({fieldMapping: {}});
  const [activeImport, setActiveImport] = useState({});

  const userDataSelector = (state) => state.authReducer.userData;
  const userData = useSelector(userDataSelector);
  const history = useHistory();

  useMountEffect(() => {
    (async function () {
      let imp = await CatalogService.getActiveImport();
      if (imp?.status !== 'PENDING') {
        imp = await CatalogService.createPendingImport(values);
      }
      setActiveImport(imp);
      if (imp.description) {
        setValues((values) => ({...values, description: imp.description}));
      }
    })();
  });

  const gotoStep = useCallback((stepIndex) => {
    if (stepIndex < activeStep) {
      setActiveStep(stepIndex);
    }
  }, [activeStep, setActiveStep]);

  const nextStep = useCallback(async (values) => {
    setValues((prev) => {
      CatalogService.updatePendingImport(activeImport._id, {...prev, ...values});
      return {...prev, ...values};
    });
    setHighestStep(activeStep);
    if (activeStep + 1 < steps.length) {
      setActiveStep((prev) => prev + 1);
    } else {
      CatalogService.processImport(activeImport._id, values);
      history.push('/catalog/history');
    }
  }, [setValues, setHighestStep, activeStep, steps.length, activeImport._id, history]);

  const prevStep = useCallback(() => {
    if (activeStep > 0) {
      setActiveStep((prev) => prev - 1);
    }
  }, [activeStep, setActiveStep]);

  const skipStep = useCallback(() => {
  }, []);

  const FormComp = steps[activeStep].content;

  const ImportForm = ({handleSubmit, values}) => (
      <Grid item style={{maxWidth: '100%'}}>
        <Paper style={paperStyle}>
          <form onSubmit={(...args) => {
            handleSubmit(...args);
          }}>
            <Grid container spacing={3}>
              <Grid item container justifyContent={'center'}>
                <Grid container direction={'column'} spacing={3}>
                  <Grid container justifyContent={'center'} item>
                    <Typography variant={'h5'}>{steps[activeStep].title}</Typography>
                  </Grid>
                  <Grid item/>
                </Grid>
              </Grid>
              <Grid item container justifyContent='center'>
                <Grid item xs={10}>
                  <Grid container>
                    <FormComp values={values} setValues={setValues} catalogImportId={activeImport._id}/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container justifyContent={'space-between'}>
                <Grid item>
                  {activeStep > 0 && <Button variant={'outlined'} color={'primary'} onClick={prevStep}>Back</Button>}
                </Grid>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      {steps[activeStep].skippable &&
                      <Button variant={'outlined'} color={'primary'} onClick={skipStep}>Skip</Button>}
                    </Grid>
                    <Grid item>
                      {(activeStep < (steps.length - 1)) ?
                        <Button type='submit' variant={'contained'} color={'primary'}>Next Step</Button> :
                        <MuiThemeProvider theme={green}>
                          <Button type='submit' variant={'contained'} color={'primary'}>Good To Go</Button>
                        </MuiThemeProvider>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    );

  return (
    <>
      <Grid container spacing={3} direction={'column'}>
        <Grid item container>
          <PageHeader
            pageTitle={'Import Products'}
            helpLink={'http://help.hoopscrm.com/en/collections/2104745-catalog'}
          >
            <Button
              variant='outlined'
              color='primary'
              component={Link}
              to='history'
            >
              Import History
            </Button>
          </PageHeader>
        </Grid>
        <Grid item>
          <Paper style={paperStyle}>
            <Grid container>
              <Stepper activeStep={activeStep} alternativeLabel style={{width: '100%'}}>
                {steps.map(({label}, index) => (
                  <Step key={label} onClick={() => gotoStep(index)} completed={index <= highestStep}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
          </Paper>
        </Grid>
        {steps[activeStep] && userData.role === roles.admin
          ? <Form
            onSubmit={nextStep}
            initialValues={values}
            mutators={{...arrayMutators}}
            render={ImportForm}/>
          : <Grid item>
            <Typography variant={'body1'} align={'center'}>
              You must have admin permission to perform imports.
            </Typography>
          </Grid>
        }
      </Grid>
    </>
  );
}

export default CatalogImport;
