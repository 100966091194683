// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {Button, Dialog, DialogContent} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {get, map, round} from 'lodash';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {submit} from 'redux-form';
import {saveDecoration, setDecoration} from '../../actions/decoration';
import {hoopsQuerySetRefetch} from '../../actions/hoopsQuery';
import DialogActions from '../shared/DialogActions';
import DialogTitle from '../shared/DialogTitle';
import DecorationForm from './decorationForm';

const decorationSelector = (state) => state.decorations.decoration;

export const DecorationDialog = ({
  isOpen = false,
  onClose = () => null,
}) => {
  const decoration = useSelector(decorationSelector);
  const dispatch = useDispatch();

  const handleClose = (decoration) => {
    dispatch(setDecoration());
    onClose(decoration);
    dispatch(hoopsQuerySetRefetch('decoration', true));
  };

  const handleSaveDecoration = ({name, setupPrice, ...values}) => {
    const priceBreaks = map(values.priceBreaks, (priceBreak) => {
      priceBreak.price = round(priceBreak.price, 2);
      return priceBreak;
    });

    const decoration = {
      ...values,
      priceBreaks,
      name,
      vendorId: get(values, 'vendor._id'),
      setupPrice
    };

    dispatch(saveDecoration(decoration)).then(({record}) => {
      handleClose(record);
    });

  };

  return (
    <Dialog open={isOpen} onClose={handleClose} >
      <DialogTitle disableTypography={true} onClick={handleClose}>
        <h3>
          {decoration._id ? <span>Update </span> : <span>Add </span>}
          Decoration
        </h3>
      </DialogTitle>
      <DialogContent>
        <DecorationForm onSubmit={handleSaveDecoration} />
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent='space-between'>
          <Button onClick={handleClose}>Cancel</Button>
          <Button data-intercom-target={'decoration-form-save-button'} variant='contained' color={'primary'} onClick={() => dispatch(submit('decorationForm'))}>Save</Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

// class DecorationDialog extends React.Component {
//   state = {
//     open: false,
//     addAnother: false,
//   };

//   handleClose = () => {
//     this.setState({open: false});
//     this.props.onClose();
//   };

//   shouldComponentUpdate = () => {
//     const {getDecoration, decoration, isOpen} = this.props;
//     this.setState({open: isOpen});

//     if (decoration && decoration._id && isOpen) {
//       getDecoration(decoration._id);
//     }
//   }

//   saveDecoration = ({ name, setupPrice, ...values }) => {
//     const {getDecorations, saveDecoration} = this.props;

//     const priceBreaks = map(values.priceBreaks, priceBreak => {
//       priceBreak.price = round(priceBreak.price, 2);
//       return priceBreak;
//     });

//     const decoration = {
//       ...values,
//       priceBreaks,
//       name,
//      vendorId: get(values, 'vendor._id'),
//       setupPrice
//     };

//     saveDecoration(decoration)
//       .then(() => {
//         // getDecorations();
//         this.handleClose();
//       })
//   };

//   render() {
//     const {submit, decoration} = this.props;
//     return (
//       <Dialog open={this.state.open} onClose={this.handleClose} >
//         <DialogTitle disableTypography={true} onClick={this.handleClose}>
//           <h3>
//             {decoration._id ? <span>Update </span> : <span>Add </span>}
//             Decoration
//           </h3>
//         </DialogTitle>
//         <DialogContent>
//           <DecorationForm onSubmit={this.saveDecoration}/>
//         </DialogContent>
//         <DialogActions>
//           <Grid container justifyContent="space-between">
//               <Button onClick={this.handleClose}>Cancel</Button>
//               <Button data-intercom-target={'decoration-form-save-button'} variant="contained" color={'primary'} onClick={() => submit('decorationForm')}>Save</Button>
//           </Grid>
//         </DialogActions>
//       </Dialog>
//     )
//   }
// }

// function mapStateToProps(state) {
//   return {
//     decoration: state.decorations.decoration,
//   };
// }

// function mapDispatchToProps(dispatch) {
//   return {
//     saveDecoration: (values) => dispatch(saveDecoration(values)),
//     getDecorations: () => dispatch(getDecorations()),
//     getDecoration: (_id) => dispatch(getDecoration(_id)),
//     submit: (form) => dispatch(submit(form)),
//   }
// }

// DecorationDialog = connect(mapStateToProps, mapDispatchToProps)(DecorationDialog);

export default DecorationDialog;
