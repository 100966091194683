// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {Checkbox} from '@kestreltech/final-form-material-ui';
import {Divider, makeStyles, MenuItem, Typography} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import {useSelector} from 'react-redux';
import {Field, formValueSelector} from 'redux-form';
import HoopsSelectField from '../HoopsSelectField';
import {HoopsTextField} from '../HoopsTextField';
import ShortcodeList from '../../modules/emailTemplate/ShortcodeList';
import theme from '../../ui/theme';
import EditorField from '../companies/editor';
import {required} from '../shared/formHelpers/validation';

const useStyles = makeStyles((theme) => ({
  actionsContainer: {padding: theme.spacing(3),},
  greyText: {color: theme.colors.grey.main}
}));

export const SendQuoteForm = ({
  change,
  emailTemplates = [],
  smsTemplates = [],
  stripHtml = () => null
}) => {
  const classes = useStyles();
  const selector = formValueSelector('sendQuoteForm');
  const sendViaEmailSelector = (state) => selector(state, 'viaEmail');
  const sendViaSMSSelector = (state) => selector(state, 'viaSms');

  const sendViaEmail = useSelector(sendViaEmailSelector);
  const sendViaSMS = useSelector(sendViaSMSSelector);

  const smsBodyLimit = 160;

  return (
    <>
      <Grid container spacing={3}>

        <Grid item sm={12}>
          <Typography variant='body1' display='inline'>Send Via:</Typography>
          <Field
            name={'viaEmail'}
            color={'primary'}
            type={'checkbox'}
            component={Checkbox}
          />
          <Typography variant='body1' display='inline' >Email</Typography>

          <Field
            name={'viaSms'}
            color={'primary'}
            type={'checkbox'}
            component={Checkbox}
          />
          <Typography variant='body1' display='inline' >SMS</Typography>
        </Grid>

        {/* Email Form  */}
        {sendViaEmail &&
          <>
            <Grid item sm={12}>
              <Typography variant='h5' >Email</Typography>
            </Grid>

            <Grid item sm={12} md={5}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Field
                    name={'to'}
                    label={'To:'}
                    type={'email'}
                    validate={required}
                    component={HoopsTextField}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name={'cc'}
                    label={'CC:'}
                    type={'email'}
                    component={HoopsTextField}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name={'replyTo'}
                    label={'Reply To:'}
                    type={'email'}
                    validate={required}
                    component={HoopsTextField}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name={'emailTemplate'}
                    label={'Template:'}
                    component={HoopsSelectField}
                    onChange={(e, value) => {
                      change('subject', value.subject);
                      change('body', value.body);
                    }}
                  >
                    {emailTemplates.map((template) => (<MenuItem key={template._id} value={template}>{template.title}</MenuItem>))}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name={'subject'}
                    label={'Subject:'}
                    validate={required}
                    component={HoopsTextField}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={12} md={7}>
              <Typography variant='caption' className={classes.greyText}>Body</Typography>
              <Field
                name={'body'}
                label={'Body:'}
                validate={required}
                component={(p) => <EditorField {...p} key='editor' />}
              />

            </Grid>
          </>
        }

        {sendViaEmail && sendViaSMS &&
          <Grid item sm={12}>
            <Divider />
          </Grid>
        }

        {/* SMS Form  */}

        <Grid item sm={12} style={{display: sendViaSMS ? 'initial' : 'none'}}>
          <Typography variant='h5' >SMS</Typography>
        </Grid>

        <Grid item sm={12} md={5} style={{display: sendViaSMS ? 'initial' : 'none'}}>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Field
                name={'phone'}
                label={'Phone Number:'}
                validate={sendViaSMS ? required : null}
                component={HoopsTextField}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name={'smsTemplate'}
                label={'Template:'}
                component={HoopsSelectField}
                disabled={smsTemplates.length === 0}
                onChange={(e, value) => {
                  change('smsBody', stripHtml(value.body));
                }}
              >
                {smsTemplates.map((template) => (<MenuItem key={template._id} value={template}>{template.title}</MenuItem>))}
              </Field>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} md={7} style={{display: sendViaSMS ? 'initial' : 'none'}}>
          <Field
            name={'smsBody'}
            validate={required}
            defaultValue=''
            label='Body'
            multiline
            rows={10}
            style={{paddingBottom: theme.spacing(1)}}
            inputProps={{maxlength: smsBodyLimit}}
            component={HoopsTextField}
          />
          <Typography variant='body2' className={classes.greyText} >{`Max ${smsBodyLimit} Characters. USD $0.07 per SMS charges apply.`}</Typography>
        </Grid>

        <Grid item sm={7}>
          <ShortcodeList entity='quote' />
        </Grid>

      </Grid>
    </>
  );
};

export default SendQuoteForm;
