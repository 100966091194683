// TODO: fix eslint disable
/* eslint-disable no-class-assign, no-shadow */

import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import queryString from 'query-string';
import {register, SetNewUser} from '../../../actions/action-types';
import XeroSignupForm from './XeroSignupForm';
import {Typography} from '@material-ui/core';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    width: '100%'
  },
});

class XeroRegister extends React.Component {
  submit = (values) => {
    const {register} = this.props;
    register(values);
  };

  componentDidMount() {
    document.title = 'Xero Register | Hoops';
    const {payload} = queryString.parse(this.props.location.search);

    if (!payload) {
      return;
    }

    const value = JSON.parse(decodeURIComponent(payload));
    this.props.setNewUser({
      ...value,
      company: {source: 'xero'},
      xero: {xeroTokenSet: value.xeroTokenSet}
    });
  }

  render() {
    const {registrationStatus} = this.props;
    return (
      <Grid item>
        {!this.props.location.search && <Grid container><Grid item xs={12}>
          <Typography color={'primary'} variant={'h4'}>Xero Sign Up</Typography>
        </Grid>

          <Grid item xs={12}>
            <Typography variant={'body'}>You have an invalid payload. If you didn&apos;t come to this page from Xero directly then please go to our login page.</Typography>
          </Grid>
        </Grid>}
        {this.props.location.search && <XeroSignupForm
          onSubmit={this.submit}
          isLoading={registrationStatus.loading}
        />}
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.authReducer,
    user: state.user,
    registrationStatus: state.registrationReducer
  };
}

function mapDispatchToProps(dispatch) {
  return {
    register: (payload) => dispatch(register(payload)),
    setNewUser: (payload) => dispatch(SetNewUser(payload))
  };
}

XeroRegister = connect(mapStateToProps, mapDispatchToProps)(XeroRegister);
XeroRegister = withRouter(XeroRegister);
XeroRegister = withStyles(styles)(XeroRegister);

export default XeroRegister;
