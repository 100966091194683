// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React, {Fragment} from 'react';
import {Avatar, Grid, Paper, withStyles} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';

const styles = (theme) => (
  {
    paper: {padding: theme.spacing(2)},
    paperTitle: {marginBottom: theme.spacing(1)},
    paperContent: {
      paddingLeft: theme.spacing(7),
      paddingBottom: theme.spacing(3),
    },
    avatar: {backgroundColor: theme.palette.primary.main,},
  }
);

class HoopsDataPanel extends React.Component {
  render() {
    const {classes, children, title, icon} = this.props;
    return (
      <Fragment>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item container spacing={2} alignItems={'center'} className={classes.paperTitle}>
              <Grid item>
                <Avatar className={classes.avatar}><Icon variant='outlined' color={'white'} style={{fontSize: '30px'}}>{icon}</Icon></Avatar>
              </Grid>
              <Grid item>
                <h4>{title}</h4>
              </Grid>
            </Grid>
            <Grid container className={classes.paperContent}>
              {children}
            </Grid>
          </Grid>
        </Paper>
      </Fragment>
    );
  }
}

HoopsDataPanel = withStyles(styles, {withTheme: true})(HoopsDataPanel);

export default HoopsDataPanel;
