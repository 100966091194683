import React, {useCallback} from 'react';
import {IconButton} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import {TrackerNumberModal} from './TrackerNumberModal';
import {TrackingNumberForm} from './TrackingNumberForm';

export function DeleteTrackingNumber({trackingNumbers, index, onSubmit}) {
  const _onSubmit = useCallback(() => {
    const clone = [...trackingNumbers];
    clone.splice(index, 1);
    onSubmit({trackingNumbers: clone});
  }, [index, onSubmit, trackingNumbers]);
  return (
    <TrackerNumberModal
      title={'Delete Tracking Number'}
      button={<IconButton><DeleteIcon/></IconButton>}
      onSubmit={_onSubmit}
    >
      <TrackingNumberForm onSubmit={_onSubmit} model={trackingNumbers[index]} deleting/>
    </TrackerNumberModal>
  );
}
