import {Grid, Icon, IconButton, InputAdornment, Typography} from '@material-ui/core';
import {get} from 'lodash';
import React from 'react';
import {useSelector} from 'react-redux';
import {HoopsTextField} from '../../componentsOld/HoopsTextField';
import theme from '../../ui/theme/index';

const currencySymbolSelector = (state) => get(state, 'authReducer.userData.company.currencySymbol');

export const MarkupRow = ({
    row = {},
    rowIdx = null,
    onDelete = () => null
}) => {
    const currencySymbol = useSelector(currencySymbolSelector);

    return (

        <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
            style={{justifyContent: 'center', marginBottom: theme.spacing(2)}}
            spacing={1}
        >
            <Grid item><Typography variant='body1'>If an item costs between</Typography></Grid>
            <Grid item>
                <HoopsTextField
                    name={`markupRanges[${rowIdx}].lowerPrice`}
                    value={row.lowerPrice}
                    fullWidth={false}
                    type='number'
                    onFocus={(event) => {
                        event.target.select();
                    }}
                    InputProps={{startAdornment: <InputAdornment position={'start'}>{currencySymbol}</InputAdornment>}}
                    style={{width: 90}}
                />
            </Grid>
            <Grid item><Typography variant='body1'>and</Typography></Grid>
            <Grid item>
                <HoopsTextField
                    name={`markupRanges[${rowIdx}].upperPrice`}
                    value={row.upperPrice}
                    fullWidth={false}
                    type='number'
                    onFocus={(event) => {
                        event.target.select();
                    }}
                    InputProps={{startAdornment: <InputAdornment position={'start'}>{currencySymbol}</InputAdornment>}}
                    style={{width: 110}}
                />
            </Grid>
            <Grid item><Typography variant='body1'>and you’re selling a quantity more than or equal to</Typography></Grid>
            <Grid item>
                <HoopsTextField
                    name={`markupRanges[${rowIdx}].quantity`}
                    value={row.quantity}
                    fullWidth={false}
                    type='number'
                    onFocus={(event) => {
                        event.target.select();
                    }}
                    style={{width: 90}}
                />
            </Grid>
            <Grid item><Typography variant='body1'>, the cost price will be marked up by</Typography></Grid>
            <Grid item>
                <HoopsTextField
                    name={`markupRanges[${rowIdx}].percentage`}
                    value={row.percentage}
                    fullWidth={false}
                    type='number'
                    onFocus={(event) => {
                        event.target.select();
                    }}
                    InputProps={{endAdornment: <InputAdornment position={'end'}>%</InputAdornment>}}
                    style={{width: 90}}
                />
            </Grid>
            <Grid>
                <IconButton
                    onClick={(e) => onDelete(e, rowIdx)}
                    variant={'outlined'}
                    style={{marginLeft: theme.spacing(2)}}
                >
                    <Icon fontSize={'small'}>delete</Icon>
                </IconButton>
            </Grid>
        </Grid >

    );
};
