// TODO: fix eslint disable
/* eslint-disable no-shadow */

import React from 'react';
import {useSelector} from 'react-redux';
import HoopsQueryFilterList from './HoopsQueryFilterList';

export const HoopsQueryFilterStatus = ({
    label = '',
    value = {},
    entityType,
    entityMapping = 'status',
    onChange = () => null,
}) => {
    const companySelector = (state) => state.companyReducer.company;
    const company = useSelector(companySelector);
    let options = [];

    const handleOnChange = (value) => {
        onChange(value);
    };

    const parsedValue = (value) => value;

    if (entityType && entityMapping && company && company.statuses) {
        const statuses = company
            .statuses
            .filter((status) => status.entityType === entityType && status.entityMapping === entityMapping)[0]
            ?.statuses ?? [];

        options = statuses.map((status) => ({value: status.value, label: status.label}));
    }

    return (
        <HoopsQueryFilterList label={label} options={options} value={parsedValue(value)} onChange={handleOnChange} />
    );
};

export default HoopsQueryFilterStatus;
