import {withStyles} from '@material-ui/core';
import {ToggleButton} from '@material-ui/lab';

const HoopsToggleButton = withStyles((theme) => ({
      root: {
        color: theme.palette.primary.main,
        '&$selected': {
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.primary.main
        }
      },
      selected: {}
    }))(ToggleButton);

export default HoopsToggleButton;

