import React, {useState} from 'react';
import {MentionsInput, Mention} from 'react-mentions';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {format, isYesterday, isToday} from 'date-fns';
import './comment.scss';

const useStyles = makeStyles(
    (theme) => ({
        commentUser: {
            fontSize: '0.9rem',
            fontWeight: 500,
            color: theme.colors.grey.main,
        },
        commentTime: {
            fontSize: '0.9rem',
            color: theme.colors.grey.main,
            fontWeight: 200
        }
    }
));

const getCreatedDisplay = (date) => {
    const time = format(date, 'h:mmaa').toLowerCase();

    if (isToday(date)) {
        return `Today, ${time}`;
    }
    
    if (isYesterday(date)) {
        return `Yesterday, ${time}`;
    }
    
    return `${format(date, 'LLLL d, yyyy')}, ${time}`;
};

export default ({comment, onChange = () => null, readOnly = false, users = []}) => {
    const classes = useStyles();
    const [value, setValue] = useState(comment.content);

    const handleChange = (ev) => {
        setValue(ev.target.value);
        onChange(ev.target.value);
    };

    return (
        <React.Fragment>
            <Typography component='div' className={classes.commentUser} display='inline'>
                {comment.createdByName}
            </Typography>&nbsp;&nbsp;&nbsp;
            <Typography className={classes.commentTime}  display='inline'>
            {getCreatedDisplay(comment.createdAt)}
            </Typography>
            
            <Typography component='div' variant='body2' className={classes.commentBody} gutterBottom>
                <MentionsInput
                value={value}
                onChange={handleChange}
                readOnly={readOnly}
                className='mentions mentions__readonly'
                >
                    <Mention
                        trigger='@'
                        data={users}
                        className={'mentions__mention'}
                        appendSpaceOnAdd={false}
                    />
                </MentionsInput>
                <div>&nbsp;</div>
            </Typography>
        </React.Fragment>
    );
};
