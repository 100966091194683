// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-shadow */

import {Button, Checkbox, Divider, Grid, InputAdornment, makeStyles, MuiThemeProvider, Link as MuiLink, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import InfoIcon from '@material-ui/icons/Info';
import ErrorIcon from '@material-ui/icons/Error';
import {get} from 'lodash';
import React, {useContext, useState} from 'react';
import {Controller, useFieldArray, useFormContext} from 'react-hook-form';
import ControlledHoopsAutocomplete from '../../../componentsOld/ControlledHoopsAutocomplete';
import {HoopsTextField} from '../../../componentsOld/HoopsTextField';
import green from '../../../ui/theme/green';
import {itemBuilder} from '../../../ui/theme/itemBuilder';
import {ItemBuilderVendorAutocomplete} from '../ItemBuilderVendorAutocomplete';
import ActionMenu from './ActionMenu';
import BuyPriceField from './BuyPriceField';
import DecorationField from './DecorationField';
import DecorationQuantityOverride from './DecorationOverrideDialog';
import DecorationTotals from './DecorationTotals';
import DecorationType from './DecorationType';
import MarkupField from './MarkupField';
import QuantityField from './QuantityField';
import SellPriceField from './SellPriceField';
import {toFloat} from '../../../componentsOld/shared/numberUtils';
import {ItemBuilderContext} from '../ItemBuilderContext';

const useStyles = makeStyles((theme) => ({...itemBuilder,}));

const ItemBuilderDecorationTable = ({
    currencySymbol = '$',
    priceElement = {}
}) => {
    const classes = useStyles();
    const {control, setValue, watch} = useFormContext();
    const {onPriceChanged, decorationDisplayToCustomer} = useContext(ItemBuilderContext);
    const {fields, append, remove} = useFieldArray({
        control,
        name: 'decorationCosts'
    });
    const [overrideDialogOpen, setOverrideDialogOpen] = useState(false);
    const [overrideRowIndex, setOverrideRowIndex] = useState(null);

    const fieldArray = watch('decorationCosts');

    const title = 'Decoration';

    //Clone and Add functionality >> Delete runs from the buttons onClick
    //must use fieldArray to duplicate the item otherwise if the row hasn't been saved the new duplicated row will be empty
    const handleDuplicate = (rowIndex) => {
        append(fieldArray[rowIndex]);
        onPriceChanged();
    };
    const handleAdd = () => {
        append({
            additionalCost: null,
            buyPrice: 0,
            colour: null,
            decoration: null,
            decorationId: 'draft',
            description: null,
            displayToCustomer: decorationDisplayToCustomer,
            markup: null,
            position: null,
            sellPrice: 0,
            setupPrice: null,
            size: null,
            vendor: null,
            vendorId: null,
        });
        onPriceChanged();
    };
    const handleOverrideQuantityOpen = (rowIndex) => {
        setOverrideRowIndex(rowIndex);
        setOverrideDialogOpen(true);
    };

    const handleDelete = (rowIndex) => {
        fields.length > 1
            ? remove(rowIndex)
            : remove();
        onPriceChanged();
    };

    return (
        <div className={classes.container}>
            <DecorationQuantityOverride onClose={() => setOverrideDialogOpen(false)} open={overrideDialogOpen} rowIndex={overrideRowIndex} />
            <Grid container direction='row' justifyContent='flex-start' alignItems='center' className={classes.itemBuilderHeader}>
                <Grid item><Typography variant='h6' gutterBottom>{`${title}(s)`}</Typography></Grid>
                <Grid item className={classes.infoCell}>
                    <Tooltip arrow placement='top' classes={{tooltip: classes.tooltip}} title={
                        <>
                            {'Add any decorations or ‘run charges’ here. '}<br /><br />
                            {'Tip: You can create a database of decorations that you use regularly, or add ad-hoc decorations for less common decorations/run charges.'}
                        </>
                    }><InfoIcon fontSize='small' className={classes.icon} /></Tooltip>
                </Grid>
            </Grid>
            <Divider />
            <Paper elevation={0} className={classes.tableContainer} >
                <Table >
                    {fields.length > 0 &&
                        <TableHead>
                            <TableRow className={classes.tableHeaderRow}>
                                <TableCell style={{width: '15.38%', minWidth: 200}}>Decoration</TableCell>
                                <TableCell style={{width: '7.69%', minWidth: 80}}>Vendor</TableCell>
                                <TableCell style={{width: '7.69%', minWidth: 80}}>Quantity</TableCell>
                                <TableCell style={{width: '7.69%', minWidth: 80}}>Position</TableCell>
                                <TableCell style={{width: '7.69%', minWidth: 80}}>Color</TableCell>
                                <TableCell style={{width: '7.69%', minWidth: 80}}>Size</TableCell>
                                <TableCell style={{width: '7.69%', minWidth: 80}}>Buy Price</TableCell>
                                <TableCell style={{width: '7.69%', minWidth: 80}}>Setup Cost</TableCell>
                                <TableCell style={{width: '7.69%', minWidth: 80}}>Extra Cost/Unit</TableCell>
                                <TableCell style={{width: '7.69%', minWidth: 80}}>Markup (%)</TableCell>
                                <TableCell style={{width: '7.69%', minWidth: 80}}>Sell Price</TableCell>
                                <TableCell style={{width: '2.56%', minWidth: 88}}>
                                    <Grid container direction='row' justifyContent='space-between' alignItems='flex-end'>
                                        <Grid item>Display </Grid>
                                        <Grid item className={classes.infoCell}>
                                            <Tooltip arrow placement='top' classes={{tooltip: classes.tooltip}} title={<>Check this box to display the Decoration Name, Position, Color, and Size information to your customer. <br /><br />The individual pricing for this decoration will not be displayed.</>}><HelpIcon fontSize='small' className={classes.icon} style={{display: 'flex', marginBottom: 4}} /></Tooltip>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell style={{width: '2.56%'}} className={classes.actionButtonsCell}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                    }
                    <TableBody>
                        {fields.map((field, rowIdx) => (
                                <TableRow hover={false} key={field.id} >
                                    <TableCell scope='row' className={classes.tableCell}>
                                        <Controller
                                            name={`decorationCosts[${rowIdx}].decorationId`}
                                            defaultValue={field.decorationId}
                                            render={(field) => (
                                                <input
                                                    type='hidden'
                                                    {...field}
                                                />)}
                                            control={control}
                                        />
                                        <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' >
                                            <Grid xs={4}>
                                                <DecorationType
                                                    rowIndex={rowIdx}
                                                    field={field}
                                                    _id={field._id}
                                                    priceElement={priceElement}
                                                />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <DecorationField
                                                    rowIndex={rowIdx}
                                                    field={field}
                                                    _id={field._id}
                                                    control={control}
                                                />
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    <TableCell scope='row' className={classes.tableCell}>
                                        <ControlledHoopsAutocomplete
                                            name={`decorationCosts[${rowIdx}].vendor`}
                                            control={control}
                                            placeholder='Search Vendor'
                                            textFieldProps={{
                                                margin: 'dense',
                                                variant: 'outlined',
                                                placeholder: 'Search Vendors',
                                                inputProps: {
                                                    margin: 'none',
                                                    className: classes.inputClass,
                                                },
                                            }}
                                            Component={(params) =>
                                                <ItemBuilderVendorAutocomplete
                                                    fullWidth
                                                    disabled={watch(`decorationCosts[${rowIdx}].decorationType`) !== 'adhoc'}

                                                    {...params}
                                                />
                                            }
                                            defaultValue={field.vendor}
                                            onSelect={(d) => {
                                                setValue(`decorationCosts[${rowIdx}].vendor`, d);
                                                setValue(`decorationCosts[${rowIdx}].vendorId`, get(d, '_id'));
                                                onPriceChanged();
                                            }}
                                            onChange={(d) => {
                                                setValue(`decorationCosts[${rowIdx}].vendor`, d);
                                                setValue(`decorationCosts[${rowIdx}].vendorId`, get(d, '_id'));
                                                onPriceChanged();
                                            }}
                                        />

                                        <Controller
                                            name={`decorationCosts[${rowIdx}].vendorId`}
                                            defaultValue={field.vendorId}
                                            render={(field) => (
                                                <input
                                                    type='hidden'
                                                    {...field}
                                                />)}
                                            control={control}
                                        />
                                    </TableCell>

                                    <TableCell scope='row' className={classes.tableCell}>
                                        <QuantityField
                                            field={field}
                                            index={rowIdx}
                                            name={`decorationCosts[${rowIdx}].quantity`}
                                            classes={classes}
                                        />
                                        <Controller
                                            name={`decorationCosts[${rowIdx}].priceBreakQuantityOverride`}
                                            defaultValue={field.priceBreakQuantityOverride}
                                            render={(field) => (
                                                <input
                                                    type='hidden'
                                                    {...field}
                                                />)}
                                            control={control}
                                        />
                                    </TableCell>
                                    <TableCell scope='row' className={classes.tableCell}>
                                        <Controller
                                            name={`decorationCosts[${rowIdx}].position`}
                                            defaultValue={field.position}
                                            render={(field) => (
                                                <HoopsTextField
                                                    responsive
                                                    {...field}
                                                    onChange={(e) => {onPriceChanged(); field.onChange(e);}}
                                                />)}
                                            control={control}
                                        />
                                    </TableCell>
                                    <TableCell scope='row' className={classes.tableCell}>
                                        <Controller
                                            name={`decorationCosts[${rowIdx}].colour`}
                                            defaultValue={field.colour}
                                            render={(field) => (
                                                <HoopsTextField
                                                    responsive
                                                    {...field}
                                                    onChange={(e) => {onPriceChanged(); field.onChange(e);}}
                                                />)}
                                            control={control}
                                        />
                                    </TableCell>
                                    <TableCell scope='row' className={classes.tableCell}>
                                        <Controller
                                            name={`decorationCosts[${rowIdx}].size`}
                                            defaultValue={field.size}
                                            render={(field) => (
                                                <HoopsTextField
                                                    responsive
                                                    {...field}
                                                    onChange={(e) => {onPriceChanged(); field.onChange(e);}}
                                                />
                                            )}
                                            control={control}
                                        />
                                    </TableCell>
                                    <TableCell scope='row' className={classes.tableCell}>
                                        <BuyPriceField
                                            index={rowIdx}
                                            field={field}
                                            name={`decorationCosts[${rowIdx}].buyPrice`}
                                            defaultValue={field.buyPrice}
                                            classses={classes}
                                            currencySymbol={currencySymbol}
                                        />
                                    </TableCell>
                                    <TableCell scope='row' className={classes.tableCell}>
                                        <Controller
                                            name={`decorationCosts[${rowIdx}].setupPrice`}
                                            defaultValue={field.setupPrice}
                                            render={(field) => (
                                                <HoopsTextField
                                                    type='number'
                                                    responsive
                                                    className={fieldArray && fieldArray.length > 0 && fieldArray[rowIdx] && toFloat(fieldArray[rowIdx].priceBreakQuantityOverride) > 0 && classes.warningCell}
                                                    {...field}
                                                    onChange={(e) => {onPriceChanged(); field.onChange(e);}}
                                                    InputProps={{
                                                        endAdornment: fieldArray && fieldArray.length > 0 && fieldArray[rowIdx] && toFloat(fieldArray[rowIdx].priceBreakQuantityOverride) > 0
                                                            ? <InputAdornment position='end' className={classes.warningAdornment}>
                                                                <Tooltip arrow placement='top' classes={{tooltip: classes.tooltip}} title={<>This decoration quantity was overridden to a total quantity of <b>{fieldArray && fieldArray[rowIdx].priceBreakQuantityOverride}</b>.</>}>
                                                                    <ErrorIcon fontSize='small' />
                                                                </Tooltip>
                                                            </InputAdornment>
                                                            : false,
                                                        startAdornment: <InputAdornment position={'start'}>{currencySymbol}</InputAdornment>//depends on the currency used... this needs work
                                                    }}
                                                />
                                            )}
                                            control={control}
                                        />
                                    </TableCell>
                                    <TableCell scope='row' className={classes.tableCell}>
                                        <Controller
                                            name={`decorationCosts[${rowIdx}].additionalCost`}
                                            defaultValue={field.additionalCost}
                                            render={(field) => (
                                                <HoopsTextField
                                                    type='number'
                                                    responsive
                                                    {...field}
                                                    onChange={(e) => {onPriceChanged(); field.onChange(e);}}
                                                    InputProps={{startAdornment: <InputAdornment position={'start'}>{currencySymbol}</InputAdornment>}}//depends on the currency used... this needs work
                                                />
                                            )}
                                            control={control}
                                        />
                                    </TableCell>
                                    <TableCell scope='row' className={classes.tableCell}>
                                        <MarkupField
                                            index={rowIdx}
                                            field={field}
                                            name={`decorationCosts[${rowIdx}].markup`}
                                            defaultValue={field.markup}
                                            classes={classes}
                                        />
                                    </TableCell>
                                    <TableCell scope='row' className={classes.tableCell}>
                                        <SellPriceField
                                            index={rowIdx}
                                            field={field}
                                            name={`decorationCosts[${rowIdx}].sellPrice`}
                                            classes={classes}
                                            currencySymbol={currencySymbol}
                                        />
                                    </TableCell>
                                    <TableCell scope='row' className={classes.actionButtons}>
                                        <MuiThemeProvider theme={green}>
                                            <Controller
                                                name={`decorationCosts[${rowIdx}].displayToCustomer`}
                                                defaultValue={field.displayToCustomer}
                                                control={control}
                                                render={(props) => (
                                                    <Checkbox
                                                        checked={props.value}
                                                        onChange={(e) => { onPriceChanged(); props.onChange(e.target.checked); }}
                                                        style={{padding: 0}}
                                                        iconStyle={{fill: 'green'}}
                                                        color={'primary'}
                                                    />
                                                )}
                                            />
                                        </MuiThemeProvider>
                                    </TableCell>
                                    <TableCell className={classes.actionButtons}>
                                        <ActionMenu rowIndex={rowIdx} onDuplicate={handleDuplicate} onDelete={handleDelete} onOverrideQuantityOpen={handleOverrideQuantityOpen} />
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </Paper>
            <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start'>
                <Grid item xs={2}><Button color='primary' variant={fields.length > 0 ? 'text' : 'outlined'} className={classes.button} onClick={handleAdd}>{`Add ${title}`}</Button></Grid>
                <Grid item xs={10}>{fields.length > 0 && <DecorationTotals classes={classes} priceElement={priceElement} />}</Grid>
            </Grid>
            <Grid containter>
                <Grid item>
                    <Typography variant='caption' className={classes.greyText}>Need help adding the same decoration to multiple products? <MuiLink color={'primary'} underline={'none'} href={'http://help.hoopscrm.com/en/articles/5549915-adding-decorations-to-multiple-lines'} target='_blank'>Click Here</MuiLink></Typography>
                </Grid>
            </Grid>
        </div >
    );
};

export default ItemBuilderDecorationTable;
