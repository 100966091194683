// TODO: fix eslint disable
/* eslint-disable consistent-return, no-shadow */

import {Avatar, Chip, Grid, Link as MuiLink, makeStyles, Paper, Table, TableContainer, Tooltip, Typography, ListItem, ListItemIcon, Checkbox, ListItemText} from '@material-ui/core';
import AmpStoriesIcon from '@material-ui/icons/AmpStories';
import {get} from 'lodash';
import moment from 'moment';
import * as numeral from 'numeral';
import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {updateQuoteStatus} from '../../actions/quote';
import AutomationIconButton from '../../modules/automation/AutomationIconButton';
import {GQL_QUOTE_PAGINATION} from '../../queries/quotes';
import {hoopsQueryStyles} from '../../ui/theme/hoops';
import {featureFlagEnabled, featureFlags} from '../../utils/featureFlag';
import {HoopsQuery, HoopsQueryContext, HoopsQueryEmptyState, HoopsQueryFilterChips, HoopsQueryFilterDropMenu, HoopsQueryFilterSearch, HoopsQueryFilterStatus, HoopsQueryFilterUser, HoopsQueryKanbanBoard, HoopsQueryKanbanCardQuote, HoopsQueryPagination, HoopsQueryTableBody, HoopsQueryTableHeader, HoopsQueryViewButton} from '../HoopsQuery';
import HoopsQueryTableCellDateTime from '../HoopsQuery/HoopsQueryTableCellDateTime';
import HoopsQueryTableConfig from '../HoopsQuery/HoopsQueryTableConfig';
import InvoiceCell from '../invoice/InvoiceCell';
import StatusChangerCell from '../Statuses/StatusChangerCell';
import {QuoteMenuCell} from './quoteMenuCell';
import theme from '../../ui/theme';
import gql from 'graphql-tag';
import {useQuery} from '@apollo/client';

// is user integrated with stripe
const STIPE_INTEGRATION_ACTIVE = gql`
  query integrationOne{
    integrationOne(filter:{type:stripe}){
        _id
        status
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  ...hoopsQueryStyles,
  greyText: {color: theme.colors.grey.main},
  orangeText: {color: theme.colors.orange},
  redText: {color: theme.colors.red},
  viewed: {
    backgroundColor: theme.colors.green,
    '& .MuiChip-avatarSmall': {marginRight: -2},
    paddingRight: 4,
    fontWeight: 500,
  },
  viewedCount: {
    backgroundColor: theme.colors.greenDark,
    display: 'inline-flex',
    fontSize: 'inherit',
  }
}));

export const QuoteTable = ({
  openCreateQuote = () => null,
  fixedFilter = {},
  queryStateId = 'quoteMany',
}) => {
  const quoteTableRef = React.useRef();
  const dispatch = useDispatch();
  const companySelector = (state) => state.companyReducer.company;
  const company = useSelector(companySelector);
  const classes = useStyles();
  const [columns, setColumns] = useState([]);
  const handleUpdate = (item) => {
    dispatch(updateQuoteStatus(item._id, item.status));
  };

  const TooltipContent = ({siblings, number}) => {
    const siblingRender = siblings.map((sibling) =>
      number !== sibling.number && <Typography key={sibling.number} variant='body2'>- Quote {sibling.number}</Typography>
    );
    return (
      <>
        <div style={{width: 200, padding: 8}}>
          <Typography variant='body1'>Grouped with others:</Typography>
          {siblingRender}
        </div>
      </>
    );
  };

  const tableContainerElement = useRef();

  // check for stipe integration
  const {data: stripeData} = useQuery(STIPE_INTEGRATION_ACTIVE, {fetchPolicy: 'no-cache'});

  const isStripeActive = stripeData && stripeData.integrationOne && stripeData.integrationOne.status === 'active';

  useEffect(() => {
    // handle due date styling
    const dueDateClass = (dueDate) => {
      const now = moment();
      const daysToDueDate = now.diff(dueDate, 'days');

      if (daysToDueDate > -7 && daysToDueDate < 0) {return classes.orangeText;}
      if (daysToDueDate > 0) {return classes.redText;}
      return classes.greyText;
    };

    if (
      stripeData
    ) {
      setColumns([{
        label: 'Quote #',
        field: 'quotenumber',
        sortable: true,
        sortKey: 'NUMBER',
        render: (rowData) => (
            <>
              <MuiLink component={Link} underline={'none'} style={{verticalAlign: rowData.siblings.length > 0 && 'super', paddingRight: rowData.siblings.length > 0 && '8px'}} color={'primary'} to={`/quotes/${rowData._id}/view`}>
                {rowData.number}
              </MuiLink>
              {rowData.isSibling && <Tooltip title={<TooltipContent siblings={rowData.siblings} number={rowData.number} />}><AmpStoriesIcon color={'secondary'} style={{paddingTop: '2px'}} /></Tooltip>}
            </>
          ),
        columnStyle: {minWidth: 140, width: 140, whiteSpace: 'nowrap'},
        cellStyle: {minWidth: 140, width: 140},
      }, {
        label: 'Job #',
        field: 'number',
        render: (rowData) => (
            <Fragment>
              {rowData.job &&
                <MuiLink component={Link} underline={'none'} color={'primary'} to={`/jobs/${rowData.job._id}/details`}>
                  {rowData.job.number}
                </MuiLink>
              }
            </Fragment>
          ),

        cellStyle: {minWidth: 100, width: 100},
        columnStyle: {minWidth: 100, width: 100},

      }, {
        label: 'Customer',
        field: 'customer.name',
        render: (rowData) => {
          if (!rowData.customer) {return '-';}
          return (
            <>
              <Typography variant={'body2'} noWrap>{`${get(rowData, 'customer.name', '')}${rowData.customer.deletedAt ? ' (Deleted)' : ''}`}</Typography>
              <Typography variant={'caption'} className={classes.greyText} noWrap>{get(rowData, 'contact.fullName', '')}</Typography>
            </>
          );
        },
        cellStyle: {paddingTop: '8px !important', paddingBottom: 8},
        columnStyle: {minWidth: 180, width: 180}
      }, {
        label: 'Created By',
        render: (rowData) =>
          <>
            <Typography variant={'body2'} noWrap>{get(rowData, 'createdBy.fullName', '')}</Typography>
            <HoopsQueryTableCellDateTime value={rowData.createdAt} showTime={false} className={classes.greyText} variant='caption' />
          </>,
        columnStyle: {minWidth: 180, width: 180}
      }, {
        label: 'Reference',
        field: 'customReference',
        cellStyle: {minWidth: 140},
      }, {
        label: 'Deadline',
        render: (rowData) => <HoopsQueryTableCellDateTime value={rowData.deadlineAt} showTime={false} className={dueDateClass(rowData.deadlineAt)} />,
        sortable: true,
        sortKey: 'DEADLINEAT',
        columnStyle: {minWidth: 180, width: 180}
      }, {
        label: 'Accounting Status',
        field: 'invoice.invoiceNumber',
        render: (rowData) => <>{
          !!rowData.invoice && <InvoiceCell invoiceData={rowData.invoice} showText={false} />}
        </>,
        columnStyle: {minWidth: 180, width: 180}
      },
      {
        label: 'Stripe Invoice',
        field: 'stripeInvoice',
        inActive: !isStripeActive,
        render: (rowData) => {
          if (!rowData.stripeInvoiceId) {return;}
          return (
            <MuiLink underline={'none'} color={'primary'} target='blank' href={`https://dashboard.stripe.com/invoices/${rowData.stripeInvoiceId}`}>
              View Invoice
            </MuiLink>
          );
        },
        columnStyle: {minWidth: 180, width: 180}
      }, {
        label: 'Total (ex. Tax)',
        field: 'total',
        render: (rowData) => `${company.currencySymbol || ''} ${numeral(rowData.subTotal).format('0,0.00')}`,
        columnStyle: {minWidth: 180, width: 180}
      }, {
        label: 'Viewed',
        align: 'center',
        render: (rowData) =>
          <Chip
            avatar={rowData.viewed > 0 ? <Avatar style={{color: theme.colors.white}} className={classes.viewedCount}>{rowData.viewed}</Avatar> : <></>}
            size={'small'}
            style={{color: '#FFFFFF'}}
            className={rowData.viewed > 0 ? classes.viewed : null}
            label={rowData.viewed > 0 ? 'Viewed' : 'Not Viewed'}
          />,
        cellStyle: {padding: 0, borderRight: '1px solid white', borderBottom: '1px solid white', whiteSpace: 'nowrap'},
        columnStyle: {width: 162, minWidth: 162},
      }, {
        label: 'Status',
        align: 'center',
        render: (rowData) => <StatusChangerCell entityType='quote' entityMapping='status' value={rowData.status} onChange={(newStatus) => dispatch(updateQuoteStatus(rowData._id, newStatus.value))} />,
        cellStyle: {padding: 0, borderRight: '1px solid white', borderBottom: '1px solid white', whiteSpace: 'nowrap', position: 'relative'},
        columnStyle: {width: 162, minWidth: 162},
      }, {
        label: 'Actions',
        align: 'center',
        render: (rowData) => <QuoteMenuCell rowData={rowData} quoteTableRef={quoteTableRef} />,
        columnStyle: {minWidth: 90, width: 90}
      }]);
    }
  }, [isStripeActive, stripeData, classes, company.currencySymbol, dispatch]);

  const filterListStatus = [{
    key: 'status',
    type: 'array',
    options: [],
    label: 'Status',
    component: (props) => <HoopsQueryFilterStatus {...props} entityType='quote' />
  }];

  const filterListUser = [{
    key: 'createdById',
    type: 'array',
    options: [],
    label: 'User',
    component: (props) => <HoopsQueryFilterUser {...props} />
  }];

  return columns.length > 0 && (
    <>
      <HoopsQuery refetchStateId='quote' queryStateId={queryStateId} columns={columns.filter((value) => Object.keys(value).length !== 0)} gqlQuery={GQL_QUOTE_PAGINATION} resultField='quotePagination' initialFilter={{_operators: {archived: {ne: true}}}} fixedFilter={fixedFilter} initialSort={'NUMBER_DESC'} tableContainerElement={tableContainerElement}>
        <HoopsQueryContext.Consumer>
          {({items, sort, setSort, filter, setFilter, paging, setPaging, chips, setChips, hoopsQueryColumns, setHoopsQueryColumns, view, setView, getKanbanColumns}) => (<>
              <Grid container direction='row' justifyContent='space-between' alignItems='flex-start'>
                <Grid item xs={6}>
                  {
                    view === 'table' &&
                    <HoopsQueryFilterDropMenu
                      label={'Status'}
                      filter={filter}
                      filterComponents={filterListStatus}
                      onFilterChange={setFilter}
                      chips={chips}
                      onChipsChange={setChips}
                    />
                  }
                  <HoopsQueryFilterDropMenu
                    label={'Created By'}
                    filter={filter}
                    filterComponents={filterListUser}
                    onFilterChange={setFilter}
                    chips={chips}
                    onChipsChange={setChips}
                  />
                </Grid>
                <Grid item xs={6} >
                  <Grid container justifyContent='flex-end' spacing={1}>
                    <Grid item>
                      {featureFlagEnabled(featureFlags.automations) && <AutomationIconButton />}
                    </Grid>
                    <Grid item>
                      {featureFlagEnabled(featureFlags.kanban) && <HoopsQueryViewButton view={view} onViewChange={setView} />}
                    </Grid>
                    <Grid item>
                      {view === 'table' &&
                        <HoopsQueryTableConfig
                          columns={hoopsQueryColumns}
                          leftTitle={'Show/Hide Columns'}
                          rightBlock={<>
                            <b style={{marginLeft: 20}}>Archived Quotes</b>
                            <ListItem style={{minWidth: 170}} dense >
                              <ListItemIcon style={{minWidth: 'unset'}}>
                                <Checkbox
                                  color='primary'
                                  disableRipple
                                  checked={!(filter._operators && filter._operators.archived && filter._operators.archived.ne)}
                                  onChange={() => {
                                    const clone = {...filter} ?? {};
                                    if (clone._operators && filter._operators.archived && filter._operators.archived.ne) {
                                      delete clone._operators.archived;
                                      setFilter(clone);
                                    } else {
                                      clone._operators = clone._operators ?? {};
                                      clone._operators.archived = {ne: true};
                                      setFilter(clone);
                                    }
                                  }
                                  } />
                              </ListItemIcon>
                              <ListItemText primary={'Show Archived Quotes'} />
                            </ListItem>
                          </>
                          }
                          onColumnsChange={setHoopsQueryColumns}
                        />
                      }
                    </Grid>
                    <Grid item>
                      <HoopsQueryFilterSearch onChange={setFilter} filter={filter} placeholder={'Search'} chips={chips} onChipsChange={setChips} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid >
              <HoopsQueryFilterChips filter={filter} onFilterChange={setFilter} chips={chips} onChipsChange={setChips} />
              {
                view === 'table' &&
                <>
                  <TableContainer component={Paper} className={classes.tableContainer} ref={tableContainerElement}>
                    <Table className={classes.table} stickyHeader>
                      <HoopsQueryTableHeader hasCheckBox={false} columns={hoopsQueryColumns} onSort={setSort} sortString={sort} />
                      <HoopsQueryTableBody rowsPerPage={paging.perPage} rows={items} columns={hoopsQueryColumns} stripedRows={true} emptyStateComponent={() => <HoopsQueryEmptyState filter={filter} columns={hoopsQueryColumns} field={'Quote'} rowsPerPage={paging.perPage} onClearFilter={setFilter} onChipsChange={setChips} onAddItem={openCreateQuote} tableContainerElement={tableContainerElement} />} />
                    </Table>
                  </TableContainer>
                  <HoopsQueryPagination paging={paging} onPaging={setPaging} />
                </>
              }
              {
                view === 'kanban' &&
                <HoopsQueryKanbanBoard
                  itemsPerColumn={20} // how many items to show on load
                  itemsShowMore={5} // this is how many more items you'd like to show when you click the show more button
                  columns={getKanbanColumns('quote')}
                  onChange={handleUpdate}
                  cardContent={(item) => <HoopsQueryKanbanCardQuote item={item} />}
                />
              }

            </>)}
        </HoopsQueryContext.Consumer >
      </HoopsQuery >
    </>
  );

};

export default QuoteTable;
