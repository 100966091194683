import React, {createContext, useState} from 'react';

export const HoopsModalContext = createContext(null);

export const HoopsModalContextContainer = ({children,}) => {
    const [open, setOpen] = useState(false);
    const [activeId, setActiveId] = useState(null);
    
    const handleOpen = () => {
        setActiveId(null);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const value = {
        open,
        setOpen,

        activeId,
        setActiveId,
        
        handleOpen,

        handleClose,
    };

    return (
        <HoopsModalContext.Provider value={value}>
            {children}
        </HoopsModalContext.Provider>
    );
};

export default HoopsModalContextContainer;
