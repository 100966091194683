import React, {useCallback} from 'react';
import {makeStyles, Typography} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HelpArticle from '../helpArticle';
import {useHistory} from 'react-router-dom';

const styles = () => ({});

function PageHeader({helpLink, pageTitle, children, goBack, showBackButton = true}) {
  const history = useHistory();

  const handleClick = useCallback(() => {
    if (goBack) {
      goBack();
      return;
    }

    history.goBack();
  }, [ history, goBack ]);

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Grid item xs={4}>
          <Grid container
                alignItems={'center'}
                spacing={2}>
            {showBackButton && <Grid item>
              <IconButton className={classes.bufferRight} onClick={handleClick}>
                <ArrowBackIcon/>
              </IconButton>
            </Grid>}
            <Grid item>
              <Typography variant={'h4'}>{pageTitle}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container justifyContent={'center'} xs={4}>
          <HelpArticle link={helpLink}/>
        </Grid>
        <Grid item container justifyContent={'flex-end'} xs={4}>
          {children}
        </Grid>
      </Grid>
    </>
  );
}

export default PageHeader;
