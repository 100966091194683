// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-shadow */

import React, {useState} from 'react';
import {MentionsInput, Mention} from 'react-mentions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import './comment.scss';
import {Grid} from '@material-ui/core';

export default ({
    users = [],
    onSave = (value) => null
}) => {
    const [value, setValue] = useState('');
    const [controlVisible, setControlsVisible] = useState(false);
    const [saveDisabled, setSaveDisabled] = useState(true);

    const displayTransform = (id, display) => ` @${display} `;

    const handleChange = (ev) => {
        const value = ev.target.value;
        setValue(value);
        setSaveDisabled(!value);
    };

    const handleCancel = () => {
        setValue('');
        setControlsVisible(false);
    };

    const handleSave = () => {
        onSave(value);
        setValue('');
        setControlsVisible(false);
    };

    return (
        <React.Fragment>
            <Grid
                container
                spacing={3}
            >
                <Grid item xs={true}>
                    <Typography variant='body2'>
                        <MentionsInput
                            value={value}
                            onChange={handleChange}
                            readOnly={false}
                            className='mentions'
                            placeholder={'Add a comment & mention people using \'@\''}
                            onFocus={() => setControlsVisible(true)}
                        >
                            <Mention
                                trigger='@'
                                data={users}
                                className={'mentions__mention'}
                                displayTransform={displayTransform}
                            />
                        </MentionsInput>
                    </Typography>

                </Grid>
            </Grid>
            {controlVisible ? <Grid
                item container
                xs={true}
                direction={'row'}
                alignItems={'left'}
                spacing={3}
            >
                <Grid item><Button variant='contained' style={{backgroundColor: '#22CC96', color: '#fff'}} disabled={saveDisabled} onClick={handleSave}>
                    Save
                </Button></Grid>

                <Grid item><Button color='secondary' onClick={() => handleCancel()}>
                    Cancel
                </Button></Grid>
            </Grid>
                : null}
        </React.Fragment>
    );
};
