import {Grid, Link as MuiLink} from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {get} from 'lodash';
import {updateSchedule} from '../../actions/schedules';
import {HoopsLabelWithText} from '../../componentsOld/shared/Typography';
import StatusChanger from '../../componentsOld/Statuses/StatusChanger';
import {JobStatusBar} from '../../pages/JobPage/JobStatusBar';
import {useGetJob, useUpdateJob} from '../../hooks/api';

export const JobPreviewDetails = ({schedule}) => {
    const dispatch = useDispatch();
  // it needs to be queried from api, to get viewFields
    const {data: job, isLoading, refetch} = useGetJob(schedule?.job?._id);
    const {mutateAsync: updateJob} = useUpdateJob();
    const scheduleId = schedule._id;
    const handleStatusChange = useCallback((status) => {
        dispatch(updateSchedule(scheduleId, {_id: scheduleId, status: status.value}));
    }, [scheduleId, dispatch]);
    const handleBarStatusChange = useCallback(async (selected, field) => {
      await updateJob({
        id: job._id,
        data: {[field]: selected.statusLabel},
      });
      await refetch();
    }, [job, updateJob, refetch]);
    if (!schedule || !job || isLoading) {
        return null;
    }
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <HoopsLabelWithText label='Status' component={
                    <StatusChanger
                        entityType='schedule'
                        entityMapping='status'
                        value={schedule.status}
                        size='small'
                        onChange={handleStatusChange}
                    />
                } />
            </Grid>
            <Grid item xs={12}>
                <HoopsLabelWithText label='Title' component={schedule.title} />
            </Grid>
            <Grid item xs={12}>
                <HoopsLabelWithText label='Description' component={schedule.description} />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item>
                        <HoopsLabelWithText label='Schedule Created By' component={get(schedule, 'createdBy.fullName')} />
                    </Grid>
                    <Grid item>
                        <HoopsLabelWithText label='Job Created By' component={get(schedule, 'job.createdBy.fullName')} />
                    </Grid>
                    <Grid item>
                        <HoopsLabelWithText label='Job Owner' component={get(schedule, 'job.jobOwner.fullName')} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <HoopsLabelWithText label='Resource' component={get(schedule, 'resource.title')} />
            </Grid>
            <Grid item xs={12}>
                <HoopsLabelWithText
                    label='Associated with'
                    component={
                        <MuiLink
                            component={Link}
                            underline={'none'}
                            color={'primary'}
                            target='_blank'
                            to={`/jobs/${get(schedule, 'job._id')}/details`}
                        >
                            <Grid container direction='row' alignItems='center'>
                                Job #{get(schedule, 'job.number')} | {get(schedule, 'job.customer.name')}&nbsp;<LaunchIcon size='small' style={{fontSize: '18px'}} />
                            </Grid>
                        </MuiLink>
                    } />
                <JobStatusBar job={job} onSelect={handleBarStatusChange}/>
            </Grid>
        </Grid>
    );
};

export default JobPreviewDetails;
