import {toFloat} from '../../componentsOld/shared/numberUtils';
import adjustmentLineValues from './adjustmentLineValues';
import {asCurrencyRounded} from '../../utils';

export const calculatePriceElementBreakdown = (priceElement) => {
  const {adjustmentLineName} = adjustmentLineValues;
  if (!priceElement) { return undefined; }

  // Product
  const variationQuantity = priceElement.variations && priceElement.variations.reduce((agg, variation) => agg += toFloat(variation.quantity), 0);
  const variationTotalCost = priceElement.variations && priceElement.variations.reduce((agg, variation) => agg += ((toFloat(variation.buyPrice) + toFloat(variation.additionalCost)) * toFloat(variation.quantity)), 0);
  const variationTotalSellPrice = priceElement.variations && priceElement.variations.reduce((agg, variation) => agg += (toFloat(variation.sellPrice) * toFloat(variation.quantity)), 0);
  const variationTotalMarkup = priceElement.variations && priceElement.variations.reduce((agg, variation) => agg += toFloat(variation.markup), 0);
  const variationTotalProfit = priceElement.variations && variationTotalSellPrice - variationTotalCost;

  // Decoration
  const decorationQuantity = priceElement.decorationCosts && priceElement.decorationCosts.length > 0 ? toFloat(priceElement.decorationCosts[0].quantity) : 0;
  const decorationTotalCost = priceElement.decorationCosts && priceElement.decorationCosts.reduce((agg, decoration) => agg += ((toFloat(decoration.buyPrice) + toFloat(decoration.additionalCost)) * decorationQuantity) + toFloat(decoration.setupPrice), 0);
  const decorationTotalSellPrice = priceElement.decorationCosts && priceElement.decorationCosts.reduce((agg, decoration) => agg += (toFloat(decoration.sellPrice) * toFloat(decoration.quantity)), 0);
  const decorationTotalMarkUp = priceElement.decorationCosts && priceElement.decorationCosts.reduce((agg, decoration) => agg += toFloat(decoration.markup), 0);
  const decorationTotalProfit = priceElement.decorationCosts && decorationTotalSellPrice - decorationTotalCost;
  const decorationLength = priceElement.decorationCosts && priceElement.decorationCosts.length;

  // Additional
  const additionalCostTotalCost = priceElement.additionalCosts && priceElement.additionalCosts.reduce((agg, additionalCost) => agg += additionalCost.name !== adjustmentLineName && toFloat(additionalCost.buyPrice), 0);
  const additionalCostTotalSellPrice = priceElement.additionalCosts && priceElement.additionalCosts.reduce((agg, additionalCost) => agg += additionalCost.name !== adjustmentLineName && toFloat(additionalCost.sellPrice), 0);
  const additionalCostTotalMarkup = priceElement.additionalCosts && priceElement.additionalCosts.reduce((agg, additionalCost) => agg += additionalCost.name !== adjustmentLineName && toFloat(additionalCost.markup), 0);
  const additionalCostTotalProfit = priceElement.additionalCosts && additionalCostTotalSellPrice - additionalCostTotalCost;
  const additionalCostLength = priceElement.additionalCosts && additionalCostTotalCost > 0 && 1;

  // Effective quantity
  const productQuantity = ((decorationQuantity ? decorationQuantity : variationQuantity) + ((variationQuantity > 0 || decorationQuantity > 0) ? 0 : additionalCostLength));
  const effectiveQuantity = ((decorationQuantity ? decorationQuantity : variationQuantity) + (additionalCostLength || 0));

  // Adjustment
  const adjustmentTotalSellPrice = priceElement.additionalCosts && priceElement.additionalCosts.reduce((agg, additionalCost) => agg += additionalCost.name === adjustmentLineName && toFloat(additionalCost.sellPrice), 0);
  const adjustmentQuanitity = priceElement.additionalCosts && priceElement.additionalCosts.filter((additionalCost) => additionalCost.name === adjustmentLineName).length;

  // Summary Totals
  const totalCost = (variationTotalCost || 0) + (decorationTotalCost || 0) + (additionalCostTotalCost || 0);
  const totalSellPrice = (variationTotalSellPrice || 0) + (decorationTotalSellPrice || 0) + (additionalCostTotalSellPrice || 0) + (adjustmentTotalSellPrice || 0);
  const sellPricePerUnit = asCurrencyRounded(
    productQuantity === 0
      ? effectiveQuantity === 0 ? 0 : totalSellPrice / effectiveQuantity
      : totalSellPrice / productQuantity);
  const costPricePerUnit = asCurrencyRounded(
    productQuantity === 0
      ? effectiveQuantity === 0 ? 0 : totalCost / effectiveQuantity
      : totalCost / productQuantity);
  const grossMargin = ((totalSellPrice - totalCost) / totalSellPrice * 100) || 0;
  const profitPerUnit = effectiveQuantity === 0 ? 0 : asCurrencyRounded(totalSellPrice / effectiveQuantity) - asCurrencyRounded(totalCost / effectiveQuantity);
  const totalProfit = totalSellPrice - totalCost;

  return {
    effectiveQuantity,
    productQuantity,
    variationQuantity,
    variationTotalCost,
    variationTotalSellPrice,
    variationTotalMarkup,
    variationTotalProfit,
    decorationQuantity,
    decorationTotalCost,
    decorationTotalSellPrice,
    decorationTotalMarkUp,
    decorationTotalProfit,
    decorationLength,
    additionalCostTotalCost,
    additionalCostTotalSellPrice,
    additionalCostTotalMarkup,
    additionalCostTotalProfit,
    additionalCostLength,
    totalCost,
    totalSellPrice,
    sellPricePerUnit,
    costPricePerUnit,
    grossMargin,
    profitPerUnit,
    totalProfit,
    adjustmentQuanitity
  };
};
