// TODO: fix eslint disable
/* eslint-disable no-class-assign, no-unused-vars, no-shadow, */

import {Button, MuiThemeProvider} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from '@material-ui/core/styles';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {CardElement, injectStripe} from 'react-stripe-elements';
import {snackError as _snackError, snackSuccess as _snackSuccess} from '../actions/action-types/snackbar';
import {getBilling as _getBilling} from '../actions/billing';
import {BillingService} from '../servicesOld/BillingService';
import theme from '../ui/theme';
import green from '../ui/theme/green';

const styles = (theme) => ({
  cardInputWrapper: {
    width: '100%',
    marginBottom: theme.spacing(3),
    border: `1px solid ${theme.colors.grey.light}`,
    padding: '12px',
    borderRadius: '5px'
  },
  subscribeButton: {
    marginBottom: theme.spacing(3),
    width: '100%'
  },
  loading: {color: 'white'}
});

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state = {loading: false};
  }

  async submit(ev) {
    const {checkoutSuccess, snackSuccess, snackError, getBilling} = this.props;

    // Create token from card details
    let {token} = await this.props.stripe.createToken();
    if (!token) {
      snackError('Something went wrong');
      return;
    }

    // Call API to convert the trial
    this.setState({loading: true});
    BillingService
    .addPaymentMethod(token.id)
    .then((result) => { // Success
      this.setState({loading: false});
      checkoutSuccess({sourceId: result.data.addStripeCard});
      getBilling();
      snackSuccess('Subscription activated!');
    })
    .catch((err) => { // Fail
      this.setState({loading: false});
      snackError('Something went wrong');
    });
  }

  render() {
    const {classes, buttonText = 'START SUBSCRIPTION'} = this.props;
    const {loading} = this.state;
    return (
      <Fragment>
        <CardElement className={classes.cardInputWrapper} hidePostalCode={true} />
        <MuiThemeProvider theme={green}>
          <Button onClick={this.submit} className={classes.subscribeButton} color={'primary'} variant={'contained'}>
            {loading ? <><CircularProgress size={16} style={{marginRight: theme.spacing(1)}}/>{buttonText}</> : buttonText}
          </Button>
        </MuiThemeProvider>
      </Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    snackSuccess: (message) => dispatch(_snackSuccess(message)),
    snackError: (err) => dispatch(_snackError(err)),
    getBilling: () => dispatch(_getBilling()),
  };
}

CheckoutForm = withStyles(styles)(CheckoutForm);
CheckoutForm = connect(null, mapDispatchToProps)(CheckoutForm);
CheckoutForm = injectStripe(CheckoutForm);

export default CheckoutForm;
