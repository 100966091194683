import {MenuItem} from '@material-ui/core';
import {get} from 'lodash';
import React from 'react';
import {useSelector} from 'react-redux';
import HoopsSelectField from '../../componentsOld/HoopsSelectField';
import TaxService from './TaxService';

const companyStateSelector = (state) => state.companyReducer.company;

export const TaxSelect = ({
  input,
  label,
  taxType = 'revenue',
  ...custom
}) => {

  const companyState = useSelector(companyStateSelector);
  const taxes = taxType === 'revenue'
    ? TaxService.getRevenueTaxes(companyState).map(TaxService.parseTaxToTaxInput)
    : TaxService.getExpenseTaxes(companyState).map(TaxService.parseTaxToTaxInput);

  const valueId = get(input, 'value._id', '');

  const getValue = (taxId) => taxes.find((tax) => tax._id === taxId);

  return (
    <HoopsSelectField
      {...input}
      label={label}
      fullWidth
      {...custom}
      value={valueId}
      onChange={(e) => {
        const {value} = e.target;
        const tax = getValue(value);
        input.onChange(tax);
      }}
      onBlur={(e) => {
        const {value} = e.target;
        const tax = getValue(value);
        input.onBlur(tax);
      }}
    >
      {taxes.map((tax) => (
        <MenuItem key={tax._id} value={tax._id}>
          {tax.name}
        </MenuItem>
      ))}
    </HoopsSelectField>
  );
};

export default TaxSelect;
