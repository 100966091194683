import {TableHead, TableRow} from '@material-ui/core';
import React from 'react';
import {HoopsTableCell, isSelectable} from '../HoopsTable';

export const JobItemTableVariantadditionalItemHeader = ({mode = 'checkbox'}) => (
        <TableHead>
            <TableRow>
                {isSelectable(mode) ? <HoopsTableCell /> : null}
                <HoopsTableCell colSpan={2}>
                    Additional Items
            </HoopsTableCell>
                <HoopsTableCell colSpan={5} />
            </TableRow>
        </TableHead>
    );

export default JobItemTableVariantadditionalItemHeader;
