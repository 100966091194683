import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';

export function CatalogImportFormHeader() {
  return (
    <Grid container>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12}>
          <Grid container alignItems={'center'} spacing={2} wrap={'nowrap'}>
            <Grid item xs={3}>
              <Typography align={'center'} variant={'body2'} color={'textSecondary'}>Hoops Fields</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography align={'left'} variant={'body2'} color={'textSecondary'}>Fields from your
                CSV</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography align={'left'} variant={'body2'} color={'textSecondary'}>Data Preview</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
