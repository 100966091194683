import {HttpLink} from 'apollo-link-http';
import {ApolloLink, concat} from 'apollo-link';
import {ApolloClient} from 'apollo-client';
import {InMemoryCache} from 'apollo-cache-inmemory';
import fetch from 'unfetch';
import {TOKEN_KEY} from '../constants/api';

export function createGraphQLClient({endpoint = process.env.REACT_APP_GRAPHQL_URI,}) {
  const httpLink = new HttpLink({uri: endpoint});

  const authMiddleware = new ApolloLink((operation, forward) => {
    const token = localStorage.getItem(TOKEN_KEY);
    operation.setContext({
      headers: {authorization: (token ? `Bearer ${token}` : null),},
      credentials: 'include'
    });
    return forward(operation);
  });

  return new ApolloClient({
    link: concat(authMiddleware, httpLink),
    fetchOptions: {fetch},
    cache: new InMemoryCache({
      // This is set to false because creating nested inputs can fail with __typeName
      addTypename: false
    }),
    defaultOptions: {
      query: {fetchPolicy: 'no-cache'},
      mutate: {fetchPolicy: 'no-cache'}
    }
  });
}

export const client = createGraphQLClient({});
