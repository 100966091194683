// TODO: fix eslint disable
/* eslint-disable no-unused-vars, consistent-return, no-shadow */

import {Button, Chip, makeStyles} from '@material-ui/core';
import React, {useEffect, useState} from 'react';

const useChipStyles = makeStyles((theme) => ({
    root: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
}));

const useStyles = makeStyles((theme) => ({
    chipsContainer: {paddingBottom: theme.spacing(1)},
    clearButton: {
        textTransform: 'none',
        padding: 0,
        marginBottom: theme.spacing(2),
    }
}));

export const HoopsQueryFilterChips = ({
    chips = {},
    filter = [],
    onFilterChange = () => null,
    onChipsChange = () => null
}) => {
    const [_filters, _setFilters] = useState(filter);
    const chipClasses = useChipStyles();
    const classes = useStyles();

    useEffect(() => {
        _setFilters(filter);
    }, [filter]);

    if (filter.q && !chips.q) {
      chips.q = [{
        value: filter.q,
        label: filter.q,
        onRemove: (filter) => ({
            ...filter,
            q: undefined,
          })
      }];
    }

    const handleDelete = (filterKey, filterValue) => {
        _setFilters((prev) => {
            const _chips = chips[filterKey];
            if (!_chips) {
                return;
            }

            const chipIdx = _chips.findIndex(({value}) =>  value === filterValue);
            const [chip] = _chips.splice(chipIdx, 1);

            if (chip && chip.onRemove) {
                const result = chip.onRemove(filter, filterKey, filterValue);

                onFilterChange(result); // push upstream
                onChipsChange({
                    ...chips,
                    [filterKey]: _chips
                });
                return result;
            }

            return prev;
        });
    };

    const handleClearFilters = () => {
        onFilterChange({});
        onChipsChange({});
    };

    /**
     * convert the Chips Object into an array of Chips
     */
    const getChipsArray = () => Object.keys(chips).reduce((agg, filterKey) => {
            const existing = chips[filterKey] || [];
            return [
                ...agg,
                ...existing.map(({value, label}) => ({filterKey, value, label}))
            ];
        }, []);

    return (
        <div>
            {getChipsArray().map(
                ({filterKey, value, label}) => <Chip key={value} label={label} onDelete={() => handleDelete(filterKey, value)} color='primary' size='small' classes={chipClasses} />
            )}
            {Boolean(getChipsArray().length > 0) && <Button className={classes.clearButton} color='primary' onClick={handleClearFilters}>Clear All</Button>}
        </div>

    );
};

export default HoopsQueryFilterChips;
