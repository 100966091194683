import {gql} from 'apollo-boost';
import {GQL_CUSTOMER_FRAG} from './customers';

export const GQL_GET_CONTACTS = gql`
  query ContactMany($filter: FilterFindManySearchGCContactInput, $limit: Int) {
    contactMany(filter: $filter, limit: $limit) {
      _id
      fullName
      phone
      email
      customer {
          ...CustomerFragment
      }
    }
  }
  ${GQL_CUSTOMER_FRAG}
`;

export const GQL_GET_CONTACT = gql`
  query ContactOne($filter: FilterFindOneGCContactInput) {
    contactOne(filter: $filter) {
      _id
      fullName
      phone
      email
      customer {
          ...CustomerFragment
      }
    }
  }
  ${GQL_CUSTOMER_FRAG}
`;
