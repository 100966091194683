import {Grid} from '@material-ui/core';
import React from 'react';
import HoopsMultiSelectChips from '../../componentsOld/shared/HoopsMultiSelectChips';
import FilePreview from './FilePreview';

export default ({files = [], options = [], onChange = () => null}) => {

    const handleOnChange = (file, event) => {
        onChange({
            file,
            associations: event.target.value
        });
    };

    return (
        <Grid container>
            {files.map(
                (file) => <Grid key={file._id} container>
                    <Grid item xs={4}>
                        <FilePreview file={file} />
                        {file.filename}
                    </Grid>
                    <Grid item xs>
                        <HoopsMultiSelectChips
                            name={'products'}
                            label={'Link to Product(s)'}
                            fullWidth
                            input={{onChange: (event) => handleOnChange(file, event)}}
                            options={options} />
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};
