import React, {Fragment} from 'react';
import {Grid} from '@material-ui/core';
import InvoiceHistory from './InvoiceHistory';
import {UpcomingInvoice} from './UpcomingInvoice';
import PaymentMethods from './PaymentMethods';

class BillingDashboard extends React.Component {

  componentDidMount() {
    document.title = 'Billing | Hoops';
  }

  render() {
    return (
      <Fragment>
          <Grid
            container
            spacing={4}
          >
            <Grid item xs={12}>
                <h3>Billing</h3>
            </Grid>
            <Grid item md={12} lg={6}>
              <Grid container direction={'column'} spacing={4}>
                <Grid item>
                  <UpcomingInvoice/>
                </Grid>
                <Grid item>
                  <PaymentMethods/>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12} lg={6}>
              <InvoiceHistory/>
            </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default BillingDashboard;
