import React, {Component, Fragment} from 'react';
import {IconButton, Icon, Menu, MenuItem, ListItemIcon, MuiThemeProvider} from '@material-ui/core';
import green from '../../../../ui/theme/green';
import {connect} from 'react-redux';
import {
  openProofViewModal as _openProofViewModal,
  openProofNotesModal as _openProofNotesModal
} from '../../../../actions/modals';
import Link from '@material-ui/core/Link';
import {
  approveProof as _approveProof,
  deleteProof as _deleteProof,
  getProofs as _getProofs
} from '../../../../actions/proofs';

class ProofsTableActionCell extends Component {

  state = {anchorEl: null};

  handleClick = (e) => {
    this.setState({anchorEl: e.currentTarget});
  };

  handleClose = () => {
    this.setState({anchorEl: null});
  };

  handleProofViewModalOpen = () => {
    const {openProofViewModal, proof} = this.props; 
    openProofViewModal(proof);
    this.handleClose();
  };

  handleApproveProof = () => {
    const {proof, approveProof} = this.props;
    approveProof(proof);
    this.handleClose();
  };

  handleDeleteProof = () => {
    const {proof, deleteProof} = this.props;
    deleteProof(proof);
    this.handleClose();
  };

  handleProofNotesModalOpen = () => {
    const {openProofNotesModal, proof} = this.props;
    openProofNotesModal({proofId: proof._id});
    this.handleClose();
  };

  handleProofRejectModalOpen = () => {
    const {openProofNotesModal, proof} = this.props;
    openProofNotesModal({proofId: proof._id, rejecting: true});
    this.handleClose();
  };

  render() {
    const {proof} = this.props;
    const {anchorEl} = this.state;
    return (
      <Fragment>
        <IconButton
          variant={'outlined'}
          size={'small'}
          onClick={this.handleProofNotesModalOpen}
          data-intercom-target={'job-proofs-notes-button'}
        >
          <MuiThemeProvider theme={green}>
            <Icon color='primary'>chat_bubble</Icon>
          </MuiThemeProvider>
        </IconButton>
        <IconButton
          variant={'outlined'}
          size={'small'}
          onClick={this.handleProofViewModalOpen}
          data-intercom-target={'job-proofs-view-button'}
        >
          <Icon>remove_red_eye</Icon>
        </IconButton>
        {
          proof.file && <Link href={proof.file.url} underline={'none'} color={'textPrimary'}>
            <IconButton
                variant={'outlined'}
                size={'small'}
                data-intercom-target={'job-proofs-download-button'}
            >
              <Icon>cloud_download</Icon>
            </IconButton>
          </Link>
        }
        <IconButton
          variant={'outlined'}
          size={'small'}
          onClick={this.handleClick}
          data-intercom-target={'job-proofs-more-button'}
        >
          <Icon>more_horiz</Icon>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.handleProofViewModalOpen}>
            <ListItemIcon>
              <Icon>remove_red_eye</Icon>
            </ListItemIcon>
            View
          </MenuItem>
          <MenuItem onClick={this.handleProofNotesModalOpen}>
            <ListItemIcon>
              <Icon>chat_bubble</Icon>
            </ListItemIcon>
            Comment
          </MenuItem>
          {
            proof.file && <Link href={proof.file.url} color={'textPrimary'} underline={'none'}>
              <MenuItem>
                <ListItemIcon>
                  <Icon>cloud_download</Icon>
                </ListItemIcon>
                Download
              </MenuItem>
            </Link>
          }
          <MenuItem onClick={this.handleApproveProof}
                    data-intercom-target={'job-proofs-approve-button'}>
            <ListItemIcon>
              <Icon>check_circle</Icon>
            </ListItemIcon>
            Approve
          </MenuItem>
          <MenuItem onClick={this.handleProofRejectModalOpen}
                    data-intercom-target={'job-proofs-reject-button'}>
            <ListItemIcon>
              <Icon>close</Icon>
            </ListItemIcon>
            Reject
          </MenuItem>
          <MenuItem disabled>
            <ListItemIcon>
              <Icon>edit</Icon>
            </ListItemIcon>
            Edit
          </MenuItem>
          <MenuItem onClick={this.handleDeleteProof}
                    data-intercom-target={'job-proofs-delete-button'}>
            <ListItemIcon>
              <Icon>delete</Icon>
            </ListItemIcon>
            Delete
          </MenuItem>
        </Menu>
      </Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    openProofViewModal: (proof) => dispatch(_openProofViewModal(proof)),
    openProofNotesModal: (context) => dispatch(_openProofNotesModal(context)),
    getProofs: (jobId) => dispatch(_getProofs(jobId)),
    approveProof: (proof) => dispatch(_approveProof(proof)),
    deleteProof: (proof) => dispatch(_deleteProof(proof))
  };
}

function mapStateToProps(state) {
  return {job: state.jobsReducer.activeJob};
}

export default connect(mapStateToProps, mapDispatchToProps)(ProofsTableActionCell);
