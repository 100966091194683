import React from 'react';
import PropTypes from 'prop-types';
import {registerGlobalStyle} from '../../theme';
import {Row, RowRight} from '../Layout';
import {HeadingText} from '../Text';

registerGlobalStyle('.page-header', (theme) => ({
  backgroundColor: theme.colors.background.white,
  padding: theme.spacing(1.5, 2, 1.375),
  position: 'sticky',
  top: 0,
  zIndex: theme.zIndex.appBar,
  alignItems: 'center',
  columnGap: theme.spacing(2),
  h4: {width: 'unset',},
  '&.border': {borderBottom: `1px solid ${theme.colors.border.light}`,},
}));

export function PageHeader({title, noBorder, children}) {
  return (
    <Row className={['page-header', noBorder || 'border']}>
      {title &&
        <>
          <HeadingText x24>
            {title}
          </HeadingText>
          <RowRight>
            {children}
          </RowRight>
        </>
      }
      {!title &&
        children
      }
    </Row>
  );
}

PageHeader.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any.isRequired,
};
