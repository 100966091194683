// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {Accordion, AccordionDetails, AccordionSummary, Grid, makeStyles, Typography} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {get, set} from 'lodash';
import React, {useEffect, useState} from 'react';

const useButtonStyles = makeStyles((theme) => ({
    root: {fontSize: '14px'},
    label: {
        textTransform: 'capitalize',
        color: theme.colors.grey.main,
        fontSize: '14px',
        fontWeight: '500',
        paddingRight: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    selected: {
        color: theme.palette.primary.main,
        fontWeight: '500',
    }
}));

const useAccordianStyles = makeStyles((theme) => ({
    root: {
        minHeight: 'unset',
        '&$expanded': {
            minHeight: 'unset',
            margin: 0
        },
    },
    expanded: {
        margin: 0,
        minHeight: 'unset',
        borderBottomColor: theme.colors.grey.light,
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',

    },
    content: {
        margin: 0,
        '&$expanded': {margin: 0,},

    },
}));

const useStyles = makeStyles((theme) => ({
    menuRoot: {
        padding: theme.spacing(0),
        margin: theme.spacing(0),
    },
    menuList: {padding: theme.spacing(0),},
    menuButton: {
        borderTopColor: theme.colors.grey.light,
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        padding: theme.spacing(1),
        paddingBottom: theme.spacing(0),
        minWidth: theme.spacing(25),
        display: 'flex',
        justifyContent: 'flex-end',
    },
    root: {width: '100%',},
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    accordianStyle: {'&$expanded': {margin: '0 !important',}},
    accordianSummary: {
        minHeight: 'unset',
        fontWeight: 500,
    },
    accordianDetails: {
        display: 'unset',
        padding: 'unset',
    },
    expanded: {
        '&$expanded': {
            borderBottomColor: theme.colors.grey.light,
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
        }
    },
    listContainer: {
        maxHeight: 300,
        width: '100%',
        overflow: 'auto'
    }
}));

export const HoopsQueryFilterDropMenu = ({
    label = '',
    filterComponents = [],

    filter = {},
    chips = {},
    onFilterChange = () => null,
    onChipsChange = () => null,
}) => {
    const buttonClasses = useButtonStyles();
    const classes = useStyles();
    const accordianClasses = useAccordianStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [filters, setFilters] = useState(filter);
    const [_chips, setChips] = useState(chips);
    const [filterCount, setFilterCount] = useState(0);

    useEffect(() => {
        setFilters(filter);
    }, [filter, filterComponents]);

    useEffect(() => {
        const count = filterComponents.reduce((agg, filterComponent) => {
            const chipsForFilter = chips[filterComponent.key];
            return agg += chipsForFilter ? chipsForFilter.length : 0;
        }, 0);
        setFilterCount(count);
    }, [chips, filterComponents]);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleChange = (filterKey, {filterValue, chips, forceApplyChips = false}) => {
        setChips((prev) => {
            const result = {
                ...prev,
                [filterKey]: chips
            };

            if (forceApplyChips) {
                onChipsChange(result);
            }
            return result;
        });

        // NOTE: This is incorrect. We should be doing _operators on the filter itself
        setFilters((prev) => set(prev, `_operators.${filterKey}`, filterValue));
    };

    const getFilterValue = (filterKey) => get(filters, `_operators.${filterKey}`, {});

    const handleClose = () => {
        setFilters(filter);
        setAnchorEl(null);
    };

    const handleFilterApply = () => {
        onFilterChange(filters);
        onChipsChange(_chips);
        handleClose();
    };

    return (
        <>
            <Button aria-controls='filter-menu' aria-haspopup='true' onClick={handleMenuClick} className={filterCount > 0 ? `${buttonClasses.selected} ${buttonClasses.label}` : buttonClasses.label}>
                {label}
                &nbsp;
                {filterCount > 0 ? <span>({filterCount})</span> : null}
                &nbsp;
                <KeyboardArrowDownIcon />
            </Button>

            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Grid
                    container
                    direction='column'
                    justifyContent='flex-start'
                    alignItems='flex-start'
                >
                    {filterComponents.length === 1
                        ? <Grid item className={classes.listContainer}>
                            {filterComponents.map(
                                (filter) => React.createElement(
                                    filter.component,
                                    {
                                        key: filter.key,
                                        options: filter.options,
                                        value: getFilterValue(filter.key),
                                        onChange: (payload) => handleChange(filter.key, payload)
                                    }
                                ))}
                        </Grid>
                        : filterComponents.map((filter) =>
                            <Accordion key={filter.value} option={filter} elevation={0} classes={accordianClasses}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls='panel1a-content'
                                    id='panel1a-header'
                                    className={`${classes.expanded} ${classes.accordianSummary}`}
                                >
                                    <Typography className={classes.heading}>{filter.label}</Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                    className={`${classes.accordianDetails}`}
                                >
                                    {React.createElement(
                                        filter.component,
                                        {
                                            key: filter.key,
                                            options: filter.options,
                                            value: getFilterValue(filter.key),
                                            onChange: (payload) => handleChange(filter.key, payload)
                                        }
                                    )}
                                </AccordionDetails>
                            </Accordion>

                        )}

                    <Grid role={undefined} className={classes.menuButton} item>
                        <Button
                            variant='contained'
                            target='_blank'
                            color='primary'
                            disableElevation
                            onClick={handleFilterApply}
                        >
                            Apply
                        </Button>
                    </Grid>
                </Grid>
            </Menu>

        </>
    );
};

export default HoopsQueryFilterDropMenu;
