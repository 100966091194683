// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {openCatalogModal as _openCatalogModal} from '../../../actions/modals';

export const ProductAutocompleteRenderOptionButton = () => {
  const dispatch = useDispatch();

  const handleButtonClick = () => {
    dispatch(_openCatalogModal());
  };

  return (
    <Box mb={2} mr={2} ml={2} style={{marginTop: 16}}>
      <Grid container alignItems={'center'} justifyContent={'center'}>
        <Grid item>
          {/* onMouseDown is fired before onBlur */}
          <Button color={'primary'} variant={'contained'} onMouseDown={handleButtonClick}>
            Add New Product
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductAutocompleteRenderOptionButton;
