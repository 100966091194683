import React, {useState} from 'react';
import Description from '@material-ui/icons/Description';
import {HoopsButton} from '../../../componentsLib';
import theme from '../../../ui/theme';
import CardContainer from '../../common/CardContainer';
import PurchaseOrderManyTable from '../../purchase-orders/table/PurchaseOrderManyTable';
import {AddPrurchaseOrderModal} from '../../shared/modals/PurchaseOrdersModals/AddPurchaseOrderModal';

export const JobPurchaseOrders = ({job}) => {
  const [open, setOpen] = useState(false);
  const handleAddPo = () => {
    setOpen(true);
  };

  return (
    <>
      <CardContainer
        title={'Purchase Orders'}
        icon={<Description />}
        iconBackgroundColor={theme.colors.red}
        buttonComponent={
          <HoopsButton purpose={'add'} onClick={handleAddPo}>Add Purchase Order</HoopsButton>
        }
      >
        {
          job &&
          <PurchaseOrderManyTable
            handleNewPurchaseOrder={handleAddPo}
            queryStateId={'jobPurchaseOrderMany' + job?._id}
            fixedFilter={{linkedJobIds: [job?._id]}}
          />
        }
      </CardContainer>
      {open && <AddPrurchaseOrderModal jobId={job._id} open={open} closeModal={() => setOpen(false)}/>}
    </>
  );
};
