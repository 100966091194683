import React, {useState} from 'react';
import classNames from 'classnames';
import {registerGlobalStyle} from '../../theme';
import {Tabs} from './Tabs';
import {HoopsPropTypes} from '../utils';
import {Column} from '../Layout';

registerGlobalStyle('.tabbed-content', (theme) => ({
  flexGrow: 1,
  '.tabs.row': {background: 'none'},
  '.tab-panel': {
    padding: 0,
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'start',
    flexGrow: 1,
    alignSelf: 'stretch',
  },
}));

export const TabbedContent = ({className, value, children}) => {
  const visibleChildren = children.filter((child) => !child.props.visible);
  const [selectedTabIndex, setSelectedTabIndex] = useState(value || 0);

  return (
    <Column className={[className, 'tabbed-content']}>
      <Tabs
        value={selectedTabIndex}
        onChange={(tabIndex) => {
          setSelectedTabIndex(tabIndex);
        }}
        variant='scrollable'
        scrollButtons='auto'
        allowScrollButtonsMobile
        tabs={visibleChildren.map((child) => child.props.label)}
      />
      {visibleChildren[selectedTabIndex]}
    </Column>
  );
};

TabbedContent.propTypes = {
  className: HoopsPropTypes.className,
  value: HoopsPropTypes.number,
  children: HoopsPropTypes.children
};

export const TabPanel = ({className, children}) => (<div className={classNames([className, 'tab-panel'])}>{children}</div>);

TabPanel.propTypes = {
  className: HoopsPropTypes.className,
  children: HoopsPropTypes.children
};
