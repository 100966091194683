// TODO: fix eslint disable
/* eslint-disable no-shadow */

import React, {useCallback, useEffect, useState} from 'react';
import * as uuidv4 from 'uuid/v4';
import {CircularProgress, Icon, IconButton} from '@material-ui/core';
import * as AWS from 'aws-sdk';
import ImageWithTooltip from '../shared/ImageWithTooltip';

AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: new AWS.CognitoIdentityCredentials({IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,})
});

const s3 = new AWS.S3({
  apiVersion: '2006-03-01',
  params: {
    Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
    Region: process.env.REACT_APP_AWS_REGION
  }
});

function SingleFileUpload({onComplete, disabled, input: {value, onChange, ...input}}) {
  const [preview, setPreview] = useState('');
  const [, setAlt] = useState('');
  const [loading, setLoading] = useState(false);
  const [, setComplete] = useState(false);
  const [progress, setProgress] = useState(0);
  const [inputField, setInputField] = useState(null);

  const handleClick = useCallback(() => {
    inputField.click();
  }, [inputField]);

  const handleProgress = useCallback(({loaded, total}) => {
    if (loaded && total) {
      const progress = loaded / total;
      setProgress(progress);
    }
  }, []);

  const handleChange = useCallback((e) => {
    const file = e.target.files[0];
    setPreview(URL.createObjectURL(file));
    setAlt(file.name);

    setLoading(true);
    setComplete(false);

    const managedUpload = s3.upload({
      Key: uuidv4(),
      Body: file,
      ACL: 'public-read'
    }, (err, data) => {
      if (err) {
        console.log(err);
        return;
      }
      setLoading(false);
      setComplete(true);
      setProgress(0);
      const newValue = {
        filename: file.name,
        key: data.Key,
        bucket: data.Bucket,
        url: data.Location,
        contentType: file.type,
        contentLength: file.size,
      };
      onChange(newValue);
      onComplete(newValue);
    });
    managedUpload.on('httpUploadProgress', handleProgress);
  }, [handleProgress, onChange, onComplete]);

  useEffect(() => {
    if (value.url) {
      setPreview(value.url);
    }
  }, [value]);

  return (
    <>
      <input
        {...input}
        hidden
        type={'file'}
        onChange={handleChange}
        disabled={disabled}
        ref={(ref) => {
          setInputField(ref);
        }}
      />
      {!loading && !preview && <IconButton onClick={handleClick}>
        <Icon color={'primary'}>add_circle_outline</Icon>
      </IconButton>}
      {
        loading ?
          <CircularProgress value={progress * 100} variant={'static'} /> :
          <ImageWithTooltip imageUrl={preview} onClick={handleClick} />
      }
    </>
  );
}

export default SingleFileUpload;
