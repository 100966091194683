// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import {MuiThemeProvider} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {find, get} from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {change as _change, Field, getFormValues, reduxForm, reset} from 'redux-form';
import {
  closeMergePurchaseOrderModal as _closeMergePurchaseOrderModal,
  openPOShipTo as _openShipTo
} from '../../../../actions/modals';
import {createPo as _createPo, resetActivePo as _resetActivePo, resetFormPo as _resetFromPo, updatePo as _updatePo} from '../../../../actions/purchaseOrders';
import PurchaseOrdersService from '../../../../servicesOld/PurchaseOrders';
import green from '../../../../ui/theme/green';
import MergePurchaseOrderTable from '../../../purchase-orders/table/MergePurchaseOrderTable';
import VendorAutocomplete from '../../../vendors/VendorAutocomplete';
import DialogActions from '../../DialogActions';
import {HoopsDatePicker, renderTextField} from '../../formHelpers/fields';

export const purchaseOrderForm = 'purchaseOrderForm';

export function AddressOneLine({addressType, addresses}) {
  let address = find(addresses, {label: addressType});
  if (!address) {
    if (addresses && addresses.length) {
      address = addresses[0];
    }
  }

  let addressString = [
    'address1', 'address2', 'city', 'state', 'postcode', 'country'
  ]
    .filter((key) => Boolean(address && address[key]))
    .map((key) => address[key])
    .join(', ');

  return (
    <>
      {(address ?
        <Typography variant='body2' gutterBottom>
          {addressString}
        </Typography> :
        <Typography variant={'body2'} color={'textSecondary'} gutterBottom>No linked address</Typography>)}
    </>
  );
}

const styles = (theme) => ({
  topContainer: {marginBottom: theme.spacing(3)},
  primaryColor: {backgroundColor: theme.palette.primary.main},
  header: {
    position: 'relative',
    backgroundColor: theme.colors.grey.ultraLight,
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  orderContainer: {padding: theme.spacing(5)},
  button: {
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  buttonContainer: {marginBottom: theme.spacing(8)},
  marginRight: {marginRight: theme.spacing(2)},
  badgeMargin: {margin: theme.spacing(2)}
});

class MergePurchaseOrderModal extends Component {
  state = {
    purchaseOrders: [],
    saveDisabled: true
  }

  submit = (values) => {
    const {createPo, updatePo, job} = this.props;

    if (values._id) {
      updatePo(values._id, values, () => {
        this.handleClose();
      });
      return;
    }
    createPo(values, {jobId: job._id}, () => {
      this.handleClose();
    });
  };

  initForm = () => {
    this.initTitle();
    this.initTaxes();
    this.initShipTo();
    this.getPurchaseOrders();
  };

  getPurchaseOrders = async () => {
    const vendorId = this?.props?.poFormValues?.vendor?._id;
    if (vendorId) {
      const filter = {
        vendorId,
        status: 'CREATED',
        _operators: {
          isMasterPurchaseOrder: {ne: true},
          masterPurchaseOrderId: null,
        }
      };
      const sort = '_ID_DESC';

      const purchaseOrders = await PurchaseOrdersService.getPurchaseOrderMany({
        filter,
        sort,
      });

      this.setState({purchaseOrders});
    }
  }

  initTaxes = () => {
    const {company, change, poFormValues, context} = this.props;
    const isNewPo = get(context, 'isNewPo', false);
    if (company && !poFormValues?._id && isNewPo) {
      change('items', [{
        taxes: company?.taxes,
        files: [],
      }, {
        taxes: company?.taxes,
        files: [],
      }, {
        taxes: company?.taxes,
        files: [],
      }]);
    }
  };

  initTitle = () => {
    const {change} = this.props;
    change('title', 'Merged PO');
  };

  initShipTo = () => {
    const {company, change, user, poFormValues} = this.props;
    if (company && !poFormValues?._id) {
      change('shipTo', {
        name: get(company, 'name'),
        attention: get(user, 'fullName'),
        email: get(company, 'email'),
        phone: get(company, 'phone'),
        address: get(company, 'shippingAddress', {})
      });
    }
  };

  handleClose = () => {
    const {closeModal, resetForm, resetFormPo, resetUploadForm} = this.props;
    resetForm();
    resetFormPo();
    closeModal();
    resetUploadForm();
  };

  handlePurchaseOrderSelection = (purchaseOrderIds) => {
    const {change} = this.props;
    const saveDisabled = (purchaseOrderIds.length < 2);
    change('childPurchaseOrderIds', purchaseOrderIds);
    this.setState({saveDisabled: saveDisabled});
  }

  handleEditShipTo = () => {
    const {openShipTo, poFormValues: {shipTo}} = this.props;
    openShipTo(shipTo, true);
  };

  render() {
    const {
      open, classes, company,
      handleSubmit, user,
      poFormValues
    } = this.props;

    const shipTo = get(poFormValues, 'shipTo', {});
    const shippingAddress = get(shipTo, 'address.formattedAddress', '');
    return (
      <Dialog open={open} fullScreen={true} TransitionProps={{onEnter: this.initForm}}>
        <form onSubmit={handleSubmit(this.submit)}>
          <Grid container className={classes.header}>
            <Toolbar style={{width: '100%'}}>
              <Grid container justifyContent={'space-between'}>
                <Grid item xs={11}>
                  {/* PO Title */}
                  <Grid container spacing={4} alignItems={'center'}> {/*1*/}
                    <Grid item>
                    </Grid> {/*Offset*/}
                    <Grid container item xs={10} spacing={5}>
                      <Grid container item spacing={5}>
                      </Grid>
                      <Grid container item spacing={5}>
                        <Grid item xs={4}>
                          <Field
                            name={'title'}
                            label={'Purchase Order Title'}
                            component={renderTextField}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Field
                            fullWidth
                            name={'vendor'}
                            label={'Vendor'}
                            disabled={!!poFormValues?.locked}
                            component={VendorAutocomplete}
                          />
                        </Grid>
                        <Grid container item direction={'column'} xs={4}>

                          <Field
                            name={'deadlineAt'}
                            label='Deadline Date'
                            component={HoopsDatePicker}
                          />
                        </Grid>
                      </Grid>
                      <Grid container item spacing={5}>
                        <Grid container item xs={4} direction='row' wrap='nowrap'>
                          <Grid item className={classes.marginRight}>
                            <Avatar className={classes.primaryColor}>
                              <Icon>assignment_ind</Icon>
                            </Avatar>
                          </Grid>
                          <Grid item>
                            <Typography variant='h6' style={{fontWeight: 400}} gutterBottom>Vendor:</Typography>
                            {poFormValues?.vendor && (
                              <>
                                <Typography variant='body2' style={{fontWeight: 500}}
                                  gutterBottom>{poFormValues?.vendor?.name}</Typography>
                                <AddressOneLine addressType={'BILLING'} addresses={poFormValues?.vendor?.addresses} />
                                <Typography variant='body2' gutterBottom>{poFormValues?.vendor?.email}</Typography>
                                <Typography variant='body2' gutterBottom>{poFormValues?.vendor?.phone}</Typography>
                              </>
                            )}
                          </Grid>
                        </Grid>
                        <Grid container item xs={4} direction='column'>
                          <Typography variant='h6' style={{fontWeight: 400}} gutterBottom>Bill to:</Typography>
                          <Typography variant='body2' style={{fontWeight: 500}} gutterBottom>{company?.name}</Typography>
                          <AddressOneLine addressType={'BILLING'} addresses={company?.addresses} />
                          <Typography variant='body2' gutterBottom>{user.username}</Typography>
                          <Typography variant='body2' gutterBottom>{company?.phone}</Typography>
                        </Grid>
                        <Grid container item xs={4} direction='column'>
                          <Grid item container justifyContent={'space-between'} alignItems={'center'}>
                            <Typography variant='h6' style={{fontWeight: 400}} gutterBottom>Ship to:</Typography>
                            <Button size={'small'}
                              color={'primary'}
                              variant={'outlined'}
                              onClick={() => this.handleEditShipTo()}
                            >
                              Edit
                            </Button>
                          </Grid>
                          <Typography variant='body2' style={{fontWeight: 500}} gutterBottom>{shipTo.name}</Typography>
                          {shipTo.attention &&
                            <Typography variant='body2' gutterBottom>Attn: {shipTo.attention}</Typography>}
                          <Typography variant='body2' gutterBottom>{shippingAddress}</Typography>
                          <Typography variant='body2' gutterBottom>{shipTo.email}</Typography>
                          <Typography variant='body2' gutterBottom>{shipTo.phone}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <IconButton onClick={this.handleClose}>
                    <Icon>close</Icon>
                  </IconButton>
                </Grid>
              </Grid>
            </Toolbar>
          </Grid>
          <Grid container className={classes.orderContainer}>
            <Typography variant='h6' style={{fontWeight: 400, paddingBottom: '16px'}}> Select {get(poFormValues, 'vendor.name', '')} Purchase Orders to Merge</Typography>
            <MergePurchaseOrderTable data={this.state.purchaseOrders} onChange={this.handlePurchaseOrderSelection} />
          </Grid>
          <DialogActions>
            <Grid container justifyContent={'space-between'}>
              <Grid item>
                <Button variant={'text'} color={'secondary'} onClick={this.handleClose}>Cancel</Button>
              </Grid>
              <Grid item>
                <MuiThemeProvider theme={green}>
                  <Button variant={'contained'} disabled={this.state.saveDisabled} color={'primary'} type={'submit'}>Merge & Save</Button>
                </MuiThemeProvider>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    open: state.modalReducer.mergePurchaseOrderModal.isOpen,
    context: state.modalReducer.mergePurchaseOrderModal.context,
    company: state.companyReducer.company,
    user: state.authReducer.userData,
    initialValues: state.purchaseOrdersReducer.formPurchaseOrder,
    poFormValues: getFormValues(purchaseOrderForm)(state),
    job: state.jobsReducer.activeJob
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => dispatch(_closeMergePurchaseOrderModal()),
    resetForm: () => dispatch(reset(purchaseOrderForm)),
    change: (field, value) => dispatch(_change(purchaseOrderForm, field, value)),
    resetFormPo: () => dispatch(_resetFromPo()),
    createPo: (poData, options, cb) => dispatch(_createPo(poData, options, cb)),
    updatePo: (_id, poData, cb) => dispatch(_updatePo(_id, poData, cb)),
    resetUploadForm: () => dispatch(reset('PoUploadForm')),
    openShipTo: (shipTo, isDraftPO) => dispatch(_openShipTo(shipTo, isDraftPO)),
    resetActivePo: () => dispatch(_resetActivePo()),
  };
}

MergePurchaseOrderModal = reduxForm({
  form: purchaseOrderForm,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(MergePurchaseOrderModal);
MergePurchaseOrderModal = withStyles(styles)(MergePurchaseOrderModal);
MergePurchaseOrderModal = connect(mapStateToProps, mapDispatchToProps)(MergePurchaseOrderModal);

export default MergePurchaseOrderModal;
