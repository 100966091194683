import {MenuItem, Select} from '@material-ui/core';
import React, {useContext} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {clearDecorationRow} from './decorationUtils';
import {ItemBuilderContext} from '../ItemBuilderContext';

export const DecorationType = ({
    rowIndex,
    field
}) => {
    const {control, setValue} = useFormContext();
    const {onPriceChanged, productDecorations} = useContext(ItemBuilderContext);
    
    const productIdArray = productDecorations?.reduce((ag, el) => {
        el?.productId && ag.push(el?.productId);
        return (ag);
    }, []) ?? [];
    const productDecorationConcat = productDecorations?.reduce((ag, el) => {
        el?.decorations && ag.push(...el?.decorations);
        return (ag);
    }, []) ?? [];
    const linkedToProductAvailable = productIdArray.length < 2 && productDecorationConcat.length > 0;

    // set the default value
    const linkedToProduct = productDecorationConcat.find((el) => el == field.decorationId) || false;

    const decorationType = field.decorationId
        ? linkedToProduct ? 'product' : 'hoops'
        : 'adhoc';

    return (

        <Controller
            name={`decorationCosts[${rowIndex}].decorationType`}
            defaultValue={decorationType}
            render={(params) => (
                <Select
                    {...params}
                    onChange={(ev) => {
                        const {value} = ev.target;
                        clearDecorationRow(rowIndex, setValue);
                        params.onChange(value);
                        onPriceChanged();
                    }}
                    variant='outlined'
                    fullWidth
                    margin='dense'
                    style={{borderRadius: '4px 0 0 4px', borderRight: 'none'}}
                >
                    <MenuItem value='hoops'>Hoops</MenuItem>
                    {linkedToProductAvailable && <MenuItem value='product'>Linked to Product</MenuItem>}
                    <MenuItem value='adhoc'>Adhoc</MenuItem>

                </Select>
            )}
            control={control}

        />
    );
};

export default DecorationType;
