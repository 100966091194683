import {useLazyQuery} from '@apollo/client';
import {Checkbox, InputAdornment, makeStyles, MuiThemeProvider, TableCell, TableRow} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {get} from 'lodash';
import React, {useContext, useCallback} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {HoopsTextField} from '../../../componentsOld/HoopsTextField';
import ControlledHoopsAutocomplete from '../../../componentsOld/ControlledHoopsAutocomplete';
import {ItemBuilderProductField} from '../../../componentsOld/Products/productAutocomplete/ItemBuilderProductField';
import {useMountEffect} from '../../../hooks';
import {GQL_GET_PRODUCT} from '../../../queries/product';
import {itemBuilder} from '../../../ui/theme/itemBuilder';
import {ItemBuilderContext} from '../ItemBuilderContext';
import {ItemBuilderVendorAutocomplete} from '../ItemBuilderVendorAutocomplete';
import ActionMenu from './ActionMenu';
import BuyPriceField from './BuyPriceField';
import ColorField from './ColorField';
import MarkupField from './MarkupField';
import SellPriceField from './SellPriceField';
import SizeField from './SizeField';
import green from '../../../ui/theme/green';
import {IconButton} from '@mui/material';

const useStyles = makeStyles(() => ({...itemBuilder,}));

export const ItemBuilderProductRow = ({
  field = {},
  rowIdx = 0,
  variations = [],
  onDuplicate = () => null,
  onDelete = () => null,
}) => {
  const classes = useStyles();
  const currencySymbolSelector = (state) => get(state, 'authReducer.userData.company.currencySymbol');
  const currencySymbol = useSelector(currencySymbolSelector);
  const {onPriceChanged, setPrimaryProduct, setProductDecorations} = useContext(ItemBuilderContext);

  const {control, setValue, watch} = useFormContext();
  const productVariant = watch(`variations[${rowIdx}].productVariant`);

  const [getProductData] = useLazyQuery(GQL_GET_PRODUCT, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (result) => {
      if (result.product) {
        setValue(`variations[${rowIdx}].product`, result.product);
        setValue(`variations[${rowIdx}].productName`, result.product.title);
        setValue(`variations[${rowIdx}].productId`, result.product._id);
        setValue(`variations[${rowIdx}].vendor`, result.product.vendor.name);
        setValue(`variations[${rowIdx}].vendorId`, result.product.vendor._id);

        setProductDecorations((prev) => {
          let cloneArray = [...prev];
          const obj = {productId: result.product._id, decorations: result.product.decorations};
          cloneArray[rowIdx] = obj;
          return (cloneArray);
        });
        if (rowIdx === 0) {
          setPrimaryProduct(result.product);
        }
        onPriceChanged();
      }
    },
  });

  const initLoad = useCallback(() => {
    field.productId && getProductData({variables: {_id: field.productId}});
    setValue(`variations[${rowIdx}].productType`, !field.productId && (field.productName || field.sellPrice) ? 'oneOff' : 'hoops');
  }, [getProductData, field.productId, field.productName, field.sellPrice, rowIdx, setValue]);

  useMountEffect(() => {
    initLoad();
  }, [initLoad]);

  const handleClearProductRow = useCallback((productTypeValue) => {
    setValue(`variations[${rowIdx}]`, {
      productType: productTypeValue,
      product: {},
      productCode: '',
      productName: '',
      productId: null,
      quantity: 0,
      productVariant: null,
      buyPrice: 0,
      buyPriceOverride: false,
      markup: null,
      colour: '',
      size: '',
    });
  }, [rowIdx, setValue]);

  const handleProductTypeChange = useCallback((productTypeValue) => {
    handleClearProductRow(productTypeValue);
    if (rowIdx === 0) {
      setPrimaryProduct(null);
    }
  }, [rowIdx, setPrimaryProduct, handleClearProductRow]);

  return (
    <TableRow hover={false} key={field.id} >
      <Controller
        name={`variations[${rowIdx}].productType`}
        defaultValue={field.productType}
        render={(props) => (
          <input
            type='hidden'
            {...props}
          />)}
        control={control}
      />
      <ItemBuilderProductField
        defaultValue={field.productName}
        rowIdx={rowIdx}
        field={field}
        control={control}
        productType={variations[rowIdx]?.productType}
        setProductType={handleProductTypeChange}
        handleProductSelected={(el) => {
          // Setting these fields before loading full product to hide any potential load times
          handleClearProductRow();
          setValue(`variations[${rowIdx}].vendor`, el?.vendor);
          setValue(`variations[${rowIdx}].productCode`, el?.code);
          getProductData({variables: {_id: el._id}});
        }}
      />
      <Controller
        name={`variations[${rowIdx}].product`}
        defaultValue={field.product}
        render={(props) => (
          <input
            type='hidden'
            {...props}
          />)}
        control={control}
      />

      <TableCell scope='row' className={classes.tableCell} style={{minWidth: 136, maxWidth: 256}}>
        <ControlledHoopsAutocomplete
          name={`variations[${rowIdx}].vendor`}
          control={control}
          placeholder='Search Vendor'
          disabled={variations[rowIdx]?.productType !== 'oneOff'}
          textFieldProps={{
            margin: 'dense',
            variant: 'outlined',
            placeholder: 'Search Vendors',
            inputProps: {
              margin: 'none',
              className: classes.inputClass,
            }
          }}
          Component={(params) =>
            <ItemBuilderVendorAutocomplete
              fullWidth
              {...params}
            />
          }
          defaultValue={field.vendor}
          onSelect={(d) => {
            setValue(`variations[${rowIdx}].vendor`, d);
            setValue(`variations[${rowIdx}].vendorId`, d?._id);
            onPriceChanged();
          }}
          onChange={(d) => {
            setValue(`variations[${rowIdx}].vendor`, d);
            setValue(`variations[${rowIdx}].vendorId`, d?._id);
            onPriceChanged();
          }}
        />

        <Controller
          name={`variations[${rowIdx}].vendorId`}
          defaultValue={field.vendorId}
          render={(props) => (
            <input
              type='hidden'
              {...props}
            />)}
          control={control}
        />
      </TableCell>
      <TableCell scope='row' className={classes.tableCell}>
        <Controller
          name={`variations[${rowIdx}].productCode`}
          defaultValue={field.productCode}
          render={(props) => (
            <HoopsTextField
              responsive
              disabled={variations[rowIdx]?.productType !== 'oneOff'}
              {...props}
              onChange={(e) => { onPriceChanged(); props.onChange(e); }}
            />
          )}
          control={control}
        />
      </TableCell>
      <TableCell scope='row' className={classes.tableCell}>
        <Controller
          defaultValue={field.quantity}
          name={`variations[${rowIdx}].quantity`}
          render={(props) => (
            <HoopsTextField
              type='number'
              responsive
              {...props}
              onChange={(e) => { onPriceChanged(); props.onChange(e); }}
            />
          )}
          control={control}
        />
      </TableCell>
      <TableCell scope='row' className={classes.tableCell}>
        {variations[rowIdx]?.productType === 'oneOff' ?
          <Controller
            defaultValue={variations[rowIdx]?.colour}
            name={`variations[${rowIdx}].colour`}
            render={(props) => (
              <HoopsTextField
                responsive
                {...props}
                onChange={(e) => { onPriceChanged(); props.onChange(e); }}
              />
            )}
            control={control}
          />
          : <ColorField
            field={field}
            rowIdx={rowIdx}
            name={`variations[${rowIdx}].colour`}
            defaultValue={variations[rowIdx]?.colour}
          />
        }
      </TableCell>
      <TableCell scope='row' className={classes.tableCell}>
        {variations[rowIdx]?.productType === 'oneOff' ?
          <Controller
            defaultValue={variations[rowIdx]?.size ?? field.size}
            name={`variations[${rowIdx}].size`}
            render={(props) => (
              <HoopsTextField
                responsive
                {...props}
                onChange={(e) => { onPriceChanged(); props.onChange(e); }}
              />
            )}
            control={control}
          />
          :
          <SizeField
            field={field}
            index={rowIdx}
            name={`variations[${rowIdx}].productVariant._id`}
            defaultValue={productVariant ? productVariant._id : field.size}
          />
        }
      </TableCell>
      <TableCell scope='row' className={classes.tableCell}>
        {`variations[${rowIdx}].productType` === 'oneOff' ?
          <Controller
            defaultValue={variations[rowIdx]?.buyPrice}
            name={`variations[${rowIdx}].buyPrice`}
            render={(props) => (
              <HoopsTextField
                responsive
                type='number'
                InputProps={{startAdornment: <InputAdornment position={'start'}>{currencySymbol}</InputAdornment>}}
                {...props}
                onChange={(e) => { onPriceChanged(); props.onChange(e); }}
              />
            )}
            control={control}
          />
          :
          <>
            <BuyPriceField
              index={rowIdx}
              field={field}
              classses={classes}
              currencySymbol={currencySymbol}
              name={`variations[${rowIdx}].buyPrice`}
              defaultValue={field.buyPrice}
            />
            <Controller
              name={`variations[${rowIdx}].buyPriceOverride`}
              defaultValue={field.buyPriceOverride}
              render={(props) => (
                <input
                  type='hidden'
                  {...props}
                />)}
              control={control}
            />
          </>
        }
      </TableCell>
      <TableCell scope='row' className={classes.tableCell}>
        <Controller
          name={`variations[${rowIdx}].additionalCost`}
          defaultValue={field.additionalCost}
          render={(props) => (
            <HoopsTextField
              type='number'
              responsive
              {...props}
              onChange={(e) => { onPriceChanged(); props.onChange(e); }}
              InputProps={{startAdornment: <InputAdornment position={'start'}>{currencySymbol}</InputAdornment>}}
            />
          )}
          control={control}
        />
      </TableCell>
      <TableCell scope='row' className={classes.tableCell}>
        <MarkupField
          index={rowIdx}
          field={field}
          name={`variations[${rowIdx}].markup`}
          classes={classes}
        />
      </TableCell>
      <TableCell scope='row' className={classes.tableCell}>
        <SellPriceField
          index={rowIdx}
          field={field}
          name={`variations[${rowIdx}].sellPrice`}
          classes={classes}
          currencySymbol={currencySymbol}
        />
      </TableCell>
      <TableCell scope='row' className={classes.actionButtons}>
        <MuiThemeProvider theme={green}>
          <Controller
            name={`variations[${rowIdx}].displayToCustomer`}
            defaultValue={field.displayToCustomer}
            control={control}
            render={(props) => (
              <Checkbox
                checked={props.value}
                onChange={(e) => { onPriceChanged(); props.onChange(e.target.checked); }}
                style={{padding: 0}}
                iconStyle={{fill: 'green'}}
                color={'primary'}
              />
            )}
          />
        </MuiThemeProvider>
      </TableCell>
      <TableCell scope='row' className={classes.responsiveActionButtons}>
        <IconButton color={'secondary'} disabled={!variations[rowIdx]?.productId && !variations[rowIdx]?.productName} onClick={() => onDuplicate(rowIdx)} ><FileCopyIcon /></IconButton>
      </TableCell>
      <TableCell scope='row' className={classes.responsiveActionButtons}>
        <IconButton color={'secondary'} onClick={() => onDelete(rowIdx)} ><DeleteIcon /></IconButton>
      </TableCell>
      <TableCell className={classes.responsiveActionMenu}>
        <ActionMenu rowIndex={rowIdx} onDuplicate={onDuplicate} onDelete={onDelete} />
      </TableCell>
    </TableRow>
  );
};
