import {findIndex, get} from 'lodash';
import {TableCell, TableRow, Typography} from '@material-ui/core';
import classNames from 'classnames';
import numeral from 'numeral';
import React, {useEffect, useState} from 'react';
import {calculateItemSubtotal} from '../CalculationMethods';
import {formValueSelector} from 'redux-form';
import {useSelector} from 'react-redux';
import {purchaseOrderForm} from '../PurchaseOrderEditPage';

const LeftSpace = ({taxes, classes, POInternal}) => (
  <TableCell
    colSpan={3}
    rowSpan={taxes && taxes.length ? taxes.length + 3 : 0}
    className={classNames([classes.noBorder])}
  >
    <POInternal />
  </TableCell>
);

export const PoSubtotal = ({internalComponent: POInternal, company, classes}) => {

  let totals = {
    subTotal: 0,
    taxes: [],
    total: 0
  };
  const selector = formValueSelector(purchaseOrderForm);
  const itemsSelector = (state) => selector(state, 'items');
  const items = useSelector(itemsSelector);
  const [subTotal, setSubTotal] = useState(0);
  const [taxes, setTaxes] = useState([]);
  const [total, setTotal] = useState(0);

  if (items && items.length) {
    totals = items.reduce((sum, item) => {

      let itemSubtotal = item && calculateItemSubtotal(item);
      let itemTotal;
      let itemTaxes = [];

      if (item && item.tax) {
        const components = get(item, 'tax.componentsOld', []);
        itemTaxes = components.map((component) => ({
          name: component.name,
          rate: component.rate,
          amount: component.rate / 100 * itemSubtotal
        }));
        itemTotal = itemSubtotal + itemTaxes.reduce((s, tax) => s + tax.amount, 0);
      }

      return {
        subTotal: sum.subTotal + itemSubtotal,
        total: sum.total + itemTotal,
        taxes: sum.taxes.concat(itemTaxes)
      };
    }, {
      subTotal: 0,
      taxes: [],
      total: 0
    });

    totals.taxes = totals.taxes.reduce((sum, tax) => {
      let index = findIndex(sum, {name: tax.name});
      if (index === -1) {
        sum.push(tax);
        return sum;
      }
      sum[index].amount += tax.amount;
      return sum;
    }, []);
  }

  useEffect(() => {
    if (total !== totals.total) {
      setTotal(totals.total);
    }

    if (JSON.stringify(taxes) !== JSON.stringify(totals.taxes)) {
      setTaxes(totals.taxes);
    }

    if (subTotal !== totals.subTotal) {
      setSubTotal(totals.subTotal);
    }

  }, [total, totals.total, totals.taxes, totals.subTotal, taxes, subTotal]);

  const RightSpace = () => (<TableCell colSpan={2} className={classes.noBorder} />);

  return (
    <>
      <TableRow>
        <LeftSpace classes={classes} taxes={taxes} POInternal={POInternal} />
        <TableCell>
          <Typography variant={'body2'}>Sub Total</Typography>
        </TableCell>
        <TableCell padding={'none'} className={classes.subTotalCell} align={'right'}>
          <Typography variant={'body2'}>
            {company.currencySymbol} {numeral(subTotal).format('0,0.00')}
          </Typography>
        </TableCell>
        <RightSpace />
      </TableRow>
      {taxes && taxes.map((tax) => (
        <TableRow key={tax.name}>
          <TableCell>
            <Typography variant={'body2'}>{tax.name} ({tax.rate}%)</Typography>
          </TableCell>
          <TableCell padding={'none'} className={classes.subTotalCell} align={'right'}>
            <Typography variant={'body2'}>
              {company.currencySymbol} {numeral(tax.amount).format('0,0.00')}
            </Typography>
          </TableCell>
          <RightSpace />
        </TableRow>))}
      <TableRow>
        <TableCell><strong>Total</strong></TableCell>
        <TableCell padding={'none'} className={classes.subTotalCell} align={'right'}>
          <Typography>
            <strong>{company.currencySymbol} {numeral(total).format('0,0.00')}</strong>
          </Typography>
        </TableCell>
        <RightSpace />
      </TableRow>
      <TableRow />
    </>
  );
};
