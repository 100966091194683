import {Skeleton} from '@mui/material';
import React from 'react';
import {HoopsPropTypes} from '../utils';

export const RectangularSkeleton = ({className, width, height, animation, rounded, onClick}) => (
  <Skeleton  className={className} variant={rounded ? 'rounded' : 'rectangular'} width={width} height={height} animation={animation} onClick={onClick}></Skeleton>
);

RectangularSkeleton.propTypes = {
  className: HoopsPropTypes.className,
  width: HoopsPropTypes.number,
  height: HoopsPropTypes.number,
  animation: HoopsPropTypes.stringOrBool,
  onClick: HoopsPropTypes.func
};
