// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import Grid from '@material-ui/core/Grid';
import React, {useCallback, useEffect, useState} from 'react';
import {GQL_SEARCH_VENDORS} from '../../queries/vendors';
import {client} from '../../servicesOld/GraphQLService';
import {colors} from '../../ui/theme';
import NameWithInitials from '../NameWithInitials';
import HoopsCustomAutocomplete from '../shared/formHelpers/HoopsCustomAutocomplete';
import VendorDialog from './VendorDialog';

function RenderOption({...option}) {
  return (
    <Grid container spacing={1} alignItems={'center'}>
      <Grid item>
        <NameWithInitials name={option.name} color={colors.purple} />
      </Grid>
    </Grid>
  );
}

function VendorAutocomplete({textFieldProps, meta, input: {onChange}, input, field, ...params}) {
  const [vendors, setVendors] = useState([]);
  const [vendorDialogOpen, setVendorDialogOpen] = useState(false);
  const [searchString, setSearchString] = useState('');

  const handleButtonClick = useCallback(() => {
    setVendorDialogOpen(true);
  }, []);

  const handleVendorClose = useCallback((data) => {
    setVendorDialogOpen(false);
    if (data && data._id) {
      onChange(data);
      setSearchString(data.name);
    }
  }, [onChange]);

  useEffect(() => {
    client.query({
      query: GQL_SEARCH_VENDORS,
      variables: {
        filter: {q: searchString,},
        limit: 5,
      },
      fetchPolicy: 'no-cache'
    })
      .then(({data: {vendorMany}}) => setVendors(vendorMany));
  }, [searchString]);

  useEffect(() => {
    if (input && input.value && input.value !== '') {
      setSearchString(input.value.name ? input.value.name : input.value);
    }
  }, [input]);

  return (
    <>
      <VendorDialog isOpen={vendorDialogOpen} onClose={(vendor) => handleVendorClose(vendor)} />
      <HoopsCustomAutocomplete
        input={input}
        options={vendors}
        textFieldProps={textFieldProps}
        RenderOption={RenderOption}
        setSearchString={setSearchString}
        onSelect={(val) => {
          onChange(val);
          setSearchString(val.name);
        }}
        buttonProps={{
          label: 'Add New Vendor',
          onClick: handleButtonClick
        }}
        inputValue={searchString}
        getOptionLabel={(option) => option.name}
        {...params}
      />
    </>
  );
}

export default VendorAutocomplete;
