import {MuiThemeProvider, Button, Grid} from '@material-ui/core';
import {Typography} from '@mui/material';
import React, {useCallback, useState} from 'react';
import {StandardDialog} from '../../componentsOld/modal/StandardDialog';
import {PreBuiltItemAutocomplete} from '../../componentsOld/Products/productAutocomplete/PreBuiltItemAutocomplete';
import green from '../../ui/theme/green';

export const PreBuiltItemDialog = ({
  dialogOpen = () => null,
  setDialogOpen = () => null,
  handlePreBuiltItemSelected = () => null,
}) => {
  const [preBuiltItem, setPreBuildItem] = useState([]);
  const [preBuiltItemsNotEmpty, setPreBuiltItemsNotEmpty] = useState(true);

  const handleAddPreBuiltItem = useCallback(() => {
    handlePreBuiltItemSelected(preBuiltItem);
    setDialogOpen(false);
  }, [handlePreBuiltItemSelected, preBuiltItem, setDialogOpen]);

  return (
    <StandardDialog
      open={dialogOpen}
      handleClose={() => setDialogOpen(false)}
      title={preBuiltItemsNotEmpty && 'Choose Pre Built Item'}
      actions={preBuiltItemsNotEmpty &&
        <>
          <Button color='primary' onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button variant='contained' color='primary' onClick={handleAddPreBuiltItem}>Add</Button>
        </>
      }
    >
      {preBuiltItemsNotEmpty
        ?
        <PreBuiltItemAutocomplete
          onSelect={(item) => setPreBuildItem(item)}
          getPreBuiltItemLength={(value) => setPreBuiltItemsNotEmpty(value > 0)}
        />
        :
        <Grid container direction='column' justifyContent='flex-start' alignItems='center' spacing={2}>
          <Grid item><Typography variant='h4' align='center'>You do not have any <br />Pre-Built Items created yet.</Typography></Grid>
          <Grid item><Typography variant='body2' align='center'>To create one, close this popup, choose <strong>Build Item</strong>. Inside the Item Builder<br /> enter the necessary details and choose <strong>Save as Pre-Built Item.</strong> <br />Next time you come here, that item will show as an option you can select. </Typography></Grid>
          <Grid item><MuiThemeProvider theme={green}>
            <Button variant='contained' color='primary' style={{width: '80px'}} onClick={() => setDialogOpen(false)}>Got It</Button>
          </MuiThemeProvider></Grid>
        </Grid>
      }
    </StandardDialog>);
};
