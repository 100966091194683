import TaskService from '../servicesOld/TaskService';
import {newTask} from '../reducers/tasks';
import {hoopsQuerySetRefetch} from './hoopsQuery';
export const SET_TASK = 'SET_TASK';
export const SET_TASK_LOADING = 'SET_TASK_LOADING';
export const UPDATE_TASK = 'UPDATE_TASK';
export const CREATE_TASK = 'CREATE_TASK';
export const REMOVE_TASK = 'REMOVE_TASK';
export const UPDATE_TASKS = 'UPDATE_TASKS';
export const MODAL_TASK = 'MODAL_TASK';

export function editNewTask(state = {}) {
  return function(dispatch) {
    const payload = {...newTask, ...state};
    dispatch({type: SET_TASK, payload}); // new empty task and merge any data
    dispatch(setModal(true)); // open modal
    return payload;
  };
}

export function setModal(isOpen = false) {
  return function(dispatch) {
    dispatch({type: MODAL_TASK, payload: isOpen});
    return isOpen;
  };
}

export function getTasks(variables, cb) {
  return function(dispatch) {
    return TaskService.getTasks(variables).then((payload) => {
      if (cb) {
        cb(payload);
      }
      dispatch({type: UPDATE_TASKS, payload: payload});
      return payload;
    });
  };
}

export function createTask(data, cb) {
  return function(dispatch) {
    return TaskService.createTask(data).then((file) => {
      if (cb) {
        cb(file);
      }
      dispatch({type: CREATE_TASK, payload: file});
      dispatch(hoopsQuerySetRefetch('task', true));
      return file;
    });
  };
}

export function getTask(id, cb) {
  return function(dispatch) {
    dispatch({type: SET_TASK_LOADING, payload: true});
    return TaskService.getTask(id).then((payload) => {
      dispatch({
        type: SET_TASK,
        payload,
      });
      dispatch({type: SET_TASK_LOADING, payload: false});
      if (cb) {
        cb(payload);
      }

      return payload;
    });
  };
}

export function updateTask(id, data, enableRefetch = true, cb) {
  return function(dispatch) {
    return TaskService.updateTask(id, data).then((payload) => {
      dispatch({type: UPDATE_TASK, payload});
      dispatch(hoopsQuerySetRefetch('task', enableRefetch));
      if (cb) {
        cb(payload);
      }
      return payload;
    });
  };
}

export function deleteTask(id) {
  return function(dispatch) {
    return TaskService.deleteTask(id).then((d) => {
      dispatch({type: REMOVE_TASK, payload: id});
      dispatch(hoopsQuerySetRefetch('task', true));
      return d;
    });
  };
}
