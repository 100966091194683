import {CircularProgress, Link as MuiLink, MenuItem, MuiThemeProvider, Typography, withStyles} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import queryString from 'query-string';
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {Field, reduxForm} from 'redux-form';
import HoopsSelectField from '../HoopsSelectField';
import {HoopsTextField} from '../HoopsTextField';
import {REGISTER_FIELD_GROUP_REGIONS} from '../../constants/index';
import {FORGOT_PASSWORD_ROUTE, LOGIN_ROUTE} from '../../containers/routes/PreAuthRoutes';
import theme from '../../ui/theme';
import green from '../../ui/theme/green';
import Logo from '../Logo';

const styles = (_theme) => ({
  greyText: {color: _theme.colors.grey.main,},
  deviceLogo: {
    display: 'none',
    [_theme.breakpoints.down('sm')]: {
      display: 'block',
      marginBottom: 50,
    }
  },

  deviceLine: {
    display: 'none',
    [_theme.breakpoints.down('sm')]: {
      display: 'block',
      background: _theme.palette.primary.dark,
      height: 8,
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100vw',
    }
  },

  shapeGrey: {
    width: 58,
    height: 6,
    borderRadius: 15,
    backgroundColor: _theme.colors.grey.light,
    marginTop: 8
  },
});
class _SignupForm extends React.Component {
  getMessage() {
    const {message} = queryString.parse(this.props.location.search);
    if (message) {
      return decodeURIComponent(message);
    }
    return null;
  }

  render() {
    const {error, formGroup, handleSubmit, isLoading, classes, formValidation = []} = this.props;

    const {primary} = theme.palette;
    const {grey} = theme.colors;

    return (
      <Fragment>
        <form autoComplete='off' onSubmit={handleSubmit}>

          {/* Device logo */}
          <div className={classes.deviceLine}></div>
          {formGroup.name !== 'register'
            &&
            <Grid item xs={12} className={classes.deviceLogo}>
              <Link to={'/'}>
                <Logo
                  fill={[grey.logo, primary.main, green, primary.contrastText]}
                />
              </Link>
              <Typography variant='body2'>We believe business can be easy</Typography>
              <div className={classes.shapeGrey} />

            </Grid>
          }

          <Grid container spacing={4}>
            {/* FORM HEADING */}
            <Grid item xs={12} >
              {formGroup.name === 'register' || formGroup.name === 'accept_invite'
                ?
                <>
                  <div style={{width: '100%', textAlign: '-webkit-center', paddingBottom: theme.spacing(3)}}>
                    <Link to={'/'}>
                      <Logo
                        fill={[grey.logo, primary.main, green, primary.contrastText]}
                      />
                    </Link>
                  </div>
                  <Typography variant={'h5'} align='center'>{formGroup.headingLabel}</Typography>
                </>
                :
                <Typography color={'primary'} variant={'h4'}>{formGroup.headingLabel}</Typography>
              }
            </Grid>

            {this.getMessage() && <Grid item xs={12}>
              <Typography color='primary' variant='body2'> {this.getMessage()}</Typography>
            </Grid>}

            <Grid item xs={12}>
              <Grid container spacing={1}>
                {formGroup['group'].map((item, index) => (
                  <Grid item xs={item.xs_size} md={item.md_size} key={index}>

                    {
                      item.fieldType !== 'select'
                        ?
                        <Field
                          id={item.id}
                          name={item.name}
                          type={item.fieldType}
                          placeholder={item.label}
                          data={item}
                          component={HoopsTextField}
                          disabled={isLoading}
                          error={formValidation[index]?.error}
                          helperText={formValidation[index]?.helperText}
                        />
                        :
                        <Field
                          name={item.name}
                          component={HoopsSelectField}
                          displayEmpty
                          error={formValidation[index]?.error}
                          helperText={formValidation[index]?.helperText}
                        >
                          <MenuItem value='' disabled><span className={classes.greyText}>{item.label}</span></MenuItem>
                          {
                            REGISTER_FIELD_GROUP_REGIONS.regions.map((region) => (
                              <MenuItem key={region._id} value={region.name}>{region.label}</MenuItem>
                            ))
                          }
                        </Field>
                    }

                  </Grid>
                ))}
              </Grid>

              <Grid item xs={12}>
                {/* Above Button Section*/
                  formGroup.name === 'login' && (
                    <Grid container alignItems={'center'} justifyContent={'space-between'} style={{paddingTop: '16px'}}>
                      <Grid item>
                        <Typography style={{paddingTop: '8px'}}>
                          <MuiLink color={'primary'}
                            component={Link}
                            underline={'always'}
                            to={FORGOT_PASSWORD_ROUTE}>
                            Forgot password ?
                          </MuiLink>
                        </Typography>
                      </Grid>
                      <Grid item>
                        {error && <strong>{error}</strong>}
                        <Button
                          variant='contained'
                          disabled={isLoading}
                          size='medium'
                          color='primary'
                          type={formGroup['name']}

                        >
                          {isLoading ? <><CircularProgress size={16} style={{marginRight: theme.spacing(1)}}/>{formGroup['buttonLabel']}</> : formGroup['buttonLabel']}
                        </Button>
                      </Grid>
                    </Grid>
                  )
                }
              </Grid>
            </Grid>

            {/*  BUTTON */}
            {
              formGroup.name === 'register' || formGroup.name === 'accept_invite'
                ?
                <Grid item xs={12}>
                  {error && <strong>{error}</strong>}
                  <MuiThemeProvider theme={green}>
                    <Button
                      variant='contained'
                      disabled={isLoading}
                      fullWidth
                      color='primary'
                      type={formGroup['name']}
                    >
                      {isLoading ? <><CircularProgress size={16} style={{marginRight: theme.spacing(1)}}/>{formGroup['buttonLabel']}</> : formGroup['buttonLabel']}
                    </Button>
                  </MuiThemeProvider>
                </Grid>
                :
                formGroup.name !== 'login' &&
                <Grid item xs={12}>
                  {error && <strong>{error}</strong>}
                  <Button
                    variant='contained'
                    disabled={isLoading}
                    size='medium'
                    color='primary'
                    type={formGroup['name']}
                  >
                    {isLoading ? <><CircularProgress size={16} style={{marginRight: theme.spacing(1)}}/>{formGroup['buttonLabel']}</> : formGroup['buttonLabel']}
                  </Button>
                </Grid>

            }

            {/*  LINKS*/}
            <Grid item
              container
              direction='column'
              alignItems='center'
              justifyContent='flex-start'>
              {
                /*
                  Bellow Button Section Switch based on formGroup.name 'login',
                  'forget-password'
                */
                (() => {
                  switch (formGroup.name) {
                    case 'login':
                      return null;
                    case 'forget-password':
                      return (
                        <Typography variant='body1'>
                          {'Remembered it? '}
                          <MuiLink component={Link} color={'primary'} underline={'always'} to={LOGIN_ROUTE}>
                            {' Log in'}
                          </MuiLink>
                        </Typography>
                      );

                    case 'register':
                      return (
                        <Fragment>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography variant='caption' style={{color: `${theme.colors.grey.main}`}}>
                                By clicking {formGroup.buttonLabel}, you agree to our <MuiLink
                                  href='https://hoopscrm.com/terms-service/'
                                  rel='noopener noreferrer'
                                  target='_blank'
                                  color={'inherit'}
                                  underline={'always'}
                                > Terms of Service</MuiLink> and <MuiLink
                                  href='https://hoopscrm.com/privacy-policy/'
                                  rel='noopener noreferrer'
                                  target='_blank'
                                  color={'inherit'}
                                  underline={'always'}
                                > Privacy Policy</MuiLink>.
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant='body1'>
                                {'Already have an account? '}
                                <MuiLink component={Link}
                                  underline={'always'}
                                  to='/login'
                                  color={'primary'}
                                  variant={'body1'}
                                >
                                  {' Log in'}
                                </MuiLink>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Fragment>
                      );
                    case 'accept_invite':
                      return (
                        <Fragment>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography variant='caption'>
                                By clicking {formGroup.buttonLabel}, you agree to our <MuiLink
                                  href='https://hoopscrm.com/terms-service/'
                                  rel='noopener noreferrer'
                                  target='_blank'
                                  color={'inherit'}
                                  underline={'always'}
                                > Terms of Service</MuiLink> and <MuiLink
                                  href='https://hoopscrm.com/privacy-policy/'
                                  rel='noopener noreferrer'
                                  target='_blank'
                                  color={'inherit'}
                                  underline={'always'}
                                > Privacy Policy</MuiLink>.
                              </Typography>
                            </Grid>
                          </Grid>
                        </Fragment>
                      );
                    default:
                      return null;
                  }
                })()}
            </Grid>
          </Grid>
        </form>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    initialValues: state.authReducer.newUser,
    user: state.user,
  };
}

export const SignupForm = withStyles(styles)(
  withRouter(
    connect(mapStateToProps)(
      reduxForm({
        form: 'register',
        enableReinitialize: true
      })(_SignupForm))
  )
);
