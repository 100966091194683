import React, {createElement, isValidElement} from 'react';

export function Decorator({children}) {
  if (!children) {
    return null;
  }

  if (isValidElement(children)) {
    return children;
  }

  if (typeof children === 'string') {
    return <span className={'material-icons notranslate'}>{children}</span>;
  }

  return createElement(children);
}
