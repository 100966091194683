import getApiCredentials from '../constants/api';

const User = {};

User.loginRegister = (userData, uri) => {
  const reqUri = getApiCredentials.host + uri;
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify(userData)
  };
  const reqInstance = new Request(reqUri, requestOptions);
  return fetch(reqInstance)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return undefined;
    })
    .then((data) =>
      //Fetched user register data is stored
       data
    )
    .catch((err) => console.error(err.message, 'error111'));
};
User.logout = (token) => {
  let uri = getApiCredentials.host + '/api/auth/logout';
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Bearer ' + token
    }
  };
  const reqInstance = new Request(uri, requestOptions);
  return fetch(reqInstance)
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw response.json();
      }
    })
    .then((data) => data)
    .catch((err) => console.error(err, 'error'));
};
User.getUser = (token) => {
  let uri = getApiCredentials.host + '/api/auth/user';
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Bearer ' + token
    }
  };
  const reqInstance = new Request(uri, requestOptions);
  return fetch(reqInstance)
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw response.json();
      }
    })
    .then((data) => data)
    .catch((err) => console.error(err, 'error'));
};

export default User;
