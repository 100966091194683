import React from 'react';
import {Button, Grid, IconButton, Typography} from '@mui/material';
import {Delete as DeleteIcon, FileCopy as FileCopyIcon} from '@mui/icons-material';
import {useDispatch} from 'react-redux';
import copy from 'copy-to-clipboard';
import {IntegrationTile} from '../IntegrationTile';
import hoopsLogo from '../../../assets/images/Logo.svg';
import {createIntegration, deleteIntegration} from '../IntegrationActions';
import {hoopsTheme} from '../../../theme';
import {AppAccess, PermissionBasedContent} from '../../../componentsHoops/AccessControl';

const styles = {
  'button:has(>svg)': {
    padding: '0 0 2px 0',
    marginLeft: '11px'
  },
  'svg': {
    fontSize: '16px',
    color: hoopsTheme.colors.text.light,
  },
  '.grey-text': {color: hoopsTheme.colors.text.medium},
  '.api-key': {fontWeight: 500},
};

export const HoopsApiIntegrationTile = ({type, config}) => {
  const dispatch = useDispatch();

  const handleGenerateApiKey = () => {
    dispatch(createIntegration({type}));
  };

  const handleDeleteApiKey = () => {
    dispatch(deleteIntegration(config?._id));
  };

  return (
    <PermissionBasedContent allowedAppAccess={[AppAccess.ApiAccess]}>
      <IntegrationTile
        name={name}
        logo={hoopsLogo}
        disabled={false}
        ContentComponent={() =>
          <Grid sx={styles}
            container
            direction='column'
            justifyContent='space-between'
            alignItems='center'
          >
            <Grid item xs={12}>
              <Typography variant={'body2'} className={'grey-text'} align='center'>
                Connect with the Hoops API
              </Typography>
            </Grid>
          </Grid>
        }
        ActionComponent={() =>
          <Grid sx={styles}>
            {config?.apiKey == null &&
              <Button variant='contained' color='primary' onClick={() => handleGenerateApiKey()}>Generate API Key</Button>
            }
            {config?.apiKey != null &&
              <Grid container direction='row' justifyContent='center' alignItems='flex-start'>
                <Grid item xs={12}>
                  <Typography variant={'body2'} className={'grey-text'} align='center'>API Key</Typography>
                </Grid>
                <Grid item>
                  <Typography variant={'body2'} align='center' className='api-key'>{config?.apiKey}</Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={() => copy(config?.apiKey)}>
                    <FileCopyIcon/>
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton onClick={() => handleDeleteApiKey()}>
                    <DeleteIcon/>
                  </IconButton>
                </Grid>
              </Grid>
            }
          </Grid>
        }
        config={config}/>
    </PermissionBasedContent>
  );
};
