import {useQuery} from '@apollo/client';
import {gql} from 'apollo-boost';
import React, {useEffect, useState} from 'react';
import HoopsQueryFilterList from './HoopsQueryFilterList';

const GQL_VENDOR_FILTER = gql`
        query VendorManyForFilter {
            vendorMany(sort: NAME_ASC, limit:1000) {
                _id
                name
            }
        }
    `;

export const HoopsQueryFilterVendor = ({
    label = '',
    value = {},
    onChange = () => null,
}) => {
    const {data} = useQuery(GQL_VENDOR_FILTER);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (data && data.vendorMany) {
            const _options = data.vendorMany.map(
                (vendor) => ({value: vendor._id, label: vendor.name})
            );
            setOptions(_options);
        }
    }, [data]);

    return (
        <HoopsQueryFilterList label={label} options={options} value={value} onChange={onChange} />
    );
};

export default HoopsQueryFilterVendor;
