// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {createGraphQLClient} from './GraphQLService';
import {pick, omit} from 'lodash';
import {
  GQL_CREATE_VENDOR,
  GQL_DELETE_VENDOR,
  GQL_GET_VENDOR,
  GQL_VENDORS_PAGINATION,
  GQL_UPDATE_VENDOR,
  GQL_GET_VENDORS_FOR_JOB,
  GQL_GET_VENDORS_WITH_OPEN_PURCHASE_ORDERS,
  GQL_SEARCH_VENDORS
} from '../queries/vendors';
const client = createGraphQLClient({endpoint: process.env.REACT_APP_GRAPHQL_URI});

const parseVendorInput = (vendor) => {
  const {name, email} = vendor;
  let {ordersSite, addresses} = vendor;
  ordersSite = pick(ordersSite, ['website', 'username', 'password']);

  return {
    name,
    email,
    ordersSite,
    addresses: addresses.map((address) => omit(address, 'formattedAddress')),
  };
};

export class VendorService {
  constructor(vendor) {
    this.vendor = vendor;
  }

  static getVendorsWithOpenPurchaseOrders() {
    return client.query({query: GQL_GET_VENDORS_WITH_OPEN_PURCHASE_ORDERS,}).then(({data: {vendorHasOpenPurchaseOrders}}) => vendorHasOpenPurchaseOrders);
  }

  static getVendorsForJob(jobId) {
    return client.query({
      query: GQL_GET_VENDORS_FOR_JOB,
      variables: {jobId}
    }).then(({data: {vendorsForJob}}) => vendorsForJob);
  }

  static getVendors(options) {
    return client.query({
      query: GQL_VENDORS_PAGINATION,
      fetchPolicy: 'no-cache',
      variables: options
    })
      .then(({data: {vendorPagination}}) => vendorPagination);

  }

  static getVendor(_id) {
    return client.query({
      query: GQL_GET_VENDOR,
      variables: {_id},
      fetchPolicy: 'no-cache'
    })
      .then(({data: {vendorOne}}) => vendorOne);
  }

  static createVendor(data) {
    return client.mutate({
      mutation: GQL_CREATE_VENDOR,
      variables: {
        record: parseVendorInput(data),
        contacts: data.contacts.map((contact) => omit(contact, 'fullName')),
      }
    })
      .then((data) => data)
      .then(({data: {createVendor}}) => createVendor);
  }

  static search(searchQuery) {
    return client.query({
      query: GQL_SEARCH_VENDORS,
      variables: {searchQuery},
      fetchPolicy: 'no-cache'
    })
    .then(({data: {searchVendors: vendors}}) => vendors);
  }

  delete() {
    return client.mutate({
      mutation: GQL_DELETE_VENDOR,
      variables: {_id: this.vendor._id}
    })
      .then(({data: {deleteVendor}}) => deleteVendor);

  }

  save() {
    if (this.vendor._id) {
      return client.mutate({
        mutation: GQL_UPDATE_VENDOR,
        variables: {
          _id: this.vendor._id,
          record: parseVendorInput(this.vendor),
          contacts: this.vendor.contacts.map((contact) => omit(contact, 'fullName')),
        }
      })
        .then(({data: {vendorUpdateById}}) => vendorUpdateById);
    }

    return VendorService.createVendor(this.vendor);
  }
}
