import {Button, Grid, Link, MuiThemeProvider, Paper, Typography} from '@material-ui/core';
import csv from 'csvtojson';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Link as RouterLink, useHistory} from 'react-router-dom';
import {customerImport} from '../../../actions/customer';
import green from '../../../ui/theme/green';
import SingleFile from '../../catalog/catalogImport/SingleFile';
import {EntityListHero} from '../../EntityList/EntityListHero';
import HoopsUpload from '../../shared/HoopsUpload';
import {CustomerImportDialog} from './CustomerImportDialog';
import customerImportTemplate from '../../../assets/Customer Import Template.csv';

export const CustomerImport = () => {
    let history = useHistory();

    const [csvFile, setCsvFile] = useState(null);
    const [content, setContent] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const dispatch = useDispatch();

    const handleUploadComplete = async (hoopsFile) => {
        const {file} = hoopsFile;
        const reader = new FileReader();

        reader.readAsText(file, 'utf8');
        reader.onloadend = () => {
            const csvString = reader.result;
            // let headerRow = [];
            csv()
                // .on('header', (header) => {
                //     headerRow = header;
                // })
                .fromString(csvString).then(async (data) => {
                    setContent({
                        data,
                        lines: data.length
                    });
                    setCsvFile(hoopsFile);
                });
        };

    };

    const handleRemoveCsvFile = () => {
        setContent(null);
        setCsvFile(null);
    };

    const handleImport = () => {
        setIsDialogOpen(true);
        dispatch(customerImport(csvFile._id));
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
        history.push('/customers');
    };

    return (
        <Grid container>
            <CustomerImportDialog open={isDialogOpen} onClose={handleDialogClose} />
            <EntityListHero
                title='Customer Import'
                subtitle='Quickly &amp; easily import your customers in bulk.'
                helpArticleUrl='http://help.hoopscrm.com/en/articles/4736254-import-customers'
                videoId='1cN8pDsaeKo'
            >
            </EntityListHero>
            <Grid item xs={12} >

                <Paper>
                    <Grid container direction='column'
                        justifyContent='center'
                        alignItems='center'
                    >
                        <Grid style={{width: '603px'}}>
                            <br />
                            <br />
                            <br />
                            <Typography variant='h1'>Step 1</Typography>
                            <Typography variant='subtitle1'>Download your <Link href={customerImportTemplate} target='_blank'>template file</Link> and add your customer data</Typography>
                            <br />
                            <br />
                            <Typography variant='h1'>Step 2</Typography>
                            <Typography variant='subtitle1'>Upload the completed CSV file</Typography>

                            {csvFile && content
                                ? <><br /><SingleFile file={csvFile} onRemove={handleRemoveCsvFile} content={content} /></>
                                : <HoopsUpload accept={['.csv']} maxSizeMb='10' onUploadComplete={handleUploadComplete} />
                            }

                            <br />
                            <Typography variant='body2'>If we find any contact email address within your CSV that already exists in Hoops, we&apos;ll update the Hoops information to match your CSV</Typography>
                            <br />
                            <br />
                            <Grid container alignContent='space-between'>
                                <Grid >
                                    <Link
                                        component={RouterLink}
                                        to='/customers'
                                        underline='none'
                                    >
                                        <Button color='secondary'>Cancel</Button>
                                    </Link>
                                </Grid>
                                <Grid>
                                    {csvFile && content
                                        ? <MuiThemeProvider theme={green}>
                                            <Button color='primary' variant='contained' onClick={handleImport}>Import</Button>
                                        </MuiThemeProvider>
                                        : null
                                    }
                                </Grid>
                            </Grid>
                            <br />
                            <br />
                        </Grid>
                    </Grid>
                </Paper>

            </Grid>
        </Grid>
    );
};
