// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React from 'react';
import {Grid, Typography, withStyles} from '@material-ui/core';
import {connect} from 'react-redux';

const styles = (theme) => ({grey: {color: theme.colors.grey.main}});

class IndividualAddress extends React.Component {

  render() {

    const {classes, address} = this.props;
    return (
      <Grid item container xs={12} >
        <Grid item container xs={12} direction={'column'}>
          <Grid item>
            {address.label
              ? <Typography className={classes.grey}>{address.label}</Typography>
              : <Typography className={classes.grey}>Address</Typography>}
          </Grid>
          <Grid item>
            <Typography>{address && address.formattedAddress}</Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {customer: state.customerReducer.customer};
}

IndividualAddress = connect(mapStateToProps)(IndividualAddress);

export default withStyles(styles)(IndividualAddress);
