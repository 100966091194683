import * as React from 'react';
import {AttentionConfirmDialog} from '../../../componentsLib/Popovers';

export const DeleteViewConfirmation = ({onClose, name}) => (
    <AttentionConfirmDialog
      onClose={onClose}
      okText={'Delete'}
      cancelText={'Cancel'}
      content={`Are you sure you want to delete the '${name}' view? This action cannot be undone.`}
    />
  );
