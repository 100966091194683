import React, {Fragment} from 'react';
import {IconButton, ListItemIcon, MenuItem} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import Menu from '@material-ui/core/Menu';
import PropTypes from 'prop-types';
import DeleteUserDialog from './DeleteUserDialog';
import {UserEditModal} from './UserEditModal';

class UserActionsMenu extends React.Component {

  state = {
    anchorEl: null,
    deleteOpen: false,
    editUserOpen: false

  };

  handleClick = (event) => {
    this.setState({anchorEl: event.currentTarget});
  };

  handleClose = () => {
    this.setState({anchorEl: null});
  };

  openUserEdit = () => {
    this.setState({
      ...this.state,
      editUserOpen: true
    });
  };

  closeUserEdit = () => {
    this.setState({
      ...this.state,
      editUserOpen: false
    });
  };

  openConfirmDelete = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
      deleteOpen: true
    });
  };

  handleDeleteClose = () => {
    this.setState({
      ...this.state,
      deleteOpen: false
    });
  };

  render() {
    const {anchorEl, deleteOpen, editUserOpen} = this.state;
    const {user} = this.props;

    return (
      <Fragment>
        {user.type !== 'admin' &&
          <>
            <IconButton onClick={this.handleClick} data-intercom-target={'user-table-actions'}>
              <Icon>more_horiz</Icon>
            </IconButton>
            <Menu anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleClose}>
              <MenuItem onClick={this.openUserEdit}>
                <ListItemIcon>
                  <Icon>edit</Icon>
                </ListItemIcon>
                Edit User
              </MenuItem>
              <MenuItem onClick={this.openConfirmDelete}>
                <ListItemIcon>
                  <Icon>delete</Icon>
                </ListItemIcon>
                Delete
              </MenuItem>
            </Menu>
          </>
        }
        <DeleteUserDialog open={deleteOpen} onClose={this.handleDeleteClose} user={user} />
        <UserEditModal open={editUserOpen} user={user} onClose={this.closeUserEdit} roleSelector={true} title='Edit User' />
      </Fragment>
    );
  }
}

export default UserActionsMenu;

UserActionsMenu.propTypes = {user: PropTypes.any.isRequired};
