// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import {makeStyles} from '@material-ui/core/styles';
import {useQuery} from '@apollo/client';
import gql from 'graphql-tag';
import React, {useState} from 'react';
import {Field} from 'redux-form';
import ProductAutocomplete from './ProductAutocomplete';
import ProductTypeSelect from './ProductTypeSelect';
import SageProductSelect from './SageProductSelect';
import productAutocompleteStyles from './productAutocompleteStyles';

const useStyles = makeStyles((theme) => ({...productAutocompleteStyles}));

// is user integrated with stripe
const SAGE_INTEGRATION_ACTIVE = gql`
query integrationOne{
  integrationOne(filter:{type:sage}){
      _id
      status
  }
}`;

export const ProductField = ({
  handleProductSelected = () => null,
  field,
}) => {

  const classes = useStyles();

  // set product type
  const [productType, setProductType] = useState('hoops');
  const [isSageActive, setIsSageActivee] = useState(false);

  // check for sage integration
  useQuery(SAGE_INTEGRATION_ACTIVE, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (result) => setIsSageActivee(result && result.integrationOne && result.integrationOne.status === 'active'),
  });

  const handleProductTypeChange = (e) => {
    setProductType(e.target.value);
  };

  return (
    <div style={{display: 'flex'}}>

      {productType &&
        <>
          {(isSageActive) &&
            <ProductTypeSelect
              isSageActive={isSageActive}
              onProductTypeChange={handleProductTypeChange}
              productType={productType}
            />
          }

          {productType === 'hoops' &&
            <Field
              isSageActive={isSageActive}
              name={`${field}.product`}
              field={field}
              component={ProductAutocomplete}
              onSelect={handleProductSelected}
              style={{border: 5}}
            />
          }

          {productType === 'sage' &&
            <Field
              name={`${field}.product`}
              component={SageProductSelect}
              onSelect={handleProductSelected}
              className={classes.rightPanelBorder}
            />
          }
        </>
      }

    </div>
  );
};

export default ProductField;
