import ToggleButtonGroup  from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import {makeStyles} from '@material-ui/core';
import React from 'react';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';

const useStyles = makeStyles((theme) => ({
    selected: {
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: `${theme.colors.white} !important`,
    },
    notSelected: {backgroundColor: theme.colors.white,},
    toggleGroup: {
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(1),
    }
}));

export const HoopsQueryViewButton = ({
    view = '',
    onViewChange = () => null
}) => {
    const classes = useStyles();
    const handleViewChange = (e, newView) => {
        onViewChange(newView);
    };

    return (
        <ToggleButtonGroup value={view} exclusive onChange={handleViewChange} size='small' className={classes.toggleGroup}>
            <ToggleButton value='kanban' className={view === 'kanban' ? classes.selected : classes.notSelected}><ViewColumnIcon /></ToggleButton>
            <ToggleButton value='table' className={view === 'table' ? classes.selected : classes.notSelected}><ViewHeadlineIcon /></ToggleButton>
        </ToggleButtonGroup>
    );
};

export default HoopsQueryViewButton;
