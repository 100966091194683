import React from 'react';
import {registerGlobalStyle} from '../../theme';
import {HoopsPropTypes} from '../utils';
import classNames from 'classnames';

window.CSS.registerProperty?.({
  name: '--progress',
  syntax: '<number>',
  inherits: true,
  initialValue: 1,
});

registerGlobalStyle('.progress-ring', (theme) => ({
  '--progress': '20',
  '--thickness': '22px',
  '--color': theme.colors.palette.blue,
  '--size': '150px',

  width: 'var(--size)',
  height: 'var(--size)',
  aspectRatio: '1',
  position: 'relative',
  display: 'inline-grid',
  margin: 5,
  placeContent: 'center',
  fontSize: 25,
  fontWeight: 'bold',
  fontFamily: theme.typography.fontFamily,
  animation: 'progress-ring .25s 0s both',

  '&:before, &:after': {
  content: '""',
  position: 'absolute',
  borderRadius: '50%',
  },
  '&:before': {
    inset: 0,
    background: 'radial-gradient(farthest-side, var(--color) 98%, #0000) top/var(--thickness) var(--thickness) no-repeat, conic-gradient(var(--color) calc(var(--progress) * 1%), #0000 0)',
    mask: 'radial-gradient(farthest-side, #0000 calc(99% - var(--thickness)), #000 calc(100% - var(--thickness)))',
  },
  '&:after': {
    inset: 'calc(50% - var(--thickness) / 2)',
    background: 'var(--color)',
    transform: 'rotate(calc(var(--progress) * 3.6deg)) translateY(calc(50% - var(--size) / 2))',
  },
  '&.flat': {
    '&:before': {backgroundSize: '0 0, auto',},
    '&:after': {content: 'none',},
  },
}));

registerGlobalStyle('@keyframes progress-ring', () => ({from: {'--progress': 0}}));

export function ProgressRing({flat, progress}) {
  return <div className={classNames(['progress-ring', flat && 'flat'])} style={{'--progress': progress}}/>;
}

ProgressRing.propTypes = {
  flat: HoopsPropTypes.bool,
  progress: HoopsPropTypes.stringOrNumber
};
