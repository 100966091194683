// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import React, {useEffect, useState, useCallback} from 'react';
import {useSelector} from 'react-redux';
import Intercom, {IntercomAPI} from 'react-intercom';
import {useLocation} from 'react-router-dom';

function IntercomLauncher() {
  const userSelector = (state) => state.authReducer.userData;
  const intercomAttributesSelector = (state) => state.authReducer.intercomAttributes;
  const user = useSelector(userSelector);
  const intercomAttributes = useSelector(intercomAttributesSelector);
  const location = useLocation();

  const intercomOptions = () => (
      {
        email: user?.username,
        name: `${user?.firstName} ${user.lastName}`,
        user_id: `${user?._id}`,
        company_id: `${user?.companyId}`,
        ...intercomAttributes,
      }
    );

  IntercomAPI('boot', intercomOptions);

  return (
    <Intercom appID={process.env.REACT_APP_INTERCOM_ID} {...intercomOptions()} />
  );
}

export default IntercomLauncher;
