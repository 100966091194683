// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React, {Component} from 'react';
import {Button, Dialog, DialogContent, Typography, Grid, Icon} from '@material-ui/core';
import DialogTitle from '../DialogTitle';
import DialogActions from '../DialogActions';
import {connect} from 'react-redux';
import {closeJobDeleteModal as _closeJobDeleteModal} from '../../../actions/modals';
import {deleteJob as _deleteJob} from '../../../actions/jobs';

class JobDeleteModal extends Component {

  closeJobDeleteModal = () => {
    const {closeModal} = this.props;
    closeModal();
  };

  deleteJob = () => {
    const {closeModal, deleteJob, context} = this.props;
    deleteJob(context.jobId);
    if (context.onDelete) {
      context.onDelete();
    }
    closeModal();
  };

  render() {
    const {isOpen} = this.props;
    return (
      <Dialog
        open={isOpen}
        maxWidth={'sm'}
        fullWidth={true}
        onClose={this.closeJobDeleteModal}
      >
        <DialogTitle onClick={this.closeJobDeleteModal}>
          <Typography variant='h5'>Delete Job</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography>Are you sure you wish to delete this Job?</Typography>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent={'space-between'}>
            <Grid item>
              <Button onClick={this.closeJobDeleteModal}>
                <Icon>close</Icon>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={this.deleteJob}
                  >
                    Delete Job</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => dispatch(_closeJobDeleteModal()),
    deleteJob: (_id) => dispatch(_deleteJob(_id)),
  };
}

function mapStateToProps(state) {
  return {
    isOpen: state.modalReducer.jobDeleteModal.isOpen,
    context: state.modalReducer.jobDeleteModal.context
  };
}

JobDeleteModal = connect(mapStateToProps, mapDispatchToProps)(JobDeleteModal);
export default JobDeleteModal;
