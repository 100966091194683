// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React, {Component} from 'react';
import {Button, Dialog, DialogContent, Grid, Typography,} from '@material-ui/core';
import DialogTitle from '../../DialogTitle';
import {connect} from 'react-redux';
import {closeTagModal as _closeTagModal} from '../../../../actions/modals';
import {Field, Form} from 'react-final-form';
import {MultipleAutocompleteField} from '../../formHelpers/fields';
import {autocompleteRequired} from '../../formHelpers/validation';
import DialogActions from '../../DialogActions';
import {updateProduct as _updateProduct} from '../../../../actions/catalog';
import {get} from 'lodash';
import {getCompany as _getCompany} from '../../../../actions/action-types/company';

class TagModal extends Component {

  componentDidMount() {
    const {getCompany} = this.props;
    getCompany();
  }

  closeModal = () => {
    const {closeModal} = this.props;
    closeModal();
  };

  handleSubmit = (values) => {
    const {updateProduct, getCompany, context, closeModal} = this.props;
    updateProduct(context._id, values);
    getCompany();
    closeModal();
  };

  render() {
    const {isOpen, context, company} = this.props;
    return (
      <Dialog
        open={isOpen}
        maxWidth={'sm'}
        fullWidth={true}
        onClose={this.closeModal}
      >
        <DialogTitle onClick={this.closeModal}>
          <Typography variant='h5'>Add Tags</Typography>
        </DialogTitle>
        <Form
          onSubmit={this.handleSubmit}
          initialValues={{tags: get(context, 'tags', [])}}
          render={({handleSubmit}) => (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container style={{overflow: 'hidden'}}>
                  <Grid item container spacing={2}>
                    <Grid item container xs={12} spacing={2}>
                      <Grid item xs={10}>
                        <Field
                          label='Name *'
                          name={'tags'}
                          component={MultipleAutocompleteField}
                          validate={autocompleteRequired}
                          helperText={'Type a tag name and press tab or select from existing tags'}
                          options={get(company, 'Tags', [])}
                          fullWidth
                          freeSolo
                          disableCloseOnSelect
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Grid container justifyContent={'space-between'}>
                  <Grid item>
                    <Button onClick={this.closeModal}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={2}>
                      <Grid item>
                        <Button
                          variant={'contained'}
                          color={'primary'}
                          type='submit'>Save</Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => dispatch(_closeTagModal()),
    updateProduct: (_id, data) => dispatch(_updateProduct(_id, data)),
    getCompany: () => dispatch(_getCompany())
  };
}

function mapStateToProps(state) {
  return {
    isOpen: state.modalReducer.tagModal.isOpen,
    context: state.modalReducer.tagModal.context,
    company: state.companyReducer.company
  };
}

TagModal = connect(mapStateToProps, mapDispatchToProps)(TagModal);
export default TagModal;
