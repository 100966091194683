import {gql, useQuery} from '@apollo/client';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  MuiThemeProvider,
  Select,
  Typography
} from '@material-ui/core';
import React, {useState} from 'react';
import theme from '../../../ui/theme/index';
import DialogActions from '../DialogActions';
import DialogTitle from '../DialogTitle';

const GET_DECORATORS = gql`
    query decorators($jobId: String!){
        decorators(jobId: $jobId){
            _id
            name
        }
    }
`;
export const DecoratorSpecSheetModal = ({jobId, handleClose}) => {
  const [selected, setSelected] = useState('');

  const onSubmit = () => {
    window.open(`${process.env.REACT_APP_BASE_URL}/jobs/${jobId}/decorator/${selected}/pdf`, '_blank');
    handleClose();
  };
  const {data, loading} = useQuery(GET_DECORATORS, {variables: {jobId}});
  return (
    <Dialog open={true} onClose={handleClose} maxWidth='sm' fullWidth>
      {<>
        <DialogTitle onClick={handleClose}>
          <Typography variant='h5'>Create Decorator Spec Sheet</Typography>
        </DialogTitle>
        <DialogContent>
          {!loading && <>
            {(data && data.decorators.length ?
              <FormControl fullWidth>
                <InputLabel>Select Vendor</InputLabel>
                <Select input={<Input/>}
                        value={selected}
                        onChange={(e) => setSelected(e.target.value)}
                >
                  {data.decorators.map((vendor, index) => (
                    vendor && <MenuItem value={vendor._id} key={index}>{vendor.name}</MenuItem>
                  ))}
                </Select>
              </FormControl> :
              <>
                <Typography variant={'h6'}>No linked decorations</Typography>
                <Typography variant={'body1'} paragraph>
                  There are no decorations linked with a vendor that are also linked with a product on this
                  job.<br/><br/>
                  <b>To correct this</b>: Edit the job and ensure that the line items have been created using the Item Builder,
                  that the products & decorations are within the same item builder. Also check that the decorations are
                  linked with a vendor.
                </Typography>
              </>)}
          </>}

          {loading && <CircularProgress/>}

        </DialogContent>
        <DialogActions>
          <Grid container justifyContent={'space-between'}>
            <Grid item>
              <Button variant={'text'} color={'secondary'} onClick={handleClose}>Cancel</Button>
            </Grid>
            <Grid item>
              <MuiThemeProvider theme={theme}>
                <Button
                  variant={'contained'}
                  color={'primary'}
                  type={'button'}
                  disabled={loading || !selected}
                  onClick={() => onSubmit()}
                >CREATE</Button>
              </MuiThemeProvider>
            </Grid>
          </Grid>
        </DialogActions>
      </>}
    </Dialog>
  );
};
