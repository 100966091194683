import {client} from './GraphQLService';

export default class hoopsQueryService {
  static kanbanPagination(gqlQuery, filter, sort, perPage, page) {
    return client.query({
      query: gqlQuery,
      variables: {
        filter: filter,
        sort: sort,
        perPage: perPage,
        page: page,
      }
    }).then(({data: job}) => job);
  }
}
