import {startCase} from 'lodash';

export const categories = {
  job: 'job',
  quote: 'quote',
  proof: 'proof',
  automationQuote: 'automationQuote',
  automationJob: 'automationJob'
};

export const shortcodesQuote = [{
  name: 'company',
  title: 'Your Company',
  shortCodes: ['name', 'phone', 'website', 'currencySymbol']
}, {
  name: 'user',
  title: 'User',
  shortCodes: ['firstName', 'lastName', 'fullName', ['username', 'email']],
}, {
  name: 'quote',
  title: 'Document',
  shortCodes: ['number', 'total', 'subTotal', ['docTypeName', 'Document Type'], 'linkGuest', 'customReference', 'billTo', 'shipTo'],
}, {
  name: 'contact',
  title: 'Customer',
  shortCodes: ['firstName', 'lastName', 'fullName', ['email', 'Contact Email']],
}, {
  name: 'customer',
  title: 'Customer',
  shortCodes: [['name', 'Company Name'], ['email', 'Company Email']],
}];

export const shortcodesJob = [{
  name: 'company',
  shortCodes: ['name', 'phone', 'website',]
}, {
  name: 'user',
  shortCodes: ['firstName', 'lastName', 'fullName', ['username', 'email'],]
}, {
  name: 'job',
  shortCodes: ['linkProof', 'shippingInstructions', 'trackingNumbers', 'customReference', 'billTo', 'shipTo'],
}, {
  name: 'contact',
  shortCodes: ['firstName', 'lastName', 'fullName', 'email',]
}, {
  name: 'customer',
  shortCodes: ['name', 'email',],
}];

export const shortcodesProof = [{
  name: 'company',
  shortCodes: ['name', 'phone', 'website',]
}, {
  name: 'user',
  shortCodes: ['firstName', 'lastName', 'fullName', ['username', 'email'],]
}, {
  name: 'job',
  shortCodes: ['linkProof'],
}, {
  name: 'contact',
  shortCodes: ['firstName', 'lastName', 'fullName', 'email',]
}, {
  name: 'customer',
  shortCodes: ['name', 'email',]
}];

export const shortcodesAutomation = [{
  name: 'company',
  shortCodes: ['name', 'phone', 'website', 'currencySymbol']
}, {
  name: 'user',
  shortCodes: ['firstName', 'lastName', 'fullName', ['username', 'email']],
}, {
  name: 'contact',
  shortCodes: ['firstName', 'lastName', 'fullName', 'email'],
}, {
  name: 'customer',
  shortCodes: ['name', 'email'],
}, {
  name: 'status',
  shortCodes: ['previous', 'current'],
}];

export const shortcodesAutomationJob = [{
  name: 'job',
  shortCodes: [
    'number', 'createdBy.fullName', 'jobOwner.fullName', 'deadlineAt', 'title', 'linkHoops', 'linkProof', 'shippingInstructions', 'trackingNumbers'
  ],
},
  ...shortcodesAutomation,
];

export const shortcodesAutomationQuote = [{
  name: 'quote',
  shortCodes: ['number', 'createdBy.fullName', 'quoteOwner.fullName', 'deadlineAt', 'linkGuest'],
},
  ...shortcodesAutomation,
];

export const SUGGESTION_TRIGGER = '{{';
export const SUGGESTION_TRIGGER_END = '}}';

export function getShortCodes(entity, newFormat = false) {
  const {job, quote, proof, automationJob, automationQuote} = categories;
  let shortCodeArray;
  switch (true) {
    case (entity === quote):
      shortCodeArray = (shortcodesQuote);
      break;
    case (entity === job):
      shortCodeArray = (shortcodesJob);
      break;
    case (entity === proof):
      shortCodeArray = (shortcodesProof);
      break;
    case (entity === automationJob):
      shortCodeArray = (shortcodesAutomationJob);
      break;
    case (entity === automationQuote):
      shortCodeArray = (shortcodesAutomationQuote);
      break;
    default:
      shortCodeArray = (shortcodesQuote);
      break;
  }
  if (newFormat) {
    const mapped = shortCodeArray.reduce((codes, shortCodeCategory) => {
      shortCodeCategory.shortCodes.forEach((shortCode) => {
        if (!codes[shortCodeCategory.title]) {
          codes[shortCodeCategory.title] = [];
        }
        codes[shortCodeCategory.title].push({
          entity: shortCodeCategory.name,
          title: shortCodeCategory.title,
          ...(typeof shortCode === 'string' ? {field: shortCode, alias: shortCode} : {field: shortCode[0], alias: shortCode[1]}),
        });
      });
      return codes;
    }, {});
    return Object.keys(mapped).map((key) => ({
      title: key,
      shortCodes: mapped[key],
    }));
  } else {
    return shortCodeArray.map((shortCodeItem) => ({
      name: shortCodeItem.name,
      shortCodes: shortCodeItem.shortCodes.map((code) => typeof code === 'string' ? code : code[0]),
    }));
  }
}

export function getShortCodesWithAliases(entity) {
  return getShortCodes(entity, true);
}

export function formatShortCode(code) {
  return `${SUGGESTION_TRIGGER}${code.entity}.${code.field}${SUGGESTION_TRIGGER_END}`;
}

export function sentenceShortCode(code) {
  return startCase(code.alias);
}
