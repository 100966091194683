import {gql} from 'apollo-boost';

export const GQL_SCHEDULE_DETAIL_JOB_FRAGMENT = gql`
    fragment ScheduleDetailJobFragment on Job {
        _id
        number
        title
        customer {
            name
        }
        createdById

        createdBy {
            _id
            fullName
        }

        jobOwnerId

        jobOwner {
            _id
            fullName
        }

        items {
            _id
            imageUrl
            description
            quantity
            status
            product {
                _id
                code
                title
                vendorId
            colors {
                _id
                name
                image {
                _id
                contentLength
                contentType
                bucket
                key
                filename
                url
                }
            }
            sizes {
                _id
                name
                description
            }
            variants {
                _id
                sku
                color {
                    _id
                    name
                }
                size {
                    _id
                    name
                    description
                }
                priceBreaks {
                    _id
                    price
                    quantity
                }
            }
            vendor {
                _id
                name
            }
        }
        jobItemVariant {
            _id
            quantity
            variants {
                _id
                productCode
                productName
                colour
                size
                quantity
                status
                vendor {
                _id
                    name
                }
                vendorId
                productVariant {
                    _id
                    size {
                        _id
                        name
                    }
                    color {
                        _id
                        name
                    }
                }
            }
            decorations {
                _id
                description
                decoration {
                    name
                }
                position
                colour
                size
                quantity
                vendor {
                    name
                }
            }
            additionalItems {
                _id
                description
                vendor {
                    name
                }
            }
        }
    }

    proofs {
        _id
        jobId
        status
        createdAt
        products {
            description
            quantity
            jobItemVariant {
                variants {
                    colour
                    size
                    quantity
                }
            }
        }
        file {
            filename
            url
        }
        createdBy {
            fullName
        }
    }
    departmentalStatuses {
        accountStatus
        purchasingStatus
        artworkStatus
        productionStatus
        shippingStatus
        }
    }
`;

export const GQL_SCHEDULE_LIST_PAGINATED_FRAGMENT = gql`
    fragment ScheduleListFragmentPaginated on SchedulePagination {
        items {
            _id
            title
            description
            status
            startAt
            endAt
            resourceId
            isDone
        }
    }
`;

export const GQL_SCHEDULE_LIST_FRAGMENT = gql`
    fragment ScheduleListFragment on Schedule {
        _id
        title
        description
        status
        startAt
        endAt
        createdBy {
            _id
            fullName
        }
        resourceId
    }
`;

export const GQL_SCHEDULE_DETAIL_FRAGMENT = gql`
    ${GQL_SCHEDULE_DETAIL_JOB_FRAGMENT}
    fragment ScheduleDetailFragment on Schedule {
        _id
        title
        description
        status
        startAt
        endAt
        createdBy {
            _id
            fullName
        }

        jobId
        resourceId
        resource {
            title
        }
        jobChildMappingIds
        jobId
        job {
            ...ScheduleDetailJobFragment
        }
    }
`;

export const GQL_GET_SCHEDULE = gql`
    ${GQL_SCHEDULE_DETAIL_FRAGMENT}
    query getSchedule($_id: MongoID!) {
        scheduleById(_id: $_id) {
            ...ScheduleDetailFragment
        }
    }
`;

export const GQL_GET_SCHEDULE_JOB = gql`
    ${GQL_SCHEDULE_DETAIL_JOB_FRAGMENT}
    query getScheduleJob($_id: String!) {
        job(_id: $_id) {
            ...ScheduleDetailJobFragment
        }
    }
`;

export const GQL_GET_SCHEDULES = gql`
    ${GQL_SCHEDULE_LIST_FRAGMENT}
    query getSchedules(
        $scheduleFilter: FilterFindManyScheduleInput,
        $scheduleSort: SortFindManyScheduleInput,
        $taskFilter: FilterFindManyTaskInput,
        $taskSort: SortFindManyTaskInput,
        ) {
            scheduleMany(
                filter: $scheduleFilter,
                sort: $scheduleSort,
                limit: 1000
            ) {
                ...ScheduleListFragment
            }
            taskMany(
                filter: $taskFilter,
                sort: $taskSort,
                limit: 1000
            ) {
                _id
                title

                status
                dueAt
                endAt
                allDay
                resourceId

                isDone
            }
        }
`;

export const GQL_CREATE_SCHEDULE = gql`
    ${GQL_SCHEDULE_DETAIL_FRAGMENT}
    mutation createSchedule($record: CreateOneScheduleInput!) {
        scheduleCreateOne(record: $record) {
            recordId
            record {
                ...ScheduleDetailFragment
            }
        }
    }
`;

export const GQL_UPDATE_SCHEDULE = gql`
    ${GQL_SCHEDULE_DETAIL_FRAGMENT}
    mutation updateschedule($_id: MongoID!, $record: UpdateByIdScheduleInput!) {
        scheduleUpdateById(_id: $_id, record: $record) {
            recordId
            record {
                ...ScheduleDetailFragment
            }
        }
    }
`;

export const GQL_DELETE_SCHEDULE = gql`
    mutation deleteSchedule($_id: MongoID!) {
        scheduleRemoveById(_id: $_id) {
            recordId
        }
    }
`;
