// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import {FormControl, FormHelperText, makeStyles, Typography} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import EditorField from '../../componentsOld/companies/editor';
import EmailTemplateService from '../../servicesOld/EmailTemplateService';
import {HoopsTextField} from '../../componentsOld/HoopsTextField';
import {useMutation} from '@apollo/client';
import {GQL_SEND_PURCHASE_ORDER_LINK} from '../../queries/purchaseOrders';
import {snackError, snackSuccess} from '../../actions/action-types';
import {hoopsQuerySetRefetch} from '../../actions/hoopsQuery';

const useStyles = makeStyles((theme) => ({greyText: {color: theme.colors.grey.main}}));

export const SendPurchaseOrderForm = ({
  sendFunction = () => null,
  setSendOpen = () => null,
  setIsValid = () => null,
  setIsLoading = () => null,
  purchaseOrder = {}
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userSelector = (state) => state.authReducer.userData;
  const user = useSelector(userSelector);

  const [touched, setTouched] = useState(false);
  const [errors, setErrors] = useState({});
  const [emailOptions, setEmailOptions] = useState({
    to: '',
    cc: '',
    bcc: '',
    replyTo: user.username,
    subject: `Purchase Order ${purchaseOrder.number} From ${user.company.companyTradingEntities?.[0]?.name ?? ''}`,
    body: '<p>Hi,</p><p>A link to your purchase order is below.<br/>Any questions, please let us know.</p><p>Kind regards,<br/>{{LoggedINUserFirstName}}</p><p>{{POLinkButton}}</p>',
  });

  useEffect(() => {
    touched && setIsValid(!Object.keys(errors).length > 0);
  }, [errors, setIsValid, touched]);

  const [handleSendPurchaseOrder] = useMutation(GQL_SEND_PURCHASE_ORDER_LINK, {
    fetchPolicy: 'no-cache',
    variables: {
      emailOptions: emailOptions,
      _id: purchaseOrder._id
    },
    onError: (error) => {
      if (error) {
        dispatch(snackError('Error Sending Email. Please carefully check your email template and short codes.'));
      }
    },
    onCompleted: (result) => {
      if (result) {
        setIsLoading(false);
        setSendOpen(false);
        dispatch(snackSuccess(`Purchase Order number #${purchaseOrder.number} has been sent`));
        dispatch(hoopsQuerySetRefetch('purchaseOrder', true));
      }
    },
  });

  useEffect(() => {
    const handleSend = () => {
      setIsLoading(true);
      handleSendPurchaseOrder();
    };
    sendFunction.current = handleSend;
  }, [sendFunction, handleSendPurchaseOrder, setIsLoading]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const type = e.target.type;
    const validEmail = type === 'email' && EmailTemplateService.validateEmail(value);
    setTouched(true);

    setEmailOptions((prev) => ({...prev, [name]: value}));

    //clearing validation errors
    if ((value !== '' || value)) {
      if (type === 'email' && !validEmail) {return;}
      setErrors((prev) => {
        delete prev[name];
        return {...prev};
      });
      return;
    }
  };

  const handleValidate = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const type = e.target.type;
    const required = e.target.required;
    const validEmail = type === 'email' && EmailTemplateService.validateEmail(value);
    if ((value === '' || !value) && required) {
      setErrors((prev) => ({...prev, [name]: 'This field is required'}));
      return;
    }
    if (type === 'email' && !validEmail && (value !== '' || value)) {
      setErrors((prev) => ({...prev, [name]: 'This email address is invalid'}));
      return;
    }
    setErrors((prev) => {
      delete prev[name];
      return {...prev};
    });
  };

  const handleBodyChange = (value) => {
    setEmailOptions((prev) => ({...prev, body: value}));
    if (value === '' || !value || value === '<p></p>\n') {
      setErrors((prev) => ({...prev, body: true}));
    } else {
      setErrors((prev) => {
        delete prev.body;
        return {...prev};
      });
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item sm={12} md={5}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <HoopsTextField
                name={'to'}
                label={'To:'}
                type={'email'}
                defaultValue={emailOptions.to}
                onChange={handleChange}
                onBlur={handleValidate}
                required
                autoFocus
                error={errors.to}
                helperText={errors.to && errors.to}
              />
            </Grid>
            <Grid item xs={12}>
              <HoopsTextField
                name={'cc'}
                label={'CC:'}
                type={'email'}
                defaultValue={emailOptions.cc}
                onChange={handleChange}
                onBlur={handleValidate}
                error={errors.cc}
                helperText={errors.cc && errors.cc}
              />
            </Grid>
            <Grid item xs={12}>
              <HoopsTextField
                name={'replyTo'}
                label={'Reply To:'}
                type={'email'}
                defaultValue={emailOptions.replyTo}
                onChange={handleChange}
                onBlur={handleValidate}
                required
                error={errors.replyTo}
                helperText={errors.replyTo && errors.replyTo}
              />
            </Grid>
            <Grid item xs={12}>
              <HoopsTextField
                name={'subject'}
                label={'Subject:'}
                defaultValue={emailOptions.subject}
                onChange={handleChange}
                onBlur={handleValidate}
                required
                error={errors.subject}
                helperText={errors.subject && errors.subject}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} md={7}>
          <Typography variant='caption' className={classes.greyText}>Body</Typography>
          <FormControl fullWidth error={errors.body} >
            <EditorField
              input={{
                value: emailOptions.body,
                onChange: handleBodyChange
              }}
              meta={true}
              error={errors.body}
            />
            <FormHelperText>{errors.body && 'Text is required in the email template'}</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default SendPurchaseOrderForm;
