import {Button, Grid, IconButton, makeStyles, Tooltip, Typography} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import React, {useEffect, useRef, useState} from 'react';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import copy from 'copy-to-clipboard';
import {getShortCodes, SUGGESTION_TRIGGER, SUGGESTION_TRIGGER_END} from '../../models/ShortCodes';

const useStyles = makeStyles((theme) => ({
  iconStyle: {
    fontSize: '16px',
    color: theme.palette.secondary.main,
  },
  iconButton: {
    padding: '0 0 2px 0',
    marginLeft: '11px'
  },
  shortCodeGridItem: {marginBottom: theme.spacing(2)},
  shortCodeText: {lineHeight: '20px',}
}));

export const ShortcodeList = ({
                                entity = null,
                                open = false
                              }) => {
  const classes = useStyles();
  const [shortcodeOpen, setShortcodeOpen] = useState(open);

  const shortCodeArray = getShortCodes(entity);

  const handleShortcodeToggle = () => {
    setShortcodeOpen(!shortcodeOpen);
  };

  const handleCopy = ({shortCode, group}) => {
    const shortcodeItem = SUGGESTION_TRIGGER + (group.name === 'Status' ? '' : group.name + '.') + shortCode + SUGGESTION_TRIGGER_END;
    copy(shortcodeItem);
  };

  return (
    <>
      <Button
        color='primary'
        startIcon={shortcodeOpen ? <VisibilityOffIcon/> : <VisibilityIcon/>}
        onClick={handleShortcodeToggle}
        style={{display: !entity && 'none'}}
      >
        {shortcodeOpen ? 'Hide Shortcodes' : 'View Shortcodes'}
      </Button>
      {shortcodeOpen ?
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-start'
          style={{paddingTop: 16}}
        >
          <Grid
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='flex-start'
          ><ShowShortCodes
            toolTip={'Copied'}
            shortCodeArray={shortCodeArray}
            onClick={handleCopy}
            icon={<FileCopyIcon className={classes.iconStyle}/>}
          />
          </Grid>
        </Grid>
        :
        null
      }
    </>
  );
};

export function ShowShortCodes({shortCodeArray, onClick, toolTip, icon}) {
  const classes = useStyles();
  return shortCodeArray.map((group, gIdx) =>
    <Grid key={gIdx} item xs={6} className={classes.shortCodeGridItem}>
      <Typography variant='body2' style={{textTransform: 'capitalize'}}
                  gutterBottom><strong>{group.name}</strong></Typography>
      {group.shortCodes.map((shortCode, scIdx) => <ShortCodeToolTip key={scIdx} {...{scIdx, toolTip, shortCode, group, onClick, icon}} />)}
    </Grid>
  );
}

export function ShortCodeToolTip({scIdx, shortCode, group, onClick, toolTip, icon}) {
  const id = useRef(null);

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const shortcodeItem = SUGGESTION_TRIGGER + (group.name === 'Status' ? '' : group.name + '.') + shortCode + SUGGESTION_TRIGGER_END;
  useEffect(() => clearTimeout(id.current),[]);
  return  <Typography
    sytyle={{cursor: 'pointer'}}
      onClick={() => {
        onClick({scIdx, shortCode, group});
        setOpen(true);
        id.current = setTimeout(() => setOpen(false), 1000);
      }}
      variant='body2'
                key={scIdx}
                className={classes.shortCodeText}

    >
      {shortcodeItem}
      {toolTip && <Tooltip title={toolTip} open={open} placement='right' interactive>
        {icon && <IconButton className={classes.iconButton}>
          {icon}
        </IconButton>}
      </Tooltip>}
    </Typography>;
}

export default ShortcodeList;
