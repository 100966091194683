// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {Button, Grid, Menu, MenuItem, Tab, Tabs, Typography} from '@material-ui/core';
import {makeStyles, MuiThemeProvider} from '@material-ui/core/styles';
import {ArrowDropDown, Delete, Edit, FileCopy} from '@material-ui/icons';
import {get} from 'lodash';
import React from 'react';
import theme from '../../ui/theme/index';
import {QuoteInlineTitleEdit} from './QuoteInlineTitleEdit';

const tabHeight = '48px';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    tableCell: {border: 'none',},
    option: {
        flexDirection: 'row-reverse',
        textTransform: 'none',

    },
    menuIcon: {
        color: theme.colors.grey.main,
        paddingRight: '8px',
    },
    tabsRoot: {
        minHeight: tabHeight,
        height: tabHeight,
        borderBottomColor: theme.colors.grey.light,
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
    },
    tabRoot: {
        minHeight: tabHeight,
        height: tabHeight,
        fontWeight: 500,
        color: theme.palette.text.primary,

    },
    buttonsInTabs: {
        height: theme.spacing(4),
        margin: theme.spacing(1)
    }

}));

export const QuoteEditTabs = ({
    quotes = [],
    activeIndex = 0,
    onAddOption = () => null,
    onRenameOption = () => null,
    onDuplicateOption = () => null,
    onRemoveOption = () => null,
    onTabChange = () => null,
}) => {
    const classes = useStyles();
    const [renameIndex, setRenameIndex] = React.useState();
    const [isRenaming, setIsRenaming] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleTabChange = (_, newTabIndex) => {
        if (activeIndex === newTabIndex || typeof newTabIndex !== 'number') {
            return;
        }

        onTabChange({
            previous: activeIndex,
            current: newTabIndex,
        });
    };

    const handleAddOption = () => {
        handleClose();
        onAddOption();
    };

    const handleRename = () => {
        setIsRenaming(true);
        setRenameIndex(activeIndex);
        handleClose();
    };

    const handleRenameSave = (newTitle) => {
        onRenameOption(activeIndex, newTitle);
        setRenameIndex(null);
        setIsRenaming(false);
    };

    const handleRenameCancel = () => {
        setRenameIndex(null);
        setIsRenaming(false);
    };

    const handleDuplicate = () => {
        handleClose();
        onDuplicateOption(activeIndex);
    };

    const handleRemove = () => {
        handleClose();
        onRemoveOption(activeIndex);
    };

    return (

        <MuiThemeProvider theme={theme}>
            <Grid item>
                <Tabs
                    value={activeIndex}
                    onChange={handleTabChange}
                    indicatorColor='primary'
                    textColor='primary'
                    classes={{
                        root: classes.tabsRoot,
                        wrapper: classes.tabsWrapper,
                    }}
                >
                    {quotes.map((quote, index) => {
                        const quoteTitle = get(quote, 'title', '') === ''
                            ? 'Option ' + (index + 1)
                            : get(quote, 'title', '');

                        return (
                            <Tab key={index}
                                label={quoteTitle}
                                classes={{wrapper: classes.option, root: classes.tabRoot}}
                                icon={<ArrowDropDown onClick={(event) => handleClick(event, index)} style={{marginBottom: 0, marginLeft: '8px'}} />}
                                component={renameIndex === index ? () => <QuoteInlineTitleEdit onSave={handleRenameSave} onCancel={handleRenameCancel} quoteTitle={quoteTitle} /> : undefined}
                            />
                        );
                    })
                    }

                    {!isRenaming ?
                        <Button
                            variant={'contained'}
                            size='small'
                            color={'primary'}
                            className={classes.buttonsInTabs}
                            type='button'
                            disableElevation
                            onClick={handleAddOption}
                        >
                            Add Option
                        </Button>
                        : null
                    }
                </Tabs>

                <Menu
                    id='long-menu'
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    onClose={handleClose}
                    PaperProps={{style: {width: '20ch',},}}
                >
                    <MenuItem onClick={handleRename} disabled={quotes && quotes.length < 2}><Edit className={classes.menuIcon} /><Typography variant={'body2'}>Rename</Typography></MenuItem>
                    <MenuItem onClick={handleDuplicate} ><FileCopy className={classes.menuIcon} /> <Typography variant={'body2'}>Duplicate</Typography></MenuItem>
                    <MenuItem onClick={handleRemove} disabled={quotes && quotes.length < 2}><Delete className={classes.menuIcon} /> <Typography variant={'body2'}>Remove</Typography></MenuItem>

                </Menu>

            </Grid>
        </MuiThemeProvider>
    );
};
