import Button from '@material-ui/core/Button';
import {get} from 'lodash';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {reset, submit as _submit} from 'redux-form';
import {clearCustomer, getCustomers, saveCustomer as _saveCustomer} from '../../../actions/action-types';
import {closeCustomerModal} from '../../../actions/modals';
import HoopsFormDialog from '../../modal/HoopsFormDialog';
import CustomerForm from '../customerForm';

function SaveButtonComponent() {
  const dispatch = useDispatch();

  const submit = (form) => dispatch(_submit(form));

  return (
    <>
      <Button
        variant={'contained'}
        color={'primary'}
        onClick={() => submit('customerForm')}
      >Save</Button>
    </>
  );
}

function CustomerModal() {
  const dispatch = useDispatch();
  const formSelector = (state) => state.customerReducer;
  const modalSelector = (state) => state.modalReducer.customerModal;
  let {formCustomer: activeCustomer,} = useSelector(formSelector);
  const {open, context} = useSelector(modalSelector);

  const handleClose = (data) => {
    const callback = get(context, 'callback');
    dispatch(reset('customerForm'));
    dispatch(closeCustomerModal());
    dispatch(getCustomers());
    dispatch(clearCustomer());
    if (callback) {
      callback(data);
    }
  };

  const handleSubmit = (values) => {
    const {type} = values;

    if (type === 'INDIVIDUAL') {
      const firstName = get(values, 'contacts[0].firstName', '');
      const lastName = get(values, 'contacts[0].lastName', '');
      const email = get(values, 'contacts[0].email', '');
      const phone = get(values, 'contacts[0].phone', '');

      values = {
        ...values,
        name: [firstName, lastName].join(' '),
        phone,
        email
      };
    }
    dispatch(_saveCustomer(values, (data) => {
      handleClose(data);
    }));
  };

  return (
    <>
      <HoopsFormDialog
        maxWidth={'lg'}
        open={open}
        onCancel={handleClose}
        title={activeCustomer._id ? 'Edit Customer' : 'Add Customer'}
        SaveButton={SaveButtonComponent}
      >
        <CustomerForm onSubmit={handleSubmit} />
      </HoopsFormDialog>
    </>
  );
}

export default CustomerModal;
