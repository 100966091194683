import {client} from './GraphQLService';
import {pick} from 'lodash';
import {
  GQL_GET_TASK,
  GQL_GET_TASKS,
  GQL_CREATE_TASK,
  GQL_UPDATE_TASK,
  GQL_DELETE_TASK,
} from '../queries/tasks';

export default class TaskService {
  static _parseTaskInput(task) {
    const keys = [
      'title',
      'description',
      'status',
      'dueAt',
      'endAt',
      'allDay',
      'memberIds',
      'jobIds',
      'quoteIds',
      'fileIds',
      'isDone',
      'resourceId',
      'customerId'
    ];

    if (task.entities) {
      task.quoteIds = task.entities ? task.entities.filter((entity) => entity.entityType === 'Quote').map((entity) => entity.entityId) : [];
      task.jobIds = task.entities ? task.entities.filter((entity) => entity.entityType === 'Job').map((entity) => entity.entityId) : [];  
    }
    
    return pick(task, keys);
  }

  static async getTask(id) {
    return client
      .query({
        query: GQL_GET_TASK,
        variables: {_id: id,},
        fetchPolicy: 'no-cache',
      })
      .then(({data: {taskOne}}) => taskOne);
  }

  static async getTasks({...variables}) {
    return client
      .query({
        query: GQL_GET_TASKS,
        fetchPolicy: 'network-only',
        variables: {...variables,},
      })
      .then(({data: {taskMany}}) => taskMany);
  }

  static async createTask(taskData) {
    return client
      .mutate({
        mutation: GQL_CREATE_TASK,
        variables: {record: TaskService._parseTaskInput(taskData),},
      })
      .then(({data: {taskCreateOne}}) => taskCreateOne);
  }

  static async updateTask(_id, data) {
    return client
      .mutate({
        mutation: GQL_UPDATE_TASK,
        variables: {
          _id,
          record: TaskService._parseTaskInput(data),
        },
      })
      .then(({data: {taskUpdateById}}) => taskUpdateById);
  }

  static async deleteTask(_id) {
    return client
      .mutate({
        mutation: GQL_DELETE_TASK,
        variables: {_id,},
      })
      .then(({data: {taskRemoveById}}) => taskRemoveById);
  }
}
