import {gql} from 'apollo-boost';

export const SINGLE_PRODUCT_FRAGMENT = gql`
  fragment SingleProduct on Product {
    _id
    readOnly
    company {
      _id
      companyTradingEntities {
        _id
        name
      }
    }
    title
    code
    description
    vendor {
      name
      _id
    }
    defaultPriceBreaks {
      quantity
      price
    }
    brand
    category
    subCategory
    url
    primaryPriceDescription
    materials
    decorationsAvailable
    decorationAreas
    stockIndent
    cartonSize {
      length
      width
      height
    }
    cartonWeight
    qtyPerCarton
    cartonCubic
    cartonNotes
    freightDescription
    individualProductPackaging
    companyId
    createdAt
    updatedAt
    createdById
    deleted
    deletedAt
    deletedById
    primaryImage {
      _id
      contentLength
      contentType
      bucket
      key
      filename
      url
    }
    images {
      _id
      contentLength
      contentType
      bucket
      key
      filename
      url
    }
    colors {
      _id
      name
      image {
        _id
        contentLength
        contentType
        bucket
        key
        filename
        url
      }
    }
    sizes {
      _id
      name
      description
    }
    tags {
      _id
      name
      description
      companyId
    }
    variants {
      _id
      sku
      color {
        _id
        name
      }
      size {
        _id
        name
        description
      }
      priceBreaks {
        _id
        price
        quantity
      }
    }
    decorations {
      _id
      name
      vendorId
      vendor {
        _id
        name
        email
      }
    }
  }
`;

export const PARTIAL_PRODUCT_FRAGMENT = gql`
    fragment PartialProduct on Product {
        _id
        readOnly
        company {
            _id
            companyTradingEntities {
        _id
              name
            }
        }
        title
        code
        description
        vendor {
            name
            _id
        }
        defaultPriceBreaks {
            quantity
            price
        }
        brand
        category
        subCategory
        url
        primaryPriceDescription
        materials
        decorationsAvailable
        decorationAreas
        stockIndent
        cartonSize {
            length
            width
            height
        }
        cartonWeight
        qtyPerCarton
        cartonCubic
        cartonNotes
        freightDescription
        individualProductPackaging
        companyId
        createdAt
        updatedAt
        createdById
        deleted
        deletedAt
        deletedById
        primaryImage {
            _id
            contentLength
            contentType
            bucket
            key
            filename
            url
        }
        tags {
            _id
            name
            description
            companyId
        }
        variants {
            _id
            sku
            color {
            _id
            name
            }
            size {
            _id
            name
            description
            }
            priceBreaks {
            _id
            price
            quantity
            }
        }
        decorations {
            _id
            name
            vendorId
            vendor {
            _id
            name
            email
            }
        }
    }
`;

export const SINGLE_GCPRODUCT_FRAGMENT = gql`
  fragment SingleGCProduct on GCProduct {
    _id
    company {
      _id
      companyTradingEntities {
        _id
        name
      }
    }
    title
    code
    vendor {
      name
      _id
    }
    readOnly
    primaryImage {
      url
    }
  }
`;

export const GQL_CACHE_PRODUCT = gql`
mutation cacheProduct($catalog:String!, $_id:String!) {
  cacheProduct(catalog: $catalog, _id: $_id) {
    ...PartialProduct
  }
}
${PARTIAL_PRODUCT_FRAGMENT}
`;

export const GQL_CREATE_PRODUCT = gql`
  mutation createProduct($product:CreateProductInput!) {
    createProduct(data:$product) {
      _id
      title
      code
      description
      colors {
        _id
        name
      }
      sizes {
        _id
        name
      }
      vendorId
      images {
        _id
        contentLength
        contentType
        bucket
        key
        filename
        url
      }
    }
  }
`;

export const GQL_SHARE_PRODUCT = gql`
  mutation shareProduct($_id: ID! $companyIds: [ID!]!) {
    shareProduct(_id: $_id, companyIds: $companyIds) {
      _id
      title
    }
  }
`;

export const GQL_GET_PRODUCT = gql`
  query getProduct($_id:ID!) {
    product(_id:$_id) {
      ...SingleProduct
    }
  }
  ${SINGLE_PRODUCT_FRAGMENT}
`;

export const GQL_GET_PRODUCT_WITH_VARIANTS_TABLE = gql`
  query getProductWithVariantsTable($_id:ID!) {
    product(_id:$_id) {
      ...SingleProduct
      variantsTable {
        colors {
          _id
          name
        }
        sizes {
          _id
          name
        }
        variants {
          _id
          color {
            _id
            name
          }
          size {
            name
          }
        }
        priceBreaks {
          quantity
          price
        }
      }
    }
  }
  ${SINGLE_PRODUCT_FRAGMENT}
`;

export const GQL_UPDATE_PRODUCT = gql`
  mutation updateProduct($_id: String!, $data: ProductInput) {
    updateProduct(_id:$_id, data:$data) {
      ...SingleProduct
    }
  }
  ${SINGLE_PRODUCT_FRAGMENT}
`;

export const GQL_UPDATE_PRODUCT_COLOR = gql`
  mutation updateProductColor($_id:String, $data: ProductColorInput) {
    updateProductColor(_id: $_id, data: $data) {
      _id
      image {
        filename
        url
      }
      name
    }
  }
`;

export const GQL_DELETE_PRODUCT = gql`
  mutation deleteProduct($_id:String!) {
    deleteProduct(_id:$_id) {
      code
      deleted
    }
  }
`;
export const GQL_REMOVE_SHARED_PRODUCT = gql`
  mutation removeSharedProduct($productId:ID! $companyId: ID!) {
    removeSharedProduct(_id: $productId companyId:$companyId) {
      _id
    }
  }
`;

export const GQL_BULK_UPDATE_PRODUCT_VARIANTS = gql`
  mutation bulkUpdateProductVariants($productId:String!, $variants:[ProductVariantUpdateInput!]) {
    bulkUpdateProductVariants(productId:$productId, variants:$variants) {
      _id
      color {
        _id
        name
      }
      size {
        _id
        name
      }
      priceBreaks {
        price
        quantity
      }
    }
  }
`;

export const GQL_DELETE_VENDOR_PRODUCTS = gql`
  mutation deleteVendorProducts($_id: ID!) {
    deleteVendorProducts(_id:$_id) 
  }
`;

export const GQL_PRODUCT_PAGINATION = gql`
    query productPagination(
        $filter: FilterFindManySearchGCProductInput,
        $sort: SortFindManyGCProductInput,
        $perPage: Int,
        $page: Int
    ){
        productPagination(
            filter: $filter,
            sort: $sort,
            perPage: $perPage,
            page: $page
        ) {
            items {
                ...SingleGCProduct
            }
            pageInfo {
                currentPage
                perPage
                pageCount
                itemCount
                hasNextPage
                hasPreviousPage
            }
        }
    }
    ${SINGLE_GCPRODUCT_FRAGMENT}
`;
