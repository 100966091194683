import {makeStyles, TextField, Typography} from '@material-ui/core';
import React, {useEffect, useRef} from 'react';
import {formStyles} from '../ui/theme/formStyles';

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  responsive: {
    [theme.breakpoints.down('md')]: {
      '& .MuiInputAdornment-root': {display: 'none',},
      '&:hover': {
        position: 'absolute',
        zIndex: '1',
        width: (props) =>
          props.textfieldwidth > props.textwidth
            ? props.textwidth + 80
            : props.textfieldwidth + 80
        ,
        marginTop: '-20px !important',
        boxShadow: '20px 0px 20px -10px #888',
        '& .MuiInputAdornment-root': {display: 'inherit'}
      }
    }
  },
}));

export const HoopsTextField = ({
  input = {},
  label = null,
  style = {},
  className = '',
  responsive = false,
  selectOnFocus = false,
  autoFocus = false,
  variant = 'outlined',
  loading = false,
  ...props
}) => {
  // Responsive work to compare text length to field width.
  const textFieldRef = useRef(null);
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = getComputedStyle(document.body).font;

  props.textwidth = props.value && context.measureText(props.value).width;
  props.textfieldwidth = textFieldRef && textFieldRef.current && textFieldRef.current.offsetWidth;
  useEffect(() => {
    if (textFieldRef.current && autoFocus) {
      textFieldRef.current.focus();
      if (selectOnFocus) {
        textFieldRef.current.select();
      }
    }
  }, [autoFocus, selectOnFocus, textFieldRef]);
  const classes = useStyles(props);
  return (
    <>
      {label && <Typography variant='caption' className={classes.labelText}>{label}</Typography>}
      <TextField
        inputRef={textFieldRef}
        variant={variant}
        margin='dense'
        fullWidth
        className={`${classes.inputStyles} ${responsive ? classes.responsive : ''} ${loading ? classes.loadingFieldStyle : ''} ${className}`}
        style={{margin: 0, ...style}}
        //Props for redux-form
        error={props.meta && Boolean(props.meta.error)}
        helperText={props.meta && props.meta.error ? props.meta.error : null}
        {...input}
        {...props}

        onChange={(e) => {
          if (props?.type === 'number') {
            const match = e.target.value.match?.(/([0-9]*\.[0-9][0-9])([0-9])/);
            if (match && e.target.value !== match[1]) {
              e.target.value = match[1];
            }
          }
          input?.onChange && input.onChange(e);
          props?.onChange && props.onChange(e);
        }}
      />
    </>
  );
};
