// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import {hoopsQuerySetRefetch} from './hoopsQuery';
import {getJob} from './jobs';
import {getProof} from './proofs';

export const SET_MODAL_OPEN = 'SET_MODAL_OPEN';
export const SET_MODAL_CONTEXT = 'SET_MODAL_CONTEXT';

// Quote Modals
export const OPEN_SEND_QUOTE_MODAL = 'OPEN_SEND_QUOTE_MODAL';
export const CLOSE_SEND_QUOTE_MODAL = 'CLOSE_SEND_QUOTE_MODAL';

// Job Modals
export const OPEN_JOB_MODAL = 'OPEN_JOB_MODAL';
export const CLOSE_JOB_MODAL = 'CLOSE_JOB_MODAL';

export const OPEN_JOB_VARIANTS_MODAL = 'OPEN_JOB_VARIANTS_MODAL';
export const CLOSE_JOB_VARIANTS_MODAL = 'CLOSE_JOB_VARIANTS_MODAL';

export const OPEN_PROOF_UPLOAD_MODAL = 'OPEN_PROOF_UPLOAD_MODAL';
export const CLOSE_PROOF_UPLOAD_MODAL = 'CLOSE_PROOF_UPLOAD_MODAL';

export const OPEN_PROOF_VIEW_MODAL = 'OPEN_PROOF_VIEW_MODAL';
export const CLOSE_PROOF_VIEW_MODAL = 'CLOSE_PROOF_VIEW_MODAL';

export const OPEN_PROOF_NOTES_MODAL = 'OPEN_PROOF_NOTES_MODAL';
export const CLOSE_PROOF_NOTES_MODAL = 'CLOSE_PROOF_NOTES_MODAL';

export const OPEN_SEND_PROOF_MODAL = 'OPEN_SEND_PROOF_MODAL';
export const CLOSE_SEND_PROOF_MODAL = 'CLOSE_SEND_PROOF_MODAL';

export const OPEN_GUEST_REJECT_PROOF_MODAL = 'OPEN_GUEST_REJECT_PROOF_MODAL';
export const CLOSE_GUEST_REJECT_PROOF_MODAL = 'CLOSE_GUEST_REJECT_PROOF_MODAL';

export const OPEN_PROOF_TERMS_MODAL = 'OPEN_PROOF_TERMS_MODAL';
export const CLOSE_PROOF_TERMS_MODAL = 'CLOSE_PROOF_TERMS_MODAL';
export const OPEN_CUSTOMER_MODAL = 'OPEN_CUSTOMER_MODAL';
export const CLOSE_CUSTOMER_MODAL = 'CLOSE_CUSTOMER_MODAL';

export const OPEN_MERGE_PURCHASE_ORDER_VENDOR_MODAL  = 'OPEN_MERGE_PURCHASE_ORDER_VENDOR_MODAL';
export const CLOSE_MERGE_PURCHASE_ORDER_VENDOR_MODAL  = 'CLOSE_MERGE_PURCHASE_ORDER_VENDOR_MODAL';

export const OPEN_VIEW_PURCHASE_ORDER_MODAL  = 'OPEN_VIEW_PURCHASE_ORDER_MODAL';
export const CLOSE_VIEW_PURCHASE_ORDER_MODAL  = 'CLOSE_VIEW_PURCHASE_ORDER_MODAL';

export const OPEN_PRICE_BUILDER_MODAL = 'OPEN_PRICE_BUILDER_MODAL';
export const CLOSE_PRICE_BUILDER_MODAL = 'CLOSE_PRICE_BUILDER_MODAL';

export const OPEN_QUOTE_TERMS = 'OPEN_QUOTE_TERMS';
export const CLOSE_QUOTE_TERMS = 'CLOSE_QUOTE_TERMS';

// Purchase Order modals
export const OPEN_ADD_PURCHASE_ORDER_MODAL = 'OPEN_ADD_PURCHASE_ORDER_MODAL';
export const CLOSE_ADD_PURCHASE_ORDER_MODAL = 'CLOSE_ADD_PURCHASE_ORDER_MODAL';

export const OPEN_ADD_PURCHASE_ORDER_PRODUCT_MODAL = 'OPEN_ADD_PURCHASE_ORDER_PRODUCT_MODAL';
export const CLOSE_ADD_PURCHASE_ORDER_PRODUCT_MODAL = 'CLOSE_ADD_PURCHASE_ORDER_PRODUCT_MODAL';

export const OPEN_MERGE_PURCHASE_ORDER_MODAL = 'OPEN_MERGE_PURCHASE_ORDER_MODAL';
export const CLOSE_MERGE_PURCHASE_ORDER_MODAL = 'CLOSE_MERGE_PURCHASE_ORDER_MODAL';

export const OPEN_PURCHASE_ORDER_VARIANTS_MODAL = 'OPEN_PURCHASE_ORDER_VARIANTS_MODAL';
export const CLOSE_PURCHASE_ORDER_VARIANTS_MODAL = 'CLOSE_PURCHASE_ORDER_VARIANTS_MODAL';

export const OPEN_PURCHASE_ORDER_FILE_UPLOAD_MODAL = 'OPEN_PURCHASE_ORDER_FILE_UPLOAD_MODAL';
export const CLOSE_PURCHASE_ORDER_FILE_UPLOAD_MODAL = 'CLOSE_PURCHASE_ORDER_FILE_UPLOAD_MODAL';

export const OPEN_PO_SHIP_TO = 'OPEN_PO_SHIP_TO';
export const CLOSE_PO_SHIP_TO = 'CLOSE_PO_SHIP_TO';

export const OPEN_JOB_NOTE_MODAL = 'OPEN_JOB_NOTE_MODAL';
export const CLOSE_JOB_NOTE_MODAL = 'CLOSE_JOB_NOTE_MODAL';

export const OPEN_JOB_ARTWORK_MODAL = 'OPEN_JOB_ARTWORK_MODAL';
export const CLOSE_JOB_ARTWORK_MODAL = 'CLOSE_JOB_ARTWORK_MODAL';

export const OPEN_CATALOG_MODAL = 'OPEN_CATALOG_MODAL';
export const CLOSE_CATALOG_MODAL = 'CLOSE_CATALOG_MODAL';

export const OPEN_PRODUCT_PRICE_VARIANTS_MODAL = 'OPEN_PRODUCT_PRICE_VARIANTS_MODAL';
export const CLOSE_PRODUCT_PRICE_VARIANTS_MODAL = 'CLOSE_PRODUCT_PRICE_VARIANTS_MODAL';

export const OPEN_CREATE_PRODUCT_VARIANT_MODAL = 'OPEN_CREATE_PRODUCT_VARIANT_MODAL';
export const CLOSE_CREATE_PRODUCT_VARIANT_MODAL = 'CLOSE_CREATE_PRODUCT_VARIANT_MODAL';
export const SET_LOADING_VARIANTS_MODAL = 'SET_LOADING_VARIANTS_MODAL';

export const OPEN_TAG_MODAL = 'OPEN_TAG_MODAL';
export const CLOSE_TAG_MODAL = 'CLOSE_TAG_MODAL';

export const OPEN_JOB_DELETE_MODAL = 'OPEN_JOB_DELETE_MODAL';
export const CLOSE_JOB_DELETE_MODAL = 'CLOSE_JOB_DELETE_MODAL';

export const OPEN_INVOICE_MODAL = 'OPEN_INVOICE_MODAL';
export const CLOSE_INVOICE_MODAL = 'CLOSE_INVOICE_MODAL';
export const SET_INVOICE_MODAL_ON_CLOSE = 'SET_INVOICE_MODAL_ON_CLOSE';

export function openQuoteTerms() {
  return function(dispatch) {
    dispatch({type: OPEN_QUOTE_TERMS,});
  };
}

export function closeQuoteTerms() {
  return function(dispatch) {
    dispatch({type: CLOSE_QUOTE_TERMS});
  };
}

export function openCustomerModal(context) {
  return function(dispatch) {
    dispatch({
      type: OPEN_CUSTOMER_MODAL,
      payload: context
    });
  };
}

export function closeCustomerModal() {
  return function(dispatch) {
    dispatch({type: CLOSE_CUSTOMER_MODAL});
    dispatch(hoopsQuerySetRefetch('customer', true));
  };
}

export function openMergePurchaseOrderVendorModal(context) {
  return function(dispatch) {
    dispatch({
      type: OPEN_MERGE_PURCHASE_ORDER_VENDOR_MODAL,
      payload: context
    });
  };
}

export function closeMergePurchaseOrderVendorModal() {
  return function(dispatch) {
    dispatch({type: CLOSE_MERGE_PURCHASE_ORDER_VENDOR_MODAL});
    dispatch(hoopsQuerySetRefetch('purchaseOrder', true));
  };
}

export function openProofUploadModal(jobId = null) {
  return function (dispatch) {
    dispatch({type: OPEN_PROOF_UPLOAD_MODAL,});
    (jobId && dispatch(getJob(jobId, {fetchPolicy: 'no-cache'})));
  };
}

export function closeProofUploadModal() {
  return function (dispatch) {
    dispatch({type: CLOSE_PROOF_UPLOAD_MODAL});
  };
}

export function openJobVariantsModal(context) {
  return function (dispatch) {
    dispatch({
      type: OPEN_JOB_VARIANTS_MODAL,
      payload: context
    });
  };
}

export function closeJobVariantsModal() {
  return function (dispatch) {
    dispatch({type: CLOSE_JOB_VARIANTS_MODAL});
  };
}

export function openProofViewModal(proof) {
  return (dispatch) => {
    dispatch({
      type: OPEN_PROOF_VIEW_MODAL,
      payload: proof
    });
  };
}

export function closeProofViewModal() {
  return (dispatch) => {
    dispatch({type: CLOSE_PROOF_VIEW_MODAL});
  };
}

export function openProofNotesModal(context = {rejecting: false, proofId: null}) {
  return (dispatch) => {
    dispatch(getProof(context.proofId));
    dispatch({
      type: OPEN_PROOF_NOTES_MODAL,
      payload: context
    });
  };
}

export function closeProofNotesModal(context = {rejecting: false, proofId: null}) {
  return (dispatch) => {
    dispatch({
      type: CLOSE_PROOF_NOTES_MODAL,
      payload: context
    });
  };
}

export function openSendProofModal(context) {
  return (dispatch) => {
    dispatch({
      type: OPEN_SEND_PROOF_MODAL,
      payload: context
    });
  };
}

export function closeSendProofModal() {
  return (dispatch) => {
    dispatch({type: CLOSE_SEND_PROOF_MODAL});
  };
}

export function openGuestRejectProofModal(context) {
  return (dispatch) => {
    dispatch({
      type: OPEN_GUEST_REJECT_PROOF_MODAL,
      payload: context
    });
  };
}

export function closeGuestRejectProofModal() {
  return (dispatch) => {
    dispatch({type: CLOSE_GUEST_REJECT_PROOF_MODAL});
  };
}

export function openProofTermsModal() {
  return function (dispatch) {
    dispatch({type: OPEN_PROOF_TERMS_MODAL});
  };
}

export function closeProofTermsModal() {
  return function (dispatch) {
    dispatch({type: CLOSE_PROOF_TERMS_MODAL});
  };
}

export function openAddPurchaseOrderModal(_id) {
  return (dispatch) => {
    if (_id) {
      dispatch(getJob(_id, null, () => {
        dispatch({type: OPEN_ADD_PURCHASE_ORDER_MODAL});
      }));
      return;
    }

    dispatch({type: OPEN_ADD_PURCHASE_ORDER_MODAL});
  };
}

export function closeAddPurchaseOrderModal() {
  return (dispatch) => {
    dispatch({type: CLOSE_ADD_PURCHASE_ORDER_MODAL});
  };
}

export function openItemBuilderModal(context) {
  return function (dispatch) {
    dispatch({
      type: OPEN_PRICE_BUILDER_MODAL,
      payload: context
    });
  };
}

export function closeItemBuilderModal() {
  return function (dispatch) {
    dispatch({type: CLOSE_PRICE_BUILDER_MODAL,});
  };
}

export function openMergePurchaseOrderModal(context) {
  return (dispatch) => {
    dispatch({
      type: OPEN_MERGE_PURCHASE_ORDER_MODAL,
      payload: context
    });
  };
}

export function closeMergePurchaseOrderModal() {
  return (dispatch) => {
    dispatch({type: CLOSE_MERGE_PURCHASE_ORDER_MODAL});
    dispatch(hoopsQuerySetRefetch('purchaseOrder', true));
  };
}

export function openViewPurchaseOrderModal(context) {
  return (dispatch) => {
    dispatch({
      type: OPEN_VIEW_PURCHASE_ORDER_MODAL,
      payload: context
    });
  };
}

export function closeViewPurchaseOrderModal() {
  return (dispatch) => {
    dispatch({type: CLOSE_VIEW_PURCHASE_ORDER_MODAL});
    dispatch(hoopsQuerySetRefetch('purchaseOrder', true));
  };
}

export function openPurchaseOrderVariantsModal(options) {
  return (dispatch) => {
    dispatch({
      type: OPEN_PURCHASE_ORDER_VARIANTS_MODAL,
      payload: options
    });
  };
}

export function closePurchaseOrderVariantsModal() {
  return (dispatch) => {
    dispatch({type: CLOSE_PURCHASE_ORDER_VARIANTS_MODAL});
  };
}

export function openPurchaseOrderFileUploadModal(context) {
  return (dispatch) => {
    dispatch({
      type: OPEN_PURCHASE_ORDER_FILE_UPLOAD_MODAL,
      payload: context
    });
  };
}

export function closePurchaseOrderFileUploadModal() {
  return (dispatch) => {
    dispatch({type: CLOSE_PURCHASE_ORDER_FILE_UPLOAD_MODAL});
  };
}

export function openPOShipTo(shipTo, isDraftPO = false) {
  return (dispatch) => {
    dispatch({
      type: OPEN_PO_SHIP_TO,
      payload: {shipTo, isDraftPO}
    });
  };
}
export function closePOShipTo(shipTo) {
  return (dispatch) => {
    dispatch({type: CLOSE_PO_SHIP_TO,});
  };
}

export function openSendQuoteModal(context) {
  return (dispatch) => {
    dispatch({
      type: OPEN_SEND_QUOTE_MODAL,
      payload: context
    });
    dispatch(hoopsQuerySetRefetch('quote', true));
  };
}

export function closeSendQuoteModal() {
  return (dispatch) => {
    dispatch({type: CLOSE_SEND_QUOTE_MODAL});
  };
}

export function openJobNoteModal(context) {
  return (dispatch) => {
    dispatch({
      type: OPEN_JOB_NOTE_MODAL,
      payload: context
    });
  };
}

export function closeJobNoteModal() {
  return (dispatch) => {
    dispatch({type: CLOSE_JOB_NOTE_MODAL});
  };
}

export function openJobArtworkModal(context) {
  return (dispatch) => {
    dispatch({
      type: OPEN_JOB_ARTWORK_MODAL,
      payload: context
    });
  };
}

export function closeJobArtworkModal() {
  return (dispatch) => {
    dispatch({type: CLOSE_JOB_ARTWORK_MODAL});
  };
}

export function
openCatalogModal(context) {
  return (dispatch) => {
    dispatch({
      type: OPEN_CATALOG_MODAL,
      payload: context
    });
  };
}

export function closeCatalogModal() {
  return (dispatch) => {
    dispatch({type: CLOSE_CATALOG_MODAL});
    dispatch(hoopsQuerySetRefetch('product', true));
  };
}

export function openProductPriceVariantsModal(context) {
  return (dispatch) => {
    dispatch({
      type: OPEN_PRODUCT_PRICE_VARIANTS_MODAL,
      payload: context
    });
  };
}

export function closeProductPriceVariantsModal() {
  return (dispatch) => {
    dispatch({type: CLOSE_PRODUCT_PRICE_VARIANTS_MODAL});
  };
}

export function openCreateProductVariantModal(context) {
  return (dispatch) => {
    dispatch({
      type: OPEN_CREATE_PRODUCT_VARIANT_MODAL,
      payload: context
    });
  };
}

export function closeCreateProductVariantModal() {
  return (dispatch) => {
    dispatch({type: CLOSE_CREATE_PRODUCT_VARIANT_MODAL});
  };
}

export function openTagModal(context) {
  return (dispatch) => {
    dispatch({
      type: OPEN_TAG_MODAL,
      payload: context
    });
  };
}

export function closeTagModal() {
  return (dispatch) => {
    dispatch({type: CLOSE_TAG_MODAL});
  };
}

export function openJobDeleteModal(context) {
  return (dispatch) => {
    dispatch({
      type: OPEN_JOB_DELETE_MODAL,
      payload: context
    });
  };
}

export function closeJobDeleteModal() {
  return (dispatch) => {
    dispatch({type: CLOSE_JOB_DELETE_MODAL});
  };
}

export function openInvoiceModal(payload) {
  return (dispatch) => {
    dispatch({
      type: OPEN_INVOICE_MODAL,
      payload
    });
  };
}

export function closeInvoiceModal() {
  return (dispatch) => {
    dispatch({
      type: CLOSE_INVOICE_MODAL,
      payload: {}
    });
  };
}

export function setInvoiceModalOnClose(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_INVOICE_MODAL_ON_CLOSE,
      payload
    });
  };
}

export function setModalOpen(modal, isOpen) {
  return (dispatch) => {
    dispatch({
      type: SET_MODAL_OPEN,
      payload: {
        modal,
        open: isOpen
      }
    });
  };
}
export function setModalContext(modal, context) {
  return (dispatch) => {
    dispatch({
      type: SET_MODAL_CONTEXT,
      payload: {
        modal,
        context
      }
    });
  };
}
