// TODO: fix eslint disable
/* eslint-disable no-shadow, no-unused-vars */

import {VendorService} from '../../servicesOld/VendorService';
import {snackError, snackSuccess} from './snackbar';
import {client} from '../../servicesOld/GraphQLService';
import {GQL_DELETE_VENDOR_PRODUCTS} from '../../queries/product';
import {GQL_DELETE_VENDOR_DECORATIONS} from '../../queries/decorations';
import {hoopsQuerySetRefetch} from '../hoopsQuery';

export const SET_VENDORS = 'SET_VENDORS';
export const SET_VENDOR = 'SET_VENDOR';
export const CLEAR_VENDOR = 'CLEAR_VENDOR';

export function deleteVendorProducts(_id) {
  return function (dispatch, getState) {
    return client.mutate({
      mutation: GQL_DELETE_VENDOR_PRODUCTS,
      variables: {_id}
    })
      .then(({data: {deleteVendorProducts}}) => {
        dispatch(snackSuccess(`${deleteVendorProducts} products removed from ${getState().vendorReducer.vendor.name}.`));
      });
  };
}

export function deleteVendorDecorations(id) {
  return function (dispatch, getState) {
    return client.mutate({
      mutation: GQL_DELETE_VENDOR_DECORATIONS,
      variables: {filter: {vendorId: id}}
    })
        .then(({data: {decorationRemoveMany: {numAffected}}}) => {
          dispatch(snackSuccess(`${numAffected} decorations removed from ${getState().vendorReducer.vendor.name}.`));
        });
  };
}

function setVendors(payload) {
  return {
    type: SET_VENDORS,
    payload,
  };
}

export function setVendor(payload) {
  return {
    type: SET_VENDOR,
    payload,
  };
}

export function clearVendor(payload) {
  return {
    type: CLEAR_VENDOR,
    payload,
  };
}

export function getVendors(options, cb) {
  return function (dispatch) {
    return VendorService.getVendors(options)
      .then((data) => {
        dispatch(setVendors(data.items));

        if (cb) {
          cb(data);
        }
      })
      .catch((e) => {
        dispatch(setVendors([]));
      });
  };
}

export function getVendorsForJob({jobId}) {

  return function (dispatch) {
    return VendorService.getVendorsForJob(jobId)
      .then((data) => {
        dispatch(setVendors(data));
      })
      .catch(() => {
        dispatch(setVendors([]));
      });
  };
}

export function getVendor(id) {
  return function (dispatch) {
    return VendorService.getVendor(id)
      .then((data) => {
        dispatch(setVendor(data));

      });
  };
}

export function deleteVendor(vendor) {
  return function (dispatch) {
    const vendorService = new VendorService(vendor);
    return vendorService.delete()
      .then(() => {
        dispatch(getVendors());
        dispatch(hoopsQuerySetRefetch('vendor', true));
      });
  };
}

export function saveVendor(vendor, cb) {
  return function (dispatch) {
    const vendorService = new VendorService(vendor);
    return vendorService.save()
      .then((data) => {
        dispatch(snackSuccess('Vendor Saved'));
        if (cb) {
          cb(data);
        }
      })
      .catch((err) => {
        console.error(err);
        dispatch(snackError('Something went wrong saving your vendor.'));
      });
  };
}
