import {Grid, makeStyles, Tab, Tabs} from '@material-ui/core';
import React from 'react';
import {BrowserRouter, Link, Redirect, Route, Switch, useHistory} from 'react-router-dom';
import ConfiguredProducts from '../../pages/catalog/ConfiguredProducts';
import DecorationManyTable from '../decorations/DecorationManyTable';
import EntityListHero from '../EntityList/EntityListHero';
import ProductManyTable from './ProductManyTable';
import ProductManyViewButtons from './ProductManyViewButtons';

const useStyles = makeStyles((theme) => ({
  topButtons: {marginLeft: theme.spacing(2)},
  tabsRoot: {
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    marginBottom: theme.spacing(2)
  },
}));

export const ProductManyViewPage = () => {

  const classes = useStyles();
  const routes = ['/catalog/products', '/catalog/configured-products', '/catalog/decorations'];
  const history = useHistory();

  const handleProductSelect = (productId) => {
    history.push(`/catalog/product/${productId}/details`);
  };

  return (
    <>
      <Grid container >
        <EntityListHero
          title='Catalog'
          subtitle='Organise all of your product data &amp; find the products you need quickly.'
          helpArticleUrl='http://help.hoopscrm.com/en/collections/2642943-catalog'
          videoId='_NDNd1kqgKo'
        >
          <ProductManyViewButtons />

        </EntityListHero>

        <Grid item xs={12}>
          <BrowserRouter>
            <Route
              path='/'
              render={({location}) => (

                <Tabs
                  value={location && location.pathname === '/' ? routes[0] : location && location.pathname}
                  indicatorColor='primary'
                  textColor='primary'
                  classes={{root: classes.tabsRoot,}}
                >
                  <Tab
                    value={routes[0]}
                    label='Products'
                    component={Link}
                    to={routes[0]}
                  />
                  <Tab
                    value={routes[1]}
                    label='Pre-Built Items'
                    component={Link}
                    to={routes[1]}
                  />
                  <Tab
                    value={routes[2]}
                    label='Decorations'
                    component={Link}
                    to={routes[2]}
                  />
                </Tabs>

              )}
            />

            <Switch>
              <Route
                exact path='/' render={() => (
                    <Redirect to='/catalog/products' />
                  )}
              />
              <Route
                exact path='/catalog' render={() => (
                    <Redirect to='/catalog/products' />
                  )}
              />
              <Route path='/catalog/products' exact component={() => <ProductManyTable onProductSelect={handleProductSelect} />} />
              <Route path='/catalog/configured-products' exact component={() => <ConfiguredProducts />} />
              <Route path='/catalog/decorations' exact component={() => <DecorationManyTable />} />
            </Switch>
          </BrowserRouter>
        </Grid>

      </Grid>
    </>
  );
};

export default ProductManyViewPage;
