import {
  SET_BILLING,
  SET_DEFAULT_PAYMENT_METHOD,
  SET_INVOICES,
  SET_PAYMENT_METHODS,
  SET_UPCOMING_INVOICE,
} from '../actions/billing';

export const initialState = {
  billing: {stripeSubscription: {}},
  upcomingInvoice: {company: {numberOfUsers: {},},},
  invoices: [],
  paymentMethods: [],
  defaultPaymentMethod: '',
};

const billingReducer = (state = {...initialState}, action) => {
  switch (action.type) {
    case SET_BILLING:
      return {
        ...state,
        billing: action.payload,
      };
    case SET_UPCOMING_INVOICE:
      return {
        ...state,
        upcomingInvoice: action.payload,
      };
    case SET_INVOICES:
      return {
        ...state,
        invoices: action.payload,
      };
    case SET_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.payload,
      };
    case SET_DEFAULT_PAYMENT_METHOD:
      return {
        ...state,
        defaultPaymentMethod: action.payload,
      };
    default:
      return state;
  }
};

export default billingReducer;
