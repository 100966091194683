import {Box, Checkbox, Chip, Input, MenuItem} from '@material-ui/core';
import React, {useState} from 'react';
import HoopsSelectField from '../../../componentsOld/HoopsSelectField';
import {stripHTML} from '../../../utils/stripHtml';

const LinkToProductField = ({
    proof = {},
    job = {},
    onLinkProducts = () => null
}) => {

    const [linkedProducts, setLinkedProducts] = useState([]);

    const handleChange = (e, item) => {
        if (!e && !e.target) {return;}
        const targetItem = e.target ? e.target.value[e.target.value.length - 1] : item;
        setLinkedProducts((prev) => {
            if (prev.length === 0) {
              onLinkProducts(proof, [targetItem]);
              return [targetItem];
            }
            let newArray = [...prev];

            if (newArray.find((el) => el.value === targetItem.value)) {
                newArray = newArray.filter((el) =>
                    // if (targetItem.value !== el.value) {
                         (targetItem.value !== el.value && el)
                    // }
                );
                onLinkProducts(proof, newArray);
                return (newArray);
            }
            onLinkProducts(proof, [...newArray, targetItem]);
            return ([...newArray, targetItem]);
        });
    };

    // Convert the nested Product -> Decoration structure to a list
    const options = job && job.items ? job.items.reduce((agg, product) => {
        let local = [
            ...agg,
            {
                value: product._id,
                text: product.description,
            },
        ];

        if (product && product.variants) {
            local = [
                ...local,
                ...product.variants.decorations.map((decoration) => ({
                    value: decoration._id,
                    text: `${decoration.description}`,
                })),
            ];
        }

        return local;
    }, []) : [];

    return (
        <HoopsSelectField
            multiple
            MenuProps={{PaperProps: {style: {width: 'auto'}}}}
            input={<Input {...linkedProducts} />}
            value={linkedProducts}
            onChange={(e, item) => handleChange(e, item)}
            renderValue={(selected) => (
                <Box display='flex' flexWrap={'wrap'}>
                    {selected.map((item) => (
                        <Chip
                            key={item.value}
                            color={'primary'}
                            label={stripHTML(item.text)}
                            size={'small'}
                            style={{marginRight: 2, marginTop: 2}}
                        />
                    ))}
                </Box>
            )}
            placeholder='Link to product/s'
        >
            {options.map((item) => (
                <MenuItem key={item.value} value={item}>
                    <Checkbox
                        checked={
                            !!linkedProducts.filter(
                                (selectedItem) => selectedItem.value === item.value
                            ).length
                        }
                        name={item.text}
                        color='primary'
                    />
                    {stripHTML(item.text)}
                </MenuItem>
            ))}
        </HoopsSelectField>
    );
};

export default LinkToProductField;
