export const tableStyles = (theme) => ({
  topBuffer: {marginTop: theme.spacing(2)},
  topBufferLarger: {marginTop: theme.spacing(5)},
  companyLogo: {
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    height: '75px',
    width: '75px',
    backgroundRepeat: 'no-repeat'
  },
  detailCard: {
    marginTop: theme.spacing(2),
    padding: '1px 30px',
    flex: 1
  },
  tableHeader: {
    color: theme.colors.grey.main,
    fontSize: '12px',
    width: '80px',
  },
  footerLogo: {
    marginTop: theme.spacing(5),
    height: '20px'
  },
  border: {border: `1px solid ${theme.colors.grey.light}`},
  statusColumn: {width: 200},
  quantityColumn: {width: 150}
});
