// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {Checkbox, ListItem, ListItemIcon, ListItemText, makeStyles} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {get, set, unset} from 'lodash';

const useStyles = makeStyles((theme) => ({
    menuRoot: {
        padding: theme.spacing(0),
        margin: theme.spacing(0),
    },
    menuList: {padding: theme.spacing(0),},
    menuButton: {
        borderTopColor: theme.colors.grey.light,
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        padding: theme.spacing(1),
        paddingBottom: theme.spacing(0),
        minWidth: theme.spacing(25),
        display: 'flex',
        justifyContent: 'flex-end',
    }
}));

export const HoopsQueryFilterList = ({
    options = [],
    value = {},
    onChange = () => null,
}) => {
    const classes = useStyles();
    const [_value, _setValue] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        // We want to do an initial load
        if (!isLoaded && value.in && Object.keys(value.in).length && options.length) {
            onChange({filterValue: value, chips: getChips(value), forceApplyChips: true});
            setIsLoaded(true);
        } else {
            _setValue(value);
        }
      // TODO: FIX HOOK DEPENDENCIES
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, isLoaded, options, onChange ]);

    const getChips = (filter = {}) => {
        if (!filter || !filter.in) {
            return [];
        }

        // Simply use the label as the chip label
        return filter.in.map(
            (filterValue) => {
                const option = options.find((option) => option.value === filterValue);
                return ({
                    value: filterValue,
                    label: option?.label,
                    onRemove: (filter, filterKey, value) => {
                        const filterKeyParts = filterKey.split('.');
                        const parentKey = filterKeyParts.length > 1 ? filterKeyParts[0] : undefined;
                        const absoluteKey = `_operators.${filterKey}`;
                        const result = toggleFilterValue(get(filter, absoluteKey), value);

                        // Empty the filter for this filter and possbily parent
                        if (result === undefined) {
                            unset(filter, absoluteKey); // remove from the filter

                            // This could be cleaner
                            // we are checking if the parent is empty and removing if it is
                            if (parentKey && parentKey !== '_operators') {
                                const absoluteParentKey = `_operators.${parentKey}`;
                                const parent = get(filter, absoluteParentKey);
                                if (Object.keys(parent).length === 0) {
                                    unset(filter, absoluteParentKey);
                                }
                            }
                            return Object.assign({}, filter);
                        }

                        // update the filter
                        return set(filter, absoluteKey, result);
                    }
                });
            }
        );
    };

    const toggleFilterValue = (filterValue, value) => {
        const exists = filterValue.in && filterValue.in.indexOf(value) !== -1;

        // toggle if the item is in the filter
        const result = exists
            ? filterValue.in.filter((item) => item !== value)
            : filterValue.in ? [...filterValue.in, value] : [value];

        if (result.length) {
            return {in: result};
        }

        return undefined;
    };

    const handleChange = (option) => {
        const {value} = option;
        _setValue((prev) => {
            const result = toggleFilterValue(prev, value);
            onChange({filterValue: result, chips: getChips(result)});
            return result;
        });
    };

    const isChecked = (option) => get(_value, 'in') ? _value.in.indexOf(option.value) !== -1 : false;

    const HoopsDataFilterMenuItem = ({option}) => (
            <ListItem role={undefined} dense button onClick={() => handleChange(option)} className={classes.menuList}>
                <ListItemIcon style={{minWidth: 'unset'}}>
                    <Checkbox
                        color='primary'
                        checked={isChecked(option)}
                        disableRipple
                    />
                </ListItemIcon>
                <ListItemText primary={option.label} />
            </ListItem>
        );

    return (
        <>
            {options.map((option) => <HoopsDataFilterMenuItem key={option.value} option={option} />)}
        </>
    );
};

export default HoopsQueryFilterList;
