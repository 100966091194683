import {createGraphQLClient} from './GraphQLService';
import {
  GQL_GET_BILLING,
  GQL_ADD_PAYMENT_METHOD,
  GQL_DELETE_PAYMENT_METHOD,
  GQL_GET_INVOICES,
  GQL_GET_PAYMENT_METHODS,
  GQL_GET_UPCOMING_INVOICE,
  GQL_SET_DEFAULT_PAYMENT_METHOD,
  GQL_RETRY_DEFAULT_PAYMENT_METHOD,
  GQL_GET_TRIAL_SUBSCRIPTION_INFO,
} from '../queries/billing';

const client = createGraphQLClient({});

export class BillingService {
  static getUpcomingInvoice() {
    return client.query({
      query: GQL_GET_UPCOMING_INVOICE,
      fetchPolicy: 'no-cache',
    });
  }

  static getBilling() {
    return client.query({
      query: GQL_GET_BILLING,
      fetchPolicy: 'no-cache',
    });
  }

  static getInvoices() {
    return client.query({query: GQL_GET_INVOICES,});
  }

  static getPaymentMethods(options) {
    return client.query({
      query: GQL_GET_PAYMENT_METHODS,
      ...options,
    });
  }

  static getTrialSubscriptionInfo() {
    return client.query({
      query: GQL_GET_TRIAL_SUBSCRIPTION_INFO,
      fetchPolicy: 'no-cache',
    });
  }

  static addPaymentMethod(token) {
    return client.mutate({
      mutation: GQL_ADD_PAYMENT_METHOD,
      variables: {data: {token},},
    });
  }

  static deletePaymentMethod(paymentMethodId) {
    return client.mutate({
      mutation: GQL_DELETE_PAYMENT_METHOD,
      variables: {paymentMethodId,},
    });
  }

  static setDefaultPaymentMethod(paymentMethodId) {
    return client.mutate({
      mutation: GQL_SET_DEFAULT_PAYMENT_METHOD,
      variables: {paymentMethodId,},
    });
  }

  static retryStripePayment({source}) {
    return client.mutate({
      mutation: GQL_RETRY_DEFAULT_PAYMENT_METHOD,
      variables: {source,},
    });
  }
}
