// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import {Icon, IconButton, makeStyles, Menu, MenuItem} from '@material-ui/core';
import React, {useState} from 'react';
import {actionMenu, itemBuilder} from '../../../ui/theme/hoops';

const useStyles = makeStyles((theme) => ({
    ...itemBuilder,
    ...actionMenu,
}));

const ActionMenu = ({
    rowIndex = null,
    onDuplicate = () => null,
    onOverrideQuantityOpen = () => null,
    onOverrideQuantityClose = () => null,
    onDelete = () => null,
}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                onClick={handleClick}
                variant={'outlined'}
                size={'small'}
            >
                <Icon>more_horiz</Icon>
            </IconButton>
            <Menu
                elevation={1}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                className={classes.actionMenuStyles}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem
                    onClick={() => {
                        onDuplicate(rowIndex);
                        handleClose();
                    }}
                >
                    <Icon>file_copy</Icon>
                    Clone
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        onOverrideQuantityOpen(rowIndex);
                        handleClose();
                    }}
                >
                    <Icon>dynamic_feed</Icon>
                    Override Quantity
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        onDelete(rowIndex);
                        handleClose();
                    }}
                >
                    <Icon>delete</Icon>
                    Delete
                </MenuItem>
            </Menu>
        </>
    );
};
export default ActionMenu;
