// TODO: fix eslint disable
/* eslint-disable no-unused-vars, */

export class DefaultMarkup {
    constructor(treeBranchObj, assignedMarkupArray) {
        this.treeBranchObj = treeBranchObj;
        this.assignedMarkupArray = assignedMarkupArray;
    }

    // markup index search
    get markupAssignedIndex() {
        return this.assignedMarkupArray && this.assignedMarkupArray.findIndex((el) => {

            const elClone = {...el};
            delete elClone.markup;
            delete elClone.markupId;

            let matches = 0;

            for (const [key, value] of Object.entries(elClone)) {

                if (this.treeBranchObj[key] === value) {
                    matches++;
                }
            }

            return (matches === 5);

        });
    }

    get isAssignedMarkup() { return this.markupAssignedIndex > -1; }
    get isParentOfMarkup() { return this.parentAssignedMarkupArray.length > 0; }
    get isChildOfMarkup() { return !!this.isChildOfMarkupId; }
    get assignedMarkupName() {
        return this.isAssignedMarkup
            ? this.assignedMarkupArray && this.assignedMarkupArray[this.markupAssignedIndex].markup.name
            : null;
    }
    get assignedMarkupId() {
        return this.isAssignedMarkup
            ? this.assignedMarkupArray && this.assignedMarkupArray[this.markupAssignedIndex].markupId
            : null;
    }

    get isChildOfMarkupId() {
      return this.isChildOfMarkupObj?.markupId;
    }

    get isChildOfMarkupObj() {
        const index = this.assignedMarkupArray?.findIndex((el, idx) => {
            const elClone = {...el};
            delete elClone.markup;
            delete elClone.markupId;

            let matches = 0;
            let exactMatches = 0;

            for (const [key, value] of Object.entries(elClone)) {

                if (this.treeBranchObj[key] === value) {
                    matches++;
                    exactMatches++;
                } else if (value === null) {
                    matches++;
                }
            }
            return (matches === 5 && exactMatches !== 5);
        });

        return index > -1 ? this.assignedMarkupArray[index] : null;
    }

    get childOfAssignedMarkupArray() {

        let childArray = [];

        this.assignedMarkupArray?.forEach((el, idx) => {
            const elClone = {...el};
            delete elClone.markup;
            delete elClone.markupId;

            let matches = 0;
            let exactMatches = 0;

            for (const [key, value] of Object.entries(elClone)) {

                if (this.treeBranchObj[key] === value) {
                    matches++;
                    exactMatches++;
                } else if (value === null) {
                    matches++;
                }
            }
            if (matches === 5  && exactMatches !== 5) {childArray.push(el);}
        });

        return (childArray);
    }

    get parentAssignedMarkupArray() {
        if (!this.assignedMarkupArray) {return [];}

        let childArray = [];

        this.assignedMarkupArray.forEach((el) => {
            const elClone = {...el};
            delete elClone.markup;
            delete elClone.markupId;

            let matches = 0;
            let exactMatches = 0;

            for (const [key, value] of Object.entries(elClone)) {

                if (this.treeBranchObj[key] === value) {
                    matches++;
                    exactMatches++;
                } else if (this.treeBranchObj[key] === null) {
                    matches++;
                } else {
                    return;
                }
            }

            if (matches === 5  && exactMatches !== 5) {childArray.push(el);}
        });

        return (childArray);

    }

}
