import React from 'react';
import classNames from 'classnames';
import {HoopsPropTypes} from '../utils';
import {registerGlobalStyle} from '../../theme';

registerGlobalStyle('.text.text-caption', (theme) => ({
  '&.medium': {fontSize: '0.875rem'},
  color: theme.colors.text.medium,
  fontWeight: theme.typography.light,
  lineHeight: 1.25,
}));

export function CaptionText({className, text, medium, small, children}) {
  return (
    <span className={classNames([className, 'text text-caption', medium && 'medium', small && 'small'])}>{text ?? children}</span>
  );
}

CaptionText.propTypes = {
  className: HoopsPropTypes.className,
  medium: HoopsPropTypes.bool,
  small: HoopsPropTypes.bool,
  text: HoopsPropTypes.string,
  children: HoopsPropTypes.children,
};
