// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {UserLoggedIn, SetUserData} from '../../../actions/action-types/user';
import XeroSignUpForm from './XeroSignupForm';
import {REGISTER_FIELD_GROUP_USER} from '../../../constants';
import User from '../../../core/auth';
import Grid from '@material-ui/core/Grid';

class RegisterXeroUser extends React.Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.login = this.login.bind(this);
  }

  login(token) {
    localStorage.setItem('userToken', token);
    User.getUser(token)
      .then((data) => {
        this.props.dispatch(SetUserData(data));
      })
      .then(() => {
        this.props.dispatch(UserLoggedIn(true));
      });
  }

  submit(values) {
    User.loginRegister(values, '/api/auth/register').then((data) => {
      if (data && data['access_token']) {
        this.login(data['access_token']);
      }
    });
  }

  render() {
    return (
      <div>
        <Grid container>
          <XeroSignUpForm
            onSubmit={this.submit}
            formGroup={REGISTER_FIELD_GROUP_USER}
          />
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {userInfo: state.userInfoReducer};
}

RegisterXeroUser = withRouter(RegisterXeroUser);
export default connect(mapStateToProps)(RegisterXeroUser);
