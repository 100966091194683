import * as React from 'react';
import {Grid} from '@material-ui/core';
import FormHookTextField from './FormHookTextField';
import {useFormContext} from 'react-hook-form';
import {useEffect} from 'react';

export const FormHookAddressField = ({showAddressLine2, variant, type}) => {
  const {control,setValue, errors} = useFormContext(); // retrieve all hook methods
  useEffect(() => {
    setValue('label', type);
  }, [type, setValue]);
  return (<Grid container spacing={2}>

    <Grid item xs={12}>
      <FormHookTextField
                         control={control}
                         name={'_id'}
                         type={'hidden'}
      />
      <FormHookTextField
                         control={control}
                         name={'label'}
                         type={'hidden'}
                         value={type}
      />
      <FormHookTextField fullWidth
                         variant={variant}
                         control={control}
                         errors={errors}
                         label={'Address'}
                         name={'address1'}
                         type={'text'}
      />
    </Grid>
    {showAddressLine2 &&
      <Grid item xs={12}>
        <FormHookTextField fullWidth
                           variant={variant}
                           control={control}
                           errors={errors}
                           label={'Address Line 2'}
                           name={'address2'}
                           type={'text'}
        />
      </Grid>
    }
    <Grid item xs={6}>
      <FormHookTextField fullWidth
                         variant={variant}
                         control={control}
                         errors={errors}
                         label={'City'}
                         name={'city'}
                         type={'text'}
      />
    </Grid>
    <Grid item xs={6}>
      <FormHookTextField fullWidth
                         variant={variant}
                         control={control}
                         errors={errors}
                         label={'State'}
                         name={'state'}
                         type={'text'}
      />
    </Grid>
    <Grid item xs={6}>
      <FormHookTextField fullWidth
                         variant={variant}
                         control={control}
                         errors={errors}
                         label={'Postcode'}
                         name={'postcode'}
                         type={'text'}
      />
    </Grid>
    <Grid item xs={6}>
      <FormHookTextField fullWidth
                         variant={variant}
                         control={control}
                         errors={errors}
                         label={'Country'}
                         name={'country'}
                         type={'text'}
      />
    </Grid>
  </Grid>);
};

