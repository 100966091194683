import Grid from '@material-ui/core/Grid';
import {Typography} from '@material-ui/core';
import * as numeral from 'numeral';
import * as pluralize from 'pluralize';
import IconButton from '@material-ui/core/IconButton';
import {Delete} from '@material-ui/icons';
import React from 'react';
import {get} from 'lodash';

function SingleFile({file, content, onRemove}) {
  const lines = get(content, 'lines');
  return (
    <>
      <Grid container justifyContent={'space-between'} alignItems={'center'}>
        <Grid item>
          <Typography variant={'body2'}>
            {file.filename} ({numeral(file.contentLength).format('0.0b')})
            {lines && <span>- {numeral(lines).format('0,0')} {pluralize('Line', lines)}</span>}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={onRemove}>
            <Delete size={'small'}/>
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
}

export default SingleFile;
