export function toFloat(value = '', defaultValue = 0) {
  const safeValue = value ? value : `${defaultValue}`;
  const parsedValue = safeValue.toString().replace(/,/g, '');
  return (isNaN(parseFloat(parsedValue)) ? defaultValue : parseFloat(parsedValue));
}

function _reduceVariations(itemBuilder) {
  const {variations} = itemBuilder;

  if (variations && variations.length) {
    return variations.reduce((sum, variation) => {
      let {quantity, buyPrice, sellPrice, additionalCost} = variation;

      return {
        quantity: sum.quantity + toFloat(quantity),
        buyPrice: sum.buyPrice + ((toFloat(buyPrice) + toFloat(additionalCost)) * toFloat(quantity)),
        sellPrice: sum.sellPrice + (toFloat(sellPrice) * toFloat(quantity))
      };
    }, {
      quantity: 0,
      buyPrice: 0,
      sellPrice: 0
    });
  }
  return {
    quantity: 0,
    buyPrice: 0,
    sellPrice: 0
  };

}

function _reduceDecorations(itemBuilder, quantity) {
  const {decorationCosts} = itemBuilder;

  if (decorationCosts && decorationCosts.length) {
    return decorationCosts.reduce((sum, decoration) => ({
        buyPrice: sum.buyPrice + toFloat(decoration.setupPrice) + ((toFloat(decoration.buyPrice) + toFloat(decoration.additionalCost)) * quantity),
        sellPrice: sum.sellPrice + (toFloat(decoration.sellPrice) * quantity)
      }), {
      buyPrice: 0,
      sellPrice: 0
    });
  }
  return {
    buyPrice: 0,
    sellPrice: 0
  };
}

function _reduceAdditionalCosts(itemBuilder) {
  const {additionalCosts} = itemBuilder;

  if (additionalCosts && additionalCosts.length) {
    return additionalCosts.reduce((sum, additionalCost) => ({
        buyPrice: sum.buyPrice + toFloat(additionalCost.buyPrice),
        sellPrice: sum.sellPrice + toFloat(additionalCost.sellPrice)
      }));
  }
  return {
    buyPrice: 0,
    sellPrice: 0
  };
}

export function calculateTotals(itemBuilder) {
  itemBuilder = itemBuilder || {};

  const {quantity, buyPrice: variationsBuyPrice, sellPrice: variationsSellPrice} = _reduceVariations(itemBuilder);
  const {buyPrice: decorationsBuyPrice, sellPrice: decorationsSellPrice} = _reduceDecorations(itemBuilder, quantity);
  const {buyPrice: additionalCostsBuyPrice, sellPrice: additionalCostsSellPrice} = _reduceAdditionalCosts(itemBuilder);

  const totalBuyPrice = (
    toFloat(variationsBuyPrice) +
    toFloat(decorationsBuyPrice) +
    toFloat(additionalCostsBuyPrice)
  );

  const totalSellPrice = (
    toFloat(variationsSellPrice) +
    toFloat(decorationsSellPrice) +
    toFloat(additionalCostsSellPrice)
  );

  const margin = (totalSellPrice - totalBuyPrice) / totalSellPrice;

  const buyPrice = totalBuyPrice / toFloat(quantity, 1);
  const sellPrice = totalSellPrice / toFloat(quantity, 1);

  return {
    ...itemBuilder,
    quantity,
    buyPrice,
    sellPrice,
    variationsBuyPrice,
    decorationsBuyPrice,
    additionalCostsBuyPrice,
    variationsSellPrice,
    decorationsSellPrice,
    additionalCostsSellPrice,
    margin
  };
}
