import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/en';
import 'dayjs/locale/en-au';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/en-nz';
dayjs.extend(utc);
dayjs.extend(isSameOrAfter);
dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(isTomorrow);
dayjs.extend(localizedFormat);

const locale = navigator.language;
dayjs.locale(locale);

export function formatDateTimeShort(d, country = locale) {
  if (!d) {
    return '';
  }
  const date = new Date(d);

  const options = {
    dateStyle: 'medium',
    timeStyle: 'short',
  };

  return date.toLocaleString(country, options);
}

export function formatDateShort(d, country = locale) {
  if (!d) {
    return '';
  }
  const date = new Date(d);

  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };

  return date.toLocaleDateString(country, options);
}

export function formatDateLong(d, country = locale) {
  const date = new Date(d);

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  return date.toLocaleDateString(country, options);
}

export function formatIso(d) {
  return dayjs(d).format();
}

export function formatTime(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  return hours + ':' + minutes + ' ' + ampm;
}

export function formatDateTimeRelative(date) {
  date = dayjs(date);

  if (date.isToday()) {
    return date.format('[Today at] LT');
  }
  if (date.isYesterday()) {
    return date.format('[Yesterday at] LT');
  }
  if (date.isTomorrow()) {
    return date.format('[Tomorrow at] LT');
  }
  if (date.isSameOrAfter(dayjs().startOf('week')) && date.isBefore(dayjs().endOf('week'))) {
    return date.format('dddd [at] LT');
  }

  return date.format(`[${date.toDate().toLocaleDateString(locale, {year: 'numeric', month: 'long', day: 'numeric', weekday: 'long'})}] [at] LT`);
}

export function formatDateRelative(date) {
  date = dayjs(date);

  if (date.isToday()) {
    return 'Today';
  }
  if (date.isYesterday()) {
    return 'Yesterday';
  }
  if (date.isTomorrow()) {
    return 'Tomorrow';
  }
  if (date.isSameOrAfter(dayjs().startOf('week')) && date.isBefore(dayjs().endOf('week'))) {
    return date.format('dddd');
  }

  return date.toDate().toLocaleDateString(locale, {year: 'numeric', month: 'long', day: 'numeric', weekday: 'long'});
}
