// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {Grid, TextField} from '@material-ui/core';
import React, {useCallback, useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';

const fieldDefaults = {
    fullWidth: true,
    variant: 'standard'
};

export const ResourceEdit = ({resource = {}, onChange = () => null, onFormValidation = () => null}) => {
    const {watch, control, reset, errors, formState} = useForm({defaultValues: resource, mode: 'onBlur'});
    const all = watch();

    useEffect(() => {
        reset(resource);
    }, [resource, reset]);

    const handleChange = useCallback(
        (resource) => {
            onChange(resource);
        }, [onChange]);
        onFormValidation(formState.isValid);
    handleChange(all);

    return (

        <form autoComplete='off'>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Controller as={TextField} name='title' control={control} label='Resource Name' required rules={{required: true}} error={errors.title ? true : false} helperText={errors.title ? 'Resource name is required' : ''} {...fieldDefaults} />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller as={TextField} name='description' control={control} label='Description' defaultValue='' {...fieldDefaults} {...{rows: 4, multiline: true}} />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller as={TextField} name='capacity' control={control} label='Capacity' {...fieldDefaults} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default ResourceEdit;
