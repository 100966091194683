import theme from '../theme';

//these styles are used on the
//for any react hook form
export const formStyles = {
    inputStyles: {
        '& .MuiOutlinedInput-root': {
            background: theme.colors.white,
            paddingRight: 8
        },
        '& .MuiOutlinedInput-root.Mui-disabled': {background: theme.colors.grey.ultraLight},
        '& .MuiSelect-outlined.MuiSelect-outlined': {
            background: theme.colors.white,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },

    },
    labelText: {
        color: theme.colors.grey.main,
        display: 'block'
    },
    loadingFieldStyle: {'& .MuiOutlinedInput-root': {color: theme.colors.grey.light,},}
};
