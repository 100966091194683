import {gql} from 'apollo-boost';

const GQL_INTEGRATION_DETAIL_FRAGMENT = gql`
  fragment IntegrationDetailFragment on Integration {
    _id
    type
    status
    apiKey
    accountId
    accountKey
    username
    createdAt
    createdById
  }
`;

export const GQL_INTEGRATION_LIST_FRAGMENT = gql`
  fragment IntegrationListFragment on Integration {
    _id
    type
    status
    apiKey
    accountId
    accountKey
    username
  }
`;

export const GQL_GET_INTEGRATION = gql`
  ${GQL_INTEGRATION_DETAIL_FRAGMENT}
  query getIntegration($_id: MongoID!) {
    integrationOne(filter: { _id: $_id }) {
      ...IntegrationDetailFragment
    }
  }
`;

export const GQL_GET_INTEGRATIONS = gql`
  ${GQL_INTEGRATION_LIST_FRAGMENT}
  query getIntegrations($filter: FilterFindManyIntegrationInput, $sort: SortFindManyIntegrationInput) {
    integrationMany(filter: $filter, sort: $sort) {
      ...IntegrationListFragment
    }
  }
`;

export const GQL_CREATE_INTEGRATION = gql`
    ${GQL_INTEGRATION_DETAIL_FRAGMENT}
    mutation createIntegration($record: CreateOneIntegrationInput!) {
        integrationCreateOne(record: $record) {
            recordId
            record {
                ...IntegrationDetailFragment
            }
        }
    }
`;

export const GQL_TEST_INTEGRATION = gql`
    query integrationTestCredentials($record: CreateOneIntegrationInput!) {
        integrationTestCredentials(record: $record)
    }
`;

export const GQL_UPDATE_INTEGRATION = gql`
  ${GQL_INTEGRATION_DETAIL_FRAGMENT}
  mutation updateIntegration($_id: MongoID!, $record: UpdateByIdIntegrationInput!) {
    integrationUpdateById(_id: $_id, record: $record) {
      recordId
      record {
        ...IntegrationDetailFragment
      }
    }
  }
`;

export const GQL_DELETE_INTEGRATION = gql`
  mutation deleteIntegration($_id: MongoID!) {
    integrationRemoveById(_id: $_id) {
      recordId
    }
  }
`;

export const GQL_INTEGRATION_PAGINATION = gql`
  query IntegrationPagination(
    $filter: FilterFindManyIntegrationInput
    $sort: SortFindManyIntegrationInput
    $perPage: Int
    $page: Int
  ) {
    integrationPagination(
      filter: $filter
      sort: $sort
      perPage: $perPage
      page: $page
    ) {
      items {
        ...IntegrationListFragment
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${GQL_INTEGRATION_LIST_FRAGMENT}
`;
