import {useCallback, useEffect, useRef, useState} from 'react';
import {debounce, isEqual} from 'lodash';

export function useDebounceState(initState, wait = 300) {
  const [state, _setState] = useState(initState);

  const setState = useCallback((newState) => {
    if (typeof newState === 'function') {
      _setState((prev) => {
        newState = newState(prev);
        return isEqual(newState, prev) ? prev : newState;
      });
    } else {
      _setState((prev) => isEqual(newState, prev) ? prev : newState);
    }
  }, []);

  const debounceSetStateRef = useRef(wait != null ? debounce(setState, wait) : setState);

  // When unmounted, cancel any outstanding timers
  useEffect(() => debounceSetStateRef.current.cancel, []);

  return [state, debounceSetStateRef.current];
}
