import {
  GQL_ADD_PROOF_NOTE, GQL_APPROVE_PROOF, GQL_DELETE_PROOF, GQL_EMAIL_PROOFS, GQL_GET_JOB_PROOFS,
  GQL_GET_PROOF,
  GQL_REJECT_PROOF
} from '../queries/proofs';
import EmailTemplateService from './EmailTemplateService';
import {createGraphQLClient} from './GraphQLService';

const client = createGraphQLClient({});

export default class ProofService {

  static getProof(_id) {
    return client.mutate({
      mutation: GQL_GET_PROOF,
      fetchPolicy: 'no-cache',
      variables: {_id}
    });
  }

  static getProofs(jobId, tokenId = null) {
    return client.query({
      query: GQL_GET_JOB_PROOFS,
      fetchPolicy: 'no-cache',
      variables: {
        jobId,
        tokenId
      }
    });
  }

  static addProofNote(proofId, noteData) {
    return client.mutate({
      mutation: GQL_ADD_PROOF_NOTE,
      variables: {
        proofId,
        noteData
      }
    });
  }

  static rejectProof(proofId, noteData, token) {
    return client.mutate({
      mutation: GQL_REJECT_PROOF,
      variables: {
        _id: proofId,
        data: noteData,
        token
      }
    });
  }

  static approveProof(proofId, token) {
    return client.mutate({
      mutation: GQL_APPROVE_PROOF,
      variables: {_id: proofId, token}
    });
  }

  static deleteProof(proofId) {
    return client.mutate({
      mutation: GQL_DELETE_PROOF,
      variables: {_id: proofId,}
    });
  }

  static sendProofs(jobId, formValues) {
    return client.mutate({
      mutation: GQL_EMAIL_PROOFS,
      variables: {
        _id: jobId,
        ...(formValues.viaEmail && {emailOptions: EmailTemplateService.cleanEmailContent(formValues)}),
        ...(formValues.viaSms && {smsOptions: EmailTemplateService.cleanSmsContent(formValues)}),
      }
    });
  }
}
