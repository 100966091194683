// TODO: fix eslint disable
/* eslint-disable no-class-assign, no-shadow */

import React, {Component} from 'react';
import DialogTitle from '../../../DialogTitle';
import DialogActions from '../../../DialogActions';
import {
  Dialog,
  Typography,
  DialogContent,
  Grid,
  Button,
  MenuItem,
  MuiThemeProvider
 } from '@material-ui/core';
import {connect} from 'react-redux';
import {closeProofNotesModal as _closeProofNotesModal} from '../../../../../actions/modals';
import Note from './Note';
import Divider from '@material-ui/core/Divider';
import {withStyles} from '@material-ui/core/styles';
import {GetUsers as _getUsers} from '../../../../../actions/action-types/user';
import {
  getProof as _getProof,
  getProofs as _getProofs
} from '../../../../../actions/proofs';
import {Field, reduxForm, formValueSelector, reset} from 'redux-form';
import {renderTextField, renderSelectField} from '../../../formHelpers/fields';
import theme from '../../../../../ui/theme/index';
import red from '../../../../../ui/theme/red';
import ProofService from '../../../../../servicesOld/ProofService';
import {required} from '../../../formHelpers/validation';

const styles = (theme) => ({
  notesContainer: {
    maxHeight: theme.spacing(50),
    overflowY: 'scroll'
  },
  divider: {margin: `${theme.spacing(2)}px ${theme.spacing(0)}px`},
});

class ProofNotesModal extends Component {

  handleFormSubmit = async (values) => {
    const {
      proof, getProof, job, getProofs, resetForm,
      modalState: {context: {rejecting}}
    } = this.props;
    const note = {content: values.note, assigneeId: values.assignedTo};

    if (rejecting) {
      await ProofService.rejectProof(proof._id, note);
      setTimeout(this.handleClose, 200);
      getProofs(job._id);
    } else {
      await ProofService.addProofNote(proof._id, note);
    }
    getProof(proof._id);
    resetForm();
  };

  handleClose = () => {
    const {resetForm, closeModal} = this.props;
    resetForm();
    closeModal();
  };

  render() {
    const {
      classes,
      job,
      Users,
      handleSubmit,
      assignedTo,
      proof: {notes},
      modalState: {isOpen, context: {rejecting}}
    } = this.props;
    return (
      <Dialog
        open={isOpen}
        onClose={this.handleClose}
        fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogTitle onClick={this.handleClose}>
          <Typography variant={'h4'}>{rejecting ? 'Reject Proof' : 'Proof Notes'}</Typography>
        </DialogTitle>
        <form onSubmit={handleSubmit(this.handleFormSubmit)}>
          <DialogContent>
            <Grid container component={'div'} spacing={2}>
              <Grid container item xs={12} spacing={3} className={classes.notesContainer}>
                {notes && notes.map((note, index) =>
                  <Note key={index} proofNote={note} job={job}/>
                )}
              </Grid>
              <Grid item xs={12}>
                <Divider variant='middle' className={classes.divider} />
              </Grid>
              <Grid container item xs={12} className={classes.noteFieldContainer}>
                <Field
                  name='note'
                  component={renderTextField}
                  label='Add Note *'
                  multiline
                  rows='4'
                  validate={required}
                  placeholder='Type your note here...'
                  className={classes.noteField}
                  autoFocus
                  variant='outlined'
                />
              </Grid>
              <Grid container item xs={12} >
                  <Field
                    name='assignedTo'
                    label='Assign to'
                    className={classes.usersDropdown}
                    value={assignedTo}
                    fullWidth
                    component={renderSelectField}
                  >
                    {Users.map((User, index) => (
                      <MenuItem key={index} value={User._id}>
                        {User.fullName}
                      </MenuItem>
                    ))}
                  </Field>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent={'space-between'} component={'div'}>
              <Grid item component={'div'}>
                <Button variant={'text'} color={'secondary'} onClick={this.handleClose}>Cancel</Button>
              </Grid>
              <Grid item component={'div'}>
                <MuiThemeProvider theme={rejecting ? red : theme}>
                  <Button variant={'contained'} color={'primary'}
                          type={'submit'}>{rejecting ? 'Reject Proof' : 'Save'}</Button>
                </MuiThemeProvider>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  const assignedTo = formValueSelector('noteForm')(state, 'assignedTo');
  return {
    Users: state.authReducer.users,
    modalState: state.modalReducer.jobProofNotesModal,
    proof: state.proofReducer.activeProof,
    job: state.jobsReducer.activeJob,
    assignedTo
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => dispatch(_closeProofNotesModal()),
    getUsers: () => dispatch(_getUsers()),
    resetForm: () => dispatch(reset('proofNotes')),
    getProof: (_id) => dispatch(_getProof(_id)),
    getProofs: (jobId) => dispatch(_getProofs(jobId))
  };
}

ProofNotesModal = reduxForm({
  form: 'proofNotes',
  enableReinitialize: true
})(ProofNotesModal);
ProofNotesModal = connect(mapStateToProps, mapDispatchToProps)(ProofNotesModal);
ProofNotesModal = withStyles(styles)(ProofNotesModal);

export default ProofNotesModal;
