import {Icon, IconButton, ListItemIcon, Menu, MenuItem, MuiThemeProvider} from '@material-ui/core';
import Link from '@material-ui/core/Link';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {openProofNotesModal, openProofViewModal} from '../../../actions/modals';
import {approveProof, deleteProof} from '../../../actions/proofs';
import green from '../../../ui/theme/green';

const ProofsTableActionCell = ({proof = {}}) => {
  const dispatch = useDispatch();

  const [anchorEl , setAnchorEl] = useState(null);

  const handleClick = (e) => {
      setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
      setAnchorEl(null);
  };

  const handleProofViewModalOpen = () => {
    openProofViewModal(proof);
    handleClose();
  };

  const handleApproveProof = () => {
    dispatch(approveProof(proof));
    handleClose();
  };

  const handleDeleteProof = () => {
    dispatch(deleteProof(proof));
    handleClose();
  };

  const handleProofNotesModalOpen = () => {
    dispatch(openProofNotesModal({proofId: proof._id}));
    handleClose();
  };

  const handleProofRejectModalOpen = () => {
    dispatch(openProofNotesModal({proofId: proof._id, rejecting: true}));
    handleClose();
  };

    return (
      <>
        <IconButton
          variant={'outlined'}
          size={'small'}
          onClick={handleProofNotesModalOpen}
          data-intercom-target={'job-proofs-notes-button'}
        >
          <MuiThemeProvider theme={green}>
            <Icon color='primary'>chat_bubble</Icon>
          </MuiThemeProvider>
        </IconButton>
        <IconButton
          variant={'outlined'}
          size={'small'}
          onClick={handleProofViewModalOpen}
          data-intercom-target={'job-proofs-view-button'}
        >
          <Icon>remove_red_eye</Icon>
        </IconButton>
        {
          proof.file && <Link href={proof.file.url} underline={'none'} color={'textPrimary'}>
            <IconButton
                variant={'outlined'}
                size={'small'}
                data-intercom-target={'job-proofs-download-button'}
            >
              <Icon>cloud_download</Icon>
            </IconButton>
          </Link>
        }
        <IconButton
          variant={'outlined'}
          size={'small'}
          onClick={handleClick}
          data-intercom-target={'job-proofs-more-button'}
        >
          <Icon>more_horiz</Icon>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleProofViewModalOpen}>
            <ListItemIcon>
              <Icon>remove_red_eye</Icon>
            </ListItemIcon>
            View
          </MenuItem>
          <MenuItem onClick={handleProofNotesModalOpen}>
            <ListItemIcon>
              <Icon>chat_bubble</Icon>
            </ListItemIcon>
            Comment
          </MenuItem>
          {
            proof.file && <Link href={proof.file.url} color={'textPrimary'} underline={'none'}>
              <MenuItem>
                <ListItemIcon>
                  <Icon>cloud_download</Icon>
                </ListItemIcon>
                Download
              </MenuItem>
            </Link>
          }
          <MenuItem onClick={handleApproveProof}
                    data-intercom-target={'job-proofs-approve-button'}>
            <ListItemIcon>
              <Icon>check_circle</Icon>
            </ListItemIcon>
            Approve
          </MenuItem>
          <MenuItem onClick={handleProofRejectModalOpen}
                    data-intercom-target={'job-proofs-reject-button'}>
            <ListItemIcon>
              <Icon>close</Icon>
            </ListItemIcon>
            Reject
          </MenuItem>
          <MenuItem disabled>
            <ListItemIcon>
              <Icon>edit</Icon>
            </ListItemIcon>
            Edit
          </MenuItem>
          <MenuItem onClick={handleDeleteProof}
                    data-intercom-target={'job-proofs-delete-button'}>
            <ListItemIcon>
              <Icon>delete</Icon>
            </ListItemIcon>
            Delete
          </MenuItem>
        </Menu>
      </>
    );
};

export default ProofsTableActionCell;
