import React, {useRef, useState} from 'react';
import LockIcon from '@material-ui/icons/Lock';
import {loadStripe} from '@stripe/stripe-js';
import {CardElement, Elements, useElements, useStripe} from '@stripe/react-stripe-js';
import Lottie from 'react-lottie';
import {Column, Row} from '../../componentsLib/Layout';
import {ModalDialog} from '../../componentsLib/Popovers';
import {BodyText, CaptionText, HeadingText} from '../../componentsLib/Text';
import {asCurrencyString} from '../../utils';
import {Button, Link} from '../../componentsLib/Basic';
import {useAnonSalesDocPaymentIntent} from '../../hooks/api';
import {hoopsTheme, registerGlobalStyle} from '../../theme';
import successAlertIcon from '../../assets/lotties/success-alert-icon.json';
import {HoopsPropTypes} from '../../componentsLib';

registerGlobalStyle('.stripe-payment-dialog', (theme) => ({
  '.modal-dialog-content': {
    minWidth: 600,
    minHeight: 512,
    width: 600,
    height: 512,
    padding: theme.spacing(0, 4, 4, 4),
  },
  '.payment-success': {padding: theme.spacing(3)},
  '.text': {width: 'unset',},
  '.dialog-title.text': {
    fontSize: '2.25rem',
    fontWeight: theme.typography.normal,
    textAlign: 'center',
  },
  '.text-body.text': {paddingInline: theme.spacing(3)},
  '.text-caption.text': {
    textAlign: 'center',
    fontSize: '1rem',
    margin: 0,
  },
  '#card-element': {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    border: `1px solid ${theme.colors.border.light}`,
    borderRadius: theme.spacing(1),
    '& .MuiOutlinedInput-notchedOutline': {border: 'none'}
  },
  '.amount': {
    margin: theme.spacing(2),
    paddingTop: theme.spacing(5),
    '.heading-text': {},
    '.text-body': {fontSize: '1rem'},
  },
  button: {
    alignSelf: 'center',
    marginBottom: theme.spacing(1),
  },
  '.error-message': {
    marginTop: theme.spacing(1),
    color: theme.colors.palette.red,
    lineHeight: 1.2,
    marginBottom: theme.spacing(-3),
  },
  '.stripe-message': {
    marginTop: theme.spacing(5),
    'svg': {color: theme.colors.text.highlight},
  }
}));

export function PayOnlineDialog({amountToPay, companyName, currencySymbol, salesDocId, stripeConnectedAccount, onClose, onSuccess}) {
  const stripePromiseRef = useRef(loadStripe(process.env.REACT_APP_STRIPE_KEY, {stripeAccount: stripeConnectedAccount}));

  return (
    <Elements stripe={stripePromiseRef.current}>
      <_PayOnlineDialog {...{amountToPay, companyName, currencySymbol, salesDocId, stripeConnectedAccount, onClose, onSuccess}}/>
    </Elements>
  );
}

PayOnlineDialog.propTypes = {
  amountToPay: HoopsPropTypes.number,
  companyName: HoopsPropTypes.string,
  currencySymbol: HoopsPropTypes.string,
  salesDocId: HoopsPropTypes.string,
  stripeConnectedAccount: HoopsPropTypes.string,
  onClose: HoopsPropTypes.func,
  onSuccess: HoopsPropTypes.func,
};

const successLottie = {
  loop: false,
  autoplay: true,
  animationData: successAlertIcon,
  rendererSettings: {preserveAspectRatio: 'xMidYMid slice'}
};

function _PayOnlineDialog({amountToPay, companyName, currencySymbol, salesDocId, onClose, onSuccess}) {
  const stripe = useStripe();
  const elements = useElements();
  const {['payment-intent']: paymentIntentApi} = useAnonSalesDocPaymentIntent();
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState();
  const [invoiceUrl, setInvoiceUrl] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handlePayOnline = async () => {
    try {
      setIsLoading(true);
      const {intent: clientSecret, link} = await paymentIntentApi({id: salesDocId}, {successMessage: false, throwOnError: true});
      if (!stripe || !elements) {
        return;
      }
      if (!clientSecret) {
        throw new Error(`This payment can not be processed, please contact ${companyName}`);
      }

      setInvoiceUrl(link);

      // Have Stripe do the payment
      const {paymentIntent, error: stripeError} = await stripe.confirmCardPayment(
        clientSecret,
        {payment_method: {card: elements.getElement(CardElement)}}
      );

      if (stripeError) {
        setError(stripeError.message);
        return;
      }

      if (paymentIntent.status === 'succeeded') {
        setIsPaymentSuccessful(true);
        onSuccess(true);
      }

      if (paymentIntent.status) {
        setError(paymentIntent.status);
      }
    } catch (e) {
      setError(e.response?.data?.message ?? e.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalDialog className={'stripe-payment-dialog'} okText={''} cancelText={''} title={'Accept & Pay Online'} onClose={onClose}>
      {!isPaymentSuccessful &&
        <>
          <CaptionText>
            Please enter your payment information
          </CaptionText>
          <Row className={'amount'} alignCenter justifySpaceBetween>
            <BodyText>Total Amount (inc Tax)</BodyText>
            <HeadingText x24>{currencySymbol} {asCurrencyString(amountToPay)}</HeadingText>
          </Row>
          <Row divider/>
          <CardElement
            id='card-element'
            options={{
              style: {
                base: {
                  color: '#666',
                  fontSize: '16px',
                },
                invalid: {color: hoopsTheme.colors.palette.red}
              },
              hidePostalCode: true
            }}
          />
          <Button
            navMain
            disabled={isLoading || !stripe || !elements || parseFloat(amountToPay) < 2}
            loading={isLoading}
            onClick={handlePayOnline}
            noWrap
            text={'Process Payment'}
          />
          {error &&
            <>
              <CaptionText text={'Unable to process payment at this time:'}/>
              <BodyText className={'error-message'} text={error}/>
            </>
          }
          <Row className={'stripe-message'} alignCenter justifyCenter gap>
            <LockIcon/>
            <CaptionText>Secured by Stripe with bank-level encryption</CaptionText>
          </Row>
        </>
      }
      {isPaymentSuccessful &&
        <div className='payment-success'>
          <Lottie
            options={successLottie}
            height={200}
            width={200}
          />
          <Column alignCenter gap>
            <CaptionText text={'Payment successful'}/>
            <CaptionText text={'Thank you'}/>
            {invoiceUrl &&
              <Link href={invoiceUrl}>View Invoice/Receipt</Link>
            }
          </Column>
        </div>
      }
    </ModalDialog>
  );
}
