// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React, {Component} from 'react';
import {withStyles} from '@material-ui/core';
import HoopsFormDialog from '../modal/HoopsFormDialog';
import {change, Field, Form, formValueSelector, reduxForm, submit} from 'redux-form';
import EditorField from '../companies/editor';
import {connect, useDispatch} from 'react-redux';
import {closeQuoteTerms as _closeQuoteTerms} from '../../actions/modals';
import Button from '@material-ui/core/Button';

const styles = () => ({});

function SaveButton() {
  const dispatch = useDispatch();

  return (
    <Button color={'primary'}
            variant={'contained'}
            onClick={() => dispatch(submit('quoteTermsForm'))}>
      Save
    </Button>
  );
}

class QuoteTermsModal extends Component {

  handleSubmit = ({terms}) => {
    const {changeQuoteTerms, closeQuoteTerms} = this.props;

    changeQuoteTerms(terms);
    closeQuoteTerms();
  };

  render() {
    const {open, handleSubmit, closeQuoteTerms} = this.props;

    return (
      <HoopsFormDialog title={'Quote Terms'}
                       open={open}
                       onCancel={closeQuoteTerms}
                       SaveButton={SaveButton}
      >
        <Form onSubmit={handleSubmit(this.handleSubmit)}>
          <Field name={'terms'}
                 component={EditorField}/>
        </Form>
      </HoopsFormDialog>
    );
  }
}

const selector = formValueSelector('quoteForm');
const initialValues = (state) => ({terms: selector(state, 'terms')});

const mapStateToProps = (state) => ({
    open: state.modalReducer.quoteTerms.open,
    initialValues: initialValues(state)
  });

const mapDispatchToProps = (dispatch) => ({
    closeQuoteTerms: () => dispatch(_closeQuoteTerms()),
    changeQuoteTerms: (terms) => dispatch(change('quoteForm', 'terms', terms))
  });

QuoteTermsModal = reduxForm({
  form: 'quoteTermsForm',
  enableReinitialize: true
})(QuoteTermsModal);
QuoteTermsModal = connect(mapStateToProps, mapDispatchToProps)(QuoteTermsModal);
QuoteTermsModal = withStyles(styles)(QuoteTermsModal);

export default QuoteTermsModal;
