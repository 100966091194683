// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {Button, CircularProgress, Fade, Grid, makeStyles, Typography} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import React, {useEffect, useState} from 'react';
import {HoopsTextField} from '../../componentsOld/HoopsTextField';
import HoopsFormDialog from '../../componentsOld/modal/HoopsFormDialog';
import theme from '../../ui/theme/index';
import {integrations} from '../../ui/theme/integrations';
import IntegrationService from './IntegrationService';
import {useGetIntegrationPromoStandardsProgress} from '../../hooks/api';
import {formatDateTimeRelative} from '../../utils';

const useStyles = makeStyles((theme) => (
  {
    ...integrations,
    iconStyle: {
      fontSize: '16px',
      color: theme.palette.secondary.main,
    },
    iconButton: {
      padding: '0 0 2px 0',
      marginLeft: '11px'
    },
    progress: {position: 'absolute',}
  }
));
const UTCHours = 12;

function PromoStandardsSyncProgress({vendorName}) {
  const classes = useStyles();
  const {data, isLoading} = useGetIntegrationPromoStandardsProgress(vendorName, {}, {refetchInterval: 10000});
  if (isLoading) {
    return <CircularProgress size={20} className={classes.progress}/>;
  }
  const {errorMessage, createProductsStatus, importCount, importProgressCount} = data;
  const nextSyncAt = new Date();
  if ( nextSyncAt.getUTCHours() > UTCHours){
    nextSyncAt.setUTCDate(nextSyncAt.getUTCDate() + 1);
    nextSyncAt.setUTCHours(UTCHours);
  }else{
    nextSyncAt.setUTCHours(UTCHours);
  }
  nextSyncAt.setUTCMinutes(0);
  const updatedAt = new Date(data.updatedAt);
  const createdAt = new Date(data.createdAt);
  if (createProductsStatus === 'COMPLETE') {
    return (
      <>
        <Typography variant='body2'>
          Last sync: {formatDateTimeRelative(updatedAt)}
        </Typography>
        <Typography variant='body2'>
          Next sync: {formatDateTimeRelative(nextSyncAt)}
        </Typography>
        <br/>
      </>
    );
  }
  if (createProductsStatus === 'IN PROGRESS') {
    return (
      <>
        <Typography variant='body2'>
          Last sync: {formatDateTimeRelative(createdAt)}
        </Typography>
        <Typography variant='body2'>
          Progress: <span className={classes.green}>{importProgressCount ? Math.ceil(100 * importProgressCount  / importCount)  : '0'}%</span>
        </Typography>
        <Typography variant='body2'>
          Next sync: {formatDateTimeRelative(nextSyncAt)}
        </Typography>
      </>
    );
  }
  if (createProductsStatus === 'ERROR') {
    return (
      <>
        <Typography variant='body2'>
          Last sync: {formatDateTimeRelative(createdAt)}
        </Typography>
        <br/>
        <Typography variant='body2'>Error: </Typography>
        <Typography variant='body2' className={classes.red}>{errorMessage}</Typography>
        <br/>
        <Typography variant='body2'>
          Next sync: {formatDateTimeRelative(nextSyncAt)}
        </Typography>
      </>
    );
  }
  return null;
}

export const PromoStandardButton = ({vendorName, vendorNameTitle = vendorName}) => {
  const generalErrorMessage = `Can not connect to ${vendorName}, please contact Hoops support.`;
  const classes = useStyles();
  const [connected, setConnected] = useState(null);
  const [user, setUser] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [testResults, setTestResults] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [credentials, setCredentials] = useState({});

  const handleGetConnection = () => {
    setLoading(true);
    setErrorMessage('');
    const url = `${process.env.REACT_APP_BASE_URL}/rest/promo-standards/credentials/${vendorName}`;
    IntegrationService.promoStandardsGet('GET', url)
      .then((response) => {
        if (response.ok || response.status === '404'){
          return response.json();
        }
        return false;
      })
      .then((response) => {
        setErrorMessage('');
        if (response) {
          if (response.promoStandards?.username) {
            setUser(response.promoStandards?.username);
            setConnected(true);
          } else {
            setConnected(false);
          }
        } else {
          setConnected(false);
          setErrorMessage(response.message);
        }
      })
      .catch((e) => {
        setErrorMessage(e.message);
        setTestResults(null);
        setConnected(false);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    handleGetConnection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorName]);

  const handleTestCredentials = async () => {
    setLoading(true);
    setTestResults(null);
    const PromoStandardsServiceConfig = {
      ...credentials,
      vendorName: vendorName,
    };
    IntegrationService.promoStandardsFetch('POST', `${process.env.REACT_APP_BASE_URL}/rest/promo-standards/credentials/test`, PromoStandardsServiceConfig)
      .then((response) => {
        setErrorMessage('');
        if (response) {
          return response.json();
        } else {
          return false;
        }
      })
      .then((response) => {
        if (!response || response.error) {
          setTestResults(false);
          setErrorMessage(response.message || generalErrorMessage);
        } else {
          setTestResults(true);
        }
      })
      .catch((e) => {
        setErrorMessage(e.message);
        setTestResults(null);
        setConnected(false);
      })
      .finally(() => setLoading(false));
  };

  const handleSaveCredentials = () => {

    setLoading(true);

    const PromoStandardsServiceConfig = {
      ...credentials,
      vendorName,
    };

    IntegrationService.promoStandardsFetch(connected ? 'PATCH' : 'POST', `${process.env.REACT_APP_BASE_URL}/rest/promo-standards/credentials/${vendorName}`, PromoStandardsServiceConfig)
      .then((response) => {
        setErrorMessage('');
        if (response) {
          return response.json();
        } else {
          return false;
        }
      })
      .then((response) => {
        if (!response || response.error) {
          setConnected(false);
          setErrorMessage(response.message || generalErrorMessage);
        } else {
          IntegrationService.promoStandardsFetch('POST', `${process.env.REACT_APP_BASE_URL}/rest/promo-standards/promostandards/${vendorName}`, PromoStandardsServiceConfig)
            .finally(() => {
              setErrorMessage('');
              setDialogOpen(false);
              setConnected(true);
              setUser(response.promoStandards?.username);
              setLoading(false);
            });
        }
      })
      .catch((e) => {
        setErrorMessage(e.message);
        setTestResults(null);
        setConnected(false);
        setLoading(false);
      });
  };

  const handleDelete = () => {
    setLoading(true);
    IntegrationService.promoStandardsFetch('DELETE', `${process.env.REACT_APP_BASE_URL}/rest/promo-standards/credentials/${vendorName}`)
      .then((response) => {
        setErrorMessage('');
        if (response) {
          return response.json();
        } else {
          return false;
        }
      })
      .then((response) => {
        if (!response) {
          setTestResults(null);
          setErrorMessage(response.message || generalErrorMessage);
        } else {
          setConnected(false);
        }
      })
      .catch((e) => {
        setErrorMessage(e.message);
        setTestResults(null);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>

      <HoopsFormDialog
        open={dialogOpen}
        title={`Connect to ${vendorNameTitle}`}
        DialogActionComponent={
          <Grid container
            justifyContent={'space-between'}
          >
            <Grid item>
              <Button variant={'text'}
                color={'secondary'}
                onClick={() => setDialogOpen(false)}
              >
                Cancel
              </Button>
            </Grid>

            <Grid item>

              <Button
                variant='contained'
                color='primary'
                disabled={!testResults}
                disableElevation
                onClick={() => handleSaveCredentials()}
              >
                {loading && testResults ? <><CircularProgress size={16} style={{marginRight: theme.spacing(1)}} />Connect</> : 'Connect'}
              </Button>
            </Grid>
          </Grid>

        }
        onCancel={() => {
          setDialogOpen(false);
          setTestResults(null);
        }}

      >
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
          spacing={2}
          style={{maxWidth: 500, margin: 'auto'}}
        >

          <Grid item xs={12}>
            <Typography variant='body2' className={classes.greyText} align='center'>Enter Your {vendorNameTitle} Account Credentials</Typography>
            <Typography variant='body2' className={classes.greyText} align='center' style={{fontSize: '.75rem'}}>
              Your account credentials to connect to alphabroder are usually the same credentials that you use to log in to the alphabroder website.
              If you receive the error &apos;user does not exist&apos;, use the login which can be found in your account details on the alphabroder website.
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <HoopsTextField
              name='user_name'
              label='Login'
              disabled={loading}
              onChange={(e) => {
                const username = e.target.value;
                setCredentials((prev) => ({...prev, username}));
              }}

            />
          </Grid>
          <Grid item xs={6}>
            <HoopsTextField
              label='Password'
              name='password'
              type='password'
              autoComplete='new-password'
              inputProps={{autoComplete: 'new-password'}}
              disabled={loading}
              onChange={(e) => {
                //needs to be done with an intermediate var if not you will ge this error: Warning: This synthetic event is reused for performance reasons. If you're seeing this, you're accessing the property `target` on a released/nullified synthetic event. This is set to null. If you must keep the original synthetic event around, use event.persist(). See https://fb.me/react-event-pooling for more informatio
                const password = e.target.value;
                setCredentials((prev) => ({...prev, password}));
              }}
            />
          </Grid>

          <Grid item xs={12} style={{textAlign: 'center'}}>
            <Fade in={loading} out={loading}>
              <div>
                {
                  loading &&
                  <Typography variant='body2' display='inline' style={{verticalAlign: 'top', lineHeight: 2, marginLeft: 8}} className={classes.greyText}>
                    {testResults
                      ? 'Setting up connection please wait...'
                      : 'Testing account credentials please wait...'
                    }
                  </Typography>
                }
              </div>
            </Fade>

            <Fade in={testResults !== null}>

              <div>
                {/* Test success */}
                {!loading && testResults === true && !connected &&
                  <>
                    <CheckCircleIcon className={classes.green} />
                    <Typography variant='body2' display='inline' style={{verticalAlign: 'top', lineHeight: 2, marginLeft: 8}} className={classes.green}>Success!</Typography>
                  </>
                }
                {/* Test failed*/}
                {!loading && testResults === false && !connected &&
                  <>
                    <ErrorIcon className={classes.red} />
                    <Typography variant='body2' display='inline' style={{verticalAlign: 'top', lineHeight: 2, marginLeft: 8}} className={classes.red}>{errorMessage}</Typography>
                  </>
                }
              </div>
            </Fade>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'center'}}>

            <Button variant='outlined' color='primary' disabled={loading} onClick={handleTestCredentials}>
              {loading && !testResults ? <><CircularProgress size={16} style={{marginRight: theme.spacing(1)}} />Test Credentials</> : 'Test Credentials'}
            </Button>

          </Grid>
        </Grid>

      </HoopsFormDialog>
      <Grid container
        direction='row'
        justifyContent='center'
        alignItems='flex-start'
      >
        {connected && <Grid item xs={12}>
          <Typography align={'center'}>
            <>Connected to <br />
              <strong>{user}</strong></>
            <PromoStandardsSyncProgress vendorName={vendorName}/>
          </Typography>
        </Grid>
        }

        <Grid item xs={12}>
          {
            errorMessage ? <>
              <ErrorIcon className={classes.red} />
              <Typography variant='body2' display='inline' style={{verticalAlign: 'top', lineHeight: 2, marginLeft: 8}} className={classes.red}>{errorMessage}</Typography>
              <br />
              <Button variant='contained' color='primary' disabled={loading} onClick={() => handleGetConnection()}>
                Try again
              </Button>
            </>
              :
              connected === null
                ? <CircularProgress style={{color: theme.colors.grey.light, display: 'block', margin: 'auto'}} />
                : connected
                  ? <Button variant='outlined' color='primary' disabled={loading} onClick={() => handleDelete()}>
                    {loading ? <><CircularProgress size={16} style={{marginRight: theme.spacing(1)}} />Disconnect</> : 'Disconnect'}
                  </Button>
                  : <Button variant='contained' color='primary' disabled={loading} onClick={() => setDialogOpen(true)}>
                    {loading ? <><CircularProgress size={16} style={{marginRight: theme.spacing(1)}} />Connect</> : 'Connect'}
                  </Button>
          }
        </Grid>
      </Grid>
    </>
  );
};
