// TODO: fix eslint disable
/* eslint-disable consistent-return, no-shadow */

import {DayPilot} from 'daypilot-pro-react';
import {sortBy} from 'lodash';

export class Schedule {

    constructor(schedule) {
        Object.assign(this, schedule);
    }

    toDayPilotEvent(statuses = []) {
        if (!this.startAt || !this.endAt || !this.resourceId) {
            return;
        }

        const payload = {
            id: this._id,
            start: new DayPilot.Date(new Date(this.startAt), true),
            end: new DayPilot.Date(new Date(this.endAt), true),
            text: this.title,
            entityType: 'schedule',
            entityData: this,
            resource: this.resourceId,
        };

        if (statuses) {
            const status = statuses.filter((status) => this.status === status.value)[0];
            if(status) {
                payload.backColor = status.color;
            }
        }

        return payload;
    }
}

export const schedulesToDayPilot = (schedules, statuses = []) => schedules.map(
        (schedule) => new Schedule(schedule).toDayPilotEvent(statuses)
    )
    .filter((d) => !!d);

export const scheduleToDayPilot = (schedule, statuses = []) => new Schedule(schedule).toDayPilotEvent(statuses);

export const resourceToDayPilot = (resource) => {
    const bubbleHtml = (resource.description || resource.capacity)
        ? `<div>
            ${resource.description ? `<div>${resource.description}</div>` : ''}
            ${resource.capacity ? `<div>Capacity: ${resource.capacity}</div>` : ''}
        </div>`
        : null;

    return {
        id: resource._id,
        name: resource.title,
        bubbleHtml
    };
};

export const resourcesToDayPilot = (resources) => sortBy(resources.map(resourceToDayPilot), 'name');
