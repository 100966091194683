// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-shadow */

import {InputAdornment} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import {get} from 'lodash';
import React, {useEffect, useState} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {HoopsTextField} from '../../../componentsOld/HoopsTextField';

const QuantityField = ({
    field,
    index = 0,
    name = '',
    classes,
    ...params
}) => {
    const {watch, control, setValue} = useFormContext();
    const formPriceElement = watch();

    const variations = get(formPriceElement, 'variations', []);
    const variationLength = variations.length;
    const decorationQuantity = watch('decorationCosts[0].quantity');
    const [disabled, setDisabled] = useState(true);
    const variationQuantity = variations.reduce((agg, variation) => agg += parseInt(variation.quantity || 0), 0);

    useEffect(() => {
        if (index > 0) {setValue(name, decorationQuantity);}
        if (index === 0 && variationLength > 0) {setValue(name, variationQuantity);}
        variationLength > 0 ? setDisabled(true) : index === 0 && setDisabled(false);
    }, [decorationQuantity, index, setValue, name, variationLength, variationQuantity]);

    return (
        <Controller
            defaultValue={field.quantity}
            name={name}
            render={(field) => (
                <HoopsTextField
                    type='number'
                    responsive
                    disabled={disabled}
                    {...field}
                    InputProps={{endAdornment: disabled && <InputAdornment position={'end'}><LockIcon fontSize='small' /></InputAdornment>}}
                    {...params}
                />
            )}
            control={control}
        />
    );
};

export default QuantityField;
