import React, {Fragment} from 'react';
import {Route, Switch} from 'react-router-dom';
import CustomersTab from '../../componentsOld/customers/CustomerManyViewPage';
import CustomerViewPage from '../../componentsOld/customers/CustomerViewPage';
import {CustomerImport} from '../../componentsOld/customers/import/CustomerImport';
import {roles, PermissionBasedRoute} from '../../componentsHoops/AccessControl';

class Customers extends React.Component {
  render() {
    const {admin, standard, lowAccess} = roles;
    return (
      <Fragment>
        <Switch>
          <Route path='/customers' exact component={CustomersTab}/>
          <Route path='/customers/import' exact component={CustomerImport}/>
          <PermissionBasedRoute allowedRoles={[admin, standard, lowAccess]} path='/customers/:_id/details'>
                  <CustomerViewPage />
          </PermissionBasedRoute>
          <PermissionBasedRoute allowedRoles={[admin, standard]} path='/customers/:_id/quotes'>
                  <CustomerViewPage />
          </PermissionBasedRoute>
          <PermissionBasedRoute allowedRoles={[admin, standard, lowAccess]} path='/customers/:_id/jobs'>
                  <CustomerViewPage />
          </PermissionBasedRoute>
          <PermissionBasedRoute allowedRoles={[admin, standard, lowAccess]} path='/customers/:_id/proofs'>
            <CustomerViewPage />

          </PermissionBasedRoute>
          <PermissionBasedRoute allowedRoles={[admin, standard, lowAccess]} path='/customers/:_id/files'>
                  <CustomerViewPage />

          </PermissionBasedRoute>

          <PermissionBasedRoute allowedRoles={[admin, standard, lowAccess]} path='/customers/:_id/comments'>
                  <CustomerViewPage />
          </PermissionBasedRoute>
          <PermissionBasedRoute allowedRoles={[admin, standard, lowAccess]} path='/customers/:_id/tasks'>
                  <CustomerViewPage />
          </PermissionBasedRoute>
        </Switch>
      </Fragment>
    );
  }
}

export default Customers;
