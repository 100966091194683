export function replaceAll(str, mapObj) {
  const keys = Object.keys(mapObj);
  if (keys.length > 0) {
    var re = new RegExp(keys.join('|'), 'gi');
    return str.replace(re, function (matched) {
      return mapObj[matched];
    });
  }
  return str;
}
