import {Typography} from '@material-ui/core';
import {find} from 'lodash';
import React from 'react';

export function AddressOneLine({addressType, addresses}) {
    let address = find(addresses, {label: addressType});
    if (!address) {
      if (addresses && addresses.length) {
        address = addresses[0];
      }
    }
  
    let addressString = [
      'address1', 'address2', 'city', 'state', 'postcode', 'country'
    ]
      .filter((key) => Boolean(address && address[key]))
      .map((key) => address[key])
      .join(', ');
  
    return (
      <>
        {(address ?
          <Typography variant='body2' gutterBottom>
            {addressString}
          </Typography> :
          <Typography variant={'body2'} color={'textSecondary'} gutterBottom>No linked address</Typography>)}
      </>
    );
  }

export default AddressOneLine;
