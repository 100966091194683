// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import React, {Component, Fragment} from 'react';
import {Chip, Menu, MenuItem} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {toUpper, findIndex} from 'lodash';
import PropTypes from 'prop-types';
import {KeyboardArrowDown} from '@material-ui/icons';

const styles = () => ({
  chip: {color: 'white',},
  arrowIcon: {color: 'white'},
  listItem: {'&:active': {backgroundColor: 'transparent'},}
});

class ChipSelect extends Component {
  state = {
    anchorEl: null,
    activeOptionIndex: -1
  };

  setActiveOptionIndex = () => {
    const {activeOption, options} = this.props;
    const index = findIndex(options, (option) => toUpper(activeOption) === toUpper(option.name));
    this.setState({activeOptionIndex: index});
  };

  openMenu = (e) => {
    this.setState({anchorEl: e.currentTarget});
  };

  closeMenu = () => {
    this.setState({anchorEl: null});
  };

  componentDidMount() {
    this.setActiveOptionIndex();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {activeOption} = this.props;
    if (activeOption !== prevProps.activeOption) {
      this.setActiveOptionIndex();
    }
  }

  onOptionChange = (index) => {
    const {onOptionChange, options} = this.props;
    this.setState({activeOptionIndex: index, anchorEl: null});
    if (onOptionChange){
      const newActiveOption = options[index];
      onOptionChange(newActiveOption);
    }
  };

  render() {
    const {anchorEl, activeOptionIndex} = this.state;
    const {classes, options, fallbackOption, disabled, activeOption, onOptionChange, ...chipProps} = this.props;
    const fallback = {
      name: 'Status not set',
      color: 'secondary',
      ...fallbackOption
    };

    let activeChip = null;
    if (activeOptionIndex !== -1) {
      activeChip = options[activeOptionIndex];
    }

    return (
      <Fragment>

        {activeChip ?
          <Chip
            label={toUpper(activeChip.name)}
            className={classes.chip}
            deleteIcon={<KeyboardArrowDown className={classes.arrowIcon}/>}
            onDelete={disabled ? null : this.openMenu}
            onClick={disabled ? null : this.openMenu}
            style={{
              cursor: (disabled ? 'inherit' : 'pointer'),
              backgroundColor: activeChip.color
            }}
            {...chipProps}
            /> :
          <Chip
            label={fallback.name}
            className={classes.chip}
            deleteIcon={!disabled ? <KeyboardArrowDown className={classes.arrowIcon} /> : null}
            onDelete={disabled ? null : this.openMenu}
            onClick={disabled ? null : this.openMenu}
            style={{
              cursor: (disabled ? 'inherit' : 'pointer'),
              backgroundColor: fallback.color
            }}
            {...chipProps}
          />
        }
        {!disabled && <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.closeMenu}
        >
          {options && options.map((option, index) => (
              <MenuItem disableRipple={true} key={index} className={classes.listItem}
                        style={{backgroundColor: 'transparent'}}>
                <Chip
                  label={toUpper(option.name)}
                  className={classes.chip}
                  style={{backgroundColor: option.color}}
                  onClick={() => this.onOptionChange(index)}
                  {...chipProps}
                />
              </MenuItem>
            ))}
        </Menu>}
      </Fragment>
    );
  }
}

export default withStyles(styles)(ChipSelect);

ChipSelect.propTypes = {
  activeOption: PropTypes.any,
  classes: PropTypes.any,
  disabled: PropTypes.bool,
  fallbackOption: PropTypes.any,
  onOptionChange: PropTypes.func,
  options: PropTypes.array.isRequired
};
