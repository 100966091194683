import store from '../../store';

// Check access returns boolean, for this to succeed, the user and tenant must have all the listed 'allowed' permissions
// and none of the listed disallowed permissions.
export const permissionCheck = ({
  allowedRoles = [],
  disallowedRoles = [],
  allowedSubscriptions = [],
  disallowedSubscriptions = [],
  allowedAppAccess = [],
  disallowedAppAccess = [],
  allowedSubscriptionManager = false,
}) => {
  const user = store.getState()?.authReducer?.userData;
  const company = store.getState()?.companyReducer.company;
  const subscriptionType = company?.subscriptionType;
  const userSubscriptionManager = company?.subscriptionManager;

  // If subscription manager is required, and not present, fail
  if (allowedSubscriptionManager && !userSubscriptionManager) {
    return false;
  }

  // If the user has a disallowed role, fail
  if (disallowedRoles.length > 0 && disallowedRoles.indexOf(user.role) >= 0) {
    return false;
  }

  // If the user does not have an allowed role, fail
  if (allowedRoles.length > 0 && allowedRoles.indexOf(user.role) < 0) {
    return false;
  }

  // If the tenant has a disallowed subscription type, fail
  if (disallowedSubscriptions.length > 0 && disallowedSubscriptions.indexOf(subscriptionType) >= 0) {
    return false;
  }

  // If the tenant does not have an allowed subscription type, fail
  if (allowedSubscriptions.length > 0 && allowedSubscriptions.indexOf(subscriptionType) < 0) {
    return false;
  }

  // If the tenant does not have all allowed app access flags, fail
  if (allowedAppAccess.length > 0 && !allowedAppAccess.every((key) => company?.appAccess?.allowedFeatures?.includes(key))) {
    return false;
  }

  // If the tenant has any disallowed app access flags, fail
  if (disallowedAppAccess.length > 0 && disallowedAppAccess.some((key) => company?.appAccess?.allowedFeatures?.includes(key))) {
    return false;
  }

  // All checks passed, succeed
  return true;
};
