import {Button, Grid, MuiThemeProvider} from '@material-ui/core';
import React, {createContext} from 'react';
import EntityListHero from '../../componentsOld/EntityList/EntityListHero';
import HoopsModalContextContainer, {HoopsModalContext} from '../../componentsOld/shared/HoopsModalContext';
import green from '../../ui/theme/green';
import AutomationEditModal from './AutomationEditModal';
import AutomationManyTable from './AutomationManyTable';

export const AutomationContext = createContext(null);

export const AutomationManyViewPage = () => (
        <HoopsModalContextContainer>
            <HoopsModalContext.Consumer>
                {({handleOpen}) => (
                        <Grid container>
                            <EntityListHero
                                title='Automations'
                                subtitle='Automate your workflow with clever rules to make your life easier.'
                            >
                                <MuiThemeProvider theme={green}>
                                    <Button
                                        data-intercom-target={'email-template-create'}
                                        onClick={handleOpen}
                                        color={'primary'}
                                        variant={'contained'}>Add Automation</Button>
                                </MuiThemeProvider>
                                <AutomationEditModal />
                            </EntityListHero>

                            <Grid item xs={12}>
                                <AutomationManyTable />
                            </Grid>
                        </Grid>
                    )}
            </HoopsModalContext.Consumer>
        </HoopsModalContextContainer>
    );

export default AutomationManyViewPage;
