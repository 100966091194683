
export function toUTCDate(date) {
  if (date instanceof Date) {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  }
  return date;
}

export function toLocalDate(date) {
  if (date instanceof Date) {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  }
  return date;
}
