// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {pick} from 'lodash';
import {
    GQL_EMAIL_TEMPLATE_CREATE, GQL_EMAIL_TEMPLATE_DELETE, GQL_EMAIL_TEMPLATE_GET_BY_ID,
    GQL_EMAIL_TEMPLATE_GET_MANY,
    GQL_EMAIL_TEMPLATE_MAKE_DEFAULT, GQL_EMAIL_TEMPLATE_UPDATE
} from '../queries/emailTemplate';
import {client} from './GraphQLService';

export default class EmailTemplateService {
    static cleanEmailContent(emailOptions) {
        // This regex will replace empty paragraph tags with a break, solves issue of empty paragraphs being removed by email client
        const regex = /<p><\/p>/g;

        const result = pick(emailOptions, [
            'subject',
            'body',
            'via',
            'title',
            'entity',
            'to',
            'replyTo',
            'cc',
            'bcc',
        ]);

        return {
            ...result,
            body: result.body.replace(regex, '<p>&nbsp;</p>')
        };
    }

    static cleanSmsContent(smsOptions) {

        const result = pick(smsOptions, [
            'smsBody',
            'phone',
        ]);

        return {
            to: result.phone,
            body: result.smsBody,
        };
    }

    static stripHtml = (html) => {
        let tmp = document.createElement('DIV');
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || '';
    }

    static validateEmail = (emailAddress) => String(emailAddress)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );

    static createEmailTemplate(emailTemplateData) {
        return client
            .mutate({
                mutation: GQL_EMAIL_TEMPLATE_CREATE,
                variables: {record: EmailTemplateService.cleanEmailContent(emailTemplateData)}
            })
            .then(({data: {emailTemplateCreateOne: emailTemplate}}) => emailTemplate);
    }

    static updateEmailTemplate(_id, emailTemplateData) {
        return client
            .mutate({
                mutation: GQL_EMAIL_TEMPLATE_UPDATE,
                variables: {
                    _id,
                    record: EmailTemplateService.cleanEmailContent(emailTemplateData)
                }
            })
            .then(({data: {emailTemplateUpdateById: emailTemplate}}) => emailTemplate);
    }

    static getEmailTemplateById(_id) {
        return client
            .query({
                query: GQL_EMAIL_TEMPLATE_GET_BY_ID,
                variables: {_id,},
                fetchPolicy: 'no-cache',
            })
            .then(({data: {emailTemplateById}}) => emailTemplateById);
    }

    static getManyEmailTemplate(entity) {
        return client
            .query({
                query: GQL_EMAIL_TEMPLATE_GET_MANY,
                variables: {filter: {entity}},
                fetchPolicy: 'no-cache'
            })
            .then(({data: {emailTemplateMany}}) => emailTemplateMany);
    }

    static makeDefaultEmailTemplate(emailTemplateId) {
        return client
            .mutate({
                mutation: GQL_EMAIL_TEMPLATE_MAKE_DEFAULT,
                variables: {emailTemplateId,}
            })
            .then(({data: {emailTempateMakeDefault: emailTemplateId}}) => emailTemplateId);
    }

    static deleteEmailTemplate(_id) {
        return client
            .mutate({
                mutation: GQL_EMAIL_TEMPLATE_DELETE,
                variables: {_id,}
            })
            .then(({data: {emailTemplateRemoveById: recordId}}) => recordId);
    }
}
