import React from 'react';
import {Tooltip, Button, makeStyles} from '@material-ui/core';
import {Document, Page, pdfjs} from 'react-pdf/dist/esm/entry.webpack';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles(() => ({
  thumbnail: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '50px',
    width: '50px'
  },
  popper: {opacity: 1},
  tooltip: {maxWidth: '450px'},
}));

export const ProofsTableThumbnail = ({imageUrl, contentType}) => {
  const classes = useStyles();

  return (
    <Tooltip
      classes={{popper: classes.popper, tooltip: classes.tooltip}}
      title={
        contentType === 'application/pdf'
          ?
          <Document
            file={imageUrl}
          >
            <Page
              pageNumber={1}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              width={435}
            />
          </Document>
          :
          <img src={imageUrl} alt='' style={{maxWidth: '100%'}} />
      }
      placement={'right'}
    >
      <Button>
        {
          contentType === 'application/pdf'
            ?
            <Document
              file={imageUrl}
            >
              <Page
                pageNumber={1}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                width={50}
              />
            </Document>
            :
            <span className={classes.thumbnail} style={{backgroundImage: `url('${imageUrl}')`}}></span>
        }
      </Button>
    </Tooltip>
  );
};
