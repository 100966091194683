import {Grid} from '@mui/material';
import * as React from 'react';
import Lottie from 'react-lottie';
import noSearchResult from '../../../assets/lotties/no-search-result.json';

export const HoopsPowerGridEmptyState = () => {

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: noSearchResult,
    rendererSettings: {preserveAspectRatio: 'xMidYMid slice'}
  };

  return (
    <Grid
      container
      direction={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      spacing={2}
      style={{height: '70vh'}}
    >
      <Grid item>
        <Lottie
          options={defaultOptions}
          height={200}
          width={200}
        />
      </Grid>
    </Grid>
  );
};
