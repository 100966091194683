import {
    SET_INTEGRATION,
    CREATE_INTEGRATION,
    UPDATE_INTEGRATION,
    REMOVE_INTEGRATION,
    UPDATE_INTEGRATIONS,
  } from './IntegrationActions';
 
  export const initialState = {
    active: {},
    list: {
      data: [],
      pagination: null,
      loaded: false
    }
  };
  
  const integrationReducer = (state = initialState, {type, payload}) => {
    switch (type) {
      case UPDATE_INTEGRATIONS:
        return {
          ...state,
          list: {
            data: payload,
            loaded: true
          }
        };
  
        case SET_INTEGRATION:
          return {
            ...state,
            active: payload
          };
  
      case CREATE_INTEGRATION:
        return {
          ...state,
          lastSaved: payload.record,
          list: {
            ...state.list,
            data: [
              ...state.list.data,
              payload.record
            ]
          }
        };
  
      case UPDATE_INTEGRATION:
        return {
          ...state,
          lastSaved: payload.record,
          list: {
            ...state.list,
            data: state.list.data.map(
              (item) => item._id === payload.recordId ? payload.record : item
            )
          }
        };
  
      case REMOVE_INTEGRATION:
        return {
          ...state,
          lastRemoved: payload,
          list: {
            ...state.list,
            data: state.list.data.filter(
              (item) => item._id === payload ? false : true
            )
          }
        };
  
      default:
        return state;
    }
  };
  
  export default integrationReducer;
  
