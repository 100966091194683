import {DexieBase} from './index';
import {groupBy, sample} from 'lodash';

export class CatalogImportData extends DexieBase {
  constructor(data) {
    super('catalogImportData', data);
  }

  async getSampleData(groupByColumn) {
    const importData = await this._collection.toArray();
    if (!groupByColumn) {
      return sample(importData);
    }

    const productGrouping = groupBy(importData, groupByColumn);
    const uniqueProducts = Object.keys(productGrouping);
    const sampleProductKey = sample(uniqueProducts);
    const sampleVariants = productGrouping[sampleProductKey];
    const sampleProduct = {
      ...sampleVariants[0],
      variants: []
    };

    sampleVariants.forEach((variant) => {
      sampleProduct.variants.push(variant);
    });

    return sampleProduct;
  }
}
