import {
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle, Grid,
  Icon,
  TextField, Typography
} from '@material-ui/core';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';

export const FileEditModal = ({openModal, file = {}, onCloseModal = () => null, onSave = () => null}) => {
  const {handleSubmit, control} = useForm({defaultValues: file});
  const onSubmit = (data) => onSave({...file, ...data});

  const handleCloseModal = () => {
    onCloseModal();
  };

  return (
    <Dialog
      open={openModal}
      maxWidth={'sm'}
      fullWidth={true}
      onClose={handleCloseModal}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle onClick={handleCloseModal}>
          <Typography variant='h5'>Edit File</Typography>
        </DialogTitle>
        <DialogContent>

          <Controller
            as={TextField}
            multiline
            fullWidth
            label='Description'
            variant='standard'
            name='description'
            control={control}
            rows='4'
            placeholder='Type your description here...'
            autoFocus
          />

        </DialogContent>
        <DialogActions>
          <Grid container justifyContent={'space-between'}>
            <Grid item>
              <Button onClick={handleCloseModal}>
                <Icon>close</Icon>
                  Cancel
                </Button>
            </Grid>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    variant={'contained'}
                    color={'primary'}
                    type='submit'
                  >
                    done
                    </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog >
  );
};

export default FileEditModal;
