// TODO: fix eslint disable
/* eslint-disable no-class-assign, */

import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {createQuote, getQuotes} from '../../actions/quote';
import {Route, Switch} from 'react-router-dom';
import Quotes from '../../componentsOld/quotes/QuoteManyViewPage';

class QuotesContainer extends React.Component {
  render() {
    return (
      <Fragment>
        <Switch>
          <Route path='/quotes' exact>
            <Quotes/>
          </Route>
        </Switch>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {quotes: state.quoteReducer.list};
}

const mapDispatchToProps = (dispatch) => ({
  getQuotes: () => dispatch(getQuotes()),
  createQuote: (quote) => dispatch(createQuote(quote))
});

QuotesContainer = connect(mapStateToProps, mapDispatchToProps)(QuotesContainer);

export default QuotesContainer;
