import {CCY_EUR, CCY_GBP, CCY_INR, CCY_USD, CCY_YEN, CCY_PHP, CCY_THB, CCY_VND, CCY_ZAR, CCY_IDR, CCY_MYR, CCY_SEK} from '../ui/CurrencyUtil';

export const CURRENCIES = [
  CCY_USD,
  CCY_EUR,
  CCY_GBP,
  CCY_INR,
  CCY_YEN,
  CCY_PHP,
  CCY_THB,
  CCY_VND,
  CCY_ZAR,
  CCY_IDR,
  CCY_MYR,
  CCY_SEK
];
