// TODO: fix eslint disable
/* eslint-disable no-shadow, no-unused-vars */

import JobsService from '../servicesOld/JobsService';
import {snackSuccess, snackError} from './action-types';
import {change} from 'redux-form';
import {hoopsQuerySetRefetch} from './hoopsQuery';

export const SET_JOBS = 'SET_JOBS';
export const SET_JOB = 'SET_JOB';
export const CLEAR_JOB = 'CLEAR_JOB';
export const SET_JOB_ITEM_VARIANTS = 'SET_JOB_ITEM_VARIANTS';
export const CLEAR_JOB_ITEM_VARIANTS = 'CLEAR_JOB_ITEM_VARIANTS';
export const SET_JOBS_LOADING = 'SET_JOBS_LOADING';
export const SET_JOB_LOADING = 'SET_JOB_LOADING';
export const UPSERT_JOBS = 'UPSERT_JOBS';
export const SET_EMAIL_TYPE = 'SET_EMAIL_TYPE';
export const EMAIL_JOB = 'EMAIL_JOB';

export function getJobs(options, cb) {
  return function (dispatch) {
    dispatch({
      type: SET_JOBS_LOADING,
      payload: true
    });
    return JobsService.getJobs(options)
    .then((data) => {
      dispatch({
        type: SET_JOBS,
        payload: data.data
      });
      dispatch({
        type: SET_JOBS_LOADING,
        payload: false
      });
      if (cb) {
        cb(data);
      }
    });
  };
}

export function createJob(job, callback) {
  return function (dispatch) {
    return JobsService.createJob(job)
      .then((job) => {
        dispatch(getJobs({fetchPolicy: 'no-cache'}));
        if (callback) {
          callback(null, job);
        }
        dispatch(hoopsQuerySetRefetch('job', true));
      });
    };
}

export function getJob(id, options, cb) {
  return function (dispatch) {
    dispatch({type: SET_JOB_LOADING, payload: true});
    return JobsService.getJob(id, options)
    .then((job) => {
      dispatch({
        type: SET_JOB,
        payload: job
      });
      dispatch({type: SET_JOB_LOADING, payload: false});
      if (cb) {
        cb(job);
      }
    });
  };
}

export function updateJob(_id, data, cb) {
    return function (dispatch) {
      return JobsService.updateJob(_id, data)
        .then((job) => {
          dispatch(snackSuccess(`Job number #${job.number} updated.`));
          // Set active Job
          dispatch({type: SET_JOB, payload: job});
          // Upsert job list
          dispatch({type: UPSERT_JOBS, payload: job});
          // If callback, callback()
          if (cb) {
            cb(null, job);
          }
          dispatch(hoopsQuerySetRefetch('job', true));
        });
    };
}

export function updateJobItem(_id, data, cb) {
    return function (dispatch) {
      return JobsService.updateJobItem(_id, data)
        .then((job) => {
          dispatch(snackSuccess(`Job number #${job.number} updated.`));
          // I think we dont need this
          // because this status is only shown in the detail, not in the list
          dispatch(getJobs({fetchPolicy: 'no-cache'}));
          // I think we dont need this
          // because this status is only shown in the detail, not in the list
          // I included because is in the old version (above)
          if (cb){
            cb();
          }
        });
    };
}

export function deleteJob(_id) {
    return function (dispatch) {
      return JobsService.deleteJob(_id)
        .then((job) => {
          dispatch(snackSuccess(`Job number #${job.number} deleted.`));
          dispatch(getJobs({fetchPolicy: 'no-cache'}));
          dispatch(hoopsQuerySetRefetch('job', true));
        });
    };
}

export function clearJob() {
  return (dispatch) => {
    dispatch({type: CLEAR_JOB});
  };
}

export function createJobItemVariant(data, form, field) {
  return (dispatch) => JobsService.createJobItemVariant(data)
      .then((jobVariant) => {
        if (form && field) {
          const item = field.split('.')[0];
          dispatch(change(form, field, jobVariant._id));
          dispatch(change(form, `${item}.quantity`, jobVariant.quantity));
        }
        return jobVariant;
      });
}

export function updateJobItemVariant(_id, data, form, field) {
  return (dispatch) => JobsService.updateJobItemVariant(_id, data)
      .then((jobVariant) => {
        if (form && field) {
          const item = field.split('.')[0];
          dispatch(change(form, `${item}.quantity`, jobVariant.quantity));
        }
        return jobVariant;
      });
}

export function getJobItemVariant(_id) {
  return (dispatch) => JobsService.getJobItemVariant(_id, {fetchPolicy: 'no-cache'})
      .then((jobItemVariant) => {
        dispatch({
          type: SET_JOB_ITEM_VARIANTS,
          payload: jobItemVariant
        });
      });
}

export function addJobNote(_id, content) {
  return (dispatch) => {
    JobsService.addJobNote(_id, content)
      .then(({data: {addJobNote: Note}}) => {
        dispatch(getJob(_id));
        dispatch(snackSuccess('Note added'));
      })
      .catch(() => {
        dispatch(snackError('Could not create note'));
      });
  };
}

export function updateJobNote(noteId, content, jobId) {
  return (dispatch) => {
    JobsService.updateJobNote(noteId, content)
      .then(({data: {updateJobNote: Note}}) => {
        dispatch(getJob(jobId));
        dispatch(snackSuccess('Note updated'));
      })
      .catch(() => {
        dispatch(snackError('Could not update note'));
      });
  };
}

export function deleteJobNote(noteId, jobId) {
  return (dispatch) => {
    JobsService.deleteJobNote(noteId)
      .then(({data: {deleteJobNote: Note}}) => {
        dispatch(getJob(jobId));
        dispatch(snackSuccess('Note deleted'));
      })
      .catch(() => {
        dispatch(snackError('Could not delete note'));
      });
  };
}

export function addJobArtwork(_id, content) {
  return (dispatch) => {
    JobsService.addJobArtwork(_id, content)
      .then(({data: {addJobArtwork: Artwork}}) => {
        dispatch(getJob(_id));
        dispatch(snackSuccess('Artwork added'));
      })
      .catch(() => {
        dispatch(snackError('Could not create Artwork'));
      });
  };
}

export function updateJobArtwork(ArtworkId, content, jobId) {
  return (dispatch) => {
    JobsService.updateJobArtwork(ArtworkId, content)
      .then(({data: {updateJobArtwork: Artwork}}) => {
        dispatch(getJob(jobId));
        dispatch(snackSuccess('Artwork updated'));
      })
      .catch(() => {
        dispatch(snackError('Could not update Artwork'));
      });
  };
}

export function deleteJobArtwork(ArtworkId, jobId) {
  return (dispatch) => {
    JobsService.deleteJobArtwork(ArtworkId)
      .then(({data: {deleteJobArtwork: Artwork}}) => {
        dispatch(getJob(jobId));
        dispatch(snackSuccess('Artwork deleted'));
      })
      .catch(() => {
        dispatch(snackError('Could not delete Artwork'));
      });
  };
}

export function setEmailType(type) {
  return (dispatch) => {
    dispatch({
      type: SET_EMAIL_TYPE,
      payload: type,
    });
  };
}

export function emailJob(JobId, formValues) {
  return function (dispatch) {
    dispatch({type: EMAIL_JOB});
    return JobsService.emailJob(JobId, formValues)
      .then(({data: {emailJob}}) => emailJob);
  };
}
