import * as React from 'react';
import {
  GridColumnMenuContainer,
  GridColumnPinningMenuItems,
  GridFilterMenuItem,
  SortGridMenuItems
} from '@mui/x-data-grid-pro';
import {HideGridColMenuItem} from '@mui/x-data-grid';
import {Divider, Menu, MenuItem} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import theme from '../../../ui/theme';
import EventIcon from '@mui/icons-material/Event';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import PersonIcon from '@mui/icons-material/Person';
import PropTypes from 'prop-types';

export const AddColumnMenu = ({onAddColumn}) => [
  <MenuItem key='Status' id={'Status'} onClick={() => onAddColumn('STATUS', 'Status')}>
    <ViewAgendaIcon sx={{marginRight: 1, color: theme.colors.grey.main}} />Status</MenuItem>,
  <MenuItem key='Date' id={'Date'} onClick={() => onAddColumn('DATE', 'Date')}>
    <EventIcon sx={{marginRight: 1, color: theme.colors.grey.main}} />Date</MenuItem>,
  <MenuItem key='Text' id={'Text'} onClick={() => onAddColumn('TEXT', 'Text')}>
    <TextFieldsIcon sx={{marginRight: 1, color: theme.colors.grey.main}} />Text</MenuItem>,
  <MenuItem key='Person' id={'Person'} onClick={() => onAddColumn('USER', 'Person')}>
    <PersonIcon sx={{marginRight: 1, color: theme.colors.grey.main}} />Person</MenuItem>,
];

export const CustomColumnMenuComponent = (props) => {
  const {hideMenu, currentColumn, apiRef, onAddColumn, onDeleteColumn, allowAddColumns, ...other} = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [position, setPosition] = React.useState(0);

  const colDef = apiRef.current.getColumn(currentColumn.field);

  const handleSubMenuOpen = (event, pos) => {
    setPosition(pos);
    setAnchorEl(event.currentTarget);
  };

  const handleAddColumn = async (type, name) => {
    apiRef.current.hideColumnMenu('default');
    onAddColumn(type, name, position);
    setAnchorEl(null);
  };

  const handleDeleteColumn = () => {
    apiRef.current.hideColumnMenu('default');
    onDeleteColumn(currentColumn.field, colDef.headerName);
    setAnchorEl(null);
  };

  const handleMenuClose = () => {
    //click twice to get the other menu
    // https://github.com/mui/material-ui/issues/11243
    //a solution: https://github.com/mui/material-ui/issues/11243#issuecomment-1043589153
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const menuItemStyle = {display: 'flex', justifyContent: 'space-between'};
  return (
    <GridColumnMenuContainer
      hideMenu={hideMenu}
      currentColumn={currentColumn}
      {...other}
    >

      <SortGridMenuItems onClick={hideMenu} column={currentColumn} />
      <GridFilterMenuItem onClick={hideMenu} column={currentColumn} />
      <HideGridColMenuItem onClick={hideMenu} column={currentColumn} />
      {(currentColumn.hideable || currentColumn.filterable || currentColumn.sortable) && <Divider />}
      <GridColumnPinningMenuItems onClick={hideMenu} column={currentColumn} />
      <Divider />
      <MenuItem
        sx={menuItemStyle}
        aria-describedby={id}
        onClick={(event) => handleSubMenuOpen(event, currentColumn.index)}
        // add proofs subscription column limits
        disabled={!allowAddColumns}
      >
        Add Column Left<ArrowRightIcon />
      </MenuItem>
      <MenuItem
        sx={menuItemStyle}
        aria-describedby={id}
        onClick={(event) => handleSubMenuOpen(event, currentColumn.index + 1)}
        // add proofs subscription column limits
        disabled={!allowAddColumns}
      >
        Add Column Right<ArrowRightIcon />
      </MenuItem>
      <Menu
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <AddColumnMenu onAddColumn={handleAddColumn} />
      </Menu>

      {colDef.deletable && [
        <Divider key='divider' />,
        <MenuItem key='DeleteColumn' id={'DeleteColumn'} onClick={() => handleDeleteColumn()}>Delete Column</MenuItem>
      ]}
    </GridColumnMenuContainer>
  );
};

CustomColumnMenuComponent.propTypes = {
  currentColumn: PropTypes.object.isRequired,
  hideMenu: PropTypes.func.isRequired,
};
