import React from 'react';
import {Button, CircularProgress, makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function LoadingButton({loading, children, ...rest}) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.wrapper}>
        <Button
          {...rest}
          disabled={loading}
        >
          {children}
        </Button>
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    </>
  );
}

export default LoadingButton;
