import {useRef} from 'react';

//
// Hook to perform a shallow compare on the dependencies and only execute the callback
// when they have changed.
//
// The callback is given the previous dependencies as a parameter.
//
// The callback will always be called on the first render, and will be passed null for the
// previous dependencies.
//
export function useWatch(callback, deps) {
  const depsRef = useRef(null);

  const prevDeps = depsRef.current;
  depsRef.current = deps;

  if (prevDeps !== deps) {
    if (!Array.isArray(prevDeps) || !Array.isArray(deps) || prevDeps.length !== deps.length || deps.some((dep, i) => dep !== prevDeps[i])) {
      callback(prevDeps);
    }
  }
}
