// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React, {Component, Fragment} from 'react';
import {Grid, Button} from '@material-ui/core';
import {renderTextField} from './shared/formHelpers/fields';
import {required} from './shared/formHelpers/validation';
import {reduxForm, Field} from 'redux-form';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';

const styles = (theme) => ({buttonBuffer: {marginTop: theme.spacing(5)}});

class ChangePasswordForm extends Component {
  render() {
    const {classes, handleSubmit} = this.props;
    return (
      <Fragment>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} className={classes.gridPadding}>
              <Field fullWidth
                    label={'New Password'}
                    name={'newPassword'}
                    validate={required}
                    type={'password'}
                    component={renderTextField}/>
            </Grid>
            <Grid item xs={12} className={classes.gridPadding}>
              <Field fullWidth
                    label={'Confirm New Password'}
                    name={'confirmNewPassword'}
                    validate={required}
                    type={'password'}
                    component={renderTextField}/>
            </Grid>
          </Grid>
          <Grid container item justifyContent={'center'} alignItems={'center'}>
            <Button className={classes.buttonBuffer} type='submit' color={'primary'} variant={'contained'}>
              Change Password
            </Button>
          </Grid>
        </form>
      </Fragment>
    );
  }
}

ChangePasswordForm = reduxForm({
  form: 'changePasswordForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(ChangePasswordForm);
ChangePasswordForm = connect()(ChangePasswordForm);
ChangePasswordForm = withStyles(styles)(ChangePasswordForm);

export default ChangePasswordForm;
