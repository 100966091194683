// TODO: fix eslint disable
/* eslint-disable no-shadow */

import React, {useState} from 'react';
import {Grid, Button, ListItemIcon, TableBody, Table} from '@material-ui/core';
import StatusLabelItemEdit from './StatusLabelItemEdit';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export default ({statuses = [], onChange = () => null}) => {
    const [_statuses, setStatuses] = useState(statuses);

    const handleDragEnd = (result) => {
        if (!result.destination) {
            return; // dropped outside the list
        }

        const statuses = reorder(
            _statuses,
            result.source.index,
            result.destination.index
        );

        setStatuses(statuses);
        onChange(statuses);
    };

    const handleStatusChange = (status) => {
        // Simple for our small arrays. Iterate over all items and modify the one that matches id.
        const local = statuses.map((k) => k._id === status._id ? status : k);
        setStatuses(local);
        onChange(local);
    };

    const handleStatusDelete = (status) => {

        setStatuses((prev) => {
            // Simple for small arrays to filter out the array we want to delete
            const local = prev.filter((k) => k._id !== status._id);
            onChange(local);
            return local;
        });

    };

    const handleAddMore = () => {
        setStatuses((prev) => {
            const newStatus = {
                _id: new Date().toISOString(), // random value
                label: '',
                isNew: true,
                value: new Date().toISOString(), // random value
                color: '#00ccf0'
            };

            const result = [
                ...prev,
                newStatus
            ];

            onChange(result);
            return result;
        });
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Table style={{minWidth: '450px'}}>
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId='droppable' direction='vertical'>
                            {(droppableProvided) => (
                                <TableBody
                                    ref={droppableProvided.innerRef}
                                    {...droppableProvided.droppableProps}
                                >
                                    {_statuses.map(
                                        (status, idx) =>
                                            <Draggable
                                                key={status._id}
                                                draggableId={status._id}
                                                index={idx}
                                            >
                                                {(draggableProvided, snapshot) => (

                                                    <StatusLabelItemEdit
                                                        status={status}
                                                        onChange={handleStatusChange}
                                                        onDelete={handleStatusDelete}
                                                        draggableProvided={draggableProvided}
                                                        snapshot={snapshot}
                                                    />
                                                )}
                                            </Draggable>
                                    )}
                                    {droppableProvided.placeholder}
                                </TableBody>
                            )}
                        </Droppable>

                    </DragDropContext>
                </Table>
            </Grid>
            <Grid item xs={12}>
                <ListItemIcon style={{width: '73px'}}>&nbsp;</ListItemIcon>
                <Button variant='outlined' color='primary' size='large' onClick={handleAddMore}>ADD MORE</Button>
            </Grid>
        </Grid>
    );
};
