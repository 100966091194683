
export function bind(object) {
  const proto = Object.getPrototypeOf(object);
  const descriptors = Object.getOwnPropertyDescriptors(proto);
  Object.keys(descriptors)
    .filter((fieldName) => typeof descriptors[fieldName].value === 'function' && fieldName !== 'constructor')
    .forEach((funcName) => {
      object[funcName] = proto[funcName].bind(object);
    });
}

export function unbind(originalObject) {
  const newObject = {};
  Object.entries(originalObject).forEach(([key, value]) => {
    if (typeof value !== 'function') {
      newObject[key] = value;
    }
  });
  return newObject;
}
