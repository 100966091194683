import Grid from '@material-ui/core/Grid';
import {get} from 'lodash';
import React, {useContext} from 'react';
import {useSelector} from 'react-redux';
import GrossMarginReport from './GrossMargin/GrossMarginReport';
import {ReportContext} from './Reporting';
import {RoyaltyReport} from './RoyaltyReport';
import SalesReport from './Sales/SalesReport';
import {VendorReport} from './VendorReport';
import {RoyaltySummaryReport} from './RoyaltySummaryReport';

const Report = ({reportType, reportSettings, reportData, currencySymbol}) => {
  switch (reportType) {
    case 'grossMargin':
      return <GrossMarginReport reportSettings={reportSettings} reportData={reportData} totals={reportData.totals} currencySymbol={currencySymbol} />;
    default:
    case 'sales':
      return <SalesReport reportSettings={reportSettings} reportData={reportData} totals={reportData.totals} currencySymbol={currencySymbol} />;
    case 'vendor/sales':
      return <VendorReport reportSettings={reportSettings} reportData={reportData} totals={reportData.totals} currencySymbol={currencySymbol} />;
    case 'fullypromoted/royalty':
      return <RoyaltyReport reportSettings={reportSettings} reportData={reportData} totals={reportData.totals} currencySymbol={currencySymbol} />;
    case 'fullypromoted/summary':
      return <RoyaltySummaryReport reportSettings={reportSettings} reportData={reportData} totals={reportData.totals} currencySymbol={currencySymbol} />;
  }
};

function ReportDataView() {
  const {reportSettings, reportType} = useContext(ReportContext);
  const reportDataSelector = (state) => state.reportingReducer.reportData;
  const currencySymbolSelector = (state) => get(state, 'authReducer.userData.company.currencySymbol');
  const reportData = useSelector(reportDataSelector);
  const currencySymbol = useSelector(currencySymbolSelector);

  return (
    <>
      <Grid container justifyContent={'center'} spacing={2}>
        <Report reportType={reportType} reportSettings={reportSettings} reportData={reportData} currencySymbol={currencySymbol} />
      </Grid>
    </>
  );
}

export default ReportDataView;
