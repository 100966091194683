import React from 'react';
import {CircularProgress} from '@mui/material';
import {registerGlobalStyle} from '../../theme';
import {HoopsPropTypes} from '../utils';
import classNames from 'classnames';

registerGlobalStyle('.busy-spinner', (theme) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  columnGap: theme.spacing(1),
  '&.left': {justifyContent: 'start'},
  span: {
    width: theme.spacing(2.25),
    height: theme.spacing(2.25),
  },
  svg: {
    color: theme.colors.text.highlight,
    width: theme.spacing(2.25),
    height: theme.spacing(2.25),
  },
  '&.large': {
    span: {
      width: theme.spacing(4.5),
      height: theme.spacing(4.5),
    },
    svg: {
      width: theme.spacing(4.5),
      height: theme.spacing(4.5),
    },
  },
  '&.center': {
    display: 'grid',
    gap: theme.spacing(2),
    justifyItems: 'center'
  }
}));

export function BusySpinner({large, left, center, message}) {
  return (
    <div className={classNames(['busy-spinner', left && 'left', large && 'large', center && 'center'])}>
      <CircularProgress size={''} />
      {message}
    </div>
  );
}

BusySpinner.propTypes = {
  large: HoopsPropTypes.bool,
  left: HoopsPropTypes.bool,
  center: HoopsPropTypes.bool,
  message: HoopsPropTypes.string,
};
