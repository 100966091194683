// TODO: fix eslint disable
/* eslint-disable no-unused-vars, consistent-return, no-shadow */

import {useLazyQuery} from '@apollo/client';
import {Divider, InputAdornment, TableRow} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import TableCell from '@material-ui/core/TableCell';
import {Cancel} from '@material-ui/icons';
import Delete from '@material-ui/icons/Delete';
import FileCopy from '@material-ui/icons/FileCopy';
import classNames from 'classnames';
import {Select} from 'final-form-material-ui';
import {get, uniqBy} from 'lodash';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {change, Field, formValueSelector, getFormValues} from 'redux-form';
import {HoopsTextField} from '../../../HoopsTextField';
import {useMountEffect} from '../../../../hooks';
import {GQL_GET_PRODUCT} from '../../../../queries/product';
import VendorAutocomplete from '../../../vendors/VendorAutocomplete';
import {renderTextField as RenderTextField} from '../../formHelpers/fields';
import {required} from '../../formHelpers/validation';
import {ItemBuilderProductField} from './ItemBuilderProductField';
import {sortSizes} from '../../../../utils/sortSizes';

function SelectField({children, ...params}) {
  return (
    <Select
      formControlProps={{
        fullWidth: true,
        variant: 'outlined',
        margin: 'dense',
      }}
      SelectDisplayProps={{fullWidth: true}}
      input={params.input}
      meta={params.meta}
    >
      {children}
    </Select>
  );
}

function ColorField({product, classes, productType, ...params}) {
  const [isCustom, setIsCustom] = useState(false);
  const dispatch = useDispatch();

  const handleCustomToggle = (toggleValue) => {
    dispatch(change(params.meta.form, params.input.name, null));
    setIsCustom(toggleValue);
  };

  useEffect(() => {
    params && params.input &&
      params.input.value.length > 0 &&
      product && product._id &&
      setIsCustom(!product.colors.filter((element) => element.name === params.input.value).length > 0);
  }, [params, product]);

  if (product._id) {
    return (
      <>
        {isCustom
          ? <HoopsTextField
            className={classes.textFieldWithAdornmentBtn}
            defaultValue={params.input.value}
            placeholder='Enter custom color'
            input={params.input}
            meta={params.meta}
            InputProps={{
              endAdornment:
                <InputAdornment position={'end'}>
                  <IconButton onClick={() => handleCustomToggle(false)}>
                    <Cancel fontSize='small' className={classes.greyText} />
                  </IconButton>
                </InputAdornment>
            }}
          />
          : <Select
            formControlProps={{
              fullWidth: true,
              variant: 'outlined',
              margin: 'dense',
            }}
            SelectDisplayProps={{fullWidth: true}}
            input={params.input}
            meta={params.meta}
            {...params}
          >
            {product && product.colors && product.colors.map((o) => (
              <MenuItem key={o._id} value={o.name}>{o.name}</MenuItem>
            ))}
            <Divider light style={{marginBottom: 8}} />
            <MenuItem onClick={() => handleCustomToggle(true)}>Use custom color</MenuItem>
          </Select>
        }
      </>
    );
  }
  return <RenderTextField {...params} />;
}

const selector = formValueSelector('jobVariantsForm');

function SizeField({product, field, classes, productType, ...params}) {
  const dispatch = useDispatch();
  const [variants, setVariants] = useState([]);

  const colorSelector = (state) => selector(state, `${field}.colour`);
  const sizeSelector = (state) => selector(state, `${field}.size`);
  const variantSelector = (state) => selector(state, `${field}.productVariant`);

  const color = useSelector(colorSelector);
  const size = useSelector(sizeSelector);
  const variant = useSelector(variantSelector);

  const [isCustom, setIsCustom] = useState(false);

  const getSizeVariants = useCallback(() => {
    const variants = get(product, 'variants', []);
    const availableVariants = variants.filter((v) => (v.color.name === color));
    setVariants(sortSizes(uniqBy(availableVariants, 'size.name'),'size.name'));

    // filter selected variant (size) by the defaultvalue set for the field
    const cleanedVariantOptions = uniqBy(availableVariants, 'size.name');
    const filterVariants = cleanedVariantOptions.filter((v) => v._id === size);

    setIsCustom((prev) => {

      if (!prev && !size) {return false;}
      if (prev) {return !filterVariants.length > 0;}

    });

    if (!variant && filterVariants.length === 0) {
      // if there is a size set that isn't in the variant array, it is custom
      size && size.length > 0 && setIsCustom(true);
    }
  }, [color, product, size, variant]);

  const handleCustomToggle = (toggleValue) => {
    dispatch(change(params.meta.form, params.input.name, null));
    setIsCustom(toggleValue);
  };

  useEffect(() => {
    if (color) {
      getSizeVariants();
    } else {
      // if there is a size set but no color it has to be custom
      setIsCustom(size && size.length > 0 && product && product.sizes);
    }

    if (!variant) {
      dispatch(change(params.meta.form, params.input.name, size));
    }

  }, [product, color, variant, size, dispatch, params.meta.form, params.input.name, getSizeVariants]);

  if (product._id) {
    return (
      <>
        {isCustom
          ? <HoopsTextField
            className={classes.textFieldWithAdornmentBtn}
            defaultValue={size}
            placeholder='Enter custom size'
            input={params.input}
            meta={params.meta}
            InputProps={{
              endAdornment:
                <InputAdornment position={'end'}>
                  <IconButton onClick={() => handleCustomToggle(false)}>
                    <Cancel fontSize='small' className={classes.greyText} />
                  </IconButton>
                </InputAdornment>
            }}
          />
          : <SelectField
            {...params}
          >
            {variants.map((o) => (
              <MenuItem key={o._id} value={o._id}>{o.size.name}</MenuItem>
            ))}
            <Divider light style={{marginBottom: 8}} />
            <MenuItem onClick={() => handleCustomToggle(true)}>Use custom size</MenuItem>
          </SelectField>
        }
      </>
    );
  }

  return (
    <RenderTextField {...params} />
  );
}

export const ProductsTableRow = ({
  meta,
  field,
  rowIdx,
  classes,
  handleQuantityChange = () => null,
  handleDeleteRowClick = () => null,
  handleDuplicateRowClick = () => null,
}) => {
  const dispatch = useDispatch();

  const formValuesSelector = (state) => getFormValues(meta.form)(state);
  const formValues = useSelector(formValuesSelector);
  const rowFields = formValues?.variants[rowIdx];

  const [product, setProduct] = useState({title: ''});
  const [productType, setProductType] = useState(() => ((!rowFields.productId && rowFields.productName) ? 'oneOff' : 'hoops'));

  const [getProductData] = useLazyQuery(GQL_GET_PRODUCT, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (result) => {
      if (result.product) {
        dispatch(change(meta.form, `variants[${rowIdx}].product`, result.product));
        dispatch(change(meta.form, `variants[${rowIdx}].productCode`, result.product.code));
        dispatch(change(meta.form, `variants[${rowIdx}].productName`, result.product.title));
        dispatch(change(meta.form, `variants[${rowIdx}].productId`, result.product._id));
        dispatch(change(meta.form, `variants[${rowIdx}].vendor`, result.product.vendor.name));
        dispatch(change(meta.form, `variants[${rowIdx}].vendorId`, result.product.vendor._id));
        setProduct(result.product);
      }
    },
    onError(error) {
      console.error(error);
    },
  });

  const handleClearRowFields = useCallback(() => {
    dispatch(change(meta.form, `variants[${rowIdx}].product`, null));
    dispatch(change(meta.form, `variants[${rowIdx}].productCode`, ''));
    dispatch(change(meta.form, `variants[${rowIdx}].productName`, null));
    dispatch(change(meta.form, `variants[${rowIdx}].productId`, null));
    dispatch(change(meta.form, `variants[${rowIdx}].vendor`, null));
    dispatch(change(meta.form, `variants[${rowIdx}].vendorId`, null));
    dispatch(change(meta.form, `variants[${rowIdx}].productVariant._id`, null));
    dispatch(change(meta.form, `variants[${rowIdx}].size`, null));
    dispatch(change(meta.form, `variants[${rowIdx}].colour`, null));
  }, [dispatch, meta.form, rowIdx,]);

  const handleProductTypeChange = useCallback((value) => {
    handleClearRowFields();
    setProductType(value);
    setProduct({title: ''});
  }, [handleClearRowFields]);

  const handleProductSelected = useCallback((product) => {
    handleClearRowFields();
    getProductData({variables: {_id: product._id}});
  }, [getProductData, handleClearRowFields]);

  const handleColorChange = () => {
    dispatch(change(meta.form, `variants[${rowIdx}].productVariant._id`, null));
    dispatch(change(meta.form, `variants[${rowIdx}].size`, null));
  };

  const initLoad = useCallback(() => {
    rowFields.productId && getProductData({variables: {_id: rowFields.productId}});
  }, [rowFields.productId, getProductData]);

  useMountEffect(() => {
    initLoad();
  }, [initLoad]);

  return (
    <TableRow>
      <ItemBuilderProductField
        rowFields={rowFields}
        rowIdx={rowIdx}
        field={field}
        productType={productType}
        handleProductTypeChange={handleProductTypeChange}
        handleProductSelected={handleProductSelected}
        margin={'dense'}
      />
      <TableCell className={classes.tableCell}>
        <Field name={`${field}.productCode`}
          data-intercom-target={'job-item-variant-product-code-field'}
          component={RenderTextField}
          variant={'outlined'}
          disabled={productType !== 'oneOff'}
          margin={'dense'} />
      </TableCell>
      <TableCell className={classes.tableCell}>
        <Field
          fullWidth
          disabled={productType !== 'oneOff'}
          name={`${field}.vendor`}
          component={VendorAutocomplete}
          onChange={(vendor) => {
            dispatch(change(meta.form, `variants[${rowIdx}].vendorId`, vendor._id));
          }}
          textFieldProps={{
            margin: 'dense',
            variant: 'outlined'
          }}
        />
      </TableCell>
      <TableCell className={classes.tableCell}>
        <Field name={`${field}.quantity`}
          data-intercom-target={'job-item-variant-quantity-field'}
          component={RenderTextField}
          variant={'outlined'}
          margin={'dense'}
          validate={required}
          onChange={handleQuantityChange}
          InputProps={{
            type: 'number',
            step: 1
          }} />
      </TableCell>
      <TableCell className={classes.tableCell}>
        <Field name={`${field}.colour`}
          data-intercom-target={'job-item-variant-colour-field'}
          product={product}
          productType={productType}
          field={field}
          classes={classes}
          component={ColorField}
          onChange={handleColorChange}
          variant={'outlined'}
          margin={'dense'} />
      </TableCell>
      <TableCell className={classes.tableCell}>
        <Field name={`${field}.productVariant._id`}
          product={product}
          productType={productType}
          field={field}
          classes={classes}
          component={SizeField}
          variant={'outlined'}
          margin={'dense'} />
      </TableCell>
      <TableCell className={classNames([classes.tableCell, classes.actionColumn])}>
        <Grid item>
          <IconButton data-intercom-target={'job-item-variant-duplicate-button'}
            onClick={() => handleDuplicateRowClick(field)}>
            <FileCopy color={'secondary'} />
          </IconButton>
          <IconButton data-intercom-target={'job-item-variant-delete-button'}
            onClick={() => handleDeleteRowClick(rowIdx)}>
            <Delete color={'secondary'} />
          </IconButton>
        </Grid>
      </TableCell>
    </TableRow>
  );
};
