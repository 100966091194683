import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import {makeStyles, MuiThemeProvider} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import {get} from 'lodash';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {PURCHASE_ORDER_STATUSES} from '../../../constants/status';
import green from '../../../ui/theme/green';
import ChipSelect from '../../jobs/ChipSelect';
import {FormattedCurrency} from '../../reporting/FormattedCurrency';
import {FormatDate} from '../../shared/FormatDate';

const headCells = [
    {id: 'number', alignHeader: 'left', disablePadding: false, label: 'PO #'},
    {id: 'dateCreated', alignHeader: 'left', disablePadding: false, label: 'Date Created'},
    {id: 'title', alignHeader: 'left', disablePadding: false, label: 'Title'},
    {id: 'relatedJob', alignHeader: 'left', disablePadding: false, label: 'Related Job'},
    {id: 'vendor', alignHeader: 'left', disablePadding: false, label: 'Vendor'},
    {id: 'createdBy', alignHeader: 'left', disablePadding: false, label: 'Created By'},
    {id: 'deadlineAt', alignHeader: 'left', disablePadding: false, label: 'Deadline'},
    {id: 'total', alignHeader: 'right', disablePadding: false, label: 'Total'},
    {id: 'status', alignHeader: 'center', disablePadding: false, label: 'Status'},

];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {return order;}
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const {classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort} = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding='checkbox'>
                    <MuiThemeProvider theme={green}>
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{'aria-label': 'select all'}}
                            color={'primary'}
                        />
                    </MuiThemeProvider>
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.alignHeader}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <span className={classes.headerText}>{headCell.label}</span>
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {width: '100%',},
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {minWidth: 750,},
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    chip: {color: 'white',},
    arrowIcon: {color: 'white'},
    listItem: {'&:active': {backgroundColor: 'transparent'},},
    headerText: {color: theme.colors.grey.main}

}));

export default function MergePurchaseOrderTable({data = [], onChange = () => null}) {
    const rows = data.map(function (purchaseOrder) {
        return {
            _id: purchaseOrder._id,
            number: purchaseOrder.number,
            dateCreated: purchaseOrder.createdAt,
            title: purchaseOrder.title,
            relatedJob: get(purchaseOrder, 'linkedJobs[0]'),
            vendor: get(purchaseOrder, 'vendor.name'),
            createdBy: get(purchaseOrder, 'createdBy.fullName'),
            deadlineAt: get(purchaseOrder, 'deadlineAt'),
            total: purchaseOrder.total,
            status: purchaseOrder.status
        };
    });

    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('number');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    useEffect(() => {
        const newSelecteds =  data.map((n) => n._id);
        setSelected(newSelecteds);
        onChange(newSelecteds);
    }, [data, onChange]);

    const handleRequestSort = (_event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = ({target}) => {
        const newSelecteds = target.checked
            ? rows.map((n) => n._id)
            : [];

        setSelected(newSelecteds);
        onChange(newSelecteds);
    };

    const handleClick = (_, _id) => {
        setSelected((previousSelections) => {
            const exists = previousSelections.indexOf(_id) !== -1;

            const results = exists
                ? previousSelections.filter((item) => item !== _id)
                : [...previousSelections, _id];

            onChange(results);
            return results;
        });
    };

    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = rows && ((_id) => selected.indexOf(_id) !== -1);

    return (
        <div className={classes.root}>
            {typeof rows !== 'undefined' ?
                <Paper className={classes.paper} elevation={0}>

                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby='tableTitle'
                            size={'medium'}
                            aria-label='enhanced table'
                        >
                            <EnhancedTableHead
                                classes={classes}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />
                            <TableBody>
                                {stableSort(rows, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(row._id);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                onClick={(event) => handleClick(event, row._id)}
                                                role='checkbox'
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row._id}
                                                selected={isItemSelected}
                                            >
                                                <TableCell padding='checkbox'>
                                                    <MuiThemeProvider theme={green}>
                                                        <Checkbox
                                                            defaultChecked
                                                            checked={isItemSelected}
                                                            inputProps={{'aria-labelledby': labelId}}
                                                            iconStyle={{fill: 'green'}}
                                                            color={'primary'}
                                                        />
                                                    </MuiThemeProvider>
                                                </TableCell>
                                                <TableCell component='th' id={labelId} scope='row' >{row.number}</TableCell>
                                                <TableCell align='left'><FormatDate date={row.dateCreated} /></TableCell>
                                                <TableCell align='left'>{row.title}</TableCell>
                                                <TableCell align='left'>{row.relatedJob && get(row, 'relatedJob.number', '') + ' | ' + get(row, 'relatedJob.customer.name', '') + ' | ' + get(row, 'relatedJob.title', '')}</TableCell>
                                                <TableCell align='left'>{row.vendor}</TableCell>
                                                <TableCell align='left'>{row.createdBy}</TableCell>
                                                <TableCell align='left'><FormatDate date={row.deadlineAt} /></TableCell>
                                                <TableCell align='left'>
                                                    <FormattedCurrency>
                                                        {row.total}
                                                    </FormattedCurrency>
                                                </TableCell>
                                                <TableCell align='left'>
                                                    <ChipSelect disabled
                                                        activeOption={row.status}
                                                        options={PURCHASE_ORDER_STATUSES}
                                                        fallbackOption={{name: 'CREATED'}} />

                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}

                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />

                </Paper>
                : <div>Error! There is no data to display</div>}
        </div>
    );
}
