// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {FormControl, FormHelperText, Grid, makeStyles, TextField, Typography} from '@material-ui/core';
import React, {useEffect} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import EditorField from '../../componentsOld/companies/editor';
import ShortcodeList from '../emailTemplate/ShortcodeList';
import {HoopsTextField} from '../../componentsOld/HoopsTextField';
import templateType from '../emailTemplate/templateType';
import theme from '../../ui/theme';
import EmailTemplateService from '../../servicesOld/EmailTemplateService';

const useStyles = makeStyles((theme) => ({
    greyText: {color: theme.colors.grey.main,},
    selectClass: {minWidth: 184,},
}));

const AutomationEmailMessage = ({type, entity}) => {
    const classes = useStyles();
    const {control, errors, watch, setValue} = useFormContext();
    const smsBodyLimit = 160;
    const {email, sms} = templateType;

    const body = watch('body');

    useEffect(() => {
        if (type === sms) {
            setValue('body', EmailTemplateService.stripHtml(body));
        }
    }, [type, sms, setValue, body]);

    return (
        <Grid container direction='row' justifyContent='center' alignItems='flex-start' spacing={4}>
            <Grid item xs={12}>
                <Typography variant='h5' className={classes.greyText} align='center'>and what should the notification say</Typography>
            </Grid>
            <Grid item>

                {type === email
                    ?
                    <>
                        <Typography variant='caption'>Email Subject</Typography>
                        <Controller
                            name='subject'
                            control={control}
                            defaultValue=''
                            style={{marginBottom: 32}}
                            rules={{required: true}}
                            as={<TextField variant='outlined' size='small' fullWidth
                                helperText={errors.subject && 'Subject is required'} error={errors.subject} />}
                        />

                        <Controller
                            name={'body'}
                            control={control}
                            rules={{required: true}}
                            render={({onChange, value}) =>
                                <FormControl fullWidth error={errors.body} >
                                    <EditorField
                                        entity={'automation'}
                                        input={{value: value || '', onChange: onChange}} meta={true} className={classes.body} />
                                    <FormHelperText>{errors.body && 'Text is required in the email template'}</FormHelperText>
                                </FormControl>
                            }
                        />

                    </>
                    :
                    <>
                        <Controller
                            name='body'
                            defaultValue=' '
                            control={control}
                            rules={{required: true, maxLength: smsBodyLimit}}
                            render={(props) =>
                                <FormControl fullWidth error={errors.body} >
                                    <HoopsTextField
                                        {...props}
                                        label='Body'
                                        multiline
                                        rows={10}
                                        style={{paddingBottom: theme.spacing(1)}}
                                        inputProps={{maxlength: smsBodyLimit}}
                                    />
                                    <FormHelperText>{errors.body && errors.body.type === 'required' && 'Text is required in the sms template'}</FormHelperText>
                                    <FormHelperText>{errors.body && errors.body.type === 'maxLength' && `The maximum length of the sms message is ${smsBodyLimit}`}</FormHelperText>
                                </FormControl>
                            }
                        />
                        <Typography variant='body2' className={classes.greyText} align='right' gutterBottom>{`${body ? body.length : 0} / ${smsBodyLimit}`}</Typography>
                        <Typography variant='body2' className={classes.greyText} >{`Max ${smsBodyLimit} Characters. Please note that using short codes will affect the number of characters depending on the length of the text string that is inserted by the short code.`}</Typography>
                    </>

                }

            </Grid>
            <Grid item style={{maxWidth: 500}}>
                <ShortcodeList entity={'automation' + entity} open={true} />
            </Grid>
        </Grid>
    );
};

export default AutomationEmailMessage;
