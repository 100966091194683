import {getLocalStorageItem, getLocalStorageItemKeys, putLocalStorageItem, removeLocalStorageItem} from '../utils';

const autoSaveStorageLimit = 8;

/*
The full autosave key is of the form

 user|autosave|type|...client-definition|...

The user is added by the localStorage functions, autosave is added by the service, clients of the
service specify a key in the form

 type|...client-definition|...
 */

export class AutoSaveService {
  static listRecords({type, allUsers = false}) {
    const localKeys = getLocalStorageItemKeys(allUsers);
    return localKeys.filter((key) => {
      const parts = key.split('|');
      return parts[1] === 'autosave' && (type == null || parts[2] === type);
    })
      .map((key) => {
        const record = JSON.parse(localStorage[key]);
        if (!record.meta) {
          localStorage.removeItem(key);
        }
        return record.meta;
      })
      .filter(Boolean)
      .sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt));
  }

  static saveRecord(key, record, meta) {
    meta = {...meta, createdAt: Date.now(), key};
    putLocalStorageItem(`autosave|${key}`, {meta, record});

    AutoSaveService.removeExcessRecords();

    return {meta};
  }

  static loadRecord(key) {
    return getLocalStorageItem(`autosave|${key}`);
  }

  static removeRecord(key) {
    return removeLocalStorageItem(`autosave|${key}`);
  }

  static removeExcessRecords() {
    // To prevent autosave from using all the local storage, only store N auto saves Objects
    if (Object.keys(localStorage).filter((localKey) => localKey.split('|')[1] === 'autosave').length > autoSaveStorageLimit) {
      const autoSaveList = AutoSaveService.listRecords({allUsers: true});
      for (let i = autoSaveStorageLimit; i < autoSaveList.length; i++) {
        AutoSaveService.removeRecord(autoSaveList[i].key);
      }
    }
  }
}
