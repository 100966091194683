// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-duplicate-imports, no-class-assign */

import React, {Component} from 'react';
import {
  Grid,
  Paper, Tab, Tabs,
  withStyles,
} from '@material-ui/core';
import PageHeading from './PageHeading';
import {Route, Switch, withRouter} from 'react-router-dom';
import {getProductWithVariantsTable as _getProductWithVariantsTable} from '../../../actions/catalog';
import {connect} from 'react-redux';
import ProductInformation from './ProductInformation';
import ProductDetail from './ProductDetail';
import Prices from './Prices';
import {Form} from 'react-final-form';
import {
  toggleEditProduct as _toggleEditProduct,
  updateProduct as _updateProduct,
} from '../../../actions/catalog';
import Images from './Images';

const PRODUCT_INFORMATION_FORM = 'productInformationFormId';

const styles = (theme) => ({
  paperPadding: {padding: theme.spacing(3)},
  tabsOuterContainer: {borderBottom: `1px solid ${theme.palette.secondary.main}`},
  activeTabContainer: {marginTop: theme.spacing(2)},
  pagePaddingBottom: {marginBottom: theme.spacing(5)}
});

class SingleProduct extends Component {

  constructor(props) {
    super(props);
    this.state = {activeTab: props.location.pathname.split('/').pop()};
  }

  componentDidMount() {
    const {match: {params: {_id}}, getProductWithVariantsTable} = this.props;
    getProductWithVariantsTable(_id);
  }

  saveProduct = ({_id, title, code, description, tags, ...other}) => {
    const {toggleEditProduct, updateProduct, product} = this.props;
    updateProduct(product._id, {title, code, description, tags});
    toggleEditProduct();
  };

  handleTabChange = (e, newActiveTab) => {
    const {history, product} = this.props;
    this.setState({activeTab: newActiveTab});
    history.push(`/catalog/product/${product._id}/${newActiveTab}`);
  };

  render() {
    const {product, classes} = this.props;
    const {activeTab} = this.state;
    return (
      <>
        { product && (
          <Grid container spacing={3} className={classes.pagePaddingBottom}>
            <Grid item>
              <PageHeading product={product}/>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paperPadding}>
                <Form onSubmit={this.saveProduct}
                      initialValues={product}
                      render={({handleSubmit}) => (
                        <form onSubmit={handleSubmit}
                              id={PRODUCT_INFORMATION_FORM}
                        >
                          <ProductInformation product={product}/>
                        </form>
                      )}/>
                <Grid container className={classes.tabsOuterContainer}>
                  <Tabs
                    className={classes.tabs}
                    value={activeTab}
                    onChange={this.handleTabChange}
                    indicatorColor='primary'
                    textColor='primary'
                    variant='scrollable'
                    scrollButtons='auto'
                  >
                    <Tab label='Product Detail' value='details'/>
                    <Tab label='Prices' value='prices'/>
                    <Tab label='Images' value='images'/>
                  </Tabs>
                </Grid>
                <Grid container className={classes.activeTabContainer}>
                  <Switch>
                    <Route path='/catalog/product/:_id/details' exact component={(params) => (<ProductDetail {...params} />)}/>
                    <Route path='/catalog/product/:_id/prices' exact component={(params) => (<Prices {...params} />)}/>
                    <Route path='/catalog/product/:_id/images' exact component={(params) => (<Images {...params} />)}/>
                  </Switch>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        )}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getProductWithVariantsTable: (_id) => dispatch(_getProductWithVariantsTable(_id)),
    updateProduct: (_id, data) => dispatch(_updateProduct(_id, data)),
    toggleEditProduct: () => dispatch(_toggleEditProduct())
  };
}

function mapStateToProps(state) {
  return {product: state.catalogReducer.activeProduct};
}

SingleProduct = withStyles(styles)(SingleProduct);
SingleProduct = withRouter(SingleProduct);
SingleProduct = connect(mapStateToProps, mapDispatchToProps)(SingleProduct);
export default SingleProduct;
