import {gql} from 'apollo-boost';

export const GQL_RESOURCE_FRAGMENT = gql`
    fragment ResourceFragment on Resource {
        _id
        title
        description
        capacity
    }
`;

export const GQL_GET_RESOURCE = gql`
    ${GQL_RESOURCE_FRAGMENT}
    query resourceById($_id: MongoID!) {
        resourceById(_id: $_id) {
            ...ResourceFragment
        }
    }
`;

export const GQL_GET_RESOURCES = gql`
    query ResourceMany($sort: SortFindManyResourceInput) {
        resourceMany(sort: $sort) {
            ...ResourceFragment
        }
    }
    ${GQL_RESOURCE_FRAGMENT}
`;

export const GQL_CREATE_RESOURCE = gql`
    ${GQL_RESOURCE_FRAGMENT}
    mutation resourceCreateOne($record: CreateOneResourceInput!) {
        resourceCreateOne(record: $record) {
            recordId
            record {
                ...ResourceFragment
            }
        }
    }
`;

export const GQL_UPDATE_RESOURCE = gql`
    ${GQL_RESOURCE_FRAGMENT}
    mutation resourceUpdateById($_id: MongoID!, $record: UpdateByIdResourceInput!) {
        resourceUpdateById(_id: $_id, record: $record ) {
            recordId
            record {
                ...ResourceFragment
            }
        }
    }
`;

export const GQL_DELETE_RESOURCE = gql`
    mutation resoureceDeleteById($_id: MongoID!) {
        resourceRemoveById(_id: $_id) {
            recordId
        }
    }
`;

export const GQL_RESOURCE_PAGINATION = gql`
    query resourcePagination(
        $filter: FilterFindManySearchGCResourceInput, 
        $sort: SortFindManyResourceInput,
        $page: Int, 
        $perPage: Int
    ) {
        resourcePagination(
            filter: $filter,
            sort: $sort,
            perPage: $perPage,
            page: $page
        ) {
            items {
                ...ResourceFragment
            }
            pageInfo {
                currentPage
                perPage
                pageCount
                itemCount
                hasNextPage
                hasPreviousPage
            }
        }
    }
    ${GQL_RESOURCE_FRAGMENT}
`;
