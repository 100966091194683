import React, { } from 'react';
import {Grid, Typography} from '@material-ui/core';
import HoopsDropzone from './HoopsDropzone';
import {Link} from 'react-router-dom';
import {MAX_FILE_SIZE_MEGABYTES} from '../../constants/file';

const imageAccept = ['.png', '.jpeg', '.gif', '.jpg', '.bmp'];

export const HoopsUpload = ({
  maxSizeMb = MAX_FILE_SIZE_MEGABYTES,
  accept = imageAccept,
  message,
  mock = false,
  onUploadComplete = () => null,
  meta = {}
}) => {

  const UnacceptedFileTypeReason = () => {
    const helpDocs = 'http://help.hoopscrm.com/en/articles/3300631-image-file-types';
    return (
      <Typography variant={'body2'}>Only file types of ({accept.join(', ')}) are supported.
        <Link href={helpDocs} target={'_blank'}> View Full List</Link>
      </Typography>
    );
  };

  const handleFileUploadComplete = (file) => {
    onUploadComplete(file);
  };

  const RecommendedFileTypes = () => (
    <>
      <Typography variant={'body2'}>Recommended file types:</Typography>
      <Typography variant={'body2'}>({accept.join(', ')})</Typography>
    </>
  );

  const Message = () => (
    <Typography variant={'body2'}>{message}</Typography>
  );

  const FileSize = () => (
    <Typography variant={'body2'}>Maximum file size: {maxSizeMb}MB</Typography>
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <HoopsDropzone
          mock={mock}
          meta={meta}
          onComplete={(file) => handleFileUploadComplete(file)}
          maxSize={maxSizeMb * 1024 * 1024}
          accept={accept}
          acceptReason={<UnacceptedFileTypeReason />}
        />
      </Grid>
      <Grid item xs={12}>
        {accept ? <RecommendedFileTypes /> : null}
        {message ? <Message /> : null}
        <FileSize />
      </Grid>
    </Grid>
  );
};

export default HoopsUpload;
