import {Icon, IconButton, ListItemIcon, Menu, MenuItem, Typography} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, {useState} from 'react';
import ConfirmDeleteDialog from '../../componentsOld/confirmDeleteDialog';

const EmailTemplateManyTableActionMenu = ({
    rowData = [],
    handleMakeDefault = () => null,
    handleDelete = () => null,
    openEditModal = () => null
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const openConfirmDelete = () => {
        setConfirmDelete(true);
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                onClick={handleClick}
                variant={'outlined'}
                size={'small'}
            >
                <Icon>more_horiz</Icon>
            </IconButton>
            <Menu
                elevation={1}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={(e) => {handleClose();openEditModal(e, rowData._id);}}>
                    <ListItemIcon style={{minWidth: '48px'}}>
                        <Icon fontSize='small'>edit</Icon>
                    </ListItemIcon>
                    <Typography variant='body2'>Edit</Typography>
                </MenuItem>
                <MenuItem disabled={rowData.isDefault} onClick={(e) => {handleClose();handleMakeDefault(e, rowData._id);}}>
                    <ListItemIcon style={{minWidth: '48px'}}>
                        <CheckCircleIcon fontSize='small' />
                    </ListItemIcon>
                    <Typography variant='body2'>Set as Default</Typography>
                </MenuItem>
                <MenuItem disabled={rowData.isDefault} onClick={() => {handleClose();openConfirmDelete();}}>
                    <ListItemIcon style={{minWidth: '48px'}}>
                        <Icon fontSize='small'>delete</Icon>
                    </ListItemIcon>
                    <Typography variant='body2'>Delete</Typography>
                </MenuItem>
            </Menu>
            <ConfirmDeleteDialog name={rowData.title} isOpen={confirmDelete} ok={(e) => {setConfirmDelete(false);handleDelete(e, rowData._id);}} />
        </>
    );
};

export default EmailTemplateManyTableActionMenu;
