import React, {Component} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

const styles = (theme) => ({
  absolute: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  relative: {
    position: 'relative',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  loaderContainer: {
    backgroundColor: theme.colors.white,
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 0px 2px',
    borderRadius: '5px',
    padding: theme.spacing(3)
  },
  loadingText: {marginTop: '20px'}
});

class Loading extends Component {
  render() {
    const {text, classes, position} = this.props;
    return (
      <Grid container alignItems={'center'} justifyContent={'center'} className={position === 'absolute' ? classes.absolute : classes.relative} >
        <Grid container item xs={6} md={2} direction={'column'} alignItems={'center'} justifyContent={'center'} className={classes.loaderContainer}>
          <CircularProgress />
          <Typography className={classes.loadingText}>{text}</Typography>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Loading);
