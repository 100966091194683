// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-unused-vars */

import {Table, TableBody, TableCell, TableRow} from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import FormattedCurrency from '../FormattedCurrency';

function SalesReportSummaryTable({currencySymbol, reportSettings, totals = {decorationSales: 0, inventorySales: 0, totalSales: 0}}) {
    return (
        <Table size={'small'}>
            <TableBody>
                <TableRow>
                    <TableCell variant={'head'}>Report Date Range</TableCell>
                    <TableCell colSpan='4' align={'right'}>{moment(reportSettings.startDate).format('D MMM. YYYY')} to {moment(reportSettings.endDate).format('D MMM. YYYY')}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell variant={'head'}>Total Decoration Sales for Period</TableCell>
                    <TableCell align={'right'}>
                        <FormattedCurrency>{totals.decorationSales}</FormattedCurrency>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell variant={'head'}>Total Product Sales for Period</TableCell>
                    <TableCell align={'right'}>
                        <FormattedCurrency>{totals.inventorySales}</FormattedCurrency>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell variant={'head'}>Total Sales for Period</TableCell>
                    <TableCell align={'right'}>
                        <FormattedCurrency>{totals.totalSales}</FormattedCurrency>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
}

export default SalesReportSummaryTable;
