import {Button} from '@material-ui/core';
import {NotificationModal} from '../../Notification/NotificationModal';
import React from 'react';
import {useMutation} from '@apollo/client';
import {GQL_EMAIL_JOB} from '../../../queries/jobs';
import {useDispatch, useSelector} from 'react-redux';
import {get} from 'lodash';
import {snackError, snackSuccess} from '../../../actions/action-types';

const defaultValuesSelector = (state) => ({
    viaEmail: true,
    viaSms: false,
    to: get(state, 'jobsReducer.activeJob.contact.email'),
    replyTo: get(state, 'authReducer.userData.username'),
    subject: 'Your job from {{company.name}} has been shipped! ',
    body: 'Hi {{contact.firstName}},<br/>\n' +
      '<br/>\n' +
      'Thanks for choosing {{company.name}}.<br/>\n' +
      '<br/>\n' +
      'Here’s the tracking number(s) {{job.trackingNumbers}}<br/>\n' +
      '<br/>\n' +
      'Kind regards<br/>\n' +
      '<br/>\n' +
      '{{user.firstName}}',
    phone: get(state, 'jobsReducer.activeJob.contact.phone', ''),
    smsBody: 'Hi {{contact.firstName}},\n' +
      '\n' +
      'Thanks for choosing {{company.name}}.\n' +
      '\n' +
      'Here’s the tracking number(s) {{job.trackingNumbers}}\n' +
      '\n' +
      'Kind regards\n' +
      '\n' +
      '{{user.firstName}}',
  });

export default function JobNotification({jobId, disabled}) {
  const [send] = useMutation(GQL_EMAIL_JOB);
  const dispatch = useDispatch();
  const defaultValues = useSelector(defaultValuesSelector);
  return (
    <NotificationModal hey={1} entity={'job'} defaultValues={defaultValues} onSubmit={({viaSms, viaEmail, to, cc, replyTo, subject, body, phone, smsBody}) => send({
        variables: {
          _id: jobId,
          ...(viaEmail ? {emailOptions: {to, cc, replyTo, subject, body}} : {}),
          ...(viaSms ? {smsOptions: {to: phone, body: smsBody}} : {}),
        }
      })
        .then((log) => {
          console.log(log);
          return dispatch(snackSuccess('Shipping notifications sent'));
        })
        .catch(() => dispatch(snackError('Error Sending Email. Please carefully check your email template and short codes')))}>
      {({open}) => (
          <Button
            disabled={disabled}
            variant={'contained'}
            color={'primary'}
            onClick={open}
          >SEND SHIPPING NOTIFICATION</Button>
        )}
    </NotificationModal>
  );
}
