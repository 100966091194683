// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import React from 'react';
import {Tooltip, Button, Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import DefaultProductImage from '../../assets/images/product_placeholder.jpg';
import {Document, Page, pdfjs} from 'react-pdf/dist/esm/entry.webpack';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme) => ({
  thumbnail: {
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  popper: {opacity: 1},
  tooltip: {maxWidth: '450px'},
}));

const defaults = {
  options: {
    thumbWidth: '50px',
    thumbHeight: '50px',
    tooltipMaxWidth: '450px'
  }
};

export default ({file, options = defaults.options}) => {
  const classes = useStyles();
  const imageUrl = file?.url;

  const {thumbWidth, thumbHeight, tooltipMaxWidth} = {
    ...defaults.options,
    ...options
  };

  return (
    <Tooltip
      classes={{popper: classes.popper, tooltip: classes.tooltip}}
      title={
        file.contentType === 'application/pdf'
          ?
          <Document
            file={imageUrl}
          >
            <Page
              pageNumber={1}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              width={435}
            />
          </Document>
          :
          <img src={imageUrl} alt='' style={{maxWidth: '100%'}} />
      }
      styles={{maxWidth: tooltipMaxWidth}}
      placement={'right'}
    >
      <Button>
        {
          file.contentType === 'application/pdf'
            ?
            <Document
              file={imageUrl}
            >
              <Page
                pageNumber={1}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                width={thumbWidth}
              />
            </Document>
            :
            <span
              className={classes.thumbnail}
              style={{
                backgroundImage: `url('${imageUrl}')`,
                width: thumbWidth,
                height: thumbHeight
              }}
            />
        }
      </Button>
    </Tooltip>
  );

};
