// TODO: fix eslint disable
/* eslint-disable no-shadow */

import DateFnsUtils from '@date-io/date-fns';
import {Checkbox, FormControlLabel, Grid, InputLabel, TextField, Typography} from '@material-ui/core';
import {KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {add, isAfter, roundToNearestMinutes} from 'date-fns';
import React, {useCallback, useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import Comments from '../../componentsOld/Comments/Comments';
import CustomerSelect from '../../componentsOld/customers/CustomerSelect';
import EntitySelect from '../../componentsOld/Entity/EntitySelect';
import StatusChanger from '../../componentsOld/Statuses/StatusChanger';
import UserSelect from '../../componentsOld/User/UserSelect';
import FileListItem from '../file/FileListItem';
import FileSelect from '../file/FileSelect';
import ResourceSelect from '../schedule/ResourceSelect';
import {taskAssociationsToEntities} from './utils';

const pickerDefaults = {
    variant: 'dialog',
    clearable: true,
    inputVariant: 'standard'
};

const fieldDefaults = {
    fullWidth: true,
    variant: 'standard'
};

export const TaskEdit = ({
    task = {},
    onChange = () => null
}) => {
    task.entities = taskAssociationsToEntities(task);
    task.dueAt = task.dueAt ? task.dueAt : roundToNearestMinutes(new Date(), {nearestTo: 15});
    task.endAt = task.endAt ? task.endAt : add(task.dueAt, {minutes: 15});

    const {watch, control, setValue, reset, errors} = useForm({defaultValues: task, mode: 'onChange'});
    const all = watch();
    const allDay = watch('allDay');
    const dueAt = watch('dueAt');
    const files = watch('files');

    useEffect(() => {
        reset(task);
    }, [task, reset]);

    /**
     * Shift the endAt date 15 mins in future if it is not greater than dueAt
     */
    useEffect(() => {
        const due = new Date(dueAt);
        const end = new Date(all.endAt);

        if (!isAfter(end, due)) {
            setValue('endAt', add(due, {minutes: 15}));
        }
    }, [dueAt, all.endAt, setValue]);

    const handleChange = useCallback(
        (task) => {
            onChange(task);
        }, [onChange]);

    handleChange(all);

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <form noValidate autoComplete='off'>
                <Grid container spacing={3}>
                    <Grid item xs={8}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Controller as={TextField} name='title' control={control} label='Title' {...fieldDefaults} />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller as={TextField} name='description' control={control} label='Description' defaultValue='' {...fieldDefaults} {...{minRows: 4, multiline: true}} />
                            </Grid>
                            {!task.$isNew ? <Grid item xs={12}>
                                <Typography variant='subtitle1'>Comments</Typography>
                                <Comments
                                    cardElevation={0}
                                    channelId={`task-${task._id}`}
                                    meta={{
                                        entityId: task._id,
                                        entityType: 'task',
                                        entityTypeName: 'Task',
                                        entityNumber: task.title // Yes this is not the number, but is what is used in the comments notification
                                    }}
                                />
                            </Grid> : null}
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <div>
                                    <InputLabel shrink>Status</InputLabel>
                                </div>
                                <Controller as={<StatusChanger
                                    entityType='task'
                                    entityMapping='status'
                                    size='small'
                                    returnValue
                                />} name='status' required control={control} />

                            </Grid>
                            <Grid item xs={12}>
                                {allDay ?
                                    <Controller as={KeyboardDatePicker} name='dueAt' required control={control} label='Due Date' {...fieldDefaults} {...pickerDefaults} /> :
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}><Controller as={KeyboardDateTimePicker} name='dueAt' required control={control} label='Due Date/Time' {...fieldDefaults} {...pickerDefaults} /></Grid>
                                        <Grid item xs={12}>
                                            <Controller
                                                as={KeyboardDateTimePicker}
                                                name='endAt'
                                                minDate={dueAt}
                                                rules={{
                                                    validate: (v) =>
                                                        // we want a better date range picker
                                                         isAfter(v, dueAt)

                                                }}
                                                helperText={errors.endAt ? 'Can\'t be before Due Date' : null}
                                                error={errors.endAt}
                                                control={control}
                                                label='End Date/Time'
                                                {...fieldDefaults}
                                                {...pickerDefaults}
                                            />
                                        </Grid>
                                    </Grid>

                                }
                                <FormControlLabel
                                    label='All Day'
                                    control={
                                        <Controller
                                            name='allDay'
                                            control={control}
                                            checked={allDay}
                                            as={<Checkbox
                                                color='primary'
                                                label='All Day'
                                                inputProps={{
                                                    onChange: (ev) => {
                                                        setValue('allDay', ev.target.checked);
                                                    }
                                                }}
                                            />}
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    as={ResourceSelect}
                                    name='resourceId'
                                    control={control}
                                    label='Resource'
                                    {...fieldDefaults}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    as={UserSelect}
                                    name='memberIds'
                                    control={control}
                                    label='Assign To'
                                    {...fieldDefaults}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    as={CustomerSelect}
                                    name='customerId'
                                    control={control}
                                    label='Customer'
                                    placeholder='Search Customer'
                                    {...fieldDefaults}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    as={EntitySelect}
                                    name='entities'
                                    control={control}
                                    label='Associate With'
                                    placeholder='Search Jobs/Quotes'
                                    {...fieldDefaults}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    as={FileSelect}
                                    name='fileIds'
                                    control={control}
                                    label='Files'
                                    placeholder='Search Files'
                                    {...fieldDefaults}
                                />

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {files && files.length ?
                            files.map(
                                (file, idx) => <FileListItem key={idx} file={file} />
                            ) :
                            null}
                    </Grid>
                </Grid>
            </form>
        </MuiPickersUtilsProvider>
    );
};

export default TaskEdit;
