// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import EmailTemplateService from '../servicesOld/EmailTemplateService';
import {snackError} from './action-types';
import {hoopsQuerySetRefetch} from './hoopsQuery';

export const CREATE_EMAIL_TEMPLATE = 'CREATE_EMAIL_TEMPLATE';

export function createEmailTemplate(record) {
    return function (dispatch) {
        return EmailTemplateService.createEmailTemplate(record).then((payload) => {
            dispatch(hoopsQuerySetRefetch('emailTemplate', true));
            return payload;
        });
    };
}

export function updateEmailTemplate(id, record) {
    return function (dispatch) {
        return EmailTemplateService.updateEmailTemplate(id, record).then((payload) => {
            dispatch(hoopsQuerySetRefetch('emailTemplate', true));
            return payload;
        });
    };
}

export function getEmailTemplate(id) {
    return function (dispatch) {
        return EmailTemplateService.getEmailTemplateById(id).then((payload) => payload);
    };
}

export function getManyEmailTemplate(filter) {
    return function (dispatch) {
        return EmailTemplateService.getManyEmailTemplate(filter).then((payload) => payload);
    };
}

export function makeDefaultEmailTemplate(id) {
    return function (dispatch) {
        return EmailTemplateService.makeDefaultEmailTemplate(id).then((payload) => {
            dispatch(hoopsQuerySetRefetch('emailTemplate', true));
            return payload;
        });
    };
}

export function deleteEmailTemplate(id) {
    return function (dispatch) {
        return EmailTemplateService.deleteEmailTemplate(id).then((payload) => {
            dispatch(hoopsQuerySetRefetch('emailTemplate', true));
            return payload;
        })
        .catch((e) => {
            const message = e.graphQLErrors[0] ? e.graphQLErrors[0].message : 'Something went wrong when deleting this Email Template.';
            dispatch(snackError(message));
          });
    };
}
