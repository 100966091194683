// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {InputAdornment} from '@material-ui/core';
import React, {useEffect} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {HoopsTextField} from '../../../componentsOld/HoopsTextField';
import {convertMarginToMarkup} from '../../../componentsOld/shared/hoopsUtils';
import {decimalPlaces, toFloat} from '../../../componentsOld/shared/numberUtils';
import priceLogicValues from '../itemLogicValues';

const SellPriceField = ({
    index = null,
    field = {},
    name = '',
    currencySymbol,
}) => {
    const {watch, control, setValue} = useFormContext();

    const buyPrice = watch(`additionalCosts[${index}].buyPrice`);
    const markup = watch(`additionalCosts[${index}].markup`);
    const priceLogicSelected = watch('priceLogic');
    const grossMarginTarget = watch('grossMarginTarget');
    const {grossMargin} = priceLogicValues;

    useEffect(() => {
        if (priceLogicSelected === grossMargin) {
            const markup = convertMarginToMarkup(grossMarginTarget);
            setValue(`additionalCosts[${index}].markup`, decimalPlaces(markup, 2));
        }
        const sellPrice = (toFloat(buyPrice)) * (1 + (toFloat(markup || 0) / 100));
        setValue(name, decimalPlaces(sellPrice, 2));

    }, [markup, buyPrice, priceLogicSelected, grossMarginTarget, grossMargin, index, setValue, name]);

    return (
        <Controller
            name={name}
            defaultValue={field.defaultValue}
            render={(field) => (
                <HoopsTextField
                    type='number'
                    responsive
                    disabled
                    {...field}
                    InputProps={{startAdornment: <InputAdornment position={'start'}>{currencySymbol}</InputAdornment>}}
                />
            )}
            control={control}
        />
    );
};

export default SellPriceField;
