import {Grid, Paper as MuiPaper, withStyles} from '@material-ui/core';
import React, {createContext, useState} from 'react';
import {useDispatch} from 'react-redux';
import {getReportData, SET_REPORT_DATA} from '../../actions/reporting';
import {useGetReports} from '../../hooks/api';
import EntityListHero from '../EntityList/EntityListHero';
import ReportDataView from './ReportDataView';
import ReportSelect from './ReportSelect';
import {ReportSettings} from './ReportSettings';
import dayjs from 'dayjs';

const Paper = withStyles((theme) => ({root: {padding: theme.spacing(2)}}))(MuiPaper);

export const ReportContext = createContext({
  reportType: 'grossMargin',
  reportSettings: {
    startDate: new Date(),
    endDate: new Date(),
  },
  setReportType: () => null,
  setReportSettings: () => null,
  generateReport: () => null,
  getReportApi: () => null,
});

function Reporting() {
  const [reportType, setReportType] = useState('sales');
  const [reportSettings, _setReportSettings] = useState({
    startDate: dayjs().startOf('month').format(),
    endDate: dayjs().endOf('month').format(),
    skip: 0, limit: 200, isPaged: false
});
  const dispatch = useDispatch();
  const generateReport = (payload = {}) => {
    dispatch(getReportData({
      reportType,
      ...reportSettings,
      ...payload,
    }));
  };

  const {data, isLoading, mutateAsync: getReportApi} = useGetReports(reportType, reportSettings);

  const setReportSettings = (payload) => {
    _setReportSettings((prev) => ({
      ...prev,
      ...payload
    }));
    dispatch({type: SET_REPORT_DATA, payload: {}});
  };
  const value = {reportType, setReportType, reportSettings, setReportSettings, generateReport, getReportApi, data, isLoading};

  return (
    <ReportContext.Provider value={value}>
      <Grid container spacing={2} direction={'column'}>
        <EntityListHero
          title='Reporting'
          subtitle='Analyse your financial, customer &amp; team performance with easy reporting.'
          helpArticleUrl='http://help.hoopscrm.com/en/articles/4655944-sales-reports'
          videoId='KTF4hv5cSSQ'
        />
        <Grid item>
          <Grid container spacing={2}>
            {/* Left */}
            <Grid item lg={4} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Paper>
                    <ReportSelect />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper>
                    <ReportSettings />
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            {/* Right  */}
            <Grid item xs>
              <Paper>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ReportDataView />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ReportContext.Provider>
  );
}

export default Reporting;
