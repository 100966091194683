import {TablePagination} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'visible',
    '& > *': {marginTop: theme.spacing(.5),},
    '& .Mui-selected': {
      border: 'solid 1px',
      backgroundColor: 'unset',
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main
    }
  },
}));

export const HoopsQueryPagination = ({
  paging = {
    page: 1,
    perPage: 25,
    itemCount: 250
  },
  inputProps = {},
  onPaging = () => null,
  onPageChange = () => null,
  onRowsPerPageChange = () => null,
}) => {
  const classes = useStyles();

  const handleChangePage = (page) => {
    const serverPage = page + 1;
    onPageChange(serverPage);

    onPaging({
      ...paging,
      page: serverPage,
    });
  };

  const handleChangeRowsPerPage = (perPage) => {
    onRowsPerPageChange(perPage);

    onPaging({
      ...paging,
      perPage,
    });
  };

  return (
    <TablePagination
      component='div'
      count={paging.itemCount}
      page={paging.page - 1}
      className={classes.root}
      {...inputProps}
      onPageChange={(event, value) => handleChangePage(value)}
      rowsPerPage={paging.perPage}
      onRowsPerPageChange={(e) => handleChangeRowsPerPage(e.target.value)}

    />
  );
};
