import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import {HoopsPropTypes} from '../utils';
import {Tabs} from './Tabs';
import {useResizeObserver} from '../../hooks';
import {registerGlobalStyle} from '../../theme';
import {Row} from '../Layout';
import {PopupItem} from '../Popovers';
import {Button} from './Button';

registerGlobalStyle('.tabs-list', (theme) => ({
  '.tabs-container': {
    display: 'flex',
    flexWrap: 'wrap',
    maxHeight: 'var(--tabs-row-height)',
  },
  '.tabs-menu': {
    marginLeft: 1,
    '&::before': {
      content: '""',
      position: 'absolute',
      left: -2,
      top: 5,
      bottom: 7,
      borderLeft: `1px solid ${theme.colors.border.light}`,
    },
    '&.hidden': {display: 'none',},
  }
}));

export function TabsList({className, classNamePopover, value, onChange, resizeContainer, children}) {
  const outerRef = useRef(null);
  const tabsContainerRef = useRef(null);
  const {width: outerWidth} = useResizeObserver(resizeContainer ?? outerRef.current);
  const [tabHeight, setTabHeight] = useState();
  const [tabs, setTabs] = useState(children);
  const [buttons, setButtons] = useState([]);

  // On every render, check if we need to change the tabs that are visible
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    if (tabsContainerRef.current?.firstElementChild) {
      const container = tabsContainerRef.current;
      if (container.firstElementChild.offsetHeight > 0) {
        setTabHeight(container.firstElementChild.offsetHeight);
      }

      // Check if there are any tabs that will overflow
      if (tabs.length === container.children.length) {
        const moreButton = outerRef.current.querySelector('.tabs-menu');

        let firstOverflow = [...container.children].findIndex((el) => el.offsetTop > container.offsetTop);
        if (firstOverflow >= 0 && buttons.length === 0) {
          // The tabs have become too large to fit, but did fit previously, or we are checking sizes, so
          // make sure the more button is visible, so we know where the excess actually is, so make sure
          // the button is displayed, then recalculate the first element that is overflowing
          moreButton.style.display = 'flex';
          firstOverflow = [...container.children].findIndex((el) => el.offsetTop > container.offsetTop);
        }
        let newTabs, newButtons;
        if (firstOverflow > 0) {
          let selectedIndex = tabs.findIndex((child) => child.props.value === value);
          if (selectedIndex < 0 && value != null) {
            // We should never get here, but if we do, we will not do an update to avoid an infinite loop
            newTabs = tabs;
            newButtons = buttons;
          } else if (selectedIndex >= firstOverflow) {
            const width = container.children.item(selectedIndex).offsetWidth + 1; // Allow one for the border
            const right = container.offsetLeft + container.offsetWidth;
            for (; firstOverflow > 2; firstOverflow -= 1) {
              const elem = container.children.item(firstOverflow - 1);
              if (elem.offsetLeft + elem.offsetWidth + 1 + width <= right) { // Allow one for the border
                break;
              }
            }
            newTabs = children.slice(0, firstOverflow);
            newTabs.push(children[selectedIndex]);
            newButtons = children.slice(firstOverflow, children.length);
            newButtons.splice(selectedIndex - firstOverflow, 1);
          } else {
            newTabs = children.slice(0, firstOverflow);
            newButtons = children.slice(firstOverflow, children.length);
          }
          if (newTabs.length !== tabs.length || newTabs.some((tab, index) => tab !== tabs[index])
            || newButtons.length !== buttons.length || newButtons.some((button, index) => button !== buttons[index])) {
            setTabs(newTabs);
            setButtons(newButtons);
          }
        }
        moreButton.style.display = null;
      }
    }
  });

  useEffect(() => {
    setTabs(children);
    setButtons([]);
  }, [outerWidth, children]);

  const handleMenuClick = useCallback((e) => {
    let newValue = e.target.getAttribute(value) ?? e.target.closest('li')?.getAttribute('value');
    if (newValue != null && newValue !== value) {
      onChange?.(newValue);
    }
  }, [onChange, value]);

  return (
    <Row className={[className, 'tabs-list']} ref={outerRef}>
      <Tabs value={value} onChange={onChange}>
        <div className={'tabs-container'} ref={tabsContainerRef} style={tabHeight ? {'--tabs-row-height': `${tabHeight}px`} : {}}>
          {tabs}
        </div>
      </Tabs>
      <Button className={['tabs-menu', buttons.length === 0 && 'hidden']} menu navMinor text={'More'}>
        <div className={classNames([classNamePopover, 'tabs-list tabs-list-popover'])} onClick={handleMenuClick}>
          {buttons.map((button) => (
            <PopupItem key={button.key} value={button.props.value}>{button.props.children}</PopupItem>
          ))}
        </div>
      </Button>
    </Row>
  );
}

TabsList.propTypes = {
  className: HoopsPropTypes.className,
  classNamePopover: HoopsPropTypes.className,
  value: HoopsPropTypes.stringOrNumber,
  onChange: HoopsPropTypes.func,
  resizeContainer: HoopsPropTypes.any,
  children: HoopsPropTypes.children,
};

