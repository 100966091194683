import theme from '../theme';

//these styles are used on the 
//job quote and purchase order edit pages
export const integrations = {
    container: {minHeight: 150},
    greyText: {color: theme.colors.grey.main},
    red: {color: theme.colors.red},
    green: {color: theme.colors.green}

};

export default integrations;
