import {createGraphQLClient} from './GraphQLService';

import {GQL_CREATE_FILE, GQL_UPDATE_FILE, GQL_DELETE_FILE, GQL_GET_FILES} from '../queries/files';
import {isNull} from 'lodash';

const client = createGraphQLClient({});

export default class FileService {
  static _parseFileInput(fileData) {
    const {_id, filename, key, bucket, url, contentType, contentLength, entities = [], customerId = undefined, status = isNull} = fileData;
    return {
      _id,
      filename,
      key,
      bucket,
      url,
      contentType,
      contentLength,
      customerId,
      entities,
      status
    };
  }

  static getFiles({entityIds, isWorkable, customerId, filter, limit = 50, isProof}) {
    return client.query({
      query: GQL_GET_FILES,
      variables: {
        entityIds,
        isWorkable,
        customerId,
        filter,
        limit,
        isProof
      }
    })
    .then((result) => result.data.files);
  }

  static createFile(fileData) {
    return client.mutate({
      mutation: GQL_CREATE_FILE,
      variables: {file: FileService._parseFileInput(fileData)}
    })
      .then(({data: {createFile: file}}) => file);
  }

  static updateFile(_id, fileData) {
    return client.mutate({
      mutation: GQL_UPDATE_FILE,
      variables: {
        _id,
        file: {
          description: fileData.description,
          entities: fileData.entities,
          status: fileData.status
        }
      }
    })
      .then(({data: {updateFile: file}}) => file);
  }

  static deleteFile(_id) {
    return client.mutate({
      mutation: GQL_DELETE_FILE,
      variables: {_id}
    })
      .then(console.log);
  }
}
