// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {Avatar, Grid, Icon, makeStyles, Paper, Typography} from '@material-ui/core';
import {Skeleton} from '@mui/material';
import React from 'react';
import theme from '../../ui/theme';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    marginBottom: theme.spacing(4)
  },
  avatar: {margin: theme.spacing(1),},
  contentContainer: {marginTop: theme.spacing(2)}
}));

const CardContainer = ({
  showTitle = true,
  children,
  title = '',
  icon = null,
  loading = false,
  iconBackgroundColor = theme.color.white,
  buttonComponent
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.card} elevation={0}>
      {showTitle && <Grid container item justifyContent={'space-between'} alignItems={'center'}>
        <Grid item>
          <Grid container alignItems={'center'} spacing={1}>
            <Grid item>
              {loading
                ? <Skeleton variant='circular' sx={{width: 40, height: 40, m: 1}} />
                : <Avatar className={classes.avatar} style={{backgroundColor: iconBackgroundColor}}>
                  <Icon >{icon}</Icon>
                </Avatar>}
            </Grid>
            <Grid item>
              <Typography variant={'h5'}>
                {
                  loading
                    ? <Skeleton sx={{width: 300}} />
                    : title
                }
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {
            (loading && buttonComponent)
              ? <Skeleton sx={{width: 116, height: 52}} />
              : buttonComponent
          }
        </Grid>
      </Grid>}
      <Grid container className={classes.contentContainer}>
        {
          loading
            ? <>
              <Skeleton variant='text' sx={{m: 1, width: '100%'}} />
              <Skeleton variant='text' sx={{m: 1, width: '100%'}} />
            </>
            : children
        }
      </Grid>
    </Paper>
  );

};

export default CardContainer;
