import {gql} from 'apollo-boost';

export const GQL_REGISTER = gql`
  mutation registercompany($data: RegistrationInput!) {
    register(data: $data) {
      companyTradingEntities {
        name
        email
        phone
      }
    }
  }
`;
