// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-shadow */

import {Avatar, Grid, Link as MuiLink, makeStyles, Paper, Table, TableContainer, Tooltip} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import {get} from 'lodash';
import moment from 'moment';
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {updateTask} from '../../../actions/tasks';
import {
    HoopsQuery,
    HoopsQueryContext,
    HoopsQueryEmptyState,
    HoopsQueryFilterChips,
    HoopsQueryFilterDropMenu,
    HoopsQueryFilterStatus,
    HoopsQueryFilterUser, HoopsQueryKanbanBoard,
    HoopsQueryKanbanCardTask, HoopsQueryPagination,
    HoopsQueryTableBody, HoopsQueryTableCellDateTime, HoopsQueryTableHeader, HoopsQueryViewButton
} from '../../../componentsOld/HoopsQuery';
import StatusChangerCell from '../../../componentsOld/Statuses/StatusChangerCell';
import {GQL_TASK_PAGINATION} from '../../../queries/tasks';
import {colors} from '../../../ui/theme';
import {hoopsQueryStyles} from '../../../ui/theme/hoops';
import {featureFlagEnabled, featureFlags} from '../../../utils/featureFlag';
import AutomationIconButton from '../../automation/AutomationIconButton';
import {taskAssociationsToEntities} from '../utils';
import TaskListActions from './TaskListActions';
import {initials} from '../../../utils';

const useStyles = makeStyles((theme) => ({
    ...hoopsQueryStyles,
    greyText: {color: theme.colors.grey.main},
    orangeText: {color: theme.colors.orange},
    redText: {color: theme.colors.red},
}));

const filterListMembers = [{
    key: 'memberIds',
    type: 'array',
    options: [],
    label: 'Assigned To',
    component: (props) => <HoopsQueryFilterUser {...props} />
}];

const filterListStatus = [{
    key: 'status',
    type: 'array',
    options: [],
    label: 'Status',
    component: (props) => <HoopsQueryFilterStatus {...props} entityType='task' />
}];

const Members = ({task}) => {
    const ToolTipTitle = ({users}) => (<>
            {users.map((user, idx) =>
                <Typography key={idx}>{user.fullName}</Typography>
            )}
        </>);

    const members = task.members ? task.members : [];
    return (
        <Tooltip title={<ToolTipTitle users={members} />} arrow>
            <AvatarGroup max={4} style={{justifyContent: 'center'}}>
                {members.map((user) =>
                    <Avatar
                        key={user._id}
                        alt={user.fullName}
                        style={{backgroundColor: colors.green}}
                    >{initials(user.fullName)}</Avatar>
                )}
            </AvatarGroup>
        </Tooltip>
    );
};

const Associations = ({task}) => {
    const entities = taskAssociationsToEntities(task);
    const getEntityLabel = ({entityType, entityData}) => {
        switch (entityType) {
            case 'Job':
                return `Job #${entityData.number} | ${get(entityData, 'customer.name', '')}`;

            case 'Quote':
            default:
                return `Quote #${entityData.number} | ${get(entityData, 'customer.name', '')}`;

        }
    };

    const getEntityLink = ({entityType, entityData}) => {
        switch (entityType) {
            case 'Job':
                return `jobs/${entityData._id}/details`;

            case 'Quote':
            default:
                return `quotes/${entityData._id}/view`;

        }
    };

    const ToolTipTitle = ({entities}) => (<>
            {entities.map((item, idx) =>
                <Typography key={idx}>{getEntityLabel(item)}</Typography>
            )}
        </>);

    const reducedEntites = entities.slice(0, 2);
    const difference = entities.length - reducedEntites.length;

    return (
        <>
            {entities ? <Tooltip title={<ToolTipTitle entities={entities} />} arrow>
                <>
                    {reducedEntites.map((item, idx) =>
                        <MuiLink
                            component={Link}
                            key={idx}
                            to={getEntityLink(item)}
                            alt={'TEST'}
                            underline={'none'}
                            color={'primary'}
                            display='block'
                        >
                            {getEntityLabel(item)}
                        </MuiLink>
                    )}
                    {difference ? <span>+{difference} More</span> : null}
                </>
            </Tooltip> : null}
        </>
    );
};

const isColumnHidden = (hiddenList, column) => {
    if (!hiddenList || hiddenList.length === 0) {
        return false;
    }

    return hiddenList.indexOf(column) !== -1;
};

export const TaskManyTable = ({
    queryStateId = 'taskMany',
    filterToMe = false,
    statusFilter = null,
    jobIds = null,
    quoteIds = null,
    hiddenColumns = null,
    handleCreateTaskClick = () => null,
    fixedFilter = {},
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const meSelector = (state) => state.authReducer.userData;
    const me = useSelector(meSelector);
    const [columns, setColumns] = useState([]);
    const [isFilterReady, setIsFilterReady] = useState(false);
    const [initialFilter, setInitialFilter] = useState({});

    const handleUpdate = (task, enableRefetch = true) => {
        dispatch(updateTask(task._id, task, enableRefetch));
    };
    // handle due date styling
    const dueDateClass = (dueDate) => {
        const now = moment();
        const daysToDueDate = now.diff(dueDate, 'days');

        if (daysToDueDate > -7 && daysToDueDate < 0) {return classes.orangeText;}
        if (daysToDueDate > 0) {return classes.redText;}
        return classes.greyText;
    };

    useEffect(() => {
        if (isFilterReady) {
            return; // don't process again
        }

        let ready = true;
        if (filterToMe) {
            if (!get(initialFilter, '_operators.memberIds.in[0]')) {
                ready = false;
            }
        }

        if (statusFilter) {
            if (!get(initialFilter, '_operators.status.in[0]')) {
                ready = false;
            }
        }

        if (jobIds) {
            if (!get(initialFilter, '_operators.jobIds.in[0]')) {
                ready = false;
            }
        }

        if (quoteIds) {
            if (!get(initialFilter, '_operators.quoteIds.in[0]')) {
                ready = false;
            }
        }

        if (ready) {
            setIsFilterReady(true);
        }

    }, [isFilterReady, initialFilter, filterToMe, statusFilter, me, jobIds, quoteIds]);

    useEffect(() => {
        if (filterToMe && me) {
            setInitialFilter((prev) => ({
                    ...prev,
                    _operators: {
                        ...prev._operators || {},
                        memberIds: {in: [me._id]}
                    }
                }));
        }

        if (statusFilter) {
            setInitialFilter((prev) => ({
                    ...prev,
                    _operators: {
                        ...prev._operators || {},
                        status: {in: statusFilter}
                    }
                }));
        }

        if (jobIds && jobIds.length) {
            setInitialFilter((prev) => ({
                    ...prev,
                    _operators: {
                        ...prev._operators || {},
                        jobIds: {in: jobIds}
                    }
                }));
        }

        if (quoteIds && quoteIds.length) {
            setInitialFilter((prev) => ({
                    ...prev,
                    _operators: {
                        ...prev._operators || {},
                        quoteIds: {in: quoteIds}
                    }
                }));
        }

    }, [filterToMe, me, statusFilter, jobIds, quoteIds]);

    useEffect(() => {

        setColumns([{
            label: 'Task',
            field: 'title',
            render: (rowData) =>
                <>
                    <Typography color='primary' variant='body2' style={{cursor: 'pointer'}} noWrap>{rowData.title}</Typography>
                    <Typography variant='caption' className={classes.greyText} style={{cursor: 'pointer'}}>{rowData.description}</Typography>
                </>,
            sortable: true,
            sortKey: 'TITLE',
            columnStyle: {minWidth: 280,},
            disableClick: false,
        },
        {
            label: 'Assigned To',
            align: 'center',
            field: 'memberIds',
            render: (rowData) => <Members task={rowData} />,
            disableClick: true,
            columnStyle: {width: 160}
        },
        {
            label: 'Associated With',
            field: 'entities',
            render: (rowData) => <Typography variant='body2' className={classes.typographyRestrictTwoLines}><Associations task={rowData} /></Typography>,
            filtering: false,
            disableClick: true,
            hidden: isColumnHidden(hiddenColumns, 'entities'),
            columnStyle: {minWidth: 220}
        },
        {
            label: 'Due',
            field: 'dueAt',
            render: (rowData) => <HoopsQueryTableCellDateTime value={rowData.dueAt} showTime={false} className={dueDateClass(rowData.dueAt)} />,
            filtering: false,
            sortable: true,
            sortKey: 'DUEAT',
            columnStyle: {width: 160}
        },
        {
            label: 'Status',
            field: 'status',
            align: 'center',
            render: (rowData) => <StatusChangerCell entityType='task' entityMapping='status' value={rowData.status} onChange={(newStatus) => handleUpdate({...rowData, status: newStatus.value})} />,
            cellStyle: {padding: 0, borderRight: '1px solid white', borderBottom: '1px solid white', whiteSpace: 'nowrap', position: 'relative'},
            columnStyle: {width: 162, minWidth: 162},
            disableClick: true,
            sortable: true,
            sortKey: 'STATUS',
        },
        {
            label: 'Actions',
            filtering: false,
            disableClick: true,
            align: 'center',
            render: (rowData) => <TaskListActions task={rowData} />,
            columnStyle: {minWidth: 90, width: 90}
        }

        ]);
      // TODO: FIX HOOK DEPENDENCIES
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hiddenColumns]);

    const handleRowClick = (event, row) => {
        history.push(`/tasks/${row._id}/details`);
    };

    const tableContainerElement = useRef();

    return (
        <>{isFilterReady ?
            <HoopsQuery
                refetchStateId='task' // the entity to listen to for refetching
                queryStateId={queryStateId} columns={columns} // the location in state for storing and getting the query
                gqlQuery={GQL_TASK_PAGINATION}
                resultField='taskPagination'
                initialFilter={initialFilter}
                initialSort='DUEAT_ASC'
                initialPaging={{perPage: 50, page: 1}}
                fixedFilter={fixedFilter}
            >
                <HoopsQueryContext.Consumer>
                    {({items, sort, setSort, filter, paging, setPaging, setFilter, chips, setChips, view, setView, getKanbanColumns, hoopsQueryColumns, setHoopsQueryColumns}) => (<>
                            <Grid container direction='row' justifyContent='space-between' alignItems='flex-start'>
                                <Grid item xs={6}>
                                    <HoopsQueryFilterDropMenu
                                        label={'Assigned To'}
                                        filter={filter}
                                        filterComponents={filterListMembers}
                                        onFilterChange={setFilter}
                                        chips={chips}
                                        onChipsChange={setChips}
                                    />

                                    <HoopsQueryFilterDropMenu
                                        label={'Status'}
                                        filter={filter}
                                        filterComponents={filterListStatus}
                                        onFilterChange={setFilter}
                                        chips={chips}
                                        onChipsChange={setChips}
                                    />
                                </Grid>
                                <Grid item xs={6} >
                                    <Grid container justifyContent='flex-end' spacing={1}>
                                        <Grid item>
                                            {featureFlagEnabled(featureFlags.automations) && <AutomationIconButton />}
                                        </Grid>
                                        <Grid item>
                                            {featureFlagEnabled(featureFlags.kanban) && <HoopsQueryViewButton view={view} onViewChange={setView} />}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <HoopsQueryFilterChips filter={filter} onFilterChange={setFilter} chips={chips} onChipsChange={setChips} />
                            {view === 'table' &&
                                <>
                                    <TableContainer component={Paper} className={classes.tableContainer} ref={tableContainerElement}>
                                        <Table className={classes.table} stickyHeader>
                                            <HoopsQueryTableHeader hasCheckBox={false} columns={hoopsQueryColumns} onSort={setSort} sortString={sort} />
                                            <HoopsQueryTableBody rows={items} columns={hoopsQueryColumns} onRowClick={handleRowClick} stripedRows={true} emptyStateComponent={() => <HoopsQueryEmptyState filter={filter} columns={hoopsQueryColumns} field={'Task'} rowsPerPage={paging.perPage} onClearFilter={setFilter} onChipsChange={setChips} onAddItem={handleCreateTaskClick} tableContainerElement={tableContainerElement} />} />
                                        </Table>
                                    </TableContainer>
                                    <HoopsQueryPagination paging={paging} onPaging={setPaging} />
                                </>
                            }
                            {view === 'kanban' &&
                                <HoopsQueryKanbanBoard
                                    items={items}
                                    itemsPerColumn={20} // how many items to show on load
                                    itemsShowMore={5} // this is how many more items you'd like to show when you click the show more button
                                    columns={getKanbanColumns('task')}
                                    onChange={(task) => handleUpdate(task, false)}
                                    cardContent={(item) => <HoopsQueryKanbanCardTask item={item} />}
                                />
                            }

                        </>)}
                </HoopsQueryContext.Consumer>
            </HoopsQuery> : null}
        </>
    );
};

export default TaskManyTable;
