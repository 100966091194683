// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {get, truncate} from 'lodash';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import React, {useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';

export default function SelectShipTo({purchaseOrder, onSelect, input: {onChange, value}}) {
  const [ addresses, setAddresses ] = useState([]);

  useEffect(() => {
    let addressList = [];
    const linkedJobs = get(purchaseOrder, 'linkedJobs', []);

    linkedJobs.forEach((job) => {
      const address = get(job, 'customer.shippingAddress');
      const name = get(job, 'customer.name', 'customer');
      const phone = get(job, 'customer.phone');
      const email = get(job, 'customer.email');
      if (address) {
        addressList.push({
          name,
          phone,
          email,
          ...address
        });
      }
    });

    const companyAddress = get(purchaseOrder, 'company.shippingAddress');
    const name = get(purchaseOrder, 'company.name', 'company');
    const phone = get(purchaseOrder, 'company.phone');
    const email = get(purchaseOrder, 'company.email');
    if (companyAddress) {
      addressList.push({
        name,
        phone,
        email,
        ...companyAddress
      });
    }

    setAddresses(addressList);
  }, [ purchaseOrder ]);

  return (
    <Grid container spacing={2} alignItems={'center'} wrap={'nowrap'}>
      <Grid item>
        <Typography variant={'body1'}>Ship To: </Typography>
      </Grid>
      <Grid item xs>
        <Select fullWidth
                renderValue={(value) => truncate(value.name)}
                onChange={(e, value) => {
                  onChange(e, value);
                  onSelect(e.target.value);
                }}
                value={value}
        >
          {addresses.map((address, index) => (
            <MenuItem value={address} key={index}>{address.name} - {address.formattedAddress}</MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
}
