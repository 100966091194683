// TODO: fix eslint disable
/* eslint-disable no-duplicate-imports */

import React from 'react';
import {connect} from 'react-redux';
import {SignupForm} from './SignupForm';
import {FORGET_PASSWORD_GROUP} from '../../constants/index';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {AuthService} from '../../servicesOld/AuthService';
import {snackSuccess} from '../../actions/action-types';
import {Typography} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {Link as MuiLink} from '@material-ui/core';
import {LOGIN_ROUTE} from '../../containers/routes/PreAuthRoutes';

const styles = (theme) => ({

  formContainer: {
    width: '100%',
    backgroundColor: '#fff'
  },
  buffer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
});

class ForgetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);

    this.state = {passwordReset: false};
  }

  componentDidMount() {
    document.title = 'Forgot Password | Hoops';
  }

  submit(values) {
    AuthService.resetPassword(values.email)
    .then(() => {
      this.setState({passwordReset: true});
    });
  }

  renderSubmitMessage() {
    const {classes} = this.props;
    return (
      <React.Fragment>
        <Typography color={'primary'} variant={'h4'}>Reset Complete</Typography>
        <Typography className={classes.buffer} >Great! If that email is linked to an account, we have sent you a new password. Please check your email.</Typography>
        <MuiLink component={Link} color={'primary'} underline={'always'} to={LOGIN_ROUTE}>
          {'Log in'}
        </MuiLink>
      </React.Fragment>
    );
  }

  render() {
    const {classes} = this.props;
    const {passwordReset} = this.state;
    return (
      <div className={classes.root}>
        <Grid item className={classes.formContainer}>
          {
            passwordReset ? this.renderSubmitMessage()
            :
            <SignupForm
            onSubmit={this.submit}
            formGroup={FORGET_PASSWORD_GROUP}
          />
          }
        </Grid>
      </div>
    );
  }
}

function mapStateToProps() {
  return {}; // user: state.user
}

function mapDispatchToProps(dispatch) {
  return {snackSuccess: (message) => dispatch(snackSuccess(message))};
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ForgetPassword));
