// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {Button, ButtonGroup, MuiThemeProvider} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React, {useCallback, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {CLEAR_JOB_ITEM_VARIANTS, getJobItemVariant} from '../../../../actions/jobs';
import {openJobVariantsModal} from '../../../../actions/modals';
import {PopupItem, PopupMenu} from '../../../../componentsLib/Popovers/PopupMenu';
import CatalogService from '../../../../servicesOld/CatalogService';
import green from '../../../../ui/theme/green';

const JobVariantsButton = ({
  meta,
  input,
  input: {value: _id},
  product,
  setDialogOpen = () => null
}) => {
  const dispatch = useDispatch();

  const [openMenu, setOpenMenu] = useState(false);
  const anchorRef = useRef(null);

  const handleBuildItem = useCallback(() => {
    if (product && product._id) {
      CatalogService.getProduct(product._id)
        .then((response) => {

          if (_id) {
            dispatch({type: CLEAR_JOB_ITEM_VARIANTS});
            setTimeout(dispatch(getJobItemVariant(_id)));
          } else {
            dispatch({type: CLEAR_JOB_ITEM_VARIANTS});
          }

          let product = response && response.data && response.data.product;

          dispatch(
            openJobVariantsModal({
              meta,
              input,
              product
            })
          );
        });
    } else {
      dispatch(getJobItemVariant(_id));
      dispatch(
        openJobVariantsModal({
          meta,
          input,
          product
        })
      );
    }
  }, [_id, dispatch, input, meta, product]);

  const handleAddConfiguration = () => {
    setDialogOpen(true);
    setOpenMenu(false);
  };

  const handleToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenMenu(false);
  };

  return (
    <>
      <MuiThemeProvider theme={green}>
        <ButtonGroup disableElevation color='primary' variant='contained' ref={anchorRef} aria-label='split button'>
          <Button style={{marginBottom: 1, whiteSpace: 'nowrap', borderRight: 'none', marginRight: 1}} onClick={handleBuildItem}>
            Build Item
          </Button>
          <Button
            color='primary'
            size='small'
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label='select merge strategy'
            aria-haspopup='menu'
            onClick={handleToggle}
            style={{marginBottom: 1, whiteSpace: 'nowrap'}}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
      </MuiThemeProvider>
      <PopupMenu open={openMenu} anchorEl={anchorRef.current} onClose={handleClose}>
        <PopupItem text='Build Item' onClick={handleBuildItem} />
        <PopupItem text='Add a pre-built Item' onClick={() => handleAddConfiguration()} />
      </PopupMenu>
    </>
  );
};

export default JobVariantsButton;
