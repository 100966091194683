// TODO: fix eslint disable
/* eslint-disable no-unused-vars, */

import {client} from './GraphQLService';
import {GQL_GET_CUSTOMER, GQL_IMPORT_CUSTOMERS, GQL_DELETE_CUSTOMER, GQL_CREATE_CUSTOMER, GQL_UPDATE_CUSTOMER, GQL_GET_CUSTOMERS} from '../queries/customers';
import {find, omit, get} from 'lodash';
import {GQL_GET_CONTACT} from '../queries/contacts';

export class CustomerService {
  constructor(customer) {
    this.customer = customer;
  }

  static customerImport({fileId}) {
    return client.mutate({
      mutation: GQL_IMPORT_CUSTOMERS,
      variables: {_id: fileId} // the id for the Hoops file to import
    }).then(({data: {customerImport}}) => customerImport);
  }

  static _parseCustomerInput(customer) {
    const settings = {
      minimumMarkup: parseFloat(customer.settings?.minimumMarkup) || null,
      salesDiscount: parseFloat(customer.settings?.salesDiscount) || null,
      // cast as a number, not boolean as you might expect, hangover from MySQL database
      whitelabelProofPortal: get(customer, 'settings.whitelabelProofPortal', false) ? 1 : 0
    };
    return omit({
      ...customer,
      addresses: customer.addresses.map((address) => omit(address, 'formattedAddress')),
      settings,
    }, 'contacts');
  }

  static getContact(_id) {
    return client.query({
      query: GQL_GET_CONTACT,
      variables: {filter: {_id}}
    })
      .then(({data: {contactOne}}) => contactOne);
  }

  static getAddressByLabel(customer, label) {
    if (!customer || !customer.addresses) {
      return null;
    }

    let address = find(customer.addresses, {label});

    if (address) {
      let formattedAddress = address.formattedAddress;
      if (!formattedAddress){
        const keys = ['address1', 'address2', 'city', 'state', 'country', 'postcode'];
        formattedAddress = keys
          .filter((key) => address[key])
          .map((key) => address[key])
          .join(', ');

      }
      return formattedAddress;

    }
    return null;
  }

  static getCustomers({q = null, page = 1, perPage = 25}) {
    return client.query({
      query: GQL_GET_CUSTOMERS,
      variables: {
        filter: {q},
        page,
        perPage
      }
    })
      .then(({data: {customerPagination}}) => customerPagination);
  }

  static getCustomer(_id) {
    return client.query({
      query: GQL_GET_CUSTOMER,
      fetchPolicy: 'no-cache',
      variables: {_id}
    })
      .then(({data: {customerOne}}) => customerOne);
  }

  static async create(customer) {
    const {data} =  await client.mutate({
      mutation: GQL_CREATE_CUSTOMER,
      variables: {
        record: CustomerService._parseCustomerInput(customer),
        contacts: customer.contacts
      }
    });

    return data;
  }

  static searchCustomerContacts(query) {
    throw new Error('NOT IMPMENTDED!');
  }

  async delete(_id) {
    const {data: {customerRemoveById}} = await client.mutate({
      mutation: GQL_DELETE_CUSTOMER,
      variables: {_id}
    });

    return customerRemoveById.recordId;
  }

  async save() {
    const {_id, customerRep, ...customer} = this.customer;
    if (_id) {
        const {data} = await client.mutate({
          mutation: GQL_UPDATE_CUSTOMER,
          variables: {
            _id,
            record: CustomerService._parseCustomerInput(customer),
            contacts: customer.contacts,
          }
        });

        return data.customerUpdateById.record;
    } else {
      const result = await CustomerService.create(this.customer);

      return result.customerCreateOne.record;
    }
  }
}
