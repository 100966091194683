import {
  HOOPS_QUERY_SET_REFETCH,
  HOOPS_QUERY_SET_QUERY,
  HOOPS_QUERY_SET_QUERY_FILTER,
  HOOPS_QUERY_SET_QUERY_SORT,
  HOOPS_QUERY_SET_QUERY_PAGING,
  HOOPS_QUERY_SET_VIEW,
} from '../actions/hoopsQuery';

const initialState = {
  queryState: {},
  refetchState: {},
};

export const hoopsQueryReducer = (
  state = {...initialState},
  {type, payload}
) => {
  switch (type) {
    case HOOPS_QUERY_SET_QUERY_SORT:
      return {
        ...state,
        queryState: {
          ...state.queryState,
          [payload.queryStateId]: {
            ...state.queryState[payload.queryStateId],
            sort: payload.sort,
            page: 1, // reset page
          },
        },
      };

    case HOOPS_QUERY_SET_QUERY_FILTER:
      return {
        ...state,
        queryState: {
          ...state.queryState,
          [payload.queryStateId]: {
            ...state.queryState[payload.queryStateId],
            filter: payload.filter,
            page: 1, // reset page
          },
        },
      };

    case HOOPS_QUERY_SET_QUERY_PAGING:
      return {
        ...state,
        queryState: {
          ...state.queryState,
          [payload.queryStateId]: {
            ...state.queryState[payload.queryStateId],
            perPage: payload.paging.perPage || 25,
            page: payload.paging.page || 1,
          },
        },
      };

    case HOOPS_QUERY_SET_REFETCH:
      return {
        ...state,
        refetchState: {
          ...state.refetchState,
          [payload.refetchStateId]: payload.state,
        },
      };

    case HOOPS_QUERY_SET_QUERY:
      return {
        ...state,
        queryState: {
          ...state.queryState,
          [payload.queryStateId]: payload.query,
        },
      };

    case HOOPS_QUERY_SET_VIEW:
      return {
        ...state,
        queryState: {
          ...state.queryState,
          [payload.queryStateId]: {
            ...state.queryState[payload.queryStateId],
            view: payload.view
          },
        },
      };

    default:
      return state;
  }
};

export default hoopsQueryReducer;
