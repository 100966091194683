// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-shadow */

import {FormControl, InputLabel, MenuItem, Select, Typography} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {closeMergePurchaseOrderVendorModal, openMergePurchaseOrderModal} from '../../../../actions/modals';
import {SET_FORM_PURCHASE_ORDER} from '../../../../actions/purchaseOrders';
import {VendorService} from '../../../../servicesOld/VendorService';
import HoopsFormDialog from '../../../modal/HoopsFormDialog';

export function MergePurchaseOrderVendorModal() {

  const dispatch = useDispatch();
  const [vendorId, setVendorId] = useState(null);
  const [vendor, setVendor] = useState(null);
  const [vendors, setVendors] = useState([]);
  const modalSelector = (state) => state.modalReducer.mergePurchaseOrderVendorModal;
  const {open} = useSelector(modalSelector);

  useEffect(() => {
    if (open) {
      VendorService.getVendorsWithOpenPurchaseOrders().then((results) => setVendors(results));
    }
  }, [open]);

  const handleSubmit = () => {
    handleClose();
      dispatch({
        type: SET_FORM_PURCHASE_ORDER,
        payload: {
          locked: true,
          vendor,
          vendorId,
          isMasterPurchaseOrder: true
        }
      });

    dispatch(openMergePurchaseOrderModal({vendorId}));
  };

  const handleClose = (data) => {
    dispatch(closeMergePurchaseOrderVendorModal());
  };

  function SaveButtonComponent() {
    return (
      <Button
        variant={'contained'}
        disabled={!vendorId}
        color={'primary'}
        onClick={handleSubmit}
      >Next</Button>
    );
  }

  const handleChange = (e) => {
    setVendorId(e.target.value);
    setVendor(vendors.find(
      (vendor) => vendor._id === e.target.value)
      );
  };

  return (
    <>
      <HoopsFormDialog
        maxWidth={'sm'}
        open={open}
        onCancel={handleClose}
        title={'Choose Vendor'}
        SaveButton={SaveButtonComponent}
      >
        {(vendors && vendors.length ?
          <FormControl style={{width: '100%'}}>
            <InputLabel>Select Vendor</InputLabel>
            <Select
              id='vendorSelect'
              value={vendorId}
              onChange={handleChange}
            >
              {vendors && vendors.map((vendor, index) => (
                <MenuItem value={vendor._id} key={index}>{vendor.name}</MenuItem>
              ))}
            </Select>
          </FormControl> :
          <>
            <Typography variant={'h6'}>Sorry we can&apos;t find any Purchase Orders to Merge</Typography>
            <Typography variant={'body1'} paragraph>
              {'Only purchase orders that are from the same Vendor can be merged. '}
            </Typography>
          </>)}
      </HoopsFormDialog>
    </>
  );
}

export default MergePurchaseOrderVendorModal;
