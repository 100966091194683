import {ApolloProvider} from '@apollo/client';
import {CssBaseline} from '@material-ui/core';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {ThemeProvider} from '@mui/material';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import './assets/scss/index.scss';
import './assets/scss/overwrite.scss';
import FirebaseProvider from './componentsOld/FirebaseProvider/FirebaseProvider';
import SnackbarLauncher from './componentsOld/snackbar/snackbar';
import {firebaseConfig} from './config';
import App from './containers/App.jsx';
import './servicesOld/CustomerService';
import {client} from './servicesOld/GraphQLService';
import store from './store';
import theme, {muiTheme} from './ui/theme';
import {QueryClient, QueryClientProvider} from 'react-query';
import {SnackbarProvider} from 'notistack';
import {HoopsGlobalStyles} from './theme';
import {GlobalDragDropProvider} from './componentsLib/DragDrop';

const queryClient = new QueryClient();

const rootElement = document.getElementById('root');
ReactDOM.render(
    <ThemeProvider theme={muiTheme}>
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <CssBaseline/>
            <HoopsGlobalStyles/>
            <GlobalDragDropProvider/>
            <ApolloProvider client={client}>
              <FirebaseProvider firebaseConfig={firebaseConfig}>
                <QueryClientProvider client={queryClient}>
                <SnackbarProvider>
                  <App/>
                </SnackbarProvider>
                </QueryClientProvider>
              </FirebaseProvider>
            </ApolloProvider>
          </LocalizationProvider>
          <SnackbarLauncher/>
        </Provider>
      </MuiThemeProvider>
    </ThemeProvider>,
  rootElement
);

if (module.hot) {
  module.hot.accept();
}
