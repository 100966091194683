import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';

class CustomerContactCell extends React.Component {
  state = {
    anchorEl: null,
    contactModalOpen: false
  };

  render() {
    const {rowData} = this.props;
    const chipLabel = `${rowData.contacts.length - 1} More`;

    return (
      <Grid container spacing={2} justifyContent={'flex-start'} alignItems={'center'}>
        <Grid item>
          {rowData.contacts[0] ? <Typography variant={'body2'}>{rowData.contacts[0].firstName} {rowData.contacts[0].lastName}</Typography> : 
          <Typography variant={'body2'}>No Contacts</Typography>}
        </Grid>
        <Grid item>
          {(rowData.contacts.length - 1) > 0 &&
          <Chip label={chipLabel}
                variant='outlined'
                size={'small'}
                color={'primary'}
          />
          }
        </Grid>
      </Grid>
    );
  }
}

export default CustomerContactCell;
