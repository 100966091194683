// TODO: fix eslint disable
/* eslint-disable no-shadow */

import Grid from '@material-ui/core/Grid';
import React from 'react';
import {connect} from 'react-redux';
import {login} from '../../../actions/action-types';
import {LOGIN_FIELD_GROUP} from '../../../constants';
import {SignupForm} from '../SignupForm';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  submit(values) {
    const {login} = this.props;
    const {username, password} = values;

    login(username, password);
  }

  componentDidMount() {
    document.title = 'Login | Hoops';
  }

  render() {
    const {auth} = this.props;

    return (
      <Grid item xs={12}>
        <SignupForm onSubmit={this.submit} formGroup={LOGIN_FIELD_GROUP} isLoading={auth.loggingIn} />
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {auth: state.authReducer};
}

function mapDispatchToProps(dispatch) {
  return {login: (username, password) => dispatch(login(username, password))};
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);
