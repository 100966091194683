// TODO: fix eslint disable
/* eslint-disable no-class-assign, no-shadow */

import React, {Component, Fragment} from 'react';
import {withStyles} from '@material-ui/core';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {get} from 'lodash';
import {stripHTML} from '../../../utils/stripHtml';

function ProofTh({children, ...thProps}) {
  return (
    <th {...thProps}>
      <Typography variant={'body2'}>
        <strong>{children}</strong>
      </Typography>
    </th>
  );
}

function ProofTd({children, ...tdProps}) {
  return (
    <td {...tdProps}>
      <Typography variant={'body2'}>
        {children}
      </Typography>
    </td>
  );
}

const styles = (theme) => ({
  proofTable: {
    width: '100%',
    borderCollapse: 'collapse'
  },
  descriptionHeading: {width: '100%'},
  tableHeadingContainer: {textAlign: 'left'},
  tableHeading: {
    padding: `${theme.spacing(1.5)}px ${theme.spacing(0.5)}px`,
    fontWeight: 500,
    width: 60,
    [theme.breakpoints.down('lg')]: {width: 0}
  },
  tableCell: {padding: theme.spacing(1.5),},
  quantity: {textAlign: 'right'},
  topBorder: {borderTop: `1px solid ${theme.colors.grey.light}`},
});

class ProofPreviewTable extends Component {
  render() {
    const {classes, products} = this.props;

    return (
      <Fragment>
        <Grid item container>
          <table className={classes.proofTable}>
            <thead>
              <tr className={classNames(classes.tableHeadingContainer)}>
                <ProofTh className={classNames(classes.tableHeading, classes.descriptionHeading)}>
                  Product Description
                </ProofTh>
                <ProofTh className={classes.tableHeading}>
                  Size
                </ProofTh>
                <ProofTh className={classes.tableHeading}>
                  Colour
                </ProofTh>
                <ProofTh className={`${classes.tableHeading} ${classes.quantity}`}>
                  Quantity
                </ProofTh>
              </tr>
            </thead>
            <tbody>
            {products && products.map((product, index) => {
              const variants = get(product, 'jobItemVariant.variants', []);
              return (
                variants.length
                  ? <Fragment key={index}>
                    <tr className={classes.topBorder}>
                      <ProofTd rowSpan={variants.length + 1} className={classes.tableCell}>
                        {stripHTML(product.description)}
                      </ProofTd>
                    </tr>
                    {variants.map((variant, index) => (
                      <tr key={index} className={index === 0 ? classes.topBorder : ''}>
                        <ProofTd className={classes.tableCell}>
                          {get(variant, 'size')}
                        </ProofTd>
                        <ProofTd className={classes.tableCell}>
                          {get(variant, 'colour')}
                        </ProofTd>
                        <ProofTd className={classNames([classes.tableCell, classes.quantity])}>
                          {get(variant, 'quantity')}
                        </ProofTd>
                      </tr>
                    ))}
                  </Fragment>
                  : <Fragment key={index}>
                    <tr className={classes.topBorder}>
                      <ProofTd className={classes.tableCell}>
                        {stripHTML(product.description)}
                      </ProofTd>
                      <ProofTd className={classes.tableCell}>
                        -
                      </ProofTd>
                      <ProofTd className={classes.tableCell}>
                        -
                      </ProofTd>
                      <ProofTd className={classNames([classes.tableCell, classes.quantity])}>
                        {get(product, 'quantity')}
                      </ProofTd>
                    </tr>
                  </Fragment>
              );
            })}
            </tbody>
          </table>
        </Grid>
      </Fragment>
    );
  }
}

ProofPreviewTable = withStyles(styles)(ProofPreviewTable);

export default ProofPreviewTable;
