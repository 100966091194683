import {gql} from 'apollo-boost';
import {ADDRESS_TO_FRAGMENT} from './addressToFragment';
import {SINGLE_JOB_ITEM_VARIANT_FRAGMENT} from './jobs';
import {SINGLE_PRICE_ELEMENT_FRAGMENT} from './quotes';

const INVOICE_FRAGMENT = gql`
  fragment Invoice on Invoice {
    _id
    customReference
    shipTo {
      ${ADDRESS_TO_FRAGMENT}
    }
    billTo {
      ${ADDRESS_TO_FRAGMENT}
    }
    quoteId
    jobId
    invoiceNumber
    balance
    dueDate
    xeroInvoiceId
    xeroCustomerId
    qboInvoiceReference
    qboLastRequestStatus
    errorMessage
    customer {
      _id
      name
      phone
      email
      contacts {
        _id
        fullName
        phone
        email
      }
      addresses {
        _id
        address1
        address2
        country
        city
        postcode
        state
        label
        formattedAddress
      }
    }
    contactId
    contact {
      _id
      fullName
      phone
      email
    }
    items {
      _id
      qty
      rate
      description
      salesDocItemId
      priceElementId
      priceElement {
        ...SinglePriceElement
      }
      jobItemVariantId
      jobItemVariant {
        ...SingleJobItemVariant
      }
      tax {
        _id
        name
        type
        components {
          name
          rate
        }
      }
      accountingRevenueAccount
      accountingInventoryItem
    }
  }
  ${SINGLE_JOB_ITEM_VARIANT_FRAGMENT}
  ${SINGLE_PRICE_ELEMENT_FRAGMENT}
`;

export const GQL_DRAFT_INVOICE_FROM_QUOTE = gql`
  query draftInvoiceFromQuote($quoteId: String!) {
    draftInvoiceFromQuote(quoteId: $quoteId) {
      ...Invoice
    }
  }
  ${INVOICE_FRAGMENT}
`;

export const GQL_DRAFT_INVOICE_FROM_JOB = gql`
  query draftInvoiceFromJob($jobId: String!) {
    draftInvoiceFromJob(jobId: $jobId) {
      ...Invoice
    }
  }
  ${INVOICE_FRAGMENT}
`;

export const GQL_UPDATE_INVOICE = gql`
  mutation updateInvoice($_id: String, $invoice: InvoiceInput) {
    updateInvoice(_id: $_id, invoice: $invoice) {
      ...Invoice
    }
  }
  ${INVOICE_FRAGMENT}
`;

export const GQL_CREATE_INVOICE = gql`
  mutation createInvoice($invoice: InvoiceInput) {
    createInvoice(invoice: $invoice) {
      ...Invoice
    }
  }
  ${INVOICE_FRAGMENT}
`;

export const GQL_GET_INVOICE = gql`
  query invoice($_id: String!) {
    invoice(_id: $_id) {
      ...Invoice
    }
  }
  ${INVOICE_FRAGMENT}
`;
