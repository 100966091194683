import React, {useCallback, useEffect, useRef, useState} from 'react';
import {CalendarPicker} from '@mui/x-date-pickers';
import {Event as CalendarIcon} from '@mui/icons-material';
import dayjs from 'dayjs';
import {registerGlobalStyle} from '../../theme';
import {HoopsPropTypes} from '../utils';
import {AutoCompleteContainer, AutoCompletePopover, AutoCompleteValueContainer} from '../Popovers';
import {TextInput} from '../Basic';
import {formatDateShort} from '../../utils';

registerGlobalStyle('.date-picker', (theme) => ({
  '*': {cursor: 'pointer',},
  '.popover-open .text-input': {borderColor: theme.colors.border.focus},
  '.suffix': {
    color: theme.colors.text.mediumIcon,
    marginRight: theme.spacing(1),
  }
}));

const minDate = new Date((new Date()).getFullYear() - 2, 0, 0, 0, 0, 0);

export function DatePicker({className, initialOpen, label, placeholder, placement, value, onChange, onClose}) {
  const [view, setView] = useState('day');
  const [date, setDate] = useState(value ? dayjs(value) : null);
  const contextRef = useRef();

  useEffect(() => {
    setDate(value ? dayjs(value) : null);
  }, [value]);

  const handleChange = useCallback((newDate) => {
    if (view === 'day') {
      if (contextRef.current) {
        contextRef.current.closePopover();
      }

      // Convert the Dayjs date to a string, using UTC 10am to handle NZ
      onChange(newDate.format('YYYY-MM-DD[T10:00:00Z]'));
    } else {
      setView('day');
    }
  }, [view, onChange]);

  const handleViewChange = useCallback((newView) => {
    setView(newView);
  }, [setView]);

  return (
    <AutoCompleteContainer
      className={[className, 'date-picker']}
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onClose={onClose}
      contextRef={contextRef}
    >
      <AutoCompleteValueContainer initialOpen={initialOpen}>
        <TextInput
          readOnly
          value={formatDateShort(date)}
          type={'tel'}
          suffix={CalendarIcon}
        />
      </AutoCompleteValueContainer>
      <AutoCompletePopover className={'date-picker-popover'} placement={placement}>
        <CalendarPicker date={date} minDate={minDate} onChange={handleChange} onViewChange={handleViewChange} view={view}/>
      </AutoCompletePopover>
    </AutoCompleteContainer>
  );
}

DatePicker.propTypes = {
  className: HoopsPropTypes.className,
  initialOpen: HoopsPropTypes.bool,
  label: HoopsPropTypes.string,
  placeholder: HoopsPropTypes.string,
  placement: HoopsPropTypes.string,
  value: HoopsPropTypes.string,
  onChange: HoopsPropTypes.func,
  onClose: HoopsPropTypes.func,
};
