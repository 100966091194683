import {Box, Paper, Typography} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import React from 'react';

const VendorInformationCard = ({classes, heading, subHeading, information, loading, editComponent}) => {
    if (loading) {
        return <Skeleton variant='rect' width='100%' height={200} />;
    }
    return (
        <Paper className={classes.paperPadding}>
            <Box >
                <Typography className={classes.vendorHeading} variant='h5' gutterBottom>{heading}</Typography>
                {editComponent}
            </Box>
            <Typography variant='h6'>{subHeading}</Typography>
            {information.attention && <Typography variant={'body2'}>Attn: {information.attention}</Typography>}

            <Typography>{information.phone}</Typography>
            <Typography>{information.email}</Typography>
            <Typography>{information.address}</Typography>
        </Paper>
    );
};

export default VendorInformationCard;
