import {combineReducers} from 'redux';
import {reducer as formReducer} from 'redux-form';
import authReducer from './user';
import appReducer from './app';
import customerReducer from './customer';
import vendorReducer from './vendor';
import decorations from './decoration';
import addressReducer from './address';
import contactReducer from './contact';
import quoteReducer from './quote';
import itemBuilderReducer from './itemBuilder';
import companyReducer from './company';
import snackbarReducer from './snackbar';
import registrationReducer from './registration';
import jobsReducer from './jobs';
import billingReducer from './billing';
import filesReducer from './files';
import taskReducer from './tasks';
import taxReducer from './taxes';
import scheduleReducer from './schedule';
import resourceReducer from './resource';
import modalReducer from './modal';
import proofReducer from './proof';
import purchaseOrdersReducer from './purchaseOrders';
import catalogReducer from './catalog';
import catalogImportReducer from './catalogImport';
import dropzoneReducer from './dropzoneReducer';
import {reportingReducer} from './reporting';
import hoopsQueryReducer from './hoopsQuery';
import integrationReducer from '../modules/integrations/IntegrationReducer';

export default combineReducers({
  authReducer,
  appReducer,
  form: formReducer,
  customerReducer,
  vendorReducer,
  decorations,
  addressReducer,
  contactReducer,
  quoteReducer,
  itemBuilderReducer,
  billingReducer,
  companyReducer,
  snackbarReducer,
  registrationReducer,
  jobsReducer,
  filesReducer,
  taskReducer,
  scheduleReducer,
  resourceReducer,
  modalReducer,
  proofReducer,
  purchaseOrdersReducer,
  catalogReducer,
  catalogImportReducer,
  dropzoneReducer,
  reportingReducer,
  taxReducer,
  hoopsQueryReducer,
  integrationReducer,
});
