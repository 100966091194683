export const ACCEPT_INVITE_FIELD_GROUP = {
  name: 'accept_invite',
  headingLabel: 'Create Your Password',
  buttonLabel: 'Sign Up',
  group: [{
    id: 1,
    name: 'fullName',
    fieldType: 'text',
    label: 'Full Name',
    xs_size: 12,
    md_size: 12,
  }, {
    id: 2,
    name: 'username',
    fieldType: 'text',
    label: 'Email',
    xs_size: 12,
    md_size: 12,
  }, {
    id: 3,
    name: 'password',
    icon: 'https',
    fieldType: 'password',
    label: 'Password',
    xs_size: 12,
    md_size: 6,
  }, {
    id: 4,
    name: 'confirmPassword',
    icon: 'https',
    fieldType: 'password',
    label: 'Confirm Password',
    xs_size: 12,
    md_size: 6,
  }]
};

export const REGISTER_FIELD_GROUP = {
  name: 'register',
  headingLabel: 'Sign up now!',
  buttonLabel: 'CREATE YOUR HOOPS ACCOUNT',
  group: [
    {
      id: 1,
      name: 'firstName',
      icon: 'person',
      fieldType: 'text',
      label: 'First Name',
      xs_size: 12,
      md_size: 6,
    },
    {
      id: 2,
      name: 'lastName',
      icon: 'person',
      fieldType: 'text',
      label: 'Last Name',
      xs_size: 12,
      md_size: 6,
    },
    {
      id: 3,
      name: 'username',
      icon: 'person',
      fieldType: 'email',
      label: 'Your Email',
      xs_size: 12,
      md_size: 12,
    },
    {
      id: 4,
      name: 'name',
      icon: 'person',
      fieldType: 'text',
      label: 'Company Name',
      xs_size: 12,
      md_size: 6,
    },
    {
      id: 5,
      name: 'phone',
      icon: 'local_phone',
      fieldType: 'text',
      label: 'Company Phone Number',
      xs_size: 12,
      md_size: 6,
    },
    {
      id: 6,
      name: 'region',
      icon: 'person',
      fieldType: 'select',
      label: 'Select Region',
      xs_size: 12,
      md_size: 6,
    },
    {
      id: 6,
      name: 'password',
      icon: 'https',
      fieldType: 'password',
      label: 'Password',
      xs_size: 12,
      md_size: 6,
    },
  ]
};

export const REGISTER_FIELD_GROUP_PROOFS = {
  name: 'register',
  headingLabel: 'Sign up now to start your free trial. ',
  buttonLabel: 'START FREE TRIAL NOW',
  group: [
    {
      id: 1,
      name: 'firstName',
      icon: 'person',
      fieldType: 'text',
      label: 'First Name',
      xs_size: 12,
      md_size: 6,
    },
    {
      id: 2,
      name: 'lastName',
      icon: 'person',
      fieldType: 'text',
      label: 'Last Name',
      xs_size: 12,
      md_size: 6,
    },
    {
      id: 3,
      name: 'username',
      icon: 'person',
      fieldType: 'email',
      label: 'Your Email',
      xs_size: 12,
      md_size: 12,
    },
    {
      id: 4,
      name: 'name',
      icon: 'person',
      fieldType: 'text',
      label: 'Company Name',
      xs_size: 12,
      md_size: 6,
    },
    {
      id: 5,
      name: 'phone',
      icon: 'local_phone',
      fieldType: 'text',
      label: 'Company Phone Number',
      xs_size: 12,
      md_size: 6,
    },
    {
      id: 6,
      name: 'region',
      icon: 'person',
      fieldType: 'select',
      label: 'Select Region',
      xs_size: 12,
      md_size: 6,
    },
    {
      id: 6,
      name: 'password',
      icon: 'https',
      fieldType: 'password',
      label: 'Password',
      xs_size: 12,
      md_size: 6,
    },
  ]
};

export const REGISTER_FIELD_GROUP_FULLY_PROMOTED = {
  name: 'register',
  headingLabel: 'Sign up now.',
  buttonLabel: 'SIGN UP NOW',
  group: [
    {
      id: 1,
      name: 'firstName',
      icon: 'person',
      fieldType: 'text',
      label: 'First Name',
      xs_size: 12,
      md_size: 6,
    },
    {
      id: 2,
      name: 'lastName',
      icon: 'person',
      fieldType: 'text',
      label: 'Last Name',
      xs_size: 12,
      md_size: 6,
    },
    {
      id: 3,
      name: 'username',
      icon: 'person',
      fieldType: 'email',
      label: 'Your Email',
      xs_size: 12,
      md_size: 12,
    },
    {
      id: 4,
      name: 'name',
      icon: 'person',
      fieldType: 'text',
      label: 'Company Name',
      xs_size: 12,
      md_size: 6,
    },
    {
      id: 5,
      name: 'phone',
      icon: 'local_phone',
      fieldType: 'text',
      label: 'Company Phone Number',
      xs_size: 12,
      md_size: 6,
    },
    {
      id: 6,
      name: 'region',
      icon: 'person',
      fieldType: 'select',
      label: 'Select Region',
      xs_size: 12,
      md_size: 6,
    },
    {
      id: 6,
      name: 'password',
      icon: 'https',
      fieldType: 'password',
      label: 'Password',
      xs_size: 12,
      md_size: 6,
    },
  ]
};

export const REGISTER_FIELD_GROUP_REGIONS = {
  name: 'regions',
  headingLabel: 'Select Region',
  buttonLabel: 'Set Region',
  label: 'Select Region',
  regions: [

    {
      id: 1,
      name: 'USA',
      icon: 'https',
      fieldType: 'password',
      label: 'United States',
      xs_size: 12,
      md_size: 12,
    },
    {
      id: 2,
      name: 'CAN',
      icon: 'https',
      fieldType: 'password',
      label: 'Canada',
      xs_size: 12,
      md_size: 12,
    },
    {
      id: 3,
      name: 'UK',
      icon: 'https',
      fieldType: 'password',
      label: 'United Kingdom',
      xs_size: 12,
      md_size: 12,
    },
    {
      id: 4,
      name: 'AUS',
      icon: 'https',
      fieldType: 'password',
      label: 'Australia',
      xs_size: 12,
      md_size: 12,
    },
    {
      id: 5,
      name: 'NZ',
      icon: 'https',
      fieldType: 'password',
      label: 'New Zealand',
      xs_size: 12,
      md_size: 12,
    },
    {
      id: 6,
      name: 'OTHER',
      icon: 'https',
      fieldType: 'password',
      label: 'Other',
      xs_size: 12,
      md_size: 12,
    }

  ]

};

export const REGISTER_FIELD_GROUP_USER = {
  name: 'register-simple',
  headingLabel: 'Create a password',
  buttonLabel: 'Sign Up',
  group: [
    {
      id: 1,
      name: 'full_name',
      icon: 'person',
      fieldType: 'text',
      label: 'Full Name',
      xs_size: 12,
      md_size: 12,
    },
    {
      id: 5,
      name: 'email',
      icon: 'person',
      fieldType: 'email',
      label: 'User Email',
      xs_size: 12,
      md_size: 12,
    },
    {
      id: 6,
      name: 'password',
      icon: 'https',
      fieldType: 'password',
      label: 'Password',
      xs_size: 12,
      md_size: 12,
    },
    {
      id: 7,
      name: 'password_confirmation',
      icon: 'https',
      fieldType: 'password',
      label: 'Confirm Password',
      xs_size: 12,
      md_size: 12,
    }
  ]
};

export const LOGIN_FIELD_GROUP = {
  name: 'login',
  headingLabel: 'Log In',
  buttonLabel: 'LOG IN',

  group: [
    {
      id: 1,
      name: 'username',
      icon: 'person',
      fieldType: 'email',
      label: 'Username',
      xs_size: 12,
      md_size: 12,
      placeholder: 'Your Email'
    },
    {
      id: 2,
      name: 'password',
      icon: 'https',
      fieldType: 'password',
      label: 'Password',
      xs_size: 12,
      md_size: 12,
      placeholder: 'Your Password'
    }
  ]
};

export const FORGET_PASSWORD_GROUP = {
  name: 'forget-password',
  headingLabel: 'Forgot Password',
  buttonLabel: 'Reset Password',
  group: [
    {
      id: 1,
      name: 'email',
      icon: 'email',
      fieldType: 'email',
      label: 'Email',
      xs_size: 12,
      md_size: 12,
    }
  ]
};

export const HOOPS_PUBLIC_WEBSITE_URL = 'https://www.hoopscrm.com';
