// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-shadow */

import {useQuery} from '@apollo/client';
import {Grid, Link as MuiLink, makeStyles, Switch, Typography} from '@material-ui/core';
import gql from 'graphql-tag';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import theme from '../../ui/theme';
import {featureFlagEnabled, featureFlags} from '../../utils/featureFlag';

const STIPE_INTEGRATION_ACTIVE = gql`
    query integrationOne{
        integrationOne(filter:{type:stripe}){
            _id
            status
        }
    }
`;

const useStyles = makeStyles((theme) => ({
    settingsItem: {
        height: 38,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    greyText: {color: theme.colors.grey.main}
}));

const QuoteSettingsMenu = ({
    handleCommentsToggle = () => null,
    handleAcceptOnlineToggle = () => null,
    handlePayOnlineToggle = () => null,
    handleHideTotalToggle = () => null,
    canContactComment = true,
    canContactAccept = null,
    canContactPay = null,
    hideTotal = null,
    user = null,
    quoteIsDraft = true
}) => {
    const classes = useStyles();
    const [isStripeActive, setIsStripeActive] = useState(null);

    // check for stipe integration
    useQuery(STIPE_INTEGRATION_ACTIVE, {
        fetchPolicy: 'no-cache',
        onCompleted: (result) => {
            const active = result?.integrationOne?.status === 'active';
            setIsStripeActive(active);
            if (quoteIsDraft) {
                if (active) {
                    handleAcceptOnlineToggle(false);
                    handlePayOnlineToggle(true);
                } else {
                    handleAcceptOnlineToggle(true);
                }
            }
        },
    });

    return (
        <>
            <Typography variant='h6'>Settings</Typography>

                <Grid container direction='row' justifyContent='space-between' alignItems='stretch'>
                    <Grid item md={12} sm={6} className={classes.settingsItem}>
                        <Typography variant='body2' display='inline'>Allow comments</Typography>
                        <Switch
                            checked={canContactComment}
                            color='primary'
                            onChange={(e, checked) => handleCommentsToggle(checked)}
                            name='allowComments'
                            inputProps={{'aria-label': 'secondary checkbox'}}
                        />
                    </Grid>
                    <Grid item md={12} sm={6} className={classes.settingsItem}>
                        <Typography variant='body2' display='inline'>Accept online</Typography>
                        <Switch
                            checked={canContactAccept}
                            color='primary'
                            onChange={(e, checked) => handleAcceptOnlineToggle(checked)}
                            name='acceptOnline'
                            inputProps={{'aria-label': 'secondary checkbox'}}
                        />
                    </Grid>
                    <Grid item md={12} sm={6} className={classes.settingsItem}>
                        <Typography variant='body2' display='inline'>Accept & Pay Online</Typography>
                        {isStripeActive ?
                            <Switch
                                checked={canContactPay}
                                color='primary'
                                onChange={(e, checked) => handlePayOnlineToggle(checked)}
                                name='payOnline'
                                inputProps={{'aria-label': 'secondary checkbox'}}
                            />
                            :
                            featureFlagEnabled(featureFlags.stripe_integration_feature)
                                ?
                                <MuiLink
                                    color={'primary'}
                                    component={Link}
                                    underline={'none'}
                                    to={'/settings/integrations'}
                                    style={{marginRight: theme.spacing(2)}}
                                >
                                    Set Up
                                </MuiLink>
                                : <span className={classes.greyText}>Coming Soon</span>
                        }
                    </Grid>
                    <Grid item md={12} sm={6} className={classes.settingsItem}>
                        <Typography variant='body2' display='inline'>Hide total</Typography>
                        <Switch
                            checked={hideTotal}
                            color='primary'
                            onChange={(e, checked) => handleHideTotalToggle(checked)}
                            name='allowComments'
                            inputProps={{'aria-label': 'secondary checkbox'}}
                        />
                    </Grid>
                </Grid>

        </>
    );
};

export default QuoteSettingsMenu;
