import {
  ActivityCurrencyRenderer,
  ActivityDateRenderer,
  ActivityTextRenderer,
  ActivityContactRenderer,
  ActivityCustomerRenderer,
  ActivityDateTimeRenderer, ActivityValueRenderer
} from './activityRenderers';
import {useSelector} from 'react-redux';
import {asCurrencyStringCommaSeparated} from '../../utils';
import * as React from 'react';
import {Grid, Typography} from '@mui/material';

export const invoiceRenderers = {
  'invoices.CREATE': {renderer: false, heading: 'Sent the Invoice to Accounts'},
  'invoices.UPDATE': {
    renderer: false,
    renderIfEmpty: true,
    heading: ({activity, changes, renderIfEmpty}) => {
      if (activity.user === 'Accounts') {
        if (activity.body.qboLastRequestStatus === 'ERROR') {
          return 'Rejected the Invoice with an error';
        }
        if (changes && changes.balance?.length === 2 && changes.totalAmount == null && changes.totalTaxAmount == null
          && changes.balance[0] != null && changes.balance[0] > changes.balance[1]) {
          return 'Made a payment';
        }
        return 'Accepted the Invoice';
      }
      return renderIfEmpty ? 'Resent the Invoice to Accounts' : 'Updated the Invoice';
    }
  },
  'invoices.customerId': {renderer: ActivityCustomerRenderer, title: 'Customer'},
  'invoices.contactId': {renderer: ActivityContactRenderer, title: 'Contact'},
  'invoices.qboCustomerName': {renderer: ActivityTextRenderer, title: 'QuickBooks Customer Name', omitPreviousUndefined: true},
  'invoices.invoiceNumber': {renderer: ActivityTextRenderer, title: 'Invoice Number', omitPreviousUndefined: true},
  'invoices.transactionDate': {renderer: ActivityDateTimeRenderer, title: 'Transaction Date', omitPreviousUndefined: true},
  'invoices.dueDate': {renderer: ActivityDateRenderer, title: 'Due Date', omitPreviousUndefined: true},
  'invoices.totalAmount': {renderer: ActivityCurrencyRenderer, title: 'Total Amount', omitPreviousUndefined: true},
  'invoices.totalTaxAmount': {renderer: ActivityCurrencyRenderer, title: 'Total Tax Amount', omitPreviousUndefined: true},
  'invoices.balance': {renderer: BalanceRenderer, title: 'Balance', omitPreviousUndefined: true},
  'invoices.xeroCustomerName': {renderer: ActivityTextRenderer, title: 'Xero Customer Name', omitPreviousUndefined: true},
  'invoices.errorMessage': {renderer: ActivityTextRenderer, title: 'Error', omitPreviousUndefined: true},
};

function BalanceRenderer({activity, change, changes, ...props}) {
  const currencySymbol = useSelector((state) => state?.authReducer.userData.company.currencySymbol);

  const currencyChange = [
    change[0] != null ? `${currencySymbol}${asCurrencyStringCommaSeparated(change[0])}` : undefined,
    change[1] != null ? `${currencySymbol}${asCurrencyStringCommaSeparated(change[1])}` : undefined
  ];
  return (
    <>
      {changes && changes.balance?.length === 2 && changes.totalAmount == null && changes.totalTaxAmount == null
        && changes.balance[0] != null && changes.balance[0] > changes.balance[1] &&
        <>
          <Typography variant='caption'>Payment</Typography>
          <Grid className='change-item text' container direction='row' gap={1.5}>
            <Typography variant='body2'>{`${currencySymbol}${asCurrencyStringCommaSeparated(change[0] - change[1])}`}</Typography>
          </Grid>
        </>
      }

      <ActivityValueRenderer activity={activity} change={currencyChange} changes={changes} {...props}/>
    </>
  );
}
