// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React, {Component} from 'react';
import {
  Table,
  TableRow as MuiTableRow,
  TableCell,
  TableBody,
  MenuItem,
  withStyles,
} from '@material-ui/core';
import {get, truncate} from 'lodash';
import {Field} from 'react-final-form';
import {renderTextField} from '../../shared/formHelpers/fields';
import {getVendors as _getVendors} from '../../../actions/action-types';
import {connect} from 'react-redux';
import {Select} from 'final-form-material-ui';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

const TableRow = withStyles(() => ({
  '@global': {
    'tr > td:nth-child(1)': {
      minWidth: 200,
      width: 200
    },
    'tr > td:nth-child(2)': {
      maxWidth: 200,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      overflowWrap: 'break-word',
      whiteSpace: 'nowrap',
    }
  }
}))(MuiTableRow);

const styles = (theme) => ({
  border: {border: `1px solid ${theme.colors.grey.light}`,},
  stripedRow: {backgroundColor: theme.colors.grey.ultraLight,},
  minimumPadding: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: 'white',
    fontSize: 12,
    fontWeight: '500',
  },
  noMargin: {margin: '0px',}
});

class ProductDetailTable extends Component {

  componentDidMount() {
    const {getVendors} = this.props;
    getVendors();
  }

  _renderEditCell = (key, component) => {
    const {product, classes, editing} = this.props;
    return (
      <TableCell className={classes.border}>
        {editing ? (
          <Field
            name={key}
            component={renderTextField}
            variant={'outlined'}
            size={'small'}
            margin={'none'}
            InputProps={{classes: {input: classes.minimumPadding}}}
          />
        ) : (component ? component : get(product, key, ''))}
      </TableCell>
    );
  };

  render() {
    const {product, classes, editing, vendors} = this.props;
    return (
      <Grid container item>
        <Table size='small'>
          <TableBody>
            <TableRow>
              <TableCell className={classes.border}>Vendor</TableCell>
              <TableCell className={classes.border}>
                {editing ? (
                  <Field
                    name={'vendor'}
                    variant={'outlined'}
                    component={Select}
                    renderValue={(value) => value.name}
                    formControlProps={{
                      variant: 'outlined',
                      size: 'small',
                      fullWidth: true,
                      margin: 'none'
                    }}
                    SelectDisplayProps={{
                      className: classes.minimumPadding,
                      style: {
                        paddingLeft: 14,
                        width: '100%'
                      }
                    }}
                    MenuProps = {{style: {maxHeight: '600px'}}}
                    size={'small'}
                  >
                    {vendors.map((vendor) => (
                      <MenuItem key={vendor._id} value={vendor}>{vendor.name}</MenuItem>
                    ))}
                  </Field>) : get(product, 'vendor.name', '')}
              </TableCell>
            </TableRow>
            <TableRow className={classes.stripedRow}>
              <TableCell className={classes.border}>Brand</TableCell>
              {this._renderEditCell('brand')}
            </TableRow>
            <TableRow>
              <TableCell className={classes.border}>Category</TableCell>
              {this._renderEditCell('category')}
            </TableRow>
            <TableRow className={classes.stripedRow}>
              <TableCell className={classes.border}>Sub Category</TableCell>
              {this._renderEditCell('subCategory')}
            </TableRow>
            <TableRow>
              <TableCell className={classes.border}>Product URL</TableCell>
              {this._renderEditCell('url',
                <Link href={product.url}
                      target={'_blank'}>{truncate(product.url, {length: 50})}
                </Link>)}
            </TableRow>
            <TableRow className={classes.stripedRow}>
              <TableCell className={classes.border}>Primary Price Description</TableCell>
              {this._renderEditCell('primaryPriceDescription')}
            </TableRow>
            <TableRow>
              <TableCell className={classes.border}>Materials</TableCell>
              {this._renderEditCell('materials')}
            </TableRow>
            <TableRow className={classes.stripedRow}>
              <TableCell className={classes.border}>Decorations Available</TableCell>
              {this._renderEditCell('decorationsAvailable')}
            </TableRow>
            <TableRow>
              <TableCell className={classes.border}>Decoration Areas</TableCell>
              {this._renderEditCell('decorationAreas')}
            </TableRow>
            <TableRow className={classes.stripedRow}>
              <TableCell className={classes.border}>Stock/Indent</TableCell>
              {this._renderEditCell('stockIndent')}
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {getVendors: () => dispatch(_getVendors({perPage: 500}))};
}

function mapStateToProps(state) {
  return {vendors: state.vendorReducer.list};
}

ProductDetailTable = withStyles(styles)(ProductDetailTable);
ProductDetailTable = connect(mapStateToProps, mapDispatchToProps)(ProductDetailTable);
export default ProductDetailTable;
