import {Chip, Tooltip} from '@mui/material';
import * as React from 'react';
import {useRef} from 'react';
import {useResizeObserver} from '../../../../hooks';
import classNames from 'classnames';
import './chip-cell-render.css';

export const ChipCell = ({
                           dataArray = [],
                           getLabel,
                         }: { dataArray: any[], getLabel?: (value: any) => string }) => {
  const chips = getLabel ? dataArray.map(getLabel).filter(Boolean) :  dataArray.filter(Boolean);
  const ref = useRef(null);
  const chipsRef = useRef<(HTMLDivElement | null)[]>([]);
  const plusRef = useRef<HTMLDivElement>(null);
  const {width}: any = useResizeObserver(ref.current);
  //accumulator of the width of shown ships, need to be 2 because I need render the chips to get the actual size
  let acc1 = 0;
  let acc2 = 0;
  const plusWidth = (plusRef.current?.offsetWidth || 0);
  const extraChips = chips.filter((chip, i) => {
    acc2 += ((chipsRef.current[i] && chipsRef.current[i]?.offsetWidth) || 0) + plusWidth;
    return width && acc2 > width;
  });
  // only show the first chip tool tip when is hidden text, (...)

  const showFirstToolTip = width && chipsRef.current[0] && (width <=  chipsRef.current[0]?.offsetWidth + plusWidth);
  return (
    <div ref={ref} className={'container-chip'}>
      {chips.map((chip, i) => {
        acc1 += ((chipsRef.current[i] && chipsRef.current[i]?.offsetWidth) || 0) + plusWidth;
        return (
          <div
            className={classNames(i !== 0 &&  (!width || acc1 > width) ? 'hidden-chip' : 'visible-chip',i === 0 && 'first-chip')}
            key={i}
            ref={(el) => chipsRef.current[i] = el}
          >
            <Tooltip arrow title={
              i === 0 && showFirstToolTip ? <div style={{maxWidth: 150}}>{chip}</div> : ''}>
              {/*style to hide text ... when is the first chip and exists extra chips*/}
              <Chip label={chip} size='small'    style={{maxWidth: i === 0 ? `calc(100% - ${plusWidth}px)` : undefined}} />
            </Tooltip>
          </div>
        );
      })}
      {extraChips.length > 0 && <Tooltip arrow title={<div style={{maxWidth: 150}}>{extraChips.map((extraChip,i) => <div key={i}>{extraChip}</div>)}</div>}>
        <Chip ref={plusRef} label={`+${extraChips.length}`} size='small' />
      </Tooltip>}
    </div>
  );
};

