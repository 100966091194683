// TODO: fix eslint disable
/* eslint-disable no-class-assign, */

import {Button, Grid, MuiThemeProvider} from '@material-ui/core';
import React from 'react';
import {connect} from 'react-redux';
import green from '../../ui/theme/green';
import VendorDialog from './VendorDialog';
import VendorManyTable from './VendorManyTable';

class VendorManyViewPage extends React.Component {
  state = {isCreateVendorOpen: false};

  openCreateVendor = () => {
    this.setState({isCreateVendorOpen: true});
  };

  handleCreateVendorClose = () => {
    this.setState({isCreateVendorOpen: false});
  };

  componentDidMount() {
    document.title = 'Vendors';
  }

  render() {
    return (
      <Grid container>
        <Grid container
          justifyContent={'space-between'}
          alignItems={'center'}
          spacing={3}
        >
          <Grid item>
            <h3>Vendors</h3>
          </Grid>
          <Grid item>
            <MuiThemeProvider theme={green}>
              <Button onClick={this.openCreateVendor} color={'primary'} variant={'contained'}>Add Vendor</Button>
            </MuiThemeProvider>
            <VendorDialog addVendor={true} isOpen={this.state.isCreateVendorOpen} onClose={this.handleCreateVendorClose} />
          </Grid>
          <Grid item xs={12}>
            <VendorManyTable onVendorOpen={this.openCreateVendor} />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

VendorManyViewPage = connect()(VendorManyViewPage);

export default VendorManyViewPage;
