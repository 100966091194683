// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-duplicate-imports, no-class-assign */

import React, {Component} from 'react';
import {Grid, Typography, Link as MuiLink, withStyles, MuiThemeProvider} from '@material-ui/core';
import {Link} from 'react-router-dom';
import ChipSelect from '../../jobs/ChipSelect';
import PurchaseOrdersActionMenu from '../PurchaseOrdersActionMenu';
import {PURCHASE_ORDER_STATUSES} from '../../../constants/status';
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';
import {resetActivePo as _resetActivePo} from '../../../actions/purchaseOrders';
import {withRouter} from 'react-router-dom';
import {get} from 'lodash';
import {connect} from 'react-redux';
import {Skeleton} from '@material-ui/lab';
import AccessTime from '@material-ui/icons/AccessTime';
import red from '../../../ui/theme/red';
import moment from 'moment';

const styles = (theme) => ({
  overFlow: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '1000px'
  }
});

class Heading extends Component {

  handleDelete = () => {
    const {history} = this.props;
    history.goBack();
  };

  render() {
    const {resetActivePo, purchaseOrder, loading, history, classes, getPurchaseOrder, company} = this.props;
    const platform = get(company, 'accountingPlatform.platform', null);

    return (
      <>
        {
          loading ? <Skeleton width='100%' height='50px' />
            :
            <Grid container justifyContent={'space-between'} alignItems={'center'}>
              <Grid item>
                <Grid container spacing={2} alignItems='center'>
                  <Grid item>
                    <KeyboardBackspace onClick={() => history.goBack()} style={{cursor: 'pointer'}} />
                  </Grid>
                  <Grid item>
                    <Grid container spacing={1}>
                      <Grid item container direction='column' spacing={1}>
                        <Grid item>
                          <Typography variant={'h4'} className={classes.overFlow}>
                            {get(purchaseOrder, 'title', 'No title provided')}
                          </Typography>
                        </Grid>
                        <Grid item container spacing={1}>
                          <Grid item>
                            <Typography>
                              <strong>PO #: {get(purchaseOrder, 'number', '')}</strong>
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item container spacing={1}>
                          <Grid item>
                            {purchaseOrder?.deadlineAt &&
                              <MuiThemeProvider theme={red}>
                                <Grid container spacing={1} alignItems={'center'}>
                                  <Grid item>
                                    <AccessTime fontSize={'small'} color={'primary'} />
                                  </Grid>
                                  <Grid item>
                                    <Typography >
                                      Deadline:{' '} {moment(purchaseOrder?.deadlineAt).format('D MMM YYYY')}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </MuiThemeProvider>
                            }
                          </Grid>
                        </Grid>
                        <Grid item container spacing={1}>
                          <Grid item>
                            {purchaseOrder?.dueDate &&
                              <MuiThemeProvider theme={red}>
                                <Grid container spacing={1} alignItems={'center'}>
                                  <Grid item>
                                    <AccessTime fontSize={'small'} color={'primary'} />
                                  </Grid>
                                  <Grid item>
                                    <Typography >
                                      B/O Due Date:{' '} {moment(purchaseOrder?.dueDate).format('D MMM YYYY')}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </MuiThemeProvider>
                            }
                          </Grid>
                        </Grid>
                        <Grid item container spacing={1}>
                          <Grid item>
                            {purchaseOrder?.relatedPurchaseOrder && purchaseOrder?.relatedpurchaseOrder?.length >= 0 &&
                              <MuiThemeProvider theme={red} >
                                <Grid container spacing={1} alignItems={'center'}>
                                  <Grid item>
                                    <AccessTime fontSize={'small'} color={'primary'} />
                                  </Grid>
                                  <Grid item>
                                    <Typography >
                                      Related Purchase Orders:{' '}
                                      {purchaseOrder?.relatedpurchaseOrder?.map(({number, _id}) => (<><Link

                                        key={_id}
                                        style={{textDecoration: 'none', color: '#00CCF0'}}
                                        to={`/purchase-orders/${_id}/view`}>{number}</Link>{' '}</>))}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </MuiThemeProvider>
                            }
                          </Grid>
                        </Grid>
                        <Grid item container spacing={1}>
                          {purchaseOrder && purchaseOrder?.linkedJobs && purchaseOrder?.linkedJobs.length ?
                            <Grid item container spacing={1}>
                              <Grid item>
                                <Typography>
                                  <strong>{`Job #${get(purchaseOrder?.linkedJobs[0], 'number', '')}`}</strong>
                                </Typography>
                              </Grid>
                              <Grid item>
                                {get(purchaseOrder?.linkedJobs[0], 'deadlineAt', null)
                                  ? <MuiThemeProvider theme={red}>
                                    <Grid container spacing={1} alignItems={'center'}>
                                      <Grid item style={{display: 'flex'}}>
                                        <AccessTime fontSize={'small'} color={'primary'} />
                                      </Grid>
                                      <Grid item>
                                        <Typography color={'primary'} variant={'body1'}>
                                          Deadline: {moment(get(purchaseOrder?.linkedJobs[0], 'deadlineAt', null)).format('D MMM YYYY')}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </MuiThemeProvider> : null}
                              </Grid>
                            </Grid> : null}
                          {purchaseOrder?.childPurchaseOrders &&
                            <Grid item container spacing={1}>
                              <Grid item>
                                <Typography>
                                  <strong>Related Purchase Orders:
                                    {purchaseOrder?.childPurchaseOrders.map((childNumber, index) => (
                                      <MuiLink
                                        component={Link}
                                        underline={'none'}
                                        key={index}
                                        onClick={() => getPurchaseOrder({variables: {_id: childNumber._id}})}
                                        to={`/purchase-orders/${childNumber._id}/view`}
                                      >
                                        {` ${childNumber.number}`}
                                        {Object.keys(purchaseOrder?.childPurchaseOrders).length - 1 !== index && ','}
                                      </MuiLink>
                                    ))
                                    }</strong>

                                </Typography>
                              </Grid>
                            </Grid>
                          }
                          {purchaseOrder?.masterPurchaseOrder &&
                            <Grid item container spacing={1}>
                              <Grid item>
                                <Typography>
                                  <strong>Merged PO #:
                                    <MuiLink
                                      component={Link}
                                      onClick={() => getPurchaseOrder({variables: {_id: purchaseOrder?.masterPurchaseOrderId}})}
                                      to={`/purchase-orders/${purchaseOrder?.masterPurchaseOrderId}/view`}
                                    >
                                      {` ${purchaseOrder?.masterPurchaseOrder?.number}`}
                                    </MuiLink>
                                  </strong>
                                </Typography>
                              </Grid>
                            </Grid>
                          }

                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={2} alignItems={'center'}>
                  <Grid item>
                    <ChipSelect
                      disabled
                      activeOption={get(purchaseOrder, 'status', 'CREATED')}
                      options={PURCHASE_ORDER_STATUSES}
                      onOptionChange={() => { }}
                    />
                  </Grid>
                  <Grid item>
                    <PurchaseOrdersActionMenu purchaseOrder={purchaseOrder} onDelete={this.handleDelete} platform={platform}/>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
        }
      </>
    );
  }
}

function mapStateToProps(state) {
  return {company: state.companyReducer.company,};
}
function mapDispatchToProps(dispatch) {
  return {resetActivePo: () => dispatch(_resetActivePo()),};
}

Heading = withStyles(styles)(Heading);
Heading = connect(mapStateToProps, mapDispatchToProps)(Heading);
Heading = withRouter(Heading);

export default Heading;
