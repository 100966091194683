import React from 'react';
import {Typography, makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  label: {color: theme.colors.grey.main},
  component: {
    fontSize: '16px',
    wordBreak: 'break-word'
  },
})
);

export const HoopsLabelWithText = ({
  label = '',
  labelProps = {},
  componentProps = {},
  component = <></>
}) => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.label} {...labelProps}>{label}</Typography>
      <Typography className={classes.component} {...componentProps} component='div'>{component}</Typography>
    </>
  );

};
