// TODO: fix eslint disable
/* eslint-disable no-shadow */

import JobsService from '../servicesOld/JobsService';
import {snackSuccess} from './action-types';
import {closeProofUploadModal} from './modals';
import ProofService from '../servicesOld/ProofService';

export const UPDATE_ACTIVE_PROOF = 'UPDATE_ACTIVE_PROOF';
export const GET_PROOF = 'GET_PROOF';
export const UPDATE_PROOF = 'UPDATE_PROOF';
export const ADD_PROOF_NOTE = 'ADD_PROOF_NOTE';
export const SET_PROOF = 'SET_PROOF';
export const SET_PROOFS = 'SET_PROOFS';
export const BULK_CREATE_PROOF = 'BULK_CREATE_PROOF';
export const EMAIL_PROOFS = 'EMAIL_PROOFS';

export function bulkCreateProof(proofData, callback) {
  return function (dispatch) {
    return JobsService.bulkCreateProof(proofData)
      .then(({data: {bulkCreateProof: proofs}}) => {
        dispatch(snackSuccess(`${proofs.length} Proofs Added to Job Number ${proofs[0].job.number}`));
        dispatch(closeProofUploadModal());
        if (callback) {
          callback(proofs);
        }
      });
  };
}

export function getProof(_id) {
  return function (dispatch) {
    ProofService.getProof(_id)
      .then(({data}) => {
        dispatch({
          type: SET_PROOF,
          payload: data.proof
        });
      });
  };
}

export function getProofs(JobId) {
  return async function (dispatch) {
    await ProofService.getProofs(JobId)
      .then(({data: {proofs}}) => {
        dispatch({
          type: SET_PROOFS,
          payload: proofs
        });
      });
  };
}

export function getProofsWithToken(JobId, token, callback) {
  return function (dispatch) {
    return ProofService.getProofs(JobId, token)
      .then(({data: {proofs}}) => {
        dispatch({
          type: SET_PROOFS,
          payload: proofs
        });
        if (callback) {
          callback();
        }
      });
  };
}

export function approveProof(proof) {
  return function (dispatch) {
    ProofService.approveProof(proof._id)
      .then(( {data: {approveProof}}) => {
        dispatch(getProofs(proof.jobId));
        dispatch(snackSuccess(`Proof for Job Number ${approveProof.job.number} approved.`));
      });
  };
}

export function deleteProof(proof) {
  return function (dispatch) {
    ProofService.deleteProof(proof._id)
      .then(( {data: {deleteProof}}) => {
        dispatch(getProofs(deleteProof.job._id));
        dispatch(snackSuccess(`Proof for Job Number ${deleteProof.job.number} deleted.`));
      });
  };
}

export function sendProofs(JobId, formValues) {
  return function (dispatch) {
    dispatch({type: EMAIL_PROOFS});
    return ProofService.sendProofs(JobId, formValues)
    .then(({data: {sendProof}}) => sendProof);
  };
}
