import {gql} from 'apollo-boost';

export const GQL_FRAGMENT_STRIPE_PAYMENT_METHOD = gql`
  fragment StripePaymentMethodFragment on StripePaymentMethod {
    id

    billing_details {
      name
    }

    card {
      brand
      description
      exp_month
      exp_year
      last4
    }
  }
`;

export const GQL_GET_BILLING = gql`
  query GetBilling {
    billing {
      stripeSubscription {
        id
        trial_end
        status
      }

      stripeCustomer {
        default_source {
          id
          card {
            brand
          }
          exp_month
          last4
        }
      }

      stripePaymentMethods {
        ...StripePaymentMethodFragment
      }

      priceOfNextUser
    }
  }
  ${GQL_FRAGMENT_STRIPE_PAYMENT_METHOD}
`;

export const GQL_GET_UPCOMING_INVOICE = gql`
  query GetUpcomingInvoice {
    upcomingInvoice {
      id
      created
      number
      amount_due
      company {
        numberOfUsers {
          ACTIVE
          INVITED
        }
      }
    }
  }
`;

export const GQL_GET_INVOICES = gql`
  query GetInvoices {
    billing {
      stripeInvoices {
        id
        created
        total
        invoice_pdf
      }
    }
  }
`;

export const GQL_GET_PAYMENT_METHODS = gql`
  query GetPaymentMethods {
    billing {
      stripeCustomer {
        default_source {
          id
          card {
            brand
          }
          exp_month
          last4
        }
      }
      stripePaymentMethods {
        ...StripePaymentMethodFragment
      }
    }
  }
  ${GQL_FRAGMENT_STRIPE_PAYMENT_METHOD}
`;

export const GQL_GET_TRIAL_SUBSCRIPTION_INFO = gql`
  query GetTrailSubscriptionInfo {
    upcomingInvoice {
      number
      amount_due
      currency
    }
  }
  ${GQL_FRAGMENT_STRIPE_PAYMENT_METHOD}
`;

export const GQL_ADD_PAYMENT_METHOD = gql`
  mutation AddCard($data: StripeCardInput!) {
    addStripeCard(data: $data)
  }
`;

export const GQL_DELETE_PAYMENT_METHOD = gql`
  mutation RemoveCard($paymentMethodId: String!) {
    removeStripeCard(paymentMethodId: $paymentMethodId)
  }
`;
export const GQL_SET_DEFAULT_PAYMENT_METHOD = gql`
  mutation SetDefaultCard($paymentMethodId: String!) {
    setDefaultStripeCard(paymentMethodId: $paymentMethodId)
  }
`;

export const GQL_RETRY_DEFAULT_PAYMENT_METHOD = gql`
  mutation RetryStripePayment($source: String) {
    retryStripePayment(source: $source)
  }
`;
