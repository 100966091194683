import theme from '../../../ui/theme';

export const productAutocompleteStyles = {
    reducedPadding: {
        paddingTop: '3px !important',
        paddingBottom: `${theme.spacing(0.5)}px !important`,
    },
    reducedMargin: {margin: 0},
    rightPanelBorder: {
        ['& fieldset']: {
            borderRadius: '0 4px 4px 0',
            borderLeftColor: theme.colors.white,
        },
        '& .MuiOutlinedInput-adornedEnd': {
            padding: '0px !important',
            marginRight: 0,
            maxHeight: 35,
        },
        '& .MuiInputAdornment-positionEnd': {marginLeft: 0}
    },
    orange: {backgroundColor: theme.colors.orange,},
    secondaryDetails: {
        fontSize: '10px',
        lineHeight: '14px',
    },
    primaryDetails: {
        fontSize: '16px',
        lineHeight: '20px'
    },
    secondaryText: {color: '#979797',},
    whiteSpaceNoWrap: {whiteSpace: 'nowrap'},
    quoteInput: {
        padding: theme.spacing(1),
        '&&& $input': {paddingLeft: '0px !important',},
        '& .MuiOutlinedInput-adornedStart': {padding: '0px !important',},
        '& .MuiOutlinedInput-adornedEnd': {
            padding: '0px !important',
            marginRight: 0
        },
        '& .MuiInputAdornment-root': {padding: '0px !important',},
        '& .MuiInputAdornment-positionStart': {
            marginLeft: '8px !important',
            marginRight: '4px !important'
        },
    },
    searchFeedbackPanel: {
        backgroundColor: theme.colors.grey.ultraLight,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: theme.spacing(1)
    }
};

export default productAutocompleteStyles;
