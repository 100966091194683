import Grid from '@material-ui/core/Grid';
import {Typography} from '@material-ui/core';
import React, {useCallback, useEffect, useState} from 'react';
import {useMutation} from '@apollo/client';
import {GQL_REMOVE_SHARED_CATALOG} from '../../../queries/catalogImport';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as pluralize from 'pluralize';
import {Chip} from '@mui/material';

export default function RenderCatalogImport({catalogImport, onUpdate, loading}) {
  const [viewers, setViewers] = useState([]);

  const [removeSharedCatalog] = useMutation(GQL_REMOVE_SHARED_CATALOG, {onCompleted: () => onUpdate()},);

  useEffect(() => {
    if (catalogImport) {
      setViewers(catalogImport.viewOnlyCompanies || []);
    }
  }, [catalogImport]);

  const handleDelete = useCallback(async (companyId) => {
    await removeSharedCatalog({
      variables: {
        _id: catalogImport._id,
        companyId,
      },
    });
  }, [removeSharedCatalog, catalogImport]);

  const ImportText1 = () => (<>
    Import for <strong>{catalogImport.vendor.name} </strong>
    <span>({catalogImport.numberProducts} {pluralize('Product', catalogImport.numberProduct)}) </span>
    on <i>{moment(catalogImport.createdAt, 'x').format('DD MMM. YYYY h:mm a')} </i>
  </>);

  return (
    <>
      <Grid container spacing={2} alignItems={'center'}>
        {viewers.length ? <>
          <Grid item xs={12}>
            <Typography>
              <ImportText1 /> is shared with the following companies:
            </Typography>
          </Grid>
          <Grid item xs={12}>

            {viewers.map((viewer) => (
              <Chip
                key={viewer._id}
                color={'primary'}
                sx={{mr: 1, mb: 1}}
                label={viewer?.companyTradingEntities[0]?.name}
                onDelete={() => handleDelete(viewer._id)}
              />
            ))}

            {loading && <CircularProgress disableShrink size={20} color={'primary'} />}

          </Grid>
        </> : <>
          <Grid item xs={12}>
            <Typography>
              <ImportText1 />is not yet shared with any other companies. </Typography>
            <Typography variant={'body2'}>Use the form to share products with other companies.</Typography>
          </Grid>
        </>}
      </Grid>
    </>
  );
}
