import {useLazyQuery} from '@apollo/client';
import {Grid, makeStyles} from '@material-ui/core';
import React, {useEffect, useState, useCallback} from 'react';
import NameWithInitials from '../../componentsOld/NameWithInitials';
import HoopsCustomAutocomplete from '../../componentsOld/shared/formHelpers/HoopsCustomAutocomplete';
import VendorDialog from '../../componentsOld/vendors/VendorDialog';
import {useDebounce, useMountEffect, useWatch} from '../../hooks';
import {GQL_SEARCH_VENDORS} from '../../queries/vendors';
import {colors} from '../../ui/theme';
import {itemBuilder} from '../../ui/theme/itemBuilder';

const useStyles = makeStyles(() => ({...itemBuilder,}));

function RenderOption({...option}) {
  return (
    <Grid container spacing={1} alignItems={'center'}>
      <Grid item>
        <NameWithInitials name={option.name} color={colors.purple} />
      </Grid>
    </Grid>
  );
}

export const ItemBuilderVendorAutocomplete = ({input, input: {onChange}, ...params}) => {
  const [vendors, setVendors] = useState([]);
  const [vendorDialogOpen, setVendorDialogOpen] = useState(false);
  const [searchString, setSearchString] = useState('');

  const classes = useStyles();

  const [getVendors, {loading}] = useLazyQuery(GQL_SEARCH_VENDORS, {
    onCompleted: ({vendorMany}) => {
      setVendors(vendorMany);
    }
  });
  const getVendorsDebounce = useDebounce((...args) => getVendors(...args), [getVendors]);

  const handleButtonClick = useCallback(() => {
    setVendorDialogOpen(true);
  }, []);

  const handleVendorClose = useCallback((data) => {
    setVendorDialogOpen(false);
    if (data && data._id) {
      onChange(data);
      setSearchString(data.name);
    }
  }, [onChange]);

  useEffect(() => {
    if (searchString != null && !params.disabled) {
      getVendors({
        variables: {
          filter: {q: searchString,},
          limit: 15,
        }
      });
    }
  }, [searchString, params.disabled, getVendors]);

  useWatch(() => {
    if (input && input.value && input.value !== '' && !params.disabled) {
      setSearchString(input.value.name ? input.value.name : input.value);
    }
  }, [input, params.disabled]);

  useMountEffect(() => {
    getVendorsDebounce({variables: {limit: 15,}});
  }, []);

  return (
    <>
      <VendorDialog isOpen={vendorDialogOpen} onClose={(vendor) => handleVendorClose(vendor)} />
      <HoopsCustomAutocomplete
        input={input}
        options={vendors}
        loading={loading}
        RenderOption={RenderOption}
        setSearchString={setSearchString}
        className={`${classes.disableAutocompleteMargin} ${params.disabled && classes.disabledTextField}`}
        inputValue={searchString}
        getOptionLabel={(option) => option?.name ?? option}
        buttonProps={{
          label: 'Add New Vendor',
          onClick: handleButtonClick
        }}
        {...params}
      />
    </>
  );
};
