import React from 'react';
import {DecimalInput} from '.';
import {registerGlobalStyle} from '../../theme';
import {HoopsPropTypes} from '../utils';

registerGlobalStyle('.percentage-input', () => ({'&::after': {content: '"%"',}}));

export function PercentageInput({className, ...props}) {
  return <DecimalInput className={[className, 'percentage-input']} minDecimals={0} maxDecimals={2} container {...props}/>;
}

PercentageInput.propTypes = {
  className: HoopsPropTypes.className,
  autoSize: HoopsPropTypes.bool,
  blurOnEnterEscape: HoopsPropTypes.bool,
  label: HoopsPropTypes.string,
  onChange: HoopsPropTypes.func,
  value: HoopsPropTypes.stringOrNumber,
};
