import {gql} from 'apollo-boost';

const GQL_REPORT_SALES_TOTALS = gql`
  fragment reportSalesTotals on ReportSalesTotals {
    inventorySales
    decorationSales
    totalSales
    percentOfTotal

    totalSalesWithAdhocItems
    totalTax
  }
`;

const GQL_REPORT_MARGIN_TOTALS = gql`
  fragment reportMarginTotals on ReportMarginTotals {
    inventorySales
    inventoryCosts
    inventoryMargin

    decorationSales
    decorationCosts
    decorationMargin

    additionalCostSales
    additionalCostCosts
    additionalCostMargin

    totalSales
    totalCosts
    totalMargin

    totalSalesWithAdhocItems
    totalTax
  }
`;

export const GQL_REPORT_SALES_PER_CUSTOMER = gql`
  query ReportSalesPerCustomer($startDate: String, $endDate: String) {
    reportSalesPerCustomer(startDate: $startDate, endDate: $endDate) {
      startDate
      endDate
      totals {
        ...reportSalesTotals
      }
      data {
        totals {
          ...reportSalesTotals
        }
        customer {
          _id
          name
        }
      }
    }
  }
  ${GQL_REPORT_SALES_TOTALS}
`;

export const GQL_REPORT_SALES_PER_USER = gql`
  query ReportSalesPerUser($startDate: String, $endDate: String) {
    reportSalesPerUser(startDate: $startDate, endDate: $endDate) {
      startDate
      endDate
      totals {
        ...reportSalesTotals
      }
      data {
        totals {
          ...reportSalesTotals
        }
        user {
          _id
          fullName
        }
      }
    }
  }
  ${GQL_REPORT_SALES_TOTALS}
`;

export const GQL_REPORT_SALES_PER_QUOTE_OWNER = gql`
  query ReportSalesPerQuoteOwner($startDate: String, $endDate: String) {
    reportSalesPerQuoteOwner(startDate: $startDate, endDate: $endDate) {
      startDate
      endDate
      totals {
        ...reportSalesTotals
      }
      data {
        totals {
          ...reportSalesTotals
        }
        user {
          _id
          fullName
        }
      }
    }
  }
  ${GQL_REPORT_SALES_TOTALS}
`;

export const GQL_REPORT_GROSS_MARGIN_QUOTES_PER_CUSTOMER = gql`
  query ReportGrossMarginQuotesPerCustomer(
    $startDate: String
    $endDate: String
  ) {
    reportGrossMarginQuotesPerCustomer(
      startDate: $startDate
      endDate: $endDate
    ) {
      startDate
      endDate
      totals {
        ...reportMarginTotals
      }
      data {
        totals {
          ...reportMarginTotals
        }
        customer {
          _id
          name
        }
      }
    }
  }
  ${GQL_REPORT_MARGIN_TOTALS}
`;

export const GQL_REPORT_GROSS_MARGIN_QUOTES_PER_USER = gql`
  query ReportGrossMarginQuotesPerUser($startDate: String, $endDate: String) {
    reportGrossMarginQuotesPerUser(startDate: $startDate, endDate: $endDate) {
      startDate
      endDate
      totals {
        ...reportMarginTotals
      }
      data {
        totals {
          ...reportMarginTotals
        }
        user {
          _id
          fullName
        }
      }
    }
  }
  ${GQL_REPORT_MARGIN_TOTALS}
`;

export const GQL_REPORT_GROSS_MARGIN_SALES_PER_CUSTOMER = gql`
  query ReportGrossMarginSalesPerCustomer(
    $startDate: String
    $endDate: String
  ) {
    reportGrossMarginSalesPerCustomer(
      startDate: $startDate
      endDate: $endDate
    ) {
      startDate
      endDate
      totals {
        ...reportMarginTotals
      }
      data {
        totals {
          ...reportMarginTotals
        }
        customer {
          _id
          name
        }
      }
    }
  }
  ${GQL_REPORT_MARGIN_TOTALS}
`;

export const GQL_REPORT_GROSS_MARGIN_SALES_PER_USER = gql`
  query ReportGrossMarginSalesPerUser($startDate: String, $endDate: String) {
    reportGrossMarginSalesPerUser(startDate: $startDate, endDate: $endDate) {
      startDate
      endDate
      totals {
        ...reportMarginTotals
      }
      data {
        totals {
          ...reportMarginTotals
        }
        user {
          _id
          fullName
        }
      }
    }
  }
  ${GQL_REPORT_MARGIN_TOTALS}
`;

