// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {get} from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {change as _change, getFormValues} from 'redux-form';
import {toFloat} from '../../../../servicesOld/ItemBuilderServices';
import {ProductsTableRow} from './ProductsTableRow';

const styles = (theme) => ({
    actionColumn: {width: 120},
    tableCell: {padding: theme.spacing(1)},
    textFieldWithAdornmentBtn: {
      margin: '8px 0 4px 0 !important',
      '& .MuiOutlinedInput-adornedEnd': {
        paddingLeft: 0,
        paddingRight: 0,
      }
    }
  });

class ProductsTableRows extends Component {

  handleQuantityChange = () => {
    setTimeout(() => {
      const {change, formValues, meta: {form}} = this.props;
      const variants = get(formValues, 'variants', []);
      const decorations = get(formValues, 'decorations', []);
      let quantity = 0;
      if (variants.length) {
        quantity = formValues.variants.reduce((sum, v) => {
          if (v) {
            return sum + toFloat(v.quantity);
          }
          return sum;
        }, 0);
      } else if (decorations.length) {
        quantity = decorations[0].quantity;
      }
      change(form, 'quantity', quantity);
    });
  };

  handleAddMoreClick = () => {
    const {fields, formValues: {quantity}, product} = this.props;
    let values = {};
    if (product) {
      values = {
        productCode: get(product, 'code'),
        productName: get(product, 'title'),
        vendor: get(product, 'vendor'),
        vendorId: get(product, 'vendor._id')
      };
    }
    if (fields.length === 0) {
      values = {
        ...values,
        quantity,
      };
    }
    fields.push(values);
  };

  handleDeleteRowClick = (index) => {
    const {fields} = this.props;
    fields.splice(index, 1);
    this.handleQuantityChange();
  };

  handleDuplicateRowClick = (field) => {
    const {fields, formValues} = this.props;
    const newRow = get(formValues, field, {});
    delete newRow._id;

    fields.push(newRow);
    this.handleQuantityChange();
  };

  render() {
    const {fields, classes, meta} = this.props;
    return (
      <>
        {fields.length > 0 && fields.map((field, index) => (
            <ProductsTableRow
              key={index}
              field={field}
              rowIdx={index}
              meta={meta}
              classes={classes}
              handleQuantityChange={this.handleQuantityChange}
              handleDeleteRowClick={this.handleDeleteRowClick}
              handleDuplicateRowClick={this.handleDuplicateRowClick}
            />))}
        <TableRow>
          <TableCell className={classes.tableCell} colSpan={8}>
            <Button
              color={'primary'}
              variant={'outlined'}
              size={'small'}
              data-intercom-target={'job-item-variant-add-more-button'}
              onClick={this.handleAddMoreClick}
            >
              {fields.length ? 'Add Another Product' : 'Add Product'}
            </Button>
          </TableCell>
        </TableRow>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {formValues: getFormValues(ownProps.meta.form)(state),};
}

function mapDispatchToProps(dispatch) {
  return {change: (form, field, value) => dispatch(_change(form, field, value))};
}

ProductsTableRows = connect(mapStateToProps, mapDispatchToProps)(ProductsTableRows);
ProductsTableRows = withStyles(styles)(ProductsTableRows);

export default ProductsTableRows;
