// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {useQuery} from '@apollo/client';
import {CircularProgress, Link, makeStyles, Typography} from '@material-ui/core';
import gql from 'graphql-tag';
import React, {useEffect} from 'react';
import Lottie from 'react-lottie';
import successAlertIcon from '../../../assets/lotties/success-alert-icon.json';
import theme from '../../../ui/theme';

const GET_STIPE_INVOICE = gql`
    query($_id:String!){
        quote(_id: $_id){
            stripeInvoiceLink
        }
    }
`;

const useStyles = makeStyles((theme) => ({greyText: {color: theme.colors.grey.main},}));

const PaymentSuccess = ({_id = null}) => {
    const classes = useStyles();

    const {data, startPolling, stopPolling} = useQuery(GET_STIPE_INVOICE, {variables: {_id: _id},});

    const invoiceUrl = data && data.quote && data.quote.stripeInvoiceLink;

    useEffect(() => {
        startPolling(3000);
    }, [startPolling]);

    useEffect(() => () => stopPolling(), [stopPolling]);

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: successAlertIcon,
        rendererSettings: {preserveAspectRatio: 'xMidYMid slice'}
    };

    return (
        <div style={{padding: theme.spacing(3)}}>
            <Lottie
                options={defaultOptions}
                height={200}
                width={200}
            />
            <Typography variant='h5' className={classes.greyText}>Payment successfull</Typography>
            <Typography variant='h5' gutterBottom className={classes.greyText}>Thank you.</Typography>

            {invoiceUrl
                ? <Link href={invoiceUrl} color={'primary'} underline={'none'} target='_blank'>View Invoice/Receipt</Link>
                : <>
                    <CircularProgress size={18} />
                    <Typography variant='body2' className={classes.greyText}>Loading Invoice/Reciept</Typography>
                </>
            }
        </div >
    );
};

export default PaymentSuccess;
