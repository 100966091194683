import {useCallback, useEffect, useState} from 'react';
import {isEqual} from 'lodash';

export function useFilterManager(columns, filters, onChangeFilters) {
  // The filters are a real pain. DataGridPro will send an event with invalid filters while the filters are being built,
  // we filter those out so they aren't sent to the back end, but filtering them out means the when the element rerenders
  // we have to add them back. Hence, intermediateFilters state. Then to keep track of when the parent changes the filters
  // we have an effect which detects changes, but of course useEffect detects shallow changes, so we need a ref to keep
  // track of the old deep value of the filters, and in the effect we do a deep comparison as well. If the deep comparison
  // shows a change we clear the intermediate filters. Quite a pain, but when we use the gris in lots of places it will be
  // nice to isolate the pages from the mechanics of the filter model.
  const [displayedFilters, setDisplayedFilters] = useState({linkOperator: 'and', items: []});

  const handleFilterModelChange = useCallback((gridFilters) => {
    // Don't do anything if the filters have not changed
    if (isEqual(gridFilters, displayedFilters)) {
      return;
    }
    setDisplayedFilters(gridFilters);

    const newFilters = convertFromGridFilters(gridFilters, columns);

    if (!isEqual(filters, newFilters)) {
      onChangeFilters(newFilters);
    }
  }, [columns, displayedFilters, filters, onChangeFilters]);

  useEffect(() => {
    // The page has sent us new filters, we check whether the given filters match the filters that
    // we are displaying, and if they do, we don't do anything. If they don't match we update the
    // displayed filters to match the ones we were given.

    const gridFilters = convertToGridFilters(filters, columns);
    const validItems = extractValidFilters(displayedFilters, columns);

    // Iterate every converted item and check if there is a corresponding item being displayed, if the arrays
    // do not match we will replace the displayed filters with the new filters
    if (gridFilters.items.length !== validItems.length
      || gridFilters.linkOperator !== displayedFilters.linkOperator
      || !gridFilters.items.every((converted) => validItems.some((valid) =>
        valid.columnField === converted.columnField && valid.operatorValue === converted.operatorValue && valid.value === converted.value))
    ) {
      setDisplayedFilters(gridFilters);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return [displayedFilters, handleFilterModelChange];
}

function convertFromGridFilters(gridFilters, columns) {
  const validItems = extractValidFilters(gridFilters, columns);
  if (validItems.length === 0) {
    return [];
  } else {
    return validItems.reduce((items, item) => {
      let value = item.value ?? '';
      const column = columns.find((col) => col.field === item.columnField);
      if (column?.type === 'date') {
        // If it's a date convert from a plain date to a UTC ISO8601 date
        const dateUtc = new Date(value);
        const date = new Date(dateUtc.getTime() + dateUtc.getTimezoneOffset() * 60 * 1000);
        value = date.toISOString();
      }
      return [...items, item.columnField, item.operatorValue, value];
    }, [gridFilters.linkOperator]);
  }
}

function convertToGridFilters(filters, columns) {
  if (!filters) {
    filters = [];
  }
  const items = [];
  for (let i = 1; i < filters.length; i += 3) {
    const item = {columnField: filters[i], operatorValue: filters[i + 1], value: filters[i + 2], id: (i - 1) / 3};
    const column = columns.find((col) => col.field === item.columnField);
    if (column?.type === 'date') {
      // If it's a date convert from UTC ISO8601 to just a date
      const dateUtc = new Date(item.value);
      item.value = `${dateUtc.getFullYear()}-${(dateUtc.getMonth() + 1).toString().padStart(2, '0')}-${dateUtc.getDate().toString().padStart(2, '0')}`;
    }
    items.push(item);
  }
  return {linkOperator: filters[0] ?? 'and', items};
}

function extractValidFilters(gridFilters, columns) {
  const validItems = [];
  gridFilters?.items?.forEach((item) => {
    if (isFilterItemValid(item, columns)) {
      validItems.push(item);
    }
  });
  return validItems;
}

function isFilterItemValid({columnField: field, operatorValue: operator, value}, columns) {
  if (operator.match(/Empty/) || operator.match(/Zero/)) {
    // Filter is Empty or isEmpty
    return true;
  }
  if (value === null || value === '' || value === undefined) {
    // Filter must have a value, this one is invalid so far
    return false;
  }
  if (Array.isArray(value)) {
    if (value.length === 0) {
      return false;
    }
    return value.every((v) => isFilterValueValid({field, value: v}, columns));
  }

  return isFilterValueValid({field, value}, columns);
}

function isFilterValueValid({field, value}, columns) {
  const column = columns.find((col) => col.field === field);
  if (column) {
    switch (column.type) {
      case 'date':
        return !isNaN(new Date(value));

      case 'singleSelect': {
        const values = column.valueOptions instanceof Function ? column.valueOptions({field}) : column.valueOptions;
        return values && values.some((v) => v.value === value);
      }

      default:
        return true;
    }
  }
  return false;
}
