import React from 'react';

const LogoMini = (props) => (
    <div {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 274.54 183.84'>
        <g>
          <path
            fill='#1fb3e6'
            d='M132.26,10a108,108,0,0,0-27.39,26.59c-1.8,2.55-4,1-5.91.77C74,34.66,52.83,46.05,42.29,67.91c-11,22.83-4.84,50.39,15,67,18,15.05,45.23,16.25,64.19,2.82,21.39-15.15,29.74-41.95,20.21-66-1.39-3.51-.87-5.82,1.16-8.56a54.66,54.66,0,0,1,21.69-18c3.32-1.51,4.83-.87,6.59,2.22,18.43,32.35,14,76.08-11.28,104.5s-57.33,38.3-93.63,28c-35.46-10.12-57.37-35-64.52-71.27C-8.37,57.49,27.52,7.71,79.1.89,97.38-1.51,115.26.78,132.26,10Z'
          />
          <path
            fill='#939598'
            d='M183.19.3c50.45.07,90.33,40.43,91.33,90.18.94,46.86-34.6,84.52-73.44,91.46-18.83,3.36-37.1,1.6-54.74-6-3.32-1.44-3.76-2.43-.49-4.71a93.19,93.19,0,0,0,22.7-22.3c1.73-2.41,3.65-2.79,6.38-2.53,12,1.16,23.53.18,34.38-5.83,20-11.06,30.9-33.22,27.83-57-2.77-21.5-20.12-40.44-41.62-45.43-37.07-8.61-72,24.41-65.69,62,.64,3.79,1.06,7.7,2.8,11.08,2.08,4,1.23,7.09-1.41,10.4-5.8,7.27-12.51,13.29-21.15,17.07-3,1.31-4.7,1-6.36-2.08C72.14,78,109.71,10.76,168.5,1.37A111,111,0,0,1,183.19.3Z'
          />
        </g>
      </svg>
    </div>
  );

export default LogoMini;
