// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import {
  Table, TableBody, TableCell, TableHead,
  TableRow, Tooltip, withStyles, Button, Grid,
} from '@material-ui/core';
import React, {Fragment} from 'react';
import {connect, useDispatch} from 'react-redux';
import {change, FieldArray, getFormValues} from 'redux-form';
import {QuoteService} from '../../servicesOld/QuoteService';
import QuoteFormSubtotalField from './QuoteFormSubtotalField';
import QuoteFormTerms from './QuoteFormTerms';
import QuoteItemFieldRow from './QuoteItemFieldRow';
import InfoIcon from '@material-ui/icons/Info';
import {ItemBuilderContextContainer} from '../../modules/itemBuilder/ItemBuilderContext';
import TaxService from '../../modules/tax/TaxService';
import {ItemBuilderEditModal} from '../../modules/itemBuilder/ItemBuilderEditModal';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';

function RenderItemFields({fields, classes, meta, company, quote, onQuoteChange}) {
  const dispatch = useDispatch();

  const handleDelete = (index) => {
    fields.splice(index, 1);
    onQuoteChange();
  };

  const handleDuplicate = async (index) => {
    const sourceQuoteLineItem = fields.get(index);
    const newQuoteLineItem = await QuoteService.duplicateQuoteItemData(sourceQuoteLineItem);

    fields.insert(index + 1, newQuoteLineItem);

    onQuoteChange();
  };

  // TODO: get the default revenue TAX
  const handleAddLine = () => {
    const match = TaxService.getRevenueDefaultTax(company);
    fields.push({tax: match || {}});
    onQuoteChange();
  };

  const handleUploadComplete = (item) => (s3Object) => {
    dispatch(change(meta.form, `${item}.imageUrl`, s3Object.Location));
    onQuoteChange();
  };

  const move = (dragIndex, hoverIndex) => {
    fields.move(dragIndex, hoverIndex);
    onQuoteChange();
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    fields.move(result.source.index, result.destination.index);
    onQuoteChange();
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd} >
        <Droppable droppableId='droppable' direction='vertical'>
          {(droppableProvided) => (
            <TableBody
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
            >
              {fields.map((field, index) => (
                <Draggable
                  key={field}
                  draggableId={field}
                  index={index}
                >
                  {(draggableProvided, snapshot) => (
                    <QuoteItemFieldRow
                      classes={classes}
                      key={index}
                      field={field}
                      fields={fields}
                      index={index}
                      handleDelete={handleDelete}
                      handleDuplicate={handleDuplicate}
                      handleUploadComplete={handleUploadComplete}
                      meta={meta}
                      company={company}
                      quote={quote}
                      onQuoteChange={onQuoteChange}
                      move={move}
                      draggableProvided={draggableProvided}
                      snapshot={snapshot}
                    />
                  )}
                </Draggable>
              ))}
              {droppableProvided.placeholder}
            </TableBody>
          )}
        </Droppable>
      </DragDropContext>

      <TableRow>
        <TableCell colSpan={6} className={classes.noBorder}>
          <Button color={'primary'} variant={'outlined'} onClick={handleAddLine}>Add Lines</Button>
        </TableCell>
      </TableRow>
    </>
  );
}

const styles = (theme) => (
  {
    noBorder: {
      borderStyle: 'none',
      paddingLeft: 0
    },
    headerText: {paddingBottom: theme.spacing(1)},
    buildItemHeader: {
      minWidth: 111,
      height: 24
    },
    tooltip: {
      maxWidth: 170,
      textAlign: 'center'
    },
    infoCell: {
      paddingTop: 1,
      paddingLeft: 4
    },
    icon: {color: theme.colors.grey.main,},
    cellPadding: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      textAlign: 'center'
    },
    //cell styles
    descriptionCell: {
      width: '70%',
      minWidth: 200,
      padding: theme.spacing(1),
    },
    productCell: {
      paddingLeft: 0,
      paddingRight: 0,
      width: '10%',
      minWidth: 220
    },
    iconCell: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      minWidth: 112,
    },
    quantityCell: {
      minWidth: 90,
      width: '5%',
    },
    rateCell: {
      minWidth: 106,
      width: '5%',
    },
    taxCell: {
      minWidth: 105,
      width: '5%',
    },
    subTotalCell: {
      paddingRight: theme.spacing(1),
      width: '10%',
      whiteSpace: 'nowrap'
    },
    actionsCell: {
      paddingLeft: '0px !important',
      paddingRight: '0px !important',
      textAlign: 'right',
      width: 80,
    },
    //input styles
    quoteInput: {
      padding: theme.spacing(1),
      '&&& $input': {paddingLeft: '0px !important',},
      '& .MuiOutlinedInput-adornedStart': {padding: '0px !important',},
      '& .MuiInputAdornment-root': {padding: '0px !important',},
      '& .MuiInputAdornment-positionStart': {
        marginLeft: '8px !important',
        marginRight: '4px !important'
      },
    },
    autocompleteInput: {padding: 0},
    narrowColumn: {width: 'auto'},
    amountInput: {
      textAlign: 'right',
      maxWidth: 100
    },
    // WYSIWYG overrides
    editor: {
      overflowY: 'auto',
      '& div+div': {marginTop: theme.spacing(1),},
      '& .public-DraftStyleDefault-block': {
        margin: 0,
        padding: theme.spacing(.5),
        paddingTop: 7,
        paddingLeft: 7,
      },
    },
    wrapper: {
      border: '1px solid #BDBDBD',
      borderRadius: theme.spacing(.5),
      minHeight: 33,
    },

  }
);

class SingleQuoteTable extends React.Component {
  state = {
    totals: {
      subTotal: 0,
      taxes: [],
      total: 0
    }
  };

  render() {
    const {classes, quote, onChange: onQuoteChange} = this.props;
    const quoteColumns = [
      {},
      {
        title:
          <Grid container direction='row' justifyContent='center' alignItems='flex-start' className={classes.buildItemHeader}>
            <Grid item>Item Builder</Grid>
            <Grid item className={classes.infoCell}><Tooltip placement='top' arrow classes={{tooltip: classes.tooltip}} title={'Use the item builder to add product variants, decorations, and additional costs.'}><InfoIcon fontSize='small' className={classes.icon} /></Tooltip></Grid>
          </Grid>,
        class: classes.cellPadding,
        align: 'center',
      }, {
        title: 'Image',
        class: classes.iconCell,
        align: 'center'
      }, {
        title: 'Description',
        class: classes.descriptionCell
      }, {
        title: 'Qty',
        align: 'center',
        class: classes.quantityCell,
      }, {
        title: 'Rate/Unit',
        align: 'center',
        class: classes.rateCell,
      }, {
        title: 'Tax',
        align: 'center',
        class: classes.taxCell
      }, {
        title: 'Sub Total',
        align: 'right',
        class: classes.subTotalCell
      }, {
        title: 'Actions',
        class: classes.actionsCell
      }];

    return (
      <>
        <Table>
          <TableHead>
            <TableRow>
              {quoteColumns.map((column, index) => (
                <TableCell key={index} padding={'8px'} align={column.align}
                  className={`${column.class} ${classes.headerText}`}>{column.title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <ItemBuilderContextContainer onQuoteChange={onQuoteChange}>
            <ItemBuilderEditModal quote={quote} />
            <FieldArray
              classes={classes}
              name={'items'}
              quote={quote}
              component={RenderItemFields}
              onSaveTaxes={this.handleSaveTaxes}
              onQuoteChange={onQuoteChange}
              {...this.props}
            />
          </ItemBuilderContextContainer>
        </Table>
        <QuoteFormSubtotalField form='quoteForm'>
          <QuoteFormTerms onQuoteChange={onQuoteChange} />
        </QuoteFormSubtotalField>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    company: state.companyReducer.company,
    quote: getFormValues('quoteForm')(state),
  };
}

SingleQuoteTable = withStyles(styles)(SingleQuoteTable);
SingleQuoteTable = connect(mapStateToProps, null)(SingleQuoteTable);

export default SingleQuoteTable;
