import {Button, Grid, MuiThemeProvider} from '@material-ui/core';
import {Image} from '@material-ui/icons';
import React, {useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {setEmailType} from '../../../../actions/jobs';
import {openProofUploadModal, openSendProofModal} from '../../../../actions/modals';
import AddExistingProofModal from '../../../../pages/proofs/addExistingProofModal/AddExistingProofModal';
import green from '../../../../ui/theme/green';
import theme from '../../../../ui/theme/index';
import CardContainer from '../../../common/CardContainer';
import ProofsTable from './ProofsTable';
import {getProofs as _getProofs} from '../../../../actions/proofs';

function Buttons({
  jobId = '',
  setIsAddExistingModalOpen = () => null
}) {
  const dispatch = useDispatch();

  const handleSendClick = () => {
    dispatch(setEmailType('proof'));
    dispatch(openSendProofModal({jobId}));
  };

  const handleUploadClick = () => {
    dispatch(openProofUploadModal());
  };

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Button
          data-intercom-target={'job-proof-send-button'}
          variant='contained'
          color={'primary'}
          onClick={handleSendClick}
        >
          Send
        </Button>
      </Grid>
      <Grid item>
        <Button
          data-intercom-target={'job-proof-upload-button'}
          variant='contained'
          color={'primary'}
          onClick={() => setIsAddExistingModalOpen(true)}
        >
          Use Previous Proofs
        </Button>
      </Grid>
      <Grid item>
        <MuiThemeProvider theme={green}>
          <Button
            data-intercom-target={'job-proof-upload-button'}
            variant='contained'
            color={'primary'}
            onClick={handleUploadClick}
          >
            Upload Proof
          </Button>
        </MuiThemeProvider>
      </Grid>
    </Grid>
  );
}

const jobSelector = (state) => state.jobsReducer.activeJob;

const JobProofs = ({getProofs, updateJobBoard}) => {
  const Job = useSelector(jobSelector);
  const [isAddExistingModalOpen, setIsAddExistingModalOpen] = useState(false);

  return (
    <>
      {
        isAddExistingModalOpen &&
        <AddExistingProofModal
          updateJobBoard={updateJobBoard}
          updateJobProof={() => {
            getProofs(Job._id);
          }}
          existingAssociatedFiles={[{}]}
          job={Job}
          customerId={Job.customer._id}
          title='Previous Proofs'
          subTitle='Any proof linked with this customer will display here and can be reused on this job. The proof image will need to be sent for approval again.'
          isOpen={isAddExistingModalOpen}
          onCloseModal={() => setIsAddExistingModalOpen(false)}
        />
      }
      <MuiThemeProvider theme={theme}>
        <CardContainer
          title='Proofs'
          icon={<Image />}
          iconBackgroundColor={theme.palette.primary.main}
          buttonComponent={<Buttons jobId={Job._id} setIsAddExistingModalOpen={setIsAddExistingModalOpen} />}
          
        >
          <Grid container style={{marginBottom: 16}}>
            <Grid item xs={12}>
              <ProofsTable />
            </Grid>
          </Grid>
        </CardContainer>
      </MuiThemeProvider>
    </>

  );
};

function mapStateToProps(state) {
  return {proofs: state.proofReducer.proofs};
}

function mapDispatchToProps(dispatch) {
  return {getProofs: (jobId) => dispatch(_getProofs(jobId)),};
}

export default connect(mapStateToProps, mapDispatchToProps)(JobProofs);

