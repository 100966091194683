// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React, {Component} from 'react';
import {
  Grid,
  Typography,
  withStyles,
  Chip,
  Avatar,
  Link
} from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import {includes} from 'lodash';
import numeral from 'numeral';

const styles = (theme) => ({
  container: {padding: theme.spacing(3),},
  icon: {
    fontSize: '12px',
    marginRight: theme.spacing(1)
  },
  attachmentImg: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  pointer: {cursor: 'pointer'}
});

class Attachments extends Component {
  render() {
    const {classes, files} = this.props;
    return (
      <Grid container justifyContent='center' alignItems='center' className={classes.container} spacing={3}>
        <Grid item>
          <AttachFileIcon className={classes.icon} />
        </Grid>
        <Grid item>
          <Typography variant='caption'>Attachments ({files.length})</Typography>
        </Grid>
        {files.map((file) => (
          <Grid item key={file._id}>
            <Link href={file.url} underline='none'>
              <Chip
                label={`${file.filename} (${numeral(file.contentLength).format('0.0 b')})`}
                className={classes.pointer}
                avatar={includes(file.contentType, 'image') ? <Avatar src={file.url} /> : null}
                icon={<DescriptionIcon />}
              />
            </Link>
          </Grid>
        ))}
      </Grid>
    );
  }
}

Attachments = withStyles(styles)(Attachments);

export default Attachments;
