import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {Box, Tooltip, Typography} from '@mui/material';
import Menu from '@mui/material/Menu';
import * as React from 'react';
import {AddColumnMenu} from './CustomColumnMenuComponent';
import {HoopsTextField} from '../../../componentsOld/HoopsTextField';

const textFieldInputStyles = {
  fontSize: 14,
  height: 4,
};

const tooltipOffset = {
  modifiers: [{
    name: 'offset',
    options: {offset: [0, -4],},
  }]
};

export const ColumnHeaderField = ({
                                    defaultValue = '',
                                    onRenameColumn,
                                    onAddColumn,
                                    headerTextClass,
                                    colDef,
                                    allowAddColumns
                                  }) => {
  const [fieldState, setFieldState] = React.useState(null);

  const editField = (e) => {
    e.stopPropagation();
    setFieldState('edit');
  };

  const saveField = (e) => {
    onRenameColumn(e.target.value);
    setFieldState(null);
  };

  const [addColumnMenuAnchorEl, setAddColumnMenuAnchorEl] = React.useState(null);
  const [addColumnPosition, setAddColumnPosition] = React.useState(0);
  const [showIcon, setShowIcon] = React.useState('');

  const handleOpenAddColumnMenu = (event, position) => {
    event.stopPropagation();
    setAddColumnMenuAnchorEl(event.currentTarget);
    setAddColumnPosition(position);
    setShowIcon(position === colDef.index ? 'addColumnIconShownLeft' : 'addColumnIconShownRight');
  };
  const handleCloseAddColumnMenu = () => {
    setAddColumnMenuAnchorEl(null);
  };
  const handleAddColumn = (type, name) => {
    handleCloseAddColumnMenu();
    onAddColumn(type, name, addColumnPosition);
  };

  return (
    <>
      {(() => {
        switch (fieldState) {
          case 'edit':
            return <>
              <DragIndicatorIcon className='dragIcon' color={'secondary'}/>
              <Box className={'titleTextContainer'}>
                <HoopsTextField
                  autoFocus
                  selectOnFocus
                  size='small'
                  defaultValue={defaultValue}
                  onBlur={saveField}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.stopPropagation();
                      saveField(e);
                    }
                    if (e.key === 'Escape') {
                      setFieldState(null);
                    }
                  }}
                  inputProps={{style: textFieldInputStyles}}
                  onClick={(e) => {e.stopPropagation();}}
                />
              </Box>
            </>;
          default:
            return <>
              {allowAddColumns && <Box className={`addColumnIcon addColumnIconLeft ${addColumnMenuAnchorEl ? showIcon : ''}`}>
                <Tooltip arrow placement='top' title={'Add Column'} PopperProps={tooltipOffset}>
                  <AddCircleIcon cursor={'pointer'} onClick={(event) => handleOpenAddColumnMenu(event, colDef.index)}
                  />
                </Tooltip>
              </Box>}
              <DragIndicatorIcon className='dragIcon' color={'secondary'}/>
              <Box className={'spaceAfterDragIcon'}/>
              <Box className={'spaceBalanceSortIcon'}/>
              <Box className={'headerTextContainer'}>
                <Typography className={headerTextClass} variant='body2' onClick={editField} noWrap>
                  {defaultValue}
                </Typography>
              </Box>
              <Box className={'spaceForSortArrow'}/>
              {allowAddColumns && <Box className={`addColumnIcon addColumnIconRight ${addColumnMenuAnchorEl ? showIcon : ''}`}>
                <Tooltip arrow placement='top' title={'Add Column'} PopperProps={tooltipOffset}>
                  <AddCircleIcon cursor={'pointer'} onClick={(event) => handleOpenAddColumnMenu(event, colDef.index + 1)}
                  />
                </Tooltip>
              </Box>}
              <Menu
                id='basic-menu'
                anchorEl={addColumnMenuAnchorEl}
                open={Boolean(addColumnMenuAnchorEl)}
                onClose={handleCloseAddColumnMenu}
                MenuListProps={{'aria-labelledby': 'basic-button',}}
              >
                <AddColumnMenu onAddColumn={handleAddColumn}/>
              </Menu>
            </>;
        }
      })()}
    </>
  );
};
