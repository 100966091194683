// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  DialogContent, Divider,
  Grid,
  Typography,
  withStyles
} from '@material-ui/core';
import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import React, {Component} from 'react';
import {Field, Form} from 'react-final-form';
import {connect} from 'react-redux';
import {createProduct as _createProduct} from '../../../../actions/catalog';
import {closeCatalogModal as _closeCatalogModal} from '../../../../actions/modals';
import {MAX_FILE_SIZE_BYTES} from '../../../../constants/file';
import SizeColorPriceBreakFields from '../../../Products/SizeColorPriceBreaksFields';
import VendorAutocomplete from '../../../vendors/VendorAutocomplete';
import DialogActions from '../../DialogActions';
import {renderTextField} from '../../formHelpers/fields';
import {required} from '../../formHelpers/validation';
import HoopsDropzone from '../../HoopsDropzone';

const styles = (theme) => ({
  marginBuffer: {margin: theme.spacing(1)},
  ellipsis: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  bold: {fontWeight: '500'},
  flex: {display: 'flex'},
  alignEnd: {
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  },
  fullWidth: {width: '100%'}
});

class CatalogModalForm extends Component {

  componentDidMount() {

  }

  handleSubmit = (values) => {
    const {createProduct, closeModal} = this.props;
    values.vendorId = values.vendorId._id;
    createProduct(values);
    closeModal();
  };

  render() {
    const {classes, closeModal} = this.props;
    return (
      <Form
        onSubmit={this.handleSubmit}
        mutators={{
          ...arrayMutators,
          removePrimaryImage: (args, state, utils) => {
            utils.changeValue(state, 'primaryImage', () => null);
          }
        }}
        initialValues={{
          colors: [],
          sizes: [],
          priceBreaks: [{
            quantity: 1,
            price: null
          }]
        }}
        render={({
          handleSubmit,
          form: {
            mutators,
            getState,
            ...restForm
          }
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container style={{overflow: 'hidden'}} spacing={2}>

                  <Grid item xs={12}>
                    <Field
                      name='title'
                      component={renderTextField}
                      label='Name *'
                      validate={required}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name='vendorId'
                      component={VendorAutocomplete}
                      label='Vendor *'
                      validate={required}
                      fullWidth
                    >
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name='code'
                      component={renderTextField}
                      label='Product Code *'
                      validate={required}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name='description'
                      component={renderTextField}
                      label='Description'
                      multiline
                      minRows='2'
                    />
                  </Grid>
                  <Grid item container justifyContent={'center'} xs={12} className={classes.marginBuffer}>
                    <Field name={'primaryImage'}
                      component={({input: {onChange}}) => (
                        <Grid item container direction={'column'}>
                          {!getState().values.primaryImage && (
                            <>
                              <Grid item>
                                <Typography variant={'h6'}>Primary Image</Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant={'caption'}>
                                  {'Note: You can only select one image for the Product\'s primary image'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <HoopsDropzone
                                  minHeight={'100px'}
                                  onComplete={(file) => onChange(file)}
                                  maxSize={MAX_FILE_SIZE_BYTES}
                                  accept={['.png', '.jpeg', '.gif', '.jpg', '.bmp']}
                                  dropzoneOptions={{multiple: false}}
                                  removeOnComplete={false}
                                  multipleUploadErrorMessage={'Only one file can be uploaded as the Primary Image.'}
                                  cardList
                                  acceptReason={(
                                    <Typography variant={'body2'}>
                                      Only image type files are accepted (.png, .jpeg, .jpg).
                                    </Typography>
                                  )}
                                />
                              </Grid>
                            </>
                          )}
                        </Grid>
                      )} />
                  </Grid>
                  <Grid item container spacing={1}>
                    {getState().values.primaryImage && (
                      <Grid item xs={12}>
                        <Card className={classes.flex}>
                          <div className={classes.fullWidth}>
                            <CardMedia image={getState().values.primaryImage.url}
                              component='img'
                            />
                          </div>
                          <CardContent className={classes.fullWidth}>
                            <Typography className={classes.bold}>Primary Image</Typography>
                            <Typography gutterBottom variant='body1' className={classes.ellipsis}>
                              {getState().values.primaryImage.filename}
                            </Typography>
                          </CardContent>
                          <CardActions className={classNames(classes.alignEnd, classes.fullWidth)}>
                            <Button size='small' color='primary' onClick={() => {
                              mutators.removePrimaryImage();
                            }}>
                              Remove
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider style={{margin: '24px 0px'}} />
                  </Grid>
                  <SizeColorPriceBreakFields form={{mutators, getState, ...restForm}} />
                </Grid>

            </DialogContent>
            <DialogActions>
              <Grid container justifyContent={'space-between'}>
                <Grid item>
                  <Button onClick={closeModal}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Button
                        variant={'contained'}
                        color={'primary'}
                        type='submit'>Save</Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        )}
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => dispatch(_closeCatalogModal()),
    createProduct: (data) => dispatch(_createProduct(data)),
  };
}

function mapStateToProps(state) {
  return {isOpen: state.modalReducer.catalogModal.isOpen,};
}

CatalogModalForm = connect(mapStateToProps, mapDispatchToProps)(CatalogModalForm);
CatalogModalForm = withStyles(styles)(CatalogModalForm);
export default CatalogModalForm;
