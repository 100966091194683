/**
 * This will clear the data for an entire row of decoration costs
 * @param rowIndex
 * @param setValue
 */
export const clearDecorationRow = (
  rowIndex: number,
  setValue: (key: string, value: any) => void
) => {
  setValue(`decorationCosts[${rowIndex}].decoration`, undefined);
  setValue(`decorationCosts[${rowIndex}].decorationId`, '');
  setValue(`decorationCosts[${rowIndex}].description`, '');
  setValue(`decorationCosts[${rowIndex}].position`, '');
  setValue(`decorationCosts[${rowIndex}].colour`, '');
  setValue(`decorationCosts[${rowIndex}].size`, '');
  setValue(`decorationCosts[${rowIndex}].setupPrice`, 0);
  setValue(`decorationCosts[${rowIndex}].buyPrice`, 0);
  setValue(`decorationCosts[${rowIndex}].additionalCost`, 0);
  setValue(`decorationCosts[${rowIndex}].markup`, 0);
  setValue(`decorationCosts[${rowIndex}].vendor`, '');
};
