import React, {createContext, isValidElement, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import classNames from 'classnames';
import {HoopsPropTypes} from '../utils';
import {ModelessPopover} from './ModelessPopover';
import {registerGlobalStyle} from '../../theme';
import {Column} from '../Layout';
import {CircularProgress, Icon} from '@mui/material';
import {BodyText} from '../Text';

registerGlobalStyle('.popup-menu, .MuiDataGrid-menu', (theme) => ({
  'ul': {
    flexGrow: 1,
    width: '100%',
    listStyle: 'none',
    padding: theme.spacing(1, .25),
    margin: 0,
    overflowY: 'auto',
    alignItems: 'stretch',
    'li': {
      padding: theme.spacing(1, 2),
      cursor: 'pointer',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      color: theme.colors.text.main,
      columnGap: theme.spacing(1.5),
      lineHeight: 1,
      '&.disabled': {
        cursor: 'default',
        opacity: theme.disabled.opacity,
      },
      '.text-body': {
        color: 'inherit',
        fontSize: 'inherit',
      },
      '&:hover:not(.disabled)': {backgroundColor: theme.colors.background.hover,},
      '&.divider': {
        paddingBlock: theme.spacing(.5, 0),
        color: theme.colors.text.dark,
        fontSize: '.9em',
        pointerEvents: 'none',
        '&::after': {
          content: '""',
          borderTop: `1px solid ${theme.colors.border.light}`,
          position: 'absolute',
          inset: '0 0 0 0',
        },
        '&:empty::after': {transform: 'translateY(50%)',},
      },
      '&:has(>a)': {padding: 0,},
      '&>a': {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1, 2),
        width: '100%',
        height: '100%',
        color: theme.colors.text.main,
        columnGap: theme.spacing(1.5),
      },
      '.material-icons': {
        color: theme.colors.text.mediumIcon,
        fontSize: '1.5rem',
      },
      '&>.loading': {
        width: theme.spacing(3),
        color: theme.colors.text.highlight,
        svg: {
          width: theme.spacing(2.25),
          height: theme.spacing(2.25),
        },
      },
      '&>label': {
        width: '100%',
        '&>span:first-child': {width: '100%',},
      },
      '&>svg': {color: theme.colors.text.mediumIcon,}
    },
  },
  '.text': {fontSize: '1rem'},
  'li': {fontSize: '1rem'},
  '.popover-elevation, .MuiPaper-elevation': {border: `1px solid ${theme.colors.border.light}`,}
}));

const PopupMenuContext = createContext(null);
export const usePopupMenuContext = () => useContext(PopupMenuContext);

export function PopupMenu({className, anchorEl, open, placement, onClose, onShownChange, children}) {
  const [hidden, setHidden] = useState(false);

  const handleHideMenu = useCallback(() => {
    setHidden(true);
  }, []);

  useEffect(() => {
    if (open) {
      setHidden(false);
    }
  }, [open]);

  const popupMenuContext = useMemo(() => ({
    closeMenu: onClose,
    hideMenu: handleHideMenu,
  }), [handleHideMenu, onClose]);

  return (
    <PopupMenuContext.Provider value={popupMenuContext}>
      <ModelessPopover
        className={[className, 'popup-menu']}
        open={open ?? !!anchorEl}
        hidden={hidden}
        placement={placement}
        anchorEl={anchorEl}
        onClose={onClose}
        onShownChange={onShownChange}
      >
        {isValidElement(children) && children.type.name === PopupMenuList.prototype.constructor.name &&
          children
        }
        {(!isValidElement(children) || children.type.name !== PopupMenuList.prototype.constructor.name) &&
          <PopupMenuList>
            {children}
          </PopupMenuList>
        }
      </ModelessPopover>
    </PopupMenuContext.Provider>
  );
}

PopupMenu.propTypes = {
  className: HoopsPropTypes.className,
  anchorEl: HoopsPropTypes.object,
  open: HoopsPropTypes.bool,
  placement: HoopsPropTypes.string,
  onClose: HoopsPropTypes.func,
  onShownChange: HoopsPropTypes.func,
  children: HoopsPropTypes.children,
};

export function PopupMenuList({className, children}) {
  const ref = useRef(null);
  const {closeMenu} = usePopupMenuContext();

  const handleCloseMenu = useCallback((e) => {
    if (ref.current.contains(e.target)) {
      closeMenu();
    }
  }, [closeMenu]);

  return (
    <Column className={[className, 'popup-menu-list']} tag={'ul'} onClick={handleCloseMenu} ref={ref}>
      {children}
    </Column>
  );
}

PopupMenuList.propTypes = {
  className: HoopsPropTypes.className,
  children: HoopsPropTypes.children,
};

export function PopupItem({className, disabled, divider, keepOpen, keepOpenHidden, loading, prefix, text, value, onClick, children}) {
  const {hideMenu} = usePopupMenuContext();

  const handleClick = useCallback((e) => {
    if (disabled) {
      e.stopPropagation();
      return;
    } else if (keepOpen) {
      e.stopPropagation();
    } else if (keepOpenHidden) {
      e.stopPropagation();
      hideMenu();
    }
    onClick?.(e);
  }, [disabled, hideMenu, keepOpen, keepOpenHidden, onClick]);

  if (loading) {
    prefix = <div className='loading'><CircularProgress size={''}/></div>;
  } else if (typeof prefix === 'string') {
    prefix = <Icon>{prefix}</Icon>;
  }

  return (
    <li className={classNames([className, 'menuitem', divider && 'divider', disabled && 'disabled'])} onClick={handleClick} value={value}>
      {prefix}
      {text &&
        <BodyText>{text}</BodyText>
      }
      {children}
    </li>
  );
}

PopupItem.propTypes = {
  className: HoopsPropTypes.className,
  disabled: HoopsPropTypes.bool,
  divider: HoopsPropTypes.bool,
  keepOpen: HoopsPropTypes.bool,
  keepOpenHidden: HoopsPropTypes.bool,
  loading: HoopsPropTypes.bool,
  prefix: HoopsPropTypes.decorator,
  text: HoopsPropTypes.string,
  value: HoopsPropTypes.string,
  onClick: HoopsPropTypes.func,
  children: HoopsPropTypes.children,
};
