import {Table, TableBody, TableCell, TableRow} from '@material-ui/core';
import moment from 'moment';
import * as numeral from 'numeral';
import React from 'react';
import FormattedCurrency from '../FormattedCurrency';

function GrossMarginSummaryTable({currencySymbol, reportSettings, totals = {decorationSales: 0, inventorySales: 0, totalSales: 0}}) {
    const inventorySalesMargin = totals.inventoryMargin;
    const inventorySalesMarginPercent = inventorySalesMargin / totals.inventorySales * 100;
    const decorationSalesMargin = totals.decorationMargin;
    const decorationSalesMarginPercent = decorationSalesMargin / totals.decorationSales * 100;
    const additionalCostSalesMargin = totals.additionalCostMargin;
    const additionalCostSalesMarginPercent = additionalCostSalesMargin / totals.additionalCostSales * 100;
    const totalCosts = totals.totalCosts;
    const totalSales = totals.totalSales;
    const totalMargin = totalSales - totalCosts;
    const totalMarginPercent = totalMargin / totalSales * 100;

    return (
        <Table size={'small'}>
            <TableBody>
                <TableRow>
                    <TableCell variant={'head'}>Report Date Range</TableCell>
                    <TableCell colSpan='4' align={'right'}>{moment(reportSettings.startDate).format('D MMM. YYYY')} to {moment(reportSettings.endDate).format('D MMM. YYYY')}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell variant={'head'}></TableCell>
                    <TableCell variant={'head'} align={'right'}>
                        Total Cost
                        </TableCell>
                    <TableCell variant={'head'} align={'right'}>
                        Total Income
                        </TableCell>
                    <TableCell variant={'head'} align={'right'}>
                        Gross Margin ({currencySymbol})
                        </TableCell>
                    <TableCell variant={'head'} align={'right'}>
                        Gross Margin (%)
                        </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell variant={'head'}>Product Sales</TableCell>
                    <TableCell align={'right'}>
                        <FormattedCurrency>{totals.inventoryCosts}</FormattedCurrency>
                    </TableCell>
                    <TableCell align={'right'}>
                        <FormattedCurrency>{totals.inventorySales}</FormattedCurrency>
                    </TableCell>
                    <TableCell align={'right'}>
                        <FormattedCurrency>{inventorySalesMargin}</FormattedCurrency>
                    </TableCell>
                    <TableCell align={'right'}>
                        {numeral(inventorySalesMarginPercent).format('0.0')}%
                        </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell variant={'head'}>Decoration Sales</TableCell>
                    <TableCell align={'right'}>
                        <FormattedCurrency>{totals.decorationCosts}</FormattedCurrency>
                    </TableCell>
                    <TableCell align={'right'}>
                        <FormattedCurrency>{totals.decorationSales}</FormattedCurrency>
                    </TableCell>
                    <TableCell align={'right'}>
                        <FormattedCurrency>{decorationSalesMargin}</FormattedCurrency>
                    </TableCell>
                    <TableCell align={'right'}>
                        {numeral(decorationSalesMarginPercent).format('0.0')}%
                        </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell variant={'head'}>Additional Cost Sales</TableCell>
                    <TableCell align={'right'}>
                        <FormattedCurrency>{totals.additionalCostCosts}</FormattedCurrency>
                    </TableCell>
                    <TableCell align={'right'}>
                        <FormattedCurrency>{totals.additionalCostSales}</FormattedCurrency>
                    </TableCell>
                    <TableCell align={'right'}>
                        <FormattedCurrency>{additionalCostSalesMargin}</FormattedCurrency>
                    </TableCell>
                    <TableCell align={'right'}>
                        {numeral(additionalCostSalesMarginPercent).format('0.0')}%
                        </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell variant={'head'}>Total Sales for Period</TableCell>
                    <TableCell variant={'head'} align={'right'}>
                        <FormattedCurrency>{totalCosts}</FormattedCurrency>
                    </TableCell>
                    <TableCell variant={'head'} align={'right'}>
                        <FormattedCurrency>{totalSales}</FormattedCurrency>
                    </TableCell>
                    <TableCell variant={'head'} align={'right'}>
                        <FormattedCurrency>{totalMargin}</FormattedCurrency>
                    </TableCell>
                    <TableCell variant={'head'} align={'right'}>
                        {numeral(totalMarginPercent).format('0.0')}%
                        </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
}

export default GrossMarginSummaryTable;
