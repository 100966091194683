import DateFnsUtils from '@date-io/date-fns';
import {Button, MuiThemeProvider, Radio, RadioGroup} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import moment from 'moment';
import React, {useContext} from 'react';
import {Controller, useForm} from 'react-hook-form';
import green from '../../../ui/theme/green';
import {ReportContext} from '../Reporting';

export function SalesReportSettings() {
  const {setReportSettings, reportSettings, generateReport} = useContext(ReportContext);
  const {handleSubmit, control, reset} = useForm({defaultValues: reportSettings});
  const defaultStartDate = moment().startOf('month').toDate();
  const defaultEndDate = moment().endOf('month').toDate();

  const onSubmit = (d) => {
    setReportSettings(d);
    generateReport(d);
  };

  const handleGroupingChange = (d) => {
    const {value} = d.target;
    const payload = {grouping: value};
    setReportSettings(payload);
    reset(payload);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item container spacing={1}>
                    <Grid item lg={6} xs={12}>
                      <Controller as={KeyboardDatePicker} name='startDate' required control={control} label='Start Date' fullWidth={true} defaultValue={defaultStartDate} />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <Controller as={KeyboardDatePicker} name='endDate' required control={control} label='End Date' fullWidth={true} defaultValue={defaultEndDate} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item>
                    <Controller
                      render={(props) => (
                        <RadioGroup {...props} onChange={handleGroupingChange}>
                          <FormControlLabel
                            value='salesPerCustomer'
                            control={<Radio color='primary' />}
                            label='Sales per Customer'
                          />
                          <FormControlLabel
                            value='salesPerUser'
                            control={<Radio color='primary' />}
                            label='Sales per User'
                          />
                          <FormControlLabel
                            value='salesPerQuoteOwner'
                            control={<Radio color='primary' />}
                            label='Sales per Quote Owner'
                          />
                        </RadioGroup>
                      )}
                      rules={{required: true}}
                      name='grouping'
                      defaultValue='salesPerCustomer'
                      control={control}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent={'flex-end'}>
              <MuiThemeProvider theme={green}>
              <Button color={'primary'}
                type='submit'
                variant={'contained'}>Generate Report</Button>
                </MuiThemeProvider>
            </Grid>
          </Grid>
        </Grid>

      </MuiPickersUtilsProvider>
    </form>
  );
}

export default SalesReportSettings;
