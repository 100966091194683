import {Chip, Grid, Link as MuiLink, MuiThemeProvider, Paper, Table, TableContainer} from '@material-ui/core';
import {get, upperFirst} from 'lodash';
import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import {
  HoopsQuery,
  HoopsQueryContext,
  HoopsQueryEmptyState,
  HoopsQueryFilterChips,
  HoopsQueryFilterSearch, HoopsQueryPagination,
  HoopsQueryTableBody, HoopsQueryTableCellDateTime, HoopsQueryTableHeader
} from '../../componentsOld/HoopsQuery';
import HoopsQueryTableConfig from '../../componentsOld/HoopsQuery/HoopsQueryTableConfig';
import {GQL_TAX_PAGINATION} from '../../queries/taxes';
import purple from '../../ui/theme/purple';
import TaxMenuCell from './TaxMenuCell';
import TaxService from './TaxService';

export const TaxManyTable = ({
  fixedFilter = {},
  queryStateId = 'taxMany',
  openCreateTax = () => null
}) => {
  const taxTableRef = React.useRef();

  const columns = [
    {
      label: 'Tax #',
      field: 'name',
      render: (data) => (
          <Fragment>
            <MuiLink
              component={Link}
              underline={'none'}
              color={'primary'}
              to={`/settings/taxes/${data._id}/edit`}
            >
              {data.name}
            </MuiLink>
          </Fragment>
        ),
    },
    {
      label: 'Type',
      field: 'type',
      render: (data) => (
        <span>{upperFirst(get(data, 'type', ''))}</span>
      ),
    },
    {
      label: 'Date Created',
      field: 'createdAt',
      render: (data) => (
        <HoopsQueryTableCellDateTime value={data.createdAt} />
      ),
    },
    {
      label: 'Created By',
      field: 'createdBy.fullName',
    },
    {
      label: 'Tax Total',
      field: 'total',
      render: (data) => {
        const total = TaxService.getTaxRateTotal(data);
        return `${total}%`;
      },
    },
    {
      label: '',
      field: 'isDefault',
      render: (data) => data.isDefault && <MuiThemeProvider theme={purple}><Chip label='Default' color='primary' size='small' style={{paddingLeft: 8, paddingRight: 8}} /> </MuiThemeProvider>

    },
    {
      label: 'Actions',
      render: (data) => <TaxMenuCell data={data} taxTableRef={taxTableRef} />,
    },
  ];

  return (
    <>
      <HoopsQuery
        refetchStateId='tax'
        queryStateId={queryStateId}
        columns={columns}
        gqlQuery={GQL_TAX_PAGINATION}
        resultField='taxPagination'
        initialFilter={{}}
        fixedFilter={fixedFilter}
        initialSort={'_ID_DESC'}
      >
        <HoopsQueryContext.Consumer>
          {({
            items,
            sort,
            setSort,
            filter,
            setFilter,
            paging,
            setPaging,
            chips,
            setChips,
            hoopsQueryColumns,
            setHoopsQueryColumns
          }) => (
              <>
                <Grid
                  container
                  direction='row'
                  justifyContent='space-between'
                  alignItems='flex-start'
                >
                  <Grid item></Grid>
                  <Grid item>
                  <Grid container spacing={1}>
                    <Grid item>
                      <HoopsQueryTableConfig
                        columns={hoopsQueryColumns}
                        onColumnsChange={setHoopsQueryColumns}
                      />
                    </Grid>
                    <Grid item>
                      <HoopsQueryFilterSearch onChange={setFilter} filter={filter} placeholder={'Search'} chips={chips} onChipsChange={setChips} />
                    </Grid>
                  </Grid>
                  </Grid>
                </Grid>
                <HoopsQueryFilterChips
                  filter={filter}
                  onFilterChange={setFilter}
                  chips={chips}
                  onChipsChange={setChips}
                />
                <TableContainer component={Paper}>
                  <Table style={{minWidth: 'auto'}}>
                    <HoopsQueryTableHeader
                      hasCheckBox={false}
                      columns={hoopsQueryColumns}
                      onSort={setSort}
                      sortString={sort}
                    />
                    <HoopsQueryTableBody
                      rowsPerPage={paging.perPage}
                      rows={items}
                      columns={hoopsQueryColumns}
                      emptyStateComponent={() => (
                        <HoopsQueryEmptyState
                          filter={filter}
                          columns={hoopsQueryColumns}
                          field={'Tax'}
                          fieldPlural={'Taxes'}
                          rowsPerPage={paging.perPage}
                          onClearFilter={setFilter}
                          onChipsChange={setChips}
                          onAddItem={openCreateTax}
                        />
                      )}
                    />
                  </Table>
                </TableContainer>
                <HoopsQueryPagination paging={paging} onPaging={setPaging} />
              </>
            )}
        </HoopsQueryContext.Consumer>
      </HoopsQuery>
    </>
  );
};

export default TaxManyTable;
