// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-shadow */

import React, {useRef, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import HoopsFormDialog from '../modal/HoopsFormDialog';
import {FormProvider, useForm} from 'react-hook-form';
import {FormHookAddressField} from '../FormHooks/FormHookAddressField';
import {Close} from '@material-ui/icons';
import {get} from 'lodash';
import MenuItem from '@material-ui/core/MenuItem';
import {Select} from '@material-ui/core';
import {readOnlyAddress} from '../shared/formHelpers/fields';
import {Field} from 'redux-form';
import {capitalize} from '../../utils/capitalize';
import Typography from '@material-ui/core/Typography';

const addressFields = ['_id', 'address1', 'address2', 'city', 'state', 'postcode', 'country'];

export const AddressToField = ({name, type, defaultValues, addresses, change, value, classes}) => {
  const title = `${capitalize(type)} Address`;
  const [addressId, setAddressId] = useState(value?._id || defaultValues?._id);
  const [open, setOpen] = useState(false);
  const form = useRef(null);
  const formDefaultValues = {...(value || defaultValues)};
  formDefaultValues.label = type;
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: formDefaultValues
  });
  const {handleSubmit, setValue} = methods;
  const onSubmit = async (data, e) => {
    change(name, data);
    e.preventDefault();
    setOpen(false);

  };
  return (
    <>
      <Typography variant='caption' className={classes.greyText}>{title}</Typography>
      <Typography variant='body2' gutterBottom>
        <Field
          name={name}
          defaultValues={defaultValues}
          component={readOnlyAddress}
        />
      </Typography>
      <Button size={'small'}
              color={'primary'}
              variant={'outlined'}
              type={'button'}
              onClick={() => {
                methods.reset(value || defaultValues);
                setOpen(true);
              }}
      >
        Edit</Button>
      <HoopsFormDialog
        portal
        onCancel={() => setOpen(false)}
        DialogActionComponent={false}
        open={open}
        title={title}
      >
        <FormProvider {...methods}  >

          <form ref={form} onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleSubmit(onSubmit)(e);
          }}>
            <Grid container spacing={3} direction={'column'}>
              <Grid item>
                <Grid container justifyContent={'center'}>
                  <Grid item xs={8}>

                    <Select fullWidth
                            value={addressId}
                            renderValue={(value) => {
                              if (value === 'new') {
                                return 'Add new Address';
                              }
                              const address = addresses.find(({_id}) => _id === value);
                              if (!address) {return '';}
                              return `${address.name ?? ''} - ${address.formattedAddress ?? ''}`;
                            }}
                            onChange={(e) => {
                              setAddressId(e.target.value);
                              const address = addresses.find(({_id}) => _id === e.target.value);
                              addressFields.forEach(((addressField) => {
                                setValue(addressField, get(address, addressField));
                              }));
                            }}
                    >
                      {addresses?.filter(({label}) => label === type).map((address) => (
                        <MenuItem value={address._id} key={address._id}>{address.name ?? ''} - {address.formattedAddress ?? ''}</MenuItem>
                      ))}
                      <MenuItem value={'new'} key={'new'}>Add new Address</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormHookAddressField
                      type={type}
                      variant={'standard'}
                      showAddressLine2/>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <br/>
            <br/>
            <Grid container
                  justifyContent={'space-between'}
            >
              <Grid item>
                <Button variant={'text'}
                        color={'secondary'}
                        onClick={() => setOpen(false)}
                >
                  <Close/> Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color={'primary'}
                  variant={'contained'}
                  type={'submit'}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </HoopsFormDialog>

    </>
  );
};

