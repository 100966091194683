// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-class-assign */

import React from 'react';
import Autosuggest from 'react-autosuggest';
import {change} from 'redux-form';
import {MenuItem, withStyles} from '@material-ui/core';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import {connect} from 'react-redux';
import Paper from '@material-ui/core/Paper';
import * as ReactDom from 'react-dom';
import {renderTextField} from '../../common/RenderTextField';

const styles = (theme) => ({
  container: {position: 'relative',},
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  suggestion: {display: 'block',},
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
});

export function renderSuggestion(suggestion, {query, isHighlighted}) {
  const matches = match(suggestion.name, query);
  const parts = parse(suggestion.name, matches);

  return (
    <MenuItem selected={isHighlighted} component='div'>
      <div>
        {parts.map((part, index) =>
          part.highlight ? (
            <span key={String(index)} style={{fontWeight: 500}}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{fontWeight: 300}}>
              {part.text}
            </strong>
          ),
        )}
      </div>
    </MenuItem>
  );
}

// TODO: safe to delete?
// function renderInputComponent(inputProps) {
//   const { classes, inputRef = () => {}, ref, ...other } = inputProps;
//
//   return (
//     <TextField
//       fullWidth
//       InputProps={{
//         inputRef: node => {
//           ref(node);
//           inputRef(node);
//         },
//         classes: {
//           input: classes.input,
//         },
//       }}
//       {...other}
//     />
//   );
// }

class renderAutosuggest extends React.Component {
  container = React.createRef();

  onKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  handleChange = (event, {newValue}) => {
    const {meta: {form}, input: {name}, dispatch} = this.props;

    if (typeof newValue === 'string') {
      dispatch(change(form, name, newValue));
    }
  };

  render() {
    const {
autosuggestProps, classes,
      label, input, suggestionComponent,
      dispatch, meta: {touched, error}, meta, ...custom
} = this.props;
    return (
      <div ref={this.container}>
        <Autosuggest
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          {...autosuggestProps}
          inputProps={{
            ...autosuggestProps.inputProps,
            ...custom,
            ...input,
            error: Boolean(touched && error),
            label,
            onChange: this.handleChange,
            onKeyDown: this.onKeyDown,
            autoComplete: 'off'
          }}
          renderInputComponent={renderTextField}
          renderSuggestionsContainer={({containerProps , children}) => {
            if(!this.container.current) {
              return null;
            }
            let rect = this.container.current.getBoundingClientRect();
            let {style} = containerProps;
            style = {
              ...style,
              position: 'fixed',
              top: rect.top + 50,
              left: rect.left,
              maxWidth: (rect.right - rect.left) * 2,
              zIndex: 10000,
            };
            return (
              ReactDom.createPortal(
                <Paper {... containerProps}
                     style={style}>
                  {children}
                </Paper>, document.body)
            );
          }}
          renderSuggestion={suggestionComponent}
        />
      </div>
    );
  }
}

renderAutosuggest = withStyles(styles)(renderAutosuggest);
renderAutosuggest = connect()(renderAutosuggest);

export default renderAutosuggest;
