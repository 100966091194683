// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import React, {Component, Fragment} from 'react';
import {IconButton, TableRow} from '@material-ui/core';
import {Cancel, FileCopy} from '@material-ui/icons';
import {TableCell} from './ItemBuilderTable';
import {Field} from 'redux-form';

class ItemBuilderTableRow extends Component {

  handleDeleteClick = (index) => {
    const {fields} = this.props;
    fields.remove(index);
  };

  handleDuplicateClick = (index) => {
    const {fields} = this.props;
    const rowValue = fields.get(index);
    fields.push(rowValue);
  };

  render() {
    const {fields, classes, headings, duplicate, ...custom} = this.props;

    return (
      <Fragment>
        {fields.map((field, index) => (<TableRow key={index}>
          {headings.map((h, i) => (
              <TableCell key={i}>
                <Field
                  name={h.name ? `${field}.${h.name}` : field}
                  field={field}
                  index={index}
                  component={h.component}
                  {...custom}
                />
              </TableCell>
            ))}
          {duplicate &&
          <TableCell padding={'checkbox'}>
            <IconButton size={'small'} onClick={() => this.handleDuplicateClick(index)}>
              <FileCopy fontSize={'small'}/>
            </IconButton>
          </TableCell>
          }
          <TableCell padding={'checkbox'}>
            <IconButton size={'small'} onClick={() => this.handleDeleteClick(index)}>
              <Cancel fontSize={'small'}/>
            </IconButton>
          </TableCell>
        </TableRow>))}
      </Fragment>
    );
  }
}

export default ItemBuilderTableRow;
