// TODO: fix eslint disable
/* eslint-disable no-shadow */

import React, {createContext, useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import {change} from 'redux-form';
import {calculatePriceElementBreakdown} from './itemBuilderUtils';
import {useLocalStorageState, useWatch} from '../../hooks';
import {stripHTML} from '../../utils/stripHtml';

export const ItemBuilderContext = createContext(null);

export const ItemBuilderContextContainer = ({onQuoteChange, children}) => {
  const dispatch = useDispatch();
  const [item, setItem] = useState(false); // the quote item to build price for
  const [open, setOpen] = useState(false); // the open state of the the item builder modal
  const [sexyPrice, setSexyPrice] = useState(null); // sexy price set in the ItemBuilderSexyPricePopup
  const [itemIndex, setItemIndex] = useState(null); // the index of the items array so that we can pass data back the redux-form in the correct location1`
  const [primaryProduct, setPrimaryProduct] = useState(); // all prodct ids in the quote with quantity, a job or purchase order
  const [productDecorations, setProductDecorations] = useState([]);
  const [priceElement, setPriceElement] = useState({}); // the items of a quote, a job or purchase order
  const [formName, setFormName] = useState(''); // the name of the redux-form that we are going to populate with the data from the item
  const [assignedDefaultMarkupData, setAssignedDefaultMarkupData] = useState(null);
  const [isNotTouched, setIsNotTouched] = useState(true);
  const [calculatedPriceElementValues, setCalculatedPriceElementValues] = useState({});
  const [productDisplayToCustomer, setProductDisplayToCustomer] = useLocalStorageState(['products_displayToCustomer'], false);
  const [decorationDisplayToCustomer, setDecorationDisplayToCustomer] = useLocalStorageState(['decorations_displayToCustomer'], false);

  const onPriceChanged = useCallback(() => setIsNotTouched(false), []);

  useWatch(() => {
    setCalculatedPriceElementValues(calculatePriceElementBreakdown(priceElement));
  }, [priceElement]);

  const handleSavePriceElement = useCallback((priceElement) => {
    const values = calculatePriceElementBreakdown(priceElement);
    // set values for the quote line item
    dispatch(change(formName, `items[${itemIndex}].configuredProductId`, null));
    dispatch(change(formName, `items[${itemIndex}].priceElement`, priceElement));
    dispatch(change(formName, `items[${itemIndex}].product`, primaryProduct));
    dispatch(change(formName, `items[${itemIndex}].quantity`, values.productQuantity));
    dispatch(change(formName, `items[${itemIndex}].rate`, parseFloat(values.sellPricePerUnit)));

    // set display to client local storage defaults, count the number of rows that are checked, if more than half are checked set true as default
    if (priceElement?.variations?.length > 0) {
      const productDisplayToClientCheckedCount = priceElement?.variations?.reduce((ac, row) => ac + (row.displayToCustomer === true ? 1 : 0), 0);
      setProductDisplayToCustomer(priceElement?.variations?.length / 2 <= productDisplayToClientCheckedCount);
    }
    if (priceElement?.decorationCosts?.length > 0) {
      const decorationDisplayToClientCheckedCount = priceElement?.decorationCosts?.reduce((ac, row) => ac + (row.displayToCustomer === true ? 1 : 0), 0);
      setDecorationDisplayToCustomer(priceElement?.decorationCosts?.length / 2 <= decorationDisplayToClientCheckedCount);
    }

    // quote line item description
    let primaryDescription = '';
    // quote line item image
    let primaryImage = null;
    if (priceElement?.variations?.length > 0) {
      primaryDescription = primaryProduct?.description
        ? `#DYNAMIC# ${primaryProduct?.title} - ${primaryProduct?.description}`
        : `#DYNAMIC# ${primaryProduct?.title ?? priceElement?.variations[0]?.productName ?? ''}`;
        primaryImage = primaryProduct?.primaryImage?.url ?? primaryProduct?.images[0]?.url ?? null;
    } else if (priceElement?.decorationCosts?.length > 0) {
      primaryDescription = `#DYNAMIC# ${priceElement?.decorationCosts[0]?.description ?? ''}`;
      primaryImage = null;
    } else if (priceElement?.additionalCosts?.length > 0) {
      primaryDescription = `#DYNAMIC# ${priceElement?.additionalCosts[0]?.name ?? ''}`;
      primaryImage = null;
    }

    // check if the line item image and description needs to be updated
    if (!item?.description || stripHTML(item?.description ?? '') === '' || (item?.description.slice(0, 9) === '#DYNAMIC#' && item?.description !== primaryDescription) || (item?.product?._id !== primaryProduct?._id)) {
      dispatch(change(formName, `items[${itemIndex}].imageUrl`, primaryImage));
      dispatch(change(formName, `items[${itemIndex}].description`, primaryDescription));
    }

    if (onQuoteChange) {
      onQuoteChange();
    }
    setIsNotTouched(true);
    setPrimaryProduct(null);
    setProductDecorations([]);
  }, [dispatch, formName, item, itemIndex, onQuoteChange, primaryProduct, setProductDisplayToCustomer, setDecorationDisplayToCustomer]);

  const value = {
    item,
    setItem,

    open,
    setOpen,

    sexyPrice,
    setSexyPrice,

    itemIndex,
    setItemIndex,

    primaryProduct,
    setPrimaryProduct,

    productDecorations,
    setProductDecorations,

    priceElement,
    setPriceElement,

    formName,
    setFormName,

    assignedDefaultMarkupData,
    setAssignedDefaultMarkupData,

    calculatedPriceElementValues,
    setCalculatedPriceElementValues,

    handleSavePriceElement,

    productDisplayToCustomer,
    decorationDisplayToCustomer,

    isNotTouched,
    setIsNotTouched,
    onPriceChanged,
  };

  return (
    <ItemBuilderContext.Provider value={value}>
      {children}
    </ItemBuilderContext.Provider>
  );
};

export default ItemBuilderContextContainer;
