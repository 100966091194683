import {Tab, Tabs} from '@mui/material';
import {styled} from '@mui/material/styles';
import * as React from 'react';
import theme from '../../ui/theme';

const HoopsStyledTabs = styled((props) => <Tabs {...props} />)(() => ({
  borderBottom: `1px solid ${theme.palette.secondary.main}`,
  minHeight: 34,
  '& .MuiTabs-indicator': {backgroundColor: theme.palette.primary.main,},
}));

const HoopsStyledTab = styled((props) => <Tab disableRipple {...props} />)(() => ({
  color: theme.palette.text.primary,
  textTransform: 'none',
  padding: `0 ${theme.spacing(2)}px 0 ${theme.spacing(2)}px`,
  minHeight: 34,
  whiteSpace: 'noWrap',
  '&:hover': {
    backgroundColor: theme.palette.secondary.hover,
    opacity: 1,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5
  },
  '&.Mui-selected': {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  '&.Mui-focusVisible': {backgroundColor: theme.palette.primary.main,},
  '&:not(:last-child):after': {
    position: 'absolute',
    content: '""',
    right: 0,
    width: 1,
    height: '40%',
    background: theme.palette.secondary.main,
  },

}));

export const HoopsTabs = ({
  value = 'home',
  onChange = () => null,
  labelComponent = (label) => label,
  items = {},
  componentsStart,
  componentsEnd,
  ...props
}) => (
    <HoopsStyledTabs
      value={value}
      onChange={onChange}
      variant='scrollable'
      scrollButtons='auto'
      allowScrollButtonsMobile
      {...props}
    >
      {componentsStart}
      {
        items.map((item, idx) => (
            <HoopsStyledTab key={idx} value={item?.value} icon={item?.icon} iconPosition={item?.iconPosition} label={labelComponent(item?.label, item?.value)} />
          ))
      }
      {componentsEnd}
    </HoopsStyledTabs>
  );
