// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-class-assign */

import React, {Fragment} from 'react';
import {Field} from 'redux-form';
import * as AWS from 'aws-sdk';
import {LinearProgress, withStyles} from '@material-ui/core';
import * as uuidv4 from 'uuid/v4';

AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: new AWS.CognitoIdentityCredentials({IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,})
});

const s3 = new AWS.S3({
  apiVersion: '2006-03-01',
  params: {
    Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
    Region: process.env.REACT_APP_AWS_REGION
  }
});

const styles = (theme) => ({marginTop: {marginTop: theme.spacing(1)}});

class S3FileUpload extends React.Component {
  state = {
    isLoading: false,
    progress: 0,
    isComplete: false,
    s3Object: null,
    error: false,
    errorMessage: null,
    preview: ''
  };

  handleChange = (e, file) => {

    if (!file.length) {
      return;
    }

    const acceptableFormats = ['image/jpeg', 'image/png', 'image/bmp'];
    if (acceptableFormats.indexOf(file[0].type) === -1) {
      this.handleError('Invalid image format');
      return;
    }

    const {s3Key} = this.props;
    this.setState({
      ...this.state,
      isLoading: true,
      isComplete: false,
      error: false,
      errorMessage: null,
      s3Object: null
    });

    const managedUpload = s3.upload({
      Key: s3Key || uuidv4(),
      Body: file[0],
      ACL: 'public-read'
    }, (err, data) => {
      if (err) {
        this.handleError(err);
        return;
      }

      this.handleComplete({file: file[0], ...data});
    });

    managedUpload.on('httpUploadProgress', this.handleProgress);
  };

  handleProgress = ({loaded, total}) => {
    const {onProgress} = this.props;
    if (loaded && total) {
      const progress = loaded / total;

      this.setState({
        ...this.state,
        progress
      });

      if (onProgress) {
        onProgress(progress);
      }
    }
  };

  handleComplete = (s3Object) => {
    const {onComplete} = this.props;

    this.setState({
      ...this.state,
      isLoading: false,
      isComplete: true,
      error: false,
      errorMessage: null,
      s3Object: s3Object
    });

    if (onComplete) {
      onComplete(s3Object);
    }
  };

  handleError = (err) => {
    const {onError} = this.props;

    this.setState({
      ...this.state,
      isLoading: false,
      isComplete: false,
      error: true,
      errorMessage: err.message,
      s3Object: null
    });
    if (onError) {
      onError(err);
    }
  };

  render() {
    const {inputProps, inputLabel, onChange, onComplete, classes, hideLoading, ...custom} = this.props;
    const {...uploadState} = this.state;

    return (
      <Fragment>
        <Field type={'file'}
               inputLabelProps={{shrink: true}}
               inputProps={{
                 ...inputProps,
                 type: 'file',
               }}
               onChange={this.handleChange}
               uploadState={uploadState}
               {...custom}
        />
        {
          !hideLoading &&
          <LinearProgress className={classes.marginTop}
                          color='primary'
                          variant='determinate'
                          value={uploadState.progress * 100}/>
        }
      </Fragment>
    );
  }
}

S3FileUpload = withStyles(styles)(S3FileUpload);
export default S3FileUpload;
