// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {useApolloClient} from '@apollo/client';
import {Button, CircularProgress, Divider, makeStyles, MuiThemeProvider, Typography} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import gql from 'graphql-tag';
import numeral from 'numeral';
import React, {useEffect, useState} from 'react';
import theme from '../../../ui/theme';
import green from '../../../ui/theme/green';
import {ErrorMessage} from '../../ErrorMessage';

const GET_CLIENT_SECRET = gql`
    query($_id:String!){
        quote(_id: $_id){
            stripePaymentIntentClientSecret
            customer {
              companyTradingEntityId
            }
            company {
                companyTradingEntities {
                    _id
                    name
                  }
            }
        }
    }
`;

const useStyles = makeStyles((theme) => ({
  greyText: {color: theme.colors.grey.main},
  creditCardField: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: theme.spacing(1),
    borderColor: theme.colors.grey.light,
    '& .MuiOutlinedInput-notchedOutline': {border: 'none'}
  },
  totalText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(6),
    margin: theme.spacing(2),
    '& > *': {
      [theme.breakpoints.up('xs')]: {
        display: 'inline',
        whiteSpace: 'nowrap'
      },
      [theme.breakpoints.down('xs')]: {
        display: 'contents',
        whiteSpace: 'nowrap',
        minWidth: 150,
      },
    }
  }
}));

const PaymentForm = ({
  _id = null,
  onSuccess = () => null,
  currencySymbol = '$',
  payTotal = null
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles();

  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const client = useApolloClient();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const {data} = await client.query({
        query: GET_CLIENT_SECRET,
        variables: {_id}
      });
      const companyTradingEntityIndex = data.quote.company.companyTradingEntities.findIndex((company) => company._id === data.quote.customer.companyTradingEntityId) ?? 0;
      const clientSecret = data.quote.stripePaymentIntentClientSecret;
      if (!stripe || !elements) {
        return;
      }
      if (!clientSecret) { throw new Error(`This payment can not be processed, please contact ${data.quote.company.companyTradingEntities[companyTradingEntityIndex].name}`); }
      // confirm payment
      const {paymentIntent, error: stripeError} = await stripe.confirmCardPayment(
        clientSecret,
        {payment_method: {card: elements.getElement(CardElement),}}
      );

      if (stripeError) {
        setMessage(stripeError.message);
        setIsLoading(false);
        return;
      }

      if (paymentIntent.status) {
        setMessage(paymentIntent.status);
      }

      if (paymentIntent.status === 'succeeded') {
        onSuccess(true);
      }
    } catch (e) {
      setError(e);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (parseFloat(payTotal) < 2) {
      setMessage('This payment can not be processed, the total is below the minimum transaction amount.');
    }
    // TODO: FIX HOOK DEPENDENCIES
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) { return <ErrorMessage error={error} />; }
  return (
    <>
      <Typography variant='h4' gutterBottom>Accept & Pay Online</Typography>
      <Typography variant='body1' className={classes.greyText}>Please enter your payment information</Typography>
      <div className={classes.totalText}>
        <Typography variant='body1' >Total Amount (inc Tax)</Typography>
        <Typography variant='h5' >{currencySymbol} {numeral(payTotal).format('0,0.00')}</Typography>
      </div>
      <Divider style={{marginTop: theme.spacing(4), marginBottom: theme.spacing(4)}} />

      <form id='payment-form' onSubmit={handleSubmit}>
        <CardElement id='card-element' className={classes.creditCardField}
          options={{
            style: {
              base: {
                color: '#666',
                fontSize: '16px',
              },
              invalid: {color: theme.colors.red,}
            },
            hidePostalCode: true
          }}
        />

        <MuiThemeProvider theme={green}>
          <Button
            color={'primary'}
            variant={'contained'}
            disableElevation
            disabled={isLoading || !stripe || !elements || parseFloat(payTotal) < 2}
            id='submit'
            type='submit'
          >
            {isLoading ? <><CircularProgress size={16} style={{marginRight: theme.spacing(1)}} />Process Payment</> : 'Process Payment'}

          </Button>
        </MuiThemeProvider>
        {/* Show any error or success messages */}
        {message && <Typography variant='body2' style={{color: theme.colors.red, padding: theme.spacing(2)}}>{message}</Typography>}
      </form >

      <Typography variant='body1' className={classes.greyText} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: theme.spacing(6)}}><LockIcon color='primary' style={{margin: theme.spacing(1)}} />Secured by Stripe with bank-level encryption</Typography>

    </>
  );
};

export default PaymentForm;
