// TODO: fix eslint disable
/* eslint-disable no-shadow */

import React from 'react';
import {Divider, Grid, Typography, Table, TableBody, TableRow, TableCell, makeStyles, Checkbox} from '@material-ui/core';
import FilePreview from './FilePreview';
import FileTableCellActions from './FileTableCellActions';
import {format} from 'date-fns';
import StatusChanger from '../../componentsOld/Statuses/StatusChanger';
import {useDispatch} from 'react-redux';
import {updateFile} from '../../actions/files';
import {get} from 'lodash';
import theme from '../../ui/theme';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: '50%',
        width: 0,
        overflow: 'hidden'
    },
    noBorderHead: {
        borderStyle: 'none',
        padding: 4,
        textAlign: 'left',
        width: 90
    },
    noBorder: {
        borderStyle: 'none',
        padding: 4,
        textAlign: 'left'
    },
    fileHeader: {color: theme.colors.grey.main}
}));

export const FileListItem = ({
    file,
    filePreviewOptions = {
        thumbWidth: '150px',
        thumbHeight: '150px',
        tooltipMaxWidth: '650px'
    },
    fileActionOptions = {
        view: true,
        download: true,
        edit: false,
        delete: false,
        createTask: false,
        createTaskState: {}
    },
    selectable = false,
    selected = false,
    onSelect = () => null,
    onDeleteAction = () => null,
    onEditAction = () => null,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const getCreatedAt = () => {
        const d = new Date(parseInt(file.createdAt)); // the number representation for our date is a string from server
        return format(d, 'LLLL d yyyy');
    };

    const updateFileStatus = (status) => {
        dispatch(updateFile(file._id, {status}));
    };

    const handleChange = (event) => {
        const checked = event.target.checked;
        onSelect(checked);
    };

    return (
        <> {file && file.filename ?
            <>
                <Grid container spacing={2} alignItems='center'>
                    {selectable ? <Grid item xs={false}>
                        <Checkbox checked={selected} size='small' color='primary' onChange={handleChange} />
                    </Grid> : null}
                    <Grid item xs={false} style={{minWidth: `calc(${filePreviewOptions.thumbWidth} + 16px)`}}>
                        <FilePreview file={file} options={filePreviewOptions} />
                    </Grid>
                    <Grid item xs style={{overflow: 'auto'}}>
                        <Table className={classes.table}>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={classes.noBorderHead}>
                                        <Typography variant='caption' className={classes.fileHeader}>File Name</Typography>
                                    </TableCell>
                                    <TableCell className={classes.noBorder}>
                                        <Typography variant='body2' className={classes.fileHeader}>{file.filename}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.noBorderHead}>
                                        <Typography variant='caption' className={classes.fileHeader}>Description</Typography>
                                    </TableCell>
                                    <TableCell className={classes.noBorder}>
                                        <Typography variant='body2' className={classes.fileHeader}>{file.description}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.noBorderHead}>
                                        <Typography variant='caption' className={classes.fileHeader}>Uploaded By</Typography>
                                    </TableCell>
                                    <TableCell className={classes.noBorder}>
                                        <Typography variant='body2' className={classes.fileHeader}>{get(file, 'createdBy.firstName', '')} {get(file, 'createdBy.lastName', '')}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.noBorderHead}>
                                        <Typography variant='caption' className={classes.fileHeader}>Upload Date</Typography>
                                    </TableCell>
                                    <TableCell className={classes.noBorder}>
                                        <Typography variant='body2' className={classes.fileHeader}>{getCreatedAt()}</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid item style={{alignSelf: 'start'}}>
                        <span style={{marginRight: '20px'}}>
                            <StatusChanger entityType='file' entityMapping='status' value={file.status} onChange={(newStatus) => updateFileStatus(newStatus.value)} />
                        </span>
                        <FileTableCellActions actionOptions={fileActionOptions} file={file} onDeleteAction={() => onDeleteAction(file)} onEditAction={() => onEditAction(file)} />
                    </Grid>
                </Grid>
                <Divider light style={{marginTop: theme.spacing(1),marginBottom: theme.spacing(1)}}/>
            </>
            : null} </>
    );
};

export default FileListItem;
