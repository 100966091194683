import React from 'react';
import {Controller} from 'react-hook-form';
import {HoopsTextField} from '../HoopsTextField';

export default function FormHookTextField({label,rules,name,control,errors,...props}){
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({onChange, onBlur, value, ref}) => (
          <HoopsTextField
            meta={{error: errors && errors[name] && errors[name].message}}
            onBlur={onBlur}
            value={value ?? ''}
            onChange={(e) => onChange(e.target.value)}
            label = {label}
            style={{display: props.type === 'hidden' ? 'none' : 'inherit'}}
            inputRef={ref}
            {...props}
          />
        )}
    />
  );
}
