// TODO: fix eslint disable
/* eslint-disable no-unused-vars, */

import mixpanel from 'mixpanel-browser';
import {SET_USER_DATA} from '../actions/action-types/auth';
import {SEND_QUOTE_COMPLETE, SEND_QUOTE_ERROR, SET_QUOTE} from '../actions/quote';
import {mixPanelProjectToken} from '../config';

mixpanel.init(mixPanelProjectToken);

const identifyAndSetPerson = (payload) => {
  mixpanel.identify(payload.username);

  mixpanel.people.set({
    name: payload.fullName,
    $email: payload.username,
    USER_ID: payload.username,
    company: payload.company.name,
    companyId: payload.company._id,
    phone: payload.phone,
    $timezone: payload.timezone,
  });
};

export const mixpanelTracking = (_) => (next) => (action) => {
  const {type, payload} = action;

  switch (type) {
    case SET_USER_DATA:
      if (payload.company) { // on login you have company, on logout you do not
        identifyAndSetPerson(payload);
      }
      break;

    case SEND_QUOTE_COMPLETE:
    case SEND_QUOTE_ERROR:
    case SET_QUOTE:
      mixpanel.track(type); // simplistic tracking
      break;
    default:
      break;
  }

  return next(action);
};
