import {Paper} from '@material-ui/core';
import MaterialTable from 'material-table';
import moment from 'moment';
import React from 'react';
import EmptyTable from '../../../componentsOld/emptyTable';
import ChipSelect from '../../../componentsOld/jobs/ChipSelect';
import {PROOF_STATUSES} from '../../../constants/status';
import ProofsTableActionCell from './ProofsTableActionCell';
import ProofsTableProductsCell from './ProofsTableProductsCell';
import ProofsTableThumbnail from './ProofsTableThumbnail';

const ProofsTable = ({
  proofs = [],
  isLoading = false
}) => {

  const tableOptions = {
    showTitle: false,
    selection: false,
    pageSizeOptions: [25, 50, 100],
    pageSize: 25
  };

  const columns = [
    {
      title: 'Preview',
      render: (rowData) => (
        rowData.file && <ProofsTableThumbnail imageUrl={rowData.file.url} />
      )
    }, {
      title: 'File Name',
      field: 'file.filename'
    }, {
      title: 'Linked Products',
      render: (rowData) => <ProofsTableProductsCell products={rowData.products} />
    }, {
      title: 'Uploaded By',
      field: 'createdBy.fullName'
    }, {
      title: 'Date',
      render: (rowData) => moment(rowData.createdAt).format('Do MMM YYYY')
    }, {
      title: 'Status',
      render: (rowData) => (
        <ChipSelect disabled activeOption={rowData.status} options={PROOF_STATUSES} />
      )
    }, {
      title: 'Action',
      render: (rowData) => <ProofsTableActionCell proof={rowData} />
    }
  ];

  return (
        <MaterialTable
          localization={{body: {emptyDataSourceMessage: <EmptyTable field={'Proof'} showAddButton={false} loading={isLoading} />}}}
          columns={columns}
          data={proofs}
          options={tableOptions}
          components={{Container: (props) => <Paper {...props} elevation={0} />}}
        />
  );
};

export default ProofsTable;
