import {
  Button,
  Grid,
  Link,
  MuiThemeProvider
} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import greenTheme from '../../ui/theme/green';
import EntityListHero from '../EntityList/EntityListHero';
import DecorationDialog from './decorationDialog';
import DecorationManyTable from './DecorationManyTable';

export const DecorationManyViewPage = () => {
  const [isCreateDecorationOpen, setIsCreateDecorationOpen] = useState(false);

  const openCreateDecoration = () => {
    setIsCreateDecorationOpen(true);
  };

  const handleCreateDecorationClose = () => {
    setIsCreateDecorationOpen(false);
  };

  useEffect(() => {
    // This shold be in the router
    document.title = 'Decorations | Hoops';
  }, []);

  return (
    <Grid container>
      <EntityListHero
        title='Decorations'
        subtitle='Store &amp; manage your decorations to automate your processes.'
        helpArticleUrl='http://help.hoopscrm.com/en/articles/4634996-adding-your-decorations'
        videoId='IVJkWT8ZdWU'
      >
        <Link component={RouterLink} to='/settings/decorations/import' underline='none'>
          <Button variant='outlined' color='primary'>
            Import
            </Button>
        </Link>
          &nbsp;&nbsp;&nbsp;
          <MuiThemeProvider theme={greenTheme}>
          <Button
            data-intercom-target={'decorations-table-add-button'}
            onClick={openCreateDecoration}
            color={'primary'}
            variant={'contained'}
          >
            Add Decoration
            </Button>
        </MuiThemeProvider>
        <DecorationDialog
          isOpen={isCreateDecorationOpen}
          onClose={handleCreateDecorationClose}
        />
      </EntityListHero>
      <Grid
        container
        justifyContent={'space-between'}
        alignItems={'center'}
        spacing={3}
      >
        <Grid item xs={12}>
          <DecorationManyTable />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DecorationManyViewPage;
