import {get} from 'lodash';

export const SET_PROGRESS = 'SET_PROGESS';
export const SET_COMPLETE = 'SET_COMPLETE';
export const SET_FILE = 'SET_FILE';
export const SET_STATUS = 'SET_STATUS';

const initialState = {
  file: {name: 'images.zip'},
  destination: 'Catalog Import for AS Colour',
  loading: false,
  multipartUploads: {}
};

const dropzoneReducer = (state = {...initialState}, action) => {
  const key = get(action, 'payload.key');
  const progress = get(action, 'payload.progress');
  const complete = get(action, 'payload.complete');
  const file = get(action, 'payload.file');
  const status = get(action, 'payload.status', 'UPLOADING');
  const error = get(action, 'payload.error');

  switch (action.type) {
    case SET_PROGRESS:
      if (!key) {
        throw new Error(`Key required to set multipart upload for "${action.type}"`);
      }
      return {
        ...state,
        multipartUploads: {
          ...state.multipartUploads,
          [key]: {
            ...state.multipartUploads[key],
            progress,
            complete: (progress >= 100),
            loading: (progress < 100),
            status: (progress >= 100 ? 'COMPLETE' : status)
          }
        }
      };
    case SET_COMPLETE:
      if (!key) {
        throw new Error(`Key required to set multipart upload for "${action.type}"`);
      }
      return {
        ...state,
        multipartUploads: {
          ...state.multipartUploads,
          [key]: {
            ...state.multipartUploads[key],
            complete,
          }
        }
      };
    case SET_STATUS:
      if (!key) {
        throw new Error(`Key required to set multipart upload for "${action.type}"`);
      }
      return {
        ...state,
        multipartUploads: {
          ...state.multipartUploads,
          [key]: {
            ...state.multipartUploads[key],
            status,
            error
          }
        }
      };
    case SET_FILE:
      if (!key) {
        throw new Error(`Key required to set multipart upload for "${action.type}"`);
      }
      return {
        ...state,
        multipartUploads: {
          ...state.multipartUploads,
          [key]: {
            ...state.multipartUploads[key],
            file,
          }
        }
      };
    default:
      return state;
  }
};

export default dropzoneReducer;
