// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {client} from '../../servicesOld/GraphQLService';
import {get, pick} from 'lodash';
import {
  GQL_GET_TAX,
  GQL_GET_TAXES,
  GQL_CREATE_TAX,
  GQL_UPDATE_TAX,
  GQL_DELETE_TAX,
  GQL_SET_DEFAULT_TAX,
} from '../../queries/taxes';

export default class TaxService {

  static parseTaxToTaxInput(tax) {
    return pick(tax, ['_id', 'name', 'components', 'type']);
  }

  static getTaxRateTotal(taxRate) {
    if (!taxRate) {
      return 0;
    }

    return get(taxRate, 'components', []).reduce(
      (agg, component) => (agg += parseFloat(component.rate)),
      0
    );
  }

  static getRevenueTaxes(companyState) {
    return TaxService.getAllExpenses(companyState).filter(
      (tax) => tax.type === 'revenue'
    );
  }

  static getRevenueDefaultTax(companyState) {
    const taxes = TaxService.getRevenueTaxes(companyState);
    return taxes.filter((tax) => tax.isDefault)[0];
  }

  static getExpenseTaxes(companyState) {
    return TaxService.getAllExpenses(companyState).filter(
      (tax) => tax.type === 'expense'
    );
  }

  static getExpenseDefaultTax(companyState) {
    const taxes = TaxService.getExpenseTaxes(companyState);
    return taxes.filter((tax) => tax.isDefault)[0];
  }

  static getAllExpenses(companyState) {
    return get(companyState, 'taxes', []);
  }

  static _parseTaxInput(tax) {
    const keys = ['_id', 'name', 'components', 'type'];
    const pruned = pick(tax, keys);

    return {
      ...pruned,
      components: pruned.components.map(TaxService._parseTaxComponentInput)
    };
  }

  static _parseTaxComponentInput(taxComponent) {
    const keys = ['_id', 'name', 'rate'];

    const pruned = pick(taxComponent, keys);

    return {
      ...pruned,
      rate: parseFloat(pruned.rate.toString())
    };

  }

  static async getTax(id) {
    return client
      .query({
        query: GQL_GET_TAX,
        variables: {_id: id,},
        fetchPolicy: 'no-cache',
      })
      .then(({data: {taxOne}}) => taxOne);
  }

  static async getTaxes({...variables}) {
    return client
      .query({
        query: GQL_GET_TAXES,
        fetchPolicy: 'network-only',
        variables: {...variables,},
      })
      .then(({data: {taxMany}}) => taxMany);
  }

  static async createTax(taxData) {
    return client
      .mutate({
        mutation: GQL_CREATE_TAX,
        variables: {record: TaxService._parseTaxInput(taxData),},
      })
      .then(({data: {taxCreateOne}}) => taxCreateOne);
  }

  static async updateTax(_id, data) {
    return client
      .mutate({
        mutation: GQL_UPDATE_TAX,
        variables: {
          _id,
          record: TaxService._parseTaxInput(data),
        },
      })
      .then(({data: {taxUpdateById}}) => taxUpdateById);
  }

  static async deleteTax(_id) {
    return client
      .mutate({
        mutation: GQL_DELETE_TAX,
        variables: {_id,},
      })
      .then(({data: {taxRemoveById}}) => taxRemoveById);
  }

  static setDefault(_id) {
    return client
        .mutate({
            mutation: GQL_SET_DEFAULT_TAX,
            variables: {_id,}
        })
        .then(({data: {taxSetDefault: _id}}) => _id);
}
}
