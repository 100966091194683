// TODO: fix eslint disable
/* eslint-disable no-shadow */

import React from 'react';
import {useSelector} from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

export default ({label, placeholder, value = [], ChipProps = {color: 'primary', size: 'small'}, ...props}) => {
  const itemsSelector = (state) => state.authReducer.users;
  const items = useSelector(itemsSelector);

  const itemIds = items.map((item) => item._id);

  const getItem = (itemId) => items.filter((item) => item._id === itemId)[0];

  return (
    <Autocomplete
      multiple
      limitTags={2}
      options={itemIds}
      disableCloseOnSelect
      label={label}
      value={value}
      ChipProps={ChipProps}
      getOptionLabel={(itemId) => {
        const item = getItem(itemId);
        return item ? item.fullName : '';
      }}
      onChange={(event, value) => {
        props.onChange(value ? value : null);
      }}
      getOptionSelected={(itemId, value) => itemId === value}
      renderOption={(itemId, {selected}) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{marginRight: 8}}
            checked={selected}
          />
          {getItem(itemId).fullName}
        </React.Fragment>
      )}

      renderInput={(params) => (
        <TextField {...params} variant='standard' label={label} placeholder={placeholder} />
      )}
    />
  );
};
