// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import {Checkbox, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, TextField, Typography} from '@material-ui/core';
import React, {useEffect, useRef} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {useSelector} from 'react-redux';
import ControlledAutocomplete from './ControlledAutocomplete';

const useStyles = makeStyles((theme) => ({
    greyText: {color: theme.colors.grey.main,},
    selectClass: {minWidth: 184,},
    optionTextAny: {fontStyle: 'italic',},
}));

const AutomationTriggerConfigProofs = () => {
  const classes = useStyles();
  const {control, watch, setValue} = useFormContext();

  const jobStatus = watch('statusType');

  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    setValue('status', []);
  }, [jobStatus, setValue]);

  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

  let options = [{value: 'APPROVED', label: 'Approved'},{value: 'REJECTED', label: 'Rejected'}];

  return (

    <Grid container direction='row' justifyContent='center' alignItems='flex-end' spacing={1}>
      <Grid item >
        <Typography variant='h5' className={classes.greyText}>If </Typography>
      </Grid>
      <Grid item >
        <FormControl>
          <InputLabel id='status-label'>Choose Condition</InputLabel>
          <Controller
            name='statusType'
            control={control}
            render={(props) =>
              <Select
                inputProps={{className: classes.selectClass}}
                labelId='selectorPath-label'
                {...props}
              >
                <MenuItem value={'all'}>All</MenuItem>
                <MenuItem value={'some'}>Some</MenuItem>
              </Select>
            }

          />
        </FormControl>
      </Grid>
      <Grid item >
        <Typography variant='h5' className={classes.greyText}> proofs are  </Typography>
      </Grid>
      <Grid item >
        <ControlledAutocomplete
          control={control}
          name='status'
          options={options}
          getOptionLabel={(option) => option.label}
          getOptionSelected={(option, value) => option.value === value.value}
          renderOption={(option, {selected}) => (
              <>
                <Checkbox
                  color='primary'
                  style={{marginRight: 8}}
                  checked={selected}
                />
                <span
                  className={option.label === 'Anything' && classes.optionTextAny}>{capitalize(option.label)}</span>
              </>
            )}
          renderInput={(params) => (
            <TextField {...params} label='Select' placeholder='Select' />
          )}
          multiple={true}

          disableCloseOnSelect={true}
          defaultValue={[]}
        />
      </Grid>
    </Grid>

  );
};

export default AutomationTriggerConfigProofs;
