import {Button, Checkbox, Grid, ListItem, ListItemIcon, ListItemText, makeStyles, Menu} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SettingsIcon from '@material-ui/icons/Settings';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    menuButton: {
        background: '#FFF',
        height: 40,
        color: theme.colors.grey.main,
    },
    menuItem: {

        '& .MuiCheckbox-root': {
            padding: 0,
            paddingRight: theme.spacing(1)
        },
        '& .MuiMenuItem-root': {
            padding: '8px 16px',
            minWidth: theme.spacing(25),
        }

    },
}));

export const HoopsQueryTableConfig = ({
    leftTitle = '',
    rightBlock = null,
    columns = [],
    onColumnsChange = () => null,
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const classes = useStyles();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const hideColumnToggle = (e, index) => {
        const updateColumns = [...columns];
        updateColumns[index].hide = (!updateColumns[index].hide);
        onColumnsChange(updateColumns);
    };

    return (
        <div>
            <Button
                id='table-config-button'
                aria-controls='table-config-menu'
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                variant='outlined'
                className={classes.menuButton}
            >
                <SettingsIcon />
            </Button>
            <Menu
                id='table-config-menu'
                aria-labelledby='table-config-button'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                getContentAnchorEl={null}
                className={classes.menuItem}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Grid container spacing={5}>
                    <Grid item>
                        <b style={{marginLeft: 19}}>{leftTitle}</b>
                        {columns.map((column, cIdx) => (
                                <div key={cIdx}>
                                    {
                                        !column.inActive &&
                                        <ListItem role={undefined} dense button onClick={(e) => hideColumnToggle(e, cIdx)}>
                                            <ListItemIcon style={{minWidth: 'unset'}}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={!column.hide}
                                                    disableRipple
                                                />
                                            </ListItemIcon>
                                            <ListItemText primary={column.label} />
                                        </ListItem>
                                    }
                                </div>
                            ))}
                    </Grid>
                    {rightBlock && <Grid item>
                        {rightBlock}
                    </Grid>}
                </Grid>
            </Menu>
        </div>
    );
};

export default HoopsQueryTableConfig;
