// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-class-assign, no-shadow */

// Material UI
import {Button, Divider, Grid, Paper, Switch, Tooltip, Typography} from '@material-ui/core';
import {MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import {get} from 'lodash';
import moment from 'moment';
import React, {Component} from 'react';
// Redux
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {change, Field, getFormValues, reduxForm, reset as _reset} from 'redux-form';
import {setFormCustomer as _getCustomer} from '../../actions/action-types/customer';
import {
  clearJob as _clearJob,
  createJob as _createJob,
  getJob as _getJob,
  getJobs as _getJobs,
  updateJob as _updateJob
} from '../../actions/jobs';
// -- Actions
import {openCustomerModal as _openCustomerModal} from '../../actions/modals';
import {editEntity} from '../../ui/theme/editEntity';
import green from '../../ui/theme/green';
import {isSameDay} from '../../utils/isSameDay';
import ContactAutocomplete from '../customers/ContactAutocomplete';
import EntityListHero from '../EntityList/EntityListHero';
import {datePickerField, renderTextField, UserAutocompleteField} from '../shared/formHelpers/fields';
import {required} from '../shared/formHelpers/validation';
// External
import SingleJobTable from './SingleJobTable';
import {getLocalStorageItem, putLocalStorageItem} from '../../utils';
import {AddressToField} from '../address/AddressToField';
import {PermissionBasedContent, SubscriptionTypes} from '../../componentsHoops';

const styles = (theme) => ({
  ...editEntity,
  vertDivider: {
    margin: '0 auto',
    color: theme.colors.grey.light,
  }
});
const JOB_SHOW_CUSTOMER_DETAILS_LOCALSTORAGE_KEY = 'job_showCustomerDetails';

class JobEditPage extends Component {
  state = {
    showCustomer: getLocalStorageItem(JOB_SHOW_CUSTOMER_DETAILS_LOCALSTORAGE_KEY),
    inheritFiles: true, //settings for convert to a job
    inheritTask: false, //settings for convert to a job
    linkComments: true, //settings for convert to a job
    inheritDeadline: true, //settings for convert to a job

  };

  async componentDidMount() {
    const {getJob, match} = this.props;

    if (match.params._id) {
      await getJob(match.params._id);
    }
  }

  handleSelectCustomerContact = (contact) => {
    const {change} = this.props;
    change('contact', contact);
    change('customer', contact.customer);

    if (!contact._id) { // this hack is to enfore that we clear out the customer when contact is unset
      change('contact', null);
      change('customer', null);
    }
  };

  handleSuccess = () => {
    const {resetForm, history} = this.props;
    resetForm();
    history.push('/jobs');
  };
  oldDeadlineAt = '';

  async handleSubmit(values) {
    // TODO: This should all be in a service that handles this logic
    const {createJob, updateJob, job, getJob} = this.props;
    if (job._id && job.number) {
      updateJob(job._id, {
        ...values,
        contactId: values.contact._id,
        customerId: values.customer._id,
      }, () => {
        getJob(job._id);
        this.handleSuccess();
      });
      return;
    }

    createJob({
      ...values,
      contactId: values.contact._id,
      customerId: values.customer._id,
      inheritFiles: this.state.inheritFiles,
      inheritTask: this.state.inheritTask,
      linkComments: this.state.linkComments,
    }, () => {
      //set the current job view to home, makes the same as viewState.setCurrentView
      putLocalStorageItem(['jobs', 'currentView'].join('|'), 'home');
      this.handleSuccess();

    });
  }

  openEditCustomerModal = (_id) => {
    const {getCustomer, openCustomerModal, change} = this.props;
    getCustomer(_id, () => {
      openCustomerModal((customer) => {
        if (customer && customer.contacts) {
          change('customer', customer);
          change('contact', customer.contacts[0]);
        }
      });
    });
  };

  handleShowCustomer = () => {
    const newStatus = !this.state.showCustomer;
    if (newStatus) {
      putLocalStorageItem(JOB_SHOW_CUSTOMER_DETAILS_LOCALSTORAGE_KEY, 'show');
    } else {
      putLocalStorageItem(JOB_SHOW_CUSTOMER_DETAILS_LOCALSTORAGE_KEY, '');
    }
    this.setState({showCustomer: newStatus});
  };

  render() {
    const {
      classes,
      handleSubmit,
      newJobForm,
      job,
      invalid,
      history,
      change,
    } = this.props;

    if (!job) {
      return null;
    }
    const settingsItem = {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 10,
    };
    const {showCustomer, inheritFiles, inheritDeadline, inheritTask, linkComments} = this.state;

    const addresses = get(newJobForm, 'customer.addresses', []);
    const attention = {
      name: newJobForm?.customer?.name,
      attention: newJobForm?.contact?.fullName,
      email: newJobForm?.contact?.email,
      phone: newJobForm?.contact?.phone,
    };
    const defaultShipTo = newJobForm?.shipTo || addresses?.find(({label}) => label === 'SHIPPING') || addresses?.[0];
    const defaultBillTo = newJobForm?.billTo || addresses?.find(({label}) => label === 'BILLING') || addresses?.[0];
    let title = '';
    let convertingToJob = false;
    if (job._id && job.number) {
      title = `Job ${job.number}`;
    } else if (job._id && !job.number) {
      title = 'Create Job for Quote';
      convertingToJob = true;
    } else if (!job._id && !job.number) {
      title = 'New Job';
    }

    return (
      <form autoComplete={'off'} onSubmit={handleSubmit((values) => this.handleSubmit(values))}>
        <Grid container>
          <EntityListHero
            title={title}
            subtitle='Simplify job management, improve communication &amp; increase efficiency.'
            helpArticleUrl='http://help.hoopscrm.com/en/articles/4634959-creating-a-job'
            videoId='6eeQQSnKTy4'
          >

            <Button
              data-intercom-target={'job-modal-cancel'}
              onClick={() => history.goBack()}
              disableElevation
              className={`${classes.whiteButton} ${classes.buttons}`}
              variant={'contained'}>Cancel</Button>

            <MuiThemeProvider theme={green}>
              <Button
                data-intercom-target={'job-modal-save'}
                variant={'contained'}
                color={'primary'}
                type='submit'
                className={classes.buttons}
                disabled={invalid}
              >Save</Button>
            </MuiThemeProvider>

          </EntityListHero>

          <Grid item xs={12} data-intercom-target={'job-table'}>
            <Paper>

              <Grid container className={classes.header}>
                <Grid
                  container
                  direction='row'
                  justifyContent='space-between'
                  alignItems='flex-start'
                  spacing={4}
                >

                  <Grid item xs={6} className={classes.detailsSecondRow}>
                    <Typography variant='caption' className={classes.labelText}>Select Customer</Typography>
                    <Field
                      data-intercom-target='job-customer-field'
                      name='contact'
                      component={ContactAutocomplete}
                      onChange={this.handleSelectCustomerContact}
                      className={classes.inputStyles}
                      textFieldProps={{variant: 'outlined',}}
                      validate={required}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={3} style={{paddingTop: 30}}>
                    {newJobForm?.customer?._id && <Button
                      color='primary'
                      variant='outlined'
                      onClick={() => this.openEditCustomerModal(newJobForm.customer._id)}
                    >Edit Customer</Button>}
                  </Grid>
                  <Grid item xs={3} style={{paddingTop: 30, textAlign: 'right'}}>
                    <Button
                      style={newJobForm?.customer?._id ? null : {display: 'none'}}
                      onClick={this.handleShowCustomer}
                      color='primary'

                    >
                      {showCustomer ? 'Hide' : 'Show'} details
                    </Button>
                  </Grid>
                  {newJobForm && newJobForm.customer && newJobForm.customer._id && showCustomer &&
                    <Grid item xs={12}>
                      <Grid container spacing={4} alignItems={'center'}>
                        <Grid item xs={4}>
                          <Typography variant='caption' className={classes.greyText}>Company</Typography>
                          <Typography variant='body2'>{newJobForm.customer.name}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant='caption' className={classes.greyText}>Contact Name</Typography>
                          <Typography variant='body2'>
                            {get(newJobForm, 'contact.fullName', null) || [
                              get(newJobForm, 'contact.firstName', ''),
                              get(newJobForm, 'contact.lastName', '')
                            ].join(' ')}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant='caption' className={classes.greyText}>Email</Typography>
                          <Typography variant='body2'>{get(newJobForm, 'contact.email')}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <AddressToField
                            classes={classes}
                            type={'BILLING'}
                            addresses={addresses}
                            defaultValues={defaultBillTo}
                            name={'billTo'}
                            change={(...value) => {
                              this.props.change(...value);
                            }}
                            value={newJobForm?.billTo}
                          />

                        </Grid>
                        <Grid item xs={4}>
                          <AddressToField
                            classes={classes}
                            type={'SHIPPING'}
                            name={'shipTo'}
                            addresses={addresses}
                            defaultValues={{...attention, ...defaultShipTo}}
                            change={(...value) => {
                              this.props.change(...value);
                            }}
                            value={newJobForm?.shipTo}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant='caption' className={classes.greyText}>Contact Number</Typography>
                          <Typography variant='body2'>{newJobForm.customer.phone}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  }

                </Grid>
              </Grid>
              <Grid
                container className={classes.entityDetails}
                justifyContent={'space-between'}
                alignItems={'flex-start'}
              >

                <Grid item xs={12} md={convertingToJob ? 9 : 12}>
                  <Grid container spacing={3}>
                    <Grid item lg={2} sm={4} xs={6}>
                      <Typography variant='caption' className={classes.greyText}>Job Number</Typography>
                      <Typography variant='body1' className={classes.headerInfo}> {(newJobForm && newJobForm.number ?
                        <span>{newJobForm.number}</span> : <span>DRAFT</span>)}</Typography>
                    </Grid>
                    <Grid item lg={2} sm={4} xs={6}>
                      <Typography variant='caption' className={classes.greyText}>Date</Typography>
                      <Typography variant='body1'
                        className={classes.headerInfo}>{get(newJobForm, 'createdAt') ? moment(newJobForm.createdAt, 'x').format('DD MMM. YYYY') : '--'}</Typography>
                    </Grid>
                    <Grid item lg={2} sm={4} xs={6}>
                      <Typography variant='caption' className={classes.greyText}>Creator</Typography>
                      <Typography variant='body1'
                        className={classes.headerInfo}>{get(newJobForm, 'createdBy.fullName', '--')}</Typography>
                    </Grid>
                    <Grid item lg={2} sm={4} xs={6}>
                      <Typography variant='caption' className={classes.greyText}>Job Owner</Typography>
                      <Field
                        name={'jobOwnerId'}
                        component={UserAutocompleteField}
                        className={classes.inputStyles}
                      />
                    </Grid>
                    <Grid item lg={2} sm={4} xs={6}>
                      <Typography variant='caption' className={classes.greyText}>Deadline</Typography>
                      <Field
                        data-intercom-target={'job-deadlineAt-field'}
                        name={'deadlineAt'}
                        component={datePickerField}
                        onChange={(date) => {
                          if (inheritDeadline && !isSameDay(date, job.deadlineAt)) {
                            this.setState({inheritDeadline: false});
                          }
                        }}
                        fullWidth
                        className={classes.inputStyles}
                      />
                    </Grid>
                    <Grid item lg={2} sm={4} xs={6}>
                      <Typography variant='caption' className={classes.greyText}>Reference Number</Typography>
                      <Field
                        component={renderTextField}
                        name={'customReference'}
                        variant='outlined'
                        size='small'
                        fullWidth
                        className={classes.inputStyles}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='caption' className={classes.greyText}>Job Title</Typography>
                    <Field
                      data-intercom-target={'job-title-field'}
                      component={renderTextField}
                      name={'title'}
                      variant='outlined'
                      size='small'
                      fullWidth
                      placeholder='Give the job a descriptive title'
                    />
                  </Grid>
                </Grid>
                {convertingToJob &&
                  <Grid item xs={12} md={3}>
                    <Grid container>
                      <Grid item sm={0} md={3}>
                        <Divider orientation='vertical' light variant='fullWidth' className={classes.vertDivider} />
                      </Grid>
                      <Grid item sm={12} md={9}>
                        <Typography variant='h6'>Settings</Typography>
                        <Grid container alignItems='stretch'>
                          <Grid item style={settingsItem} sm={4} md={12}>
                            <Typography variant='body2' display='inline'>Inherit Files From Quote
                              <Tooltip
                                placement='top'
                                arrow
                                title={
                                  <Typography variant='caption' display='block'>
                                    Any files that have been added to the FILES tab of the quote, will be added to the
                                    FILES
                                    tab of the job.
                                  </Typography>}
                              >
                                <InfoIcon fontSize='small' className={classes.icon} />
                              </Tooltip>
                            </Typography>
                            <Switch
                              checked={inheritFiles}
                              color='primary'
                              onChange={(e, checked) => this.setState({inheritFiles: checked})}
                              name='inheritFiles'
                              inputProps={{'aria-label': 'secondary checkbox'}}
                            />

                          </Grid>
                          <Grid item style={settingsItem} sm={4} md={12}>
                            <Typography variant='body2' display='inline'>Inherit Task From Quote
                              <Tooltip
                                placement='top'
                                arrow
                                title={
                                  <Typography variant='caption' display='block'>
                                    Any tasks that have been added to the quote, will be added to the TASKS tab of the
                                    job.
                                  </Typography>}
                              >
                                <InfoIcon fontSize='small' className={classes.icon} />
                              </Tooltip>
                            </Typography>
                            <Switch
                              checked={inheritTask}
                              color='primary'
                              onChange={(e, checked) => this.setState({inheritTask: checked})}
                              name='inheritTask'
                              inputProps={{'aria-label': 'secondary checkbox'}}
                            />

                          </Grid>
                          <Grid item style={settingsItem} sm={4} md={12}>

                            <Typography variant='body2' display='inline'>Link Quote and Job Comments
                              <Tooltip
                                placement='top'
                                arrow
                                title={
                                  <Typography variant='caption' display='block'>
                                    Any comments that have been added to the quote, will be added to the COMMENTS tab of
                                    the
                                    job. Any future comments that are added to the job will be viewable on both the quote
                                    and the job.
                                  </Typography>}
                              >
                                <InfoIcon fontSize='small' className={classes.icon} />
                              </Tooltip>
                            </Typography>
                            <Switch
                              checked={linkComments}
                              color='primary'
                              onChange={(e, checked) => this.setState({linkComments: checked})}
                              name='linkComments'
                              inputProps={{'aria-label': 'secondary checkbox'}}
                            />

                          </Grid>
                          <Grid item style={settingsItem} sm={4} md={12}>

                            <Typography variant='body2' display='inline'>Inherit Deadline
                              <Tooltip
                                placement='top'
                                arrow
                                title={
                                  <Typography variant='caption' component='span' display='block'>
                                    The quote deadline will be added as the job deadline.
                                  </Typography>}
                              >
                                <InfoIcon fontSize='small' className={classes.icon} />
                              </Tooltip>
                            </Typography>
                            <Switch
                              checked={inheritDeadline}
                              color='primary'
                              onChange={(e, checked) => {
                                this.setState({inheritDeadline: checked});
                                if (checked) {
                                  if (job.deadlineAt) {
                                    change('deadlineAt', new Date(job.deadlineAt).toISOString());
                                  } else {
                                    change('deadlineAt', '');
                                  }
                                  this.oldDeadlineAt = newJobForm.deadlineAt;
                                } else {
                                  if (this.oldDeadlineAt) {
                                    change('deadlineAt', this.oldDeadlineAt);
                                  } else {
                                    change('deadlineAt', '');
                                  }
                                }
                              }}
                              name='inheritDeadline'
                              inputProps={{'aria-label': 'secondary checkbox'}}
                            />

                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                }
              </Grid>
              <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.proofs]}>
                <Grid container className={classes.entityTable}>
                  <SingleJobTable
                    job={job}
                    data-intercom-target={'job-modal-items'}
                  />
                </Grid>
              </PermissionBasedContent>
            </Paper>
          </Grid>
        </Grid>
      </form>

    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    clearJob: () => dispatch(_clearJob()),
    updateJob: (id, data, cb) => dispatch(_updateJob(id, data, cb)),
    change: (field, value) => dispatch(change('newJobForm', field, value)),
    resetForm: () => dispatch(_reset('newJobForm')),
    createJob: (job, callback) => dispatch(_createJob(job, callback)),
    getJobs: () => dispatch(_getJobs()),
    getJob: (_id) => dispatch(_getJob(_id)),
    openCustomerModal: (callback) => dispatch(_openCustomerModal({callback})),
    getCustomer: (_id, cb) => dispatch(_getCustomer(_id, cb))
  };
}

function mapStateToProps(state) {
  return {
    newJobForm: getFormValues('newJobForm')(state),
    initialValues: state.jobsReducer.activeJob,
    job: state.jobsReducer.activeJob,
  };
}

JobEditPage = reduxForm({
  form: 'newJobForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: false,
  validate: (values) => {
    if (!values) {
      return {};
    }

    const errors = {};

    if (!values || !values.contact || !values.contact._id) {
      errors.contact = 'Contact is required';
    }

    return errors;
  }
})(JobEditPage);
JobEditPage = withRouter(JobEditPage);
JobEditPage = connect(mapStateToProps, mapDispatchToProps)(JobEditPage);
JobEditPage = withStyles(styles)(JobEditPage);

export default JobEditPage;
