// TODO: fix eslint disable
/* eslint-disable no-unused-vars, */

import {snackError} from '../../actions/action-types';
import {hoopsQuerySetRefetch} from '../../actions/hoopsQuery';
import AutomationService from './AutomationService';

export const CREATE_AUTOMATION = 'CREATE_AUTOMATION';

export function createAutomation(record) {
    return function (dispatch) {
        return AutomationService.createAutomation(record).then((payload) => {
            dispatch(hoopsQuerySetRefetch('automation', true));
            return payload;
        });
    };
}

export function updateAutomation(id, record) {
    return function (dispatch) {
        return AutomationService.updateAutomation(id, record).then((payload) => {
            dispatch(hoopsQuerySetRefetch('automation', true));
            return payload;
        });
    };
}

export function duplicateAutomation(id) {
    return function (dispatch) {
        return AutomationService.getAutomationById(id).then((payload) => {
            payload = {...payload, isDisabled: true};
            dispatch(createAutomation(payload));
            dispatch(hoopsQuerySetRefetch('automation', true));
            return payload;
        });
    };
}

export function getAutomation(id) {
    return function (dispatch) {
        return AutomationService.getAutomationById(id).then((payload) => payload);
    };
}

export function getManyAutomation(filter) {
    return function (dispatch) {
        return AutomationService.getManyAutomation(filter).then((payload) => payload);
    };
}

export function makeDefaultAutomation(id) {
    return function (dispatch) {
        return AutomationService.makeDefaultAutomation(id).then((payload) => {
            dispatch(hoopsQuerySetRefetch('automation', true));
            return payload;
        });
    };
}

export function deleteAutomation(id) {
    return function (dispatch) {
        return AutomationService.deleteAutomation(id).then((payload) => {
            dispatch(hoopsQuerySetRefetch('automation', true));
            return payload;
        })
            .catch((e) => {
                const message = e.graphQLErrors[0] ? e.graphQLErrors[0].message : 'Something went wrong when deleting this Email Template.';
                dispatch(snackError(message));
            });
    };
}
