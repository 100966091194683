import React, {useCallback, useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {Typography} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import {useMutation} from '@apollo/client';
import {GQL_REMOVE_SHARED_PRODUCT} from '../../../queries/product';
import {createGraphQLClient} from '../../../servicesOld/GraphQLService';

const client = createGraphQLClient({});
export default function RenderProduct({product, onUpdate}) {
  const [viewers, setViewers] = useState([]);

  useEffect(() => {
    setViewers(product.viewOnlyCompanies || []);
  }, [product]);

  const [removeSharedProduct] = useMutation(GQL_REMOVE_SHARED_PRODUCT, {client, onCompleted: () => onUpdate(),},
  );

  const handleDelete = useCallback(async (companyId) => {
      await removeSharedProduct({
        variables: {
          companyId,
          productId: product._id,
        },
      });
  }, [product, removeSharedProduct]);

  return (
    <>
      <Grid container spacing={2}>
        {viewers.length ? <>
          <Grid item xs={12}>
            <Typography><strong>{product.title} </strong>is shared with the following companies: </Typography>
          </Grid>
          {viewers.map((viewer) => (
            <Grid item key={viewer._id}>
              <Chip
                color={'primary'}
                label={viewer.name} onDelete={() => handleDelete(viewer._id)}/>
            </Grid>))}
        </> : <>
          <Grid item xs={12}>
            <Typography><strong>{product.title} </strong>is shared with the following companies: </Typography>
            <Typography variant={'body2'}>Use the form to share with other companies.</Typography>
          </Grid>
        </>}
      </Grid>
    </>
  );
}
