import {Grid, Table} from '@material-ui/core';
import React from 'react';
import {useDispatch} from 'react-redux';
import {openCatalogModal as _openCatalogModal} from '../../actions/modals';
import {HoopsQuery, HoopsQueryContext, HoopsQueryEmptyState, HoopsQueryPagination} from '../../componentsOld/HoopsQuery';
import {GQL_CONFIGURED_PRODUCT_PAGINATION} from '../../queries/configuredProducts';
import ConfiguredProductManyGrid from './ConfiguredProductsTable/ConfiguredProductManyGrid';

export const ConfiguredProducts = () => {
    const dispatch = useDispatch();

    return (
        <Grid container >
            <Grid item container xs={12}>
                <HoopsQuery refetchStateId='configuredProduct' queryStateId='configuredProductMany' gqlQuery={GQL_CONFIGURED_PRODUCT_PAGINATION} resultField='configuredProductPagination' initialFilter={{}} initialSort={'_ID_ASC'} initialPaging={{perPage: 30}}>
                    <HoopsQueryContext.Consumer>
                        {({items, filter, setFilter, paging, setPaging, setChips}) => (<>
                                <Table>
                                    <ConfiguredProductManyGrid rowsPerPage={paging.perPage} rows={items} emptyStateComponent={() => <HoopsQueryEmptyState filter={filter} columns={[]} field={'Pre-Built Items'} rowsPerPage={paging.perPage} onClearFilter={setFilter} onChipsChange={setChips} onAddItem={() => dispatch(_openCatalogModal())} />} />
                                </Table>
                                <Grid container direction='row' justifyContent='center' alignItems='center'>
                                    <HoopsQueryPagination paging={paging} onPaging={setPaging} inputProps={{rowsPerPageOptions: [10, 30, 60, 100]}} />
                                </Grid>
                            </>)}
                    </HoopsQueryContext.Consumer>
                </HoopsQuery>
            </Grid>
        </Grid>
    );
};

export default ConfiguredProducts;
