// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React from 'react';
import {Grid, Typography, withStyles, Divider} from '@material-ui/core';
import {connect} from 'react-redux';

const styles = (theme) => ({
  gridPadding: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  gridPaddingContainer: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  contactPadding: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2)
  },
  grey: {color: theme.colors.grey.main}
});

class IndividualContact extends React.Component {

  render() {

    const {classes} = this.props;
    const contact = this.props.contact;

    return (
        <Grid item container xs={12} spacing={1}>
          <Grid item xs={6}>
            <Typography className={classes.grey}>Name</Typography>
            <Typography>{contact.firstName} {contact.lastName}</Typography>
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <Typography className={classes.grey}>Email</Typography>
            <Typography>{contact.email}</Typography>
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <Typography className={classes.grey}>Phone</Typography>
            <Typography>{contact.phone}</Typography>
          </Grid>
          <Grid item xs={12}>
          <Divider/>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {customer: state.customerReducer.customer};
}

IndividualContact = connect(mapStateToProps)(IndividualContact);

export default withStyles(styles)(IndividualContact);
