import {makeStyles} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, {useState} from 'react';

const useStyles = makeStyles((theme) => ({
    iconButton: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      padding: 4,
      '&:hover': {backgroundColor: theme.palette.primary.dark},
      '&:active': {backgroundColor: theme.palette.primary.main}
    },
    extendedIcon: {marginRight: theme.spacing(1)}
  })
  );

const ExpandButton = ({
    isExpanded,
    onChange = () => null,
    ...props
}) => {
    const classes = useStyles();
    const [_isExpanded, _setIsExpanded] = useState(isExpanded);
    
    const handleClick = () => {
        _setIsExpanded((prev) => {
            const state = !prev;
            onChange(state);
            return state;
        });
    };

    return (
        <IconButton
            size='small'
            aria-label='expand'
            onClick={handleClick}
            className={classes.iconButton}
            {...props}
        >
            {_isExpanded
                ? <ExpandLessIcon style={{fontSize: '16px'}} />
                : <ExpandMoreIcon style={{fontSize: '16px'}} />
            }

        </IconButton>
    );
};

export default ExpandButton;
