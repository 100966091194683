import React, {useCallback, useLayoutEffect, useRef, useState} from 'react';
import {
  ChevronLeft as ScrollLeftIcon,
  ChevronRight as ScrollRightIcon,
  ExpandLess as ScrollUpIcon,
  ExpandMore as ScrollDownIcon
} from '@mui/icons-material';
import {isEqual} from 'lodash';
import classNames from 'classnames';
import {HoopsPropTypes} from '../utils';
import {registerGlobalStyle} from '../../theme';
import {useTimer} from '../../hooks';

registerGlobalStyle('.scroller', (theme) => ({
  display: 'flex',
  flexShrink: 1,
  flexGrow: 1,
  minWidth: 0,
  minHeight: 0,
  flexDirection: 'column',
  alignItems: 'stretch',
  '&.vertical': {flexDirection: 'row',},

  'svg': {
    cursor: 'pointer',
    '&[disabled]': {opacity: .5,}
  },
  '.scroller-container': {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    flexShrink: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  '&.vertical>.scroller-container': {flexDirection: 'column'},

  '.scroller-viewport': {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    flexShrink: 1,
    overflow: 'hidden',
  },
  '&.vertical .scroller-viewport': {flexDirection: 'column'},

  '.scroller-content': {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    transition: 'left .15s ease-in-out, top .15s ease-in-out',
    '&>*': {
      flexGrow: 0,
      flexShrink: 0,
    }
  },
  '&.vertical .scroller-content': {flexDirection: 'column'},
  '&.multi .scroller-content': {
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  '&.multi.vertical .scroller-content': {flexDirection: 'row',},

  '.scroller-index': {
    display: 'grid',
    padding: 'calc(.75em + 2px) 0',
    '&>div': {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      width: 'calc(1.5em + 4px)',
      '&::before': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: 'calc(-.75em - 2px)',
        borderRadius: 'calc(.75em + 2px)',
        width: '100%',
        height: 'calc(100% + 1.5em + 4px)',
        backgroundColor: theme.colors.transparent,
        transition: 'background-color .2s linear',
      },
      '&.visible::before': {
        backgroundColor: theme.colors.background.contrast.medium,
        zIndex: 1,
      },
      '&>div': {
        width: '1.5em',
        height: '1.5em',
        position: 'relative',
        left: 2,
        textAlign: 'center',
        zIndex: 2,
        cursor: 'pointer',
        '&::before': {
          content: '""',
          position: 'absolute',
          left: 0,
          top: -1,
          width: '1.5em',
          height: '1.5em',
          backgroundColor: theme.colors.background.contrast.medium,
          borderRadius: '50%',
          zIndex: -1,
          transition: 'background-color .2s linear',
        },
      },
      '&.visible>div::before': {backgroundColor: theme.colors.background.white}
    }
  }
}));

export function Scroller({className, vertical, multi, showIndex, children}) {
  const [sizes, setSizes] = useState({viewport: {width: 0, height: 0}, content: {width: 0, height: 0}});
  const [positions, setPositions] = useState([{pos: 0, size: 0}]);
  const [notch, setNotch] = useState(0);
  const viewportRef = useRef();
  const contentRef = useRef();

  const maxNotch = positions.findLastIndex((position) => {
    if (!vertical && sizes.content.width + position.pos + position.size - sizes.viewport.width >= 0) {
      return true;
    }
    if (vertical && sizes.content.height + position.pos + position.size - sizes.viewport.height >= 0) {
      return true;
    }
    return false;
  });

  const scroll = useCallback((direction) => {
    setNotch((prevNotch) => {
      const newNotch = prevNotch + direction;
      // Ensure the new position index is within bounds
      if (newNotch >= 0 && newNotch < positions.length && newNotch !== prevNotch && newNotch <= maxNotch) {
        return newNotch;
      }
      return prevNotch;
    });
  }, [positions.length, maxNotch]);

  const {startTimer, stopTimer} = useTimer(120, scroll, 0);

  useLayoutEffect(() => {
    const viewportRect = viewportRef.current?.getBoundingClientRect();
    const contentRect = contentRef.current?.getBoundingClientRect();

    setSizes((prevSizes) => {
      if (prevSizes.viewport.width !== viewportRect.width || prevSizes.viewport.height !== viewportRect.height
        || prevSizes.content.width !== contentRect.width || prevSizes.content.height !== contentRect.height) {
        return {
          viewport: {width: viewportRect.width, height: viewportRect.height},
          content: {width: contentRect.width, height: contentRect.height},
        };
      }
      return prevSizes;
    });
  }, [children]);

  useLayoutEffect(() => {
    const newPositions = [];
    let lastPos;
    let lastLetter;
    children.forEach((child, index) => {
      const elem = contentRef.current?.children[index];
      const pos = vertical ? {pos: -elem.offsetTop, size: elem.offsetHeight} : {pos: -elem.offsetLeft, size: elem.offsetWidth};
      if (pos.pos !== lastPos) {
        lastPos = pos.pos;
        newPositions.push(pos);

        if (showIndex) {
          const letter = (typeof child.key === 'string' ? child.key : child.value)?.substring(0, 1);
          if (letter !== lastLetter) {
            lastLetter = letter;
            pos.letter = letter;
          }
        }
      }
    });
    setPositions((prevPositions) => isEqual(prevPositions, newPositions) ? prevPositions : newPositions);
  }, [children, showIndex, vertical]);

  const handleLeftClick = useCallback(() => {
    startTimer(-1);
  }, [startTimer]);

  const handleRightClick = useCallback(() => {
    startTimer(1);
  }, [startTimer]);

  const handleMouseUp = useCallback(() => {
    stopTimer();
  }, [stopTimer]);

  const offsetX = vertical ? 0 : positions[notch]?.pos ?? 0;
  const offsetY = vertical ? positions[notch]?.pos ?? 0 : 0;

  return (
    <div className={classNames([className, 'scroller', vertical && 'vertical', multi && 'multi'])}>
      {vertical && showIndex &&
        <div className={'scroller-index'}>
          {positions.map(({pos, letter}, index) => (letter &&
            <div
              key={letter}
              className={offsetY - pos >= 0 && offsetY - pos < sizes.viewport.height ? 'visible' : 'hidden'}
              onClick={() => setNotch(index)}
            >
              <div>
                {letter}
              </div>
            </div>
          ))}
        </div>
      }
      <div className={'scroller-container'}>
        {!vertical && sizes.viewport.width < sizes.content.width &&
          <ScrollLeftIcon disabled={notch === 0} onMouseDown={handleLeftClick} onMouseUp={handleMouseUp}/>
        }
        {vertical && sizes.viewport.height < sizes.content.height &&
          <ScrollUpIcon disabled={notch === 0} onMouseDown={handleLeftClick} onMouseUp={handleMouseUp}/>
        }
        <div className='scroller-viewport' ref={viewportRef}>
          <div className={'scroller-content'} ref={contentRef} style={{left: offsetX, top: offsetY}}>
            {children}
          </div>
        </div>
        {!vertical && sizes.viewport.width < sizes.content.width &&
          <ScrollRightIcon disabled={notch >= maxNotch} onMouseDown={handleRightClick} onMouseUp={handleMouseUp}/>
        }
        {vertical && sizes.viewport.height < sizes.content.height &&
          <ScrollDownIcon disabled={notch >= maxNotch} onMouseDown={handleRightClick} onMouseUp={handleMouseUp}/>
        }
      </div>
    </div>
  );
}

Scroller.propTypes = {
  className: HoopsPropTypes.className,
  vertical: HoopsPropTypes.bool,
  multi: HoopsPropTypes.bool,
  showIndex: HoopsPropTypes.bool,
  children: HoopsPropTypes.children,
};
