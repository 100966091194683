import React, {useCallback} from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import {Checkbox} from '@mui/material';
import {useGridApiContext} from '@mui/x-data-grid-pro/hooks/utils/useGridApiContext';
import {useGridSelector} from '@mui/x-data-grid/hooks/utils/useGridSelector';
import {gridFilterModelSelector} from '@mui/x-data-grid/hooks/features/filter/gridFilterSelector';

export function VoidedJobsFilter ({applyFilterChanges, deleteFilter}: any) {
  const apiRef = useGridApiContext();
  const filterModel = useGridSelector(apiRef, gridFilterModelSelector);

  const voided = React.useMemo(() => (filterModel.items || []).find(({columnField}) => columnField === 'voided'), [filterModel.items]);

  const handleChange = useCallback(() => {
    const filter = {id: 'voided', columnField: 'voided', operatorValue: 'voided', value: !voided?.value};
    if (filter.value) {
      applyFilterChanges(filter);
    } else {
      deleteFilter(voided);
    }
  }, [voided, applyFilterChanges, deleteFilter]);

  return <FormGroup>
    <FormControlLabel
      control={<Checkbox checked={!!voided?.value}/>} label='Show Voided Jobs'
      onChange={handleChange}
    />
  </FormGroup>;
}
