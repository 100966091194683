import {Grid} from '@material-ui/core';
import React, {useEffect, useState} from 'react';

export const HoopsDataFilterBar = ({
    filter = {},
    filters = [],
    onFilter = () => null
}) => {
    const [_filter, setFilter] = useState({});

    const handleOnChange = (filterName, filterValue) => {

        setFilter((prev) => {
            const result = {...prev};

            if (typeof filterValue === 'object') {
                filterValue.length
                    ? result[filterName] = {in: filterValue}
                    : delete result[filterName];
            }

            if (typeof filterValue === 'string') {
                filterValue
                    ? result[filterName] = filterValue
                    : delete result[filterName];
            }

            onFilter(result);
            return result;
        });
    };

    const getValue = ({name, type}) => {
        switch (type) {
            case 'string':
                return _filter[name] ? _filter[name] : '';

            default:
            case 'array':
                return _filter[name] ? _filter[name].in : [];
        }
    };

    const Filter = ({filterItem}) => (
            <filterItem.component
                label={filterItem.label}
                options={filterItem.options}
                onChange={(values) => handleOnChange(filterItem.name, values)}
                value={getValue(filterItem)}
            />
        );

    useEffect(() => {
        setFilter(filter);
    }, [filter]);

    return (
        <Grid container spacing={1}>
            {filters.map(
                (filterItem, idx) => <Grid item key={idx}>
                    <Filter filterItem={filterItem} />
                </Grid>
            )}

        </Grid>);
};

export default HoopsDataFilterBar;
