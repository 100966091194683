import {Button, Grid, MuiThemeProvider} from '@material-ui/core';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import React from 'react';
import {useDispatch} from 'react-redux';
import {editNewTask} from '../../../actions/tasks';
import TaskManyTable from '../../../modules/task/List/TaskManyTable';
import {colors} from '../../../ui/theme';
import green from '../../../ui/theme/green';
import CardContainer from '../../common/CardContainer';

function AddTaskButton({onClick}) {
  return (
    <>
      <MuiThemeProvider theme={green}>
        <Button
          onClick={onClick}
          variant={'contained'}
          color={'primary'}
        >
          Add Task
        </Button>
      </MuiThemeProvider>
    </>
  );
}

export const QuoteTasks = ({quote}) => {
  const dispatch = useDispatch();

  const handleAddTask = () => {
    dispatch(editNewTask({
      quoteIds: [quote._id],
      quotes: [quote]
    }));
  };

  return (
    <CardContainer
      title={'Tasks'}
      icon={<AssignmentTurnedInIcon />}
      iconBackgroundColor={colors.purple}
      buttonComponent={<AddTaskButton onClick={handleAddTask} />}
    >
      <Grid container>
        <Grid item xs={12} alignContent='center' justifyContent='center'>
          <TaskManyTable
            filterToMe={false}
            queryStateId={'quoteTaskMany' + quote._id}
            fixedFilter={{quoteIds: [quote._id]}}
          />
        </Grid>
      </Grid>
    </CardContainer>

  );
};

export default QuoteTasks;
