// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {Button, Grid, makeStyles, MenuItem, Paper} from '@material-ui/core';
import RateReviewIcon from '@material-ui/icons/RateReview';
import {ToggleButtonGroup} from '@material-ui/lab';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {getResources} from '../../actions/resource';
import {editNewSchedule, getSchedule, setModal, updateSchedule} from '../../actions/schedules';
import {editNewTask, getTask, setModal as setTaskModal, updateTask} from '../../actions/tasks';
import HoopsSelectField from '../../componentsOld/HoopsSelectField';
import EntityListHero from '../../componentsOld/EntityList/EntityListHero';
import HoopsToggleButton from '../../componentsOld/shared/HoopsToggleButton';
import ScheduleService from '../../servicesOld/ScheduleService';
import Navigator from './Navigator/Navigator';
import {ScheduleJobList} from './ScheduleJobList';
import Scheduler from './Scheduler/Scheduler';

const START_TIMES = [
  '12 AM', '1 AM', '2 AM', '3 AM', '4 AM', '5 AM', '6 AM', '7 AM', '8 AM', '9 AM', '10 AM', '11 AM',
  '12 PM', '1 PM', '2 PM', '3 PM', '4 PM', '5 PM', '6 PM', '7 PM', '8 PM', '9 PM', '10 PM', '11 PM',
];
const END_TIMES = [
  '1 AM', '2 AM', '3 AM', '4 AM', '5 AM', '6 AM', '7 AM', '8 AM', '9 AM', '10 AM', '11 AM',
  '12 PM', '1 PM', '2 PM', '3 PM', '4 PM', '5 PM', '6 PM', '7 PM', '8 PM', '9 PM', '10 PM', '11 PM',
  '12 AM'
];

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220
  },
  selectEmpty: {marginTop: theme.spacing(2)},
  menuPaper: {maxHeight: 300}
}));

export const ScheduleDashboard = () => {
  let initStartOfDay = localStorage.getItem('Schedules.StartOfDay');
  if (initStartOfDay === undefined || initStartOfDay === null) {
    initStartOfDay = 6;
  }
  let initEndOfDay = localStorage.getItem('Schedules.EndOfDay');
  if (initEndOfDay === undefined || initEndOfDay === null) {
    initEndOfDay = 18;
  }
  let initLengthOfWeek = localStorage.getItem('Schedules.LengthOfWeek');
  if (initLengthOfWeek == null) {
    initLengthOfWeek = '5dayweek';
  }

  const [schedulerStartDate, setSchedulerStartDate] = useState(new Date().toISOString());
  const [navigatorStartDate, setNavigatorStartDate] = useState(null);
  const [scheduleStatuses, setScheduleStatuses] = useState([]);
  const [taskStatuses, setTaskStatuses] = useState([]);
  const [view, setView] = useState('day');
  const [week, setWeek] = useState(initLengthOfWeek);

  const resourcesStateSelector = (state) => state.resourceReducer;
  const resourcesState = useSelector(resourcesStateSelector);

  const companySelector = (state) => state.companyReducer.company;
  const company = useSelector(companySelector);
  const dispatch = useDispatch();
  const [schedulerConfig, setSchedulerConfig] = useState({
    businessBeginsHour: +initStartOfDay,
    businessEndsHour: +initEndOfDay,
    businessWeekends: initLengthOfWeek === '7dayweek',
  });

  const classes = useStyles();

  const handleNavigate = (args) => {
    switch (args.selectMode) {
      case 'week':
        setSchedulerConfig((old) => ({
          ...old,
          cellDuration: 60,
          days: 30,
          timeHeaders: [{'groupBy': 'Day'}, {'groupBy': 'Hour'}]
        }));
        break;

      default:
      case 'day':
        setSchedulerConfig((old) => ({
          ...old,
          cellDuration: 15,
          days: 30,
          timeHeaders: [{'groupBy': 'Day'}, {'groupBy': 'Hour'}],
        }));
        break;
    }

    setSchedulerStartDate(args.start);
  };

  const handleScroll = ({startDate}) => {
    setNavigatorStartDate(startDate);
  };

  const handleItemClick = ({entityType, entityData}) => {
    switch (entityType) {
      case 'task':
        dispatch({type: 'SET_TASK', payload: entityData}); //pre set schedule then load detailed)
        dispatch(getTask(entityData._id));
        dispatch(setTaskModal(true));
        break;
      case 'schedule':
        dispatch({type: 'SET_SCHEDULE', payload: entityData}); //pre set schedule then load detailed)
        dispatch(getSchedule(entityData._id));
        dispatch(setModal('preview'));
        break;
      default:
        dispatch({type: 'SET_TASK', payload: entityData}); //pre set schedule then load detailed)
        dispatch(getTask(entityData._id));
        dispatch(setTaskModal(true));
        break;
    }
  };

  const handleItemMoved = ({entityType, entityData}) => {
    const fn = entityType === 'schedule'
      ? updateSchedule(entityData._id, entityData)
      : updateTask(entityData._id, entityData);
    dispatch(fn);

  };

  const handleTimeRangeClicked = (partialTask) => {
    dispatch(editNewTask(partialTask));
  };

  const handleJobDrop = (schedule) => {
    // I'm not a fan of this approach but time wise it is acceptable to get
    // all the data we need
    ScheduleService.getJob(schedule.jobId)
      .then((job) => {
        const p = {...schedule, job};
        dispatch(editNewSchedule(p));
      });
  };

  const handleViewChange = (event, newValue) => {
    setView(newValue);
  };

  const handleWeekChange = (event, newValue) => {
    setWeek(newValue);
    setSchedulerConfig((old) => {
      localStorage.setItem('Schedules.LengthOfWeek', newValue);
      return {...old, businessWeekends: newValue === '7dayweek'};
    });
  };

  const handleStartOfDay = (value) => {
    setSchedulerConfig((old) => {
      value = Math.min(old.businessEndsHour - 1, value);
      localStorage.setItem('Schedules.StartOfDay', value);
      return {...old, businessBeginsHour: value};
    });
  };

  const handleEndOfDay = (value) => {
    setSchedulerConfig((old) => {
      value = Math.max(old.businessBeginsHour + 1, value);
      localStorage.setItem('Schedules.EndOfDay', value);
      return {...old, businessEndsHour: value};
    });
  };

  useEffect(() => {
    dispatch(getResources());
  }, [dispatch]);

  useEffect(() => {
    if (company.statuses) {
      const scheduleStatusGroup = company.statuses.filter((scheduleStatusGroup) => scheduleStatusGroup.entityType === 'schedule' && scheduleStatusGroup.entityMapping === 'status')[0];
      if (scheduleStatusGroup) {
        setScheduleStatuses(
          scheduleStatusGroup.statuses
        );
      }

      const taskStatusGroup = company.statuses.filter((taskStatusGroup) => taskStatusGroup.entityType === 'task' && taskStatusGroup.entityMapping === 'status')[0];
      if (taskStatusGroup) {
        setTaskStatuses(
          taskStatusGroup.statuses
        );
      }

    }
  }, [company]);

  return (
    <Grid container spacing={1}>
      <EntityListHero
        title='Schedule'
        subtitle='Easily schedule &amp; track your production progress. '
        helpArticleUrl='http://help.hoopscrm.com/en/articles/4630549-adding-jobs-to-your-schedule'
        videoId='kJ3Mn7EK-CE'
      >
        <Button
          href='https://share.hsforms.com/16m1yx4tDSPCs48H6hqWyfA3yuob'
          target='_blank'
          color='primary'
          endIcon={<RateReviewIcon />}
        >
          GIVE US YOUR FEEDBACK
        </Button>
      </EntityListHero>

      {/* jobs and navigator */}
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs style={{minWidth: 'calc(100% - 450px)'}}>
            <Paper style={{height: '240px', overflow: 'hidden'}}>
              <ScheduleJobList />
            </Paper>
          </Grid>
          <Grid item xs style={{width: '450px'}}>
            <Navigator onNavigate={handleNavigate} startDate={navigatorStartDate} selectMode={view} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container direction='row' justifyContent='flex-end' alignItems='flex-end' spacing={1}>
          <Grid item>
            <ToggleButtonGroup
              value={week}
              size='small'
              exclusive
              onChange={handleWeekChange}
              aria-label='Week'
            >
              <HoopsToggleButton value={'5dayweek'} aria-label='Weekdays'>
                Weekdays
              </HoopsToggleButton>
              <HoopsToggleButton value={'7dayweek'} aria-label='Weekends'>
                7 Day week
              </HoopsToggleButton>
            </ToggleButtonGroup>
          </Grid>

          <Grid item>
            <HoopsSelectField
              label={'Start of Day'}
              MenuProps={{classes: {paper: classes.menuPaper}}}
              onChange={(e) => handleStartOfDay(e.target.value)}
              value={schedulerConfig.businessBeginsHour}
            >
              {START_TIMES.map((t, i) => (
                <MenuItem key={i} value={i}>{t}</MenuItem>
              ))}
            </HoopsSelectField>
          </Grid>
          <Grid item>
            <HoopsSelectField
              label={'End of Day'}
              MenuProps={{classes: {paper: classes.menuPaper}}}
              onChange={(e) => handleEndOfDay(e.target.value)}
              value={schedulerConfig.businessEndsHour}
            >
              {END_TIMES.map((t, i) => (
                <MenuItem key={i} value={i + 1}>{t}</MenuItem>
              ))}
            </HoopsSelectField>
          </Grid>
          <Grid item>
            <ToggleButtonGroup
              value={view}
              size='small'
              exclusive
              onChange={handleViewChange}
              aria-label='View'
            >
              <HoopsToggleButton value={'day'} aria-label='Day'>
                Day
              </HoopsToggleButton>
              <HoopsToggleButton value={'week'} aria-label='Week'>
                Week
              </HoopsToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs>
            <Paper>
              <Scheduler
                config={schedulerConfig}
                startDate={schedulerStartDate}
                onScroll={handleScroll}
                resources={resourcesState.list}
                scheduleStatuses={scheduleStatuses}
                taskStatuses={taskStatuses}
                onItemClick={handleItemClick}
                onItemMoved={handleItemMoved}
                onTimeRangeClicked={handleTimeRangeClicked}
                onJobDrop={handleJobDrop}
              />
            </Paper>
            <Button color='primary' component={Link} to={'/settings/resources'}>Manage Resources</Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid >
  );
};
export default ScheduleDashboard;
