import {TableHead, TableRow} from '@material-ui/core';
import React from 'react';
import {HoopsTableCell, isSelectable, useHoopsJobTableStyles} from '../HoopsTable';

/**
 * Single Job Item Table
 * 
 */
export const JobItemTableVariantHeader = ({mode = 'checkbox'}) => {
  const classes = useHoopsJobTableStyles();

  return (
    <TableHead>
    <TableRow>
      {isSelectable(mode) ? <HoopsTableCell /> : null}
      <HoopsTableCell className={classes.productCodeColumn}>
        Code
      </HoopsTableCell>
      <HoopsTableCell className={classes.productVariantNameColumn}>
        Product Name
      </HoopsTableCell>
      <HoopsTableCell className={classes.vendorNameColumn}>
        Vendor
      </HoopsTableCell>
      <HoopsTableCell className={classes.colorColumn}>
        Color
      </HoopsTableCell>
      <HoopsTableCell className={classes.sizeColumn}>
        Size
      </HoopsTableCell>
      <HoopsTableCell className={classes.quantityColumn}>
        Quantity
      </HoopsTableCell>
      <HoopsTableCell>
        Status
      </HoopsTableCell>
    </TableRow>
    </TableHead>
  );
};

export default JobItemTableVariantHeader;
