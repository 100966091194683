import RegistrationService from '../../servicesOld/RegistrationService';
import {login} from './auth';
import {snackError, snackSuccess} from './snackbar';

export const REGISTER_LOADING = 'REGISTER_LOADING';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';

export function register(payload) {
  return function (dispatch) {
    dispatch({type: REGISTER_LOADING});
    const {username, password} = payload;
    RegistrationService.register(payload)
      .then(({data}) => {
        dispatch({
          type: REGISTER_SUCCESS,
          payload: data
        });
        dispatch(snackSuccess('Welcome to Hoops!'));
        dispatch(login(username, password));
      })
      .catch((err) => {
        dispatch({
          type: REGISTER_ERROR,
          payload: err.message
        });
        dispatch(snackError('Something went wrong - please try again.'));
      });
  };
}
