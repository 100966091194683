// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-shadow */

import {Button, Card, Fade, Grid, makeStyles, Paper, Typography} from '@material-ui/core';
import {find, pick, uniqBy} from 'lodash';
import React, {useContext, useEffect, useState} from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import hoopsQueryService from '../../servicesOld/hoopsQueryService';
import theme from '../../ui/theme';
import {columnWidth, kanban} from '../../ui/theme/kanban';
import HoopsCapitalizeText from '../shared/HoopsCapitalizeText';
import HoopsQueryContext from './HoopsQueryContext';

const useStyles = makeStyles((theme) => (
    {...kanban,}
));

export const HoopsQueryKanbanBoard = ({
    columns = [],
    statusType = null,
    itemsPerColumn = 20,
    itemsShowMore = 5,
    onChange = () => null,
    cardContent = () => null,
}) => {
    const classes = useStyles();
    const [draggingData, setDraggingData] = useState(null);
    const [draggableItems, setDraggableItems] = useState(null);
    const {gqlQuery, sort, filter} = useContext(HoopsQueryContext);
    const [columnData, setColumnData] = useState(columns);

    // control the length of each column by using this array
    const [columnLengthArray, setColumnLengthArray] = useState([]);

    // useEffect will load cards
    useEffect(() => {
        const loadColumnData = (loadNumber, columnIndex) => {
            let columnArray = [];
            let pageNumber = 1;

            if (!loadNumber) {
                loadNumber = columnLengthArray[columnIndex];
            } else {
                pageNumber = (columnLengthArray[columnIndex] / loadNumber) + 1;
                handleColumnLength(columnIndex);
            }
            hoopsQueryService.kanbanPagination(
                gqlQuery,
                getFilter(statusType, columns[columnIndex].value),
                sort,
                loadNumber,
                pageNumber
            ).then((data) => {

                let dataItems = [];
                for (const [key] of Object.entries(data)) {
                    dataItems = data[key].items;

                    setColumnData((prev) => {
                        let updateArray = [...prev];
                        updateArray[columnIndex].pageInfo = data[key].pageInfo;
                        return updateArray;
                    });
                }

                columnArray = [...columnArray, ...dataItems];
                setDraggableItems((prev) => {
                    if (!prev) {return [...columnArray];}
                    const newArray = uniqBy([...prev, ...columnArray], '_id');
                    return newArray;
                });
            });
        };

        setDraggableItems(null);
        setColumnData(columns);
        setColumnLengthArray(Array(columns.length).fill(itemsPerColumn));
        columns.forEach((column, columnIndex) => loadColumnData(null, columnIndex));
      // TODO: FIX HOOK DEPENDENCIES
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, statusType]);

    const loadColumnData = (loadNumber, columnIndex) => {
        let columnArray = [];
        let pageNumber = 1;

        if (!loadNumber) {
            loadNumber = columnLengthArray[columnIndex];
        } else {
            pageNumber = (columnLengthArray[columnIndex] / loadNumber) + 1;
            handleColumnLength(columnIndex);
        }
        hoopsQueryService.kanbanPagination(
            gqlQuery,
            getFilter(statusType, columns[columnIndex].value),
            sort,
            loadNumber,
            pageNumber
        ).then((data) => {

            let dataItems = [];
            for (const [key] of Object.entries(data)) {
                dataItems = data[key].items;

                setColumnData((prev) => {
                    let updateArray = [...prev];
                    updateArray[columnIndex].pageInfo = data[key].pageInfo;
                    return updateArray;
                });
            }

            columnArray = [...columnArray, ...dataItems];
            setDraggableItems((prev) => {
                if (!prev) {return [...columnArray];}
                const newArray = uniqBy([...prev, ...columnArray], '_id');
                return newArray;
            });
        });
    };

    const getFilter = (statusType, columnValue) => {

        const textSearch = pick(filter, 'q');
        const operators = pick(filter, '_operators');

        const operatorsFilters = operators._operators && operators._operators.departmentalStatuses;

        let filterObj = {};
        switch (statusType) {
            case 'accountStatus':
                filterObj = {_operators: {departmentalStatuses: {...operatorsFilters, accountStatus: {in: [columnValue]}}}, ...textSearch};
                break;
            case 'purchasingStatus':
                filterObj = {_operators: {departmentalStatuses: {...operatorsFilters, purchasingStatus: {in: [columnValue]}}}, ...textSearch};
                break;
            case 'artworkStatus':
                filterObj = {_operators: {departmentalStatuses: {...operatorsFilters, artworkStatus: {in: [columnValue]}}}, ...textSearch};
                break;
            case 'productionStatus':
                filterObj = {_operators: {departmentalStatuses: {...operatorsFilters, productionStatus: {in: [columnValue]}}}, ...textSearch};
                break;
            case 'shippingStatus':
                filterObj = {_operators: {departmentalStatuses: {...operatorsFilters, shippingStatus: {in: [columnValue]}}}, ...textSearch};
                break;
            case null:
                filterObj = {_operators: {...operators._operators, status: {in: [columnValue]}}, ...textSearch};
                break;
            default:
                filterObj = {_operators: {departmentalStatuses: {...operatorsFilters, accountStatus: {in: [columnValue]}}}, ...textSearch};
                break;
        }
        return filterObj;
    };

    const handleColumnLength = (columnIndex) => {
        setColumnLengthArray((currentArray) => {
            currentArray[columnIndex] += itemsShowMore;
            return ([...currentArray]);
        });
    };

    const onDragStart = (result) => {
        // Shows the drop space for columns aside from the column the item came from
        setDraggingData(result);
    };

    const onDragEnd = (result) => {
        const {destination, draggableId} = result;

        // clearing this will hide the item drop space
        setDraggingData(null);

        // dropped outside the list
        if (!destination) {return;}

        //Fix the destination to land in the correct drop space
        const newDestination = destination.droppableId.replace('_Virtual', '');

        const itemOrigin = result.source.droppableId;

        let updatedItem = find(draggableItems && draggableItems, {'_id': draggableId});
        switch (statusType) {
            case 'accountStatus':
                updatedItem = {...updatedItem, departmentalStatuses: {accountStatus: newDestination}};
                break;
            case 'purchasingStatus':
                updatedItem = {...updatedItem, departmentalStatuses: {purchasingStatus: newDestination}};
                break;
            case 'artworkStatus':
                updatedItem = {...updatedItem, departmentalStatuses: {artworkStatus: newDestination}};
                break;
            case 'productionStatus':
                updatedItem = {...updatedItem, departmentalStatuses: {productionStatus: newDestination}};
                break;
            case 'shippingStatus':
                updatedItem = {...updatedItem, departmentalStatuses: {shippingStatus: newDestination}};
                break;
            case null:
                updatedItem = {...updatedItem, status: newDestination};
                break;
            default:
                updatedItem = {...updatedItem, departmentalStatuses: {accountStatus: newDestination}};
                break;
        }

        const newData = draggableItems?.filter((i) => i._id !== draggableId);
        setDraggableItems([updatedItem, ...newData]);

        // update column item count
        setColumnData((prev) => {
            let updateArray = [...prev];
            // index of the source column
            const originColumnIndex = columnData.findIndex((c) => c.value === itemOrigin);
            updateArray[originColumnIndex].pageInfo.itemCount--;
            // index of the destination column
            const destinationColumnIndex = columnData.findIndex((c) => c.value === newDestination);
            updateArray[destinationColumnIndex].pageInfo.itemCount++;
            return updateArray;
        });

        onChange({...updatedItem, departmentalStatuses: updatedItem.departmentalStatuses});

    };

    const dropZoneStyling = (draggingData, column) => {
        // don't show unless card is being dragged
        if (!draggingData) {return ({display: 'none'});}
        // don't show a blue dropzone for the cards origin column
        if (draggingData.source.droppableId === column.value) {return ({opacity: 100, display: 'block', height: 150});}
        return ({opacity: 100, display: 'block', height: 150,});
    };

    const filterDraggableItems = (item, columnValue) => {
        if (statusType && columnValue && (item.departmentalStatuses[statusType] === columnValue)) {return true;}
        if (!statusType && columnValue && (item.status === columnValue)) {return true;}
        return false;
    };

    return (
        <Fade in={true} timeout={500}>
            <Paper
                elevation={0}
                style={{
                    width: '100%',
                    overflowX: 'auto',
                    overflowY: 'hidden',
                    background: 'none',
                    height: 'fit-content(20em)',
                    paddingBottom: theme.spacing(2)

                }}
            >
                <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                    <Grid
                        container
                        direction='row'
                        justifyContent='flex-start'
                        alignItems='flex-start'
                        spacing={1}
                        style={{
                            width: columns.length * (8 + columnWidth),
                            display: 'grid',
                            gridTemplateColumns: 'auto auto auto auto',
                            gridAutoFlow: 'column'
                        }}
                    >
                        {columns && columns.map((column, columnIndex) => (
                                <Grid item key={column.value} style={{height: '100%'}}>
                                    <Droppable droppableId={`${column.value}_Virtual`} ignoreContainerClipping>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                isDraggingOver={snapshot.isDraggingOver}
                                                {...provided.droppableProps}
                                                style={{height: '100%'}}
                                                className={snapshot.isDraggingOver ? classes.dragItemDropColumn : null}
                                            >
                                                {/* This is the colored bar that appears at the top of each column */}
                                                <div className={classes.columnColor} style={{backgroundColor: `${column.color}`}}></div>

                                                <div className={classes.columnDropSpace}>
                                                    <Typography variant='body1'><HoopsCapitalizeText text={column.label} />
                                                        {
                                                            columnData &&
                                                                columnData[columnIndex] &&
                                                                columnData[columnIndex].pageInfo &&
                                                                columnData[columnIndex].pageInfo.itemCount
                                                                ? ` (${columnData[columnIndex].pageInfo.itemCount})`
                                                                : ' (0)'
                                                        }
                                                    </Typography>
                                                    {/* This div is used to show the drop space in each column during a drag */}
                                                    <div
                                                        style={dropZoneStyling(draggingData, column)}
                                                        className={`${classes.dragItemDropSpace} ${snapshot.isDraggingOver ? classes.dragItemDropSpaceOver : null}`}
                                                    >
                                                        {provided.placeholder}
                                                    </div>

                                                </div>
                                                <Droppable droppableId={column.value} isDropDisabled={true}>
                                                    {(provided, snapshot) => (
                                                        <>
                                                            <Fade in={columnData && columnData[columnIndex] && columnData[columnIndex].pageInfo && columnData[columnIndex].pageInfo.itemCount > -1} timeout={400}>
                                                                <div ref={provided.innerRef} className={classes.column}>
                                                                    {draggableItems && draggableItems.filter((item) => filterDraggableItems(item, column.value)).map((item, itemIndex) => (
                                                                            <Draggable
                                                                                key={item._id}
                                                                                draggableId={item._id}
                                                                                index={itemIndex}
                                                                            >
                                                                                {(provided, snapshot) => (
                                                                                    <Fade in={true} timeout={600}>
                                                                                        <Card
                                                                                            elevation={snapshot.isDragging ? 3 : 0}
                                                                                            ref={provided.innerRef}
                                                                                            {...provided.draggableProps}
                                                                                            {...provided.dragHandleProps}
                                                                                            style={provided.draggableProps.style}
                                                                                            className={classes.item}
                                                                                        >
                                                                                            {cardContent(item)}
                                                                                        </Card>
                                                                                    </Fade>
                                                                                )
                                                                                }
                                                                            </Draggable>
                                                                        ))}
                                                                    {
                                                                        columnData && columnData[columnIndex] && columnData[columnIndex].pageInfo && columnData[columnIndex].pageInfo.hasNextPage &&
                                                                        <Button fullWidth className={classes.showMoreBtn} color='primary' onClick={() => loadColumnData(itemsShowMore, columnIndex)}>Show More</Button>
                                                                    }
                                                                </div>
                                                            </Fade>
                                                        </>
                                                    )}
                                                </Droppable>
                                            </div>
                                        )}
                                    </Droppable>

                                </Grid>))}
                    </Grid>
                </DragDropContext>
            </Paper>
        </Fade>
    );
};

export default HoopsQueryKanbanBoard;
