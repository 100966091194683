import React, {useCallback} from 'react';
import {CellContainer, useCellContainerState} from './CellContainer';
import {colors, registerGlobalStyle} from '../../../theme';
import {StatusLabel} from '../../Basic';
import {StatusPicker} from '../../Pickers';
import {Placement} from '../../Popovers';

registerGlobalStyle('.power-grid .cell-data-type-status.editable', () => ({
  '.cell-container': {
    paddingBlock: 1,
    '.status-picker': {
      position: 'absolute',
      width: '100%',
      opacity: 0,
      zIndex: -1,
      pointerEvents: 'none',
    },
  },
  '.status-label': {
    width: '100%',
    height: '100%',
  },
  '&.power-grid-cell .cell-container > div::before': {content: 'none',},
}));

export function StatusCell ({value, colDef, row}) {
  const {editing, startEditing, endEditing} = useCellContainerState();

  const handleChange = useCallback((newValue) => {
    endEditing();
    colDef.valueSetter({row, value: newValue});
  }, [colDef, endEditing, row]);

  const handleChangeOptions = useCallback((newOptions) => {
    colDef.setOptions(newOptions);
  }, [colDef]);

  const option = colDef.viewField.options?.find(({statusLabel}) => statusLabel === value);
  const text = option?.statusLabel ?? value ?? '';
  const color = option?.color ?? colors.palette.greyLightest;

  return (
    <CellContainer editing={editing} onClick={startEditing}>
      <StatusLabel asButton asButtonOpen={editing} color={color} statusLabel={text}/>
      {editing &&
        <StatusPicker
          initialOpen={true}
          placement={Placement.Bottom}
          value={value}
          onChange={handleChange}
          onClose={endEditing}
          options={colDef.viewField.options}
          onChangeOptions={handleChangeOptions}
        />
      }
    </CellContainer>
  );
}
