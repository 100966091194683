import {get} from 'lodash';
import React from 'react';
import {useSelector} from 'react-redux';
import StatusChipEdit from './StatusChipEdit';

export const StatusChanger = ({entityType, entityMapping, value, disabled = false, size = 'small', onChange = () => null, returnValue = false}) => {
    const statusGroupSelector = (state) => {
        if (!entityMapping || !entityType) {
            return {};
        }

        const group = get(state, 'companyReducer.company.statuses', []).filter((statusGroup) => statusGroup.entityType === entityType && statusGroup.entityMapping === entityMapping)[0];
        return group;

    };
    const statusGroup = useSelector(statusGroupSelector);

    const handleOnChange = (status) => {
        const result = returnValue ? status.value : status;
        onChange(result);
    };

    return (
        <StatusChipEdit onChange={handleOnChange} value={value} disabled={disabled} statusGroup={statusGroup} size={size} />
    );
};

export default StatusChanger;
