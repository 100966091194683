import {
  SET_JOBS,
  SET_JOB,
  CLEAR_JOB,
  SET_JOB_ITEM_VARIANTS,
  CLEAR_JOB_ITEM_VARIANTS,
  SET_JOBS_LOADING,
  SET_JOB_LOADING,
  UPSERT_JOBS,
  SET_EMAIL_TYPE
} from '../actions/jobs';
import {findIndex} from 'lodash';

export function upsertList(list, updatedObject, uniqueKey = '_id') {
  let index = findIndex(list, {[uniqueKey]: updatedObject[uniqueKey]});
  if (index > -1) {
    list[index] = updatedObject;
  } else {
    list.push(updatedObject);
  }
  return list;
}

const initialState = {
  activeJob: {
    customer: {},
    contact: {},
    jobOwnerId: null,
    jobOwner: {fullName: null},
    items: [{
      imageUrl: '',
      description: '',
      quantity: null,
      status: '',
      product: {}
    }, {
      imageUrl: '',
      description: '',
      quantity: null,
      status: '',
      product: {}
    }, {
      imageUrl: '',
      description: '',
      quantity: null,
      status: '',
      product: {}
    }],
    proofs: [
      {
        jobId: '',
        status: '',
        products: [],
        file: {
          filename: '',
          url: '',
          createdAt: '',
          createdBy: {fullName: ''}
        }
      }
    ],
    deadlineAt: null
  },
  activeJobVariants: {
    variants: [],
    decorations: [],
    additionalItems: [],
  },
  list: [],
  newJobForm: {
    title: '',
    contact: {},
    jobOwnerId: null,
    jobOwner: {fullName: null},
    customer: {
      addresses: [],
      settings: {}
    },
    items: [{
      imageUrl: '',
      description: '',
      quantity: null,
      status: '',
      product: {}
    }, {
      imageUrl: '',
      description: '',
      quantity: null,
      status: '',
      product: {}
    }, {
      imageUrl: '',
      description: '',
      quantity: null,
      status: '',
      product: {}
    }],
    deadlineAt: null,
  },
  emailType: '',
  jobsLoading: false,
  jobLoading: false,
};

const jobsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_JOBS:
      return {
        ...state,
        list: action.payload
      };

    /**
     * this ADDs or UPDATEs a job in the state
     */
    case UPSERT_JOBS: {
      let list = upsertList(state.list, action.payload);
      return {
        ...state,
        list
      };
    }

    case SET_JOB_ITEM_VARIANTS:
      return {
        ...state,
        activeJobVariants: action.payload
      };

    case CLEAR_JOB_ITEM_VARIANTS:
      return {
        ...state,
        activeJobVariants: initialState.activeJobVariants
      };

    case SET_JOB:
      return {
        ...state,
        activeJob: action.payload
      };

    case CLEAR_JOB:
      return {
        ...state,
        newJobForm: initialState.newJobForm,
        activeJob: initialState.activeJob
      };

    case SET_JOBS_LOADING:
      return {
        ...state,
        jobsLoading: action.payload
      };

    case SET_JOB_LOADING:
      return {
        ...state,
        jobLoading: action.payload
      };

    case SET_EMAIL_TYPE:
      return {
        ...state,
        emailType: action.payload,
      };

    default:
      return {...state};
  }
};

export default jobsReducer;
