import {gql} from 'apollo-boost';
import {ADDRESS_TO_FRAGMENT} from './addressToFragment';

export const SINGLE_JOB_ITEM_VARIANT_FRAGMENT = gql`
	fragment SingleJobItemVariant on JobItemVariant {
	_id
		quantity
		variants {
				_id
				productId
				productCode
				productName
				colour
				size
				quantity
				status
				buyPrice
				sellPrice
				vendor {
						_id
						name
				}
				vendorId
				productVariant {
						_id
						size {
								_id
								name
						}
						color {
								_id
								name
						}
				}
		}
		decorations {
				_id
				description
				decoration {
						name
				}
				decorationId
				position
				colour
				size
				buyPrice
				sellPrice
				quantity
				vendor {
						name
				}
		}
		additionalItems {
				description
				vendorId
				buyPrice
				sellPrice
				vendor {
						name
				}
		}
  }
`;

export const SINGLE_JOB_FRAGMENT = gql`
		${SINGLE_JOB_ITEM_VARIANT_FRAGMENT}
	fragment SingleJob on Job {
		_id
		archived
		linkComments
		number
		title
		description
		status
		deadlineAt
		jobOwnerId
		jobOwner {
			fullName
		}
		createdById
		createdBy {
			fullName
		}
		createdAt
		updatedAt
		companyId
		customReference
		quoteId
		contactId
		contact {
			_id
			fullName
			firstName
			lastName
			email
			phone
		}
		customerId
		customer {
			_id
			name
			email
			phone
			deletedAt
			addresses {
				_id
				address1
				address2
				city
				country
				formattedAddress
				label
				postcode
				state
			}
		}
		items {
			_id
			imageUrl
			description
			quantity
			status
			configuredProductId
			configuredProduct {
				_id
				code
				name
			}
			productId
			product {
				_id
				code
				title
				vendorId
				colors {
					_id
					name
					image {
						_id
						contentLength
						contentType
						bucket
						key
						filename
						url
					}
				}
				sizes {
					_id
					name
					description
				}
				variants {
					_id
					sku
					color {
						_id
						name
					}
					size {
						_id
						name
						description
					}
					priceBreaks {
						_id
						price
						quantity
					}
				}
				vendor {
					_id
					name
				}
				decorations {
					_id
					name
					vendorId
					vendor {
						_id
						name
						email
					}
				}
			}
			jobItemVariant {
							...SingleJobItemVariant
			}
		}
		departmentalStatuses {
			accountStatus
			purchasingStatus
			artworkStatus
			productionStatus
			shippingStatus
		}
		invoice {
			_id
			balance
			totalAmount
		}
		shipTo {
			${ADDRESS_TO_FRAGMENT}
		}
		billTo {
			${ADDRESS_TO_FRAGMENT}
		}
	}
`;

export const GQL_GET_JOBS = gql`
	query getJobs(
		$q: String
		$page: Int
		$pageLimit: Int
	) {
		jobs(q:$q, page:$page, pageLimit:$pageLimit) {
			page
			recordsFiltered
			recordsTotal
			data {
				_id
				archived
				number
				title
				company {
					companyTradingEntities {
        _id
						name
					}
				}
				customer {
					name
					deletedAt
				}
				contact {
					fullName
				}
				createdBy {
					fullName
				}
				deadlineAt
				createdAt
				status
				departmentalStatuses {
					accountStatus
					purchasingStatus
					artworkStatus
					productionStatus
					shippingStatus
				}
				quote {
					_id
					number
				}
				invoice {
					_id
					invoiceNumber
					balance
					totalAmount
					dueDate
					xeroCustomerId
					xeroInvoiceId
					qboInvoiceReference
					qboLastRequestStatus
					errorMessage
				}
			}
		}
	}
`;

export const GQL_CREATE_JOB = gql`
	mutation CreateJob($job: JobInput!) {
		createJob(job: $job) {
			_id
			title
			number
		}
	}`;

export const GQL_GET_JOB = gql`
	query getJob($_id: String!) {
		job(_id: $_id) {
			...SingleJob
		}
	}
	${SINGLE_JOB_FRAGMENT}
`;

export const GQL_UPDATE_JOB = gql`
	mutation updateJob($_id: String!, $job: JobInput!) {
		updateJob(_id: $_id, job: $job) {
			...SingleJob
		}
	}
	${SINGLE_JOB_FRAGMENT}
`;

export const GQL_UPDATE_JOB_ITEM = gql`
	mutation updateJobItem($_id: String!, $jobItem: JobItemInput!) {
		updateJobItem(_id: $_id, jobItem: $jobItem) {
			...SingleJob
		}
	}
	${SINGLE_JOB_FRAGMENT}
`;

export const GQL_DELETE_JOB = gql`
	mutation deleteJob($_id: String!) {
		deleteJob(_id: $_id) {
			_id
			number
		}
	}
`;

export const GQL_CREATE_JOB_ITEM_VARIANT = gql`
	mutation createJIV($variant: JobItemVariantInput!) {
		createJobItemVariant(variant: $variant) {
			_id
			quantity
		}
	}
`;

export const GQL_UPDATE_JOB_ITEM_VARIANT = gql`
	mutation updateJIV($_id: String!, $variant: JobItemVariantInput!) {
		updateJobItemVariant(_id: $_id, variant: $variant) {
			_id
			quantity
		}
	}
`;

export const GQL_GET_JOB_ITEM_VARIANT = gql`
	query getJIV($_id: String!) {
		jobItemVariant(_id: $_id) {
			_id
			quantity
			variants {
				_id
				productId
				productCode
				productName
				quantity
				vendorId
				status
				size
				colour
				buyPrice
				sellPrice
				vendor {
					_id
					name
				}
				productVariant {
					_id
					color {
						name
					}
					size {
						name
					}
				}
			}
			decorations {
				_id
				description
				decoration {
					_id
					name
				}
				decorationId
				position
				colour
				size
				vendor {
					_id
					name
				}
				vendorId
				buyPrice
				sellPrice
				setupPrice
				quantity
			}
			additionalItems {
				_id
				vendor {
					_id
					name
				}
				vendorId
				description
				buyPrice
				sellPrice
			}
		}
	}
`;

export const GQL_JOB_PAGINATION = gql`
	query jobPagination(
		$filter: FilterFindManySearchGCJobInput,
		$sort: SortFindManyGCJobInput,
		$perPage: Int,
		$page: Int
	){
		jobPagination(
			filter: $filter,
			sort: $sort,
			perPage: $perPage,
			page: $page
		) {
			items {
				_id
				archived
				number
				quote {
					_id
					number
				}
				title
				customer {
					name
					deletedAt
				}
				contact {
					fullName
				}
				createdBy {
					fullName
				}
				createdAt
				deadlineAt
				customReference
				departmentalStatuses {
					accountStatus
					purchasingStatus
					artworkStatus
					productionStatus
					shippingStatus
				}
				quote{
					_id
					number
					stripeInvoiceId
					items {
						_id
						quantity
					}
					invoice {
						_id
						invoiceNumber
						balance
						totalAmount
						dueDate
						xeroInvoiceId
						qboInvoiceReference
						qboLastRequestStatus
						errorMessage
					}
				}
				items {
					_id
					quantity
				}
			}
			pageInfo {
				currentPage
				perPage
				pageCount
				itemCount
				hasNextPage
				hasPreviousPage
			}
		}
	}
`;

export const GQL_EMAIL_JOB = gql`
	mutation emailJob($_id: String!, $emailOptions: EmailOptionsInput, $smsOptions: SMSOptions) {
		emailJob(_id: $_id, emailOptions: $emailOptions, smsOptions: $smsOptions) {
			emailStatus,
			message
		}
	}
`;

