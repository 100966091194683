import {CircularProgress, Grid, Typography} from '@material-ui/core';
import theme from '../../../ui/theme/index';
import React, {useEffect} from 'react';
import {gql, useQuery} from '@apollo/client';
import {ButtonDisconnectStripe} from './ButtonDisconnectStripe';
import {ButtonConnectStripe} from './ButtonConnectStripe';
import {ErrorMessage} from '../../../componentsOld/ErrorMessage';
import {StripeInactiveReason} from './StripeInactiveReason';
import {capitalize} from '../../../utils/capitalize';
import OpenInNew from '@material-ui/icons/OpenInNew';

export const GET_INTEGRATION = gql`
    query integrationOne{
        integrationOne(filter:{type:stripe}){
            _id
            status
        }
    }
`;

export function StripeButton() {
  const {data, loading, error, refetch, stopPolling, startPolling} = useQuery(GET_INTEGRATION, {fetchPolicy: 'no-cache'});
  const exists = !!(data && data.integrationOne && data.integrationOne._id);
  const status = (data && data.integrationOne && data.integrationOne.status) || 'pending';

  useEffect(() => {
    if (!loading) {
      if (status === 'pending') {
        startPolling(10000);
      }
      if (status === 'active') {
        stopPolling();
      }
    }
  }, [loading, status, startPolling, stopPolling]);

  if (loading) {
    return <CircularProgress style={{color: theme.colors.grey.light, display: 'block'}} />;
  }
  return <div>
    <ErrorMessage onClick={() => refetch()} error={error} />

    {exists && <a href={'https://dashboard.stripe.com/login'} target={'_blank'} rel='noopener noreferrer' style={{textDecoration: 'none'}}>
      <Grid style={{display: 'flex', margin: 8}}>
        <Typography variant={'body2'} >Stripe Settings</Typography>
        <OpenInNew fontSize={'small'} style={{marginLeft: 8}} />
      </Grid>
    </a>}
    {!exists && <a href={'https://dashboard.stripe.com/register'} target={'_blank'} rel='noopener noreferrer' style={{textDecoration: 'none'}}>
      <Grid style={{display: 'flex', margin: 8}}>
        <Typography variant={'body2'} >Need a stripe account?</Typography>
        <OpenInNew fontSize={'small'} style={{marginLeft: 8}} />
      </Grid>
    </a>}

    {exists && status && <p>Status: <b>{capitalize(status)}</b> {status !== 'active' && <StripeInactiveReason refetch={refetch} />}</p>}
    <div style={{
      flexDirection: 'column',
      display: 'flex',
      gap: 10,
    }}>

      {!exists && <ButtonConnectStripe>CONNECT</ButtonConnectStripe>}

      {exists && <ButtonDisconnectStripe>DISCONNECT</ButtonDisconnectStripe>}
    </div>
  </div>;

}

