import React from 'react';
import {HoopsPropTypes} from '../utils';
import {registerGlobalStyle} from '../../theme';

registerGlobalStyle('.text', (theme) => ({
  margin: 0,
  fontFamily: theme.typography.fontFamily,
  fontWeight: theme.typography.normal,
  fontSize: '0.75rem',
  lineHeight: 1.66,
  color: theme.colors.text.main,
  '&.text-caption:has(+*)': {marginRight: theme.spacing(1),},
  '&.currency': {
    '&::before': {
      content: 'var(--currencyContent)',
      paddingRight: '.25em',
    }
  },
}));

export function Text({text, children}) {
  return (
    <div className={'text'}>{text ?? children}</div>
  );
}

Text.propTypes = {
  text: HoopsPropTypes.string,
  children: HoopsPropTypes.children,
};
