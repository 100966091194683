// TODO: fix eslint disable
/* eslint-disable no-class-assign, no-shadow */

import {Card, CardActions, CardContent, Typography, withStyles} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import cardLogos from '../../constants/cardLogos';
import {ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles';
import red from '../../ui/theme/red';
import Button from '@material-ui/core/Button';
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {deletePaymentMethod, setDefaultPaymentMethod} from '../../actions/billing';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import {get} from 'lodash';

const styles = (theme) => ({
    cardContainer: {backgroundColor: '#f8f8f8'},
    defaultCard: {
      borderLeftStyle: 'solid',
      borderLeftWidth: '4px',
      borderLeftColor: theme.palette.primary.main
    }
  });

class PaymentMethodCard extends React.Component {
  state = {open: false};

  handleDeleteCard = () => {
    this.setState({
      ...this.state,
      open: true
    });
  };

  handleDelete = async () => {
    const {deletePaymentMethod, method} = this.props;

    await deletePaymentMethod(method.id);
    this.handleClose();
  };

  handleClose = () => {
    this.setState({
      ...this.state,
      open: false
    });
  };

  render() {
    const {billing, method, classes, setDefaultPaymentMethod} = this.props;
    const {open} = this.state;
    const defaultSource = get(billing, 'stripeCustomer.default_source.id');
    const isDefault = (defaultSource === method.id);
    const brand = get(method, 'card.brand', '');

    return (
      <Fragment>
        <Card className={`${classes.cardContainer} ${isDefault && classes.defaultCard}`}>
          <CardContent>
            <Grid container spacing={1} alignItems={'center'}>
              <Grid item>
                <img src={cardLogos[brand.toLowerCase()]} alt={brand}/>
              </Grid>
              <Grid item>
                <Grid container direction={'column'}>
                  {method.name && <Grid item>
                    <Typography color={'textSecondary'}>Name</Typography>
                  </Grid>}
                  <Grid item>
                    <Typography color={'textSecondary'}>Last 4</Typography>
                  </Grid>
                  <Grid item>
                    <Typography color={'textSecondary'}>Expiry</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction={'column'}>
                  {method.name && <Grid item>
                    <Typography color={'textPrimary'}>{get(method, 'billing_details.name')}</Typography>
                  </Grid>}

                  <Grid item>
                    <Typography color={'textPrimary'}>{get(method, 'card.last4')}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography color={'textPrimary'}>{get(method, 'card.exp_month')} / {get(method, 'card.exp_year')}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Grid container justifyContent={'space-between'}>
              <Grid item>
                <MuiThemeProvider theme={red}>
                  <Button size='small' color='primary' variant={'outlined'} disabled={isDefault}
                          onClick={this.handleDeleteCard}>
                    Delete
                  </Button>
                </MuiThemeProvider>
              </Grid>
              <Grid item>
                <Button size='small' color='primary' variant={'outlined'} disabled={isDefault}
                        onClick={() => setDefaultPaymentMethod(method.id)}>
                  Make Primary
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{'Are you sure?'}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Are you sure you want to remove the card ending {get(method, 'card.last4')}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color='secondary'>
              Cancel
            </Button>
            <Button onClick={this.handleDelete} color='primary'>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {billing: state.billingReducer.billing};
}

function mapDispatchToProps(dispatch) {
  return {
    setDefaultPaymentMethod: (paymentId) => dispatch(setDefaultPaymentMethod(paymentId)),
    deletePaymentMethod: (paymentId) => dispatch(deletePaymentMethod(paymentId))
  };
}

PaymentMethodCard = withStyles(styles)(PaymentMethodCard);
PaymentMethodCard = connect(mapStateToProps, mapDispatchToProps)(PaymentMethodCard);

export default PaymentMethodCard;
