import React, {useState} from 'react';
import {useMutation, useQuery} from '@apollo/client';
import {Grid, Icon, Typography} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import {Link, useParams} from 'react-router-dom';
import {GQL_GET_QUOTE, GQL_UPDATE_QUOTE_STATUS} from '../../queries/quotes';
import StatusChanger from '../Statuses/StatusChanger';
import ActionsButton from '../common/ActionsButton';
import {SingleQuoteView} from './SingleQuote/SingleQuoteView';
import {QuoteMenuCell} from './quoteMenuCell';
import {SET_QUOTE} from '../../actions/quote';
import {useDispatch} from 'react-redux';
import Loading from '../shared/Loading';

export const AuthorisedQuotePreview = () => {
  const {quoteId} = useParams();
  const dispatch = useDispatch();
  const [quote, setQuote] = useState(null);

  const [updateQuoteStatus, {loading: loadingUpdate}] = useMutation(GQL_UPDATE_QUOTE_STATUS);

  const {loading} = useQuery(GQL_GET_QUOTE, {
    variables: {_id: quoteId},
    fetchPolicy: 'no-cache',
    onCompleted: (result) => {
      dispatch({type: SET_QUOTE, payload: result.quote});
      setQuote(result.quote);
    }
  });

  if(loading || !quote?._id){
    return <Loading text='Loading Quote' position={'absolute'} />;
  }

  return (
    <>
      <Grid container
        justifyContent={'space-between'}
        alignItems={'center'}
        spacing={4}
      >
        <Grid item>
          <Link to={'/quotes'}
            style={{textDecoration: 'none'}}>
            <IconButton><Icon>arrow_back</Icon></IconButton>
          </Link>
          <Typography variant='h5' display='inline'>Quote {quote?.number}&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;{quote?.customer?.name}&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;{quote?.contact?.fullName}</Typography>

        </Grid>
        <Grid item>
          <Grid container spacing={3} alignItems={'center'}>
            <Grid item>
              <StatusChanger
                entityType='quote'
                loadingUpdate={loadingUpdate}
                entityMapping='status'
                value={quote?.status}
                onChange={(newStatus) => updateQuoteStatus({variables: {_id: quote._id, status: newStatus.value}})}
              />
            </Grid>
            <Grid>
              <QuoteMenuCell rowData={quote} ><ActionsButton /></QuoteMenuCell>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing='4'>
        <Grid item xs={12}>
          <SingleQuoteView quote={quote} loading={loading}/>
        </Grid>
      </Grid>
    </>
  );
};
