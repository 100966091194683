import {Button, Dialog, DialogActions, DialogContent, DialogContentText, makeStyles, MuiThemeProvider, Typography} from '@material-ui/core';
import React from 'react';
import Lottie from 'react-lottie';
import successAlertIcon from '../../../assets/lotties/success-alert-icon.json';
import green from '../../../ui/theme/green';

const useStyles = makeStyles((theme) => ({
    title: {color: theme.palette.text.primary},
    body: {padding: '0 26px'},
}));

const SuccessDialog = ({
    successDialogObject = {open: false},
    handleClose = () => { }
}) => {
    const classes = useStyles();

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: successAlertIcon,
        rendererSettings: {preserveAspectRatio: 'xMidYMid slice'}
    };

    return (
        <Dialog
            open={successDialogObject?.open}
            onClose={handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            maxWidth='xs'
            style={{textAlign: 'center'}}
        >
            <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                    <Lottie
                        options={defaultOptions}
                        height={200}
                        width={200}
                    />
                    <Typography variant='h4' gutterBottom className={classes.title}>{successDialogObject && successDialogObject.title}</Typography>
                    <Typography variant='body2' className={classes.body}><strong>{successDialogObject && successDialogObject.bodyHeader}</strong></Typography>
                    <Typography variant='body2' className={classes.body}>{successDialogObject && successDialogObject.body}</Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{textAlign: 'center', padding: '0 40px 40px 40px', margin: '0 auto'}}>
                <MuiThemeProvider theme={green}>
                    <Button onClick={handleClose} color='primary' autoFocus variant='contained'>
                        Got It!
                    </Button>
                </MuiThemeProvider>
            </DialogActions>
        </Dialog>
    );
};

export default SuccessDialog;
