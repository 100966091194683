// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React, {Component} from 'react';
import {Grid, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import PoweredByHoops from '../../../assets/images/logo-grey.png';
import defaultCompanyLogo from '../../../assets/images/default-logo.png';
import classNames from 'classnames';
import {connect} from 'react-redux';

const MAX_WIDTH = 1440;
const styles = (theme) => ({
  logo: {
    width: '100%',
    height: '100%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  footer: {
    backgroundColor: theme.colors.grey.ultraLight,
    padding: theme.spacing(2),
    marginTop: '0px'
  },
  footerContentContainer: {
    [theme.breakpoints.up('md')]: {
      maxWidth: MAX_WIDTH,
      paddingLeft: theme.spacing(12),
      paddingRight: theme.spacing(12),
      paddingBottom: theme.spacing(6),
    }
  },
  footerInfo: {
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {textAlign: 'right'}
  },
  footerLogo: {
    maxWidth: '25%',
    maxHeight: '100%',
    [theme.breakpoints.down('md')]: {maxWidth: '20%'}
  },
  footerLogoSecondary: {
    height: theme.spacing(3),
    marginTop: theme.spacing(3)
  },
  footerLogoContainer: {
    width: '100%',
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      justifyContent: 'flex-start'
    }
  }
});

class Footer extends Component {
  render() {
    const {classes, activeProof} = this.props;
    const whitelabelPortal = activeProof?.job?.customer?.settings?.whitelabelProofPortal ?? false;

    const getTradingEntityIndexOrDefault = (entities, companyTradingEntityId) => {
      if (!companyTradingEntityId) {
        return 0;
      }
      const idx = entities.findIndex((entity) => entity._id.toString() === companyTradingEntityId.toString());
      return idx !== -1 ? idx : 0;
    };

    const companyEntityIndex = activeProof?.job?.customer
      ? getTradingEntityIndexOrDefault(activeProof?.job?.company?.companyTradingEntities, activeProof?.job?.customer?.companyTradingEntityId)
      : 0;

    const logo = activeProof?.job?.company?.companyTradingEntities[companyEntityIndex]?.logo ?? defaultCompanyLogo;
    const company = activeProof?.job?.company;

    return (
      <Grid container spacing={1} className={classes.footer} justifyContent='center'>
        <Grid container className={classes.footerContentContainer} alignItems={'center'} justifyContent={'space-between'}>
          {!whitelabelPortal && <Grid item xs={12} md={6}>
            <Box display='flex' alignItems={'center'} className={classes.footerLogoContainer}>
              <img className={classes.footerLogo}
                   src={logo}
                   alt='Logo'
              />
            </Box>
          </Grid>}
          {!whitelabelPortal && <Grid container item xs={12} md={6} direction='column' className={classes.footerInfo}>
            <Typography variant='caption' style={{fontWeight: '500'}}>
              {company?.name}
            </Typography>
            {company?.companyTradingEntities[companyEntityIndex]?.website &&
              <Typography variant='caption'>
              <a href={`//${company?.companyTradingEntities[companyEntityIndex]?.website}`} rel={'noreferrer'} target={'_blank'}>{company?.companyTradingEntities[companyEntityIndex]?.website}</a>
            </Typography>}
            <Typography variant='caption'>
              {company?.companyTradingEntities[companyEntityIndex]?.addresses[0]?.formattedAddress}
            </Typography>
            <Typography variant='caption'>
              {company?.companyTradingEntities[companyEntityIndex]?.phone}
            </Typography>
            <Typography variant='caption'>
              {company?.companyTradingEntities[companyEntityIndex]?.email}
            </Typography>
          </Grid>}
          <Grid container item xs={12} justfiy={'center'}>
            <Grid className={classNames([classes.footerLogoSecondary, classes.logo])}
                  style={{backgroundImage: `url(${PoweredByHoops})`}}/>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {company: state.companyReducer.company};
}

Footer = withStyles(styles)(Footer);
Footer = connect(mapStateToProps)(Footer);

export default Footer;
