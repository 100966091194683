// TODO: fix eslint disable
/* eslint-disable no-class-assign, no-shadow */

import React, {useState} from 'react';
import {
  Grid,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import Heading from './Heading';
import VendorInformation from './VendorInformation';
import VariantsTable from './VariantsTable';
import DecorationsTable from './DecorationsTable';
import AdditionalItemsTable from './AdditionalItemsTable';
import Attachments from './Attachments';
import Totals from './Totals';
import {get} from 'lodash';
import {useDispatch} from 'react-redux';
import {Skeleton} from '@material-ui/lab';
import {TextField} from 'final-form-material-ui';
import {Field, Form} from 'react-final-form';
import PurchaseOrdersService from '../../../servicesOld/PurchaseOrders';
import {snackError, snackSuccess} from '../../../actions/action-types';
import {useLazyQuery, useMutation} from '@apollo/client';
import {useHistory} from 'react-router';
import {useMountEffect} from '../../../hooks';
import {CREATE_BACKORDERS, GQL_GET_PO, GQL_UPDATE_PO} from '../../../queries/purchaseOrders';
import {HoopsButton} from '../../../componentsLib';
import {SET_ACTIVE_PURCHASE_ORDER} from '../../../actions/purchaseOrders';
import WarningIcon from '@material-ui/icons/Warning';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const toArray = (obj) => Object.keys(obj).map((_id) => ({
  _id,
  backorder: obj[_id].backorder,
  date: obj[_id].date,
}));

const useStyles = makeStyles((theme) => ({
  paperPadding: {
    padding: theme.spacing(3),
    paddingTop: 0,
    height: '100%'
  },
  tableContainer: {
    border: `1px solid ${theme.colors.grey.light}`,
    marginTop: theme.spacing(3)
  },
  topBuffer: {marginTop: theme.spacing(5)},
  itemsHeaderSticky: {
    position: 'sticky',
    top: theme.spacing(3),
    width: '-webkit-fill-available',
    zIndex: 25
  },
  itemsHeaderScreen: {
    background: '#fafafa',
    height: theme.spacing(3),
    width: '100%',
    top: 0,
    left: 0,
    position: 'sticky',
    zIndex: 30,
    boxShadow: '0px -5px 0px 5px #fafafa'
  },
  itemsHeaderTitle: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    height: '100%',
    background: 'white',

  },
  shadow: {
    position: 'sticky',
    top: 197,
    height: 1,
    width: '100%',
    zIndex: 15,
    boxShadow: '0px 3px 3px 0px rgb(0 0 0 / 75%)'
  },
  shadowCover: {
    position: 'relative',
    background: 'white',
    width: '100%',
    height: 7,
    zIndex: 20
  },
  // helper styles
  helperHighlight: {
    display: 'flex',
    padding: 16,
    background: '#FFBF66',
    color: 'white',
    borderRadius: '4px 4px 0px 0px',
  },
  helperWarningIcon: {
    margin: '0 20px 0 8px',
    alignSelf: 'center'
  },
  helperArrowIcon: {
    position: 'absolute',
    top: -39,
    color: '#FFBF66'
  }
}));

export const baseColumnWidth = {width: '100px', maxWidth: '100px'};

export const PurchaseOrder = ({match: {params: {orderId: purchaseOrderId}}}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [purchaseOrder, setPurchaseOrder] = useState({});
  const [touched, setTouched] = useState(false);

  const [variants, setVariants] = useState([]);
  const [decorations, setDecorations] = useState([]);
  const [additionalItems, setAdditionalItems] = useState([]);

  const [getPurchaseOrder, {loading}] = useLazyQuery(GQL_GET_PO, {
    fetchPolicy: 'no-cache',
    onError: (error) => {
      dispatch(snackError('Error loading purchase order: ' + error.message));
    },
    onCompleted: ({purchaseOrder}) => {
      setPurchaseOrder(purchaseOrder);
      dispatch({
        type: SET_ACTIVE_PURCHASE_ORDER,
        payload: purchaseOrder
      });
    }
  });

  const [savePurchaseOrder, {loading: saving}] = useMutation(GQL_UPDATE_PO, {
    variables: {
      _id: purchaseOrder._id,
      poData: PurchaseOrdersService.parsePo(purchaseOrder)
    },
    onError: (error) => {
      dispatch(snackError('Error saving purchase order: ' + error.message));
    },
    onCompleted: () => {
      history.push('/purchase-orders');
    }
  });

  const [createBackOrder, {savingBackOrder}] = useMutation(CREATE_BACKORDERS, {
    variables: {
      _id: purchaseOrder._id,
      variants: toArray(variants),
      decorations: toArray(decorations),
      additionalItems: toArray(additionalItems),
    },
    onError: (error) => {
      dispatch(snackError('Error creating backorder: ' + error.message));
    },
  });

  const handleSave = async () => {
    await createBackOrder();
    await savePurchaseOrder();
  };

  useMountEffect(() => {
    getPurchaseOrder({variables: {_id: purchaseOrderId}});
  }, []);

  const hasBackOrders =
    Object.keys(variants).filter((k) => variants[k].backorder).length > 0 ||
    Object.keys(decorations).filter((k) => decorations[k].backorder).length > 0 ||
    Object.keys(additionalItems).filter((k) => additionalItems[k].backorder).length > 0;

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Heading loading={loading} purchaseOrder={purchaseOrder} getPurchaseOrder={getPurchaseOrder} />
        </Grid>
        <Grid item xs={12} style={{paddingBottom: 7}}>
          <VendorInformation loading={loading} purchaseOrder={purchaseOrder} />
        </Grid>
        <Grid item xs={12}>
          {loading ? <Skeleton variant='rect' width='100%' height={400} /> :
            <>
              <div className={classes.itemsHeaderScreen} />
              <Paper>
                {/* Helper for save button feature change */}
                <div className={classes.itemsHeaderSticky}>
                  <div className={classes.helperHighlight}>
                    <WarningIcon className={classes.helperWarningIcon} />
                    <div>
                      <Typography variant='body1'><strong>Important: We’ve made changes to the performance of Purchase Orders.</strong></Typography>
                      <Typography variant='body2'>You must click <strong>SAVE CHANGES</strong> for the ordered/received changes to be saved and the status of the purchase order to update.</Typography>
                      <Typography variant='body2'>Mark all items as ordered or received, add items for backorder, then click Save Changes.</Typography>
                    </div>
                  </div>

                  <Grid container className={classes.itemsHeaderTitle}>
                    <Grid item xs={10}>
                      <Typography variant='h5' gutterBottom>Items</Typography>
                    </Grid>
                    <Grid item xs={2} style={{display: 'flex', justifyContent: 'flex-end'}}>
                      <HoopsButton
                        purpose='ok'
                        loading={saving || savingBackOrder}
                        disabled={!hasBackOrders && !touched}
                        onClick={handleSave}
                      >
                        Save Updates
                        <ArrowDropDownIcon fontSize='large' className={classes.helperArrowIcon} />
                      </HoopsButton>
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.shadow} />

                <div className={classes.shadowCover} />
                <Grid container className={classes.paperPadding}>
                  {purchaseOrder?.items && purchaseOrder.items.map((item, index) => {
                    const files = get(item, 'files');
                    return (
                      <Grid key={item._id} item xs={12} className={classes.tableContainer} style={{marginTop: index === 0 && 8}}>
                        {item.variants && item.variants.length ?
                          <VariantsTable
                            backorders={variants}
                            setBackorders={(variants) => setVariants(variants)}
                            item={item}
                            purchaseOrder={purchaseOrder}
                            setPurchaseOrder={setPurchaseOrder}
                            setTouched={setTouched}
                            description={get(item, 'description')}
                            variants={get(item, 'variants', [])}
                          /> : null}
                        {item.decorations && item.decorations.length ?
                          <DecorationsTable
                            backorders={decorations}
                            setBackorders={(decorations) => setDecorations(decorations)}
                            item={item}
                            purchaseOrder={purchaseOrder}
                            setPurchaseOrder={setPurchaseOrder}
                            setTouched={setTouched}
                            itemId={get(item, '_id')}
                            decorations={get(item, 'decorations', [])}
                          /> : null}
                        {item.additionalItems && item.additionalItems.length ?
                          <AdditionalItemsTable
                            backorders={additionalItems}
                            setBackorders={(additionalItems) => setAdditionalItems(additionalItems)}
                            item={item}
                            purchaseOrder={purchaseOrder}
                            setPurchaseOrder={setPurchaseOrder}
                            setTouched={setTouched}
                            itemId={get(item, '_id')}
                            additionalItems={get(item, 'additionalItems', [])}
                          /> : null}
                        {files && Boolean(files.length) && <Attachments files={files} />}
                      </Grid>
                    );
                  })}
                  <Grid item xs={6} className={classes.topBuffer}>
                    <Form
                      initialValues={purchaseOrder}
                      onSubmit={({notes}) => {
                        const notesString = notes || '';
                        setPurchaseOrder((prev) => ({...prev, notes: notesString}));
                        PurchaseOrdersService.updatePurchaseOrder(purchaseOrderId, {notes: notesString})
                          .then((po) => {
                            dispatch(snackSuccess(`PO ${po.number} updated for ${po.vendor.name}.`));
                          });
                      }}>
                      {({form, handleSubmit}) => (
                        <>
                          <form onSubmit={handleSubmit}>
                            <Typography variant='caption'>Notes</Typography>
                            <Field
                              name={'notes'}
                              component={TextField}
                              fullWidth
                              multiline
                              rows={4}
                              variant='outlined'
                              onBlur={() => form.submit()}
                            />
                          </form>
                        </>
                      )}
                    </Form>
                  </Grid>
                  <Grid container item xs={6} direction='column' justifyContent='center' spacing={2}>
                    <Totals />
                  </Grid>
                </Grid>
              </Paper>
            </>}
        </Grid>
      </Grid>
    </>
  );
};
