import {IconButton, ListItemIcon, Menu, MenuItem} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import React, {Fragment, useState} from 'react';
import {useDispatch} from 'react-redux';
import {deleteTask, getTask, setModal} from '../../../actions/tasks';

export const TaskListActions = ({task}) => {
    const [anchorEl, setAnchorEl] = useState();
    const dispatch = useDispatch();

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        dispatch({type: 'SET_TASK', payload: task}); //pre set task then load detailed)
        dispatch(getTask(task._id));
        dispatch(setModal(true));
        handleClose();
    };

    const handleDelete = () => {
        dispatch(deleteTask(task._id));
        handleClose();
    };

    return (
        <Fragment>
            <IconButton
                variant={'outlined'}
                size={'small'}
                onClick={handleClick}>
                <MoreHorizIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                >
                <MenuItem onClick={handleEdit}>
                    <ListItemIcon>
                        <EditIcon />
                    </ListItemIcon>
                    Edit
                </MenuItem>
                <MenuItem onClick={handleDelete}>
                    <ListItemIcon>
                        <DeleteIcon />
                    </ListItemIcon>
                    Delete
                </MenuItem>
            </Menu>
        </Fragment>
    );
};

export default TaskListActions;
