// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React from 'react';
import {withStyles, DialogTitle as MuiDialogTitle, Icon} from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

const overrides = (theme) => ({
    titlePadding: {padding: theme.spacing(2)},
    closeButton: {
      cursor: 'pointer',
      '&:hover': {color: theme.palette.secondary.dark}
    }
  });

class DialogTitle extends React.Component {
  render() {
    const {classes, className, children, onClick, ...other} = this.props;

    return (
      <MuiDialogTitle disableTypography={true} className={classNames(classes.titlePadding, className)} {...other}>
        <Grid container justifyContent={'space-between'}>
          <Grid item xs={10}>
            {children}
          </Grid>
          <Grid item>
            <Icon className={classes.closeButton} onClick={onClick} color={'secondary'}>close</Icon>
          </Grid>
        </Grid>
      </MuiDialogTitle>
    );
  }
}

DialogTitle = withStyles(overrides)(DialogTitle);

export default DialogTitle;

DialogTitle.propTypes = {onClick: PropTypes.func.isRequired};
