import {Checkbox, TableCell} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import {get} from 'lodash';
import React, {useEffect, useState} from 'react';
import ImageWithTooltip from '../shared/ImageWithTooltip';

function ColorCard({image, label, value, onChange}) {
  return (
    <>
      <Grid container direction={'column'} justifyContent={'center'} alignItems={'center'}>
        <Grid item>
          <ImageWithTooltip imageUrl={image.url} onClick={() => onChange(image.url)}/>
        </Grid>
        <Grid item>
          <FormControlLabel
            onClick={() => onChange(image.url)}
            label={<Typography variant={'body2'}>{label}</Typography>}
            control={<Checkbox name={label} size={'small'} color={'primary'} checked={(value === image.url)} />}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default function ProductImageSelector({onHide, product, input: {value, onChange}}) {
  const [colors, setColors] = useState([]);
  const [hero, setHero] = useState([]);

  useEffect(() => {
    setColors(get(product, 'colors', []));
    setHero(get(product, 'primaryImage', {}));
  }, [product]);

  if (colors.length === 0) {
    return (<></>);
  }

  return (<>
    <TableRow>
      <TableCell colSpan={2}>
        <Grid container spacing={2} direction={'column'}>
          <Grid item xs>
            <Typography variant={'body1'} color={'textPrimary'}>Product Image</Typography>
            <Typography variant={'body2'} color={'textSecondary'}>Click the image assign it to this line
              item.</Typography>
          </Grid>
          <Grid item xs>
            <Button variant={'outlined'} fullWidth={true} onClick={() => onHide()}>Hide</Button>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell colSpan={8}>
        <Grid container direction={'row'} spacing={1}>
          <Grid item>
            <ColorCard label={'Primary'} image={hero} value={value} onChange={onChange}/>
          </Grid>
          {colors && colors.map((color) => (
            <Grid item key={color._id}>
              {color.image && <>
                <ColorCard label={color.name} image={color.image} value={value} onChange={onChange}/>
              </>}
            </Grid>
          ))}
        </Grid>
      </TableCell>
    </TableRow>
  </>);
}
