import React, {useCallback, useState} from 'react';
import classNames from 'classnames';
import {ModelessPopover, useModelessPopoverState} from '../Popovers';
import {TextInput} from '../Basic';
import {Row} from '../Layout';
import {colors as hoopsColors, registerGlobalStyle} from '../../theme';
import {isLightColor} from '../../utils';
import {CaptionText} from '../Text';

registerGlobalStyle('.color-picker', (theme) => ({
  '&.color-picker-button': {
    width: theme.spacing(3),
    height: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
  },
  '&.color-picker-popover': {
    width: 170,
    '.color-input': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      padding: theme.spacing(0, 3),
      height: 80,
    },
    '.text-input': {
      backgroundColor: 'transparent',
      borderColor: 'inherit',
      color: 'inherit',
      textAlign: 'center',
      '&::before': {
        content: '"#"',
        color: 'inherit',
      },
      'input': {
        backgroundColor: 'transparent',
        borderColor: 'inherit',
        color: 'inherit',
        textAlign: 'center',
      }
    },
    '.color-swatch': {
      padding: 10,
      rowGap: 10,
      columnGap: 10,
      '&>div': {
        borderRadius: theme.shape.borderRadius,
        width: 22,
        height: 22,
        cursor: 'pointer',
      },
    },
    '.color-bottom': {
      width: '100%',
      height: 30,
    }
  },
}));

const defaultColors = [
  '#00CCF0', '#9CECFA', '#00CEC9', '#A29BFE', '#9366AF',
  '#22CC96', '#178965', '#48FC70', '#7DB84C', '#4D7103',
  '#FC5F65', '#FD79A8', '#FC66FF', '#FAB1A0', '#D01E25',
  '#A36468', '#CC8778', '#FD8979', '#FFBF66', '#FFEAA7',
  '#2F323C', '#8F9197', '#007DF0', '#01549F',
];

const validHexColor = /^#[0-9a-fA-F]{6}$/;
const validHexString = /^[0-9a-fA-F]*$/;

export function ColorPicker({className, color: initColor, label, onChange}) {
  const {anchorEl, anchorElRef, closePopover, open, togglePopover} = useModelessPopoverState();
  const [color, setColor] = useState(initColor.toUpperCase());

  const handleChangeColor = useCallback((newColor) => {
    closePopover();
    setColor(newColor);
    onChange?.(newColor);
  }, [closePopover, onChange]);

  const handleEditColor = useCallback((newColor) => {
    setColor(newColor);
    onChange?.(newColor);
  }, [onChange]);

  return (
    <>
      {label &&
        <CaptionText>{label}</CaptionText>
      }
      <div
        className={classNames([className, 'color-picker color-picker-button'])}
        onClick={togglePopover}
        ref={anchorElRef}
        style={{backgroundColor: color}}
      >
        <ModelessPopover
          className={[className, 'color-picker color-picker-popover']}
          anchorEl={anchorEl}
          placement={'bottom-start'}
          open={!!(open && anchorEl)}
          onClose={closePopover}
        >
          <ColorInput color={color} onChange={handleEditColor}/>
          <ColorSwatch onClick={handleChangeColor}/>
          <div className={'color-bottom'} style={{backgroundColor: color}}/>
        </ModelessPopover>
      </div>
    </>
  );
}

export function ColorInput({color, onChange}) {
  const [editColor, setEditColor] = useState(color.replace('#', ''));

  const handleChange = useCallback((e) => {
    let newColor = e.target.value;
    if (validHexString.test(newColor)) {
      setEditColor(newColor);

      if (newColor.substring(0, 1) !== '#') {
        newColor = '#' + newColor;
      }
      if (newColor.length < 7) {
        const lastChar = newColor.length > 1 ? newColor.slice(-1) : '0';
        newColor = newColor + lastChar.repeat(7 - newColor.length);
      } else if (newColor.length > 7) {
        newColor = newColor.substring(0, 7);
      }
      if (validHexColor.test(newColor)) {
        onChange?.(newColor.toUpperCase());
      }
    }
  }, [onChange]);

  const textColor = isLightColor(color) ? hoopsColors.text.main : hoopsColors.text.white;

  return (
    <div className={'color-input'} style={{backgroundColor: color, color: textColor, borderColor: textColor}}>
      <TextInput value={editColor} container onChange={handleChange}/>
    </div>
  );
}

export function ColorSwatch({colors = defaultColors, onClick}) {
  return (
    <Row className={'color-swatch'} wrap>
      {colors.map((color) => (
        <div key={color} style={{background: color}} onClick={() => onClick(color)}/>
      ))}
    </Row>
  );
}
