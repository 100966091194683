import {gql} from 'apollo-boost';

export const GQL_AUTH_LOGIN = gql`
mutation Login($username: String!, $password: String!) {
  login(username: $username, password: $password) {
    token
  }
}`;

export const GQL_AUTH_LOGOUT = gql`
mutation Logout {
  logout {
    message
  }
}`;

export const GQL_AUTH_RESET_PASSWORD = gql`
mutation RestPassword($email: String!) {
  resetPassword(email: $email) {
   message   
  }
}`;
