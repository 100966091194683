import React, {useCallback} from 'react';
import {makeStyles} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {formValueSelector} from 'redux-form';
import {useDispatch, useSelector} from 'react-redux';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {openQuoteTerms} from '../../actions/modals';

const styles = (theme) => ({
  termsBox: {
    maxHeight: 400,
    overflowY: 'auto',
    padding: `${theme.spacing(1)}px!important`,
    borderStyle: 'solid',
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.secondary.main,
    borderWidth: 1
  }
});

function QuoteFormTerms({onQuoteChange}) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const dispatch = useDispatch();
  const selector = formValueSelector('quoteForm');
  const termsSelector = (state) => selector(state, 'terms');
  const terms = useSelector(termsSelector);

  const handleOpenQuoteTerms = useCallback(() => {
    dispatch(openQuoteTerms());
    if (onQuoteChange) {
      onQuoteChange();
    }
  }, [onQuoteChange, dispatch]);
  return (
    <Grid container >
      <Grid item xs={12}>
        <Grid container justifyContent={'space-between'} style={{paddingBottom: '8px'}}>
          <Grid item>
            <Typography variant={'h6'}>Terms</Typography>
          </Grid>
          <Grid item>
            <Button color={'primary'} variant={'outlined'} size={'small'} onClick={handleOpenQuoteTerms}>Edit Terms</Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.termsBox}>
        <Box dangerouslySetInnerHTML={{__html: terms}} />
      </Grid>
    </Grid>
  );
}

export default QuoteFormTerms;
