// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React, {Component, Fragment} from 'react';
import {Grid, Paper, Typography, withStyles} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import {Edit} from '@material-ui/icons';
import {HoopsButton} from '../../componentsLib';

const styles = (theme) => ({paperPadding: {padding: theme.spacing(3)}});

class SettingsPaper extends Component {
  render() {
    const {
      classes, editing, title, subtitle, onEdit, loading,
      onCancel, onSave,
      gridContainerProps,
      children, 
      disabled
    } = this.props;

    return (
      <Fragment>
        <Paper className={classes.paperPadding}>
          <Grid container justifyContent='space-between' alignItems='center' className={classes.header}>
            <Grid item>
              <Typography variant='h5'>{title}</Typography>
              {subtitle ? <Typography variant='subtitle'>{subtitle}</Typography> : null}
            </Grid>
            <Grid item>
              {editing ?
                <Grid container spacing={1}>
                  <Grid item>
                    <HoopsButton purpose='cancel' onClick={onCancel}>Cancel</HoopsButton>
                  </Grid>
                  <Grid item>
                    <HoopsButton purpose='save' loading={loading} disabled={disabled} onClick={onSave}>Save</HoopsButton>
                  </Grid>
                </Grid> :
                <Fragment>
                  <IconButton variant={'text'} color={'primary'} onClick={onEdit}>
                    <Edit />
                  </IconButton>
                </Fragment>
              }
            </Grid>
          </Grid>
          <Grid container {...gridContainerProps}>
            {children}
          </Grid>
        </Paper>
      </Fragment>
    );
  }
}

SettingsPaper = withStyles(styles)(SettingsPaper);

export default SettingsPaper;
