// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import {Grid, Tooltip, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import React, {useContext, useEffect, useMemo} from 'react';
import {useFormContext} from 'react-hook-form';
import FormattedCurrency from '../../componentsOld/reporting/FormattedCurrency';
import {decimalPlaces} from '../../componentsOld/shared/numberUtils';
import {itemBuilder} from '../../ui/theme/itemBuilder';
import {ItemBuilderContext} from './ItemBuilderContext';
import ItemBuilderSexyPricePopup from './ItemBuilderSexyPricePopup';
import {calculatePriceElementBreakdown} from './itemBuilderUtils';

const useStyles = makeStyles((theme) => ({
    ...itemBuilder,
    bar: {
        paddingTop: theme.spacing(3),
        overflow: 'hidden',
    },
}));

export const ItemBuilderValueBar = () => {
    const classes = useStyles();

    const {watch} = useFormContext();
    const {setIsNotTouched} = useContext(ItemBuilderContext);
    const priceElement = watch();
    const values = useMemo(() =>
            calculatePriceElementBreakdown(priceElement),
    [priceElement]);

    return (
        <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='flex-start'
            className={classes.bar}
        >

            <Grid item >
                <Typography variant={'caption'} className={classes.greyText}>Product Cost</Typography>
                <Typography variant={'body1'} component='div'>
                    <FormattedCurrency justifyContent={'flex-start'}>{values.variationTotalCost}</FormattedCurrency>
                </Typography>
            </Grid>

            <Grid item >
                <Typography variant={'caption'} className={classes.greyText}>Decoration Cost</Typography>
                <Typography variant={'body1'} component='div'>
                    <FormattedCurrency justifyContent={'flex-start'}>{values.decorationTotalCost}</FormattedCurrency>
                </Typography>
            </Grid>

            <Grid item >
                <Typography variant={'caption'} className={classes.greyText}>Additional Cost</Typography>
                <Typography variant={'body1'} component='div'>
                    <FormattedCurrency justifyContent={'flex-start'}>{values.additionalCostTotalCost}</FormattedCurrency>
                </Typography>
            </Grid>

            <Grid item >
                <Typography variant={'caption'} className={classes.greyText}>Total Cost</Typography>
                <Typography variant={'body1'} component='div'>
                    <FormattedCurrency justifyContent={'flex-start'}>{values.totalCost}</FormattedCurrency>
                </Typography>
            </Grid>

            <Grid item >
                <Typography variant={'caption'} className={classes.greyText}>Sell Price Per Unit</Typography>
                <Typography variant={'body1'} component='div'>
                    <Grid container justifyContent={'flex-start'}>
                        <Grid item><FormattedCurrency justifyContent={'flex-start'}>{values.sellPricePerUnit}</FormattedCurrency></Grid>
                        <Grid item><ItemBuilderSexyPricePopup values={values} priceElement={priceElement} /></Grid>
                    </Grid>
                </Typography>
            </Grid>

            <Grid item >
                <Typography variant={'caption'} className={classes.greyText}>Total Sell Price</Typography>
                <Typography variant={'body1'} component='div'>
                    <FormattedCurrency justifyContent={'flex-start'}>{values.sellPricePerUnit * values.productQuantity}</FormattedCurrency>
                </Typography>
            </Grid>

            <Grid item >
                <Grid container justifyContent={'flex-start'} spacing={1}>
                    <Grid item>
                        <Typography variant={'caption'} className={classes.greyText}>Gross Margin</Typography>
                    </Grid>
                    <Grid item>
                        {priceElement.grossMarginTarget && <Tooltip arrow placement='top' classes={{tooltip: classes.tooltip}} title={<>This is a calculated value and due to rounding it can differ slightly from the margin that has been set.</>}><InfoIcon fontSize='small' className={classes.icon} style={{display: 'flex', marginBottom: 4}} /></Tooltip>}
                    </Grid>
                </Grid>
                <Typography variant={'body1'} component='div'>
                    <Grid container justifyContent={'flex-start'}>
                        <Grid item>{decimalPlaces(values.grossMargin, 2)}
                        </Grid>
                        <Grid item>%</Grid>
                    </Grid>
                </Typography>
            </Grid>

            <Grid item >
                <Typography variant={'caption'} className={classes.greyText}>Profit Per Unit</Typography>
                <Typography variant={'body1'} component='div'>
                    <FormattedCurrency justifyContent={'flex-start'}>{values.profitPerUnit}</FormattedCurrency>
                </Typography>
            </Grid>

            <Grid item >
                <Typography variant={'caption'} className={classes.greyText}>Profit Total</Typography>
                <Typography variant={'body1'} component='div'>
                    <FormattedCurrency justifyContent={'flex-start'}>{values.totalProfit}</FormattedCurrency>
                </Typography>
            </Grid>

        </Grid>
    );
};

export default ItemBuilderValueBar;
