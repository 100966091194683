// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import {CustomerService} from '../servicesOld/CustomerService';

export function customerImport(fileId) {
    return (dispatch) => {
      CustomerService.customerImport({fileId});
    };
  }
