// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import {Card, CardActionArea, CardContent, CardMedia, Grid, Typography, withStyles} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import {get, truncate} from 'lodash';
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import favicon from '../../assets/images/favicon.ico';
import DefaultProductImage from '../../assets/images/product_placeholder.jpg';
import ProductActionMenu from './ProductActionMenu';

const styles = (theme) => ({
  image: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    margin: '2px',
    height: '278px', // 4 * 5 ratio
  },
  contentContainer: {padding: `${theme.spacing(0.5)}px !important`,},
  productCode: {fontWeight: '500'},
  popper: {opacity: 1,},
  tooltip: {
    maxWidth: '450px',
    backgroundColor: 'transparent'
  },
});

class ProductCard extends Component {

  handleProductClick = (product) => {
    const {onProductSelect} = this.props;
    if (!product) {return;}
    onProductSelect(product._id);
  };

  render() {
    const {classes, product} = this.props;
    return (
      <Card style={{height: '370px'}}>
        <CardActionArea onClick={() => this.handleProductClick(product)}>
          <CardMedia
            image={get(product, 'primaryImage.url', DefaultProductImage)}
            className={classes.image}
          />
        </CardActionArea>
        <CardContent classes={{root: classes.contentContainer}}>
          <Grid container className={classes.contentContainer}>
            <Grid item xs={12}>
              <Grid container wrap={'nowrap'} spacing={1} alignItems={'center'} justifyContent={'space-between'}>
                <Grid item>
                  <Typography variant={'body2'}>
                    <span className={classes.productCode}>
                      {get(product, 'code', '')}
                    </span> | {get(product, 'vendor.name', '')}
                  </Typography>
                </Grid>
                {product.readOnly && <Grid item>
                  <Tooltip title={`This product is managed by ${product.company.name}`}>
                    <img src={favicon} alt={'favicon'} style={{width: 20, height: 20}} />
                  </Tooltip>
                </Grid>}
              </Grid>
            </Grid>
            <Grid item container justifyContent={'space-between'} alignItems={'center'}>
              <Grid item container>
                <Grid item container alignItems={'center'} xs={10}>
                  <Typography variant={'subtitle2'} color={'primary'}>
                    {truncate(
                      get(product, 'title', ''),
                      {length: 46}
                    )}
                  </Typography>
                </Grid>
                <Grid item container alignItems={'flex-end'} xs={2}>
                  <ProductActionMenu product={product} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

ProductCard = withStyles(styles)(ProductCard);
ProductCard = withRouter(ProductCard);
export default ProductCard;

