import {Divider, Grid, makeStyles} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {findIndex, get} from 'lodash';
import * as numeral from 'numeral';
import React from 'react';
import {useSelector} from 'react-redux';
import {formValueSelector} from 'redux-form';
import {asCurrencyRounded, asCurrencyStringCommaSeparated} from '../../utils';

const useStyles = makeStyles((theme) => ({
  subTotalCell: {
    paddingRight: theme.spacing(1),
    minWidth: 200,
  },
  noBorder: {
    borderStyle: 'none',
    paddingLeft: 0,
  },
  leftSpace: {
    width: '100%',
    verticalAlign: 'top'
  },
  totalItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2)
  }
}));

const getTotals = (items, form) => {
  let totals = {
    subTotal: 0,
    taxes: [],
    total: 0
  };

  if (items && items.length) {
    const qtySelector = form === 'invoiceForm' ? 'qty' : 'quantity';
    totals = items.reduce((sum, item) => {
      let itemSubtotal = asCurrencyRounded(item.rate * item[qtySelector]);
      let itemTotal;
      let itemTaxes = [];

      const components = get(item, 'tax.components', []);
      itemTaxes = components.map((component) => ({
        name: component.name,
        rate: component.rate,
        amount: asCurrencyRounded(component.rate / 100 * itemSubtotal),
      }));
      itemTotal = itemSubtotal + itemTaxes.reduce((s, tax) => s + tax.amount, 0);

      return {
        subTotal: sum.subTotal + itemSubtotal,
        total: sum.total + itemTotal,
        taxes: sum.taxes.concat(itemTaxes)
      };
    }, {
      subTotal: 0,
      taxes: [],
      total: 0
    });

    totals.taxes = totals.taxes.reduce((sum, tax) => {
      let index = findIndex(sum, {name: tax.name});
      if (index === -1) {
        sum.push(tax);
        return sum;
      }
      sum[index].amount += tax.amount;
      return sum;
    }, []);
  }
  return totals;
};

const QuoteFormSubtotalField = ({form, children}) => {
  const selector = formValueSelector(form);
  const companySelector = (state) => state.companyReducer.company;
  const itemsSelector = (state) => selector(state, 'items');
  const hideTotalSelector = (state) => selector(state, 'settings.hideTotal');
  const company = useSelector(companySelector);
  const items = useSelector(itemsSelector);
  const hideTotal = useSelector(hideTotalSelector);
  const classes = useStyles();
  const totals = getTotals(items, form);

  return (

    <Grid
      container
      spacing={2}
    >
      <Grid item md={hideTotal ? 12 : 9} sm={12}>
        {children}
      </Grid>
      {!hideTotal &&
        <Grid item md={3} sm={12}>

          <div className={classes.totalItem}>
            <Typography variant={'body2'} display='inline'>Subtotal</Typography>
            <Typography variant={'body2'} display='inline'>
              {company.currencySymbol} {asCurrencyStringCommaSeparated(totals.subTotal)}
            </Typography>
          </div>

          <Divider />

          {
            totals.taxes.map((tax) => (
              <>
                <div className={classes.totalItem} key={tax.name}>
                  <Typography variant={'body2'} display='inline'>{tax.name} ({tax.rate}%)</Typography>
                  <Typography variant={'body2'} display='inline'>
                    {company.currencySymbol} {numeral(tax.amount).format('0,0.00')}
                  </Typography>
                </div>
                <Divider />
              </>
            ))
          }
          <div className={classes.totalItem}>
            <Typography variant={'body1'} display='inline'><strong>Total</strong></Typography>
            <Typography variant={'body1'} display='inline'>
              <strong>{company.currencySymbol} {numeral(totals.total).format('0,0.00')}</strong>
            </Typography>
          </div>
          <Divider />

        </Grid>
      }
    </Grid>
  );
};

export default QuoteFormSubtotalField;
