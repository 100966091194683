import {useCallback, useLayoutEffect, useState} from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export function useResizeObserver(elemWatch) {
  const [rect, setRect] = useState({});

  const handleResize = useCallback((entries) => {
    const entryRect = entries[0]?.contentRect;
    if (entryRect) {
      setRect((prevRect) => {
        if (prevRect == null || prevRect.width !== entryRect.width || prevRect.height !== entryRect.height) {
          return {
            left: Math.round(entryRect.left),
            top: Math.round(entryRect.top),
            width: Math.round(entryRect.width),
            height: Math.round(entryRect.height),
            ...(prevRect == null ? {
              leftChange: 0,
              topChange: 0,
              widthChange: 0,
              heightChange: 0,
            } : {
              leftChange: Math.round(entryRect.left - prevRect.left),
              topChange: Math.round(entryRect.top - prevRect.top),
              widthChange: Math.round(entryRect.width - prevRect.width),
              heightChange: Math.round(entryRect.height - prevRect.height),
            })
          };
        }
        return prevRect;
      });
    }
  }, []);

  useLayoutEffect(() => {
    if (!elemWatch) {
      return undefined;
    }

    let observer = new ResizeObserver((entries) => handleResize(entries));
    observer.observe(elemWatch);

    return () => {
      observer.disconnect();
    };
  }, [elemWatch, handleResize]);

  return rect;
}
