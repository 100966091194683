import {
  USER_LOGGED_IN,
  SET_USER_DATA,
  LOGGING_IN,
  LOGIN_ERROR,
  SET_USERS,
  SET_LOADING_USERS_FLAG, CLEAR_LOADING_USERS_FLAG, SET_NEW_USER, SET_USER_INTERCOM_ATTRIBUTES
} from '../actions/action-types';

const initialState = {
  isLoggedIn: false,
  loggingIn: false,
  loginErrorMessage: '',
  userData: {},
  newUser: {xero: {}},
  users: [],
  loadingUsers: true,
  intercomAttributes: {}
};

const authReducer = (state = {...initialState}, action) => {
  switch (action.type) {
    case USER_LOGGED_IN:
      return {
        ...state,
        loggingIn: false,
        loginErrorMessage: '',
        isLoggedIn: action.payload
      };
    case SET_USER_DATA:
      return {
        ...state,
        userData: action.payload
      };
    case LOGGING_IN:
      return {
        ...state,
        loggingIn: true
      };
    case LOGIN_ERROR:
      return {
        ...state,
        loggingIn: false,
        loginErrorMessage: action.payload
      };
    case SET_LOADING_USERS_FLAG:
      return {
        ...state,
        loadingUsers: true
      };
    case CLEAR_LOADING_USERS_FLAG:
      return {
        ...state,
        loadingUsers: false
      };
    case SET_USERS:
      return {
        ...state,
        users: action.payload,
        loadingUsers: false
      };
    case SET_NEW_USER:
      return {
        ...state,
        newUser: action.payload
      };
    case SET_USER_INTERCOM_ATTRIBUTES:
      return {
        ...state,
        intercomAttributes: action.payload
      };
    default:
      return state;
  }
};

export default authReducer;
