// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React, {Component, Fragment} from 'react';
import Grid from '@material-ui/core/Grid';
import {MuiThemeProvider, Typography, withStyles} from '@material-ui/core';
import {Delete} from '@material-ui/icons';
import {Field} from 'redux-form';
import MuiMultiSelect from '../../formHelpers/MuiMultiSelect';
import {connect} from 'react-redux';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import purple from '../../../../ui/theme/purple';
import {truncate} from 'lodash';
import FilePreview from '../../../../modules/file/FilePreview';
import {PermissionBasedContent, SubscriptionTypes} from '../../../../componentsHoops';
import {stripHTML} from '../../../../utils/stripHtml';

const styles = (theme) => ({
  chips: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap'
  }
});

class ProofFile extends Component {
  handleRemove = (index) => {
    const {onRemoveFile} = this.props;
    if (onRemoveFile) {
      onRemoveFile(index);
    }
  };

  render() {
    const {classes, file, job, field, fieldIndex: index} = this.props;

    return (
      <Fragment>
        <Grid container alignItems={'center'} spacing={2}>
          <Grid item xs={6}>
            <Grid container spacing={1} alignItems={'center'}>
              <Grid item xs={2}>
                <FilePreview
                  file={file}
                  options={{thumbWidth: 50, thumbHeight: 50}}
                />
              </Grid>
              <Grid item xs={10}>
                <Typography variant={'body2'}>{file.filename}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container justifyContent={'space-between'} alignItems={'center'} spacing={2}>
              <Grid item xs={10}>
              <PermissionBasedContent disallowedSubscriptions={[SubscriptionTypes.proofs]}>
                <Field
                  data-intercom-target={'proof-link-to-product-field'}
                  name={`${field}.products`}
                  component={MuiMultiSelect}
                  label={'Link to Product(s)'}
                  fullWidth
                  options={job.items}
                  SelectedComponent={({value: item}) => (
                    <Chip className={classes.chip} key={item._id}
                      color={'primary'} label={truncate(stripHTML(item.description))} size={'small'} />
                  )}
                  OptionComponent={({selected, option: item}) => (
                    <Fragment>
                      <MuiThemeProvider theme={purple}>
                        <Checkbox checked={selected.includes(item)} color={'primary'} />
                      </MuiThemeProvider>
                      <ListItemText primary={stripHTML(item.description)} />
                    </Fragment>

                  )}
                />
                </PermissionBasedContent>
              </Grid>
              <Grid item>
                <IconButton data-intercom-target={'proof-delete-button'} onClick={() => this.handleRemove(index)}>
                  <Delete />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {job: state.jobsReducer.activeJob};
}

ProofFile = connect(mapStateToProps, null)(ProofFile);
ProofFile = withStyles(styles)(ProofFile);

export default ProofFile;
