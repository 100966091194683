// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import {TableRow} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';
import React, {Component, useEffect} from 'react';
import {connect, useSelector} from 'react-redux';
import {Field, FieldArray, formValueSelector} from 'redux-form';
import {toFloat} from '../../../../servicesOld/ItemBuilderServices';
import ProductsTableRows from './ProductsTableRows';

const styles = (theme) => {
  const baseWidth = 140;
  return {
    sectionFooter: {
      margin: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      maxWidth: '100%'
    },
    baseColumn: {width: `${baseWidth}px`},
    vendorColumn: {width: `${baseWidth * 2}px`},
    actionColumn: {width: `${baseWidth + 5}px`}
  };
};

function RenderReadOnlyQty({input: {value, onChange}, meta: {form}}) {
  const selector = formValueSelector(form);

  const variantsSelector = (state) => selector(state, 'variants');
  const decorationsSelector = (state) => selector(state, 'decorations');

  const variants = useSelector(variantsSelector);
  const decorations = useSelector(decorationsSelector);

  let quantity = 0;
  if (variants && variants.length) {
    quantity = variants.reduce((sum, v) => sum + toFloat(v.quantity), 0);
  } else if (decorations && decorations.length) {
    quantity = decorations[0].quantity;
  }

  useEffect(() => {
    if (value !== quantity) {
      onChange(quantity);
    }
  });

  return (
    <Typography variant={'body2'} color={'textPrimary'}>
      {value}
    </Typography>
  );
}

class ProductsTable extends Component {
  render() {
    const {classes, variants} = this.props;

    return (
      <>
        <Table>
          {Boolean(variants && variants.length) && <TableHead>
            <TableRow>
              <TableCell>Product Type</TableCell>
              <TableCell>Product Name</TableCell>
              <TableCell className={classes.baseColumn}>Product Code</TableCell>
              <TableCell className={classes.vendorColumn}>Vendor</TableCell>
              <TableCell className={classes.baseColumn}>Quantity</TableCell>
              <TableCell className={classes.baseColumn}>Color</TableCell>
              <TableCell className={classes.baseColumn}>Size</TableCell>
              <TableCell className={classes.actionColumn} />
            </TableRow>
          </TableHead>}
          <TableBody>
            <FieldArray name={'variants'} component={ProductsTableRows} />
          </TableBody>
        </Table>
        <Grid container component={'div'} spacing={2} className={classes.sectionFooter}>
          <Grid item component={'div'}>
            <Typography variant={'body2'} color={'textSecondary'}>Total Quantity: </Typography>
          </Grid>
          <Grid item component={'div'}>
            <Field name={'quantity'}
              component={RenderReadOnlyQty}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

const selector = formValueSelector('jobVariantsForm');
function mapStateToProps(state) {
  return {
    quantity: selector(state, 'quantity'),
    variants: selector(state, 'variants')
  };
}

ProductsTable = connect(mapStateToProps)(ProductsTable);
ProductsTable = withStyles(styles)(ProductsTable);
export default ProductsTable;
