import React from 'react';
import {Radio as MuiRadio, RadioGroup as MuiRadioGroup} from '@mui/material';
import {CaptionText} from '../Text';
import {HoopsPropTypes} from '../utils';
import {Row} from '../Layout';
import {registerGlobalStyle} from '../../theme';
import classNames from 'classnames';

registerGlobalStyle('.radio-group', (theme) => ({
  '&>label': {
    alignSelf: 'start',
    alignItems: 'center',
    cursor: 'pointer',
    '.radio-button .text-caption': {color: theme.colors.text.main}
  }
}));

export function RadioGroup({className, row, value, onChange, children}) {
  return (
    <MuiRadioGroup className={classNames([className, 'radio-group'])} {...{row, value, onChange}}>
      {children}
    </MuiRadioGroup>
  );
}

RadioGroup.propTypes = {
  className: HoopsPropTypes.className,
  row: HoopsPropTypes.bool,
  value: HoopsPropTypes.any,
  onChange: HoopsPropTypes.func,
  children: HoopsPropTypes.children,
};

export function Radio({className, label, value, children, ...props}) {
  if (label || children) {
    return (
      <Row className={[className, 'radio-button']} tag={'label'}>
        <MuiRadio value={value} {...props}/>
        <CaptionText>{label ?? children}</CaptionText>
      </Row>
    );
  } else {
    return <MuiRadio className={classNames([className, 'radio-button'])} value={value} {...props}/>;
  }
}

Radio.propTypes = {
  className: HoopsPropTypes.className,
  label: HoopsPropTypes.string,
  value: HoopsPropTypes.boolOrString,
  children: HoopsPropTypes.children,
};
