import {useCallback, useEffect, useRef} from 'react';

/**
 * A hook to start and stop a timer and call a function with arguments on each interval,
 * as well as once when the timer is started
 * @param interval
 * @param callback
 * @param initArgs
 * @returns {{startTimer: ((function(...[*]): void)|*), stopTimer: ((function(): void)|*)}}
 */
export function useTimer(interval, callback, initArgs) {
  const argsRef = useRef(initArgs);
  const timerRef = useRef(null);

  const startTimer = useCallback((newArgs) => {
    argsRef.current = newArgs;
    if (timerRef.current) {
      return;
    }
    callback(argsRef.current);
    timerRef.current = setInterval(() => callback(argsRef.current), interval);
  }, [callback, interval]);

  const stopTimer = useCallback(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
  }, []);

  // Make sure the timer is stopped when the component is unmounted
  useEffect(() => () => stopTimer(), [stopTimer]);

  return {startTimer, stopTimer};
}
