import {makeStyles} from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import React, {useState} from 'react';
import {IntercomAPI} from 'react-intercom';
import SettingsPaper from './SettingsPaper';
import {OrgSettingsField} from './OrgSettingsField';
import HoopsUpload from '../shared/HoopsUpload';
import {HoopsButton} from '../../componentsLib';
import {Chip, Divider, Grid, Typography} from '@mui/material';
import {hoopsTheme} from '../../theme';
import {AppAccess, PermissionBasedContent, permissionCheck} from '../../componentsHoops/AccessControl';

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: theme.spacing(3),
    '& .MuiTypography-h5': {marginBottom: theme.spacing(5)}
  },
  paperPadding: {padding: theme.spacing(5)},
  editIcon: {
    fontSize: '30px',
    cursor: 'pointer'
  },
  editField: {paddingRight: theme.spacing(5)},
  logo: {
    maxWidth: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  labelText: {
    color: theme.colors.grey.main,
    display: 'block'
  },
  subTitle: {marginTop: theme.spacing(2)},
  error: {color: theme.palette.error.main,}
}));

export const CompanyDetails = ({
  company,
  loading,
  saveSettings = () => null
}) => {
  const classes = useStyles();
  const [editing, setEditing] = useState(false);
  const [companyUpdate, setCompanyUpdate] = useState(company);

  const handleCancel = () => {
    setCompanyUpdate(company);
    toggleEdit();
  };

  const toggleEdit = () => {
    setEditing((prev) => !prev);
  };

  const handleCompanyEdit = (params) => {
    setCompanyUpdate((prev) => ({...prev, ...params}));
  };

  const handleSave = async () => {
    saveSettings(companyUpdate);
    setEditing(false);
    IntercomAPI('trackEvent', 'company_logo');
  };

  const handleChangeLogo = (file, index) => {
    const cloneCompany = {...companyUpdate};
    cloneCompany.companyTradingEntities[index].logo = file.url;
    handleCompanyEdit(cloneCompany);
  };

  const handleDeleteLogo = (index) => {
    const cloneCompany = {...companyUpdate};
    cloneCompany.companyTradingEntities[index].logo = null;
    handleCompanyEdit(cloneCompany);
  };

  const setAsDefault = (index) => {
    const cloneCompany = {...companyUpdate};
    const newDefault = cloneCompany?.companyTradingEntities?.splice(index, 1);
    cloneCompany?.companyTradingEntities?.splice(0, 0, ...newDefault);
    handleCompanyEdit(cloneCompany);
  };

  const handleAddTradingEntity = () => {
    const cloneCompany = {...companyUpdate};
    handleCompanyEdit(cloneCompany?.companyTradingEntities?.push({'name': null, 'phone': null, 'email': null, 'website': null, 'logo': null, 'addresses': []}));
  };

  const handleDeleteTradingEntity = (index) => {
    const cloneCompany = {...companyUpdate};
    handleCompanyEdit(cloneCompany?.companyTradingEntities?.splice(index, 1));
  };

  return (
    <SettingsPaper
      title={'Company Details'}
      editing={editing}
      loading={loading}
      onEdit={toggleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
      gridContainerProps={{spacing: 3}}
    >
      {companyUpdate?.companyTradingEntities?.map((entity, idx) => (
        <>
          {idx === 0 && editing && <Grid item xs={12}><Divider sx={{mt: 3}} /></Grid>}
          {idx > 0 && <Grid item xs={12}><Divider /></Grid>}
          {idx === 0 && <Grid item xs={12}><Chip sx={{background: hoopsTheme.colors.purple, color: hoopsTheme.colors.white}} size='small' label='Default' /></Grid>}
          {idx > 0 && editing &&
            <>
              <Grid item xs={6}><HoopsButton purpose='primary' onClick={() => setAsDefault(idx)}>Set as Default Company</HoopsButton></Grid>
              <Grid item xs={6} sx={{textAlign: 'right'}}><HoopsButton purpose='cancel' onClick={() => handleDeleteTradingEntity(idx)}><DeleteIcon color='secondary' sx={{mr: 1}} /> Delete Company</HoopsButton></Grid>
            </>
          }
          {editing
            ?
            <>
              {entity.logo
                ?
                <Grid item xs={3}>
                  <img alt='Company Logo' src={entity?.logo} style={{maxWidth: 300, width: '100%'}} />
                  <HoopsButton purpose='save' sx={{mt: 1}} onClick={() => handleDeleteLogo(idx)}>Upload New Logo</HoopsButton>
                </Grid>
                :
                <Grid item xs={3} >
                  <HoopsUpload onUploadComplete={(file) => handleChangeLogo(file, idx)} meta={{isWorkable: true}} accept={false} message='Recomended: 300px x 200px, clear background .png' />
                </Grid>
              }
            </>
            :
            <>
              {entity.logo
                ?
                <Grid item xs={3}>
                  <img alt='Company Logo' className={classes.logo} src={entity.logo || entity?.logo} style={{maxWidth: 300, width: '100%'}} />
                </Grid>
                :
                <Grid item xs={3}>
                  <Typography variant='body2' className={classes.labelText}>Your Logo</Typography>
                </Grid>
              }
            </>
          }
          <Grid item xs={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} className={editing ? classes.editField : null}>
                <OrgSettingsField
                  onChange={handleCompanyEdit}
                  company={companyUpdate}
                  embeddedArrayName={'companyTradingEntities'}
                  embeddedArrayIndex={idx}
                  editing={editing}
                  fieldName={'Company Name'}
                  fieldReference={'name'} />
              </Grid>
              <Grid item xs={12} className={editing ? classes.editField : null}>
                <OrgSettingsField
                  onChange={handleCompanyEdit}
                  company={companyUpdate}
                  embeddedArrayName={'companyTradingEntities'}
                  embeddedArrayIndex={idx}
                  editing={editing}
                  fieldName={'Phone'}
                  fieldReference={'phone'} />
              </Grid>
              <Grid item xs={12} className={editing ? classes.editField : null}>
                <OrgSettingsField
                  onChange={handleCompanyEdit}
                  company={companyUpdate}
                  embeddedArrayName={'companyTradingEntities'}
                  embeddedArrayIndex={idx}
                  editing={editing}
                  fieldName={'Website'}
                  fieldReference={'website'} />
              </Grid>
              <Grid item xs={12} className={editing ? classes.editField : null}>
                <OrgSettingsField
                  onChange={handleCompanyEdit}
                  company={companyUpdate}
                  embeddedArrayName={'companyTradingEntities'}
                  embeddedArrayIndex={idx}
                  editing={editing}
                  fieldName={'Company Email'}
                  fieldReference={'email'} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} className={editing ? classes.editField : null}>
                <OrgSettingsField
                  onChange={handleCompanyEdit}
                  company={entity}
                  embeddedArrayName={'addresses'}
                  embeddedArrayIndex={0}
                  editing={editing}
                  fieldName={'Address'}
                  fieldReference={'address1'} />
              </Grid>
              <Grid item xs={12} className={editing ? classes.editField : null}>
                <OrgSettingsField
                  onChange={handleCompanyEdit}
                  company={entity}
                  embeddedArrayName={'addresses'}
                  embeddedArrayIndex={0}
                  editing={editing}
                  fieldName={'State'}
                  fieldReference={'state'} />
              </Grid>
              <Grid item xs={12} className={editing ? classes.editField : null}>
                <OrgSettingsField
                  onChange={handleCompanyEdit}
                  company={entity}
                  embeddedArrayName={'addresses'}
                  embeddedArrayIndex={0}
                  editing={editing}
                  fieldName={'Country'}
                  fieldReference={'country'} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} className={editing ? classes.editField : null}>
                <OrgSettingsField
                  onChange={handleCompanyEdit}
                  company={entity}
                  embeddedArrayName={'addresses'}
                  embeddedArrayIndex={0}
                  editing={editing}
                  fieldName={'City'}
                  fieldReference={'city'} />
              </Grid>
              <Grid item xs={12} className={editing ? classes.editField : null}>
                <OrgSettingsField
                  onChange={handleCompanyEdit}
                  company={entity}
                  embeddedArrayName={'addresses'}
                  embeddedArrayIndex={0}
                  editing={editing}
                  fieldName={'Postcode'}
                  fieldReference={'postcode'} />
              </Grid>
            </Grid>
          </Grid>
          {editing && permissionCheck({allowedAppAccess: [AppAccess.MultiCompany]}) && idx === company?.companyTradingEntities?.length - 1 && <Grid item xs={12}><Divider /></Grid>}
        </>
      ))}
      {editing &&
        <Grid item xs={12}>
          <PermissionBasedContent allowedAppAccess={[AppAccess.MultiCompany]}>
            <HoopsButton purpose='save' onClick={() => handleAddTradingEntity()}>Add another company</HoopsButton>
          </PermissionBasedContent>
        </Grid>
      }
    </SettingsPaper>
  );
};
