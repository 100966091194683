import {SET_ACTIVE_IMPORT} from '../actions/catalogImport';
import {get} from 'lodash';

export const initialState = {
  activeStep: 0,
  currentImportSettings: {},
  sampleData: {},
  sampleDataLoading: false,
  dataSummary: {
    products: 0,
    variants: 0
  },
  activeImport: {},
  importInProgress: false
};

const catalogImportReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_IMPORT: {
      const status = get(action.payload, 'status', 'COMPLETE');
      return {
        ...state,
        activeImport: action.payload,
        importInProgress: (status !== 'COMPLETE' && status !== 'UNDONE')
      };
    }
    default:
      return state;
  }
};

export default catalogImportReducer;
