// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon, IconButton, ListItemIcon, Menu, MenuItem
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import {upperFirst} from 'lodash';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Link as RouterLink} from 'react-router-dom';
import {deletePurchaseOrder, sendToAccounting} from '../../actions/purchaseOrders';
import SendPurchaseOrderForm from '../../pages/purchaseOrders/SendPurchaseOrderForm';
import SendDialog from '../modal/SendDialog';

const ConfirmDeleteModal = ({
  onConfirm = () => null,
  onCancel = () => null,
  open = false,
  poNumber = ''
}) => (<Dialog open={open}>
    <DialogTitle>Are you sure?</DialogTitle>
    <DialogContent>
      <DialogContentText>Are you sure you want to <b>Purchase Order Number {poNumber}</b>?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} color='secondary'>
        Cancel
      </Button>
      <Button onClick={onConfirm} color='primary'>
        Ok
      </Button>
    </DialogActions>
  </Dialog>);

const PurchaseOrdersActionMenu = ({
  purchaseOrder = {},
  buttonType = '',
  platform = null
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [sendOpen, setSendOpen] = useState(false);
  const dispatch = useDispatch();
  const pdfLink = `${process.env.REACT_APP_BASE_URL}/purchase-orders/${purchaseOrder._id}/pdf`;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = (_id) => {
    dispatch(deletePurchaseOrder(_id));
    handleClose();
  };

  const handleSendToAccounting = (_id) => {
    dispatch(sendToAccounting(_id));
    handleClose();
  };

  const handleCloseSendDialog = async () => {
    setSendOpen(false);
    setAnchorEl(null);
  };

  const handleOpenSendDialog = async () => {
    setSendOpen(true);
    setAnchorEl(null);
  };

  return (
    <>
      {sendOpen &&
        <SendDialog
          open={sendOpen}
          closeDialog={handleCloseSendDialog}
          title='Send Purchase Order'
          formComponent={<SendPurchaseOrderForm purchaseOrder={purchaseOrder} setSendOpen={setSendOpen} />}
        />
      }
      <ConfirmDeleteModal open={modalOpen} onConfirm={() => handleDelete(purchaseOrder._id)} onCancel={() => setModalOpen(false)} poNumber={purchaseOrder.number} />
      <IconButton
        onClick={handleClick}
        // buttonType={buttonType}
        size={'small'}
      >
        <Icon>more_horiz</Icon>
      </IconButton>
      <Menu
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {!purchaseOrder.masterPurchaseOrderId &&
          <RouterLink to={`/purchase-orders/${purchaseOrder._id}/edit`} style={{textDecoration: 'none', outline: 'none', color: 'inherit'}}>
            <MenuItem>
              <ListItemIcon>
                <Icon>edit</Icon>
              </ListItemIcon>
              Edit
            </MenuItem>
          </RouterLink>
        }
        <MenuItem onClick={handleOpenSendDialog}>
          <ListItemIcon>
            <Icon>send</Icon>
          </ListItemIcon>
          Send
        </MenuItem>
        <MenuItem component={Link}
          href={pdfLink}
          color={'inherit'}
          underline={'none'}
          onClick={handleClose}
          target={'_blank'}>
          <ListItemIcon>
            <Icon>description</Icon>
          </ListItemIcon>
          PDF
        </MenuItem>
        {!purchaseOrder.masterPurchaseOrderId &&
          <MenuItem onClick={() => setModalOpen(true)}>
            <ListItemIcon>
              <Icon>delete</Icon>
            </ListItemIcon>
            Delete
          </MenuItem>
        }
        {platform &&
          <MenuItem onClick={() => handleSendToAccounting(purchaseOrder._id)} disabled={purchaseOrder.accountingSync}>
            <ListItemIcon>
              <Icon>description</Icon>
            </ListItemIcon>
            {purchaseOrder.accountingSync
              ? 'Sent to '
              : 'Send to '
            }
            {platform
              ? upperFirst(platform.toLowerCase())
              : 'Accounting'
            }
          </MenuItem>
        }
      </Menu>
    </>
  );
};

export default PurchaseOrdersActionMenu;
