import {
  SET_PROOF,
  SET_PROOFS,
  UPDATE_ACTIVE_PROOF
} from '../actions/proofs';

const initialState = {
  pendingProofs: [],
  activeProof: {
    files: [],
   notes: []
  },
  proofs: []
};

const proofReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ACTIVE_PROOF:
      return {
        ...state,
        activeProof: action.payload
      };
    case SET_PROOFS:
      return {
        ...state,
        proofs: action.payload
      };
    case SET_PROOF:
      return {
        ...state,
        activeProof: {
          ...state.activeProof,
          ...action.payload
        }
      };

    default:
      return {...state};
  }
};

export default proofReducer;
