import * as AWS from 'aws-sdk';
import * as uuidv4 from 'uuid/v4';

AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: new AWS.CognitoIdentityCredentials({IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,})
});

const s3 = new AWS.S3({
  apiVersion: '2006-03-01',
  endpoint: (process.env.REACT_APP_SERVERLESS_OFFLINE === 'true' ?
    'http://localhost:4572' : undefined),
  s3ForcePathStyle: (process.env.REACT_APP_SERVERLESS_OFFLINE === 'true' ?
    true : undefined),
  params: {Region: process.env.REACT_APP_AWS_REGION}
});

class S3UploadService {
  upload(file, s3Params) {
    const managedUpload = s3.upload({
      Key: uuidv4(),
      Body: file,
      ACL: 'public-read',
      Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      ContentDisposition: `filename=${file.path}`,
      ...s3Params
    });

    return managedUpload;
  }
}

export default S3UploadService;
