// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-shadow */

import React, {useState, useEffect} from 'react';
import {MenuItem, Chip, Menu, makeStyles} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles((theme) => ({
    chip: {color: 'white',},
    arrowIcon: {color: 'white'},
    listItem: {'&:active': {backgroundColor: 'transparent'},}
}));

export default ({
    statusGroup = {statuses: []},
    disabled,
    value,
    onChange = () => null,
    ...chipProps
}) => {
    const {statuses} = statusGroup;
    const [anchorEl, setAnchorEl] = useState(null);
    const [activeChip, setActiveChip] = useState(null);
    const classes = useStyles();

    const onOptionChange = (index) => {
        handleMenuClose();
        const newActiveOption = statuses[index];
        setActiveChip(newActiveOption);
        onChange(newActiveOption);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuOpen = (e) => {
        setAnchorEl(e.currentTarget);
    };

    useEffect(
        () => {
            const status = statuses
                .find(
                    (status) => status.value === value
                ) || statuses[0];

            setActiveChip(status);
        },
        [statuses, value]
    );

    return (
        <>
            {activeChip ?
                <Chip
                    label={activeChip.label}
                    className={classes.chip}
                    deleteIcon={<KeyboardArrowDownIcon className={classes.arrowIcon} />}
                    onDelete={disabled ? null : handleMenuOpen}
                    onClick={disabled ? null : handleMenuOpen}
                    style={{
                        cursor: (disabled ? 'inherit' : 'pointer'),
                        backgroundColor: activeChip.color
                    }}
                    {...chipProps}
                /> :
                <Chip
                    label={''}
                    className={classes.chip}
                    deleteIcon={!disabled ? <KeyboardArrowDownIcon className={classes.arrowIcon} /> : null}
                    onDelete={disabled ? null : handleMenuOpen}
                    onClick={disabled ? null : handleMenuOpen}
                    style={{
                        cursor: (disabled ? 'inherit' : 'pointer'),
                        // backgroundColor: fallback.color
                    }}
                    {...chipProps}
                />
            }
            {!disabled && <Menu
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                {statuses && statuses.map((option, index) => (
                        <MenuItem disableRipple={true} key={index} className={classes.listItem}
                            style={{backgroundColor: 'transparent'}}>
                            <Chip
                                label={option.label}
                                className={classes.chip}
                                style={{backgroundColor: option.color}}
                                onClick={() => onOptionChange(index)}
                                {...chipProps}
                            />
                        </MenuItem>
                    ))}
            </Menu>}
        </>
    );
};
