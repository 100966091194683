import React from 'react';
import {Grid, Button, Icon, Typography, withStyles} from '@material-ui/core';

 export const LowerCaseButton = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    fontWeight: theme.typography.body2.fontWeight
  }
}))(Button);

 class HelpArticle extends React.Component {
  render() {
    const {link, buttonText = 'Read the Docs'} = this.props;
    return (
      <Grid container spacing={1} alignItems={'center'} justifyContent={'center'}>
        <Grid item>
          <Typography variant={'body2'}>Need Help? </Typography>
        </Grid>
        <Grid item>
          <LowerCaseButton href={link}
            target={'blank'}
            variant={'text'}
            color={'primary'}>
              <Icon style={{marginRight: '4px'}}>bookmark</Icon>
              {buttonText}
          </LowerCaseButton>
        </Grid>
      </Grid>
    );
  }
}

 export default HelpArticle;
