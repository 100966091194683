import {Chip, MuiThemeProvider, Paper, Table, TableContainer} from '@material-ui/core';
import React, {useContext} from 'react';
import {useDispatch} from 'react-redux';
import {HoopsQuery, HoopsQueryContext, HoopsQueryEmptyState, HoopsQueryPagination, HoopsQueryTableBody, HoopsQueryTableHeader} from '../../componentsOld/HoopsQuery';
import {HoopsModalContext} from '../../componentsOld/shared/HoopsModalContext';
import green from '../../ui/theme/green';
import red from '../../ui/theme/red';
import {deleteAutomation, duplicateAutomation} from './actions';
import AutomationManyTableActionMenu from './AutomationManyTableActionMenu';
import {GQL_AUTOMATION_PAGINATION} from './queries';

const entityTypeMap = {
  quote: 'Classic Quote',
  job: 'JobBoard',
  salesdoc: 'SalesBoard',
};

const AutomationManyTable = ({
    fixedFilter = {},
    queryStateId = 'automationMany',
}) => {
    const dispatch = useDispatch();
    const {setActiveId, setOpen} = useContext(HoopsModalContext);

    const columns = [
        {
            label: 'Entity',
            field: 'entityType',
            render: (rowData) => <div style={{textTransform: 'capitalize'}}>{entityTypeMap[rowData.entityType] ?? capitalize(rowData.entityType)}</div>
        }, {
            label: 'Rule type',
            field: 'trigger',
            render: (rowData) => <div style={{textTransform: 'capitalize'}}>{capitalize(rowData.trigger)}</div>
        }, {
            label: 'Automation name',
            field: 'name',
        }, {
            label: 'Status',
            field: 'isDisabled',
            render: (rowData) =>
                rowData.isDisabled
                        ? <MuiThemeProvider theme={red}><Chip size='small' label='DISABLED' color='primary' style={{fontWeight: 500}}/></MuiThemeProvider>
                        : <MuiThemeProvider theme={green}><Chip size='small' label='ACTIVE' color='primary' style={{fontWeight: 500}}/></MuiThemeProvider>
        }, {
            label: 'Actions',
            align: 'right',
            render: (rowData) => <AutomationManyTableActionMenu rowData={rowData} handleDelete={handleDelete} openEditModal={openEditModal} duplicateLineItem={duplicateLineItem} />
        }
    ];

    const capitalize = (str) => {
        str = str.replace(/([A-Z])/g, ' $1').trim();
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    const handleDelete = (e, id) => {
        dispatch(deleteAutomation(id));
    };

    const openEditModal = (e, id) => {
        setActiveId(id);
        setOpen(true);
    };

    const duplicateLineItem = (e, id) => {
        dispatch(duplicateAutomation(id));
    };

    return (
        <HoopsQuery refetchStateId='automation' queryStateId={queryStateId} gqlQuery={GQL_AUTOMATION_PAGINATION} resultField='automationPagination' initialFilter={{}} fixedFilter={fixedFilter} initialSort={'_ID_ASC'}>
            <HoopsQueryContext.Consumer>
                {({items, sort, setSort, filter, setFilter, paging, setPaging, setChips}) => (<>
                        <TableContainer component={Paper}>
                            <Table style={{minWidth: 'auto'}}>
                                <HoopsQueryTableHeader hasCheckBox={false} columns={columns} onSort={setSort} sortString={sort} />
                                <HoopsQueryTableBody rowsPerPage={paging.perPage} rows={items} columns={columns} emptyStateComponent={() => <HoopsQueryEmptyState filter={filter} columns={columns} field={'Automation'} rowsPerPage={paging.perPage} onClearFilter={setFilter} onChipsChange={setChips} onAddItem={() => setOpen(true)} />} />
                            </Table>
                        </TableContainer>
                        <HoopsQueryPagination paging={paging} onPaging={setPaging} />
                    </>)}
            </HoopsQueryContext.Consumer>
        </HoopsQuery>
    );
};

export default AutomationManyTable;
