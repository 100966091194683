import React, {useState} from 'react';
import {GlobalStyles} from '@mui/system';
import {hoopsTheme} from './hoopsTheme';

const allStyles = {};
function globalStyles() {
  console.log('Create global styles');
  return Object.keys(allStyles).reduce((acc, key) => {
    acc[key] = typeof allStyles[key] !== 'function' ? allStyles[key] : allStyles[key](hoopsTheme);
    return acc;
  }, {});
}

// A hack so that when styles are changed in a file we will rerender them
let useForceRerender = () => null;
let forceRerender;
let forceRerenderIgnore;
if (process.env.NODE_ENV === 'development') {
  useForceRerender = () => {
    [, forceRerender] = useState({});
  };
}

export function registerGlobalStyle(baseSelector, styles) {
  allStyles[baseSelector] = styles;

  if (forceRerender && !forceRerenderIgnore) {
    console.log(`Force rerender global styles: ${baseSelector} allStyles=`, allStyles);
    forceRerender((oldForce) => {
      if (oldForce[baseSelector]) {
        console.log('Break global styles loop');
        forceRerenderIgnore = true;
        setTimeout(() => {
          forceRerenderIgnore = false;
          forceRerender({});
'';        }, 5000);
        return oldForce;
      }
      return {...oldForce, [baseSelector]: 1};
    });
  }
}

export function HoopsGlobalStyles() {
  useForceRerender();

  return <GlobalStyles styles={globalStyles}/>;
}
