export const HOOPS_QUERY_SET_REFETCH = 'HOOPS_QUERY_SET_REFETCH';
export const HOOPS_QUERY_SET_QUERY = 'HOOPS_QUERY_SET_QUERY';

export const HOOPS_QUERY_SET_QUERY_FILTER = 'HOOPS_QUERY_SET_QUERY_FILTER';
export const HOOPS_QUERY_SET_QUERY_SORT = 'HOOPS_QUERY_SET_QUERY_SORT';
export const HOOPS_QUERY_SET_QUERY_PAGING = 'HOOPS_QUERY_SET_QUERY_PAGING';

export const HOOPS_QUERY_SET_VIEW = 'HOOPS_QUERY_SET_VIEW';

/**
 * Setting the state to true tells the listner to re-run the query
 * @param {string} refetchStateId
 * @param {boolean} state
 */
export const hoopsQuerySetRefetch = (refetchStateId, state = false) => ((dispatch) => {
        dispatch({
            type: HOOPS_QUERY_SET_REFETCH,
            payload: {
                refetchStateId,
                state
            }
        });
    });

/**
 * Setting the state to true tells the listner to re-run the query
 * @param {string} queryStateId
 * @param {bolean} state
 */
export const hoopsQuerySetQuery = (queryStateId, query = {}) => ((dispatch) => {
        dispatch({
            type: HOOPS_QUERY_SET_QUERY,
            payload: {
                queryStateId,
                query
            }
        });
    });

export const hoopsQuerySetQueryFilter = (queryStateId, filter = {}) => ((dispatch) => {
        dispatch({
            type: HOOPS_QUERY_SET_QUERY_FILTER,
            payload: {
                queryStateId,
                filter
            }
        });
    });

export const hoopsQuerySetQueryPaging = (queryStateId, paging = {}) => ((dispatch) => {
        dispatch({
            type: HOOPS_QUERY_SET_QUERY_PAGING,
            payload: {
                queryStateId,
                paging
            }
        });
    });

export const hoopsQuerySetQuerySort = (queryStateId, sort = '_ID_ASC') => ((dispatch) => {
        dispatch({
            type: HOOPS_QUERY_SET_QUERY_SORT,
            payload: {
                queryStateId,
                sort
            }
        });
    });

export const hoopsQuerySetView = (queryStateId, view = 'table') => ((dispatch) => {
        dispatch({
            type: HOOPS_QUERY_SET_VIEW,
            payload: {
                queryStateId,
                view
            }
        });
    });
