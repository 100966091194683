import {makeStyles} from '@material-ui/core';
import {ContentState, convertToRaw, EditorState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {memoize} from 'lodash/function';
import React, {useEffect, useState} from 'react';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const useStyles = makeStyles((theme) => (
  {
    wrapper: {
      padding: '5px',
      border: 'solid',
      borderWidth: 1,
      borderColor: theme.colors.grey.light,
    },
    editor: {
      paddingBottom: theme.spacing(10),
      minHeight: 206,
      maxHeight: 450,
      overflowY: 'scroll'
    },
    error: {
      padding: '5px',
      border: 'solid',
      borderWidth: 1,
      borderColor: theme.colors.red,
    }
  }
));

const initialEditorState = (value) => {
  const dynamicCode = value.slice(0,9);
  let updatedValue = '';
  if(dynamicCode === '#DYNAMIC#'){
    updatedValue = value.slice(10);
  } else {
    updatedValue = value;
  }
  const blocks = htmlToDraft(updatedValue || '');
  const contentState = ContentState.createFromBlockArray(blocks.contentBlocks);
  return EditorState.createWithContent(contentState);
};

export const EditorField = ({input, error = false , ...props}) => {
  const classes = useStyles();
  const [editorState, setEditorState] = useState(initialEditorState(input.value));

  const onEditorStateChange = memoize((newEditorState) => {
    setEditorState(newEditorState);
  });

  useEffect(() => {
    setEditorState(initialEditorState(input.value));
  }, [input.value]);
  const onBlur = () => {
    if (input.onChange) {
      input.onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    }
  };
  
  return (
    <div id={'react-wysiwyg'}>
      <Editor
        onBlur={onBlur}
        editorState={editorState}
        wrapperClassName={error ? classes.error : classes.wrapper}
        editorClassName={classes.editor}
        toolbarClassName={'toolbar-class'}
        onEditorStateChange={onEditorStateChange}
        {...props}
      />
    </div>
  );
};

export default EditorField;

