import {Box, Grid, Typography} from '@mui/material';
import * as React from 'react';
import {useSelector} from 'react-redux';
import {BusySpinner, StatusLabel} from '../../componentsLib/Basic';
import {asCurrencyStringCommaSeparated, commaSeparated, formatDateRelative, formatDateTimeRelative} from '../../utils';
import {useGetContact, useGetCustomer, useGetUser} from '../../hooks/api';

export function ActivityContactRenderer({change, ...props}) {
  const {data: {contact: contactOld}, isLoading: isLoadingOld} = useGetContact(change[0]);
  const {data: {contact: contactNew}, isLoading: isLoadingNew} = useGetContact(change[1]);

  return <ActivityValueRenderer change={[contactOld?.fullName, contactNew?.fullName]} isLoading={isLoadingOld || isLoadingNew} {...props}/>;
}

export function ActivityCurrencyRenderer({change, ...props}) {
  const currencySymbol = useSelector((state) => state?.authReducer.userData.company.currencySymbol);

  const currencyChange = [
    change[0] != null ? `${currencySymbol}${asCurrencyStringCommaSeparated(change[0])}` : undefined,
    change[1] != null ? `${currencySymbol}${asCurrencyStringCommaSeparated(change[1])}` : undefined
  ];
  return <ActivityValueRenderer change={currencyChange} {...props}/>;
}

export function ActivityCustomerRenderer({change, ...props}) {
  const {data: {customer: customerOld}, isLoading: isLoadingOld} = useGetCustomer(change[0]);
  const {data: {customer: customerNew}, isLoading: isLoadingNew} = useGetCustomer(change[1]);

  return <ActivityValueRenderer change={[customerOld?.name, customerNew?.name]} isLoading={isLoadingOld || isLoadingNew} {...props}/>;
}

export function ActivityDateRenderer({activity, title, change, omitPreviousUndefined}) {
  return (
    <>
      <Typography variant='caption'>{title}</Typography>
      <Grid className='change-item text' container direction='row' gap={1.5}>
        {activity.action !== 'CREATE' && (!omitPreviousUndefined || change[0] != null) &&
          <>
            <Typography variant='body2' className={change[0] == null ? 'nothing' : ''}>{change[0] != null && formatDateRelative(change[0])}</Typography>
            <span>→</span>
          </>
        }
        <Typography variant='body2' className={change[1] == null ? 'nothing' : ''}>{change[1] != null && formatDateRelative(change[1])}</Typography>
      </Grid>
    </>
  );
}

export function ActivityDateTimeRenderer({activity, title, change, omitPreviousUndefined}) {
  return (
    <>
      <Typography variant='caption'>{title}</Typography>
      <Grid className='change-item text' container direction='row' gap={1.5}>
        {activity.action !== 'CREATE' && (!omitPreviousUndefined || change[0] != null) &&
          <>
            <Typography variant='body2' className={change[0] == null ? 'nothing' : ''}>{formatDateTimeRelative(change[0])}</Typography>
            <span>→</span>
          </>
        }
        <Typography variant='body2' className={change[1] == null ? 'nothing' : ''}>{formatDateTimeRelative(change[1])}</Typography>
      </Grid>
    </>
  );
}

export function ActivityDefaultRenderer({title, change}) {
  {
    return (
      <Grid className='change-item text' container direction='row' gap={1.5}>
        <Typography variant='body2'>
          {change[0]?.length < change[1].length && 'Added '}
          {change[0]?.length > change[1].length && 'Removed '}
          {change[0]?.length === change[1].length && 'Updated '}
          {title}
        </Typography>
      </Grid>
    );
  }
}

export function ActivityEmailRenderer({activity: {body: email, ...activity}}) {
  return (
    <Grid>
      <Box className={'email-item activity-fields'}>
        <AutomationIdentRenderer activity={activity}/>
        <Typography variant={'caption'}>To</Typography>
        <Typography variant={'body2'}>{commaSeparated(email.to)}</Typography>
        {email.error &&
          <>
            <Typography variant={'caption'}>Error</Typography>
            <Typography variant={'body2'}>{email.error}</Typography>
          </>
        }
        {email.cc &&
          <>
            <Typography variant={'caption'}>CC</Typography>
            <Typography variant={'body2'}>{commaSeparated(email.cc)}</Typography>
          </>
        }
        {email.bcc &&
          <>
            <Typography variant={'caption'}>BCC</Typography>
            <Typography variant={'body2'}>{commaSeparated(email.bcc)}</Typography>
          </>
        }
        {email.replyTo &&
          <>
            <Typography variant={'caption'}>Reply To</Typography>
            <Typography variant={'body2'}>{email.replyTo}</Typography>
          </>
        }
        <Typography variant={'caption'}>Subject</Typography>
        <Typography variant={'body2'}>{email.subject}</Typography>
      </Box>
      {email.body &&
        <div className='email-body' dangerouslySetInnerHTML={{__html: email.body.replaceAll('\n', '<br>')}} />
      }
    </Grid>
  );
}

export function ActivitySmsRenderer({activity: {body: sms, ...activity}}) {
  const errors = sms.results?.filter((res) => !!res.error) ?? [];
  return (
    <Grid>
      <Box className={'sms-item activity-fields'}>
        <AutomationIdentRenderer activity={activity}/>
        <Typography variant={'caption'}>To</Typography>
        <Typography variant={'body2'}>{sms.to?.join(', ')}</Typography>
      </Box>
      {sms.message &&
        <Grid item className='sms-body'>
          <Typography variant={'body2'}>{sms.message}</Typography>
        </Grid>
      }
      {(sms.error || errors.length > 0) &&
        <Box className={'sms-error'}>
          {sms.error &&
            <>
              <Typography variant={'caption'}>Error</Typography>
              <Typography variant={'body2'}>{sms.error}</Typography>
            </>
          }
          {errors.length > 0 && errors.map((res) => (
            <>
              <Typography variant={'caption'}>To</Typography>
              <Typography variant={'body2'}>{res.to}</Typography>
              <Typography variant={'caption'}>Error</Typography>
              <Typography variant={'body2'}>{res.error}</Typography>
            </>
          ))
          }
        </Box>
      }
    </Grid>
  );
}

export function ActivityStatusRenderer({activity, fieldInfo, title, change, omitPreviousUndefined}) {
  const options = [
    fieldInfo.options?.find((o) => o.statusLabel === change[0]) ?? change[0],
    fieldInfo.options?.find((o) => o.statusLabel === change[1]) ?? change[1],
  ];
  return (
    <>
      <Typography variant='caption'>{title}</Typography>
      <Grid className='change-item status' container direction='row' gap={1.5} alignItems='center'>
        {activity.action !== 'CREATE' && (!omitPreviousUndefined || change[0] != null) &&
          <>
            <StatusLabel color={options[0]?.color}>{options[0]?.display ?? change[0]}</StatusLabel>
            <span>→</span>
          </>
        }
        <StatusLabel color={options[1]?.color}>{options[1]?.display ?? change[1]}</StatusLabel>
      </Grid>
    </>
  );
}

export const ActivityTextRenderer = ActivityValueRenderer;

export function ActivityValueRenderer({activity, title, change, omitPreviousUndefined, isLoading}) {
  return (
    <>
      {isLoading && <BusySpinner/>}
      {!isLoading &&
        <>
          <Typography variant='caption'>{title}</Typography>
          <Grid className='change-item text' container direction='row' gap={1.5}>
          {activity.action !== 'CREATE' && (!omitPreviousUndefined || change[0] != null) &&
            <>
            <Typography variant='body2' className={change[0] ? '' : 'nothing'}>{change[0]?.toString()}</Typography>
            <span>→</span>
            </>
          }
          <Typography variant='body2' className={change[1] ? '' : 'nothing'}>{change[1]?.toString()}</Typography>
          </Grid>
        </>
      }
    </>
  );
}

export function ActivityUserRenderer({change, ...props}) {
  const {data: {user: userOld}, isLoading: isLoadingOld} = useGetUser(change[0]);
  const {data: {user: userNew}, isLoading: isLoadingNew} = useGetUser(change[1]);

  return <ActivityValueRenderer change={[userOld?.fullName, userNew?.fullName]} isLoading={isLoadingOld || isLoadingNew} {...props}/>;
}

export function AutomationIdentRenderer({activity}) {
  if (activity.user !== 'Automation' || !activity.originator?.name) {
    return null;
  }
  return (
    <>
      <Typography variant={'caption'}>Automation</Typography>
      <Typography variant={'body2'}>{activity.originator.name}</Typography>
    </>
  );
}
