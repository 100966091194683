import React from 'react';
import PropTypes from 'prop-types';
import {Icon, IconButton, ListItemIcon, Menu, MenuItem,} from '@material-ui/core';

import VendorDialog from './VendorDialog';

import {deleteVendor, setVendor, getVendor} from '../../actions/action-types';
import ConfirmDeleteDialog from '../confirmDeleteDialog';
import {connect} from 'react-redux';

class VendorMenuCell extends React.Component {
  state = {
    anchorEl: null,
    confirmDelete: false,
    editModalOpen: false
  };

  handleClick = (event) => {
    this.setState({anchorEl: event.currentTarget});
  };

  handleClose = () => {
    this.setState({anchorEl: null});
  };

  openConfirmDelete = () => {
    this.setState({confirmDelete: !this.state.confirmDelete});
  };

  openEditModal = () => {
    // Get a new copy of the vendor
    this.props.dispatch(getVendor(this.props.rowData._id));
    this.setState({editModalOpen: true, anchorEl: null});
  };

  editModalHandleClose = () => {
    this.props.dispatch(setVendor({}));
    this.setState({editModalOpen: false});
  };

  deleteVendor = () => {
    this.handleClose();
    return this.props.dispatch(deleteVendor(this.props.rowData));
  };

  render() {
    const {anchorEl} = this.state;
    const {rowData} = this.props;

    return (
      <div key={rowData._id}>
        <IconButton
          onClick={this.handleClick}
          variant='outlined'
          size={'small'}
        >
          <Icon>more_horiz</Icon>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.openEditModal}>
            <ListItemIcon>
              <Icon>edit</Icon>
            </ListItemIcon>
            Edit
          </MenuItem>
          <MenuItem onClick={this.openConfirmDelete}>
            <ListItemIcon>
              <Icon>delete</Icon>
            </ListItemIcon>
            Delete
            <ConfirmDeleteDialog name={rowData.name} isOpen={this.state.confirmDelete} ok={this.deleteVendor}/>
          </MenuItem>
        </Menu>
        <VendorDialog data={rowData} isOpen={this.state.editModalOpen} onClose={this.editModalHandleClose} />
      </div>
    );
  }
}

VendorMenuCell.propTypes = {rowData: PropTypes.object};

export default connect()(VendorMenuCell);
