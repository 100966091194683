import React, {Component} from 'react';
import JobVariantsModal from './shared/modals/JobVariationsModal/JobVariantsModal';
import ProofUploadModal from './shared/modals/ProofUploadModal/ProofUploadModal';
import {ProofViewModal} from './shared/modals/JobProofsModals/ProofViewModal';
import ProofNotesModal from './shared/modals/JobProofsModals/ProofNotesModal/ProofNotesModal';
import SendProofModal from './shared/modals/JobProofsModals/SendProofModal';
import PurchaseOrderVariantsModal from './shared/modals/PurchaseOrderVariationsModal/PurchaseOrderVariantsModal';
import CustomerModal from './customers/modal/CustomerModal';
import MergePurchaseOrderVendorModal from './shared/modals/PurchaseOrdersModals/MergePurchaseOrderVendorModal';
import MergePurchaseOrderModal from './shared/modals/PurchaseOrdersModals/MergePurchaseOrderModal';
import ViewPurchaseOrderModal from './shared/modals/PurchaseOrdersModals/ViewPurchaseOrderModal';
import QuoteTermsModal from './quotes/QuoteTermsModal';
import UploadModal from './shared/modals/PurchaseOrdersModals/UploadModal';
import POShipToModal from './purchase-orders/POShipToModal';
import SendQuoteDialog from './quotes/SendQuoteDialog';
import JobNoteModal from './shared/modals/JobNoteModal';
import JobArtworkModal from './shared/modals/JobArtworkModal';
import CatalogModal from './shared/modals/CatalogModal/CatalogModal';
import ProductPriceVariantsModal from './shared/modals/ProductPriceVariantsModal/ProductPriceVariantsModal';
import CreateProductVariationModal from './shared/modals/CreateProductVariationModal/CreateProductVariationModal';
import TagModal from './shared/modals/TagModal/TagModal';
import JobDeleteModal from './shared/modals/JobDeleteModal';
import InvoiceModal from './invoice/InvoiceModal';
import ShareProductModal from './Products/ShareProductModal';
import TaskEditModal from '../modules/task/TaskEditModal';
import ScheduleEditModal from '../modules/schedule/ScheduleEditModal';
import SchedulePreviewModal from '../modules/schedule/SchedulePreviewModal';
import ResourceModal from '../modules/schedule/ResourceModal';

class Modals extends Component {
  render() {
    return (
      <>
        <JobVariantsModal/>
        <ProofUploadModal/>
        <ProofViewModal />
        <ProofNotesModal />
        <SendProofModal />
        <PurchaseOrderVariantsModal />
        <CustomerModal />
        <MergePurchaseOrderVendorModal />
        <MergePurchaseOrderModal />
        <ViewPurchaseOrderModal />
        <QuoteTermsModal/>
        <UploadModal />
        <POShipToModal />
        <SendQuoteDialog />
        <JobNoteModal />
        <JobArtworkModal />
        <CatalogModal />
        <ProductPriceVariantsModal />
        <CreateProductVariationModal />
        <TagModal />
        <JobDeleteModal/>
        <InvoiceModal />
        <ShareProductModal />
        <TaskEditModal />
        <ScheduleEditModal />
        <SchedulePreviewModal />
        <ResourceModal />
      </>
    );
  }
}

export default Modals;
