import {gql} from 'apollo-boost';

const GQL_MARKUP_DETAIL_FRAGMENT = gql`
  fragment MarkupDetailFragment on GCMarkup {
    _id
    name
    markupRanges {
      _id
      lowerPrice
      upperPrice
      percentage
      quantity
    } 
  }
`;

export const GQL_MARKUP_LIST_FRAGMENT = gql`
  fragment MarkupListFragment on GCMarkup {
    _id
    name
    markupRanges {
      _id
      lowerPrice
      upperPrice
      percentage
      quantity
    } 
  }
`;

export const GQL_GET_MARKUP = gql`
  ${GQL_MARKUP_DETAIL_FRAGMENT}
  query getMarkup($_id: MongoID!) {
    markupOne(filter: { _id: $_id }) {
      ...MarkupDetailFragment
    }
  }
`;

export const GQL_GET_MARKUPS = gql`
  ${GQL_MARKUP_LIST_FRAGMENT}
  query getMarkups($filter: FilterFindManySearchGCMarkupInput, $sort: SortFindManyGCMarkupInput) {
    markupMany(filter: $filter, sort: $sort) {
      ...MarkupListFragment
    }
  }
`;

export const GQL_CREATE_MARKUP = gql`
  ${GQL_MARKUP_DETAIL_FRAGMENT}
  mutation createMarkup($record: CreateOneGCMarkupInput!) {
    markupCreateOne(record: $record) {
      recordId
      record {
        ...MarkupDetailFragment
      }
    }
  }
`;

export const GQL_UPDATE_MARKUP = gql`
  ${GQL_MARKUP_DETAIL_FRAGMENT}
  mutation updateMarkup($_id: MongoID!, $record: UpdateByIdGCMarkupInput!) {
    markupUpdateById(_id: $_id, record: $record) {
      recordId
      record {
        ...MarkupDetailFragment
      }
    }
  }
`;

export const GQL_DELETE_MARKUP = gql`
  mutation deleteMarkup($_id: MongoID!) {
    markupRemoveById(_id: $_id) {
      recordId
    }
  }
`;

export const GQL_MARKUP_PAGINATION = gql`
  query GCMarkupPagination(
    $filter: FilterFindManySearchGCMarkupInput
    $sort: SortFindManyGCMarkupInput
    $perPage: Int
    $page: Int
  ) {
    markupPagination(
      filter: $filter
      sort: $sort
      perPage: $perPage
      page: $page
    ) {
      items {
        ...MarkupListFragment
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${GQL_MARKUP_LIST_FRAGMENT}
`;

export const GQL_GET_MARKUP_TREE = gql`
  query markupTree{
    productsVendorBrandCategorySubcategoryTree{
      _id
      count
      type
      name
      children {
        count
        type
        name
        children {
          count
          type
          name
          children {
            count
            type
            name
          }
        }
      }
    } 
    decorationsVendorBrandCategorySubcategoryTree{
      _id
      count
      type
      name
      children {
        count
        type
        name
        children {
          count
          type
          name
          children {
            count
            type
            name
          }
        }
      }
    } 
    
    vendorMany(filter: null, limit: null) {
      _id
      name
      
    }
    
    defaultMarkupAssignmentFind{
      type
      markupId
      vendorId
      brand
      category
      subCategory 
      markup {
        name
      }
    }  
  }`;

export const GQL_GET_ASSIGNED_MARKUPS = gql`
query assignedMarkups{
  defaultMarkupAssignmentFind{
    type
    markupId
    vendorId
    brand
    category
    subCategory 
    markup {
      name
    }
  }  
}`;

export const GQL_UPDATE_DEFAULT_MARKUP_ASSIGNMENT = gql`
  mutation updateDefaultMarkupAssignmentReplace($data: [DefaultMarkupAssignmentCreateOneInput!]!){
    defaultMarkupAssignmentReplace(data:$data){
      _id
    }  
  }`;
