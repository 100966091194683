import {useQuery} from '@apollo/client';
import {Box, Grid, Typography, List, ListItem, ListItemText} from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import {DayPilot} from 'daypilot-pro-react';
import gql from 'graphql-tag';
import React, {useState} from 'react';
import HoopsDataFilterBar from '../../componentsOld/shared/HoopsDataFilterBar';
import HoopsSearchDataFilter from '../../componentsOld/shared/HoopsSearchDataFilter';
import {themeOptions} from '../../ui/theme';
import {get} from 'lodash';
import {FormatDate} from '../../componentsOld/shared/FormatDate';

const QUERY_JOBS_SIMPLE = gql`
    query getJobsSimple($q: String) {
        jobs(q: $q, page: 1, pageLimit: 50, archived: false) {
            data {
                _id
                    number
                    title
                    deadlineAt
                    isScheduled
                    company {
                        companyTradingEntities {
        _id
                            name
                        }
                    }
                    contact {
                        fullName
                    }
                }
        }
    }
`;

const ScheduledDeadline = ({job}) => job.deadlineAt
        ? <Typography component='span' color='error'>Deadline: <FormatDate date={job.deadlineAt} />&nbsp;&nbsp;&nbsp;</Typography>
        : null;

const ScheduledIcon = ({job}) => job.isScheduled
        ? <DateRangeIcon style={{fontSize: '16px', color: themeOptions.colors.green, verticalAlign: 'text-top'}} />
        : null;

const DraggableJob = ({job}) => {

    const displayText = `${get(job, 'number')} ${get(job, 'title')} | ${get(job, 'company.companyTradingEntities[0].name')} | ${get(job, 'contact.fullName')}`;
    const text = `${job.number} ${job.title}`;

    return (<ListItem ref={(element) => {
        if (!element) {
            return;
        }

        DayPilot.Scheduler.makeDraggable({
            element: element,
            id: job._id,
            text,
            entityData: job,
            keepElement: true,
            duration: 45 * 60 // 45 minutes converted to seconds
        });
    }}
        style={{padding: '3px 16px'}}
    >
        <ListItemText primary={<Typography style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}} noWrap>
            {displayText}&nbsp;&nbsp;&nbsp;
                    {<ScheduledDeadline job={job} />}
            {<ScheduledIcon job={job} />}
        </Typography>} />

    </ListItem>
    );
};

const filters = [{
    name: 'q',
    type: 'string',
    component: (props) => <HoopsSearchDataFilter {...props} />
}];

export const ScheduleJobList = () => {
    const {data, refetch} = useQuery(QUERY_JOBS_SIMPLE,{fetchPolicy: 'cache-and-network'});
    const [filter, setFilter] = useState({});

    const handleFilter = (_filter) => {
        setFilter(() => {
            refetch({q: _filter.q});
            return _filter;
        });

    };

    return (
        <Box style={{padding: '8px'}}>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container style={{paddingLeft: 16}}>
                        <Grid item xs>
                            <Typography variant='h6' display='inline'>Jobs</Typography>&nbsp;&nbsp;&nbsp;
                            <Typography variant='caption' display='inline' style={{color: themeOptions.colors.grey.main}}>Drag &amp; Drop Jobs onto Schedule</Typography>
                        </Grid>
                        <Grid item>
                            <HoopsDataFilterBar filters={filters} filter={filter} onFilter={handleFilter} showClearFilterButton={false} />
                        </Grid>
                    </Grid>
                </Grid>
                {data ? <Grid item xs={12}>
                    <List style={{overflow: 'auto', position: 'relative', maxHeight: 200, padding: 0}} dense>
                        {data.jobs.data.map(
                            (job) =>
                                <DraggableJob job={job} key={job._id} />
                        )}
                    </List>
                </Grid> : null}
            </Grid>
        </Box>
    );
};
