import classNames from 'classnames';
import React from 'react';
import {registerGlobalStyle} from '../../theme';
import {HoopsPropTypes} from '../utils';

registerGlobalStyle('.field-columns', (theme) => ({
  display: 'grid',
  padding: 0,
  rowGap: theme.spacing(2),
  '.text.text-caption': {fontSize: '.75rem'}
}));

export function FieldColumns({className, columns = 1, children}) {
  const template = typeof columns === 'string' ? columns : `repeat(${columns}, 1fr)`;
  return (
    <div className={classNames([className, 'field-columns'])} style={{gridTemplateColumns: template}}>
      {children}
    </div>
  );
}

FieldColumns.propTypes = {
  className: HoopsPropTypes.className,
  columns: HoopsPropTypes.stringOrNumber.isRequired,
  children: HoopsPropTypes.children,
};
