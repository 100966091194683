import React, {useCallback, useRef, useState} from 'react';
import HomeIcon from '@mui/icons-material/Home';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {registerGlobalStyle} from '../../theme';
import {HoopsPropTypes} from '../../componentsLib';
import {Tab} from '../../componentsLib/Basic/Tabs';
import {TabsList} from '../../componentsLib/Basic/TabsList';
import {Row} from '../../componentsLib/Layout';
import {Button, TextInput} from '../../componentsLib/Basic';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {AttentionConfirmDialog, PopupItem} from '../../componentsLib/Popovers';

registerGlobalStyle('.view-tabs', (theme) => ({
  minWidth: 100,
  display: 'flex',
  alignItems: 'flex-end',
  margin: theme.spacing(0, 2),
  background: theme.colors.background.white,
  borderBottom: `1px solid ${theme.colors.border.light}`,
  '.tabs': {
    padding: 0,
    borderBottom: 'none',
  },
  '.tab': {
    display: 'flex',
    fontWeight: 500,
    position: 'relative',
    '&>button.menu': {
      position: 'relative',
      left: '4px',
      padding: 0,
      marginRight: '-8px',
      border: 'none',
      svg: {fontSize: '1.125rem'},
      color: theme.colors.text.main,
      zIndex: 1,
      opacity: 0,
      transition: theme.transitions.out.all,
    },
    '&:hover>button.menu': {
      opacity: 1,
      transition: theme.transitions.in.all,
    },
    '&:first-child>button.menu': {
      opacity: 0,
      pointerEvents: 'none',
    },
    input: {
      position: 'absolute',
      inset: theme.spacing(.5, 1),
      paddingInline: theme.spacing(1.5),
      width: 'unset',
      zIndex: 2,
    }
  },
  '.view-tabs-menu': {
    marginLeft: 1,
    '&::before': {
      content: '""',
      position: 'absolute',
      left: -2,
      top: 5,
      bottom: 7,
      borderLeft: `1px solid ${theme.colors.border.light}`,
    }
  },
}));

registerGlobalStyle('.view-tabs-popover', () => ({'&.tabs-list-popover': {'button.menu': {display: 'none',},},}));

export function ViewTabs({viewState}) {
  const tabsRef = useRef();
  const [renaming, setRenaming] = useState();
  const [deleting, setDeleting] = useState();

  const handleRenameView = useCallback((id) => {
    const view = viewState.views.find((v) => v._id === id);
    if (view) {
      setRenaming({id, name: view.name});
    }
  }, [viewState?.views]);

  const handleAddView = useCallback(async () => {
    const res = await viewState.addView();
    if (res) {
      setRenaming({id: res, name: 'New View'});
    }
  }, [viewState]);

  const handleRenameViewComplete = useCallback(async () => {
    setRenaming(null);
    if (renaming) {
      await viewState.renameView(renaming.id, renaming.name);
    }
  }, [renaming, viewState]);

  const handleRenameChange = useCallback((e) => {
    setRenaming((prev) => {
      if (prev) {
        return {id: prev.id, name: e.target.value};
      }
      return prev;
    });
  }, []);

  const handleRenameKeyDown = useCallback(async (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      await handleRenameViewComplete(e);
    } else if (e.key === 'Escape') {
      setRenaming(null);
    }
  }, [handleRenameViewComplete]);

  const handleConfirmDeleteView = useCallback(async (id) => {
    const view = viewState.views.find((v) => v._id === id);
    if (view) {
      setDeleting({id, name: view.name});
    }
  }, [viewState?.views]);

  const handleDeleteView = useCallback(async (confirmed) => {
    setDeleting(null);
    if (confirmed && deleting) {
      await viewState.deleteView(deleting.id);
    }
  }, [deleting, viewState]);

  return (
    <Row className={'view-tabs'} ref={tabsRef}>
      <TabsList classNamePopover={'view-tabs-popover'} value={viewState.currentView._id} onChange={viewState.setCurrentView} resizeContainer={tabsRef.current}>
        {viewState.views.map(({name, _id}, index) => (
          <Tab key={_id} value={_id}>
            {index === 0 &&
              <HomeIcon/>
            }
            {renaming?.id === _id ? renaming.name : name}
            <Button navMinor menu suffix={MoreHorizIcon}>
              <PopupItem onClick={() => handleRenameView(_id)} text={'Rename'}/>
              <PopupItem onClick={() => handleConfirmDeleteView(_id)} text={'Delete'}/>
            </Button>
            {renaming?.id === _id &&
              <TextInput
                autoFocus
                selectOnFocus
                value={renaming.name}
                onChange={handleRenameChange}
                onBlur={handleRenameViewComplete}
                onKeyDown={handleRenameKeyDown}
              />
            }
          </Tab>
        ))}
      </TabsList>
      <Button
        className={'view-tabs-menu'}
        navMinor
        prefix={AddCircleOutlineIcon}
        onClick={handleAddView}
        disabled={!viewState.allowAddViews}
        noWrap
        text={'Add View'}
      />

      {deleting?.id &&
        <DeleteViewConfirmation onClose={handleDeleteView} name={deleting.name}/>
      }
    </Row>
  );
}

ViewTabs.propTypes = {viewState: HoopsPropTypes.object,};

function DeleteViewConfirmation({onClose, name}) {
  return (
    <AttentionConfirmDialog
      onClose={onClose}
      okText={'Delete'}
      cancelText={'Cancel'}
      content={`Are you sure you want to delete the '${name}' view? This action cannot be undone.`}
    />
  );
}
