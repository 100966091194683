import {Button, Grid, MuiThemeProvider} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {editNewTask} from '../../actions/tasks';
import EntityListHero from '../../componentsOld/EntityList/EntityListHero';
import green from '../../ui/theme/green';
import TaskManyTable from './List/TaskManyTable';

export const TaskManyViewPage = () => {
  const dispatch = useDispatch();
  const companySelector = (state) => state.companyReducer.company;
  const company = useSelector(companySelector);
  const [statusFilter, setStatusFilter] = useState();

  useEffect(() => {
    const statuses = company && company.statuses
      ? company.statuses
        .filter((status) => status.entityType === 'task' && status.entityMapping === 'status')[0]
        .statuses
        .map((status) => status.value)
        .filter((statusValue) => statusValue !== 'COMPLETE')
      : [];

    setStatusFilter(statuses);
  }, [company]);

  const handleCreateTaskClick = () => {
    dispatch(editNewTask());
  };

  return (
    <Grid container>
      <EntityListHero
        title='Tasks'
        subtitle='Work more collaboratively with your team to organise &amp; prioritise your tasks.'
        helpArticleUrl='http://help.hoopscrm.com/en/articles/4636499-tasks'
        videoId='AaquxjPDcNA'
      >
        <MuiThemeProvider theme={green}>
          <Button
            data-intercom-target={'task-create'}
            onClick={handleCreateTaskClick}
            color={'primary'}
            variant={'contained'}>Add Task</Button>
        </MuiThemeProvider>
      </EntityListHero>

      <Grid item xs={12}>
        <TaskManyTable filterToMe={true} statusFilter={statusFilter} handleCreateTaskClick={handleCreateTaskClick} queryStateId='taskManyViewPage' />
      </Grid>
    </Grid>

  );
};

export default TaskManyViewPage;
