import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import PropTypes from 'prop-types';
import * as React from 'react';
import {HoopsButton} from '../HoopsButton';

export const ConfirmDialog = ({
  title = 'Are you sure?',
  content = '',
  confirmValue = true,
  onClose = () => null,
  cancelText = 'Cancel',
  okText = 'Ok',
}) => {
  ConfirmDialog.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
    confirmValue: PropTypes.any.isRequired,
    onClose: PropTypes.func.isRequired,
    cancelText: PropTypes.string,
    okText: PropTypes.string,
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(confirmValue);
  };

  return (
    <Dialog open={true} sx={{'& .MuiDialog-paper': {minWidth: 350}}}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <HoopsButton onClick={handleCancel} purpose='cancel'>{cancelText}</HoopsButton>
        <HoopsButton onClick={handleOk} purpose='ok' color='primary'>{okText}</HoopsButton>
      </DialogActions>
    </Dialog>
  );
};

export const ConfirmYesNoDialog = (props) => ConfirmDialog({cancelText: 'No', okText: 'Yes', ...props});
