// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {Button, Card, CardContent, CircularProgress, Divider, Grid, Link, makeStyles, MuiThemeProvider, Paper, Table, TableCell, TableHead, TableRow, Typography} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DescriptionIcon from '@material-ui/icons/Description';
import classNames from 'classnames';
import {get} from 'lodash';
import moment from 'moment';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {snackError} from '../../../actions/action-types';
import {getQuote} from '../../../actions/quote';
import defaultLogo from '../../../assets/images/default-logo.png';
import logo from '../../../assets/images/logo-grey.png';
import {HOOPS_PUBLIC_WEBSITE_URL} from '../../../constants';
import {QuoteService} from '../../../servicesOld/QuoteService';
import theme from '../../../ui/theme';
import green from '../../../ui/theme/green';
import {cleanDescriptionDynamic} from '../../../utils/stripHtml';
import SuccessDialog from '../../shared/modals/SuccessDialog';
import StripeInvoiceBtn from '../StripeInvoiceBtn';
import PayOnlineDialog from './PayOnlineDialog';
import PreviewQuoteCommentField from './PreviewQuoteCommentField';
import QuoteItem from './QuoteItem';
import QuoteTerms from './QuoteTerms';
import {QuoteTotals} from './QuoteTotals';

const useStyles = makeStyles((theme) => ({
  prepared: {height: '100%'},
  topBuffer: {marginTop: theme.spacing(2),},
  tableTopBuffer: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {marginTop: theme.spacing(0),},
  },
  topBufferLarger: {marginTop: theme.spacing(7),},
  content: {paddingLeft: theme.spacing(3),},
  companyLogo: {
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    height: '75px',
    width: '75px',
    backgroundRepeat: 'no-repeat',
  },
  detailCard: {
    marginTop: theme.spacing(2),
    padding: '1px 30px',
    flex: 1,
  },
  tableHeader: {
    fontSize: '14px',
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  footerLogo: {
    marginTop: theme.spacing(5),
    height: '20px',
  },
  tdProduct: {width: 80,},
  tdQuantity: {width: 80,},
  tdRate: {
    width: 80,
    paddingRight: theme.spacing(1),
    whiteSpace: 'nowrap'
  },
  tdTotal: {
    width: 120,
    whiteSpace: 'nowrap'
  },
  tdAction: {width: 80,},
  border: {border: `1px solid ${theme.colors.grey.light}`,},
  textAlign: {
    textAlign: 'right',
    [theme.breakpoints.down('md')]: {textAlign: 'left',},
  },
  quoteSubheader: {},
  quoteDescription: {paddingTop: theme.spacing(1),},
  quoteDetails: {width: '200px',},
  divider: {
    width: '100%',
    marginTop: theme.spacing(8),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > *': {marginLeft: theme.spacing(1)}
  }

}));

export default ({quote, renderSiblings, tenantView = true}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {tdAction, tdProduct, tdQuantity, tdRate, tdTotal} = classes;
  const {customer, contact, createdBy, quoteOwner, shipTo, billTo, company} = quote;

  const companyEntity = customer?.companyTradingEntityId
    ? company?.companyTradingEntities?.find((entity) => entity._id.toString() === customer?.companyTradingEntityId.toString()) ?? company?.companyTradingEntities[0]
    : company?.companyTradingEntities[0];

  const billingAddress = billTo || (customer
    ? customer.addresses.find((Address) => Address.label === 'BILLING')
    : '');
  const shippingAddress = shipTo || (customer
    ? customer.addresses.find((Address) => Address.label === 'SHIPPING')
    : '');
  const [successDialogObject, setSuccessDialogObject] = useState({});

  const quotes = renderSiblings ? quote.siblings : [quote];
  const [showComments, setShowComments] = useState({});
  const [quoteSelectedForPayOnline, setQuoteSelectedForPayOnline] = useState({});
  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [paymentSuccess, setPaymentSuccess] = useState({});

  if (!quotes) {return undefined;}

  const acceptQuote = (quoteId, number) => {
    setIsLoading(true);
    QuoteService.acceptQuote(quoteId)
      .then(() => {
        //success dialog
        setSuccessDialogObject({
          open: true,
          title: 'Quote Accepted',
          bodyHeader: `You accepted Quote ${number}.`,
          body: 'Thank you for your business, we\'ll be in touch with further updates'
        });
        dispatch(getQuote(quoteId, false));
        setIsLoading(false);
      })
      .catch((e) => {
        dispatch(snackError(e.message));
        setIsLoading(false);
      });
  };

  const openPayOnlineModal = (quoteId, number, payTotal) => {
    setQuoteSelectedForPayOnline({
      _id: quoteId,
      quoteNumber: number,
      payTotal: payTotal
    });
    setIsPaymentDialogOpen(true);
  };

  const saveComment = (quoteId, value) => {
    QuoteService.commentQuote(quoteId, value)
      .then(() => {
        //success dialog
        setSuccessDialogObject({
          open: true,
          title: 'Comment Sent',
          bodyHeader: '',
          body: 'Your comment was successfully added, our team will be notified'
        });
      })
      .catch((e) => {
        dispatch(snackError(e.message));
      });

    handleShowComments(quoteId);
  };

  const isStripeIntegrated = quote && quote.company && !!quote.company.stripeConnectedAccountId;

  const stripeConnectedAccount = quote && quote.company && quote.company.stripeConnectedAccountId;

  const handleShowComments = (id) => {
    setShowComments(
      (prev) => ({
        ...prev,
        [id]: !prev[id] // flip
      })
    );
  };

  const handlePaymentSuccess = (id) => {
    setPaymentSuccess(
      (prev) => ({
        ...prev,
        [id]: true // flip
      })
    );
  };

  return quotes.length ? (
    <>
      {isStripeIntegrated &&
        <PayOnlineDialog
          stripeConnectedAccount={stripeConnectedAccount}
          isDialogOpen={isPaymentDialogOpen}
          currencySymbol={company.currencySymbol}
          onClose={() => setIsPaymentDialogOpen(false)}
          _id={quoteSelectedForPayOnline._id}
          quoteNumber={quoteSelectedForPayOnline.quoteNumber}
          payTotal={quoteSelectedForPayOnline.payTotal}
          onSuccess={handlePaymentSuccess}
        />
      }

      <SuccessDialog successDialogObject={successDialogObject} handleClose={() => setSuccessDialogObject({...successDialogObject, open: false})} />

      <Grid container className={classes.topBuffer} justifyContent={'center'}>
        <Grid
          item
          xs={12}
          id='companyLogo'
          className={classes.companyLogo}
          style={{
            backgroundImage: companyEntity.logo
              ? `url(${companyEntity.logo})`
              : `url(${defaultLogo})`,
          }}
        ></Grid>
      </Grid>
      {/* Prepared For & Prepared By*/}

      <Grid container spacing={4} className={classes.topBuffer}>
        <Grid container item xs={12} md={6} direction={'column'}>
          <Card className={classes.prepared}>
            <CardContent className={classes.content}>
              <Typography component='h5' variant='h6'>
                Prepared For:
              </Typography>
              <Typography variant='body2'>
                <strong>{customer.name}</strong> <br />
                <strong>{contact.fullName}</strong> <br />
                {billingAddress ? (
                  <>
                    Billing Address: {billingAddress.formattedAddress} <br />
                  </>
                ) : null}
                {shippingAddress ? (
                  <>
                    Shipping Address: {shippingAddress.formattedAddress} <br />
                  </>
                ) : null}
                {contact.phone ? (
                  <>
                    {contact.phone}
                    <br />
                  </>
                ) : <>
                  {customer.phone && customer.phone}
                  <br />
                </>}
                {contact.email ? (
                  <>
                    {contact.email}
                    <br />
                  </>
                ) : customer.email && customer.email}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid container item xs={12} md={6} direction={'column'}>
          <Card className={classes.prepared}>
            <CardContent className={classes.content}>
              <Typography component='h5' variant='h6'>
                Prepared By:
              </Typography>
              <Typography variant='body2'>
                <strong>{companyEntity.name}</strong> <br />
                <strong>{get(quoteOwner ?? createdBy, 'fullName', '')}</strong> <br />
                {companyEntity.addresses.length ? (
                  <>
                    {companyEntity.addresses[0].formattedAddress} <br />
                  </>
                ) : null}
                {companyEntity.phone ? (
                  <>
                    {companyEntity.phone}
                    <br />
                  </>
                ) : null}
                {get(quoteOwner ?? createdBy, 'username') ? (
                  <>
                    {get(quoteOwner ?? createdBy, 'username', '')}
                    <br />
                  </>
                ) : null}
                {companyEntity.website ? (
                  <>
                    {companyEntity.website}
                    <br />
                  </>
                ) : null}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {
        quotes.map((quote, index) => {
          const {
            _id,
            title,
            items,
            number,
            taxes,
            subTotal,
            onlinePaymentStatus,
            terms,
            total,
            description,
            isQuoteAccepted,
            settings,
          } = quote;
          const pdfLink = `${process.env.REACT_APP_BASE_URL}/quotes/${_id}/pdf`;

          return (
            <div key={_id}>
              {/* Single Quote to START */}

              <Grid
                container
                spacing={1}
                justifyContent='space-between'
                className={classes.topBufferLarger}
                alignItems='flex-end'
              >
                <Grid
                  item
                  direction='column'
                  md={5}
                  xs={12}
                >
                  {quotes.length > 1 ? (
                    <Typography variant='h4'>
                      {title === null ? 'Option ' + (index + 1) : title}
                    </Typography>
                  ) : null}
                  {description && (
                    <Typography
                      variant='body1'
                      className={classes.quoteDescription}
                      gutterBottom
                    >
                      {cleanDescriptionDynamic(description)}
                    </Typography>
                  )}
                  <Typography
                    variant='body1'
                    className={classes.quoteSubheader}
                  >
                    Quote #: <strong>{number}</strong> <br />
                    {`${moment(parseInt(quote.createdAt), 'x').format(
                      'DD MMM YYYY'
                    )}`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={7}
                  xs={12}
                  className={classes.buttonContainer}
                >

                  <Button
                    color='primary'
                    startIcon={<DescriptionIcon />}
                    href={pdfLink}
                    target='_blank'
                  >
                    Download PDF
                  </Button>
                  {settings && !tenantView && settings.canContactComment &&
                    <Button
                      color='primary'
                      variant='outlined'
                      onClick={() => handleShowComments(_id)}
                      style={{backgroundColor: theme.colors.white,}}
                    >
                      Add Comment
                    </Button>
                  }
                  {settings && !tenantView && settings.canContactAccept &&

                    <MuiThemeProvider theme={!settings.canContactPay && green}>
                      {
                        isQuoteAccepted
                          ? <>
                            <CheckCircleIcon color='primary' style={{marginTop: '4px', paddingTop: '4px', paddingLeft: '4px'}} />
                            <Typography variant='body1' color='primary' style={{marginTop: '4px', paddingTop: '4px'}}><strong>ACCEPTED</strong></Typography>
                          </>
                          : <Button color={'primary'} variant={'contained'} disabled={isLoading} onClick={() => acceptQuote(_id, number)}>
                            {isLoading ? <><CircularProgress size={16} style={{marginRight: theme.spacing(1)}} />Accept Quote</> : 'Accept Quote'}

                          </Button>
                      }
                    </MuiThemeProvider>
                  }
                  {settings && !tenantView && settings.canContactPay &&

                    <MuiThemeProvider theme={green}>
                      {
                        onlinePaymentStatus === 'successful' || paymentSuccess[_id]
                          ? <>
                            <CheckCircleIcon color='primary' style={{marginTop: '4px', paddingTop: '4px', paddingLeft: '4px'}} />
                            <Typography variant='body1' color='primary' style={{marginTop: '4px', paddingTop: '4px'}}><strong>ACCEPTED & PAID</strong></Typography>
                          </>
                          : isStripeIntegrated &&
                          <Button color={'primary'} variant={'contained'} onClick={() => {
                            openPayOnlineModal(_id, number, total);
                          }}>
                            Accept & Pay Online
                          </Button>
                      }
                    </MuiThemeProvider>
                  }

                  {/* Show in the tenant view if the item is accepted and paid  */}
                  {settings && settings.canContactAccept && tenantView && isQuoteAccepted &&
                    <MuiThemeProvider theme={green}>
                      <CheckCircleIcon color='primary' style={{marginTop: '4px', paddingTop: '4px', paddingLeft: '4px'}} />
                      <Typography variant='body1' color='primary' style={{marginTop: '4px', paddingTop: '4px'}}><strong>ACCEPTED</strong></Typography>
                    </MuiThemeProvider>
                  }

                  {/* Show in the tenant view if the item is accepted and paid  */}
                  {settings && settings.canContactPay && tenantView && (onlinePaymentStatus === 'successful' || paymentSuccess[_id]) &&
                    <MuiThemeProvider theme={green}>
                      <CheckCircleIcon color='primary' style={{marginTop: '4px', paddingTop: '4px', paddingLeft: '4px'}} />
                      <Typography variant='body1' color='primary' style={{marginTop: '4px', paddingTop: '4px'}}><strong>ACCEPTED & PAID</strong></Typography>
                    </MuiThemeProvider>
                  }

                </Grid>

              </Grid>

              {(onlinePaymentStatus === 'successful' || paymentSuccess[_id]) && !tenantView && isStripeIntegrated &&
                <div style={{float: 'right', paddingBottom: theme.spacing(1)}}>
                  <StripeInvoiceBtn quoteId={_id} />
                </div>
              }

              <PreviewQuoteCommentField
                quoteId={_id}
                showComments={showComments[_id]}
                saveComment={saveComment}
                handleShowComments={handleShowComments} />

              <Grid container className={classes.topBuffer}>
                <Grid item xs={12}>
                  <Paper elevation={1} style={{overflow: 'auto'}}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            className={classNames([
                              classes.tdProduct,
                              classes.tableHeader,
                            ])}
                          >
                            <strong>Product</strong>
                          </TableCell>
                          <TableCell
                            className={classNames([classes.tableHeader])}
                            colSpan={4}
                          />
                          <TableCell
                            className={classNames([
                              classes.tdQuantity,
                              classes.tableHeader,
                            ])}
                          >
                            <strong>Quantity</strong>
                          </TableCell>
                          <TableCell
                            className={classNames([
                              classes.tdRate,
                              classes.tableHeader,
                            ])}
                          >
                            <strong>{`Rate${settings && settings.hideTotal ? ' (Ex Tax)' : ''}`}</strong>
                          </TableCell>
                          <TableCell
                            className={classNames([
                              classes.tdTotal,
                              classes.tableHeader,
                            ])}
                            align={'right'}
                          >
                            <strong>{`Total${settings && settings.hideTotal ? ' (Ex Tax)' : ''}`}</strong>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {items.filter(Boolean).map(
                        (item) =>
                          item.description && (
                            <QuoteItem
                              key={item._id}
                              item={item}
                              hideTotal={settings && settings.hideTotal}
                              columnClasses={{
                                tdProduct,
                                tdQuantity,
                                tdRate,
                                tdTotal,
                                tdAction,
                              }}
                            />
                          )
                      )}
                      {settings && !settings.hideTotal &&
                        <QuoteTotals
                          subTotal={subTotal}
                          taxes={taxes}
                          total={total}
                          columnClasses={{
                            tdProduct,
                            tdQuantity,
                            tdRate,
                            tdTotal,
                            tdAction,
                          }}
                        />
                      }
                    </Table>
                  </Paper>
                </Grid>
              </Grid>
              {terms && <QuoteTerms terms={terms} />}
              {/* Single Quote to END */}
              {index < quotes.length - 1 && (
                <Divider className={classes.divider} />
              )}
            </div>
          );
        })
      }

      <Grid item container align-items='center'>
        <Grid item xs={true} align='center'>
          <Link
            href={`${HOOPS_PUBLIC_WEBSITE_URL}/?src=qid_${quote._id}`}
            target={'_blank'}
          >
            <img className={classes.footerLogo} src={logo} alt='logo' />
          </Link>
        </Grid>
      </Grid>
    </>
  ) : null;
};
