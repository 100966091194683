import {registerGlobalStyle} from '../../theme';
import React, {useMemo} from 'react';
import {Column, Row} from '../Layout';
import classNames from 'classnames';

registerGlobalStyle('.image-list', (theme) => ({
  rowGap: theme.spacing(2),
  columnGap: theme.spacing(2),
  '.row-wrap': {
    rowGap: theme.spacing(2),
    columnGap: theme.spacing(2),
  },
  '.image-list-id': {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'start',
    alignSelf: 'stretch',
    color: theme.colors.text.main,
    width: theme.spacing(4),
    flex: 'none',
    'div': {
      width: theme.spacing(4),
      height: theme.spacing(4),
      lineHeight: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: theme.colors.text.highlight,
      borderRadius: '50%',
      color: theme.colors.text.white,
      fontSize: theme.spacing(3),
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      width: 6,
      height: `calc(100% - ${theme.spacing(2)})`,
      border: `1px solid ${theme.colors.text.highlight}`,
      borderRight: 'none',
      borderRadius: '4px 0 0 4px',
      left: '50%',
      zIndex: '-1',
    }
  },
}));

export function ImageList({className, threshold = 12, children}) {
  const items = useMemo(() => {
    const map = {};
    if (children.length >= threshold) {
      children.forEach((child) => {
        const letter = (typeof child.key === 'string' ? child.key : child.value)?.substring(0, 1);
        if (!map[letter]) {
          map[letter] = [];
        }
        map[letter].push(child);
      });
    }
    return map;
  }, [children, threshold]);

  return (
    <Column className={classNames(className, 'image-list')}>
      {children.length < threshold &&
        <Row wrap>
          {children}
        </Row>
      }
      {children.length >= threshold && Object.entries(items).map(([key, images]) => (
        <Row key={key}>
          <div className={'image-list-id'}><div>{key}</div></div>
          <Row wrap>
            {images}
          </Row>
        </Row>
      ))}
    </Column>
  );
}
