import {useRef} from 'react';
import {isEqual} from 'lodash';

//
// Hook to perform a deep compare on the parameter and return the old version if nothing has changed
//
export function useDeepObjectMemo(newObject) {
  const prevRef = useRef(null);

  const prevObject = prevRef.current;

  if (!isEqual(prevObject, newObject)) {
    prevRef.current = newObject;
    return newObject;
  }

  return prevObject;
}
