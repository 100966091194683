import React, {useCallback, useEffect, useRef, useState} from 'react';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import FitScreenIcon from '@mui/icons-material/FitScreen';
import OneHundredIcon from '@mui/icons-material/MoneyRounded';
import AutoZoomOffIcon from '@mui/icons-material/AutoFixOff';
import AutoZoomOnIcon from '@mui/icons-material/AutoFixNormal';
import {Row} from './Row';
import {Button, PercentageInput, ToolTip} from '../Basic';
import {registerGlobalStyle} from '../../theme';

registerGlobalStyle('.zoomer', (theme) => ({
  padding: theme.spacing(.25, 1),
  columnGap: theme.spacing(1),
  border: `1px solid ${theme.colors.border.main}`,
  borderRadius: theme.shape.borderRadius,
  alignItems: 'center',
  '.text-input': {
    borderColor: 'transparent',
    padding: theme.spacing(.625, .5, .125),
    paddingRight: '2ch',
  },
  '.button': {
    'svg': {
      fontSize: '1.5rem',
      color: theme.colors.text.dark,
      '&:hover': {color: theme.colors.text.highlight,}
    },
  },
  '&.auto-zoom .auto-zoom-button svg:not(:hover)': {color: theme.colors.text.highlight,}
}));

export function Zoomer({autoZoom, zoom: _zoom, maxZoom = 200, minZoom = 10, onChange, onChangeAutoZoom, onFitScreen, onZoomIn, onZoomOut}) {
  const [zoom, setInputZoom] = useState(_zoom);
  const ref = useRef(null);

  useEffect(() => {
    if (!ref.current || !document.activeElement || document.activeElement.tagName !== 'INPUT' || !ref.current.contains(document.activeElement)) {
      setInputZoom(_zoom);
    }
  }, [_zoom]);

  const handleChange = useCallback((newZoom) => {
    setInputZoom(newZoom);
    newZoom = Math.max(Math.min(newZoom, maxZoom), minZoom);
    if (newZoom !== zoom) {
      onChange(newZoom);
    }
  }, [maxZoom, minZoom, onChange, zoom]);

  const handleBlur = useCallback(() => {
    setInputZoom(_zoom);
  }, [_zoom]);

  const handleZoomIn = useCallback(() => {
    if (onZoomIn) {
      onZoomIn();
    } else {
      handleChange(Math.min(zoom + 10, maxZoom));
    }
  }, [handleChange, maxZoom, onZoomIn, zoom]);

  const handleZoomOut = useCallback(() => {
    if (onZoomOut) {
      onZoomOut();
    } else if (zoom > 10) {
      handleChange(Math.max(zoom - 10, minZoom));
    }
  }, [handleChange, minZoom, onZoomOut, zoom]);

  const handle100Percent = useCallback(() => {
    handleChange(100);
  }, [handleChange]);

  const handleSwitchAutoZoom = useCallback(() => {
    onChangeAutoZoom?.(!autoZoom);
  }, [autoZoom, onChangeAutoZoom]);

  return (
    <Row paper className={['zoomer', autoZoom && 'auto-zoom']} ref={ref}>
      <PercentageInput value={zoom} autoSize onBlur={handleBlur} onChange={handleChange} blurOnEnterEscape/>
      <Button prefix={ZoomInIcon} onClick={handleZoomIn} tabIndex={-1}/>
      <Button prefix={ZoomOutIcon} onClick={handleZoomOut} tabIndex={-1}/>
      <Button prefix={FitScreenIcon} onClick={onFitScreen} tabIndex={-1}/>
      <Button prefix={OneHundredIcon} onClick={handle100Percent} tabIndex={-1}/>
      <ToolTip tip={'Turning this on will auto-size the document preview to fit in the available space, or set the zoom to 100%.'}>
        <Button className={'auto-zoom-button'} prefix={autoZoom ? AutoZoomOnIcon : AutoZoomOffIcon} onClick={handleSwitchAutoZoom} tabIndex={-1}/>
      </ToolTip>
    </Row>
  );
}
