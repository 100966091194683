import React, {Fragment} from 'react';
import {Chip} from '@material-ui/core';
import PropTypes from 'prop-types';
import {ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles';
import green from '../../ui/theme/green';
import red from '../../ui/theme/red';
import blue from '../../ui/theme';
import Grid from '@material-ui/core/Grid';

class UserStatusChip extends React.Component {
  render() {
    const {status} = this.props;
    const theme = {
      ACTIVE: green,
      INVITED: blue,
      DELETED: red
    };

    const statusDict = {
      ACTIVE: 'ACTIVE',
      INVITED: 'PENDING',
      DELETED: 'DELETED'
    };

    return (
      <Fragment>
        <MuiThemeProvider theme={theme[status]}>
          <Grid container justifyContent={'center'}>
            <Grid item>
              <Chip color={'primary'} label={statusDict[status]} />
            </Grid>
          </Grid>
        </MuiThemeProvider>
      </Fragment>
    );
  }
}

UserStatusChip.propTypes = {status: PropTypes.any.isRequired};

export default UserStatusChip;
