import {Typography} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import classNames from 'classnames';
import React from 'react';

export const ConfiguredProductAutocompleteRenderOption = (
  option,
  classes,
) => {

  const highlightedWord = (word) => {
    const matches = match(word);
    const parts = parse(word, matches);
    return (
      <>
        {parts.map((part, index) =>
          part.highlight ?
            <span key={String(index)} style={{fontWeight: 500}}>
              {part.text}
            </span>
            : <strong key={String(index)} style={{fontWeight: 300}}>
              {part.text}
            </strong>
        )}
      </>
    );
  };

  return (
    <Grid container spacing={1} alignItems={'center'}>
      <Grid item>
        {option.image &&
          <img src={option.image}
            height={'auto'}
            width={40}
            alt='Primary'
          />}
      </Grid>
      <Grid item>
        <Typography className={classNames(classes.primaryDetails, classes.whiteSpaceNoWrap)}>
          {option.title && highlightedWord(option.title)}
        </Typography>
        <Typography className={classNames(classes.secondaryDetails, classes.whiteSpaceNoWrap)}>
          <span className={classes.secondaryText}>Code: </span>{option.code && `${option.code}`}
          <span className={classes.secondaryText}> | Name: </span>{option.name && `${option.name}`}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ConfiguredProductAutocompleteRenderOption;
