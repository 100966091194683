// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-shadow */

import {CircularProgress, Fade, IconButton, InputAdornment, List, ListItem, Paper, Popper} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import React, {useEffect, useRef, useState} from 'react';
import {HoopsTextField} from '../../HoopsTextField';
import CatalogService from '../../../servicesOld/CatalogService';
import theme from '../../../ui/theme';
import ProductAutocompleteRenderOption from './ProductAutocompleteRenderOption';
import productAutocompleteStyles from './productAutocompleteStyles';
import {Box} from '@mui/material';

const useStyles = makeStyles((theme) => ({...productAutocompleteStyles,}));

export const SageProductSelect = ({
  name,
  onSelect,
  ...props
}) => {
  const classes = useStyles();
  const selectdEl = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchValue, setSearchValue] = useState();
  const [title, setTitle] = useState();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSageSearch = () => {
    setLoading(true);
    setAnchorEl(selectdEl.current);

    CatalogService.listProducts('SAGE', searchValue, 1, 100)
      .then(({data: {listProducts: {items}}}) => {
        setProducts(items);
      })
      .finally(() => setLoading(false));

  };

  const handleSelect = async (product) => {
    setSearchValue(product.code);
    setTitle(product.title);
    const r = await CatalogService.cacheProduct('SAGE', product._id);
    if (r && r.data && r.data.cacheProduct) {
      onSelect(r.data.cacheProduct);
    } else {
      onSelect(product);
    }
    setAnchorEl(null);
  };

  useEffect(() => {
    setAnchorEl(null);
  }, [searchValue]);

  const open = Boolean(anchorEl);
  const id = open ? 'sage-popper' : undefined;

  return (
    <>
      <Box ref={selectdEl}>
        <HoopsTextField
          value={title || searchValue}
          onChange={(e) => { setTitle(null); return setSearchValue(e.target.value); }}
          InputProps={{
            endAdornment:
              <InputAdornment position={'end'}>
                {!loading && <IconButton onClick={() => handleSageSearch()}><SearchIcon color='primary' style={{paddingLeft: 0}} /></IconButton>}
                {loading && <CircularProgress size={20} style={{marginRight: theme.spacing(1)}} />}
              </InputAdornment>
          }}
          {...props}
        />
      </Box>
      <Popper id={id} open={open} anchorEl={anchorEl} placement={'bottom-start'} style={{zIndex: 9999}} transition>
        {({TransitionProps}) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <List component='ul' aria-label='main mailbox folders' style={{maxHeight: 400, overflowY: 'auto'}}>
                {products.map(
                  (product, idx) => (
                      <ListItem button key={idx} onClick={() => handleSelect(product)}>
                        {ProductAutocompleteRenderOption(product, classes)}
                      </ListItem>
                    )
                )}
              </List>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default SageProductSelect;
