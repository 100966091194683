import {Grid, Table} from '@material-ui/core';
import React from 'react';
import {useDispatch} from 'react-redux';
import {openCatalogModal as _openCatalogModal} from '../../actions/modals';
import {GQL_PRODUCT_PAGINATION} from '../../queries/product';
import {
    HoopsQuery,
    HoopsQueryContext,
    HoopsQueryEmptyState,
    HoopsQueryFilterChips,
    HoopsQueryFilterDropMenu,
    HoopsQueryFilterSearch,
    HoopsQueryFilterVendor,
    HoopsQueryPagination
} from '../HoopsQuery';
import ProductManyGrid from './ProductManyGrid';

export const ProductManyTable = ({onProductSelect = () => null}) => {
    const dispatch = useDispatch();

    const filterListOne = [{
        key: 'vendorId',
        type: 'array',
        options: [],
        label: 'Vendor',
        component: (props) => <HoopsQueryFilterVendor {...props} />
    }];

    return (

        <HoopsQuery refetchStateId='product' queryStateId='productMany' gqlQuery={GQL_PRODUCT_PAGINATION} resultField='productPagination' initialFilter={{}} initialSort={'TITLE_ASC'} initialPaging={{perPage: 30}}>
            <HoopsQueryContext.Consumer>
                {({items, filter, setFilter, paging, setPaging, chips, setChips}) => (<>
                        <Grid container direction='row' justifyContent='space-between' alignItems='flex-start'>
                            <Grid item>
                                <HoopsQueryFilterDropMenu
                                    label={'Vendor'}
                                    filter={filter}
                                    filterComponents={filterListOne}
                                    onFilterChange={setFilter}
                                    chips={chips}
                                    onChipsChange={setChips}
                                />
                            </Grid>
                            <Grid item>
                                <HoopsQueryFilterSearch onChange={setFilter} filter={filter} placeholder={'Search'} chips={chips} onChipsChange={setChips} />
                            </Grid>
                        </Grid>
                        <HoopsQueryFilterChips filter={filter} onFilterChange={setFilter} chips={chips} onChipsChange={setChips} />
                        <Table>
                            <ProductManyGrid rowsPerPage={paging.perPage} rows={items} onProductSelect={onProductSelect} emptyStateComponent={() => <HoopsQueryEmptyState filter={filter} columns={[]} field={'Product'} rowsPerPage={paging.perPage} onClearFilter={setFilter} onChipsChange={setChips} onAddItem={() => dispatch(_openCatalogModal())} />} />
                        </Table>
                        <Grid container direction='row' justifyContent='center' alignItems='center'>
                            <HoopsQueryPagination paging={paging} onPaging={setPaging} inputProps={{rowsPerPageOptions: [10, 30, 60, 100]}} />
                        </Grid>
                    </>)}
            </HoopsQueryContext.Consumer>
        </HoopsQuery>

    );
};

export default ProductManyTable;
