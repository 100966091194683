import {GQL_GET_STATUS_GROUP, GQL_GET_STATUS_GROUPS, GQL_UPDATE_STATUS_GROUP} from '../queries/statusGroup';
import {createGraphQLClient} from './GraphQLService';

const client = createGraphQLClient({});

export class StatusGroupService {

  constructor(statusGroup) {
    this.statusGroup = statusGroup;
  }

  static updateStatusGroup(_id, data) {

    const payload = {
      label: data.label,
      statuses: data.statuses.map((d) => ({
        _id: d.isNew ? null : d._id,
        label: d.label,
        value: d.value,
        color: d.color
      }))
    };

    return client.mutate({
      mutation: GQL_UPDATE_STATUS_GROUP,
      fetchPolicy: 'no-cache',
      variables: {
        _id,
        data: payload
      }
    });
  }

  static getStatusGroups() {
    return client.query({
      query: GQL_GET_STATUS_GROUPS,
      fetchPolicy: 'no-cache'
    });
  }

  static getStatusGroup(_id) {
    return client.query({
      query: GQL_GET_STATUS_GROUP,
      variables: {_id},
      fetchPolicy: 'no-cache'
    });
  }

  save() {
    return client.mutate({
      mutation: GQL_UPDATE_STATUS_GROUP,
      variables: {
        id: this.company._id,
        company: this.statusGroup
      }
    });
  }
}
