import React from 'react';
import {HoopsPropTypes} from '../utils';
import {Popper} from '@material-ui/core';

export function Offscreen({children}) {
  return (
    <Popper className={'offscreen'} open={true} style={{overflow: 'hidden', top: '100vh', height: 0}}>
      {children}
    </Popper>
  );
}

Offscreen.propTypes = {children: HoopsPropTypes.children};
