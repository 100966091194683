// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import React, {useCallback, useEffect, useState} from 'react';
import {Button, DialogContent, Grid,} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {get} from 'lodash';
import {closeCreateProductVariantModal as _closeCreateProductVariantModal} from '../../../../actions/modals';
import arrayMutators from 'final-form-arrays';
import {Form} from 'react-final-form';
import {createProductVariant as _createProductVariant} from '../../../../actions/catalog';
import HoopsFormDialog from '../../../modal/HoopsFormDialog';
import SizeColorPriceBreakFields from '../../../Products/SizeColorPriceBreaksFields';
import {Close} from '@material-ui/icons';

const ADD_VARIANT_FORM_ID = 'ADD_VARIANT_FORM_ID';

function SaveButton() {
  const handleClick = useCallback(() => {
    document.getElementById(ADD_VARIANT_FORM_ID)
      .dispatchEvent(new Event('submit', {cancelable: true}));
  }, []);

  return (<Button
    variant={'contained'}
    color={'primary'}
    onClick={handleClick}
    type='submit'>Save</Button>);
}

function CreateProductVariationModal() {
  const [initialValues, setInitialValues] = useState(
    {
      priceBreaks: [{
        quantity: 1,
        price: null
      }],
      colors: [],
      sizes: []
    }
  );

  const dispatch = useDispatch();
  const closeModal = useCallback(
    () => {
      setInitialValues({
        priceBreaks: [{
          quantity: 1,
          price: null
        }],
        colors: [],
        sizes: []
      });
      dispatch(_closeCreateProductVariantModal());
    }, [dispatch]);

  const createProductVariant = useCallback(
    (productId, data) => dispatch(_createProductVariant(productId, data)
    ), [dispatch]);
  const productSelector = (state) => state.catalogReducer.activeProduct;
  const isOpenSelector = (state) => state.modalReducer.createProductVariationsModal.isOpen;
  const contextSelector = (state) => state.modalReducer.createProductVariationsModal.context;
  const isOpen = useSelector(isOpenSelector);
  const context = useSelector(contextSelector);
  const product = useSelector(productSelector);

  useEffect(() => {
    if (context) {
      const defaultPriceBreaks = get(context, 'defaultPriceBreaks', [{quantity: 1}]);
      const priceBreaks = defaultPriceBreaks.map((pb) => ({
        quantity: pb.quantity,
        price: null
      }));
      if (context.addSize) {
        setInitialValues({
          priceBreaks,
          colors: context.colors,
          sizes: []
        });
      } else if (context.addColor) {
        setInitialValues({
          priceBreaks,
          sizes: context.sizes,
          colors: [],
        });
      }
    }
  }, [context]);

  const _handleSubmit = useCallback((values) => {
    createProductVariant(context._id, values);
    closeModal();
  }, [context, closeModal, createProductVariant]);

  return (
    <>
      <HoopsFormDialog
        open={isOpen}
        maxWidth={'sm'}
        fullWidth={true}
        title={'Add / Edit Variants'}
      >
        <Form
          onSubmit={_handleSubmit}
          mutators={{...arrayMutators}}
          initialValues={initialValues}
          render={({handleSubmit, form: {mutators, getState, ...restForm}}) => (
            <form id={ADD_VARIANT_FORM_ID} onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container style={{overflow: 'hidden'}}>
                  <Grid item container spacing={2}>
                    <SizeColorPriceBreakFields form={{mutators, getState, ...restForm}}
                      sizeOptions={product.sizes}
                      colorOptions={product.colors} />
                  </Grid>
                </Grid>

              </DialogContent>
              <>
                <Grid container
                      justifyContent={'space-between'}
                >
                  <Grid item>
                    <Button variant={'text'}
                            color={'secondary'}
                            onClick={closeModal}
                    >
                      <Close /> Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant={'contained'}
                      color={'primary'}
                      type='submit'>Save</Button>
                  </Grid>
                </Grid>
              </>
            </form>
          )}
        />
      </HoopsFormDialog>
    </>
  );
}

export default CreateProductVariationModal;
