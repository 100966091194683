import React from 'react';
import {Dialog, DialogContent} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux';
import {closeProofViewModal} from '../../../../actions/modals';
import {SinglePagePdfViewer} from '../../../../componentsLib/PDFViewer/SinglePagePdfViewer';

const useStyles = makeStyles((theme) => ({
  parent: {padding: '0px'},
  container: {padding: theme.spacing(3)},
  proofImage: {
    maxWidth: '-webkit-fill-available',
    maxHeight: 'inherit',
    objectFit: 'contain',
  },
}));

export const ProofViewModal = () => {
  const classes = useStyles();

  const modalSelector = (state) => state.modalReducer.jobProofViewModal;
  const {isOpen, proof} = useSelector(modalSelector);

  const isPdf = proof?.file?.contentType === 'application/pdf';

  const dispatch = useDispatch();
  return (
    <Dialog
      open={isOpen}
      onClose={() => dispatch(closeProofViewModal())}
      maxWidth={'lg'}
      fullWidth={!isPdf}
      className={classes.parent}
    >
      <DialogContent>
        
          {isPdf
            ?
            <SinglePagePdfViewer pdfUrl={proof?.file?.url} />
            :
            <img
              className={classes.proofImage}
              alt={proof?.file?.filename ?? 'Proof image'}
              src={proof?.file?.url ?? null}
            />
          }
        
      </DialogContent>
    </Dialog>
  );

};
