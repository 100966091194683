// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import {Button, Grid, makeStyles, Typography} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {hoopsQuerySetRefetch} from '../../actions/hoopsQuery';
import {createResource, setModal, updateResource} from '../../actions/resource';
import ResourceEdit from './ResourceEdit';

const useStyles = makeStyles((theme) => ({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    dialogActions: {padding: theme.spacing(2),}
}));

export const ResourceEditModal = ({onClose = () => null, onSave = () => null}) => {
    const classes = useStyles();

    const resourceSelector = (state) => state.resourceReducer.active;
    const isOpenSelector = (state) => state.resourceReducer.isModalOpen;

    const resource = useSelector(resourceSelector);
    const isOpen = useSelector(isOpenSelector);

    const dispatch = useDispatch();
    let _resource = null;

    const modalTitle = resource._id ? 'Edit Resource' : 'Add Resource';
    const [ saveDisabled , setSaveDisabled] = useState();

    const handleClose = () => {
        dispatch(setModal(false));
        dispatch(hoopsQuerySetRefetch('resource', true));
    };

    const handleChange = (v) => {
        _resource = v;
    };

    const handleSave = () => {
        const payload = {...resource, ..._resource};
        payload.$isNew ?
            dispatch(createResource(payload)) :
            dispatch(updateResource(payload._id, payload));
            dispatch(setModal(false));
    };

    const handleValidation = (isFormValid) => {
        setSaveDisabled(!isFormValid);
    };

    return (
            <Dialog
              open={isOpen}
              maxWidth={'lg'}
              fullWidth={true}
              onClose={handleClose}
            >
                <DialogTitle disableTypography>
                    <Typography variant={'h4'}>{modalTitle}</Typography>
                    <IconButton aria-label='close' className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <ResourceEdit resource={resource} onChange={handleChange} onFormValidation={handleValidation} />
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Grid container justifyContent={'space-between'}>
                    <Grid item xs>
                        <Button onClick={handleClose}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={2}>
                        <Grid item>
                            <Button
                            onClick={handleSave}
                            variant={'contained'}
                            disabled={saveDisabled}
                            color={'primary'}
                            type='submit'>Save</Button>
                        </Grid>
                        </Grid>
                    </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
          );
};

export default ResourceEditModal;
