import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {FormControlLabel, Grid, Radio, RadioGroup, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography} from '@mui/material';
import React, {useContext} from 'react';
import {HoopsButton} from '../../componentsLib';
import {FormattedCurrency} from './FormattedCurrency';
import {ReportContext} from './Reporting';

export const VendorReport = ({
  currencySymbol,
  reportSettings,
}) => {
  const {data} = useContext(ReportContext);
  const header = (groupingSelected) => {
    switch (groupingSelected) {
      default:
      case 'vendorsByQuote':
        return (
          <Typography variant={'h5'}>Vendor Report - Vendors By Quote</Typography>
        );
      case 'vendorsByJob':
        return (
          <Typography variant={'h5'}>Vendor Report - Vendors By Job</Typography>
        );
      case 'vendorsByPurchaseOrder':
        return (
          <Typography variant={'h5'}>Vendor Report - Vendors By Purchase Order</Typography>
        );
    }
  };
  return (
    <>
      <Grid item xs={12}>
        {header(reportSettings.grouping)}
      </Grid>
      <Grid item xs={12}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Vendor
              </TableCell>
              <TableCell align={'right'}>
                Total ({currencySymbol}) Ex Tax
              </TableCell>
              <TableCell align={'right'}>
                %
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.rows.map((row) =>
              <TableRow key={row._id}>
                <TableCell>{row.name}</TableCell>
                <TableCell align={'right'}>
                  <FormattedCurrency>
                    {row.total}
                  </FormattedCurrency>
                </TableCell>
                <TableCell align={'right'}>
                  {row.percentage}%
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell>Total</TableCell>
              <TableCell align={'right'}>
                <FormattedCurrency>
                  {data?.total}
                </FormattedCurrency>
              </TableCell>
              <TableCell align={'right'}>
                100%
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Grid>
    </>
  );
};

export const VendorReportSettings = () => {
  const {setReportSettings, reportSettings, getReportApi, isLoading} = useContext(ReportContext);
  reportSettings.grouping ?? setReportSettings({grouping: 'vendorsByQuote'});
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item lg={6} xs={12}>
                  <KeyboardDatePicker name='startDate' required label='Start Date' fullWidth={true} value={reportSettings.startDate} onChange={(value) => setReportSettings({startDate: value})} />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <KeyboardDatePicker name='endDate' required label='End Date' fullWidth={true} value={reportSettings.endDate} onChange={(value) => setReportSettings({endDate: value})} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item>
                  <RadioGroup defaultValue={reportSettings.grouping ?? 'vendorsByQuote'} onChange={(e) => setReportSettings({grouping: e.target.value})}>
                    <FormControlLabel
                      value='vendorsByQuote'
                      control={<Radio color='primary' />}
                      label='Vendor by Quote'
                    />
                    <FormControlLabel
                      value='vendorsByJob'
                      control={<Radio color='primary' />}
                      label='Vendor by Job'
                    />
                    <FormControlLabel
                      value='vendorsByPurchaseOrder'
                      control={<Radio color='primary' />}
                      label='Vendor by Purchase Order'
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent={'flex-end'}>
            <HoopsButton color={'primary'}
              purpose='add'
              variant={'contained'}
              onClick={() => getReportApi({data: reportSettings})}
              loading={isLoading}
            >
              Generate Report
            </HoopsButton>
          </Grid>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};
