import {gql} from 'apollo-boost';
import {SINGLE_PRODUCT_FRAGMENT} from './product';

export const CATALOG_PRODUCT_FRAGMENT = gql`
  fragment CatalogProduct on Product {
    _id
    readOnly
    viewOnlyCompanies {
      name
      _id
    }

    title
    code
    description
    vendor {
      name
      _id
    }

    brand
    category
    subCategory
    url

    companyId
    createdAt
    updatedAt
    createdById
    deleted

    primaryImage {
      _id
      contentLength
      contentType
      bucket
      key
      filename
      url
    }

    images {
      _id
      contentLength
      contentType
      bucket
      key
      filename
      url
    }
  }
`;

export const GQL_GET_CATALOG_PRODUCTS = gql`
    ${ SINGLE_PRODUCT_FRAGMENT }
    query searchCatalogProducts($q: String, $page: Int, $pageLimit: Int) {
    products(q: $q, page: $page, pageLimit: $pageLimit) {
        recordsTotal
        recordsFiltered
        page
        pageLimit
            data {
                ...SingleProduct
        }
    }
}
`;

export const GQL_GET_CATALOG_LIST_PRODUCTS = gql`
    query searchCatalogListProducts($q: String, $page: Int, $pageLimit: Int, $noPopulate: Boolean) {
    products(q: $q, page: $page, pageLimit: $pageLimit, noPopulate: $noPopulate) {
        recordsTotal
        recordsFiltered
        page
        pageLimit
            data {
            _id
            code
            title
            description

                vendor {
                name
                _id
            }

                primaryImage {
                url
            }
        }
    }
}
`;

export const GQL_GET_FULL_PRODUCTS = gql`
    ${ SINGLE_PRODUCT_FRAGMENT }
    query searchFullProducts($q: String, $page: Int, $pageLimit: Int) {
    products(q: $q, page: $page, pageLimit: $pageLimit) {
        recordsTotal
        recordsFiltered
        page
        pageLimit
            data {
                ...SingleProduct
        }
    }
}
`;

export const GQL_LIST_PRODUCTS = gql`
    query listProducts($catalog: String, $search: String, $page: Int, $pageSize: Int){
        listProducts(
            catalog: $catalog,
            search: $search,
            page: $page,
            pageSize: $pageSize,
        ){
            search
            items {
                brand
                code
                vendor
                category
                description
                title
                image
                moq
                _id
            }
        }
    }
`;

export const GQL_UPDATE_PRODUCT_VARIANTS = gql`
    mutation updateVariants($_id: String!, $data: [ProductVariantsInput]!) {
    updateVariants(_id: $_id, data: $data) {
        _id
            color {
            _id
            name
        }
            size {
            _id
            name
            description
        }
            priceBreaks {
            _id
            quantity
            price
        }
    }
}
`;

export const GQL_CREATE_PRODUCT_VARIANT = gql`
    mutation addProductVariant($productId: String!, $createVariantData: CreateVariationInput!) {
    createVariant(productId: $productId, data: $createVariantData) {
        _id
            color {
            _id
            name
        }
            size {
            _id
            name
            description
        }
        sku
            priceBreaks {
            _id
            quantity
            price
        }
    }
}
`;
