
export function commaSeparated(value) {
  if (value == null) {
    return '';
  }
  if (Array.isArray(value)) {
    return value.join(', ');
  }
  return value.toString();
}
