import {makeStyles, Button, MuiThemeProvider, TextField} from '@material-ui/core';
import React from 'react';
import green from '../../ui/theme/green';

const useStyles = makeStyles((theme) => ({
    buttons: {
        height: '32px',
        marginTop: '8px',
        marginRight: '8px'
    },
    cancel: {color: theme.colors.grey.main,},
    noPadding: {padding: 0},

}));

export const QuoteInlineTitleEdit = ({quoteTitle = '', onSave = () => null, onCancel = () => null}) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(quoteTitle);

    const handleChange = (event) => {
        setValue(event.target.value);
      };

    const handleSave = () => {
        onSave(value);
    };

    return (
        <div>
            <TextField variant='outlined' inputProps={{style: {padding: 8, fontSize: '0.875rem'}}} style={{padding: '8px'}} size='small' onChange={handleChange} value={value}></TextField>
            <MuiThemeProvider theme={green}>
                <Button variant={'contained'} size='small' color={'primary'} className={classes.buttons} type='button' onClick={handleSave} disabled={value ? value.trim() === '' : value}>Save</Button>
            </MuiThemeProvider>
            <Button size='small' className={`${classes.buttons} ${classes.cancel}`} onClick={onCancel}>Cancel</Button>
        </div>
    );
};
