import {
  SET_TAX,
  CREATE_TAX,
  UPDATE_TAX,
  REMOVE_TAX,
  UPDATE_TAXES,
} from '../actions/taxes';

export const newTax = {
  name: '',
  type: 'revenue',
  components: []
};

export const initialState = {
  active: {...newTax},
  list: {
    data: [],
    pagination: null,
    loaded: false
  }
};

const taxReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case UPDATE_TAXES:
      return {
        ...state,
        list: {
          data: payload,
          loaded: true
        }
      };

      case SET_TAX:
        return {
          ...state,
          active: payload
        };

    case CREATE_TAX:
      return {
        ...state,
        lastSaved: payload.record,
        list: {
          ...state.list,
          data: [
            ...state.list.data,
            payload.record
          ]
        }
      };

    case UPDATE_TAX:
      return {
        ...state,
        lastSaved: payload.record,
        list: {
          ...state.list,
          data: state.list.data.map(
            (item) => item._id === payload.recordId ? payload.record : item
          )
        }
      };

    case REMOVE_TAX:
      return {
        ...state,
        lastRemoved: payload,
        list: {
          ...state.list,
          data: state.list.data.filter(
            (item) => item._id === payload ? false : true
          )
        }
      };

    default:
      return state;
  }
};

export default taxReducer;
