import {
  SNACKBAR_TOGGLED,
  SNACK_ERROR,
  SNACK_INFO,
  SNACK_SUCCESS,
  SNACK_WARNING
} from '../actions/action-types/snackbar';

export const initialState = {
  snackOptions: {
    snackMessage: '',
    variant: ''
  },
  snackbarOpen: false,
};
const snackbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SNACKBAR_TOGGLED:
      return {
        snackbarOpen: !state.snackbarOpen,
        snackOptions: action.payload
      };
    case SNACK_ERROR:
      return {
        snackbarOpen: !state.snackbarOpen,
        snackOptions: {
          snackMessage: action.payload,
          variant: 'error'
        }
      };
    case SNACK_SUCCESS:
      return {
        snackbarOpen: !state.snackbarOpen,
        snackOptions: {
          snackMessage: action.payload,
          variant: 'success'
        }
      };
    case SNACK_WARNING:
      return {
        snackbarOpen: !state.snackbarOpen,
        snackOptions: {
          snackMessage: action.payload,
          variant: 'warning'
        }
      };
    case SNACK_INFO:
      return {
        snackbarOpen: !state.snackbarOpen,
        snackOptions: {
          snackMessage: action.payload,
          variant: 'info'
        }
      };
    default:
      return state;
  }
};

export default snackbarReducer;
