import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import {useSelector} from 'react-redux';
import {get} from 'lodash';

function RemoveProductsConfirmDialog({open, onOk, onCancel}) {

  const vendorSelector = (state) => state.vendorReducer.vendor;
  const vendor = useSelector(vendorSelector);

  return (
    <>
      <Dialog
        open={open}
      >
        <DialogTitle id='alert-dialog-title'>{'Are you sure?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            This process will remove all products in your catalog that are linked to <strong>{get(vendor, 'name')}</strong>.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color='primary'>
            Cancel
          </Button>
          <Button onClick={onOk} color='primary' autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default RemoveProductsConfirmDialog;
