import {MuiThemeProvider, Grid, Card, makeStyles, Typography, Checkbox} from '@material-ui/core';
import green from '../../ui/theme/green';
import React, {useState} from 'react';
import {roles} from '../../componentsHoops/AccessControl';

const useStyles = makeStyles((theme) => ({
    card: {
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid',
        borderColor: theme.colors.grey.main,
        width: '100%',
        height: '100%',
        padding: theme.spacing(2),
    },
    selectedCard: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        border: 'none'
    },
    grid: {width: '33.33%'},
}));

const UserRoleSelector = ({
    initialRole = null,
    onChange = () => null,
    input,
    
}) => {
    const classes = useStyles();
    const [role, setRole] = useState(initialRole);

    const handleCheck = (value) => {
        setRole(value);
        if(input && input.onChange){
            input.onChange(value);
        } 
        onChange(value);
    };

    return (
        <Grid container alignItems='stretch' direction='row' >
            <Grid item className={classes.grid} style={{display: 'flex', paddingRight: '8px'}} direction='column'>
                <Card elevation='0' className={`${classes.card} ${role === roles.admin && classes.selectedCard}`} >
                    <Typography variant='h5' style={{marginBottom: '8px'}}>Admin</Typography>
                    <Typography variant='body2'>Allow this user access to everything including: reports, pricing management, adding/removing catalogs and adding new users.</Typography>
                </Card>
                <Grid item container direction='row' justifyContent='center' alignItems='flex-start' >
                    <MuiThemeProvider theme={green}>
                        <Checkbox color='primary' onClick={() => handleCheck(roles.admin)} checked={role === roles.admin} />
                    </MuiThemeProvider>
                </Grid>
            </Grid>
            <Grid item className={classes.grid} style={{display: 'flex', paddingLeft: '8px', paddingRight: '8px'}} direction='column'>
                <Card elevation='0' className={`${classes.card} ${role === roles.standard && classes.selectedCard}`}>
                    <Typography variant='h5' style={{marginBottom: '8px'}}>Standard</Typography>
                    <Typography variant='body2'>This role suits those who create quotes, invoices, jobs and purchase orders but do not need access to decoration/catalog management, reports, or integrations. This user cannot add other users.</Typography>
                </Card>
                <Grid item container direction='row' justifyContent='center' alignItems='flex-start' >
                    <MuiThemeProvider theme={green}>
                        <Checkbox color='primary' onClick={() => handleCheck(roles.standard)} checked={role === roles.standard} />
                    </MuiThemeProvider>
                </Grid>
            </Grid>
            <Grid item className={classes.grid} style={{display: 'flex', paddingLeft: '8px'}} direction='column'>
                <Card elevation='0' className={`${classes.card} ${role === roles.lowAccess && classes.selectedCard}`}>
                    <Typography variant='h5' style={{marginBottom: '8px'}}>Low Access</Typography>
                    <Typography variant='body2'>This role suits those who manage jobs, proofs, files, schedules, and purchase orders, but do not need access to management functions such as updating pricing/catalogs or any organisation settings.</Typography>
                </Card>
                <Grid item container direction='row' justifyContent='center' alignItems='flex-start' >
                    <MuiThemeProvider theme={green}>
                        <Checkbox color='primary' onClick={() => handleCheck(roles.lowAccess)} checked={role === roles.lowAccess} />
                    </MuiThemeProvider>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default UserRoleSelector;
