// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {Route, Switch, withRouter} from 'react-router-dom';
import {TaxEditPage} from './TaxEditPage';
import TaxManyViewPage from './TaxManyViewPage';

export class TaxRoutes extends React.Component {

    componentDidMount() {
        document.title = 'Taxes | Hoops';
    }

    render() {
        return (
            <Fragment>
                <Switch>
                    <Route path='/settings/taxes' exact component={TaxManyViewPage} />
                    <Route path='/settings/taxes/new' exact render={() => <TaxEditPage />} />
                    <Route path='/settings/taxes/:_id/edit' exact render={() => <TaxEditPage />} />
                </Switch>
            </Fragment>
        );
    }
}

TaxRoutes = connect()(TaxRoutes);
TaxRoutes = withRouter(TaxRoutes);

export default TaxRoutes;
