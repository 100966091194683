// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {Table, TableBody} from '@material-ui/core';
import React, {useState} from 'react';
import {get} from 'lodash';
import JobItemTableItemHeader from './JobItemTableItemHeader';
import JobItemTableItemRow from './JobItemTableItemRow';
import JobItemTableVariantAdditionalItemHeader from './JobItemTableVariantAdditionalItemHeader';
import JobItemTableVariantAdditionalItemRow from './JobItemTableVariantAdditionalItemRow';
import JobItemTableVariantDecorationHeader from './JobItemTableVariantDecorationHeader';
import JobItemTableVariantDecorationRow from './JobItemTableVariantDecorationRow';
import JobItemTableVariantHeader from './JobItemTableVariantHeader';
import JobItemTableVariantRow from './JobItemTableVariantRow';

/**
 * Single Job Item Table
 *
 */
export const JobItemTable = ({
  jobItem = {},
  mode = 'checkbox',
  value = [],
  disabled = false,
  onChange = () => null
}) => {
  const [checked, setChecked] = useState(value);

  const variants = get(jobItem, 'jobItemVariant.variants');
  const decorations = get(jobItem, 'jobItemVariant.decorations');
  const additionalItems = get(jobItem, 'jobItemVariant.additionalItems');

  const hasVariants = () => variants && variants.length;

  const hasDecorations = () => decorations && decorations.length;

  const hasAdditionalItems = () => additionalItems && additionalItems.length;

  const getAllItemIds = () => {
    const result = [
      jobItem._id
    ];

    if (hasDecorations()) {
      result.push(...decorations.map((v) => v._id));
    }

    if (hasVariants()) {
      result.push(...variants.map((v) => v._id));
    }

    if (hasAdditionalItems()) {
      result.push(...additionalItems.map((v) => v._id));
    }

    return result;
  };

  const handleHeaderChecked = (checked) => {
    const itemIds = checked
     ? getAllItemIds()
     : [];

     setChecked(itemIds);
     onChange(itemIds);
  };

  const handleItemChecked = (_id, checked) => {
    setChecked((prev) => {
      const items = checked
        ? [...prev, _id] // add
        : prev.filter((prevId) => prevId !== _id); // remove

        onChange(items);
        return items;
    });
  };

  const isChecked = (_id) => checked.indexOf(_id) !== -1;

  return (
    <Table size='small'>
      <JobItemTableItemHeader mode={mode} />
      <TableBody>
        <JobItemTableItemRow
          jobItem={jobItem}
          onChecked={handleHeaderChecked}
          mode={mode}
          checked={isChecked(jobItem._id)}
          disabled={disabled}
          />
      </TableBody>

      {hasVariants() ? <>
        <JobItemTableVariantHeader mode={mode} />
        <TableBody>
          {jobItem.jobItemVariant.variants.map(
            (variant) =>
              <JobItemTableVariantRow
                key={variant._id}
                mode={mode}
                variant={variant}
                jobItem={jobItem}
                checked={isChecked(variant._id)}
                disabled={disabled}
                onChange={(checked) => handleItemChecked(variant._id, checked)}
              />
          )}
        </TableBody>
        {hasDecorations() ? <>
          <JobItemTableVariantDecorationHeader mode={mode} />
          <TableBody>
            {jobItem.jobItemVariant.decorations.map(
              (decoration) => <JobItemTableVariantDecorationRow
                key={decoration._id}
                mode={mode}
                decoration={decoration}
                checked={isChecked(decoration._id)}
                disabled={disabled}
                onChange={(checked) => handleItemChecked(decoration._id, checked)}
              />
            )}
          </TableBody>
        </>
          : null}

        {hasAdditionalItems() ? <>
          <JobItemTableVariantAdditionalItemHeader mode={mode} />
          <TableBody>
            {jobItem.jobItemVariant.additionalItems.map(
              (additionalItem, idx) => <JobItemTableVariantAdditionalItemRow
                key={idx}
                mode={mode}
                additionalItem={additionalItem}
                checked={isChecked(additionalItem._id)}
                disabled={disabled}
                onChange={(checked) => handleItemChecked(additionalItem._id, checked)}
              />
            )}
          </TableBody>
        </>
          : null}

      </> : null}
    </Table>
  );
};

export default JobItemTable;
