import {gql} from 'apollo-boost';

const VENDOR_FRAGMENT = gql`
    fragment SingleVendor on Vendor {
        _id
        name
        email
        ordersSite {
            website
            username
            password
        }
        contacts {
            _id
            firstName
            lastName
            fullName
            phone
            email
        }
        addresses {
            _id
            label
            address1
            address2
            city
            state
            postcode
            country
            formattedAddress
        }
        companyId
    }
`;

export const GQL_VENDORS_PAGINATION = gql`
    query VendorPagination(
        $filter: FilterFindPagingationSearchVendorInput,
        $sort: SortFindManyVendorInput,
        $page: Int,
        $perPage: Int
    ){
        vendorPagination(
            filter: $filter,
            sort: $sort,
            page: $page,
            perPage: $perPage
        ) {
            items {
                ...SingleVendor
            }
            pageInfo {
                perPage
                pageCount
                currentPage
                hasNextPage
                itemCount
            }
        }
    }
    ${VENDOR_FRAGMENT}
`;

export const GQL_GET_VENDORS_FOR_JOB = gql`
    query GetVendorsForJob($jobId: MongoID!){
        vendorsForJob(jobId: $jobId) {
            ...SingleVendor
        }
    }
    ${VENDOR_FRAGMENT}
`;

/**
 * Get a list of vendors that can be used to create a
 * Master / Merged Purchase Order
 */
export const GQL_GET_VENDORS_WITH_OPEN_PURCHASE_ORDERS = gql`
    query GetVendorsHasOpenPurchaseOrders {
        vendorHasOpenPurchaseOrders (minimum: 2) {
            _id
            name
        }
    }
`;

export const GQL_GET_VENDOR = gql`
    query GetVendor($_id: MongoID!) {
        vendorOne(filter: {_id: $_id}) {
            ...SingleVendor
        }
    }
    ${VENDOR_FRAGMENT}
`;

export const GQL_SEARCH_VENDORS = gql`
    query SearchVendors($filter: FilterFindManySearchVendorInput, $limit: Int) {
        vendorMany(filter: $filter, limit: $limit) {
            ...SingleVendor
        }
    }
    ${VENDOR_FRAGMENT}
`;

export const GQL_UPDATE_VENDOR = gql`
    mutation UpdateVendor($_id: MongoID!, $record: UpdateByIdVendorWithContactsInput!, $contacts: [GCContactInput]) {
        vendorUpdateById(_id: $_id, record: $record, contacts: $contacts ) {
            recordId
            record {
                ...SingleVendor
            }
        }
    }
    ${VENDOR_FRAGMENT}
`;

export const GQL_CREATE_VENDOR = gql`
    mutation CreateVendor($record: CreateOneVendorWithContactsInput!, $contacts: [CreateManyGCContactInput!]!) {
        vendorCreateOne(record: $record, contacts: $contacts) {
            record {
                ...SingleVendor
            }
        }
    }
    ${VENDOR_FRAGMENT}
`;

export const GQL_DELETE_VENDOR = gql`
    mutation DeleteVendor($_id: MongoID!) {
        vendorRemoveById(_id: $_id) {
            recordId
            record {
                ...SingleVendor
            }
        }
    }
    ${VENDOR_FRAGMENT}
`;
