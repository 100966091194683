import React, {Component} from 'react';
import {Divider, Typography} from '@material-ui/core';
import {connect} from 'react-redux';
import {ProofsTableThumbnail} from './ProofsTableThumbnail';
import ProofsTableActionCell from './ProofsTableActionCell';
import ProofsTableProductsCell from './ProofsTableProductsCell';
import moment from 'moment';
import ChipSelect from '../../ChipSelect';
import {PROOF_STATUSES} from '../../../../constants/status';
import {openProofUploadModal as _openProofUploadModal} from '../../../../actions/modals';
import {getProofs as _getProofs} from '../../../../actions/proofs';
import {Table, TableCell, TableRow} from '../../../../componentsLib/Layout';
import {registerGlobalStyle} from '../../../../theme';

registerGlobalStyle('.proofs-table', (theme) => ({
  width: '100%',
  'td': {border: 'none'},
  'td, th': {padding: theme.spacing(1)},
  'td:nth-of-type(1), th:nth-of-type(1)': {textAlign: 'center', width: 64},
  'td:nth-of-type(2), th:nth-of-type(2)': {textAlign: 'start', width: '30%'},
  'td:nth-of-type(3), th:nth-of-type(3)': {textAlign: 'start', width: 200},
  'td:nth-of-type(4), th:nth-of-type(4)': {textAlign: 'start'},
  'td:nth-of-type(5), th:nth-of-type(5), td:nth-of-type(6), th:nth-of-type(6)': {textAlign: 'center'},
  'td:nth-of-type(7), th:nth-of-type(7)': {textAlign: 'center', width: 140},
}));

class ProofsTable extends Component {

  componentDidMount() {
    const {job, getProofs} = this.props;
    getProofs(job._id);
  }

  componentDidUpdate(prevProps) {
    const prevJobId = prevProps.job?._id;

    const {job, getProofs} = this.props;
    if (job?._id !== prevJobId) {
      getProofs(job?._id);
    }
  }

  render() {
    const {proofs} = this.props;

    const awaitingApprovalData = proofs.filter(({jobId, status}) => jobId === this.props.job?._id && status === 'UPLOADED');
    const approvedData = proofs.filter(({jobId, status}) => jobId === this.props.job?._id && status === 'APPROVED');
    const rejectedData = proofs.filter(({jobId, status}) => jobId === this.props.job?._id && status === 'REJECTED');

    return (
      <>
        <Typography variant='h6'>{`Awaiting Approval (${awaitingApprovalData.length})`}</Typography>
        {awaitingApprovalData.length > 0 && <ProofTableLayout proofs={awaitingApprovalData}/>}
        <Divider style={{marginTop: 32, marginBottom: 32}} />
        <Typography variant='h6'>{`Approved (${approvedData.length})`}</Typography>
        {approvedData.length > 0 && <ProofTableLayout proofs={approvedData}/>}
        <Divider style={{marginTop: 32, marginBottom: 32}} />
        <Typography variant='h6'>{`Rejected (${rejectedData.length})`}</Typography>
        {rejectedData.length > 0 && <ProofTableLayout proofs={rejectedData}/>}
      </>
    );
  }
}

const ProofTableLayout = ({proofs}) => (
  <Table
    className={'proofs-table'}
    labels={['Preview', 'File Name', 'Linked Products', 'Uploaded By', 'Date', 'Status', 'Action',]}
  >
    {proofs.map((rowData) => (
      <TableRow key={rowData._id}>
        <TableCell>
          <ProofsTableThumbnail imageUrl={rowData.file.url} contentType={rowData.file.contentType} />
        </TableCell>
        <TableCell text={rowData.file.filename} />
        <TableCell><ProofsTableProductsCell products={rowData.products} /></TableCell>
        <TableCell text={rowData.createdBy.fullName} />
        <TableCell>{moment(rowData.createdAt, 'x').format('Do MMM YYYY')}</TableCell>
        <TableCell><ChipSelect disabled activeOption={rowData.status} options={PROOF_STATUSES} /></TableCell>
        <TableCell><ProofsTableActionCell proof={rowData} /></TableCell>
      </TableRow>
    ))}
  </Table>
);

function mapStateToProps(state) {
  return {
    job: state.jobsReducer.activeJob,
    proofs: state.proofReducer.proofs
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProofs: (jobId) => dispatch(_getProofs(jobId)),
    openProofUploadModal: (jobId) => dispatch(_openProofUploadModal(jobId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProofsTable);
