import {themeOptions} from '../ui/theme';

const ON_HOLD_COLOR = '#FAB1A0';
const AWAITING_PAYMENT_COLOR = '#A29BFE';
const DEPOSIT_PAID_COLOR = '#FD79A8';
const PAID_COLOR = '#00CEC9';

export const JOB_STATUSES = [{
  name: 'CREATED',
  color: themeOptions.palette.secondary.main,
}, {
  name: 'ARTWORK',
  color: themeOptions.colors.orange,
}, {
  name: 'PROOF SENT',
  color: themeOptions.palette.primary.main,
}, {
  name: 'PROOF APPROVED',
  color: themeOptions.colors.green
}, {
  name: 'PROOF REJECTED',
  color: themeOptions.colors.red
}, {
  name: 'ON HOLD',
  color: ON_HOLD_COLOR
}, {
  name: 'IN PRODUCTION',
  color: themeOptions.colors.purple
}, {
  name: 'AWAITING PAYMENT',
  color: AWAITING_PAYMENT_COLOR
}, {
  name: 'DEPOSIT PAID',
  color: DEPOSIT_PAID_COLOR
}, {
  name: 'PAID',
  color: PAID_COLOR
}, {
  name: 'COMPLETE',
  color: themeOptions.palette.secondary.dark
}];

export const VARIANT_STATUSES = [{
  name: 'IN PROGRESS',
  color: themeOptions.palette.secondary.main,
}, {
  name: 'ORDERED',
  color: themeOptions.palette.primary.main,
}, {
  name: 'BACKORDER',
  color: ON_HOLD_COLOR,
}, {
  name: 'OUT OF STOCK',
  color: themeOptions.colors.red,
}, {
  name: 'RECEIVED',
  color: themeOptions.colors.green
}];

export const ITEM_STATUSES = [{
  name: 'IN PROGRESS',
  color: themeOptions.palette.secondary.main,
}, {
  name: 'ARTWORK',
  color: themeOptions.colors.orange,
}, {
  name: 'PROOF SENT',
  color: themeOptions.palette.primary.main,
}, {
  name: 'PROOF APPROVED',
  color: themeOptions.colors.green
}, {
  name: 'PROOF REJECTED',
  color: themeOptions.colors.red
}, {
  name: 'ON HOLD',
  color: ON_HOLD_COLOR
}, {
  name: 'IN PRODUCTION',
  color: themeOptions.colors.purple
}, {
  name: 'COMPLETE',
  color: themeOptions.palette.secondary.dark
}];

export const PROOF_STATUSES = [{
  name: 'UPLOADED',
  color: themeOptions.colors.purple
}, {
  name: 'APPROVED',
  color: themeOptions.colors.green
}, {
  name: 'REJECTED',
  color: themeOptions.colors.red
}];

export const ARTWORK_STATUSES = [{
  name: 'UPLOADED',
  color: themeOptions.colors.purple
}, {
  name: 'APPROVED',
  color: themeOptions.colors.green
}, {
  name: 'REJECTED',
  color: themeOptions.colors.red
}];

export const PURCHASE_ORDER_STATUSES = [{
  name: 'CREATED',
  color: themeOptions.palette.secondary.main,
}, {
  name: 'PARTIALLY ORDERED',
  color: themeOptions.colors.purple
}, {
  name: 'ORDERED',
  color: themeOptions.palette.primary.main
}, {
  name: 'PARTIALLY RECEIVED',
  color: themeOptions.colors.orange
}, {
  name: 'RECEIVED',
  color: themeOptions.colors.green
},  {
  name: 'BACKORDER',
  color: themeOptions.colors.red
}];

export const QUOTE_STATUSES = [{
  name: 'CREATED',
  color: themeOptions.palette.secondary.main,
}, {
  name: 'SENT',
  color: themeOptions.palette.primary.main
}, {
  name: 'FOLLOW UP',
  color: themeOptions.colors.orange
}, {
  name: 'ACCEPTED',
  color: themeOptions.colors.green
}, {
  name: 'LOST',
  color: themeOptions.colors.red
}];
