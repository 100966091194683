// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-class-assign */

import React, {Component, Fragment} from 'react';
import {useTheme, withStyles} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import ItemThumbnail from '../quotes/PreviewQuote/ItemThumbnail';
import Paper from '@material-ui/core/Paper';

const styles = (theme) => ({
  popper: {opacity: 1,},
  tooltip: {
    maxWidth: '450px',
    backgroundColor: 'transparent'
  },
});

export function ImagePreview({imageUrl}) {
  const theme = useTheme();
  return (
    <Paper style={{width: '100%', padding: theme.spacing(1)}}>
      <img src={imageUrl} alt='' style={{maxWidth: '100%'}}/>
    </Paper>
  );
}

class ImageWithTooltip extends Component {

  render() {
    const {classes, imageUrl, onClick} = this.props;

    return (
      <Fragment>
        {imageUrl && <Tooltip
          classes={{popper: classes.popper, tooltip: classes.tooltip}}
          title={<ImagePreview imageUrl={imageUrl}/>}
          placement={'right'}
        >
          <Button onClick={onClick}>
            <ItemThumbnail url={imageUrl}/>
          </Button>
        </Tooltip>}
      </Fragment>
    );
  }
}

ImageWithTooltip = withStyles(styles)(ImageWithTooltip);

export default ImageWithTooltip;
