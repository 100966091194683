import {createGraphQLClient} from './GraphQLService';
import {
  GET_COMPANY,
  GQL_GET_COMPANY_FROM_JOB,
  GQL_UPDATE_COMPANY,
  GQL_UPDATE_COMPANY_NUMBERING,
} from '../queries/companies';
import {Company} from '../interfaces';
import {toFloat} from '../componentsOld/shared/numberUtils';

const client = createGraphQLClient({});

export const parseCompany = (company: Partial<Company>): Partial<Company> => {
  const {
    defaultTimezone,
    quoteTerms,
    currencySymbol,
    proofTerms,
    invoiceTerms,
    subscriptionType,
    stripeSubscriptionId,
    leadTypes,
    industryTypes,
    quoteNumber,
    jobNumber,
    purchaseOrderNumber,
    royaltySettings
  } = company;

  company.companyTradingEntities = company.companyTradingEntities ?? [];
  const companyTradingEntities = company.companyTradingEntities.map((entity) => {
    const {_id: entityId, name, phone, email, website, logo} = entity;
    entity.addresses = entity.addresses || [];
    const addresses = entity.addresses.map((address) => {
      const {_id, address1, address2, city, state, postcode, country} = address;
      return {
        _id,
        address1,
        address2,
        city,
        state,
        postcode,
        country,
      };
    });
    return {
      _id: entityId,
      name,
      phone,
      email,
      website,
      addresses,
      logo,
    };
  });

  const royaltySettingsParse = {
    royaltyCap: toFloat(royaltySettings?.royaltyCap ?? 0),
    royaltyPercentage: toFloat(royaltySettings?.royaltyPercentage ?? 0),
    marketingFee: toFloat(royaltySettings?.marketingFee ?? 0),
    storeID: royaltySettings?.storeID ?? '',
  };

  return {
    companyTradingEntities,
    defaultTimezone,
    quoteTerms,
    proofTerms,
    invoiceTerms,
    currencySymbol,
    subscriptionType,
    stripeSubscriptionId,
    leadTypes,
    industryTypes,
    quoteNumber: toFloat(quoteNumber),
    jobNumber: toFloat(jobNumber),
    purchaseOrderNumber: toFloat(purchaseOrderNumber),
    royaltySettings: royaltySettingsParse
  };
};

export class CompanyService {
  company: Company;

  constructor(company: Company) {
    this.company = company;
  }

  static getCompanyFromJob(jobId: string) {
    return client
      .query({
        query: GQL_GET_COMPANY_FROM_JOB,
        variables: {jobId,},
      })
      .then(({data: {company}}) => company);
  }

  static getCompany() {
    return client
      .query({
        query: GET_COMPANY,
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        const {data: {company},} = result;
        let {data: {company: {Taxes, addresses},},} = result;

        Taxes = Taxes || [];
        addresses = addresses || [];
        return {
          ...company,
          Taxes,
          addresses,
        };
      });
  }

  save() {
    return client.mutate({
      mutation: GQL_UPDATE_COMPANY,
      variables: {
        _id: this.company._id,
        record: parseCompany(this.company),
      },
    });
  }

  updateEntityNumbers() {
    return client.mutate({
      mutation: GQL_UPDATE_COMPANY_NUMBERING,
      variables: {
        _id: this.company._id,
        jobNumber: parseInt(this.company.jobNumber.toString(), 10),
        quoteNumber: parseInt(this.company.quoteNumber.toString(), 10),
        purchaseOrderNumber: parseInt(this.company.purchaseOrderNumber.toString(), 10),
      },
    });
  }
}
