import {useLazyQuery} from '@apollo/client';
import {Card, CardContent, Grid, Link as MuiLink, Typography} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {GQL_GET_CUSTOMER} from '../../queries/customers';

// Duplicate of List.jsx
const getEntityLabel = ({entityType, entityData}) => {
    switch (entityType) {
        case 'Customer':
            return entityData.name;

        case 'Job':
            return `Job #${entityData.number} | ${entityData.customer.name}`;

        case 'Quote':
        default:
            return `Quote #${entityData.number} | ${entityData.customer.name}`;

    }
};

// Duplicate of List.jsx
const getEntityLink = ({entityType, entityData}) => {
    switch (entityType) {
        case 'Customer':
            return `/customers/${entityData._id}/details`;

        case 'Job':
            return `/jobs/${entityData._id}/details`;

        case 'Quote':
        default:
            return `/quotes/${entityData._id}/view`;

    }
};

const EntityLink = ({entityType, entityData}) => (
        <MuiLink
            component={Link}
            to={getEntityLink({entityType, entityData})}
            alt={getEntityLabel({entityType, entityData})}
            underline={'none'}
            color={'primary'}
            display='block'
        >
            {getEntityLabel({entityType, entityData})}
        </MuiLink>
    );

export const TaskPageDetails = ({task}) => {
    const [customer, setCustomer] = useState('');

    const [getCustomer] = useLazyQuery(GQL_GET_CUSTOMER, {
        fetchPolicy: 'no-cache',
        variables: {_id: task.customerId,},
        onCompleted: (result) => {
            if (result) {
                setCustomer(result.customerOne);
            }
        },
    });

    useEffect(() => {
        task.customerId && getCustomer();
    }, [task.customerId,getCustomer]);

    if (!task._id) {
        return null;
    }

    return (
        <Card>
            <CardContent>
                <Grid container spacing={3} direction='column'>
                    <Grid item>
                        <Typography variant='subtitle2'>Title</Typography>
                        <Typography>{task.title}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant='subtitle2'>Description</Typography>
                        <Typography>{task.description}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant='subtitle2'>Created by</Typography>
                        <Typography>{task.createdBy?.fullName}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant='subtitle2'>Assigned to</Typography>
                        {task.members.map((member) => <Typography key={member._id}>{member.fullName}</Typography>)}
                    </Grid>
                    <Grid item>
                        <Typography variant='subtitle2'>Customer</Typography>
                        <EntityLink entityType='Customer' entityData={customer} />
                    </Grid>
                    <Grid item>
                        <Typography variant='subtitle2'>Associated with</Typography>
                        {task.jobs.map((entity) => <EntityLink key={entity._id} entityType='Job' entityData={entity} />)}
                        {task.quotes.map((entity) => <EntityLink key={entity._id} entityType='Quote' entityData={entity} />)}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );

};

export default TaskPageDetails;
