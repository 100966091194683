import {
  SET_CATEGORY_SUGGESTIONS,
  SET_DECORATION, SET_DECORATIONS, FETCH_DECORATIONS
} from '../actions/decoration';

const initialState = {
  list: [],
  listLoading: false,
  decoration: {priceBreaks: [{}, {}, {}]},
  categorySuggestions: []
};

const decorationReducer = (state = {...initialState}, action) => {
  switch (action.type) {
    case FETCH_DECORATIONS:
      return {
        ...state,
        listLoading: true
      };
    case SET_DECORATIONS:
      return {
        ...state,
        list: action.payload,
        listLoading: false
      };
    case SET_DECORATION:
      return {
        ...state,
        decoration: {
          ...initialState.decoration,
          ...action.payload
        }
      };
    case SET_CATEGORY_SUGGESTIONS:
      return {
        ...state,
        categorySuggestions: action.payload
      };
    default:
      return state;
  }
};

export default decorationReducer;
