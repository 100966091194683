import React from 'react';
import {get} from 'lodash';
import DialogTitle from '../../DialogTitle';
import {useDispatch, useSelector} from 'react-redux';
import {closeSendProofModal} from '../../../../actions/modals';
import SendEmailForm from '../../SendEmailForm';
import {Dialog, Typography} from '@mui/material';

function SendProofModal() {
  const dispatch = useDispatch();

  const openSelector = (state) => state.modalReducer.jobSendProofModal.isOpen;
  const emailTypeSelector = (state) => state.jobsReducer.emailType;

  const open = useSelector(openSelector);
  const emailType = useSelector(emailTypeSelector);

  const jobEmailType = emailType === 'job';

  const handleClose = () => {
    dispatch(closeSendProofModal());
  };

  const initialValuesSelector = (state) => ({
      viaEmail: true,
      viaSms: false,
      to: get(state, 'jobsReducer.activeJob.contact.email'),
      replyTo: get(state, 'authReducer.userData.username'),
      body: '',
      phone: get(state, 'jobsReducer.activeJob.contact.phone', ''),
      smsBody: ''
    });

  const initialValues = useSelector(initialValuesSelector);

  return (
    <Dialog
      disableRestoreFocus
      disableEnforceFocus
      disableAutoFocus
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth='lg'
    >
      <DialogTitle onClick={handleClose}>
        <Typography variant='h4'>Send {jobEmailType ? 'Job' : 'Proof'}</Typography>
      </DialogTitle>
      <SendEmailForm initialValues={initialValues} handleClose={handleClose} type={emailType} entityLocation={jobEmailType ? 'job' : 'proof'} />
    </Dialog>
  );
}

export default SendProofModal;
