// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import React, {useCallback} from 'react';
import {
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {get} from 'lodash';
import {Form, Field} from 'react-final-form';
import SingleFileUpload from '../../common/SingleFileUpload';
import {updateProduct, updateProductColor} from '../../../actions/catalog';
import arrayMutators from 'final-form-arrays';
import {FieldArray} from 'react-final-form-arrays';

function UploadImageField({classes, color, name, onComplete, readOnly}) {
  return (
    <Grid item xs={4} container wrap={'nowrap'}>
      <Grid item className={classes.padding} style={{display: 'flex', alignItems: 'center'}}>
        <Field
          name={name}
          component={SingleFileUpload}
          onComplete={onComplete}
          disabled={readOnly}
        />
      </Grid>
      <Grid item container className={classes.padding} alignItems={'center'}>
        <Typography variant={'body1'}>{color ? color.name : 'Primary'}</Typography>
      </Grid>
    </Grid>
  );
}

const productSelector = (state) => state.catalogReducer.activeProduct;

function Images({handleSubmit}) {
  const useStyles = makeStyles((theme) => ({
    padding: {padding: theme.spacing(2)},
    fullWidth: {width: '100%'}
  }));
  const classes = useStyles();
  const product = useSelector(productSelector);
  const dispatch = useDispatch();

  return (
    <Grid container>
      <Form onSubmit={console.log}
        initialValues={product}
        mutators={{...arrayMutators}}
        render={({values}) => {

          const handleColorImageComplete = (field, file) => {
            const {_id, name} = get(values, field, {});
            dispatch(updateProductColor(_id, {image: file, name, productId: product._id}));
          };

          const handlePrimaryImageComplete = (field, file) => {
            const {_id} = product;
            dispatch(updateProduct(_id, {primaryImage: file}));
          };

          return (
            <>
              <form onSubmit={handleSubmit} style={{width: '100%'}}>
                <Grid container spacing={5}>
                  <Grid item className={classes.padding} style={{display: 'flex', alignItems: 'center'}}>
                    <UploadImageField
                      name={'primaryImage'}
                      classes={classes}
                      onComplete={(file) => handlePrimaryImageComplete(null, file)}
                      readOnly={product.readOnly}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={5}>
                  <FieldArray name={'colors'}>
                    {({fields}) =>
                      fields.map((field) => (
                          <UploadImageField
                            key={field}
                            name={`${field}.image`}
                            classes={classes}
                            color={get(values, field)}
                            readOnly={product.readOnly}
                            onComplete={(file) => handleColorImageComplete(field, file)}
                          />
                        ))
                    }
                  </FieldArray>
                </Grid>
              </form>
            </>
          );
        }} />
    </Grid>
  );
}

export default Images;
