import {IconButton} from '@material-ui/core';
import {IconButtonProps} from '@material-ui/core/IconButton/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import React from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';

interface HoopsIconButtonProps extends IconButtonProps {
  purpose?: 'delete' | 'menu' | 'add' | 'none' | 'next' | 'previous' | 'edit',
  theme?: Object
}

export const HoopsIconButton = ({
  purpose,
  ...props
}: HoopsIconButtonProps) => (
  <>
    {(() => {
      switch (purpose) {
        case 'delete':
          return <IconButton component='span'  {...props}><DeleteIcon /></IconButton>;
        case 'menu':
          return <IconButton component='span'  {...props}><MoreHorizIcon /></IconButton>;
        case 'add':
          return <IconButton component='span'  {...props}><AddCircleIcon /></IconButton>;
        case 'edit':
          return <IconButton component='span'  {...props}><EditIcon /></IconButton>;
        case 'next':
          return <IconButton component='span'  {...props}><KeyboardArrowRightIcon /></IconButton>;
        case 'previous':
          return <IconButton component='span'  {...props}><KeyboardArrowLeftIcon /></IconButton>;
        default:
          return <IconButton component='span'  {...props} />;
      }
    })()}
  </>
);
