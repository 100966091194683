// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import {makeStyles} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
    greyText: {color: theme.colors.grey.main,},
    selectClass: {
        minWidth: 184,
        '& .MuiChip-root': {
            backgroundColor: theme.palette.primary.main,
            color: theme.colors.white,
            height: 28,
            // textTransform: "capitalize"
        },
        '& .MuiChip-deleteIcon': {
            color: theme.colors.white,
            opacity: '.5'
        }
    },
}));

const ControlledAutocomplete = ({multiple = false, disableCloseOnSelect = false, options = [], renderInput, getOptionLabel, getOptionSelected, onChange: ignored, defaultValue, name, renderOption}) => {
    const classes = useStyles();
    const {control} = useFormContext();

    return (
        <Controller
            render={({onChange, ...props}) => (
                    <Autocomplete
                        className={classes.selectClass}
                        options={options}
                        multiple={multiple}
                        disableCloseOnSelect={disableCloseOnSelect}
                        getOptionLabel={getOptionLabel}
                        renderOption={renderOption}
                        renderInput={renderInput}
                        getOptionSelected={getOptionSelected}
                        onChange={(e, data) => onChange(data)}
                        style={{minWidth: 225}}
                        {...props}
                    />
            )}
            onChange={([, data]) => data}
            defaultValue={defaultValue}
            name={name}
            control={control}
        />
    );
};

export default ControlledAutocomplete;
