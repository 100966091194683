import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import PreviewQuoteContent from './PreviewQuoteContent';
import Loading from '../../shared/Loading';
import {connect} from 'react-redux';
import DialogTitle from '../../shared/DialogTitle';

const styles = () => ({
  titleContainer: {
    display: 'flex', 
    justifyContent: 'center'
  },
  contentContainer: {backgroundColor: '#FAFAFA'}
});

class PreviewQuoteDialog extends Component {
  render() {
    const {open, handleClose, quote, classes} = this.props;
    return(
      <Dialog
          onClose={handleClose}
          aria-labelledby='customized-dialog-title'
          open={open}
          maxWidth={ 'lg' }
          fullWidth={true}
          onBackdropClick={handleClose}
        >
          <DialogTitle onClick={handleClose}>
              <Typography variant='h5'>Quote Preview</Typography>
          </DialogTitle>  
            <MuiDialogContent className={classes.contentContainer}>
              {quote ? <PreviewQuoteContent quote={quote} renderSiblings={false} tenantView={true}/> : <Loading text={'Loading quote..'} /> }
            </MuiDialogContent> 
        </Dialog>
    );
  }
}

function mapStateToProps(state){
  return {quote: state.quoteReducer.quote};
}

export default connect(mapStateToProps)(withStyles(styles)(PreviewQuoteDialog));
