import {useApolloClient} from '@apollo/client';
import {FormControl, makeStyles, MenuItem, MuiThemeProvider, Paper, Select, TextField, Tooltip, Typography} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InfoIcon from '@material-ui/icons/Info';
import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import {useParams} from 'react-router-dom';
import {getCompany} from '../../actions/action-types/company';
import EntityListHero from '../../componentsOld/EntityList/EntityListHero';
import {GQL_GET_TAX} from '../../queries/taxes';
import {editEntity} from '../../ui/theme/editEntity';
import green from '../../ui/theme/green';
import {TaxRateForm} from './TaxRateForm';
import TaxService from './TaxService';

const useStyles = makeStyles((theme) => (
  {
    ...editEntity,
    link: {paddingLeft: theme.spacing(2)},
    inputWidth: {width: 176},
    vertDivider: {
      margin: '0 auto',
      color: theme.colors.grey.light,
    },
    // this styling was lifed from CardContainer.jsx
    card: {
      width: '100%',
      padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
      marginBottom: theme.spacing(4)
    },

  }
));

export const TaxEditPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const {_id} = useParams();
  const {query} = useApolloClient();
  const form = useForm({
    mode: 'all',
    criteriaMode: 'all',
    defaultValues: {
      components: [{
        name: null,
        rate: null
      }]
    }

  });

  const {control, handleSubmit, reset, formState, errors} = form;

  /**
   * We are doing this because we want to update the form
   */
  useEffect(() => {
    if (!_id) {
      return;
    }

    const fetch = async () => {
      const {data} = await query({
        query: GQL_GET_TAX,
        variables: {_id}
      });

      reset(data.taxOne);
    };

    fetch();
  }, [_id, query, reset]);

  const cancel = () => {
    history.push('/settings/taxes');
  };

  const handleOnSubmit = (values) => {
    if (_id) {
      TaxService.updateTax(_id, values);
    } else {
      TaxService.createTax(values);
    }

    dispatch(getCompany()); // Update the company because this is where we are storing taxes
    history.push('/settings/taxes');
  };

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)} noValidate autoComplete='off'>
      <Grid container>
        <EntityListHero
          title={(_id ? 'Edit ' : 'New ') + 'Tax'}
          subtitle='Manage your taxes for sale and purchases'
        >

          <Button
            data-intercom-target={'quote-create'}
            onClick={cancel}
            className={`${classes.whiteButton} ${classes.buttons}`}
            disableElevation
            variant={'contained'}>Cancel</Button>

          <MuiThemeProvider theme={green}>
            <Button
              color={'primary'}
              type='submit'
              data-intercom-target={'quote-save'}
              disabled={!formState.isValid}
              className={classes.buttons}
              variant={'contained'}>Save</Button>
          </MuiThemeProvider>

        </EntityListHero>
        <Grid container
          justifyContent={'space-between'}
          alignItems={'center'}
          spacing={3}
        >
          <Grid item xs={12} data-intercom-target={'quote-table'}>
            <Paper className={classes.card}>
              <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' spacing={2}>
                <Grid item>
                  <Controller
                    render={(props) => <>
                      <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start'>
                        <Grid item><Typography variant='caption'>Name </Typography></Grid>
                        <Grid item>
                          <Tooltip placement='top' arrow classes={{tooltip: classes.tooltip}}
                            title={<>
                              <Typography align='center' variant='caption' display='block'>This is the name of the tax that will be displayed in the dropdown wherever you can select tax in Hoops.</Typography>
                              <br />
                              <Typography align='center' variant='caption' display='block'>For single taxes, this may be &quot;GST&quot;, or &quot;VAT&quot;
                                For multiple taxes, this may be &quot;Sales + County&quot; or &quot;Local + Provincial&quot;.</Typography>
                            </>}><InfoIcon fontSize='small' className={classes.icon} /></Tooltip>
                        </Grid>
                      </Grid>

                      <TextField className={`${classes.inputStyles} ${classes.inputWidth}`} fullWidth required variant='outlined' {...props} error={!!errors.name} helperText={errors.name ? errors.name.message : null} placeholder='Enter Name...'/>
                    </>}
                    name='name'
                    control={control}
                    rules={{required: {value: true, message: 'Name is Required'}}}
                    defaultValue=''
                  />
                </Grid>
                <Grid item>

                  <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start'>
                    <Grid item><Typography variant='caption'>Tax Type </Typography></Grid>
                    <Grid item>
                      <Tooltip placement='top' arrow classes={{tooltip: classes.tooltip}}
                        title={<>
                          <Typography align='center' variant='caption' display='block'>Choose the tax type.</Typography>
                          <Typography align='center' variant='caption' display='block'>Revenue taxes are used in any sales document such as a quote.</Typography>
                          <Typography align='center' variant='caption' display='block'>Expsense tax type is used for purchase orders.</Typography>
                        </>}><InfoIcon fontSize='small' className={classes.icon} /></Tooltip>
                    </Grid>
                  </Grid>

                  <Controller
                    name='type'
                    defaultValue='none'
                    required={true}
                    render={(field) =>
                      <FormControl error={errors.type}>
                        <Select
                          style={{maxHeight: '43px'}}
                          variant='outlined'
                          className={`${classes.inputStyles} ${classes.inputWidth}`}
                          {...field}
                        >
                          <MenuItem value='none' disabled><span style={{color: '#a9aaae'}}>Select...</span></MenuItem>
                          <MenuItem value='revenue'>Revenue</MenuItem>
                          <MenuItem value='expense'>Expense</MenuItem>
                        </Select>
                      </FormControl>
                    }
                    control={control}
                  />
                </Grid>
              </Grid>
              <TaxRateForm form={form} dataId={_id}/>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );

};

export default TaxEditPage;
