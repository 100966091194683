// TODO: fix eslint disable
/* eslint-disable no-class-assign, no-shadow */

import React, {Fragment} from 'react';
import Paper from '@material-ui/core/Paper';
import {IconButton, Typography, withStyles} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import MaterialTable from 'material-table';
import {getInvoices} from '../../actions/billing';
import {connect} from 'react-redux';
import * as numeral from 'numeral';
import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import Link from '@material-ui/core/Link';

const styles = (theme) => ({paper: {padding: theme.spacing(2)}});

class InvoiceHistory extends React.Component {
  state = {
    columns: [{
      title: 'Date',
      field: 'created',
      render: (data) => moment(data.created, 'X').format('D MMM YYYY')
    }, {
      title: 'Amount',
      field: 'total',
      render: (data) => numeral(data.total / 100).format('0,0.00')
    }, {
      title: 'View',
      field: 'invoice_pdf',
      render: (data) => (
          <Link href={data.invoice_pdf} target={'_blank'}>
            <IconButton>
              <Icon fontSize={'small'} color={'primary'}>description</Icon>
            </IconButton>
          </Link>
        )
    }],
    tableOptions: {
      showTitle: false,
      selection: false,
      pageSize: 10,
    }
  };

  UNSAFE_componentWillMount() {
    const {getInvoices} = this.props;
    getInvoices();
  }

  render() {
    const {classes, invoices} = this.props;
    const {columns, tableOptions} = this.state;

    return (
      <Fragment>
        <Grid container>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Typography variant={'h4'}>Invoice History</Typography>
              <MaterialTable className={classes.table} columns={columns} data={invoices} options={tableOptions} components={{Container: 'div'}}/>
            </Paper>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {invoices: state.billingReducer.invoices,};
}

function mapDispatchToProps(dispatch) {
  return {getInvoices: () => dispatch(getInvoices())};
}

InvoiceHistory = withStyles(styles)(InvoiceHistory);
InvoiceHistory = connect(mapStateToProps, mapDispatchToProps)(InvoiceHistory);

export default InvoiceHistory;
