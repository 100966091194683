// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React from 'react';
import {
Grid, MuiThemeProvider, IconButton, Icon, withStyles,
  Button, InputAdornment
} from '@material-ui/core';
import {renderInputField, renderTextField} from '../shared/formHelpers/fields';
import {Field, reduxForm, FieldArray} from 'redux-form';
import {connect} from 'react-redux';
import ContactFields from '../contacts/contactFields';
import AddressFields from '../address/addressFields';
import red from '../../ui/theme/red';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {required, email} from '../shared/formHelpers/validation';

const styles = (theme) => ({
  gridPadding: {
    paddingTop: 0,
    paddingBottom: 0
  },
  formBlock: {marginBottom: theme.spacing(4)},
  vendorContact: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1)
  },
});

class VendorForm extends React.Component {
  state = {showPassword: false};

  handleClickShowPassword = () => {
    this.setState((state) => ({showPassword: !state.showPassword}));
  };

  renderContactFields = ({fields}) => {
    const {classes} = this.props;
    return (
      <Grid container>
        {fields.map((contact, index) => (
            <Grid container key={index} className={classes.vendorContact}>
              <Grid container item justifyContent={'center'} alignItems={'center'} xs={2}>
                {<MuiThemeProvider theme={red}>
                  <IconButton size='small' color='primary' disabled={fields.length === 1}
                              onClick={() => fields.splice(index, 1)}>
                    <Icon>delete</Icon>
                  </IconButton>
                </MuiThemeProvider>}
              </Grid>
              <Grid item xs={10}>
                <ContactFields contact={contact} notRequired={true}/>
              </Grid>
            </Grid>
          ))}
        <Grid container>
          <Button variant='text' color={'primary'} onClick={() => fields.push({})}>
            Add Another Contact
          </Button>
        </Grid>
      </Grid>
    );
  };

  renderAddressFields = ({fields}) => (
      <Grid container>
        {fields.map((address, index) => (
          <Grid container key={index} className={this.props.classes.formBlock}>
            <AddressFields address={address} showLabelInput={false} showAddressLine2={false}/>
          </Grid>
        ))}

        {fields.length < 2 && (<Grid container>
          <Button variant='text' color={'primary'} onClick={() => fields.push({})}>
            Add Another Address
          </Button>
        </Grid>)}
      </Grid>
    );

  render() {
    const {onSubmit, classes} = this.props;
    return (
      <form onSubmit={onSubmit} autoComplete={'off'}>
        <Grid container spacing={2}>
          <Grid item md={12} lg={12}>
            <Grid container spacing={2} className={classes.formBlock}>
              <Grid item xs={12} className={classes.gridPadding}>
                <Field fullWidth
                       label={'Vendor Name *'}
                       name={'name'}
                       type={'text'}
                       validate={required}
                       component={renderTextField}/>
              </Grid>
              <Grid item xs={12} className={classes.gridPadding}>
                <Field fullWidth
                       label={'Email *'}
                       name={'email'}
                       type={'email'}
                       validate={[required, email]}
                       component={renderTextField}/>
              </Grid>
              <Grid item>
                <Grid item xs={12}>
                  <FieldArray name={'contacts'} component={this.renderContactFields}/>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <FieldArray name={'addresses'} component={this.renderAddressFields}/>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.gridPadding}>
                <Field fullWidth
                       label={'Orders Website'}
                       name={'ordersSite.website'}
                       type={'text'}
                       component={renderTextField}/>
              </Grid>
              <Grid item xs={6} className={classes.gridPadding}>
                <Field fullWidth
                       label={'Username'}
                       name={'ordersSite.username'}
                       type={'text'}
                       component={renderTextField}/>
              </Grid>
              <Grid item xs={6} className={classes.gridPadding}>
                <Field fullWidth
                       label={'Password'}
                       name={'ordersSite.password'}
                       type={this.state.showPassword ? 'text' : 'password'}
                       endAdornment={
                         <InputAdornment position='end'>
                           <IconButton
                             aria-label='Toggle password visibility'
                             onClick={this.handleClickShowPassword}
                           >
                             {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                           </IconButton>
                         </InputAdornment>
                       }
                       component={renderInputField}/>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  }
}

VendorForm = reduxForm({
  form: 'vendorForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(VendorForm);
VendorForm = connect()(VendorForm);
VendorForm = withStyles(styles)(VendorForm);

export default VendorForm;
