import store from '../store';

export function getLocalStorageItem(key, defaultValue) {
  try {
    if (key) {
      const userId = store.getState()?.authReducer?.userData?._id;
      if (userId) {
        const text = localStorage.getItem(`${userId}|${key}`);
        if (text) {
          return JSON.parse(text);
        }
      }
    }
  } catch (err) {
    console.log(`Error reading ${key} from storage: `, err);
  }
  return defaultValue;
}

export function putLocalStorageItem(key, value) {
  try {
    if (key) {
      const userId = store.getState()?.authReducer?.userData?._id;
      if (userId) {
        value = typeof value === 'function' ? value(getLocalStorageItem(key)) : value;
        localStorage.setItem(`${userId}|${key}`, JSON.stringify(value));
      }
    }
  } catch (err) {
    console.log(`Error writing ${key} to storage: `, err);
  }
  return value;
}

export function removeLocalStorageItem(key) {
  try {
    if (key) {
      const userId = store.getState()?.authReducer?.userData?._id;
      if (userId) {
        localStorage.removeItem(`${userId}|${key}`);
      }
    }
  } catch (err) {
    console.log(`Error removing ${key} from storage: `, err);
  }
}

export function updateLocalStorageItem(key, value) {
  return putLocalStorageItem(key, {...getLocalStorageItem(key), ...value});
}

// Get a list of all the local storage items for all users or the current user
export function getLocalStorageItemKeys(allUsers) {
  if (allUsers) {
    return Object.keys(localStorage);
  }
  const userId = store.getState()?.authReducer?.userData?._id;
  return Object.keys(localStorage).filter((key) => key.startsWith(userId));
}

const applicationStorage = {};

export function getApplicationStorageItem(key, defaultValue) {
  return applicationStorage[key] ?? defaultValue ?? {};
}

export function putApplicationStorageItem(key, value) {
  value = value instanceof Function ? value(applicationStorage[key]) : value;
  applicationStorage[key] = value;
  return value;
}

export function removeApplicationStorageItem(key) {
  const value = getApplicationStorageItem(key);
  delete applicationStorage[key];
  return value;
}

export function updateApplicationStorageItem(key, value) {
  applicationStorage[key] = {...applicationStorage[key], ...value};
  return applicationStorage[key];
}
