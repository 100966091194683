import React, {useState} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Button,
  Typography,
  MuiThemeProvider, useMediaQuery, useTheme, makeStyles, Checkbox, FormControlLabel,
} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {closeProofTermsModal as _closeProofTermsModal} from '../../../../actions/modals';
import green from '../../../../ui/theme/green';
import {get} from 'lodash';

const styles = (theme) => ({
  acceptButton: {margin: theme.spacing(3)},
  subTitle: {color: theme.colors.grey.main},
  header: {
    borderBottom: `1px solid ${theme.colors.grey.light}`,
    padding: theme.spacing(1.5)
  },
  darkerMuiBackdrop: {'& .MuiBackdrop-root': {backgroundColor: 'rgba(0, 0, 0, 0.7)'}},
  termsContainer: {
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.secondary.main,
    backgroundColor: theme.colors.grey.ultraLight,
    padding: theme.spacing(2),
    maxHeight: 450,
    overflowY: 'auto'
  }
});

function TermsModal() {
    const dispatch = useDispatch();

    const useStyles = makeStyles(styles);
    const theme = useTheme();
    const classes = useStyles();
    
    const openSelector = (state) => state.modalReducer.proofTermsModal.isOpen;
    const proofsSelector = (state) => state.proofReducer.proofs;

    const open = useSelector(openSelector);
    const proofs = useSelector(proofsSelector);

    const closeModal = () => dispatch(_closeProofTermsModal());
    const [isChecked, setIsChecked] = useState(false);

    const termsHtml = get(proofs, '[0].job.company.proofTerms');
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    return (
      <Dialog
        open={open}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={'sm'}
        classes={{root: classes.darkerMuiBackdrop}}
      >
        <DialogTitle disableTypography={true}>
          <Typography variant={'h4'} align='center'>Our Terms</Typography>
          <Typography variant='body2' align='center'><strong>Your proofs will be displayed after accepting these terms.</strong></Typography>
          
        </DialogTitle>
        <DialogContent dividers>
          <Grid container direction='column' justifyContent={'center'} alignItems={'stretch'} className={classes.content}>
            <Grid item xs='12'>
              <div className={classes.termsContainer} dangerouslySetInnerHTML={{__html: termsHtml}}/>
            </Grid>
            <Grid item justifyContent={'center'}>
                <Grid container direction='column' justifyContent={'center'} alignItems={'center'}>
                  <Grid item xs='12'>
                    <br/>
                  <FormControlLabel
                control={<Checkbox checked={isChecked} color='primary' onChange={(event) => setIsChecked(event.target.checked)}/>}
                label='I accept these terms'
                labelPlacement='end'
              />
                  </Grid>
                  <Grid item xs='12'><MuiThemeProvider theme={green}>
                <Button
                  variant={'contained'}
                  disabled={!isChecked}
                  color={'primary'}
                  onClick={closeModal}
                  className={classes.acceptButton}
                  size={'large'}
                >VIEW YOUR PROOFS</Button>
              </MuiThemeProvider></Grid>
                </Grid>
              
              </Grid>
          </Grid>
        </DialogContent>
    </Dialog>
    );
}

export default TermsModal;
