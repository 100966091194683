import React from 'react';
import {Row} from '../Layout';
import {HoopsPropTypes} from '../utils';
import {registerGlobalStyle} from '../../theme';

registerGlobalStyle('label.row', () => ({
  '&:not(.disabled)': {cursor: 'pointer'},
  '&.disabled .text': {opacity: .5},
  '&.nowrap>.text-caption': {textWrap: 'nowrap'}
}));

export function Label({className, disabled, noWrap, children}) {
  return (
    <Row tag={'label'} className={[className, disabled && 'disabled', noWrap && 'nowrap']}>
      {children}
    </Row>
  );
}

Label.propTypes = {
  className: HoopsPropTypes.className,
  disabled: HoopsPropTypes.bool,
  noWrap: HoopsPropTypes.bool,
  children: HoopsPropTypes.children,
};
