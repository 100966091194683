// TODO: fix eslint disable
/* eslint-disable no-duplicate-imports */

import React, {Fragment} from 'react';
import {IconButton, Menu, MenuItem, ListItemIcon, DialogContent, Grid, makeStyles} from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import EditIcon from '@material-ui/icons/Edit';
import EventIcon from '@material-ui/icons/Event';
import DeleteIcon from '@material-ui/icons/Delete';
import Link from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {editNewTask} from '../../actions/tasks';

const useStyles = makeStyles((theme) => ({
  parent: {padding: '0px'},
  container: {padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`},
  proofImage: {
    backgroundSize: 'contain',
    height: theme.spacing(75),
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  }
})
);

export const FileTableCellActions = ({
  file,
  actionOptions = {
    view: true,
    download: true,
    edit: false,
    delete: false,
    createTask: false,
    createTaskState: []
  },
  onDeleteAction = () => null,
  onEditAction = () => null
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
    handleClose();
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleCreateTask = () => {
    const state = {
      ...actionOptions.createTaskState,
      fileIds: [file._id],
      files: [file]
    };
    dispatch(editNewTask(state));
    handleClose();
  };

  return (
    <Fragment>
      <IconButton
        variant={'outlined'}
        size={'small'}
        onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {actionOptions.view ? <MenuItem onClick={handleModalOpen}>
          <ListItemIcon>
            <RemoveRedEyeIcon />
          </ListItemIcon>
          View
        </MenuItem> : null}
        {actionOptions.download ? <Link href={file.url} color={'textPrimary'} underline={'none'}>
          <MenuItem>
            <ListItemIcon>
              <CloudDownloadIcon />
            </ListItemIcon>
            Download
          </MenuItem>
        </Link> : null}

        {actionOptions.createTask ? <MenuItem onClick={handleCreateTask}>
          <ListItemIcon>
            <EventIcon />
          </ListItemIcon>
          Create Task
        </MenuItem> : null}

        {actionOptions.edit ? <MenuItem onClick={onEditAction}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          Edit
        </MenuItem> : null}

        {actionOptions.delete ? <MenuItem onClick={onDeleteAction}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          Delete
        </MenuItem> : null}
      </Menu>

      <Dialog
        open={modalOpen}
        onClose={handleModalClose}
        fullWidth={true}
        maxWidth={'md'}
        className={classes.parent}
      >
        <DialogContent>
          <Grid container className={classes.container} component={'div'}>
            <Grid item xs={12} className={classes.proofImage} style={{backgroundImage: `url(${file.url})`}} />
          </Grid>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default FileTableCellActions;
