import {FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import React, {useCallback} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {HoopsTextField} from '../../componentsOld/HoopsTextField';
import priceLogicValues from './itemLogicValues';

const useStyles = makeStyles((theme) => ({
    container: {padding: theme.spacing(3),},
    select: {
        flexGrow: 1,
        minWidth: 250,
        height: '40px',
        '& .MuiSelect-select:focus': {
            height: '16px',
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            border: 'none'
        },
    },
    input: {height: '40px',},
    inputLabel: {
        transform: 'translate(1px, -4px) scale(0.90) !important',
        position: 'relative'
    },
    margin: {marginRight: theme.spacing(1),},
    caption: {
        color: theme.colors.grey.main,
        paddingTop: theme.spacing(2)
    },
    strong: {fontWeight: 'bold'},
    error: {
        color: theme.colors.red,
        maxWidth: 'fit-content',
        paddingTop: theme.spacing(1)
    }
}));

export const ItemBuilderPriceLogic = () => {
    const classes = useStyles();
    const {watch, control, errors, setValue} = useFormContext();

    const priceElement = watch();
    const priceLogic = priceElement.priceLogic;
    const grossMarginTarget = priceElement.grossMarginTarget;
    const {grossMargin, costPriceMarkup} = priceLogicValues;

    const handlePriceLogic = useCallback((priceLogicValue) => {
        switch (priceLogicValue) {
            case costPriceMarkup:
                setValue('grossMarginTarget', null);
                setValue('priceLogic', costPriceMarkup);
                break;
            case grossMargin:
                setValue('grossMarginTarget', 0);
                setValue('priceLogic', grossMargin);
                break;
            default:
                setValue('grossMarginTarget', null);
                setValue('priceLogic', costPriceMarkup);
                break;
        }
    },[costPriceMarkup, grossMargin, setValue]);

    return (
        <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' className={classes.container}>
            <Grid item>
                <FormControl variant='outlined' className={`${classes.formControl} ${classes.margin}`}>
                    <InputLabel disableAnimation shrink className={classes.inputLabel}>Set your price logic</InputLabel>
                    <Controller
                        name='priceLogic'
                        defaultValue={grossMarginTarget ? grossMargin : costPriceMarkup}
                        render={(field) => (
                            <Select
                                className={classes.select}
                                {...field}
                                inputProps={{style: {padding: 5}}}
                                MenuProps={{style: {padding: 50}}}
                                varient='outlined'
                                onChange={(e) => handlePriceLogic(e.target.value)}
                            >
                                <MenuItem value={costPriceMarkup}>Cost Price + Markup</MenuItem>
                                <MenuItem value={grossMargin}>Set Gross Margin</MenuItem>
                            </Select>)}
                        control={control}
                    />

                </FormControl>
            </Grid>
            {
                priceLogic === grossMargin
                    ?
                    <>
                        <Grid item>
                            <FormControl className={classes.margin} variant='outlined'>
                                <InputLabel disableAnimation shrink className={classes.inputLabel}>Set margin</InputLabel>
                                <Controller
                                    name='grossMarginTarget'
                                    defaultValue=''
                                    render={(field) => (
                                        <HoopsTextField
                                            type='number'
                                            className={classes.input}
                                            InputProps={{endAdornment: <InputAdornment position={'end'}>%</InputAdornment>}}
                                            {...field}
                                        />
                                    )}
                                    control={control}
                                    rules={{max: {value: 99, message: 'Margin must be below 100%'},}}
                                />
                                {errors.grossMarginTarget && <div className={classes.error}>{errors.grossMarginTarget.message}</div>}
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <Typography variant='caption' className={classes.caption} display='block' gutterBottom>
                                This logic allows you to <span className={classes.strong}>set a global margin</span>. All items added within the item builder<br /> will <span className={classes.strong}>automatically calculate to achieve the desired margin.</span>
                            </Typography>
                        </Grid>
                    </>
                    :
                    <Grid item>
                        <Typography variant='caption' className={classes.caption} display='block' gutterBottom>
                            Using this logic, your <span className={classes.strong}>sell price</span> is calculated by adding a <br /> <span className={classes.strong}>markup</span> percentage to the <span className={classes.strong}>buy price</span> of the product.
                        </Typography>
                    </Grid>

            }
        </Grid>
    );
};

export default ItemBuilderPriceLogic;

