import * as React from 'react';
import {Grid, MenuItem} from '@material-ui/core';
import {Field} from 'redux-form';
import {renderSelectField, renderTextField} from '../shared/formHelpers/fields';

class AddressFields extends React.Component {

  render() {
    const {showLabelInput, showAddressLine2, address} = this.props;

    return (<Grid container spacing={2}>
      {showLabelInput &&
      <Grid item xs={12}>
        <Field fullWidth
               label={'Address Type'}
               name={`${address}.label`}
               type={'text'}
               component={renderSelectField}>
          <MenuItem value={'SHIPPING'}>Shipping Address</MenuItem>
          <MenuItem value={'BILLING'}>Billing Address</MenuItem>
          <MenuItem value={'OTHER'}>Other</MenuItem>
        </Field>
      </Grid>
      }

      <Grid item xs={12}>
        <Field fullWidth
               label={'Address'}
               name={`${address}.address1`}
               type={'text'}
               component={renderTextField}/>
      </Grid>
      {showAddressLine2 &&
      <Grid item xs={12}>
        <Field fullWidth
               label={'Address Line 2'}
               name={`${address}.address2`}
               type={'text'}
               component={renderTextField}/>
      </Grid>
      }
      <Grid item xs={6}>
        <Field fullWidth
               label={'City'}
               name={`${address}.city`}
               type={'text'}
               component={renderTextField}/>
      </Grid>
      <Grid item xs={6}>
        <Field fullWidth
               label={'State'}
               name={`${address}.state`}
               type={'text'}
               component={renderTextField}/>
      </Grid>
      <Grid item xs={6}>
        <Field fullWidth
               label={'Postcode'}
               name={`${address}.postcode`}
               type={'text'}
               component={renderTextField}/>
      </Grid>
      <Grid item xs={6}>
        <Field fullWidth
               label={'Country'}
               name={`${address}.country`}
               type={'text'}
               component={renderTextField}/>
      </Grid>
    </Grid>);
  }
}

export default AddressFields;
