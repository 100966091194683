import {MenuItem, Select} from '@material-ui/core';
import React from 'react';

export const ProductTypeSelect = ({
  productType = null,
  onProductTypeChange = () => null,
  isSageActive = false, 
  configuredProductId,
}) => (

    <Select
      variant='outlined'
      margin='dense'
      size='small'
      style={{borderRadius: '4px 0 0 4px', borderRight: 'none', maxHeight: 35, minWidth: 94}}
      value={configuredProductId ? 'configuredProduct' : productType}
      onChange={(e) => onProductTypeChange(e)}
    >
      <MenuItem value='hoops'>Hoops</MenuItem>
      {isSageActive && <MenuItem value='sage'>Sage</MenuItem>}
    </Select>

  );

export default ProductTypeSelect;
