import theme from '../theme';

export const actionMenu = {
    actionMenuStyles: {
        '& .MuiIcon-root': {
            margin: theme.spacing(1),
            marginRight: theme.spacing(2),
            color: theme.colors.grey.main,
            fontSize: theme.typography.h6.fontSize,
        },
        '& .MuiMenuItem-root': {
            padding: theme.spacing(1),
            paddingRight: theme.spacing(2),
            fontSize: theme.typography.body2.fontSize,
        }
    }
};
