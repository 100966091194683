// TODO: fix eslint disable
/* eslint-disable consistent-return, no-class-assign */

import {CardContent, MuiThemeProvider, Typography, withStyles} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import {Cancel as CancelIcon} from '@material-ui/icons';
import {truncate} from 'lodash';
import * as numeral from 'numeral';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {SET_FILE, SET_PROGRESS, SET_STATUS} from '../../reducers/dropzoneReducer';
import S3UploadService from '../../servicesOld/s3UploadService';
import blue from '../../ui/theme';
import green from '../../ui/theme/green';
import red from '../../ui/theme/red';

const styles = () => ({
  thumbnail: {
    height: 100,
    backgroundRepeat: 'cover'
  },
});

class FileUploadCard extends Component {
  state = {
    progress: 0,
    barTheme: blue,
    thumbnail: null,
    managedUpload: null,
    visible: true
  };

  componentDidMount() {
    const {file} = this.props;
    this.setState({
      progress: 0,
      barTheme: blue,
      thumbnail: (file.type.includes('image') ? URL.createObjectURL(file) : null)
    });
    setTimeout(this.startUpload);
  }

  startUpload = () => {
    const {multipartFileLabel, file, snackbar, setFile, s3ObjectParams, mock = false} = this.props;

    if (mock) {
      this.handleComplete({});
      return;
    }

    let s3 = new S3UploadService();
    const managedUpload = s3.upload(file, s3ObjectParams);

    if (snackbar) {
      setFile(multipartFileLabel, file);
    }

    managedUpload.on('httpUploadProgress', ({loaded}) => {
      this.handleProgressChange(loaded);
    });

    this.setState({
      ...this.state,
      managedUpload
    });

    return managedUpload.promise()
      .then((data) => {
        this.handleComplete(data);
      })
      .catch((err) => {
        console.error(s3ObjectParams);
        console.error(err);
        this.handleUploadError(err);
      });
  };

  handleProgressChange = (loaded) => {
    const {multipartFileLabel, file, snackbar, setProgress} = this.props;
    let {barTheme} = this.state;
    if (loaded >= file.size) {
      barTheme = green;
    }

    this.setState({
      ...this.state,
      barTheme,
      progress: Math.round(loaded * 100 / file.size)
    });

    if (snackbar) {
      setProgress(multipartFileLabel, Math.round(loaded * 100 / file.size));
    }
  };

  handleUploadError = (err) => {
    const {setStatus, snackbar, multipartFileLabel} = this.props;

    this.setState({barTheme: red});

    if (snackbar) {
      setStatus(multipartFileLabel, 'ERROR', err.message);
    }
  };

  handleComplete = (data) => {
    const {onComplete, file} = this.props;
    if (onComplete) {
      const fileData = {
        filename: file.path,
        key: data.Key,
        bucket: data.Bucket,
        contentType: file.type,
        contentLength: file.size,
        url: data.Location,
        uuid: file.uuid,
        file
      };
      onComplete(fileData);
    }
  };

  handleRemove = (ev, delay = 0) => {
    this.setState({
      ...this.state,
      visible: false
    });
    ev && ev.stopPropagation();

    const {multipartFileLabel, onCancel, snackbar, setProgress, setStatus} = this.props;
    const {managedUpload} = this.state;

    managedUpload.abort();
    if (onCancel) {
      setTimeout(() => onCancel(ev), delay);
    }

    if (snackbar) {
      setProgress(multipartFileLabel, 0);
      setStatus(multipartFileLabel, 'CANCELLED');
    }
  };

  render() {
    const {file, classes, onCancel, uploadMessage, snackbar} = this.props;
    const {progress, barTheme, thumbnail, visible} = this.state;

    return (
      <>
        <Fade in={visible} timeout={300}>
          <Grid item xs={(snackbar ? true : 3)}>
            <Card className={classes.card}>
              <div>
                {thumbnail &&
                  <CardMedia className={classes.thumbnail} image={thumbnail}/>
                }
                <CardContent>
                  <Typography variant={'body2'}>{truncate(file.path)}</Typography>
                  <MuiThemeProvider theme={barTheme}>
                    <Grid container alignItems={'center'}>
                      <Box flexGrow={1}>
                        <LinearProgress color={'primary'} variant='determinate' value={progress ? progress : 0}/>
                      </Box>
                      {onCancel && <Grid item>
                        <IconButton onClick={(ev) => this.handleRemove(ev, 1500)}>
                          <CancelIcon fontSize={'small'}/>
                        </IconButton>
                      </Grid>}
                    </Grid>
                  </MuiThemeProvider>
                  {uploadMessage && <Typography variant={'body2'}>{uploadMessage}</Typography> }
                  <Typography variant={'body2'}
                              color={'textSecondary'}>{numeral(file.size).format('0.0b')}
                  </Typography>
                </CardContent>
              </div>
            </Card>
          </Grid>
        </Fade>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setProgress: (key, progress) => dispatch({
      type: SET_PROGRESS,
      payload: {
        key,
        progress
      }
    }),
    setStatus: (key, status, error) => dispatch({
      type: SET_STATUS,
      payload: {
        key,
        status,
        error
      }
    }),
    setFile: (key, file) => dispatch({
      type: SET_FILE,
      payload: {
        key,
        file
      }
    }),
  };
}

FileUploadCard = withStyles(styles)(FileUploadCard);
FileUploadCard = connect(null, mapDispatchToProps)(FileUploadCard);

export default FileUploadCard;
