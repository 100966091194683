import React, {useCallback, useEffect, useMemo} from 'react';
import {DataGridPro, useGridApiRef} from '@mui/x-data-grid-pro';
import {Checkbox} from '@mui/material';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import Lottie from 'react-lottie';
import {registerGlobalStyle} from '../../theme';
import {HoopsPropTypes} from '../utils';
import {GridToolbar, GridViewColumnMenu} from './components';
import {useFilterManager} from './helpers/useFilterManager';
import noSearchResult from '../../assets/lotties/no-search-result.json';
import {BodyText} from '../Text';
import {Column} from '../Layout';

registerGlobalStyle('.power-grid', (theme) => ({
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  '.MuiDataGrid-root': {
    border: 'none',
    overflow: 'visible',
  },
  '.MuiDataGrid-main': {
    overflow: 'visible',
    '.MuiDataGrid-row:nth-child(odd)': {
      backgroundColor: theme.colors.background.almostWhite,
      '&:hover, &.Mui-hovered': {backgroundColor: theme.colors.background.almostWhiteHover}
    },
    '.MuiDataGrid-row:nth-child(even)': {
      backgroundColor: theme.colors.background.white,
      '&:hover, &.Mui-hovered': {backgroundColor: theme.colors.background.whiteHover}
    },
  },

  // Borders and shadows on pinned columns
  '.MuiDataGrid-pinnedColumns.MuiDataGrid-pinnedColumns--left': {
    boxShadow: 'none',
    '.MuiDataGrid-cell:last-child': {borderRight: `1px solid ${theme.colors.border.main}`},
  },
  '.MuiDataGrid-pinnedColumns.MuiDataGrid-pinnedColumns--right': {
    boxShadow: 'none',
    '.MuiDataGrid-cell:first-child': {borderLeft: `1px solid ${theme.colors.border.main}`},
  },
  '.MuiDataGrid-pinnedColumnHeaders, .MuiDataGrid-pinnedColumnHeaders': {boxShadow: 'none'},
  '.empty-state': {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

registerGlobalStyle('.MuiDataGrid-panel', (theme) => ({
  '.MuiDataGrid-paper': {
    background: 'transparent',
    boxShadow: 'none',
  },
  '.MuiDataGrid-panelWrapper': {
    padding: theme.spacing(4),
    borderRadius: 10,
    marginLeft: 20,
    marginTop: -21,
    zIndex: theme.zIndex.modal,
    backgroundColor: theme.colors.background.white,
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.25)',
    '&:has(.MuiDataGrid-filterForm)': {maxWidth: '80vw'},

    '.MuiDataGrid-panelWrapper': {
      padding: 0,
      borderRadius: 0,
      margin: 0,
      zIndex: 'unset',
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },

  // Filters styles
  '.MuiDataGrid-filterForm': {
    '.MuiDataGrid-filterFormColumnInput': {width: 180},
    '.MuiDataGrid-filterFormOperatorInput': {width: 120},
    '.MuiDataGrid-filterFormValueInput': {
      flex: 1,
      minWidth: 190,
      width: 'unset',
    },
  }
}));

export const PowerGridComponents = {
  BaseSwitch: Checkbox,
  ColumnMenu: GridViewColumnMenu,
  ColumnMenuIcon: MoreHorizIcon,
  ColumnUnsortedIcon: UnfoldMoreIcon,
  ColumnResizeIcon: () => <div/>,
  NoRowsOverlay: EmptyState,
  Toolbar: GridToolbar,
};

const localeText = {
  toolbarColumns: 'Show/Hide Columns',
  toolbarDensity: 'Row Height',
  toolbarExportCSV: 'Export',
};

const rowsPerPageOptions = [10, 25, 50, 100];

const experimentalFeatures = {newEditingApi: true};

export function PowerGrid(
  {
    columnState,
    components,
    density,
    filters,
    loading,
    page,
    pageSize,
    rows,
    rowsTotal,
    search,
    sort,
    viewChanged,
    viewReadOnly,
    onChangeDensity,
    onChangeFilters,
    onChangePage,
    onChangePageSize,
    onChangeSearch,
    onChangeSort,
    onSaveView,
  }
) {
  const apiRef = useGridApiRef();
  const [displayedFilters, handleFilterModelChange] = useFilterManager(columnState.columns, filters, onChangeFilters);

  useEffect(() => apiRef.current.subscribeEvent('columnHeaderDragEnd', () => {
    columnState.setColumnOrder?.(apiRef.current.getAllColumns().map(({field}) => field));
  }), [apiRef, columnState, columnState.setColumnOrder]);

  const handleChangeColumnWidth = useCallback(({colDef, width}) => colDef.setWidth(width), []);

  const handleChangeDensity = useCallback((state) => {
    if (state.density?.value && state.density.value !== density) {
      onChangeDensity(state.density.value);
    }
  }, [density, onChangeDensity]);

  const handleChangeHiddenColumns = useCallback((model) => {
    const hidden = Object.entries(model).filter(([, vis]) => !vis).reduce((acc, [col]) => [...acc, col], []);
    columnState.setHiddenColumns(hidden);
  }, [columnState]);

  const hiddenColumns = useMemo(() =>
    columnState.hiddenColumns?.reduce((acc, col) => ({...acc, [col]: false}), {}), [columnState.hiddenColumns]);

  const handleChangeSort = useCallback((model) => {
    if (model.length > 0) {
      onChangeSort({[model[0].field]: model[0].sort === 'asc' ? 1 : -1});
    } else {
      onChangeSort({});
    }
  }, [onChangeSort]);

  const sortModel = useMemo(() =>
    Object.keys(sort).map((key) => ({field: key, sort: sort[key] === 1 ? 'asc' : 'desc'})), [sort]);

  return (
    <div className={'power-grid'}>
      <DataGridPro
        apiRef={apiRef}
        experimentalFeatures={experimentalFeatures}
        disableVirtualization={true}
        loading={loading}

        columns={columnState.columns}
        columnVisibilityModel={hiddenColumns}
        onColumnVisibilityModelChange={handleChangeHiddenColumns}
        onColumnWidthChange={handleChangeColumnWidth}
        onPinnedColumnsChange={columnState.setPinnedColumns}
        pinnedColumns={columnState.pinnedColumns}

        rows={rows}

        pagination
        paginationMode={'server'}
        page={page}
        pageSize={pageSize}
        rowCount={rowsTotal}
        onPageChange={loading ? undefined : onChangePage}   // If there are no rows, grid sets the page to 0
        onPageSizeChange={onChangePageSize}
        rowBuffer={100}
        rowsPerPageOptions={rowsPerPageOptions}

        sortingMode='server'
        sortModel={sortModel}
        onSortModelChange={handleChangeSort}

        filterModel={displayedFilters}
        onFilterModelChange={handleFilterModelChange}
        filterMode={'server'}

        density={density ?? 'standard'}
        onStateChange={handleChangeDensity}

        components={components ?? PowerGridComponents}
        componentsProps={useMemo(() => ({
          toolbar: {
            search,
            viewChanged,
            viewReadOnly,
            onSaveView,
            onChangeSearch,
          },
        }), [onSaveView, onChangeSearch, search, viewChanged, viewReadOnly])}
        localeText={localeText}
      />
    </div>
  );
}

PowerGrid.propTypes = {
  columnState: HoopsPropTypes.object,
  components: HoopsPropTypes.object,
  density: HoopsPropTypes.string,
  filters: HoopsPropTypes.arrayOfAny,
  loading: HoopsPropTypes.bool,
  page: HoopsPropTypes.number,
  pageSize: HoopsPropTypes.number,
  rows: HoopsPropTypes.arrayOfObject,
  rowsTotal: HoopsPropTypes.number,
  search: HoopsPropTypes.string,
  sort: HoopsPropTypes.object,
  viewChanged: HoopsPropTypes.bool,
  viewReadOnly: HoopsPropTypes.bool,
  onChangeDensity: HoopsPropTypes.func,
  onChangeFilters: HoopsPropTypes.func,
  onChangePage: HoopsPropTypes.func,
  onChangePageSize: HoopsPropTypes.func,
  onChangeSearch: HoopsPropTypes.func,
  onChangeSort: HoopsPropTypes.func,
  onSaveView: HoopsPropTypes.func,
};

function EmptyState() {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: noSearchResult,
    rendererSettings: {preserveAspectRatio: 'xMidYMid slice'}
  };
  return (
    <Column className={'empty-state'}>
      <BodyText>
        No results found
      </BodyText>
      <Lottie options={defaultOptions} height={200} width={200}/>
    </Column>
  );
}
