// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-shadow */

import {MuiThemeProvider} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import React, {useCallback, useState} from 'react';
import {InvoiceService} from '../../servicesOld/InvoiceService';
import red from '../../ui/theme/red';

function TooltipContent({invoice}) {
  return (
    <>
      <div style={{width: 300, padding: 3}}>
        An error occurred while syncing the invoice. Click to retry.
        {invoice.errorMessage &&
        <>
          <hr/>
          <div>{invoice.errorMessage}</div>
        </>}
      </div>
    </>
  );
}

export default function InvoiceErrorChip({invoice, setInvoice}) {
  const [ loading, setLoading ] = useState(false);

  const handleClick = useCallback(() => {
    setLoading(true);

    InvoiceService.getInvoice(invoice._id)
      .then((invoiceData) => {
        const invoice = new InvoiceService(invoiceData);
        return invoice.save();
      })
      .then((res) => new Promise((resolve) => {
          setTimeout(() => {
            resolve(InvoiceService.getInvoice(invoice._id));
          }, 3000);
        }))
      .then((updatedInvoice) => {
        setInvoice(updatedInvoice);
      })
      .finally(() => setLoading(false));
  }, [ invoice, setInvoice ]);

  if (loading) {
    return (
      <>
        <Grid container alignItems={'center'} justifyContent={'flex-end'} spacing={1}>
          <Grid item>
            <CircularProgress size={18} />
          </Grid>
          <Grid item>
            <Chip size={'small'} label={'Loading...'} />
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <>
      <Grid container alignItems={'center'} justifyContent={'flex-end'}>
        <Grid item>
          <MuiThemeProvider theme={red}>
            <Tooltip title={<TooltipContent invoice={invoice} />}>
              <Chip size={'small'} label={'Error Syncing'} color={'primary'} onClick={handleClick} />
            </Tooltip>
          </MuiThemeProvider>
        </Grid>
      </Grid>
    </>
  );
}
