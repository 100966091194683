// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React, {Component} from 'react';
import {
  Grid,
  Typography,
  Paper,
  withStyles, createStyles, makeStyles
} from '@material-ui/core';
import {get} from 'lodash';
import {connect} from 'react-redux';
import {Skeleton} from '@material-ui/lab';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import {openPOShipTo as _openShipTo} from '../../../actions/modals';

const useStyles = makeStyles(() => createStyles({
  flexContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
  }
}));

function VendorInformationCard({classes, heading, subHeading, information, loading, editComponent}) {
  const vendorStyles = useStyles();
  if (loading) {
    return <Skeleton variant='rect' width='100%' height={200} />;
  }
  return (
    <Paper className={classes.paperPadding}>
      <Box className={vendorStyles.flexContainer}>
        <Typography className={classes.vendorHeading} variant='h5' gutterBottom>{heading}</Typography>
        {editComponent}
      </Box>
      <Typography variant='h6'>{subHeading}</Typography>
      {information.attention && <Typography variant={'body2'}>Attn: {information.attention}</Typography> }

      <Typography>{information.phone}</Typography>
      <Typography>{information.email}</Typography>
      <Typography>{information.address}</Typography>
    </Paper>
  );
}

const styles = (theme) => ({
  paperPadding: {
    padding: theme.spacing(3),
    height: '100%'
  },
  vendorHeading: {color: theme.palette.primary.main}
});

class VendorInformation extends Component {
  handleEditShipTo = () => {
    const {purchaseOrder: po, openShipTo} = this.props;
    openShipTo(po.shipTo);
  };

  render() {
    const {loading, classes, purchaseOrder, company} = this.props;
    const Vendor = get(purchaseOrder, 'vendor');
    return (
      <Grid container spacing={5}>
        <Grid item xs={4}>
          <VendorInformationCard
            classes={classes}
            loading={loading}
            heading='Vendor'
            subHeading={get(Vendor, 'name')}
            information={{
              phone: get(Vendor, 'contacts[0].phone', '-'),
              email: get(Vendor, 'email', '-'),
              address: get(Vendor, 'addresses[0].formattedAddress', '-'),
            }}
          />
        </Grid>
        <Grid item xs={4}>
        <VendorInformationCard
            classes={classes}
            loading={loading}
            heading='Bill To'
            subHeading={company?.companyTradingEntities?.[0]?.name ?? '-'}
            information={{
              phone: company?.companyTradingEntities?.[0]?.phone ?? '',
              email: company?.companyTradingEntities?.[0]?.email ?? '',
              address: company?.billingAddress?.formattedAddress ?? '',
            }}
          />
        </Grid>
        <Grid item xs={4}>
        <VendorInformationCard
          classes={classes}
          loading={loading}
          heading='Ship To'
          editComponent={<Button
            size={'small'}
            variant={'outlined'}
            onClick={this.handleEditShipTo}
            color={'primary'}>
            Edit
          </Button>}
          subHeading={get(purchaseOrder, 'shipTo.name', '-')}
          information={{
            attention: get(purchaseOrder, 'shipTo.attention'),
            phone: get(purchaseOrder, 'shipTo.phone', '-'),
            email: get(purchaseOrder, 'shipTo.email', '-'),
            address: get(purchaseOrder, 'shipTo.address.formattedAddress', '-'),
            }}
          />
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {company: state.companyReducer.company};
}

function mapDispatchToProps(dispatch) {
  return {openShipTo: (shipTo) => dispatch(_openShipTo(shipTo))};
}

VendorInformation = connect(mapStateToProps, mapDispatchToProps)(VendorInformation);
VendorInformation = withStyles(styles)(VendorInformation);

export default VendorInformation;
