import {
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle, Grid,
  Icon, Typography
} from '@material-ui/core';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {deleteFile, updateFile} from '../../actions/files';
import HoopsUpload from '../../componentsOld/shared/HoopsUpload';
import {FileUploadEdit} from './FileUploadEdit';

export const FileUploadModal = ({openModal, entities = [], customerId = null, onCloseModal = () => null, accept = null}) => {
  const [files, setFiles] = useState([]);
  const dispatch = useDispatch();

  const handleFileUploaded = (file) => {
    setFiles(
      (prev) => ([...prev, file])
    );
  };

  const handleFileUpdate = (file) => {
    dispatch(updateFile(file._id, file));
  };

  const handleFileRemove = (file) => {
    dispatch(deleteFile(file._id));
    setFiles((prev) => prev.filter((prevFile) => prevFile._id !== file._id));
  };

  const handleCloseModal = () => {
    onCloseModal(files);
    setFiles([]);
  };

  const handleSubmit = () => {
    onCloseModal();
    setFiles([]);
    // write descriptions
  };

  return (
    <Dialog
      open={openModal}
      maxWidth={'lg'}
      fullWidth={true}
      onClose={handleCloseModal}
    >
      <DialogTitle onClick={handleCloseModal}>
        <Typography variant='h5'>Upload File</Typography>
      </DialogTitle>
      <DialogContent>
        <HoopsUpload accept={accept} onUploadComplete={handleFileUploaded} meta={{entities, customerId, isWorkable: true}} />
        {files.map((file) => <FileUploadEdit
          key={file._id}
          file={file}
          onFileUpdate={handleFileUpdate}
          onFileRemove={handleFileRemove}
        />
        )}
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={'space-between'}>
          <Grid item>
            <Button onClick={handleCloseModal}>
              <Icon>close</Icon>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant={'contained'}
                  color={'primary'}
                  type='submit'
                  onClick={handleSubmit}
                >
                  done
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default FileUploadModal;
