// TODO: fix eslint disable
/* eslint-disable consistent-return */

import {Card, CardContent, Grid} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as firebase from 'firebase/app';
import React, {useContext, useLayoutEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {FirebaseContext} from '../FirebaseProvider/FirebaseProvider';
import CommentInput from './CommentInput';
import CommentsList from './CommentsList';

const CommentsLoading = () => (
        <Grid item container align-items='center'>
            <Grid item xs={true} align='center'>
                <CircularProgress />
            </Grid>
        </Grid>
    );

const CommentsView = ({users, comments, handleSave, cardElevation = 1}) => (
        <React.Fragment>
            <Card style={{overflow: 'visible'}} elevation={cardElevation}>
                <CardContent>
                    <CommentInput users={users} onSave={handleSave} />
                </CardContent>
            </Card>
            <div>&nbsp;</div>
            {comments.length ? <Card elevation={cardElevation}>
                <CardContent>
                    <CommentsList users={users} comments={comments} readOnly={true} />
                </CardContent>
            </Card> : null}

        </React.Fragment>
    );

const Comments = ({channelId, meta, cardElevation = 1}) => {
    const usersSelector = (state) => state.authReducer.users.map((user) => ({email: user.username, id: user._id.toString(), display: user.fullName}));
    const meSelector = (state) => state.authReducer.userData;
    const users = useSelector(usersSelector);
    const me = useSelector(meSelector);
    const tenantId = me.company ? me.company._id : null;

    const commentsPath = `tenants/${tenantId}/channels/${channelId}/comments`;
    const {authenticated} = useContext(FirebaseContext);

    const [loading, setLoading] = useState(true);
    const [comments, setComments] = useState([]);

    const handleSave = async (content) => {
        if (typeof content !== 'string' || content === '') {
            return; // no valid for saving
        }

        const newDoc = firebase.firestore().collection(commentsPath).doc();
        const newDocRef = await newDoc.get();
        const $id = newDocRef.id;

        const comment = {
            $id,
            content,
            meta,
            createdAt: new Date().getTime(),
            createdById: me._id,
            createdByName: me.fullName,
            updatedAt: new Date().getTime(),
            updatedById: me._id,
            updatedByName: me.fullName,
            users
        };

        newDoc.set(JSON.parse(JSON.stringify(comment)));
    };

    useLayoutEffect(
        () => {
            if (authenticated) {
                const unsubscribeComments = firebase.firestore()
                    .collection(commentsPath)
                    .orderBy('createdAt', 'desc')
                    .onSnapshot(
                        (collection) => {
                            setLoading(false);
                            setComments(
                                collection.docs.map(
                                    (doc) => doc.data()
                                )
                            );
                        }
                    );

                return () => unsubscribeComments();
            }
        },
        [commentsPath, authenticated]
    );

    return (
        <Grid item container>
            <Grid item xs={true}>
                {loading ? <CommentsLoading /> : <CommentsView users={users} comments={comments} handleSave={handleSave} cardElevation={cardElevation} />}
            </Grid>
        </Grid>
    );
};

export default Comments;
