// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import React, {Component} from 'react';
import {Grid, Typography} from '@material-ui/core';
import {stripHTML} from '../../../../utils/stripHtml';

class ProofsTableActionCell extends Component {
  render() {
    const {products} = this.props;
    return (
      <Grid container direction='column'>
        {products.map((product, index) => (
          <Typography variant='caption' key={product._id}>{stripHTML(product.description)}</Typography>
        ))}
      </Grid>
    );
  }
}

export default ProofsTableActionCell;
