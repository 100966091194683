import React from 'react';
import {Column} from '../../componentsLib/Layout';
import {ImageThumbnail} from '../../componentsLib/Basic';
import {registerGlobalStyle} from '../../theme';
import {BodyText} from '../../componentsLib/Text';
import {HoopsPropTypes} from '../../componentsLib';

registerGlobalStyle('.product-preview', () => ({overflow: 'hidden',}));

export function ProductPreview({className, imageUrl, product, ...props}) {
  return (
    <Column className={[className, 'product-preview']}>
      <ImageThumbnail imageUrl={imageUrl} {...props}/>
      {product &&
        <>
          <BodyText><b>{product.code}</b> | {product.vendor?.name}</BodyText>
          <BodyText>{product.title}</BodyText>
        </>
      }
    </Column>
  );
}

ProductPreview.propTypes = {
  className: HoopsPropTypes.className,
  imageUrl: HoopsPropTypes.string,
  product: HoopsPropTypes.object,
  onClick: HoopsPropTypes.func,
};
