// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import {Button, Grid, IconButton, Link, makeStyles, Typography} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import copy from 'copy-to-clipboard';
import {get} from 'lodash';
import React from 'react';
import {useDispatch} from 'react-redux';
import {integrations} from '../../ui/theme/integrations';
import {createIntegration, deleteIntegration} from './IntegrationActions';

const useStyles = makeStyles((theme) => (
    {
        ...integrations,
        iconStyle: {
            fontSize: '16px',
            color: theme.palette.secondary.main,
        },
        iconButton: {
            padding: '0 0 2px 0',
            marginLeft: '11px'
        },
        apiKey: {fontWeight: 500}
    }
));

export const ZapierContent = ({config}) => {
    const classes = useStyles();
    return (
        <Grid
            container
            direction='column'
            justifyContent='space-between'
            alignItems='center'

        >
            <Grid item xs={12}>
                <Typography variant={'body2'} className={classes.greyText} align='center'>
                    Connect Hoops with <br />
                    3000+ Apps via Zapier
                </Typography>
                <Link underline={'none'} target='_blank' href='http://help.hoopscrm.com/en/articles/5343363-zapier-integration'>
                    <Typography variant='caption' align='center'> Learn how to set up Zapier</Typography>
                </Link>
            </Grid>
        </Grid>
    );
};

export const ZapierButton = ({config = {}}) => {
    const classes = useStyles();
    const dispatch = useDispatch(); // to be used to generate API Key
    const apiKey = get(config, 'apiKey');

    const handleGenerateApiKey = () => {
        dispatch(createIntegration({type: 'zapier'}));
    };

    const handleDeleteApiKey = () => {
        dispatch(deleteIntegration(config._id));
    };

    return (
        <>
            {apiKey
                ?
                <Grid container direction='row' justifyContent='center' alignItems='flex-start'>
                    <Grid item xs={12}><Typography variant={'body2'} className={classes.greyText} align='center'>API Key</Typography></Grid>
                    <Grid item><Typography variant={'body2'} align='center' className={classes.apiKey}>{apiKey}</Typography></Grid>
                    <Grid item><IconButton onClick={() => copy(apiKey)} className={classes.iconButton}><FileCopyIcon className={classes.iconStyle} /></IconButton></Grid>
                    <Grid item><IconButton onClick={() => handleDeleteApiKey()} className={classes.iconButton}><DeleteIcon className={classes.iconStyle} /></IconButton></Grid>
                </Grid>
                : <Button variant='contained' color='primary' onClick={() => handleGenerateApiKey()}>Generate API Key</Button>
            }
        </>
    );
};
