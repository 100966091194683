import {useCallback, useEffect, useMemo, useState} from 'react';
import {useGetSalesDoc} from '../../../hooks/api';
import {SalesDoc} from '../Models/SalesDoc';

export function useSalesDocViewState(salesDocNumber) {
  const {data: {salesDoc: apiSalesDoc}, isLoading: salesDocLoading, refetch: refetchSalesDoc} = useGetSalesDoc(salesDocNumber);

  const [salesDoc, _setSalesDoc] = useState();

  // Whenever the SalesDoc is set, it will be bound to the salesDoc state
  const setSalesDoc = useCallback((newSalesDoc) => {
    _setSalesDoc(newSalesDoc.setNotify(setSalesDoc));
  }, []);

  // This effect handles the initial load, loading a SalesDoc from the server
  useEffect(() => {
    if (apiSalesDoc) {
      const newSalesDoc = SalesDoc.fromApi(apiSalesDoc);
      _setSalesDoc(newSalesDoc.setNotify(setSalesDoc));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiSalesDoc]);

  return useMemo(() => ({
    loading: salesDocLoading,
    salesDoc,
    salesDocNumber,
    refetchSalesDoc,
  }), [salesDocLoading, salesDoc, salesDocNumber, refetchSalesDoc]);
}
