// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-shadow */

import {Grid, InputAdornment, makeStyles, Popper, Tooltip, Typography} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import {Autocomplete} from '@material-ui/lab';
import {get} from 'lodash';
import React, {useContext, useEffect, useState} from 'react';
import {Controller, useFormContext, useWatch} from 'react-hook-form';
import {HoopsTextField} from '../../../componentsOld/HoopsTextField';
import {decimalPlaces, toFloat} from '../../../componentsOld/shared/numberUtils';
import {DecorationService} from '../../../servicesOld/DecorationService';
import {itemBuilder} from '../../../ui/theme/itemBuilder';
import {ItemBuilderContext} from '../ItemBuilderContext';

const useStyles = makeStyles((theme) => ({...itemBuilder}));

const BuyPriceField = ({
    index = null,
    field,
    defaultValue = '',
    name = '',
    currencySymbol,
}) => {

    const classes = useStyles();
    const {watch, control, setValue} = useFormContext();
    const {onPriceChanged} = useContext(ItemBuilderContext);

    const formPriceElement = useWatch({});
    const formDecorationCosts = formPriceElement && formPriceElement.decorationCosts && formPriceElement.decorationCosts[index];
    const quantity = formDecorationCosts && formDecorationCosts.quantity;
    const markup = formDecorationCosts && formDecorationCosts.markup;
    const decoration = formDecorationCosts && formDecorationCosts.decoration;
    const decorationType = formDecorationCosts && formDecorationCosts.decorationType;
    const priceBreaks = get(decoration, 'priceBreaks', []);
    const quantityOverride = formDecorationCosts && toFloat(formDecorationCosts.priceBreakQuantityOverride);
    const buyPrice = formDecorationCosts ? formDecorationCosts.buyPrice : 0;

    const [suggestedPriceBreak, setSuggestedPriceBreak] = useState(false);

    const handlePriceBreak = (inputValue) => {
        const decoService = new DecorationService(decoration);
        setSuggestedPriceBreak(
            (prevVal) => {
                let newVal = quantity && decoService.getPrice(quantityOverride > 0 ? quantityOverride : quantity);
                if ((toFloat(buyPrice) === toFloat(prevVal)) || (!defaultValue && prevVal === undefined)) {
                    setValue(name, decimalPlaces(newVal, 2));
                }
                if (inputValue === '') {
                    setValue(name, decimalPlaces(newVal, 2));
                }
                return (decimalPlaces(newVal, 2));
            }
        );
    };

    const handleDecimals = (e) => {
        setValue(name, decimalPlaces(e.target.value, 2));
    };

    useEffect(() => {
        if (decorationType !== 'hoops' && decorationType !== 'product') {return;}
        if (!decoration) {return;}
        handlePriceBreak();
      // TODO: FIX HOOK DEPENDENCIES
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quantity, decoration, decorationType, markup, quantityOverride]);

    return (
        <>
            {(decorationType === 'hoops' || decorationType === 'product')
                ? <>
                    <Autocomplete
                        onInputChange={(e, val, reason) => {
                            if (reason === 'reset' && !val) {
                                return;
                            }
                            setValue(name, val.toString());
                            onPriceChanged();
                        }}
                        inputValue={buyPrice ? buyPrice.toString() : ''}
                        freeSolo
                        options={priceBreaks}
                        getOptionLabel={(option) => decimalPlaces(option.price.toString(), 2)}
                        getOptionSelected={(value, option) => (value.price.toString() === option.price.toString())}
                        renderInput={(params) =>
                            <HoopsTextField
                                type='number'
                                responsive
                                className={quantityOverride > 0 && classes.warningCell}
                                {...params}
                                value={buyPrice ? buyPrice.toString() : ''}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: quantityOverride > 0
                                        ? <InputAdornment position='end' className={classes.warningAdornment}>
                                            <Tooltip arrow placement='top' classes={{tooltip: classes.tooltip}} title={<>This decoration quantity was overridden to a total quantity of <b>{quantityOverride}</b>.</>}>
                                                <ErrorIcon fontSize='small' />
                                            </Tooltip>
                                        </InputAdornment>
                                        : false,
                                    className: toFloat(suggestedPriceBreak) !== toFloat(buyPrice) ? classes.boldField : null,
                                    startAdornment: <InputAdornment position='start' className={classes.customAdornment}>{currencySymbol}</InputAdornment>,
                                    onBlur: (e) => handleDecimals(e)
                                }}

                            />
                        }
                        renderOption={(option) => (
                            <>
                                <Grid container direction={'column'}>
                                    <Typography>{currencySymbol}{decimalPlaces(option.price, 2)}</Typography>
                                    <Typography variant={'body2'} color={'textSecondary'}>{decimalPlaces(option.quantity, 2)} or more</Typography>
                                </Grid>
                            </>
                        )}
                        className={classes.disableAutocompleteMargin}
                        PopperComponent={({children, ...params}) => (
                            <Popper
                                {...params}
                                placement={'bottom-start'}
                                style={{width: 'auto'}}
                            >
                                {children}
                            </Popper>
                        )}

                    />
                    <Controller
                        name={name}
                        defaultValue={field.buyPrice}
                        render={(field) => (
                            <input
                                type='hidden'
                                {...field}
                            />)}
                        control={control}
                    />
                </>
                : <Controller
                    name={name}
                    defaultValue={defaultValue}
                    render={(field) => (
                        <HoopsTextField
                            type='number'
                            responsive
                            className={quantityOverride && classes.warningCell}
                            {...field}
                            onChange={(e) => {onPriceChanged(); field.onChange(e);}}
                            InputProps={{
                                endAdornment: quantityOverride ? <InputAdornment position='end' className={classes.warningAdornment}><ErrorIcon fontSize='small' /></InputAdornment> : false,
                                startAdornment: <InputAdornment position={'start'}>{currencySymbol}</InputAdornment>
                            }}
                        />
                    )}
                    control={control}
                />
            }
        </>
    );
};

export default BuyPriceField;
