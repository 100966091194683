import {
  Button,
  Grid,
  MuiThemeProvider
} from '@material-ui/core';
import React, {useEffect} from 'react';
import {useHistory} from 'react-router';
import {EntityListHero} from '../../componentsOld/EntityList/EntityListHero';
import green from '../../ui/theme/green';
import TaxManyTable from './TaxManyTable';

function TaxManyViewPage() {
  const history = useHistory();

  const openCreateTax = () => {
    history.push('/settings/taxes/new');
  };

  useEffect(() => {
    document.title = 'Taxes | Hoops';
  });

  return (
    <Grid container>
      <EntityListHero
        title='Taxes'
        subtitle='Manage your taxes for sale and purchases'
      >
        <MuiThemeProvider theme={green}>
          <Button
            data-intercom-target={'tax-create'}
            onClick={openCreateTax}
            color={'primary'}
            variant={'contained'}>New Tax</Button>
        </MuiThemeProvider>
      </EntityListHero>
      <Grid container
        justifyContent={'space-between'}
        alignItems={'center'}
        spacing={3}
      >
        <Grid item xs={12} data-intercom-target={'tax-table'}>
          <TaxManyTable openCreateTax={openCreateTax} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TaxManyViewPage;
