import React, {useCallback, useState} from 'react';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import {ArrowDropDown} from '@material-ui/icons';
import Popper from '@material-ui/core/Popper';
import {ClickAwayListener} from '@material-ui/core';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import RemoveProductsConfirmDialog from './RemoveProductsConfirmDialog';
import RemoveDecorationsConfirmDialog from './RemoveDecorationsConfirmDialog';
import {useDispatch, useSelector} from 'react-redux';
import {deleteVendorProducts, deleteVendorDecorations} from '../../actions/action-types';

function VendorOptions({handleEditClick}) {
  const dispatch = useDispatch();
  const anchorRef = React.useRef(null);
  const [open, setOpen] = useState(false);
  const [confirmRemoveProductsOpen, setConfirmRemoveProductsOpen] = useState(false);
  const [confirmRemoveDecorationsOpen, setConfirmRemoveDecorationsOpen] = useState(false);

  const vendorSelector = (state) => state.vendorReducer.vendor;
  const vendor = useSelector(vendorSelector);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleRemoveAllProducts = useCallback(() => {
    setConfirmRemoveProductsOpen(true);
  }, []);

  const handleRemoveAllDecorations = useCallback(() => {
    setConfirmRemoveDecorationsOpen(true);
  }, []);

  const handleCancel = useCallback(() => {
    setConfirmRemoveProductsOpen(false);
    setConfirmRemoveDecorationsOpen(false);
  }, []);

  const handleRemoveProductsOk = useCallback(() => {
    dispatch(deleteVendorProducts(vendor._id));
    setConfirmRemoveProductsOpen(false);
  }, [dispatch, vendor]);

  const handleRemoveDecorationsOk = useCallback(() => {
    dispatch(deleteVendorDecorations(vendor._id));
    setConfirmRemoveDecorationsOpen(false);
  }, [dispatch, vendor]);

  return (
    <>
      <ButtonGroup variant={'outlined'} ref={anchorRef} color={'primary'}>
        <Button color={'primary'} variant={'outlined'} onClick={handleEditClick}>Edit</Button>
        <Button
          color='primary'
          size='small'
          onClick={handleOpen}
        >
          <ArrowDropDown />
        </Button>
      </ButtonGroup>

      <Popper open={open} anchorEl={anchorRef.current}>
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList>
              <MenuItem onClick={handleRemoveAllProducts}>Remove All Products</MenuItem>
              <MenuItem onClick={handleRemoveAllDecorations}>Remove All Decorations</MenuItem>
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>

      <RemoveProductsConfirmDialog open={confirmRemoveProductsOpen} onOk={handleRemoveProductsOk} onCancel={handleCancel} />
      <RemoveDecorationsConfirmDialog open={confirmRemoveDecorationsOpen} onOk={handleRemoveDecorationsOk} onCancel={handleCancel} />
    </>
  );
}

export default VendorOptions;
