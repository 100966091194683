import React, {useMemo} from 'react';
import {Select, SelectItem} from './Select';
import {UserAvatar} from '../Basic';
import {fullName} from '../../utils/fullName';
import {BodyText} from '../Text';
import {registerGlobalStyle} from '../../theme';
import {initials} from '../../utils';
import {HoopsPropTypes} from '../utils';

registerGlobalStyle('.user-select', () => ({'.user-avatar': {display: 'none'},}));

registerGlobalStyle('.user-select-item', (theme) => ({
  display: 'flex',
  alignItems: 'center',
  columnGap: theme.spacing(2),
}));

export function UserSelect({initialOpen, placement, users: _users, value, onChange, onClose}) {
  const users = useMemo(() => _users.filter((user) => user.status !== 'INACTIVE'), [_users]);

  return (
    <Select className={'user-select'} initialOpen={initialOpen} placement={placement} value={value} onChange={onChange} onClose={onClose}>
      {users?.map((user) => {
        const name = fullName(user);
        return (
          <SelectItem className={'user-select-item'} key={user._id ?? user.value ?? name} value={user._id ?? user.value ?? name}>
            <UserAvatar name={name} initials={user.initials ?? initials(name)}/>
            <BodyText text={name}/>
          </SelectItem>
        );
      })}
    </Select>
  );
}

UserSelect.propTypes = {
  initialOpen: HoopsPropTypes.bool,
  placement: HoopsPropTypes.string,
  users: HoopsPropTypes.arrayOf(HoopsPropTypes.object),
  value: HoopsPropTypes.string,
  onChange: HoopsPropTypes.func,
  onClose: HoopsPropTypes.func,
};
