import {Card, CardActionArea, CardContent, Grid, makeStyles, Radio, Typography} from '@material-ui/core';
import React, {useEffect} from 'react';
import {useFormContext} from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
    card: {
        width: 281,
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        '& .MuiCardContent-root': {padding: 0}
    },
    cardSelected: {'& .MuiCardContent-root': {backgroundColor: theme.palette.primary.main}},
    radioBtn: {
        height: 42,
        '&$checked': {color: theme.colors.white,}
    },
    checked: {},
    titleContainer: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingBottom: theme.spacing(1),
    },
    descriptionContainer: {
        paddingBottom: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        color: theme.palette.text.primary
    },
    selected: {color: theme.colors.white,}
}));

const AutomationRadioCard = ({
    title = 'No title',
    value = 'blank',
    description = 'This component needs a description',
    alwaysSelected = false
}) => {
    const classes = useStyles();
    const {watch, reset,  setValue, getValues} = useFormContext();
    const isSelected = watch('trigger') === value;

    const handleCardClick = () => {
        const {entityType} = getValues();
        reset({entityType,trigger: value, recipients: [],action: '', updateFieldPath: '', updateFieldValue: '' ,statusType: '', status: []});
        setValue('trigger', value);
    };
    // it force to be always selected
    //removed when implement other triggers
    const values = getValues();
    useEffect(() => {
        if (alwaysSelected){
            setValue('trigger', value);
        }
    },[alwaysSelected, values.trigger, setValue, value]);
    return (
        <Card className={`${classes.card} ${isSelected && classes.cardSelected}`}>
            <CardActionArea onClick={handleCardClick}>
                <CardContent>
                    <Grid container justifyContent='space-between'>
                        <Grid item xs={10} className={`${classes.titleContainer} ${isSelected && classes.selected}`}>
                            <Typography variant='h6' style={{fontWeight: 400}}>{title}</Typography>
                        </Grid>
                        <Grid item xs={2} className={classes.radioContainer} container justifyContent='flex-end'>
                            <Radio
                                value={value}
                                checked={isSelected}
                                classes={{root: classes.radioBtn, checked: classes.checked}}
                            />
                        </Grid>
                        <Grid item xs={12} className={`${classes.descriptionContainer} ${isSelected && classes.selected}`}>
                            <Typography variant='body2' component='p'>{description}</Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default AutomationRadioCard;
