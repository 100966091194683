import {TableRow} from '@material-ui/core';
import {get} from 'lodash';
import React from 'react';
import {VARIANT_STATUSES} from '../../../constants/status';
import ChipSelect from '../../jobs/ChipSelect';
import {HoopsTableCell, HoopsTableCellCheckbox} from '../HoopsTable';

export const JobItemTableVariantRow = ({
  variant,
  onChange = () => null,
  mode = 'checklist',
  checked = false,
  disabled = false
}) => {

  const handleChange = (event) => {
    onChange(event.target.checked);
  };

  return (
    <>
      <TableRow key={variant._id}>
        <HoopsTableCellCheckbox onChange={handleChange} mode={mode} checked={checked} disabled={disabled} />
        <HoopsTableCell>{variant.productCode}</HoopsTableCell>
        <HoopsTableCell>{variant.productName}</HoopsTableCell>
        <HoopsTableCell>{get(variant, 'vendor.name')}</HoopsTableCell>
        <HoopsTableCell>{variant.colour}</HoopsTableCell>
        <HoopsTableCell>{variant.size}</HoopsTableCell>
        <HoopsTableCell>{variant.quantity}</HoopsTableCell>
        <HoopsTableCell>
          <ChipSelect activeOption={variant.status}
            disabled
            size='small'
            options={VARIANT_STATUSES}
            fallbackOption={VARIANT_STATUSES[0]}
          />
        </HoopsTableCell>
      </TableRow>
    </>
  );
};

export default JobItemTableVariantRow;
