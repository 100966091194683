// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-class-assign */

import React, {Component, Fragment} from 'react';
import {Icon, IconButton, ListItemIcon, Menu, MenuItem} from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import {connect} from 'react-redux';
import {deleteProduct as _deleteProduct} from '../../actions/catalog';
import {setModalContext as _setModalContext, setModalOpen,} from '../../actions/modals';

class ProductActionMenu extends Component {
  state = {anchorEl: null,};

  handleOpen = (e) => {
    this.setState({anchorEl: e.target,});
  };

  handleClose = () => {
    this.setState({anchorEl: null,});
  };

  handleShare = () => {
    this.handleClose();
    const {setModalContext, openShareModal, product} = this.props;
    setModalContext(product._id);
    openShareModal();
  };

  handleDelete = (e) => {
    const {deleteProduct, product} = this.props;
    deleteProduct(product._id);
    this.setState({anchorEl: null,});
  };

  render() {
    const {product, userData} = this.props;
    const {anchorEl} = this.state;
    return (
      <Fragment>
        <IconButton
          size={'small'}
          onClick={this.handleOpen}
        >
          <MoreHorizIcon/>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.handleDelete}>
            <ListItemIcon>
              <Icon>delete</Icon>
            </ListItemIcon>
            {(product.readOnly ? 'Remove' : 'Delete')}
          </MenuItem>
          {!product.readOnly && userData.superadmin && <MenuItem onClick={this.handleShare}>
            <ListItemIcon>
              <Icon>share</Icon>
            </ListItemIcon>
            Share Product
          </MenuItem>}
        </Menu>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {userData: state.authReducer.userData,};
}

function mapDispatchToProps(dispatch) {
  return {
    deleteProduct: (_id) => dispatch(_deleteProduct(_id)),
    setModalContext: (_id) => dispatch(_setModalContext('shareProductModal', {productId: _id,})),
    openShareModal: () => dispatch(setModalOpen('shareProductModal', true)),

  };
}

ProductActionMenu = connect(mapStateToProps, mapDispatchToProps)(ProductActionMenu);
export default ProductActionMenu;

