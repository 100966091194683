import React, {useCallback} from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import {Checkbox} from '@mui/material';
import {useGridApiContext} from '@mui/x-data-grid-pro/hooks/utils/useGridApiContext';
import {useGridSelector} from '@mui/x-data-grid/hooks/utils/useGridSelector';
import {gridFilterModelSelector} from '@mui/x-data-grid/hooks/features/filter/gridFilterSelector';

export function ArchivedJobsFilter ({applyFilterChanges, deleteFilter}: any) {
  const apiRef = useGridApiContext();
  const filterModel = useGridSelector(apiRef, gridFilterModelSelector);

  const archived = React.useMemo(() => (filterModel.items || []).find(({columnField}) => columnField === 'archived'), [filterModel.items]);

  const handleChange = useCallback(() => {
    const filter = {id: 'archived', columnField: 'archived', operatorValue: 'archived', value: !archived?.value};
    if (filter.value) {
      applyFilterChanges(filter);
    } else {
      deleteFilter(archived);
    }
  }, [archived, applyFilterChanges, deleteFilter]);

  return <FormGroup>
    <FormControlLabel
      control={<Checkbox checked={!!archived?.value}/>} label='Show Archived Jobs'
      onChange={handleChange}
    />
  </FormGroup>;
}
