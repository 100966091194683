import React, {Fragment} from 'react';
import {Switch} from 'react-router-dom';
import Integrations from '../../modules/integrations/Integrations';
import BillingDashboard from '../../componentsOld/billingDashboard/BillingDashboard';
import {OrganisationSettings} from '../../pages/settings';
import {DecorationManyViewPage} from '../../componentsOld/decorations/DecorationManyViewPage';
import {DecorationImport} from '../../componentsOld/decorations/import/DecorationImport';
import StatusesDashboard from '../../componentsOld/Statuses/StatusesDashboard';
import UserManyViewPage from '../../componentsOld/userDashboard/UserManyViewPage';
import Vendor from '../../componentsOld/vendors/Vendor';
import VendorManyViewPage from '../../componentsOld/vendors/VendorManyViewPage';
import AutomationManyViewPage from '../../modules/automation/AutomationManyViewPage';
import EmailTemplateManyViewPage from '../../modules/emailTemplate/EmailTemplateManyViewPage';
import {roles, PermissionBasedRoute} from '../../componentsHoops/AccessControl';
import ResourceManyViewPage from '../../modules/schedule/ResourceManyViewPage';
import {TaxRoutes} from '../../modules/tax/TaxRoutes';
import MarkupsPage from '../../pages/markups/MarkupsPage';

class Settings extends React.Component {
  render() {
    const {admin, standard} = roles;

    return (
      <Fragment>
        <Switch>
          <PermissionBasedRoute
            allowedRoles={[admin, standard]}
            path='/settings/organisation'
            exact
          >
            <OrganisationSettings/>
          </PermissionBasedRoute>
          <PermissionBasedRoute
            allowedRoles={[admin]}
            path='/settings/billing'
            exact
          >
            <BillingDashboard />
          </PermissionBasedRoute>
          <PermissionBasedRoute
            allowedRoles={[admin]}
            path='/settings/users'
            exact
          >
            <UserManyViewPage />
          </PermissionBasedRoute>
          <PermissionBasedRoute
            allowedRoles={[admin, standard]}
            path='/settings/integrations'
            exact
          >
            <Integrations />
          </PermissionBasedRoute>
          <PermissionBasedRoute
            allowedRoles={[admin, standard]}
            path='/settings/vendors'
            exact
          >
            <VendorManyViewPage />
          </PermissionBasedRoute>
          <PermissionBasedRoute
            allowedRoles={[admin, standard]}
            path='/settings/vendors/:_id'
            exact>
            <Vendor />
          </PermissionBasedRoute>
          <PermissionBasedRoute
            allowedRoles={[admin]}
            path='/settings/decorations/import'
          >
            <DecorationImport />
          </PermissionBasedRoute>
          <PermissionBasedRoute
            allowedRoles={[admin]}
            path='/settings/decorations'
          >
            <DecorationManyViewPage />
          </PermissionBasedRoute>

          <PermissionBasedRoute
            allowedRoles={[admin, standard]}
            path='/settings/statuses'
          >
            <StatusesDashboard />
          </PermissionBasedRoute>
          <PermissionBasedRoute
            allowedRoles={[admin, standard]}
            path='/settings/resources'
          >
            <ResourceManyViewPage />
          </PermissionBasedRoute>
          <PermissionBasedRoute
            allowedRoles={[admin, standard]}
            path='/settings/email-templates'
          >
            <EmailTemplateManyViewPage />
          </PermissionBasedRoute>
          <PermissionBasedRoute
            allowedRoles={[admin, standard]}
            path='/settings/automations'
          >
            <AutomationManyViewPage />
          </PermissionBasedRoute>
          <PermissionBasedRoute
            allowedRoles={[admin, standard]}
            path='/settings/markups'
          >
            <MarkupsPage />
          </PermissionBasedRoute>
         <TaxRoutes />
        </Switch>
      </Fragment >
    );
  }
}

export default Settings;
