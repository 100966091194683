import React, {useCallback, useMemo} from 'react';
import {Select, SelectItem} from '../../componentsLib/Pickers';
import {useCompany, useMountEffect} from '../../hooks';
import {registerGlobalStyle} from '../../theme';
import {HoopsPropTypes} from '../../componentsLib';

registerGlobalStyle('.tax-select', () => ({minWidth: 300,}));

export function TaxSelect({value, onChange, selectDefault}) {
  const {company} = useCompany();

  const taxes = useMemo(() => {
    const taxList = company.taxes.filter((tax) => tax.type === 'revenue');
    const defaultTax = taxList.find((tax) => tax.isDefault);
    const taxNames = taxList.map((tax) => tax.name);
    return {taxList, defaultTax, taxNames};
  }, [company.taxes]);

  const handleChange = useCallback((taxName) => {
    if (taxName !== value?.name) {
      const tax = taxes.taxList.find(({name}) => name === taxName);
      onChange(tax);
    }
  }, [onChange, taxes, value?.name]);

  useMountEffect(() => {
    if (selectDefault && value === null && taxes.defaultTax) {
      onChange(taxes.defaultTax);
    }
  }, []);

  return (
    <Select className={'tax-select'} label={'Tax'} value={value?.name} onChange={handleChange}>
      {taxes.taxNames.map((name) => (<SelectItem key={name} value={name} text={name}/>))}
    </Select>
  );
}

TaxSelect.propTypes = {
  value: HoopsPropTypes.object,
  selectDefault: HoopsPropTypes.bool,
  onChange: HoopsPropTypes.func,
};
