import {Chip, Grid, Typography} from '@material-ui/core';
import React from 'react';

export default ({statusGroup}) => (
    <Grid container spacing={4}>
      {statusGroup.locked ? null : <Grid item xs={12}>
        <Typography variant='subtitle1'>Column Name:</Typography>
        <Typography variant='h5'>{statusGroup.label}</Typography>

      </Grid>}
      <Grid item xs={12}>
        <Grid container spacing={3}>
          {statusGroup.statuses.map(
            (status) => <Grid item xs={12} key={status._id}>
              <Chip label={status.label} color={'primary'} style={{backgroundColor: status.color}} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
