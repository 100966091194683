import {gql, useLazyQuery} from '@apollo/client';
import {Button, CircularProgress, Dialog, DialogContent, IconButton} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import React, {useState} from 'react';
import {ErrorMessage} from '../../../componentsOld/ErrorMessage';

const STRIPE_INACTIVE_REASON = gql`
    query stripeInactiveReason{
        stripeInactiveReason
    }
`;

export function StripeInactiveReason({refetch}) {
  const [open, setOpen] = useState(false);
  const [stripeInactiveReason, {data, loading, error, called}] = useLazyQuery(STRIPE_INACTIVE_REASON);
  return (<>
      <IconButton disabled={loading} onClick={async () => {
        setOpen(true);
        await stripeInactiveReason();

      }} color='primary' aria-label='upload picture' component='span'>
        {!loading && <InfoIcon/>}
        {loading && <CircularProgress size={18}/>}
      </IconButton>
      <Dialog open={open && !loading} onClose={() => {
        if (data && data.stripeInactiveReason.length === 0) {
          refetch();
        }
        setOpen(false);
      }}>
        {called && data && <DialogContent>
          <p style={{textAlign: 'center'}}>
            {data.stripeInactiveReason.length > 0 && <InfoIcon color={'error'}/>}
            {data.stripeInactiveReason.length === 0 && <InfoIcon color={'primary'}/>}
          </p>
          <ErrorMessage error={error}/>
          {data.stripeInactiveReason.map((reason, index) => <li key={index}>{reason}</li>)}
          {data.stripeInactiveReason.length === 0 && <li>Your account has been connected</li>}
          <p style={{textAlign: 'center'}}><Button variant={'contained'} onClick={() => {
            if (data && data.stripeInactiveReason.length === 0) {
              refetch();
            }
            setOpen(false);
          }} color={'primary'}>GOT IT</Button></p>
        </DialogContent>}
      </Dialog>
    </>

  );
}
