import {gql, useMutation} from '@apollo/client';
import React from 'react';
import {Button, CircularProgress} from '@material-ui/core';
import {ErrorMessage} from '../../../componentsOld/ErrorMessage';

const GET_LINK = gql`
    mutation getStripeOAuthLink{
        getStripeOAuthLink
    }
`;

export function ButtonConnectStripe({children}) {
  const [getLink, {loading, error, refetch}] = useMutation(GET_LINK);
  return (
    <div>
      <ErrorMessage error={error} onClick={() => refetch()}/>
      <Button variant='contained' color='primary' disabled={loading}
              onClick={async () => {
                const {data} = await getLink();
                window.location.href = data.getStripeOAuthLink;
              }}>
        {children}
        {loading && <CircularProgress size={24} color={'white'}/>}
      </Button>
    </div>
  );
}
