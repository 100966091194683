import React, {useCallback, useEffect, useState} from 'react';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {Tooltip} from '@mui/material';
import {registerGlobalStyle} from '../../../theme';
import {BodyText} from '../../Text';
import {Placement} from '../../Popovers';
import {TextInput} from '../../Basic';
import {HoopsPropTypes} from '../../utils/HoopsPropTypes';
import {GridViewAddColumnMenu} from './GridViewAddColumnMenu';

registerGlobalStyle('.power-grid .MuiDataGrid-columnHeaders', (theme) => ({
  borderRadius: 0,
  borderBottom: `1px solid ${theme.colors.border.lightest}`,
  borderTop: `1px solid ${theme.colors.border.lightest}`,
  overflow: 'visible',
  alignItems: 'stretch',
  button: {padding: 3},
  '&>div': {
    alignItems: 'stretch',
    overflow: 'visible',
  },
  '.column-header': {
    padding: 0,
    alignItems: 'stretch',
    '&:hover': {backgroundColor: theme.colors.background.whiteHover},
    '&:focus, &:focus-within': {outline: 'none'},
    '.MuiDataGrid-columnHeaderTitleContainerContent': {width: '100%',},
    '.add-column-icon-left': {
      position: 'absolute',
      left: theme.spacing(-1.25),
      top: theme.spacing(-1),
      color: theme.colors.text.highlight,
      fontSize: '1.25rem',
      backgroundColor: theme.colors.palette.white,
      borderRadius: '50%',
      zIndex: 101,
      opacity: 0,
      transition: theme.transitions.out.all,
    },
    '&:hover .add-column-icon-left': {
      opacity: 1,
      transition: theme.transitions.in.all,
    },
    '&:hover +.column-header .add-column-icon-left': {
      opacity: 1,
      transition: theme.transitions.in.all,
    },
    '&:first-child .add-column-icon-left': {display: 'none'},
    '.drag-icon': {
      color: theme.colors.text.medium,
      transition: theme.transitions.out.all,
      opacity: 0,
      '&:hover': {
        color: theme.colors.text.main,
        transition: theme.transitions.in.all,
      },
    },
    '.MuiDataGrid-columnHeaderDraggableContainer[draggable="true"]': {cursor: 'grab'},
    '&:hover .MuiDataGrid-columnHeaderDraggableContainer[draggable="true"] .drag-icon': {
      opacity: 1,
      transition: theme.transitions.in.all,
    },
    '.column-header-title': {
      minWidth: '0',
      flex: '1 1 fit-content',
      display: 'flex',
      justifyContent: 'center',
      '.text': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        borderRadius: theme.shape.borderRadius,
        border: '1px solid transparent',
        cursor: 'text',
        padding: theme.spacing(0, 1),
        transition: theme.transitions.out.all,
        '&:hover': {
          borderColor: theme.colors.border.light,
          transition: theme.transitions.in.all,
          backgroundColor: theme.colors.palette.white,
        }
      }
    },
    '&.MuiDataGrid-columnHeader--sortable .sort-icon-space': {
      flex: `0 1000000 ${theme.spacing(3)}`,
      minWidth: 0,
    },
    '&.MuiDataGrid-columnHeader--sortable:hover .sort-icon-space.reserve': {display: 'none',},
    '&.MuiDataGrid-columnHeader--sorted .sort-icon-space.reserve': {display: 'none',},
    '&.MuiDataGrid-columnHeader--sortable .MuiDataGrid-iconButtonContainer': {
      visibility: 'visible',
      width: 0,
      opacity: 0,
      transition: theme.transitions.out.opacity,
      minWidth: 0,
      'button': {
        background: 'transparent',
        transition: theme.transitions.out.all,
        '&:hover': {
          color: theme.colors.text.highlight,
          transition: theme.transitions.in.all,
        },
      },
      svg: {opacity: 1},
    },
    '&.MuiDataGrid-columnHeader--sortable:hover .MuiDataGrid-iconButtonContainer': {
      width: theme.spacing(3),
      flex: 'none',
      opacity: 1,
      transition: theme.transitions.in.opacity,
    },
    '&.MuiDataGrid-columnHeader--sorted .MuiDataGrid-iconButtonContainer': {
      width: theme.spacing(3),
      flex: 'none',
      opacity: 1,
      transition: theme.transitions.in.opacity,
    },
    '.MuiDataGrid-menuIcon': {
      width: 'auto',
      height: '100%',
      right: 0,
      margin: 0,
      opacity: 0,
      visibility: 'visible',
      transition: theme.transitions.out.all,
      'button': {
        background: 'transparent',
        transition: theme.transitions.out.all,
        '&:hover': {
          color: theme.colors.text.highlight,
          transition: theme.transitions.in.all,
        },
      },
      svg: {fontSize: '1.125rem'}
    },
    '&:hover .MuiDataGrid-menuIcon': {
      opacity: 1,
      transition: theme.transitions.in.all,
    },
    // '& .MuiDataGrid-columnHeaderDraggableContainer:hover .MuiDataGrid-menuIcon': {opacity: 1},
    '.MuiDataGrid-columnSeparator': {
      width: 12,
      position: 'absolute',
      alignItems: 'center',
      '&.MuiDataGrid-columnSeparator--sideRight': {right: -5},
      '&.MuiDataGrid-columnSeparator--sideLeft': {left: -7},
      '&>div': {
        width: 3,
        height: theme.spacing(2.125),
        backgroundColor: theme.colors.text.light,
        transition: theme.transitions.out.all,
        zIndex: 1,
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        height: '100%',
        right: 5,
        borderLeft: `1px solid ${theme.colors.text.light}`,
      },
      '&:hover': {
        '&>div': {
          backgroundColor: theme.colors.text.medium,
          transition: theme.transitions.in.all,
        }
      },
    },
    '.column-heading-edit-container': {
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      width: '100%',
      left: 0,
      padding: '8px',
      zIndex: 1,
      backgroundColor: theme.colors.background.white,

      '.text-input': {
        backgroundColor: theme.colors.background.white,
        paddingBlock: 0,
        textAlign: 'center',
        lineHeight: '1.66',
      }
    },
  },
  '.MuiDataGrid-pinnedColumnHeaders': {'.add-column-icon-left': {display: 'none'},},
  '.MuiDataGrid-pinnedColumnHeaders--left .column-header:last-child .MuiDataGrid-columnSeparator::after': {borderLeft: `1px solid ${theme.colors.border.main}`},
  '.MuiDataGrid-pinnedColumnHeaders--right .column-header:first-child .MuiDataGrid-columnSeparator': {
    left: -6,
    '&::after': {borderLeft: `1px solid ${theme.colors.border.main}`,}
  },
}));

const tooltipOffset = {
  modifiers: [{
    name: 'offset',
    options: {offset: [0, -4]}
  }]
};

export function GridViewColumnHeader({colDef}) {
  const [title, setTitle] = useState(colDef?.headerName);
  const [editing, setEditing] = useState(false);
  const [addColumnMenuAnchorEl, setAddColumnMenuAnchorEl] = useState(null);

  useEffect(() => {
    setTitle(colDef?.headerName);
  }, [colDef?.headerName]);

  const handleOpenAddColumnMenu = useCallback((event) => {
    event.stopPropagation();
    setAddColumnMenuAnchorEl(event.currentTarget);
  }, []);

  const handleCloseAddColumnMenu = useCallback(() => {
    setAddColumnMenuAnchorEl(null);
  }, []);

  const handleAddColumn = useCallback(async (colType, colName) => {
    if (colType != null) {
      await colDef.addColumn(colType, colName, false);
    }
  }, [colDef]);

  const handleColumnHeadingClick = useCallback((e) => {
    e.stopPropagation();
    setEditing(true);
  }, []);

  const handleEditChange = useCallback((e) => setTitle(e.target.value), []);

  const handleEditClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const handleEditBlur = useCallback(async (e) => {
    setEditing(false);
    await colDef.renameColumn(e.target.value);
  }, [colDef]);

  const handleEditKeyDown = useCallback(async (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      setEditing(false);
      await colDef.renameColumn(e.target.value);
    } else if (e.key === 'Escape') {
      setEditing(false);
    }
  }, [colDef]);

  return (
    <>
      {!editing &&
        <>
          {colDef.context.allowAddColumns &&
            <Tooltip arrow placement='top' title={'Add Column'} PopperProps={tooltipOffset}>
              <AddCircleIcon className={'add-column-icon add-column-icon-left'} cursor={'pointer'} onClick={handleOpenAddColumnMenu}/>
            </Tooltip>
          }
          <DragIndicatorIcon className='drag-icon'/>
          <div className={'sort-icon-space'}/>
          <div className={'column-header-title'}>
            <BodyText noWrap onClick={handleColumnHeadingClick} text={title}/>
          </div>
          <div className={'sort-icon-space reserve'}/>
          {addColumnMenuAnchorEl &&
            <GridViewAddColumnMenu anchorEl={addColumnMenuAnchorEl} onClose={handleCloseAddColumnMenu} onAddColumn={handleAddColumn} placement={Placement.Bottom}/>
          }
        </>
      }

      {editing &&
        <div className={'column-heading-edit-container'}>
          <TextInput
            autoFocus
            selectOnFocus
            value={title}
            onBlur={handleEditBlur}
            onKeyDown={handleEditKeyDown}
            onClick={handleEditClick}
            onChange={handleEditChange}
          />
        </div>
      }
    </>
  );
}

GridViewColumnHeader.propTypes = {colDef: HoopsPropTypes.any,};
