import {isEqual} from 'lodash';

export function compareArrays(previous, current, key) {
  const changes = {
    added: [],
    changed: [],
    removed: [],
  };

  // First find anything that has been added or changed in the current array
  current.forEach((curr, i) => {
    const prevIdx = previous.findIndex((e) => e[key] === curr[key]);
    if (prevIdx === -1) {
      changes.added.push({change: 'add', current: curr, index: i});
    } else if (!isEqual(curr, previous[prevIdx])) {
      changes.changed.push({change: 'change', current: curr, previous: previous[prevIdx], index: i});
    }
  }, []);

  // Now find any elements that have been removed from the previous array
  previous.forEach((prev, i) => {
    const currIdx = current.findIndex((e) => e[key] === prev[key]);
    if (currIdx === -1) {
      changes.removed.push({change: 'remove', previous: prev, index: i});
    }
  }, []);

  return changes;
}
