import theme from '.';

export const itemBuilder = {
    header: {color: theme.colors.grey.main,},
    tableHeaderRow: {
        verticalAlign: 'bottom',
        '& .MuiTableCell-head': {
            color: theme.colors.grey.main,
            fontWeight: 300,
            paddingLeft: 0,
            paddingBottom: theme.spacing(1)
        },
    },
    tableCell: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(1),
        '& .MuiTableCell-root': {padding: theme.spacing(1),}
    },
    button: {
        float: 'left',
        margin: theme.spacing(1),
        marginLeft: 0,
        whiteSpace: 'nowrap'
    },
    disabledTextField: {'& .MuiOutlinedInput-root': {background: '#F3F3F6'},},
    disableAutocompleteMargin: {'&& .MuiFormControl-marginDense': {margin: 0}},
    tableContainer: {
        minWidth: 'fit-content',
        paddingTop: theme.spacing(1),
        background: 'none'
    },
    container: {padding: theme.spacing(3)},
    boldField: {fontWeight: 500},
    actionCellHeader: {
        paddingRight: 0,
        textAlign: 'center',
    },
    actionCell: {textAlign: 'center',},
    tooltip: {
        maxWidth: 170,
        textAlign: 'center',
        fontSize: theme.typography.caption.fontSize,
        fontWeight: 400,
    },
    icon: {color: theme.colors.grey.main,},
    infoCell: {
        paddingTop: 1,
        paddingLeft: 4
    },
    greyText: {color: theme.colors.grey.main},
    textFieldWithAdornmentBtn: {
        '& .MuiOutlinedInput-adornedEnd': {
            paddingLeft: 0,
            paddingRight: 0,
        }
    },
    error: {
        color: theme.colors.red,
        maxWidth: 'fit-content',
        paddingTop: theme.spacing(1)
    },
    warningAdornment: {color: theme.colors.orange,},
    warningCell: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: theme.colors.orange,
                background: 'rgba(255,191,102,0.2)',
            },
        },
    },
    selectStyles: {
        display: 'grid',
        '& .MuiSelect-selectMenu': {width: 'auto'}
    },
    marginDense: {
        marginTop: 8,
        marginBottom: 4,
    },

    // Responsive Styles
    responsiveActionMenu: {
        textAlign: 'center',
        padding: '16px 0 8px 0',
        [theme.breakpoints.up('md')]: {display: 'none'},
        [theme.breakpoints.down('md')]: {display: 'revert'},
    },
    responsiveActionButtons: {
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {display: 'revert'},
        [theme.breakpoints.down('md')]: {display: 'none'},
        '& .MuiIconButton-root.Mui-disabled': {color: 'rgba(0, 0, 0, 0.05)',}
    },

};
