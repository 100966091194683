import {
  SET_TASK,
  CREATE_TASK,
  UPDATE_TASK,
  REMOVE_TASK,
  UPDATE_TASKS,
  MODAL_TASK
} from '../actions/tasks';

export const newTask = {
  $isNew: true,
  status: 'TO DO',
  dueAt: null,
  endAt: null,
  allDay: true,
  isDone: false,
  memberIds: [],
  members: [],
  quoteIds: [],
  quotes: [],
  jobIds: [],
  jobs: [],
  fileIds: [],
  files: []
};

export const initialState = {
  active: {...newTask},
  list: {
    data: [],
    pagination: null,
    loaded: false
  },
  lastSaved: null,
  lastRemoved: null,
  isModalOpen: false
};

const taskReducer = (state = initialState, {type, payload}) => {
  switch (type) {

    case MODAL_TASK:
      return {
        ...state,
        isModalOpen: payload
      };
    
    case UPDATE_TASKS:
      return {
        ...state,
        list: {
          data: payload,
          loaded: true
        }
      };

      case SET_TASK:
        return {
          ...state,
          active: payload
        };

    case CREATE_TASK:
      return {
        ...state,
        lastSaved: payload.record,
        list: {
          ...state.list,
          data: [
            ...state.list.data,
            payload.record
          ]
        }
      };

    case UPDATE_TASK:
      return {
        ...state,
        lastSaved: payload.record,
        list: {
          ...state.list,
          data: state.list.data.map(
            (item) => item._id === payload.recordId ? payload.record : item
          )
        }
      };

    case REMOVE_TASK:
      return {
        ...state,
        lastRemoved: payload,
        list: {
          ...state.list,
          data: state.list.data.filter(
            (item) => item._id === payload ? false : true
          )
        }
      };

    default:
      return state;
  }
};

export default taskReducer;
