import {REGISTER_LOADING, REGISTER_ERROR, REGISTER_SUCCESS} from '../actions/action-types/registration';

const initialState = {
  loading: false,
  error: false,
  errorMessage: '',
  success: false,
  registeredCompany: {}
};

const registrationReducer = (state = {...initialState}, action) => {
  switch (action.type) {
    case REGISTER_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: '',
        success: false,
        registeredCompany: {}
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        success: true,
        registeredCompany: action.payload
      };
    case REGISTER_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        success: false,
      };
    default:
      return state;
  }
};

export default registrationReducer;
