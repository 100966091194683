import React, {forwardRef} from 'react';
import classNames from 'classnames';
import {registerGlobalStyle} from '../../theme';
import {HoopsPropTypes} from '../utils';

registerGlobalStyle('.row', (theme) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'start',
  alignSelf: 'stretch',
  '&.row-align-baseline': {alignItems: 'baseline'},
  '&.row-align-center': {alignItems: 'center'},
  '&.row-divider': {
    borderTop: `1px solid ${theme.colors.border.main}`,
    marginBlock: theme.spacing(1),
  },
  '&.row-gap': {columnGap: theme.spacing(2)},
  '&.row-gap.row-wrap': {rowGap: theme.spacing(1)},
  '&.row-justify-center': {justifyContent: 'center'},
  '&.row-justify-right': {justifyContent: 'end'},
  '&.row-justify-space-between': {justifyContent: 'space-between'},
  '&.row-pad': {padding: theme.spacing(2)},
  '&.row-space': {
    columnGap: theme.spacing(2),
    padding: theme.spacing(2)
  },
  '&.row-wrap': {flexWrap: 'wrap'},
  '&>.row-right': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    marginLeft: 'auto',
    marginRight: '0',
    columnGap: 'inherit',
  },
}));

export const Row = forwardRef(
function Row(
  {
    className,
    alignBaseline,
    alignCenter,
    divider,
    gap,
    justifyCenter,
    justifyRight,
    justifySpaceBetween,
    pad,
    paper,
    space,
    style,
    tag,
    value,
    wrap,
    onClick,
    children
  },
  ref
) {
  className = classNames([
    className,
    'row',
    alignBaseline && 'row-align-baseline',
    alignCenter && 'row-align-center',
    divider && 'row-divider',
    gap && 'row-gap',
    justifyCenter && 'row-justify-center',
    justifyRight && 'row-justify-right',
    justifySpaceBetween && 'row-justify-space-between',
    pad && 'row-pad',
    paper && 'row-paper paper',
    space && 'row-space',
    wrap && 'row-wrap',
  ]);

  const Tag = tag ?? 'div';

  return <Tag className={className} ref={ref} style={style} value={value} onClick={onClick}>{children}</Tag>;
});

export function RowRight({children}) {
  return <div className={'row-right'}>{children}</div>;
}

Row.propTypes = {
  className: HoopsPropTypes.className,
  alignBaseline: HoopsPropTypes.bool,
  alignCenter: HoopsPropTypes.bool,
  divider: HoopsPropTypes.bool,
  gap: HoopsPropTypes.bool,
  justifyCenter: HoopsPropTypes.bool,
  justifyRight: HoopsPropTypes.bool,
  justifySpaceBetween: HoopsPropTypes.bool,
  pad: HoopsPropTypes.bool,
  paper: HoopsPropTypes.bool,
  space: HoopsPropTypes.bool,
  style: HoopsPropTypes.object,
  tag: HoopsPropTypes.string,
  value: HoopsPropTypes.string,
  wrap: HoopsPropTypes.bool,
  onClick: HoopsPropTypes.func,
  children: HoopsPropTypes.children,
};
RowRight.propTypes = {children: HoopsPropTypes.children.isRequired};
