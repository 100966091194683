import React, {useCallback, useEffect, useMemo, useState} from 'react';
import CopyIcon from '@mui/icons-material/FileCopy';
import InsertTextIcon from '@mui/icons-material/ReadMore';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import copy from 'copy-to-clipboard';
import {HoopsPropTypes} from '../../componentsLib';
import {Column, ExpansionSection, Fields, Row} from '../../componentsLib/Layout';
import {Button, TextInput, useWysiwygContext, WysiwygEditor, WysiwygProvider} from '../../componentsLib/Basic';
import {BodyText, CaptionText, HeadingText} from '../../componentsLib/Text';
import {Tab, Tabs} from '../../componentsLib/Basic/Tabs';
import {useLocalStorageState} from '../../hooks';
import {Checkbox} from '../../componentsLib/Basic/Checkbox';
import {ModalDialog} from '../../componentsLib/Popovers';
import {registerGlobalStyle} from '../../theme';
import {formatShortCode, getShortCodesWithAliases, sentenceShortCode} from '../../models/ShortCodes';
import {capitalize} from '../../utils';
import {useListNotificationTemplates} from '../../hooks/api';
import {Select, SelectItem} from '../../componentsLib/Pickers';

registerGlobalStyle('.send-dialog', (theme) => ({
  '.modal-dialog-content': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    width: '80vw',
    maxWidth: 1280,
    height: '84vh',
  },
  '.form-body': {
    flexGrow: 1,
    columnGap: theme.spacing(2),
    '&>*': {
      flex: '3 1 0',
      alignSelf: 'stretch',
    },
    '&>*:first-child': {flex: '2 1 0',},
    '.wysiwyg-input': {flexGrow: 1},
    'textarea': {flexGrow: 1},
  },
  '.shortcodes-list': {
    justifyContent: 'space-between',
    columnGap: theme.spacing(2),
    '&>*': {flex: '1 1 0'},
  },
  '.shortcode': {
    columnGap: theme.spacing(.75),
    alignItems: 'center',
    svg: {fontSize: '1rem'},
    '*:last-child>svg': {color: theme.colors.text.mediumDecorator},
  },
  '.expand-button': {textTransform: 'uppercase'}
}));

export function SendDialog({className, entity, title, to, cc, phone, replyTo, onClose, onSend}) {
  const [sendViaEmail, setSendViaEmail] = useLocalStorageState('send|sendViaEmail', true);
  const [sendViaSMS, setSendViaSMS] = useLocalStorageState('send|sendViaSms', false);
  const {data: {notificationTemplates}, isLoading} = useListNotificationTemplates({query: {entity}});
  const [tab, setTab] = useState(sendViaEmail || !sendViaSMS ? 'email' : 'sms');
  const [emailState, setEmailState] = useState({to, cc, replyTo});
  const [smsState, setSMSState] = useState({phone});
  const [sending, setSending] = useState(false);

  const emailTemplates = useMemo(() => {
    if (!notificationTemplates) {
      return [];
    }
    return notificationTemplates
      ?.filter((template) => template.via === 'email')
      .toSorted((a, b) => a.title.localeCompare(b.title)) ?? [];
  }, [notificationTemplates]);

  const smsTemplates = useMemo(() => {
    if (!notificationTemplates) {
      return [];
    }
    return notificationTemplates
      ?.filter((template) => template.via === 'sms')
      .toSorted((a, b) => a.title.localeCompare(b.title)) ?? [];
  }, [notificationTemplates]);

  useEffect(() => {
    if (notificationTemplates) {
      if (!emailState.template && emailTemplates.length > 0) {
        const template = emailTemplates.find(({isDefault}) => isDefault) ?? emailTemplates[0];
        if (template) {
          setEmailState({
            ...emailState,
            template: template._id,
            subject: template.subject,
            body: template.body,
          });
        }
      }

      if (!smsState.template && smsTemplates.length > 0) {
        const template = smsTemplates.find(({isDefault}) => isDefault) ?? smsTemplates[0];
        if (template) {
          setSMSState({
            ...smsState,
            template: template._id,
            body: template.body,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationTemplates]);

  const handleChangeSendViaEmail = useCallback((e) => {
    setSendViaEmail(e.target.checked);
    if (e.target.checked) {
      setTab('email');
    }
  }, [setSendViaEmail]);

  const handleChangeSendViaSMS = useCallback((e) => {
    setSendViaSMS(e.target.checked);
    if (e.target.checked) {
      setTab('sms');
    }
  }, [setSendViaSMS]);

  const handleCheckClick = useCallback((e) => e.stopPropagation(), []);

  const handleClose = useCallback((confirmed) => {
    if (!confirmed) {
      onClose(false);
    }
  }, [onClose]);

  const handleOk = useCallback(async () => {
    try {
      setSending(true);
      await onSend?.({
        ...(sendViaEmail ? {email: emailState} : {}),
        ...(sendViaSMS ? {sms: smsState} : {}),
      });
    } finally {
      setSending(false);
      onClose(true);
    }
  }, [emailState, onClose, onSend, sendViaEmail, sendViaSMS, smsState]);

  return (
    <ModalDialog
      className={[className, 'send-dialog']}
      title={title}
      loading={sending}
      actionsBorder
      onClose={handleClose}
      onOk={handleOk}
      okText={'Send'}
      okDecorator={'send'}
    >
      <Tabs value={tab} onChange={setTab}>
        <Tab value={'email'}>
          <Checkbox checked={sendViaEmail} onChange={handleChangeSendViaEmail} onClick={handleCheckClick}/>
          <CaptionText text={'Email'}/>
        </Tab>
        <Tab value={'sms'}>
          <Checkbox checked={sendViaSMS} onChange={handleChangeSendViaSMS} onClick={handleCheckClick}/>
          <CaptionText text={'SMS'}/>
        </Tab>
      </Tabs>
      <Column pad scroll grow>
        {tab === 'email' &&
          <EmailForm
            emailState={emailState}
            onChange={setEmailState}
            entity={entity}
            loading={isLoading}
            templates={emailTemplates}
          />}
        {tab === 'sms' &&
          <SMSForm
            smsState={smsState}
            onChange={setSMSState}
            entity={entity}
            loading={isLoading}
            templates={smsTemplates}
          />
        }
      </Column>
    </ModalDialog>
  );
}

SendDialog.propTypes = {
  className: HoopsPropTypes.className,
  entity: HoopsPropTypes.string,
  title: HoopsPropTypes.string,
  to: HoopsPropTypes.string,
  cc: HoopsPropTypes.string,
  phone: HoopsPropTypes.string,
  replyTo: HoopsPropTypes.string,
  onClose: HoopsPropTypes.func,
  onSend: HoopsPropTypes.func,
};

function EmailForm({entity, emailState, onChange, templates, loading}) {
  const handleChangeTo = useCallback((e) => onChange((prev) => ({...prev, to: e.target.value})), [onChange]);
  const handleChangeCC = useCallback((e) => onChange((prev) => ({...prev, cc: e.target.value})), [onChange]);
  const handleChangeReplyTo = useCallback((e) => onChange((prev) => ({...prev, replyTo: e.target.value})), [onChange]);
  const handleChangeSubject = useCallback((e) => onChange((prev) => ({...prev, subject: e.target.value})), [onChange]);
  const handleChangeBody = useCallback((value) => onChange((prev) => ({...prev, body: value})), [onChange]);

  const handleChangeTemplate = useCallback((templateId) => {
    const template = templates.find(({_id}) => templateId === _id);
    if (template) {
      onChange((prev) => ({
        ...prev,
        template: templateId,
        subject: template.subject,
        body: template.body,
      }));
    }
  }, [onChange, templates]);

  return (
    <Column grow gap>
      <WysiwygProvider>
        <Row className={'form-body'} stretch>
          <Fields topLabel>
            <TextInput label={'To:'} value={emailState.to ?? ''} onChange={handleChangeTo}/>
            <TextInput label={'CC:'} value={emailState.cc ?? ''} onChange={handleChangeCC}/>
            <TextInput label={'Reply To:'} value={emailState.replyTo ?? ''} onChange={handleChangeReplyTo}/>
            <Select label={'Template:'} loading={loading} placeholder={'Choose email template'} value={emailState.template} onChange={handleChangeTemplate}>
              {templates?.map((template) => (
                <SelectItem key={template._id} value={template._id} text={template.title}/>
              ))}
            </Select>
            <TextInput label={'Subject:'} value={emailState.subject ?? ''} onChange={handleChangeSubject}/>
          </Fields>
          <Fields topLabel>
            <WysiwygEditor label={'Body'} value={emailState.body ?? ''} onChange={handleChangeBody} outerBorder/>
          </Fields>
        </Row>
        <ShortCodes codes={getShortCodesWithAliases(entity)}/>
      </WysiwygProvider>
    </Column>
  );
}

function SMSForm({entity, smsState, onChange, templates, loading}) {
  const handleChangePhone = useCallback((e) => onChange((prev) => ({...prev, phone: e.target.value})), [onChange]);
  const handleChangeBody = useCallback((e) => onChange((prev) => ({...prev, body: e.target.value})), [onChange]);

  const handleChangeTemplate = useCallback((templateId) => {
    const template = templates.find(({_id}) => templateId === _id);
    if (template) {
      onChange((prev) => ({
        ...prev,
        template: templateId,
        body: template.body,
      }));
    }
  }, [onChange, templates]);

  return (
    <Column grow gap>
      <WysiwygProvider>
        <Row className={'form-body'} stretch>
          <Fields topLabel>
            <TextInput label={'Phone Number:'} value={smsState.phone ?? ''} onChange={handleChangePhone}/>
            <Select label={'Template:'} loading={loading} placeholder={'Choose SMS template'} value={smsState.template} onChange={handleChangeTemplate}>
              {templates?.map((template) => (
                <SelectItem key={template._id} value={template._id} text={template.title}/>
              ))}
            </Select>
          </Fields>
          <Fields topLabel>
            <TextInput label={'Body'} value={smsState.body ?? ''} onChange={handleChangeBody} multiline/>
          </Fields>
        </Row>
        <ShortCodes codes={getShortCodesWithAliases(entity)}/>
      </WysiwygProvider>
    </Column>
  );
}

function ShortCodes({codes}) {
  const {insertText} = useWysiwygContext();

  console.log(codes);

  return (
    <ExpansionSection hideText={'Hide Shortcodes'} showText={'Show Shortcodes'} hideIcon={VisibilityOffIcon} showIcon={VisibilityIcon}>
    <Row className={'shortcodes-list'} wrap>
      {codes.map((code) => (
        <Column key={code.title}>
          <HeadingText text={code.title ?? capitalize(code.name)} subHeading/>
          {code.shortCodes.map((shortCode) => (
            <Row className={'shortcode'} key={shortCode.name}>
              <Button navPrimary prefix={InsertTextIcon} tabIndex={-1} onClick={() => insertText({text: formatShortCode(shortCode)})}/>
              <BodyText text={sentenceShortCode(shortCode)}/>
              <Button actionStandard prefix={CopyIcon} tabIndex={-1} onClick={() => copy(formatShortCode(shortCode))}/>
            </Row>
          ))}
        </Column>
      ))}
    </Row>
    </ExpansionSection>
  );
}

