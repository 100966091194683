import React, {useCallback} from 'react';
import {UserAvatar} from '../../Basic';
import {UserSelect} from '../../Pickers';
import {CellContainer, useCellContainerState} from './CellContainer';
import {registerGlobalStyle} from '../../../theme';
import {Placement} from '../../Popovers';

registerGlobalStyle('.power-grid .cell-data-type-user', (theme) => ({
  '&.editable .cell-container .user-select': {
    position: 'absolute',
    width: '100%',
    opacity: 0,
    zIndex: -1,
    pointerEvents: 'none',
  },
  '&:is(.MuiDataGrid-root--densityCompact .cell-data-type-user)': {
    '.user-avatar': {
      fontSize: '.9375rem',
      width: theme.spacing(3.5),
      height: theme.spacing(3.5),
    }
  }
}));

export function UserAvatarCell({row, colDef, value}) {
  const {editing, startEditing, endEditing} = useCellContainerState();

  const handleChange = useCallback((newValue) => {
    endEditing();
    colDef.valueSetter({row, value: newValue});
  }, [colDef, endEditing, row]);

  const option = value && colDef.valueOptions?.find((opt) => opt.value === value);

  return (
    <CellContainer editing={editing} onClick={startEditing}>
      <UserAvatar name={option?.fullName} initials={option?.initials}/>
      {editing &&
        <UserSelect initialOpen={true} placement={Placement.Bottom} value={value} onChange={handleChange} onClose={endEditing} users={colDef.valueOptions}/>
      }
    </CellContainer>
  );
}
