import {Grid, Typography} from '@material-ui/core';
import React from 'react';
import GrossMarginPerCustomerTable from './GrossMarginPerCustomerTable';
import GrossMarginPerUserTable from './GrossMarginPerUserTable';
import GrossMarginReportSummaryTable from './GrossMarginReportSummaryTable';

const GrossMarginHeader = ({reportSettings}) => {
    switch (reportSettings.grouping) {
        default:
        case 'salesPerCustomer':
            return (
                <>
                    <Typography variant={'h5'}>Gross Margin Report - Sales Per Customer</Typography>
                    <Typography>This report tallies the value for all quotes that were converted to jobs within the selected date range, grouped by customer.</Typography>
                    <Typography>Any items on quotes that have not used the item builder will be ignored from this report because Hoops cannot calculate the gross margin for these items.</Typography>
                </>
            );
        case 'salesPerUser':
            return (
                <>
                    <Typography variant={'h5'}>Gross Margin Report - Sales Per User</Typography>
                    <Typography>This report tallies the value for all quotes that were converted to jobs within the selected date range, grouped by user.</Typography>
                    <Typography>Any items on quotes that have not used the item builder will be ignored from this report because Hoops cannot calculate the gross margin for these items.</Typography>
                </>
            );
        case 'quotesPerCustomer':
            return (
                <>
                    <Typography variant={'h5'}>Gross Margin Report - Quotes Per Customer</Typography>
                    <Typography>This report tallies the costs, income, and gross margin, grouped by customer, for all quotes within the selected date range.</Typography>
                    <Typography>Any items on quotes that have not used the item builder will be ignored from this report because Hoops cannot calculate the gross margin for these items.</Typography>
                </>
            );
        case 'quotesPerUser':
            return (
                <>
                    <Typography variant={'h5'}>Gross Margin Report - Quotes Per User</Typography>
                    <Typography>This report tallies the costs, income, and gross margin, grouped by user, for all quotes within the selected date range.</Typography>
                    <Typography>Any items on quotes that have not used the item builder will be ignored from this report because Hoops cannot calculate the gross margin for these items.</Typography>
                </>
            );
    }
};

const GrossMarginDetailsTable = ({reportSettings}) => {
    switch (reportSettings.grouping) {
        default:
        case 'quotesPerCustomer':
        case 'salesPerCustomer':
            return <GrossMarginPerCustomerTable />;
        case 'quotesPerUser':
        case 'salesPerUser':
            return <GrossMarginPerUserTable />;
    }
};

function GrossMarginReport({currencySymbol, reportSettings, totals = {decorationSales: 0, inventorySales: 0, totalSales: 0}}) {

    return (<>
        <Grid item xs={12}>
            <GrossMarginHeader reportSettings={reportSettings} />
        </Grid>
        <Grid item xs={12}>
        <br/>
            <Typography align={'center'} variant={'h6'}>Summary</Typography>
        </Grid>
        <Grid item xs={12}>
            <GrossMarginReportSummaryTable {...{currencySymbol, totals}} reportSettings={reportSettings} />
        </Grid>
        <Grid item xs={12}>
        <br/>
            <Typography align={'center'} variant={'h6'}>Details</Typography>
        </Grid>
        <Grid item xs={12}>
            <GrossMarginDetailsTable reportSettings={reportSettings} />
        </Grid></>
    );
}

export default GrossMarginReport;
