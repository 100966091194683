import * as React from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogActions, DialogContent, Grid, IconButton, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {HoopsButton} from '../HoopsButton';

const styles = {
  '.MuiDialogContent-root': {
    padding: 2,
    minWidth: 600,
    '& > *:not(span)': {marginBottom: 2},
  },
  '.MuiDialogActions-root': {
    padding: 2,
    justifyContent: 'space-between'
  },
  '.MuiSelect-outlined': {padding: 1.1,},
};

export function StandardDialog({open, title, smallTitle, actions, children, onClose, closeText, onSave, saveText, saveDisabled, loading, handleClose, sx}) {
  onClose = onClose ?? handleClose;

  if (!actions && (onClose || onSave)) {
    actions = (
      <>
        {onClose && onSave &&
          <HoopsButton purpose='primary' onClick={onClose}>{closeText ?? 'Cancel'}</HoopsButton>
        }
        {onClose && !onSave &&
          <HoopsButton purpose='primary' onClick={onClose}>{closeText ?? 'Close'}</HoopsButton>
        }
        {onSave &&
          <HoopsButton disabled={saveDisabled} loading={loading} purpose='save' onClick={onSave}>{saveText ?? 'Save'}</HoopsButton>
        }
      </>
    );
  }

  return (
    <Dialog onClose={onClose} aria-labelledby='hoops-dialog-title' open={open} sx={{...styles, ...sx}}>
      {title &&
        <Grid sx={{m: 0, p: 2}}>
          <Typography variant={smallTitle ? 'h6' : 'h4'}>{title}</Typography>
          {onClose &&
            <IconButton
              aria-label='close'
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: 16,
                top: 16,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon/>
            </IconButton>
          }
        </Grid>
      }
      <DialogContent>
        {children}
      </DialogContent>

      {actions &&
        <DialogActions>
          {actions}
        </DialogActions>
      }
    </Dialog>
  );
}

StandardDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  smallTitle: PropTypes.bool,
  actions: PropTypes.PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  onClose: PropTypes.func,
  closeText: PropTypes.string,
  onSave: PropTypes.func,
  saveText: PropTypes.string,
  saveDisabled: PropTypes.bool,
  loading: PropTypes.bool,
  handleClose: PropTypes.func,
  sx: PropTypes.styles
};
