import {gql} from 'apollo-boost';

export const GQL_SET_DEFAULT_TAX =  gql`
  mutation TaxSetDefault($_id: MongoID!){
    taxSetDefault(_id: $_id) {
      _id
    }
}`;

const GQL_TAX_DETAIL_FRAGMENT = gql`
  fragment TaxDetailFragment on Tax {
    _id
    name
    type
    isDefault
    components {
      _id
      name
      rate
    }
  }
`;

export const GQL_TAX_LIST_FRAGMENT = gql`
  fragment TaxListFragment on Tax {
    _id
    name
    type
    isDefault
    components {
      name
      rate
    }
    createdBy {
      _id
      fullName
    }
  }
`;

export const GQL_GET_TAX = gql`
  ${GQL_TAX_DETAIL_FRAGMENT}
  query getTax($_id: MongoID!) {
    taxOne(filter: { _id: $_id }) {
      ...TaxDetailFragment
    }
  }
`;

export const GQL_GET_TAXES = gql`
  ${GQL_TAX_LIST_FRAGMENT}
  query getTaxes($filter: FilterFindManySearchTaxInput, $sort: SortFindManyTaxInput) {
    taxMany(filter: $filter, sort: $sort) {
      ...TaxListFragment
    }
  }
`;

export const GQL_CREATE_TAX = gql`
  ${GQL_TAX_DETAIL_FRAGMENT}
  mutation createTax($record: CreateOneTaxInput!) {
    taxCreateOne(record: $record) {
      recordId
      record {
        ...TaxDetailFragment
      }
    }
  }
`;

export const GQL_UPDATE_TAX = gql`
  ${GQL_TAX_DETAIL_FRAGMENT}
  mutation updateTax($_id: MongoID!, $record: UpdateByIdTaxInput!) {
    taxUpdateById(_id: $_id, record: $record) {
      recordId
      record {
        ...TaxDetailFragment
      }
    }
  }
`;

export const GQL_DELETE_TAX = gql`
  mutation deleteTax($_id: MongoID!) {
    taxRemoveById(_id: $_id) {
      recordId
    }
  }
`;

export const GQL_TAX_PAGINATION = gql`
  query TaxPagination(
    $filter: FilterFindManySearchTaxInput
    $sort: SortFindManyTaxInput
    $perPage: Int
    $page: Int
  ) {
    taxPagination(
      filter: $filter
      sort: $sort
      perPage: $perPage
      page: $page
    ) {
      items {
        ...TaxListFragment
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${GQL_TAX_LIST_FRAGMENT}
`;
