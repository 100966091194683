// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import {Button, Dialog, DialogActions, DialogContent, Grid, IconButton, Link as MuiLink, makeStyles, Typography} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, {useCallback, useContext, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {HoopsTextField} from '../../../componentsOld/HoopsTextField';
import {toFloat} from '../../../componentsOld/shared/numberUtils';
import {itemBuilder} from '../../../ui/theme/itemBuilder';
import {ItemBuilderContext} from '../ItemBuilderContext';

const useStyles = makeStyles((theme) => ({...itemBuilder,}));

const DecorationOverrideDialog = ({
    onClose = () => null,
    open = false,
    rowIndex = null,
}) => {

    const classes = useStyles();
    const [overrideValue, setOverrideValue] = useState(null);

    const {watch, setValue, register, errors, setError, clearErrors} = useFormContext();
    const {onPriceChanged} = useContext(ItemBuilderContext);
    const priceElement = watch();
    const decorationCosts = priceElement && priceElement.decorationCosts;

    const handleDelete = useCallback(() => {
        const existingOverride = toFloat(decorationCosts[rowIndex]?.priceBreakQuantityOverride);
        // if an override exists reverse the setup change
        if (existingOverride > 0) {
            const overrideSetupPrice = (toFloat(decorationCosts[rowIndex].setupPrice) * toFloat(decorationCosts[rowIndex]?.priceBreakQuantityOverride)) / toFloat(decorationCosts[rowIndex].quantity);
            setValue(`decorationCosts[${rowIndex}].setupPrice`, overrideSetupPrice);
        }

        setValue(`decorationCosts[${rowIndex}].priceBreakQuantityOverride`, null);
        clearErrors('overrideField');
        onClose();
    }, [clearErrors, decorationCosts, onClose, rowIndex, setValue]);

    const handleSave = useCallback(() => {

        const existingOverride = toFloat(decorationCosts[rowIndex]?.priceBreakQuantityOverride);
        let correctedSetupPrice = null;

        // validate the input before saving
        if (toFloat(overrideValue) < toFloat(decorationCosts[rowIndex].quantity) && toFloat(overrideValue) > 0) {
            setError('overrideField', {
                type: 'manual',
                message: 'The override must be higher than the current quantity'
            });
        } else if (toFloat(overrideValue) === 0) {
            handleDelete();
            onPriceChanged();
        } else {
            setValue(`decorationCosts[${rowIndex}].priceBreakQuantityOverride`, overrideValue);

            // correct the setup price before adjusting it
            if (existingOverride > 0) {
                correctedSetupPrice = (toFloat(decorationCosts[rowIndex].setupPrice) * toFloat(decorationCosts[rowIndex]?.priceBreakQuantityOverride)) / toFloat(decorationCosts[rowIndex].quantity);
            }

            const overrideSetupPrice = ((correctedSetupPrice ? correctedSetupPrice : toFloat(decorationCosts[rowIndex].setupPrice)) / toFloat(overrideValue)) * toFloat(decorationCosts[rowIndex].quantity);
            setValue(`decorationCosts[${rowIndex}].setupPrice`, overrideSetupPrice);

            clearErrors('overrideField');
            onPriceChanged();
            onClose();
        }
    }, [clearErrors, decorationCosts, handleDelete, onClose, onPriceChanged, overrideValue, rowIndex, setError, setValue]);

    return (
        <Dialog onClose={onClose} open={open}>
            <DialogContent>
                <Grid container justifyContent={'space-between'}>
                    <Grid item>
                        <Typography variant='h4'>
                            Override Decoration Quantity
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton color='inherit' onClick={onClose} aria-label='close'>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Typography variant='caption' className={classes.greyText}>
                    <p>If you are adding the same decoration to multiple items on this quote, use this tool to allow you to set the total quantity of the decoration.</p>
                    <p>This will: <br />
                        - Spread the setup cost evenly across the total quantity<br />
                        - Get the decoration price based on the total quantity (if this decoration has multiple price breaks)<br />
                    </p>
                </Typography>
                <Typography variant='caption' color='primary'>
                    <MuiLink color={'primary'} underline={'none'} href={'http://help.hoopscrm.com/en/articles/'} target='_blank'>Learn more</MuiLink>
                </Typography>
                <Grid container direction='row' justifyContent={'space-between'} alignItems='center'>
                    <Grid item>
                        <Typography variant='body2'>
                            Enter Total Quantity for the Decoration
                        </Typography>
                    </Grid>
                    <Grid item>
                        <HoopsTextField
                            type='number'
                            defaultValue={rowIndex !== null && decorationCosts && decorationCosts[rowIndex]?.priceBreakQuantityOverride}
                            onChange={(e) => setOverrideValue(e.target.value)}
                            inpurRef={register}
                            error={errors.overrideField}
                            autoFocus
                            onFocus={(event) => {
                                event.target.select();
                            }}
                            onKeyPress={(ev) => {
                                if (open && ev.key === 'Enter') {
                                    handleSave();
                                }
                            }}
                        />
                        {errors.overrideField && <div className={classes.error}>{errors.overrideField.message}</div>}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent={'space-between'} style={{padding: 16}}>
                    <Grid item>
                        <Button
                            className={classes.greyText}
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={handleSave}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default DecorationOverrideDialog;
