import {get, pick} from 'lodash';
import {
  GQL_CREATE_PRODUCT_VARIANT, GQL_GET_CATALOG_LIST_PRODUCTS, GQL_GET_CATALOG_PRODUCTS,
  GQL_GET_FULL_PRODUCTS, GQL_LIST_PRODUCTS, GQL_UPDATE_PRODUCT_VARIANTS
} from '../queries/catalog';
import {
  GQL_ACTIVE_CATALOG_IMPORT, GQL_CANCEL_CATALOG_IMPORT,
  GQL_CATALOG_IMPORT, GQL_CATALOG_IMPORTS, GQL_SHARE_CATALOG_IMPORT, GQL_START_CATALOG_IMPORT, GQL_UNDO_CATALOG_IMPORT
} from '../queries/catalogImport';
import {
  GQL_BULK_UPDATE_PRODUCT_VARIANTS, GQL_CACHE_PRODUCT, GQL_CREATE_PRODUCT,
  GQL_DELETE_PRODUCT,
  GQL_GET_PRODUCT,
  GQL_GET_PRODUCT_WITH_VARIANTS_TABLE,
  GQL_UPDATE_PRODUCT,
  GQL_UPDATE_PRODUCT_COLOR
} from '../queries/product';
import {createGraphQLClient} from './GraphQLService';
import {toFloat} from './ItemBuilderServices';

const client = createGraphQLClient({});

function parseCatalogImport({
  vendor: {_id: vendorId},
  description,
  file: {file},
  imageFile,
  fieldMapping,
  dataStructure,
  groupBy,
  noImages
}) {
  const variantKeys = [
    'sku',
    'colors',
    'size',
    'variantImage',
  ];
  const targetKeys = Object.keys(fieldMapping)
    .filter((key) => !variantKeys.includes(key))
    .filter((key) => key !== 'priceBreaks');

  const productFieldMap = targetKeys.map((target) => ({
    target,
    source: fieldMapping[target]
  }));

  const variantFieldMap = variantKeys
    .filter((key) => Boolean(fieldMapping[key]))
    .map((target) => ({
      target,
      source: fieldMapping[target]
    }));

  const priceBreaksMap = get(fieldMapping, 'priceBreaks', []);
  return {
    dataStructure,
    description,
    groupBy,
    vendorId,
    csvFile: file?._id,
    imageZipFile: imageFile?._id,
    productFieldMap,
    variantFieldMap,
    priceBreaksMap,
    noImages
  };
}

export default class CatalogService {

  static shareImport(importId, companyIds) {
    return client.mutate({
      mutation: GQL_SHARE_CATALOG_IMPORT,
      variables: {
        _id: importId,
        companyIds
      }
    })
      .then(({data: {addImageFile}}) => addImageFile);
  }

  static getActiveImport() {
    return client.query({
      query: GQL_ACTIVE_CATALOG_IMPORT,
      fetchPolicy: 'no-cache'
    })
      .then(({data: {activeCatalogImport}}) => activeCatalogImport);
  }

  static createPendingImport(data) {
    return client.mutate({
      mutation: GQL_CATALOG_IMPORT,
      variables: {data: parseCatalogImport({vendor: {}, file: {}, ...data})}
    })
      .then(({data: {catalogImport}}) => catalogImport);
  }

  static updatePendingImport(_id, data) {
    return client.mutate({
      mutation: GQL_CATALOG_IMPORT,
      variables: {
        _id,
        data: parseCatalogImport({vendor: {}, file: {}, ...data})
      }
    })
      .then(({data: {catalogImport}}) => catalogImport);
  }

  static processImport(_id, data) {
    return client.mutate({
      mutation: GQL_START_CATALOG_IMPORT,
      variables: {
        _id,
        catalogImport: (data ? parseCatalogImport({vendor: {}, file: {}, ...data}) : undefined)
      }
    })
      .then(({data: {startCatalogImport}}) => startCatalogImport);
  }

  static createProduct(data) {
    return client.mutate({
      mutation: GQL_CREATE_PRODUCT,
      variables: {product: CatalogService._parseProductCreateData(data)}
    });
  }

  static getProducts(q, page = 1, pageLimit = 24) {
    return client.query({
      query: GQL_GET_CATALOG_PRODUCTS,
      variables: {
        q,
        page,
        pageLimit
      },
      fetchPolicy: 'no-cache',
    });
  }

  static getProductsForCatalogList(q, page = 1, pageLimit = 24, noPopulate = true) {
    return client.query({
      query: GQL_GET_CATALOG_LIST_PRODUCTS,
      variables: {
        q,
        page,
        pageLimit,
        noPopulate
      },
      fetchPolicy: 'no-cache',
    });
  }

  static getFullProducts(q, page = 1, pageLimit = 24) {
    return client.query({
      query: GQL_GET_FULL_PRODUCTS,
      variables: {
        q,
        page,
        pageLimit
      },
      fetchPolicy: 'no-cache',
    });
  }

  static getProduct(_id) {
    return client.query({
      query: GQL_GET_PRODUCT,
      variables: {_id},
      fetchPolicy: 'no-cache'
    });
  }

  static cacheProduct(catalog, _id) {
    return client.query({
      query: GQL_CACHE_PRODUCT,
      variables: {
        catalog,
        _id
      },
      fetchPolicy: 'no-cache'
    });
  }

  static listProducts(catalog, search, page = 1, pageSize = 8) {
    return client.query({
      query: GQL_LIST_PRODUCTS,
      variables: {
        catalog,
        search,
        page,
        pageSize
      },
      fetchPolicy: 'no-cache'
    });
  }

  static getProductWithVariantsTable(_id) {
    return client.query({
      query: GQL_GET_PRODUCT_WITH_VARIANTS_TABLE,
      variables: {_id},
      fetchPolicy: 'no-cache'
    });
  }

  static updateProduct(_id, data) {
    data = this._parseProductUpdateData(data);
    return client.mutate({
      mutation: GQL_UPDATE_PRODUCT,
      variables: {
        _id,
        data
      }
    });
  }

  static updateProductColor(_id, {image, name, productId}) {
    return client.mutate({
      mutation: GQL_UPDATE_PRODUCT_COLOR,
      variables: {
        _id,
        data: {
          image,
          name,
          productId,
        }
      }
    });
  }

  static updateProductVariants(_id, data) {
    data = this._parseVariants(data);
    return client.mutate({
      mutation: GQL_UPDATE_PRODUCT_VARIANTS,
      variables: {
        _id,
        data
      }
    });
  }

  static createProductVariant(productId, data) {
    const createVariantData = this._parseProductVariant(data);
    return client.mutate({
      mutation: GQL_CREATE_PRODUCT_VARIANT,
      variables: {
        productId,
        createVariantData
      }
    });
  }

  static deleteProduct(_id) {
    return client.mutate({
      mutation: GQL_DELETE_PRODUCT,
      variables: {_id}
    });
  }

  static bulkUpdateProductVariants(productId, data) {
    return client.mutate({
      mutation: GQL_BULK_UPDATE_PRODUCT_VARIANTS,
      variables: {
        productId,
        variants: this._parseBulkUpdateProductVariants(data)
      }
    });
  }

  static getCatalogImports() {
    return client.query({
      query: GQL_CATALOG_IMPORTS,
      fetchPolicy: 'no-cache'
    })
      .then(({data: {catalogImports}}) => catalogImports);
  }

  static undoCatalogImport(_id) {
    return client.mutate({
      mutation: GQL_UNDO_CATALOG_IMPORT,
      variables: {_id}
    });
  }

  static cancelCatalogImport(_id) {
    return client.mutate({
      mutation: GQL_CANCEL_CATALOG_IMPORT,
      variables: {_id}
    });
  }

  static _parseBulkUpdateProductVariants(data) {
    return data.variants.map((variant) =>
    ({
      colorId: variant.color._id,
      sizeId: variant.size._id,
      priceBreaks: variant.priceBreaks
    })
    );
  }

  static _parseProductUpdateData(data) {
    return {
      ...this._parseProductToFormData(data),
      primaryImage: get(data, 'primaryImage'),
      vendorId: get(data, 'vendor._id')
    };
  }

  static _parseProductCreateData = (data) => {
    let product = pick(data, [
      'title',
      'code',
      'description',
      'vendorId',
      'colors',
      'sizes'
    ]);
    product.priceBreaks = data.priceBreaks
      .map(({price, quantity}) => ({
        price: toFloat(price, 0),
        quantity: toFloat(quantity, 0)
      }));
    product.primaryImageId = get(data, 'primaryImage._id');
    return product;
  };

  static _parseProductToFormData(data) {
    const productData = pick(data, [
      'title',
      'code',
      'description',
      'brand',
      'category',
      'subCategory',
      'url',
      'primaryPriceDescription',
      'materials',
      'decorationsAvailable',
      'decorationAreas',
      'stockIndent',
      'cartonSize',
      'cartonWeight',
      'qtyPerCarton',
      'cartonCubic',
      'cartonNotes',
      'freightDescription',
      'individualProductPackaging',
      'tags'
    ]);

    if (productData.qtyPerCarton) {productData.qtyPerCarton = parseInt(productData.qtyPerCarton);}
    return productData;
  }

  static _parseVariants(variants) {
    return variants;
  }

  static _parseProductVariant(data) {
    const colors = data.colors.map(({_id, name}) => ({_id, name}));
    const sizes = data.sizes.map(({_id, name}) => ({_id, name}));
    const priceBreaks = data.priceBreaks.map((priceBreak) => ({
      quantity: parseFloat(priceBreak.quantity),
      price: parseFloat(priceBreak.price)
    }));
    return {
      colors,
      sizes,
      priceBreaks
    };
  }
}
