import {gql} from 'apollo-boost';

export const GQL_GCFILE_FRAGMENT = gql`
    fragment GCFileFragment on GCFile {
        _id
        filename
        url
        description
        createdAt
        createdById
        createdBy {
            username
            firstName
            lastName
        }
        
        bucket
        key
        contentType
        contentLength
        companyId
        isWorkable
        entities
        status
    }
`;

export const GQL_FILE_FRAGMENT = gql`
    fragment FileFragment on File {
        _id
        filename
        url
        description
        createdAt
        createdById
        createdBy {
            username
            firstName
            lastName
        }
        
        bucket
        key
        contentType
        contentLength
        company {
            companyTradingEntities {
                _id
                name
            }
        }
        companyId
        customerUpload
        isWorkable
        entities
        status
    }
`;

export const GQL_GET_FILES = gql`
    ${GQL_FILE_FRAGMENT}
    query getFiles($entityIds: [String], $isWorkable: Boolean, $customerId: String, $filter: String, $limit: Int, $isProof: Boolean) {
        files(entityIds: $entityIds, isWorkable: $isWorkable, customerId: $customerId, filter: $filter, limit: $limit, isProof: $isProof) {
            ...FileFragment
        }
    }
`;

export const GQL_CREATE_FILE = gql`
    ${GQL_FILE_FRAGMENT}
    mutation createFile($file: FileInput!) {
        createFile(data: $file) {
            ...FileFragment
        }
    }
`;

export const GQL_UPDATE_FILE = gql`
    ${GQL_FILE_FRAGMENT}
    mutation updatefile($_id: String!, $file: FileUpdateInput!) {
        updateFile(_id: $_id, data: $file) {
            ...FileFragment
        }
    }
`;

export const GQL_DELETE_FILE = gql`
    mutation deleteFile($_id: String!) {
        deleteFile(_id: $_id) {
            _id
        }
    }
`;
