// TODO: fix eslint disable
/* eslint-disable no-class-assign, */

import React, {Fragment} from 'react';
import {getVendor, setVendor} from '../../actions/action-types';
import {connect} from 'react-redux';
import {Grid, Typography, withStyles, IconButton} from '@material-ui/core';
import {Link, withRouter} from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import IndividualContact from '../contacts/individualContact';
import IndividualAddress from '../address/individualAddress';
import VendorDialog from './VendorDialog';
import HoopsDataPanel from '../shared/HoopsDataPanel';
import VendorOptions from './VendorOptions';

const styles = (theme) => ({grey: {color: theme.colors.grey.main}});

class Vendor extends React.Component {
  state = {editModalOpen: false};

  UNSAFE_componentWillMount() {
    const {dispatch, match} = this.props;

    dispatch(getVendor(match.params._id));
  }

  openEditModal = () => {
    const {dispatch} = this.props;
    dispatch(setVendor(this.props.vendor));
    this.setState({editModalOpen: true, anchorEl: null});
  };

  editModalHandleClose = () => {
    const {dispatch, match} = this.props;
    dispatch(getVendor(match.params._id));
    this.setState({editModalOpen: false});
  };

  componentDidMount() {
    document.title = 'View Vendor | Hoops';
  }

  render() {
    const {classes, vendor, company} = this.props;
    return (
      <Fragment>
        <Grid container
              justifyContent={'space-between'}
              alignItems={'center'}
              spacing={4}
        >
          <Grid item>
            <Grid container spacing={2} alignItems={'center'}>
              <Grid item>
                <Link to={'/settings/vendors'}
                      style={{textDecoration: 'none'}}
                >
                  <IconButton><Icon>arrow_back</Icon></IconButton>
                </Link>
              </Grid>
              <Grid item>
                <h3>View Vendor</h3>
              </Grid>
            </Grid>
          </Grid>
          {company._id === vendor.companyId ? <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <VendorOptions handleEditClick={this.openEditModal}/>
              </Grid>
            </Grid>

            <VendorDialog data={vendor} isOpen={this.state.editModalOpen} onClose={this.editModalHandleClose}/>
          </Grid> : null}
        </Grid>

        <Grid container spacing={4} direction={'row'}>
          <Grid item xs={7}>
            <Grid container direction={'column'} spacing={4}>
              <Grid item>
                <HoopsDataPanel title='Vendor' icon={'business'}>
                  <Grid container item xs={12} spacing={4}>
                    <Grid item xs={6}>
                      <Typography className={classes.grey}>Vendor Name</Typography>
                      <Typography>{vendor.name}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.grey}>Vendor Email</Typography>
                      <Typography>{vendor.email}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.grey}>Orders Website</Typography>
                      {vendor.ordersSite && <Typography>{vendor.ordersSite.website}</Typography>}
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.grey}>Username</Typography>
                      {vendor.ordersSite && <Typography>{vendor.ordersSite.username}</Typography>}
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.grey}>Password</Typography>
                      {vendor.ordersSite && <Typography>{vendor.ordersSite.password}</Typography>}
                    </Grid>
                  </Grid>
                </HoopsDataPanel>
              </Grid>
              <Grid item>
                <HoopsDataPanel title={'Address'} icon={'place'}>
                  <Grid container spacing={4}>
                    {vendor.addresses && vendor.addresses.length === 0 && (
                      <Grid item>
                        <Typography className={classes.grey} component={'span'}>
                          {vendor.name} has no stored addresses
                        </Typography>
                      </Grid>
                    )}
                    {vendor.addresses && vendor.addresses.map((address, index) => (
                        <IndividualAddress key={index} address={address}/>
                      ))}
                  </Grid>
                </HoopsDataPanel>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <HoopsDataPanel title={'Contacts'} icon={'person'}>
              <Grid container spacing={4}>
                {vendor.contacts.length === 0 && (
                  <Grid item>
                    <Typography className={classes.grey} component={'span'}>
                      {vendor.name} has no stored contacts
                    </Typography>
                  </Grid>
                )}
                {vendor.contacts.map((contact, index) => (
                    <IndividualContact key={index} contact={contact}/>
                  ))}
              </Grid>
            </HoopsDataPanel>
          </Grid>
        </Grid>
      </Fragment>
    );
  }

}

function mapStateToProps(state) {
  return {
    vendor: state.vendorReducer.vendor,
    company: state.companyReducer.company,
  };
}

Vendor = connect(mapStateToProps)(Vendor);
Vendor = withRouter(Vendor);
export default withStyles(styles)(Vendor);
