import {Popover} from '@material-ui/core';
import React, {useState} from 'react';
import {BlockPicker} from 'react-color';
import reactCSS from 'reactcss';
import {colorsArray} from '../../constants/colors';

export default ({color = '#00ccf0', onChange = () => null}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [_color, setColor] = useState(color);

  const handleChange = ({hex}) => {
    setColor(hex);
    onChange(hex);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const styles = reactCSS({
    'default': {
      swatch: {
        background: `${_color}`,
        borderRadius: '100%',
        width: '24px',
        height: '24px',
        display: 'inline-block',
        cursor: 'pointer',
      }
    },
  });

  const open = Boolean(anchorEl || false);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <div style={styles.swatch} onClick={handleClick} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <BlockPicker
          color={_color}
          onChange={handleChange}
          colors={colorsArray}
          styles={{default: {label: {color: '#fff'}}}}
          />
      </Popover>
    </>
  );
};

/*
card: CSSProperties;
    head: CSSProperties;
    body: CSSProperties;
    label: CSSProperties;
    triangle: CSSProperties;
    input: CSSProperties;*/
