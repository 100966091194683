import {Grid, Typography} from '@material-ui/core';
import {withStyles, withTheme} from '@material-ui/core/styles';
import Person from '@material-ui/icons/Person';
import {get} from 'lodash';
import * as React from 'react';
import {CustomerService} from '../../../../servicesOld/CustomerService';
import CardContainer from '../../../common/CardContainer';

const _JobCustomerCard = ({job, theme, classes, button, loading = false, footer = null}) => {
  const customer = get(job, 'customer', {});
  const contact = get(job, 'contact', {});
  const customerName = `${get(customer, 'name', '')} ${get(customer, 'deletedAt', '') ? '(Deleted)' : ''}`;
  return (
    <>
        <CardContainer
          data-intercom-target={'job-details-customer-panel'}
          title='Customer Information'
          icon={<Person />}
          iconBackgroundColor={theme.palette.primary.main}
          buttonComponent={button}
          loading={loading}
        >
          <Grid container className={classes.parentContainer}>
            <Grid container item className={classes.detailRow} style={{marginBottom: '24px'}} spacing={2}>
              <Grid item className={classes.detail} xs={3}>
                <Typography className={classes.detailTitle}>Company</Typography>
                <Typography className={classes.detailContent}>{customerName}</Typography>
              </Grid>
              <Grid item className={classes.detail} xs={3}>
                <Typography className={classes.detailTitle}>Contact Name</Typography>
                <Typography className={classes.detailContent}>{contact && contact.fullName}</Typography>
              </Grid>
              <Grid item className={classes.detail} xs={3}>
                <Typography className={classes.detailTitle}>Email</Typography>
                <Typography className={classes.detailContent}>{get(contact, 'email', '')}</Typography>
              </Grid>
            </Grid>
            <Grid container item className={classes.detailRow} spacing={2}>
              <Grid item className={classes.detail} xs={3}>
                <Typography className={classes.detailTitle}>Contact Number</Typography>
                <Typography className={classes.detailContent}>{get(contact, 'phone', '')}</Typography>
              </Grid>
              <Grid item className={classes.detail} xs={3}>
                <Typography className={classes.detailTitle}>Billing Address</Typography>
                <Typography className={classes.detailContent}>
                  {job?.billTo?.formattedAddress || CustomerService.getAddressByLabel(customer, 'BILLING')}
                </Typography>
              </Grid>
              <Grid item className={classes.detail} xs={3}>
                <Typography className={classes.detailTitle}>Shipping Address</Typography>
                <Typography className={classes.detailContent}>
                  {job?.shipTo?.formattedAddress || CustomerService.getAddressByLabel(customer, 'SHIPPING')}
                </Typography>
              </Grid>
            </Grid>
            {!!footer && <Grid container item className={classes.detailRow} spacing={2}>
              <Grid item className={classes.detail} xs={12}>
                {footer}
              </Grid>
            </Grid>}
          </Grid>
        </CardContainer>
    </>

  );
};

export const styles = (theme) => ({
  parentContainer: {padding: `0px ${theme.spacing(6)}px`},
  detailRow: {},
  detail: {width: '200px'},
  detailTitle: {color: theme.colors.grey.main},
  detailContent: {
    fontSize: '16px',
    marginTop: '5px',
    wordBreak: 'break-word'
  },
});

const _JobCustomerCardWithStyle = withStyles(styles, {withTheme: true})(_JobCustomerCard);

export default withTheme(_JobCustomerCardWithStyle);
