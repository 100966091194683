
export function scrollIntoView(element, options) {
  // Have to find the rectangle of the element with the scroll bar to determine visibility
  let scrollParent = element.parentElement;
  for (; scrollParent; scrollParent = scrollParent.parentElement) {
    if (scrollParent.scrollHeight > scrollParent.clientHeight) {
      break;
    }
  }
  if (scrollParent) {
    const rect = element.getBoundingClientRect();
    const scrollRect = scrollParent.getBoundingClientRect();
    if (rect && scrollRect) {
      if (rect.top < scrollRect.top) {
        element.scrollIntoView(options ?? true);
      } else if (rect.bottom > scrollRect.bottom) {
        element.scrollIntoView(options ?? false);
      }
    }
  }
}
