import {createGraphQLClient} from './GraphQLService';
import {
  GQL_ACCEPT_USER_INVITE,
  GQL_DELETE_USER,
  GQL_GET_USER_BY_EXTERNAL_ID,
  GQL_GET_USER_BY_ID,
  GQL_GET_USERS,
  GQL_UPDATE_USER_ROLE,
  GQL_INVITE_USER,
  GQL_CHANGE_PASSWORD,
  GQL_GET_USER_INTERCOM_ATTRIBUTES
} from '../queries/users';
import {trim} from 'lodash';

const client = createGraphQLClient({});

class UserService {
  static getUsers(options) {
    return client.query({
      ...options,
      query: GQL_GET_USERS
    });
  }

  static getUserIntercomAttributes() {
    return client.query({query: GQL_GET_USER_INTERCOM_ATTRIBUTES});
  }

  static updateUserRole(user, role) {
    const {_id} = user;
    return client.mutate({
      mutation: GQL_UPDATE_USER_ROLE,
      variables: {
        _id,
        role: role
      }
    });

  }

  static deleteUser(_id) {
    return client.mutate({
      mutation: GQL_DELETE_USER,
      variables: {_id}
    });
  }

  static inviteUser(user) {
    const payload = {
      email: trim(user.email),
      fullName: trim(user.fullName),
      role: user.role,
    };

    return client.mutate({
      mutation: GQL_INVITE_USER,
      variables: {user: payload}
    });
  }

  static acceptInvite(user) {
    const {_id, username: email, fullName, password} = user;
    return client.mutate({
      mutation: GQL_ACCEPT_USER_INVITE,
      variables: {
        _id,
        user: {
          fullName,
          email,
          password
        }
      }
    });
  }

  static getUserByExternalId(externalId) {
    return client.query({
      query: GQL_GET_USER_BY_EXTERNAL_ID,
      variables: {externalId}
    })
      .then(({data: {userByExternalId}}) => userByExternalId);
  }

  static getUserByIds(_ids) {
    return client.query({
      query: GQL_GET_USER_BY_ID,
      variables: {_ids}
    })
      .then(({data: {userByIds}}) => userByIds);
  }

  static changePassword(newPassword) {
    return client.mutate({
      mutation: GQL_CHANGE_PASSWORD,
      variables: {newPassword}
    });
  }

}

export default UserService;
