// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-shadow */

import {InputAdornment} from '@material-ui/core';
import React, {useEffect} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {HoopsTextField} from '../../../componentsOld/HoopsTextField';
import {convertMarginToMarkup} from '../../../componentsOld/shared/hoopsUtils';
import {decimalPlaces, toFloat} from '../../../componentsOld/shared/numberUtils';
import priceLogicValues from '../itemLogicValues';

const SellPriceField = ({
    index = null,
    field = {},
    name = '',
    currencySymbol,
    classes,
    ...params
}) => {
    const {watch, control, setValue} = useFormContext();

    const quantity = watch(`decorationCosts[${index}].quantity`);
    const buyPrice = watch(`decorationCosts[${index}].buyPrice`);
    const setupPrice = watch(`decorationCosts[${index}].setupPrice`);
    const markup = watch(`decorationCosts[${index}].markup`);
    const additionalCost = watch(`decorationCosts[${index}].additionalCost`);
    const priceLogicSelected = watch('priceLogic');
    const grossMarginTarget = watch('grossMarginTarget');
    const {grossMargin} = priceLogicValues;

    useEffect(() => {
        if (priceLogicSelected === grossMargin) {
            const markup = convertMarginToMarkup(grossMarginTarget);
            setValue(`decorationCosts[${index}].markup`, decimalPlaces(markup, 2));
        }
        const sellPrice = (toFloat(buyPrice) + toFloat(additionalCost) + (toFloat(setupPrice) / (quantity ? toFloat(quantity) : 1))) * (1 + (toFloat(markup) / 100));
        setValue(name, decimalPlaces(sellPrice, 2));
      // TODO: FIX HOOK DEPENDENCIES
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [additionalCost, markup, buyPrice, setupPrice, quantity, priceLogicSelected, grossMarginTarget]);

    return (
        <Controller
            name={name}
            defaultValue={field.sellPrice}
            render={(field) => (
                <HoopsTextField
                    type='number'
                    responsive
                    disabled
                    {...field}
                    InputProps={{startAdornment: <InputAdornment position={'start'}>{currencySymbol}</InputAdornment>}}
                    {...params}
                />
            )}
            control={control}
        />
    );
};

export default SellPriceField;
