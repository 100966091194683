
import {Grid, Paper, Typography} from '@mui/material';
import React from 'react';
import {EditableList} from '../../componentsLib/EditableList';

export const CompanyIndustryTypes = ({
  company,
  saveSettings = () => null
}) => {

  const handleCompanyEdit = (params) => {
    saveSettings({...company, ...params});
  };

  return (
    <Paper sx={{p: 3}}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant='h5'>Industry Types</Typography>
          <Typography variant='subtitle' >Create a list of industry types, which can be selected when creating a customer. This will be useful for reporting.</Typography>
        </Grid>
        <Grid item xs={12}>
          <EditableList
            data={company?.industryTypes}
            onChange={handleCompanyEdit}
            fieldName='Industries'
            fieldReference='industryTypes'
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
