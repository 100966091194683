// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-shadow */

import {gql, useLazyQuery} from '@apollo/client';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {debounce} from 'lodash';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {client} from '../../servicesOld/GraphQLService';

const selectVariables = {
  sort: 'NAME_ASC',
  limit: 8,
};

const GQL_CUSTOMER_MANY = gql`
  query CustomerSelect(
        $filter: FilterFindManySearchCustomerInput,
        $sort: SortFindManyCustomerInput,
        $limit: Int
    ) {
        customerMany(
            filter: $filter,
            sort: $sort,
            limit: $limit
        ) {
            _id
            name
        }
    }
`;

const GQL_CUSTOMER_ONE = gql`
  query CustomerSelect(
        $filter: FilterFindOneCustomerInput,
    ) {
        customerOne(
            filter: $filter,
        ) {
            _id
            name
        }
    }
`;

export default ({label, placeholder, value = null, ...props}) => {
  const [items, setItems] = useState([]);
  const [_value, _setValue] = useState(value);
  const [customer, setCustomer] = useState({});

  const [fetchCustomerMany] = useLazyQuery(
    GQL_CUSTOMER_MANY,
    {
      client,
      fetchPolicy: 'cache-and-network',
      onCompleted: ({customerMany}) => {
        setItems(customerMany);
      },
      onError: console.log
    }
  );

  const [fetchCustomerOne] = useLazyQuery(
    GQL_CUSTOMER_ONE,
    {
      client,
      fetchPolicy: 'cache-and-network',
      onCompleted: ({customerOne}) => {
        _setValue(customerOne);
        setCustomer(customerOne);
      },
      onError: console.log
    }
  );

  useEffect(() => {
    if (value) {
      fetchCustomerOne({variables: {filter: {_id: value},}});
      return;
    }

    fetchCustomerMany({variables: {...selectVariables,}});
  }, [fetchCustomerMany, value, customer, fetchCustomerOne]);

  const request = useMemo(
    () => debounce(
      (inputValue) => fetchCustomerMany({
          variables: {
            ...selectVariables,
            filter: {q: inputValue},
          },
        }), 300)
    , [fetchCustomerMany]);
  useEffect(() => () => {
      request.cancel();
    }, [request]);

  return (
    <Autocomplete
      limitTags={1}
      options={items}
      label={label}
      value={_value}
      autoHighlight
      getOptionLabel={(option) => option.name}
      getOptionSelected={(option, value) => option._id === value._id}
      onChange={(event, value) => {
        _setValue(value);
        props.onChange(value && value._id);
      }}
      onInputChange={(event, inputValue) => {
        request(inputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant='standard'
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
};
