import {Card, CardContent, CardMedia, Grid, makeStyles, Typography} from '@material-ui/core';
import {get, truncate} from 'lodash';
import React from 'react';
import DefaultProductImage from '../../../assets/images/product_placeholder.jpg';
import ConfiguredProductActionMenu from './ConfiguredProductActionMenu';

const useStyles = makeStyles((theme) => ({
  image: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    margin: '2px',
    height: '278px', // 4 * 5 ratio
  },
  contentContainer: {padding: `${theme.spacing(0.5)}px !important`,},
  productCode: {fontWeight: '500'},
  popper: {opacity: 1,},
  tooltip: {
    maxWidth: '450px',
    backgroundColor: 'transparent'
  },
}));

const ConfiguredProductCard = ({configuredProduct}) => {
  const classes = useStyles();

  return (
    <Card style={{height: '370px'}}>
      <CardContent classes={{root: classes.contentContainer}}>
        <Grid container className={classes.contentContainer}>
          <Grid item xs={12}><CardMedia
          image={get(configuredProduct, 'imageUrl', DefaultProductImage)}
          className={classes.image}
        /></Grid>
          <Grid item xs={12}>
            <Grid container wrap={'nowrap'} spacing={1} alignItems={'center'} justifyContent={'space-between'}>
              <Grid item>
                <Typography variant={'body2'}>
                  <span className={classes.productCode}> {get(configuredProduct, 'code', '')} </span> | {get(configuredProduct, 'name', '')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container justifyContent={'space-between'} alignItems={'center'}>
            <Grid item container>
              <Grid item container alignItems={'center'} xs={10}>
                <Typography variant={'subtitle2'} color={'primary'}>
                  {truncate(
                    get(configuredProduct, 'title', ''),
                    {length: 46}
                  )}
                </Typography>
              </Grid>
              <Grid item container alignItems={'flex-end'} xs={2}>
                <ConfiguredProductActionMenu configuredProduct={configuredProduct} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ConfiguredProductCard;

