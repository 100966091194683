// TODO: fix eslint disable
/* eslint-disable consistent-return, no-shadow */

import {Button, Dialog, Divider, Grid, Link, makeStyles, Typography} from '@material-ui/core';
import {clone, update} from 'lodash';
import React, {useEffect, useState} from 'react';
import Youtube from 'react-youtube';
import {DefaultMarkup} from './defaultMarkupClass';
import {MarkupRow} from './MarkupRow';
import MarkupService from './markupsService';
import {markupType} from './markupTypeValues';
import {ModalHeader} from './ModalHeader';
import {RootTree} from './RootTree';

const useStyles = makeStyles((theme) => ({
  header: {
    background: theme.colors.grey.ultraLight,
    padding: theme.spacing(3),
    overflowX: 'hidden'
  },
  content: {
    padding: theme.spacing(3),
    overflowX: 'hidden'
  },
  link: {
    display: 'inline-flex',
    lineHeight: '24px'
  },
  dialogContainer: {
    width: '854px',
    minWidth: '854px',
    height: '480px'
  }
}));

const MarkupsEditModal = ({
  modalIsOpen = null,
  markupToEdit = null,
  onSave = () => null,
  onClose = () => null,
}) => {
  const classes = useStyles();
  const [assignedMarkupData, setAssignedMarkupData] = useState(null);
  const [productTreeData, setProductTreeData] = useState(null);
  const [additionalCostTreeData, setadditionalCostTreeData] = useState(null);
  const [decorationTreeData, setDecorationTreeData] = useState(null);
  const [formValues, setFormValues] = useState(markupToEdit);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleVideoClick = (e) => {
    e.preventDefault();
    setIsDialogOpen(true);
  };

  const {PRODUCT, DECORATION, ADDITIONAL_COST} = markupType;

  const handleAddRow = () => {
    const markupArray = [...formValues.markupRanges];

    const newMarkup = clone(markupArray[formValues.markupRanges.length - 1]);
    newMarkup.quantity += 1;
    newMarkup.percentage = null;

    markupArray.push(newMarkup);
    const formatedMarkup = {markupRanges: markupArray};

    setFormValues((prev) => ({...prev, ...formatedMarkup}));
  };

  const handleDeleteRow = (e, rowIndex) => {
    const markupArray = [...formValues.markupRanges];

    markupArray.splice(rowIndex, 1);
    const formatedMarkup = {markupRanges: markupArray};

    setFormValues((prev) => ({...prev, ...formatedMarkup}));
    document.getElementById('markupForm').reset();
  };

  const handleMarkupChange = (e) => {
    const itemName = e.target.name;
    const itemValue = e.target.value;

    const clonedValues = {...formValues};
    update(clonedValues, itemName, () => itemValue);

    const updatedItem = clonedValues;

    setFormValues((prev) => ({...prev, ...updatedItem}));
  };

  useEffect(() => {
    MarkupService.getMarkupTree()
      .then((data) => {
        setProductTreeData(data.productsVendorBrandCategorySubcategoryTree);
        setDecorationTreeData(data.decorationsVendorBrandCategorySubcategoryTree);
        setadditionalCostTreeData(data.vendorMany);
        setAssignedMarkupData(data.defaultMarkupAssignmentFind);
      });
  }, []);

  const handleAssignedChange = (e, markupObj) => {

    const classyTreeBranch = new DefaultMarkup(markupObj, assignedMarkupData);
    const {isAssignedMarkup, isChildOfMarkup, parentAssignedMarkupArray, isChildOfMarkupObj} = classyTreeBranch;

    if (e.target.checked && !isAssignedMarkup) {
      setAssignedMarkupData((prev) => {
        let cloneArray = [...prev];
        if (parentAssignedMarkupArray) {
          parentAssignedMarkupArray.forEach((el) => {
            const removeChildren = new DefaultMarkup(el, cloneArray);
            cloneArray.splice(removeChildren.markupAssignedIndex, 1);
          });
        }
        return [...cloneArray, markupObj];
      });
    } else if (!e.target.checked && isChildOfMarkup) {
      handleAssignedDelete(e, isChildOfMarkupObj);
    } else {
      handleAssignedDelete(e, markupObj);
    }
  };

  const handleAssignedDelete = (e, markupObj, parentObj) => {

    const removeItem = new DefaultMarkup(markupObj, assignedMarkupData);
    const {markupAssignedIndex, parentAssignedMarkupArray, isParentOfMarkup} = removeItem;

    setAssignedMarkupData((prev) => {
      let cloneArray = [...prev];
      if (isParentOfMarkup) {
        let i = parentAssignedMarkupArray.length;
        while (i--) {
          const removeChildren = new DefaultMarkup(parentAssignedMarkupArray[i], cloneArray);
          if (parentAssignedMarkupArray[i].markupId === markupObj.markupId) {
            cloneArray.splice(removeChildren.markupAssignedIndex, 1);
          }
        }
      }
      if (parentObj) {
        const removeFromParent = new DefaultMarkup(parentObj, cloneArray);
        const {parentAssignedMarkupArray} = removeFromParent;
        let i = parentAssignedMarkupArray.length;
        while (i--) {
          if (parentAssignedMarkupArray[i].markupId === markupObj.markupId && parentAssignedMarkupArray[i].type === markupObj.type) {
            const removeChildren = new DefaultMarkup(parentAssignedMarkupArray[i], cloneArray);
            cloneArray.splice(removeChildren.markupAssignedIndex, 1);
          }
        }
      } else {
        cloneArray.splice(markupAssignedIndex, 1);
      }
      return cloneArray;
    });
  };

  return (
    <>
      <Dialog
        open={isDialogOpen}
        fullScreen={false}
        classes={{paper: classes.dialogContainer}}
        onClose={() => setIsDialogOpen(false)}
        si
      >
        <Youtube
          opts={{width: '854px', height: '480px'}}
          videoId='Mwm-yARSTcw'
        />
      </Dialog>
      <Dialog
        open={modalIsOpen}
        fullScreen
      >
        <form id='markupForm' onChange={handleMarkupChange}>
          <ModalHeader
            title={markupToEdit ? 'Edit Markup' : 'Add Markup'}
            helpArticleUrl='http://help.hoopscrm.com/en/articles/5716472-automatic-markups'
            onClose={onClose}
            onSave={(e) => onSave(e, formValues, assignedMarkupData)}
            formValues={formValues}
          />

          {/* Content */}
          <Grid
            container
            className={classes.content}
            spacing={3}
          >
            <Grid item xs={12}>
              <Typography variant='h5' gutterBottom>Create Markup</Typography>
            </Grid>

            <Grid item xs={12}>

              {formValues && formValues.markupRanges.map((row, rowIdx) => (
                <MarkupRow key={rowIdx} row={row} rowIdx={rowIdx} onDelete={handleDeleteRow}/>
              ))}

            </Grid>
            <Grid item xs={12}>
              <Button variant='text' color='primary' onClick={handleAddRow}>Add Another Row</Button>
            </Grid>
            <Grid item xs={12}>
              <Divider/>
            </Grid>
            <Grid item xs={12}>

              <Typography variant='h5' gutterBottom>Apply To Products / Decorations / Categories</Typography>
              <Link
                href='#'
                rel='noopener'
                target='_blank'
                className={classes.link}
                underline='none'
                onClick={handleVideoClick}
              >
                Learn More
              </Link>
            </Grid>
            <Grid item xs={12}>
              <RootTree
                treeRootTitle='All Products'
                markupType={PRODUCT}
                markup={markupToEdit}
                treeData={productTreeData}
                assignedMarkupData={assignedMarkupData}
                onChange={handleAssignedChange}
                onDelete={handleAssignedDelete}
              />
              <RootTree
                treeRootTitle='All Decorations'
                markupType={DECORATION}
                markup={markupToEdit}
                treeData={decorationTreeData}
                assignedMarkupData={assignedMarkupData}
                onChange={handleAssignedChange}
                onDelete={handleAssignedDelete}
              />
              <RootTree
                treeRootTitle='All Additional Costs'
                markupType={ADDITIONAL_COST}
                markup={markupToEdit}
                treeData={additionalCostTreeData}
                assignedMarkupData={assignedMarkupData}
                onChange={handleAssignedChange}
                onDelete={handleAssignedDelete}
              />
            </Grid>
          </Grid>

        </form>
      </Dialog>
    </>
  );
};

export default MarkupsEditModal;
