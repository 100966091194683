import React, {forwardRef, useCallback} from 'react';
import {GridFilterPanel as MuiGridFilterPanel,} from '@mui/x-data-grid-pro';
import {GridPanelWrapper} from '@mui/x-data-grid/components/panel/GridPanelWrapper';
import {GridPanelContent} from '@mui/x-data-grid/components/panel/GridPanelContent';
import {usePageContext} from '../../../componentsLib/Layout';
import {Checkbox} from '../../../componentsLib/Basic';
import {valueFromEvent} from '../../../utils';
import {registerGlobalStyle} from '../../../theme';

registerGlobalStyle('.custom-grid-filter-panel', () => ({'.checkbox .text-caption': {fontSize: '1rem'}}));

export const SalesDocGridFilterPanel = forwardRef(
function SalesDocGridFilterPanel(props, ref) {
  const {viewState} = usePageContext();

  const handleChangeShowArchived = useCallback((e) => {
    viewState.setParameter({archived: valueFromEvent(e) ? true : null});
  }, [viewState]);

  return (
    <GridPanelWrapper className={'custom-grid-filter-panel'} ref={ref} {...props}>
      <GridPanelContent>
        <MuiGridFilterPanel {...props}/>
        <Checkbox label={'Show Archived'} checked={viewState.currentView.parameters?.archived != null} onChange={handleChangeShowArchived}/>
      </GridPanelContent>
    </GridPanelWrapper>
  );
});
