// TODO: fix eslint disable
/* eslint-disable no-duplicate-imports, no-shadow */

import {Button, Grid, MuiThemeProvider} from '@material-ui/core';
import FolderIcon from '@material-ui/icons/Folder';
import React from 'react';
import CardContainer from '../../componentsOld/common/CardContainer';
import EmptyTable from '../../componentsOld/emptyTable';
import {colors} from '../../ui/theme';
import green from '../../ui/theme/green';
import FileListItem from './FileListItem';
import theme from '../../ui/theme';

function UploadButton({onClick}) {
  return (
    <>
      <MuiThemeProvider theme={green}>
        <Button
          onClick={onClick}
          variant={'contained'}
          color={'primary'}
        >
          Upload Files
        </Button>
      </MuiThemeProvider>
    </>
  );
}

function CustomerFileButton({onClick}) {
  return (

    <Button
      onClick={onClick}
      variant={'contained'}
      color={'primary'}
      style={{margin: theme.spacing(2)}}
    >
      Add Existing Customer File
    </Button>

  );
}

export const FileList = ({
  showAddButton = true,
  files = [],
  filePreviewOptions = {},
  fileActionOptions,
  selectable = false,
  selected = [],
  showCustomerBtn = false,
  showUploadFileBtn = false,
  showTitle = true,
  onSelect = () => null,
  onUploadExistingAction = () => null,
  onUploadAction = () => null,
  onEditAction = () => null,
  onDeleteAction = () => null,
  loading = false
}) => {

  const isSelected = (file) => selected.indexOf(file._id) !== -1;

  const handleSelect = (file, isSelected) => {
    const removed = selected.filter((id) => id !== file._id); // ensure no duplicates

    const result = isSelected
      ? [...removed, file._id]
      : removed;

    onSelect(result);
  };

  return (
    <CardContainer
      showTitle={showTitle}
      title={'Files'}
      icon={<FolderIcon />}
      iconBackgroundColor={colors.orange}
      buttonComponent={
        <>
          {showCustomerBtn && <CustomerFileButton onClick={onUploadExistingAction} />}
          {showUploadFileBtn && <UploadButton onClick={onUploadAction} />}
        </>
      }
    >
      <Grid container alignContent='center' justifyContent='center'>
        <Grid item xs={12} >
          {
            !files || !files.length ?
              <div style={{padding: '20px 0'}}>
                <EmptyTable
                  showAddButton={showAddButton}
                  field='File'
                  openDialog={onUploadAction}
                  loading={loading}
                />
              </div> :
              files.map((file) =>
                <FileListItem
                  key={file._id}
                  file={file}
                  selectable={selectable}
                  selected={isSelected(file)}
                  fileActionOptions={fileActionOptions}
                  filePreviewOptions={filePreviewOptions}
                  onSelect={(state) => handleSelect(file, state)}
                  onEditAction={onEditAction}
                  onDeleteAction={onDeleteAction}
                />
              )
          }
        </Grid>
      </Grid>
    </CardContainer>
  );
};

export default FileList;
