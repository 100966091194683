// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-shadow */

import {makeStyles} from '@material-ui/core/styles';
import React, {useEffect, useMemo, useState} from 'react';
import ProductAutocompleteRenderOption from './ProductAutocompleteRenderOption';
import ProductAutocompleteRenderOptionButton from './ProductAutocompleteRenderOptionButton';
import productAutocompleteStyles from './productAutocompleteStyles';
import {Autocomplete} from '@material-ui/lab';
import {CircularProgress, Paper, Popper, Typography} from '@material-ui/core';
import {HoopsTextField} from '../../HoopsTextField';
import {GQL_LIST_PRODUCTS} from '../../../queries/catalog';
import {useLazyQuery} from '@apollo/client';
import {useDebounce, useWatch} from '../../../hooks';

const useStyles = makeStyles(() => ({...productAutocompleteStyles}));

export const ItemBuilderProductAutocomplete = ({
  onSelect = () => null,
  isSageActive = false,
  defaultValue = null,
  productId = null,
  ...props
}) => {
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const classes = useStyles();

  const [getProducts, {loading, data}] = useLazyQuery(GQL_LIST_PRODUCTS);

  const getProductsDebouncer =
    useDebounce((value) => {
      getProducts({
        variables: {
          catalog: 'HOOPS',
          search: value,
          page: 1,
          pageSize: 25,
        }
      });
    }, [getProducts], 500);

  useEffect(() => {
    defaultValue && setInputValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    getProducts({
      variables: {
        catalog: 'HOOPS',
        search: inputValue,
        page: 1,
        pageSize: 25,
      }
    });
  }, [getProducts, inputValue]
  );

  const items = data?.listProducts?.items;
  useEffect(() => {
    if (items && !value) {
      const prod = items.find((item) => item._id === productId);
      if (prod) {
        setValue(prod);
      }
    }
  }, [items, productId, value]);

  return (
    <Autocomplete
      value={value}
      onChange={(e, newValue, reason) => {
        if (reason === 'reset' || reason === 'clear') {
          setInputValue('');
        } else {
          setInputValue(newValue?.title);
          setValue(newValue);
          onSelect(newValue);
        }
      }}
      inputValue={inputValue}
      onInputChange={(e, newInputValue, reason) => {
        if (reason === 'reset' || reason === 'clear') {
          setInputValue('');
        } else {
          setInputValue(newInputValue);
          getProductsDebouncer(newInputValue);
        }

      }}

      PopperComponent={({children, ...params}) => (
        <Popper
          {...params}
          placement={'bottom-start'}
          style={{width: 'auto'}}
        >
          {children}
        </Popper>
      )}
      PaperComponent={({children, ...paperProps}) => (
        <Paper {...paperProps}>
          <div className={classes.searchFeedbackPanel} >
            <Typography variant='body2'>{loading ? 'Searching  ' : 'Showing results for '}<strong>{inputValue}</strong></Typography>
            {loading && <CircularProgress size={20} />}
          </div>
          {children}
          <ProductAutocompleteRenderOptionButton />
        </Paper>
      )}
      renderOption={(option) => ProductAutocompleteRenderOption(option, classes)}

      classes={{inputRoot: `${(isSageActive)}`}}

      id='product-search'
      noOptionsText={'No results'}

      options={data?.listProducts.items ?? []}
      filterOptions={(options) => options}
      getOptionLabel={(option) => option?.title}
      getOptionSelected={(option, value) => (option?._id === value?._id)}
      fullWidth
      renderInput={(params) => <HoopsTextField {...params} />}
      {...props}
    />
  );
};
