import {Tooltip, Typography} from '@material-ui/core';
import React from 'react';

export const PurchaseOrderCheckboxToolTip = ({masterPurchaseOrderId, children}) => (
    <Tooltip
      placement='bottom'
      title={
        <div style={{width: 210, padding: 8}}>
          <Typography variant='caption' display='block' style={{marginBottom: '12px'}}>Use the merged purchase order to mark items as ordered/received. </Typography>
          <Typography variant='caption' display='block'>Actions on the merged purchase order will flow through to all child purchase orders.</Typography>
        </div>
      }
      disableFocusListener={!masterPurchaseOrderId}
      disableHoverListener={!masterPurchaseOrderId}
      disableTouchListener={!masterPurchaseOrderId}
      PopperProps={{
        popperOptions: {
          modifiers: {
            offset: {
              enabled: true,
              offset: '-40px, 0px',
            },
          },
        },
      }}
    >
      <div>{children}</div>
    </Tooltip>
  );

export default PurchaseOrderCheckboxToolTip;
