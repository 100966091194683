// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {makeStyles} from '@material-ui/core';
import {get} from 'lodash';
import React, {useCallback, useContext} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import ControlledHoopsAutocomplete from '../../../componentsOld/ControlledHoopsAutocomplete';
import DecorationAutocomplete from '../../../componentsOld/decorations/DecorationAutocomplete';
import {HoopsTextField} from '../../../componentsOld/HoopsTextField';
import {DecorationService} from '../../../servicesOld/DecorationService';
import {itemBuilder} from '../../../ui/theme/itemBuilder';
import {ItemBuilderContext} from '../ItemBuilderContext';
import {clearDecorationRow} from './decorationUtils';

const useStyles = makeStyles((theme) => ({
    ...itemBuilder,
    textField: {
        ['& fieldset']: {
            borderRadius: '0 4px 4px 0',
            borderLeftColor: theme.colors.white,
        },
    },
    hiddenTextField: {
        height: 0,
        margin: 0,
        display: 'flex',
        visibility: 'hidden',
        ['& fieldset']: {height: 0,},
    },
    selectField: {
        ['& fieldset']: {
            borderRadius: '0 4px 4px 0',
            borderLeftColor: theme.colors.white,
        },
    },
}));

const HoopsInput = ({rowIndex, control, field, setDecoration, linkedToProduct = false, decorationType}) => {
    const classes = useStyles();
    const textFieldProps = {
        margin: 'dense',
        variant: 'outlined',
        placeholder: 'Search Decorations',
        className: classes.selectField,
        inputProps: {
            margin: 'none',
            className: classes.inputClass,
        }
    };
    const {productDecorations} = useContext(ItemBuilderContext);

    const productIdArray = productDecorations?.reduce((ag, el) => {
        el?.productId && ag.push(el?.productId);
        return (ag);
    }, []) ?? [];

    const {onPriceChanged} = useContext(ItemBuilderContext);
    return (
        <ControlledHoopsAutocomplete
            name={`decorationCosts[${rowIndex}].decoration`}
            control={control}
            textFieldProps={textFieldProps}
            Component={(params) =>
                <DecorationAutocomplete
                    // When linkedToProduct is true we use the product id to filter for the linked decorations
                    productIdArray={linkedToProduct ? productIdArray : null}
                    hideButton={decorationType === 'product'}
                    {...params}
                />}
            defaultValue={field.decoration}
            onSelect={(d) => {
                setDecoration(d);
                onPriceChanged();
                return d;
            }}
            onChange={(d) => {
                setDecoration(d);
                onPriceChanged();
                return d;
            }}
        />
    );
};

const AdhocInput = ({rowIndex, control, decorationType, field}) => {
    const {onPriceChanged} = useContext(ItemBuilderContext);
    const classes = useStyles();
    return (
        <Controller
            name={`decorationCosts[${rowIndex}].description`}
            control={control}
            defaultValue={field.description}
            render={(field) => (
                <HoopsTextField
                    placeholder='Decoration Name'
                    style={{margin: 0}}
                    className={decorationType !== 'adhoc' ? classes.hiddenTextField : classes.selectField}
                    inputProps={{className: decorationType !== 'adhoc' ? classes.hiddenTextField : classes.inputClass}}
                    {...field}
                  onChange={(e) => {onPriceChanged(); field.onChange(e);}}
                />
            )}
        />
    );
};

const DecorationField = (props) => {
    const {rowIndex} = props;
    const {watch, setValue, getValues} = useFormContext();
    const decoration = watch(`decorationCosts[${rowIndex}]`);

    const clearValues = useCallback(() => {
        clearDecorationRow(rowIndex, setValue);
    }, [rowIndex, setValue]);

    if (!decoration) {
        return null;
    }
    const {decorationType} = decoration;

    const setDecoration = (value) => {
        const quantity = getValues('decorationCosts[0].quantity');
        const decoService = new DecorationService(value);

        if (!value || !value._id) {
            clearValues();
            return;
        }

        setValue(`decorationCosts[${rowIndex}].decoration`, value);
        setValue(`decorationCosts[${rowIndex}].decorationId`, value._id);
        setValue(`decorationCosts[${rowIndex}].name`, value.name);
        setValue(`decorationCosts[${rowIndex}].setupPrice`, value.setupPrice);
        setValue(`decorationCosts[${rowIndex}].buyPrice`, decoService.getPrice(quantity));
        setValue(`decorationCosts[${rowIndex}].vendorId`, get(value, 'vendor._id'));
        setValue(`decorationCosts[${rowIndex}].vendor`, value.vendor);

    };

    const handleDecorationField = (decorationType) => {
        switch (decorationType) {
            case 'hoops':
                return <HoopsInput {...props} setValue={setValue} setDecoration={setDecoration} decorationType={decorationType} />;
            case 'product':
                return <HoopsInput {...props} setValue={setValue} setDecoration={setDecoration} linkedToProduct={true} decorationType={decorationType} />;
            case 'adhoc':
                return <AdhocInput {...props} setValue={setValue} decorationType={decorationType} />;
            default:
                return <HoopsInput {...props} setValue={setValue} setDecoration={setDecoration} decorationType={decorationType} />;

        }
    };

    return (
        <>
            {decorationType && handleDecorationField(decorationType)}
        </>
    );
};

export default DecorationField;
