import React from 'react';
import {hoopsTheme, registerGlobalStyle} from '../../theme';
import {HoopsPropTypes} from '../utils';

registerGlobalStyle('.padded-box', () => ({
  padding: 'var(--padding)',
  width: '100%',
}));

export function PaddedBox({padding, children}) {
  if (Array.isArray(padding)) {
    padding = padding.map((v) => hoopsTheme.spacing(v)).join(' ');
  } else if (!padding) {
    padding = hoopsTheme.spacing(2);
  }

  return (
    <div className={'padded-box'} style={padding ? {'--padding': padding} : undefined}>{children}</div>
  );
}

PaddedBox.propTypes = {
  padding: HoopsPropTypes.oneOfType([HoopsPropTypes.string, HoopsPropTypes.arrayOf(HoopsPropTypes.stringOrNumber)]),
  children: HoopsPropTypes.children,
};
