// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-shadow */

import {useQuery} from '@apollo/client';
import {add} from 'date-fns';
import {DayPilot, DayPilotScheduler} from 'daypilot-pro-react';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {GQL_GET_SCHEDULES} from '../../../queries/schedules';
import {tasksToDayPilot, taskToDayPilot} from '../../task/task.class';
import './Scheduler.scss';
import {resourcesToDayPilot, schedulesToDayPilot, scheduleToDayPilot} from './utils';

const useImperativeQuery = (query) => {
  const {refetch} = useQuery(query, {skip: true});

  const imperativelyCallQuery = (variables) => refetch(variables);

  return imperativelyCallQuery;
};

export const Scheduler = ({
  config = {},
  startDate = null,
  resources = [],
  taskStatuses = [],
  scheduleStatuses = [],
  onScroll = () => null,
  onItemClick = () => null,
  onItemMoved = () => null,
  onTimeRangeClicked = () => null,
  onJobDrop = () => null
}) => {
  const [lastStart, setLastStart] = useState();
  const callQuery = useImperativeQuery(GQL_GET_SCHEDULES);

  const lastTaskSavedSelector = (state) => state.taskReducer.lastSaved;
  const lastScheduleSavedSelector = (state) => state.scheduleReducer.lastSaved;
  const lastScheduleRemovedSelector = (state) => state.scheduleReducer.lastRemoved;

  const lastTaskSaved = useSelector(lastTaskSavedSelector);
  const lastScheduleSaved = useSelector(lastScheduleSavedSelector);
  const lastScheduleRemoved = useSelector(lastScheduleRemovedSelector);

  const defaultOptions = {
    theme: 'scheduler_w_border',
    timeHeaders: [{'groupBy': 'Day'}, {'groupBy': 'Hour'}],
    scale: 'CellDuration',
    cellDuration: 15,
    days: 30,

    locale: 'en-us',
    viewType: 'Resources',
    showNonBusiness: false,
    businessWeekends: false,
    rowMinHeight: 50,
    timeRangeSelectedHandling: 'Enabled',
    eventDeleteHandling: 'Disabled',
    eventMoveHandling: 'Update',
    eventResizeHandling: 'Update',
    eventClickHandling: 'Select',
    eventHoverHandling: 'Disabled',

    dynamicLoading: true,
    infiniteScrollingEnabled: true,
    infiniteScrollingMargin: 200,
    infiniteScrollingStepDays: 10,
    dynamicEventRendering: false,
    onScroll: (args) => {
      // don't activate for vertical-only scrolling
      if (lastStart === args.viewport.start) {
        return;
      }
      else {
        setLastStart(args.viewport.start);
      }

      // these values are default since 2020.1.4276
      args.async = true;
      args.clearEvents = true;

      // load events in advance, +/- one month
      const startDate = args.viewport.start;
      const endDate = args.viewport.end;
      const startDateFilter = startDate.addMonths(-1).toDateLocal().toISOString();
      const endDateFilter = endDate.addMonths(1).toDateLocal().toISOString();

      callQuery({
        scheduleFilter: {
          AND: [
            {
              OR: [
                {
                  _operators: {
                    startAt: {
                      gt: startDateFilter,
                      lt: endDateFilter
                    }
                  }
                },
                {
                  _operators: {
                    endAt: {
                      gt: startDateFilter,
                      lt: endDateFilter
                    }
                  }
                },
                {
                  _operators: {
                    startAt: {lt: startDateFilter,},
                    endAt: {gt: endDateFilter}
                  }
                }
              ]
            },
            {_operators: {resourceId: {ne: null}}}
          ]
        },
        taskFilter: {
          AND: [
            {
              OR: [
                {
                  _operators: {
                    dueAt: {
                      gt: startDateFilter,
                      lt: endDateFilter
                    }
                  }
                },
                {
                  _operators: {
                    endAt: {
                      gt: startDateFilter,
                      lt: endDateFilter
                    }
                  }
                },
                {
                  _operators: {
                    dueAt: {lt: startDateFilter,},
                    endAt: {gt: endDateFilter}
                  }
                }
              ]
            },
            {_operators: {resourceId: {ne: null}}}
          ]
        }
      })
        .then(({data, loading, errors}) => {
          if (data && ref && ref.current && ref.current.control) {
            args.events = [
              ...schedulesToDayPilot(data.scheduleMany, scheduleStatuses),
              ...tasksToDayPilot(data.taskMany, taskStatuses),
            ];

            args.loaded();
          }
        });

        onScroll({
          startDate: startDate,
          endDate: endDate
        });
    },
  };

  const opts = {
    ...defaultOptions,
    ...config
  };

  const ref = React.useRef(null);

  /*
  Listen to changes to the external startDate so we can shift the scheduler
  */
  useEffect(
    () => {
      if (startDate && ref.current && ref.current.control) {
        const date = new DayPilot.Date(new Date(startDate), true);
        ref.current.control.scrollTo(date);
      }
    }, [startDate, ref]
  );

  const handleItemMovedOrResized = (ev) => {
    const {e: {data: {start, resource, entityData, entityType, end, text}}} = ev;

    const _entityData = {
      endAt: end.toDateLocal(),
      resourceId: resource,
    };

    const entityWrapper = {
      entityType,
      entityData: _entityData
    };

    switch (entityType) {
      case 'schedule':
        _entityData.startAt = start.toDateLocal();
        break;
      default:
      case 'task':
        _entityData.dueAt = start.toDateLocal();
        break;
    }

    if (ev.external) { // Dropped from Job List
      Object.assign(_entityData, {
        title: text,
        startAt: start.toDateLocal(),
        jobId: entityData._id
      });

      // Remove this temporary event, because we have a modal
      const eventAgain = ref.current.control.events.find(entityData._id);
      if (eventAgain) {
        ref.current.control.events.remove(eventAgain);
      }
    } else {
      Object.assign(_entityData, {_id: entityData._id});
    }

    ev.external
      ? onJobDrop(_entityData)
      : onItemMoved(entityWrapper);
  };

  const handleItemClick = ({e: {data: {entityType, entityData}}}) => {
    onItemClick({entityType, entityData});
  };

  const handleTimeRangeClicked = (ev) => {
    const {start, resource} = ev;
    const task = {
      allDay: false,
      dueAt: start.toDateLocal(),
      endAt: add(start.toDateLocal(), {minutes: 15}),
      resourceId: resource
    };

    onTimeRangeClicked(task);
  };

  const handleTimeRangeSelected = (ev) => {
    const {start, resource, end} = ev;
    const task = {
      allDay: false,
      dueAt: start.toDateLocal(),
      endAt: end.toDateLocal(),
      resourceId: resource
    };

    onTimeRangeClicked(task);
  };

  // this is so we can respond to updates made to tasks
  useEffect(() => {
    if (lastTaskSaved && ref.current && ref.current.control) {
      const {current: {control}} = ref;
      const event = control.events.find(lastTaskSaved._id);
      const newEvent = taskToDayPilot(lastTaskSaved, taskStatuses);

      // Add or Update
      if (!event) {
        control.events.add(newEvent);
      } else {
        const mergedEvent = Object.assign({}, event, newEvent);
        control.events.update(mergedEvent);
      }

      // Remove temporary event
      const tempEvent = control.events.find(lastTaskSaved.jobId);
      if (tempEvent) {
        control.events.remove(tempEvent);
      }

      // Scroll updated event into view
      const eventUpdated = control.events.find(lastTaskSaved._id); // can't use merged
      control.events.scrollIntoView(eventUpdated);
    }
  }, [lastTaskSaved, ref, taskStatuses]);

  // this is so we can respond to updates made to schedules
  useEffect(() => {
    if (lastScheduleSaved && ref.current && ref.current.control) {
      const {current: {control}} = ref;
      const event = control.events.find(lastScheduleSaved._id);
      const newEvent = scheduleToDayPilot(lastScheduleSaved, scheduleStatuses);

      // Add or Update
      if (!event) {
        control.events.add(newEvent);
      } else {
        const mergedEvent = Object.assign({}, event, newEvent);
        control.events.update(mergedEvent);
      }

      // Remove temporary event
      const tempEvent = control.events.find(lastScheduleSaved.jobId);
      if (tempEvent) {
        control.events.remove(tempEvent);
      }

      // Scroll updated event into view
      const eventUpdated = control.events.find(lastScheduleSaved._id); // can't use merged
      control.events.scrollIntoView(eventUpdated);
    }
  }, [lastScheduleSaved, ref, scheduleStatuses]);

  useEffect(
    () => {
      if (lastScheduleRemoved && ref.current && ref.current.control) {
        const {current: {control}} = ref;
        const event = control.events.find(lastScheduleRemoved);

        if (event) {
          control.events.remove(event);
        }
      }
    },
    [lastScheduleRemoved, ref]
  );

  return (
    <DayPilotScheduler
      {...opts}
      ref={ref}
      resources={resourcesToDayPilot(resources)}
      onEventClick={handleItemClick}
      onEventMoved={handleItemMovedOrResized}
      onTimeRangeClicked={handleTimeRangeClicked}
      onTimeRangeSelected={handleTimeRangeSelected}
      onEventResized={handleItemMovedOrResized}
    />
  );
};

export default Scheduler;
