import React from 'react';
import {
  AutoCompleteContainer,
  AutoCompleteList,
  AutoCompleteListItem,
  AutoCompletePopover,
  AutoCompleteValueContainer,
} from '../Popovers';
import {HoopsPropTypes} from '../utils';
import {BodyText} from '../Text';
import classNames from 'classnames';

export function Select({className, emptyList, initialOpen, label, loading, placeholder, placement, value, onChange, onClose, children}) {
  // Find the element that has the current value, if any
  let valueElement = children?.find((child) => child.props?.value === value);
  let valueClassName = '';
  if (valueElement?.props?.children) {
    valueClassName = valueElement.props.className ?? '';
    valueElement = valueElement.props?.children;
  } else if (valueElement?.props?.text) {
    valueClassName = valueElement.props.className ?? '';
    valueElement = <BodyText>{valueElement.props.text}</BodyText>;
  }

  return (
    <AutoCompleteContainer
      className={[className, 'select']}
      label={label}
      loading={loading}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onClose={onClose}
    >
      <AutoCompleteValueContainer className={classNames([className, 'input-outline select-value'])} initialOpen={initialOpen} showOpenArrow>
        {valueElement &&
          <div className={valueClassName}>
            {valueElement}
          </div>
        }
      </AutoCompleteValueContainer>
      <AutoCompletePopover className={'select-popover'} placement={placement}>
        <AutoCompleteList emptyList={emptyList}>
          {children}
        </AutoCompleteList>
      </AutoCompletePopover>
    </AutoCompleteContainer>
  );
}

Select.propTypes = {
  className: HoopsPropTypes.className,
  emptyList: HoopsPropTypes.string,
  initialOpen: HoopsPropTypes.bool,
  label: HoopsPropTypes.string,
  loading: HoopsPropTypes.bool,
  placeholder: HoopsPropTypes.string,
  placement: HoopsPropTypes.string,
  value: HoopsPropTypes.string,
  onChange: HoopsPropTypes.func,
  onClose: HoopsPropTypes.func,
  children: HoopsPropTypes.children,
};

export function SelectItem({className, value, text, children}) {
  return (
    <AutoCompleteListItem className={[className, 'select-item']} value={value}>
      {text ? <BodyText>{text}</BodyText> : children}
    </AutoCompleteListItem>
  );
}

SelectItem.propTypes = {
  className: HoopsPropTypes.className,
  value: HoopsPropTypes.string,
  text: HoopsPropTypes.string,
  children: HoopsPropTypes.children,
};
