import {FormControl, FormHelperText, Input, makeStyles, OutlinedInput, Select, Typography} from '@material-ui/core';
import React, {useRef, forwardRef, useImperativeHandle, useState, useEffect} from 'react';
import {formStyles} from '../ui/theme/formStyles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    ...formStyles,
    label: {
        color: theme.colors.grey.main,
        display: 'block'
    },
    placeholder: {
        color: theme.colors.grey.main,
        display: 'block',
        position: 'absolute',
        left: theme.spacing(2),
        top: theme.spacing(1),
    },
    responsive: {
        [theme.breakpoints.down('md')]: {
            '& .MuiInputAdornment-root': {display: 'none',},
            '&:hover': {
                position: 'absolute',
                zIndex: '1',
                width: (props) =>
                    props.textwidth > props.textfieldwidth
                        ? props.textwidth + 80
                        : props.textfieldwidth + 80
                ,
                marginTop: '-20px !important',
                boxShadow: '20px 0px 20px -10px #888',
                '& .MuiInputAdornment-root': {display: 'inherit'}
            }
        }
    },
    outlinedBorder: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
            transition: 'all 0.3s ease-out'

        }
    },
    pulseRed: {
        '& .MuiOutlinedInput-notchedOutline': {
            background: '#fc5f6526',
            borderColor: theme.colors.red,
        }
    },
}));

export const HoopsSelectField = forwardRef(({
    input = {},
    label = null,
    style = {},
    className = '',
    responsive = false,
    children = null,
    InputProps = null,
    variant = 'outlined',
    placeholder = null,
    helperText = null,
    ...props
}, ref) => {
    // Responsive work to compare text length to field width.
    const selectFieldRef = useRef(null);
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = getComputedStyle(document.body).font;
    const [pulse, setPulse] = useState(false);

    useImperativeHandle(ref, () => ({
        errorPulse() {
            setPulse(true);
        }
    }));

    useEffect(() => {
        const interval = setInterval(() => {
            setPulse(false);
        }, 800);

        return () => clearInterval(interval);
    }, [pulse]);

    props.textwidth = props.value && context.measureText(props.value).width;
    props.textfieldwidth = selectFieldRef && selectFieldRef.current && selectFieldRef.current.offsetWidth;

    const classes = useStyles(props);

    return (
        <div style={{width: '100%', position: 'relative'}}>
            {label && <Typography variant='caption' className={classes.labelText}>{label}</Typography>}
            {placeholder && (!props.value || props.value === '' || props.value.length === 0) && <Typography variant='body1' className={classes.placeholder}>{placeholder}</Typography>}
            <FormControl fullWidth>
                <Select
                    inputRef={selectFieldRef}
                    variant={variant}
                    margin='dense'
                    fullWidth
                    style={{margin: 0, ...style}}
                    className={classNames([classes.inputStyles, className, classes.outlinedBorder, pulse && classes.pulseRed, responsive && classes.responsive])}
                    //Props for redux-form
                    error={props.meta && Boolean(props.meta.error)}
                    // helperText={props.meta && props.meta.error ? props.meta.error : null}
                    input={
                        variant === 'outlined' ? (
                            <OutlinedInput {...input} {...InputProps} />
                        ) : (
                            <Input {...input} {...InputProps} />
                        )
                    }
                    {...props}
                >
                    {children}
                </Select>
                <FormHelperText error style={{marginLeft: 14}}>{helperText}</FormHelperText>
            </FormControl>
        </div>
    );
});

export default HoopsSelectField;
