import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import classNames from 'classnames';
import React, {useCallback, useEffect, useState} from 'react';
import {GQL_SEARCH_MANY_DECORATIONS} from '../../queries/decorations';
import DecorationDialog from '../decorations/decorationDialog';
import HoopsCustomAutocomplete from '../shared/formHelpers/HoopsCustomAutocomplete';
import {itemBuilder} from '../../ui/theme/itemBuilder';
import {initials} from '../../utils';
import {useLazyQuery} from '@apollo/client';
import {useDebounce, useMountEffect} from '../../hooks';

const useStyles = makeStyles((theme) => ({
  ...itemBuilder,
  orange: {backgroundColor: theme.colors.orange,},
  secondaryDetails: {
    fontSize: '10px',
    lineHeight: '14px',
  },
  primaryDetails: {
    fontSize: '16px',
    lineHeight: '20px'
  },
  secondaryText: {color: '#979797',},
  whiteSpaceNoWrap: {whiteSpace: 'nowrap'},

}));

const RenderOption = (option, {searchString, classes}) => {
  function highlightedWord(word) {
    const matches = match(word, searchString);
    const parts = parse(word, matches);
    return (
      <>
        {parts.map((part, index) =>
          part.highlight ?
            <span key={String(index)} style={{fontWeight: 500}}>
              {part.text}
            </span>
            : <strong key={String(index)} style={{fontWeight: 300}}>
              {part.text}
            </strong>
        )}
      </>
    );
  }

  return (
    <Grid container>
      <Grid item>
        <Grid container wrap='nowrap' alignItems='center' spacing={1}>
          <Grid item>
            <Avatar className={classes.orange}>
              {option.name && initials(option.name)}
            </Avatar>
          </Grid>
          <Grid item>
            <Grid container direction='column'>
              <Grid item>
                <Typography className={classNames(classes.primaryDetails, classes.whiteSpaceNoWrap)}>
                  {option.name && highlightedWord(option.name)}
                </Typography>
              </Grid>
              <Grid item>
                <Grid item>
                  <Typography className={classNames(classes.secondaryDetails, classes.whiteSpaceNoWrap)}>
                    <span className={classes.secondaryText}>Vendor: </span>{option.vendor && `${option.vendor.name}`}
                    <span className={classes.secondaryText}> | Category: </span>{option.category && `${option.category}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export function DecorationAutocomplete({
  onSelect,
  textFieldProps,
  input: {onChange = () => null},
  input,
  productIdArray = null,
  ...params
}) {
  const classes = useStyles();

  const [decorations, setDecorations] = useState([]);
  const [decorationDialogOpen, setDecorationDialogOpen] = useState(false);
  const [searchString, setSearchString] = useState('');

  const [getDecorations, {loading}] = useLazyQuery(GQL_SEARCH_MANY_DECORATIONS, {
    onCompleted: ({decorationMany}) => {
      setDecorations(decorationMany);
    }
  });

  const handleButtonClick = useCallback(() => {
    setDecorationDialogOpen(true);
  }, []);

  const handleDecorationClose = useCallback((data) => {
    setDecorationDialogOpen(false);
    if (data && data._id) {
      onSelect(data);
      onChange(data);
      setSearchString(data.name);
    }
  }, [onSelect, onChange]);

  const searchDecorations = useDebounce((search) => getDecorations(search), [getDecorations]);
  useEffect(() => {
    if (searchString && searchString !== '') {
      searchDecorations({
        variables: {
          filter: {
            q: searchString,
            // When linkedToProduct is true we use the product id array to filter for the linked decorations
            productIds: productIdArray,
          },
          limit: 25,
        },
      });
    }
  }, [searchString, productIdArray, searchDecorations]);

  useEffect(() => {
    if (input && input.value && input.value !== '') {
      setSearchString(input.value.name ? input.value.name : input.value);
    }
  }, [input]);

  // populate the list on load
  useMountEffect(() => {
    getDecorations({
      variables: {
        filter: {
          q: searchString,
          // When linkedToProduct is true we use the product id array to filter for the linked decorations
          productIds: productIdArray,
        },
        limit: 25,
      },
    });
  }, []);

  return (
    <>
      <DecorationDialog isOpen={decorationDialogOpen} onClose={(decoration) => handleDecorationClose(decoration)} />
      <HoopsCustomAutocomplete
        input={input}
        options={decorations}
        loading={loading}
        textFieldProps={textFieldProps}
        RenderOption={(option) => RenderOption(option, {searchString, classes})}
        setSearchString={setSearchString}
        className={classes.disableAutocompleteMargin}
        onSelect={(val) => {
          onChange(val);
          setSearchString(val.name);
        }}
        buttonProps={{
          label: 'Add New Decoration',
          onClick: handleButtonClick
        }}
        inputValue={searchString}
        filterOptions={(options) => options}
        getOptionLabel={(option) => option.name}
        {...params}
      />
    </>
  );
}

export default DecorationAutocomplete;
