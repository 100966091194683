import {MuiThemeProvider, Tooltip} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import InfoIcon from '@material-ui/icons/Info';
import * as numeral from 'numeral';
import * as React from 'react';
import {useSelector} from 'react-redux';
import theme from '../../../../ui/theme';
import green from '../../../../ui/theme/green';
import orange from '../../../../ui/theme/orange';
import purple from '../../../../ui/theme/purple';
import red from '../../../../ui/theme/red';
import {useWatch} from '../../../../hooks';
import {formatDateLong} from '../../../../utils';
import InvoiceErrorChip from '../../../../componentsOld/invoice/InvoiceErrorChip';

const CREATING = 'CREATING';
const OVERDUE = 'OVERDUE';
const PAID_IN_FULL = 'PAID_IN_FULL';
const PARTIALLY_PAID = 'PARTIALLY_PAID';
const CURRENT = 'CURRENT';
const ERROR = 'ERROR';
const QBO_ERROR = 'ERROR';

const invoiceStatuses = {
  [CREATING]: {
    key: CREATING,
    theme: orange,
    label: 'Sending to Accounts',
  },
  [OVERDUE]: {
    key: OVERDUE,
    theme: red,
    label: 'Overdue'
  },
  [ERROR]: {
    key: ERROR,
    theme: red,
    label: 'Error Sending to Accounts'
  },
  [PAID_IN_FULL]: {
    key: PAID_IN_FULL,
    theme: green,
    label: 'Paid'
  },
  [PARTIALLY_PAID]: {
    key: PARTIALLY_PAID,
    theme: purple,
    label: 'Partially Paid'
  },
  [CURRENT]: {
    key: CURRENT,
    theme: theme,
    label: 'Waiting Payment'
  }
};

const TooltipContent = ({invoice, status}) => {
  const currencySymbolSelector = (state) => state?.authReducer.userData.company.currencySymbol;
  const currencySymbol = useSelector(currencySymbolSelector);
  const [dueDate, setDueDate] = React.useState('-');

  React.useEffect(() => {
    const date = new Date(invoice?.dueDate);
    if (!isNaN(date.getTime())) {
      setDueDate(formatDateLong(date));
    }
  }, [invoice]);

  if (status === CREATING) {
    return (
      <div style={{width: 215, padding: 3}}>
        Your invoice data is being sent to your Accounting software.
      </div>
    );
  }
  return (
    <div style={{width: 180, padding: 3}}>
      <Grid container justifyContent={'space-between'} spacing={1}>
        <Grid item>
          <Grid container direction={'column'}>
            <Grid item>Invoice Number</Grid>
            <Grid item>Due Date</Grid>
            <Grid item>Total Amount</Grid>
            <Grid item>Amount Owing</Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction={'column'}>
            <Grid item>{invoice?.invoiceNumber ?? '-'}</Grid>
            <Grid item>{dueDate ?? '-'}</Grid>
            <Grid item>{currencySymbol}{numeral(invoice?.totalAmount).format('0,0.00')}</Grid>
            <Grid item>{currencySymbol}{numeral(invoice?.balance).format('0,0.00')}</Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export const getAccountStatusLabel = ({
                                        _id,
                                        balance,
                                        qboLastRequestStatus,
                                        totalAmount,
                                        dueDate,
                                      } = {}) => {
  if (!_id) {
    return null;
  }

  if (balance === undefined) {
    return (invoiceStatuses[CREATING]);
  }

  dueDate = new Date(dueDate);
  const currentDate = new Date();

  if (qboLastRequestStatus === QBO_ERROR) {
    return (invoiceStatuses[ERROR]);
  }

  if (balance <= 0) {
    return (invoiceStatuses[PAID_IN_FULL]);
  }

  if (balance < totalAmount) {
    return (invoiceStatuses[PARTIALLY_PAID]);
  }

  if (dueDate < currentDate) {
    return (invoiceStatuses[OVERDUE]);
  }

  return (invoiceStatuses[CURRENT]);
};

export const AccountingStatusCell = ({invoiceData}) => {
  const [invoice, setInvoice] = React.useState(invoiceData);
  const [invoiceStatus, setInvoiceStatus] = React.useState(null);

  useWatch(() => {
    setInvoiceStatus(getAccountStatusLabel(invoiceData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceData?._id, invoiceData?.balance, invoiceData?.qboLastRequestStatus, invoiceData?.totalAmount, invoiceData?.dueDate]);

  if (!invoiceData) {
    return (<></>);
  }

  if (invoice && invoice.qboLastRequestStatus === QBO_ERROR) {
    return <InvoiceErrorChip invoice={invoiceData} setInvoice={setInvoice}/>;
  }
  if (!invoiceStatus) {return null;}

  return (
    <MuiThemeProvider theme={invoiceStatus.theme}>
      <Grid container spacing={2} alignItems={'center'} justifyContent={'space-between'} wrap={'nowrap'}>
        <Grid item>
          <Tooltip title={<TooltipContent invoice={invoiceData} status={invoiceStatus.key}/>} arrow>
            <Chip
              size={'small'}
              label={invoiceStatus.label}
              color='primary'
              icon={<InfoIcon/>}
            />
          </Tooltip>
        </Grid>
      </Grid>
    </MuiThemeProvider>
  );
};

AccountingStatusCell.statusOptions = Object.keys(invoiceStatuses).map((value) => ({value, label: invoiceStatuses[value].label}));
