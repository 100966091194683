import {SET_COMPANY} from '../actions/action-types/company';

export const initialState = {
  list: [],
  company: {
    numberOfUsers: {},
    taxes: [],
    accountingPlatform: {}
  },
};

const companyReducer = (state = {...initialState}, action) => {
  switch (action.type) {
    case SET_COMPANY:
      return {
        ...state,
        company: action.payload,
      };
    default:
      return state;
  }
};

export default companyReducer;
