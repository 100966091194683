import TaxService from '../modules/tax/TaxService';
import {newTax} from '../reducers/taxes';
import {getCompany} from './action-types/company';
import {hoopsQuerySetRefetch} from './hoopsQuery';
export const SET_TAX = 'SET_TAX';
export const SET_TAX_LOADING = 'SET_TAX_LOADING';
export const UPDATE_TAX = 'UPDATE_TAX';
export const CREATE_TAX = 'CREATE_TAX';
export const REMOVE_TAX = 'REMOVE_TAX';
export const UPDATE_TAXES = 'UPDATE_TAXES';
export const MODAL_TAX = 'MODAL_TAX';

export function editNewTax(state = {}) {
  return function(dispatch) {
    const payload = {...newTax, ...state};
    dispatch({type: SET_TAX, payload}); // new empty tax and merge any data
    dispatch(setModal(true)); // open modal
    dispatch(getCompany()); // refetch company with taxes
    return payload;
  };
}

export function setModal(isOpen = false) {
  return function(dispatch) {
    dispatch({type: MODAL_TAX, payload: isOpen});
    return isOpen;
  };
}

export function getTaxes(variables, cb) {
  return function(dispatch) {
    return TaxService.getTaxes(variables).then((payload) => {
      if (cb) {
        cb(payload);
      }
      dispatch({type: UPDATE_TAXES, payload: payload});
      return payload;
    });
  };
}

export function createTax(data, cb) {
  return function(dispatch) {
    return TaxService.createTax(data).then((file) => {
      if (cb) {
        cb(file);
      }
      dispatch({type: CREATE_TAX, payload: file});
      dispatch(hoopsQuerySetRefetch('tax', true));
      dispatch(getCompany()); // refetch company with taxes
      return file;
    });
  };
}

export function getTax(id, cb) {
  return function(dispatch) {
    dispatch({type: SET_TAX_LOADING, payload: true});
    return TaxService.getTax(id).then((payload) => {
      dispatch({
        type: SET_TAX,
        payload,
      });
      dispatch({type: SET_TAX_LOADING, payload: false});
      if (cb) {
        cb(payload);
      }

      return payload;
    });
  };
}

export function updateTax(id, data, cb) {
  return function(dispatch) {
    return TaxService.updateTax(id, data).then((payload) => {
      dispatch({type: UPDATE_TAX, payload});
      dispatch(hoopsQuerySetRefetch('tax', true));
      dispatch(getCompany()); // refetch company with taxes
      if (cb) {
        cb(payload);
      }
      return payload;
    });
  };
}

export function setDefault(id) {
  return function (dispatch) {
      return TaxService.setDefault(id).then((payload) => {
          dispatch(hoopsQuerySetRefetch('tax', true));
          dispatch(getCompany()); // refetch company with taxes
          return payload;
      });
  };
}

export function deleteTax(id) {
  return function(dispatch) {
    return TaxService.deleteTax(id).then((d) => {
      dispatch({type: REMOVE_TAX, payload: id});
      dispatch(hoopsQuerySetRefetch('tax', true));
      dispatch(getCompany()); // refetch company with taxes
      return d;
    });
  };
}
