import React from 'react';
import Grid from '@material-ui/core/Grid';
import {useDispatch, useSelector} from 'react-redux';
import {register} from '../actions/action-types';
import {REGISTER_FIELD_GROUP, REGISTER_FIELD_GROUP_FULLY_PROMOTED, REGISTER_FIELD_GROUP_PROOFS} from '../constants';
import {SignupForm} from '../componentsOld/auth/SignupForm';

export const Register = ({subscriptionType}) => {
  const dispatch = useDispatch();

  const registrationStatusSelector = (state) => state.registrationReducer;
  const registrationStatus = useSelector(registrationStatusSelector);

  const validateForm = (values) => {
    let formValidated = true;
    for (let field of formValidation) {
      const fieldName = field.name.split('.');
      if ((values[fieldName]) || (fieldName.length > 1 && values[fieldName[0]] && values[fieldName[0]][fieldName[1]])) {
        field.error = false;
        field.helperText = null;
      } else {
        field.error = true;
        field.helperText = `${field.label} is a reqiured field`;
        formValidated = false;
      }
    }
    return formValidated;
  };

  const submit = async (values) => {
    if (validateForm(values)) {
      const {name, phone, username, region, ...regValues} = values;
      const company = {
        companyTradingEntities: [{name, phone, email: username}],
        region: region,
        subscriptionType: subscriptionType,
      };
      dispatch(register({...regValues, company, username}));
    }
  };

  document.title = 'Register | Hoops';

  let formGroup;
  let formValidation;
  switch (subscriptionType) {
    case 'PROOFS':
      formGroup = REGISTER_FIELD_GROUP_PROOFS;
      formValidation = formGroup.group.map((field) => ({name: field.name, label: field.label, error: false, helperText: null}));
      break;
    case 'FULLYPROMOTED':
      formGroup = REGISTER_FIELD_GROUP_FULLY_PROMOTED;
      formValidation = formGroup.group.map((field) => ({name: field.name, label: field.label, error: false, helperText: null}));
      break;
    default:
      formGroup = REGISTER_FIELD_GROUP;
      formValidation = formGroup.group.map((field) => ({name: field.name, label: field.label, error: false, helperText: null}));
      break;
  }

  return (
    <Grid item>
      <SignupForm
        onSubmit={submit}
        formGroup={formGroup}
        formValidation={formValidation}
        isLoading={registrationStatus.loading}
      />
    </Grid>
  );
};
