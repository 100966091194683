import React, {Fragment} from 'react';
import {Switch} from 'react-router-dom';
import CatalogImport from '../../componentsOld/catalog/catalogImport/CatalogImport';
import ImportHistory from '../../componentsOld/catalog/catalogImport/importHistory/ImportHistory';
import {ProductManyViewPage} from '../../componentsOld/catalog/ProductManyViewPage';
import SingleProduct from '../../componentsOld/catalog/SingleProduct/SingleProduct';
import {roles, PermissionBasedRoute} from '../../componentsHoops/AccessControl';

class Catalog extends React.Component {
  render() {
    const {admin, standard, lowAccess} = roles;
    return (
      <Fragment>
        <Switch>

          <PermissionBasedRoute
            allowedRoles={[admin, standard, lowAccess]}
            path='/catalog'
            exact
          >
            <ProductManyViewPage />
          </PermissionBasedRoute>
          <PermissionBasedRoute
            allowedRoles={[admin, standard, lowAccess]}
            path='/catalog/products'
            exact
          >
            <ProductManyViewPage />
          </PermissionBasedRoute>
          <PermissionBasedRoute
            allowedRoles={[admin, standard, lowAccess]}
            path='/catalog/configured-products'
            exact
          >
            <ProductManyViewPage />
          </PermissionBasedRoute>
          <PermissionBasedRoute
            allowedRoles={[admin]}
            path='/catalog/decorations'
            exact
          >
            <ProductManyViewPage />
          </PermissionBasedRoute>

          <PermissionBasedRoute
            allowedRoles={[admin, standard, lowAccess]}
            path='/catalog/import'
            exact

          >
            <CatalogImport />
          </PermissionBasedRoute>
          <PermissionBasedRoute
            allowedRoles={[admin, standard, lowAccess]}
            path='/catalog/history'
            exact

          >
            <ImportHistory />
          </PermissionBasedRoute>
          <PermissionBasedRoute
            allowedRoles={[admin, standard, lowAccess]}
            path='/catalog/product/:_id'

            >
            <SingleProduct />
          </PermissionBasedRoute>

        </Switch>
      </Fragment>
    );
  }
}

export default Catalog;
