// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {Button, Grid, makeStyles, Menu, MenuItem, Typography} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import React, {useEffect, useState} from 'react';

const useStyles = makeStyles((theme) => ({
    text: {
        color: 'white',
        fontWeight: 300,
        textAlign: 'left'
    },
    arrowIcon: {color: 'white',},
    listItem: {
        minWidth: 222,
        minHeight: 52,
        textAlign: 'left',
        '&:active': {backgroundColor: 'transparent'},
        padding: '0px 8px 8px 8px !important'

    },
    cellButton: {
        borderRadius: 0,
        justifyContent: 'flex-start',
        '&:hover': {background: 'none'},
        minHeight: 57,
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
    },
    addButton: {
        padding: theme.spacing(2),
        margin: theme.spacing(2),
        width: 222,
        fontSize: 16,
        textTransform: 'none'
    },
}));

export const StatusCellEdit = ({
    statusGroup = {statuses: []},
    disabled = false,
    value = '',
    onChange = () => null,
}) => {
    const {statuses} = statusGroup;
    const [anchorEl, setAnchorEl] = useState(null);
    const [activeCell, setActiveCell] = useState(null);
    const classes = useStyles();

    const onOptionChange = (index) => {
        handleMenuClose();
        const newActiveOption = statuses[index];
        setActiveCell(newActiveOption);
        onChange(newActiveOption);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuOpen = (e) => {
        setAnchorEl(e.currentTarget);
    };

    useEffect(
        () => {
            const status = statuses
                .find(
                    (status) => status.value === value
                ) || statuses[0];

            setActiveCell(status);
        },
        [statuses, value]
    );

    return (
        <>
            {activeCell
                ? <Button
                    fullWidth
                    onClick={disabled ? null : handleMenuOpen}
                    className={classes.cellButton}
                    disabled={disabled}
                    disableRipple
                    style={{background: activeCell.color}}
                >
                    <Grid container justifyContent='space-between' alignItems='center' direction='row'>
                        <Grid item><Typography variant='body2' className={classes.text} noWrap style={{width: 120}}>{activeCell.label}</Typography></Grid>
                        {!disabled && <Grid item style={{display: 'grid'}}><KeyboardArrowDownIcon className={classes.arrowIcon} /></Grid>}
                    </Grid>
                </Button>
                : ''
            }
            {!disabled && <Menu
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                {statuses && statuses.map((option, index) => (
                        <MenuItem
                            disableRipple={true}
                            key={index}
                            className={classes.listItem}
                            style={{backgroundColor: 'transparent'}}
                            disableGutters={true}
                        >
                            <Button
                                fullWidth
                                className={`${classes.cellButton} ${classes.text}`}
                                style={{backgroundColor: option.color, height: 52}}
                                onClick={() => onOptionChange(index)}
                            >{option.label}</Button>
                        </MenuItem>
                    ))}
            </Menu>}
        </>
    );
};

export default StatusCellEdit;
