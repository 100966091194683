// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-class-assign, no-shadow */

import React, {Component, Fragment, useCallback, useEffect, useState} from 'react';
import {makeStyles, MenuItem, withStyles} from '@material-ui/core';
import {ItemBuilderSellPrice, ItemBuilderTextField} from './ItemBuilderTextField';
import ItemBuilderTable from './ItemBuilderTable';
import {Select} from 'final-form-material-ui';
import {useDispatch, useSelector} from 'react-redux';
import {formValueSelector, change, getFormMeta} from 'redux-form';
import {get, uniqBy, sortBy, find} from 'lodash';
import * as numeral from 'numeral';

const styles = (theme) => ({});

export function getPrice(priceBreaks, quantity) {
  const sorted = sortBy(priceBreaks, 'quantity ');
  let priceBreak = sorted[0];
  sorted.forEach((pb) => {
    if (pb.quantity <= quantity) {
      priceBreak = pb;
    }
  });
  return numeral(priceBreak.price).format('0.00');
}

const formName = 'itemBuilder';
const selector = formValueSelector(formName);
const formMetaSelector = getFormMeta(formName);

function BuyPriceField({input: {name, onChange, value}, meta: {form}, meta, field, input, product, ...params}) {
  const dispatch = useDispatch();
  const changer = useCallback((f, value) => dispatch(change(form, f, value)), [dispatch, form]);

  const quantitySelector = (state) => selector(state, 'quantity');
  const variantSelector = (state) => selector(state, `${field}.productVariant`);
  const colorSelector = (state) => selector(state, `${field}.colour`);
  const isCustomBuyPriceSelector = (state) => selector(state, `${field}.isCustomBuyPrice`);
  const variantMetaSelector = (state) => formMetaSelector(state);

  const quantity = useSelector(quantitySelector);
  const variant = useSelector(variantSelector);
  const color = useSelector(colorSelector);
  const isCustomBuyPrice = useSelector(isCustomBuyPriceSelector);
  const variantMeta = useSelector(variantMetaSelector);

  const isRowTouched = get(variantMeta, `${field}.quantity`, false)
    || get(variantMeta, `${field}.productVariant._id.touched`, false)
    || get(variantMeta, `${field}.colour.touched`, false)
    || meta.dirty;

  useEffect(() => {
    if (!isRowTouched || isCustomBuyPrice) {
      return;
    }

    let priceBreaks = [];
    const variants = get(product, 'variants', []);
    if (quantity && variant) {
      priceBreaks = get(
        find(variants, {_id: get(variant, '_id', '')}),
        'priceBreaks',
        []);
    } else if (quantity) {
      priceBreaks = get(product, 'defaultPriceBreaks', []);
    }
    if (priceBreaks.length) {
      onChange(getPrice(priceBreaks, quantity));
    }
  }, [quantity, variant, color, isCustomBuyPrice, isRowTouched, onChange, product]);

  useEffect(() => {
    let isCustomBuyPrice = false;

    let priceBreaks = [];
    const variants = get(product, 'variants', []);
    if (quantity && variant) {
      priceBreaks = get(
        find(variants, {_id: get(variant, '_id', '')}),
        'priceBreaks',
        []);
    } else if (quantity) {
      priceBreaks = get(product, 'defaultPriceBreaks', []);
    }
    if (priceBreaks.length) {
      const price = parseFloat(getPrice(priceBreaks, quantity));
      isCustomBuyPrice = price !== parseFloat(input.value);
    }

    changer(`${field}.isCustomBuyPrice`, isCustomBuyPrice);

  }, [input, value, changer, field, product, quantity, variant]);

  return (<ItemBuilderTextField currency {...input} isBold={isCustomBuyPrice} {...params} />);
}

function SelectField({children, ...params}) {
  const useStyles = makeStyles((theme) => ({
    selectInput: {
      fontSize: 12,
      padding: theme.spacing(1),
      width: '100%'
    }
  }));
  const classes = useStyles();

  return (
    <Select
      formControlProps={{
        fullWidth: true,
        variant: 'outlined',
        margin: 'dense',
        size: 'small'
      }}
      SelectDisplayProps={{
        className: classes.selectInput,
        fullWidth: true
      }}
      input={params.input}
      meta={params.meta}
    >
      {children}
    </Select>
  );
}

function ColorField({product, ...params}) {
  if (product && product.colors) {
    return (
      <SelectField
        {...params}
      >
        {product.colors.map((o) => (
          <MenuItem key={o._id} value={o.name}>{o.name}</MenuItem>
        ))}
      </SelectField>
    );
  }
  return (
    <ItemBuilderTextField {...params} />
  );
}

function SizeField({product, field, ...params}) {
  const dispatch = useDispatch();
  const [variants, setVariants] = useState([]);
  const selector = formValueSelector('itemBuilder');

  const colorSelector = (state) => selector(state, `${field}.colour`);
  const sizeSelector = (state) => selector(state, `${field}.size`);
  const variantSelector = (state) => selector(state, `${field}.productVariant`);

  const color = useSelector(colorSelector);
  const size = useSelector(sizeSelector);
  const variant = useSelector(variantSelector);

  useEffect(() => {
    if (color) {
      const variants = get(product, 'variants', []);
      const availableVariants = variants.filter((v) => (v.color.name === color));
      setVariants(uniqBy(availableVariants, 'size.name'));
    }

    if (!variant) {
      dispatch(change(params.meta.form, params.input.name, size));
    }
  }, [color, variant, size, dispatch, params.input.name, params.meta.form, product]);

  if (product && product.sizes) {
    return (
      <>
        <SelectField
          {...params}
        >
          {variants.map((o) => (
            <MenuItem key={o._id} value={o._id}>{o.size.name}</MenuItem>
          ))}
        </SelectField>
      </>
    );
  }

  return (
    <ItemBuilderTextField {...params} />
  );
}

class VariantsTable extends Component {
  state = {
    headings: [
      {
        title: 'Quantity',
        name: 'quantity',
        component: ItemBuilderTextField
      }, {
        title: 'Color',
        name: 'colour',
        component: ColorField
      }, {
        title: 'Size',
        name: 'productVariant._id',
        component: SizeField
      }, {
        title: 'Rate/Unit',
        name: 'buyPrice',
        component: BuyPriceField
      }, {
        title: 'Additional Cost/Unit',
        name: 'additionalCost',
        component: (props) => <ItemBuilderTextField currency {...props} />
      }, {
        title: 'Markup (%)',
        name: 'markup',
        component: (props) => <ItemBuilderTextField percent {...props} />
      }, {
        title: 'Sell Price/Unit',
        name: 'sellPrice',
        component: (props) => (<ItemBuilderSellPrice disabled currency {...props} />)
      }
    ]
  };

  render() {
    const {product} = this.props;
    const {headings} = this.state;

    return (
      <Fragment>
        <ItemBuilderTable
          parentField={'variations'}
          headings={headings}
          buttonText={'Product'}
          product={product}
          duplicate
          showQuantity
        />
      </Fragment>
    );
  }
}

VariantsTable = withStyles(styles)(VariantsTable);

export default VariantsTable;
