// TODO: fix eslint disable
/* eslint-disable no-shadow */

import CatalogService from '../servicesOld/CatalogService';
import {snackSuccess, snackError} from './action-types';
import {hoopsQuerySetRefetch} from './hoopsQuery';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_ACTIVE_PRODUCT = 'SET_ACTIVE_PRODUCT';
export const TOGGLE_EDIT_PRODUCT = 'TOGGLE_EDIT_PRODUCT';

export const SET_CATALOG_IMPORTS = 'SET_CATALOG_IMPORTS';

export function createProduct(data) {
  return (dispatch) => {
    CatalogService.createProduct(data)
      .then(({data}) => {
        dispatch(snackSuccess(`Product ${data.createProduct.title} Created!`));
        dispatch(hoopsQuerySetRefetch('product', true));
      })
      .catch(() => {
        snackError('Could not create Product');
      });
  };
}

export function getProductsForCatalogList(q, page) {
  return (dispatch) => {
    CatalogService.getProductsForCatalogList(q, page)
      .then(({data: {products}}) => {
        dispatch({
          type: SET_PRODUCTS,
          payload: products,
        });
      });
  };
}

export function getProduct(_id) {
  return (dispatch) => {
    CatalogService.getProduct(_id)
      .then(({data: {product}}) => {
        dispatch({
          type: SET_ACTIVE_PRODUCT,
          payload: product,
        });
      });
  };
}

export function getProductWithVariantsTable(_id) {
  return (dispatch) => {
    CatalogService.getProductWithVariantsTable(_id)
      .then(({data: {product}}) => {
        dispatch({
          type: SET_ACTIVE_PRODUCT,
          payload: product,
        });
      });
  };
}

export function toggleEditProduct() {
  return (dispatch) => {
    dispatch({type: TOGGLE_EDIT_PRODUCT,});
  };
}

export function updateProduct(_id, data) {

  return (dispatch) => {
    CatalogService.updateProduct(_id, data)
      .then(({data: {updateProduct: product}}) => {
        dispatch({
          type: SET_ACTIVE_PRODUCT,
          payload: product,
        });
        dispatch(snackSuccess(`Product ${product.title} was updated`));
        dispatch(hoopsQuerySetRefetch('product', true));
      })
      .catch(() => {
        dispatch(snackError('Could not update Product'));
      });
  };
}

export function updateProductColor(_id, data) {
  return (dispatch) => {
    CatalogService.updateProductColor(_id, data)
      .then(({data: {updateProductColor: color}}) => {
        dispatch(snackSuccess(`Product Color "${color.name}" was updated`));
      });
  };
}

export function updateProductVariants(_id, data) {
  return (dispatch) => {
    CatalogService.updateProductVariants(_id, data)
      .then(() => {
        dispatch(getProduct(_id));
        dispatch(snackSuccess('Product Variants updated successfully'));
      })
      .catch(() => dispatch(snackError('Error updating Product Variants')));
  };
}

export function createProductVariant(productId, data) {
  return (dispatch) => {
    CatalogService.createProductVariant(productId, data)
      .then(() => {
        dispatch(getProductWithVariantsTable(productId));
        dispatch(snackSuccess('Variant added successfully'));
      })
      .catch(() => {
        dispatch(snackError('Unable to add variant'));
      });
  };
}

export function deleteProduct(_id) {
  return (dispatch) => {
    CatalogService.deleteProduct(_id)
      .then(({data: {deleteProduct}}) => {
        dispatch(snackSuccess(`Product (${deleteProduct.code}) successfully deleted`));
        dispatch(hoopsQuerySetRefetch('product', true));
      });
  };
}

export function bulkUpdateProductVariants(productId, data) {
  return (dispatch) => {
    CatalogService.bulkUpdateProductVariants(productId, data)
      .then(() => {
        dispatch(getProduct(productId));
      })
      .catch(() => {
        dispatch(snackError('Could not update product variants'));
      });
  };
}

export function getImports() {
  return function (dispatch) {
    return CatalogService.getCatalogImports()
      .then((catalogImports) => {
        dispatch({
          type: SET_CATALOG_IMPORTS,
          payload: catalogImports,
        });
      });
  };
}

export function undoCatalogImport(id) {
  return (dispatch) => CatalogService.undoCatalogImport(id)
      .then(() => dispatch(getImports()));
}

export function cancelCatalogImport(id) {
  return (dispatch) => CatalogService.cancelCatalogImport(id)
      .then(() => dispatch(getImports()));
}
