import React from 'react';
import {Controller} from 'react-hook-form';

export const ControlledHoopsAutocomplete = ({
  name,
  control,
  textFieldProps,
  Component,
  defaultValue,
  disabled,
  onChange = () => null,
  onSelect = () => null
}) => (
    <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    render={(params) => (<Component
        fullWidth
        {...params}
        textFieldProps={textFieldProps}
        disabled={disabled}
        onSelect={(a) => {
          onSelect(a);
          onChange(a); // this will update the value properly
        }}
        getOptionSelected={(option, value) => {
          if (!value || !value._id) {
            return false;
          }
          return option._id === value._id;
        }}
        onChange={(e, d) => {
          onChange(d);
        }}
        input={{
          value: params.value,
          onChange: (d) => {
            onChange(d);
          }
        }}
      />)}
  />
  );

export default ControlledHoopsAutocomplete;

