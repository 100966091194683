import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import HelpArticle from '../../../helpArticle';
import ImportHistoryTable from './ImportHistoryTable';

function ImportHistory() {
  return (
    <>
    <Grid container
          justifyContent='space-between'
          alignItems='center'
          spacing={3}
    >
      <Grid item xs={4}>
        <h3>Import History</h3>
      </Grid>
      <Grid item xs={4}>
        <HelpArticle link='http://help.hoopscrm.com/en/collections/2104745-catalog'/>
      </Grid>
      <Grid item  xs={4}/>
      <Grid item xs={12}>
        <Paper>
          <ImportHistoryTable />
        </Paper>
      </Grid>
    </Grid>
    </>
  );
}

export default ImportHistory;
