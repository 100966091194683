// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-shadow */

import {InputAdornment, makeStyles} from '@material-ui/core';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {HoopsTextField} from '../../../componentsOld/HoopsTextField';
import {useWatchEffect} from '../../../hooks';
import {asCurrencyString, asFloat} from '../../../utils';
import {ItemBuilderContext} from '../ItemBuilderContext';
import {toFloat} from '../../../componentsOld/shared/numberUtils';

const useStyles = makeStyles((theme) => ({boldField: {fontWeight: 500}}));

const BuyPriceField = ({
  index = null,
  field,
  defaultValue,
  name,
  currencySymbol,
  ...params
}) => {
  const classes = useStyles();
  const {watch, control, setValue} = useFormContext();
  const {onPriceChanged} = useContext(ItemBuilderContext);

  const [suggestedPriceBreak, setSuggestedPriceBreak] = useState(false);

  const product = watch(`variations[${index}].product`);
  const formPriceElement = watch();
  const formVariationRow = formPriceElement?.variations && formPriceElement?.variations[index];

  const quantity = formPriceElement?.variations?.reduce((agg, variation) => agg += (formVariationRow?.productId === variation?.productId && asFloat(variation?.quantity)), 0);

  // variantId is set by the selected size
  const variantId = formVariationRow?.productVariant?._id ?? null;
  const buyPrice = formVariationRow?.buyPrice;
  const buyPriceOverride = formVariationRow?.buyPriceOverride;

  const getPriceBreaks = useCallback((variantId) => {
    let priceBreaks = [];
    const variants = product?.variants ?? [];
    if (variantId) {
      priceBreaks = variants.find((variant) => variant._id === variantId)?.priceBreaks ?? [];
    } else {
      priceBreaks = product?.defaultPriceBreaks ?? [];
    }
    return (priceBreaks);
  }
    , [product]);

  const getPrice = (priceBreaks, quantity) => {
    priceBreaks.sort((a,b) => a.quantity - b.quantity);
    let priceBreak = priceBreaks[0];
    priceBreaks.forEach((pb) => {
      if (pb.quantity <= quantity) {
        priceBreak = pb;
      }
    });
    return priceBreak?.price;
  };

  const isPriceBreakValue = useCallback((priceBreaks) => {
    const price = asFloat(buyPrice) > 0 ? buyPrice : defaultValue;
    return priceBreaks.findIndex((el) => asFloat(el.price) === asFloat(price)) > -1;
  }, [buyPrice, defaultValue]);

  const getSuggestedPriceBreak = useCallback((forceUpdate = false) => {
    const priceBreaks = getPriceBreaks(variantId);
    setSuggestedPriceBreak(
      (prevVal) => {
        let newVal = (quantity && quantity !== 0) && getPrice(priceBreaks, quantity);
        if (!buyPriceOverride && newVal && ((toFloat(buyPrice) === toFloat(prevVal) && toFloat(newVal) > 0) || (!defaultValue && !prevVal) || (defaultValue && prevVal === '') || isPriceBreakValue(priceBreaks) || forceUpdate)) {
          setValue(name, asCurrencyString(newVal));
        }
        return newVal;
      }
    );
  }, [buyPrice, getPriceBreaks, defaultValue, name, quantity, setValue, variantId, isPriceBreakValue, buyPriceOverride]);

  const setCurrencyString = (e) => {
    setValue(name, asCurrencyString(e.target.value));
  };

  useEffect(() => {
    product && getSuggestedPriceBreak();
  }, [getSuggestedPriceBreak, product]);

  useWatchEffect(([prevProductId]) => {
    if (prevProductId != null && formVariationRow?.productId) {
      product && getSuggestedPriceBreak(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVariationRow?.productId]);

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      render={(field) => (
        <HoopsTextField
          responsive
          {...field}
          onChange={(e) => {
            setValue(`variations[${index}].buyPriceOverride`, true);
            onPriceChanged();
            field.onChange(e);
          }}
          onBlur={(e) => {
            setCurrencyString(e);
          }}
          {...params}
          InputProps={{
            className: buyPriceOverride ? classes.boldField : null,
            startAdornment: <InputAdornment position={'start'}>{currencySymbol}</InputAdornment>
          }}
        />
      )}
      control={control}
    />
  );
};

export default BuyPriceField;
