import {
  RESET_ACTIVE_PO,
  SET_ACTIVE_PO_VARIANTS,
  SET_ACTIVE_PURCHASE_ORDER,
  SET_FORM_PURCHASE_ORDER,
  SET_PO_LOADING,
  SET_PO_SHIP_TO,
  SET_PURCHASE_ORDERS,
  SET_JOB_PURCHASE_ORDERS,
  SET_LOADING_POS,
} from '../actions/purchaseOrders';

export const initialPurchaseOrder = {
  items: [
    {files: [],},
  ],
};

export const initialPOVariants = {
  variants: [],
  decorations: [],
  additionalItems: [],
};

const initialState = {
  list: [],
  loadingPos: false,
  jobPurchaseOrderList: [],
  loading: false,
  activePurchaseOrder: initialPurchaseOrder,
  formPurchaseOrder: initialPurchaseOrder,
  activePurchaseOrderVariants: initialPOVariants,
};

const purchaseOrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PURCHASE_ORDERS:
      return {
        ...state,
        list: action.payload,
      };
    case SET_JOB_PURCHASE_ORDERS:
      return {
        ...state,
        jobPurchaseOrderList: action.payload,
      };
    case RESET_ACTIVE_PO:
      return {
        ...state,
        activePurchaseOrder: initialPurchaseOrder,
      };
    case SET_ACTIVE_PURCHASE_ORDER:
      return {
        ...state,
        activePurchaseOrder: {
          ...initialPurchaseOrder,
          ...action.payload,
        },
      };
    case SET_FORM_PURCHASE_ORDER:
      return {
        ...state,
        formPurchaseOrder: {
          ...initialPurchaseOrder,
          ...action.payload,
        },
      };
    case SET_PO_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_ACTIVE_PO_VARIANTS:
      return {
        ...state,
        activePurchaseOrderVariants: {
          ...initialState.activePurchaseOrderVariants,
          ...action.payload,
        },
      };
    case SET_PO_SHIP_TO:
      return {
        ...state,
        shipTo: {...action.payload,},
      };
    case SET_LOADING_POS:
      return {
        ...state,
        loadingPos: action.payload,
      };

    default:
      return {...state,};
  }
};

export default purchaseOrdersReducer;
