// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import DateFnsUtils from '@date-io/date-fns';
import {Button, Divider, Grid, InputLabel, makeStyles, TextField, Typography} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {isAfter} from 'date-fns';
import {uniq} from 'lodash';
import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {createSchedule, setModal, updateSchedule} from '../../actions/schedules';
import ExpandButton from '../../componentsOld/shared/ExpandButton';
import {JobItemTable} from '../../componentsOld/shared/Job/JobItemTable';
import StatusChanger from '../../componentsOld/Statuses/StatusChanger';
import ResourceSelect from './ResourceSelect';

const JobItemTables = ({
    jobItems = [],
    value = [],
    onChange = () => null
}) => {
    const [state, setState] = useState(null);

    const handleChange = (selected) => {
        setState((prev) => {
            const result = uniq([
                ...selected
            ]);

            onChange(result);
            return result;
        });
    };

    useEffect(
        () => {
            if (value) {
                setState(value);
            }
        },
        [value, state]
    );

    return (
        <>
            {jobItems.map(
                (jobItem) => <JobItemTable
                    value={value}
                    key={jobItem._id}
                    jobItem={jobItem}
                    onChange={(values) => handleChange(values, jobItem._id)}
                />
            )}
        </>
    );
};

const pickerDefaults = {
    variant: 'dialog',
    clearable: true,
    inputVariant: 'standard'
};

const fieldDefaults = {
    fullWidth: true,
    variant: 'standard'
};

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    dialogActions: {padding: theme.spacing(2),}
}));

export const ScheduleEditModal = () => {
    const classes = useStyles();

    const scheduleSelector = (state) => state.scheduleReducer.active;
    const modalViewTypeSelector = (state) => state.scheduleReducer.modalOpen;

    const schedule = useSelector(scheduleSelector);
    const modalViewType = useSelector(modalViewTypeSelector);

    const isOpen = modalViewType === 'edit';
    const {watch, control, getValues, reset, errors} = useForm({defaultValues: schedule});
    const startAt = watch('startAt');

    const [expandedSections, setExpandedSections] = useState({
        items: true,
        proofs: false,
        comments: false
    });

    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(setModal(false));
    };

    const handleExpand = (name, state) => {
        setExpandedSections((prev) => ({
            ...prev,
            [name]: state
        }));
    };

    const handleSave = () => {
        const {$isNew} = schedule;
        const payload = {
            ...schedule,
            ...getValues()
        };

        $isNew ?
            dispatch(createSchedule(payload)) :
            dispatch(updateSchedule(schedule._id, payload));
        dispatch(setModal(false));
    };

    useEffect(() => {
        if (isOpen) {
            reset(schedule);
        }
    }, [isOpen, schedule, reset]);

    if (!isOpen) {
        return null;
    }

    return (
        <Dialog
            open={isOpen}
            maxWidth={'lg'}
            fullWidth={true}
            onClose={handleClose}
        >
            <DialogTitle disableTypography>
                <Typography variant={'h4'}>Schedule Job</Typography>
                <IconButton aria-label='close' className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <form noValidate autoComplete='off'>
                        <Grid container spacing={3}>
                            <Grid item xs={8}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Controller as={TextField} name='title' control={control} label='Title' {...fieldDefaults} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller as={TextField} name='description' control={control} label='Description' defaultValue='' {...fieldDefaults} {...{rows: 4, multiline: true}} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <div>
                                            <InputLabel shrink>Status</InputLabel>
                                        </div>
                                        <Controller as={<StatusChanger
                                            entityType='schedule'
                                            entityMapping='status'
                                            size='small'
                                            returnValue
                                        />} name='status' required control={control} />

                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}><Controller as={KeyboardDateTimePicker} name='startAt' required control={control} label='Due Date/Time' {...fieldDefaults} {...pickerDefaults} /></Grid>
                                            <Grid item xs={12}>
                                                <Controller
                                                    as={KeyboardDateTimePicker}
                                                    name='endAt'
                                                    minDate={startAt}
                                                    rules={{
                                                        validate: (v) =>
                                                            // we want a better date range picker
                                                             isAfter(v, startAt)

                                                    }}
                                                    helperText={errors.endAt ? 'Can\'t be before Due Date' : null}
                                                    error={errors.endAt}
                                                    control={control}
                                                    label='End Date/Time'
                                                    {...fieldDefaults}
                                                    {...pickerDefaults}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            as={ResourceSelect}
                                            name='resourceId'
                                            control={control}
                                            label='Resource'
                                            {...fieldDefaults}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Divider />
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container alignItems='center' justifyContent='space-between' spacing={3}>
                                    <Grid item>
                                        <Typography variant='subtitle1'>
                                            Items / Products / Decorations
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <ExpandButton onChange={(state) => handleExpand('items', state)} isExpanded={expandedSections.items} />
                                    </Grid>
                                    {expandedSections.items ? <Grid item xs={12}>
                                        <Controller as={
                                            <JobItemTables jobItems={schedule.job.items} />
                                        } name='jobChildMappingIds' control={control} label='Title' {...fieldDefaults} />
                                    </Grid> : null}
                                </Grid>
                            </Grid>

                        </Grid>
                    </form>
                </MuiPickersUtilsProvider>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Grid container justifyContent={'space-between'}>
                    <Grid item xs>
                        <Button onClick={handleClose}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Button
                                    onClick={handleSave}
                                    variant={'contained'}
                                    color={'primary'}
                                    type='submit'>Save</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default ScheduleEditModal;
