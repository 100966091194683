import TextField from '@material-ui/core/TextField';
import React from 'react';

export const renderTextField = ({input, label, ...custom}) => (
    <TextField
      fullWidth={true}
      label={label}
      {...input}
      {...custom}
    />
  );
