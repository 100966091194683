import {Button, Divider, Grid, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import HelpIcon from '@material-ui/icons/Help';
import {get} from 'lodash';
import React, {useCallback, useContext} from 'react';
import {useFieldArray, useFormContext} from 'react-hook-form';
import {itemBuilder} from '../../../ui/theme/itemBuilder';
import {ItemBuilderContext} from '../ItemBuilderContext';
import ProductVariantTotals from './ProductTotals';
import {ItemBuilderProductRow} from './ItemBuilderProductRow';

const useStyles = makeStyles(() => ({...itemBuilder,}));

const ItemBuilderProductTable = ({priceElement = {},}) => {
  const classes = useStyles();
  const {control, watch} = useFormContext();
  const {fields, append, remove} = useFieldArray({
    control,
    name: 'variations'
  });
  const {onPriceChanged, productDisplayToCustomer} = useContext(ItemBuilderContext);

  const formPriceElement = watch();
  const variations = get(formPriceElement, 'variations', []);

  const title = 'Product';

  const handleDuplicate = useCallback((rowIndex) => {
    append(variations[rowIndex]);
    onPriceChanged();
  }, [append, onPriceChanged, variations]);

  const handleDelete = useCallback((rowIndex) => {
    fields.length > 1
      ? remove(rowIndex)
      : remove();
    onPriceChanged();
  }, [fields.length, onPriceChanged, remove]);

  const handleAdd = useCallback(() => {
    append({
      productType: 'hoops',
      product: {},
      productCode: '',
      productName: '',
      quantity: 0,
      productVariant: null,
      buyPrice: 0,
      buyPriceOverride: null,
      markup: null,
      colour: null,
      additionalCost: 0,
      displayToCustomer: productDisplayToCustomer
    });
    onPriceChanged();
  }, [append, onPriceChanged, productDisplayToCustomer]);

  return (
    <div className={classes.container}>
      <Grid container direction='row' justifyContent='flex-start' alignItems='center' className={classes.itemBuilderHeader}>
        <Grid item><Typography variant='h6' gutterBottom>{`${title}(s)`}</Typography></Grid>
        <Grid item className={classes.infoCell}>
          <Tooltip arrow placement='top' classes={{tooltip: classes.tooltip}} title={
            <>
              {'Add the colors/sizes of the products you’re quoting. '}<br /><br />
              {'Tip: If you don’t know the size/color breakdown, just add the total quantity, leave color/size blank and you can add that information later.'}
            </>
          }><InfoIcon fontSize='small' className={classes.icon} /></Tooltip>
        </Grid>
      </Grid>
      <Divider />
      <Paper elevation={0} className={classes.tableContainer} >
        <Table >
          {fields[0] &&
            <TableHead>
              <TableRow className={classes.tableHeaderRow}>
                <TableCell style={{width: '4.54%', minWidth: 100}}>Product Type</TableCell>
                <TableCell style={{width: '8%', minWidth: 100}}>Product Name</TableCell>
                <TableCell style={{width: '8%', minWidth: 100}}>Vendor</TableCell>
                <TableCell style={{width: '8%', minWidth: 100}}>Code</TableCell>
                <TableCell style={{width: '8%', minWidth: 100}}>Quantity</TableCell>
                <TableCell style={{width: '8%', minWidth: 100}}>Color</TableCell>
                <TableCell style={{width: '8%', minWidth: 100}}>Size</TableCell>
                <TableCell style={{width: '8%', minWidth: 100}}>Buy Price</TableCell>
                <TableCell style={{width: '8%', minWidth: 100}}>Extra Cost/Unit</TableCell>
                <TableCell style={{width: '8%', minWidth: 100}}>Markup (%)</TableCell>
                <TableCell style={{width: '8%', minWidth: 100}}>Sell Price</TableCell>
                <TableCell style={{width: '4.54%', minWidth: 88}}>
                                    <Grid container direction='row' justifyContent='space-between' alignItems='flex-end'>
                                        <Grid item>Display </Grid>
                                        <Grid item className={classes.infoCell}>
                                            <Tooltip arrow placement='top' classes={{tooltip: classes.tooltip}} title={<>Check this option if you would like to display the product name to your customer.</>}><HelpIcon fontSize='small' className={classes.icon} style={{display: 'flex', marginBottom: 4}} /></Tooltip>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                <TableCell style={{width: '4.54%'}} className={classes.responsiveActionButtons}>Clone</TableCell>
                <TableCell style={{width: '4.54%'}} className={classes.responsiveActionButtons}>Delete</TableCell>
                <TableCell style={{width: '4.54%'}} className={classes.responsiveActionMenu}>Actions</TableCell>
              </TableRow>
            </TableHead>
          }
          <TableBody>
            {fields?.map((field, rowIdx) =>
            (
              Object.keys(field)?.length !== 0 &&
              <ItemBuilderProductRow
                key={rowIdx}
                rowIdx={rowIdx}
                field={field}
                variations={variations}
                onDuplicate={handleDuplicate}
                onDelete={handleDelete}
              />
            )
            )}
          </TableBody>
        </Table>
      </Paper>
      <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start'>
        <Grid item xs={2}><Button color='primary' variant={fields[0] ? 'text' : 'outlined'} className={classes.button} onClick={handleAdd}>{`Add ${title}`}</Button></Grid>
        <Grid item xs={10}>{fields[0] && <ProductVariantTotals classes={classes} priceElement={priceElement} />}</Grid>
      </Grid>
    </div>
  );
};

export default ItemBuilderProductTable;
