// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {useLazyQuery} from '@apollo/client';
import deepmerge from 'deepmerge';
import {debounce, get, isEmpty} from 'lodash';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {hoopsQuerySetQuery, hoopsQuerySetQueryFilter, hoopsQuerySetQueryPaging, hoopsQuerySetQuerySort, hoopsQuerySetRefetch, hoopsQuerySetView} from '../../actions/hoopsQuery';
import {client} from '../../servicesOld/GraphQLService';
import HoopsQueryContext from './HoopsQueryContext';
import {useMountEffect} from '../../hooks';
import {putLocalStorageItem, getLocalStorageItem} from '../../utils';

const EMPTY_COLUMNS = [];

/**
* Renders a <HoopsQuery /> component
* @param {Object} props
* @param {string} props.queryStateId - the id we want to use for storing state of the HoopsQuery in Redux
* @param {boolean} props.refetchStateId - the id we want to use for storing state of the HoopsQuery in Redux
* @param {object} props.fixedFilter - preset filter that the user cannot modify
*/

export const HoopsQuery = ({
    queryStateId = undefined,
    refetchStateId = undefined,
    gqlQuery = '',
    initialFilter = {},
    initialSort = '_ID_DESC',
    defaultView = 'table',
    fixedFilter = {}, // This filter can't be chagned by the users filters and is always applied on requests
    initialPaging = {
        page: 1,
        perPage: 25
    },
    resultField = null, // The field to look on data for the results
    children,
    tableContainerElement = undefined,
    columns = EMPTY_COLUMNS,
}) => {
    const dispatch = useDispatch();
    const [itemCount, setItemCount] = useState(250);
    const [_chips, _setChips] = useState({});
    const [items, setItems] = useState([]);
    const [pageInfo, setPageInfo] = useState({});
    const [leftScrollShadow, setLeftScrollShadow] = useState(false);
    const [rightScrollShadow, setRightScrollShadow] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);

    const refetchStateSelector = (state) => refetchStateId ? state.hoopsQueryReducer.refetchState[refetchStateId] : false;
    const refetchState = useSelector(refetchStateSelector);
    const userSelector = (state) => state.authReducer.userData;
    const user = useSelector(userSelector);
    const companySelector = (state) => state.companyReducer.company;
    const company = useSelector(companySelector);
    const queryStateSelector = (state) => state.hoopsQueryReducer.queryState[queryStateId];
    const queryState = useSelector(queryStateSelector);

    if (!queryStateId) {
        throw new Error('You must have a queryStateId defined for HoopsQuery');
    }

    // filter the table with stored status type
    const [statusType, setStatusType] = useState(() => {
        const localStorageStatus = getLocalStorageItem(`${user._id}-${refetchStateId}-statusType`);
        if(typeof localStorageStatus === 'string'){
            return localStorageStatus;
        }
        return 'accountStatus';
    });

    // storing the status type locally -- store the updated columns
    useEffect(() => {
        putLocalStorageItem(`${user._id}-${refetchStateId}-statusType`, statusType);
    }, [statusType, refetchStateId, user._id]);

    // populate the table with stored hidden column array
    const [hoopsQueryColumns, setHoopsQueryColumns] = useState(columns);

    // storing the table settings locally -- store the updated columns
    useEffect(() => {
      const localStorageColumns = getLocalStorageItem(`${refetchStateId}-hiddenColumnsData`);
      if (localStorageColumns?.rev === 3) {
        setHoopsQueryColumns(columns.map((col) => ({...col, hide: localStorageColumns[col.label] ?? false})));
      }
    }, [columns, refetchStateId]);

    useEffect(() => {
      putLocalStorageItem(`${refetchStateId}-hiddenColumnsData`, hoopsQueryColumns.reduce((map, col) => ({...map, [col.label]: col.hide ?? false}), {rev: 3}));
    }, [hoopsQueryColumns, refetchStateId]);

    useMountEffect(() => {
      if (!queryState) {
        dispatch(hoopsQuerySetQuery(queryStateId, {
          filter: deepmerge(initialFilter, fixedFilter),
          page: initialPaging.page,
          perPage: initialPaging.perPage,
          sort: initialSort,
          view: defaultView
        }));
      }
    });

    const setFilter = (filter) => {
        dispatch(hoopsQuerySetQueryFilter(queryStateId, deepmerge(filter, fixedFilter)));
    };

    const setView = (view = 'table') => {

        localStorage.setItem(`${refetchStateId}-view`, JSON.stringify(view));
        const storedView = JSON.parse(localStorage.getItem(`${refetchStateId}-view`));

        dispatch(hoopsQuerySetView(queryStateId, storedView ? storedView : view));
        if (view === 'table' && refetchStateId && queryState) {
            dispatch(hoopsQuerySetRefetch(refetchStateId, true));
            request(queryState);
        }
    };

    const setSort = (sort) => {
        dispatch(hoopsQuerySetQuerySort(queryStateId, sort));
    };

    const setPaging = (payload) => {
        dispatch(hoopsQuerySetQueryPaging(queryStateId, payload));
    };

    const setChips = (chips) => {
        isEmpty(chips) && _setChips(() => ({}));
        _setChips((prev) => ({
                ...prev,
                ...chips
            }));
    };

    // this is what you need to fix!! it's slow
    const handleScroll = () => {
        const scrollValueHorizontal = tableContainerElement && tableContainerElement.current && tableContainerElement.current.scrollLeft;
        const maxScrollValueHorizontal = tableContainerElement && tableContainerElement.current && tableContainerElement.current.scrollWidth - tableContainerElement.current.clientWidth;
        setLeftScrollShadow(scrollValueHorizontal > 0);
        setRightScrollShadow(scrollValueHorizontal < maxScrollValueHorizontal);
    };

    const handleTableSize = () => {
        // set table height
        let tablePositionY = tableContainerElement && tableContainerElement.current && tableContainerElement.current.getBoundingClientRect().y;
        let paginationHeight = 70;
        setContentHeight(tablePositionY + paginationHeight);
    };

    useEffect(() => {
        handleScroll();
        handleTableSize();
        window.addEventListener('resize', handleTableSize);
    });

    const [fetchData, {called, loading, error}] = useLazyQuery(
        gqlQuery,
        {
            client,
            fetchPolicy: 'cache-and-network',
            onCompleted: (data) => {
                const payload = get(data, resultField);
                setItems(payload.items);
                setPageInfo(payload.pageInfo);
                setItemCount(payload.pageInfo.itemCount);
            },
            onError: console.error
        }
    );

    // TODO NEED A useDebounce HOOK
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const request = useCallback(
      debounce(
        (variables) => fetchData({variables}),
        300
      ),
      [fetchData, queryState]
    );

    // refetch due to event
    useEffect(() => {
      if (refetchStateId && refetchState && queryState) {
        dispatch(hoopsQuerySetRefetch(refetchStateId, false));
        request(queryState);
      }
    }, [refetchStateId, refetchState, queryState, request, dispatch]);

    useEffect(() => {
      request(queryState);
    }, [request, queryState]);

    const getKanbanColumns = (entityType, entityMapping = null) => {
        const columns = entityMapping
            ? company.statuses.find((statusGroup) => statusGroup.entityMapping === entityMapping)
            : company.statuses.find((statusGroup) => statusGroup.entityType === entityType);
        return (columns.statuses);
    };

    const providerValue = {
        gqlQuery,
        items,
        hoopsQueryColumns,
        setHoopsQueryColumns,
        pageInfo,
        fetch,
        filter: get(queryState, 'filter', {}),
        setFilter,
        sort: get(queryState, 'sort', '_ID_ASC'),
        setSort,
        chips: _chips,
        setChips,
        view: get(queryState, 'view'),
        setView,
        statusType,
        setStatusType,
        getKanbanColumns,
        paging: {
            perPage: get(queryState, 'perPage', 25),
            page: get(queryState, 'page', 1),
            itemCount
        },
        setPaging,
        isCalled: called,
        isLoading: loading,
        error,
        handleScroll,
        leftScrollShadow,
        rightScrollShadow,
        contentHeight
    };

    return (
        <HoopsQueryContext.Provider value={providerValue}>
            {children}
        </HoopsQueryContext.Provider>
    );
};

export default HoopsQuery;
