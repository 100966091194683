import {pick} from 'lodash';
import {client} from '../../servicesOld/GraphQLService';
import {
  GQL_CREATE_INTEGRATION, GQL_DELETE_INTEGRATION, GQL_GET_INTEGRATION,
  GQL_GET_INTEGRATIONS, GQL_TEST_INTEGRATION, GQL_UPDATE_INTEGRATION
} from './IntegrationQueries';

export default class IntegrationService {

  static _parseIntegrationInput(input) {
    const keys = ['_id', 'type', 'accountId', 'accountKey', 'username'];
    const pruned = pick(input, keys);

    return pruned;
  }

  static async getIntegration(id) {
    return client
      .query({
        query: GQL_GET_INTEGRATION,
        variables: {_id: id,},
        fetchPolicy: 'no-cache',
      })
      .then(({data: {integrationOne}}) => integrationOne);
  }

  static async getIntegrations({...variables}) {
    return client
      .query({
        query: GQL_GET_INTEGRATIONS,
        fetchPolicy: 'network-only',
        variables: {...variables,},
      })
      .then(({data: {integrationMany}}) => integrationMany);
  }

  static async createIntegration(integrationData) {
    return client
      .mutate({
        mutation: GQL_CREATE_INTEGRATION,
        variables: {record: IntegrationService._parseIntegrationInput(integrationData),},
      })
      .then(({data: {integrationCreateOne}}) => integrationCreateOne);
  }

  static async testIntegration(integrationData) {
    return client
      .query({
        query: GQL_TEST_INTEGRATION,
        variables: {record: IntegrationService._parseIntegrationInput(integrationData),},
      })
      .then(({data: {integrationCreateOne}}) => integrationCreateOne)
      .catch((err) => {
        if (err.graphQLErrors && err.graphQLErrors.length > 0 && err.graphQLErrors[0].message) {
          throw new Error(err.graphQLErrors[0].message);
        }
        throw err;
      });
  }

  static async updateIntegration(_id, data) {
    return client
      .mutate({
        mutation: GQL_UPDATE_INTEGRATION,
        variables: {
          _id,
          record: IntegrationService._parseIntegrationInput(data),
        },
      })
      .then(({data: {integrationUpdateById}}) => integrationUpdateById);
  }

  static async deleteIntegration(_id) {
    return client
      .mutate({
        mutation: GQL_DELETE_INTEGRATION,
        variables: {_id,},
      })
      .then(({data: {integrationRemoveById}}) => integrationRemoveById);
  }

  static async promoStandardsFetch(method = '', url = '', data = {}) {
    return await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: method,
      credentials: 'include',
      body: JSON.stringify(data),
    });
  }

  static async promoStandardsGet(method = '', url = '') {
    return await fetch(url, {
      method: method,
      credentials: 'include',
      cache: 'no-cache',
      mode: 'cors',
    });
  }

}

