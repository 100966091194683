// TODO: fix eslint disable
/* eslint-disable no-class-assign, no-shadow */

import React, {Component} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from './shared/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import ChangePasswordForm from './ChangePasswordForm';
import UserService from '../servicesOld/UserService';
import {snackSuccess} from '../actions/action-types/snackbar';
import {connect} from 'react-redux';
import {SubmissionError} from 'redux-form';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme) => ({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(5),
  },
  textBuffer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  loadingBuffer: {marginTop: theme.spacing(4)}
});

class ChangePasswordDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {loading: false};
  }

  async onSubmit(values) {

    const {snackSuccess} = this.props;

    if (values.newPassword.length < 8) {
      throw new SubmissionError({newPassword: 'Password must be at least 8 characters long'});
    }

    if (values.newPassword !== values.confirmNewPassword) {
      throw new SubmissionError({
        newPassword: 'Passwords do not match',
        confirmNewPassword: 'Passwords do not match',
      });
    }

    this.setState({loading: true});

    await UserService.changePassword(values.newPassword)
    .then(() => {
      this.closeDialog();
      snackSuccess('Password reset successfully');
    });
  }

  closeDialog() {
    const {handleClose} = this.props;
    this.setState({loading: false});
    handleClose();
  }

  render() {
    const {open, classes} = this.props;
    const {loading} = this.state;
    return (
      <Dialog
        onClose={this.closeDialog.bind(this)}
        aria-labelledby='customized-dialog-title'
        open={open}
        maxWidth={ 'sm' }
        fullWidth={true}
      >
        <DialogTitle onClick={this.closeDialog.bind(this)}>
        </DialogTitle>
        <MuiDialogContent className={classes.contentContainer}>
          <Typography variant='h5'>Change password</Typography>
          <Typography className={classes.textBuffer}>Enter your new password below to reset your password</Typography>
          <ChangePasswordForm onSubmit={this.onSubmit.bind(this)}/>
          {loading ? <CircularProgress className={classes.loadingBuffer} /> : null}
        </MuiDialogContent>
      </Dialog>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {snackSuccess: (message) => dispatch(snackSuccess(message))};
}

ChangePasswordDialog = connect(null, mapDispatchToProps)(ChangePasswordDialog);

export default withStyles(styles)(ChangePasswordDialog);
