// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import {useQuery} from '@apollo/client';
import {makeStyles, MenuItem, Select, TableCell} from '@material-ui/core';
import gql from 'graphql-tag';
import React, {useState} from 'react';
import {Field} from 'redux-form';
import {HoopsTextField} from '../../../HoopsTextField';
import {itemBuilder} from '../../../../ui/theme/itemBuilder';
import {ItemBuilderProductAutocomplete} from '../../../Products/productAutocomplete/ItemBuilderProductAutocomplete';
import SageProductSelect from '../../../Products/productAutocomplete/SageProductSelect';

// is user integrated with sage
const SAGE_INTEGRATION_ACTIVE = gql`
query integrationOne{
  integrationOne(filter:{type:sage}){
      _id
      status
  }
}`;

const useStyles = makeStyles((theme) => ({
  ...itemBuilder,
  tableCell: {padding: theme.spacing(1)},
}));

export const ItemBuilderProductField = ({
  handleProductSelected = () => null,
  productType = 'hoops',
  handleProductTypeChange = () => null,
  rowFields,
  field,
  rowIdx,
  margin,
}) => {
  const classes = useStyles();

  const [isSageActive, setIsSageActivee] = useState(false);

  // check for sage integration
  useQuery(SAGE_INTEGRATION_ACTIVE, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (result) => setIsSageActivee(result && result.integrationOne && result.integrationOne.status === 'active'),
  });

  return (
    <>
      <TableCell scope='row' className={classes.tableCell} >
        <Select
          variant='outlined'
          margin='dense'
          size='small'
          style={{minWidth: 100}}
          className={`${classes.selectStyles} ${margin === 'dense' && classes.marginDense}`}
          value={productType}
          onChange={(e) => handleProductTypeChange(e.target.value)}
        >
          <MenuItem value='hoops'>Hoops</MenuItem>
          {isSageActive && <MenuItem value='sage'>Sage</MenuItem>}
          <MenuItem value='oneOff'>One Off</MenuItem>
        </Select>
      </TableCell>
      <TableCell scope='row' className={classes.tableCell} style={{width: '20%'}}>
        {productType === 'hoops' &&
          <ItemBuilderProductAutocomplete
            defaultValue={rowFields.productName}
            isSageActive={isSageActive}
            onSelect={handleProductSelected}
            className={margin === 'dense' && classes.marginDense}
          />
        }
        {productType === 'sage' &&
          <SageProductSelect
            component={SageProductSelect}
            onSelect={handleProductSelected}
            style={{marginTop: 8, marginBottom: 4}}
          />
        }
        {productType === 'oneOff' &&
          <Field
            name={`${field}.productName`}
            data-intercom-target={'job-item-variant-product-name-field'}
            component={HoopsTextField}
            style={{marginTop: 8, marginBottom: 4}}
          />
        }
      </TableCell>
    </>
  );
};
