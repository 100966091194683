// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-shadow */

import {Divider, IconButton, InputAdornment, makeStyles, MenuItem, Select} from '@material-ui/core';
import {Cancel} from '@material-ui/icons';
import React, {useCallback, useContext, useState} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {HoopsTextField} from '../../../componentsOld/HoopsTextField';
import {useWatch} from '../../../hooks';
import {itemBuilder} from '../../../ui/theme/itemBuilder';
import {ItemBuilderContext} from '../ItemBuilderContext';

const useStyles = makeStyles(() => ({
  ...itemBuilder,
  textFieldWithAdornmentBtn: {'& .MuiOutlinedInput-adornedEnd': {paddingRight: 0,}}
}));

const ColorField = ({
  field,
  rowIdx,
  defaultValue,
  ...params
}) => {
  const classes = useStyles();
  const {onPriceChanged} = useContext(ItemBuilderContext);
  const {control, setValue, watch} = useFormContext();
  const product = watch(`variations[${rowIdx}].product`);

  const [isCustom, setIsCustom] = useState(false);

  // So, with all the updates some data in the form does not sync up straight away, this is one of them
  defaultValue = defaultValue ?? field?.colour;

  const clearField = useCallback(() => {
    setValue(`variations[${rowIdx}].colour`, '');
    setIsCustom(false);
  }, [rowIdx, setValue]);

  const customCheck = useCallback(() => {
    if (product?.colors.findIndex((element) => element.name === defaultValue) === -1 && defaultValue !== '') {
      setIsCustom(true);
    }
  }, [defaultValue, product]);

  useWatch(() => {
    if(product?.colors && defaultValue?.length > 0){
      customCheck();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product?.colors]);

  return (
    <>
      {isCustom
        ? <Controller
          name={params.name}
          defaultValue={defaultValue}
          render={(field) => (
            <HoopsTextField
              className={classes.textFieldWithAdornmentBtn}
              placeholder='Enter custom color'
              InputProps={{
                endAdornment:
                  <InputAdornment position={'end'}>
                    <IconButton onClick={clearField}>
                      <Cancel fontSize='small' className={classes.greyText} />
                    </IconButton>
                  </InputAdornment>
              }}
              {...field}
            />
          )}
          control={control}
        />
        : <Controller
          name={params.name}
          defaultValue={defaultValue}
          render={(field) => (
            <Select
              margin='dense'
              size='small'
              variant='outlined'
              fullWidth
              disabled={!product?._id}
              className={classes.selectStyles}
              {...field}
              onChange={(e) => { onPriceChanged(); field.onChange(e); }}
              {...params}
            >
              {product?._id && product?.colors.map((o) => (
                <MenuItem key={o._id} value={o.name}>{o.name}</MenuItem>
              ))}
              <Divider light style={{marginBottom: 8}} />
              <MenuItem onClick={() => setIsCustom(true)}>Use custom color</MenuItem>
            </Select>
          )}
          control={control}
        />}
    </>
  );
};

export default ColorField;
