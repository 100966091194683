// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-shadow */

import {Button, Dialog, DialogActions, DialogContent, Grid, Tooltip, Typography, makeStyles, CircularProgress,} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import React, {useState, useEffect} from 'react';
import {HoopsTextField} from '../../componentsOld/HoopsTextField';
import {editEntity} from '../../ui/theme/editEntity';
import theme from '../../ui/theme';
import {useFormContext} from 'react-hook-form';

const useStyles = makeStyles((theme) => ({...editEntity,}));

const SaveConfiguredItemModal = ({
    isLoading = false,
    open = false,
    onSave = () => null,
    onClose = () => null
}) => {
    const classes = useStyles();
    const [configProductValues, setConfigItemValues] = useState({
        code: '',
        name: '',
    });
    const [touched, setTouched] = useState(false);
    const [errors, setErrors] = useState({});
    const [isValid, setIsValid] = useState(false);

    const {watch} = useFormContext();
    const priceElement = watch();

    useEffect(() => {
        touched && setIsValid(!Object.keys(errors).length > 0);
    }, [errors, touched]);

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setTouched(true);

        setConfigItemValues((prev) => ({...prev, [name]: value}));

        //clearing validation errors
        if ((value !== '' || value)) {
            setErrors((prev) => {
                delete prev[name];
                return {...prev};
            });
            return;
        }
    };

    const handleValidate = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        const required = e.target.required;

        if ((value === '' || !value) && required) {
            setErrors((prev) => ({...prev, [name]: 'This field is required'}));
            return;
        }

        setErrors((prev) => {
            delete prev[name];
            return {...prev};
        });
    };

    return (
        <Dialog
            onClose={onClose}
            open={open}
            maxWidth={'sm'}
        >

            <DialogContent className={classes.contentContainer}>
                <Typography variant='h5'>Save Configuration as a Product</Typography>
                <Typography variant='body2' className={classes.greyText}>Use this feature to save the configuration of this item builder to the catalog.
                    This product can be reused on future quotes and jobs.
                    The item builder can be completely edited on future quotes/jobs if necessary.</Typography>

                <Grid container spacing={2} style={{marginTop: 16, marginBottom: 16}}>
                    <Grid item sm={6} xs={12}>
                        <HoopsTextField
                            label={
                                <>
                                    Unique Product Code/Identifier
                                    <Tooltip
                                        arrow
                                        style={{position: 'absolute', marginTop: -2}}
                                        title={
                                            <Typography variant='caption' display='block'>
                                                Give this product a unique code or identifier to help get the right search results.
                                            </Typography>}
                                    >
                                        <InfoIcon fontSize='small' className={classes.icon} />
                                    </Tooltip>
                                </>
                            }
                            defaultValue={configProductValues.code}
                            name={'code'}
                            onChange={handleChange}
                            onBlur={handleValidate}
                            error={errors.code}
                            helperText={errors.code && errors.code}
                            required
                            autoFocus
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <HoopsTextField
                            label='Product Name'
                            defaultValue={configProductValues.name}
                            name={'name'}
                            onChange={handleChange}
                            onBlur={handleValidate}
                            error={errors.name}
                            helperText={errors.name && errors.name}
                            required
                        />
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button
                    color={'secondary'}
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <Button
                    variant='contained'
                    disabled={isLoading || !isValid}
                    color='primary'
                    onClick={() => onSave(configProductValues, priceElement)}
                >
                    {isLoading ? <><CircularProgress size={16} style={{marginRight: theme.spacing(1)}} />Save</> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SaveConfiguredItemModal;
