import React, {useContext, useRef, useState} from 'react';
import {Button, ButtonGroup, MuiThemeProvider} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import green from '../../ui/theme/green';
import {ItemBuilderContext} from './ItemBuilderContext';
import {PopupItem, PopupMenu} from '../../componentsLib/Popovers';

export const BuildItemButton = ({
  item = {},
  index = null,
  setDialogOpen = () => null,
  setProductSearch = () => null,
}) => {
  const {
    setItem,
    setOpen,
    setItemIndex,
    setPriceElement,
    setFormName
  } = useContext(ItemBuilderContext);

  const [openMenu, setOpenMenu] = useState(false);
  const anchorRef = useRef(null);
  const hasPriceElement = !!item?.priceElement?._id;

  const handleBuildItem = () => {
    const priceElement = item.priceElement || {}; // we don't want undefined or null because the form doesn't get changed

    // If we have a product associated and we've not saved this before then add an empty variation line
    const payload = !hasPriceElement ? {} : priceElement;

    setItem(item);
    setPriceElement(payload);
    setOpen(true);
    setItemIndex(index);
    setFormName('quoteForm');
  };

  const handleAddConfiguration = () => {
    setDialogOpen(true);
    setOpenMenu(false);
  };

  const handleToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenMenu(false);
  };

  const handleProductSearch = () => {
    setProductSearch(true);
  };

  return (
    <>
      <MuiThemeProvider theme={green}>
        <ButtonGroup disableElevation color='primary' variant='contained' ref={anchorRef} aria-label='split button'>
          <Button style={{marginBottom: 1, whiteSpace: 'nowrap', borderRight: 'none', marginRight: 1, minWidth: 110}} onClick={handleBuildItem}>
            {hasPriceElement ? 'Edit Item' : 'Build Item'}
          </Button>
          <Button
            color='primary'
            size='small'
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label='select merge strategy'
            aria-haspopup='menu'
            onClick={handleToggle}
            style={{marginBottom: 1, whiteSpace: 'nowrap'}}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
      </MuiThemeProvider>
      <PopupMenu open={openMenu} anchorEl={anchorRef.current} onClose={handleClose}>
        <PopupItem text='Build Item' onClick={handleBuildItem} />
        <PopupItem text='Add a pre-built Item' onClick={() => handleAddConfiguration()} />
        <PopupItem text='Switch to Product Search' onClick={() => handleProductSearch()}/>
      </PopupMenu>
    </>
  );
};
