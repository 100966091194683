export const decimalPlaces = (n, decimals) => {
  let negative = false;
  if (decimals == null) { throw new Error('func decimalPlaces() requires a decimal places argument'); }
  if (n < 0) {
    negative = true;
    n = n * -1;
  }
  const multiplicator = (10 ** decimals);
  n = (n * multiplicator).toFixed(11);
  n = (Math.round(n) / multiplicator).toFixed(decimals);
  if (negative) {
    n = (n * -1).toFixed(decimals);
  }
  return n;
};

export const toFloat = (number) => {
  // return 0 if param is not defined
  if (!number) { return (0); }
  // remove commas from formatted numbers
  const cleanedString = String(number).replace(/,/g, '');
  // convert string to number
  const cleanedNumber = parseFloat(cleanedString);
  // if the result is NaN return 0
  if (Number.isNaN(cleanedNumber)) { return (0); }
  return cleanedNumber;
};
