import {Row} from '../Layout';
import {Checkbox as MuiCheckbox} from '@mui/material';
import {CaptionText} from '../Text';
import classNames from 'classnames';
import {HoopsPropTypes} from '../utils';
import React from 'react';
import {registerGlobalStyle} from '../../theme';

registerGlobalStyle('.checkbox', (theme) => ({
  'svg': {color: theme.colors.text.mediumIcon},
  '&.Mui-checked svg, .Mui-checked svg': {color: theme.colors.text.highlight},
  '.text-caption': {color: theme.colors.text.main},
}));

export function Checkbox({className, label, checked, onChange, onClick, children}) {
  if (label || children) {
    return (
      <Row className={[className, 'checkbox']} tag={'label'} alignCenter>
        <MuiCheckbox checked={checked} onChange={onChange} onClick={onClick} disableRipple/>
        <CaptionText>{label ?? children}</CaptionText>
      </Row>
    );
  } else {
    return <MuiCheckbox className={classNames([className, 'checkbox'])} checked={checked} onChange={onChange} onClick={onClick} disableRipple/>;
  }
}

Checkbox.propTypes = {
  className: HoopsPropTypes.className,
  label: HoopsPropTypes.string,
  checked: HoopsPropTypes.bool,
  onChange: HoopsPropTypes.func,
  children: HoopsPropTypes.children,
};
