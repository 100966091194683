import IntegrationService from './IntegrationService';
import {hoopsQuerySetRefetch} from '../../actions/hoopsQuery';
export const SET_INTEGRATION = 'SET_INTEGRATION';
export const SET_INTEGRATION_LOADING = 'SET_INTEGRATION_LOADING';
export const UPDATE_INTEGRATION = 'UPDATE_INTEGRATION';
export const CREATE_INTEGRATION = 'CREATE_INTEGRATION';
export const REMOVE_INTEGRATION = 'REMOVE_INTEGRATION';
export const UPDATE_INTEGRATIONS = 'UPDATE_INTEGRATIONS';
export const MODAL_INTEGRATION = 'MODAL_INTEGRATION';

export function editNewIntegration(payload = {}) {
  return function(dispatch) {
    dispatch({type: SET_INTEGRATION, payload}); // new empty integration and merge any data
    dispatch(setModal(true)); // open modal
    return payload;
  };
}

export function setModal(isOpen = false) {
  return function(dispatch) {
    dispatch({type: MODAL_INTEGRATION, payload: isOpen});
    return isOpen;
  };
}

export function getIntegrations(variables, cb) {
  return function(dispatch) {
    return IntegrationService.getIntegrations(variables).then((payload) => {
      if (cb) {
        cb(payload);
      }
      dispatch({type: UPDATE_INTEGRATIONS, payload: payload});
      return payload;
    });
  };
}

export function createIntegration(data, cb) {
  return function(dispatch) {
    return IntegrationService.createIntegration(data).then((file) => {
      if (cb) {
        cb(file);
      }
      dispatch({type: CREATE_INTEGRATION, payload: file});
      dispatch(hoopsQuerySetRefetch('integration', true));
      return file;
    });
  };
}

export function getIntegration(id, cb) {
  return function(dispatch) {
    dispatch({type: SET_INTEGRATION_LOADING, payload: true});
    return IntegrationService.getIntegration(id).then((payload) => {
      dispatch({
        type: SET_INTEGRATION,
        payload,
      });
      dispatch({type: SET_INTEGRATION_LOADING, payload: false});
      if (cb) {
        cb(payload);
      }

      return payload;
    });
  };
}

export function updateIntegration(id, data, cb) {
  return function(dispatch) {
    return IntegrationService.updateIntegration(id, data).then((payload) => {
      dispatch({type: UPDATE_INTEGRATION, payload});
      dispatch(hoopsQuerySetRefetch('integration', true));
      if (cb) {
        cb(payload);
      }
      return payload;
    });
  };
}

export function setDefault(id) {
  return function (dispatch) {
      return IntegrationService.setDefault(id).then((payload) => {
          dispatch(hoopsQuerySetRefetch('integration', true));
          return payload;
      });
  };
}

export function deleteIntegration(id) {
  return function(dispatch) {
    return IntegrationService.deleteIntegration(id).then((d) => {
      dispatch({type: REMOVE_INTEGRATION, payload: id});
      dispatch(hoopsQuerySetRefetch('integration', true));
      return d;
    });
  };
}
