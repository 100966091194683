import {Avatar, Grid, Link, Typography} from '@material-ui/core';
import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {initials} from '../utils';

function RenderComponent({name, color}) {
  return (
    <Grid container alignItems={'center'} spacing={2} wrap='nowrap'>
      <Grid item>
        <Avatar style={{backgroundColor: color}}>
          {initials(name)}
        </Avatar>
      </Grid>
      <Grid item>
        <Typography variant={'body2'} style={{whiteSpace: 'nowrap'}}>{name}</Typography>
      </Grid>
    </Grid>
  );
}

class NameWithInitials extends React.Component {
  render() {
    const {name, color, to} = this.props;

    if (to) {
      return (<Link data-intercom-target={'customers-table-name'}
                    color={'textPrimary'}
                    underline={'none'}
                    component={RouterLink}
                    to={to}>
        <RenderComponent name={name} color={color} />
      </Link>);
    }
    return <RenderComponent name={name} color={color}/>;
  }
}

export default NameWithInitials;
