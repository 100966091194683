import {
  CREATE_FILE,
  UPDATE_FILE,
  REMOVE_FILE,
  UPDATE_FILES
} from '../actions/files';

const initialState = {list: []};

const filesReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    
    case UPDATE_FILES:
      return {
        ...state,
        list: payload
      };

    case CREATE_FILE:
      return {
        ...state,
        list: [...state.list, payload]
      };

    case UPDATE_FILE:
      return {
        ...state,
        list: state.list.map(
          (item) => item._id === payload._id ? payload : item
        )
      };

    case REMOVE_FILE:
      return {
        ...state,
        list: state.list.filter(
          (item) => item._id === payload ? false : true
        )
      };

    default:
      return state;
  }
};

export default filesReducer;
