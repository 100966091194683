import {useMutation} from '@apollo/client';
import {CircularProgress, Icon, IconButton, ListItemIcon, Menu, MenuItem} from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {hoopsQuerySetRefetch} from '../../../actions/hoopsQuery';
import {GQL_DELETE_CONFIGURED_PRODUCT} from '../../../queries/configuredProducts';
import theme from '../../../ui/theme';

const ConfiguredProductActionMenu = ({configuredProduct = {},}) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [deleteConfiguredProduct, {loading}] = useMutation(GQL_DELETE_CONFIGURED_PRODUCT, {
    onCompleted: (result) => {
      if (result) {
        setAnchorEl(null);
        dispatch(hoopsQuerySetRefetch('configuredProduct', true));
      }
    },
  });

  const handleDelete = () => {
    deleteConfiguredProduct({variables: {_id: configuredProduct._id}});
  };

  return (
    <>
      <IconButton
        size={'small'}
        onClick={handleOpen}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleDelete}>
          {loading
            ? <CircularProgress size={16} style={{marginRight: theme.spacing(1)}} />
            : <ListItemIcon>
              <Icon>delete</Icon>
            </ListItemIcon>
          }

          Delete
        </MenuItem>
      </Menu>
    </>
  );

};

export default ConfiguredProductActionMenu;

