import * as React from 'react';
import {Grid, Typography} from '@mui/material';
import {ImageThumbnail, StatusLabel} from '../../componentsLib/Basic';
import {ActivityEmailRenderer, ActivitySmsRenderer} from './activityRenderers';
import {PROOF_STATUSES} from '../../constants/status';

const proofStyles = {
  '&.proof-item': {gridColumn: '1 / 3'},
  alignItems: 'center',
  gap: '16px',
};

export const proofsRenderers = {
  'proofs.CREATE': {
    renderer: ProofRenderer,
    heading: 'Uploaded a Proof'
  },
  'proofs.DELETE': {
    renderer: ProofRenderer,
    heading: 'Deleted a Proof'
  },
  'proofs.UPDATE': {
    renderer: ProofRenderer,
    heading: ({activity}) => {
      if (activity.changes?.status?.[1] === 'APPROVED') {
        return 'Approved a Proof';
      } else if (activity.changes?.status?.[1] === 'REJECTED') {
        return 'Rejected a Proof';
      } else {
        return 'Added a note to a Proof';
      }
    }
  },
  'proofs.EMAIL_SENT': {
    renderer: ActivityEmailRenderer,
    heading: 'Sent Proofs by email'
  },
  'proofs.SMS_SENT': {
    renderer: ActivitySmsRenderer,
    heading: 'Sent Proofs by sms'
  },
};

function ProofRenderer({activity: {body, changes}}) {
  const newNote = changes?.notes?.[1]?.length > changes?.notes?.[0]?.length ? changes.notes[1][changes.notes[1].length - 1] : null;

  const color = PROOF_STATUSES.find((s) => s.name === body.status)?.color ?? 'green';

  return (
    <Grid className={'proof-item'} container direction={'row'} sx={proofStyles}>
      <Grid className={'activity-fields'}>
      {body?.actionedByRecipient &&
        <>
          <Typography variant={'caption'}>Actioned by</Typography>
          <Typography variant={'body2'}>{body?.actionedByRecipient}</Typography>
        </>
      }
      {newNote &&
        <>
          <Typography variant={'caption'}>Note</Typography>
          <Typography variant={'body2'}>{newNote.content}</Typography>
        </>
      }
      {newNote?.assigneeName &&
        <>
          <Typography variant={'caption'}>Assigned to</Typography>
          <Typography variant={'body2'}>{newNote.assigneeName}</Typography>
        </>
      }
      </Grid>
      <Grid container direction={'row'} sx={proofStyles}>
        <ImageThumbnail contentType={body.contentType} hoverZoom imageUrl={body.url} size={100}/>
        <Typography variant={'body2'}>{body.filename}</Typography>
        <StatusLabel color={color}>{body.status}</StatusLabel>
      </Grid>
    </Grid>
  );
}
