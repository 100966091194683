// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React, {Component, Fragment} from 'react';
import {Typography} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = (theme) => ({titleMargin: {padding: theme.spacing(2)}});

class FormBlock extends Component {
  render() {
    const {title, children, classes} = this.props;
    return (
      <Fragment>
        <Paper>
          <Typography variant={'h6'} className={classes.titleMargin}>{title}</Typography>
          {children}
        </Paper>
      </Fragment>
    );
  }
}

FormBlock = withStyles(styles)(FormBlock);
export default FormBlock;
