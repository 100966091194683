import {initTemplateSubstitutions} from './templateSubstitutions';

export const salesDocSubstitutions = initTemplateSubstitutions({
  number: {name: 'Document Number'},
  docTypeName: {name: 'Document Type'},
  reference: {},
  'creator.firstName': {name: 'Creator First Name', field: 'createdBy.firstName', group: 'salesDoc.creator'},
  'creator.lastName': {name: 'Creator Last Name', field: 'createdBy.lastName', group: 'salesDoc.creator'},
  'creator.email': {name: 'Creator Email', field: 'createdBy.email', group: 'salesDoc.creator'},
  'owner.firstName': {name: 'Owner First Name', group: 'salesDoc.owner'},
  'owner.lastName': {name: 'Owner Last Name', group: 'salesDoc.owner'},
  'owner.email': {name: 'Owner Email', group: 'salesDoc.owner'},
  'contact.firstName': {name: 'Contact First Name', group: 'salesDoc.customer'},
  'contact.lastName': {name: 'Contact Last Name', group: 'salesDoc.customer'},
  'contact.email': {name: 'Contact Email', group: 'salesDoc.customer'},
  'contact.company.name': {name: 'Company Name', field: 'customer.name', group: 'salesDoc.customer'},
  'contact.company.email': {name: 'Company Email', field: 'customer.email', group: 'salesDoc.customer'},
}, 'salesDoc.details');
