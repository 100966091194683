import {Grid, makeStyles, Typography} from '@material-ui/core';
import {get} from 'lodash';
import * as numeral from 'numeral';
import React from 'react';
import {RenderRate} from './VariantsTable';

const useStyles = makeStyles((theme) => ({
  total: {
    fontWeight: '500',
    marginLeft: theme.spacing(2)
  },
  leftBuffer: {marginLeft: theme.spacing(2)},
}));

const Totals = ({
  purchaseOrder,
  company
}) => {
  const classes = useStyles();
  const taxes = get(purchaseOrder, 'taxes', []);

  return (
    <Grid container direction='column' justifyContent='center' spacing={2}>
      <Grid item>
        <Typography align='right' className={classes.leftBuffer}>
          SUBTOTAL <RenderRate company={company} rate={get(purchaseOrder, 'subTotal')} />
        </Typography>
      </Grid>
      {taxes.map(({tax, total}) => (
        <Grid key={tax.name} item>
          <Typography align='right' className={classes.leftBuffer}>
            {tax.name} <RenderRate company={company} rate={total} />
          </Typography>
        </Grid>
      ))}
      <Grid item>
        <Typography className={classes.total} align='right'>
          TOTAL {company && company.currencySymbol} {numeral(purchaseOrder && purchaseOrder.total).format('0,0.00')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Totals;
