import React from 'react';
import {useSelector} from 'react-redux';
import HoopsQueryFilterList from './HoopsQueryFilterList';

export const HoopsQueryFilterUser = ({
    label = '',
    value = {},
    onChange = () => null,
}) => {
    const usersSelector = (state) => state.authReducer.users;
    const users = useSelector(usersSelector) || [];
    const options = users.map((user) => ({value: user._id, label: user.fullName}));

    return (
        <HoopsQueryFilterList label={label} options={options} value={value} onChange={onChange} />
    );
};

export default HoopsQueryFilterUser;
