// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import {Button, Grid, IconButton, InputAdornment, TableCell, TableRow, TextField, Typography, withStyles} from '@material-ui/core';
import {Delete} from '@material-ui/icons';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {get} from 'lodash';
import numeral from 'numeral';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {change as _change, Field, getFormValues} from 'redux-form';
import {reduceElement} from '../../../purchase-orders/CalculationMethods';
import {renderTextField} from '../../formHelpers/fields';
import {DecorationField} from '../DecorationField';

const styles = (theme) => ({tablePadding: {padding: theme.spacing(1)}});

class DecorationsTableRow extends Component {
  handleAddMoreClick = () => {
    const {fields} = this.props;
    fields.push({});
  };

  handleDeleteRowClick = (index) => {
    const {fields} = this.props;
    fields.splice(index, 1);
  };

  handleDuplicateRowClick = (field) => {
    const {fields, formValues} = this.props;
    const newRow = get(formValues, field, {});
    delete newRow._id;

    fields.push(newRow);
  };

  calculateTotal = (index) => {
    const {formValues} = this.props;
    const {quantity, rate} = formValues.decorations[index];
    let total = '';
    if (quantity && rate) {
      total = numeral(quantity * rate).format('0,0.00');
    }
    return total;
  };

  render() {
    const {classes, fields, formValues, poFormValues: {locked}, currencySymbol} = this.props;
    const decorationsTotal = numeral(formValues.decorations.reduce(reduceElement, 0)).format('0,0.00');

    return (
      <Fragment>
        {fields.map((field, index) => (
            <TableRow key={index}>
              <TableCell className={classes.tablePadding}>
                <Field name={`${field}.quantity`}
                       component={renderTextField}
                       variant={'outlined'}
                       margin={'dense'}
                       disabled={locked}
                       InputProps={{
                         type: 'number',
                         step: 1
                       }}/>
              </TableCell>
              <TableCell className={classes.tablePadding}>
                <DecorationField field={field} rateColumn='rate' {...this.props} />
              </TableCell>
              <TableCell className={classes.tablePadding}>
                <Field name={`${field}.position`}
                        component={renderTextField}
                        variant={'outlined'}
                        margin={'dense'}/>
              </TableCell>
              <TableCell className={classes.tablePadding}>
                <Field name={`${field}.colours`}
                        component={renderTextField}
                        variant={'outlined'}
                        margin={'dense'}/>
              </TableCell>
              <TableCell className={classes.tablePadding}>
                <Field name={`${field}.size`}
                        component={renderTextField}
                        variant={'outlined'}
                        margin={'dense'}/>
              </TableCell>
              <TableCell className={classes.tablePadding}>
                <Field name={`${field}.rate`}
                        component={renderTextField}
                        variant={'outlined'}
                        margin={'dense'}/>
              </TableCell>
              <TableCell className={classes.tablePadding}>
                <TextField
                  variant='outlined'
                  margin='dense'
                  disabled={true}
                  InputProps={{
                    value: this.calculateTotal(index),
                    startAdornment: <InputAdornment position='start'>{currencySymbol}</InputAdornment>
                  }}
                  />
              </TableCell>
              <TableCell className={classes.tablePadding}>
              <Grid container wrap='nowrap'>
                <Grid item>
                  <IconButton onClick={() => this.handleDuplicateRowClick(field)}>
                    <FileCopyIcon color={'secondary'}/>
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton onClick={() => this.handleDeleteRowClick(index)}>
                    <Delete color={'secondary'}/>
                  </IconButton>
                </Grid>
              </Grid>
              </TableCell>
            </TableRow>
          ))}
        <TableRow>
          <TableCell className={classes.tablePadding} colSpan={5}>
            {!locked &&
              <Button
                component={'div'}
                variant={'text'}
                color={'primary'}
                onClick={this.handleAddMoreClick}
              >
                {fields.length ? 'Add Another Decoration' : 'Add a Decoration'}
              </Button>}
          </TableCell>
          <TableCell className={classes.tablePadding}>
            <Typography variant={'body2'}
                        align={'right'}
                        color={'textSecondary'}>Sub Total</Typography>
          </TableCell>
          <TableCell className={classes.tablePadding}>
            <TextField disabled
                       className={classes.subTotalInput}
                       variant={'outlined'}
                       value={decorationsTotal}
                       margin={'dense'}
                       fullWidth={true}
                       InputProps={{startAdornment: <InputAdornment position={'start'}>{currencySymbol}</InputAdornment>}}

            />
          </TableCell>
          <TableCell/>
        </TableRow>
      </Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    formValues: getFormValues(ownProps.meta.form)(state),
    poFormValues: getFormValues('purchaseOrderForm')(state),
    currencySymbol: state.authReducer.userData.company.currencySymbol
  };
}

function mapDispatchToProps(dispatch) {
  return {change: (form, field, value) => dispatch(_change(form, field, value))};
}

DecorationsTableRow = connect(mapStateToProps, mapDispatchToProps)(DecorationsTableRow);
DecorationsTableRow = withStyles(styles)(DecorationsTableRow);
export default DecorationsTableRow;
