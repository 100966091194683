import {pick, sortBy} from 'lodash';
import {
  GQL_CREATE_DECORATION, GQL_DECORATION_CATEGORY_SEARCH, GQL_DELETE_DECORATION, GQL_GET_DECORATION,
  GQL_GET_DECORATIONS,
  GQL_IMPORT_DECORATIONS, GQL_SEARCH_MANY_DECORATIONS, GQL_UPDATE_DECORATION
} from '../queries/decorations';
import {client} from './GraphQLService';
import {toFloat} from './ItemBuilderServices';

export class DecorationService {
  constructor(decoration) {
    this.decoration = decoration;
  }

  static decorationImport({fileId, vendorId}) {
    return client.mutate({
      mutation: GQL_IMPORT_DECORATIONS,
      variables: {
        fileId: fileId,
        vendorId: vendorId
      }
    }).then(({data: {decorationImport}}) => decorationImport);
  }

  static _parseDecorationInput(decoration) {
    const keys = ['name', 'category', 'vendorId', 'priceBreaks', 'setupPrice'];

    const payload = pick(decoration, keys);
    return {
      ...payload,
      priceBreaks: payload.priceBreaks.map(
        (priceBreak) => ({
          ...priceBreak,
          quantity: parseInt(priceBreak.quantity),
          price: parseFloat(priceBreak.price)
        })
      ),
      setupPrice: parseFloat(decoration.setupPrice) || null,
    };
  }

  static getDecoration(_id) {
    return client.query({
      query: GQL_GET_DECORATION,
      variables: {_id}
    })
      .then(({data: {decorationById}}) => decorationById);
  }

  static getDecorations({q = null, page = 1, perPage = 100} = {}) {
    return client.query({
      query: GQL_GET_DECORATIONS,
      variables: {
        filter: {q},
        page,
        perPage
      }
    })
      .then(({data: {decorationPagination}}) => decorationPagination);
  }

  static async create(decoration) {
    const result =  await client.mutate({
      mutation: GQL_CREATE_DECORATION,
      variables: {record: DecorationService._parseDecorationInput(decoration)}
    });

    return result.data.decorationCreateOne;
  }

  static search(q) {
    return client.query({
      query: GQL_SEARCH_MANY_DECORATIONS,
      variables: {
        filter: {q},
        limit: 5
      }
    })
      .then(({data: {decorationMany}}) => decorationMany);
  }

  static searchCategories(searchString) {
    return client.query({
      query: GQL_DECORATION_CATEGORY_SEARCH,
      variables: {
        filter: {q: searchString},
        limit: 5
      }
    })
      .then(({data: {decorationCategories}}) => decorationCategories);
  }

  getPrice(quantity) {
    const {decoration} = this;
    if (decoration && decoration.priceBreaks && decoration.priceBreaks.length) {
      const breaks = sortBy(decoration.priceBreaks, 'quantity');

      let price = toFloat(breaks[0].price);
      breaks.forEach((pb) => {
        if(quantity >= pb.quantity) {
          price = toFloat(pb.price);
        }
      });
      return price;
    }
    return undefined;
  }

  async delete() {
    return client.mutate({
      mutation: GQL_DELETE_DECORATION,
      variables: {_id: this.decoration._id},
    });
  }

  async save() {
    if (this.decoration._id) {
      const {data} = await client.mutate({
        mutation: GQL_UPDATE_DECORATION,
        variables: {
          _id: this.decoration._id,
          record: DecorationService._parseDecorationInput(this.decoration)
        }
      });

      return data;
    } else {
      return DecorationService.create(this.decoration);
    }
    
  }
}
