import {Dialog} from '@material-ui/core';
import React, {useState} from 'react';
import {capitalize} from '../../utils/capitalize';
import DialogTitle from '../shared/DialogTitle';
import NotificationForm from './NotificationForm';

export const NotificationModal = ({children, entity, defaultValues, onSubmit}) => {
  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  return (
    <>
      {children({open})}
      <Dialog open={isOpen} maxWidth={'lg'}>
        <div style={{minWidth: 'min(430px, 90vw)'}}/>
        <DialogTitle onClick={close}>
          <h3>Send {capitalize(entity)}</h3>
        </DialogTitle>
        <NotificationForm defaultValues={defaultValues} onClose={close} onSubmit={
          async (model) => {
            await onSubmit(model);
            close();
          }} entity={entity}/>
      </Dialog>
    </>
  );
};

