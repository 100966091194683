import {gql} from 'apollo-boost';

const GQLF_SINGLE_PO_FRAGMENT = gql`
  fragment SinglePoFragment on PurchaseOrder {
    _id
    relatedPurchaseOrderId
    relatedPurchaseOrder{
        _id
        number
    }
    dueDate
    isMasterPurchaseOrder
    masterPurchaseOrderId
    masterPurchaseOrder {
      number
    }
    childPurchaseOrders {
      _id
      number
    }
    childPurchaseOrderIds
    number
    title
    createdAt
    deadlineAt
    customReference
    ownerId
    owner {
      fullName
    }
    status

    vendorId
    linkedJobIds
    notes
    shipTo {
      name
      attention
      phone
      email
      address {
        _id
        address1
        address2
        city
        state
        postcode
        country
        formattedAddress
      }
    }
    subTotal
    taxes {
      tax {
        name
        rate
      }
      total
    }
    total
    company {
      companyTradingEntities {
        _id
        name,
        email,
        phone,
        logo,
      }
      shippingAddress {
        _id
        address1
        address2
        city
        state
        postcode
        country
        formattedAddress
      }
      billingAddress {
        _id
        address1
        address2
        city
        state
        postcode
        country
        formattedAddress
      }
    }
    linkedJobs {
      _id
      number
      title
      company {
        companyTradingEntities {
        _id
          name,
        }
      }
      customer {
        name
        phone
        email
        shippingAddress {
          _id
          address1
          address2
          city
          state
          postcode
          country
          formattedAddress
        }
      }
      contact {
        fullName
      }
      createdBy {
        fullName
      }
      deadlineAt
      status
    }
    vendor {
      _id
      name
      email
      contacts {
        phone
      }
      addresses {
        _id
        label
        address1
        address2
        city
        state
        postcode
        country
        formattedAddress
      }
    }
    createdBy {
      username
      fullName
    }
    items {
      _id
      description
      quantity
      productId
      product {
        _id
        code
        title
        vendorId
        colors {
          _id
          name
          image {
            _id
            contentLength
            contentType
            bucket
            key
            filename
            url
          }
        }
        sizes {
          _id
          name
          description
        }
        variants {
          _id
          sku
          color {
            _id
            name
          }
          size {
            _id
            name
            description
          }
          priceBreaks {
            _id
            price
            quantity
          }
        }
        vendor {
          _id
          name
        }
        decorations {
          _id
          name
          vendorId
          vendor {
            _id
            name
            email
          }
        }
      }
      variants {
        _id
        productId
        productName
        productCode
        quantity
        colour
        size
        rate
        isCustomBuyPrice
        ordered
        received
        jobItemVariantId
        productVariantId
        productVariant {
          _id
        }
      }
      decorations {
        _id
        ordered
        received
        quantity
        description
        rate
        position
        colours
        size
        jobItemDecorationId
        decoration {
          _id
          name
          category
          vendor {
            name
          }
        }
        decorationId
      }
      additionalItems {
        _id
        ordered
        received
        description
        quantity
        jobItemAdditionalItemId
        rate
      }
      fileIds
      files {
        _id
        filename
        contentLength
        url
        contentType
      }
      tax {
        _id
        name
        type
        components {
          name
          rate
        }
      }
    }
    accountingSync
    accountingXero {
      status
      number
      date
    }
    accountingQBO {
      status
      number
      date
    }
  }
`;

export const GQL_GET_DRAFT_PO = gql`
  query getDraftPo($jobId: String!, $vendorId: String!) {
    getDraftPO(jobId: $jobId, vendorId: $vendorId) {
      ...SinglePoFragment
    }
  }
  ${GQLF_SINGLE_PO_FRAGMENT}
`;

export const GQL_GET_PO = gql`
  query GetPurchaseOrder($_id: String!) {
    purchaseOrder(_id: $_id) {
      ...SinglePoFragment
    }
  }
  ${GQLF_SINGLE_PO_FRAGMENT}
`;

export const GQL_GET_PURCHASE_ORDER_MANY = gql`
  query GetPurchaseOrderMany(
    $filter: FilterFindManySearchGCPurchaserOrderInput
    $sort: SortFindManyGCPurchaseOrderInput
  ) {
    purchaseOrderMany(filter: $filter, sort: $sort) {
      _id
      isMasterPurchaseOrder
      masterPurchaseOrderId
      masterPurchaseOrder {
        _id
        number
      }
      childPurchaseOrders {
        _id
      }
      number
      createdAt
      deadlineAt
      ownerId
      owner {
        fullName
      }
      title
      vendor {
        name
      }
      linkedJobIds
      linkedJobs {
        _id
        number
        title
        customer {
          name
        }
        quote {
          _id
          number
        }
      }
      createdBy {
        fullName
      }
      status
      subTotal
      total
    }
  }
`;

export const GQL_GET_PURCHASE_ORDERS = gql`
  query purchaseOrders($jobId: String) {
    purchaseOrders(jobId: $jobId) {
      _id
      number
      createdAt
      deadlineAt
      title
      isMasterPurchaseOrder
      masterPurchaseOrderId
      masterPurchaseOrder {
        _id
        number
      }
      childPurchaseOrders {
        _id
      }
      vendor {
        name
      }
      linkedJobIds
      linkedJobs {
        _id
        number
        quote {
          _id
          number
        }
      }
      createdBy {
        fullName
      }
      status
      subTotal
      total
    }
  }
`;

export const GQL_UPDATE_PO = gql`
  mutation updatePo($_id: String!, $poData: PurchaseOrderInput!) {
    updatePurchaseOrder(_id: $_id, poData: $poData) {
      ...SinglePoFragment
    }
  }
  ${GQLF_SINGLE_PO_FRAGMENT}
`;

export const GQL_CREATE_PO = gql`
  mutation createPurchaseOrder($poData: PurchaseOrderInput!) {
    createPurchaseOrder(poData: $poData) {
      ...SinglePoFragment
    }
  }
  ${GQLF_SINGLE_PO_FRAGMENT}
`;

export const GQL_UPDATE_PURCHASE_ORDER_ITEM = gql`
  mutation updatePurchaseOrderItem(
    $purchaseOrderId: String!
    $itemId: String!
    $updatedItem: POItemInput!
  ) {
    updatePurchaseOrderItem(
      purchaseOrderId: $purchaseOrderId
      itemId: $itemId
      updatedItem: $updatedItem
    ) {
      description
    }
  }
`;

export const GQL_UPDATE_PURCHASE_ORDER_ITEM_VARIANT = gql`
  mutation updatePurchaseOrderItemVariant(
    $purchaseOrderId: String!
    $itemId: String!
    $variantId: String!
    $updatedVariant: POItem_VariantInput!
  ) {
    updatePurchaseOrderItemVariant(
      purchaseOrderId: $purchaseOrderId
      itemId: $itemId
      variantId: $variantId
      updatedVariant: $updatedVariant
    ) {
      ...SinglePoFragment
    }
  }
  ${GQLF_SINGLE_PO_FRAGMENT}
`;

export const GQL_UPDATE_PURCHASE_ORDER_ITEM_DECORATION = gql`
  mutation updatePurchaseOrderItemDecoration(
    $purchaseOrderId: String!
    $itemId: String!
    $decorationId: String!
    $updatedDecoration: POItem_DecorationsInput!
  ) {
    updatePurchaseOrderItemDecoration(
      purchaseOrderId: $purchaseOrderId
      itemId: $itemId
      decorationId: $decorationId
      updatedDecoration: $updatedDecoration
    ) {
      ...SinglePoFragment
    }
  }
  ${GQLF_SINGLE_PO_FRAGMENT}
`;

export const GQL_UPDATE_PURCHASE_ORDER_ITEM_ADDITIONAL_ITEM = gql`
  mutation updatePurchaseOrderItemAdditionalItem(
    $purchaseOrderId: String!
    $itemId: String!
    $additionalItemId: String!
    $updatedAdditionalItem: POItem_AdditionalItemsInput!
  ) {
    updatePurchaseOrderItemAdditionalItem(
      purchaseOrderId: $purchaseOrderId
      itemId: $itemId
      additionalItemId: $additionalItemId
      updatedAdditionalItem: $updatedAdditionalItem
    ) {
      ...SinglePoFragment
    }
  }
  ${GQLF_SINGLE_PO_FRAGMENT}
`;

export const GQL_DELETE_PURCHASE_ORDER = gql`
  mutation deletePurchaseOrder($purchaseOrderId: String!) {
    deletePurchaseOrder(_id: $purchaseOrderId) {
      _id
      number
    }
  }
`;

export const GQL_SEND_PURCHASE_ORDER_LINK = gql`
  mutation SendPurchaseOrderLink($_id: String!, $emailOptions: EmailOptionsInput ) {
    sendPurchaseOrderLink(_id: $_id, emailOptions: $emailOptions ) {
      emailStatus
      message
      purchaseOrder {
        number
        _id
      }
    }
  }
`;

export const CREATE_BACKORDERS = gql`
    mutation createBackOrders($_id: String!, $variants: [CreateBackOrderInput!], $additionalItems: [CreateBackOrderInput!], $decorations: [CreateBackOrderInput!] ){
        createBackOrders(
          _id: $_id,
          variants:$variants,
          additionalItems:$additionalItems,
          decorations:$decorations)
    }
`;

export const GQL_PURCHASE_ORDER_PAGINATION = gql`
  query purchaseOrderPagination(
    $filter: FilterFindManySearchGCPurchaserOrderInput
    $sort: SortFindManyGCPurchaseOrderInput
    $page: Int
    $perPage: Int
  ) {
    purchaseOrderPagination(
      filter: $filter
      sort: $sort
      page: $page
      perPage: $perPage
    ) {
      items {
        _id
        number
        isMasterPurchaseOrder
        masterPurchaseOrderId
        masterPurchaseOrder {
          _id
          number
        }
        childPurchaseOrders {
          _id
        }
        customReference
        createdAt
        deadlineAt
        dueDate
        title
        vendor {
          name
          email
          contacts {
            firstName
            email
          }
        }
        linkedJobIds
        linkedJobs {
          _id
          number
          quote {
            _id
            number
          }
        }
        createdBy {
          fullName
        }
        subTotal
        taxes {
          tax {
            name
            rate
          }
          total
        }
        total
        status
        sentStatus
        accountingSync
        accountingXero {
          status
          number
          date
        }
        accountingQBO {
          status
          number
          date
        }
      }
      pageInfo {
        perPage
        pageCount
        currentPage
        hasNextPage
        itemCount
      }
    }
  }
`;
