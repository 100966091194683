// TODO: fix eslint disable
/* eslint-disable no-shadow */

import React from 'react';
import {ChipCell} from './ChipCell';

export const VendorsCell = ({row, colDef: {valueOptions}}) => {
  const getLabel = (id) => {
    const row = valueOptions.find(({value}) => value === id);
    return row?.label;
  };
  return row.digest?.vendorIds && <ChipCell getLabel={getLabel} dataArray={row.digest?.vendorIds}/>;
};
