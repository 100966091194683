
const HoopsCapitalizeText = ({text}) => {

    const words = text.split(/ +/);

    try {
        for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1).toLowerCase();
        }
    } catch (err) {
        return text;
    }
    const newText = words.join(' ');

    return (newText);
};

export default HoopsCapitalizeText;
