import {Grid, Typography} from '@material-ui/core';
import React from 'react';
import {stripHTML} from '../../../utils/stripHtml';

const ProofsTableProductsCell = ({products}) => (
    <Grid container direction='column'>
      {products.map((product) => (
        <Typography variant='caption' key={product._id}>{stripHTML(product.description)}</Typography>
      ))}
    </Grid>
  );

export default ProofsTableProductsCell;
