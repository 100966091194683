export function formatAddressOneLine(address) {
  return ['address1', 'address2', 'city', 'state', 'country', 'postcode']
    .filter((key) => address[key])
    .map((key) => address[key])
    .join(', ');
}

export function formatAddressTwoLines(address) {
  if (!address) {
    return {line1: '', line2: ''};
  }
  const line1 = ['address1', 'address2', 'city'].filter((key) => address[key]).map((key) => address[key]).join(', ');
  const line2 = ['state', 'country', 'postcode'].filter((key) => address[key]).map((key) => address[key]).join(', ');
  return {line1, line2};
}
