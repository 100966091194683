import {useCallback, useMemo, useState} from 'react';
import {pick} from 'lodash';
import {
  useCreateView,
  useCreateViewField,
  useDeleteView,
  useDeleteViewField,
  useGetViews,
  useUpdateViewFieldOrder,
  useUpdateView,
  useUpdateViewField, useGetViewsCache
} from '../../../hooks/api';
import {useLocalStorageState, useWatch} from '../../../hooks';
import {getApplicationStorageItem, getLocalStorageItem, putApplicationStorageItem, putLocalStorageItem, updateLocalStorageItem} from '../../../utils';
import {useSelector} from 'react-redux';

const defaultViewState = {
  columnWidths: {},
  density: 'standard',
  filters: {linkOperator: 'and', items: []},
  hidden: [],
  isChanged: false,
  page: 0,
  pageSize: 25,
  pinned: {left: ['number'], right: ['actions']},
  search: '',
  sort: {'number': -1},
};

const viewStateImmediateLocalProps = ['pinned', 'columnWidths'];
const viewStateGlobalProps = ['pageSize', 'density'];
const viewStateSaveBackendProps = ['filters', 'sort', 'hidden'];
const viewStateSaveLocalProps = [...viewStateImmediateLocalProps, ];

export const useViewState = (initialState, entity) => {
  const {data: {views: viewData}, refetch: refetchViews} = useGetViews(entity);
  const {updateCache: updateViewDataCache} = useGetViewsCache(entity);

  // The currentView is stored in local storage, which is almost permanent, since the user cannot fix an issue
  // by refreshing the page, let's make sure the currentView is valid!
  const [_currentView, setCurrentView] = useLocalStorageState([entity, 'currentView'], 'home');
  const currentView = _currentView === 'home' || viewData?.views?.find((view) => view._id === _currentView) ? _currentView : 'home';

  const getCurrentViewObject = useCallback(() => (currentView === 'home' ? viewData?.views?.[0] : viewData?.views?.find((v) => v._id === currentView)) ?? {}, [currentView, viewData?.views]);

  const getCurrentViewId = useCallback(() => currentView === 'home' ? viewData?.views[0]._id : currentView, [currentView, viewData?.views]);

  const getCleanViewState = useCallback(() => {
    const clean = {
      ...defaultViewState,
      ...getCurrentViewObject(),
      ...(getLocalStorageItem(`${entity}|ViewState-Global`)),
      ...(getLocalStorageItem(`${entity}|ViewState|${currentView}`)),
      ...(getApplicationStorageItem(`${entity}|ViewState|${currentView}`)),
    };

    // Let's do a little sanity checking and validation of the values
    clean.pageSize = clean.pageSize >= 10 && clean.pageSize <= 500 ? clean.pageSize : 25;

    return clean;
  }, [currentView, entity, getCurrentViewObject]);

  const [viewState, setViewState] = useState(getCleanViewState());
  const {update: updateViewFieldOrderApi} = useUpdateViewFieldOrder(entity);
  const {create: createViewApi} = useCreateView(entity, {updateCache: updateViewDataCache});
  const {update: updateViewApi} = useUpdateView(entity, {updateCache: updateViewDataCache});
  const {delete: deleteViewApi} = useDeleteView(entity, {updateCache: updateViewDataCache});
  const {create: createViewFieldApi} = useCreateViewField(entity, {updateCache: updateViewDataCache});
  const {update: updateViewFieldApi} = useUpdateViewField(entity, {updateCache: updateViewDataCache});
  const {delete: deleteViewFieldApi} = useDeleteViewField(entity, {updateCache: updateViewDataCache});

  useWatch(() => {
    if (currentView) {
      setViewState(getCleanViewState());
    }
  }, [getCleanViewState, currentView]);

  // If the caller changes the view state we also want to record that a change was made
  const updateViewState = useCallback((newViewState, isChange = true) => setViewState((prevState) => {
      // If it is a function, it will return the state
      if (newViewState instanceof Function) {
        newViewState = newViewState(prevState) ?? prevState;
      }
      const fullState = {...prevState, ...newViewState, isChanged: isChange || prevState.isChanged};
      putApplicationStorageItem(`${entity}|ViewState|${currentView}`, fullState);
      updateLocalStorageItem(`${entity}|ViewState|${currentView}`, pick(fullState, viewStateImmediateLocalProps));
      updateLocalStorageItem(`${entity}|ViewState-Global`, pick(fullState, viewStateGlobalProps));
      return fullState;
    }), [currentView, entity]);

  const setColumnWidths = useCallback((columnWidths) => updateViewState({columnWidths}, false), [updateViewState]);
  const setDensity = useCallback((density) => updateViewState({density}, false), [updateViewState]);
  const setFilters = useCallback((filters) => updateViewState({filters}), [updateViewState]);
  const setHidden = useCallback((hidden) => updateViewState({hidden}), [updateViewState]);
  const setPage = useCallback((page) => updateViewState({page}, false), [updateViewState]);
  const setPageSize = useCallback((pageSize) => updateViewState({pageSize}, false), [updateViewState]);
  const setPinned = useCallback((pinned) => updateViewState({pinned}, false), [updateViewState]);
  const setSearch = useCallback((search) => updateViewState({search}, false), [updateViewState]);
  const setSort = useCallback((sort) => updateViewState({sort}), [updateViewState]);

  const setColumnOrder = useCallback(async (order) => {
    try {
      const fields = order.map((path) => viewData.fields.find((field) => field.path === path));
      updateViewDataCache({views: {...viewData, fields}});
      const res = await updateViewFieldOrderApi({views: {fields}});
      updateViewDataCache(res);
    } catch(err) {
      console.error('Error while saving the column order', err);
    }
  }, [updateViewDataCache, updateViewFieldOrderApi, viewData]);

  const addColumn = useCallback(async (type, name, position) => {
    try {
      const res = await createViewFieldApi({field: {title: name, type}}, {params: {position}});
      return res.path;
    } catch(err) {
      console.error('Error while creating a column', err);
      return undefined;
    }
  }, [createViewFieldApi]);

  const deleteColumn = useCallback(async (path) => {
    try {
      const fieldIndex = viewData?.fields.findIndex((f) => f.path === path);
      if (fieldIndex >= 0) {
        await deleteViewFieldApi({id: path});
      }
    } catch(err) {
      console.error('Error while deleting a column', err);
    }
  }, [deleteViewFieldApi, viewData?.fields]);

  const setColumnName = useCallback(async (path, name) => {
    try {
      const index = viewData?.fields?.findIndex((f) => f.path === path);
      if (index != null && index >= 0 && viewData.fields[index].title !== name) {
        const fields = [...viewData.fields];
        fields[index] = {...fields[index], title: name};
        updateViewDataCache({views: {...viewData, fields}});
        await updateViewFieldApi({field: fields[index]});
      }
    } catch(err) {
      console.error('Error while saving the column name', err);
    }
  }, [updateViewDataCache, updateViewFieldApi, viewData]);

  const updateViewField = useCallback(async (path, fieldProps) => {
    try {
      const field = viewData?.fields?.find((f) => f.path === path);
      if (field) {
        Object.assign(field, fieldProps);
        await updateViewFieldApi({field});
      }
    } catch(err) {
      console.error('Error while saving the field information', err);
    }
  }, [updateViewFieldApi, viewData?.fields]);

  const addView = useCallback(async (type) => {
    try {
      const homeViewState = getLocalStorageItem(`${entity}|ViewState|home`);
      const res = await createViewApi({view: {...pick(homeViewState, viewStateSaveBackendProps), name: 'New View', type}});
      const newViewId = res?.data?.views?.at(-1)?._id;
      if (newViewId) {
        putLocalStorageItem(`${entity}|ViewState|${newViewId}`, pick(homeViewState, viewStateSaveLocalProps));
        setCurrentView(newViewId);
      }
      return newViewId ?? null;
    } catch(err) {
      console.error('Error while adding a view', err);
      return undefined;
    }
  }, [createViewApi, entity, setCurrentView]);

  const deleteView = useCallback(async (viewId) => {
    try {
      if (viewId !== 'home') {
        const index = viewData?.views.findIndex((v) => v._id === viewId);
        if (index >= 0) {
          await deleteViewApi({id: viewId});
        }
      }
    } catch (err) {
      console.error('Error while deleting a view', err);
    }
    return undefined;
  }, [deleteViewApi, viewData?.views]);

  const saveView = useCallback(async (asNewView) => {
    try {
      if (asNewView || currentView === 'home') {
        const res = await createViewApi({view: {...pick(viewState, viewStateSaveBackendProps), name: 'New View', type: viewState.type}});
        const newViewId = res?.data?.views?.at(-1)?._id;
        if (newViewId) {
          putLocalStorageItem(`${entity}|ViewState|${newViewId}`, pick(viewState, viewStateSaveLocalProps));
          putApplicationStorageItem(`${entity}|ViewState|${currentView}`, {});
          setViewState(getCleanViewState());
          setCurrentView(newViewId);
        }
        return newViewId ?? null;
      } else {
        const backendUpdate = pick(viewState, viewStateSaveBackendProps);
        await updateViewApi({id: getCurrentViewId(), view: {...backendUpdate}});
        putLocalStorageItem(`${entity}|ViewState|${currentView}`, pick(viewState, viewStateSaveLocalProps));
        putApplicationStorageItem(`${entity}|ViewState|${currentView}`, {});
        setViewState(getCleanViewState());
        return null;
      }
    } catch(err) {
      console.error('Error while saving the view', err);
    }
    return undefined;
  }, [createViewApi, currentView, entity, getCleanViewState, getCurrentViewId, setCurrentView, updateViewApi, viewState]);

  const renameView = useCallback(async (viewId, name) => {
    try {
      if (viewId !== 'home') {
        const res = await updateViewApi({id: viewId, view: {name}});
        updateViewDataCache(res);
      }
    } catch (err) {
      console.error('Error while renaming a view', err);
    }
  }, [updateViewDataCache, updateViewApi]);

  // appAccess can evolve to include other entity boards
  const companySelector = (state) => state.companyReducer.company;
  const {appAccess} = useSelector(companySelector);

  const columnLimit = appAccess?.jobBoard?.columnLimit ?? null;
  const viewLimit = appAccess?.jobBoard?.viewLimit ?? null;

  // Define Subscription Limits
  const allowAddColumns = columnLimit ? viewData?.fields.reduce((agg, field) => agg + field.builtin === false, 0) < columnLimit : true;
  const allowAddViews = viewLimit ? viewData?.views.length <= viewLimit : true;

  return useMemo(() => ({
    currentView,
    isHome: currentView === 'home',
    state: viewState,
    viewData,

    columnLimit,
    viewLimit,
    allowAddColumns,
    allowAddViews,
    addColumn,
    addView,
    deleteColumn,
    deleteView,
    renameView,
    saveView,
    setCurrentView,
    setColumnName,
    setColumnWidths,
    setDensity,
    setFilters,
    setHidden,
    setOrder: setColumnOrder,
    setPage,
    setPageSize,
    setPinned,
    setSearch,
    setSort,
    setViewState: updateViewState,
    updateViewField,
    refetchViews,
  }), [
    currentView, viewState, viewData, addColumn, addView, deleteColumn, deleteView, renameView, saveView, setCurrentView, setColumnName, setColumnWidths,
    setDensity, setFilters, setHidden, setColumnOrder, setPage, setPageSize, setPinned, setSearch, setSort, updateViewState, updateViewField, refetchViews,
    columnLimit, viewLimit, allowAddColumns, allowAddViews,
  ]);
};
