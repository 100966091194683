import React, {useCallback} from 'react';
import {Button} from '@material-ui/core';
import {TrackingNumberForm} from './TrackingNumberForm';
import {TrackerNumberModal} from './TrackerNumberModal';

export function AddTrackNumberButton({trackingNumbers = [], onSubmit}) {
  const _onSubmit = useCallback((model) => onSubmit({trackingNumbers: [...trackingNumbers, model]}), [onSubmit, trackingNumbers]);
  return (
    <TrackerNumberModal
      title={'Add New Tracking Number'}
      button={
        <Button
          key={2}
          variant={'contained'}
          color={'primary'}
        >Add New Tracking Number</Button>
      }
      onSubmit={_onSubmit}
    >
      <TrackingNumberForm onSubmit={_onSubmit}/>
    </TrackerNumberModal>
  );
}
