// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import {Button, Grid, makeStyles, Typography} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {hoopsQuerySetRefetch} from '../../actions/hoopsQuery';
import {createTask, setModal, updateTask} from '../../actions/tasks';
import TaskEdit from './TaskEdit';

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    dialogActions: {padding: theme.spacing(2),}
}));

export const TaskEditModal = ({
    onClose = () => null,
    onSave = () => null
}) => {
    const classes = useStyles();

    const taskSelector = (state) => state.taskReducer.active;
    const isOpenSelector = (state) => state.taskReducer.isModalOpen;

    const task = useSelector(taskSelector);
    const isOpen = useSelector(isOpenSelector);

    const dispatch = useDispatch();
    let _task = null;

    const modalTitle = task._id ? 'Edit Task' : 'Add Task';

    const handleClose = () => {
        dispatch(setModal(false));
        dispatch(hoopsQuerySetRefetch('task', true));
    };

    const handleChange = (v) => {
        _task = v;
    };

    const handleSave = () => {
        const payload = {...task, ..._task};
        payload.$isNew ?
            dispatch(createTask(payload)) :
            dispatch(updateTask(payload._id, payload));
        dispatch(setModal(false));
    };

    return (
        <Dialog
            open={isOpen}
            maxWidth={'lg'}
            fullWidth={true}
            onClose={handleClose}
        >
            <DialogTitle disableTypography>
                <Typography variant={'h4'}>{modalTitle}</Typography>
                <IconButton aria-label='close' className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <TaskEdit task={task} onChange={handleChange} />
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Grid container justifyContent={'space-between'}>
                    <Grid item xs>
                        <Button onClick={handleClose}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Button
                                    onClick={handleSave}
                                    variant={'contained'}
                                    color={'primary'}
                                    type='submit'>Save</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default TaskEditModal;
