import {SET_VENDORS, SET_VENDOR, CLEAR_VENDOR} from '../actions/action-types';

export const initialState = {
  list: [],
  vendor: {
    addresses: [{}],
    contacts: [{}]
  },
};

const vendorReducer = (state = {...initialState}, action) => {
  switch (action.type) {
    case SET_VENDORS:
      return {
        ...state,
        list: action.payload
      };
    case SET_VENDOR:
      return {
        ...state,
          vendor: {
            addresses: [], // TODO remove these when the API returns the populated vendor
            contacts: [],
            ...action.payload,
          }
      };
      case CLEAR_VENDOR:
        return {
          ...state,
          vendor: initialState.vendor
        };
    default:
      return state;
  }
};

export default vendorReducer;
