//Currencies use Unicode from https://www.toptal.com/designers/htmlarrows/currency/

export const CCY_USD = '\u0024'; //US Dollar
export const CCY_EUR = '\u20AC'; //European Euro
export const CCY_YEN = '\u00A5'; //Japanese Yen
export const CCY_GBP = '\u00A3'; //Great Britain Pound
export const CCY_INR = '\u20B9'; //India Rupee
export const CCY_PHP = '\u20BD'; //Philippine Peso
export const CCY_THB = '\u0E3F'; //Thai Baht
export const CCY_VND = '\u20AB'; //Vietnames Dong
export const CCY_ZAR = '\u0052'; //South African Rand
export const CCY_IDR = '\u0052\u0070'; //Indonesian Rupiah
export const CCY_MYR = '\u0052\u004D'; //Malaysian Ringgit
export const CCY_SEK = '\u0053\u0045\u004B'; //Swedish Krona
