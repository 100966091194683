import {
  SET_ACTIVE_PRODUCT,
  SET_CATALOG_IMPORTS,
  SET_PRODUCTS,
  TOGGLE_EDIT_PRODUCT
} from '../actions/catalog';

const initialState = {
  products: [],
  activeProduct: null,
  editing: false,
  loadingVariants: true,
  pageLimit: 24,
  recordsTotal: 0,
  recordsFiltered: 0,
  catalogImports: []
};

const catalogReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCTS:
      return {
        ...state,
        products: action.payload.data,
        pageLimit: action.payload.pageLimit,
        recordsTotal: action.payload.recordsTotal,
        recordsFiltered: action.payload.recordsFiltered
      };

    case SET_ACTIVE_PRODUCT:
      return {
        ...state,
        activeProduct: action.payload
      };

    case TOGGLE_EDIT_PRODUCT:
      return {
        ...state,
        editing: !state.editing
      };

    case SET_CATALOG_IMPORTS:
      return {
        ...state,
        catalogImports: action.payload,
      };

    default:
      return {...state};
  }
};

export default catalogReducer;
