// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {QuoteService} from '../../servicesOld/QuoteService';

export const SET_PRICE_BUILDER = 'SET_PRICE_BUILDER';
export const RESET_PRICE_BUILDER = 'RESET_PRICE_BUILDER';

export function getPriceElement(_id) {
  return function(dispatch) {
    return QuoteService.getPriceElement(_id)
      .then((data) => {
        dispatch({
          type: SET_PRICE_BUILDER,
          payload: data
        });
      });
  };
}

export function resetItemBuilder() {
  return function (dispatch) {
    dispatch({type: RESET_PRICE_BUILDER,});
  };
}

export function updatePriceElement(_id, data, cb) {
  return function (dispatch) {
    return QuoteService.updatePriceElement(_id, data)
      .then((data) => {
        dispatch({
          type: SET_PRICE_BUILDER,
          payload: data
        });
        if (cb) {
          cb(null, data);
        }
      });
  };
}

export function createPriceElement(data, cb) {
  return function (dispatch) {
    return QuoteService.createPriceElement(data, cb)
      .then((data) => {
        dispatch({
          type: SET_PRICE_BUILDER,
          payload: data
        });
        if (cb) {
          cb(null, data);
        }
      })
      .catch((err) => console.error(err));
  };
}
