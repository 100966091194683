import PropTypes from 'prop-types';
import {permissionCheck} from './permissionCheck';

// Checks access and either show or hides children
export const PermissionBasedContent = ({
  allowedRoles = [],
  allowedSubscriptions = [],
  disallowedSubscriptions = [],
  allowedAppAccess = [],
  children
}) => {
  if (permissionCheck({
    allowedRoles,
    allowedSubscriptions,
    disallowedSubscriptions,
    allowedAppAccess,
  })) {
    if (children) {
      return children;
    }
    return true;
  }
  return false;
};

PermissionBasedContent.propTypes = {
  allowedRoles: PropTypes.arrayOf(PropTypes.string),
  allowedSubscriptions: PropTypes.arrayOf(PropTypes.string),
  disallowedSubscriptions: PropTypes.arrayOf(PropTypes.string),
  allowedAppAccess: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
};
