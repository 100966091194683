import {Button, Grid, makeStyles, Typography} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {get} from 'lodash';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {deleteSchedule, setModal} from '../../actions/schedules';
import SchedulePreview from './SchedulePreview';

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    dialogActions: {padding: theme.spacing(2),}
}));

export const SchedulePreviewModal = () => {
    const classes = useStyles();

    const scheduleSelector = (state) => state.scheduleReducer.active;
    const modalViewTypeSelector = (state) => state.scheduleReducer.modalOpen;

    const schedule = useSelector(scheduleSelector);
    const modalViewType = useSelector(modalViewTypeSelector);
    
    const isOpen = modalViewType === 'preview';

    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(setModal(false));
    };

    const handleEdit = () => {
        dispatch(setModal('edit'));
    };

    const handleDelete = () => {
        dispatch(deleteSchedule(schedule._id));
        dispatch(setModal(false));
    };

    if (!isOpen) {
        return null;
    }

    return (
        <Dialog
            open={isOpen}
            maxWidth={'md'}
            fullWidth={true}
            onClose={handleClose}
        >
            <DialogTitle disableTypography>
                <Typography variant={'h4'}>Schedule Job</Typography>
                <IconButton aria-label='close' className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <SchedulePreview schedule={schedule} proofs={get(schedule, 'job.proofs', [])} />
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Grid container justifyContent={'space-between'}>
                    <Grid item xs>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Button
                                    onClick={handleEdit}
                                    variant={'contained'}
                                    color={'primary'}
                                    type='submit'>Edit</Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={handleDelete}
                                    color='primary'>
                                    Delete
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default SchedulePreviewModal;
