// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import {FormControl, Grid, makeStyles, TextField, Typography} from '@material-ui/core';
import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  greyText: {color: theme.colors.grey.main,},
  selectClass: {minWidth: 184,},
  optionTextAny: {fontStyle: 'italic',},
}));

const AutomationTriggerConfigProofs = () => {
  const classes = useStyles();
  const {control} = useFormContext();

  return (

    <Grid container direction='row' justifyContent='center' alignItems='flex-end' spacing={1}>
      <Grid item>
        <Typography variant='h5' className={classes.greyText}>If proofs have not been actioned after </Typography>
      </Grid>
      <Grid item>
        <FormControl>
          <Controller
            name='howLong'
            control={control}
            render={(props) =>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <TextField variant='standard' margin='dense' size='small'
                           placeholder='How long (days)'
                           inputProps={{className: classes.selectClass}}
                           labelId='selectorPath-label'
                           {...props}
                           type={'number'}
                /> <Typography variant='h5' className={classes.greyText}>days</Typography></div>
            }
          />
        </FormControl>
      </Grid>
    </Grid>

  );
};

export default AutomationTriggerConfigProofs;
