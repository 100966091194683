// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {Checkbox, Divider, Grid, makeStyles, Table, TableBody, TableCell, TableRow, Typography} from '@material-ui/core';
import {get} from 'lodash';
import moment from 'moment';
import React from 'react';
import ChipSelect from '../../../componentsOld/jobs/ChipSelect';
import {PROOF_STATUSES} from '../../../constants/status';
import theme from '../../../ui/theme';
import LinkToProductField from './LinkToProductField';
import ProofPreview from './ProofPreview';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: '50%',
        width: 0,
        overflow: 'hidden'
    },
    noBorder: {
        borderStyle: 'none',
        padding: 4,
        textAlign: 'left'
    },
    proofHeader: {color: theme.colors.grey.main}
}));

export const ProofListItem = ({
    proof,
    proofPreviewOptions = {
        thumbWidth: 80,
        thumbHeight: 80,
        tooltipMaxWidth: 650
    },
    selectable = false,
    selected = false,
    onSelect = () => null,
    onLinkProducts = () => null,
    view = null,
    job = {},
}) => {
    const classes = useStyles();

    const getCreatedAt = () => {
        const d = moment(proof.createdAt, 'x').format('MMMM D YYYY'); // the number representation for our date is a string from server
        return d;
    };

    const handleChange = (event) => {
        const checked = event.target.checked;
        onSelect(checked);
    };

    return (
        <> {proof?.file?.filename && (view === 1 || (view === 2 && selected)) ?
            <>
                <Grid container spacing={2} alignItems='center'>
                    {selectable && view === 1 ?
                        <Grid item xs={false}>
                            <Checkbox checked={selected} size='small' color='primary' onChange={handleChange} />
                        </Grid>
                        : null
                    }
                    <Grid item xs={false} style={{minWidth: `calc(${proofPreviewOptions.thumbWidth} + 16px)`}}>
                        <ProofPreview file={proof.file} options={proofPreviewOptions} />
                    </Grid>
                    <Grid item xs style={{overflow: 'auto'}}>
                        <Table className={classes.table}>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={classes.noBorder} width={80}>
                                        <Typography variant='caption' className={classes.proofHeader}>File Name</Typography>
                                    </TableCell>
                                    <TableCell className={classes.noBorder}>
                                        <Typography variant='body2'>{proof.file.filename}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.noBorder}>
                                        <Typography variant='caption' className={classes.proofHeader}>Uploaded By</Typography>
                                    </TableCell>
                                    <TableCell className={classes.noBorder}>
                                        <Typography variant='body2'>{get(proof, 'createdBy.fullName', '')}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.noBorder}>
                                        <Typography variant='caption' className={classes.proofHeader}>Upload Date</Typography>
                                    </TableCell>
                                    <TableCell className={classes.noBorder}>
                                        <Typography variant='body2'>{getCreatedAt()}</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid item xs={5} style={{alignSelf: 'center', justifyItems: 'flex-end', display: 'grid'}}>
                        {view === 1 &&
                            <ChipSelect disabled activeOption={proof.status} options={PROOF_STATUSES} />
                        }
                        {view === 2 &&
                            <LinkToProductField
                                proof={proof}
                                job={job}
                                onLinkProducts={onLinkProducts}
                            />
                        }
                    </Grid>
                </Grid >
                <Divider light style={{marginTop: theme.spacing(1), marginBottom: theme.spacing(1)}} />
            </>
            : null} </>
    );
};

export default ProofListItem;
