import {Typography} from '@material-ui/core';
import React, {useCallback, useState} from 'react';
import {HoopsTextField} from '../HoopsTextField';
import {InputAdornment} from '@mui/material';

export function OrgSettingsField({
  editing,
  fieldName,
  fieldReference,
  embeddedArrayName = null,
  embeddedArrayIndex = null,
  company,
  onChange = () => null,
  currencySymbol,
  percent,
  minValue,
  defaultValue = [],
  setDisabled = () => null,
  type = 'text'
}) {
  let embeddedArray = [];
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState(null);

  if (company) {
    defaultValue = company[fieldReference];
    embeddedArray = company[embeddedArrayName] || [];
    if (embeddedArray.length > 0) {
      defaultValue = company[embeddedArrayName][embeddedArrayIndex] ? company[embeddedArrayName][embeddedArrayIndex][fieldReference] : undefined;
    }
  }

  // validation
  const validateValue = useCallback((value) => {
    if (minValue && value - 1 < minValue) {
      setError(true);
      setHelperText(`Value entered must be above ${minValue}`);
      setDisabled(true);
      return false;
    } else {
      setError(false);
      setHelperText(null);
      setDisabled(false);
      return true;
    }
  }, [minValue, setDisabled]);

  const handleArrayUpdate = (updatedValue) => {
    embeddedArray[embeddedArrayIndex] = {...embeddedArray[embeddedArrayIndex], [fieldReference]: updatedValue};
    onChange({[embeddedArrayName]: embeddedArray});
  };

  let startAdornment;
  let endAdornment;

  if (currencySymbol) {
    startAdornment = (<InputAdornment disableTypography position={'start'}>{currencySymbol}</InputAdornment>);
  }

  if (percent) {
    endAdornment = (<InputAdornment disableTypography position={'end'}>%</InputAdornment>);
  }

  return (
    <>
      {editing ? null : <Typography variant={'body2'} color={'textSecondary'}>{fieldName}</Typography>}
      {editing ?
        <HoopsTextField
          error={error}
          helperText={helperText}
          fullWidth
          label={fieldName}
          name={fieldReference}
          value={defaultValue}
          onChange={(e) => {
            validateValue(e.target.value);
            embeddedArrayName
              ? handleArrayUpdate(e.target.value)
              : onChange({[fieldReference]: e.target.value});
          }}
          type={type}
          InputProps={{
            startAdornment,
            endAdornment
          }}
        />
        : <Typography>{(currencySymbol && !!defaultValue) && `${currencySymbol} `}{defaultValue || '-'}{(percent && !!defaultValue) && ' %'}</Typography>
      }
    </>
  );
}
