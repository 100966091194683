import {Grid, makeStyles, Typography} from '@material-ui/core';
import React from 'react';
import {useFormContext} from 'react-hook-form';

const useStyles = makeStyles((theme) => ({greyText: {color: theme.colors.grey.main,}}));

const AutomationTriggerConfigCreated = () => {
    const classes = useStyles();
    const {watch} = useFormContext();

    const capitalize = (str) => {
        str = str.replace(/([A-Z])/g, ' $1').trim();
        return str.toLowerCase();
    };

    const entityType = watch('entityType');

    return (
  
            <Grid container direction='row' justifyContent='center' alignItems='flex-start'>
                <Grid item >
                    <Typography variant='h5' className={classes.greyText}>When a {capitalize(entityType)} is created </Typography>
                </Grid>
            </Grid>
           
    );
};

export default AutomationTriggerConfigCreated;

