// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import {Dialog, Typography} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import {get} from 'lodash';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {getFormValues, reduxForm, reset as _reset, change} from 'redux-form';
import {getVendors as _getVendors} from '../../../../actions/action-types/vendor';
import {
  CLEAR_JOB_ITEM_VARIANTS, createJobItemVariant as _createJobItemVariant,
  updateJobItemVariant as _updateJobItemVariant
} from '../../../../actions/jobs';
import {closeJobVariantsModal as _closeJobVariantsModal} from '../../../../actions/modals';
import DialogActions from '../../DialogActions';
import DialogTitle from '../../DialogTitle';
import AdditionalItemsTable from './AdditionalItems';
import DecorationsTable from './DecorationsTable';
import FormBlock from './FormBlock';
import ProductsTable from './ProductsTable';

class JobVariantsModal extends Component {
  handleClose = () => {
    const {closeDialog, resetForm, clearJobItemVariant} = this.props;
    resetForm();
    clearJobItemVariant();
    closeDialog();
  };

  handleReset = () => {
    const {resetForm} = this.props;
    resetForm();
  }

  handleSubmit(values) {
    const {context: {meta: {form}, input: {name: field}}, job, changeForm} = this.props;
    const {createJobItemVariant, updateJobItemVariant} = this.props;
    const item = field.split('.')[0];
    const itemIndex = field.match(/\d+/)[0];

    if (values._id) {
      updateJobItemVariant(values._id, values, form, field);
    } else {
      createJobItemVariant(values, form, field);
    }

    // Update the line item image and description if empty
    if (!job?.items[itemIndex]?.imageUrl || job?.items[itemIndex]?.imageUrl === '') {
      changeForm(`${item}.imageUrl`, values.variants[0]?.product?.primaryImage?.url || values.variants[0]?.product?.iamges[0]?.url);
    }

    if (!job?.items[itemIndex]?.description || job?.items[itemIndex]?.description === '') {
      changeForm(`${item}.description`, values.variants[0]?.product?.title);
    }
    this.handleClose();
  }

  render() {
    const {modalOpen, handleSubmit, getVendors, variantsFormValues} = this.props;

    const decorations = get(variantsFormValues, 'decorations', []);
    const additionalItems = get(variantsFormValues, 'additionalItems', []);
    const variants = get(variantsFormValues, 'variants', []);

    const anyQuantity = !!((variants[0] && variants[0].quantity > 0) || (decorations[0] && decorations[0].quantity > 0) || (additionalItems[0] && additionalItems[0].quantity > 0));

    modalOpen && getVendors();

    return (
      <Fragment>
        <Dialog open={modalOpen}
          fullWidth={true}
          maxWidth={'lg'}
        >
          <DialogTitle onClick={this.handleClose}>
            <Typography variant={'h4'}>Select Variants</Typography>
          </DialogTitle>
          <form onSubmit={handleSubmit((values) => this.handleSubmit(values))}>
            <DialogContent data-intercom-target={'job-item-variants-modal'}>
              <Grid container component={'div'} spacing={2}>
                <Grid item component={'div'} xs={12}>
                  <FormBlock title={'Products'}>
                    <ProductsTable data-intercom-target={'job-item-variants'} />
                  </FormBlock>
                </Grid>
                <Grid item component={'div'} xs={12}>
                  <FormBlock title={'Decorations'}>
                    <DecorationsTable data-intercom-target={'job-item-decorations'} />
                  </FormBlock>
                </Grid>
                <Grid item component={'div'} xs={12}>
                  <FormBlock title={'Additional Items'}>
                    <AdditionalItemsTable data-intercom-target={'job-item-additional-items'} />
                  </FormBlock>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid container justifyContent={'space-between'} component={'div'}>
                <Grid item component={'div'}>
                  <Button
                    data-intercom-target={'job-item-variants-cancel-button'}
                    onClick={this.handleClose}>Cancel</Button>
                </Grid>
                <Grid item component={'div'}>
                  <Button variant={'contained'}
                    data-intercom-target={'job-item-variants-save-button'}
                    disabled={!anyQuantity}
                    color={'primary'} type={'submit'}>Save</Button>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        </Dialog>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    initialValues: state.jobsReducer.activeJobVariants,
    modalOpen: state.modalReducer.jobVariantsModalIsOpen,
    context: state.modalReducer.jobVariantsModalContext,
    variantsFormValues: getFormValues('jobVariantsForm')(state),
    job: getFormValues('newJobForm')(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeDialog: () => dispatch(_closeJobVariantsModal()),
    resetForm: () => dispatch(_reset('jobVariantsForm')),
    changeForm: (field, value) => dispatch(change('newJobForm', field, value)),
    createJobItemVariant: (data, form, field) => dispatch(_createJobItemVariant(data, form, field)),
    updateJobItemVariant: (_id, data, form, field) => dispatch(_updateJobItemVariant(_id, data, form, field)),
    getVendors: () => dispatch(_getVendors()),
    clearJobItemVariant: () => dispatch({type: CLEAR_JOB_ITEM_VARIANTS}),
  };
}

JobVariantsModal = reduxForm({
  form: 'jobVariantsForm',
  enableReinitialize: true
})(JobVariantsModal);
JobVariantsModal = connect(mapStateToProps, mapDispatchToProps)(JobVariantsModal);
export default JobVariantsModal;
