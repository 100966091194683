import theme from '../theme';

export const columnWidth = 234;

export const kanban = {
    item: {
        userSelect: 'none',
        padding: theme.spacing(1),
        margin: `${theme.spacing(1)}px 0 0 0`,
        transition: 'background-color 5s ease',
        background: theme.colors.white,
        minHeight: 40
    },
    itemTitle: {paddingBottom: theme.spacing(.5),},
    itemTextObject: {paddingBottom: 2},
    column: {
        background: theme.colors.grey.light,
        padding: theme.spacing(1),
        paddingTop: .1,
        width: columnWidth,
        borderRadius: '0 0 4px 4px',
    },
    columnDropSpace: {
        background: theme.colors.grey.light,
        padding: theme.spacing(1),
        paddingBottom: .1,
        width: columnWidth,
    },
    columnColor: {
        width: columnWidth,
        borderRadius: '4px 4px 0 0',
        height: 5
    },
    showMoreBtn: {marginTop: theme.spacing(1),},
    dragItemDropSpace: {
        background: `${theme.palette.primary.main}1d`,
        padding: theme.spacing(1),
        paddingBottom: 0,
        marginTop: theme.spacing(1),
        borderRadius: 4,
        border: `dashed 2px ${theme.palette.primary.main}`,
        height: 50,
    },
    dragItemDropSpaceOver: {background: `${theme.palette.primary.main}5d`,},
    dragItemDropColumn: {background: theme.colors.grey.ultraLight},
    greyText: {color: theme.colors.grey.main},
    orangeText: {color: theme.colors.orange},
    redText: {color: theme.colors.red},
    chip: {
        background: theme.colors.red,
        color: theme.colors.white,
        fontWeight: 500,
        padding: '0 8px 0 8px'
    },
};
