import React, {Component, Fragment} from 'react';
import {Grid, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {AccessTime as TimeIcon} from '@material-ui/icons';
import moment from 'moment';
import Avatar from '@material-ui/core/Avatar';
import {initials} from '../../../../../utils';

const styles = (theme) => ({
  parent: {marginTop: theme.spacing(3)},
  noteIconBackground: {
    backgroundColor: theme.colors.purple,
    height: theme.spacing(3),
    width: theme.spacing(3)
  },
  noteIcon: {fontSize: 14},
  authorContainer: {marginTop: theme.spacing(1)},
  authorIconBackground: {
    backgroundColor: theme.colors.orange,
    height: theme.spacing(4),
    width: theme.spacing(4),
    marginTop: theme.spacing(1) / -2,
  },
  authorIcon: {
    fontSize: 12,
    lineHeight: 1
  },
  timeIcon: {
    fontSize: '14px',
    marginRight: theme.spacing(1)
  }
});

class Note extends Component {
  render() {
    const {classes, proofNote, job} = this.props;

    const name = (proofNote.createdBy ? proofNote.createdBy.fullName : job.customer.name);
    return (
      <Fragment>
        <Grid item container>
          <Grid item xs={1}>
            <Avatar className={classes.authorIconBackground}>
              <Typography className={classes.authorIcon}>{initials(name)}</Typography>
            </Avatar>
          </Grid>
          <Grid container item xs={11} spacing={1}>
            <Grid item container justifyContent={'space-between'}>
              <Typography><strong>{name}</strong></Typography>
              <Typography color={'textSecondary'} variant={'body2'}>
                {proofNote.createdAt &&
                  <>
                    <TimeIcon className={classes.timeIcon} />
                    {moment(proofNote.createdAt, 'x').calendar()}
                  </>
                }
              </Typography>
            </Grid>
            <Grid item container xs={11}>
              <Typography variant='body2'>
                {proofNote.content}
              </Typography>
            </Grid>
            {proofNote.assignee && <Grid item container xs={11}>
              <Typography variant='body2' color={'textSecondary'}>
                Assigned to: {proofNote.assignee.fullName}
              </Typography>
            </Grid>}
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(Note);
