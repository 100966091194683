import React from 'react';
import {Info as InfoIcon} from '@mui/icons-material';
import {ToolTip} from './ToolTip';
import {registerGlobalStyle} from '../../theme';
import {HoopsPropTypes} from '../utils';
import {Placement} from '../Popovers';

registerGlobalStyle('.info-tip', (theme) => ({
  '&.tooltip-container': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    padding: 'inherit',
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    'svg': {
      position: 'relative',
      left: -2,
      fontSize: '1rem',
      color: theme.colors.text.mediumIcon
    },
  },
  '&.tooltip-popover.tooltip-popover.tooltip-popover': {marginBottom: 8,},
}));

export function InfoTip({className, tip, placement, children}) {
  return (
    <ToolTip className={[className, 'info-tip']} tip={tip ?? children} placement={placement ?? Placement.Top}><InfoIcon/></ToolTip>
  );
}

InfoTip.propTypes = {
  className: HoopsPropTypes.className,
  tip: HoopsPropTypes.string,
  placement: HoopsPropTypes.string,
  children: HoopsPropTypes.children,
};
