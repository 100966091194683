// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import {useMutation} from '@apollo/client';
import {Icon, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, withStyles} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {get} from 'lodash';
import React, {Fragment, useCallback, useRef, useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {change, Field, FieldArray, formValueSelector, getFormValues} from 'redux-form';
import {PreBuiltItemDialog} from '../../modules/itemBuilder/PreBuiltItemDialog';
import {GQL_CREATE_JOB_ITEM_VARIANT, GQL_UPDATE_JOB_ITEM_VARIANT} from '../../queries/jobs';
import JobsService from '../../servicesOld/JobsService';
import EditorField from '../companies/editor';
import {renderTextField} from '../shared/formHelpers/fields';
import IconUploadField from '../shared/formHelpers/IconUploadField';
import S3FileUpload from '../shared/formHelpers/S3FileUpload';
import JobVariantsButton from '../shared/modals/JobVariationsModal/JobVariantsButton';

const styles = (theme) => (
  {
    noBorder: {borderStyle: 'none'},
    descriptionCell: {
      width: 'auto',
      paddingRight: theme.spacing(1),
    },
    productCell: {width: 250},
    quantityCell: {
      minWidth: 100,
      width: '5%',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    iconCell: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      width: '30px'
    },
    quoteInput: {padding: theme.spacing(1)},
    subTotalCell: {
      paddingRight: theme.spacing(1),
      width: '80px'
    },
    narrowColumn: {width: '150px'},
    taxCell: {width: '150px'},
    amountInput: {textAlign: 'right'},
    // WYSIWYG overrides
    editor: {
      overflowY: 'auto',
      '& div+div': {marginTop: theme.spacing(1)},
      '& .public-DraftStyleDefault-block': {
        margin: 0,
        padding: theme.spacing(.5),
        paddingTop: 7,
        paddingLeft: 7,
      },
    },
    wrapper: {
      border: '1px solid #BDBDBD',
      borderRadius: theme.spacing(.5),
      minHeight: 33,
    },
  }
);

function MappedItem({
  item,
  index,
  onComplete,
  onClick,
  classes,
  meta,
}) {

  const dispatch = useDispatch();

  const selector = formValueSelector(meta.form);

  const jobItemVariantSelector = (state) => selector(state, `${item}.jobItemVariant`);
  const jivData = useSelector(jobItemVariantSelector);
  const jobItemVariant = useRef(null);
  jobItemVariant.current = jivData;

  const [dialogOpen, setDialogOpen] = useState(false);

  const [createJIV] = useMutation(GQL_CREATE_JOB_ITEM_VARIANT, {
    onCompleted: (result) => {
      if (result) {
        dispatch(change(meta.form, `${item}.jobItemVariant._id`, result.createJobItemVariant._id));
      }
    },
  });

  const [updateJIV] = useMutation(GQL_UPDATE_JOB_ITEM_VARIANT);

  const handlePreBuiltItemSelected = useCallback((configureProductSelected) => {
    const productDescription = get(configureProductSelected, 'description');
    const imageUrl = get(configureProductSelected, 'imageUrl', null);
    const priceElement = get(configureProductSelected, 'priceElement');
    const rate = get(configureProductSelected, 'rate');
    const quantity = get(configureProductSelected, 'quantity');
    const configuredProduct = {
      _id: configureProductSelected._id,
      name: configureProductSelected.name,
      code: configureProductSelected.code
    };

    const configureProductJobItemVariant = JobsService._parseJobItemVariantInput({
      variants: priceElement.variations,
      decorations: priceElement.decorationCosts,
      additionalItems: priceElement.additionalCosts,
    });

    if (jobItemVariant?._id) {
      updateJIV({variables: {_id: jobItemVariant._id, variant: configureProductJobItemVariant}});
    } else {
      createJIV({variables: {variant: configureProductJobItemVariant}});
    }

    dispatch(change(meta.form, `${item}.rate`, rate));
    dispatch(change(meta.form, `${item}.quantity`, quantity));
    dispatch(change(meta.form, `${item}.description`, productDescription));
    dispatch(change(meta.form, `${item}.imageUrl`, imageUrl));
    dispatch(change(meta.form, `${item}.priceElement`, priceElement));
    dispatch(change(meta.form, `${item}.configuredProductId`, configureProductSelected._id));
    dispatch(change(meta.form, `${item}.configuredProduct`, configuredProduct));

  }, [item, meta.form, dispatch, jobItemVariant, createJIV, updateJIV]);

  return (
    <>
      <PreBuiltItemDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        handlePreBuiltItemSelected={handlePreBuiltItemSelected}
      />
      <TableRow key={index}>
        <TableCell align={'center'} padding={'none'}>
          <Field
            component={JobVariantsButton}
            name={`${item}.jobItemVariant._id`}
            setDialogOpen={setDialogOpen}
          />
        </TableCell>
        <TableCell align={'center'} className={classes.iconCell} padding={'none'}>
          <S3FileUpload name={`${item}.imageUrl`}
            data-intercom-target={'job-item-image-upload-field'}
            component={IconUploadField}
            inputProps={{accept: '.png,.jpg,.jpeg'}}
            hideLoading={true}
            onComplete={onComplete(item)}
          />
        </TableCell>
        <TableCell padding={'none'}>
            <Field
            name={`${item}.description`}
            component={EditorField}
            editorClassName={classes.editor}
            wrapperClassName={classes.wrapper}
            toolbarHidden
          />
        </TableCell>

        <TableCell padding={'none'} className={classes.iconCell}>
          <Field
            data-intercom-target={'job-item-quantity-field'}
            name={`${item}.quantity`}
            disabled={jobItemVariant?.current?._id}
            variant={'outlined'}
            inputProps={{
              className: classes.quoteInput,
              type: 'number',
              step: '1',
              min: '0'
            }}
            component={renderTextField} />
        </TableCell>
        <TableCell className={classes.iconCell} padding={'none'} align={'center'}>
          <Icon data-intercom-target={'job-item-delete-button'} color={'secondary'}
            onClick={onClick(index)}>delete</Icon>
        </TableCell>
      </TableRow>
    </>
  );
}

const companySelector = (state) => state.companyReducer.company;

function RenderItems({fields, meta, job}) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const dispatch = useDispatch();
  const company = useSelector(companySelector);

  const handleDelete = (index) => () => {
    fields.splice(index, 1);
  };

  const handleAddLine = () => {
    fields.push({taxes: company.taxes});
  };

  const handleUploadComplete = (item) => (s3Object) => {
    dispatch(change(meta.form, `${item}.imageUrl`, s3Object.Location));
  };

  return (
    <Fragment>
      {fields.map((item, index) => (
          <MappedItem
            job={job}
            item={item}
            fields={fields}
            index={index}
            key={index}
            meta={meta}
            onComplete={handleUploadComplete}
            onClick={handleDelete}
            classes={classes}
          />
        ))}
      <TableRow>
        <TableCell colSpan={6} className={classes.noBorder}>
          <Button data-intercom-target={'job-item-add-item-button'} color={'primary'} variant={'outlined'}
            onClick={handleAddLine}>Add Lines</Button>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

class SingleJobTable extends React.Component {
  state = {
    totals: {
      subTotal: 0,
      taxes: [],
      total: 0
    },
  };

  quoteColumns = [
    {
      title: 'Item Builder',
      class: this.props.classes.iconCell,
      align: 'center'
    }, {
      title: 'Image',
      class: this.props.classes.iconCell,
      align: 'center'
    }, {
      title: 'Description',
      class: this.props.classes.descriptionCell
    }, {
      title: 'Qty',
      align: 'center',
      class: this.props.classes.quantityCell
    }, {
      title: 'Actions',
      class: this.props.classes.iconCell
    }]

  render() {
    const {classes, job} = this.props;
    return (
      <Fragment>
        <Table>
          <TableHead>
            <TableRow>
              {this.quoteColumns.map((column, index) => (
                <TableCell key={index} padding={'none'} align={column.align}
                  className={column.class}>{column.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <FieldArray
              job={job}
              classes={classes}
              name={'items'}
              component={RenderItems}
              {...this.props} />
          </TableBody>
        </Table>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    company: state.companyReducer.company,
    quote: state.quoteReducer.quote,
    job: getFormValues('newJobForm')(state)
  };
}

SingleJobTable = connect(mapStateToProps, null)(SingleJobTable);
SingleJobTable = withStyles(styles)(SingleJobTable);

export default SingleJobTable;
