// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {Checkbox} from '@kestreltech/final-form-material-ui';
import {Button, DialogContent, Divider, Grid, makeStyles, MenuItem, Typography} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {change, Field, formValueSelector, reduxForm} from 'redux-form';
import {snackError, snackSuccess} from '../../actions/action-types';
import {getManyEmailTemplate} from '../../actions/emailTemplate';
import {emailJob, getJob} from '../../actions/jobs';
import {sendProofs} from '../../actions/proofs';
import HoopsSelectField from '../HoopsSelectField';
import {HoopsTextField} from '../HoopsTextField';
import ShortcodeList from '../../modules/emailTemplate/ShortcodeList';
import theme from '../../ui/theme';
import {featureFlagEnabled, featureFlags} from '../../utils/featureFlag';
import EditorField from '../companies/editor';
import DialogActions from './DialogActions';
import {required} from './formHelpers/validation';

const useStyles = makeStyles((theme) => ({
  actionsContainer: {padding: theme.spacing(3),},
  greyText: {color: theme.colors.grey.main},
  dialogContainer: {overflowY: 'hidden'}
}));

let SendEmailForm = ({
  handleSubmit,
  handleClose,
  type,
  entityLocation = null,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const selector = formValueSelector('sendEmailForm');
  const jobIdSelector = (state) => state.modalReducer.jobSendProofModal.context.jobId;
  const sendViaEmailSelector = (state) => selector(state, 'viaEmail');
  const sendViaSMSSelector = (state) => selector(state, 'viaSms');

  const jobId = useSelector(jobIdSelector);
  const sendViaEmail = useSelector(sendViaEmailSelector);
  const sendViaSMS = useSelector(sendViaSMSSelector);

  const smsBodyLimit = 160;

  const [loading, setLoading] = useState(false);

  const [emailTemplates, setEmailTemplates] = useState([]);
  const [smsTemplates, setSmsTemplates] = useState([]);

  const stripHtml = (html) => {
    let tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };

  useEffect(() => {
    dispatch(getManyEmailTemplate(entityLocation)).then((data) => {

      setEmailTemplates(data.filter(((el) => el.via === 'email')));
      const defaultEmailTemplate = data.filter(((el) => el.via === 'email')).find((v) => v.isDefault);
      dispatch(change('sendEmailForm', 'emailTemplate', defaultEmailTemplate));
      dispatch(change('sendEmailForm', 'subject', defaultEmailTemplate.subject));
      dispatch(change('sendEmailForm', 'body', defaultEmailTemplate.body));
      setSmsTemplates(data.filter(((el) => el.via === 'sms')));
      const defaultSmsTemplate = data.filter(((el) => el.via === 'sms')).find((v) => v.isDefault);
      dispatch(change('sendEmailForm', 'smsTemplate', defaultSmsTemplate));
      dispatch(change('sendEmailForm', 'phone', defaultSmsTemplate.phone));
      dispatch(change('sendEmailForm', 'smsBody', stripHtml(defaultSmsTemplate.body)));

    });
  }, [dispatch, entityLocation,]);

  const handleEmailTemplateChange = (value) => {
    if (!value) {return;}
    dispatch(change('sendEmailForm', 'subject', value.subject));
    dispatch(change('sendEmailForm', 'body', value.body));
  };

  const handleSmsTemplateChange = (value) => {
    if (!value) {return;}
    dispatch(change('sendEmailForm', 'phone', value.phone));
    dispatch(change('sendEmailForm', 'smsBody', stripHtml(value.body)));
  };

  const submitForm = async (values) => {
    setLoading(true);
    let response;
    if (type === 'job') {
      response = await dispatch(emailJob(jobId, values));
    } else {
      response = await dispatch(sendProofs(jobId, values));
    }
    setLoading(false);

    if (response.emailStatus === 'OK') {
      dispatch(getJob(jobId));
      if (type === 'job') {
        dispatch(snackSuccess('Job sent successfully'));
      } else {
        dispatch(snackSuccess('Proof sent successfully'));
      }
      handleClose();
      return;
    }
    dispatch(snackError('Error Sending Email. Please carefully check your email template and short codes'));
    handleClose();
  };

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <DialogContent className={classes.dialogContainer}>

        <Grid container spacing={6}>

          {featureFlagEnabled(featureFlags.sms_notifications) &&
            <Grid item sm={12}>
              <Typography variant='body1' display='inline'>Send Via:</Typography>
              <Field
                name={'viaEmail'}
                component={Checkbox}
                color={'primary'}
                type={'checkbox'}
              />
              <Typography variant='body1' display='inline' >Email</Typography>

              <Field
                name={'viaSms'}
                component={Checkbox}
                color={'primary'}
                type={'checkbox'}
              />
              <Typography variant='body1' display='inline' >SMS</Typography>
            </Grid>
          }

          {/* Email Form  */}
          {sendViaEmail &&
            <>
              {featureFlagEnabled(featureFlags.sms_notifications) &&
                <Grid item sm={12}>
                  <Typography variant='h5' >Email</Typography>
                </Grid>
              }
              <Grid item sm={12} md={5}>

                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Field
                      name={'to'}
                      label={'To:'}
                      type={'email'}
                      data-intercom-target={'proof-send-to-field'}
                      validate={required}
                      component={HoopsTextField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name={'cc'}
                      label={'CC:'}
                      data-intercom-target={'proof-send-cc-field'}
                      type={'email'}
                      component={HoopsTextField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name={'replyTo'}
                      label={'Reply To:'}
                      data-intercom-target={'proof-send-reply-to-field'}
                      type={'email'}
                      validate={required}
                      component={HoopsTextField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name={'emailTemplate'}
                      label={'Template:'}
                      component={HoopsSelectField}
                      labelId='send-from-select-label'
                      onChange={(e) => {
                        handleEmailTemplateChange(e.target.value);
                      }}
                    >
                      {emailTemplates.map((template) => (<MenuItem key={template._id} value={template}>{template.title}</MenuItem>))}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name={'subject'}
                      label={'Subject:'}
                      validate={required}
                      component={HoopsTextField}
                    />
                  </Grid>

                </Grid>

              </Grid>
              <Grid item sm={12} md={7}>

                <Field name={'body'}
                  data-intercom-target={'proof-send-body-field'}
                  label={'Body *'}
                  validate={required}
                  component={EditorField}
                />

              </Grid>

            </>
          }

          {sendViaEmail && sendViaSMS && featureFlagEnabled(featureFlags.sms_notifications) &&
            <Grid item sm={12}>
              <Divider />
            </Grid>
          }

          {/* SMS Form  */}

          {sendViaSMS && featureFlagEnabled(featureFlags.sms_notifications) &&
            <>
              <Grid item sm={12}>
                <Typography variant='h5' >SMS</Typography>
              </Grid>

              <Grid item sm={12} md={5}>

                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Field
                      name={'phone'}
                      label={'Phone Number:'}
                      validate={required}
                      component={HoopsTextField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name={'smsTemplate'}
                      label={'Template:'}
                      component={HoopsSelectField}
                      disabled={smsTemplates.length === 0}
                      onChange={(e) => {
                        handleSmsTemplateChange(e.target.value);
                      }}
                    >
                      {smsTemplates.map((template) => (<MenuItem key={template._id} value={template}>{template.title}</MenuItem>))}
                    </Field>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={12} md={7}>
                <Field
                  name={'smsBody'}
                  validate={required}
                  label='Body'
                  defaultValue=''
                  multiline
                  rows={10}
                  style={{paddingBottom: theme.spacing(1)}}
                  inputProps={{maxlength: smsBodyLimit}}
                  component={HoopsTextField}
                />
                <Typography variant='body2' className={classes.greyText} >{`Max ${smsBodyLimit} Characters. USD $0.07 per SMS charges apply.`}</Typography>
              </Grid>
            </>
          }

          <Grid item sm={7}>
            <ShortcodeList entity={entityLocation} />
          </Grid>
        </Grid>

      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={'space-between'} component={'div'}>
          <Grid item component={'div'}>
            <Button variant={'text'} color={'secondary'} onClick={handleClose}>Cancel</Button>
          </Grid>
          <Grid item component={'div'}>
            <Button
              variant={'contained'}
              data-intercom-target={'proof-send-button'}
              color={'primary'}
              type={'submit'}>
              {loading ? <><CircularProgress size={16} style={{marginRight: theme.spacing(1)}} />Send</> : 'Send'}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </form>
  );
};

SendEmailForm = reduxForm({
  form: 'sendEmailForm',
  enableReinitialize: true
})(SendEmailForm);

export default SendEmailForm;
