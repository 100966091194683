import React from 'react';
import Grid from '@material-ui/core/Grid';
import {CatalogImportFormHeader} from '../CatalogImportFormHeader';
import {HoopsFieldMappingRow} from '../HoopsFieldMappingRow';

const fieldsToMap = [{
  name: 'cartonSize_length',
  label: 'Carton Length',
  help: 'http://help.hoopscrm.com/en/articles/3667975-shipping-information',
}, {
  name: 'cartonSize_width',
  label: 'Carton Width'
}, {
  name: 'cartonSize_height',
  label: 'Carton Height'
},{
  name: 'cartonWeight',
  label: 'Carton Weight'
}, {
  name: 'qtyPerCarton',
  label: 'Quantity Per Carton',
  help: 'http://help.hoopscrm.com/en/articles/3667975-shipping-information',
}, {
  name: 'cartonCubic',
  label: 'Carton Cubic',
  help: 'http://help.hoopscrm.com/en/articles/3667975-shipping-information',
}, {
  name: 'cartonNotes',
  label: 'Carton Notes',
  help: 'http://help.hoopscrm.com/en/articles/3667975-shipping-information',
}, {
  name: 'freightDescription',
  label: 'Freight Description',
  help: 'http://help.hoopscrm.com/en/articles/3667975-shipping-information',
}, {
  name: 'individualProductPackaging',
  label: 'Individual Product Packaging',
  help: 'http://help.hoopscrm.com/en/articles/3667975-shipping-information',
}];

export function Step3Shipping({values}) {
  return (
    <Grid container direction={'column'} spacing={4}>
      <CatalogImportFormHeader/>
      {fieldsToMap.map((field, index) =>
        <Grid container spacing={2} wrap={'nowrap'} key={index}>
          <HoopsFieldMappingRow
            formValues={values}
            fieldName={`fieldMapping.${field.name}`}
            key={field.name}
            field={field}/>
        </Grid>
      )}
    </Grid>
  );
}
