import React, {useMemo} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {CircularProgress, Grid, Paper, Typography} from '@mui/material';
import flatten from 'flat';
import {useGetActivityLog} from '../../hooks/api';
import {formatDateTimeRelative} from '../../utils';
import {hoopsTheme} from '../../theme';
import {ActivityStatusRenderer, ActivityTextRenderer, ActivityUserRenderer, AutomationIdentRenderer} from './activityRenderers';
import {ExpansionButton, ExpansionPanelProvider, ExpansionPanel} from '../../componentsLib/Layout';
import {UserAvatar} from '../../componentsLib/Basic';

/*
 * An activity renderer can render for an action or for a field. The format is as follows;
 *
 * An activity can be rendered as an action, or as fields. An action is something like
 * creating or updating a quote, so the action would be 'CREATE' or 'UPDATE', it could also
 * be DELETE or something like SMS_SENT or EMAIL_SENT.
 *
 * Activities happen to entities, which have an entity type, such as 'quotes' or 'jobs'. They
 * can also be triggered by other entities, triggerEntities, such as invoices and proofs.
 *
 * Activity renderer descriptors take the following form
 * {
 *   <entity|triggerEntity.ACTIVITY> or <ACTIVITY>: The activity to render, and the entity type it handles or is triggered by
 *   renderer: A component to render the activity described, false to use field renderers, true to render just a heading
 *   renderIfEmpty: True to render the activity even if there are no field renderers and renderer is false
 *   heading: The title shown for the activity such as 'Updated the Quote' or 'Sent the Invoice to Accounts'. This may be a function.
 *   expand: True to expand the activity regardless of the number of fields that are changed
 * }
 *
 * The change for each field can also be rendered, these are field renderers
 *
 * {
 *   <entity|triggerEntity.fieldName>: The field to render, and the entity type it handles or is triggered by
 *   renderer: A component to render the activity described, false to render nothing
 *   group: Some changes affect a group of fields, such as when an online payment is made, and so the title will be taken
 *          from this descriptor rather than the activity descriptor
 *   heading: The title when there is only one changed field, to use the activity heading, use null or undefined. This may be a function.
 *   expand: True to expand the activity if there is only one field changed in the activity
 *   omitPreviousUndefined: True to hide the previous value if it is undefined
 * }
 *
 * Fields may be associated with view fields which may have a type, such as STATUS or TEXT. A descriptor for a
 * status field would be the same as any other field descriptor but would use the type:
 *
 * {
 *   <TYPE>: The view field type, such as STATUS, TEXT, DATE
 *   ...
 * }
 *
 * Any additional fields will be passed to the field renderer in the fieldRenderer prop.
 *
 * Renderers get the following props
 *
 * activity: All the information from the activity
 * change: The change that pertains to this field
 * changes: All the flattened changes in the activity
 * fieldInfo: Type info for the field, such as status colours
 * fieldPath: The path to the field
 * fieldRenderer: Props from the field descriptor
 * title: The title for the field, taken from the type info, the field descriptor or the field name
 *
 */

const activityStyles = {
  padding: 1.5,
  flexGrow: 1,
  '.ActivityItem .action': {fontWeight: 'bold',},
  '.user-avatar': {alignSelf: 'start',},
  '.ActivityContent': {padding: '4px 12px',},
  '.activity-fields, .MuiCollapse-wrapperInner': {
    display: 'grid',
    gridTemplateColumns: 'max-content auto',
    rowGap: '4px',
    '.MuiTypography-caption': {paddingRight: 1.5,},
    paddingLeft: 1,
  },
  '.status-label': {
    padding: '1px 6px 0',
    minWidth: 64,
    display: 'inline-block',
    textAlign: 'center',
  },
  '.email-body': {
    background: hoopsTheme.colors.background.almostWhite,
    border: `1px solid ${hoopsTheme.colors.border.light}`,
    padding: '0 4px',
    borderRadius: '5px',
    marginTop: .5,
  },
  '.sms-body': {
    background: hoopsTheme.colors.background.almostWhite,
    border: `1px solid ${hoopsTheme.colors.border.light}`,
    padding: '1em 4px',
    borderRadius: '5px',
    marginTop: .5,
  },
  '.email-error, .sms-error': {
    display: 'grid',
    gridTemplateColumns: 'max-content auto',
    border: `1px solid ${hoopsTheme.colors.palette.red}`,
    padding: '0 4px',
    borderRadius: '5px',
    marginTop: .5,
    '.MuiTypography-caption': {paddingRight: 1.5,},
  },
  '.nothing': {
    background: hoopsTheme.colors.background.grey.light,
    fontSize: '.75rem',
    padding: '1px 6px 0',
    borderRadius: '5px',

    '&:after': {
      color: hoopsTheme.colors.text.main,
      content: '"nothing"',
    },
  },
  '.removed, .removed *': {color: hoopsTheme.colors.text.medium,},
};

const defaultRenderers = {
  STATUS: {renderer: ActivityStatusRenderer},
  TEXT: {renderer: ActivityTextRenderer},
  USER: {renderer: ActivityUserRenderer},
};

export function ActivityTab({entity, viewState: {fields = [], isViewsLoading}, renderers: _renderers}) {
  const {data: {activityLog}, isLoading: isActivityLoading} = useGetActivityLog(entity?._id);
  const isLoading = isActivityLoading || isViewsLoading;

  // fieldMap contains {type, title} for a field.
  const fieldMap = useMemo(() => fields.reduce(((map, field) => {
    map[field.path] = field;
    return map;
  }), {}), [fields]);

  const renderers = useMemo(() => ({...defaultRenderers, ..._renderers}), [_renderers]);

  const entityType = activityLog?.entityType;
  const entityTypeName = activityLog?.entityTypeName;

  return (
    <Paper className={'activity-tab'} sx={activityStyles}>
      {isLoading && <CircularProgress />}
      {!isLoading && !activityLog &&
        <Typography>
          No activity recorded.
        </Typography>
      }
      <ErrorBoundary fallback={<Typography>Error loading activity history</Typography>}>
        {!isLoading && activityLog &&
          <Grid container direction='column' gap={1.5}>
            {activityLog.activity.map((activity, index) => (
              <ErrorBoundary key={index} fallback={<Typography>Error loading activity details</Typography>}>
                <ActivityRecord {...{entityType, entityTypeName, activity, fieldMap, renderers}}/>
              </ErrorBoundary>
            ))}
          </Grid>
        }
      </ErrorBoundary>
    </Paper>
  );
}

function ActivityRecord({entityType, entityTypeName, activity, fieldMap, renderers}) {
  const changes = activity.changes ? flatten(activity.changes, {safe: true}) : {};

  // Check if there is a renderer for the action, like the created, deleted, email, renderers etc
  const actionRenderer = renderers[`${activity.triggerEntityType}.${activity.action}`]
    ?? renderers[`${entityType}.${activity.action}`]
    ?? renderers[`${activity.action}`];
  if (actionRenderer?.renderer) {
    let heading = actionRenderer?.heading ?? activity.action;
    if (typeof heading === 'function') {
      heading = heading({activity, entityTypeName});
    }
    return (
      <ActivityContainer activity={activity} heading={heading} expand={actionRenderer.expand}>
        {typeof actionRenderer.renderer === 'function' && actionRenderer.renderer({activity, fieldMap, changes})}
      </ActivityContainer>
    );
  }

  // Otherwise, find all the renderers for the changed fields
  const changedFields = Object.keys(changes)
    .map((fieldPath) => {
      if ((changes[fieldPath][0] == null || changes[fieldPath][0] === '') && (changes[fieldPath][1] == null || changes[fieldPath][1] === '')) {
        // The change was from essentially nothing to nothing, so just ignore it.
        return null;
      }
      const fieldInfo = fieldMap[fieldPath] ?? {};
      const fieldRenderer = renderers[`${entityType}.${fieldPath}`] ?? renderers[`${activity.triggerEntityType}.${fieldPath}`] ?? renderers[fieldInfo.type];
      return {fieldPath, fieldRenderer, title: fieldRenderer?.title ?? fieldInfo.title ?? fieldPath, fieldInfo, omitPreviousUndefined: fieldRenderer?.omitPreviousUndefined};
    })
    .filter(Boolean)
    .filter(({fieldRenderer}) => !!fieldRenderer);

  if (changedFields.length === 0 && actionRenderer.renderIfEmpty) {
    let heading = actionRenderer?.heading ?? activity.action;
    if (typeof heading === 'function') {
      heading = heading({activity, entityTypeName, renderIfEmpty: true});
    }
    return (
      <ActivityContainer activity={activity} heading={heading} expand={actionRenderer.expand}>
        No changes
      </ActivityContainer>
    );
  } else if (changedFields.length === 1) {
    const changedField = changedFields[0];
    let heading = changedField.fieldRenderer.heading ?? actionRenderer?.heading ?? activity.action;
    if (typeof heading === 'function') {
      heading = heading({activity, entityTypeName, ...changedField, change: changes[changedField.fieldPath], changes});
    }
    const K = changedField.fieldRenderer.renderer;
    return (
      <ActivityContainer activity={activity} heading={heading} expand={changedField.fieldRenderer.expand ?? actionRenderer?.expand ?? false}>
        <AutomationIdentRenderer activity={activity}/>
        {changedField.fieldRenderer.renderer &&
          <K key={changedField.fieldPath} {...{activity, ...changedField, change: changes[changedField.fieldPath], changes}}/>
        }
      </ActivityContainer>
    );
  } else if (changedFields.length > 1) {
    const headingField = changedFields.find((changedField) => changedField?.fieldRenderer?.group) ?? changedFields[0];
    let heading = headingField?.fieldRenderer?.heading ?? actionRenderer?.heading ?? activity.action;
    if (typeof heading === 'function') {
      heading = heading({activity, entityTypeName, ...headingField, change: changes[headingField.fieldPath], changes});
    }
    return (
      <ActivityContainer activity={activity} heading={heading} expand={actionRenderer?.expand ?? false}>
        <AutomationIdentRenderer activity={activity}/>
        {changedFields.map((changedField) => {
          const K = changedField.fieldRenderer.renderer;
          return <K key={changedField.fieldPath} {...{activity, ...changedField, change: changes[changedField.fieldPath], changes}}/>;
        })}
      </ActivityContainer>
    );
  }
  return null;
}

function ActivityContainer({activity, heading, expand, children}) {
  return (
    <Grid container direction='row' alignItems='center' gap={1.5} className='ActivityItem'>
      <UserAvatar name={activity.user} />
      <Grid container direction='column' flexGrow={1} flexBasis={0}>
        <ExpansionPanelProvider initShown={expand}>
          <Grid container direction='row' gap={1.5}>
            <Typography className='user'>{activity.user}</Typography>
            <Typography className='action'>{heading}</Typography>
            <Typography className='date'>{formatDateTimeRelative(activity.performedAt)}</Typography>
            {children &&
              <ExpansionButton/>
            }
          </Grid>
          <ExpansionPanel>
            {children}
          </ExpansionPanel>
        </ExpansionPanelProvider>
      </Grid>
    </Grid>
  );
}
