// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import {TableContainer, Grid, Paper, Table, makeStyles} from '@material-ui/core';
import React from 'react';
import {HoopsQuery, HoopsQueryContext, HoopsQueryEmptyState, HoopsQueryFilterSearch, HoopsQueryPagination, HoopsQueryTableBody, HoopsQueryTableHeader} from '../../componentsOld/HoopsQuery';
import {GQL_RESOURCE_PAGINATION} from '../../queries/resources';
import ResourceManyTableActions from './ResourceManyTableActions';
import {hoopsQueryStyles} from '../../ui/theme/hoops';

const useStyles = makeStyles((theme) => ({...hoopsQueryStyles,}));

export const ResourceManyTable = ({handleAddResource = () => null}) => {

  const classes = useStyles();

  const columns = [{
    label: 'Resource Name',
    field: 'title',
    sortable: true,
    sortKey: 'TITLE',
    cellStyle: {maxWidth: '300px'},
    disableClick: true
  }, {
    label: 'Description',
    field: 'description',
    cellStyle: {maxWidth: '300px'},
    disableClick: true
  }, {
    label: 'Capacity',
    field: 'capacity',
    cellStyle: {maxWidth: '300px'},
    disableClick: true
  }, {
    label: 'Actions',
    filtering: false,
    disableClick: true,
    sorting: false,
    align: 'right',
    render: (resource) => <ResourceManyTableActions resource={resource} />
  }];

  return (
    <HoopsQuery refetchStateId='resource' queryStateId='resourceMany' columns={columns} gqlQuery={GQL_RESOURCE_PAGINATION} resultField='resourcePagination' initialFilter={{}} initialSort={'TITLE_ASC'}>
      <HoopsQueryContext.Consumer>
        {({items, sort, setSort, filter, setFilter, paging, setPaging, setChips, chips, hoopsQueryColumns, setHoopsQueryColumns}) => (<>

            <Grid container direction='row' justifyContent='space-between' alignItems='flex-start'>
              <Grid item></Grid>
              <Grid item>
                <HoopsQueryFilterSearch onChange={setFilter} filter={filter} placeholder={'Search'} chips={chips} onChipsChange={setChips} />
              </Grid>
            </Grid>
            <TableContainer component={Paper}>
              <Table className={classes.table}>
                <HoopsQueryTableHeader hasCheckBox={false} columns={hoopsQueryColumns} onSort={setSort} sortString={sort} />
                <HoopsQueryTableBody rowsPerPage={paging.perPage} rows={items} columns={hoopsQueryColumns} emptyStateComponent={() => <HoopsQueryEmptyState filter={filter} columns={hoopsQueryColumns} field={'Resource'} rowsPerPage={paging.perPage} onClearFilter={setFilter} onChipsChange={setChips} onAddItem={handleAddResource} />} />
              </Table>
            </TableContainer>
            <HoopsQueryPagination paging={paging} onPaging={setPaging} />
          </>)}
      </HoopsQueryContext.Consumer>
    </HoopsQuery>
  );
};

export default ResourceManyTable;
