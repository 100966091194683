export const stripHTML = (description) => {
  if (!description) {
    return description;
  }
  return description.replace(/(&nbsp;|<([^>]+)>)/ig, ' ').replace(/&amp;/g, '&').replace(/\n/g, '').replace('#DYNAMIC#', '');
};

export const stripHTMLKeepBreaks = (description) => {
  if (!description) {
    return description;
  }
  return description.replace(/(&nbsp;|<([^>]+)>)/ig, ' ').replace(/&amp;/g, '&').replace('#DYNAMIC#', '');
};

export const cleanDescriptionDynamic = (description) => {
  if (!description) {
    return description;
  }
  return description.replace('#DYNAMIC#', '');
};
