import {Avatar, Button, Grid, Icon, makeStyles, MuiThemeProvider, Paper, Typography} from '@material-ui/core';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import React from 'react';
import {useDispatch} from 'react-redux';
import TaskManyTable from '../../modules/task/List/TaskManyTable';
import {editNewTask} from '../../actions/tasks';
import {colors} from '../../ui/theme';
import green from '../../ui/theme/green';

const useStyles = makeStyles((theme) => ({
    header: {
        padding: theme.spacing(2),
        paddingBottom: theme.spacing(6)
    },
}));

const CustomerViewTasks = ({customer = null}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const handleCreateTaskClick = () => {
        dispatch(editNewTask({customerId: customer._id}));
    };

    return (
        <Paper>
            <Grid container justifyContent={'space-between'} alignItems={'center'} xs={12} className={classes.header}>
                <Grid item>
                    <Grid container alignItems={'center'} spacing={2}>
                        <Grid item>
                            <Avatar style={{backgroundColor: colors.purple}}>
                                <Icon><AssignmentTurnedInIcon /></Icon>
                            </Avatar>
                        </Grid>
                        <Grid item>
                            <Typography variant={'h5'}>Tasks</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <MuiThemeProvider theme={green}>
                        <Button
                            onClick={handleCreateTaskClick}
                            variant={'contained'}
                            color={'primary'}
                        >
                            Add Task
                      </Button>
                    </MuiThemeProvider>
                </Grid>
            </Grid>
            <TaskManyTable queryStateId={`customer_task_${customer._id}`} filterToMe={false} fixedFilter={{_operators: {customerId: {in: [customer._id]}}}} filterPadding={true} />
        </Paper>
    );
};

export default CustomerViewTasks;
