import {Grid} from '@material-ui/core';
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';
import React from 'react';
import {useHistory} from 'react-router';

export const EntityViewHeader = ({
    titleComponent = <></>,
    actionsComponent = <></>
}) => {
    const history = useHistory();
    return (
        <>
        <Grid container justifyContent={'space-between'} alignItems={'center'} style={{marginLeft: '-38px', width: 'auto'}}>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <KeyboardBackspace onClick={() => history.goBack()} style={{cursor: 'pointer'}}/>
              </Grid>
              <Grid item>
                  {titleComponent}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={2} alignItems={'center'}>
              <Grid item>
                {actionsComponent}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        </>
    );
};

export default EntityViewHeader;
