import {withStyles} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, {Component} from 'react';

const styles = (theme) => ({
  termsText: {
    fontWeight: 500,
    marginTop: theme.spacing(3),
    color: theme.colors.grey.main
  },
  outerContainer: {marginTop: theme.spacing(1)},
  termsContainer: {
    p: {margin: '0px'},
    color: theme.colors.grey.main,
    border: `1px solid ${theme.colors.grey.light}`,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minHeight: '80px',
    overflowY: 'scroll',
    borderRadius: theme.spacing(0.5)
  },
});

class QuoteTerms extends Component {
  render() {
    const {terms, classes} = this.props;
    return (
      <>
        <Typography variant='body1' className={classes.termsText}><strong>Terms:</strong></Typography>
          <Grid item xs={12}>
            <Typography variant='body2' className={classes.termsContainer} id='termsBox' dangerouslySetInnerHTML={{__html: terms}} />
          </Grid>
      </>
    );
  }
}

export default withStyles(styles)(QuoteTerms);
