import {Typography} from '@material-ui/core';
import React from 'react';
import moment from 'moment';

export const HoopsQueryTableCellDateTime = ({ 
    value, 
    showTime = true, 
    ...props
}) => {
    const mCreatedAt = moment(value);
    return mCreatedAt.isValid() && (
        <>
            {value && <Typography variant={'body2'} {...props}>{mCreatedAt.format('D MMM YYYY')}</Typography>}
            {value && showTime && <Typography color={'textSecondary'} variant={'body2'} {...props}>{mCreatedAt.format('h:mm a')}</Typography>}
        </>
    );
};

export default HoopsQueryTableCellDateTime;
