// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import React from 'react';
import {Tooltip, Button, Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import DefaultProductImage from '../../../assets/images/product_placeholder.jpg';

const useStyles = makeStyles((theme) => ({
  thumbnail: {
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  popper: {opacity: 1},
}));

const defaults = {
  options: {
    thumbWidth: '50px',
    thumbHeight: '50px',
    tooltipMaxWidth: '450px'
  }
};

// todo: Share this in app config?
const supportedImages = ['png', 'jpg', 'jpeg', 'gif', 'png', 'bmp'];

const isRenderable = ({filename}) => {
  const extBreak = filename.lastIndexOf('.');

  if(extBreak === -1) {
    return false; // No extension on file, can't know
  }

  const extension = filename.slice(extBreak + 1);
  return supportedImages.indexOf(extension) !== -1;
};

export default ({file, options = defaults.options}) => {
  const classes = useStyles();
  const imageUrl = file && isRenderable(file) ? file.url : DefaultProductImage;

  const {thumbWidth, thumbHeight, tooltipMaxWidth} = {
    ...defaults.options,
    ...options
  };

  return (
    <Tooltip
      classes={{popper: classes.popper, tooltip: classes.tooltip}}
      title={<img src={imageUrl} alt='' style={{maxWidth: '100%'}} />}
      styles={{maxWidth: tooltipMaxWidth}}
      placement={'right'}
      disableHoverListener={!isRenderable(file)}
    >
      <Button>
        <Grid
          className={classes.thumbnail}
          style={{
            backgroundImage: `url('${imageUrl}')`,
            width: thumbWidth,
            height: thumbHeight
          }} />
      </Button>
    </Tooltip>
  );

};
