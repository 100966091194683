// TODO: fix eslint disable
/* eslint-disable no-shadow */

import React, {useContext} from 'react';
import StatusButton from '../../StatusPicker/StatusPicker';
import {PageContext} from '../../../../componentsOld/PageContext';

interface StatusCellRenderProps {
  rowId: string,
  value?: string,
  field: any,
}

export function StatusCell ({
                              rowId,
                              value,
                              field,
                            }: StatusCellRenderProps) {
  const {updateField, updateViewField} = useContext<any>(PageContext);
  const options = field.options || [];
  const selected = options.find(({statusLabel}: {statusLabel: string}) => statusLabel === value);
  const text = (selected && selected.statusLabel) ?? value;
  const color = selected && selected.color;
  return <StatusButton
          statuses={options}
          rowId={rowId}
          onSelect={(selected) => {
            updateField({_id: rowId, field: field.path, value: selected.statusLabel});
          }}
          onStatusChange={(statuses) => {
            updateViewField(field.path, {options: statuses});
          }}
          color={color}
          text={text}/>;
}

