// TODO: fix eslint disable
/* eslint-disable no-class-assign, no-shadow */

import React, {Component} from 'react';
import {arrayPush, FieldArray, formValueSelector, change} from 'redux-form';
import {
  withStyles,
  Table,
  TableHead,
  TableRow,
  TableCell as MuiTableCell,
  TableBody,
  Button,
  Box, Typography
} from '@material-ui/core';
import classNames from 'classnames';
import ItemBuilderTableRow from './ItemBuilderTableRow';
import {connect} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import {toFloat} from '../../../servicesOld/ItemBuilderServices';
import * as numeral from 'numeral';
import {get} from 'lodash';

const styles = (theme) => ({
  tableWrapper: {minWidth: '100%',},
  buttonBox: {padding: theme.spacing(1)}
});

export const TableCell = withStyles((theme) => ({
  root: {padding: theme.spacing(1) / 2,},
  head: {zIndex: 1},
  body: {
    border: 'none',
    minWidth: 120
  },
  paddingCheckbox: {
    minWidth: 0,
    padding: `${theme.spacing(1) / 2}px !important`
  }
}))(MuiTableCell);

function BlockSummary({label, value, company, currency}) {
  const currencySymbol =  get(company, 'currencySymbol', '$');
  return (
    <Grid item>
      <Grid container spacing={3}>
        <Grid item>
          <Typography variant={'body2'} color={'textSecondary'} align={'right'}>{label}</Typography>
        </Grid>
        <Grid item>
          <Typography variant={'body2'}>
            {currency && currencySymbol} {value}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

class ItemBuilderTable extends Component {
  handleAddMoreClick = () => {
    const {arrayPush, parentField, quoteCustomer} = this.props;
    const markup = get(quoteCustomer, 'settings.minimumMarkup', 0);
    arrayPush('itemBuilder', parentField, {markup});
  };

  getQuantity = () => {
    const {variations, decorations, change, quantityOverride} = this.props;
    let quantity = 0;
    if (quantityOverride) {
      return quantityOverride;
    } else if (variations && variations.length) {
      quantity = variations.reduce((sum, v) => sum + toFloat(v.quantity), 0);
    } else if (decorations && decorations.length) {
      quantity = decorations[0].quantity;
    }
    change('quantity', quantity);

    return quantity;
  };

  getTotalCost = () => {
    const {blockValues, change, parentField} = this.props;
    let totalCost = 0;
    if (blockValues) {
      totalCost = blockValues.reduce((sum, bv) => {
        const rate = get(bv, 'buyPrice', 0);
        const additionalCost = get(bv, 'additionalCost', 0);
        const quantity = get(bv, 'quantity', this.getQuantity());
        const setupCost = get(bv, 'setupPrice', 0);

        return sum + ((toFloat(rate) + toFloat(additionalCost)) * toFloat(quantity)) + toFloat(setupCost);
      }, 0);
    }
    change(`totals.${parentField}.totalCost`, totalCost);
    return totalCost;
  };

  getTotalSellPrice = () => {
    const {blockValues, change, parentField} = this.props;
    let sellPrice = 0;
    if (blockValues) {
      sellPrice = blockValues.reduce((sum, bv) => {
        const sellPrice = get(bv, 'sellPrice', 0);
        const quantity = get(bv, 'quantity', this.getQuantity());

        return sum + (toFloat(sellPrice) * toFloat(quantity));
      }, 0);
    }
    change(`totals.${parentField}.sellPrice`, sellPrice);
    return sellPrice;
  };

  getTotalProfit = () => {
    const {change, parentField} = this.props;
    const profit = this.getTotalSellPrice() - this.getTotalCost();
    change(`totals.${parentField}.profit`, profit);
    return profit;
  };

  render() {
    const {
classes, parentField, showQuantity,
      company, headings, duplicate, tableRows, product
} = this.props;
    let {buttonText} = this.props;
    if (tableRows >= 1) {
      buttonText = ` another ${buttonText}`;
    }

    return (
      <>
        <Box className={classes.tableWrapper}>
          <Table
            size={'small'}
          >
            {Boolean(tableRows) && <TableHead>
              <TableRow>
                {headings.map((h, i) => (
                  <TableCell key={i} className={classNames(h.classNames)}>{h.title}</TableCell>
                ))}
                {duplicate && <TableCell/>}
                <TableCell/>
              </TableRow>
            </TableHead>}
            <TableBody>
              <FieldArray
                name={parentField}
                component={ItemBuilderTableRow}
                headings={headings}
                duplicate={duplicate}
                product={product}
              />
            </TableBody>
          </Table>
        </Box>
        <Box className={classes.buttonBox}>
          <Button color={'primary'}
                  variant={'outlined'}
                  size={'small'}
                  onClick={this.handleAddMoreClick}
          >
            Add {buttonText}
          </Button>
        </Box>
        {tableRows >= 1 && <Box className={classes.buttonBox}>
          <Grid container justifyContent={'space-between'}>
            {showQuantity ?
              <BlockSummary label={'Total Quantity:'}
                          value={numeral(this.getQuantity()).format('0,0')}/> :
              <Grid item />
            }
            <BlockSummary currency
                          label={'Total Cost:'}
                          value={numeral(this.getTotalCost()).format('0,0.00')}
                          company={company}/>
            <BlockSummary currency
                          label={'Total Sell Price:'}
                          value={numeral(this.getTotalSellPrice()).format('0,0.00')}
                          company={company}/>
            <BlockSummary currency
                          label={'Total Profit:'}
                          value={numeral(this.getTotalProfit()).format('0,0.00')}
                          company={company}/>
          </Grid>
        </Box>}
      </>
    );
  }
}
const selector = formValueSelector('itemBuilder');
const quoteSelector = formValueSelector('quoteForm');
const mapDispatchToProps = (dispatch) => ({
  arrayPush: (form, field, value) => dispatch(arrayPush(form, field, value)),
  change: (field, value) => dispatch(change('itemBuilder', field, value))
});

const mapStateToProps = (state, ownProps) => ({
  variations: selector(state, 'variations'),
  decorations: selector(state, 'decorationCosts'),
  blockValues: selector(state, ownProps.parentField),
  tableRows: selector(state, ownProps.parentField).length,
  company: state.companyReducer.company,
  quoteCustomer: quoteSelector(state, 'customer'),
});

ItemBuilderTable = connect(mapStateToProps, mapDispatchToProps)(ItemBuilderTable);
ItemBuilderTable = withStyles(styles)(ItemBuilderTable);

export default ItemBuilderTable;
