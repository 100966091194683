import {
  SET_RESOURCE,
  CREATE_RESOURCE,
  UPDATE_RESOURCE,
  REMOVE_RESOURCE,
  UPDATE_RESOURCES,
  MODAL_RESOURCE
} from '../actions/resource';

export const newResource = {
  $isNew: true,
  title: '',
  description: '',
  caption: ''
};

export const initialState = {
  active: {...newResource},
  list: [],
  listLoaded: false,
  isModalOpen: false
};

const resourceReducer = (state = initialState, {type, payload}) => {
  switch (type) {

    case MODAL_RESOURCE:
      return {
        ...state,
        isModalOpen: payload
      };
    
    case UPDATE_RESOURCES:
      return {
        ...state,
        list: payload,
        listLoaded: true
      };

      case SET_RESOURCE:
        return {
          ...state,
          active: payload
        };

    case CREATE_RESOURCE:
      return {
        ...state,
        list: {
          ...state.list,
          ...payload
        }
      };

    case UPDATE_RESOURCE:
      return {
        ...state,
        list: state.list.map(
            (item) => item._id === payload._id ? payload : item
          )
      };

    case REMOVE_RESOURCE:
      return {
        ...state,
        list: state.list.filter(
          (item) => item._id === payload ? false : true
        )
      };

    default:
      return state;
  }
};

export default resourceReducer;
