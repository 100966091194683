import React, {Fragment, useCallback} from 'react';
import {withStyles} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import MuiMultiSelect from '../../formHelpers/MuiMultiSelect';
import {Field, getFormValues} from 'redux-form';
import {renderTextField} from '../../formHelpers/fields';
import {connect} from 'react-redux';
import {gql, useQuery} from '@apollo/client';
import {VisibleObserver} from '../../../VisibleObserver';

const styles = (theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {margin: theme.spacing(1) / 2},
});
const GET_JOBS = gql`
  query jobMany($limit: Int) {
    jobMany(sort: NUMBER_DESC, limit: $limit) {
      title
      number
      _id

    }
  }`;

function _POFooterComponent(props) {
  const {classes, poFormValues} = props;
  const limit = React.useRef(50);
  const {data, refetch, variables} = useQuery(GET_JOBS, {variables: {limit: limit.current}});
  const jobs = data?.jobMany;
  const lastData = React.useRef(jobs);
  const readMore = useCallback(() => {
    if (lastData.current.length === limit.current) {
      limit.current = variables.limit + 100;
      refetch({limit: limit.current});
    }
  }, [refetch, variables.limit]);
  if (jobs) {
    lastData.current = jobs;
  }
  const lastId = lastData.current?.[lastData.current?.length - 1]?._id;
  return (
    <Fragment>
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <Field
            name='notes'
            component={renderTextField}
            fullWidth
            rows={4}
            label={'Notes'}
            variant={'outlined'}
            multiline={true}/>
        </Grid>
        <Grid item xs={6}>
          {lastData.current && <Field name={'linkedJobs'}
                                      component={MuiMultiSelect}
                                      label={'Link to Job(s)'}
                                      fullWidth
                                      options={lastData.current}
                                      disabled={poFormValues?.locked}
                                      SelectedComponent={({value: item}) => (
                                        <Chip className={classes.chip} key={item._id} color={'primary'}
                                              label={item.number}/>
                                      )}
                                      OptionComponent={({selected, option: item}) => (
                                        <Fragment>
                                          <Checkbox checked={selected?.findIndex?.((s) => s?._id === item?._id) > -1} color={'primary'}/>
                                          {item._id === lastId &&
                                            <VisibleObserver onVisible={readMore}/>
                                          }
                                          <ListItemText primary={item.number} secondary={item.title}/>
                                        </Fragment>
                                      )}
          />}
        </Grid>
      </Grid>
    </Fragment>
  );
}

function mapStateToProps(state) {
  return {
    company: state.companyReducer.company,
    poFormValues: getFormValues('purchaseOrderForm')(state),
  };
}

let POFooterComponent = withStyles(styles)(_POFooterComponent);
POFooterComponent = connect(mapStateToProps, null)(POFooterComponent);

export default POFooterComponent;
