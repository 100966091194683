import React from 'react';
import classNames from 'classnames';
import {brightness} from '../../utils';
import {HoopsPropTypes} from '../utils';
import {colors, registerGlobalStyle} from '../../theme';

registerGlobalStyle('.status-label', (theme) => ({
  lineHeight: 'normal',
  paddingInline: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: 'var(--status-color)',
  color: 'var(--text-color)',
  borderRadius: theme.shape.borderRadius,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  position: 'relative',
  border: '1px solid var(--status-color)',

  '&.is-white': {borderColor: theme.colors.text.light},

  '&.button::after': {
    content: '"expand_more"',
    fontFamily: 'Material Icons',
    position: 'static',
    fontSize: '1.5rem',
    width: theme.spacing(3),
    height: theme.spacing(3),
    padding: 0,
    backgroundColor: 'transparent',
    color: 'var(--chevron-color)',
    opacity: 0,
    transition: theme.transitions.out.opacity,
  },
  '&.button.open::after': {content: '"expand_less"'},
  '&:hover::after': {
    opacity: 1,
    transition: theme.transitions.in.opacity,
  }
}));

export function StatusLabel({className, asButton, asButtonOpen, color, statusLabel, children}) {
  const {isLight, isWhite} = brightness(color || colors.palette.greyLightest);
  return (
    <div
      className={classNames([className, 'status-label', isWhite && 'is-white', isLight && 'is-light', asButton && 'button', asButtonOpen && 'open'])}
      style={{
        '--status-color': color || colors.palette.greyLightest,
        '--text-color': isLight ? colors.text.main : colors.text.white,
        '--chevron-color': isLight ? colors.text.medium : colors.text.white,
      }}
    >
      {statusLabel || children || '\u00A0'}
    </div>
  );
}

StatusLabel.propTypes = {
  className: HoopsPropTypes.className,
  asButton: HoopsPropTypes.bool,
  asButtonOpen: HoopsPropTypes.bool,
  color: HoopsPropTypes.string,
  statusLabel: HoopsPropTypes.string,
  children: HoopsPropTypes.children,
};
