// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React, {Component} from 'react';
import {Grid, Typography, withStyles} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import {connect} from 'react-redux';
import {get} from 'lodash';
import {saveCustomer as _saveCustomer} from '../../actions/action-types/customer';
import ImageWithTooltip from '../shared/ImageWithTooltip';
import UNBRANDED_PREVIEW from '../../assets/images/unbrandedProofPortal.png';

const styles = () => ({});

class ProofPortalCheckbox extends Component {

  handleChange = (e) => {
    const {customer, saveCustomer} = this.props;
    customer.settings = {
      ...customer.settings,
      whitelabelProofPortal: e.target.checked
    };
    saveCustomer(customer);
  };

  render() {
    const {customer} = this.props;
    const whitelabelProofPortal = get(customer, 'settings.whitelabelProofPortal', false);

    return (
      <>
        <Grid item container wrap={'nowrap'}>
          <Grid item>
            <Checkbox
              color={'primary'}
              onChange={this.handleChange}
              checked={whitelabelProofPortal}
            />
          </Grid>
          <Grid item>
            <Typography>
              Unbranded Proof Portal
            </Typography>
            <Typography variant={'body2'}>
              Activate this option to remove your company branding from the proof portal for jobs linked to {customer.name}.
              The red elements in the example will be hidden.
            </Typography>
          </Grid>
          <Grid item>
            <ImageWithTooltip imageUrl={UNBRANDED_PREVIEW}/>
          </Grid>
        </Grid>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {customer: state.customerReducer.customer,};
}
function mapDispatchToProps(dispatch) {
  return {saveCustomer: (data) => dispatch(_saveCustomer(data))};
}

ProofPortalCheckbox = withStyles(styles)(ProofPortalCheckbox);
ProofPortalCheckbox = connect(mapStateToProps, mapDispatchToProps)(ProofPortalCheckbox);

export default ProofPortalCheckbox;
