import React, {useCallback, useState} from 'react';
import Icon from '@material-ui/core/Icon';
import {cancelCatalogImport, getImports, undoCatalogImport} from '../../../../actions/catalog';
import {useDispatch, useSelector} from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Link from '@material-ui/core/Link';
import {processImport} from '../../../../actions/catalogImport';
import {setModalContext, setModalOpen} from '../../../../actions/modals';
import {useMutation} from '@apollo/client';
import {GQL_REMOVE_SHARED_CATALOG} from '../../../../queries/catalogImport';
import {ConfirmDialog} from '../../../modal/ConfirmDialog';
import {SendImportEmailForm} from './SendImportEmailForm';
import SendDialog from '../../../modal/SendDialog';

const userDataSelector = (state) => state.authReducer;

function ImportHistoryActions({rowData}) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const {userData} = useSelector(userDataSelector);
  const {status} = rowData;
  const [sendDialogOpen, setSendDialogOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancel = useCallback(() => {
    handleClose();
    dispatch(cancelCatalogImport(rowData._id));
    // TODO: FIX HOOK DEPENDENCIES
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleRetry = useCallback(() => {
    dispatch(processImport(rowData._id));
    // TODO: FIX HOOK DEPENDENCIES
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleUndo = useCallback(() => {
    setShowCancelConfirmation(true);
    // TODO: FIX HOOK DEPENDENCIES
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleShare = useCallback(() => {
    handleClose();
    dispatch(setModalContext('shareProductModal', {catalogImportIds: [rowData._id]}));
    dispatch(setModalOpen('shareProductModal', true));
    // TODO: FIX HOOK DEPENDENCIES
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleConfirmClose = useCallback((confirmed) => {
    if (confirmed) {
      dispatch(undoCatalogImport(rowData._id));
    }
    setShowCancelConfirmation(false);
  }, [dispatch, rowData]);

  const [removeSharedCatalog] = useMutation(GQL_REMOVE_SHARED_CATALOG,
    {onCompleted: () => dispatch(getImports())},
  );

  const handleRemoveSharedCatalog = useCallback(async () => {
    await removeSharedCatalog({
      variables: {
        _id: rowData._id,
        companyId: userData.companyId
      }
    });
  }, [rowData, userData, removeSharedCatalog]);

  const handleCloseSendDialog = async () => {
    setSendDialogOpen(false);
    setAnchorEl(null);
  };

  const handleOpenSendDialog = async () => {
    setSendDialogOpen(true);
    setAnchorEl(null);
  };

  return (
    <>
      {showCancelConfirmation &&
        <ConfirmDialog
          title={'Undo Import?'}
          content={'Are you sure you want to do this? Undo Import can not be undone... again.'}
          cancelText={'Cancel Undo Import'}
          okText={'Yes, Undo Import'}
          onClose={handleConfirmClose} />
      }
      {!rowData.readOnly && <IconButton
        onClick={handleClick}
        variant='outlined'
        size='small'
      >
        <Icon>more_horiz</Icon>
      </IconButton>}
      {sendDialogOpen &&
        <SendDialog
          open={sendDialogOpen}
          closeDialog={handleCloseSendDialog}
          title='Send Email'
          formComponent={<SendImportEmailForm setSendOpen={setSendDialogOpen} catalog={rowData} companies={rowData.viewOnlyCompanies} />}
        />
      }
      {rowData.readOnly ?
        <IconButton size={'small'} onClick={handleRemoveSharedCatalog}>
          <Icon>delete</Icon>
        </IconButton>
        : <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleClose}
        >
          {userData.superadmin &&
            <MenuItem onClick={handleOpenSendDialog}>
              <ListItemIcon>
                <Icon>email</Icon>
              </ListItemIcon>
              Send Email
            </MenuItem>
          }
          {status === 'COMPLETE' &&
            <MenuItem onClick={handleUndo}>
              <ListItemIcon>
                <Icon>undo</Icon>
              </ListItemIcon>
              Undo Import
            </MenuItem>
          }
          {status === 'COMPLETE' && userData.superadmin &&
            <MenuItem onClick={handleShare}>
              <ListItemIcon>
                <Icon>share</Icon>
              </ListItemIcon>
              Share Imported Products
            </MenuItem>
          }
          {!['COMPLETE', 'UNDONE', 'CANCELLED'].includes(status) && <MenuItem onClick={handleCancel}>
            <ListItemIcon>
              <Icon>cancel</Icon>
            </ListItemIcon>
            Cancel Import
          </MenuItem>}
          {['ERROR', 'UNDONE', 'CANCELLED'].includes(status) && <MenuItem onClick={handleRetry}>
            <ListItemIcon>
              <Icon>replay</Icon>
            </ListItemIcon>
            Retry Import
          </MenuItem>}
          {rowData.csvFile &&
            <MenuItem
              component={Link}
              target='_blank'
              onClick={handleClose}
              href={rowData.csvFile.url}
              color='inherit'
            >
              <ListItemIcon>
                <Icon>insert_drive_file</Icon>
              </ListItemIcon>
              Download CSV
            </MenuItem>
          }
          {rowData.imageZipFile &&
            <MenuItem
              component={Link}
              target='_blank'
              onClick={handleClose}
              href={rowData.imageZipFile.url}
              color='inherit'
            >
              <ListItemIcon>
                <Icon>image</Icon>
              </ListItemIcon>
              Download Images
            </MenuItem>
          }
        </Menu>}
    </>
  );
}

export default ImportHistoryActions;
