import {IconButton} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import React from 'react';
import {TrackingNumberForm} from './TrackingNumberForm';
import {TrackerNumberModal} from './TrackerNumberModal';

export function UpdateTrackingNumber({trackingNumbers, index, onSubmit}) {
  const _onSubmit = async (model) => {
    await onSubmit({trackingNumbers: trackingNumbers.map((tc, i) => i === index ? model : tc)});
  };
  return (
    <TrackerNumberModal
      title={'Update Tracking Number'}
      button={<IconButton><CreateIcon/></IconButton>}
      onSubmit={onSubmit}
    >
      <TrackingNumberForm model={trackingNumbers[index]} onSubmit={_onSubmit}/>
    </TrackerNumberModal>
  );
}
