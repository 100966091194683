import {Divider, IconButton, InputAdornment, makeStyles, MenuItem} from '@material-ui/core';
import {Cancel} from '@material-ui/icons';
import {uniqBy} from 'lodash';
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import HoopsSelectField from '../../../componentsOld/HoopsSelectField';
import {HoopsTextField} from '../../../componentsOld/HoopsTextField';
import {useWatch} from '../../../hooks';
import {itemBuilder} from '../../../ui/theme/itemBuilder';
import {ItemBuilderContext} from '../ItemBuilderContext';
import {sortSizes} from '../../../utils/sortSizes';

const useStyles = makeStyles(() => ({
  ...itemBuilder,
  textFieldWithAdornmentBtn: {
    '& .MuiOutlinedInput-adornedEnd': {
      paddingLeft: 0,
      paddingRight: 0,
    }
  },
  fakeBorder: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    left: 0,
    top: 0,
    border: '3px solid #DDD',
    margin: -3,
  }
}));

const SizeField = ({
  index,
  name,
  defaultValue,
  ...params
}) => {
  const classes = useStyles();
  const {watch, control, setValue} = useFormContext();
  const {onPriceChanged} = useContext(ItemBuilderContext);
  const colour = watch(`variations[${index}].colour`);
  const product = watch(`variations[${index}].product`);
  const productVariantId = watch(`variations[${index}].productVariant._id`);
  const productVariant = watch(`variations[${index}].productVariant`);
  const [variants, setVariants] = useState([]);
  const [size, setSize] = useState(null);
  const productRef = useRef(product);

  const hoopsSelectField = useRef();

  const [isCustom, setIsCustom] = useState(false);

  useWatch(() => {
    if (variants.length > 0) {
      let sizeIndex = variants.findIndex((el) => el._id === productVariantId);
      sizeIndex > -1 && setSize(variants[sizeIndex].size);
    }
  }, [productVariantId, variants]);

  const getSizes = useCallback(() => {
    // filter variant (size) options based on colour
    const variantsSorted = sortSizes((product?.variants ?? []).filter((v) => (v.color.name === colour)), 'size.name');

    // variants is used to map the select fields
    setVariants(uniqBy(variantsSorted, 'size.name'));

    // filter selected variant (size) by the defaultvalue set for the field
    const cleanedVariantOptions = uniqBy(variantsSorted, 'size.name');
    const selectedVariant = cleanedVariantOptions.filter((v) => v.size.name === defaultValue);
    const filterVariants = cleanedVariantOptions.filter((v) => v._id === defaultValue);

    setIsCustom((prev) => {
      if (!prev && !defaultValue) {
        return false;
      }
      if (defaultValue) {
        return (!filterVariants.length > 0);
      }
      return false;
    });

    if (!productVariant && filterVariants.length === 0) {
      // if there is a size set that isn't in the variant array, it is custom
      defaultValue && defaultValue.length > 0 && setIsCustom(true);
      setValue(`variations[${index}].productVariant._id`, defaultValue);
    }

    // set the Size field value
    if (productVariant && !productVariant._id && defaultValue) {
      // set the productVariant _id
      if (selectedVariant.length !== 0) {
        setValue(`variations[${index}].productVariant._id`, selectedVariant[0]._id);
      }
    }

    // handle change of color setting the size again if it exists in selected variant
    if (size) {
      let sizeIndex = cleanedVariantOptions.findIndex((el) => el.size.name === size.name);
      sizeIndex > -1
        ? cleanedVariantOptions && setValue(`variations[${index}].productVariant._id`, cleanedVariantOptions[sizeIndex]._id)
        : hoopsSelectField?.current?.errorPulse();
    }
    if (filterVariants.length > 0) {
      setSize(filterVariants[0].size);
    }
  }, [colour, defaultValue, index, product, productVariant, setValue, size]);

  useEffect(() => {
    if (productRef.current !== product && productRef.current?._id != null) {
      setValue(`variations[${index}].productVariant._id`, null);
      setVariants([]);
      setIsCustom(false);
    } else if (colour) {
      product && getSizes();
    } else if (defaultValue && size) {
      // if there is a size set but no color it has to be custom
      if (defaultValue !== productVariantId && defaultValue !== productVariant?._id) {
        setIsCustom(true);
        setValue(`variations[${index}].productVariant._id`, defaultValue);
      }
    }
    productRef.current = product;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colour, defaultValue, index, product]);

  return (
    <>
      {isCustom
        ? <Controller
          name={name}
          defaultValue={defaultValue}
          render={(field) => (
            <HoopsTextField
              className={classes.textFieldWithAdornmentBtn}
              placeholder='Enter custom size'
              InputProps={{
                endAdornment:
                  <InputAdornment position={'end'}>
                    <IconButton onClick={() => setIsCustom(false)}>
                      <Cancel fontSize='small' className={classes.greyText}/>
                    </IconButton>
                  </InputAdornment>
              }}
              {...field}
              onChange={(e) => {
                onPriceChanged();
                field.onChange(e);
              }}
            >

            </HoopsTextField>
          )}
          control={control}
        />
        : <Controller
          name={name}
          defaultValue={defaultValue ? defaultValue : size && size._id}
          render={(field) => (
            <HoopsSelectField
              margin='dense'
              size='small'
              variant='outlined'
              fullWidth
              ref={hoopsSelectField}
              {...field}
              onChange={(e) => {
                onPriceChanged();
                field.onChange(e);
              }}
              {...params}
            >
              {variants.map((o) => (
                <MenuItem key={o._id} value={o._id}>{o.size.name}</MenuItem>
              ))}
              <Divider light style={{marginBottom: 8}}/>
              <MenuItem onClick={() => setIsCustom(true)}>Use custom size</MenuItem>
            </HoopsSelectField>
          )}
          control={control}
        />
      }
    </>
  );

};

export default SizeField;
