import {Grid, makeStyles, Typography} from '@material-ui/core';
import React from 'react';
import {useFormContext} from 'react-hook-form';

const useStyles = makeStyles((theme) => ({greyText: {color: theme.colors.grey.main,}}));

const AutomationTriggerConfigOwnerAssigned = () => {
    const classes = useStyles();
    const {watch} = useFormContext();

    const capitalize = (str) => {
        str = str.replace(/([A-Z])/g, ' $1').trim();
        return str.toLowerCase();
    };

    const entityType = watch('entityType');

    return (
        <Grid container direction='row' justifyContent='center' alignItems='flex-start'>
            <Grid item >
                <Typography variant='h5' className={classes.greyText}>When an owner is assigned to a {capitalize(entityType)},</Typography>
            </Grid>
        </Grid>
    );
};

export default AutomationTriggerConfigOwnerAssigned;
