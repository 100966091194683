import React, {useCallback} from 'react';
import {Button} from '../../Basic';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {registerGlobalStyle} from '../../../theme';
import {CellContainer, useCellContainerState} from './CellContainer';
import {HoopsPropTypes} from '../../utils';

registerGlobalStyle('.action-menu-cell', (theme) => ({
  'svg': {
    color: theme.colors.text.medium,
    transition: theme.transitions.out.all,
    '&:is(button:hover svg)': {
      color: theme.colors.text.highlight,
      transition: theme.transitions.in.all,
    },
    fontSize: '1.5rem',
  },
}));

export function ActionMenuCell({children}) {
  const {editing, startEditing, endEditing} = useCellContainerState();

  const handleClick = useCallback(() => {
    startEditing();
  }, [startEditing]);

  const handleShownChange = useCallback((shown) => {
    if (!shown) {
      endEditing();
    }
  }, [endEditing]);

  return (
    <CellContainer className={'action-menu-cell active'} editing={editing}>
      <Button menu={editing} navMinor suffix={MoreHorizIcon} onShownChange={handleShownChange} onClick={handleClick}>
        {editing &&
          children
        }
      </Button>
    </CellContainer>
  );
}

ActionMenuCell.propTypes = {children: HoopsPropTypes.children,};
