// TODO: fix eslint disable
/* eslint-disable no-duplicate-imports, no-shadow */

import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {getResources} from '../../actions/resource';
import {useEffect} from 'react';

export default ({label, placeholder, value = '', ChipProps = {color: 'primary', size: 'small'}, ...props}) => {
    const itemsSelector = (state) => state.resourceReducer.list;
    const items = useSelector(itemsSelector);

    const itemIds = items.map((item) => item._id);
    const getItem = (itemId) => items.filter((item) => item._id === itemId)[0];
    const dispatch = useDispatch();

    useEffect(
        () => {
          if (items.length === 0) {
            dispatch(getResources({}));
          }

        }, [dispatch, items]
    );

    return (
        <Autocomplete
          limitTags={1}
          options={itemIds}
          label={label}
          value={value}
          ChipProps={ChipProps}
          getOptionLabel={(itemId) => {
            const item = getItem(itemId);
            return item ? item.title : '';
          }}
          onChange={(event, value) => {
            props.onChange(value ? value : null);
          }}

          renderInput={(params) => (
            <TextField {...params} variant='standard' label={label} placeholder={placeholder} />
          )}
        />
      );
};
