import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {get} from 'lodash';
import React, {useCallback} from 'react';
import RenderAutocompleteField from '../../common/RenderAutocompleteField';
import NameWithInitials from '../../NameWithInitials';

function DefaultRenderOption({...option}) {
  const name = get(option, 'name', get(option, 'fullName', ''));
  return (
    <Grid container spacing={1} alignItems={'center'}>
      <Grid item>
        <NameWithInitials name={name} color={'primary'}/>
      </Grid>
    </Grid>
  );
}

function ButtonComponent({label, onClick}) {
  const handleButtonClick = useCallback((e) => {
    // if left click
    if (get(e, 'nativeEvent.button', -1) === 0) {
      onClick(e);
    }
  }, [onClick]);

  return (
    <Box mb={2} mr={2} ml={2} style={{marginTop: 16}}>
      <Grid container alignItems={'center'} justifyContent={'center'}>
        <Grid item>
          {/* onMouseDown is fired before onBlur */}
          <Button color={'primary'} variant={'contained'} onMouseDown={handleButtonClick}>
            {label}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

function HoopsCustomAutocomplete({textFieldProps, RenderOption, input, inputValue, meta, onSelect, buttonProps, setSearchString, hideButton, ...params}) {

  const handleAutocompleteSearch = useCallback((e, value) => {
    if (e && e.type === 'blur') {
      return;
    }
    setSearchString(value);
  }, [setSearchString]);

  return (
    <RenderAutocompleteField
      input={input}
      meta={meta}
      textFieldProps={{...textFieldProps,}}
      
      renderOption={(RenderOption ? RenderOption : DefaultRenderOption)}
      ButtonComponent={(!hideButton && buttonProps && <ButtonComponent {...buttonProps} />)}
      noOptionsText={`No results for "${inputValue}"`}
      onChange={(e, data) => {
        input.onChange(e, data);
        if (data) {
          onSelect(data);
        }
      }}
      getOptionSelected = {(option, value) => get(option,  '_id') === get(value, '_id')}
      inputValue={inputValue}
      onInputChange={handleAutocompleteSearch}
      {...params}
    />
  );
}

export default HoopsCustomAutocomplete;
