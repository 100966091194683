import {Grid, IconButton, InputAdornment, Link as MuiLink, makeStyles, Typography} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import EditIcon from '@material-ui/icons/Edit';
import React, {useCallback, useContext, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {HoopsTextField} from '../../componentsOld/HoopsTextField';
import {convertMarginToMarkup} from '../../componentsOld/shared/hoopsUtils';
import {decimalPlaces, toFloat} from '../../componentsOld/shared/numberUtils';
import {itemBuilder} from '../../ui/theme/itemBuilder';
import adjustmentLineValues from './adjustmentLineValues';
import {ItemBuilderContext} from './ItemBuilderContext';
import {calculatePriceElementBreakdown} from './itemBuilderUtils';

const useStyles = makeStyles((theme) => ({
    ...itemBuilder,
    popOver: {
        '& .MuiPopover-paper': {
            padding: theme.spacing(2),
            width: 366,
            marginTop: theme.spacing(2)
        }
    },
}));

const ItemBuilderSexyPricePopup = ({currencySymbol = '$',}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [sexyPriceValue, setSexyPriceValue] = useState(null);
    const {setPriceElement, setSexyPrice, onPriceChanged} = useContext(ItemBuilderContext);
    const {adjustmentLineName} = adjustmentLineValues;

    const {watch} = useFormContext();
    const priceElement = watch();
    const values = calculatePriceElementBreakdown(priceElement);

    const handleOpenPopover = (event) => {
        setSexyPriceValue(values.sellPricePerUnit);
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = useCallback(() => {
        setAnchorEl(null);
    },[]);

    const handleSave = useCallback(() => {
        let newPriceElement = {...priceElement};

        // Check for existing adjustment
        const additionalCosts = newPriceElement && newPriceElement.additionalCosts && newPriceElement.additionalCosts.filter((el) => el !== null);
        const existingAdjustment = additionalCosts && additionalCosts.find((additionalCost) => additionalCost.name === adjustmentLineName);
        const existingAdjustmentIndex = additionalCosts && additionalCosts.findIndex((additionalCost) => additionalCost.name === adjustmentLineName);

        // select the correct quanitity
        const quantity = values.productQuantity === 0
            ? values.effectiveQuantity
            : values.productQuantity;

        // calculate the adjustment including existing
        const adjustmentValue = ((toFloat(sexyPriceValue) - values.sellPricePerUnit) * quantity) + ((existingAdjustment && toFloat(existingAdjustment.sellPrice)) || 0);

        // account for grossmargin calculations
        const grossMarginTarget = priceElement.grossMarginTarget;
        const markup = convertMarginToMarkup(grossMarginTarget) / 100;

        const buyPrice = grossMarginTarget
            //this is sellPrice * (1 - markup / 100 )
            ? (adjustmentValue / quantity) / (1 + (markup))
            : adjustmentValue / quantity;

        const adjustmentRow = {
            markup: 0,
            sellPrice: adjustmentValue,
            name: adjustmentLineName,
            vendor: null,
            buyPrice: decimalPlaces(buyPrice, 2),
            ignoredByJob: true,
        };

        if (existingAdjustmentIndex && existingAdjustmentIndex !== -1) {
            newPriceElement.additionalCosts[existingAdjustmentIndex] = adjustmentRow;
        } else if (newPriceElement.additionalCosts && newPriceElement.additionalCosts.length) {
            newPriceElement.additionalCosts[additionalCosts.length] = adjustmentRow;
        } else {
            newPriceElement['additionalCosts'] = [adjustmentRow];
        }

        setSexyPrice(sexyPriceValue);
        onPriceChanged();
        setPriceElement(newPriceElement);

        handleClosePopover();
    },[adjustmentLineName, values, handleClosePopover, onPriceChanged, priceElement, setPriceElement, setSexyPrice, sexyPriceValue]);

    const open = Boolean(anchorEl);
    const id = open ? 'sexy-price-popover' : undefined;

    return (
        <>
            <IconButton aria-describedby={id} style={{display: 'block', padding: '2px'}} onClick={handleOpenPopover}>
                <EditIcon style={{fontSize: '1rem'}} />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                className={classes.popOver}
            >
                <Typography variant='body2'><b>Override Sell Price Per Unit</b></Typography>
                <Grid
                    container
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    style={{marginTop: 8, marginBottom: 8}}
                >
                    <Grid item><Typography variant='caption'>Enter new Sell Price</Typography></Grid>
                    <Grid item>
                        <HoopsTextField
                            type='number'
                            style={{maxWidth: 144}}
                            InputProps={{startAdornment: <InputAdornment position={'start'}>{currencySymbol}</InputAdornment>}}
                            defaultValue={sexyPriceValue ? decimalPlaces(sexyPriceValue, 2) : 0}
                            onChange={(e) => setSexyPriceValue(e.target.value)}
                            autoFocus
                            onFocus={(event) => {
                                event.target.select();
                            }}
                            onKeyPress={(ev) => {
                                if (open && ev.key === 'Enter') {
                                    handleSave();
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                <Typography variant='caption' className={classes.greyText}>
                    Overriding the sell price per unit will recalculate all markins/markups based on your selected logic.<br /><br />

                    If other changes are made (such as adding/removing decorations/variants) this sell price will not persist and you will need to repeat this process.<br />
                    <MuiLink color={'primary'} underline={'none'} href={'http://help.hoopscrm.com/en/articles/'} target='_blank'>Learn more</MuiLink>
                </Typography>
                <Grid
                    container
                    direction='row'
                    justifyContent='space-between'
                    alignItems='flex-start'
                    style={{marginTop: 8}}
                >
                    <Grid item><Button onClick={handleClosePopover} type='submit' className={classes.greyText}>Cancel</Button></Grid>
                    <Grid item><Button variant='contained' color='primary' disableElevation onClick={handleSave}>Save</Button></Grid>
                </Grid>
            </Popover>
        </>
    );
};

export default ItemBuilderSexyPricePopup;
