import React, {useCallback, useContext, useRef, useState} from 'react';
import {Box} from '@mui/material';
import {PageContext} from '../../../../componentsOld/PageContext';

export const CellContainerPopover = ({value, options, colDef, children, popover: P, ...props}) => {
  const [open, setOpen] = useState(props.cellMode === 'edit');
  const {updateField} = useContext(PageContext);
  const anchorElRef = useRef(null);

  const handlePopupClick = useCallback((event) => {
    event.stopPropagation();
  }, []);

  const handleClick = useCallback((event) => {
    event.stopPropagation();
    if (colDef.editable) {
      setOpen((isOpen) => !isOpen);
    }
  }, [colDef.editable]);

  const handleClose = useCallback((event) => {
    event?.stopPropagation();
    setOpen(false);
  }, [setOpen]);

  const handleChange = useCallback((newValue) => {
    if (newValue !== value) {
      if (updateField) {
        updateField({_id: props.row._id, field: props.field, value: newValue});
      }
    }
    setOpen(false);
  }, [value, props.row._id, props.field, updateField]);

  return (
          <Box className='CellContainer'
               ref={anchorElRef}
               onClick={handleClick}>
            {anchorElRef.current && open &&
                    <P id={props.id}
                       open={open}
                       anchorEl={anchorElRef.current}
                       onChange={handleChange}
                       onClose={handleClose}
                       onClick={handlePopupClick}
                       anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                       transformOrigin={{vertical: 'top', horizontal: 'center'}}
                       value={value}
                       options={options}
                    />}
            {children}
          </Box>
  );
};
