// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-class-assign */

import React, {Component} from 'react';
import {Grid, Button, MuiThemeProvider} from '@material-ui/core';
import ProductDetailTable from './ProductDetailTable';
import ProductShippingDetailsTable from './ProductShippingDetailsTable';
import {connect} from 'react-redux';
import {toggleEditProduct as _toggleEditProduct, updateProduct as _updateProduct,} from '../../../actions/catalog';
import green from '../../../ui/theme/green';
import {Form} from 'react-final-form';

const PRODUCT_DETAILS_FORM = 'productDetailsFormId';

class ProductDetail extends Component {

  saveProduct = ({primaryImage, ...values}) => {
    const {toggleEditProduct, updateProduct, product} = this.props;
    updateProduct(product._id, values);
    toggleEditProduct();
  };

  render() {
    const {product, toggleEditProduct, editing} = this.props;
    const {readOnly} = product;
    return (
      <>
        <Form
          onSubmit={this.saveProduct}
          initialValues={product}
          render={({handleSubmit}) => (
            <form id={PRODUCT_DETAILS_FORM} onSubmit={handleSubmit} style={{width: '100%'}}>
              <Grid container justifyContent={'flex-end'} spacing={2}>
                {!readOnly && <Grid item>
                  {editing ? (
                    <MuiThemeProvider theme={green}>
                      <Button
                        variant={'contained'}
                        color={'primary'}
                        type={'submit'}
                      >Save</Button>
                    </MuiThemeProvider>
                  ) : (
                    <Button
                      variant={'outlined'}
                      color={'primary'}
                      onClick={toggleEditProduct}
                    >Edit</Button>
                  )}
                </Grid>}
                <Grid item container spacing={3}>
                  <Grid item lg={6} md={12}>
                    <ProductDetailTable product={product} editing={editing}/>
                  </Grid>
                  <Grid item lg={6} md={12}>
                    <ProductShippingDetailsTable product={product} editing={editing}/>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    product: state.catalogReducer.activeProduct,
    editing: state.catalogReducer.editing,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleEditProduct: () => dispatch(_toggleEditProduct()),
    updateProduct: (_id, data) => dispatch(_updateProduct(_id, data)),
  };
}

ProductDetail = connect(mapStateToProps, mapDispatchToProps)(ProductDetail);
export default ProductDetail;
