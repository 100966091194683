// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import {Checkbox, makeStyles} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import {MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import {get} from 'lodash';
import MaterialTable from 'material-table';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {editEntity} from '../../../ui/theme/editEntity';
import green from '../../../ui/theme/green';
import {backOrderColumns} from './backOrderColumns';
import {baseColumnWidth} from './PurchaseOrder';
import PurchaseOrderCheckboxToolTip from './PurchaseOrderCheckboxToolTip';
import {RenderRate} from './VariantsTable';

export function RenderLinkedJobs({jobs}) {
  const useStyle = makeStyles((theme) => ({chip: {margin: theme.spacing(1) / 2}}));
  const classes = useStyle();
  return (
    jobs.map((job) => <Chip className={classes.chip} key={job._id} color={'primary'}
      label={job.number} />)
  );
}

class DecorationsTable extends Component {
  state = {
    tableOptions: {
      showTitle: false,
      paging: false,
      search: false,
      sorting: false,
      draggable: false,
      headerStyle: {backgroundColor: '#E8EAED'},
      toolbar: false,
    }
  };

  columns = [];

  componentDidMount() {
    this.setColumns();
  }

  setColumns = () => {
    const {purchaseOrder, item, company} = this.props;
    const allOrdered = this.allItemsSelected(item, 'ordered');
    const allReceived = this.allItemsSelected(item, 'received');
    this.columns = [{
      title: 'Name/Description',
      field: 'description',
      cellStyle: baseColumnWidth,
      headerStyle: baseColumnWidth
    }, {
      title: 'Position',
      field: 'position',
      cellStyle: baseColumnWidth,
      headerStyle: baseColumnWidth
    }, {
      title: 'Colour(s)',
      field: 'colours',
      cellStyle: baseColumnWidth,
      headerStyle: baseColumnWidth
    }, {
      title: 'Size',
      field: 'size',
      cellStyle: baseColumnWidth,
      headerStyle: baseColumnWidth
    }, {
      title: 'Quantity',
      field: 'quantity',
      cellStyle: baseColumnWidth,
      headerStyle: baseColumnWidth
    }, {
      title: 'Rate',
      field: 'rate',
      cellStyle: baseColumnWidth,
      headerStyle: baseColumnWidth,
      render: (row) => <RenderRate rate={get(row, 'rate', 0)} company={company} />
    }, {
      title: (
        <PurchaseOrderCheckboxToolTip
          masterPurchaseOrderId={purchaseOrder.masterPurchaseOrderId}
        >
          <MuiThemeProvider theme={green}>
            <Checkbox
              onChange={() => this.handleBulkStatusUpdate(item, 'ordered')}
              color='primary'
              checked={allOrdered}
              disabled={Boolean(purchaseOrder.masterPurchaseOrderId)}
            />
          </MuiThemeProvider>
          Ordered
        </PurchaseOrderCheckboxToolTip>
      ),
      cellStyle: baseColumnWidth,
      key: 'orderedCheckBox',
      headerStyle: baseColumnWidth,
      render: (row) => (
        <PurchaseOrderCheckboxToolTip
          masterPurchaseOrderId={purchaseOrder.masterPurchaseOrderId}
        >
          <MuiThemeProvider theme={green}>
            <Checkbox
              onChange={() => this.handleStatusUpdate(row._id, 'ordered', row.ordered)}
              color='primary'
              checked={row.ordered}
              disabled={Boolean(purchaseOrder.masterPurchaseOrderId)}
            />
          </MuiThemeProvider>
        </PurchaseOrderCheckboxToolTip>
      ),
    },
      ...backOrderColumns.call(this),
      {
      title: (
        <PurchaseOrderCheckboxToolTip
          masterPurchaseOrderId={purchaseOrder.masterPurchaseOrderId}
        >
          <MuiThemeProvider theme={green}>
            <Checkbox
              onClick={() => this.handleBulkStatusUpdate(item, 'received')}
              color='primary'
              checked={allReceived}
              disabled={Boolean(purchaseOrder.masterPurchaseOrderId)}
            />
          </MuiThemeProvider>
          Received
        </PurchaseOrderCheckboxToolTip>
      ),
      cellStyle: baseColumnWidth,
      key: 'receivedCheckBox',
      headerStyle: baseColumnWidth,
      render: (row) => (
        <PurchaseOrderCheckboxToolTip
          masterPurchaseOrderId={purchaseOrder.masterPurchaseOrderId}
        >
          <MuiThemeProvider theme={green}>
            <Checkbox
              checked={row.received}
              color='primary'
              onClick={() => this.handleStatusUpdate(row._id, 'received', row.received)}
              disabled={Boolean(purchaseOrder.masterPurchaseOrderId)}
            />
          </MuiThemeProvider>
        </PurchaseOrderCheckboxToolTip>
      ),
    }];
  };

  handleStatusUpdate = (rowId, field, currentStatus) => {
    this.props.setTouched(true);
    // Update the relevant field and then store to state
    this.props.setPurchaseOrder((prev) => {
      let clonePO = {...prev};
      clonePO.items.find((item) => item._id === this.props.item._id).decorations.find((decoration) => decoration._id === rowId)[field] = !currentStatus;
      return (clonePO);
    });
  };

  handleBulkStatusUpdate(Item, field) {
    this.props.setTouched(true);
    const allSelected = this.allItemsSelected(Item, field);
    this.props.setPurchaseOrder((prev) => {
      let clonePO = {...prev};
      const decorations = clonePO.items.find((item) => item._id === Item._id).decorations;
      for(const decoration of decorations){
        decoration[field] = !allSelected;
      }
      return (clonePO);
    });
  }

  allItemsSelected = (Item, status) => {
    let allSelected = true;
    Item.decorations.forEach((decoration) => {
      if (!decoration[status]) {
        allSelected = false;
      }
    });
    return allSelected;
  };

  render() {
    const {decorations, readOnly} = this.props;
    const {tableOptions} = this.state;
    this.setColumns();
    return (
      <MaterialTable
        columns={
          readOnly ?
            this.columns.filter((obj) => obj.key !== 'orderedCheckBox' && obj.key !== 'receivedCheckBox')
            : this.columns
        }
        data={decorations}
        options={tableOptions}
      />
    );
  }
}

function mapStateToProps(state) {
  return {company: state.companyReducer.company};
}

const styles = (theme) => ({
  ...editEntity,
  vertDivider: {
    margin: '0 auto',
    color: theme.colors.grey.light,
  }
});

DecorationsTable = connect(mapStateToProps)(DecorationsTable);
export default withStyles(styles)(DecorationsTable);
