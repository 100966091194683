// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React, {Fragment} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import {renderInputField, renderTextField} from '../shared/formHelpers/fields';
import renderAutosuggest, {renderSuggestion} from '../shared/formHelpers/autosuggest';
import {Field, FieldArray, reduxForm, change} from 'redux-form';
import {connect} from 'react-redux';
import InputAdornment from '@material-ui/core/InputAdornment';
import {required} from '../shared/formHelpers/validation';
import {searchCategories, setCategorySuggestions} from '../../actions/decoration';
import VendorAutocomplete from '../vendors/VendorAutocomplete';

const renderPriceBreak = ({fields, company}) => (
  <Grid container spacing={2}>
    {fields.map((priceBreak, index) => (
        <Fragment key={index}>
          <Grid item xs={5}>
            <Field fullWidth
                   label={'Quantity'}
                   name={`${priceBreak}.quantity`}
                   type={'number'}
                   validate={[required]}
                   component={renderTextField}
                   data-intercom-target={'decoration-form-quantity'}
            />
          </Grid>
          <Grid item xs={5}>
            <Field fullWidth
                   label={'Price'}
                   name={`${priceBreak}.price`}
                   startAdornment={<InputAdornment position='start'>{company.currencySymbol}</InputAdornment>}
                   component={renderInputField}
                   type={'number'}
                   validate={[required]}
                   inputProps={{min: '0', step: '0.01'}}
                   data-intercom-target={'decoration-form-price'}
            />
          </Grid>
          <Grid container item justifyContent={'center'} xs={2}>
            <IconButton data-intercom-target={'decoration-form-price-break-delete'} disabled={fields.length === 1} onClick={() => fields.splice(index, 1)}>
              <Icon>delete</Icon>
            </IconButton>
          </Grid>
        </Fragment>
      ))}
    <Grid item>
      <Button data-intercom-target={'decoration-form-add-price-break'} variant='text' color={'primary'} onClick={() => fields.push({})}> Add Another Price Break</Button>
    </Grid>
  </Grid>
);

class DecorationForm extends React.Component {
  getCategoryValue = (suggestion) => {
    const {dispatch} = this.props;
    dispatch(change('decorationForm', 'category', suggestion.name));
  };

  getCategories = (update) => {
    const {dispatch} = this.props;
    dispatch(searchCategories(update.value));
    return update.value;
  };

  clearCategories = () => {
    const {dispatch} = this.props;
    dispatch(setCategorySuggestions([]));
  };

  render() {
    const {onSubmit, company, categorySuggestions} = this.props;

    const autosuggestProps = {
      suggestions: categorySuggestions,
      getSuggestionValue: this.getCategoryValue,
      onSuggestionsFetchRequested: this.getCategories,
      onSuggestionsClearRequested: this.clearCategories,
      highlightFirstSuggestion: true,
    };

    return (
      <form onSubmit={onSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Field fullWidth
                   label={'Decoration Name *'}
                   name={'name'}
                   type={'text'}
                   validate={required}
                   component={renderTextField}
                   data-intercom-target={'decoration-form-name'}/>
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              label={'Vendor'}
              name={'vendor'}
              component={VendorAutocomplete}
            />
          </Grid>
          <Grid item xs={6}>
            <Field fullWidth
                   label={'Category *'}
                   name={'category'}
                   validate={required}
                   type={'text'}
                   autosuggestProps={autosuggestProps}
                   component={renderAutosuggest}
                   suggestionComponent={renderSuggestion}
                   data-intercom-target={'decoration-form-category'}
            />
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Field fullWidth
                   startAdornment={<InputAdornment position='start'>{company.currencySymbol}</InputAdornment>}
                   label={'Setup Cost'}
                   name={'setupPrice'}
                   type={'number'}
                   inputProps={{step: '0.01'}}
                   component={renderInputField}
                   data-intercom-target={'decoration-form-setup-cost'}
            />
          </Grid>
          <Grid item xs={12}>
            <FieldArray name={'priceBreaks'} company={company} component={renderPriceBreak}/>
          </Grid>
        </Grid>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    decoration: state.decorations.decoration,
    company: state.companyReducer.company,
    categorySuggestions: state.decorations.categorySuggestions,
    initialValues: state.decorations.decoration,
  };
}

DecorationForm = reduxForm({form: 'decorationForm'})(DecorationForm);
DecorationForm = connect(mapStateToProps)(DecorationForm);

export default DecorationForm;
