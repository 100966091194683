import {isSameDay} from 'date-fns';
import {DayPilotNavigator} from 'daypilot-pro-react';
import React, {useEffect} from 'react';
import './Navigator.scss';

const defaultConfig = {
    theme: 'less_ugly',
    orientation: 'Horizontal',
    showMonths: 2
};

export const Navigator = ({
    startDate = null,
    onNavigate = () => null,
    selectMode = 'day'
}) => {
    const ref = React.useRef(null);

    const handleTimeRangeSelected = (args) => {
        const {start, end} = args;
        const startLocal = start.toDateLocal();
        const endLocal = end.toDateLocal();
        
        if (isSameDay(startLocal, startDate.toDateLocal())) {
            return;
        }

        onNavigate({
            selectMode,
            start: startLocal,
            end: endLocal
        });
    };

    useEffect(() => {
        if (startDate && ref.current && ref.current.control) {            
            ref.current.control.select(startDate);
        }
    }, [startDate, ref]);

    useEffect(() => {
        if (selectMode && ref.current && ref.current.control) {
            const control = ref.current.control;
            
            if (!control.selectionStart) {
                return;
            }

            if (control.selectMode === selectMode) {
                return;
            }

            control.update({selectMode});

            onNavigate({
                selectMode: control.selectMode,
                start: control.selectionStart.toDateLocal(),
                end: control.selectionEnd.toDateLocal(),
            });
        }
    }, [selectMode, onNavigate, ref]);

    const config = {...defaultConfig};

    return (
        <DayPilotNavigator
            {...config}
            ref={ref}
            onTimeRangeSelected={handleTimeRangeSelected}
        />
    );
};

export default Navigator;
