// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-class-assign, no-shadow */

import React, {Component} from 'react';
import {
  Button, Dialog, DialogContent, Typography, Grid, Icon,
  TableBody, TableRow, TableCell as MuiTableCell, Table, TableHead, withStyles,
  IconButton, InputAdornment, MenuItem
} from '@material-ui/core';
import {Select} from 'final-form-material-ui';
import DialogTitle from '../../DialogTitle';
import DialogActions from '../../DialogActions';
import {connect} from 'react-redux';
import {
  closeProductPriceVariantsModal as _closeProductPriceVariantsModal,
  SET_LOADING_VARIANTS_MODAL
} from '../../../../actions/modals';
import {Field, Form} from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import {FieldArray} from 'react-final-form-arrays';
import DeleteIcon from '@material-ui/icons/Delete';
import classNames from 'classnames';
import {get} from 'lodash';
import {
  updateProductVariants as _updateProductVariants,
  bulkUpdateProductVariants as _bulkUpdateProductVariants
} from '../../../../actions/catalog';
import {required} from '../../formHelpers/validation';
import {renderTextField} from '../../formHelpers/fields';

const TableCell = withStyles(() => ({root: {padding: 2}}))(MuiTableCell);

const styles = (theme) => ({
  noBorder: {border: 0,},
  marginLeft: {marginLeft: theme.spacing(1) * 0.5},
  smallIcon: {height: theme.spacing(2),},
  largeColumn: {
    width: 140,
    padding: theme.spacing(1) * 0.5
  },
  regularColumn: {padding: theme.spacing(1) * 0.5,},
  smallColumn: {
    width: 80,
    padding: theme.spacing(1) * 0.5
  },
  paddedTextfield: {
    margin: '0px',
    padding: theme.spacing(1) * 0.5
  },
  paddedSelectField: {
    margin: 0,
    padding: 10
  }
});

class ProductPriceVariantsModal extends Component {

  closeModal = () => {
    const {closeModal} = this.props;
    closeModal();
  };

  handleSubmit = (values) => {
    const {context, bulkUpdateProductVariants} = this.props;
    bulkUpdateProductVariants(context._id, values);
    this.closeModal();
  };

  handleEntered = () => {
    const {setPriceVariantsModalLoading} = this.props;
    setPriceVariantsModalLoading(false);
  };

  render() {
    const {isOpen, classes, context, company} = this.props;
    const colors = get(context, 'colors', []);
    const sizes = get(context, 'sizes', []);
    const variants = get(context, 'variants', []);
    const priceBreaks = get(variants[0], 'priceBreaks', []);

    isOpen && this.handleEntered();

    return (
      <Dialog
        open={isOpen}
        maxWidth={'lg'}
        fullWidth={true}
        onClose={this.closeModal}
      >
        <DialogTitle onClick={this.closeModal}>
          <Typography variant='h5'>Edit Product Variants</Typography>
        </DialogTitle>
        <Form
          onSubmit={this.handleSubmit}
          mutators={{...arrayMutators}}
          initialValues={{variants}}
          render={({handleSubmit, form: {mutators: {push, remove}}}) => (
            <form onSubmit={handleSubmit}>
              <DialogContent style={{overflowX: 'scroll'}}>
                <Table size={'small'} padding={'none'}>
                  {/* Table Heading */}
                  <TableHead>
                    <TableRow>
                      <TableCell className={classNames(classes.noBorder, classes.largeColumn)}>Color</TableCell>
                      <TableCell className={classNames(classes.noBorder, classes.largeColumn)}>Size</TableCell>
                      {priceBreaks.map((priceBreak, index) => (
                        <TableCell key={index} className={classNames(classes.noBorder, classes.smallColumn)}>
                          {priceBreak.quantity}
                        </TableCell>
                      ))}
                      <TableCell className={classNames(classes.noBorder, classes.smallColumn)}/>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* Variant Mapping Start */}
                    <FieldArray name={'variants'}>
                      {({fields}) => fields.map((variant, index) => (
                        <TableRow key={variant}>
                          <TableCell className={classes.noBorder}>
                            <Field
                              name={`${variant}.color._id`}
                              component={Select}
                              variant={'outlined'}
                              validate={required}
                              formControlProps={{
                                fullWidth: true,
                                size: 'small'
                              }}
                            >
                              {colors && colors.map((color) => <MenuItem key={color._id} value={color._id}>{color.name}</MenuItem>)}
                            </Field>
                          </TableCell>
                          <TableCell className={classes.noBorder}>
                            <Field
                              name={`${variant}.size._id`}
                              component={Select}
                              size={'small'}
                              variant={'outlined'}
                              validate={required}
                              formControlProps={{
                                fullWidth: true,
                                size: 'small'
                              }}
                            >
                              {sizes && sizes.map((size) => <MenuItem key={size._id} value={size._id}>{size.name}</MenuItem>)}
                            </Field>
                          </TableCell>
                          {/* Price Break Mapping Start */}
                          <FieldArray name={`${variant}.priceBreaks`}>
                            {({fields}) => fields.map((name) => (
                              <TableCell key={name} className={classes.noBorder}>
                                <Field
                                  name={`${name}.price`}
                                  component={renderTextField}
                                  margin={'dense'}
                                  size={'small'}
                                  variant={'outlined'}
                                  className={classes.paddedTextfield}
                                  InputProps={{
                                    startAdornment: <InputAdornment position='start'>{company.currencySymbol}</InputAdornment>,
                                    step: '0.01'
                                  }}
                                  validate={required}
                                  parse={(value) => parseFloat(value)}
                                  type={'number'}
                                />
                              </TableCell>
                            ))}
                          </FieldArray>
                          <TableCell className={classes.noBorder}>
                            <IconButton className={classes.marginLeft} size={'small'}
                                        onClick={() => remove('variants', index)}>
                              <DeleteIcon classes={{root: classes.smallIcon}}/>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </FieldArray>
                  </TableBody>
                </Table>
                {/*<Button color={"primary"} onClick={() => push('variants', this.generateNewRow(priceBreaks))}>Add Variant</Button>*/}
              </DialogContent>
              <DialogActions>
                <Grid container justifyContent={'space-between'}>
                  <Grid item>
                    <Button onClick={this.closeModal}>
                      <Icon>close</Icon>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={2}>
                      <Grid item>
                        <Button
                          variant={'contained'}
                          color={'primary'}
                          type='submit'>Save</Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => dispatch(_closeProductPriceVariantsModal()),
    updateProductVariants: (_id, data) => dispatch(_updateProductVariants(_id, data)),
    bulkUpdateProductVariants: (productId, data) => dispatch(_bulkUpdateProductVariants(productId, data)),
    setPriceVariantsModalLoading: (status = false) => dispatch({
      type: SET_LOADING_VARIANTS_MODAL,
      payload: status
    })
  };
}

function mapStateToProps(state) {
  return {
    company: state.companyReducer.company,
    isOpen: state.modalReducer.productPriceVariantsModal.isOpen,
    context: state.modalReducer.productPriceVariantsModal.context,
  };
}

ProductPriceVariantsModal = withStyles(styles)(ProductPriceVariantsModal);
ProductPriceVariantsModal = connect(mapStateToProps, mapDispatchToProps)(ProductPriceVariantsModal);
export default ProductPriceVariantsModal;
