import {Typography} from '@mui/material';
import React, {useMemo} from 'react';
import theme from '../../ui/theme';
import {ViewTabs} from './Views';

export const PageTitleHero = ({
                                currentView,
                                editingView,
                                title,
                                views,
                                onAddView,
                                allowAddViews,
                                onChangeCurrentView,
                                onDeleteView,
                                onRenameView,
                                children
                              }) => {
  const tabViews = useMemo(() => {
    if (views && Array.isArray(views) && views.length > 0) {
      return views.map((v, i) => ({name: v.name, id: i === 0 ? 'home' : v._id}));
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [views, views?.length]);

  return (
    <>
    <div className='page-title-hero'
         style={{padding: '12px 16px 11px', width: '100%', maxWidth: '100%', display: 'flex'}}>
      <div style={{display: 'flex', flexGrow: 1, marginRight: 16}}>
        <div style={{flexGrow: 0, flexShrink: 1, paddingRight: 18}}>
          <Typography variant='h4'>{title}<span style={{color: theme.palette.primary.main}}>Board</span></Typography>
        </div>

      </div>
      <div style={{flexGrow: 0, flexShrink: 1}}>
        {children}
      </div>
    </div>
      <div style={{padding: '0 18px 15px 18px'}}>
      {tabViews.length > 0 &&
        <ViewTabs
          currentView={currentView}
          editingView={editingView}
          views={tabViews}
          onAddView={onAddView}
          allowAddViews={allowAddViews}
          onChangeCurrentView={onChangeCurrentView}
          onDeleteView={onDeleteView}
          onRenameView={onRenameView}
        />
      }
      </div>
    </>
  );
};
