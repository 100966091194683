import React, {useCallback, useState} from 'react';
import {Button, Grid} from '@material-ui/core';
import {withStyles, withTheme} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LocalShipping from '@material-ui/icons/LocalShipping';
import CardContainer from '../../common/CardContainer';
import HoopsQueryTableCellDateTime from '../../HoopsQuery/HoopsQueryTableCellDateTime';
import Loader from '../../shared/loader';
import {AddTrackNumberButton} from './AddTrackNumberButton';
import {DeleteTrackingNumber} from './DeleteTrackingNumber';
import JobCustomerCard from './JobDetails/JobCustomerCard';
import {tableStyles} from './JobDetails/tableStyles';
import JobNotification from './JobNotification';
import {ShippingInstructions} from './ShippingInstructions';
import {UpdateTrackingNumber} from './UpdateTrackingNumber';
import {useGetJob, useUpdateJob} from '../../../hooks/api';

function _JobShipping({theme, classes, job}) {
  const {data, isLoading, refetch} = useGetJob(job?._id);
  const [updating, setUpdating] = useState(false);
  const trackingNumbers = (data?.trackingNumbers) || job?.trackingNumbers || [];
  const shippingLabelLink = `${process.env.REACT_APP_BASE_URL}/jobs/${job._id}/shipping-label`;
  const {mutateAsync: update} = useUpdateJob();

  const onSubmit = useCallback(async (model) => {
    setUpdating(true);
    await update({id: job._id, data: {...model}});
    await refetch();
    setUpdating(false);
  }, [job?._id, update, refetch]);

  return (
    <>
      <JobCustomerCard
        button={<Button
          component={'a'}
          target={'_blank'}
          href={shippingLabelLink}
          variant={'contained'}
          color={'primary'}
        >PRINT SHIPPING LABEL</Button>}
        job={job}
        footer={!isLoading && <ShippingInstructions jobId={job._id} instructions={data?.shippingInstructions} onSubmit={onSubmit} updating={isLoading || updating}/>}
      />
      <CardContainer
        data-intercom-target={'job-shipping-tracking-numbers'}
        title='Tracking Numbers'
        icon={<LocalShipping/>}
        iconBackgroundColor={theme.palette.primary.main}
        buttonComponent={
          [
            <JobNotification key={1} jobId={job._id} disabled={trackingNumbers.length === 0}/>,
            <div key={2} style={{width: 5, display: 'inline-block'}}/>,
            <AddTrackNumberButton key={3} trackingNumbers={trackingNumbers} jobId={job._id} onSubmit={onSubmit}/>
          ]
        }
      >
        {isLoading && <Loader/>}
        {trackingNumbers.length > 0 && <Grid container>
          <TableContainer>
            <Table size={'small'} className={classes.topBuffer}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeader}> Shipping Company </TableCell>
                  <TableCell className={classes.tableHeader}>Tracking Number</TableCell>
                  <TableCell className={classes.tableHeader}>Date Added</TableCell>
                  <TableCell className={classes.tableHeader}>Creator</TableCell>
                  <TableCell className={classes.tableHeader}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {trackingNumbers.map(({shippingCompany, trackingNumber, createdAt, creator}, index) => (
                  <TableRow key={index}>
                    <TableCell> {shippingCompany}</TableCell>
                    <TableCell> {trackingNumber}</TableCell>
                    <TableCell> <HoopsQueryTableCellDateTime value={createdAt}/></TableCell>
                    <TableCell> {creator && creator.name}</TableCell>
                    <TableCell>
                      <UpdateTrackingNumber jobId={job._id} trackingNumbers={trackingNumbers} index={index} onSubmit={onSubmit}/>
                      <DeleteTrackingNumber jobId={job._id} trackingNumbers={trackingNumbers} index={index} onSubmit={onSubmit}/>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>}
      </CardContainer>
    </>
  );
}

export const JobShipping = withTheme(withStyles(tableStyles)(_JobShipping));
