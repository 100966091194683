// TODO: fix eslint disable
/* eslint-disable no-class-assign, no-shadow */

import React, {Fragment} from 'react';
import DialogTitle from '../shared/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '../shared/DialogActions';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import green from '../../ui/theme/green';
import {ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles';
import InviteUserForm from './InviteUserForm';
import {submit} from 'redux-form';
import {connect} from 'react-redux';
import {hoopsQuerySetRefetch} from '../../actions/hoopsQuery';

class InviteUserDialog extends React.Component {
  state = {open: false};

  handleClose = () => {
    const {hoopsQuerySetRefetch} = this.props;
    this.setState({open: false});
    hoopsQuerySetRefetch();
  };

  handleOpen = () => {
    this.setState({open: true});
  };

  inviteUser = () => {
    console.log('Should invite');
  };

  render() {
    const {open} = this.state;
    const {submit} = this.props;

    return (
      <Fragment>
        <MuiThemeProvider theme={green}>
          <Button data-intercom-target={'users-table-invite'} variant={'contained'} color={'primary'} onClick={this.handleOpen}>Invite User</Button>
        </MuiThemeProvider>
        <Dialog open={open} maxWidth={'md'} onClose={this.handleClose}>
          <DialogTitle onClick={this.handleClose} disableTypography={true} style={{maxWidth: '800px'}}>
            <h3>Invite User</h3>
          </DialogTitle>
          <DialogContent data-intercom-target={'invite-user-screen'} style={{overflowY: 'hidden', maxWidth: '800px'}}>
            <InviteUserForm onClose={this.handleClose} />
          </DialogContent>
          <DialogActions style={{maxWidth: '800px'}}>
            <Grid container justifyContent={'space-between'}>
              <Grid item>
                <Button color={'secondary'} onClick={this.handleClose}>Cancel</Button>
              </Grid>
              <Grid item>
                <Button color={'primary'} variant={'contained'} onClick={() => submit()}>Send Invitation</Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    submit: () => dispatch(submit('inviteUserForm')),
    hoopsQuerySetRefetch: () => dispatch(hoopsQuerySetRefetch('user', true)),
  };

}

InviteUserDialog = connect(null, mapDispatchToProps)(InviteUserDialog);

export default InviteUserDialog;
