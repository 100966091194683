import * as React from 'react';
import {Button} from '../../componentsLib/Basic';
import {ExpandMore as DownArrowIcon, ExpandLess as UpArrowIcon} from '@mui/icons-material';

export const JobActionButton = ({
  MenuComponent = () => null,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const suffix = anchorEl ? UpArrowIcon : DownArrowIcon;

  const handleActionsClick = (e) => {
    setAnchorEl(e?.currentTarget);
  };

  return (
    <>
      <Button navStandard suffix={suffix} text='Actions' onClick={handleActionsClick} />
      {anchorEl &&
        <MenuComponent
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          elevation={4}
          {...props}
        />
      }
    </>
  );
};
