import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@mui/material';
import React, {useContext} from 'react';
import {HoopsButton} from '../../componentsLib';
import {ReportContext} from './Reporting';
import {asCurrencyStringCommaSeparated, formatIso, formatDateLong} from '../../utils';
import store from '../../store';
import {Box} from '@mui/system';
import '../../assets/scss/print.scss';
import qs from 'qs';

export const RoyaltySummaryReport = ({
  currencySymbol,
  reportSettings,
}) => {
  const {data} = useContext(ReportContext);

  const handlePrint = () => {
    let printContents = document.getElementById('printableArea').innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    window.location.reload();
  };

  const handleDownload = () => {
    const csvPath = `${process.env.REACT_APP_BASE_URL}/rest/reports/fullypromoted/csv/summary?${qs.stringify({startDate: formatIso(reportSettings.startDate), endDate: formatIso(reportSettings.endDate), skip: 0, limit: 200})}&tenantId=${reportSettings.tenantId}`;
    const link = document.createElement('a');
    link.download = 'royalty.csv';
    link.href = csvPath;
    link.click();
  };

  return (
    <Grid item xs={12}>
      <Grid container id='printableArea'>
        <Grid item xs={6}>
          <Typography variant={'h5'}>Fully Promoted - Royalty Summary Report</Typography>
        </Grid>
        <Grid item xs={6}>
          <Box display='flex' justifyContent='flex-end' className='noPrint'>
            <HoopsButton color={'primary'}
              purpose='add'
              variant={'contained'}
              onClick={handlePrint}
              sx={{whiteSpace: 'nowrap', mr: 1}}
            >
              Print
            </HoopsButton>
            <HoopsButton color={'primary'}
              purpose='add'
              variant={'contained'}
              onClick={handleDownload}
              sx={{whiteSpace: 'nowrap'}}
            >
              Download CSV
            </HoopsButton>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{mt: 2}}>
          <Typography variant='h6'>{`Report date from ${formatDateLong(reportSettings.startDate)} thru ${formatDateLong(reportSettings.endDate)}`}</Typography>
        </Grid>
        {data?.rows?.length > 0 ?
          <Grid item xs={12}>
            <Table size='small' sx={{'& .MuiTableCell-body': {whiteSpace: 'nowrap'}}}>
              <TableHead>
                <TableRow>
                  {Object.keys(data?.rows[0]).map((header, idx) => (<TableCell key={idx}>{header}</TableCell>))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.rows?.map((row) =>
                  <TableRow key={row._id}>
                    {Object.entries(row).map((object, idx) => {
                      if (['Royalty Percentage', 'Your Royalty % After Cap'].includes(object[0])) {
                        return (<TableCell align='right'>{`${asCurrencyStringCommaSeparated(object[1])} %`}</TableCell>);
                      }
                      if (typeof object[1] === 'number' && object[0] !== 'Number of Voided Invoices') {
                        return (<TableCell align='right'>{`${currencySymbol} ${asCurrencyStringCommaSeparated(object[1])}`}</TableCell>);
                      }
                      return (<TableCell key={idx}>{object[1]}</TableCell>);
                    })}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Grid>
          : <Grid item xs={12}>No records</Grid>
        }
      </Grid>
    </Grid >
  );
};

export const RoyaltySummaryReportSettings = () => {
  const {setReportSettings, reportSettings, getReportApi, isLoading} = useContext(ReportContext);
  const company = store.getState()?.companyReducer.company;
  reportSettings.tenantId ?? setReportSettings({tenantId: company._id});

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item lg={6} xs={12}>
                  <KeyboardDatePicker name='startDate' required label='Start Date' fullWidth={true} value={reportSettings.startDate} onChange={(value) => setReportSettings({startDate: formatIso(value)})}/>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <KeyboardDatePicker name='endDate' required label='End Date' fullWidth={true} value={reportSettings.endDate} onChange={(value) => setReportSettings({endDate: formatIso(value)})}/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box display='flex' justifyContent='flex-end'>
            <HoopsButton color={'primary'}
              purpose='add'
              variant={'contained'}
              onClick={() => getReportApi({data: reportSettings})}
              loading={isLoading}
              sx={{whiteSpace: 'nowrap'}}
            >
              Generate Report
            </HoopsButton>
          </Box>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};
