import * as React from 'react';
import {Link} from '../../Basic';
import {CellContainer} from './CellContainer';

export function LinkCell({value, href, to}) {
  return (
    <CellContainer>
      <Link href={href} to={to}>{value}</Link>
    </CellContainer>
  );
}
