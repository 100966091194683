import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import React, {createContext, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

export const FirebaseContext = createContext({
    authenticated: false,
    loading: false,
    user: null
});

export default ({firebaseConfig, children}) => {
    const meSelector = (state) => state.authReducer.userData;
    const me = useSelector(meSelector);
    const [value, setState] = useState({
        authenticated: false,
        loading: false,
        user: null
    });

    useEffect(
        () => {
            // hack to get around race issue
            if (firebase.apps.length === 0) {
                firebase.initializeApp(firebaseConfig);
            }

            if (me.firebaseToken) {
                setState((prev) => ({...prev, loading: true}));
                firebase.auth()
                    .signInWithCustomToken(me.firebaseToken)
                    .then((user) => setState({loading: false, authenticated: true, user}))
                    .catch(setState({loading: false, authenticated: false, user: null}));
            }
        },
        [firebaseConfig, me]
    );

    return (
        <FirebaseContext.Provider value={value}>
            {children}
        </FirebaseContext.Provider>
    );
};
