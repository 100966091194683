import ResourceService from '../servicesOld/ResourceService';
import {newResource} from '../reducers/resource';
import {hoopsQuerySetRefetch} from './hoopsQuery';
export const SET_RESOURCE = 'SET_RESOURCE';
export const SET_RESOURCE_LOADING = 'SET_RESOURCE_LOADING';
export const UPDATE_RESOURCE = 'UPDATE_RESOURCE';
export const CREATE_RESOURCE = 'CREATE_RESOURCE';
export const REMOVE_RESOURCE = 'REMOVE_RESOURCE';
export const UPDATE_RESOURCES = 'UPDATE_RESOURCES';
export const MODAL_RESOURCE = 'MODAL_RESOURCE';

export function editNewResource(state = {}) {
  return function(dispatch) {
    const payload = {...newResource, ...state};
    dispatch({type: SET_RESOURCE, payload}); // new empty task and merge any data
    dispatch(setModal(true)); // open modal
    return payload;
  };
}

export function setModal(isOpen = false) {
  return function(dispatch) {
    dispatch({type: MODAL_RESOURCE, payload: isOpen});
    return isOpen;
  };
}

export function getResources(variables, cb) {
  return function(dispatch) {
    return ResourceService.getResources(variables).then((payload) => {
      if (cb) {
        cb(payload);
      }
      dispatch({type: UPDATE_RESOURCES, payload: payload});
      return payload;
    });
  };
}

export function createResource(data, cb) {
  return function(dispatch) {
    return ResourceService.createResource(data).then((file) => {
      if (cb) {
        cb(file);
      }
      dispatch({type: CREATE_RESOURCE, payload: file});
      dispatch(hoopsQuerySetRefetch('resource', true));
      return file;
    });
  };
}

export function getResource(id, cb) {
  return function(dispatch) {
    dispatch({type: SET_RESOURCE_LOADING, payload: true});
    return ResourceService.getResource(id).then((payload) => {
      dispatch({
        type: SET_RESOURCE,
        payload,
      });
      dispatch({type: SET_RESOURCE_LOADING, payload: false});
      if (cb) {
        cb(payload);
      }

      return payload;
    });
  };
}

export function updateResource(id, data, cb) {
  return function(dispatch) {
    return ResourceService.updateResource(id, data).then((payload) => {
      dispatch({type: UPDATE_RESOURCE, payload});
      dispatch(hoopsQuerySetRefetch('resource', true));
      if (cb) {
        cb(payload);
      }
      return payload;
    });
  };
}

export function deleteResource(id) {
  return function(dispatch) {
    return ResourceService.deleteResource(id).then((d) => {
      dispatch({type: REMOVE_RESOURCE, payload: id});
      dispatch(hoopsQuerySetRefetch('resource', true));
      return d;
    });
  };
}
