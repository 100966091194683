import React, {useState} from 'react';
import {Grid, makeStyles, Hidden} from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import {Document, Page, pdfjs} from 'react-pdf/dist/esm/entry.webpack';
import {HoopsIconButton} from '../../HoopsIconButton';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import {useWindowWidth} from '@wojtekmaj/react-hooks';

const useStyles = makeStyles((theme) => ({
  proofImage: {
    maxWidth: '100%',
    maxHeight: 'inherit',
    objectFit: 'contain',
  },
  iconButton: {
    borderWidth: 1,
    borderStyle: 'solid',
    width: theme.spacing(7),
    height: theme.spacing(7),
    borderRadius: '50%',
    padding: 0,
    margin: theme.spacing(2)
  },
  proofGridContainerStyles: {
    root: {
      [theme.breakpoints.down('md')]: {maxHeight: 320,},
      [theme.breakpoints.down('lg')]: {maxHeight: 520,},
      [theme.breakpoints.up('lg')]: {maxHeight: 800,},
    }
  },
  pdfDoc: {
    display: 'flex',
    justifyContent: 'center',
  },
  pdfPage: {
    padding: theme.spacing(2),
    '& canvas': {boxShadow: '0px 0px 15px 1px #e5e5e5',},
  }
}));

function BackArrow({disabled, onClick}) {
  const classes = useStyles();
  return (
    <HoopsIconButton
      disabled={disabled}
      onClick={onClick}
      className={classes.iconButton}
    >
      <ArrowBack />
    </HoopsIconButton>
  );
}

function ForwardArrow({disabled, onClick}) {
  const classes = useStyles();
  return (
    <HoopsIconButton
      disabled={disabled}
      onClick={onClick}
      className={classes.iconButton}
      style={{float: 'right'}}
    >
      <ArrowForward />
    </HoopsIconButton>
  );
}

export const ProofImage = ({
  activeProof,
  onNextClick,
  nextDisabled,
  onPreviousClick,
  previousDisabled,
}) => {
  const classes = useStyles();
  const width = useWindowWidth();

  const [numPagesVal, setNumPagesVal] = useState(null);

  function onDocumentLoadSuccess({numPages}) {
    setNumPagesVal(numPages);
  }

  return (
    <>
      <Grid container justifyContent={'center'} alignItems={'center'}>
        <Grid item xs={12}>

          <Grid container direction='row' justifyContent='center' alignItems={'center'}>
            <Hidden xsDown>
              <Grid item sm={1}><BackArrow disabled={previousDisabled} onClick={onPreviousClick} /></Grid>
            </Hidden>
            <Grid item xs={12} sm={10} style={{textAlign: 'center'}}>
              {activeProof?.file?.contentType === 'application/pdf'
                ?
                <Document
                  file={activeProof.file.url}
                  onLoadSuccess={onDocumentLoadSuccess}
                  className={classes.pdfDoc}
                >
                  <div>
                    {
                      Array.from(
                        new Array(numPagesVal),
                        (el, index) => (
                          <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                            className={classes.pdfPage}
                            width={Math.min(width * 0.95, 1000)}
                          />),
                      )}
                  </div>
                </Document>
                :
                <img
                  className={classes.proofImage}
                  alt={activeProof?.file.filename ?? 'Proof image'}
                  src={activeProof?.file?.url ?? null}
                />
              }
            </Grid>
            <Hidden xsDown>
              <Grid item sm={1}><ForwardArrow disabled={nextDisabled} onClick={onNextClick} /></Grid>
            </Hidden>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Hidden smUp>
            <Grid container justifyContent='space-evenly' alignContent='center'>
              <Grid item>
                <BackArrow disabled={previousDisabled} onClick={onPreviousClick} />
                <ForwardArrow disabled={nextDisabled} onClick={onNextClick} />
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
    </>
  );
};
