import {themeOptions as baseTheme} from './index';
import {createTheme} from '@material-ui/core';

const {colors} = baseTheme;
const basePalette = baseTheme.palette;

const palette = {
  ...basePalette,
  primary: {
    main: colors.red,
    contrastText: '#ffffff'
  }
};

const themeOptions = {
  ...baseTheme,
  palette
};

export default createTheme(themeOptions);
