import {Button, Grid, MuiThemeProvider} from '@material-ui/core';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {editNewResource, getResources} from '../../actions/resource';
import EntityListHero from '../../componentsOld/EntityList/EntityListHero';
import green from '../../ui/theme/green';
import ResourceManyTable from './ResourceManyTable';

export const ResourceManyViewPage = () => {
  const dispatch = useDispatch();

  const resourcesSelector = (state) => state.resourceReducer.list;
  const resources = useSelector(resourcesSelector);

  useEffect(() => {
    dispatch(getResources());
  });

  const handleAddResource = () => {
    dispatch(editNewResource());
  };

  return (
    <Grid container>
      <EntityListHero
        title='Resources'
      >
        <MuiThemeProvider theme={green}>
          <Button
            onClick={handleAddResource}
            color={'primary'}
            variant={'contained'}>Add Resource</Button>
        </MuiThemeProvider>
      </EntityListHero>

      <Grid item xs={12}>
        <ResourceManyTable resources={resources} handleAddResource={handleAddResource} />
      </Grid>
    </Grid>

  );
};

export default ResourceManyViewPage;
