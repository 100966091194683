import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Link as MuiLink} from '@material-ui/core';
import {HoopsPropTypes} from '../utils';

export function Link({className, href, to, underline, children}) {
  return ((children ?? null) &&
    <>
      {to &&
        <MuiLink className={className} color={'primary'} component={RouterLink} underline={underline ? 'always' : 'none'} to={to}>
          {children}
        </MuiLink>
      }
      {!to && href &&
        <MuiLink color={'primary'} target='_blank' href={href} underline={underline ? 'always' : 'none'}>
          {children}
        </MuiLink>
      }
    </>
  );
}

Link.propTypes = {
  className: HoopsPropTypes.className,
  href: HoopsPropTypes.string,
  to: HoopsPropTypes.string,
  underline: HoopsPropTypes.bool,
  children: HoopsPropTypes.children,
};
