import {client} from './GraphQLService';
import {pick} from 'lodash';
import {
  GQL_GET_RESOURCE,
  GQL_GET_RESOURCES,
  GQL_CREATE_RESOURCE,
  GQL_UPDATE_RESOURCE,
  GQL_DELETE_RESOURCE,
} from '../queries/resources';

export default class ResourceService {
  static _parseResourceInput(resource) {
    const keys = [
      'title',
      'description',
      'capacity'
    ];

    return pick(resource, keys);
  }

  static async getResource(id) {
    return client
      .query({
        query: GQL_GET_RESOURCE,
        variables: {_id: id,},
        fetchPolicy: 'no-cache',
      })
      .then(({data: {resourceById}}) => resourceById);
  }

  static async getResources(variables) {
    return client
      .query({
        query: GQL_GET_RESOURCES,
        fetchPolicy: 'network-only',
        variables: {
          ...variables,
          sort: 'TITLE_ASC'
        },
      })
      .then(({data: {resourceMany}}) => resourceMany);
  }

  static async createResource(resourceData) {
    return client
      .mutate({
        mutation: GQL_CREATE_RESOURCE,
        variables: {record: ResourceService._parseResourceInput(resourceData),},
      })
      .then(({data: {resourceCreateOne: resource}}) => resource);
  }

  static async updateResource(_id, data) {
    return client
      .mutate({
        mutation: GQL_UPDATE_RESOURCE,
        variables: {
          _id,
          record: ResourceService._parseResourceInput(data),
        },
      })
      .then(({data: {updateResourceById: resource}}) => resource);
  }

  static async deleteResource(_id) {
    return client
      .mutate({
        mutation: GQL_DELETE_RESOURCE,
        variables: {_id,},
      })
      .then(({data: {resourceRemoveById: resource}}) => resource);
  }
}
