import {CompanyService} from '../../servicesOld/CompanyService';
import {snackError, snackSuccess} from './snackbar';

export const SET_COMPANY = 'SET_COMPANY';

export function setCompany(payload) {
  return {
    type: SET_COMPANY,
    payload,
  };
}

export function getCompany(cb) {
  return function (dispatch) {
    return CompanyService.getCompany()
      .then(async (data) => {
        await dispatch(setCompany(data));
        if (cb) {
          cb(data);
        }
      });
  };
}

export function getCompanyFromJob(jobId) {
  return function (dispatch) {
    return CompanyService.getCompanyFromJob(jobId)
      .then(async (data) => {
        await dispatch(setCompany(data));
      });
  };
}

export function saveCompany(company) {
  return function (dispatch) {
    const companyService = new CompanyService(company);
    return companyService.save()
      .then((data) => {
        dispatch(snackSuccess('Company saved.'));
        return data;
      })
      .catch((e) => {
        const message = e.message ? e.message : 'Something went wrong saving your organisation settings.';
        dispatch(snackError(message));
      });
  };
}

export function saveCompanyNumbering(company) {
  return function (dispatch) {
    const companyService = new CompanyService(company);
    return companyService.updateEntityNumbers()
      .then((data) => {
        dispatch(snackSuccess('Company numbering saved.'));
        return data;
      })
      .catch((e) => {
        const message = e.graphQLErrors[0] ? e.graphQLErrors[0].message : 'Something went wrong saving your organisation settings.';
        dispatch(snackError(message));
      });
  };
}
