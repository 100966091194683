// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import PurchaseOrdersService from '../servicesOld/PurchaseOrders';
import {snackSuccess, snackError} from './action-types';
import {initialPurchaseOrder} from '../reducers/purchaseOrders';
import {hoopsQuerySetRefetch} from './hoopsQuery';

export const SET_PURCHASE_ORDERS = 'SET_PURCHASE_ORDERS';
export const SET_JOB_PURCHASE_ORDERS = 'SET_JOB_PURCHASE_ORDERS';
export const SET_ACTIVE_PURCHASE_ORDER = 'SET_ACTIVE_PURCHASE_ORDER';
export const SET_FORM_PURCHASE_ORDER = 'SET_FORM_PURCHASE_ORDER';
export const SET_ACTIVE_PO_VARIANTS = 'SET_ACTIVE_PO_VARIANTS';
export const SET_PO_LOADING = 'SET_PO_LOADING';
export const SET_PO_SHIP_TO = 'SET_PO_SHIP_TO';
export const RESET_ACTIVE_PO = 'RESET_ACTIVE_PO';
export const CREATE_PO = 'CREATE_PO';
export const UPDATE_PO = 'UPDATE_PO';
export const SET_LOADING_POS = 'SET_LOADING_POS';

export function resetActivePo() {
  return (dispatch) => {
    dispatch({type: RESET_ACTIVE_PO});
  };
}

export function setPOShipTo(shipTo) {
  return (dispatch) => {
    dispatch({
      type: SET_PO_SHIP_TO,
      payload: shipTo
    });
  };
}

export function getDraftPo(jobId, vendorId) {
  return (dispatch) => PurchaseOrdersService.getDraftPo(jobId, vendorId)
      .then(({data: {getDraftPO: po}}) => {
        dispatch({
          type: SET_FORM_PURCHASE_ORDER,
          payload: {
            locked: true,
            ...po
          }
        });

        // We do this to have additional data available
        dispatch({
          type: SET_ACTIVE_PURCHASE_ORDER,
          payload: po
        });
      });
}

export function setLoading(value) {
  return function(dispatch) {
    dispatch({
      type: SET_PO_LOADING,
      payload: value
    });
  };
}

export function getPurchaseOrder(_id) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    await PurchaseOrdersService.getPurchaseOrder(_id)
      .then((po) => {
        dispatch(setLoading(false));
        dispatch({
          type: SET_ACTIVE_PURCHASE_ORDER,
          payload: po
        });
        dispatch({
          type: SET_FORM_PURCHASE_ORDER,
          payload: po
        });
      });
  };
}

export function setActivePoVariants(payload) {
  return (dispatch) => {
    dispatch({
      type: SET_ACTIVE_PO_VARIANTS,
      payload
    });
  };
}

export function setFormPo(_id, cb) {
  return (dispatch) => PurchaseOrdersService.getPurchaseOrder(_id)
      .then((po) => {
        dispatch({
          type: SET_FORM_PURCHASE_ORDER,
          payload: po
        });
        if (cb) {
          cb(null, po);
        }
      });
}

export function resetFormPo() {
  return (dispatch) => {
    dispatch({
      type: SET_FORM_PURCHASE_ORDER,
      payload: initialPurchaseOrder
    });
  };
}

export function setActivePo(_id, cb) {
  return (dispatch) => {
    dispatch(setLoading(true));
    return PurchaseOrdersService.getPurchaseOrder(_id)
      .then((po) => {
        dispatch(setLoading(false));
        dispatch({
          type: SET_ACTIVE_PURCHASE_ORDER,
          payload: po
        });
        if (cb) {
          cb(null, po);
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (cb) {
          cb(err);
        }
      });
  };
}

export function getPurchaseOrders(options = {}) {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING_POS,
      payload: true
    });
    PurchaseOrdersService.getPurchaseOrders(options)
    .then((purchaseOrders) => {
      if (options.jobId) {
        dispatch({
          type: SET_JOB_PURCHASE_ORDERS,
          payload: purchaseOrders
        });
        dispatch({
          type: SET_LOADING_POS,
          payload: false,
        });
        return;
      }
      dispatch({
        type: SET_PURCHASE_ORDERS,
        payload: purchaseOrders
      });
      dispatch({
        type: SET_LOADING_POS,
        payload: false,
      });
    });
  };
}

export function updatePo(_id, poData, cb) {
  return (dispatch) => {
    PurchaseOrdersService.updatePurchaseOrder(_id, poData)
      .then((po) => {
        dispatch(snackSuccess(`PO ${po.number} updated for ${po.vendor && po.vendor.name}.`));
        dispatch(getPurchaseOrders());
        dispatch(setActivePo(_id));
        if (cb) {
          cb(null, po);
        }
        dispatch(hoopsQuerySetRefetch('purchaseOrder', true));
      });
  };
}

export function createPo(poData, options, cb) {
  return (dispatch) => {
    PurchaseOrdersService.createPurchaseOrder(poData)
      .then((po) => {
        dispatch(snackSuccess(`PO ${po.number} created for ${po.vendor.name}.`));
        dispatch(getPurchaseOrders(options));
        if (cb) {
          cb(null, po);
        }
        dispatch(hoopsQuerySetRefetch('purchaseOrder', true));
      });
  };
}

// Update Purchase Order Item
export function updatePurchaseOrderItem(purchaseOrderId, itemId, updatedItem) {
  return async (dispatch) => {
    await PurchaseOrdersService.updatePurchaseOrderItem(purchaseOrderId, itemId, updatedItem)
      .then(() => {
        dispatch(getPurchaseOrder(purchaseOrderId));
        dispatch(snackSuccess('Purchase Order item updated'));
      })
      .catch(() => {
        dispatch(snackError('Something went wrong while updating Purchase Order item'));
      });
  };
}

// Update Variant
export function updatePurchaseOrderItemVariant(purchaseOrderId, itemId, variantId, updatedVariant) {
  return async (dispatch) => {
    await PurchaseOrdersService.updatePurchaseOrderItemVariant(purchaseOrderId, itemId, variantId, updatedVariant)
      .then(({data: {updatePurchaseOrderItemVariant: PurchaseOrder}}) => {
        dispatch({
          type: SET_ACTIVE_PURCHASE_ORDER,
          payload: PurchaseOrder
        });
        dispatch(snackSuccess('Purchase Order item variant updated'));
      })
      .catch((e) => {
        dispatch(snackError('Something went wrong while updating Purchase Order variant'));
      });
  };
}

// Update Decoration
export function updatePurchaseOrderItemDecoration(purchaseOrderId, itemId, decorationId, updatedDecoration) {
  return async (dispatch) => {
    await PurchaseOrdersService.updatePurchaseOrderItemDecoration(purchaseOrderId, itemId, decorationId, updatedDecoration)
      .then(({data: {updatePurchaseOrderItemDecoration: PurchaseOrder}}) => {
        dispatch({
          type: SET_ACTIVE_PURCHASE_ORDER,
          payload: PurchaseOrder
        });
        dispatch(snackSuccess('Purchase Order item decoration updated'));
      })
      .catch(() => {
        dispatch(snackError('Something went wrong while updating Purchase Order decoration'));
      });
  };
}

// Update Additional item
export function updatePurchaseOrderItemAdditionalItem(purchaseOrderId, itemId, additionalItemId, updatedAdditionalItem) {
  return async (dispatch) => {
    await PurchaseOrdersService.updatePurchaseOrderItemAdditionalItem(purchaseOrderId, itemId, additionalItemId, updatedAdditionalItem)
      .then(({data: {updatePurchaseOrderItemAdditionalItem: PurchaseOrder}}) => {
        dispatch({
          type: SET_ACTIVE_PURCHASE_ORDER,
          payload: PurchaseOrder
        });
        dispatch(snackSuccess('Purchase Order item additional item updated'));
      })
      .catch(() => {
        dispatch(snackError('Something went wrong while updating Purchase Order additional item'));
      });
  };
}

export function deletePurchaseOrder(_id) {
  return (dispatch) => {
    PurchaseOrdersService.deletePurchaseOrder(_id)
      .then(({data: {deletePurchaseOrder: purchaseOrder}}) => {
        dispatch(getPurchaseOrders());
        dispatch(snackSuccess(`PurchaseOrder #${purchaseOrder.number} deleted`));
        dispatch(hoopsQuerySetRefetch('purchaseOrder', true));
      })
      .catch(() => {
        dispatch(snackError('Could not delete Purchase Order'));
      });
  };
}

export function sendToAccounting(_id) {
  return (dispatch) => {
    PurchaseOrdersService.updatePurchaseOrder(_id, {accountingSync: true})
      .then((purchaseOrder) => {
        dispatch(getPurchaseOrders());
        dispatch(snackSuccess(`PurchaseOrder #${purchaseOrder.number} sent to accounting`));
        dispatch(hoopsQuerySetRefetch('purchaseOrder', true));
      })
      .catch(() => {
        dispatch(snackError('Could not send Purchase Order to accounting'));
      });
  };
}
