// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import {get} from 'lodash';
import MaterialTable from 'material-table';
import numeral from 'numeral';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PurchaseOrderCheckboxToolTip from './PurchaseOrderCheckboxToolTip';
import {MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import {editEntity} from '../../../ui/theme/editEntity';
import {Checkbox} from '@material-ui/core';
import green from '../../../ui/theme/green';
import {backOrderColumns} from './backOrderColumns';
import {baseColumnWidth} from './PurchaseOrder';

export function RenderRate({rate, company}) {
  return (
    <>
      <span>{company.currencySymbol} </span>
      <span>{numeral(rate).format('0,0.00')}</span>
    </>
  );
}

class VariantsTable extends Component {
  state = {
    tableOptions: {
      showTitle: true,
      paging: false,
      search: false,
      sorting: false,
      draggable: false,
      headerStyle: {backgroundColor: '#E8EAED'}
    }
  };

  columns = [];

  componentDidMount() {
    this.setColumns();
  }

  setColumns = () => {
    const {purchaseOrder, item, company} = this.props;
    const allOrdered = this.allItemsSelected(item, 'ordered');
    const allReceived = this.allItemsSelected(item, 'received');
    this.columns = [{
      title: 'Code',
      field: 'productCode',
      cellStyle: baseColumnWidth,
      headerStyle: baseColumnWidth
    }, {
      title: 'Name/Description',
      field: 'productName',
      cellStyle: baseColumnWidth,
      headerStyle: baseColumnWidth
    }, {
      title: 'Colour',
      field: 'colour',
      cellStyle: baseColumnWidth,
      headerStyle: baseColumnWidth
    }, {
      title: 'Size',
      field: 'size',
      cellStyle: baseColumnWidth,
      headerStyle: baseColumnWidth
    }, {
      title: 'Quantity',
      field: 'quantity',
      cellStyle: baseColumnWidth,
      headerStyle: baseColumnWidth
    }, {
      title: 'Rate',
      field: 'rate',
      cellStyle: baseColumnWidth,
      headerStyle: baseColumnWidth,
      render: (row) => <RenderRate company={company} rate={get(row, 'rate', 0)} />
    },
    //checkboxs
    {
      title: (
        <PurchaseOrderCheckboxToolTip
          masterPurchaseOrderId={purchaseOrder.masterPurchaseOrderId}
        >
          <MuiThemeProvider theme={green}>
            <Checkbox
              onChange={() => this.handleBulkStatusUpdate(item, 'ordered')}
              color='primary'
              checked={allOrdered}
              disabled={Boolean(purchaseOrder.masterPurchaseOrderId)}
            />
          </MuiThemeProvider>
          Ordered
        </PurchaseOrderCheckboxToolTip>
      ),
      cellStyle: baseColumnWidth,
      key: 'orderedCheckBox',
      headerStyle: baseColumnWidth,
      render: (row) => (
        <PurchaseOrderCheckboxToolTip
          masterPurchaseOrderId={purchaseOrder.masterPurchaseOrderId}
        >
          <MuiThemeProvider theme={green}>
            <Checkbox
              onChange={() => this.handleStatusUpdate(row._id, 'ordered', row.ordered)}
              color='primary'
              checked={row.ordered}
              disabled={Boolean(purchaseOrder.masterPurchaseOrderId)}
            />
          </MuiThemeProvider>
        </PurchaseOrderCheckboxToolTip>
      ),
    },
    ...backOrderColumns.call(this),
    {
      title: (
        <PurchaseOrderCheckboxToolTip
          masterPurchaseOrderId={purchaseOrder.masterPurchaseOrderId}
        >
          <MuiThemeProvider theme={green}>
            <Checkbox
              onClick={() => this.handleBulkStatusUpdate(item, 'received')}
              color='primary'
              checked={allReceived}
              disabled={Boolean(purchaseOrder.masterPurchaseOrderId)}
            />
          </MuiThemeProvider>
          Received
        </PurchaseOrderCheckboxToolTip>
      ),
      cellStyle: baseColumnWidth,
      key: 'receivedCheckBox',
      headerStyle: baseColumnWidth,
      render: (row) => (
        <PurchaseOrderCheckboxToolTip
          masterPurchaseOrderId={purchaseOrder.masterPurchaseOrderId}
        >
          <MuiThemeProvider theme={green}>
            <Checkbox
              checked={row.received}
              color='primary'
              onClick={() => this.handleStatusUpdate(row._id, 'received', row.received)}
              disabled={Boolean(purchaseOrder.masterPurchaseOrderId)}
            />
          </MuiThemeProvider>
        </PurchaseOrderCheckboxToolTip>
      ),
    },];

  };

  handleBulkStatusUpdate(Item, field) {
    this.props.setTouched(true);
    const allSelected = this.allItemsSelected(Item, field);
    this.props.setPurchaseOrder((prev) => {
      let clonePO = {...prev};
      const variants = clonePO.items.find((item) => item._id === Item._id).variants;
      for(const variant of variants){
        variant[field] = !allSelected;
      }
      return (clonePO);
    });
  }

  handleStatusUpdate = (rowId, field, currentStatus) => {
    this.props.setTouched(true);
    // Update the relevant field and then store to state
    this.props.setPurchaseOrder((prev) => {
      let clonePO = {...prev};
      clonePO.items.find((item) => item._id === this.props.item._id).variants.find((variant) => variant._id === rowId)[field] = !currentStatus;
      return (clonePO);
    });
  };

  allItemsSelected = (Item, status) => {
    let allSelected = true;
    Item.variants.forEach((variant) => {
      if (!variant[status]) {
        allSelected = false;
      }
    });
    return allSelected;
  };

  render() {
    const {variants, description, readOnly} = this.props;
    const {tableOptions} = this.state;
    this.setColumns();
    return (
      <MaterialTable
        title={description}
        columns={
          readOnly ?
            this.columns.filter((obj) => obj.key !== 'orderedCheckBox' && obj.key !== 'receivedCheckBox')
            : this.columns
        }
        data={variants}
        options={tableOptions}
      />
    );
  }
}

function mapStateToProps(state) {
  return {company: state.companyReducer.company};
}

const styles = (theme) => ({
  ...editEntity,
  vertDivider: {
    margin: '0 auto',
    color: theme.colors.grey.light,
  }
});

VariantsTable = connect(mapStateToProps)(VariantsTable);
export default withStyles(styles)(VariantsTable);
