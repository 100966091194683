// TODO: fix eslint disable
/* eslint-disable no-class-assign, no-shadow */

import React from 'react';
import {
  Dialog, DialogContent,
  Button,
  withStyles,
  Grid
} from '@material-ui/core';
import VendorForm from './vendorForm';
import {connect} from 'react-redux';
import {submit} from 'redux-form';
import {getVendors, saveVendor} from '../../actions/action-types';
import DialogActions from '../shared/DialogActions';
import DialogTitle from '../shared/DialogTitle';
import {initialState as initialVendor} from '../../reducers/vendor';
import {hoopsQuerySetRefetch} from '../../actions/hoopsQuery';

const styles = (theme) => ({
  gridPadding: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
});

class VendorDialog extends React.Component {
  state = {
    open: false,
    addAnother: false,
    scroll: 'body',
    vendor: {},
  };

  handleClose = (data) => {
    const {hoopsQuerySetRefetch} = this.props;
    this.props.onClose(data);
    this.setState({open: false});
    this.setState({vendor: {}});
    hoopsQuerySetRefetch();
  };

  UNSAFE_componentWillReceiveProps = (props) => {
    this.setState({open: props.isOpen});
    if (props.data) {
      this.setState({vendor: props.data});
    }
  };

  saveVendor = (values) => {
    const {getVendors, saveVendor} = this.props;

    saveVendor(values, (vendor) => {
      getVendors();
      this.handleClose(vendor);
    });
  };

  render() {
    const {submit, vendor, addVendor} = this.props;
    return (
      <div>
        <Dialog
          aria-labelledby='vendor-modal-title'
          aria-describedby='vendor-modal-description'
          open={this.state.open}
          onClose={this.handleClose}

          scroll={this.state.scroll}
        >
          <DialogTitle disableTypography={true} onClick={this.handleClose}>
            <h3>{vendor._id ? <span>Edit</span> : <span>Add</span>} Vendor</h3>
          </DialogTitle>
          <DialogContent>
            <VendorForm initialValues={addVendor ? initialVendor.vendor : vendor} onSubmit={this.saveVendor} />
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent='space-between'>
              <Button color='secondary' onClick={this.handleClose}>
                Cancel
              </Button>
              <Button color='primary' variant='contained' onClick={() => submit('vendorForm')}>
                Save
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {vendor: state.vendorReducer.vendor,};
}

function mapDispatchToProps(dispatch) {
  return {
    saveVendor: (values, cb) => dispatch(saveVendor(values, cb)),
    getVendors: () => dispatch(getVendors()),
    submit: (form) => dispatch(submit(form)),
    hoopsQuerySetRefetch: () => dispatch(hoopsQuerySetRefetch('vendor', true)),

  };
}

VendorDialog = connect(mapStateToProps, mapDispatchToProps)(VendorDialog);

export default (withStyles(styles)(VendorDialog));
