import * as React from 'react';
import {formatDateLong, formatTime} from '../../../utils';
import {BodyText} from '../../Text';
import {CellContainer, useCellContainerState} from './CellContainer';
import {registerGlobalStyle} from '../../../theme';
import {useCallback} from 'react';
import {DatePicker} from '../../Pickers';
import {Placement} from '../../Popovers';

registerGlobalStyle('.power-grid .cell-data-type-date.editable', (theme) => ({
  '.near-due .text-body': {color: theme.colors.palette.orangeContrast},
  '.overdue .text-body': {color: theme.colors.palette.red},
  '.cell-container .date-picker': {
    position: 'absolute',
    width: '100%',
    opacity: 0,
    zIndex: -1,
    pointerEvents: 'none',
  },
  '.cell-container > span:empty': {
    width: '1.5rem',
    height: '1.5rem',
    '&::after': {
      content: '"event"',
      fontFamily: 'Material Icons',
      color: theme.colors.text.medium,
      backgroundColor: 'transparent',
      fontSize: '1.5rem',
      position: 'relative',
      top: theme.spacing(-.875),
      opacity: 0,
      transition: theme.transitions.out.all,
    },
  },
  '&:hover .cell-container > span:empty::after': {
    opacity: 1,
    transition: theme.transitions.in.all,
  },
  '.cell-container > *::before': {bottom: theme.spacing(-1.125)},
  '.cell-container > *:empty::before': {bottom: theme.spacing(-1)},
}));

export function DateCell({value, colDef, row}) {
  const {editing, startEditing, endEditing} = useCellContainerState();

  const handleChange = useCallback((newValue) => {
    endEditing();
    colDef.valueSetter({row, value: newValue});
  }, [colDef, endEditing, row]);

  const date = new Date(value);

  let className = '';
  if (colDef.purpose === 'dueDate') {
    const now = new Date();
    let daysToDueDate = Math.ceil((now.getTime() - date.getTime()) / (1000 * 3600 * 24));
    if (daysToDueDate > -7 && daysToDueDate < 0) {
      className = 'near-due';
    } else if (daysToDueDate >= 0) {
      className = 'overdue';
    }
  }

  return (
    <CellContainer className={className} editing={editing} onClick={startEditing}>
      {value && colDef.showTime &&
        <BodyText>{`${formatDateLong(date)} ${formatTime(date)}`}</BodyText>
      }
      {value && !colDef.showTime &&
        <BodyText>{formatDateLong(date)}</BodyText>
      }
      {!value &&
        <span/>
      }
      {editing &&
        <DatePicker initialOpen={true} placement={Placement.Bottom} value={value} onChange={handleChange} onClose={endEditing}/>
      }
    </CellContainer>
  );
}
