import FileService from '../servicesOld/FileService';
export const UPDATE_FILE = 'UPDATE_FILE';
export const CREATE_FILE = 'CREATE_FILE';
export const REMOVE_FILE = 'REMOVE_FILE';
export const UPDATE_FILES = 'UPDATE_FILES';

export function getFiles({entityIds, isWorkable, customerId, filter, isProof}, cb) {
  return function (dispatch) {
    return FileService.getFiles({entityIds, isWorkable, customerId, filter, isProof})
      .then((payload) => {
        if (cb) { cb(payload); }
        dispatch({type: UPDATE_FILES, payload: payload});
        return payload;
      });
  };
}

export function createFile(fileData, cb) {
  return function (dispatch) {
    return FileService.createFile(fileData)
      .then((file) => {
        if (cb) { cb(file); }
        dispatch({type: CREATE_FILE, payload: file});
        return file;
      });
  };
}

export function updateFile(fileId, fileData) {
  return function (dispatch) {
    return FileService
      .updateFile(fileId, fileData)
      .then((d) => {
        dispatch({type: UPDATE_FILE, payload: d});
      });
  };
}

export function deleteFile(fileId) {
  return function (dispatch) {
    return FileService
      .deleteFile(fileId)
      .then((d) => {
        dispatch({type: REMOVE_FILE, payload: fileId});
        return d;
      });
  };
}
