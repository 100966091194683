// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React, {Component, Fragment} from 'react';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import {renderTextField} from '../../formHelpers/fields';
import {Field, change as _change} from 'redux-form';
import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import {connect} from 'react-redux';
import VendorAutocomplete from '../../../vendors/VendorAutocomplete';

const styles = (theme) => ({tablePadding: {padding: theme.spacing(1)}});

class AdditionalItemsTableRow extends Component {
  handleAddMoreClick = () => {
    const {fields} = this.props;
    fields.push({});
  };

  handleDeleteRowClick = (index) => {
    const {fields} = this.props;
    fields.splice(index, 1);
  };

  render() {
    const {classes, fields} = this.props;
    return (
      <Fragment>
        {fields.map((field, index) => (
            <TableRow key={index}>
              <TableCell className={classes.tablePadding}>
                <Field name={`${field}.description`}
                       component={renderTextField}
                       variant={'outlined'}
                       data-intercom-target={'job-item-additional-items-name-field'}
                       margin={'dense'}/>
              </TableCell>
              <TableCell className={classes.tablePadding}>
                <Field
                  fullWidth
                  name={`${field}.vendor`}
                  component={VendorAutocomplete}
                  textFieldProps={{
                    margin: 'dense',
                    variant: 'outlined'
                  }}
                />
              </TableCell>
              <TableCell className={classes.tablePadding}>
                <IconButton
                  data-intercom-target={'job-item-additional-items-delete-button'}
                  onClick={() => this.handleDeleteRowClick(index)}>
                  <Delete color={'secondary'}/>
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        <TableRow>
          <TableCell className={classes.tablePadding} colSpan={3}>
            <Button
              color={'primary'}
              variant={'outlined'}
              size={'small'}
              data-intercom-target={'job-item-additional-items-add-more-button'}
              onClick={this.handleAddMoreClick}
            >
              {fields.length ? 'Add Another Additional Item' : 'Add Additional Item'}
            </Button>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {change: (form, field, value) => dispatch(_change(form, field, value))};
}

AdditionalItemsTableRow = connect(null, mapDispatchToProps)(AdditionalItemsTableRow);
AdditionalItemsTableRow = withStyles(styles)(AdditionalItemsTableRow);

export default AdditionalItemsTableRow;
