import {Box, Grid, Paper} from '@mui/material';
import React, {MouseEventHandler, useEffect, useRef, useState} from 'react';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {StatusList} from './StatusList';
import {HoopsButton} from '../../../componentsLib';
import './status-picker.scss';
import Popover from '@mui/material/Popover';
import {PopoverActions} from '@mui/material/Popover/Popover';
import classNames from 'classnames';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {colors, palette} from '../../../ui/theme';
import {StatusLabel} from '../../../componentsLib/Basic';

export interface Status {
  statusLabel: string,
  originalLabel?: string,
  color?: string
}

export type Statuses = Status[]

interface StatusPickerProps {
  updatePosition: () => void;
  selected?: string;
  statuses: Statuses;
  onStatusChange?: (statuses: Statuses) => void;
  onSelect?: (status: Status) => void;
}

type Action = () => void

interface StatusListFooterButtonsProps {
  onEdit: Action,
  onAddNew: Action,
  onApply?: Action,
  editing: boolean,
}

function StatusListFooterButtons({onEdit, onAddNew, onApply, editing}: StatusListFooterButtonsProps) {
  return <Grid container alignItems={'center'} alignContent={'center'}>
    {!editing && <Grid item xs={12} container justifyContent={'center'}>
      {/*//@ts-ignore*/}
      <HoopsButton purpose={'primary'} onClick={onEdit} size={'large'}>Edit Statuses</HoopsButton>
    </Grid>}
    {editing && <Grid item xs={6} container justifyContent={'center'}>
      {/*//@ts-ignore*/}
      <HoopsButton purpose={'primary'} onClick={onAddNew} size={'large'}>New Status</HoopsButton>
    </Grid>}
    {editing && <Grid item xs={6} container justifyContent={'center'}>
      {/*//@ts-ignore*/}
      <HoopsButton purpose={'add'} onClick={onApply} size={'large'}>Apply</HoopsButton>
    </Grid>}
  </Grid>;
}

export const StatusPicker = ({selected, statuses, updatePosition, onStatusChange, onSelect}: StatusPickerProps) => {
  const [statusesState, setStatusesState] = useState<Statuses>([]);
  useEffect(() => {
    setStatusesState(statuses);
  }, [statuses]);
  const [editing, setEditing] = useState(false);
  const listRef = useRef<HTMLDivElement>(null);

  const onEdit = () => setEditing(true);
  const onAddNew = () => {
    updatePosition();
    setStatusesState([...statusesState, {statusLabel: '', color: palette.primary.main}]);
    process.nextTick(() => listRef.current?.scrollTo(listRef.current?.scrollHeight + 1000, listRef.current?.scrollHeight + 1000));

  };
  const onApply = () => {
    if (onStatusChange){
      onStatusChange(statusesState);
    }
    setEditing(false);
  };
  return (
    <DndProvider backend={HTML5Backend}>
      <Paper className={'status-picker'}>
        <Box
          ref={listRef}
          className={'status-picker-list-container'}
          sx={{boxShadow: statusesState.length > 8 ? '0px -15px 5px -7px rgba(0,0,0,0.05) inset' : 'none'}}>
          <StatusList
            selected={selected}
            onSelect={onSelect}
            statuses={statusesState}
            onStatusesChange={setStatusesState}
            editing={editing}/>
        </Box>
        {!!onStatusChange && <Box className={'status-picker-footer'}>
          <StatusListFooterButtons
            editing={editing}
            onEdit={onEdit}
            onAddNew={onAddNew}
            onApply={onApply}/>
        </Box>}
      </Paper>
    </DndProvider>
  );
};

interface StatusButtonProps extends Omit<StatusPickerProps, 'updatePosition'> {
  text?: string;
  color?: string;
  rowId: string;
}

export default function StatusButton({rowId, text, color = colors.grey.ultraLight, onSelect, selected, ...rest}: StatusButtonProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const _onSelect = (status: Status) => {
    handleClose();
    if (onSelect) {
      onSelect(status);
    }
  };
  const updatePosition = () => {
    if (popover.current) {
      popover.current.updatePosition();
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? rowId : undefined;
  const popover = useRef<PopoverActions>(null);
  return (
    <div className={'status-button-container'}>
      <div className={classNames('status-button', selected && 'enabled')} aria-describedby={id} onClick={handleClick}>
        <StatusLabel color={color}><span className={'status-text'}>{text}</span> <span
          className={'down-arrow'}><ExpandMoreIcon/></span></StatusLabel>
      </div>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        disableRestoreFocus
        disableEnforceFocus
        disableAutoFocus
        action={popover}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        {open &&
          <StatusPicker onSelect={_onSelect} selected={selected} {...rest} updatePosition={updatePosition}/>
        }
      </Popover>
    </div>
  );
}

