// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-shadow */

import {Chip, IconButton, makeStyles, TableCell, TableRow, TextField} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import HelpIcon from '@material-ui/icons/Help';
import LockIcon from '@material-ui/icons/Lock';
import React, {useState} from 'react';
import StatusColorEdit from './StatusColorEdit';

const useStyles = makeStyles((theme) => ({noBorder: {border: 'none'}})
);

function TooltipContent({message}) {
    return (
        <>
            <div style={{width: 300, padding: 3}}>
                <p>This status is triggered automatically when: <br />
                    {message}</p>
                <p>The name and color of this status can be edited but the status cannot be deleted.</p>

            </div>
        </>
    );
}

export const StatusLabelItemEdit = ({status = {label: '', color: '#00ccf0', locked: false}, onChange = (status) => null, onDelete = (status) => null, draggableProvided, snapshot}) => {
    const classes = useStyles();
    const [_status, setStatus] = useState(status);
    const [error, setError] = useState(false);

    const handleColorChange = (color) => {
        setStatus((prev) => {
            const value = {
                ...prev,
                color
            };
            onChange(value);
            return value;
        });
    };

    const errorHandle = (el) => {
        setError(el.target.value === '' ? true : false);
    };

    const handleLabelBlur = (e) => {
        errorHandle(e);
    };

    const handleLabelChange = (ev) => {
        const label = ev.target.value.toUpperCase();
        errorHandle(ev);
        setStatus((prev) => {
            const value = {
                ...prev,
                label
            };
            onChange(value);
            return value;
        });
    };

    const handleItemDelete = () => {
        onDelete(_status);
    };

    return (
        <TableRow
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            style={{
                ...draggableProvided.draggableProps.style,
                background: snapshot.isDragging
                    ? 'rgba(245,245,245, 0.75)'
                    : 'none',
                display: snapshot.isDragging ? 'table' : 'table-row'
            }}>
            <TableCell className={classes.noBorder} style={{width: '24px'}}>
                <div {...draggableProvided.dragHandleProps}>
                    <DragIndicatorIcon />
                </div>
            </TableCell>
            <TableCell className={classes.noBorder} style={{width: '200px'}}>
                <TextField
                    size='small'
                    variant='outlined'
                    onBlur={handleLabelBlur}
                    onChange={handleLabelChange}
                    value={_status.label}
                    inputProps={{maxLength: 15}}
                    error={error}
                    helperText={error ? 'Status name is required' : ''}
                    placeholder='STATUS NAME'
                />
            </TableCell>
            <TableCell className={classes.noBorder} style={{width: '24px'}}>
                <StatusColorEdit color={_status.color} onChange={handleColorChange} />
            </TableCell>
            <TableCell className={classes.noBorder}>
                <Chip label={_status.label} color={'primary'} style={{backgroundColor: _status.color}} />
            </TableCell>
            <TableCell align='right' className={classes.noBorder}>
                {_status.tooltip && <Tooltip title={<TooltipContent message={_status.tooltip} />}><IconButton ><HelpIcon /></IconButton></Tooltip>}
            </TableCell>
            <TableCell align='right' className={classes.noBorder}>
                {_status.locked ? <IconButton disabled={true}><LockIcon /></IconButton> : <IconButton onClick={handleItemDelete}><DeleteIcon /></IconButton>}
            </TableCell>
        </TableRow>
    );
};

export default StatusLabelItemEdit;
