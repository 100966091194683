// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import {makeStyles, TableCell, TableHead, TableRow, TableSortLabel, withStyles} from '@material-ui/core';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import React, {useEffect} from 'react';
import {hoopsQueryStyles} from '../../ui/theme/hoops';

const useStyles = makeStyles((theme) => ({...hoopsQueryStyles,}));

export const HoopsQueryTableHeader = ({
    sortString = '',
    columns = [],
    onSort = () => null,
    leftScrollShadow = false,
    rightScrollShadow = false
}) => {
    const classes = useStyles();
    const [orderProperty, setOrderProperty] = React.useState();
    const [orderDirection, setOrderDirection] = React.useState('ASC');

    useEffect(() => {
        const [property, direction] = sortString.split('_');
        setOrderProperty(property);
        setOrderDirection(direction);
    }, [sortString]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderProperty === property && orderDirection === 'ASC';
        const direction = isAsc ? 'DESC' : 'ASC';

        setOrderProperty(property);
        setOrderDirection(direction);
        onSort(`${property}_${direction}`);
    };

    const getColumnSortKey = (column) => column.sortKey || column.field;

    const StickyTableCellLeft = withStyles((theme) => ({
        head: {
            backgroundColor: theme.colors.white,
            left: 0,
            position: 'sticky',
            zIndex: theme.zIndex.appBar - 2,
            margin: 0,
            padding: '0 !important',

        },
    }))(TableCell);

    const StickyTableCellRight = withStyles((theme) => ({
        head: {
            backgroundColor: theme.colors.white,
            right: 0,
            position: 'sticky',
            zIndex: theme.zIndex.appBar - 2,
            margin: 0,
            padding: '0 !important',

        },
    }))(TableCell);

    const columnsStickyLeft = columns.filter((item) => item && item.stickyLeft === true && !item.inActive);
    const columnsStickyRight = columns.filter((item) => item && item.stickyRight === true && !item.inActive);
    const columnsNonSticky = columns.filter((item) => item && item.stickyLeft !== true && item.stickyRight !== true && !item.inActive);

    return (

        <TableHead>
            <TableRow>
                {/* Sticky left cells */}
                {columnsStickyLeft.length > 0 &&
                    <StickyTableCellLeft className={` ${leftScrollShadow && classes.leftShadowHeader}`}>
                        {columnsStickyLeft.map((column, colIdx) => {
                            // if (column.hide) return
                            column.columnStyle = {...column.columnStyle, borderBottom: 'none'};
                            return (
                                <TableCell
                                    key={colIdx}
                                    align={column.align}
                                    sortDirection={orderProperty === getColumnSortKey(column) ? orderDirection.toLowerCase() : false}
                                    className={`${classes.header} ${column.hide ? classes.hideCell : null}`}
                                    style={column.columnStyle}
                                >
                                    {column.sortable
                                        ? <TableSortLabel
                                            active={orderProperty === getColumnSortKey(column)}
                                            direction={orderProperty === getColumnSortKey(column) ? orderDirection.toLowerCase() : 'ASC'.toLowerCase()}
                                            onClick={(event) => handleRequestSort(event, getColumnSortKey(column))}
                                        >
                                            {column.label}
                                            <UnfoldMoreIcon fontSize='small' className={classes.sortIcon} />
                                        </TableSortLabel>
                                        : column.label
                                    }
                                </TableCell>
                            );
                        })}
                    </StickyTableCellLeft>}
                {/* Non-sticky cells */}
                {columnsNonSticky.map((column, colIdx) => {
                    // if (column.hide) return
                    column.columnStyle = {...column.columnStyle, borderBottom: '1px solid rgba(224, 224, 224, 1)'};
                    return (
                        <TableCell
                            key={colIdx}
                            align={column.align}
                            sortDirection={orderProperty === getColumnSortKey(column) ? orderDirection.toLowerCase() : false}
                            style={column.columnStyle}
                            className={`${column.hide ? classes.hideCell : null}`}
                        >
                            {column.sortable
                                ? <TableSortLabel
                                    active={orderProperty === getColumnSortKey(column)}
                                    direction={orderProperty === getColumnSortKey(column) ? orderDirection.toLowerCase() : 'ASC'.toLowerCase()}
                                    onClick={(event) => handleRequestSort(event, getColumnSortKey(column))}
                                >
                                    {column.label}
                                    <UnfoldMoreIcon fontSize='small' className={classes.sortIcon} />
                                </TableSortLabel>
                                : column.label
                            }
                        </TableCell>
                    );
                })}

                {/* Sticky right cells */}
                {columnsStickyRight.length > 0 &&
                    <StickyTableCellRight className={` ${rightScrollShadow && classes.rightShadowHeader}`}>
                        {columnsStickyRight.map((column, colIdx) => {
                            // if (column.hide) return
                            column.columnStyle = {...column.columnStyle, borderBottom: 'none'};
                            return (
                                <TableCell
                                    key={colIdx}
                                    align={column.align}
                                    sortDirection={orderProperty === getColumnSortKey(column) ? orderDirection.toLowerCase() : false}
                                    className={`${classes.header} ${column.hide ? classes.hideCell : null}`}
                                    style={column.columnStyle}
                                >
                                    {column.sortable
                                        ? <TableSortLabel
                                            active={orderProperty === getColumnSortKey(column)}
                                            direction={orderProperty === getColumnSortKey(column) ? orderDirection.toLowerCase() : 'ASC'.toLowerCase()}
                                            onClick={(event) => handleRequestSort(event, getColumnSortKey(column))}
                                        >
                                            {column.label}
                                            <UnfoldMoreIcon fontSize='small' className={classes.sortIcon} />
                                        </TableSortLabel>
                                        : column.label
                                    }
                                </TableCell>
                            );
                        })}
                    </StickyTableCellRight>}
            </TableRow>
        </TableHead>

    );
};

export default HoopsQueryTableHeader;

