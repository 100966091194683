// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import {Button, Grid, MuiThemeProvider} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {clearJob as _clearJob} from '../../actions/jobs';
import {openMergePurchaseOrderVendorModal as _openMergePurchaseOrderVendorModal} from '../../actions/modals';
import {
  resetActivePo as _resetActivePo,
  resetFormPo as _resetFormPo
} from '../../actions/purchaseOrders';
import green from '../../ui/theme/green';
import EntityListHero from '../EntityList/EntityListHero';
import PurchaseOrderManyTable from './table/PurchaseOrderManyTable';

const styles = (theme) => ({
  newMergePo: {
    backgroundColor: theme.colors.white,
    marginRight: theme.spacing(2)
  }
});

class PurchaseOrderManyViewPage extends Component {

  handleNewPurchaseOrder = () => {
    const {resetFormPo, history} = this.props;
    resetFormPo();

    history.push('/purchase-orders/new');
  };

  componentDidMount() {
    const {clearJob} = this.props;
    clearJob();
  }

  render() {
    const {classes, openMergePurchaseOrderVendorModal} = this.props;
    return (
      <Grid container>
        <EntityListHero
          title='Purchase Orders'
          subtitle='Manage the items on your orders &amp; track your purchases easily.'
          helpArticleUrl='http://help.hoopscrm.com/en/collections/2642495-purchase-orders'
          videoId='tdT8-dosJWI'
        >

          <Button
            color='primary'
            variant='outlined'
            onClick={openMergePurchaseOrderVendorModal}
            className={classes.newMergePo}
          >New Merged PO</Button>

          <MuiThemeProvider theme={green}>
            <Button
              color='primary'
              variant='contained'
              onClick={this.handleNewPurchaseOrder}
            >New PO</Button>
          </MuiThemeProvider>
        </EntityListHero>

        <Grid item xs={12}>
          <PurchaseOrderManyTable handleNewPurchaseOrder={this.handleNewPurchaseOrder} />
        </Grid>
      </Grid>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    openMergePurchaseOrderVendorModal: () => dispatch(_openMergePurchaseOrderVendorModal()),
    resetActivePo: () => dispatch(_resetActivePo()),
    resetFormPo: () => dispatch(_resetFormPo()),
    clearJob: () => dispatch(_clearJob()),
  };
}

PurchaseOrderManyViewPage = connect(null, mapDispatchToProps)(PurchaseOrderManyViewPage);
PurchaseOrderManyViewPage = withStyles(styles)(PurchaseOrderManyViewPage);
export default PurchaseOrderManyViewPage;
