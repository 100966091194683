// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {Grid, ListItemText, Paper} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import {makeStyles, ThemeProvider as MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import {sortBy} from 'lodash';
import React, {useEffect, useState} from 'react';
import {StatusGroupService} from '../../servicesOld/StatusService';
import green from '../../ui/theme/green';
import EntityListHero from '../EntityList/EntityListHero';
import StatusGroupEdit from './StatusGroupEdit';
import StatusGroupList from './StatusGroupList';

const HoopsVerticalTab = withStyles((theme) => ({
  root: {
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {color: theme.palette.primary.main},

    '&:focus': {color: '#40a9ff',},
  },
  selected: {},
  wrapper: {
    alignItems: 'flex-end',
    paddingRight: theme.spacing(1),
  },
  secondary: {textAlign: 'right'}
}))(({statusGroup, ...props}) => <Tab
  disableRipple
  label={<ListItemText
    primary={statusGroup.entityName}
    secondary={statusGroup.locked ? null : statusGroup.label}
    classes={props.classes}
  />}
  {...props}
/>);

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: '240px',
  },
  tabPanel: {width: '100%'}
}));

export default () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [statusGroups, setStatusGroups] = useState([]);
  const [currentStatusGroup, setCurrentStatusGroup] = useState();
  const [disableSave, setDisableSave] = React.useState(false);

  const handleTabChange = (event, idx) => {
    setValue(idx);
    setCurrentStatusGroup(statusGroups[idx]);
    setIsEdit(false);
  };

  const handleStatusGroupChange = (statusGroup) => {
    setDisableSave(false);
    statusGroup.statuses.forEach((statusItem) => {
      setDisableSave(statusItem.label === '');
    });
    if (JSON.stringify(statusGroup) === JSON.stringify(currentStatusGroup)) {
      return;
    }

    setCurrentStatusGroup(statusGroup);
  };

  const handleSave = () => {
    setIsEdit(false);
    StatusGroupService
      .updateStatusGroup(currentStatusGroup._id, currentStatusGroup)
      .then((d) => {
        const groupResult = d.data.updateStatusGroup;
        setStatusGroups((prev) => prev.map((group) => group._id === groupResult._id ? groupResult : group));
      });
  };

  useEffect(() => {
    document.title = 'Status Settings | Hoops';
    StatusGroupService
      .getStatusGroups()
      .then(
        (d) => {
          const v = sortBy(d.data.statusGroups, 'entityName').filter((d) => !d.isVirtual);
          setStatusGroups(v);
          setCurrentStatusGroup(v[0]);
        }
      );
  }, []);

  return (
    <Grid container
    >
      <EntityListHero
        title='Status Settings'
        subtitle='Customise and manage the statuses of your entire workflow.'
        helpArticleUrl='http://help.hoopscrm.com/en/articles/4636467-custom-statuses'
      >
      </EntityListHero>

      <Grid item xs={12}>
        <Paper>
          <div className={classes.root}>
            <Tabs
              orientation='vertical'
              value={value}
              onChange={handleTabChange}
              aria-label='Statuses'
              className={classes.tabs}
              indicatorColor='primary'
            >
              {statusGroups.map((statusGroup, idx) => <HoopsVerticalTab key={idx} statusGroup={statusGroup} {...a11yProps(idx)} />)}
            </Tabs>
            {currentStatusGroup ? statusGroups.map((statusGroup, idx) => <TabPanel key={idx} value={value} index={idx} className={classes.tabPanel}>
              <Grid container>
                <Grid item xs>
                  {isEdit ? <StatusGroupEdit statusGroup={currentStatusGroup} onChange={handleStatusGroupChange} /> : <StatusGroupList statusGroup={currentStatusGroup} />}
                </Grid>
                <Grid item xs={1} style={{textAlign: 'right'}}>
                  {isEdit ?
                    <MuiThemeProvider theme={green}>
                      <Button color='primary' variant='contained' disabled={disableSave} onClick={() => handleSave()}>SAVE</Button>
                    </MuiThemeProvider> :
                    <Button color='primary' variant='contained' onClick={() => setIsEdit(true)}>EDIT</Button>}
                </Grid>
              </Grid>
            </TabPanel>) : null}

          </div>
        </Paper>
      </Grid>

    </Grid>
  );
};

