// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import React, {Component} from 'react';
import {withStyles} from '@material-ui/core';
import Box from '@material-ui/core/Box';

const styles = (theme) => ({
  imgContainer: {
    height: 80,
    width: 80
  },
  thumbnail: {
    maxHeight: '100%',
    maxWidth: '100%',
  }
});

class ItemThumbnail extends Component {
  render() {
    const {url, classes} = this.props;
    return (
        <Box className={classes.imgContainer} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          {url && <img alt='job-product-thumbnail' className={classes.thumbnail} src={url}/>}
        </Box>
    );
  }
}

export default withStyles(styles)(ItemThumbnail);
