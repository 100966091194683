// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {get} from 'lodash';
import {
  GQL_ACCEPT_QUOTE,
  GQL_CONVERT_TO_JOB,
  GQL_CREATE_PRICE_ELEMENT,
  GQL_CREATE_QUOTE,
  GQL_DELETE_QUOTE, GQL_DUPLICATE_QUOTE, GQL_DUPLICATE_QUOTE_ITEM_DATA, GQL_GET_PRICE_ELEMENT,
  GQL_GET_QUOTE,
  GQL_GET_QUOTES, GQL_QUOTE_COMMENT, GQL_SEND_EMAIL_LINK,
  GQL_UPDATE_PRICE_ELEMENT,
  GQL_UPDATE_QUOTE, GQL_UPDATE_QUOTE_STATUS,
  GQL_UPSERT_GROUPED_QUOTES
} from '../queries/quotes';
import EmailTemplateService from './EmailTemplateService';
import {client as gqlClient} from './GraphQLService';
import {toFloat} from './ItemBuilderServices';

export const parsePriceElementInput = (priceElement) => {
  let {decorationCosts, additionalCosts, variations} = priceElement;

  if (decorationCosts) {
    decorationCosts = decorationCosts.map((deco) => ({
        decorationId: deco.decorationId ? deco.decorationId : null,
        description: deco.description ? get(deco, 'description') : get(deco, 'decoration.name'),
        position: get(deco, 'position'),
        colour: get(deco, 'colour'),
        size: get(deco, 'size'),
        quantity: toFloat(get(deco, 'quantity')),
        markup: toFloat(get(deco, 'markup')),
        priceBreakQuantityOverride: toFloat(get(deco, 'priceBreakQuantityOverride')),
        setupPrice: toFloat(get(deco, 'setupPrice')),
        buyPrice: toFloat(get(deco, 'buyPrice')),
        sellPrice: toFloat(get(deco, 'sellPrice')),
        additionalCost: toFloat(get(deco, 'additionalCost')),
        displayToCustomer: !!deco.displayToCustomer,
        vendorId: get(deco, 'vendorId')
      }));
  } else {
    decorationCosts = [];
  }

  if (additionalCosts) {
    let cleanAdditionalCosts = additionalCosts.filter((el) => el !== null);
    additionalCosts = cleanAdditionalCosts.map((cost) => {
      const vendorId = get(cost, 'vendor._id', get(cost, 'vendorId', null));
      return (
        cost &&
        {
          vendorId: vendorId ? vendorId : null,
          name: cost.name,
          buyPrice: toFloat(get(cost, 'buyPrice')),
          markup: toFloat(get(cost, 'markup')),
          sellPrice: toFloat(get(cost, 'sellPrice')),
          ...(cost.ignoredByJob && {ignoredByJob: cost.ignoredByJob}),
        });
    });
  } else {
    additionalCosts = [];
  }

  if (variations) {
    variations = variations.map((variation) => {

      let response = {
        colour: variation.colour,
        productName: variation.productName,
        productCode: variation.productCode,
        productId: variation.productId,
        markup: toFloat(get(variation, 'markup')),
        quantity: toFloat(get(variation, 'quantity')),
        additionalCost: toFloat(get(variation, 'additionalCost')),
        buyPrice: toFloat(get(variation, 'buyPrice')),
        buyPriceOverride: variation?.buyPriceOverride,
        isCustomBuyPrice: variation.isCustomBuyPrice || undefined,
        sellPrice: toFloat(get(variation, 'sellPrice')),
        vendorId: variation.vendorId,
        displayToCustomer: !!variation.displayToCustomer,
      };

      // variant from catalog
      if (variation.productVariant && variation.productVariant._id) {
        if (variation.productVariant._id.length === 24) {
          response.productVariantId = variation.productVariant._id;
        } else {
          response.size = variation.productVariant._id;
        }
      }
      // variant from adhoc
      if(!variation.productId) {
        response.size = variation.size;
        response.vendorId = get(variation, 'vendor._id');
      }
      return response;
    });
  } else {
    variations = [];
  }

  return {
    _id: priceElement._id,
    grossMarginTarget: get(priceElement, 'grossMarginTarget', null),
    variations,
    additionalCosts,
    decorationCosts,
  };
};

export const parseLineItemTax = (tax) => {
  if (!tax || !tax._id) {
    return null;
  }

  const {name, _id, type, components} = tax;

  return {
    _id,
    name,
    type,
    components,
  };
};

export class QuoteService {
  static parseQuoteItemInput(item) {
    const {imageUrl, description, rate, quantity, product} = item;
    let {tax, priceElement} = item;

    return {
      imageUrl,
      description,
      productId: product?._id,
      rate: parseFloat(rate),
      quantity: parseFloat(quantity),
      tax: parseLineItemTax(tax),
      priceElementId: priceElement ? priceElement._id : undefined,
    };
  }

  static parseQuoteInput(quote) {
    let {_id, title, description, customerId, contactId, terms, archived, status, items, quoteOwnerId, isQuoteAccepted, quoteAcceptedAt, deadlineAt, settings, customReference, shipTo, billTo, leadSource} = quote;

    items = items?.map((item) => QuoteService.parseQuoteItemInput(item));

    return {
      _id,
      title,
      description,
      customerId,
      contactId,
      quoteOwnerId,
      isQuoteAccepted,
      quoteAcceptedAt,
      deadlineAt,
      customReference,
      settings,
      terms,
      archived,
      status: status || 'CREATED',
      items,
      shipTo,
      billTo,
      leadSource
    };
  }

  static async duplicateQuoteItemData(quoteItemData) {
    return gqlClient
      .mutate({
        mutation: GQL_DUPLICATE_QUOTE_ITEM_DATA,
        fetchPolicy: 'no-cache',
        variables: {quoteItemData: QuoteService.parseQuoteItemInput(quoteItemData),},
      })
      .then(({data: {duplicateQuoteItemData}}) => duplicateQuoteItemData);
  }

  static async updateQuoteStatus(_id, status) {
    return gqlClient
      .mutate({
        mutation: GQL_UPDATE_QUOTE_STATUS,
        fetchPolicy: 'no-cache',
        variables: {
          _id,
          status,
        },
      })
      .then(({data: {updateQuoteStatus}}) => updateQuoteStatus);
  }

  static async getQuotes(options = {page: 1, pageLimit: 25}) {
    const {q, page, pageLimit} = options;

    return gqlClient
      .query({
        query: GQL_GET_QUOTES,
        fetchPolicy: 'no-cache',
        variables: {
          q,
          page,
          pageLimit,
        },
      })
      .then(({data: {quotes}}) => quotes);
  }

  static newDraftQuote(quote) {
    delete quote.contact;
    delete quote.customer;

    quote = {
      ...quote,
      status: 'DRAFT',
    };

    return gqlClient
      .mutate({
        mutation: GQL_CREATE_QUOTE,
        variables: {quote},
      })
      .then(({data: {createQuote: quote}}) => quote);
  }

  static async createQuote(quote) {
    return gqlClient
      .mutate({
        mutation: GQL_CREATE_QUOTE,
        variables: {quote,},
      })
      .then(({data: {createQuote}}) => createQuote);
  }

  static getQuote(args) {
    return gqlClient
      .query({
        query: GQL_GET_QUOTE,
        variables: args.quoteNumber ? {number: args.quoteNumber} : {_id: args},
        fetchPolicy: 'no-cache',
      })
      .then(({data: {quote}}) => quote);
  }

  static deleteQuote(_id) {
    return gqlClient.mutate({
      mutation: GQL_DELETE_QUOTE,
      variables: {_id},
    });
  }

  static getPriceElement(_id) {
    return gqlClient
      .query({
        query: GQL_GET_PRICE_ELEMENT,
        variables: {_id},
        fetchPolicy: 'no-cache',
      })
      .then(({data: {priceElement}}) => priceElement);
  }

  static updatePriceElement(_id, data) {
    const priceElementParsed = parsePriceElementInput(data);

    return gqlClient
      .mutate({
        mutation: GQL_UPDATE_PRICE_ELEMENT,
        variables: {
          _id,
          priceElement: priceElementParsed,
        },
      })
      .then(({data: {updatePriceElement}}) => updatePriceElement);
  }

  static createPriceElement(data) {
    const priceElementParsed = parsePriceElementInput(data);

    return gqlClient
      .mutate({
        mutation: GQL_CREATE_PRICE_ELEMENT,
        variables: {priceElement: priceElementParsed,},
      })
      .then(({data: {createPriceElement: priceElementParsed}}) => priceElementParsed);
  }

  static upsertQuotes({quotes, deletedQuotes = [], updateJob, updateInvoice}) {
    const quotesParsed = quotes.map((quote) =>
      QuoteService.parseQuoteInput(quote)
    );
    return gqlClient
      .mutate({
        mutation: GQL_UPSERT_GROUPED_QUOTES,
        variables: {
          quotes: quotesParsed,
          deletedQuotes,
          updateJob,
          updateInvoice,
        },
      })
      .then(({data: {upsertGroupedQuotes}}) => upsertGroupedQuotes.quotes);
  }

  static acceptQuote(_id) {
    return gqlClient.mutate({
      mutation: GQL_ACCEPT_QUOTE,
      variables: {quoteId: _id},
    })
      .then(({data: {quote}}) => quote);
  }

  static save(quoteInput) {
    const quote = QuoteService.parseQuoteInput(quoteInput);
    const {_id} = quoteInput;

    if (_id) {
      return gqlClient
        .mutate({
          mutation: GQL_UPDATE_QUOTE,
          variables: {
            _id,
            quote,
          },
        })
        .then(({data: {updateQuote: quote}}) => quote);
    } else {
      return gqlClient
        .mutate({
          mutation: GQL_CREATE_QUOTE,
          variables: {quote,},
        })
        .then(({data: {createQuote: quote}}) => quote);
    }
  }

  delete() {
    return gqlClient.mutate({
      mutation: GQL_DELETE_QUOTE,
      variables: {_id: this._id,},
    });
  }

  static sendEmailLink(_id, formValues, renderSiblings = false) {
    return gqlClient
      .mutate({
        mutation: GQL_SEND_EMAIL_LINK,
        variables: {
          _id,
          ...(formValues.viaEmail && {emailOptions: EmailTemplateService.cleanEmailContent(formValues)}),
          ...(formValues.viaSms && {smsOptions: EmailTemplateService.cleanSmsContent(formValues)}),
          renderSiblings
        },
      })
      .then(({data: {sendQuoteLink: result}}) => result);
  }

  static convertToJob(_id) {
    return gqlClient
      .mutate({
        mutation: GQL_CONVERT_TO_JOB,
        variables: {_id,},
      })
      .then(({data: {convertQuoteToJob: result}}) => result);
  }

  static duplicateQuote(_id) {
    return gqlClient
      .mutate({
        mutation: GQL_DUPLICATE_QUOTE,
        variables: {quoteId: _id},
      })
      .then(({data: {duplicateQuote: newQuote}}) => newQuote);
  }

  static commentQuote(_id, comment) {
    return gqlClient
      .mutate({
        mutation: GQL_QUOTE_COMMENT,
        variables: {quoteId: _id, comment: comment},
      })
      .then(({data: {commentQuote}}) => commentQuote);
  }
}
