import React from 'react';
import {
  Grid,
  IconButton,
  Typography,
  withStyles
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {get} from 'lodash';
import {withRouter} from 'react-router-dom';

const styles = (theme) => ({
  productName: {color: theme.palette.primary.main},
  productCode: {
    color: theme.colors.grey.dark,
    marginRight: theme.spacing(1)
  },
  bufferRight: {marginRight: theme.spacing(1)}
});

let PageHeading = (props) => {
  const {product, classes, history} = props;

  return (
    <Grid item container xs={12} alignItems={'center'}>
      <Grid item>
        <IconButton className={classes.bufferRight} onClick={() => history.push('/catalog/products')}>
          <ArrowBackIcon />
        </IconButton>
      </Grid>
      <Grid item>
        <Typography variant={'h4'}>
          <span className={classes.productCode}>
            {get(product, 'code', 'null')}
          </span>
          <span className={classes.bufferRight}>
           &nbsp;|&nbsp;
          </span>
          <span className={classes.productName}>
            {get(product, 'title', '')}
          </span>
        </Typography>
      </Grid>
    </Grid>
  );
};

PageHeading = withStyles(styles)(PageHeading);
PageHeading = withRouter(PageHeading);
export default PageHeading;
