import {
  getGridDateOperators,
  getGridNumericOperators,
  getGridStringOperators, GridFilterInputMultipleSingleSelect,
  GridFilterInputSingleSelect,
  GridFilterOperator
} from '@mui/x-data-grid-pro';

type Operators = 'contains' | 'equals' | 'startsWith' | 'endsWith' | 'isEmpty' | 'isNotEmpty' | 'isAnyOf' | 'is' | 'not' | 'after' | 'onOrAfter' | 'before' | 'onOrBefore' |'=' | '!=' | '>' | '>=' | '<' | '<='

/**
 * get filters for string type
 * @param include (only include those in this list)
 */
export const getFilteredGridStringOperators = (include?: Operators[]): GridFilterOperator<any,
  number | string | null,
  // @ts-ignore
  any>[] => getGridStringOperators().filter(({value}) => !include || include.includes(value));

/**
 * get filters for numeric type
 * @param filters
 */
export const getFilteredGridNumericOperators = (filters: Operators[]): GridFilterOperator<any,
  number | string | null,
  // @ts-ignore
  any>[] => getGridNumericOperators().filter(({value}) => filters.includes(value));

/**
 * get filters for date type
 * @param include (only include those in this list)
 * @param showTime
 */
const getFilteredGridDateOperators = (
  include?: Operators[],
  showTime?: boolean,
  // @ts-ignore
): GridFilterOperator<any, string | number | Date, any>[] => getGridDateOperators(showTime).filter(({value}) => !include || include.includes(value));

//code stolen from https://github.com/mui/mui-x/blob/d4946683b9f3160c5e3f70dfd2e0792426471ca9/packages/grid/x-data-grid/src/colDef/gridSingleSelectOperators.ts

const getGridSelectOperators = (filters?: Operators[]): GridFilterOperator[] => [
  ...getGridNumericOperators().filter(({value}) => ['isEmpty','isNotEmpty'].includes(value)),
  {
    value: 'is',
    getApplyFilterFn: () => () => true,
    InputComponent: GridFilterInputSingleSelect,
  },
  {
    value: 'not',
    getApplyFilterFn: () => () => true,
    InputComponent: GridFilterInputSingleSelect,
  },
  {
    value: 'isAnyOf',
    getApplyFilterFn: () => () => true,
    InputComponent: GridFilterInputMultipleSingleSelect,
  },
  // @ts-ignore
].filter(({value}) => !filters || filters.includes(value));

export function getFilteredGridOperators(filters: Operators[],type: string){
  switch (type){
    case 'User':
      return getGridSelectOperators(filters);
    case 'singleSelect':
      return getGridSelectOperators(filters);
    case 'date':
      return getFilteredGridDateOperators(filters);
     default:
      return getFilteredGridStringOperators(filters);
  }
}
