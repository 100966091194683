// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import {Checkbox, makeStyles, TableCell, withStyles} from '@material-ui/core';
import React from 'react';

export const useHoopsJobTableStyles = makeStyles((theme) => ({
    checkBoxColumn: {
        textAlign: 'center',
        width: '80px'
    },
    jobItemDescription: {maxWidth: 400,},
    productCodeColumn: {width: 100,},
    productVariantNameColumn: {width: 400,},
    vendorNameColumn: {width: 200,},
    colorColumn: {width: 150},
    sizeColumn: {width: 150},
    quantityColumn: {width: 150},
    statusColumn: {}
}));

export const HoopsTableCell = withStyles((theme) => ({
    head: {
        border: 0,
        color: theme.colors.grey.main,
        fontSize: '12px',
        padding: '16px 0 0 0'
    },
    body: {
        border: 0,
        padding: 0,
        fontSize: '14px',
        height: '38px',
        color: theme.colors.grey.dark
    }
}))(TableCell);

export const HoopsTableCellCheckbox = ({
    mode = 'checkbox',
    checked = false,
    disabled = false,
    onChange = () => null
}) => {
    const classes = useHoopsJobTableStyles();

    if (!isSelectable(mode)) {
        return null; // no render
    }

    return (
        <HoopsTableCell className={classes.checkBoxColumn}>
            <Checkbox onChange={onChange} size='small' color='primary' checked={checked} disabled={disabled} />
        </HoopsTableCell>
    );
};

export const isSelectable = (mode = 'checkbox') => mode === 'checkbox';
