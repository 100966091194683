import React, {useState} from 'react';
import {Route, Switch, useHistory} from 'react-router-dom';
import Files from '../../../modules/file/Files';
import Comments from '../../Comments/Comments';
import PreviewQuoteContent from '../PreviewQuote/PreviewQuoteContent';
import QuoteTasks from './QuoteTasks';
import {SalesDocActivityTab} from '../../../pages/SalesDoc/Tabs/SalesDocActivityTab';
import {Grid, Tab, Tabs} from '@mui/material';
import theme from '../../../ui/theme';

export const SingleQuoteView = ({quote}) => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState('view');

  const handleTabChange = (e, newActiveTab) => {
    setActiveTab(newActiveTab);
    history.push(newActiveTab);
  };

  return (
    <Grid id='quotePdf' container justifyContent={'center'}>
      <Grid
        sx={{maxWidth: 1200, [theme.breakpoints.down('md')]: {p: 1.5}}}
        md={12}
        lg={10}
        container
        item
        spacing={3}
      >
        <Grid container spacing='4'>
          <Grid item xs={12}>
            <Grid
              container
              sx={{borderBottom: `1px solid ${theme.palette.secondary.main}`}}
            >
              <Tabs
                sx={{mt: 2}}
                value={activeTab}
                onChange={handleTabChange}
                indicatorColor='primary'
                textColor='primary'
                variant='scrollable'
                scrollButtons='auto'
              >
                <Tab label='Preview' value='view' />
                <Tab label='Files' value='files' />
                <Tab label='Comments' value='comments' />
                <Tab label='Tasks' value='tasks' />
                <Tab label='Activity' value='activity' />
              </Tabs>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Switch>
                <Route path={['/quotes/:_id/view', '/app/quotes/:_id/view']} exact component={() => <PreviewQuoteContent tenantView={true} />} />
                <Route
                  path={['/quotes/:_id/comments', '/app/quotes/:_id/comments']}
                  exact
                  component={() => <Comments
                    channelId={`quote-${quote._id}`}
                    meta={{
                      entityId: quote._id,
                      entityType: 'quote',
                      entityTypeName: 'Quote',
                      entityNumber: quote.number,
                      customerName: quote.customer.name
                    }} />
                  } />
                <Route path={['/quotes/:_id/files', '/app/quotes/:_id/files']} exact component={() => <Files customerId={quote.customerId} getEntityIds={[quote._id]} setEntityIds={[quote._id]} isWorkable={true} createTaskState={{quoteIds: [quote._id], quotes: [quote]}} showCustomerBtn={true} showUploadFileBtn={true} />} />
                <Route path={['/quotes/:_id/tasks', '/app/quotes/:_id/tasks']} exact component={() => <QuoteTasks quote={quote} />} />
                <Route path={['/quotes/:_id/activity', '/app/quotes/:_id/activity']} exact component={() => <SalesDocActivityTab quote={quote} />} />
              </Switch>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
