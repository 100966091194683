// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import {DecorationService} from '../servicesOld/DecorationService';
import {snackError, snackSuccess} from './action-types';
import {hoopsQuerySetRefetch} from './hoopsQuery';

export const SET_DECORATION = 'SET_DECORATION';
export const SET_DECORATIONS = 'SET_DECORATIONS';
export const SET_CATEGORY_SUGGESTIONS = 'SET_CATEGORY_SUGGESTIONS';
export const FETCH_DECORATIONS = 'FETCH_DECORATIONS';

function setDecorations(payload) {
  return {
    type: SET_DECORATIONS,
    payload,
  };
}

export function decorationImport({fileId, vendorId}) {
  return (dispatch) => {
    DecorationService.decorationImport({fileId, vendorId});
  };
}

export function setDecoration(payload) {
  return {
    type: SET_DECORATION,
    payload,
  };
}

export function getDecoration(_id, cb = () => null) {
  return function (dispatch) {
    return DecorationService.getDecoration(_id)
      .then((payload) => {
        dispatch(setDecoration(payload));
        cb(payload);
      });
  };
}

export function setCategorySuggestions(payload) {
  return {
    type: SET_CATEGORY_SUGGESTIONS,
    payload,
  };
}

export function getDecorations() {
  return function (dispatch) {
    dispatch ({type: FETCH_DECORATIONS});
    return DecorationService.getDecorations()
      .then(({items}) => {
        dispatch(setDecorations(items));
      });
  };
}

export function deleteDecoration(decoration) {
  return function (dispatch) {
    const decorationService = new DecorationService(decoration);
    return decorationService.delete()
      .then(() => {
        dispatch(getDecorations());
        dispatch(hoopsQuerySetRefetch('decoration', true));
      });
  };
}

export function saveDecoration(decoration) {
  return function (dispatch) {
    const decorationService = new DecorationService(decoration);
    return decorationService.save()
      .then((data) => {
        dispatch(hoopsQuerySetRefetch('decoration', true));
        dispatch(snackSuccess('Decoration Saved'));
        return data;
      })
      .catch((e) => {
        console.error(e);
        dispatch(snackError('Something went wrong saving your decoration.'));
      });
  };
}

export function searchCategories(value) {
  return function (dispatch) {
    return DecorationService.searchCategories(value)
      .then((categories) => {
        dispatch(setCategorySuggestions(categories));
      });
  };
}
