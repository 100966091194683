import React, {useCallback} from 'react';
import {Dialog} from '@mui/material';
import classNames from 'classnames';
import {HeadingText} from '../Text';
import {Row} from '../Layout';
import {Button} from '../Basic';
import {HoopsPropTypes} from '../utils';
import {registerGlobalStyle} from '../../theme';

registerGlobalStyle('.modal-dialog', (theme) => ({
  '.MuiDialog-paper.MuiPaper-root': {maxWidth: 'unset',},
  '.modal-dialog-content': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    maxWidth: 1280,
    '&>.text-heading': {padding: theme.spacing(2, 3)},
    '&>.text-body': {
      fontSize: '1rem',
      padding: theme.spacing(0, 13, 2.5),
      textAlign: 'center',
    },
  },
  '.actions': {
    padding: theme.spacing(3),
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'end',
  },
  '&.actions-border .actions': {borderTop: `1px solid ${theme.colors.border.light}`},
}));

export function ModalDialog({
                              className,
                              actionsBorder,
                              cancelText = 'Cancel',
                              okDecorator,
                              okText = 'Ok',
                              loading,
                              title,
                              onClose,
                              onOk,
                              children
}) {
  const handleCancel = useCallback(() => onClose?.(false), [onClose]);

  const handleOk = useCallback(() => {
    onClose?.(true);
    onOk?.();
  }, [onClose, onOk]);

  return (
    <Dialog className={classNames(className, 'modal-dialog', actionsBorder && 'actions-border')} open={true} onClose={handleCancel}>
      <div className={'modal-dialog-content'}>
        {title &&
          <HeadingText className={'dialog-title'} x20 heading={title}/>
        }
        {children}
        {(cancelText || okText) &&
          <Row className={'actions'}>
            {cancelText && <Button navNegative text={cancelText} onClick={handleCancel}/>}
            <Button navPositive text={okText} suffix={okDecorator} loading={loading} onClick={handleOk}/>
          </Row>
        }
      </div>
    </Dialog>
  );
}

ModalDialog.propTypes = {
  className: HoopsPropTypes.className,
  actionsBorder: HoopsPropTypes.bool,
  cancelText: HoopsPropTypes.string,
  okDecorator: HoopsPropTypes.decorator,
  okText: HoopsPropTypes.string,
  loading: HoopsPropTypes.bool,
  title: HoopsPropTypes.string,
  onClose: HoopsPropTypes.func,
  onOk: HoopsPropTypes.func,
  children: HoopsPropTypes.children,
};
