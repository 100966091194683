// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-class-assign, no-shadow */

import {Backdrop, Button, CircularProgress, Dialog, DialogContent, withStyles} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {get} from 'lodash';
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {reduxForm, reset} from 'redux-form';
import {getManyEmailTemplate} from '../../actions/emailTemplate';
import {closeSendQuoteModal as _closeSendQuoteModal} from '../../actions/modals';
import {SendEmailLink} from '../../actions/quote';
import theme from '../../ui/theme';
import DialogActions from '../shared/DialogActions';
import DialogTitle from '../shared/DialogTitle';
import SendQuoteForm from './SendQuoteForm';

const styles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: '#fff',
  },
});

class SendQuoteDialog extends React.Component {
  state = {open: false,};

  stripHtml = (html) => {
    let tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }

  handleClose = () => {
    const {handleClose, closeSendQuoteModal} = this.props;
    closeSendQuoteModal();
    if (handleClose) {
      handleClose();
    }
  };

  sendQuote = (values) => {
    const {sendEmailLink, quote, renderSiblings} = this.props;
    sendEmailLink(quote._id, values, renderSiblings);
  };

  UNSAFE_componentWillReceiveProps(props) {
    const {open} = props;
    this.setState({
      ...this.state,
      open
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {sendQuoteError, sendQuoteSuccess, isLoading} = this.props;
    if (sendQuoteError || isLoading) {
      return;
    } else {
      if (this.state.open) {
        if (!prevProps.sendQuoteSuccess && sendQuoteSuccess) {
          this.handleClose();
        }
      }
    }

    const {emailTemplates, open} = this.props;
    if (open && prevProps.open !== open) {
      // Fetch and set
      emailTemplates('quote').then((data) => {
        this.setState({emailTemplates: data.filter(((el) => el.via === 'email')), smsTemplates: data.filter(((el) => el.via === 'sms'))});

        const defaultEmailTemplate = data.filter(((el) => el.via === 'email')).find((v) => v.isDefault);
        this.props.change('emailTemplate', defaultEmailTemplate);
        this.props.change('subject', defaultEmailTemplate && defaultEmailTemplate.subject);
        this.props.change('body', defaultEmailTemplate && defaultEmailTemplate.body);

        const defaultSmsTemplate = data.filter(((el) => el.via === 'sms')).find((v) => v.isDefault);
        this.props.change('smsTemplate', defaultSmsTemplate);
        this.props.change('smsBody', defaultSmsTemplate && this.stripHtml(defaultSmsTemplate.body));
      });
    }
  }

  render() {
    const {handleSubmit, isLoading, open, quote} = this.props;

    return (
      <Fragment>

        <Dialog open={open} maxWidth={'lg'}>
          <DialogTitle onClick={this.handleClose}>
            <h3>Send Quote</h3>
          </DialogTitle>
          <form onSubmit={handleSubmit(this.sendQuote)}>
            <DialogContent style={{paddingTop: 0, overflowY: 'hidden'}}>
              <Backdrop open={!quote._id} className={this.props.classes.backdrop}>
                <CircularProgress color='inherit' />
              </Backdrop>
              <SendQuoteForm change={this.props.change} emailTemplates={this.state.emailTemplates} smsTemplates={this.state.smsTemplates} stripHtml={this.stripHtml}/>
            </DialogContent>
            <DialogActions>
              <Grid container justifyContent={'space-between'}>
                <Grid item>
                  <Button color={'secondary'} onClick={this.handleClose}>Cancel</Button>
                </Grid>
                <Grid item>
                  <Button
                    variant='contained'
                    disabled={isLoading}
                    color='primary'
                    type={'submit'}
                  >
                    {isLoading ? <><CircularProgress size={16} style={{marginRight: theme.spacing(1)}}/>Send</> : 'Send'}
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        </Dialog>
      </Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sendEmailLink: (_id, values, renderSiblings) => dispatch(SendEmailLink(_id, values, renderSiblings)),
    closeSendQuoteModal: () => dispatch(_closeSendQuoteModal()),
    resetForm: () => dispatch(reset('sendQuoteForm')),
    emailTemplates: (entity) => dispatch(getManyEmailTemplate(entity))
  };
}

function mapStateToProps(state) {
  return {
    open: state.modalReducer.sendQuoteModal.isOpen,
    renderSiblings: get(state, 'modalReducer.sendQuoteModal.context.renderSiblings', false),
    quote: state.quoteReducer.quote,
    isLoading: state.quoteReducer.sendQuoteLoading,
    sendQuoteError: state.quoteReducer.sendQuoteError,
    sendQuoteSuccess: state.quoteReducer.sendQuoteSuccess,
    initialValues: {
      viaEmail: true,
      viaSms: false,
      to: get(state, 'quoteReducer.quote.contact.email', ''),
      replyTo: state.quoteReducer.quote?.quoteOwner?.username ?? state.authReducer.userData.username ?? '',
      body: '',
      phone: get(state, 'quoteReducer.quote.contact.phone', ''),
      smsBody: ''
    }
  };
}

SendQuoteDialog = reduxForm({
  form: 'sendQuoteForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(SendQuoteDialog);
SendQuoteDialog = connect(mapStateToProps, mapDispatchToProps)(SendQuoteDialog);
SendQuoteDialog = withStyles(styles)(SendQuoteDialog);

export default SendQuoteDialog;
