import {alpha} from '@mui/material/styles';
import theme, {palette} from '../../../ui/theme';

export const dataGridStyles = {
  border: 'none',

  '& .MuiDataGrid-main': {overflow: 'visible',},

  // Shadows and borders on the pinned columns
  '& .MuiDataGrid-pinnedColumns.MuiDataGrid-pinnedColumns--left': {
    boxShadow: 'none',
    '& .MuiDataGrid-cell:last-child': {borderRight: '1px solid rgba(0, 0, 0, .25)',}
  },
  '& .MuiDataGrid-pinnedColumns.MuiDataGrid-pinnedColumns--right': {
    boxShadow: 'none',
    '& .MuiDataGrid-cell:first-child': {borderLeft: '1px solid  rgba(0, 0, 0, .25)',}
  },

  '& .MuiDataGrid-columnHeaders': {
    borderRadius: 0,
    borderBottom: `1px solid ${theme.colors.grey.borderLight}`,
    borderTop: `1px solid ${theme.colors.grey.borderLight}`,
    overflow: 'visible',

    '& .MuiDataGrid-columnHeadersInner': {minHeight: 'inherit'},
    '& .MuiDataGrid-pinnedColumnHeaders': {
      minHeight: 'inherit',
      overflow: 'visible',
      border: 'inherit',
    },

    '& .MuiDataGrid-pinnedColumnHeaders .MuiDataGrid-columnHeader .addColumnIcon': {display: 'none'},
    '& .MuiDataGrid-columnHeader:first-child .addColumnIconLeft': {display: 'none'},
    '& .MuiDataGrid-columnHeader:last-child .addColumnIconRight': {display: 'none'},

    '& .MuiDataGrid-columnHeader:hover': {'& .MuiDataGrid-columnHeaderDraggableContainer .addColumnIconRight': {opacity: 1},},

    '& .MuiDataGrid-columnHeader': {
      paddingLeft: 0,
      minHeight: 'inherit',
      position: 'relative',

      '&.MuiDataGrid-columnHeader--alignCenter .headerTextContainer': {justifyContent: 'center',},

     '&:focus, &:focus-within': {outline: 'none'},
      '&::after': {
        content: '""',
        borderRight: `1px solid ${theme.colors.grey.borderLight}`,
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
      },

      // The column menu button
      '& .MuiDataGrid-menuIcon': {position: 'absolute', width: 'auto', height: '100%', right: 0, opacity: 0, visibility: 'visible'},
      '&:hover .MuiDataGrid-menuIcon': {opacity: 0},
      '&  .MuiDataGrid-columnHeaderDraggableContainer:hover .MuiDataGrid-menuIcon': {opacity: 1},

      // The sort icon, this is visible when sortable and sorted or sortable and hovered
      '& .MuiDataGrid-iconButtonContainer': {opacity: 0, visibility: 'visible', width: 0},
      '&.MuiDataGrid-columnHeader--sorted .MuiDataGrid-iconButtonContainer': {opacity: 1, width: 'auto'},
      '&.MuiDataGrid-columnHeader--sortable .MuiDataGrid-columnHeaderDraggableContainer:hover .MuiDataGrid-iconButtonContainer': {opacity: 1, width: 'auto'},

      '&.MuiDataGrid-columnHeader--sorted .spaceBalanceSortIcon': {flexBasis: '28px'},
      '&.MuiDataGrid-columnHeader--sortable .MuiDataGrid-columnHeaderDraggableContainer:hover .spaceBalanceSortIcon': {flexBasis: '28px'},

      '& .spaceBalanceSortIcon': {flex: '0 1'},

      '& .MuiDataGrid-columnHeaderDraggableContainer': {
        minHeight: 'inherit',
        cursor: 'grab',

        '& .MuiDataGrid-columnHeaderTitleContainer': {
          position: 'absolute',
          width: '100%',
          height: '100%',
          padding: '0 30px 0 18px',
          display: 'flex',
          overflow: 'visible',

          '& .MuiDataGrid-columnHeaderTitleContainerContent': {
            width: '100%',
            height: '100%',
            minHeight: 'inherit',

            '& > .spaceAfterDragIcon': {
              width: '12px',
              minWidth: 0,
              height: '100%',
            },

            '& > .spaceBalanceSortIcon': {
              width: '28px',
              minWidth: 0,
              height: '100%',
            },

            '& > .headerTextContainer': {
              width: 'fit-content',
              maxWidth: '100%',
              minWidth: 0,
              display: 'flex',
              flex: '1 0 fit-content',

              '& > p': {
                border: '1px solid transparent',
                borderRadius: '4px',
                padding: '2px 8px 0 8px',
                margin: '0',
                '&:hover': {
                  border: `1px solid ${theme.colors.grey.subtle}`,
                  borderStyle: 'solid',
                  backgroundColor: theme.colors.white,
                  cursor: 'text',
                  margin: '0',
                  padding: '2px 8px 0 8px',
                }
              },
            },
          },
        },

        '& .addColumnIcon': {
          position: 'absolute',
          top: '-8px',
          height: 22,
          width: 30,
          backgroundColor: 'transparent',
          zIndex: 10000,
          opacity: 0,
          '&.addColumnIconLeft': {left: -15,},
          '&.addColumnIconRight': {right: -15,},

          '& > svg': {
            margin: '1px',
            fontSize: '1.25rem',
            color: palette.primary.main,
            position: 'absolute',
            left: 4,
            top: 0
          },

          '&.addColumnIconShownLeft.addColumnIconLeft': {opacity: 1,},
          '&.addColumnIconShownRight.addColumnIconRight': {opacity: 1,},
        },

        '& .dragIcon': {opacity: 0, margin: '1px', position: 'absolute', left: 0},

        '&:hover': {
          backgroundColor: palette.secondary.hover,
          position: 'absolute',

          '& .addColumnIcon': {opacity: 1},
          '& .dragIcon': {opacity: 1},
        },
      },

      '& .titleTextContainer': {
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        left: 0,
        padding: '8px',
        zIndex: 1,

        '& .MuiOutlinedInput-input': {
          paddingRight: '8px',
          paddingLeft: '8px',
        }
      },
    },
    // Pinned columns cannot be dragged
    '& .MuiDataGrid-pinnedColumnHeaders .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderDraggableContainer': {
      cursor: 'default',
      '& .dragIcon': {opacity: 0},
    },

    // No shadow on pinned column headers
    '& .MuiDataGrid-pinnedColumnHeaders.MuiDataGrid-pinnedColumnHeaders--left, .MuiDataGrid-pinnedColumnHeaders.MuiDataGrid-pinnedColumnHeaders--right': {boxShadow: 'none',},

    // Pinned column header borders
    '& .MuiDataGrid-pinnedColumnHeaders.MuiDataGrid-pinnedColumnHeaders--left .MuiDataGrid-columnHeader:last-child::after': {borderRight: '1px solid rgba(0, 0, 0, .25)',},
    '& .MuiDataGrid-pinnedColumnHeaders.MuiDataGrid-pinnedColumnHeaders--right .MuiDataGrid-columnHeader:first-child::after': {borderLeft: '1px solid  rgba(0, 0, 0, .25)',},
    '& .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:last-child': {
      '& .MuiDataGrid-columnSeparator': {display: 'none',},
      '&::after': {border: 'none',}
    },

    // Column separators
    '& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator': {
      position: 'absolute',
      width: 13,
      display: 'flex',
      alignItems: 'center',
      '& > svg': {
        position: 'relative',
        fontSize: '29px',
      },
      '&:active:hover': {width: 'calc(100% + 13px)',},

      '&.MuiDataGrid-columnSeparator--sideRight': {
        right: '-6px',
        '&:active:hover': {
          alignItems: 'end',
          '& > svg': {left: 8,},
        }
      },

      '&.MuiDataGrid-columnSeparator--sideLeft': {
        left: '-6px',
        '&:active:hover': {
          alignItems: 'start',
          '& > svg': {right: 8,},
        }
      },
    },
  },

  '& .MuiDataGrid-row': {
    position: 'relative',

    '&.odd, &.odd:hover, &.odd.Mui-hovered': {bgcolor: theme.colors.white},
    '&.even, &.even:hover, &.even.Mui-hovered': {bgcolor: alpha(theme.colors.grey.bg, 0.5)},
    '&:hover::after, &.Mui-hovered::after': {
      content: '""',
      pointerEvents: 'none',
      bgcolor: alpha(palette.primary.main, .05),
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
    },
    '& .MuiDataGrid-cell': {
      borderRight: `1px solid ${theme.colors.grey.borderLight}`,
      borderBottom: `1px solid ${theme.colors.grey.borderLight}`,

      '&:focus, &:focus-within': {outline: 'none'},
      '&:last-child': {borderRight: 'none',},
    },
    '&:last-child .MuiDataGrid-cell': {borderBottom: `1px solid ${theme.colors.grey.borderLight}`,},
  },

  // General text cell
  '& .TextCell': {
    display: 'flex',
    height: '22px',
    maxWidth: '100%',
  },
  '& .TextCell > .TextCell-Container': {
    maxWidth: '100%',
    display: 'flex',
  },
  '& .TextCell > .TextCell-Container > span': {
    maxWidth: '100%',
    paddingTop: '2px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    wordWrap: 'break-word',
    display: 'inline-block',
  },
  '& .TextCell > .TextCell-Container > span:empty': {
    opacity: 0,
    transition: 'opacity .3s',
    overflow: 'visible',
    '&::before': {
      content: '"Click To Add Text"',
      color: theme.colors.grey.main,
      border: `1px dashed ${theme.colors.grey.main}`,
      borderRadius: '4px',
      padding: '11px 12px 9px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      wordWrap: 'break-word',
      display: 'inline-block',
      maxWidth: '100%',
      position: 'relative',
      top: '1px',
    }
  },
  '&.MuiDataGrid-root--densityCompact .TextCell > .TextCell-Container > span:empty': {
    '&::before': {
      top: '2px',
      padding: '4px 12px 3px',
    },
  },
  '& .MuiDataGrid-cell:hover .TextCell > .TextCell-Container > span:empty': {opacity: 1,},
  '& .MuiDataGrid-cell--editable:hover > .TextCell.TextCell-Editing > *::after': {opacity: 0,},
  '& .MuiDataGrid-cell--editable.CellDataTypeTEXT:focus-within': {
    border: `1px solid ${palette.primary.main}`,
    padding: '0 9px 0 9px',
    background: theme.colors.white,
  },
  '& .TextCell-Editing': {
    '& .MuiTextField-root': {
      '& > div': {fontSize: 'inherit',},
      '.MuiInputBase-root': {background: 'transparent',},
      'input': {
        padding: 0,
        background: 'transparent',
      },
      'fieldset': {border: 'none',}
    },
  },

  // Date cell
  '& .DateCell': {
    display: 'flex',
    height: '22px',
    maxWidth: '100%',
  },
  '& .DateCell > span': {
    maxWidth: '100%',
    paddingTop: '2px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    wordWrap: 'break-word',
    display: 'inline-block',
  },
  '& .DateCell >span:empty': {
    opacity: 0,
    transition: 'opacity .3s',
    overflow: 'visible',
    '&::after': {
      content: '"event"',
      fontFamily: 'Material Icons',
      color: theme.colors.grey.main,
      fontSize: '24px',
      position: 'relative',
      top: -7,
      transition: 'opacity .3s',
    }
  },
  '& .MuiDataGrid-cell:hover .DateCell >span:empty': {opacity: 1,},

  // Avatars
  '& .user-avatar': {
    fontSize: 20,
    width: 43,
    height: 43,
    transition: 'background-color .3s',
    '&.EmptyCell': {backgroundColor: 'transparent',},
  },
  '.MuiDataGrid-cell:hover .user-avatar.empty-avatar': {backgroundColor: palette.secondary.main,},
  '&.MuiDataGrid-root--densityCompact .user-avatar': {
    fontSize: 15,
    width: 28,
    height: 28,
  },

  // Editing a cell, remove shadow etc
  '& .MuiDataGrid-cell.MuiDataGrid-cell--editing': {
    padding: '1px',
    boxShadow: 'none',
    backgroundColor: theme.colors.white,
  },

  // Edit hover indicators
  '& .MuiDataGrid-cell--editable > .CellContainer': {
    cursor: 'pointer',
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'inherit',
  },
  '& .MuiDataGrid-row .MuiDataGrid-cell.MuiDataGrid-cell--editable': {overflow: 'visible',},
  '& .MuiDataGrid-cell--editable > .CellContainer > *': {
    position: 'relative',
    overflow: 'visible',
  },
  '& .MuiDataGrid-cell--editable > .CellContainer > *::after': {
    content: '"edit"',
    fontFamily: 'Material Icons',
    color: theme.colors.white,
    fontSize: '14px',
    lineHeight: 1,
    position: 'absolute',
    right: -11,
    bottom: 0,
    width: 20,
    height: 20,
    padding: '3px',
    borderRadius: '50%',
    background: theme.palette.primary.main,
    opacity: 0,
    pointerEvents: 'none',
    transition: 'opacity .3s',
    zIndex: 1,
  },
  '& .MuiDataGrid-cell--editable > .CellContainer > .TextCell-Container::after': {
    bottom: '-8px',
    right: '-13px',
  },
  '& .MuiDataGrid-cell--editable > .CellContainer > .TextCell-Container > span:empty': {
    marginTop: '-12px',
    marginBottom: '-12px',
  },
  '& .MuiDataGrid-cell--editable > .CellContainer > .DateCell::after': {
    bottom: '-8px',
    right: '-13px',
  },
  '& .MuiDataGrid-cell--editable:hover > .CellContainer > *::after': {opacity: 1,},
};
