import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {Collapse} from '@mui/material';
import {ExpandLess as CollapseIcon, ExpandMore as ExpandIcon} from '@mui/icons-material';
import classNames from 'classnames';
import {Button} from '../Basic';
import {registerGlobalStyle} from '../../theme';
import {HoopsPropTypes} from '../utils';
import {Column} from './Column';
import {Row} from './Row';
import {HeadingText} from '../Text';

const ExpansionPanelContext = createContext(null);
export const useExpansionPanelContext = () => useContext(ExpansionPanelContext);

registerGlobalStyle('.expansion-button', () => ({
  fontSize: '1rem',
  top: '-1px',
  '& *': {fontSize: 'inherit'}
}));

export function ExpansionSection({className, heading, hideIcon, hideText, initShown, showIcon, showText, children}) {
  return (
    <Column className={[className, 'expansion-section']}>
      <ExpansionPanelProvider initShown={initShown ?? true}>
        <Row>
          {heading &&
            <HeadingText x24 normal heading={heading}/>
          }
          <ExpansionButton hideIcon={hideIcon} hideText={hideText} showIcon={showIcon} showText={showText} noWrap/>
        </Row>
        <ExpansionPanel>
          {children}
        </ExpansionPanel>
      </ExpansionPanelProvider>
    </Column>
  );
}

ExpansionSection.propTypes = {
  className: HoopsPropTypes.className,
  heading: HoopsPropTypes.string,
  hideIcon: HoopsPropTypes.decorator,
  hideText: HoopsPropTypes.string,
  initShown: HoopsPropTypes.bool,
  showIcon: HoopsPropTypes.decorator,
  showText: HoopsPropTypes.string,
  children: HoopsPropTypes.children,
};

export function ExpansionPanelProvider({initShown, onChange, children}) {
  const [shown, _setShown] = useState(initShown);

  const setShown = useCallback((isShown) => {
    if (typeof isShown === 'function') {
      isShown = isShown(shown);
    }
    _setShown(isShown);
    if (isShown !== shown && onChange) {
      onChange(isShown);
    }
  }, [onChange, shown]);

  const toggleShown = useCallback(() => {
    setShown((prevShown) => !prevShown);
  }, [setShown]);

  const expandPanelState = useMemo(() => ({
    shown,
    setShown,
    toggleShown,
  }), [setShown, shown, toggleShown]);

  return (
    <ExpansionPanelContext.Provider value={expandPanelState}>
      {children}
    </ExpansionPanelContext.Provider>
  );
}

ExpansionPanelProvider.propTypes = {
  initShown: HoopsPropTypes.bool,
  onChange: HoopsPropTypes.func,
  children: HoopsPropTypes.children,
};

export function ExpansionPanel({className, onShown, children}) {
  const {shown} = useExpansionPanelContext();
  const [rendered, setRendered] = useState(shown);

  const handleExited = useCallback(() => setRendered(false), []);

  useEffect(() => {
    setRendered(() => shown || rendered);
    onShown?.(shown || rendered);
  }, [onShown, shown, rendered]);

  return (
    <Collapse className={classNames([className, 'expansion-panel'])} style={{width: '100%'}} in={shown} onExited={handleExited}>
      {(shown || rendered) &&
        children
      }
    </Collapse>
  );
}

ExpansionPanel.propTypes = {
  className: HoopsPropTypes.className,
  onShown: HoopsPropTypes.func,
  children: HoopsPropTypes.children,
};

export function ExpansionButton({className, hideIcon, hideText, noChevron, showIcon, showText, ...buttonProps}) {
  const {shown, toggleShown} = useExpansionPanelContext();

  return (
    <Button
      actionPrimary
      className={classNames([className, 'expand-button', shown ? 'expand-button-shown' : 'expand-button-hidden'])}
      prefix={!noChevron && (shown ? (hideIcon ?? CollapseIcon) : (showIcon ?? ExpandIcon))}
      onClick={toggleShown}
      text={shown ? hideText : showText}
      {...buttonProps}
    />
  );
}

ExpansionButton.propTypes = {
  className: HoopsPropTypes.className,
  hideIcon: HoopsPropTypes.decorator,
  hideText: HoopsPropTypes.string,
  noChevron: HoopsPropTypes.bool,
  noWrap: HoopsPropTypes.bool,
  showIcon: HoopsPropTypes.decorator,
  showText: HoopsPropTypes.string,
};
