// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React, {Component, Fragment} from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class MuiMultiSelect extends Component {
  state = {htmlId: 'multi-select-input'};

  handleChange = (event) => {
    const {input: {onChange}} = this.props;
    onChange(event);
  };

  render() {
    const {
      input, label, inputProps, options,
      OptionComponent, SelectedComponent, ...custom
    } = this.props;

    const {htmlId} = this.state;

    return (
      <Fragment>
        <FormControl style={{width: '100%'}}>
          <InputLabel htmlFor={htmlId}>{label}</InputLabel>
          <Select multiple
                  MenuProps={{
                    ...MenuProps,
                    PaperProps: {style: {width: 'auto'}}
}}
                  input={<Input {...input} id={htmlId}/>}
                  value={input.value || []}
                  onChange={this.handleChange}
                  renderValue={(selected) => (
                  <Box display='flex' flexWrap={'wrap'}>
                    {selected.map((value, index) => (
                      <SelectedComponent key={index} value={value}/>
                    ))}
                  </Box>
                )}
                  {...custom}>
          {options.map((option, index) => (
            <MenuItem dense={true} key={index} value={option}>
              <OptionComponent option={option} selected={input.value}/>
            </MenuItem>
          ))}
        </Select>
        </FormControl>
      </Fragment>
    );
  }
}

export default MuiMultiSelect;
