import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {DataGridPro, GridSeparatorIcon, useGridApiRef} from '@mui/x-data-grid-pro';
import {Checkbox} from '@mui/material';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {isEqual} from 'lodash';
import theme from '../../../ui/theme';
import {ColumnHeaderField} from './ColumnHeaderField';
import {HoopsPowerGridEmptyState} from './HoopsPowerGridEmptyState';
import {CustomColumnMenuComponent} from './CustomColumnMenuComponent';
import {dataGridStyles} from './DataGridStyles';
import {CustomToolbar} from './CustomToolbar';
import {toUTCDate} from '../../../utils';
import {useWatch} from '../../../hooks';
import {CustomGridFilterPanel} from './components/CustomGridFilterPanel';

const experimentalFeatures = {newEditingApi: true};
const rowsPerPageOptions = [10, 25, 50, 100];

export const HoopsPowerGrid = ({
                                 columnWidths = {},
                                 density = 'standard',
                                 filters,
                                 hiddenColumns,
                                 pinnedColumns,
                                 page,
                                 pageSize,
                                 sort = {},
                                 viewChanged,
                                 viewIsHome,
                                 allowAddColumns,

                                 onChangeColumnOrder,
                                 onChangeColumnWidths,
                                 onChangePage,
                                 onChangePageSize,
                                 onChangeDensity,
                                 onChangeFilters,
                                 onChangeHiddenColumns,
                                 onChangePinnedColumns,
                                 onChangeSort,
                                 onRenameColumn,
                                 onSaveView,

                                 search,
                                 onSearch,
                                 onAddColumn,
                                 onDeleteColumn,
                                 rows = [],
                                 rowCount,
                                 columns = [],
                                 loading,
                               }) => {
  const apiRef = useGridApiRef();

  useEffect(() => apiRef.current.subscribeEvent('columnHeaderDragEnd', () => {
      if (onChangeColumnOrder) {
        onChangeColumnOrder(apiRef.current.getAllColumns().map(({field}) => field));
      }
    }), [apiRef, onChangeColumnOrder]);

  const [gridDensity, setGridDensity] = useState(null);
  const handleStateChange = useCallback((state) => {
    if (state.density?.value !== gridDensity) {
      setGridDensity(state.density.value);
      if (gridDensity !== null && state.density.value !== density) {
        onChangeDensity(state.density.value);
      }
    }
  }, [density, gridDensity, onChangeDensity]);

  const handleSortModelChange = useCallback((model) => {
    if (model.length > 0) {
      const newSort = model.reduce((o, v) => {
        o[v.field] = v.sort === 'desc' ? -1 : 1;
        return o;
      }, {});
      if (!isEqual(sort, newSort)) {
        onChangeSort(newSort);
      }
    }
  }, [onChangeSort, sort]);

  const handleColumnVisibilityModelChange = useCallback((model) => {
    const newHiddenColumns = Object.keys(model).reduce((v, k) => {if (!model[k]) {v.push(k);} return v;}, []);
    if (!isEqual(hiddenColumns, newHiddenColumns)) {
      onChangeHiddenColumns(newHiddenColumns);
    }
  }, [hiddenColumns, onChangeHiddenColumns]);

  const columnVisibilityModel = useMemo(() => {
    // Hide any columns according to the view state
    const visModel = hiddenColumns?.reduce((vis, field) => {
      vis[field] = false;
      return vis;
    }, {});

    // Hide any permanently hidden columns
    columns.forEach((column) => {
      if (column.hide) {
        visModel[column.field] = false;
      }
    });

    return visModel;
  }, [hiddenColumns, columns]);

  // The filters to display
  const [displayedFilters, handleFilterModelChange] = useFilterManager(columns, filters, onChangeFilters);

  // If the row count or page switches to undefined it will reset the paging, so we have to avoid that
  const [rowCountState, setRowCountState] = useState(rowCount ?? 25);
  useEffect(() => {
    setRowCountState((prevRowCountState) => rowCount !== undefined ? rowCount : prevRowCountState);
  }, [rowCount, setRowCountState]);

  // When a column is added we focus the header so the user can change the name
  const handleAddColumn = useCallback(async (type, name, position) => {
    const path = await onAddColumn(type, name, position);
    if (path) {
      setTimeout(() => {
        apiRef.current.getColumnHeaderElement(path)?.querySelector('.column-header-title')?.click();
      });
    }
  }, [onAddColumn, apiRef]);

  // This is quite the hack. When a column on the right is unpinned the grid is rendered wrong. It
  // is in some way related to the overflow on the column headers being visible. So we must render
  // the grid with the overflow hidden, then render again with them visible.
  const [showOverflow, setShowOverflow] = useState(true);
  // When a column is pinned to the right, it is pinned after existing columns. This does not make
  // sense though, so we swap the order for the right. This ensures actions is always right-most.
  const handleChangePinnedColumns = useCallback((pinned) => {
    if (!isEqual(pinned.right, pinnedColumns.right)) {
      setShowOverflow(false);
      if (pinned.right.length > pinnedColumns.right.length) {
        const right = pinned.right.slice(0, pinnedColumns.right.length);
        if (isEqual(right, pinnedColumns.right)) {
          right.unshift(...pinned.right.slice(pinnedColumns.right.length).reverse());
          pinned = {...pinned, right};
        }
      }
    }
    onChangePinnedColumns(pinned);
    setTimeout(() => {
      setShowOverflow(true);
    });
  }, [onChangePinnedColumns, pinnedColumns.right]);
  dataGridStyles['& .MuiDataGrid-columnHeaders'].overflow = showOverflow ? 'visible' : 'hidden';

  const _columns = useMemo(() => columns.map((column) => ({
        ...column,
        ...(columnWidths && columnWidths[column.field] !== undefined ? {width: columnWidths[column.field]} : {}),
        renderHeader: (params) => (
          <ColumnHeaderField
            defaultValue={column?.headerName}
            onRenameColumn={(name) => onRenameColumn(column.field, name)}
            onAddColumn={handleAddColumn}
            headerTextClass={'column-header-title'}
            allowAddColumns={allowAddColumns}
            {...params}
          />)
      })).filter(Boolean), [columns, columnWidths, handleAddColumn, onRenameColumn, allowAddColumns]);

  const sortModel = useMemo(() => Object.keys(sort).map((key) => ({field: key, sort: sort[key] === 1 ? 'asc' : 'desc'})), [sort]);

  return (
          <div style={{
            flex: 1,
            width: '100%',
            backgroundColor: theme.colors.white
          }}>
            <DataGridPro
                    onCellClick={useCallback((params, event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      event.defaultMuiPrevented = true;
                    }, [])}
                    onCellKeyDown={useCallback((params, event) => {
                      event.defaultMuiPrevented = true;
                    }, [])}

                    onStateChange={handleStateChange}
                    density={density}
                    disableVirtualization={true}
                    experimentalFeatures={experimentalFeatures}
                    onPinnedColumnsChange={handleChangePinnedColumns}
                    pinnedColumns={pinnedColumns}
                    sortingMode='server'
                    sortModel={sortModel}
                    onSortModelChange={handleSortModelChange}
                    filterModel={displayedFilters}
                    onFilterModelChange={handleFilterModelChange}
                    onColumnWidthChange={useCallback(({colDef, width}) => {
                      onChangeColumnWidths({...columnWidths, [colDef.field]: width});
                    }, [columnWidths, onChangeColumnWidths])}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
                    page={page}
                    onPageChange={onChangePage}
                    pageSize={pageSize}
                    onPageSizeChange={onChangePageSize}
                    getRowId={useCallback((row) => row._id, [])}
                    rows={rows}
                    rowCount={rowCountState ?? rowCount}
                    columns={_columns}
                    loading={loading}
                    filterMode={'server'}
                    rowBuffer={100}
                    apiRef={apiRef}
                    componentsProps={useMemo(() => ({
                      toolbar: {onSearch, search, onSaveView: onSaveView, viewChanged, viewIsHome},
                      columnMenu: {apiRef: apiRef, onAddColumn: handleAddColumn, onDeleteColumn, allowAddColumns},
                      filterPanel: {
                        filterFormProps: {
                          valueInputProps: {
                            sx: {
                              minWidth: 190,
                              width: 'unset'
                            }
                          }
                        },
                        sx: {
                          padding: '32px',
                          borderRadius: '10px',
                          marginLeft: '20px',
                          zIndex: 10000,
                          backgroundColor: '#FFFFFF',
                          marginTop: '-21px',
                          width: 700,
                          boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.25)',
                          maxWidth: '100vw',
                          '& .MuiDataGrid-filterFormColumnInput': {width: 180},
                          '& .MuiDataGrid-filterFormOperatorInput': {width: 120},
                          '& .MuiDataGrid-filterFormValueInput': {flex: 1},
                        },
                      },
                      columnsPanel: {
                        sx: {
                          padding: '32px',
                          borderRadius: '10px',
                          marginLeft: '20px',
                          zIndex: 10000,
                          backgroundColor: '#FFFFFF',
                          marginTop: '-21px',
                          boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.25)',
                        },
                      },
                      panel: {
                        sx: {
                          '& .MuiDataGrid-paper': {
                            background: 'transparent',
                            boxShadow: 'none',
                          }
                        }
                      }
                    }), [apiRef, handleAddColumn, onSearch, onDeleteColumn, onSaveView, search, viewChanged, viewIsHome, allowAddColumns])}
                    getRowClassName={useCallback((params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd', []
                    )}
                    sx={dataGridStyles}
                    pagination
                    paginationMode='server'
                    rowsPerPageOptions={rowsPerPageOptions}
                    localeText={useMemo(() => ({
                      toolbarColumns: 'Show/Hide Columns',
                      toolbarDensity: 'Row Height',
                      toolbarExportCSV: 'Export'
                    }), [])}
                    localeIcons={useMemo(() => ({toolbarColumns: FilterAltIcon}), [])}
                    components={useMemo(() => ({
                      FilterPanel: CustomGridFilterPanel,
                      BaseSwitch: Checkbox,
                      ColumnMenu: CustomColumnMenuComponent,
                      ColumnMenuIcon: MoreHorizIcon,
                      ColumnUnsortedIcon: UnfoldMoreIcon,
                      ColumnResizeIcon: GridSeparatorIcon,
                      NoRowsOverlay: HoopsPowerGridEmptyState,
                      Toolbar: CustomToolbar,
                    }), [])}
            />
          </div>
  );
};

const useFilterManager = (columns, filters, onChangeFilters) => {
  // The filters are a real pain. DataGridPro will send an event with invalid filters while the filters are being built,
  // we filter those out so they aren't sent to the back end, but filtering them out means the when the element rerenders
  // we have to add them back. Hence, intermediateFilters state. Then to keep track of when the parent changes the filters
  // we have an effect which detects changes, but of course useEffect detects shallow changes, so we need a ref to keep
  // track of the old deep value of the filters, and in the effect we do a deep comparison as well. If the deep comparison
  // shows a change we clear the intermediate filters. Quite a pain, but when we use the gris in lots of places it will be
  // nice to isolate the pages from the mechanics of the filter model.
  const [displayedFilters, setDisplayedFilters] = useState();

  const extractValidFilters = useCallback((items = []) => {
    function validateValue(field, operator, value) {
      const column = columns.find((col) => col.field === field);
      if (column) {
        switch (column.type) {
          case 'date':
            return !isNaN(new Date(value));

          case 'singleSelect': {
            const values = column.valueOptions instanceof Function ? column.valueOptions({field}) : column.valueOptions;
            return values && values.some((v) => v.value === value);
          }

          default:
            return true;
        }
      }
      return false;
    }

    function validateItem(item) {
      const {columnField: field, operatorValue: operator, value} = item;
      if (operator.match(/Empty/)) {
        // Filter is Empty or isEmpty
        return true;
      }
      if (value === null || value === '' || value === undefined) {
        // Filter must have a value, this one is invalid so far
        return false;
      }
      if (Array.isArray(value)) {
        if (value.length === 0) {
          return false;
        }
        return value.every((v) => validateValue(field, operator, v));
      }

      return validateValue(field, operator, value);
    }

    const validItems = [];
    items.forEach((item) => {
      if (validateItem(item)) {
        validItems.push(item);
      }
    });
    return validItems;
  }, [columns]);

  const handleFilterModelChange = useCallback(({linkOperator, items}) => {
    // Don't do anything if the filters have not changed
    if (isEqual({linkOperator, items}, displayedFilters)) {
      return;
    }
    setDisplayedFilters({linkOperator, items});

    const validItems = extractValidFilters(items);

    const mappedItems = validItems.map((item) => {
      const column = columns.find(({field}) => field === item.columnField);
      if (column?.type === 'date') {
        return {
          f: item.columnField,
          o: item.operatorValue === 'is' ? 'isDate' : item.operatorValue,
          v: toUTCDate(new Date(item.value)),
          raw: {o: item.operatorValue, v: item.value}
        };
      } else {
        return {f: item.columnField, o: item.operatorValue, v: item.value};
      }
    });
    if (!isEqual(filters, {linkOperator, items: mappedItems})) {
      onChangeFilters({linkOperator, items: mappedItems});
    }
  }, [columns, displayedFilters, extractValidFilters, filters, onChangeFilters]);

  const mapFiltersToFilterModel = ({linkOperator, items}) => {
    // The page has sent us new filters, we check whether the given filters match the filters that
    // we are displaying, and they do, we don't do anything. If they don't match we update the
    // displayed filters to match the ones we were given.
    const mappedItems = items.map((item, i) => ({columnField: item.f, operatorValue: item.raw?.o ?? item.o, value: item.raw?.v ?? item.v, id: i}));

    const validItems = extractValidFilters(displayedFilters?.items);

    // Iterate every mappedItem and check if there is a corresponding item being displayed, if the arrays
    // do not match we will replace the displayed filters with the new filters
    let matches = mappedItems.length === validItems.length
            && mappedItems.every((mapped) => validItems.some((valid) =>
                    valid.columnField === mapped.columnField && valid.operatorValue === mapped.operatorValue && valid.value === mapped.value));
    if (!matches) {
      setDisplayedFilters({linkOperator, items: mappedItems});
    }
  };

  useWatch(() => {
    if (filters.items.length > 0) {
      mapFiltersToFilterModel(filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return [displayedFilters, handleFilterModelChange];
};
