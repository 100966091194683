import {Icon, IconButton, ListItemIcon, Menu, MenuItem, Typography} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Link as RouterLink} from 'react-router-dom';
import {
  deleteTax,
  setDefault
} from '../../actions/taxes';
import ConfirmDeleteDialog from '../../componentsOld/confirmDeleteDialog';

function TaxMenuCell({data, children}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMakeDefault = (e, id) => {
    dispatch(setDefault(id));
  };

  const openConfirmDelete = () => {
    setConfirmDelete(true);
    setAnchorEl(null);
  };

  const handleDeleteTax = async () => {
    await dispatch(deleteTax(data._id));
    setConfirmDelete(false);
  };

  return (
    <div key={data._id}>
      {children ?
        <div onClick={handleClick}> {children} </div> :
        <IconButton
          onClick={handleClick}
          variant={'outlined'}
          size={'small'}
        >
          <Icon>more_horiz</Icon>
        </IconButton>}
      <Menu
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {!children && <RouterLink to={`/settings/taxes/${data._id}/edit`} style={{textDecoration: 'none', outline: 'none', color: 'inherit'}}>
          <MenuItem>
            <ListItemIcon>
              <Icon>visibility</Icon>
            </ListItemIcon>
            View
          </MenuItem>
        </RouterLink>}
        <MenuItem disabled={data.isDefault} onClick={(e) => { handleClose(); handleMakeDefault(e, data._id); }}>
          <ListItemIcon style={{minWidth: '48px'}}>
            <CheckCircleIcon fontSize='small' />
          </ListItemIcon>
          <Typography variant='body2'>Set as Default</Typography>
        </MenuItem>
        <RouterLink to={`/settings/taxes/${data._id}/edit`} style={{textDecoration: 'none', outline: 'none', color: 'inherit'}}>
          <MenuItem>
            <ListItemIcon>
              <Icon>edit</Icon>
            </ListItemIcon>
            Edit
          </MenuItem>
        </RouterLink>

        <MenuItem disabled={data.isDefault} onClick={openConfirmDelete}>
          <ListItemIcon>
            <Icon>delete</Icon>
          </ListItemIcon>
          Delete
        </MenuItem>

      </Menu>
      <ConfirmDeleteDialog name={data.name} isOpen={confirmDelete} ok={handleDeleteTax} />
    </div>
  );
}

export default TaxMenuCell;
