// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import {Grid, withStyles} from '@material-ui/core';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {getQuote as _getQuote} from '../../../actions/quote';
import Loading from '../../shared/Loading';
import PreviewQuoteContent from '../PreviewQuote/PreviewQuoteContent';

const styles = (theme) => ({
  tabsOuterContainer: {borderBottom: `1px solid ${theme.palette.secondary.main}`},
  container: {[theme.breakpoints.down('md')]: {padding: theme.spacing(1.5)}}
});

class SingleQuoteView extends Component {
  state = {activeTab: 'view'}

  constructor(props) {
    super(props);
    this.state = {activeTab: props.location.pathname.split('/').pop()};
  }

  async componentDidMount() {
    document.title = 'View Quote | Hoops';

    const {match, getQuote} = this.props;

    getQuote(match.params.quoteId);
  }

  handleTabChange = (e, newActiveTab) => {
    const {history} = this.props;
    this.setState({activeTab: newActiveTab});
    history.push(newActiveTab);
  };

  render() {
    const {quoteLoading, classes, quote} = this.props;
    return (
      <>
        <Grid id='quotePdf' container justifyContent={'center'}>
          <Grid className={classes.container} md={12} lg={10} container item style={{maxWidth: '1200px'}}>
            {
              quoteLoading || !quote?._id ? <Loading text='Loading Quote' position={'absolute'}/> :
                <Grid container spacing='4'>
                  <Grid item xs={12}>
                    <PreviewQuoteContent renderSiblings={this.props.renderSiblings} tenantView={false} />
                  </Grid>
                </Grid>
            }
          </Grid>
        </Grid>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    quoteLoading: state.quoteReducer.previewLoading,
    quote: state.quoteReducer.quote
  };
}

function mapDispatchToProps(dispatch) {
  return {getQuote: (_id) => dispatch(_getQuote(_id)),};
}

SingleQuoteView = connect(mapStateToProps, mapDispatchToProps)(SingleQuoteView);
SingleQuoteView = withRouter(SingleQuoteView);
SingleQuoteView = withStyles(styles)(SingleQuoteView);
export default SingleQuoteView;

