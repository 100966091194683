import {useMemo} from 'react';
import {useSnackbar as _useSnackbar} from 'notistack';

export const GENERAL_ERROR_MESSAGE = 'Uh, oh. That didn\'t work. Please try again.';

export const useSnackbar = ({successMessage: defaultSuccessMessage, errorMessage: defaultErrorMessage} = {errorMessage: GENERAL_ERROR_MESSAGE}) => {
  const {enqueueSnackbar} = _useSnackbar();

  return useMemo(() => ({
    showSnackbarSuccess: (successMessage) => enqueueSnackbar(successMessage ?? defaultSuccessMessage, {variant: 'success'}),
    showSnackbarError: (errorMessage) => enqueueSnackbar(errorMessage ?? defaultErrorMessage, {variant: 'error'}),
  }), [defaultErrorMessage, defaultSuccessMessage, enqueueSnackbar]);
};
