// TODO: fix eslint disable
/* eslint-disable no-duplicate-imports */

import React from 'react';
import {IconButton, Menu, MenuItem, ListItemIcon} from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {deleteResource, getResource, setModal} from '../../actions/resource';

export const ResourceManyTableActions = ({resource}) => {
    const [anchorEl, setAnchorEl] = useState();
    const dispatch = useDispatch();

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        dispatch({type: 'SET_TASK', payload: resource}); //pre set resource then load detailed)
        dispatch(getResource(resource._id));
        dispatch(setModal(true));
        handleClose();
    };

    const handleDelete = () => {
        dispatch(deleteResource(resource._id));
        handleClose();
    };

    return (
        <>
            <IconButton
                variant={'outlined'}
                size={'small'}
                onClick={handleClick}>
                <MoreHorizIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                >
                <MenuItem onClick={handleEdit}>
                    <ListItemIcon>
                        <EditIcon />
                    </ListItemIcon>
                    Edit
                </MenuItem>
                <MenuItem onClick={handleDelete}>
                    <ListItemIcon>
                        <DeleteIcon />
                    </ListItemIcon>
                    Delete
                </MenuItem>
            </Menu>
        </>
    );
};

export default ResourceManyTableActions;
