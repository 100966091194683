// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React from 'react';
import {withStyles, DialogActions as MuiDialogActions} from '@material-ui/core';
import classNames from 'classnames';

const overrides = (theme) => ({actionPadding: {padding: theme.spacing(2)}});

class DialogActions extends React.Component {
  render() {
    const {classes, className, children, ...other} = this.props;

    return (
      <MuiDialogActions className={classNames(classes.actionPadding, className)} {...other}>
        {children}
      </MuiDialogActions>
    );
  }
}

DialogActions = withStyles(overrides)(DialogActions);

export default DialogActions;
