// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import {TableHead, TableRow} from '@material-ui/core';
import React from 'react';
import {HoopsTableCell, isSelectable, useHoopsJobTableStyles} from '../HoopsTable';

export const JobItemTableItemHeader = ({
  jobItem = {},
  mode = 'checklist'
}) => {
  const classes = useHoopsJobTableStyles();

  return (
    <TableHead>
      <TableRow>
        {isSelectable(mode) ? <HoopsTableCell className={classes.checkBoxColumn}>
          Select
        </HoopsTableCell> : null}
        <HoopsTableCell className={classes.tableHeader} colSpan={5}>
          Description
        </HoopsTableCell>
        <HoopsTableCell className={classes.quantityColumn}>
          Total Quantity
        </HoopsTableCell>
        <HoopsTableCell className={classes.statusColumn}>
          Status
        </HoopsTableCell>
      </TableRow>
    </TableHead>
  );
};

export default JobItemTableItemHeader;
