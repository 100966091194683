// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import {Grid, makeStyles, Paper, Table, TableContainer, Typography} from '@material-ui/core';
import React, {useRef} from 'react';
import {GQL_CUSTOMER_PAGINATION} from '../../queries/customers';
import {colors} from '../../ui/theme';
import {hoopsQueryStyles} from '../../ui/theme/hoops';
import {HoopsQuery, HoopsQueryContext, HoopsQueryEmptyState, HoopsQueryFilterChips, HoopsQueryFilterDropMenu, HoopsQueryFilterSearch, HoopsQueryFilterUser, HoopsQueryPagination, HoopsQueryTableBody, HoopsQueryTableHeader} from '../HoopsQuery';
import NameWithInitials from '../NameWithInitials';
import CustomerContactCell from './customerContactCell';
import CustomerMenuCell from './customerMenuCell';

const useStyles = makeStyles((theme) => ({...hoopsQueryStyles,}));

const columns = [{
  label: 'Name',
  field: 'name',
  sortable: true,
  sortKey: 'NAME',
  render: (rowData) => <NameWithInitials key={rowData._id} name={rowData.name} color={colors.orange} to={`/customers/${rowData._id}/details`} />
}, {
  label: 'Contacts',
  field: 'contacts[0].fullName',
  render: (rowData) => {
    if (rowData.contacts) {
      return (<CustomerContactCell rowData={rowData} />);
    }
    return (<div>
      <Grid item>
        <Typography variant={'body2'}>No stored contacts</Typography>
      </Grid>
    </div>);
  }
}, {
  label: 'Email',
  field: 'email',
}, {
  label: 'Phone',
  field: 'phone',
}, {
  label: 'Customer Rep',
  field: 'customerRep.fullName',
}, {
  label: 'Actions',
  render: (rowData) => <CustomerMenuCell rowData={rowData} />
}];

const filterListMembers = [{
  key: 'customerRepId',
  type: 'array',
  options: [],
  label: 'Customer Rep',
  component: (props) => <HoopsQueryFilterUser {...props} />
}];

export const CustomerTable = ({openCreateCustomer = () => null,}) => {
  const classes = useStyles();

  const tableContainerElement = useRef();

  return (
    <HoopsQuery refetchStateId='customer' queryStateId='customerMany' columns={columns} gqlQuery={GQL_CUSTOMER_PAGINATION} resultField='customerPagination' initialFilter={{}} initialSort={'NAME_ASC'}>
      <HoopsQueryContext.Consumer>
        {({items, sort, setSort, filter, setFilter, paging, setPaging, chips, setChips, hoopsQueryColumns, setHoopsQueryColumns}) => (<>

            <Grid container direction='row' justifyContent='space-between' alignItems='flex-start'>
              <Grid item>
                <HoopsQueryFilterDropMenu
                  label={'Customer Rep'}
                  filter={filter}
                  filterComponents={filterListMembers}
                  onFilterChange={setFilter}
                  chips={chips}
                            onChipsChange={setChips}
                />
              </Grid>
              <Grid item>
                <HoopsQueryFilterSearch onChange={setFilter} filter={filter} placeholder={'Search'} chips={chips} onChipsChange={setChips} />
              </Grid>

            </Grid>
            <HoopsQueryFilterChips filter={filter} onFilterChange={setFilter} chips={chips} onChipsChange={setChips} />
            <TableContainer component={Paper} className={classes.tableContainer} ref={tableContainerElement}>
                <Table className={classes.table}>
                <HoopsQueryTableHeader hasCheckBox={false} columns={hoopsQueryColumns} onSort={setSort} sortString={sort} />
                <HoopsQueryTableBody rowsPerPage={paging.perPage} rows={items} columns={hoopsQueryColumns} emptyStateComponent={() => <HoopsQueryEmptyState filter={filter} columns={hoopsQueryColumns} field={'Customer'} rowsPerPage={paging.perPage} onClearFilter={setFilter} onChipsChange={setChips} onAddItem={openCreateCustomer} tableContainerElement={tableContainerElement}/>}  />
              </Table>
            </TableContainer>
            <HoopsQueryPagination paging={paging} onPaging={setPaging} />
          </>)}
      </HoopsQueryContext.Consumer>
    </HoopsQuery>
  );
};

export default CustomerTable;
