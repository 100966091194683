import {Button, Typography, Avatar, Grid, Paper} from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {makeStyles} from '@material-ui/core/styles';
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    avatar: {
        backgroundColor: 'White',
        border: '2px solid',
        borderColor: theme.colors.red,
        width: '48px',
        height: '48px',
        marginBottom: theme.spacing(1),
    },
    icon: {
        color: theme.colors.red,
        fontSize: '32px'
    },
    button: {marginTop: theme.spacing(3),}

}));

export const AccessDenied = () => {
    const classes = useStyles();
    const history = useHistory();

    return (
        <Paper style={{paddingTop: '104px', paddingBottom: '104px'}}>
            <Grid
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
                spacing={1}
                style={{height: '100%'}}
            >
                <Grid item>
                    <Avatar className={classes.avatar} variant='outlined'><CloseIcon className={classes.icon} /></Avatar>
                </Grid>
                <Grid item>
                    <Typography variant='h5' display='block' align='center'>Access Denied</Typography>
                    <Typography variant='body1' display='block' align='center'>{'You don\'t have permissions to access this page.'}</Typography>
                </Grid>
                <Grid item>
                    <Button variant={'contained'} color={'primary'} disableElevation className={classes.button} onClick={() => history.goBack()}>Go Back</Button>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default AccessDenied;
