import {gql} from 'apollo-boost';

const automationFragment = `
_id
name
isDisabled
entityType
trigger
config{
    _id
    action
    via
    body
    subject
    howLong
    updateFieldPath
    updateFieldValue
    status{
      label
      value
    }
    statusType
    recipients {
        _id
        type
        label
    }
}
`;
export const GQL_AUTOMATION_CREATE = gql`
  mutation AutomationCreateOne($record: CreateOneAutomationInput!) {
    automationCreateOne(record: $record) {
      recordId
      record {
        ${automationFragment}
      }
    }
  }
`;

export const GQL_AUTOMATION_UPDATE = gql`
  mutation AutomationUpdateById(
    $_id: MongoID!
    $record: UpdateByIdAutomationInput!
  ) {
    automationUpdateById(_id: $_id, record: $record) {
      recordId
      record {
          ${automationFragment}
      }
    }
  }
`;

export const GQL_AUTOMATION_DELETE = gql`
  mutation AutomationRemoveById($_id: MongoID!) {
    automationRemoveById(_id: $_id) {
      recordId
    }
  }
`;

export const GQL_AUTOMATION_GET_BY_ID = gql`
  query AutomationById($_id: MongoID!) {
    automationById(_id: $_id) {
        ${automationFragment}
    }
  }
`;

export const GQL_AUTOMATION_GET_MANY = gql`
  query AutomationMany(
    $filter: FilterFindManySearchAutomationInput
    $sort: SortFindManyAutomationInput
    $limit: Int
    $skip: Int
  ) {
    automationMany(filter: $filter, sort: $sort, limit: $limit, skip: $skip) {
        ${automationFragment}
    }
  }
`;

export const GQL_AUTOMATION_PAGINATION = gql`
  query AutomationPagination(
    $filter: FilterFindManySearchAutomationInput
    $sort: SortFindManyAutomationInput
    $perPage: Int
    $page: Int
  ) {
    automationPagination(
      filter: $filter
      sort: $sort
      perPage: $perPage
      page: $page
    ) {
      items {
          ${automationFragment}
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
