import {
  SET_CUSTOMERS,
  SET_CUSTOMER,
  CLEAR_CUSTOMER,
  SET_FORM_CUSTOMER,
  SET_CUSTOMERS_LOADING,
} from '../actions/action-types';

export const initialState = {
  list: [],
  customersLoading: false,
  customer: {
    type: 'COMPANY',
    addresses: [],
    contacts: [{}],
    settings: {}
  },
  formCustomer: {
    type: 'COMPANY',
    addresses: [],
    contacts: [{}],
    settings: {}
  },
};

const customerReducer = (state = {...initialState}, action) => {
    switch (action.type) {
      case SET_CUSTOMERS:
        return {
          ...state,
          list: action.payload
        };
      case SET_CUSTOMER:
        return {
          ...state,
          customer: {
            ...action.payload,
            addresses: (action.payload.addresses || []),
            contacts: (action.payload.contacts || [])
          }
        };

      case SET_FORM_CUSTOMER:
        return {
          ...state,
          formCustomer: {
            ...state.formCustomer,
            ...action.payload
          }
        };
      case CLEAR_CUSTOMER:
        return {
          ...state,
          formCustomer: initialState.formCustomer
        };

      case SET_CUSTOMERS_LOADING:
        return {
          ...state,
          customersLoading: action.payload
        };
      default:
        return state;
    }
  }
;

export default customerReducer;
