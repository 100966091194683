import {Link} from '@mui/material';
import * as React from 'react';
import {Link as RouterLink} from 'react-router-dom';

export const LinkCell = ({value, href, to}) => {
  if (to) {
    return <Link underline={'none'} color={'primary'} component={RouterLink} to={to}>{value}</Link>;
  } else {
    return <Link underline={'none'} color={'primary'} target='_blank' href={href}>{value}</Link>;
  }
};
