import React, {Fragment} from 'react';
import {Route, Switch} from 'react-router-dom';
import PurchaseOrderEditPage from '../../componentsOld/purchase-orders/PurchaseOrderEditPage';
import PurchaseOrderManyViewPage from '../../componentsOld/purchase-orders/PurchaseOrderManyViewPage';
import {PurchaseOrder} from '../../componentsOld/purchase-orders/SinglePurchaseOrder/PurchaseOrder';

class PurchaseOrdersContainer extends React.Component {

  componentDidMount() {
    document.title = 'Purchase Orders | Hoops';
  }

  render() {
    return (
      <Fragment>
        <Switch>
          <Route path='/purchase-orders' exact component={PurchaseOrderManyViewPage}/>
          <Route path='/purchase-orders/new' exact component={PurchaseOrderEditPage}/>
          <Route path='/purchase-orders/:orderId/view' component={PurchaseOrder} />
          <Route path='/purchase-orders/:orderId/edit' component={PurchaseOrderEditPage} />
        </Switch>
      </Fragment>
    );
  }
}

export default PurchaseOrdersContainer;
