import Grid from '@material-ui/core/Grid';
import {MuiThemeProvider, Typography} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import {Help} from '@material-ui/icons';
import React from 'react';
import Link from '@material-ui/core/Link';

function LabelledInput({label, chipTheme, chipLabel, helpLink, children}) {
  return (
      <Grid item container direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={2}>
        <Grid container direction={'row'} item xs={3}>
          <Typography>{label}</Typography>
          {helpLink &&
          <Link href={helpLink} target={'_blank'} style={{paddingLeft: 4}}>
            <Help fontSize={'small'} color={'primary'} underline={'none'}/>
          </Link>
          }
        </Grid>
        <Grid item style={{flexGrow: 1, flexBasis: 0}}>
          {children}
        </Grid>
        <Grid item>
          <MuiThemeProvider theme={chipTheme}>
            <Chip label={chipLabel} size={'small'} color={'primary'}/>
          </MuiThemeProvider>
        </Grid>
      </Grid>
  );
}

export default LabelledInput;
