import {gql, useLazyQuery} from '@apollo/client';
import {CircularProgress, Link} from '@material-ui/core';
import React from 'react';
import theme from '../../ui/theme';

const GET_STIPE_INVOICE = gql`
    query($_id:String!){
        quote(_id: $_id){
            stripeInvoiceLink
        }
    }
`;

const StripeInvoiceBtn = ({quoteId = null}) => {

    const [getInvoiceUrl, {loading}] = useLazyQuery(GET_STIPE_INVOICE, {
        fetchPolicy: 'no-cache',
        variables: {_id: quoteId},
        onError: (error) => {
            console.error(error);
        },
        onCompleted: (result) => {
            if (result.quote) {
                window.location.assign(result.quote.stripeInvoiceLink, '_blank').focus();
            }
        },
    });

    const handleInvoiceUrl = (e) => {
        e.preventDefault();
        getInvoiceUrl();
    };

    return (

        <Link underline={'none'} disabled={loading} color={'primary'} onClick={handleInvoiceUrl} href={'#'} style={{width: 100, display: 'flex', justifyContent: 'center'}}>
            {loading ? <><CircularProgress size={16} style={{marginRight: theme.spacing(1)}} />View Invoice</> : <>View Invoice</>}
        </Link >

    );
};

export default StripeInvoiceBtn;
