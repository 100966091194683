// TODO: fix eslint disable
/* eslint-disable no-shadow */

import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {Field} from 'react-final-form';
import MenuItem from '@material-ui/core/MenuItem';
import {makeStyles, MuiThemeProvider} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import {Select} from '@kestreltech/final-form-material-ui';
import {Help} from '@material-ui/icons';
import Link from '@material-ui/core/Link';

function RenderSample({field, formValues}) {
  const sampleData = formValues?.file?.sampleData;
  const [content, setContent] = useState('');
  useEffect(() => {
    const GROUPING_FIELDS = [
      'sku', 'colors', 'size'
    ];

    let sample = GROUPING_FIELDS.includes(field) ? sampleData?.[formValues.fieldMapping?.[field]] : sampleData?.[formValues.fieldMapping?.[field]];
    setContent((sample ?? '').split('|').join(', '));
  }, [sampleData, formValues, field]);

  return (
    <Typography variant={'body2'} noWrap>
      {content}
    </Typography>
  );
}

const styles = () => ({
  fieldLabelWrapper: {textAlign: 'right',},
  fieldLabel: {fontWeight: 500}
});

export function HoopsFieldMappingRow({field, fieldName, formValues, index}) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <>
      <Grid item container alignItems={'center'} spacing={2} wrap={'nowrap'}>
        <Grid item xs={3}>
          <Grid container justifyContent={'space-between'} spacing={1}>
            <Grid item xs={true} className={classes.fieldLabelWrapper}>
              <Typography variant={'body2'} className={classes.fieldLabel}>
                {field.label} {Boolean(index + 1) && (index + 1)}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {field.help && <>
                <Link href={field.help} target={'_blank'}>
                  <Help fontSize={'small'} color={'primary'} underline={'none'}/>
                </Link>
              </>}
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={4}>
          <Grid container alignItems={'center'} spacing={1} wrap={'nowrap'}>
            <Grid item xs>
              <Field
                name={fieldName}
                fullWidth
                variant={'outlined'}
                validate={field.validate}
                formControlProps={{
                  fullWidth: true,
                  margin: 'dense',
                  size: 'small',
                  variant: 'outlined',
                }}
                component={Select}
              >
                <MenuItem key={'empty'} value=''>
                  <i>No mapping</i>
                </MenuItem>
                {formValues?.file?.headerRow && formValues?.file?.headerRow.map((field) => <MenuItem key={field} value={field}>{field}</MenuItem>)}
              </Field>
            </Grid>
            <Grid item style={{width: 110}}>
              {field.chip && <MuiThemeProvider theme={field.chip.theme}>
                <Chip label={field.chip.text} size={'small'} color={'primary'}/>
              </MuiThemeProvider>}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs zeroMinWidth>
          <RenderSample formValues={formValues} field={field.name}/>
        </Grid>
      </Grid>
    </>
  );
}

export default HoopsFieldMappingRow;
