// TODO: fix eslint disable
/* eslint-disable consistent-return, no-shadow */

import {useLazyQuery} from '@apollo/client';
import {Divider, InputAdornment, MenuItem, TableRow, TextField} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import {Cancel, Delete} from '@material-ui/icons';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {Select} from 'final-form-material-ui';
import {find, get, uniqBy} from 'lodash';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {change, Field, formValueSelector, getFormMeta} from 'redux-form';
import {HoopsTextField} from '../../../HoopsTextField';
import {useMountEffect} from '../../../../hooks';
import {GQL_GET_PRODUCT} from '../../../../queries/product';
import {renderTextField as RenderTextField, renderTextField} from '../../formHelpers/fields';
import {ItemBuilderProductField} from '../JobVariationsModal/ItemBuilderProductField';
import {getPrice} from '../VariantsTable';
import {sortSizes} from '../../../../utils/sortSizes';

function SelectField({children, ...params}) {
  return (
    <Select
      formControlProps={{
        fullWidth: true,
        variant: 'outlined',
        margin: 'dense',
      }}
      SelectDisplayProps={{fullWidth: true}}
      input={params.input}
      meta={params.meta}
    >
      {children}
    </Select>
  );
}

function BuyPriceField({input: {onChange, value}, meta, meta: {form}, field, input, product, ...params}) {
  const dispatch = useDispatch();
  const changer = useCallback((f, value) => dispatch(change(form, f, value)), [dispatch, form]);

  const quantitySelector = (state) => selector(state, `${field}.quantity`);
  const variantSelector = (state) => selector(state, `${field}.productVariant`);
  const colorSelector = (state) => selector(state, `${field}.colour`);
  const variantsSelector = (state) => selector(state, 'variants');
  const jobItemVariantIdSelector = (state) => selector(state, `${field}.jobItemVariantId`);
  const isCustomBuyPriceSelector = (state) => selector(state, `${field}.isCustomBuyPrice`);
  const variantMetaSelector = (state) => formMetaSelector(state);

  const quantity = useSelector(quantitySelector);
  const variant = useSelector(variantSelector);
  const color = useSelector(colorSelector);
  const variants = useSelector(variantsSelector);
  const jobItemVariantId = useSelector(jobItemVariantIdSelector);
  const isCustomBuyPrice = useSelector(isCustomBuyPriceSelector);
  const variantMeta = useSelector(variantMetaSelector);

  const totalQuantity = variants.reduce((agg, variant) => agg += parseInt(get(variant, 'quantity', 0)), 0);

  const isRowTouched = get(variantMeta, `${field}.quantity.touched`, false)
    || get(variantMeta, `${field}.productVariant._id.touched`, false)
    || get(variantMeta, `${field}.colour.touched`, false)
    || meta.dirty;

  useEffect(() => {
    if (!isRowTouched || isCustomBuyPrice) {
      return;
    }

    let priceBreaks = [];
    if (jobItemVariantId) {
      return;
    }

    if (totalQuantity && variant) {
      priceBreaks = get(
        find(variants, {_id: get(variant, '_id', '')}),
        'priceBreaks',
        []);
    } else if (totalQuantity) {
      priceBreaks = product?.defaultPriceBreaks ?? [];
    }
    if (priceBreaks.length) {
      const newPrice = getPrice(priceBreaks, totalQuantity);
      if (input.value !== newPrice) {
        onChange(newPrice);
      }
    }
  }, [totalQuantity, variant, color, isCustomBuyPrice, isRowTouched, jobItemVariantId, onChange, product, variants, input?.value, params]);

  useEffect(() => {
    let newIsCustomBuyPrice = false;

    let priceBreaks = [];
    const variants = product?.variants ?? [];
    if (quantity && variant) {
      priceBreaks = get(
        find(variants, {_id: get(variant, '_id', '')}),
        'priceBreaks',
        []);
    } else if (quantity) {
      priceBreaks = product?.defaultPriceBreaks ?? [];
    }
    if (priceBreaks.length) {
      const price = parseFloat(getPrice(priceBreaks, quantity));
      newIsCustomBuyPrice = price !== parseFloat(value);
      if (value === '') {
        changer(`${field}.rate`, price);
      }
    }

    if (quantity && isCustomBuyPrice !== newIsCustomBuyPrice) {
      changer(`${field}.isCustomBuyPrice`, newIsCustomBuyPrice);
    }
  }, [value, changer, field, product, quantity, variant, isCustomBuyPrice]);

  return (<RenderTextField currency meta {...input} {...params} isBold={isCustomBuyPrice} />);
}

const formName = 'purchaseOrderVariantsForm';
const selector = formValueSelector(formName);
const formMetaSelector = getFormMeta(formName);

function SizeField({product, field, classes, ...params}) {
  const dispatch = useDispatch();
  const [variants, setVariants] = useState([]);

  const colorSelector = (state) => selector(state, `${field}.colour`);
  const sizeSelector = (state) => selector(state, `${field}.size`);
  const variantSelector = (state) => selector(state, `${field}.productVariant`);

  const color = useSelector(colorSelector);
  const size = useSelector(sizeSelector);
  const variant = useSelector(variantSelector);
  const isCustomBuyPrice = useSelector((state) => selector(state, `${field}.isCustomBuyPrice`));

  const [isCustom, setIsCustom] = useState(false);

  const handleCustomTrue = () => {
    dispatch(change(params.meta.form, params.input.name, ''));
    setIsCustom(true);
  };

  const handleSelect = useCallback((event, ...args) => {
    if (!isCustomBuyPrice) {
      dispatch(change(params.meta.form, `${field}.rate`, ''));
    }
    if (params.input?.onChange) {
      params.input.onChange(event, ...args);
    }
  }, [params.input, dispatch, field, isCustomBuyPrice, params?.meta?.form]);

  useEffect(() => {
    if (color) {
      const variants = product?.variants ?? [];
      const availableVariants = variants.filter((v) => (v.color.name === color));

      // filter selected variant (size) by the defaultvalue set for the field
      const cleanedVariantOptions = uniqBy(availableVariants, 'size.name');
      const filterVariants = variant && cleanedVariantOptions.filter((v) => v._id === variant._id);

      setIsCustom((prev) => {
        if (!prev && !size) {return false;}
        if (prev) {return !filterVariants?.length > 0;}
      });

      if (variant && filterVariants.length === 0) {
        setIsCustom((prev) => {
          if (!prev) {
            dispatch(change(params.meta.form, params.input.name, variant._id));
          }
          return true;
        });
      }

    } else {
      // if there is a size set but no color it has to be custom
      if (size && size.length > 0) {
        setIsCustom(true);
      }
    }
  }, [color, dispatch, params?.input?.name, params?.meta?.form, product, size, variant, params.input?.value]);

  useEffect(() => {
    if (color) {
      const variants = product?.variants ?? [];
      const availableVariants = sortSizes(variants.filter((v) => (v.color.name === color)),'size.name');
      setVariants(uniqBy(availableVariants, 'size.name'));
    }

    if (!variant) {
      dispatch(change(params.meta.form, params.input.name, size));
    }
  }, [color, variant, size, dispatch, params?.input?.name, params?.meta?.form, product]);

  if (product && product.sizes) {
    return (
      <>
        {isCustom
          ? <HoopsTextField
            className={classes.textFieldWithAdornmentBtn}
            defaultValue={size}
            placeholder='Enter custom size'
            input={params.input}
            meta={params.meta}
            InputProps={{
              endAdornment:
                <InputAdornment position={'end'}>
                  <IconButton onClick={() => setIsCustom(false)}>
                    <Cancel fontSize='small' className={classes.greyText} />
                  </IconButton>
                </InputAdornment>
            }}
          />
          : <SelectField {...params} input={{...params.input, onChange: handleSelect}}>
            {variants.map((o) => (
              <MenuItem key={o._id} value={o._id}>{o.size.name}</MenuItem>
            ))}
            <Divider light style={{marginBottom: 8}} />
            <MenuItem onClick={handleCustomTrue}>Use custom size</MenuItem>
          </SelectField>
        }
      </>
    );
  }

  return (
    <RenderTextField {...params} />
  );
}

function ColorField({product, classes, ...params}) {
  const dispatch = useDispatch();
  const [isCustom, setIsCustom] = useState(false);
  const productVariant = useSelector((state) => selector(state, `${params.field}.productVariant`));
  const isCustomBuyPrice = useSelector((state) => selector(state, `${params.field}.isCustomBuyPrice`));

  useEffect(() => {
    if (params && params.input && params.input.value.length > 0 && product._id) {
      setIsCustom(!product.colors.filter((element) => element.name === params.input.value).length > 0);
    }
  }, [params, product]);

  const handleSelect = useCallback((event, ...args) => {
    // The color has changed, check if the new color has a variant with the same size and use it
    const variant = product.variants.find((v) => v._id === productVariant?._id);
    const newVariant = variant && product.variants.filter((v) => v.color.name === event?.target?.value).find((v) => v.size?.name === variant.size?.name);
    dispatch(change(params.meta.form, `${params.field}.productVariant`, newVariant));
    if (!isCustomBuyPrice) {
      dispatch(change(params.meta.form, `${params.field}.rate`, ''));
    }
    if (params.input?.onChange) {
      params.input.onChange(event, ...args);
    }
  }, [params.input, dispatch, isCustomBuyPrice, params.field, params.meta.form, product?.variants, productVariant?._id]);

  if (product?._id) {
    return (
      <>
        {isCustom
          ? <HoopsTextField
            className={classes.textFieldWithAdornmentBtn}
            defaultValue={params.input.value}
            placeholder='Enter custom color'
            input={params.input}
            meta={params.meta}
            InputProps={{
              endAdornment:
                <InputAdornment position={'end'}>
                  <IconButton onClick={() => setIsCustom(false)}>
                    <Cancel fontSize='small' className={classes.greyText} />
                  </IconButton>
                </InputAdornment>
            }}
          />
          :
          <Select
            formControlProps={{
              fullWidth: true,
              variant: 'outlined',
              margin: 'dense',
            }}
            SelectDisplayProps={{fullWidth: true}}
            {...params}
            input={{...params.input, onChange: handleSelect}}
            meta={params.meta}
          >
            {product.colors.map((o) => (
              <MenuItem key={o._id} value={o.name}>{o.name}</MenuItem>
            ))}
            <Divider light style={{marginBottom: 8}} />
            <MenuItem onClick={() => setIsCustom(true)}>Use custom color</MenuItem>
          </Select>
        }
      </>
    );
  }
  return <RenderTextField {...params} />;
}

export const ProductsTableRow = ({
  locked,
  meta,
  field,
  rowIdx,
  classes,
  formValues,
  currencySymbol,
  calculateTotal = () => null,
  handleDeleteRowClick = () => null,
  handleDuplicateRowClick = () => null,
}) => {

  const dispatch = useDispatch();

  const rowFields = formValues?.variants[rowIdx];

  const [product, setProduct] = useState({title: ''});
  const [productType, setProductType] = useState(() => ((!rowFields.productId && rowFields.productName) ? 'oneOff' : 'hoops'));

  const [getProductData] = useLazyQuery(GQL_GET_PRODUCT, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (result) => {
      if (result.product) {
        dispatch(change(meta.form, `variants[${rowIdx}].product`, result.product));
        dispatch(change(meta.form, `variants[${rowIdx}].productCode`, result.product.code));
        dispatch(change(meta.form, `variants[${rowIdx}].productName`, result.product.title));
        dispatch(change(meta.form, `variants[${rowIdx}].productId`, result.product._id));
        dispatch(change(meta.form, `variants[${rowIdx}].vendor`, result.product.vendor.name));
        dispatch(change(meta.form, `variants[${rowIdx}].vendorId`, result.product.vendor._id));
        setProduct(result.product);
      }
    },
    onError(error) {
      console.error(error);
    },
  });

  const handleClearRowFields = useCallback(() => {
    dispatch(change(meta.form, `variants[${rowIdx}].product`, null));
    dispatch(change(meta.form, `variants[${rowIdx}].productCode`, ''));
    dispatch(change(meta.form, `variants[${rowIdx}].productName`, null));
    dispatch(change(meta.form, `variants[${rowIdx}].productId`, null));
    dispatch(change(meta.form, `variants[${rowIdx}].vendor`, null));
    dispatch(change(meta.form, `variants[${rowIdx}].vendorId`, null));
    dispatch(change(meta.form, `variants[${rowIdx}].productVariant._id`, null));
    dispatch(change(meta.form, `variants[${rowIdx}].size`, null));
    dispatch(change(meta.form, `variants[${rowIdx}].colour`, null));
  }, [dispatch, meta.form, rowIdx,]);

  const handleProductTypeChange = useCallback((value) => {
    handleClearRowFields();
    setProductType(value);
    setProduct({title: ''});
  }, [handleClearRowFields]);

  const handleProductSelected = useCallback((product) => {
    handleClearRowFields();
    getProductData({variables: {_id: product._id}});
  }, [getProductData, handleClearRowFields]);

  const initLoad = useCallback(() => {
    rowFields.productId && getProductData({variables: {_id: rowFields.productId}});
  }, [rowFields.productId, getProductData]);

  useMountEffect(() => {
    initLoad();
  }, [initLoad]);

  return (
    <TableRow key={rowIdx}>
      <ItemBuilderProductField
        rowFields={rowFields}
        rowIdx={rowIdx}
        field={field}
        productType={productType}
        handleProductTypeChange={handleProductTypeChange}
        handleProductSelected={handleProductSelected}
        margin={'dense'}
      />
       <TableCell className={classes.tablePadding}>
        <Field name={`${field}.productCode`}
          component={renderTextField}
          variant={'outlined'}
          disabled={Boolean(get(product, '_id', ''))}
          margin={'dense'} />
      </TableCell>
      <TableCell className={classes.tablePadding}>
        <Field name={`${field}.quantity`}
          component={renderTextField}
          variant={'outlined'}
          margin={'dense'}
          disabled={locked}
          InputProps={{
            type: 'number',
            step: 1
          }} />
      </TableCell>
      <TableCell className={classes.tablePadding}>
        <Field name={`${field}.colour`}
          component={ColorField}
          product={product}
          field={field}
          classes={classes}
          variant={'outlined'}
          margin={'dense'} />
      </TableCell>
      <TableCell className={classes.tablePadding}>
        <Field name={`${field}.productVariant._id`}
          product={product}
          field={field}
          component={SizeField}
          classes={classes}
          variant={'outlined'}
          margin={'dense'} />
      </TableCell>
      <TableCell className={classes.tablePadding}>
        <Field name={`${field}.rate`}
          component={BuyPriceField}
          product={product}
          field={field}
          variant={'outlined'}
          margin={'dense'} />
      </TableCell>
      <TableCell className={classes.tablePadding}>
        <TextField
          variant='outlined'
          margin='dense'
          disabled={true}
          InputProps={{
            value: calculateTotal(rowIdx),
            startAdornment: <InputAdornment position='start'>{currencySymbol}</InputAdornment>
          }}
        />
      </TableCell>
      <TableCell className={classes.tablePadding}>
        <Grid container wrap='nowrap'>
          <Grid item>
            <IconButton onClick={() => handleDuplicateRowClick(field)}>
              <FileCopyIcon color={'secondary'} />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton onClick={() => handleDeleteRowClick(rowIdx)}>
              <Delete color={'secondary'} />
            </IconButton>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};
