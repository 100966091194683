// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-shadow */

import {useLazyQuery} from '@apollo/client';
import {CircularProgress, Paper, Popper, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Autocomplete} from '@material-ui/lab';
import React, {useState} from 'react';
import {HoopsTextField} from '../../HoopsTextField';
import {useDebounce, useMountEffect, useWatch} from '../../../hooks';
import {GQL_GET_CONFIGURED_PRODUCTS} from '../../../queries/configuredProducts';
import ConfiguredProductAutocompleteRenderOption from './ConfiguredProductAutocompleteRenderOption';
import productAutocompleteStyles from './productAutocompleteStyles';

const useStyles = makeStyles((theme) => ({...productAutocompleteStyles,}));

export const PreBuiltItemAutocomplete = ({
  onSelect = () => null,
  getPreBuiltItemLength = () => null
}) => {
  const [value, setValue] = useState();
  const [inputValue, setInputValue] = useState('');
  const classes = useStyles();

  const [getConfiguredProducts, {loading, data}] = useLazyQuery(GQL_GET_CONFIGURED_PRODUCTS, {
    fetchPolicy: 'cache-and-network',
    variables: {s: inputValue}
  });

  const getProductsDebouncer =
    useDebounce((value) => {
      getConfiguredProducts({s: value,});
    }, [getConfiguredProducts], 3000);

  useMountEffect(() => {
    getConfiguredProducts({s: value,});
  }, []);

  useWatch(() => {
    data && getPreBuiltItemLength(data.configuredProducts.length);
  }, [data, getPreBuiltItemLength]);

  return (
    <Autocomplete
      onChange={(e, newValue, reason) => {
        if (reason === 'reset') {
          setInputValue('');
          return;
        } else {
          setInputValue(newValue && newValue.name);
          setValue(newValue);
          onSelect(newValue);
        }
      }}
      value={value}
      inputValue={inputValue}
      fullWidth
      onInputChange={(e, newInputValue, reason) => {
        if (reason === 'reset') {
          setInputValue('');
          return;
        } else {
          setInputValue(newInputValue);
          getProductsDebouncer(newInputValue);
        }
      }}

      options={data ? data.configuredProducts : []}
      PopperComponent={({children, ...params}) => (
        <Popper
          {...params}
          placement={'bottom-start'}
          style={{width: 'auto'}}
        >
          {children}
        </Popper>
      )}
      PaperComponent={({children, ...paperProps}) => (
        <Paper {...paperProps}>
          <div className={classes.searchFeedbackPanel} >
            <Typography variant='body2'>{loading ? 'Searching  ' : 'Showing results for '}<strong>{inputValue}</strong></Typography>
            {loading && <CircularProgress size={20} />}
          </div>
          {children}
        </Paper>
      )}
      renderOption={(option) => ConfiguredProductAutocompleteRenderOption(option, classes)}
      getOptionLabel={(option) => option.name}

      id='prebuilt-item-search'
      noOptionsText={'No results'}

      filterOptions={(options) => options}
      getOptionSelected={
        (option, value) => (
          option._id === value._id
        )}

      renderInput={(params) => <HoopsTextField {...params} />}
    />
  );
};
