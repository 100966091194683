import {useEffect, useRef} from 'react';

export function useWatchEffect(callback, deps) {
  const depsRef = useRef([]);

  const prevDeps = depsRef.current;
  depsRef.current = deps;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => callback(prevDeps), deps);
}
