import {Grid, Typography} from '@material-ui/core';
import React from 'react';
import SalesPerCustomerTable from './SalesPerCustomerTable';
import SalesPerUserTable from './SalesPerUserTable';
import SalesPerQuoteOwnerTable from './SalesPerQuoteOwnerTable';
import SalesReportSummaryTable from './SalesReportSummaryTable';

const SalesHeader = ({reportSettings}) => {
    switch (reportSettings.grouping) {
        default:
        case 'salesPerCustomer':
            return (
                <>
                    <Typography variant={'h5'}>Sales Report - Sales Per Customer</Typography>
                    <Typography>This report tallies the value for all quotes that were converted to jobs within the selected date range, grouped by customer.</Typography>
                </>
            );
        case 'salesPerUser':
            return (
                <>
                    <Typography variant={'h5'}>Sales Report - Sales Per User</Typography>
                    <Typography>This report tallies the value for all quotes that were converted to jobs within the selected date range, grouped by user.</Typography>
                </>
            );
        case 'salesPerQuoteOwner':
            return (
                <>
                    <Typography variant={'h5'}>Sales Report - Sales Per Quote Owner</Typography>
                    <Typography>This report tallies the value for all quotes that were converted to jobs within the selected date range, grouped by quote owner.</Typography>
                </>
            );
    }
};

const SalesDetailsTable = ({reportSettings}) => {
    switch (reportSettings.grouping) {
        default:
        case 'quotesPerCustomer':
        case 'salesPerCustomer':
            return <SalesPerCustomerTable />;
        case 'quotesPerUser':
        case 'salesPerUser':
            return <SalesPerUserTable />;
        case 'salesPerQuoteOwner':
            return <SalesPerQuoteOwnerTable />;
    }
};

function SalesReport({currencySymbol, reportSettings, totals = {decorationSales: 0, inventorySales: 0, totalSales: 0}}) {
    return (<>
        <Grid item xs={12}>
            <SalesHeader reportSettings={reportSettings} />
        </Grid>
        <Grid item xs={12}>
            <br/>
            <Typography align={'center'} variant={'h6'}>Summary</Typography>
        </Grid>
        <Grid item xs={12}>
            <SalesReportSummaryTable {...{currencySymbol, totals}} reportSettings={reportSettings} />
        </Grid>
        <Grid item xs={12}>
            <br/>
            <Typography align={'center'} variant={'h6'}>Details</Typography>
        </Grid>
        <Grid item xs={12}>
            <SalesDetailsTable reportSettings={reportSettings} />
        </Grid></>
    );
}

export default SalesReport;
