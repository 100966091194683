import * as React from 'react';
import {CellContainer} from './CellContainer';
import {BodyText} from '../../Text';
import {colors} from '../../../theme';
import {Chip} from '../../Chips';

export function CounterCell({value, colDef}) {
  return (
    <CellContainer>
      <Chip color={value ? colors.palette.green : colors.palette.greyLighter}>
        {(value || !colDef.zeroText) &&
          <span>{value}</span>
        }
        <BodyText>{value ? colDef.text : colDef.zeroText ?? colDef.text}</BodyText>
      </Chip>
    </CellContainer>
  );
}
