// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React, {Component, Fragment} from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import withStyles from '@material-ui/core/styles/withStyles';
import {FieldArray, formValueSelector} from 'redux-form';
import DecorationsTableRow from './DecorationsTableRow';
import {TableRow} from '@material-ui/core';
import {connect} from 'react-redux';

const styles = () => {
  const baseWidth = 140;
  return {
    baseColumn: {width: `${baseWidth}px`},
    largeColumn: {width: `${baseWidth * 2}px`},
    actionColumn: {width: `${baseWidth / 3}px`}
  };
};

class DecorationsTable extends Component {
  render() {
    const {classes, decorations, product} = this.props;
    return (
      <Fragment>
        <Table className={classes.table}>
          {Boolean(decorations && decorations.length) && <TableHead>
            <TableRow>
              <TableCell className={classes.baseColumn}>Quantity</TableCell>
              <TableCell>Name</TableCell>
              <TableCell className={classes.baseColumn}>Position</TableCell>
              <TableCell className={classes.baseColumn}>Colour(s)</TableCell>
              <TableCell className={classes.baseColumn}>Size</TableCell>
              <TableCell className={classes.largeColumn}>Vendor</TableCell>
              <TableCell className={classes.actionColumn}/>
            </TableRow>
          </TableHead>}
          <TableBody>
            <FieldArray name={'decorations'} component={DecorationsTableRow} product={product}/>
          </TableBody>
        </Table>
      </Fragment>
    );
  }
}

const selector = formValueSelector('jobVariantsForm');
function mapStateToProps(state) {
  return {decorations: selector(state, 'decorations')};
}

DecorationsTable = withStyles(styles)(DecorationsTable);
DecorationsTable = connect(mapStateToProps)(DecorationsTable);

export default DecorationsTable;
