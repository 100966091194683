// TODO: fix eslint disable
/* eslint-disable no-shadow */

import React from 'react';
import PropTypes from 'prop-types';
import {Icon, IconButton, ListItemIcon, Menu, MenuItem,} from '@material-ui/core';

import {deleteCustomer, setFormCustomer} from '../../actions/action-types';
import ConfirmDeleteDialog from '../confirmDeleteDialog';
import {connect} from 'react-redux';
import {openCustomerModal} from '../../actions/modals';

class CustomerMenuCell extends React.Component {
  state = {
    anchorEl: null,
    confirmDelete: false,
    editModalOpen: false
  };

  handleClick = (event) => {
    this.setState({anchorEl: event.currentTarget});
  };

  handleClose = () => {
    this.setState({anchorEl: null});
  };

  openConfirmDelete = () => {
    this.setState({confirmDelete: !this.state.confirmDelete});
  };

  openEditModal = () => {
    const {getCustomer, openCustomerModal, rowData} = this.props;

    this.handleClose();
    getCustomer(rowData, (err) => {
      if (!err) {
        openCustomerModal();
      }
    });
  };

  deleteCustomer = () => {
    const {deleteCustomer, rowData} = this.props;

    this.handleClose();

    deleteCustomer(rowData);
  };

  render() {
    const {anchorEl} = this.state;
    const {rowData} = this.props;

    return (
      <div key={rowData._id}>
        <IconButton
          onClick={this.handleClick}
          variant='outlined'
          size={'small'}
          data-intercom-target={'customers-table-menu'}
        >
          <Icon>more_horiz</Icon>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.openEditModal}>
            <ListItemIcon>
              <Icon>edit</Icon>
            </ListItemIcon>
            Edit
          </MenuItem>
          <MenuItem onClick={this.openConfirmDelete}>
            <ListItemIcon>
              <Icon>delete</Icon>
            </ListItemIcon>
            Delete
            <ConfirmDeleteDialog name={rowData.name} isOpen={this.state.confirmDelete} ok={this.deleteCustomer}/>
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    openCustomerModal: () => dispatch(openCustomerModal()),
    getCustomer: (rowData, cb) => dispatch(setFormCustomer(rowData._id, cb)),
    deleteCustomer: (customer) => dispatch(deleteCustomer(customer))
  };
}

CustomerMenuCell.propTypes = {rowData: PropTypes.object};

export default connect(null, mapDispatchToProps)(CustomerMenuCell);
