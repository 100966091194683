import gql from 'graphql-tag';

export const GQL_DELETE_USER = gql`
    mutation deleteUser($_id: String!) {
        deleteUser(_id: $_id) {
            _id
            username
            firstName
            lastName
            fullName
            status
        }
    }
`;

export const GQL_GET_ME = gql`
    query GetMe {
        user {
            _id
            firebaseToken
            superadmin
            username
            firstName
            lastName
            fullName
            phone
            timezone
            avatar
            role
            type
            company {
                _id
                companyTradingEntities {
        _id
                    name
                }
                currencySymbol
                stripeSubscriptionId
                subscriptionType
                accountingPlatform {
                    _id
                    platform
                    platformId
                    companyName
                    refreshTokenExpiry
                    xeroAccountCode
                    xeroAccountCodeExpense
                    xeroAccountList {
                        code
                        name
                    }
                    xeroAccountListExpense {
                        code
                        name
                    }
                    xeroTaxTypeDefault
                    xeroTaxTypeDefaultExpense
                    xeroTaxTypeMapping {
                        hoopsTaxId
                        xeroTaxType
                    }
                    defaultDueDate
                    defaultInvoiceStatus
                }
                appAccess {
                  allowedFeatures
                }
            }
            companyId
            status
        }
    }
`;

export const GQL_GET_USER_INTERCOM_ATTRIBUTES = gql`
    query UserIntercomAttributes {
        user {
            numberDecorations
            numberQuotes
            numberProducts
            numberJobs
            numberPurchaseOrders
        }
    }
`;

export const GQL_GET_USERS = gql`query Users {
    users {
        _id
        username
        fullName
        status
        createdAt
    }
}`;

export const GQL_INVITE_USER = gql`
    mutation InviteUser($user: InviteUserInput!) {
        inviteUser(user: $user) {
            username
            firstName
            fullName
            lastName
            companyId
            status
            role
        }
    }
`;

export const GQL_GET_USER_BY_EXTERNAL_ID = gql`
    query UserByExternalId($externalId: String!) {
        userByExternalId(externalId: $externalId) {
            _id
            firstName
            lastName
            fullName
            username
        }
    }`;

export const GQL_GET_USER_BY_ID = gql`
    query UserByIds($_ids: [MongoID!]!) {
        userByIds(_ids: $_ids) {
            _id
            firstName
            lastName
            fullName
            username
        }
    }`;

export const GQL_ACCEPT_USER_INVITE = gql`
    mutation AcceptUserInvite($_id: String!, $user: AcceptUserInviteInput!) {
        acceptInvite(_id: $_id, user: $user) {
            _id
            username
            firstName
            lastName
            companyId
            status
        }
    }
`;

export const GQL_CHANGE_PASSWORD = gql`
    mutation ChangePassword($newPassword: String!){
        updatePassword(newPassword: $newPassword) {
            _id
        }
    }
`;

export const GQL_UPDATE_USER_ROLE = gql`
    mutation UpdateUserRole($_id: MongoID!, $role: EnumGCUserRole!) {
        userUpdateRole(_id: $_id, role: $role) {
            role
            fullName
        }
    }
`;

export const GQL_USER_PAGINATION = gql`
    query userPagination(
        $filter: FilterFindManySearchGCUserInput,
        $sort: SortFindManyGCUserInput,
        $perPage: Int,
        $page: Int
    ){
        userPagination(
            filter: $filter,
            sort: $sort,
            perPage: $perPage,
            page: $page
        ) {
            items {
                _id
                fullName
                firstName
                lastName
                username
                status
                createdAt
                role
                type
            }
            pageInfo {
                currentPage
                perPage
                pageCount
                itemCount
                hasNextPage
                hasPreviousPage
            }
        }
    }
`;
