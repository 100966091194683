// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-shadow */

import {makeStyles, TableBody, TableCell, TableRow, withStyles} from '@material-ui/core';
import {get} from 'lodash';
import React from 'react';
import {hoopsQueryStyles} from '../../ui/theme/hoops';

const useStyles = makeStyles((theme) => ({...hoopsQueryStyles,}));

export const HoopsQueryTableBody = ({
  columns = [],
  rows = [],
  onRowClick = (event, row) => null,
  onCellClick = (event, row, column) => null,
  emptyStateComponent = () => null,
  stripedRows = false,
  leftScrollShadow = false,
  rightScrollShadow = false,
}) => {
  const classes = useStyles();

  const handleCellClick = (event, row, column) => {
    if (column.disableClick) {
      event.disableClick = true;
      return;
    }

    onCellClick(event, row, column);
  };

  const handleRowClick = (event, row) => {
    if (event.disableClick) {
      return; // hack
    }
    onRowClick(event, row);
  };

  const StickyTableCellLeft = withStyles((theme) => ({
    body: {
      left: 0,
      position: 'sticky',
      zIndex: theme.zIndex.appBar - 1,
      padding: '0 !important',
    },

  }))(TableCell);

  const StickyTableCellRight = withStyles((theme) => ({
    body: {
      right: 0,
      position: 'sticky',
      zIndex: theme.zIndex.appBar - 1,
      padding: '0 !important',
    }
  }))(TableCell);

  const renderRows = (rows) => {
    const columnsStickyLeft = columns.filter((item) => item && item.stickyLeft === true && !item.inActive);
    const columnsStickyRight = columns.filter((item) => item && item.stickyRight === true && !item.inActive);
    const columnsNonSticky = columns.filter((item) => item && item.stickyLeft !== true && item.stickyRight !== true && !item.inActive);

    return <>
      {rows.map((row, rowIdx) => (
          <TableRow hover tabIndex={-1} key={row._id || rowIdx} onClick={(event) => handleRowClick(event, row)} className={stripedRows ? classes.greyRow : null}>

            {columnsStickyLeft.length > 0 &&
              <StickyTableCellLeft className={`${rowIdx % 2 === 0 ? classes.greyRow : classes.whiteRow} ${classes.leftSticky} ${leftScrollShadow && classes.leftShadow}`}>
                {/* Sticky left cells */}
                {columnsStickyLeft.map((column, colIdx) => {
                  // if (column.hide) return
                  column.cellStyle = {...column.cellStyle, borderBottom: 'none', display: columnsStickyLeft.length === 0 && 'none'};
                  return (
                    <TableCell
                      style={column.cellStyleMethod ? column.cellStyleMethod(row) : column.cellStyle}
                      onClick={(event) => handleCellClick(event, row, column)}
                      key={colIdx}
                      align={column.align}
                      scope='row'
                      className={`${column.hide ? classes.hideCell : null}`}
                    >
                      {column.render ? column.render(row) : get(row, column.field)}
                    </TableCell>
                  );
                })}
              </StickyTableCellLeft>}

            {/* Non-sticky cells */}
            {columnsNonSticky.map((column, colIdx) => {
              // if (column.hide) return
              column.cellStyle = {...column.cellStyle, borderBottom: '1px solid rgba(224, 224, 224, 1)'};
              return (
                <TableCell
                  style={column.cellStyleMethod ? column.cellStyleMethod(row) : column.cellStyle}
                  onClick={(event) => handleCellClick(event, row, column)}
                  key={colIdx}
                  align={column.align}
                  scope='row'
                  className={`${column.hide ? classes.hideCell : null}`}
                >
                  {column.render ? column.render(row) : get(row, column.field)}
                </TableCell>
              );
            })}

            {/* Sticky right cells */}
            {columnsStickyRight.length > 0 &&
              <StickyTableCellRight className={`${rowIdx % 2 === 0 ? classes.greyRow : classes.whiteRow} ${classes.rightSticky} ${rightScrollShadow && classes.rightShadow}`}>
                {columnsStickyRight.map((column, colIdx) => {
                  // if (column.hide) return
                  column.cellStyle = {...column.cellStyle, borderBottom: 'none', display: columnsStickyRight.length === 0 && 'none'};
                  return (
                    <TableCell
                      style={column.cellStyleMethod ? column.cellStyleMethod(row) : column.cellStyle}
                      onClick={(event) => handleCellClick(event, row, column)}
                      key={colIdx}
                      align={column.align}
                      scope='row'
                      className={`${column.hide ? classes.hideCell : null}`}
                    >
                      {column.render ? column.render(row) : get(row, column.field)}
                    </TableCell>
                  );
                })}
              </StickyTableCellRight>}

          </TableRow>
        ))}
    </>;
  };

  return (
    <TableBody>
      {
        rows.length > 0
          ? renderRows(rows)
          : emptyStateComponent()
      }
    </TableBody>
  );
};
export default HoopsQueryTableBody;
