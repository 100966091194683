import React, {useCallback} from 'react';
import MaterialTable from 'material-table';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import Chip from '@material-ui/core/Chip';
import red from '../../../../ui/theme/red';
import blue from '../../../../ui/theme';
import {ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles';
import ImportHistoryActions from './ImportHistoryActions';
import orange from '../../../../ui/theme/orange';
import green from '../../../../ui/theme/green';
import {CircularProgress} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import faviconLogo from '../../../../assets/images/favicon.ico';
import Avatar from '@material-ui/core/Avatar';
import {setModalContext, setModalOpen} from '../../../../actions/modals';
import {useQuery} from '@apollo/client';
import {GQL_CATALOG_IMPORTS} from '../../../../queries/catalogImport';

function StatusChip({status}) {
  let themeColor;
  switch (status) {
    case 'CANCELLED':
    case 'UNDONE':
      themeColor = orange;
      break;
    case 'ERROR':
      themeColor = red;
      break;
    case 'COMPLETE':
      themeColor = green;
      break;
    default:
      themeColor = blue;
      break;
  }

  let statusLabel = 'PENDING';

  if (status) {
    statusLabel = status
      .split('_')
      .join(' ');
  }

  return (
    <>
      <MuiThemeProvider theme={themeColor}>
        <Chip label={statusLabel} color={'primary'} />
      </MuiThemeProvider>
    </>
  );
}

const uploadProgressSelector = (state) => state.dropzoneReducer.multipartUploads;

function ImageUploadCell({multipartFileLabel, status}) {
  const uploadProgress = useSelector(uploadProgressSelector)[multipartFileLabel];
  return (
    <>
      {!!uploadProgress && uploadProgress.loading ?
        <>
          <Grid container spacing={2} alignItems={'center'}>
            <Grid item>
              <CircularProgress variant={'static'}
                value={uploadProgress.progress} />
            </Grid>
            <Grid item xs={true}>
              <Typography variant={'body2'}>Uploading {uploadProgress.progress}%</Typography>
            </Grid>
          </Grid>
        </> :
        <StatusChip status={status} />}
    </>
  );
}

const userDataSelector = (state) => state.authReducer.userData;

function ImportHistoryTable() {
  const dispatch = useDispatch();
  // const [columns, setColumns] = useState([]);
  const userData = useSelector(userDataSelector);

  const handleMultiShareClick = useCallback((event, data) => {
    const ids = data.map((row) => row._id);
    dispatch(setModalContext('shareProductModal', {catalogImportIds: ids,}));
    dispatch(setModalOpen('shareProductModal', true));
  }, [dispatch]);

  const {data, loading, stopPolling} = useQuery(GQL_CATALOG_IMPORTS, {
    pollInterval: 30000,
    fetchPolicy: 'no-cache',
    variables: {limit: 100, skip: 0},
    onCompleted: ({catalogImports}) => {
      const pendingImports = catalogImports.find((el) => el.status === 'PENDING' || el.status === 'IN PROGRESS'); 
      if (!pendingImports) {
        stopPolling();
      }
    }
  });

  const columns = [{
    title: 'Created At',
    field: 'createdAt',
    render({createdAt}) {
      return moment(createdAt, 'x').format('D MMM YYYY h:mm A');
    },
  }, {
    title: 'Created By',
    field: 'createdBy.fullName',
    render({readOnly, createdBy}) {
      if (readOnly) {
        return (
          <Grid container spacing={1} wrap={'nowrap'}>
            <Grid item>
              <Avatar src={faviconLogo} style={{width: 20, height: 20}} />
            </Grid>
            <Grid item>
              {createdBy.company ? createdBy.company.name : ''}
            </Grid>
          </Grid>
        );
      }
      return createdBy?.fullName;
    },
  }, {
    title: 'Description',
    field: 'description',
    hidden: !userData.superadmin
  },
  {
    title: 'Vendor',
    field: 'vendor.name',
  }, {
    title: 'Creating Products',
    field: 'createProductsStatus',
    render({readOnly, createProductsStatus}) {
      return (
        <StatusChip status={(readOnly ? 'N/A' : createProductsStatus)} />
      );
    },
  }, {
    title: 'Unzipping Files',
    field: 'unzipFilesStatus',
    render({readOnly, _id, unzipFilesStatus}) {
      return (
        <ImageUploadCell status={(readOnly ? 'N/A' : unzipFilesStatus)} multipartFileLabel={_id} />
      );
    },
  }, {
    title: 'Linking Images',
    field: 'linkingImagesStatus',
    render({readOnly, linkingImagesStatus}) {
      return (
        <StatusChip status={(readOnly ? 'N/A' : linkingImagesStatus)} />
      );
    },
  }, {
    title: 'Status',
    field: 'status',
    render({readOnly, status}) {
      return (
        <StatusChip status={(readOnly ? 'N/A' : status)} />
      );
    },
  }, {
    title: 'No. Products',
    field: 'numberProducts',
  }, {
    title: 'Actions',
    render(_data) {
      return <ImportHistoryActions rowData={_data} />;
    },
  }];

  return (
    <>
      <MaterialTable
        columns={columns}
        data={(data ? data.catalogImports : undefined)}
        isLoading={loading}
        options={{
          showTitle: false,
          paging: false,
          debounceInterval: 250,
          selection: userData.superadmin,
          selectionProps: (rowData) => ({
            disabled: (rowData.readOnly || rowData.status !== 'COMPLETE'),
            color: 'primary',
          }),
        }}
        actions={userData.superadmin ? [
          {
            tooltip: 'Share Selected Imports',
            icon: 'share',
            onClick: handleMultiShareClick,
          },
        ] : []}
      />
    </>
  );
}

export default ImportHistoryTable;
