import {
  GQL_REPORT_GROSS_MARGIN_QUOTES_PER_CUSTOMER,
  GQL_REPORT_GROSS_MARGIN_QUOTES_PER_USER,
  GQL_REPORT_GROSS_MARGIN_SALES_PER_CUSTOMER,
  GQL_REPORT_GROSS_MARGIN_SALES_PER_USER, GQL_REPORT_SALES_PER_CUSTOMER,
  GQL_REPORT_SALES_PER_QUOTE_OWNER,
  GQL_REPORT_SALES_PER_USER
} from '../queries/reporting';
import {client} from '../servicesOld/GraphQLService';

export const SET_REPORT_DATA = 'SET_REPORT_DATA';

export function getReportData({reportType, grouping, startDate, endDate}) {
  let query;
  let queryResultPath;

  return (dispatch) => {
    switch (reportType) {
      case 'grossMargin':
        switch (grouping) {
          case 'salesPerCustomer':
            query = GQL_REPORT_GROSS_MARGIN_SALES_PER_CUSTOMER;
            queryResultPath = 'reportGrossMarginSalesPerCustomer';
            break;
          case 'salesPerUser':
            query = GQL_REPORT_GROSS_MARGIN_SALES_PER_USER;
            queryResultPath = 'reportGrossMarginSalesPerUser';
            break;
          case 'quotesPerCustomer':
            query = GQL_REPORT_GROSS_MARGIN_QUOTES_PER_CUSTOMER;
            queryResultPath = 'reportGrossMarginQuotesPerCustomer';
            break;
          case 'quotesPerUser':
            query = GQL_REPORT_GROSS_MARGIN_QUOTES_PER_USER;
            queryResultPath = 'reportGrossMarginQuotesPerUser';
            break;
          default:
            query = GQL_REPORT_GROSS_MARGIN_SALES_PER_CUSTOMER;
            queryResultPath = 'reportGrossMarginSalesPerCustomer';
            break;
        }
        break;

      default:
      case 'sales':
        switch (grouping) {
          case 'salesPerUser':
            query = GQL_REPORT_SALES_PER_USER;
            queryResultPath = 'reportSalesPerUser';
            break;
          case 'salesPerQuoteOwner':
            query = GQL_REPORT_SALES_PER_QUOTE_OWNER;
            queryResultPath = 'reportSalesPerQuoteOwner';
            break;

          default:
          case 'salesPerCustomer':
            query = GQL_REPORT_SALES_PER_CUSTOMER;
            queryResultPath = 'reportSalesPerCustomer';
            break;
        }
    }

    return client
      .query({
        fetchPolicy: 'no-cache',
        query,
        variables: {
          startDate,
          endDate,
        },
      })
      .then(({data}) =>
        dispatch({
          type: SET_REPORT_DATA,
          payload: data[queryResultPath],
        })
      );
  };
}
