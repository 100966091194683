// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import {
  Checkbox,
  FormControl,
  Grid, Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import React, {useEffect, useRef} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {useGetViews} from '../../hooks/api';
import Loading from '../../componentsOld/shared/Loading';
import {capitalize} from '../../utils';
import ControlledAutocomplete from './ControlledAutocomplete';
import {CURRENT, PAID_IN_FULL, PARTIALLY_PAID} from '../../constants/invoiceStatuses';

const useStyles = makeStyles((theme) => ({
    greyText: {color: theme.colors.grey.main,},
    selectClass: {minWidth: 184,},
    optionTextAny: {fontStyle: 'italic',},
}));

export const AutomationTriggerAccountingConfigStatusChangeJob = () => {
    const classes = useStyles();
    const {data: {views: viewData}, isLoading: loading} = useGetViews('jobs');
    const {control, watch, setValue} = useFormContext();
    const jobStatus = watch('statusType');
    const firstUpdate = useRef(true);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        setValue('status', []);
    }, [jobStatus, setValue]);

        //list job statuses of selected job status type
    const options = [
      {value: '*', label: 'Anything'},
      {value: CURRENT, label: 'Waiting Payment'},
      {value: PARTIALLY_PAID, label: 'Partially Paid'},
      {value: PAID_IN_FULL, label: 'Paid'}
    ];

    if (!viewData || loading){
      return <Loading/>;
    }
    return (

        <Grid container direction='row' justifyContent='center' alignItems='flex-end' spacing={1}>
            <Grid item >
                <Typography variant='h5' className={classes.greyText}>When the Accounting Status changes to </Typography>

            </Grid>
            <Grid item >
              <ControlledAutocomplete
                control={control}
                name='status'
                options={options}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, value) => option.value === value.value}
                renderOption={(option, {selected}) => (
                    <>
                      <Checkbox
                        color='primary'
                        style={{marginRight: 8}}
                        checked={selected}
                      />
                      <span
                        className={option.label === 'Anything' && classes.optionTextAny}>{capitalize(option.label)}</span>
                    </>
                  )}
                renderInput={(params) => (
                  <TextField {...params} label='Select' placeholder='Select' />
                )}
                multiple={true}
                disableCloseOnSelect={true}
                defaultValue={[]}
              />
            </Grid>
            <Controller
              name='statusType'
              defaultValue=''
              control={control}
              render={(props) =>
                <Input
                  inputProps={{type: 'hidden',}}
                  labelId='status-label'
                  value={'accountingStatus'}
                  {...props}
                />
              }
            />
        </Grid>

    );
};

