import {Dialog, makeStyles, Typography} from '@material-ui/core';
import React, {useContext} from 'react';
import DialogTitle from '../../componentsOld/shared/DialogTitle';
import EmailTemplateForm from './EmailTemplateForm';
import {EmailTemplateContext} from './EmailTemplateManyViewPage';

const useStyles = makeStyles((theme) => ({titleContainer: {padding: theme.spacing(3),}}));

const EmailTemplateEditModal = ({
    initialValues = null,
    emailType = null,
    handleClose = () => null,
    open = null,
    emailTemplateId = null
}) => {
    const {activeEmailTemplate} = useContext(EmailTemplateContext);
    const classes = useStyles();

    return (
        <>
            <Dialog
                open={open}
                fullWidth
                maxWidth='lg'
                onClose={handleClose}
            >
                <DialogTitle onClick={handleClose} disableTypography className={classes.titleContainer}>
                    <Typography variant='h4'>{activeEmailTemplate ? 'Edit' : 'Create'} Template</Typography>
                </DialogTitle>
                <EmailTemplateForm initialValues={initialValues} handleClose={handleClose} type={emailType} emailTemplateId={emailTemplateId} />
            </Dialog>
        </>
    );
};

export default EmailTemplateEditModal;
