import {
  SEND_QUOTE_COMPLETE,
  SEND_QUOTE_ERROR,
  SEND_QUOTE_LOADING,
  SET_LOADING_QUOTES,
  SET_PREVIEW_LOADING,
  SET_QUOTE,
  CLEAR_QUOTE,
  SET_QUOTES,
  SET_QUOTE_FORM,
} from '../actions/quote';

export const initialStateQuote = {
  title: 'Option 1',
  description: '',
  company: {addresses: [{}],},
  customer: {
    addresses: [],
    settings: {},
  },
  items: [
    {},
    {},
    {},
  ],
  contact: {},
  siblings: [],
  createdAt: null,
  createdBy: null,
  updatedAt: null,
  updatedBy: null,
  status: null,
  isQuoteAccepted: null,
  quoteAcceptedAt: null,
  settings: {
    canContactAccept: true,
    canContactComment: true,
  }
};

const initialState = {
  list: [],
  table: {
    page: 1,
    pageLimit: 25,
  },
  loadingQuotes: false,
  sendQuoteLoading: false,
  sendQuoteError: false,
  sendQuoteSuccess: false,
  previewLoading: true,
  quote: {
    ...initialStateQuote,
    siblings: [initialStateQuote],
  },
  quoteForm: {
    ...initialStateQuote,
    siblings: [initialStateQuote],
  },
};

const quoteReducer = (state = {...initialState}, action) => {
  switch (action.type) {
    case SET_PREVIEW_LOADING:
      return {
        ...state,
        previewLoading: action.payload,
      };
    case SET_LOADING_QUOTES:
      return {
        ...state,
        loadingQuotes: action.payload,
      };
    case SET_QUOTES:
      return {
        ...state,
        list: action.payload,
      };
    case SET_QUOTE:
      return {
        ...state,
        quote: {
          ...initialState.quote,
          ...action.payload,
        },
      };
    case CLEAR_QUOTE:
      return {
        ...state,
          quote: initialState.quote
      };  
    case SET_QUOTE_FORM:
      return {
        ...state,
        quoteForm: {
          ...initialState.quoteForm,
          ...action.payload,
        },
      };
    case SEND_QUOTE_LOADING:
      return {
        ...state,
        sendQuoteLoading: true,
        sendQuoteError: false,
        sendQuoteSuccess: false,
      };
    case SEND_QUOTE_COMPLETE:
      return {
        ...state,
        sendQuoteLoading: false,
        sendQuoteError: false,
        sendQuoteSuccess: true,
      };
    case SEND_QUOTE_ERROR:
      return {
        ...state,
        sendQuoteLoading: false,
        sendQuoteError: true,
        sendQuoteSuccess: false,
      };
    default:
      return state;
  }
};
export default quoteReducer;
