import {Grid} from '@material-ui/core';
import React from 'react';
import PreviewPurchaseOrderContent from './PreviewPurchaseOrderContent';

const GuestViewPurchaseOrder = () => (
        <Grid container justifyContent={'center'}>
          <Grid md={12} lg={10} container item style={{maxWidth: '1200px'}}>
            {
                <Grid container spacing='4'>
                  <Grid item xs={12}>
                    <PreviewPurchaseOrderContent />
                  </Grid>
                </Grid>
            }
          </Grid>
        </Grid>
    );

export default GuestViewPurchaseOrder;
