import React, {Fragment, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import {required} from '../../../shared/formHelpers/validation';
import red from '../../../../ui/theme/red';
import {HoopsFieldMappingRow} from '../HoopsFieldMappingRow';
import {FieldArray} from 'react-final-form-arrays';
import {MuiThemeProvider} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import {Delete} from '@material-ui/icons';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';

const fieldsToMap = [{
  name: 'quantity',
  label: 'Qty',
  help: 'http://help.hoopscrm.com/en/articles/3667975-shipping-information'
}, {
  name: 'price',
  label: 'Price',
  validate: required,
  chip: {
    theme: red,
    text: 'Required'
  },
}];

const styles = (theme) => ({
  addMoreButton: {
    paddingLeft: '0 !important',
    paddingRight: `${theme.spacing(2)}px !important`
  },
  dividerSpacing: {margin: theme.spacing(1)}
});

export function Step4Pricing({values, setValues}) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  useEffect(() => {
    setValues({...values, fieldMapping: {...values.fieldMapping, priceBreaks: values.fieldMapping?.priceBreaks ?? [{}]}});
    // TODO: FIX HOOK DEPENDENCIES
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FieldArray name={'fieldMapping.priceBreaks'}>
      {({fields}) => (
        <>
          {fields.map((field, index) => (
              <Fragment key={field}>
                <Grid container alignItems={'center'} key={field}>
                  <Grid item xs={true}>
                    {fieldsToMap.map((step, i) => (<Grid
                          container
                          spacing={2}
                          key={`${field}${step}${i}`}
                          alignItems={'center'}
                        >
                          <HoopsFieldMappingRow
                            formValues={values}
                            fieldName={`${field}.${step.name}`}
                            key={`${field}.${step.name}`}
                            index={index}
                            field={step}/>
                        </Grid>)
                    )}

                  </Grid>
                  <Grid item>
                    <MuiThemeProvider theme={red}>
                      <IconButton color={'primary'}
                                  disabled={index === 0}
                                  onClick={() => fields.remove(index)}
                      >
                        <Delete/>
                      </IconButton>
                    </MuiThemeProvider>
                  </Grid>
                </Grid>
                <Divider key={`divider-${field}`} light className={classes.dividerSpacing}/>
              </Fragment>
            )
          )}

          <Grid container>
            <Grid item container xs={6} justifyContent={'center'}>
              <Button
                fullWidth
                color={'primary'}
                variant={'text'}
                onClick={() => fields.push({})}
              >Add another price break</Button>
            </Grid>
          </Grid>
        </>
      )}
    </FieldArray>
  );
}
