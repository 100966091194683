// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {SignupForm} from './SignupForm';
import {ACCEPT_INVITE_FIELD_GROUP} from '../../constants';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {GetUserByExternalId, AcceptInvite as AcceptInviteAction} from '../../actions/action-types';
import Typography from '@material-ui/core/Typography';
import {Link} from '@material-ui/core';
import {Link as RouterLink, withRouter} from 'react-router-dom';
import {LOGIN_ROUTE} from '../../containers/routes/PreAuthRoutes';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    width: '100%'
  },
  space: {marginBottom: theme.spacing(2)}
});

class AcceptInvite extends React.Component {

  submit = (values) => {
    const {acceptInvite} = this.props;
    acceptInvite(values);
  };

  componentDidMount() {
    const {match, getUserByExternalId} = this.props;
    getUserByExternalId(match.params.externalId);
  }

  render() {
    const {registrationStatus, user, classes} = this.props;
    return (
      <Fragment>
        <Grid item>
          {user ? <SignupForm
            onSubmit={this.submit}
            formGroup={ACCEPT_INVITE_FIELD_GROUP}
            isLoading={registrationStatus.loading}
          /> : <Fragment>
            <Typography variant={'h3'} color={'primary'} className={classes.space}>
              Invite Accepted
            </Typography>
            <Typography variant={'body1'} color={'textSecondary'} className={classes.space}>
              Your invite as already been accepted so this link is no longer valid.
            </Typography>
            <Typography>
              <Link component={RouterLink} to={LOGIN_ROUTE} className={classes.space}>Return to Log In</Link>
            </Typography>
          </Fragment>}
        </Grid>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.authReducer,
    user: state.authReducer.newUser,
    registrationStatus: state.registrationReducer
  };
}

function mapDispatchToProps(dispatch) {
  return {
    acceptInvite: (payload) => dispatch(AcceptInviteAction(payload)),
    getUserByExternalId: (payload) => dispatch(GetUserByExternalId(payload))
  };
}

AcceptInvite = withRouter(AcceptInvite);
AcceptInvite = connect(mapStateToProps, mapDispatchToProps)(AcceptInvite);
AcceptInvite = withStyles(styles)(AcceptInvite);

export default AcceptInvite;
