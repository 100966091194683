// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React, {Component, Fragment} from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import withStyles from '@material-ui/core/styles/withStyles';
import {FieldArray} from 'redux-form';
import DecorationsTableRow from './DecorationsTableRow';
import {TableRow} from '@material-ui/core';

const styles = (theme) => {
  const baseWidth = 140;
  return {
    sectionFooter: {
      margin: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      maxWidth: '100%'
    },
    baseColumn: {width: `${baseWidth}px`},
    largeColumn: {width: `${baseWidth * 2}px`},
    actionColumn: {width: `${baseWidth / 3}px`}
  };
};

class DecorationsTable extends Component {
  render() {
    const {classes, product} = this.props;
    return (
      <Fragment>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.baseColumn}>Quantity</TableCell>
              <TableCell>Name/Description</TableCell>
              <TableCell className={classes.baseColumn}>Position</TableCell>
              <TableCell className={classes.baseColumn}>Colour(s)</TableCell>
              <TableCell className={classes.baseColumn}>Size</TableCell>
              <TableCell className={classes.baseColumn}>Rate/Unit</TableCell>
              <TableCell className={classes.baseColumn}>Total</TableCell>
              <TableCell className={classes.actionColumn}/>
            </TableRow>
          </TableHead>
          <TableBody>
            <FieldArray name={'decorations'} component={DecorationsTableRow} product={product}/>
          </TableBody>
        </Table>
      </Fragment>
    );
  }
}

DecorationsTable = withStyles(styles)(DecorationsTable);
export default DecorationsTable;
