// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React, {Component} from 'react';
import DialogTitle from '../../DialogTitle';
import DialogActions from '../../DialogActions';
import {
  Dialog,
  Typography,
  DialogContent,
  Grid,
  Button,
  MuiThemeProvider
 } from '@material-ui/core';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {Field, reduxForm, reset} from 'redux-form';
import {renderTextField} from '../../formHelpers/fields';
import red from '../../../../ui/theme/red';
import ProofService from '../../../../servicesOld/ProofService';
import {required} from '../../formHelpers/validation';
import {closeGuestRejectProofModal as _closeGuestRejectProofModal} from '../../../../actions/modals';
import {getProofs as _getProofs} from '../../../../actions/proofs';

const styles = (theme) => ({
  notesContainer: {
    maxHeight: theme.spacing(50),
    overflowY: 'scroll'
  },
  divider: {margin: `${theme.spacing(2)}px ${theme.spacing(0)}px`},
});

class ProofNotesModal extends Component {

  handleFormSubmit = async (values) => {
    const {modalState: {proofId, jobId, navigateToNextProof, token}, getProofs} = this.props;
    await ProofService.rejectProof(proofId, {content: values.note}, token);
    await getProofs(jobId);
    this.handleClose();
    navigateToNextProof();
  };

  handleClose = () => {
    const {resetForm, closeModal} = this.props;
    resetForm();
    closeModal();
  };

  render() {
    const {
      classes,
      handleSubmit,
      modalState: {isOpen}
    } = this.props;
    return (
      <Dialog
        open={isOpen}
        onClose={this.handleClose}
        fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogTitle onClick={this.handleClose}>
          <Typography variant={'h4'}>Tell us what to change</Typography>
        </DialogTitle>
        <form onSubmit={handleSubmit(this.handleFormSubmit)}>
          <DialogContent>
            <Grid container component={'div'} spacing={2}>
              <Grid container item xs={12} className={classes.noteFieldContainer}>
                <Field
                  name='note'
                  component={renderTextField}
                  label='Add Note *'
                  multiline
                  rows='4'
                  validate={required}
                  placeholder='Type your note here...'
                  className={classes.noteField}
                  variant='outlined'
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent={'space-between'} component={'div'}>
              <Grid item component={'div'}>
                <Button variant={'text'} color={'secondary'} onClick={this.handleClose}>Cancel</Button>
              </Grid>
              <Grid item component={'div'}>
                <MuiThemeProvider theme={red}>
                  <Button variant={'contained'} color={'primary'}
                          type={'submit'}>Reject Proof</Button>
                </MuiThemeProvider>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {modalState: state.modalReducer.guestRejectProofModal};
}

function mapDispatchToProps(dispatch) {
  return {
    resetForm: () => dispatch(reset('guestProofReject')),
    closeModal: () => dispatch(_closeGuestRejectProofModal()),
    getProofs: (jobId) => dispatch(_getProofs(jobId))
  };
}

ProofNotesModal = reduxForm({form: 'guestProofReject'})(ProofNotesModal);
ProofNotesModal = connect(mapStateToProps, mapDispatchToProps)(ProofNotesModal);
ProofNotesModal = withStyles(styles)(ProofNotesModal);

export default ProofNotesModal;
