import React, {useState} from 'react';
import {Dialog, Typography} from '@material-ui/core';
import DialogTitle from '../../shared/DialogTitle';

export function TrackerNumberModal({button, title, children}) {
  const [open, setOpen] = useState(false);
  const onCancel = () => setOpen(false);
  return (
    <>
      <Dialog
        maxWidth={'md'}
        fullWidth
        open={open}
        onCancel={onCancel}
      >
        <DialogTitle onClick={onCancel}>
          <Typography variant={'h4'}>{title}</Typography>
        </DialogTitle>
        {React.cloneElement(children, {...children.props, onCancel: () => setOpen(false)})}
      </Dialog>
      {React.cloneElement(button, {...button.props, onClick: () => setOpen(true)})}
    </>
  );
}
