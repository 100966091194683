import React from 'react';
import {Link, Typography} from '@mui/material';
import {StandardDialog} from '../../componentsLib/Dialogs';

export function XeroPaymentInfoDialog({open, onClose}) {
  return (
    <StandardDialog open={open} smallTitle title={'Unable to Update Xero Invoice'} onClose={onClose}>
      <Typography variant={'body1'}>
        As there are payments applied, the invoice cannot be updated. Learn more in <Link
        href={'https://help.xero.com/au/Payments_AR_PaidView'} target={'_blank'} underline={'none'} variant={'body1'}>Xero Help.</Link>
      </Typography>
      <Typography variant={'body1'}>
        To make changes, first Remove & Redo any payments and delete any credit note allocations.
      </Typography>
    </StandardDialog>
  );
}
