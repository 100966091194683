import React from 'react';
import green from '../ui/theme/green';
import {Grid, Button, Typography, MuiThemeProvider, makeStyles, CircularProgress} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({buttonPadding: {marginTop: theme.spacing(1)}}));

const EmptyTable = ({
  showAddButton = true,
  field = null,
  openDialog = () => null,
  loading = false
}) => {
  const classes = useStyles();

  return (
    <Grid container direction={'column'} spacing={2} style={{textAlign: 'center'}}>
      <MuiThemeProvider theme={green}>
        {loading ?
          <>
            <Grid item>
              <CircularProgress color='secondary' />
            </Grid>
            <Grid item>
              <Typography variant='body3' color='secondary'>loading</Typography>
            </Grid>
          </>
          :
          <>
            <Grid item>
              <svg width='200px' height='200px' viewBox='0 0 600 600'>
                <g>
                  <g>
                    <path fill={'#F3F3F6'} d='M139.541,364.461c-27.442-14.419-46.18-44.484-56.413-73.321c-11.163-32.558-32.558-63.256-32.558-98.605c-0.465-27.907,13.953-53.023,29.767-74.884c8.837-12.093,20.93-20,35.349-24.186c15.814-5.116,33.953-5.116,50.233-6.047c20-1.395,40-3.256,60-2.326c56.744,2.326,103.721,36.744,147.442,69.302c52.558,40,93.023,87.442,116.279,149.767c11.628,32.093,26.977,74.419,17.674,108.372c-2.791,12.093-8.372,28.372-18.605,36.279c-8.372,6.047-20,4.651-29.767,2.326c-23.256-4.651-42.326-8.837-66.512-9.302c-18.605-0.465-38.605-2.791-57.674,0.93c-16.279,2.791-32.093,9.767-48.837,12.558c-30.698,5.116-70.698-5.116-94.884-25.116c-17.209-14.419-28.837-35.349-40.465-54.419C147.779,371.14,144.192,367.717,139.541,364.461z' />
                  </g>
                  <g>
                    <path fill={'#F3F3F6'} d='M167,486.648c56,0,114-2.761,170,0.345c4.667,0.345,38.667,1.726,40,4.832c1.333,5.523-48,6.213-52.667,6.213c-33.333,1.381-66.667,1.726-100,1.726c-18,0-38,0.69-55.333-2.416c-8-1.035-24-4.142-16.667-7.594C155.667,488.374,163,486.648,167,486.648z' />
                  </g>
                </g>
                <g>
                  <circle fill={'#F3F3F6'} cx='324.995' cy='93.52' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='344.996' cy='93.52' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='364.997' cy='93.52' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='384.999' cy='93.52' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='405' cy='93.52' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='425.001' cy='93.52' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='445.003' cy='93.52' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='465.004' cy='93.52' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='324.995' cy='113.522' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='344.996' cy='113.522' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='364.997' cy='113.522' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='384.999' cy='113.522' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='405' cy='113.522' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='425.001' cy='113.522' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='445.003' cy='113.522' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='465.004' cy='113.522' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='324.995' cy='133.523' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='344.996' cy='133.523' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='364.997' cy='133.523' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='384.999' cy='133.523' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='405' cy='133.523' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='425.001' cy='133.523' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='445.003' cy='133.523' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='465.004' cy='133.523' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='324.995' cy='153.524' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='344.996' cy='153.524' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='364.997' cy='153.524' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='384.999' cy='153.524' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='405' cy='153.524' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='425.001' cy='153.524' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='445.003' cy='153.524' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='465.004' cy='153.524' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='324.995' cy='173.525' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='344.996' cy='173.525' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='364.997' cy='173.525' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='384.999' cy='173.525' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='405' cy='173.525' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='425.001' cy='173.525' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='445.003' cy='173.525' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='465.004' cy='173.525' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='324.995' cy='193.527' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='344.996' cy='193.527' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='364.997' cy='193.527' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='384.999' cy='193.527' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='405' cy='193.527' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='425.001' cy='193.527' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='445.003' cy='193.527' r='4.5' />
                  <circle fill={'#F3F3F6'} cx='465.004' cy='193.527' r='4.5' />
                </g>
                <g>
                  <circle fill={'#F3F3F6'} cx='75.259' cy='359.751' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='91.833' cy='359.751' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='108.406' cy='359.751' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='124.979' cy='359.751' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='141.552' cy='359.751' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='158.125' cy='359.751' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='174.698' cy='359.751' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='191.271' cy='359.751' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='75.259' cy='376.324' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='91.833' cy='376.324' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='108.406' cy='376.324' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='124.979' cy='376.324' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='141.552' cy='376.324' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='158.125' cy='376.324' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='174.698' cy='376.324' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='191.271' cy='376.324' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='75.259' cy='392.897' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='91.833' cy='392.897' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='108.406' cy='392.897' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='124.979' cy='392.897' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='141.552' cy='392.897' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='158.125' cy='392.897' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='174.698' cy='392.897' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='191.271' cy='392.897' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='75.259' cy='409.47' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='91.833' cy='409.47' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='108.406' cy='409.47' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='124.979' cy='409.47' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='141.552' cy='409.47' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='158.125' cy='409.47' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='174.698' cy='409.47' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='191.271' cy='409.47' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='75.259' cy='426.043' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='91.833' cy='426.043' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='108.406' cy='426.043' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='124.979' cy='426.043' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='141.552' cy='426.043' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='158.125' cy='426.043' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='174.698' cy='426.043' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='191.271' cy='426.043' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='75.259' cy='442.617' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='91.833' cy='442.617' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='108.406' cy='442.617' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='124.979' cy='442.617' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='141.552' cy='442.617' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='158.125' cy='442.617' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='174.698' cy='442.617' r='3.729' />
                  <circle fill={'#F3F3F6'} cx='191.271' cy='442.617' r='3.729' />
                </g>
                <g>
                  <path fill={'#E8EAED'} d='M113.958,96.136c0.714-2.856,27.843-19.276,27.843-19.276h111.371l26.415,34.268l17.885,40.693h162.735l34.268,19.276c0,0,0,184.904,0,187.046s-7.853,39.979-7.853,39.979s-28.557,9.995-30.698,10.709c-2.142,0.714-218.643-1.428-221.406,0s-99.142,0.15-99.142,0.15l-21.417-28.707V96.136z' />
                  <path fill={'#F3F3F6'} d='M113.958,96.136c0.714-2.856,27.843-19.276,27.843-19.276h111.371l26.415,34.268l17.885,40.693h162.735l34.268,19.276c0,0,0,184.904,0,187.046s-7.853,39.979-7.853,39.979s-28.557,9.995-30.698,10.709c-2.142,0.714-218.643-1.428-221.406,0s-99.142,0.15-99.142,0.15l-21.417-28.707V96.136z' />

                  <linearGradient id='SVGID_1_' gradientUnits='userSpaceOnUse' x1='578.2859' y1='663.5294' x2='117.464' y2='397.4737' gradientTransform='matrix(1 0 0 1 -55.035 -256.5646)'>
                    <stop offset='0' stopColor={'#00CCF0'} />
                    <stop offset='0.2056' stopColor={'#14AADC'} />
                    <stop offset='0.3987' stopColor={'#13A7DA'} />
                    <stop offset='0.5656' stopColor={'#119CD4'} />
                    <stop offset='0.7228' stopColor={'#0C8AC9'} />
                    <stop offset='0.8731' stopColor={'#0771BA'} />
                    <stop offset='1' stopColor={'#0056AA'} />
                  </linearGradient>
                  <path fill={'url(#SVGID_1_)'} d='M459.875,142.616H323.123c-10.468,0.004-20.064-5.838-24.862-15.142l-19.257-37.108c-8.018-15.507-24.047-25.216-41.501-25.146h-86.09c-25.807,0.011-46.726,20.929-46.737,46.737v261.352c0.011,25.807,20.929,46.726,46.737,46.737h308.461c25.807-0.011,46.726-20.929,46.737-46.737V189.352C506.6,163.545,485.682,142.623,459.875,142.616z M123.372,112.051c0.044-15.471,12.575-27.998,28.042-28.042h86.09c10.468-0.007,20.064,5.838,24.862,15.142l19.257,37.108c1.194,2.238,2.538,4.393,4.02,6.452H123.372V112.051z M487.916,373.399c-0.044,15.471-12.575,27.998-28.042,28.042H151.413c-15.467-0.044-27.998-12.571-28.042-28.042V161.31h190.59c0.92,0.018,1.833-0.106,2.713-0.372c2.169,0.296,4.352,0.453,6.543,0.467h136.657c15.467,0.044,27.998,12.571,28.042,28.042V373.399z' />
                </g>
                <circle fill={'#FFFFFF'} cx='559.182' cy='492.673' r='2.066' />
              </svg>
            </Grid>
            {showAddButton ?
              <>
                <Grid item>
                  <Typography variant={'h5'}>No {field}s Yet</Typography>
                </Grid>
                <Grid item>
                  <Button className={classes.buttonPadding} color={'primary'} variant={'contained'} onClick={openDialog}>Add {field}</Button>
                </Grid>
              </>
              :
              <Grid item>
                <Typography variant={'h5'}>No {field}s</Typography>
              </Grid>
            }
          </>
        }

      </MuiThemeProvider>
    </Grid>
  );
};

export default EmptyTable;
