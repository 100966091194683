import {makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@material-ui/core';
import {get} from 'lodash';
import * as numeral from 'numeral';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  border: {
    border: `1px solid ${theme.colors.grey.light}`,
    width: '50%'
  },
  stripedRow: {backgroundColor: theme.colors.grey.ultraLight},
}));

const ProductVariantPriceTable = ({
  product,
  currencySymbol
}) => {

  const classes = useStyles();
  const quantities = get(product, 'variantsTable[0].priceBreaks', []).map((pb) => pb.quantity);
  const tableData = get(product, 'variantsTable', []);
  
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.border}>Color</TableCell>
            <TableCell className={classes.border}>Size</TableCell>
            {quantities.map((quantity, index) => <TableCell className={classes.border} key={index}>{quantity}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((row, index) => {
            const priceBreaks = get(row, 'priceBreaks', []);
            const colors = get(row, 'colors', []);
            const sizes = get(row, 'sizes', []);

            return (
              <TableRow key={row._id} className={index % 2 === 0 ? classes.stripedRow : ''}>
                <TableCell className={classes.border}>
                  <Typography variant={'body2'} style={{textTransform: 'capitalize'}}>{colors.map((color) => color.name.toLowerCase()).join(', ')}</Typography>
                </TableCell>
                <TableCell className={classes.border}>
                  <Typography variant={'body2'}>{sizes.map((s) => s.name).join(', ')}</Typography>
                </TableCell>
                {priceBreaks.map((priceBreak) =>
                  (<TableCell className={classes.border} key={priceBreak.quantity}>{currencySymbol}{numeral(priceBreak.price).format('0,0.00')}</TableCell>))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );

};
export default ProductVariantPriceTable;
