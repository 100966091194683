// TODO: fix eslint disable
/* eslint-disable no-shadow */

import React, {useEffect, useState} from 'react';
import Table from '@material-ui/core/Table';
import {get, sortBy, has} from 'lodash';
import * as numeral from 'numeral';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import {useSelector} from 'react-redux';
import TableBody from '@material-ui/core/TableBody';
import {FormattedCurrency} from '../FormattedCurrency';
import TableFooter from '@material-ui/core/TableFooter';

const SalesPerCustomerRow = ({data}) => (
    <TableRow>
      <TableCell>{get(data, 'customer.name', '-')}</TableCell>
      <TableCell align={'right'}>
        <FormattedCurrency>
          {get(data, 'totals.totalSales', 0)}
        </FormattedCurrency>
      </TableCell>
      <TableCell align={'right'}>
        {numeral(get(data, 'totals.percentOfTotal', 0) * 100).format('0.0')}%
      </TableCell>
    </TableRow>
  );

function SalesPerCustomerTable() {
  const currencySymbolSelector = (state) => get(state, 'authReducer.userData.company.currencySymbol');
  const reportDataSelector = (state) => state.reportingReducer.reportData;
  const currencySymbol = useSelector(currencySymbolSelector);
  const reportData = useSelector(reportDataSelector);

  const [salesPerCustomer, setSalesPerCustomer] = useState([]);

  useEffect(() => {
    const salesPerCustomer = get(reportData, 'data', []);

    if (has(salesPerCustomer, '[0].customer._id')) {
      setSalesPerCustomer(sortBy(salesPerCustomer, (customerSales) => {
        const percentOfTotal = get(customerSales, 'totals.percentOfTotal', 0);
        return -1 * percentOfTotal;
      }));
    }

  }, [reportData]);

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              Customer
            </TableCell>
            <TableCell align={'right'}>
              Total ({currencySymbol}) (Ex Tax)
            </TableCell>
            <TableCell align={'right'}>
              % of Sales
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {salesPerCustomer.map((data) => <SalesPerCustomerRow key={data.customer._id} data={data} />)}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell>Total</TableCell>
            <TableCell align={'right'}>
              <FormattedCurrency>
                {get(reportData, 'totals.totalSales', 0)}
              </FormattedCurrency>
            </TableCell>
            <TableCell align={'right'}>
              100%
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </>
  );
}

export default SalesPerCustomerTable;
