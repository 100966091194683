// TODO: fix eslint disable
/* eslint-disable no-shadow */

import React, {forwardRef, useEffect, useRef, useState} from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import './view-tabs.scss';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {HoopsButton} from '../../../componentsLib';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HomeIcon from '@mui/icons-material/Home';
import {HoopsMenuItem} from '../HoopsMenuItem';
import {HoopsTextField} from '../../../componentsOld/HoopsTextField';
import {useResizeObserver} from '../../../hooks';
import {Tooltip} from '@material-ui/core';

type View = { id: string, name: string }

interface ViewTabsProps {
  currentView: string;
  editingView: string | null;
  views: View[];
  allowAddViews: boolean;
  onAddView: (type: 'TABLE') => Promise<string>;
  onChangeCurrentView: (id: string) => void;
  onDeleteView: (id: string, name: string) => Promise<void>;
  onRenameView: (id: string, name: string) => Promise<void>;
}

export const ViewTabs = ({
  currentView,
  editingView,
  views: sourceViews = [],
  allowAddViews,
  onAddView,
  onChangeCurrentView,
  onDeleteView,
  onRenameView,
}: ViewTabsProps) => {
  const [editing, setEditing] = useState<null | string>(editingView);
  const overallSizeRef = useRef(null);
  const tabsContainerRef = useRef<any>(null);
  const {width, widthChange}: any = useResizeObserver(overallSizeRef.current);
  useResizeObserver(tabsContainerRef.current);
  const [views, setViews] = useState<View[]>(sourceViews);
  const [visibleTabs, setVisibleTabs] = useState(views.length);

  // If the views change, or the number of views changes, we recalculate what can be seen
  useEffect(() => {
    setViews(sourceViews);
    if (visibleTabs !== sourceViews.length) {
      setVisibleTabs(sourceViews.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceViews, sourceViews.length]);

  // If the view being edited changes, we set the edit mode
  useEffect(() => {
    setEditing(editingView);
  }, [editingView]);

  // We're going to use the browser to check when tabs are not visible. If a tab renders on the
  // second row, we will detect it and hide it.
  if (tabsContainerRef.current) {
    const tabs: Element = tabsContainerRef.current;
    const top = tabs.firstElementChild?.getBoundingClientRect()?.top ?? 0;
    if (top > 0) {
      const vis = Array.from(tabs.children).findIndex((node) => node.getBoundingClientRect()?.top > top);
      if (vis >= 0 && vis != visibleTabs) {
        setVisibleTabs(Math.max(vis, 1));
      }
    }
  }

  // If the width has increased, and we are hiding some views, recalculate what can be seen
  useEffect(() => {
    if (widthChange > 0 && visibleTabs < sourceViews.length) {
      setVisibleTabs(sourceViews.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, widthChange]);

  // If the current view is not visible we will
  // 1. Make it the first one that cannot be seen and re-render
  // 2. If it stil cannot be seen we will make it the last one that can be seen and re-render
  const currentIndex = views.findIndex((view) => view.id == currentView);
  if (currentIndex > visibleTabs) {
    const v = [...views];
    v.splice(currentIndex, 1);
    v.splice(visibleTabs, 0, views[currentIndex]);
    setViews(v);
  } else if (currentIndex === visibleTabs) {
    const v = [...views];
    v.splice(visibleTabs - 1, 2, views[visibleTabs], views[visibleTabs - 1]);
    setViews(v);
  }
  const shownViews = views.slice(0, visibleTabs);
  const extraViews = views.slice(visibleTabs);

  const [addViewMenuAnchorEl, setAddViewMenuAnchorEl] = useState<null | HTMLElement>(null);
  const handleOpenAddViewMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAddViewMenuAnchorEl(event.currentTarget);
  };
  const handleCloseAddViewMenu = () => {
    setAddViewMenuAnchorEl(null);
  };
  const handleAddTableView = async () => {
    handleCloseAddViewMenu();
    const res = await onAddView('TABLE');
    if (res) {
      setTimeout(() => setEditing(res));
    }
  };

  return (
    <div className={'jobs-view-tabs'} ref={overallSizeRef}>
      <div className={'view-tabs-container'} ref={tabsContainerRef}>
        {shownViews.map((view) =>
          <ViewTab key={view.id}
            onRename={onRenameView} onDelete={onDeleteView} setEditing={setEditing}
            selected={currentView === view.id} editing={editing === view.id}
            onClick={onChangeCurrentView}
            {...view} />
        )}
      </div>
      {extraViews.length > 0 && <MoreViewsMenu onClick={onChangeCurrentView} views={extraViews} />}

      <Tooltip
        title='Maximum views reached. Please remove a view, or Upgrade your subscription.'
        arrow
        disableHoverListener={allowAddViews}
        disableTouchListener={allowAddViews}
        leaveDelay={1500}
      >
        <div className={'add-view-button'}>

          <HoopsButton
            purpose='primary'
            startIcon={<AddCircleOutlineIcon />}
            onClick={handleOpenAddViewMenu}
            disabled={!allowAddViews}
          >Add View</HoopsButton>

          <Menu
            id='basic-menu'
            anchorEl={addViewMenuAnchorEl}
            open={Boolean(addViewMenuAnchorEl)}
            onClose={handleCloseAddViewMenu}
            MenuListProps={{'aria-labelledby': 'basic-button',}}
          >
            {
              // @ts-ignore
              <HoopsMenuItem key={'add-table-view'} onClick={handleAddTableView} icon='view_headline' text={'Table'} />
            }
          </Menu>

        </div>
      </Tooltip>

    </div>
  );
};

interface MoreViewsMenuProps {
  views: View[];
  onClick: (id: string) => void;
}

const MoreViewsMenu = forwardRef<HTMLDivElement, MoreViewsMenuProps>(({
  onClick,
  views,
}, ref) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const _onClick = (id: string) => {
    onClick(id);
    handleClose();
  };

  return (
    <div ref={ref} className={'more-views'}>
      <HoopsButton purpose='primary' endIcon={<ArrowDropDownIcon />} onClick={handleClick}>More</HoopsButton>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{'aria-labelledby': 'basic-button',}}
      >{views.map((view) => <MenuItem key={view.id} onClick={() => _onClick(view.id)}>{view.name}</MenuItem>)}
      </Menu>
    </div>
  );
});

const textFieldStyles = {
  position: 'absolute',
  left: '9px',
  paddingRight: '19px',
  zIndex: 1,
  top: '6px',
};

const textFieldInputStyles = {
  fontSize: 14,
  height: 4,
  paddingLeft: 7,
  paddingRight: 7,
};

interface ViewTabProps {
  id: string;
  editing: boolean;
  selected: boolean,
  onDelete: (id: string, name: string) => Promise<void>;
  onRename: (id: string, name: string) => Promise<void>;
  setEditing: (id: string | null) => void;
  onClick: (id: string) => void;
}

const ViewTab = ({
  id,
  editing,
  selected,
  setEditing,
  onClick,
  onRename,
  onDelete,
  name: initialName
}: View & ViewTabProps) => {
  const [name, setName] = useState(initialName);
  const handleRename = async () => {
    if (onRename) {
      await onRename(id, name);
    }
    setEditing(null);
  };

  const handleClick = (event: any) => {
    if (id != 'home' && event.detail === 2) {
      setEditing(id);
    } else {
      onClick(id);
    }
  };

  return (
    <div className={`view-tab ${id === 'home' ? 'home' : ''} ${selected ? 'selected' : ''}`} onClick={handleClick}>
      {id === 'home' &&
        <HomeIcon className={'icon-home'} />
      }
      <div>{name}</div>
      {editing &&
        <HoopsTextField
          autoFocus
          selectOnFocus
          size='small'
          defaultValue={name}
          onBlur={handleRename}
          onChange={(e: any) => {
            setName(e.target.value);
          }}
          onKeyDown={async (e: any) => {
            if (e.key === 'Enter') {
              e.stopPropagation();
              await handleRename();
            }
            if (e.key === 'Escape') {
              setEditing(null);
            }
          }}
          style={textFieldStyles}
          inputProps={{style: textFieldInputStyles}}
        />
      }
      {id === 'home' && <div className={'view-tab-menu'} />}
      {id !== 'home' && <ViewTabMenu onDelete={() => onDelete(id, name)} setEditing={setEditing} id={id} />}
    </div>
  );
};

interface ViewTabMenuProps {
  id: string,
  setEditing: (id: string | null) => void,
  onDelete?: (id: string) => Promise<void>
}

const ViewTabMenu = ({
  id,
  setEditing,
  onDelete
}: ViewTabMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLLIElement> | React.MouseEvent<Document>) => {
    e.stopPropagation();
    setAnchorEl(null);
  };
  const handleRename = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLLIElement> | React.MouseEvent<Document>, id: string) => {
    handleClose(e);
    setEditing(id);
  };

  const handleDelete = async (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLLIElement> | React.MouseEvent<Document>, id: string) => {
    handleClose(e);
    if (onDelete) {
      await onDelete(id);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className={'view-tab-menu'} onClick={handleClick}>
        <IconButton
          size={'small'}
          aria-label='more'
          id='long-button'
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup='true'

        >
          <MoreHorizIcon />
        </IconButton>
        <Menu
          id='long-menu'
          MenuListProps={{'aria-labelledby': 'long-button',}}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={(e) => handleRename(e, id)}>
            Rename
          </MenuItem>
          <MenuItem onClick={(e) => handleDelete(e, id)}>
            Delete
          </MenuItem>
        </Menu>
      </div>
    </ClickAwayListener>
  );
};
