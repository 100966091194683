import {Grid} from '@material-ui/core';
import {get} from 'lodash';
import numeral from 'numeral';
import React from 'react';
import {useSelector} from 'react-redux';

export const FormattedCurrency = ({children, justify = 'flex-end', ...params}) => {
  const currencySymbolSelector = (state) => get(state, 'authReducer.userData.company.currencySymbol');
  const currencySymbol = useSelector(currencySymbolSelector);

  return (
    <>
      <Grid container justifyContent={justify} spacing={1} {...params}>
        <Grid item>{currencySymbol}</Grid>
        <Grid item>{numeral(children).format('0,0.00')}</Grid>
      </Grid>
    </>
  );
};

export default FormattedCurrency;
