import {TextField} from '@material-ui/core';
import {debounce} from 'lodash';
import React, {useEffect, useState, useMemo} from 'react';

export const HoopsSearchDataFilter = ({
    label = '',
    placeholder = 'Search',
    value = '',
    inputProps = {},
    onChange = () => null
}) => {
    const [_value, _setValue] = useState('');

    const debouncedOnClose = useMemo(
        () => debounce(onChange, 500)
        , [onChange]);
    useEffect(() => () => {
            debouncedOnClose.cancel();
        }, [debouncedOnClose]);

    const handleChange = (event) => {
        const v = event.target.value;
        _setValue(v);
        debouncedOnClose(v);
    };

    useEffect(() => {
        if (value) {
            _setValue(value);
        }

    }, [value]);

    return (
        <TextField autoFocus={true} label={label} type='search' size='small' value={_value} placeholder={placeholder} variant='outlined' {...inputProps} onChange={handleChange} />
    );
};

export default HoopsSearchDataFilter;
