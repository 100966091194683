// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {useQuery} from '@apollo/client';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {gql} from 'apollo-boost';
import {get} from 'lodash';
import React from 'react';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const getOptionLabel = (item) => {
  switch (item.entityType) {
    case 'Job':
      return `Job #${get(item, 'entityData.number', 'NOT SET')} | ${get(item, 'entityData.customer.name', 'NOT SET')}`;

    default:
    case 'Quote':
      return `Quote #${get(item, 'entityData.number', 'NOT SET')} | ${get(item, 'entityData.customer.name', 'NOT SET')}`;
  }
};

const getEntities = gql`
  query {
    jobs(page: 1, pageLimit: 100) {
      page
      recordsFiltered
      recordsTotal
      data {
        _id
        number
        title
        customer {
          name
        }
      }
    }

    quotes(page: 1, pageLimit: 100) {
      page
      recordsFiltered
      recordsTotal
      data {
        _id
        number
        customer {
          name
        }
      }
    }
  }
`;

export default ({label, placeholder, value = [], ChipProps = {color: 'primary', size: 'small'}, ...props}) => {

  const {data} = useQuery(getEntities);
  let merged = [];

  const _placeholder = value.length ? null : placeholder;

  if (data) {
    merged = [
      ...data.jobs.data.map((entityData) => ({entityData, entityId: entityData._id, entityType: 'Job'})),
      ...data.quotes.data.map((entityData) => ({entityData, entityId: entityData._id, entityType: 'Quote'}))
    ].sort(
      (a, b) => getOptionLabel(b).localeCompare(getOptionLabel(a))
    );
  }

  return (
    <Autocomplete
      multiple
      limitTags={2}
      options={merged}
      disableCloseOnSelect
      label={label}
      value={value}
      ChipProps={ChipProps}
      groupBy={(option) => option.entityType}
      getOptionLabel={(option) => getOptionLabel(option)}
      onChange={(event, value) => {
        props.onChange(value ? value : null);
      }}
      getOptionSelected={(option, value) => option.entityData._id === value.entityData._id}
      renderOption={(option, {selected}) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{marginRight: 8}}
            checked={selected}
          />
          {getOptionLabel(option)}
        </React.Fragment>
      )}

      renderInput={(params) => (
        <TextField {...params} variant='standard' label={label} placeholder={_placeholder} InputLabelProps={{shrink: true,}} />
      )}
    />
  );
};
