import React from 'react';
import moment from 'moment';

export const FormatDate = (props) => {

    const dateFormat = (props.dateFormat ? props.dateFormat : 'DD MMM YYYY');

    const dateReturn = moment(props.date).format(dateFormat);

    return (
        <>
          {dateReturn === 'Invalid date' ? '' : dateReturn}  
        </>
    );
};
