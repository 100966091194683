import {Divider, Grid, makeStyles} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import LockIcon from '@material-ui/icons/Lock';
import {toUpper} from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Elements, StripeProvider} from 'react-stripe-elements';
import {setModalOpen} from '../actions/modals';
import CheckoutForm from './CheckoutForm';

const useStyles = makeStyles((theme) => ({
  heading: {marginBottom: theme.spacing(1)},
  contentContainer: {
    paddingTop: `${theme.spacing(4)}px !important`,
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7)
  },
  pricingContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%'
  },
  divider: {
    width: '100%',
    marginBottom: theme.spacing(4)
  },
  formContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 56px'
  },
  lockIcon: {color: theme.palette.primary.main}
}));

const TrialSubscriptionModal = () => {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const billingSelector = (state) => state.billingReducer.billing;
  const upcomingInvoiceSelector = (state) => state.billingReducer.upcomingInvoice;
  const billing = useSelector(billingSelector);
  const upcomingInvoice = useSelector(upcomingInvoiceSelector);
  const [trialState, setTrialState] = useState({});

  useEffect(
    () => {
      const {stripeSubscription} = billing;

      const {amount_due, currency} = upcomingInvoice;
      const {trial_end, status} = stripeSubscription;

      const now = moment();
      const trialEnd = moment.unix(trial_end);
      const trialExpired = now.isAfter(trialEnd);

      setModalOpen(trialExpired && status === 'trialing');
      setTrialState({
        amount_due,
        currency
      });
    },
    [billing, upcomingInvoice, setTrialState]
  );

  return (
    <Dialog
      aria-labelledby='customized-dialog-title'
      open={showModal}
      maxWidth={'sm'}
      fullWidth={true}
    >
      <MuiDialogContent className={classes.contentContainer}>
        <Grid container direction={'column'} alignItems={'center'}>
          <Typography className={classes.heading} variant='h5' color='primary'>Time to Subscribe!</Typography>
          <Typography variant='subtitle1' color='textSecondary'>Your trial has expired</Typography>
          <Grid item className={classes.pricingContainer}>
            <Typography variant='subtitle1' color='textPrimary'>{`Total Amount Per Month (${toUpper(trialState.currency)})`}</Typography>
            <Typography variant='h5' color='primary'>{numeral(trialState.amount_due / 100).format('$0,0.00')}</Typography>
          </Grid>
          <Divider className={classes.divider} />
          <Grid item className={classes.formContainer}>
            <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
              <Elements>
                <CheckoutForm checkoutSuccess={() => setShowModal(false)} />
              </Elements>
            </StripeProvider>
            <Grid container direction={'row'} justifyContent={'space-evenly'} alignItems={'center'}>
              <LockIcon className={classes.lockIcon} />
              <Typography color='textSecondary'>Secured by Stripe with bank-level encryption</Typography>
            </Grid>
          </Grid>
        </Grid>
      </MuiDialogContent>
    </Dialog>
  );
};

export default TrialSubscriptionModal;
