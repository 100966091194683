// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React, {Component, Fragment} from 'react';
import ProofFile from './ProofFile';
import {connect} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import {Field, formValueSelector} from 'redux-form';
import Grow from '@material-ui/core/Grow';

function renderFileList({input: {value}, field, onRemoveFile, fieldIndex}) {
  return (
    <Grid key={value._id} item xs={12}>
      <Grow timeout={500} in={true}>
        <ProofFile field={field} file={value} fieldIndex={fieldIndex} onRemoveFile={onRemoveFile} />
      </Grow>
    </Grid>
  );
}

class ProofFileList extends Component {
  handleRemoveFile = (index) => {
    const {fields} = this.props;
    fields.splice(index, 1);
  };

  render() {
    const {fields} = this.props;
    return (
      <Fragment>
        <Grid container spacing={4}>
          {fields.map((field, index) => (
            <Field
              name={`${field}.file`}
              key={index}
              field={field}
              fieldIndex={index}
              onRemoveFile={this.handleRemoveFile}
              component={renderFileList} />
          ))}
        </Grid>
      </Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const selector = formValueSelector(ownProps.meta.form);

  return {proofs: selector(state, 'proofs')};
}

ProofFileList = connect(mapStateToProps, null)(ProofFileList);
export default ProofFileList;
