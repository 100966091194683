// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-shadow */

import {useQuery} from '@apollo/client';
import {TableCell, makeStyles, MenuItem, Select} from '@material-ui/core';
import gql from 'graphql-tag';
import React, {useState, useContext} from 'react';
import SageProductSelect from './SageProductSelect';
import {itemBuilder} from '../../../ui/theme/itemBuilder';
import {ItemBuilderProductAutocomplete} from './ItemBuilderProductAutocomplete';
import {Controller, useFormContext} from 'react-hook-form';
import {HoopsTextField} from '../../HoopsTextField';
import {ItemBuilderContext} from '../../../modules/itemBuilder/ItemBuilderContext';

// is user integrated with sage
const SAGE_INTEGRATION_ACTIVE = gql`
query integrationOne{
  integrationOne(filter:{type:sage}){
      _id
      status
  }
}`;

const useStyles = makeStyles(() => ({...itemBuilder,}));

export const ItemBuilderProductField = ({
  handleProductSelected = () => null,
  productType = 'hoops',
  setProductType = () => null,
  defaultValue,
  field,
  rowIdx
}) => {
  const classes = useStyles();

  const [isSageActive, setIsSageActive] = useState(false);

  const {control} = useFormContext();
  const {onPriceChanged} = useContext(ItemBuilderContext);

  // check for sage integration
  useQuery(SAGE_INTEGRATION_ACTIVE, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (result) => setIsSageActive(result && result.integrationOne && result.integrationOne.status === 'active'),
  });

  return (
    <>
      <TableCell scope='row' className={classes.tableCell} >
        <Select
          variant='outlined'
          margin='dense'
          size='small'
          style={{minWidth: 100}}
          className={classes.selectStyles}
          value={productType}
          onChange={(e) => setProductType(e.target.value)}
        >
          <MenuItem value='hoops'>Hoops</MenuItem>
          {isSageActive && <MenuItem value='sage'>Sage</MenuItem>}
          <MenuItem value='oneOff'>One Off</MenuItem>
        </Select>

      </TableCell>
      <TableCell scope='row' className={classes.tableCell} style={{width: '20%'}}>
        {productType === 'hoops' &&
          <ItemBuilderProductAutocomplete
            defaultValue={field.productName ? field.productName : defaultValue}
            isSageActive={isSageActive}
            onSelect={handleProductSelected}
            productId={field.productId}
          />
        }

        {productType === 'sage' &&
          <SageProductSelect
            onSelect={(...args) => handleProductSelected(...args)}
          />
        }
        {productType === 'oneOff' ?
          <Controller
            defaultValue={field.productName}
            name={`variations[${rowIdx}].productName`}
            render={(field) => (
              <HoopsTextField
                responsive
                {...field}
                onChange={(e) => { onPriceChanged(); field.onChange(e); }}
              />
            )}
            control={control}
          />
          :
          <Controller
            defaultValue={field.productName}
            name={`variations[${rowIdx}].productName`}
            render={(field) => (
              <input
                type='hidden'
                {...field}
              />)}
            control={control}
          />
        }
        <Controller
          name={`variations[${rowIdx}].productId`}
          defaultValue={field.productId}
          render={(field) => (
            <input
              type='hidden'
              {...field}
            />)}
          control={control}
        />
      </TableCell>
    </>

  );
};
