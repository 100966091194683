import React from 'react';
import {Grid, TextField, Box, IconButton, Typography} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import FilePreview from './FilePreview';

export const FileUploadEdit = ({
  file,
  onFileUpdate = () => null,
  onFileRemove = () => null
}) => (
  <Grid container key={file._id} spacing={3} wrap='nowrap'>
    <Grid item xs={6} zeroMinWidth>
      <Box height='100%' display='flex' alignItems='center' >
        <FilePreview
          file={file}
          options={{thumbWidth: 50, thumbHeight: 50}}
        />
        <Typography variant={'body2'} noWrap style={{paddingLeft: 8}}>{file.filename}</Typography>
      </Box>
    </Grid>
    <Grid item xs={true}>
      <Box height='100%' display='flex' alignItems='center'>
        <TextField size='small' fullWidth variant='outlined' onBlur={(ev) => onFileUpdate({...file, description: ev.target.value})} placeholder='Description' />
        <IconButton aria-label='delete' color='secondary' onClick={() => onFileRemove(file)}>
          <DeleteIcon />
        </IconButton>
      </Box>
    </Grid>
  </Grid>
);
