// TODO: fix eslint disable
/* eslint-disable no-shadow */

import {debounce} from 'lodash';
import React, {useCallback} from 'react';
import {HoopsTextField} from '../HoopsTextField';
import theme from '../../ui/theme/index';

const sendQuery = (filter, searchString, onChange, chips, onChipsChange) => {
    onChange({...filter, q: searchString});
    const chipPayload = {...chips};

    if (!searchString) {
        delete chips.q;
        onChipsChange(chips);
        return;
    }

    chipPayload.q = [{
        value: searchString,
        label: searchString,
        onRemove: (filter) => ({
                ...filter,
                q: undefined,
            })
    }];

    onChipsChange(chipPayload);
};

export const HoopsQueryFilterSearch = ({
    placeholder = 'Search',
    filter = {},
    chips = {},
    onChange = () => null,
    onChipsChange = () => null,
}) => {
  // TODO: FIX HOOK DEPENDENCIES
  // eslint-disable-next-line react-hooks/exhaustive-deps
    const delayedQuery = useCallback(debounce((filter, searchString, onChange, chips, onChipsChange) => sendQuery(filter, searchString, onChange, chips, onChipsChange), 500), []);

    const handleChange = (event) => {
        const searchString = event.target.value;
        delayedQuery(filter, searchString, onChange, chips, onChipsChange);
    };

    return (
        <HoopsTextField
          defaultValue={filter?.q}
            fullWidth={false}
            autoFocus={true}
            type='search'
            placeholder={placeholder}
            onChange={handleChange}
            style={{paddingBottom: theme.spacing(2),}}
        />
    );
};

export default HoopsQueryFilterSearch;
