// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import {Chip, Grid, Link as MuiLink, makeStyles, Tooltip, Typography} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AmpStoriesIcon from '@material-ui/icons/AmpStories';
import {get} from 'lodash';
import moment from 'moment';
import React from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {kanban} from '../../ui/theme/kanban';
import FormattedCurrency from '../reporting/FormattedCurrency';
import HoopsQueryTableCellDateTime from './HoopsQueryTableCellDateTime';

const useStyles = makeStyles((theme) => (
    {...kanban,}
));

export const HoopsQueryKanbanCardQuote = ({item = {}}) => {
    const classes = useStyles();
    const now = moment();
    const dueDate = get(item, 'deadlineAt');
    const daysToDueDate = now.diff(dueDate, 'days');
    const usersSelector = (state) => state.authReducer.users;
    const users = useSelector(usersSelector);

    // handle due date styling
    const dueDateClass = (param) => {
        if (param > -7 && param < 0) {return classes.orangeText;}
        if (param > 0) {return classes.redText;}
        return classes.greyText;
    };

    const TooltipContent = ({siblings, number}) => {
        const siblingRender = siblings.map((sibling) =>
            number !== sibling.number && <Typography key={sibling.number} variant='body2'>- Quote {sibling.number}</Typography>
        );
        return (
            <>
                <div style={{width: 200, padding: 8}}>
                    <Typography variant='body1'>Grouped with others:</Typography>
                    {siblingRender}
                </div>
            </>
        );
    };

    return (
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
            <Grid item xs={12} className={classes.itemTitle}>
                <Typography variant='body1'>
                    <MuiLink component={Link} underline={'none'} style={{verticalAlign: item.siblings.length > 0 && 'super', paddingRight: item.siblings.length > 0 && '8px'}} color={'primary'} to={`/quotes/${item._id}/view`}>
                        {item.number}
                    </MuiLink>
                    {item.isSibling && <Tooltip title={<TooltipContent siblings={item.siblings} number={item.number} />}><AmpStoriesIcon color={'secondary'} style={{paddingTop: '2px'}} /></Tooltip>}
                </Typography>

                <Grid item xs={12} className={classes.itemTextObject}>
                    <Typography variant='caption' display='block'>{`${get(item, 'contact.fullName')} (${get(item, 'customer.name')})`}</Typography>
                </Grid>
                <Grid item xs={12} className={classes.itemTextObject}></Grid>
                <Typography variant='caption' display='block' className={classes.greyText}>{get(users.find((el) => el._id === item.quoteOwnerId), 'fullName')}</Typography>
            </Grid>
            {
                item.job &&
                <Grid item xs={12} className={classes.itemTextObject}>
                    <Typography variant='caption'>
                        Job:
                        <MuiLink rowdata-intercom-target={'job-link'} component={Link} underline={'none'} color={'primary'} style={{paddingLeft: '8px'}}
                            to={`/jobs/${get(item, 'job._id')}/details`}>
                            {get(item, 'job.number')}
                        </MuiLink>
                    </Typography>
                </Grid>
            }

            <Grid item xs={6} className={classes.itemTextObject}>
                <Typography variant='caption' className={classes.greyText}><FormattedCurrency justifyContent='flex-start'>{get(item, 'total')}</FormattedCurrency></Typography>
            </Grid>
            <Grid item xs={6} style={{textAlign: 'end'}} className={classes.itemTextObject}>
                {/* List the dueDate if it is set */}
                {dueDate &&
                    <>
                        <AccessTimeIcon style={{fontSize: 13, top: 2, position: 'relative', marginRight: 4}} className={dueDateClass(daysToDueDate)} />
                        <HoopsQueryTableCellDateTime value={dueDate} showTime={false} variant='caption' className={dueDateClass(daysToDueDate)} />
                    </>
                }
            </Grid>
            <Grid item xs={6}>
                {item.invoice && <Typography variant='caption'>#{get(item, 'invoice.invoiceNumber')}</Typography>}
            </Grid>
            <Grid item xs={6} style={{textAlign: 'end'}}>
                {/* Show chip if we're past the due date */}
                {daysToDueDate > 0 && <Chip size={'small'} label={' OVERDUE '} className={classes.chip} />}
            </Grid>
        </Grid>
    );
};

export default HoopsQueryKanbanCardQuote;
