// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import {Button, Grid, MuiThemeProvider, Paper, Table, TableContainer} from '@material-ui/core';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {hoopsQuerySetRefetch} from '../../actions/hoopsQuery';
import EntityListHero from '../../componentsOld/EntityList/EntityListHero';
import {HoopsQuery, HoopsQueryContext, HoopsQueryEmptyState, HoopsQueryPagination, HoopsQueryTableBody, HoopsQueryTableHeader} from '../../componentsOld/HoopsQuery';
import green from '../../ui/theme/green';
import MarkupsEditModal from './MarkupsEditModal';
import {GQL_MARKUP_PAGINATION} from './markupsQueries';
import MarkupService from './markupsService';
import MarkupsTableActionMenu from './MarkupsTableActionMenu';

export const MarkupsPage = ({
    fixedFilter = {},
    queryStateId = 'markup',
}) => {
    const dispatch = useDispatch();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [markupToEdit, setMarkupToEdit] = useState(null);

    const handleOpenModal = () => {
        setMarkupToEdit({
            _id: 'draftMarkup',
            name: '',
            markupRanges: [{lowerPrice: 0, upperPrice: 0, percentage: 0, quantity: 1}]
        });
        setModalIsOpen(true);
    };

    const handleCloseModal = () => {
        setModalIsOpen(false);
        dispatch(hoopsQuerySetRefetch('markup', true));
        setMarkupToEdit(null);
    };

    const columns = [
        {
            label: 'Markup Name',
            field: 'name',
        }, {
            label: 'Actions',
            align: 'right',
            render: (rowData) => (
                    <MarkupsTableActionMenu
                        rowData={rowData}
                        handleDelete={handleDelete}
                        handleEdit={openEditModal}
                    />
                )
        }
    ];

    const handleSave = (e, formValues, assignedMarkupData) => {

        if (formValues._id !== 'draftMarkup') {
            MarkupService.updateMarkup(formValues._id, formValues)
                .then((payload) => {
                    MarkupService.updateDefaultAMarkupAssignment(assignedMarkupData);
                    handleCloseModal();
                });
        } else {
            MarkupService.createMarkup(formValues)
                .then((payload) => {
                    assignedMarkupData.forEach((el) => { if (el.markupId === 'draftMarkup') { el.markupId = payload.recordId; } });
                    MarkupService.updateDefaultAMarkupAssignment(assignedMarkupData);
                    handleCloseModal();
                });
        }
    };

    const handleDelete = (e, id) => {
        MarkupService.deleteMarkup(id).then((data) => {
            dispatch(hoopsQuerySetRefetch('markup', true));
        });

    };

    const openEditModal = (e, id) => {
        setMarkupToEdit(id);
        setModalIsOpen(true);
    };

    return (
        <Grid container>

            <EntityListHero
                title='Markups'
                subtitle='Completely automate your quoting processess with automated markups.'
                helpArticleUrl='http://help.hoopscrm.com/en/articles/5716472-automatic-markups'
            >
                <MuiThemeProvider theme={green}>
                    <Button
                        data-intercom-target={'markup-create'}
                        onClick={handleOpenModal}
                        color={'primary'}

                        variant={'contained'}>Add Markup</Button>
                </MuiThemeProvider>
                {modalIsOpen && <MarkupsEditModal modalIsOpen={modalIsOpen} onClose={handleCloseModal} onSave={handleSave} markupToEdit={markupToEdit} />}
            </EntityListHero>

            <Grid item xs={12}>
                <HoopsQuery refetchStateId='markup' queryStateId='markupMany' columns={columns} gqlQuery={GQL_MARKUP_PAGINATION} resultField='markupPagination' initialFilter={{}} initialSort={'_ID_ASC'}>
                    <HoopsQueryContext.Consumer>
                        {({items, sort, setSort, filter, setFilter, paging, setPaging, setChips}) => (<>
                                <TableContainer component={Paper}>
                                    <Table style={{minWidth: 'auto'}}>
                                        <HoopsQueryTableHeader hasCheckBox={false} columns={columns} onSort={setSort} sortString={sort} />
                                        <HoopsQueryTableBody rowsPerPage={paging.perPage} rows={items} columns={columns} emptyStateComponent={() => <HoopsQueryEmptyState filter={filter} columns={columns} field={'Markup'} rowsPerPage={paging.perPage} onClearFilter={setFilter} onChipsChange={setChips} onAddItem={() => handleOpenModal()} />} />
                                    </Table>
                                </TableContainer>
                                <HoopsQueryPagination paging={paging} onPaging={setPaging} />
                            </>)}
                    </HoopsQueryContext.Consumer>
                </HoopsQuery>
            </Grid>
        </Grid>

    );
};

export default MarkupsPage;
