
import {Grid, Paper, Typography} from '@mui/material';
import React from 'react';
import {EditableList} from '../../componentsLib/EditableList';

export const CompanyLeadSources = ({
  company,
  saveSettings = () => null
}) => {

  const handleCompanyEdit = (params) => {
      saveSettings({...company, ...params});
  };

  return (
    <Paper sx={{p: 3}}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant='h5'>Lead Sources</Typography>
          <Typography variant='subtitle' >Create a list of lead sources, which can be selected when creating a quote. This will be useful for reporting.</Typography>
        </Grid>
        <Grid item xs={12}>
          <EditableList
            data={company?.leadTypes}
            onChange={handleCompanyEdit}
            fieldName='Lead Sources'
            fieldReference='leadTypes'
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

