// TODO: fix eslint disable
/* eslint-disable no-unused-vars, consistent-return, no-shadow */

import {Divider, Grid, InputAdornment, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import React, {useContext, useEffect} from 'react';
import {Controller, useFieldArray, useFormContext} from 'react-hook-form';
import {HoopsTextField} from '../../../componentsOld/HoopsTextField';
import {decimalPlaces} from '../../../componentsOld/shared/numberUtils';
import {itemBuilder} from '../../../ui/theme/itemBuilder';
import adjustmentLineValues from '../adjustmentLineValues';
import {ItemBuilderContext} from '../ItemBuilderContext';
import {calculatePriceElementBreakdown} from '../itemBuilderUtils';

const useStyles = makeStyles((theme) => ({
    ...itemBuilder,
    hideTable: {display: 'none',}
}));

const ItemBuilderAdjustmentLine = ({currencySymbol = '$',}) => {
    const classes = useStyles();
    const {control, watch} = useFormContext();
    const {fields, remove} = useFieldArray({
        control,
        name: 'additionalCosts'
    });

    const {sexyPrice, setSexyPrice, open} = useContext(ItemBuilderContext);
    const {adjustmentLineName} = adjustmentLineValues;

    const priceElement = watch();
    const values = calculatePriceElementBreakdown(priceElement);
    const sellPricePerUnit = values.sellPricePerUnit;

    const adjustment = fields && fields.find((additionalCost) => additionalCost && additionalCost.name === adjustmentLineName);
    const adjustmentIndex = fields && fields.findIndex((additionalCost) => additionalCost && additionalCost.name === adjustmentLineName);

    // this condition allows the stored adjustment to appear on first load
    if (!sexyPrice && adjustmentIndex > -1 && open) {
        setSexyPrice(sellPricePerUnit);
    }

    useEffect(() => {
        const handleDelete = (index) => {
            fields.length > 1
                ? remove(index)
                : remove();
        };
        setSexyPrice((prev) => {
            if (prev && decimalPlaces(sellPricePerUnit, 2) !== decimalPlaces(prev, 2) && adjustmentIndex > -1) {
                handleDelete(adjustmentIndex);
                return null;
            }
        });
    }, [sellPricePerUnit, adjustmentIndex, setSexyPrice, fields.length, remove]);

    const handleDelete = (index) => {
        fields.length > 1
            ? remove(index)
            : remove();
    };

    const displayTable = !(adjustmentIndex > -1);

    return (
        <div className={`${classes.container} ${displayTable && classes.hideTable}`}>
            <Grid container direction='row' justifyContent='flex-start' alignItems='center' className={classes.itemBuilderHeader}>
                <Grid item><Typography variant='h6' gutterBottom>Adjustment Line</Typography></Grid>
                <Grid item className={classes.infoCell}>
                    <Tooltip arrow placement='top' classes={{tooltip: classes.tooltip}} title={
                        <>
                            {'An adjustment line is added when the sell price is edited.'}
                        </>
                    }><InfoIcon fontSize='small' className={classes.icon} /></Tooltip>
                </Grid>
            </Grid>
            <Divider />
            <Paper elevation={0} className={classes.tableContainer} >
                <Table >
                    {fields.length > 0 &&
                        <TableHead>
                            <TableRow className={classes.tableHeaderRow}>
                                <TableCell style={{width: '75%'}}>Name</TableCell>
                                <TableCell style={{width: '10%'}}>Amount Per Unit</TableCell>
                                <TableCell style={{width: '10%'}}>Total Amount</TableCell>
                                <TableCell style={{width: '5%'}} className={classes.actionCellHeader}>Delete</TableCell>
                            </TableRow>
                        </TableHead>
                    }
                    <TableBody>
                        {fields.map((field, rowIdx) =>
                            //only include the adjustment additional cost using the field name

                             (
                                <>
                                    {
                                        (!field || field.name !== adjustmentLineName)
                                            ? <></>
                                            : <TableRow hover={false} key={field.id} >
                                                <TableCell scope='row' className={classes.tableCell}>
                                                    <Controller
                                                        name={`additionalCosts[${rowIdx}].name`}
                                                        defaultValue={field.name}
                                                        render={(field) => (
                                                            <HoopsTextField
                                                                disabled
                                                                responsive
                                                                {...field}
                                                            />)}
                                                        control={control}
                                                    />
                                                </TableCell>
                                                <TableCell scope='row' className={classes.tableCell}>
                                                    <Controller
                                                        name={`additionalCosts[${rowIdx}].buyPrice`}
                                                        defaultValue={field.buyPrice}
                                                        render={(field) => (
                                                            <HoopsTextField
                                                                disabled
                                                                responsive
                                                                {...field}
                                                                InputProps={{startAdornment: <InputAdornment position={'start'}>{currencySymbol}</InputAdornment>}}
                                                            />
                                                        )}
                                                        control={control}
                                                    />
                                                    <Controller
                                                        name={`additionalCosts[${rowIdx}].ignoredByJob`}
                                                        defaultValue={field.ignoredByJob}
                                                        render={(field) => (
                                                            <input
                                                                type={'hidden'}
                                                                {...field}
                                                            />
                                                        )}
                                                        control={control}
                                                    />
                                                </TableCell>
                                                <TableCell scope='row' className={classes.tableCell}>
                                                    <HoopsTextField
                                                        disabled
                                                        responsive
                                                        InputProps={{startAdornment: <InputAdornment position={'start'}>{currencySymbol}</InputAdornment>}}
                                                        value={adjustment && decimalPlaces(adjustment.sellPrice, 2)}
                                                    />
                                                    <Controller
                                                        name={`additionalCosts[${rowIdx}].sellPrice`}
                                                        defaultValue={field.sellPrice}
                                                        render={(field) => (
                                                            <input
                                                                type='hidden'
                                                                {...field}
                                                            />)}
                                                        control={control}
                                                    />
                                                </TableCell>
                                                <TableCell scope='row' className={classes.actionCell}>
                                                    <DeleteIcon color={'secondary'} style={{cursor: 'pointer'}} onClick={() => handleDelete(rowIdx)} />
                                                </TableCell>
                                            </TableRow>
                                    }
                                </>

                            )
                        )}
                    </TableBody>
                </Table>
            </Paper>
        </div>
    );
};

export default ItemBuilderAdjustmentLine;
