import React from 'react';
import DefaultProductImage from '../../../assets/images/product_placeholder.jpg';

export const ProductImage = ({url, className}) => {
  let imageSrc = DefaultProductImage;
  if (url) {imageSrc = url;}
  return (
    <img src={imageSrc} className={className} alt='Product' />
  );
};
