// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import {MuiThemeProvider, Typography, withStyles} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import {Cancel as CancelIcon, CloudUpload, Error as ErrorIcon} from '@material-ui/icons';
import {findIndex, get} from 'lodash';
import React, {Component, Fragment} from 'react';
import Dropzone from 'react-dropzone';
import {connect} from 'react-redux';
import * as uuidv4 from 'uuid/v4';
import {createFile as _createFile} from '../../actions/files';
import {MAX_FILE_SIZE_BYTES} from '../../constants/file';
import red from '../../ui/theme/red';
import FileUploadCard from './FileUploadCard';

function RejectReason({file, reason, onClick}) {
  const truncate = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  };

  return (
    <Fragment>
      <Grid container spacing={2}>
        <MuiThemeProvider theme={red}>
          <Grid item>
            <ErrorIcon color={'primary'}/>
          </Grid>
          <Grid item>
            <Typography variant={'body1'}>Upload Error</Typography>
          </Grid>
        </MuiThemeProvider>
      </Grid>
      <Grid container spacing={2} justifyContent={'space-between'} alignItems={'center'}>
        <Grid item md={11} xs={10}>
          <MuiThemeProvider theme={red}>
            <Typography color={'primary'} variant={'body2'} style={truncate}>{file.path}</Typography>
          </MuiThemeProvider>
          {reason}
        </Grid>
        <Grid item md={1} xs={2}>
          <IconButton onClick={(e) => {
            e.stopPropagation();
            onClick(file.uuid);
          }}>
            <CancelIcon fontSize={'small'}/>
          </IconButton>
        </Grid>
      </Grid>
    </Fragment>
  );
}

const styles = (theme) => ({
  underlined: {textDecoration: 'underline'},
  hoopsDropzone: {
    borderColor: theme.colors.grey.main,
    borderWidth: 2,
    borderStyle: 'dashed',
    borderRadius: 5,
    padding: theme.spacing(4),
    marginTop: theme.spacing(1)
  }
});

class HoopsDropzone extends Component {
  state = {
    files: [],
    rejectFiles: []
  };

  addFiles = (acceptedFiles, rejectedFiles) => {
    const {files} = this.state;
    acceptedFiles = acceptedFiles.map((file) => {
      file.uuid = uuidv4();
      return file;
    });

    rejectedFiles.forEach((file) => this.rejectFile(file));

    this.setState({
      ...this.state,
      files: files.concat(acceptedFiles)
    });
  };

  removeFile = (uuid) => {
    let {files} = this.state;
    const index = findIndex(files, {uuid});
    files.splice(index, 1);
    this.setState({
      ...this.state,
      files
    });
  };

  handleComplete = (uploadedFile) => {
    const {createFile, onComplete, removeOnComplete = true, mock = false, meta = {}} = this.props;
    const {uuid, file, ...fileData} = uploadedFile;
    if (!mock) {
      fileData.contentType = fileData.contentType
        ? fileData.contentType
        : 'application/octet-stream'; // ensure we have contentType

      createFile({...fileData, ...meta}, (fileObject) => {
        if (onComplete) {
          onComplete({file, ...fileObject, ...meta});
        }
      });
    }

    if (removeOnComplete) {
      setTimeout(() => this.removeFile(uuid), 1500);
    }
  };

  rejectFile = (file, reason) => {
    const {maxSize = MAX_FILE_SIZE_BYTES, acceptReason} = this.props;
    const {rejectFiles} = this.state;
    file.uuid = uuidv4();

    if (file.size > maxSize) {
      file.reason = <RejectReason onClick={this.removeRejectFile} file={file}
                                  reason={`Max upload size is ${maxSize / 1024 / 1024}MB`} />;
    } else {
      file.reason =
        <RejectReason onClick={this.removeRejectFile} file={file} reason={(reason ? reason : acceptReason)}/>;
    }

    rejectFiles.push(file);
    this.setState({
      ...this.state,
      rejectFiles
    });
  };

  removeRejectFile = (uuid) => {
    let {rejectFiles} = this.state;
    const index = findIndex(rejectFiles, {uuid});
    rejectFiles.splice(index, 1);
    this.setState({
      ...this.state,
      rejectFiles
    });
  };

  handlePreDrop = (e) => {
    const {dropzoneOptions, multipleUploadErrorMessage = 'Only one file can be uploaded at a time.'} = this.props;
    const multiple = get(dropzoneOptions, 'multiple', true);
    const numberOfItems = get(e, 'dataTransfer.items.length', 0);
    const items = get(e, 'dataTransfer.items', 0);

    if (!multiple && numberOfItems > 1) {
      e.stopPropagation();
      items.forEach((item) => {
        this.rejectFile(item.getAsFile(), multipleUploadErrorMessage);
      });
    }
  };

  render() {
    const {
classes, accept, maxSize, minHeight = '290px',
      dropzoneOptions, cardList = false, snackbar, s3ObjectParams, multipartFileLabel, uploadMessage, mock = false
} = this.props;
    const {files, rejectFiles} = this.state;

    return (
      <>
        <div style={{width: '100%'}} onDrop={this.handlePreDrop}>
          <Dropzone onDrop={this.addFiles}
                    accept={accept}
                    maxSize={maxSize}
          >
            {({getRootProps, getInputProps}) => (
              <Fragment>
                <Grid container className={classes.hoopsDropzone}
                      style={{minHeight: minHeight || '290px'}} {...getRootProps()}>
                  <input {...getInputProps()}/>
                  {(files.length || rejectFiles.length) ? (
                    <Fragment>
                      {files.length > 0 && <Grid item container spacing={2}>
                        {files.map((file) => (
                          file && <FileUploadCard key={file.uuid}
                            mock={mock}
                                                  file={file}
                                                  onCancel={() => this.removeFile(file.uuid)}
                                                  onComplete={this.handleComplete}
                                                  snackbar={snackbar}
                                                  uploadMessage={uploadMessage}
                                                  multipartFileLabel={multipartFileLabel}
                                                  s3ObjectParams={s3ObjectParams}
                          />
                        ))}
                      </Grid>}
                      {rejectFiles.length > 0 && <Grid item container spacing={2}>
                        {rejectFiles.map((file) => (
                          <Grid key={file.uuid} item xs={(cardList ? 12 : 6)}>
                            <Card onClick={(ev) => ev.stopPropagation()}>
                              <CardContent>
                                {file.reason}
                              </CardContent>
                            </Card>

                          </Grid>))}
                      </Grid>}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Grid container spacing={1} justifyContent={'center'} alignItems={'center'} direction={'column'}>
                        <Grid item>
                          <Avatar>
                            <CloudUpload/>
                          </Avatar>
                        </Grid>
                        <Grid item>
                          <Typography variant={'h6'} color={'textPrimary'}>Drag and Drop</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant={'body2'}
                                      color={'textSecondary'}>your {get(dropzoneOptions, 'multiple', false) ? 'files' : 'file'} here</Typography>
                          <Typography variant={'body2'} color={'textSecondary'}>or <span className={classes.underlined}>click to upload</span></Typography>
                        </Grid>
                      </Grid>
                    </Fragment>
                  )}
                </Grid>
              </Fragment>
            )}
          </Dropzone>
        </div>
      </>

    );
  }
}

function mapDispatchToProps(dispatch) {
  return {createFile: (fileData, callback) => dispatch(_createFile(fileData, callback))};
}

HoopsDropzone = withStyles(styles)(HoopsDropzone);
HoopsDropzone = connect(null, mapDispatchToProps)(HoopsDropzone);

export default HoopsDropzone;
