import React from 'react';
import {registerGlobalStyle} from '../../theme';
import {Row} from '../Layout';
import PropTypes from 'prop-types';

registerGlobalStyle('.page-footer', (theme) => ({
  backgroundColor: theme.colors.background.empty,
  position: 'sticky',
  bottom: 0,
  zIndex: theme.zIndex.appBar,
}));

export function PageFooter({children}) {
  return (
    <Row className={'page-footer'} alignBaseline space>
      {children}
    </Row>
  );
}

PageFooter.propTypes = {children: PropTypes.any.isRequired};
