import React, {useState} from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  MenuItem,
  MuiThemeProvider,
  Typography
} from '@material-ui/core';
import {useDispatch} from 'react-redux';
import theme from '../../../../ui/theme/index';
import DialogActions from '../../DialogActions';
import DialogTitle from '../../DialogTitle';
import {SET_ACTIVE_PURCHASE_ORDER, SET_FORM_PURCHASE_ORDER} from '../../../../actions/purchaseOrders';
import HoopsSelectField from '../../../HoopsSelectField';
import {usePurchaseOrderCountForJobVendors} from '../../../../hooks/api';
import {Avatar, Chip} from '@mui/material';
import {HoopsButton} from '../../../../componentsLib';
import {useHistory} from 'react-router';
import {useLazyQuery} from '@apollo/client';
import {GQL_GET_DRAFT_PO} from '../../../../queries/purchaseOrders';

export const AddPrurchaseOrderModal = ({
  jobId,
  open,
  closeModal = () => null,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [vendorId, setVendorId] = useState();
  const {data} = usePurchaseOrderCountForJobVendors(jobId);
  const vendors = data?.vendorCounts;

  const[getDraftPo, {loading}] = useLazyQuery(GQL_GET_DRAFT_PO, {
    variables: {
      jobId,
      vendorId
    },
    fetchPolicy: 'no-cache',
    onCompleted: (result) => {
      if (result) {
        dispatch({
          type: SET_FORM_PURCHASE_ORDER,
          payload: {
            locked: true,
            ...result.getDraftPO
          }
        });

        // We do this to have additional data available
        dispatch({
          type: SET_ACTIVE_PURCHASE_ORDER,
          payload: result.getDraftPO
        });
        closeModal();
        history.push('/purchase-orders/new');
      }
    },
    
  });

  const handleSave = () => {
    getDraftPo(jobId, vendorId);
  };

  return (
    <Dialog open={open} onClose={closeModal} maxWidth='sm' fullWidth>
      <DialogTitle onClick={closeModal}>
        <Typography variant='h5'>Add Purchase Order</Typography>
      </DialogTitle>
      <DialogContent>
        {(vendors && vendors.length ?
          <HoopsSelectField
            name={'vendorId'}
            defaultValue={''}
            label={'Select Vendor'}
            onChange={(e) => setVendorId(e.target.value)}
          >
            {vendors && vendors.map((vendor, index) => (
              <MenuItem value={vendor._id} key={index} style={{justifyContent: 'space-between'}}>
                {vendor.name}
                <Chip color='primary' avatar={<Avatar>{vendor.count}</Avatar>} label={'Already created.'} size='small' />
              </MenuItem>
            ))}
          </HoopsSelectField>
          :
          <>
            <Typography variant={'h6'}>No linked Vendors</Typography>
            <Typography variant={'body1'} paragraph>
              {'Use the job builder to ensure each product or service that\'s required to be purchased is linked to the correct vendor.'}
            </Typography>
          </>)}
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={'space-between'}>
          <Grid item>
            <Button variant={'text'} color={'secondary'} onClick={closeModal}>Cancel</Button>
          </Grid>
          <Grid item>
            <MuiThemeProvider theme={theme}>
              <HoopsButton
                variant={'contained'}
                color={'primary'}
                loading={loading}
                disabled={(!(vendors && vendors.length)) || loading}
                onClick={handleSave}
              >Next</HoopsButton>
            </MuiThemeProvider>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

