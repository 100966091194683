// TODO: fix eslint disable
/* eslint-disable no-shadow */

import React, {useEffect, useState} from 'react';
import Table from '@material-ui/core/Table';
import {get, has, sortBy} from 'lodash';
import * as numeral from 'numeral';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import {useSelector} from 'react-redux';
import TableBody from '@material-ui/core/TableBody';
import {FormattedCurrency} from '../FormattedCurrency';
import TableFooter from '@material-ui/core/TableFooter';

const GrossMarginPerUserRow = ({data}) => {
  const costs = get(data, 'totals.totalCosts', 0);
  const sales = get(data, 'totals.totalSales', 0);
  const margin = get(data, 'totals.totalMargin', 0);
  const marginPercent = margin / sales * 100;

  return (
    <TableRow key={data.user._id}>
      <TableCell>{get(data, 'user.fullName', '-')}</TableCell>
      <TableCell align={'right'}>
        <FormattedCurrency>
          {costs}
        </FormattedCurrency>
      </TableCell>
      <TableCell align={'right'}>
        <FormattedCurrency>
          {sales}
        </FormattedCurrency>
      </TableCell>
      <TableCell align={'right'}>
        <FormattedCurrency>
          {margin}
        </FormattedCurrency>
      </TableCell>
      <TableCell align={'right'}>
        {numeral(marginPercent).format('0.0')}%
      </TableCell>
    </TableRow>
  );
};

function GrossMarginPerUserTable() {
  const currencySymbolSelector = (state) => get(state, 'authReducer.userData.company.currencySymbol');
  const reportDataSelector = (state) => state.reportingReducer.reportData;
  const currencySymbol = useSelector(currencySymbolSelector);
  const reportData = useSelector(reportDataSelector);
  const [grossMarginPerUser, setGrossMarginPerUser] = useState([]);

  useEffect(() => {
    const grossMarginPerUser = get(reportData, 'data', []);

    if (has(grossMarginPerUser, '[0].user._id')) {
      setGrossMarginPerUser(sortBy(grossMarginPerUser, (data) => ((data?.totals?.totalMargin / data?.totals?.totalSales) * 100)).reverse());
    }

  }, [reportData]);

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              User
            </TableCell>
            <TableCell align={'right'}>
              Total Cost
            </TableCell>
            <TableCell align={'right'}>
              Total Income
            </TableCell>
            <TableCell align={'right'}>
              Gross Margin ({currencySymbol})
            </TableCell>
            <TableCell align={'right'}>
              Gross Margin (%))
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {grossMarginPerUser.map((data) => (<GrossMarginPerUserRow data={data} key={data.user._id} />))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell>Total</TableCell>
            <TableCell align={'right'}>
              <FormattedCurrency>
                {get(reportData, 'totals.totalSales', 0)}
              </FormattedCurrency>
            </TableCell>
            <TableCell align={'right'}>
              100%
            </TableCell>
            <TableCell align={'right'}>
              100%
            </TableCell>
            <TableCell align={'right'}>
              100%
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </>
  );
}

export default GrossMarginPerUserTable;
