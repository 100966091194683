import {useGetPaginatedJobs, useUpdateJob} from '../../hooks/api';
import {useCallback, useMemo} from 'react';
import {cloneDeep, pick, set} from 'lodash';

const fetchJobsParamNames = ['sort', 'filters', 'page', 'pageSize', 'search', 'fixedFilter'];

export const useJobsState = (initialParams) => {
  const {
    data: jobs,
    params: fetchParams,
    setParams: setFetchParams,
    isLoading,
    refetch,
    updateCache,
  } = useGetPaginatedJobs(pick(initialParams, fetchJobsParamNames));
  const {mutateAsync: updateJob} = useUpdateJob();

  // Update the value in the field
  const updateField = useCallback(async ({_id, field, value}) => {
    const index = jobs?.docs.findIndex((r) => r._id === _id);
    if (jobs && index >= 0) {
      const docs = [...jobs.docs];
      const doc = cloneDeep(docs[index]);
      set(doc, field, value);
      docs[index] = doc;
      updateCache({...jobs, docs});
    }
    await updateJob({data: {[field]: value}, id: _id});
    await refetch();
  }, [jobs, updateJob, refetch, updateCache]);

  const updateFetchParams = useCallback((newParams) => {
    setFetchParams((prev) => ({...prev, ...pick(newParams, fetchJobsParamNames)}));
  }, [setFetchParams]);

  return useMemo(() => ({
    jobs,
    fetchParams,
    updateFetchParams,
    isLoading,
    refetch,
    updateField,
  }), [jobs, fetchParams, updateFetchParams, isLoading, refetch, updateField]);
};
