import React, {useRef} from 'react';
import {useWatch, useVisibleObserver} from '../hooks';

export function VisibleObserver({onVisible, onHidden}) {
  const ref = useRef(null);
  const visible = useVisibleObserver(ref);

  useWatch(() => {
    if (visible && onVisible) {
      onVisible();
    } else if (!visible && onHidden) {
      onHidden();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return <span ref={ref}/>;
}
