import {get} from 'lodash';
import {GQL_GET_BILLING} from '../queries/billing';
import {BillingService} from '../servicesOld/BillingService';
import {snackError, snackSuccess} from './action-types/snackbar';

export const SET_UPCOMING_INVOICE = 'SET_UPCOMING_INVOICE';
export const SET_INVOICES = 'SET_INVOICES';
export const SET_PAYMENT_METHODS = 'SET_PAYMENT_METHODS';
export const SET_DEFAULT_PAYMENT_METHOD = 'SET_DEFAULT_PAYMENT_METHOD';
export const SET_BILLING = 'SET_BILLING';

export function getBilling() {
  return function (dispatch) {
    return BillingService.getBilling()
      .then(({data: {billing}}) => {
        dispatch({
          type: SET_BILLING,
          payload: billing
        });
      });
  };
}

export function getUpcomingInvoice() {
    return function (dispatch) {
      return BillingService.getUpcomingInvoice()
        .then(({data: {upcomingInvoice}}) => {
          dispatch({
            type: SET_UPCOMING_INVOICE,
            payload: upcomingInvoice
          });
        });
    };
  }

  export function getInvoices() {
    return function (dispatch) {
      return BillingService.getInvoices()
        .then(({data: {billing: {stripeInvoices}}}) => {
          dispatch({
            type: SET_INVOICES,
            payload: stripeInvoices
          });
        });
    };
  }

  export function addPaymentMethod(token) {
    return function (dispatch) {
      return BillingService.addPaymentMethod(token)
        .then(() => {
          dispatch(snackSuccess('Card Saved.'));
          dispatch(getPaymentMethods({fetchPolicy: 'no-cache'}));
        })
        .catch(() => {
          dispatch(snackError('Something went wrong adding your card. Please try again.'));

        });
    };
  }

  export function deletePaymentMethod(paymentId) {
    return function (dispatch) {
      return BillingService.deletePaymentMethod(paymentId)
        .then(() => {
          dispatch(snackSuccess('Card removed.'));
          dispatch(getPaymentMethods({fetchPolicy: 'no-cache'}));
        })
        .catch(() => {
          dispatch(snackError('Something went wrong removing your card. Please try again.'));

        });
    };
  }

  export function setDefaultPaymentMethod(paymentId) {
    return function (dispatch) {
      return BillingService.setDefaultPaymentMethod(paymentId)
        .then(() => {
          dispatch(snackSuccess('Default card updated.'));
          dispatch(getPaymentMethods({fetchPolicy: 'no-cache'}));
        })
        .catch(() => {
          dispatch(snackError('Something went wrong removing your card. Please try again.'));

        });
    };
  }

  export function getPaymentMethods(options) {
    return function (dispatch) {
      BillingService.getPaymentMethods(options)
        .then(({data: {billing: {stripePaymentMethods, stripeCustomer}}}) => {
          dispatch({
            type: SET_PAYMENT_METHODS,
            payload: stripePaymentMethods
          });
          dispatch({
            type: SET_DEFAULT_PAYMENT_METHOD,
            payload: get(stripeCustomer, '.default_source.id')
          });
        });
    };
  }

  export function retryStripePayment(options) {
    return function (dispatch) {
      BillingService.retryStripePayment(options)
        .then(() => {
          dispatch({type: GQL_GET_BILLING});
        });
    };
  }
