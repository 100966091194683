import {Icon, TableCell, TableRow, Typography} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import React, {useState, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Field, formValueSelector, change} from 'redux-form';
import {HoopsTextField} from '../HoopsTextField';
import {BuildItemButton} from '../../modules/itemBuilder/BuildItemButton';
import {PreBuiltItemDialog} from '../../modules/itemBuilder/PreBuiltItemDialog';
import TaxSelect from '../../modules/tax/TaxSelect';
import {renderTextField} from '../shared/formHelpers/fields';
import IconUploadField from '../shared/formHelpers/IconUploadField';
import S3FileUpload from '../shared/formHelpers/S3FileUpload';
import {decimalPlaces, toFloat} from '../shared/numberUtils';
import ProductImageSelector from './ProductImageSelector';
import {get} from 'lodash';
import EditorField from '../companies/editor';
import ProductField from '../Products/productAutocomplete/ProductField';
import {useLazyQuery} from '@apollo/client';
import {GQL_GET_PRODUCT} from '../../queries/product';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

export default function QuoteItemFieldRow({
  field,
  index,
  handleDelete,
  handleDuplicate,
  handleUploadComplete,
  classes,
  meta,
  company,
  onQuoteChange,
  quote,
  draggableProvided, snapshot
}) {
  const selector = formValueSelector(meta.form);
  const quoteItemSelector = (state) => selector(state, `${field}`);
  const quoteItem = useSelector(quoteItemSelector);
  const [showImages, setShowImages] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [productSearch, setProductSearch] = useState(false);
  const dispatch = useDispatch();

  const [getProductData] = useLazyQuery(GQL_GET_PRODUCT, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (result) => {
      if (result.product) {
        handleProductSelected(result.product);
      }
    },
  });

  const handleProductSelected = useCallback((product) => {
    const productName = get(product, 'title', '');
    const productDescription = get(product, 'description');
    let description = productName;
    if (productDescription) {
      description += ` - ${productDescription}`;
    }
    // reset some elements of the line item and the priceElement
    const imageUrl = get(product, 'primaryImage.url', null);

    dispatch(change(meta.form, `${field}.product`, product));
    dispatch(change(meta.form, `${field}.description`, description));
    dispatch(change(meta.form, `${field}.imageUrl`, imageUrl));
    dispatch(change(meta.form, `${field}.priceElement`, null));
    onQuoteChange();
  }, [dispatch, field, meta.form, onQuoteChange]);

  const handlePreBuiltItemSelected = useCallback((configureProductSelected) => {
    const productDescription = get(configureProductSelected, 'description');
    const imageUrl = get(configureProductSelected, 'imageUrl', null);
    const priceElement = get(configureProductSelected, 'priceElement');
    const rate = get(configureProductSelected, 'rate');
    const quantity = get(configureProductSelected, 'quantity');
    const configuredProduct = {
      _id: configureProductSelected._id,
      name: configureProductSelected.name,
      code: configureProductSelected.code
    };

    dispatch(change(meta.form, `${field}.rate`, rate));
    dispatch(change(meta.form, `${field}.quantity`, quantity));
    dispatch(change(meta.form, `${field}.description`, productDescription));
    dispatch(change(meta.form, `${field}.imageUrl`, imageUrl));
    dispatch(change(meta.form, `${field}.priceElement`, priceElement));
    dispatch(change(meta.form, `${field}.configuredProductId`, configureProductSelected._id));
    dispatch(change(meta.form, `${field}.configuredProduct`, configuredProduct));

  }, [field, meta.form, dispatch]);

  return (
    <>
      <PreBuiltItemDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        handlePreBuiltItemSelected={handlePreBuiltItemSelected}
      />

      <TableRow
        ref={draggableProvided.innerRef}
        {...draggableProvided.draggableProps}
        style={{
          ...draggableProvided.draggableProps.style,
          background: 'white',
          whiteSpace: 'nowrap',
          display: snapshot.isDragging ? 'table' : 'table-row'
        }}
      >
        <TableCell>
          <div {...draggableProvided.dragHandleProps}>
            <DragIndicatorIcon color='secondary' />
          </div>
        </TableCell>
        <TableCell align={'center'} padding={'none'} data-intercom-target={'quote-price-builder'}>
          {productSearch || (!!quoteItem?.product?._id && !quoteItem?.priceElement)
            ? <ProductField
              name={`${field}.product`}
              field={field}
              handleProductSelected={(product) => {getProductData({variables: {_id: product._id}});setProductSearch(false);}}
            />
            : <BuildItemButton item={quoteItem} index={index} field={field} quote={quote} setDialogOpen={setDialogOpen} setProductSearch={setProductSearch} />
          }
        </TableCell>
        <TableCell align={'center'} className={classes.iconCell} padding={'none'}
          data-intercom-target={'quote-image'}>
          <S3FileUpload name={`${field}.imageUrl`}
            component={IconUploadField}
            inputProps={{accept: '.png,.jpg,.jpeg'}}
            hideLoading={true}
            onComplete={handleUploadComplete(field)}
            onClick={((quoteItem?.product && !showImages) ? () => setShowImages(true) : undefined)}
          />
        </TableCell>
        <TableCell padding={'none'} data-intercom-target={'quote-description'} className={classes.descriptionCell} >
          <Field
            name={`${field}.description`}
            component={EditorField}
            onChange={onQuoteChange}
            editorClassName={classes.editor}
            wrapperClassName={classes.wrapper}
            toolbarHidden
          />
        </TableCell>

        <TableCell padding={'none'} data-intercom-target={'quote-quantity'} className={classes.quantityCell}>
          <Field
            name={`${field}.quantity`}
            disabled={Boolean(quoteItem?.priceElement && quoteItem?.priceElement._id)}
            variant={'outlined'}
            inputProps={{
              className: classes.quoteInput,
              type: 'number',
              step: '1',
              min: '0',
            }}
            style={{maxWidth: 150}}
            component={renderTextField}
            onChange={onQuoteChange} />
        </TableCell>
        <TableCell padding={'none'} data-intercom-target={'quote-rate'} className={classes.rateCell}>

          {Boolean(quoteItem?.priceElement && quoteItem?.priceElement._id) &&
            <HoopsTextField
              value={decimalPlaces(quoteItem?.rate, 2)}
              disabled={Boolean(quoteItem?.priceElement && quoteItem?.priceElement._id)}
              variant={'outlined'}
              type='number'
              InputProps={{
                inputProps: {
                  className: [classes.quoteInput, classes.amountInput],
                  step: '0.01',
                },
                startAdornment: <InputAdornment position={'start'}>{company.currencySymbol}</InputAdornment>,
                className: classes.inputStyles
              }}
              className={classes.quoteInput}
              style={{maxWidth: 150}}
              onChange={onQuoteChange}
            />
          }
          <Field
            name={`${field}.rate`}
            disabled={Boolean(quoteItem?.priceElement && quoteItem?.priceElement._id)}
            variant={'outlined'}
            type='number'
            InputProps={{
              inputProps: {
                className: [classes.quoteInput, classes.amountInput],
                step: '0.01',
              },
              startAdornment: <InputAdornment position={'start'}>{company.currencySymbol}</InputAdornment>,
              className: classes.inputStyles
            }}
            className={classes.quoteInput}
            style={quoteItem?.priceElement && quoteItem?.priceElement._id ? {display: 'none'} : {maxWidth: 150}}
            component={HoopsTextField}
            onChange={onQuoteChange}
          />

        </TableCell>
        <TableCell align={'center'} className={classes.taxCell} padding={'none'}>
          <Field
            name={`${field}.tax`}
            component={TaxSelect}
            variant='outlined'
            style={{maxHeight: '35px'}}
            onChange={onQuoteChange}
          />
        </TableCell>
        <TableCell padding={'none'} className={classes.subTotalCell} align={'right'}>
          <Typography style={{whiteSpace: 'nowrap'}}>
            {company.currencySymbol} {decimalPlaces(decimalPlaces(quoteItem?.rate || 0, 2) * toFloat(quoteItem?.quantity), 2)}
          </Typography>
        </TableCell>
        <TableCell className={classes.actionsCell} padding={'none'} align={'center'}>
          <Icon color={'secondary'} style={{cursor: 'pointer'}} onClick={() => handleDuplicate(index)}>file_copy</Icon>
          &nbsp;
          <Icon color={'secondary'} style={{cursor: 'pointer'}} onClick={() => { handleDelete(index); setProductSearch(false); }}>delete</Icon>
        </TableCell>
      </TableRow>
      {!!quoteItem?.product && !!quoteItem?.product.colors && !!quoteItem?.product.colors.length && showImages && (<>
        <Field name={`${field}.imageUrl`}
          component={ProductImageSelector}
          product={quoteItem?.product}
          onHide={() => setShowImages(false)}
          onChange={onQuoteChange}
        />
      </>)}
    </>
  );
}
