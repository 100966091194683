// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import React, {Component} from 'react';
import {Tooltip, Button, Grid} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';

const styles = (theme) => ({
  thumbnail: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '50px',
    width: '50px'
  },
  popper: {opacity: 1},
  tooltip: {maxWidth: '450px'},
});

class ProofsTableThumbnail extends Component {
  render() {
    const {imageUrl, classes} = this.props;
    return (
    <Tooltip
      classes={{popper: classes.popper, tooltip: classes.tooltip}}
      title={<img src={imageUrl} alt='' style={{maxWidth: '100%'}}/>}
      placement={'right'}
    >
      <Button>
        <Grid className={classes.thumbnail} style={{backgroundImage: `url('${imageUrl}')`}}></Grid>
      </Button>
    </Tooltip>
    );
  }
}

export default withStyles(styles)(ProofsTableThumbnail);
