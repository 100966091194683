// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React, {Component} from 'react';
import {Grid, Typography, Button, withStyles, Chip, IconButton, MuiThemeProvider} from '@material-ui/core';
import {get} from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import {connect} from 'react-redux';
import green from '../../../ui/theme/green';
import {toggleEditProduct as _toggleEditProduct} from '../../../actions/catalog';
import {Field} from 'react-final-form';
import {renderTextField} from '../../shared/formHelpers/fields';
import {
  openCreateProductVariantModal as _openCreateProductVariantModal,
  openTagModal as _openTagModal,
} from '../../../actions/modals';
import {ProductImage} from './ProductImage';

const styles = (theme) => ({
  productImage: {
    width: '100%',
    maxHeight: '400px',
    objectFit: 'contain'
  },
  mutedText: {
    color: theme.colors.grey.main,
    whiteSpace: 'pre-wrap',
    maxHeight: 180,
    overflowY: 'auto'
  },
  bufferRight: {marginRight: theme.spacing(1)}
});

function RenderChips({chips, onClick, disabled}) {
  return (
    <Grid item container spacing={1}>
      {
        chips?.map((chip, idx) => (
          <Grid item key={idx}>
            <Chip label={chip.name}/>
          </Grid>
        ))
      }
      {!disabled && <Grid item>
        <IconButton color={'primary'} size={'small'} onClick={onClick}>
          <AddIcon/>
        </IconButton>
      </Grid>}
    </Grid>
  );
}

class ProductInformation extends Component {

  handleCreateProductModalOpen = (additionalContext) => {
    const {openCreateVariationModal, product} = this.props;
    openCreateVariationModal({
      ...product,
      ...additionalContext
    });
  };

  handleTagModalOpen = () => {
    const {openTagModal, product} = this.props;
    openTagModal(product);
  };

  render() {
    const {
      product,
      classes,
      editing,
      toggleEditProduct,
    } = this.props;
    const tags =  get(product, 'tags', '[]');
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <ProductImage url={get(product, 'primaryImage.url', '')} className={classes.productImage} />
        </Grid>
        <Grid item container direction={'column'} spacing={1} xs={12} md={6}>
          <Grid item container justifyContent={'space-between'}>
            <Grid item>
              { /* Title */}
              {editing ? (
                <Field
                  name={'title'}
                  component={renderTextField}
                  variant={'outlined'}
                  size={'small'}
                  margin={'none'}
                />
              ) : (
                <Typography variant={'h4'}>
                  {get(product, 'title', '')}
                </Typography>
              )}
            </Grid>
            { /* Edit-Save buttons */}
            {!product.readOnly && <Grid item>
              {editing ? (
                <MuiThemeProvider theme={green}>
                  <Button variant={'contained'} color={'primary'} type={'submit'}>Save</Button>
                </MuiThemeProvider>
              ) : (
                <Button
                  color={'primary'}
                  variant={'outlined'}
                  onClick={toggleEditProduct}
                >Edit</Button>
              )}
            </Grid>}
          </Grid>
          {/* Code */}
          <Grid item xs>
            {editing ? (
              <Grid container alignItems={'center'}>
                <Grid item>
                  <Typography variant={'h5'} className={classes.bufferRight}>
                    <span className={classes.mutedText}>Code:</span>
                  </Typography>
                </Grid>
                <Field
                  name={'code'}
                  component={renderTextField}
                  variant={'outlined'}
                  size={'small'}
                  margin={'none'}
                  style={{width: '20%'}}
                />
              </Grid>
            ) : (
              <Typography variant={'h5'}>
                <span className={classes.mutedText}>Code:</span> {get(product, 'code', '')}
              </Typography>
            )}
          </Grid>
          {/* Description */}
          <Grid item>
            {editing ? (
              <Field
                name={'description'}
                component={renderTextField}
                variant={'outlined'}
                size={'small'}
                margin={'dense'}
                multiline={true}
                rows={'2'}
                style={{width: '80%'}}
              />
            ) : (
              <Typography variant={'body2'} className={classes.mutedText}>
                {get(product, 'description', '')}
              </Typography>
            )}
          </Grid>
          {/* Colors */}
          <Grid item container>
            <Grid item>
              <Typography variant={'body1'} className={classes.mutedText}>
                Colors Available:
              </Typography>
            </Grid>
            <RenderChips chips={get(product, 'colors', [])}
                         disabled={product.readOnly}
                         onClick={() => this.handleCreateProductModalOpen({addColor: true})} />
          </Grid>
          <Grid item container>
            <Grid item>
              <Typography variant={'body1'} className={classes.mutedText}>
                Sizes Available:
              </Typography>
            </Grid>
            <RenderChips chips={get(product, 'sizes', [])}
                         disabled={product.readOnly}
                         onClick={() => this.handleCreateProductModalOpen({addSize: true})} />
          </Grid>
          {!!tags.length && <Grid item>
            <Typography variant={'body1'} className={classes.mutedText}>
              Tags
            </Typography>
            { tags.map((tag) => (
                  <Chip
                    key={tag._id}
                    label={tag.name}
                    variant={'outlined'}
                    color={'primary'}
                    className={classes.bufferRight}
                  />))
            }
            {!product.readOnly && <IconButton color={'primary'} size={'small'} onClick={this.handleTagModalOpen}>
              <AddIcon/>
            </IconButton>}
          </Grid>}
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {editing: state.catalogReducer.editing};
}

function mapDispatchToProps(dispatch) {
  return {
    toggleEditProduct: () => dispatch(_toggleEditProduct()),
    openCreateVariationModal: (context) => dispatch(_openCreateProductVariantModal(context)),
    openTagModal: (context) => dispatch(_openTagModal(context)),
  };
}

ProductInformation = withStyles(styles)(ProductInformation);
ProductInformation = connect(mapStateToProps, mapDispatchToProps)(ProductInformation);
export default ProductInformation;
