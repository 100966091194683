import React from 'react';
import {Button, IconButton, InputAdornment, makeStyles, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import classNames from 'classnames';
import {useSelector} from 'react-redux';
import {Field, FieldArray} from 'redux-form';
import TaxSelect from '../../modules/tax/TaxSelect';
import EditorField from '../companies/editor';
import QuoteFormSubtotalField from '../quotes/QuoteFormSubtotalField';
import {renderTextField} from '../shared/formHelpers/fields';
import {INVOICE_FORM} from './InvoiceModal';
import {useGetAccountingInventoryItems, useGetAccountingRevenueAccounts} from '../../hooks/api';
import HoopsSelectField from '../HoopsSelectField';
import {Grid} from '@mui/material';
import {asCurrencyStringCommaSeparated} from '../../utils';

const useStyles = makeStyles((theme) => ({
  noBorder: {borderStyle: 'none'},
  widthAuto: {width: 'auto'},
  iconCell: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: 30,
  },
  padding1: {padding: theme.spacing(1), verticalAlign: 'middle'},
  narrowColumn: {width: 150,},
  selectColumn: {width: 250,},
  narrowColumntextAlignRight: {width: 150,textAlign: 'right'},
  textAlignRight: {textAlign: 'right'},
  // WYSIWYG overrides
  editor: {
    overflowY: 'auto',
    '& div+div': {marginTop: theme.spacing(1)},
    '& .public-DraftStyleDefault-block': {
      margin: 0,
      padding: theme.spacing(.5),
      paddingTop: 7,
      paddingLeft: 7,
    },
  },
  wrapper: {
    border: '1px solid #BDBDBD',
    borderRadius: theme.spacing(.5),
    minHeight: 33,
  },
  extraInfoContainer: {paddingLeft: theme.spacing(.75),},
  referenceField: {
    '& input': {
      paddingTop: theme.spacing(1.3125),
      paddingBottom: theme.spacing(1.3125),
    },
    '&>*': {width: 400,},
    marginBottom: theme.spacing(2),
  },
  accountSelect: {width: 400,},
}));

function RenderItemFields({fields, inventoryItems, revenueAccounts}) {
  const companySelector = (state) => state.companyReducer.company;
  const company = useSelector(companySelector);
  const classes = useStyles();

  const handleDelete = (index) => {
    fields.splice(index, 1);
  };

  const handleAddLine = () => {
    fields.push({});
  };

  return (
    <>
      {fields.map((field, index) => {
        const item = fields.get(index);

        return (
          <TableRow key={item._id ?? index} style={{verticalAlign: 'top'}}>
            <TableCell
              data-intercom-target='invoice-description'
              className={classNames(classes.padding1)}
            >
              <Field
                name={`${field}.description`}
                component={EditorField}
                editorClassName={classes.editor}
                wrapperClassName={classes.wrapper}
                toolbarHidden
              />
            </TableCell>
            {company?.accountingPlatform?.platform === 'XERO' &&
              <TableCell className={classNames(classes.padding1)}>
                <Field
                  name={`${field}.accountingRevenueAccount`}
                  component={HoopsSelectField}
                  style={{top: '1px', maxHeight: '35px'}}
                >
                  {revenueAccounts?.items?.map((acc) => (
                    <MenuItem key={acc.id} value={acc.id}>
                      {acc.name}
                    </MenuItem>
                  ))}
                </Field>
              </TableCell>
            }
            <TableCell className={classNames(classes.padding1)}>
              <Field
                name={`${field}.accountingInventoryItem`}
                component={HoopsSelectField}
                style={{top: '1px', maxHeight: '35px'}}
                MenuProps={{style: {maxHeight: 500}}}
              >
                {inventoryItems?.items?.map((invItem) => (
                  <MenuItem key={invItem.id} value={invItem.id}>
                    {invItem.name}
                  </MenuItem>
                ))}
              </Field>
            </TableCell>
            <TableCell
              className={classNames(classes.padding1)}
              data-intercom-target='invoice-quantity'
            >
              <Field
                name={`${field}.qty`}
                variant='outlined'
                inputProps={{
                  className: [classes.padding1, classes.textAlignRight],
                  type: 'number',
                  step: '1',
                  min: '0'
                }}
                component={renderTextField}
              />
            </TableCell>
            <TableCell
              className={classNames(classes.padding1)}
              data-intercom-target='invoice-rate'
            >
              <Field
                name={`${field}.rate`}
                variant='outlined'
                inputProps={{
                  className: [classes.padding1, classes.textAlignRight],
                  type: 'number',
                  step: '0.01',
                }}
                InputProps={{startAdornment: <InputAdornment position='start'>{company.currencySymbol}</InputAdornment>}}
                component={renderTextField}
              />
            </TableCell>
            <TableCell align='center' className={classNames(classes.padding1)}>
              <Field
                name={`${field}.tax`}
                component={TaxSelect}
                variant='outlined'
                style={{top: '1px', maxHeight: '35px'}}
              />
            </TableCell>
            <TableCell className={classNames(classes.padding1)} align='right'>
              <Typography>
                {company.currencySymbol}
                {asCurrencyStringCommaSeparated(item.rate * item.qty)}
              </Typography>
            </TableCell>
            <TableCell className={classNames(classes.padding1)} align='center'>
              <IconButton onClick={() => handleDelete(index)}>
                <DeleteIcon color='secondary' />
              </IconButton>
            </TableCell>
          </TableRow>
        );
      })}
      <TableRow>
        <TableCell colSpan={6} className={classes.noBorder}>
          <Button color='primary' variant='outlined' onClick={handleAddLine}>Add Line</Button>
        </TableCell>
      </TableRow>
    </>
  );
}

function InvoiceTable() {
  const company = useSelector((state) => state.companyReducer.company);

  const classes = useStyles();

  const {data: inventoryItems} = useGetAccountingInventoryItems();
  const {data: revenueAccounts} = useGetAccountingRevenueAccounts();

  const columns = [{
    title: 'Description',
    class: classes.widthAuto
  }, ...(company?.accountingPlatform?.platform !== 'XERO' ? [] : [{
    title: 'Account',
    class: classes.selectColumn,
  }]), {
    title: company?.accountingPlatform?.platform === 'XERO' ? 'Item' : 'Product / Service',
    class: classes.selectColumn,
  }, {
    title: 'Qty',
    align: 'center',
    class: classes.narrowColumn
  }, {
    title: 'Rate/Unit',
    class: classes.narrowColumn,
    align: 'center'
  }, {
    title: 'Tax',
    align: 'center',
    class: classes.narrowColumn
  }, {
    title: 'Total',
    align: 'center',
    class: classes.narrowColumntextAlignRight
  }, {
    title: 'Actions',
    align: 'center',
    class: classes.iconCell
  }];

  return (
    <>
      <Grid className={classes.extraInfoContainer}>
        <Typography variant={'caption'}>Reference</Typography>
        <Field className={classes.referenceField} name={'customReference'} variant='outlined' component={renderTextField}/>
      </Grid>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.title}
                align={column.align}
                className={column.class}
              >
                {column.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <FieldArray
            name='items'
            component={RenderItemFields}
            props={{inventoryItems, revenueAccounts}}
          />
          <TableRow style={{verticalAlign: 'top'}}>
            <TableCell
              className={classNames(classes.padding1)}
              style={{border: 'none'}}
              colSpan='9'
            >
              <QuoteFormSubtotalField leftSpaceColSpan={4} form={INVOICE_FORM} />
            </TableCell>
          </TableRow>

        </TableBody>
      </Table>
    </>
  );
}

export default InvoiceTable;
