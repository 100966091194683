import {Grid, Typography, makeStyles} from '@material-ui/core';
import React from 'react';
import {useFormContext} from 'react-hook-form';
import FormattedCurrency from '../../../componentsOld/reporting/FormattedCurrency';

import {calculatePriceElementBreakdown} from '../itemBuilderUtils';

const useStyles = makeStyles((theme) => ({
    totalCellsLabel: {
        padding: theme.spacing(2),
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(4),
    },
    totalCellsValue: {
        padding: theme.spacing(2),
        paddingLeft: theme.spacing(0),
    },
    totalLabel: {fontWeight: 300},
    totalValue: {fontWeight: 500},
}));

export const DecorationTotals = () => {
    const {watch} = useFormContext();
    const priceElement = watch() || [];

    const classes = useStyles();

    const values = calculatePriceElementBreakdown(priceElement);

    const totals = [
        {label: 'Total Quantity', value: values.productQuantity},
        {label: 'Total Cost', value: values.decorationTotalCost, currency: true},
        {label: 'Total Sell Price', value: values.decorationTotalSellPrice, currency: true},
        {label: 'Profit', value: values.decorationTotalProfit, currency: true},
    ];

    return (
        <Grid container direction='row' justifyContent='flex-end' alignItems='flex-start'>
            {totals.map((total, tIdx) => (
                    <Grid item key={`totals${tIdx}`} >
                        <Grid container >
                            <Grid item className={classes.totalCellsLabel}>
                                <Typography variant='subtitle2' className={classes.totalLabel}>
                                    {total.label}:
                            </Typography>
                            </Grid>
                            <Grid item className={classes.totalCellsValue}>
                                <Typography variant='subtitle2' className={classes.totalValue}>
                                    {
                                        total.currency
                                            ? <FormattedCurrency justifyContent={'flex-start'}>{total.value}</FormattedCurrency>
                                            : total.value
                                    }
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
        </ Grid>
    );
};

export default DecorationTotals;
