// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import {Button, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {FieldArray, getFormValues} from 'redux-form';
import {openPurchaseOrderFileUploadModal as _openPurchaseOrderFileUploadModal} from '../../../actions/modals';
import TaxService from '../../../modules/tax/TaxService';
import POFooterComponent from '../../shared/modals/PurchaseOrdersModals/POFooterComponent';
import {PoSubtotal} from './PoSubtotals';
import PurchaseOrderItemFieldRow from './PurchaseOrderItemFieldRow';

function RenderItemFields({fields, classes, meta, company, openPurchaseOrderFileUploadModal, formValues}) {

  const handleDelete = (index) => () => {
    fields.splice(index, 1);
  };

  // TODO: get the default revenue TAX
  const handleAddLine = () => {
    const match = TaxService.getExpenseDefaultTax(company);

    fields.push({tax: match || {}});
  };

  return (
    <Fragment>
      {fields.map((item, index) => (
          <PurchaseOrderItemFieldRow
            key={index}
            field={item}
            fields={fields}
            index={index}
            handleDelete={handleDelete}
            formValues={formValues}
            openPurchaseOrderFileUploadModal={openPurchaseOrderFileUploadModal}
            classes={classes}
            meta={meta}
            company={company}
          />
        ))}
      <TableRow>
        <TableCell colSpan={6} className={classes.noBorder}>
          <Button color={'primary'} variant={'outlined'} onClick={handleAddLine}>Add Lines</Button>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

const styles = (theme) => (
  {
    noBorder: {borderStyle: 'none'},
    leftSpace: {height: 350},
    descriptionCell: {
      minWidth: '210px',
      width: '73%',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    autocompleteInput: {padding: 0},
    paddedCell: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      width: '5%',
    },
    actionCell: {
      paddingLeft: '0px !important',
      paddingRight: '0px !important',
      textAlign: 'center',
      width: 80
    },
    buildItemButtonCell: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      textAlign: 'center',
      width: '5%'
    },
    quoteInput: {padding: theme.spacing(1)},
    subTotalCell: {
      paddingRight: theme.spacing(1),
      width: '10%'
    },
    narrowColumn: {minWidth: 150},
    taxCell: {width: '6%',},
    amountInput: {textAlign: 'right'}
  }
);

class PurchaseOrderTable extends React.Component {
  state = {
    totals: {
      subTotal: 0,
      taxes: [],
      total: 0
    }
  };

  render() {
    const {classes, purchaseOrderForm, company, formValues} = this.props;
    const purchaseOrderColumns = [
      {
        title: 'Item Builder',
        class: classes.buildItemButtonCell,
        align: 'center'
      }, {
        title: 'Description',
        class: classes.descriptionCell
      }, {
        title: 'Tax',
        align: 'center',
        class: classes.taxCell
      }, {
        title: 'Sub Total',
        align: 'right',
        class: classes.subTotalCell
      }, {
        title: 'Attach',
        class: classes.paddedCell,
        align: 'center'
      }, {
        title: 'Actions',
        class: classes.actionCell
      }];
    return (
      <Fragment>
        <Table>
          <TableHead>
            <TableRow>
              {purchaseOrderColumns.map((column, index) => (
                <TableCell key={index} align={column.align}
                  className={column.class}>{column.title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <FieldArray
              purchaseOrderForm={purchaseOrderForm}
              classes={classes}
              name={'items'}
              formValues={formValues}
              component={RenderItemFields}
              {...this.props} />
            <PoSubtotal company={company} internalComponent={POFooterComponent} classes={classes} />
          </TableBody>
        </Table>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    company: state.companyReducer.company,
    jobs: state.jobsReducer.list,
    formValues: getFormValues('purchaseOrderForm')(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {openPurchaseOrderFileUploadModal: (context) => dispatch(_openPurchaseOrderFileUploadModal(context))};
}

PurchaseOrderTable = connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderTable);
PurchaseOrderTable = withStyles(styles)(PurchaseOrderTable);

export default PurchaseOrderTable;

