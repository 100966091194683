// TODO: fix eslint disable
/* eslint-disable no-unused-vars */

import {useApolloClient, useQuery} from '@apollo/client';
import {Button, Checkbox, CircularProgress, DialogContent, Divider, MenuItem, Typography} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {gql} from 'apollo-boost';
import React, {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {snackError} from '../../actions/action-types';
import HoopsSelectField from '../HoopsSelectField';
import ShortcodeList from '../../modules/emailTemplate/ShortcodeList';
import {GQL_EMAIL_TEMPLATE_GET_MANY} from '../../queries/emailTemplate';
import theme from '../../ui/theme';
import EditorField from '../companies/editor';
import FormHookTextField from '../FormHooks/FormHookTextField';
import DialogActions from '../shared/DialogActions';

const smsBodyLimit = 150;
const classes = {};

export const GQL_TEMPLATES = gql`
    query EmailTemplateMany(
        $filter: FilterFindManySearchGCemailTemplateInput,
        $sort: SortFindManyEmailTemplateInput,
        $limit: Int,
        $skip: Int
    ){
        emailTemplateMany(
            filter: $filter
            sort: $sort
            limit: $limit
            skip: $skip
        ) {
            title
            subject
            entity
            isDefault
            via
            _id
        }
    }
`;
export const GQL_TEMPLATE_DETAIL = gql`
    query EmailTemplateOne(
        $_id: MongoID!
    ){
        emailTemplateById(_id: $_id) {
            body
            _id
        }
    }
`;

export default function NotificationForm({onSubmit, onClose,defaultValues, entity}) {
  const [loading,setLoading] = useState(false);
  const dispatch = useDispatch();
  const {control, handleSubmit, errors, watch, setValue} = useForm({defaultValues});
  const {
    data: templates,
    loading: loadingTemplates
  } = useQuery(GQL_EMAIL_TEMPLATE_GET_MANY, {variables: {filter: {entity}}});
  const client = useApolloClient();
  const getBody = async (_id) => client.query({query: GQL_TEMPLATE_DETAIL, variables: {_id}});
  const viaEmail = watch('viaEmail', defaultValues.viaEmail);
  const viaSms = watch('viaSms', defaultValues.viaSms);
  const emailTemplates = (templates && templates.emailTemplateMany && templates.emailTemplateMany.filter(({via}) => via === 'email')) || [];
  const smsTemplates = (templates && templates.emailTemplateMany && templates.emailTemplateMany.filter(({via}) => via === 'sms')) || [];

  return (
    <form onSubmit={handleSubmit(async (model) => {
      setLoading(true);
      try {
        await onSubmit(model);
      } catch (e) {
        dispatch(snackError(e.message));
      }
      setLoading(false);
    })}>
      <DialogContent style={{paddingTop: 0, overflowY: 'hidden'}}>
        {/*<Backdrop open={!quote._id} className={this.props.classes.backdrop}>*/}
        {/*  <CircularProgress color="inherit"/>*/}
        {/*</Backdrop>*/}
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <Typography variant='body1' display='inline'>Send Via:</Typography>
            <Controller
              name='viaEmail'
              control={control}
              render={(
                {onChange, onBlur, value, name, ref},
              ) => (
                <>
                  <Checkbox
                    onBlur={onBlur}
                    onChange={(e) => {
                      onChange(e.target.checked);
                    }}
                    checked={!!value}
                    inputRef={ref}
                    variant='outlined'
                    size='small'
                    fullWidth
                    type={'checkbox'}
                    helperText={errors.viaEmail && errors.viaEmail.message} error={!!errors.viaEmail}
                  />
                  <Typography variant='body1' display='inline'>Email</Typography>
                </>
              )
              }
            />

            <Controller
              name='viaSms'
              control={control}
              render={(
                {onChange, onBlur, value, ref},
              ) => (
                <>
                  <Checkbox
                    onBlur={onBlur}
                    onChange={(e) => {
                      onChange(e.target.checked);
                    }}
                    checked={!!value}
                    inputRef={ref}
                    variant='outlined'
                    size='small'
                    fullWidth
                    type={'checkbox'}
                    helperText={errors.viaSms && errors.viaSms.message} error={!!errors.viaSms}
                  />
                  <Typography variant='body1' display='inline'>SMS</Typography>
                </>
              )}
            />
            {viaEmail &&
            <Grid container spacing={3}>
              <Grid item sm={12}>
                <Typography variant='h5'>Email</Typography>
              </Grid>

              <Grid item sm={12} md={5}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <FormHookTextField
                      control={control}
                      errors={errors}
                      name={'to'}
                      label={'To:'}
                      type={'email'}
                      rules={{
                        required: {
                          value: true,
                          message: 'Required'
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormHookTextField
                      control={control}
                      errors={errors}
                      name={'cc'}
                      label={'CC:'}
                      type={'email'}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormHookTextField
                      control={control}
                      errors={errors}
                      name={'replyTo'}
                      label={'Reply To:'}
                      type={'email'}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <HoopsSelectField
                      label={'Template:'}
                      disabled={loadingTemplates}
                      onChange={(e) => {
                        setValue('subject', e.target.value.subject);
                        getBody( e.target.value._id)
                          .then(({data}) =>  setValue('body', data.emailTemplateById.body));
                      }}
                    >
                      {emailTemplates.map((template) => (<MenuItem key={template._id} value={template}>{template.title}</MenuItem>))}
                    </HoopsSelectField>
                  </Grid>
                  <Grid item xs={12}>
                    <FormHookTextField
                      control={control}
                      errors={errors}
                      name={'subject'}
                      rules={{
                        required: {
                          value: true,
                          message: 'Required'
                        }
                      }}
                      label={'Subject:'}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={12} md={7}>
                <Typography variant='caption' className={classes.greyText}>Body</Typography>
                <Controller
                  control={control}
                  name={'body'}
                  rules={{
                    required: {
                      value: true,
                      message: 'Required'
                    }
                  }}
                  render={({onChange, onBlur, value,}) => (
                      <EditorField
                        input={{
                          value,
                          onChange
                        }}
                        onBlur={onBlur}
                        error={errors.body}
                        value={value}
                        label={'Body:'}
                      />
                    )}
                />
              </Grid>
            </Grid>
            }

            {viaEmail && viaSms &&
            <Grid item sm={12}>
              <br/>
              <br/>
              <Divider/>
            </Grid>
            }

            {/* SMS Form  */}

            {viaSms &&
            <Grid container spacing={3}>

              <Grid item sm={12}>
                <Typography variant='h5'>SMS</Typography>
              </Grid>

              <Grid item sm={12} md={5}>

                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <FormHookTextField
                      control={control}
                      errors={errors}
                      name={'phone'}
                      label={'Phone Number:'}
                      rules={{
                        required: {
                          value: true,
                          message: 'Required'
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <HoopsSelectField
                      label={'Template:'}
                      disabled={loadingTemplates}
                      onChange={(e) => {
                        getBody( e.target.value._id)
                          .then(({data}) =>  setValue('smsBody', data.emailTemplateById.body));
                      }}
                    >
                      {smsTemplates.map((template) => (<MenuItem key={template._id} value={template.body}>{template.title}</MenuItem>))}
                    </HoopsSelectField>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={12} md={7}>
                <FormHookTextField
                  control={control}
                  name={'smsBody'}
                  rules={{
                    required: {
                      value: true,
                      message: 'Required'
                    }
                  }}
                  label='Body'
                  multiline
                  rows={10}
                  style={{paddingBottom: theme.spacing(1)}}
                  inputProps={{maxlength: smsBodyLimit}}
                />
                <Typography variant='body2'
                            className={classes.greyText}>{`Max ${smsBodyLimit} Characters. USD $0.07 per SMS charges apply.`}</Typography>
              </Grid>
            </Grid>
            }

            <Grid item sm={7}>
              <ShortcodeList entity={entity}/>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={'space-between'}>
          <Grid item>
            <Button color={'secondary'} onClick={onClose}>Cancel</Button>
          </Grid>
          <Grid item>
            <Button
              variant='contained'
              disabled={loading || (!viaSms && !viaEmail)}
              color='primary'
              type={'submit'}
            >
              {(loading ? <CircularProgress size={24}/> : 'Send')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </form>
  );
}
