import {QuoteService} from '../servicesOld/QuoteService';
import {snackError, snackSuccess} from './action-types';
import {hoopsQuerySetRefetch} from './hoopsQuery';
import {SET_JOB} from './jobs';

export const SET_QUOTES = 'SET_QUOTES';
export const SET_QUOTE = 'SET_QUOTE';
export const CLEAR_QUOTE = 'CLEAR_QUOTE';
export const SEND_QUOTE_LOADING = 'SEND_QUOTE_LOADING';
export const SEND_QUOTE_COMPLETE = 'SEND_QUOTE_COMPLETE';
export const SEND_QUOTE_ERROR = 'SEND_QUOTE_ERROR';
export const SET_QUOTE_FORM = 'SET_QUOTE_FORM';
export const SET_PREVIEW_LOADING = 'SET_PREVIEW_LOADING';
export const SET_LOADING_QUOTES = 'SET_LOADING_QUOTES';

export function setQuoteForm(_id) {
  return function (dispatch) {
    QuoteService.getQuote(_id)
      .then((quote) => {
        dispatch({
          type: SET_QUOTE_FORM,
          payload: quote
        });
      });
  };
}

export function setQuoteFormData(quote) {
  return function (dispatch) {
    dispatch({type: SET_QUOTE_FORM, payload: quote});
  };
}

export function clearQuote() {
  return function (dispatch) {
    dispatch({type: CLEAR_QUOTE,});
  };
}

export function clearQuoteForm() {
  return function (dispatch) {
    dispatch({
      type: SET_QUOTE_FORM,
      payload: {}
    });
  };
}

export function getQuotes(options = {}, cb) {
  const {q, page, pageLimit} = options;
  return function (dispatch) {
    dispatch({
      type: SET_LOADING_QUOTES,
      payload: true
    });
    return QuoteService.getQuotes({q, page, pageLimit})
      .then((result) => {
        dispatch({
          type: SET_QUOTES,
          payload: result.data
        });
        if (cb) {
          cb(result);
        }
      })
      .finally(() => {
        dispatch({
          type: SET_LOADING_QUOTES,
          payload: false
        });
      });
  };
}

export function createQuote(quote) {
  return async function () {
    return QuoteService.createQuote(quote);
  };
}

function setPreviewLoading(status) {
  return function (dispatch) {
    dispatch({
      type: SET_PREVIEW_LOADING,
      payload: status
    });
  };
}

export function getQuote(_id, doLoader = true) {
  return function (dispatch) {
    if (doLoader) {
      dispatch(setPreviewLoading(true));
    }
    return QuoteService.getQuote(_id)
      .then((quote) => {
        if (doLoader) {
          dispatch(setPreviewLoading(false));
        }
        dispatch({
          type: SET_QUOTE,
          payload: quote
        });
        dispatch({
          type: SET_QUOTE_FORM,
          payload: quote
        });
      });
  };
}

export function saveQuote(data) {
  return (dispatch) => QuoteService.save(data).then((quote) => {
      dispatch({
        type: SET_QUOTE,
        payload: quote
      });
    });
}

export function deleteQuote(_id) {
  return async function (dispatch) {
    await QuoteService.deleteQuote(_id);
    await dispatch(getQuotes());
    await dispatch(hoopsQuerySetRefetch('quote', true));
  };
}

export function newDraftQuote(payload) {
  return function (dispatch) {
    return QuoteService.newDraftQuote(payload)
      .then((quote) => {
        dispatch({
          type: SET_QUOTE,
          payload: quote
        });
      });
  };
}

export function SendEmailLink(_id, formValues, renderSiblings = false) {
  const viaMessage = `${formValues.viaEmail ? 'email' : ''} ${(formValues.viaEmail && formValues.viaSms) ? '&' : ''} ${formValues.viaSms ? 'sms' : ''}`;
  return function (dispatch) {
    dispatch({type: SEND_QUOTE_LOADING});
    return QuoteService.sendEmailLink(_id, formValues, renderSiblings)
      .then((result) => {
        dispatch({type: SEND_QUOTE_COMPLETE});
        dispatch(getQuotes());
        dispatch(snackSuccess(`Quote number #${result.quote.number} has been sent via ${viaMessage}`));
      })
      .catch((err) => {
        console.error(err);
        dispatch({type: SEND_QUOTE_ERROR,});
        dispatch(snackError('Error Sending Email. Please carefully check your email template and short codes.'));
      });
  };
}

export function convertToJob(_id) {
  return function (dispatch) {
    return QuoteService.convertToJob(_id)
      .then((job) => {
        dispatch({
          type: SET_JOB,
          payload: job
        });
      });
  };
}

export function duplicateQuote(_id) {
  return (dispatch) => QuoteService.duplicateQuote(_id)
      .then((newQuote) => {
        //delete newQuote._id;
        //is not deleting the quoteId because in case of a
        // duplicate quote the id is useful
        dispatch({
          type: SET_QUOTE_FORM,
          payload: newQuote
        });
      })
      .catch((err) => console.error(err));
}

export function updateQuoteStatus(_id, status) {
  return (dispatch) => {
    QuoteService
      .updateQuoteStatus(_id, status)
      .then((quote) => {
        dispatch({
          type: SET_QUOTE,
          payload: quote
        });
        dispatch(hoopsQuerySetRefetch('quote', true));
      });
  };
}
