import React, {useContext} from 'react';
import GrossMarginReportSettings from './GrossMargin/GrossMarginReportSettings';
import {ReportContext} from './Reporting';
import {RoyaltyReportSettings} from './RoyaltyReport';
import SalesReportSettings from './Sales/SalesReportSettings';
import {VendorReportSettings} from './VendorReport';
import {RoyaltySummaryReportSettings} from './RoyaltySummaryReport';

const ReportSettingsRenderer = ({reportType}) => {
  switch (reportType) {
    case 'sales':
      return <SalesReportSettings />;
    default:
    case 'grossMargin':
      return <GrossMarginReportSettings />;
    case 'vendor/sales':
      return <VendorReportSettings />;
    case 'fullypromoted/royalty':
      return <RoyaltyReportSettings />;
    case 'fullypromoted/summary':
      return <RoyaltySummaryReportSettings />;
  }
};

export const ReportSettings = () => {
  const {reportType} = useContext(ReportContext);
  return (<ReportSettingsRenderer reportType={reportType} />);
};

export default ReportSettings;
