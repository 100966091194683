// TODO: fix eslint disable
/* eslint-disable no-class-assign, no-shadow */

import React, {Fragment} from 'react';
import {renderTextField} from '../common/RenderTextField';
import {Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import {Typography, Link as MuiLink} from '@material-ui/core';
import {InviteUser} from '../../actions/action-types';
import {getCompany} from '../../actions/action-types/company';
import * as numeral from 'numeral';
import UserRoleSelector from '../userDashboard/UserRoleSelector';

function inviteUser(values, dispatch, props) {
  const {inviteUser, onClose, getCompany} = props;
  inviteUser(values);
  getCompany();
  onClose();

}

class InviteUserForm extends React.Component {

  UNSAFE_componentWillMount() {
    const {getCompany} = this.props;
    getCompany();
  }

  render() {
    const {company} = this.props;
    const {priceOfNextUser} = company;

    return (
      <Fragment>
        <form onSubmit={this.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field component={renderTextField}
                InputProps={{fullWidth: true}}
                label={'Name'}
                name={'fullName'}
                data-intercom-target={'invite-user-name'} />
            </Grid>
            <Grid item xs={12}>
              <Field component={renderTextField}
                InputProps={{fullWidth: true}}
                label={'Email'}
                name={'email'}
                data-intercom-target={'invite-user-email'} />
            </Grid>
            <Grid item xs={12}>
              <Typography color={'textSecondary'} variant={'body1'} gutterBottom>Permission</Typography>
              <Field
                component={UserRoleSelector}
                name={'role'}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'body1'} gutterBottom>Want the full breakdown of user access?
                <MuiLink color={'primary'} underline={'none'} href={'http://help.hoopscrm.com/en/articles/5026846-user-roles-permissions'} target='_blank'>
                  &nbsp;Click Here
                </MuiLink>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {priceOfNextUser > 0 ? (
                <Fragment>
                  <Typography color={'textSecondary'} variant={'body1'}>
                    <strong>{numeral(priceOfNextUser / 100).format('$0,0.00')}</strong> will be added to your monthly billing
                    cycle.
                  </Typography>
                </Fragment>
              ) : <Typography color={'textSecondary'} variant={'body1'}>
                  Your bill won{'\''}t change with this invitation.
              </Typography>}
              <Typography color={'textSecondary'} variant={'body1'}>Thanks for using Hoops.</Typography>
            </Grid>
          </Grid>
        </form>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {company: state.companyReducer.company};
}

function mapDispatchToProps(dispatch) {
  return {
    getCompany: () => dispatch((getCompany())),
    inviteUser: (user) => dispatch((InviteUser(user))),
  };
}

InviteUserForm = reduxForm({
  form: 'inviteUserForm',
  onSubmit: inviteUser
})(InviteUserForm);
InviteUserForm = connect(mapStateToProps, mapDispatchToProps)(InviteUserForm);

export default InviteUserForm;
