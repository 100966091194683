import {gql, useMutation} from '@apollo/client';
import {ErrorMessage} from '../../../componentsOld/ErrorMessage';
import {Button, CircularProgress} from '@material-ui/core';
import React from 'react';

const REMOVE_INTEGRATION = gql`
    mutation removeStripeIntegration{
        removeStripeIntegration
    }
`;

export function ButtonDisconnectStripe({children}) {
  const [removeStripeIntegration, {loading, error, refetch}] = useMutation(REMOVE_INTEGRATION);
  return (
    <div>
      {error && <ErrorMessage error={error} onClick={() => refetch()}/>}
      <Button variant='outlined' color='primary' disabled={loading}
              onClick={async () => {
                await removeStripeIntegration({refetchQueries: ['integrationOne']});
              }}>
        {children}
        {loading && <CircularProgress size={24}/>}
      </Button>
    </div>
  );
}
