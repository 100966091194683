import {Button, Dialog, DialogActions, DialogContent, Typography} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import {Close} from '@material-ui/icons';
import React, {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {HoopsTextField} from '../../HoopsTextField';
import DialogTitle from '../../shared/DialogTitle';
import LoadingButton from '../../shared/LoadingButton';
import {styles} from './JobDetails/JobCustomerCard';

function _ShippingInstructions({instructions, classes, onSubmit, updating}) {
  const {control, handleSubmit, errors} = useForm({initialValues: {shippingInstructions: instructions + '1233'}});
  const [open, setOpen] = useState(false);
  const onCancel = () => setOpen(false);
  const _onSubmit = async (model) => {
    onSubmit(model);
    setOpen(false);
  };

  const title = instructions ? 'Update Shipping Instructions' : 'Add Shipping Instructions';
  return (
    <>
      <br/>
      <br/>
      <br/>
      {instructions && <>
        <Typography className={classes.detailTitle}>Shipping Instructions</Typography>
        <Typography className={classes.detailContent}>{instructions}</Typography>
      </>}
      <Button
        key={2}
        variant={'text'}
        color={'primary'}
        onClick={() => setOpen(true)}
      >{title}</Button>
      <Dialog
        maxWidth={'md'}
        fullWidth
        open={open}
        onCancel={onCancel}
      >
        <form onSubmit={handleSubmit(_onSubmit)} autoComplete='off'>
          <DialogTitle onClick={onCancel}>
            <Typography variant={'h4'}>{title}</Typography>
          </DialogTitle>
          <DialogContent>
            <Controller
              as={<HoopsTextField
                multiline
                rows={5}
                size='small'

                label='Shipping Instructions'
                autoComplete='off'
                meta={{error: errors?.shippingInstructions?.message}}
                placeholder='Shipping Instructions'
              />}
              defaultValue={instructions || ''}
              name='shippingInstructions'
              control={control}
            />
            <DialogActions>
              <Grid container justifyContent={'space-between'}>
                <Grid item>
                  <Button variant={'text'}
                          color={'secondary'}
                          onClick={onCancel}
                  >
                    <Close/> Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <LoadingButton
                    isLoading={updating}
                    type={'submit'}
                    variant={'contained'}
                    color={'primary'}
                  >Save</LoadingButton>
                </Grid>
              </Grid>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    </>
  );
}

export const ShippingInstructions = withStyles(styles)(_ShippingInstructions);
