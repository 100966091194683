// TODO: fix eslint disable
/* eslint-disable no-class-assign */

import React, {Component} from 'react';
import {Button, Dialog, DialogContent, Typography, Grid, Icon} from '@material-ui/core';
import DialogTitle from '../DialogTitle';
import DialogActions from '../DialogActions';
import {connect} from 'react-redux';
import {closeJobNoteModal as _closeJobNoteModal} from '../../../actions/modals';
import {reduxForm, Field, reset as _reset} from 'redux-form';
import {required} from '../formHelpers/validation';
import {renderTextField} from '../formHelpers/fields';
import {
  addJobNote as _addJobNote,
  updateJobNote as _updateJobNote
} from '../../../actions/jobs';

class JobNoteModal extends Component {

  closeJobNoteModal = () => {
    const {closeModal, resetForm} = this.props;
    closeModal();
    resetForm();
  };

  handleSubmit = ({content}) => {
    const {Job, addJobNote, updateJobNote, context} = this.props;
    if (context && context._id) {
      updateJobNote(context._id, content, Job._id);
      this.closeJobNoteModal();
      return;
    }
    addJobNote(Job._id, content);
    this.closeJobNoteModal();
  };

  render() {
    const {isOpen, handleSubmit} = this.props;
    return (
      <Dialog
        open={isOpen}
        maxWidth={'sm'}
        fullWidth={true}
        onClose={this.closeJobNoteModal}
      >
        <DialogTitle onClick={this.closeJobNoteModal}>
          <Typography variant='h5'>Add Note to Job</Typography>
        </DialogTitle>
        <form onSubmit={handleSubmit(this.handleSubmit)}>
          <DialogContent>
            <Field
              name='content'
              component={renderTextField}
              label='Add Note *'
              multiline
              rows='4'
              validate={required}
              placeholder='Type your note here...'
              autoFocus
              variant='outlined'
            />
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent={'space-between'}>
              <Grid item>
                <Button onClick={this.closeJobNoteModal}>
                  <Icon>close</Icon>
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      variant={'contained'}
                      color={'primary'}
                      type='submit'>Save Note</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => dispatch(_closeJobNoteModal()),
    addJobNote: (jobId, content) => dispatch(_addJobNote(jobId, content)),
    updateJobNote: (noteId, content, jobId) => dispatch(_updateJobNote(noteId, content, jobId)),
    resetForm: () => dispatch(_reset('jobNotes')),
  };
}

function mapStateToProps(state) {
  return {
    isOpen: state.modalReducer.jobNoteModal.isOpen,
    Job: state.jobsReducer.activeJob,
    initialValues: state.modalReducer.jobNoteModal.context,
    context: state.modalReducer.jobNoteModal.context
  };
}

JobNoteModal = reduxForm({
  form: 'jobNotes',
  enableReinitialize: true
})(JobNoteModal);
JobNoteModal = connect(mapStateToProps, mapDispatchToProps)(JobNoteModal);
export default JobNoteModal;
