import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {ContentState, convertToRaw, EditorState, Modifier, SelectionState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {registerGlobalStyle} from '../../theme';
import {HoopsPropTypes} from '../utils';
import classNames from 'classnames';
import {useMountEffect} from '../../hooks';
import {CaptionText} from '../Text';

registerGlobalStyle('.wysiwyg-input', (theme) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'stretch',
  border: 'none',
  '&.outer-border': {
    border: `1px solid ${theme.colors.border.main}`,
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.out.all,
    '.wysiwyg-text-input': {border: 'none'},
    '.wysiwyg-input-toolbar': {borderBottom: `1px solid ${theme.colors.border.light}`},
  },
  '&:focus-within': {
    borderColor: theme.colors.border.highlight,
    transition: theme.transitions.in.all,
    // '.wysiwyg-input-toolbar': {borderColor: theme.colors.border.highlight},
    '.wysiwyg-text-input': {
      borderColor: theme.colors.border.highlight,
      transition: theme.transitions.in.all,
    },
  },
  '.wysiwyg-input-container': {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    flex: '1 0',
  },
  '&.auto-hide-toolbar': {
    '.wysiwyg-input-toolbar': {
      position: 'absolute',
      zIndex: -1000,
      transform: 'translateY(-100%)',
      border: '1px solid',
      borderColor: theme.colors.border.main,
      borderRadius: theme.shape.borderRadius,
      opacity: 0,
      pointerEvents: 'none',
      transition: theme.transitions.create('opacity', {duration: theme.transitions.duration.standard})
        + ', '
        + theme.transitions.create('z-index', {delay: theme.transitions.duration.standard, duration: 0}),
    },
    '&:focus-within, &:hover': {
      '.wysiwyg-input-toolbar': {
        opacity: 1,
        pointerEvents: 'auto',
        zIndex: 1,
        transition: theme.transitions.create('opacity', {duration: theme.transitions.duration.standard}),
      },
    },
  },
  '.wysiwyg-input-toolbar': {
    padding: 0,
    margin: 0,
    border: 'none',
    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
    '& > *': {marginBottom: 2,},
    '& > * > .rdw-option-wrapper': {
      padding: 2,
      margin: 0,
      border: 'none',
      background: 'transparent',
      '&:hover': {
        border: 'none',
        background: theme.colors.background.hover,
        boxShadow: 'none',
      },
      '&.rdw-option-active': {
        border: 'none',
        background: theme.colors.background.selected,
        boxShadow: 'none',
        '&:hover': {background: theme.colors.background.selectedHover,},
      },
      'img': {
        opacity: 0.7,
        transform: 'scale(0.8)',
      },
    },
    'span, li': {
      fontSize: '.75rem',
      color: theme.colors.text.main,
    },
    '.rdw-dropdown-wrapper': {
      border: 'none',
      height: 24,
      boxShadow: 'none',
      '&:hover': {
        background: theme.colors.background.selectedHover,
        boxShadow: 'none',
      },
    },
    '.rdw-dropdown-selectedtext': {paddingRight: '20px'},
    '.rdw-dropdown-carettoopen': {
      borderTopColor: theme.colors.text.medium,
      borderWidth: '5px 4px 0px 4px',
      top: '40%',
    },
    '.rdw-dropdown-carettoclose': {
      borderBottomColor: theme.colors.text.medium,
      borderWidth: '0px 4px 5px 4px',
      top: '40%',
    },
    '.rdw-block-dropdown': {width: '92px',},
    '.rdw-fontfamily-dropdown': {width: '100px'},
    '.rdw-colorpicker-modal': {width: 200,},
    '.rdw-colorpicker-modal-options': {overflow: 'auto'},
  },
  '.rdw-option-wrapper[title="Monospace"]': {display: 'none'},
  '.rdw-option-wrapper[title="Superscript"]': {display: 'none'},
  '.rdw-option-wrapper[title="Subscript"]': {display: 'none'},
  '.rdw-option-wrapper[title="Indent"]': {display: 'none'},
  '.rdw-option-wrapper[title="Outdent"]': {display: 'none'},
  '.rdw-option-wrapper[title="Justify"]': {display: 'none'},
  '.wysiwyg-text-input': {
    margin: theme.spacing(.75, 0, 0, 0),
    padding: theme.spacing(.75),
    border: `1px solid ${theme.colors.border.main}`,
    transition: theme.transitions.out.all,
    borderRadius: theme.shape.borderRadius,
    height: 'unset',
    flex: '1 0',
    overflow: 'visible',
    zIndex: 0,
    '&>*:first-child': {
      marginTop: '-1em',
      height: '100%',
    }
  },
  '.rdw-left-aligned-block .public-DraftStyleDefault-block': {textAlign: 'left'},
  '.rdw-right-aligned-block .public-DraftStyleDefault-block': {textAlign: 'right'},
}));

const toolOptions = {options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'remove'],};

const Context = createContext(null);
export function useWysiwygContext() {
  const [editorState, setEditorState] = useState();
  const context = useContext(Context);
  const noContext = useMemo(() => ({editorState, setEditorState}), [editorState]);

  return context ? context : noContext;
}

/**
 * Wysiwyg rich text editor. Docs here: https://jpuri.github.io/react-draft-wysiwyg/#/docs
 *
 * @param className
 * @param autoToolbar
 * @param label
 * @param outerBorder
 * @param textAlignment
 * @param value
 * @param onChange
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function WysiwygEditor({className, autoToolbar, label, outerBorder, textAlignment, value, onChange, ...props}) {
  const {editorState, setEditorState, setTouched} = useWysiwygContext();
  const [_value, setValue] = useState();

  const onEditorStateChange = useCallback((newEditorState) => {
    setEditorState(newEditorState);
    const v = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
    setValue(v);
    onChange(v);
  }, [onChange, setEditorState]);

  // Clear the shared state on mount
  useMountEffect(() => {
    setEditorState(createEditorState(''));
  });

  useEffect(() => {
    // To facilitate buttons that can modify the text in the editor, we have to keep track of the
    // html state. This allows us to avoid resetting the internal state when the editor loses focus.
    // Without this, the editor will lose focus, call onChange(), a rerender will happen, this
    // component will be given a new value and will use that value to set the state of the editor,
    // losing selection and cursor position etc.
    if (value !== _value) {
      setValue(value);
      setEditorState(createEditorState(value ?? ''));
      // Touched lets us know if the user has clicked on the editor,
      // if the editor is touched the caret position has been set
      // when rerendering if the editor has focus, touched should remain true
      setTouched(editorState?.getSelection()?.getHasFocus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setEditorState, value]);

  const onBlur = useCallback(() => {
    const v = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setValue(v);
    onChange(v);
  }, [editorState, onChange]);

  const onFocused = useCallback(() => {
    setTouched(true);
  }, [setTouched]);

  return (
    <>
      {label &&
        <CaptionText text={label} />
      }
      <div className={classNames([className, 'wysiwyg-input', autoToolbar && 'auto-hide-toolbar', outerBorder && 'outer-border'])}>
        <Editor
          onBlur={onBlur}
          onFocus={onFocused}
          textAlignment={textAlignment}
          editorState={editorState}
          editorClassName='wysiwyg-text-input'
          toolbarClassName={classNames(['wysiwyg-input-toolbar', autoToolbar && 'popover-elevation'])}
          wrapperClassName='wysiwyg-input-container'
          onEditorStateChange={onEditorStateChange}
          toolbar={toolOptions}
          {...props}
        />
      </div>
    </>
  );
}

function createEditorState(value) {
  return EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(value.replace(/#DYNAMIC# ?/, '')).contentBlocks));
}

WysiwygEditor.propTypes = {
  className: HoopsPropTypes.className,
  autoToolbar: HoopsPropTypes.bool,
  label: HoopsPropTypes.string,
  outerBorder: HoopsPropTypes.bool,
  textAlignment: HoopsPropTypes.string,
  value: HoopsPropTypes.string,
  onChange: HoopsPropTypes.func.isRequired,
};

export function WysiwygProvider({children}) {
  const [editorState, setEditorState] = useState();
  const [touched, setTouched] = useState(false);

  const insertText = useCallback(({text, addToNewLine = false}) => {
    const currentContent = editorState.getCurrentContent();
    let currentSelection = editorState.getSelection();

    const anchorKey = currentSelection.getAnchorKey();
    const currentContentBlock = currentContent.getBlockForKey(anchorKey);
    const currentText = currentContentBlock.getText();

    let positionText = text;

    if (addToNewLine) {
      positionText = positionText + '\n';
    }

    // If the text editor hasn't been touched, add the new text to the end
    if (!touched) {
      // If the last line of the current text is populated and addToNewLine is true add a new line
      const currentTextLines = currentText.split('\n');
      if (currentTextLines[currentTextLines.length - 1].length > 0 && addToNewLine) {
        positionText = '\n' + positionText;
      }

      const blockMap = currentContent.getBlockMap();
      const key = blockMap.last().getKey();
      const length = blockMap.last().getLength();
      currentSelection = new SelectionState({
        anchorKey: key,
        anchorOffset: length,
        focusKey: key,
        focusOffset: length,
      });
    }

    const newContent = Modifier.replaceText(currentContent, currentSelection, positionText);
    let newState = EditorState.push(editorState, newContent, 'insert-characters');
    newState = EditorState.forceSelection(newState, newContent.getSelectionAfter());
    setEditorState(newState);
  }, [editorState, touched]);

  const context = useMemo(() => ({
    editorState,
    setEditorState,
    insertText,
    setTouched
  }), [editorState, insertText]);

  return (
    <Context.Provider value={context}>
      {children}
    </Context.Provider>
  );
}
