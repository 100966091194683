const BLUE = '#00CCF0';
const RED = '#FC5F65';
const YELLOW = '#FFBF66';
const PURPLE = '#9366AF';
const GREEN = '#22CC96';

const BLACK = '#2F323C';
const GREY = '#D9DADC';
const PINK = '#FD79A8';
const LIGHT_RED = '#FAB1A0';
const MAUVE = '#A29BFE';

export const colorsName = {
    BLUE,
    RED,
    YELLOW,
    PURPLE,
    GREEN,
    
    BLACK,
    GREY,
    PINK,
    LIGHT_RED,
    MAUVE,
};

export const colorsArray = [
    BLUE,
    RED,
    YELLOW,
    PURPLE,
    GREEN,
    
    BLACK,
    GREY,
    PINK,
    LIGHT_RED,
    MAUVE,
];
  
