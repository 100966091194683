
export const SNACKBAR_TOGGLED = 'SNACKBAR_TOGGLED';

export const SNACK_SUCCESS = 'SNACK_SUCCESS';
export const SNACK_ERROR = 'SNACK_ERROR';
export const SNACK_WARNING = 'SNACK_WARNING';
export const SNACK_INFO = 'SNACK_INFO';

export function snackbarToggle(payload) {
  return {
    type: SNACKBAR_TOGGLED,
    payload
  };
}

export function snackSuccess(payload) {
  return {
    type: SNACK_SUCCESS,
    payload
  };
}

export function snackError(payload) {
  return {
    type: SNACK_ERROR,
    payload
  };
}

export function snackWarning(payload) {
  return {
    type: SNACK_WARNING,
    payload
  };
}

export function snackInfo(payload) {
  return {
    type: SNACK_INFO,
    payload
  };
}
